var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v("Unterdepot")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [_vm._v(_vm._s((_vm.items && _vm.items.input_name) || ""))]
            },
            proxy: true
          }
        ])
      }),
      _vm.items
        ? _c(
            "BoxContainer",
            [
              _c("InputField", {
                attrs: { label: "Name", isComponentHalfSize: "" },
                model: {
                  value: _vm.input_name,
                  callback: function($$v) {
                    _vm.input_name = $$v
                  },
                  expression: "input_name"
                }
              }),
              _c("div", { staticClass: "mb-3" }, [
                _c("div", { staticClass: "mb-1" }, [_vm._v("Eigenschaften")]),
                _c(
                  "div",
                  { staticClass: "checkbox-input__container" },
                  [
                    _c("InputCheckBoxItem", {
                      attrs: { label: "Vermögensverwaltungsdepot" },
                      model: {
                        value: _vm.eigenschaft_vermoegensverwaltung,
                        callback: function($$v) {
                          _vm.eigenschaft_vermoegensverwaltung = $$v
                        },
                        expression: "eigenschaft_vermoegensverwaltung"
                      }
                    }),
                    _c("InputCheckBoxItem", {
                      attrs: { label: "Minderjährigendepot" },
                      model: {
                        value: _vm.eigenschaft_minderjaehrig,
                        callback: function($$v) {
                          _vm.eigenschaft_minderjaehrig = $$v
                        },
                        expression: "eigenschaft_minderjaehrig"
                      }
                    }),
                    _c("InputCheckBoxItem", {
                      attrs: { label: "verpfändet" },
                      model: {
                        value: _vm.eigenschaft_verpfaendet,
                        callback: function($$v) {
                          _vm.eigenschaft_verpfaendet = $$v
                        },
                        expression: "eigenschaft_verpfaendet"
                      }
                    }),
                    _c("InputCheckBoxItem", {
                      attrs: { label: "Garantiedepot" },
                      model: {
                        value: _vm.eigenschaft_garantiedepot,
                        callback: function($$v) {
                          _vm.eigenschaft_garantiedepot = $$v
                        },
                        expression: "eigenschaft_garantiedepot"
                      }
                    }),
                    _vm.eigenschaft_para14_ekst !== undefined
                      ? _c("InputCheckBoxItem", {
                          attrs: { label: "§14 ESTG" },
                          model: {
                            value: _vm.eigenschaft_para14_ekst,
                            callback: function($$v) {
                              _vm.eigenschaft_para14_ekst = $$v
                            },
                            expression: "eigenschaft_para14_ekst"
                          }
                        })
                      : _vm._e(),
                    _vm.eigenschaft_verlassenschaft !== undefined
                      ? _c("InputCheckBoxItem", {
                          attrs: { label: "Verlassenschaft" },
                          model: {
                            value: _vm.eigenschaft_verlassenschaft,
                            callback: function($$v) {
                              _vm.eigenschaft_verlassenschaft = $$v
                            },
                            expression: "eigenschaft_verlassenschaft"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("InputTextArea", {
                attrs: { label: "Beschreibung", isComponentHalfSize: "" },
                model: {
                  value: _vm.input_beschreibung,
                  callback: function($$v) {
                    _vm.input_beschreibung = $$v
                  },
                  expression: "input_beschreibung"
                }
              })
            ],
            1
          )
        : _c(
            "GhostLoading",
            { attrs: { useBoxContainer: "" } },
            [_c("Block", { attrs: { height: "264" } })],
            1
          ),
      _vm.rowsCount
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Nur Depotpositionen mit Anteilen",
                  inLineLabel: ""
                },
                model: {
                  value: _vm.nurMitAnteilen,
                  callback: function($$v) {
                    _vm.nurMitAnteilen = $$v
                  },
                  expression: "nurMitAnteilen"
                }
              }),
              _vm.rowsFonds.length
                ? _c("Table", {
                    attrs: {
                      headers: _vm.headersFonds,
                      rows: _vm.rowsFonds,
                      rowId: "index",
                      rowsPerPage: 20,
                      mobileConfig: {
                        title: "lagerstelle",
                        headers: ["fondsname", "wert", "verwendet"]
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "zuordnen",
                          fn: function(row) {
                            return [
                              _c("InputCheckBoxItem", {
                                on: {
                                  input: function($event) {
                                    return _vm.chgZuord(row)
                                  }
                                },
                                model: {
                                  value: row.zuordnen,
                                  callback: function($$v) {
                                    _vm.$set(row, "zuordnen", $$v)
                                  },
                                  expression: "row.zuordnen"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4244484015
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.items
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Automatisch diesem Depot zuordnen")
              ]),
              _c(
                "BaseButton",
                {
                  staticClass: "mt-2",
                  attrs: { isSecondary: "" },
                  on: {
                    click: function($event) {
                      return _vm.showAutozuord()
                    }
                  }
                },
                [_vm._v("Neue Autozuordnung")]
              ),
              _vm.rowsAuto.length
                ? _c("Table", {
                    attrs: {
                      headers: _vm.headersAuto,
                      rows: _vm.rowsAuto,
                      rowId: "id",
                      hidePagination: "",
                      mobileConfig: {
                        title: "lagerstelle",
                        headers: ["depot_nr"]
                      }
                    },
                    on: {
                      "action-DELETE": _vm.delAutozuord,
                      "action-EDIT": _vm.showAutozuord,
                      "click-lagerstelle": function($event) {
                        return _vm.showAutozuord($event)
                      }
                    }
                  })
                : _c("NoData")
            ],
            1
          )
        : _vm._e(),
      _c("BaseModal", {
        ref: "depotAutoZuord",
        attrs: { modalTitle: "Depot automatisch zuordnen" },
        on: { onConfirmButton: _vm.saveAutozuord },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("HinweiseUndFehler", {
                  attrs: {
                    hints: [
                      {
                        title: "Depotnummer",
                        message:
                          "Falls Sie die Depotnummer leer lassen, werden alle eingehenden Bestände dieser Lagerstelle dem Depot zugewiesen."
                      }
                    ]
                  }
                }),
                _c("ComboBox", {
                  attrs: { label: "Lagerstelle", values: _vm.lagerstellen },
                  model: {
                    value: _vm.lagerstelleId,
                    callback: function($$v) {
                      _vm.lagerstelleId = $$v
                    },
                    expression: "lagerstelleId"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Depotnummer" },
                  model: {
                    value: _vm.depotnummer,
                    callback: function($$v) {
                      _vm.depotnummer = $$v
                    },
                    expression: "depotnummer"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "virtuellesDepot",
        attrs: { modalTitle: "Neues virtuelles Depot", autoClose: false },
        on: { onConfirmButton: _vm.createVDepot, close: _vm.closeVDepot },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("InputField", {
                  ref: "depotname",
                  attrs: { label: "Depotname" },
                  model: {
                    value: _vm.input_name,
                    callback: function($$v) {
                      _vm.input_name = $$v
                    },
                    expression: "input_name"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }