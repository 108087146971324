export const MUTATION_PREFIX = 'WEBRTC_MUTATIONS_';
export const ACTIONS_PREFIX = 'WEBRTC_ACTIONS_';
export const GETTERS_PREFIX = 'WEBRTC_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_PUSH_SOCKET: MUTATION_PREFIX + 'SET_PUSH_SOCKET',
    TOGGLE_CALL_MODAL: MUTATION_PREFIX + 'TOGGLE_CALL_MODAL',
    UPDATE_BETEILIGTER_INFO: MUTATION_PREFIX + 'UPDATE_BETEILIGTER_INFO',
    UPDATE_CALLING_ID: MUTATION_PREFIX + 'UPDATE_CALLING_ID',
    SET_CALLEE_CHAT_BETEILIGTER_ID: MUTATION_PREFIX + 'SET_CALLEE_CHAT_BETEILIGTER_ID',
    UPDATE_AVAILABILITY: MUTATION_PREFIX + 'UPDATE_AVAILABILITY',
    CREATE_CALL_INFO: MUTATION_PREFIX + 'CREATE_CALL_INFO',
    CLEAR_SIGNAL_BACKLOG: MUTATION_PREFIX + 'CLEAR_SIGNAL_BACKLOG',
    SEND_SIGNAL_MESSAGE: MUTATION_PREFIX + 'SEND_SIGNAL_MESSAGE',
    RECEIVE_STREAMS: MUTATION_PREFIX + 'RECEIVE_STREAMS',
    END_CALL: MUTATION_PREFIX + 'END_CALL',
    REMOTE_ICE_CANDIDATE: MUTATION_PREFIX + 'REMOTE_ICE_CANDIDATE',
    CALL_ESTABLISHED: MUTATION_PREFIX + 'CALL_ESTABLISHED',
    REMOTE_VIDEO: MUTATION_PREFIX + 'REMOTE_VIDEO',
    REMOTE_AUDIO: MUTATION_PREFIX + 'REMOTE_AUDIO',
    ACCEPT_CALL: MUTATION_PREFIX + 'ACCEPT_CALL',
    AUDIO_INPUT: MUTATION_PREFIX + 'AUDIO_INPUT',
    SET_MUTE_STATE: MUTATION_PREFIX + 'SET_MUTE_STATE',
    TRANSMIT_VIDEO: MUTATION_PREFIX + 'TRANSMIT_VIDEO',
    SETUP_INITIAL_STREAM: MUTATION_PREFIX + 'SETUP_INITIAL_STREAM',
    CAMERA_VIDEO: MUTATION_PREFIX + 'CAMERA_VIDEO',
    ADD_CHAT_INFO: MUTATION_PREFIX + 'ADD_CHAT_INFO',
    SAVE_PUSH_PAYLOAD: MUTATION_PREFIX + 'SAVE_PUSH_PAYLOAD',
    UPDATE_CHAT_MESSAGES: MUTATION_PREFIX + 'UPDATE_CHAT_MESSAGES',
    UNSENT_CHAT: MUTATION_PREFIX + 'UNSENT_CHAT',
    INITIALIZE_CHAT_INFO: MUTATION_PREFIX + 'INITIALIZE_CHAT_INFO',
    LOAD_ATTACHMENT: MUTATION_PREFIX + 'LOAD_ATTACHMENT',
    CLOSE_ALERT_MESSAGE_AFTER_CLOSING: MUTATION_PREFIX + 'CLOSE_ALERT_MESSAGE_AFTER_CLOSING',
    CLOSED_TOO_EARLY: MUTATION_PREFIX + 'CLOSED_TOO_EARLY',
    UPDATE_RINGING_SYMBOL: MUTATION_PREFIX + 'UPDATE_RINGING_SYMBOL',
    START_RECORDING: MUTATION_PREFIX + 'START_RECORDING',
    ENUMERATE_DEVICES: MUTATION_PREFIX + 'ENUMERATE_DEVICES',
    CREATE_AUDIO_CONTEXT: MUTATION_PREFIX + 'CREATE_AUDIO_CONTEXT',
    UPDATE_REQUESTED_VIDEO: MUTATION_PREFIX + 'UPDATE_REQUESTED_VIDEO',
    WEBRTC_PREPARE_CALL_REQUEST_UUID: MUTATION_PREFIX + 'WEBRTC_PREPARE_CALL_REQUEST_UUID',
    IS_DUMMY_STREAM: MUTATION_PREFIX + 'IS_DUMMY_STREAM',
  },
  ACTIONS: {
    START_PUSH_SOCKET: ACTIONS_PREFIX + 'START_PUSH_SOCKET',
    STOP_PUSH_SOCKET: ACTIONS_PREFIX + 'STOP_PUSH_SOCKET',
    UPDATE_AVAILABILITY: ACTIONS_PREFIX + 'UPDATE_AVAILABILITY',
    OPEN_CALL_MODAL: ACTIONS_PREFIX + 'OPEN_CALL_MODAL',
    LOAD_BETEILIGTER_INFO: ACTIONS_PREFIX + 'LOAD_BETEILIGTER_INFO',
    START_CALL: ACTIONS_PREFIX + 'START_CALL',
    SETUP_WEBRTC_CALL: ACTIONS_PREFIX + 'SETUP_WEBRTC_CALL',
    CREATE_CALL_INFO: ACTIONS_PREFIX + 'CREATE_CALL_INFO',
    END_CALL: ACTIONS_PREFIX + 'END_CALL',
    ACCEPT_CALL: ACTIONS_PREFIX + 'ACCEPT_CALL',
    CALL_ESTABLISHED: ACTIONS_PREFIX + 'CALL_ESTABLISHED',
    REJECT_CALL: ACTIONS_PREFIX + 'REJECT_CALL',
    HANG_UP: ACTIONS_PREFIX + 'HANG_UP',
    MUTE: ACTIONS_PREFIX + 'MUTE',
    UNMUTE: ACTIONS_PREFIX + 'UNMUTE',
    MICROPHONE_BLOCKED: ACTIONS_PREFIX + 'MICROPHONE_BLOCKED',
    MICROPHONE_ALLOWED: ACTIONS_PREFIX + 'MICROPHONE_ALLOWED',
    REQUEST_AUDIO_INPUT: ACTIONS_PREFIX + 'REQUEST_AUDIO_INPUT',
    TRANSMIT_AUDIO: ACTIONS_PREFIX + 'TRANSMIT_AUDIO',
    TRANSMIT_VIDEO: ACTIONS_PREFIX + 'TRANSMIT_VIDEO',
    LOAD_CHAT_INFO_FOR_WEBRTC: ACTIONS_PREFIX + 'LOAD_CHAT_INFO_FOR_WEBRTC',
    SEND_CHAT_MESSAGE: ACTIONS_PREFIX + 'SEND_CHAT_MESSAGE',
    UPDATE_CHAT_MESSAGES: ACTIONS_PREFIX + 'UPDATE_CHAT_MESSAGES',
    NOTIFY_NEW_CHAT_MESSAGE: ACTIONS_PREFIX + 'NOTIFY_NEW_CHAT_MESSAGE',
    LOAD_CHAT_COMMUNICATION: ACTIONS_PREFIX + 'LOAD_CHAT_COMMUNICATION',
    LOAD_ATTACHMENT: ACTIONS_PREFIX + 'LOAD_ATTACHMENT',
    OPEN_FILE: ACTIONS_PREFIX + 'OPEN_FILE',
    DELETE_MESSAGE: ACTIONS_PREFIX + 'DELETE_MESSAGE',
    UPLOAD_CHAT: ACTIONS_PREFIX + 'UPLOAD_CHAT',
    UPDATE_RINGING_SYMBOL: ACTIONS_PREFIX + 'UPDATE_RINGING_SYMBOL',
    START_RECORDING: ACTIONS_PREFIX + 'START_RECORDING',
    HANDLE_ACTION_SUGGESTION: ACTIONS_PREFIX + 'HANDLE_ACTION_SUGGESTION',
    START_CALL_WEB: ACTIONS_PREFIX + 'START_CALL_WEB',
    START_CALL_IOS: ACTIONS_PREFIX + 'START_CALL_IOS',
    STOP_CALL_ON_LOGOUT: ACTIONS_PREFIX + 'STOP_CALL_ON_LOGOUT',
    STOP_CALL_ON_ERROR: ACTIONS_PREFIX + 'STOP_CALL_ON_ERROR',
    STOP_CALL_ON_NO_AUDIO_STREAM_AVAILABLE: ACTIONS_PREFIX + 'STOP_CALL_ON_NO_AUDIO_STREAM_AVAILABLE',
    ADD_FILE_CHAT: ACTIONS_PREFIX + 'ADD_FILE_CHAT'
  },
  GETTERS: {
    PUSH_SOCKET: GETTERS_PREFIX + 'PUSH_SOCKET',
    AVAILABLE: GETTERS_PREFIX + 'AVAILABLE',
    OPENED_CHAT_BETEILIGTER_ID: GETTERS_PREFIX + 'OPENED_CHAT_BETEILIGTER_ID',
    CHAT_BETEILIGTER_ID: GETTERS_PREFIX + 'CHAT_BETEILIGTER_ID',
    RECEIVED_VIDEO: GETTERS_PREFIX + 'RECEIVED_VIDEO',
    RECEIVED_AUDIO: GETTERS_PREFIX + 'RECEIVED_AUDIO',
    PARTNER_MUTED: GETTERS_PREFIX + 'PARTNER_MUTED',
    MUTED: GETTERS_PREFIX + 'MUTED',
    RECORDING: GETTERS_PREFIX + 'RECORDING',
    BETEILIGTER_INFOS: GETTERS_PREFIX + 'BETEILIGTER_INFOS',
    IN_CALL_WITH: GETTERS_PREFIX + 'IN_CALL_WITH',
    WE_ARE_CALLING_ID: GETTERS_PREFIX + 'WE_ARE_CALLING_ID',
    IS_CALLING_ACTIVE: GETTERS_PREFIX + 'IS_CALLING_ACTIVE',
    NEXT_INCOMING_CALL: GETTERS_PREFIX + 'NEXT_INCOMING_CALL',
    NEXT_INCOMING_CALL_UUID: GETTERS_PREFIX + 'NEXT_INCOMING_CALL_UUID',
    CALL_START_DATE: GETTERS_PREFIX + 'CALL_START_DATE',
    SENT_VIDEO: GETTERS_PREFIX + 'SENT_VIDEO',
    SENT_AUDIO: GETTERS_PREFIX + 'SENT_AUDIO',
    CALL_ID: GETTERS_PREFIX + 'CALL_ID',
    CHAT_INFO: GETTERS_PREFIX + 'CHAT_INFO',
    SAVED_PUSH_PAYLOAD: GETTERS_PREFIX + 'SAVED_PUSH_PAYLOAD',
    CALL_INFO_AFTER_CLOSING: GETTERS_PREFIX + 'CALL_INFO_AFTER_CLOSING',
    CALL_SYMBOL: GETTERS_PREFIX + 'CALL_SYMBOL',
    CAMERA_OPTIONS: GETTERS_PREFIX + 'CAMERA_OPTIONS',
    MICROPHONE_OPTIONS: GETTERS_PREFIX + 'MICROPHONE_OPTIONS',
    SPEAKER_OPTIONS: GETTERS_PREFIX + 'SPEAKER_OPTIONS',
    AUDIO_CONTEXT: GETTERS_PREFIX + 'AUDIO_CONTEXT',
    IS_DUMMY_STREAM: GETTERS_PREFIX + 'IS_DUMMY_STREAM',
  }
}