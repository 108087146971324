<template>
  <div>
    <GesellschaftTitleNavigation/>

    <GhostLoading v-if="loading" type="table" useBoxContainer :title="TABLE_TITLE" />

    <template v-else>
      <div class="box__container">
        <div class="box__title">Einzahlung für Kunden</div>
        <InputField
          label="Name Adresse"
          isComponentHalfSize
          v-model="fondsauftrag.einzahlungEmpfaenger"
        />
        <InputIbanBic
          label="IBAN"
          type="IBAN"
          isComponentHalfSize
          v-model="fondsauftrag.einzahlungIban"
        />
        <InputIbanBic
          label="BIC"
          type="BIC"
          isComponentHalfSize
          v-model="fondsauftrag.einzahlungBic" 
        />
        <InputField
          label="Bank"
          isComponentHalfSize
          v-model="fondsauftrag.einzahlungBank"
        />
        <InputField
          label="Bemerkung"
          isComponentHalfSize
          v-model="fondsauftrag.einzahlungBemerkung"
        />
        <InputField
          label="Fax"
          isComponentHalfSize
          v-model="fondsauftrag.einzahlungFax"
        />
      </div>

      <div class="box__container">
        <div class="box__title">Einreichungsstelle</div>
        <InputField
          label="Name"
          isComponentHalfSize
          v-model="fondsauftrag.einreichName"
        />
        <InputField
          label="Strasse"
          isComponentHalfSize
          v-model="fondsauftrag.einreichStrasse"
        />
        <InputZIP
          label="PLZ"
          isComponentHalfSize
          v-model="fondsauftrag.einreichPlz"
          @city="fondsauftrag.einreichOrt = $event"
        />
        <InputField
          label="Ort"
          isComponentHalfSize
          v-model="fondsauftrag.einreichOrt"
        />
        <InputField
          label="Fax"
          isComponentHalfSize
          v-model="fondsauftrag.einreichFax"
        />
      </div>

      <div class="box__container">
        <InputToggleSwitch
          label="Formular Kauf möglich"
          v-model="fondsauftrag.formularKauf"
        />
        <InputToggleSwitch
          label="Formular Verkauf möglich"
          v-model="fondsauftrag.formularVerkauf"
        />
        <InputToggleSwitch
          label="Formular Fondsumschichtung möglich"
          v-model="fondsauftrag.formularUmschichtung"
        />
        <InputToggleSwitch
          label="Formular Sparplan möglich"
          v-model="fondsauftrag.formularSparplan"
        />
        <InputToggleSwitch
          label="Formular Entnahmeplan möglich"
          v-model="fondsauftrag.formularEntnahmeplan"
        />
        <InputToggleSwitch
          label="Fondskürzel im Auftrag verwenden"
          v-model="fondsauftrag.kuerzelImAuftrag"
        />
        <InputToggleSwitch
          label="Lastschrifteinzug von Einmalanlagen möglich"
          v-model="fondsauftrag.lastschrifteinzug"
        />
        <InputToggleSwitch
          label="Kundenfremdnummern in Depotauswahl anzeigen"
          v-model="fondsauftrag.kundenGesnrInDepotauswahl"
        />
        <InputTextArea
          label="Text bei Sparplan"
          isComponentHalfSize
          v-model="fondsauftrag.textSparplan"
        />
      </div>

      <div class="box__container">
        <div class="box__title">Hinweis zu SEPA in Orderformularen</div>
        <InputRadioBoxGroup 
          v-model="fondsauftrag.orderBemerkungSepaAktiv" 
          :values="orderBemerkungSepaAktivValues"
          @input="getDefaultOrderBemerkungSepa"
        />
        <InputTextArea
          label="Text bei Sparplan"
          isComponentHalfSize
          v-model="fondsauftrag.orderBemerkungSepa"
          :disabled="fondsauftrag.orderBemerkungSepaAktiv !== '1'"
        />
        <div v-if="fondsauftrag.orderBemerkungSepaAktiv === '1'">Bitte beachten Sie, dass die Gläubiger-ID im Text per Hand ergänzt werden muss.</div>
      </div>
    </template>
  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import InputField from '@/components/core/forms/InputField.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputIbanBic from '@/components/core/forms/InputIBAN_BIC.vue';
import InputZIP from '@/components/core/forms/InputZIP.vue'

const TABLE_TITLE = 'Fondsauftrag';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    GhostLoading,
    InputField,
    InputToggleSwitch,
    InputTextArea,
    InputRadioBoxGroup,
    InputIbanBic,
    InputZIP,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      fondsauftrag: {},
      originalFondsauftrag: null,
      orderBemerkungSepaAktivValues: [
        {
          value: '0',
          label: 'Lastschrift deaktivieren'
        },
        {
          value: '1',
          label: 'Text inkl. Gläubiger-Id:'
        },
      ],
    }
  },
  watch: {
    gesellschaft: {
      handler(newVal) {
        this.fondsauftrag = newVal.fondsauftrag || {}

        if (!this.originalFondsauftrag && Object.keys(this.fondsauftrag).length) {
          // Create a deep copy and compare it when trying to save. If no changes were made we don't need to save
          this.originalFondsauftrag = JSON.parse(JSON.stringify(this.fondsauftrag))
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
  },
  mounted() {
      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_FONDSAUFTRAG, { gesellschaftId: this.$route.params.gesellId })
      .finally(() => this.loading = false);
  },
  methods: {
    saveFondsauftrag() {
      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/fondsauftrag?${params}`, this.fondsauftrag, config)
        .then(response => {
          this.fondsauftrag = response.data
          this.originalFondsauftrag = JSON.parse(JSON.stringify(this.fondsauftrag))
        })
    },
    getDefaultOrderBemerkungSepa(value) {
      if (value !== '1') {
        return
      }

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.get(`${process.env.VUE_APP_API}/gesellschaftenService/defaultOrderBemerkungSepa?${params}`, config)
        .then(response => {
          this.fondsauftrag.orderBemerkungSepa = response.data
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveFondsauftrag();
    next();
  }
}
</script>