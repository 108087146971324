var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showCloseExtraWebInstance
    ? _c(
        "BaseButton",
        {
          attrs: { isClear: "" },
          on: { click: _vm.callCloseExtraWebInstance }
        },
        [_c("PhXCircle", { attrs: { size: _vm.size } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }