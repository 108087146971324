var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasStornoRoles
    ? _c(
        "div",
        { staticClass: "box__container-wrap" },
        [
          _vm.showTitle
            ? _c("div", { staticClass: "box__title" }, [
                _vm._v("Wichtige Warnungen, Stornos, Informationen ")
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "box__container-rows" },
            [
              _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: _vm.MAX_OVERVIEW_COUNT,
                      hidePagination: "",
                      mobileConfig: _vm.mobileConfig
                    },
                    on: {
                      "click-kundenLinkName": _vm.openCustomerNewTab,
                      "click-beschreibungShort": _vm.openLink,
                      "action-dokument": _vm.openDocument,
                      "action-chat": function($event) {
                        return _vm.createChat($event.chatData)
                      },
                      "action-emailBearbeitender": _vm.createEmail,
                      "action-emailKunde": function($event) {
                        return _vm.createEmail($event, $event.kundennr)
                      },
                      "action-vertrag": _vm.openLink,
                      "action-vermoegen": _vm.openVermoegenNewTab,
                      "action-confirm": _vm.confirmSelection,
                      "action-freigabe": function($event) {
                        return _vm.dokumentFreigeben($event, true)
                      },
                      "action-tan": function($event) {
                        return _vm.comdirectTan($event, true)
                      },
                      "action-mailsettings": function($event) {
                        return _vm.openMailSettings($event)
                      },
                      "action-sign": function($event) {
                        return _vm.signPdf($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "document",
                          fn: function(row) {
                            return [
                              row.docType
                                ? _c(
                                    "DownloadLink",
                                    {
                                      attrs: {
                                        downloadServicePath: _vm.downloadServicePath(
                                          row
                                        ),
                                        filename: row.fileName,
                                        queryParams: {
                                          docId: row.dokId,
                                          fileId: row.fileId,
                                          stornoId: row.stornoId
                                        }
                                      }
                                    },
                                    [
                                      _c(_vm.getDocTypeIcon(row.docType), {
                                        tag: "component",
                                        attrs: { size: 22 }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2796005099
                    )
                  })
                : _vm.loading
                ? _c("GhostLoading", {
                    attrs: {
                      type: "table",
                      config: { table: { rows: _vm.MAX_OVERVIEW_COUNT } }
                    }
                  })
                : _c("NoData", { attrs: { noIcon: "" } })
            ],
            1
          ),
          !_vm.loading && !_vm.$isSmallScreen
            ? _c(
                "div",
                { staticClass: "bottom-row text-right mt-3" },
                [
                  _c("router-link", { attrs: { to: { name: "stornos" } } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.rows.length ? "weitere..." : "Übersicht anzeigen"
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c("BaseModal", {
            ref: "hatFehler",
            attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
            on: {
              onConfirmButton: function($event) {
                _vm.errorStr = null
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modalTitle",
                  fn: function() {
                    return [
                      _c("ph-warning", {
                        staticClass: "mr-2",
                        staticStyle: { color: "orangered" },
                        attrs: { size: 16 }
                      }),
                      _vm._v(" " + _vm._s(_vm.errorStr) + " ")
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2081146381
            )
          }),
          _c(
            "BaseModal",
            {
              ref: "info",
              attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return [_vm._v("Informationen")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3811174129
              )
            },
            [
              _c("div", {
                staticClass: "col mb-2",
                domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.infoText)) }
              })
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }