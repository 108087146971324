<template>
  <TeleportToBody>
    <ResizableModal 
      ref="resizablePopup" 
      :modalTitle="modalTitle" 
      :initialWidth="initialWidth"
      :initialHeight="initialHeight"
      :sizeIsPercentage="sizeIsPercentage"
      :startPosition="startPosition"
      :id="id"
      :fullClientHeight="fullClientHeight"
      :saveLastPosition="shouldSaveLastPosition"
      @onRestore="onRestore" 
      @onMinimize="onMinimize" 
      @onMaximize="onMaximize" 
      @onResize="resizeObjectContainer"
      @onClose="cleanUpMemory">

        <!-- Additional header buttons -->
        <template #header-buttons>
          <!-- Navigation controls for PDF pages -->
          <div v-if="usePDFJs && !isMinimized" class="header_buttons">
            <button type="button" class="base-modal__header--button clickable" @click="prevPage()" :disabled="pageNum <= 1" v-if="pageCount > 1">
              <PhCaretLeft :size="24" />
            </button>
            <span class="current__pdf__page" v-if="pageCount > 1">{{ pageNum }}</span>
            <button type="button" class="base-modal__header--button clickable" @click="nextPage()" :disabled="pageNum >= pageCount" v-if="pageCount > 1">
              <PhCaretRight :size="24" />
            </button>
            <span class="spacer"></span>
            <button type="button" class="base-modal__header--button clickable" @click="zoomIn()">
              <PhMagnifyingGlassPlus :size="24" />
            </button>
            <button type="button" class="base-modal__header--button clickable"  @click="zoomOut()">
              <PhMagnifyingGlassMinus :size="24" />
            </button>
            <span class="spacer"></span>
          </div>

          

        </template>

        <!-- Main content for the popup -->



        <GhostLoading v-if="loading || !objectUrl">
          <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />

        </GhostLoading>

        <div class="object-container" ref="objectContainer">
          <canvas v-if="usePDFJs" v-show="!loading" ref="pdfCanvas"></canvas>

          <iframe v-else v-show="!loading" :src="objectUrl" width="100%" height="100%" @load="loading=false">
            Der Browser unterstützt das Format nicht. Bitte laden Sie die Datei herunter: <a :href="objectUrl">Herunterladen</a>.
          </iframe>


        </div>
    </ResizableModal>
  </TeleportToBody> 
</template>

<script>
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
GlobalWorkerOptions.workerSrc = '/web-workers/pdf.worker.min.js'; // Adjust the path as needed


import ResizableModal from '@/components/core/ResizableModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import TeleportToBody from '@/components/core/teleport/TeleportToBody.vue';
import { PhCaretLeft , PhCaretRight , PhMagnifyingGlassPlus, PhMagnifyingGlassMinus } from 'phosphor-vue';
import LOG_TYPES from '@/store/log/types';
import Vue from 'vue';


export default {
  name: 'PreviewPDF',
  components: {
    ResizableModal,
    GhostLoading,
    Block,
    TeleportToBody,
    PhMagnifyingGlassPlus,
    PhMagnifyingGlassMinus,
    PhCaretLeft, 
    PhCaretRight,
  },  
  props: {
    modalTitle: {
      default: 'Objekt-Vorschau'
    },
    initialHeight: {
      type: Number,
      default: 400, 
    },
    initialWidth: {
      type: Number,
      default: 600, 
    }, 
    objectUrl: {
      type: String,
    },
    sizeIsPercentage: {
      type: Boolean,
      default: false, 
    },
    startPosition: {
      type: String,
      default: 'TOP_LEFT',
      validator(value) {
        return ['TOP_RIGHT', 'TOP_LEFT', 'BOTTOM_RIGHT', 'BOTTOM_LEFT'].includes(value);
      }
    },
    fullClientHeight: {
      type: Boolean,
      default: false, 
    },
    id: {
      type: String,
      required: true,
      validator: (value) => {
        const notEmpty = !!value && !!value.trim();
        return notEmpty;
      },
    },
    saveLastPosition: {
      type: Boolean,
      default: false, 
    },                         
  },  
  data() {
    return {
      loading: true,
      pdfDoc: null,
      pageNum: 1, // Current page
      pageCount: 0, // Total number of pages
      scale: 1.0,
      isMinimized: false,
    };
  },
  computed: {
    shouldSaveLastPosition() {
      return !this.isMinimized && this.saveLastPosition;
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },
    looksLikePDF() {
      if (!this.objectUrl) { return; }
      // Extract the URL before any query parameters
      const baseUrl = this.objectUrl.split('?')[0];
      // Check if the base URL ends with '.pdf', ignoring any case sensitivity
      return baseUrl.match(/\.pdf$/i) !== null;
    },
    usePDFJs() {
      return /* this.isIOS && */ this.looksLikePDF;
    },
  },
  methods: {
    async loadPDF() {
      try {
        this.pdfDoc = await getDocument(this.objectUrl).promise;
        Vue.set(this, "pageCount", this.pdfDoc.numPages);
        this.renderPage(this.pageNum);
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "Error loading the PDF document:", error});
      }
    },
    async renderPage(num) {
      try {
        this.loading = true;

        const page = await this.pdfDoc.getPage(num);
        const viewport = page.getViewport({ scale: this.scale });
        const canvas = this.$refs.pdfCanvas;

        // Set exact width and height based on the viewport
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height); // Clear before rendering

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext).promise;

        this.loading = false;
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "Error rendering the page:", error});
      }
    },
    onFileLoaded() {
      this.loadPDF();
    },
    resizeObjectContainer() {
      let container = this.$refs.objectContainer;
      if (container) {
        container.style.minHeight = `${this.initialHeight - 80}px`;
      }
    },
    onMinimize() {
      this.resizeObjectContainer();
      this.isMinimized = true;
    },
    onRestore() {
      this.resizeObjectContainer();
      this.isMinimized = false;
    },
    onMaximize() {
      this.resizeObjectContainer();
      this.isMinimized = false;
    },
    async showPopup() {
      Vue.set(this, "loading", true);
      Vue.set(this, "pageNum", 1);
      Vue.set(this, "scale", 1.0);
      Vue.set(this, "pageCount", 0);
      await this.$nextTick(); // Wait for DOM update
      this.$refs.resizablePopup.showPopup();

      if (this.looksLikePDF) {
        await this.loadPDF();
      } 
    },
    nextPage() {
      if (this.pageNum >= this.pageCount) return;
      this.pageNum++;
      this.renderPage(this.pageNum);
    },
    prevPage() {
      if (this.pageNum <= 1) return;
      this.pageNum--;
      this.renderPage(this.pageNum);
    },
    async zoomIn() {
      this.scale += 0.2;
      await this.renderPage(this.pageNum);
    },
    async zoomOut() {
      this.scale = Math.max(1.0, this.scale - 0.2); // Prevent zoom out below 50%
      await this.renderPage(this.pageNum);
    },
    cleanUpMemory() {
      if (this.pdfDoc) {
        this.pdfDoc.destroy();  // Terminate the worker associated with the PDF document
      }
    },    
  },
  beforeDestroy() {
    this.cleanUpMemory();
  },  
};
</script>

<style scoped>

.object-container {
  width: 100%; 
  height: calc(100% - 62px); 
  overflow: auto; 
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.object-container canvas {
  display: block;
  margin: 0 auto;
  width: auto; 
  height: auto;
  max-width: none; 
  max-height: none; 
}


.object-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}


.pdf-controls  {
  user-select: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  background-color: var(--big-table-header);
  color: var(--big-table-header-text);
}

.current__pdf__page {
  display: inline-block;
  user-select: none;
  border-radius: 4px;
  padding: 0px;
  margin: 0px;
  min-width: 24px;
  text-align: center;
  border: 1px solid var(--big-table-highlighted-column-border);
  color: var(--big-table-header-text);
}


.spacer {
  display: inline-block;
  width: 16px;
  min-width: 16px;
}

</style>
