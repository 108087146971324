import HONORARE_TYPES from './types';

export default {
  [HONORARE_TYPES.GETTERS.FEES](state) {
    return state.fees;
  },
  [HONORARE_TYPES.GETTERS.SELECTED_ROW](state) {
    return state.selectedRow;
  },
  [HONORARE_TYPES.GETTERS.EDITOR_LAYOUT_DATA](state) {
    return state.editorLayoutData;
  },
  [HONORARE_TYPES.GETTERS.EDIT_ROW](state) {
    return state.editRow;
  }
}