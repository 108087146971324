var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputField", {
    attrs: {
      value: _vm.internalValue,
      label: _vm.label,
      labelClass: _vm.labelClass,
      inputClass: _vm.inputClass,
      validateUntouched: _vm.validateUntouched,
      type: _vm.type,
      placeholder: _vm.placeholder,
      suppressValidationMessage: _vm.suppressValidationMessage,
      id: _vm.id,
      isEmbedded: _vm.isEmbedded,
      disabled: _vm.disabled,
      small: _vm.small,
      precision: _vm.precision,
      showConfirmationIcon: _vm.showConfirmationIcon,
      isComponentHalfSize: _vm.isComponentHalfSize,
      foreign_currency: _vm.foreign_currency,
      isNotReactive: _vm.isNotReactive,
      inputmode: _vm.inputmode,
      autocomplete: _vm.autocomplete,
      autofocus: _vm.autofocus,
      isInteger: _vm.isInteger,
      renderDanger: _vm.renderDanger,
      allowPlainPassword: _vm.allowPlainPassword,
      allowPasswordRules: _vm.allowPasswordRules,
      maxLength: _vm.maxLength
    },
    on: {
      input: function($event) {
        return _vm.input($event)
      },
      change: function($event) {
        return _vm.$emit("change")
      },
      reset: function($event) {
        return _vm.$emit("reset")
      },
      blur: function($event) {
        return _vm.$emit("blur")
      },
      onBlur: function($event) {
        return _vm.$emit("onBlur")
      },
      focus: function($event) {
        return _vm.$emit("focus")
      },
      searchAction: function($event) {
        return _vm.$emit("searchAction")
      },
      contentSelected: function($event) {
        return _vm.$emit("contentSelected")
      },
      ref: function($event) {
        return _vm.$emit("ref")
      },
      inputRawValue: function($event) {
        return _vm.$emit("inputRawValue")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }