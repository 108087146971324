var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DepotpositionsNavigation", {
        attrs: {
          title: _vm.depotTitle,
          subtitle:
            _vm.hasDepotpositions && _vm.depotpositions[_vm.depotid].guvDate
              ? _vm.depotpositions[_vm.depotid].guvDate
              : "",
          headerActions: _vm.headerActions
        }
      }),
      _c("HinweiseUndFehler", { attrs: { hints: _vm.hints } }),
      _vm.hasDepotpositions
        ? _c(
            "div",
            [
              !_vm.$isSmallScreen
                ? _c(
                    "div",
                    { staticClass: "depotpositions-categories__container" },
                    _vm._l(
                      _vm.getDataFromLabels(
                        _vm.depotpositions[_vm.depotid].resultSums
                      ),
                      function(sum, index) {
                        return _c("div", { key: "sum" + index }, [
                          sum && sum[1] !== ""
                            ? _c(
                                "div",
                                {
                                  staticClass: "box__container",
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      "box__container" + "sum" + index
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-small text-center mp-0"
                                    },
                                    [_vm._v(_vm._s(sum[0]))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center mp-0",
                                      class: _vm.getBadgesClassCategory(sum)
                                    },
                                    [
                                      sum[2] === "LABEL_DEPOT_WERTENTWICKLUNG"
                                        ? _c(
                                            "span",
                                            [
                                              sum[3]
                                                ? [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.convertStringToNumber(
                                                          sum[1]
                                                        )
                                                      ) + " %"
                                                    )
                                                  ]
                                                : [
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value: _vm.convertStringToNumber(
                                                          sum[1]
                                                        )
                                                      }
                                                    })
                                                  ]
                                            ],
                                            2
                                          )
                                        : _vm.isNumber(sum[1])
                                        ? _c(
                                            "span",
                                            [
                                              _c("CurrencyLabel", {
                                                attrs: {
                                                  value: _vm.convertStringToNumber(
                                                    sum[1]
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c("span", [
                                            _vm._v(" " + _vm._s(sum[1]) + " ")
                                          ])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      }
                    ),
                    0
                  )
                : _c(
                    "div",
                    { staticClass: "box__container" },
                    [
                      _vm._l(
                        _vm.getDataFromLabels(
                          _vm.depotpositions[_vm.depotid].resultSums
                        ),
                        function(sum, index) {
                          return [
                            _c("MobileInfoRow", {
                              key: "sum" + index,
                              attrs: { label: sum[0] },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "value",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            class: _vm.getBadgesClassCategory(
                                              sum
                                            )
                                          },
                                          [
                                            sum[2] ===
                                            "LABEL_DEPOT_WERTENTWICKLUNG"
                                              ? _c(
                                                  "span",
                                                  [
                                                    sum[3]
                                                      ? [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.convertStringToNumber(
                                                                sum[1]
                                                              )
                                                            ) + " %"
                                                          )
                                                        ]
                                                      : [
                                                          _c("CurrencyLabel", {
                                                            attrs: {
                                                              value: _vm.convertStringToNumber(
                                                                sum[1]
                                                              )
                                                            }
                                                          })
                                                        ]
                                                  ],
                                                  2
                                                )
                                              : _vm.isNumber(sum[1])
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value: _vm.convertStringToNumber(
                                                          sum[1]
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c("span", [
                                                  _vm._v(_vm._s(sum[1]))
                                                ])
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ]
                        }
                      )
                    ],
                    2
                  ),
              _vm.depotpositionsCategories &&
              _vm.depotpositionsCategories.length
                ? _c(
                    "div",
                    _vm._l(_vm.treeGroup, function(group, idx) {
                      return _c(
                        "div",
                        { key: "group" + idx, staticClass: "box__container" },
                        [
                          _vm.showGroupTitle && group.title
                            ? _c("div", { staticClass: "box__title" }, [
                                _vm._v(_vm._s(group.title))
                              ])
                            : _vm._e(),
                          _c("Table", {
                            attrs: {
                              tableId: "b6d61b0f-3dd5-4940-b387-fe4390ba26da",
                              headers: _vm.headers,
                              rows:
                                _vm.depotpositionsCategories[group.index].rows,
                              rowId: "rowId",
                              hidePagination: "",
                              title: group.title4Tbl,
                              showColumnsConfigEvenNoTitle: "",
                              enableToggleHorizontalScrollingConfig:
                                _vm.mustEnableHorizontalScrolling,
                              mobileConfig: { disableClickRow: false }
                            },
                            on: {
                              "action-OEFFNEN": _vm.navigateToFondsinfo,
                              "action-ALT_KAUF": function($event) {
                                return _vm.addDepotToWertpapierorder(
                                  $event,
                                  "ALT_KAUF"
                                )
                              },
                              "action-ALT_VERKAUF": function($event) {
                                return _vm.addDepotToWertpapierorder(
                                  $event,
                                  "ALT_VERKAUF"
                                )
                              },
                              "action-ALT_TAUSCH": function($event) {
                                return _vm.addDepotToWertpapierorder(
                                  $event,
                                  "ALT_TAUSCH"
                                )
                              },
                              "action-ALT_SPARPLAN": function($event) {
                                return _vm.addDepotToWertpapierorder(
                                  $event,
                                  "ALT_SPARPLAN"
                                )
                              },
                              "action-ALT_ENTNAHMEPLAN": function($event) {
                                return _vm.addDepotToWertpapierorder(
                                  $event,
                                  "ALT_ENTNAHMEPLAN"
                                )
                              },
                              "action-LIMITS": _vm.actionLimit,
                              "action-COURTAGE_BGS": _vm.handleCourtagehistorie,
                              "action-TRANSAKTIONEN":
                                _vm.navigateToTransactions,
                              "action-FONDSINFO": _vm.actionFondsinfo,
                              "action-DEPOT_DETAILS": _vm.getDepotDetails,
                              "action-NEW_APPOIMENT": _vm.handleAddActivity,
                              "action-FACTSHEET": function($event) {
                                return _vm.openFactsheetFile(
                                  $event,
                                  "FACTSHEET"
                                )
                              },
                              "action-KAG": function($event) {
                                return _vm.openFactsheetFile($event, "MR")
                              },
                              "action-PRIIP-BIB": function($event) {
                                return _vm.openFactsheetFile($event, "KIID")
                              },
                              "click-depotnummer": _vm.openVertragdOverview,
                              "click-stand_anteile": function($event) {
                                return _vm.openVertragDetails(
                                  $event.depotnummer
                                )
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.zeigeDepotnummerGruppierung &&
                                group.titleMetadata.title
                                  ? {
                                      key: "customTableTitle",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                group.titleMetadata.title
                                              ) +
                                              " | "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: { target: "_blank" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openVertragdOverview(
                                                    group.titleMetadata.key
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(group.titleMetadata.key)
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  : null,
                                {
                                  key: "historyChart",
                                  fn: function(row) {
                                    return [
                                      _vm.investmentBestand &&
                                      _vm.investmentBestand.chart
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "history-chart depotpositions-container"
                                            },
                                            [
                                              _c("LineChart", {
                                                staticStyle: { height: "35px" },
                                                attrs: {
                                                  xAxisType: "category",
                                                  chartData: _vm.historyChartData(
                                                    row
                                                  ),
                                                  showDataZoom: false,
                                                  showYaxis: false,
                                                  showXaxis: false,
                                                  isTooltip: false,
                                                  isSmallChart: "",
                                                  gridHeight: 35
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                },
                                {
                                  key: "wertpapiername",
                                  fn: function(row) {
                                    return [
                                      !row.mobileTableContext
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "depotpositions--wertpapiername-column"
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.navigateToFondsinfo(
                                                        row
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(row.wertpapiername)
                                                  ),
                                                  row.fondsampel
                                                    ? _c("PhCircle", {
                                                        staticClass:
                                                          "ml-2 mb-1",
                                                        attrs: {
                                                          size: 16,
                                                          color: row.fondsampel,
                                                          weight: "fill"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _vm._v(
                                                _vm._s(row.wertpapiername)
                                              ),
                                              row.fondsampel
                                                ? _c("PhCircle", {
                                                    staticClass: "ml-2 mb-1",
                                                    attrs: {
                                                      size: 16,
                                                      color: row.fondsampel,
                                                      weight: "fill"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                    ]
                                  }
                                },
                                {
                                  key: "info",
                                  fn: function(row) {
                                    return [
                                      row.info
                                        ? _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            _vm._l(row.info, function(
                                              line,
                                              index
                                            ) {
                                              return _c(
                                                "div",
                                                { key: index },
                                                [
                                                  line.isLink &&
                                                  !row.mobileTableContext
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-color",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openInfo(
                                                                line,
                                                                row
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          line.tooltip
                                                            ? _c("Pill", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "fc-tooltip",
                                                                    rawName:
                                                                      "v-fc-tooltip",
                                                                    value: {
                                                                      content:
                                                                        line.tooltip,
                                                                      className:
                                                                        "info-tooltip"
                                                                    },
                                                                    expression:
                                                                      "{content: line.tooltip, className: 'info-tooltip'}"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  label:
                                                                    line.label,
                                                                  type:
                                                                    line.type
                                                                }
                                                              })
                                                            : _c("Pill", {
                                                                attrs: {
                                                                  label:
                                                                    line.label,
                                                                  type:
                                                                    line.type
                                                                }
                                                              })
                                                        ],
                                                        1
                                                      )
                                                    : _c("Pill", {
                                                        attrs: {
                                                          label: line.label,
                                                          type: line.type
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            }),
                                            0
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("NoData", { staticClass: "box__container" }),
              _vm.isVersicherungVermoegen
                ? _c("BalanceSheetInsuranceTable", {
                    attrs: {
                      tableConfigId: "eecf48ac-bf47-452d-81f0-16611bf14b5f",
                      title: "Rückkaufswerte",
                      showOnlyRueckkaufswert: ""
                    },
                    on: {
                      totalAmount: function($event) {
                        return _vm.setRueckkaufswertTotalAmount($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm.loadingDepotpositions
        ? _c(
            "div",
            [
              _c(
                "GhostLoading",
                {
                  staticClass: "hide-on-small-screen",
                  attrs: { repeat: 5, useBoxContainer: "", inline: "" }
                },
                [_c("Block"), _c("Block")],
                1
              ),
              _c(
                "GhostLoading",
                {
                  staticClass: "hide-on-large-screen",
                  attrs: { repeat: 2, useBoxContainer: "", inline: "" }
                },
                [_c("Block"), _c("Block")],
                1
              ),
              _c(
                "GhostLoading",
                { attrs: { repeat: 5, useBoxContainer: "" } },
                [
                  _c("Block", { attrs: { type: "title" } }),
                  _c("Block", { attrs: { type: "table-row" } })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "notImplemented",
          attrs: {
            modalTitle: "Nicht implementiert",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_vm._v(" Formular noch nicht implementiert ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "modalDepotDetails",
          attrs: {
            modalTitle: _vm.modalArg.title,
            showConfirmButton: _vm.modalArg.showConfirmButton,
            labelButtonConfirm: _vm.modalArg.labelButtonConfirm,
            labelButtonCancel: _vm.modalArg.labelButtonCancel
          },
          on: { onConfirmButton: _vm.modalArg.onConfirm }
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.modalArg.body) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }