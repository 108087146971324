var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "BiPRO Kategorien Ausnahmen" }
      }),
      _vm.gesellschaftOptions
        ? _c("BaseFilter", {
            attrs: {
              title: "BiPRO Ausnahmen Übersicht",
              filterId: "BiPRO Kategorien Ausnahmen",
              configFilter: _vm.filterConfig,
              metadata: _vm.metadata,
              defaultOptions: _vm.defaultOptions,
              showSaveButton: ""
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("BigTable", {
                staticStyle: { clear: "both" },
                attrs: {
                  selectable: "",
                  title: "BiPRO Ausnahmen Übersicht",
                  tableId: "08977d53-c495-4765-bd86-77e5ca8c5d6f",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "gevoArt",
                  page: _vm.page,
                  sortingState: _vm.sortingState,
                  headerActions: _vm.headerActions,
                  initialPageSize: _vm.minNumRows
                },
                on: {
                  onClickHeader: _vm.onClickHeader,
                  selectedRow: _vm.onRowSelection,
                  selectedRows: _vm.onAllRowsSelection,
                  "headerAction-SAVE": _vm.save
                }
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }