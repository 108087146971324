<template>
  <div v-if="parameters && showMultibankingActionButtons">
    <div class="layout__negative-margin--8 d-flex align-items-center">
      <InputToggleSwitch 
        v-model="shareAccounts" 
        :label="parameters.shareDataSwitchText" 
        @input="toggleShareAccount"
        inLineLabel 
        suppressValidationMessage/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch'
import CORE_TYPES from '@/store/core/types'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import ROLES from '@/router/roles'

export default {
  components: {
    InputToggleSwitch,
  },
  watch: {
    userSettings() {
      this.shareAccounts = this.userSettings?.shareAccounts
    },
  },
  props: {
    showAccounts: {
      type: String,
    }
  },
  data() {
    return {
      shareAccounts: false,
    }
  },
  computed: {
    ...mapGetters({
      parameters: MULTIBANKING_TYPES.GETTERS.PARAMETERS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      brokerData: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      userSettings: MULTIBANKING_TYPES.GETTERS.USER_SETTINGS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isUserAllowedToGetMultibankingData () {
      return this.hasRoles(ROLES.MULTIBANKING_DATA_ALLOWED);
    },
    showMultibankingActionButtons() {
      return !this.isBrokerOrBypass && !this.hasRoles(ROLES.IS_BYPASS_SLASH) && this.isUserAllowedToGetMultibankingData;
    },
  },
  methods: {
    goToCategories() {
      this.$router.push({name: `kategorien`})
    },
    goToBalancePerPeriod() {
      this.$router.push({name: `balance-per-period`})
    },
    toggleShareAccount($event) {
      this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.SAVE_USER_SETTINGS, {shareAccounts: $event})
    },
  },
  mounted() {
    this.shareAccounts = this.userSettings?.shareAccounts
  }
}
</script>

<style>

</style>