<template>
    <div>
        <InputField label="Anmeldename" v-model="form.anwenderAnmeldename" isComponentHalfSize @change="updateNafiStore('anwenderAnmeldename', $event)" />
        <InputField label="Kennwort" v-model="form.anwenderKennwort" isComponentHalfSize validateUntouched @change="updateNafiStore('anwenderKennwort', $event)" />
        <div class="mb-2">
            <BaseButton @click="createPassword()">Kennwort generieren</BaseButton>
        </div>
        <InputField label="Login-Schlüssel" v-model="form.anwenderLoginKey" isComponentHalfSize @change="updateNafiStore('anwenderLoginKey', $event)" />
    </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import { required } from "@/mixins/validator/rules";
import BaseButton from '@/components/core/BaseButton.vue'
import validator from "@/mixins/validator";
import NAFI_TYPES from '@/store/nafi/types';
import mixin from '@/mixins/nafi/nafi-mixin.js';

export default {
    mixins: [validator, mixin],
    components: {
        InputField,
        BaseButton,
    },
    validators: {
        form: {
            anwenderKennwort: [required('Kennwort ist erforderlich!')],
        }
    },
    data() {
        return {
            form: {
                anwenderAnmeldename: '',
                anwenderKennwort: '',
                anwenderLoginKey: '',
            },
        }
    },
    mounted() {
        this.initForm();
    },
    methods: {
        createPassword() {
            this.$confirmModal({
                title: 'Neues Kennwort vergeben', 
                message: 'Soll das Kennwort für diesen Zugang wirklich neu erzeugt werden?', 
                labelButtonConfirm: 'Ja',
                labelButtonCancel: 'Nein',
            }).then(() => {
                this.$store.dispatch(NAFI_TYPES.ACTIONS.GENERATE_NAFI_PASSWORD);
            })
        },
    },

}
</script>

<style>

</style>