<template>
  <div>
    <ComboBox 
      :label="label"
      :values="values" 
      :value="value" 
      :sortComboboxValues="false"
      :disabled="disabled"
      @change="$emit('change', $event)"/>
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
  components: {
    ComboBox,
  },
  props: {
    value: {
    },
    label: {
      type: String,
      default: '',
    },
    values: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  }

}
</script>
