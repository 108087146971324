<template>
  <BaseModal
    ref="modal"
    modalTitle="Dokumente hinzufügen"
    :showDefaultButtons="false"
  >
    <template #default>
      <MultiFileSelect v-model="files" />

      <div class="mb-3">
        <div>Kunde: {{ customer.name }} ({{ customer.customerID }})</div>

        <div v-if="hasPersonen" class="mb-3">
          <InputToggleSwitch
            label="Andere Person"
            inLineLabel
            v-model="showPersonen"
            @input="clearPersonId()"
          />

          <ComboBox
            v-if="showPersonen"
            :values="personenValues"
            v-model="form.personId"
          />
        </div>
      </div>

      <ComboBox
        label="Dokumenten-Typ"
        :values="deckblattTypeValues"
        v-model="form.deckblattType"
        validateUntouched
        @change="clearForm()"
      />

      <div v-if="form.deckblattType" :key="form.deckblattType">
        <AllgemeineDokumente
          ref="internalForm"
          v-if="form.deckblattType && isAllgemeineDokumente"
          :customer="customer"
          :deckblattType="form.deckblattType"
          @updateValidation="updateValidation()"
          @dataChanged="updateForm($event)"
        />
        <Investmentfonds
          ref="internalForm"
          v-else-if="form.deckblattType === 'INVESTMENTFONDS_ORDER' || form.deckblattType === 'INVESTMENTFONDS_ANTRAG'"
          :customer="customer"
          :deckblattType="form.deckblattType"
          @updateValidation="updateValidation()"
          @dataChanged="updateForm($event)"
        />
        <GeschlosseneFonds
          ref="internalForm"
          v-else-if="form.deckblattType === 'GESCHLOSSENE_FONDS'"
          :customer="customer"
          :deckblattType="form.deckblattType"
          @updateValidation="updateValidation()"
          @dataChanged="updateForm($event)"
        />
        <Vermittlerwechsel
          ref="internalForm"
          v-else-if="form.deckblattType === 'VERMITTLERWECHSEL'"
          :customer="customer"
          :deckblattType="form.deckblattType"
          @updateValidation="updateValidation()"
          @dataChanged="updateForm($event)"
        />
      </div>
    </template>
    <template #footer>
      <BaseButton @click="close()" isSecondary>Abbrechen</BaseButton>
      <BaseButton @click="upload()" :disabled="!hasFiles || isFormInvalid || loading">
        <AnimatedSpinner v-if="loading" /> Speichern
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import FILEUPLOAD_TYPES from '@/store/fileUpload/types';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';

import BaseModal from '@/components/core/BaseModal.vue';
import MultiFileSelect from '@/components/fileUpload/MultiFileSelect.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

import Investmentfonds from './customerDocumentUpload/Investmentfonds.vue';
import GeschlosseneFonds from './customerDocumentUpload/GeschlosseneFonds.vue';
import Vermittlerwechsel from './customerDocumentUpload/Vermittlerwechsel.vue';
import AllgemeineDokumente from './customerDocumentUpload/AllgemeineDokumente.vue';

const UMSATZBEGLEITSCHEINE_TYPES = ['INVESTMENTFONDS_ORDER', 'INVESTMENTFONDS_ANTRAG', 'GESCHLOSSENE_FONDS', 'VERMITTLERWECHSEL'];

export default {
  mixins: [validator],
  data() {
    return {
      customer: {},
      files: [],
      showPersonen: false,
      form: {},
      loading: false,
      validationUpdated: null,
    };
  },
  computed: {
    ...mapGetters({
      deckblattCombosData: FILEUPLOAD_TYPES.GETTERS.DECKBLATT_COMBOS,
    }),
    hasFiles() {
      return !!this.files?.length;
    },
    deckblattCombos() {
      return this.deckblattCombosData(this.customer?.customerID);
    },
    personenValues() {
      return [...this.deckblattCombos?.personen || []];
    },
    hasPersonen() {
      return !!this.personenValues?.length;
    },
    deckblattTypeValues() {
      return [...this.deckblattCombos?.deckblattTypes || []];
    },
    isAllgemeineDokumente() {
      return UMSATZBEGLEITSCHEINE_TYPES.indexOf(this.form?.deckblattType) < 0;
    },
    isFormInvalid() {
      return this.validation?.updated && this.validation?.isInvalid('form') 
        || this.validationUpdated && this.$refs.internalForm?.validation?.isInvalid('form');
    },
  },
  watch: {
    'form.deckblattType'() {
      this.$nextTick(() => this.updateValidation());
    },
  },
  methods: {
    open(customer, files) {
      if(!customer?.customerID || !files?.length) {
        return;
      }

      this.showPersonen = false;
      this.loading = false;
      this.customer = { ...customer, };
      this.files = [...files];
      this.form = {
        deckblattType: null,
      };

      this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.GET_DECKBLATT_COMBOS, customer);

      this.$refs.modal?.open();
    },
    close() {
      this.$refs.modal?.close();
    },
    removeFile(file) {
      this.files.splice(this.files.indexOf(file), 1);
    },
    clearPersonId() {
      this.$set(this.form, 'personId', null);
    },
    clearForm() {
      this.$set(this, 'form', {
        personId: this.form.personId,
        deckblattType: this.form.deckblattType,
      });
    },
    updateForm(form) {
      this.$set(this, 'form', {
        personId: this.form.personId,
        deckblattType: this.form.deckblattType,
        ...form,
      });
    },
    updateValidation() {
      this.validationUpdated = new Date().getTime();
    },
    async upload() {
      if(!this.customer?.customerID || !this.files?.length || !this.form.deckblattType) {
        return ;
      }

      this.loading = true;
      try {
        await this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.UPLOAD_DECKBLATT, {
          customer: { ...this.customer, },
          files: [...this.files],
          payload: { 
            ...this.form || {},
          },
        });
        this.close();
      } finally {
        this.loading = false;
      }
    },
  },
  validators: {
    form: {
      deckblattType: [required()],
    },
  },
  components: {
    BaseModal,
    MultiFileSelect,
    ComboBox,
    InputToggleSwitch,
    BaseButton,
    AnimatedSpinner,
    Investmentfonds,
    GeschlosseneFonds,
    Vermittlerwechsel,
    AllgemeineDokumente,
  },
}
</script>

<style lang="scss" scoped>
.files__container {
  margin: 1px 0 16px;

  .pill__status {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
