
import LOG_TYPES from '@/store/log/types.js';
import MENU_TYPES from '@/store/menu/types.js';
import CORE_TYPES from '@/store/core/types.js';
import WEBRTC_TYPES from '@/store/webrtc/types.js';
import { AppNotificationKey, AppNotificationType } from '@/store/webrtc/utils/appNotificationCommon_types';
import { SignalingConnectionRole } from '@/store/webrtc/utils/signalingService_types.js';
import { createCallInfo, sendSignalingRegistrationMessage } from '@/store/webrtc/sockets/helpers.js';
import { setupSignalingSocket } from '@/store/webrtc/sockets/signaler';

const uuidPrefix = "@"

const HANDLER = {
  [AppNotificationType.CHAT_MESSAGE]: chatMessageHandler,
  [AppNotificationType.CHAT_VERSAND_STATUS]: chatVersandStatusHandler,
  [AppNotificationType.WEB_RTC_CALL]: webRtcCallHandler,
  [AppNotificationType.WEB_RTC_AVAILABILITY]: webRtcAvailabilityHandler,
  [AppNotificationType.LOGOUT]: logoutHandler,
  [AppNotificationType.SUGGESTED_ACTION_MESSAGE]: suggestedActionMessageHandler,
  [AppNotificationType.COUNTER_UPDATE]: counterUpdateHandler,
}


async function chatMessageHandler({ msg, dispatch, getters, state }) {
  console.log("chatMessageHandler");
  if (!getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
    console.log("chatMessageHandler not logged in");
    // we are gettting a chat push notification but are not logged in, save for after login:
    state.commit(WEBRTC_TYPES.MUTATIONS.SAVE_PUSH_PAYLOAD, msg);
  }
  dispatch(LOG_TYPES.ACTIONS.INFO, { message: "WebRTC: CHAT_MESSAGE", msg });
  dispatch(WEBRTC_TYPES.ACTIONS.NOTIFY_NEW_CHAT_MESSAGE, { chatId: msg[AppNotificationKey.NOTIFICATION_ITEM_ID], });
  state.chatInfos.forEach(chatInfo => {
    dispatch(WEBRTC_TYPES.ACTIONS.LOAD_CHAT_COMMUNICATION, chatInfo)
  })
}

async function chatVersandStatusHandler({ msg, dispatch }) {
  dispatch(LOG_TYPES.ACTIONS.INFO, { message: "WebRTC: CHAT_VERSAND_STATUS", msg });
}

/**
 * Received calls are started from here
 * 
 * @param {*} context 
 */
async function webRtcCallHandler({ msg, dispatch, getters, commit, state }) {
  await dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_AVAILABILITY, {
    calleeChatBeteiligterId: msg[AppNotificationKey.ABSENDER_TICKET_BETEILIGTER_ID],
    available: true,
  });

  try {
    const callInfo = createCallInfo(
      { getters, commit, dispatch, state },
      parseInt(msg[AppNotificationKey.WEB_RTC_CAll_ID]),
      SignalingConnectionRole.CALLEE_WEBRTC_CALL_INITIATOR,
      uuidPrefix + msg[AppNotificationKey.SIGNALING_UUID],
      msg[AppNotificationKey.ANRUFER_SIGNALING_UUID],
      msg[AppNotificationKey.ABSENDER_TICKET_BETEILIGTER_ID],
      msg[AppNotificationKey.SIGNALING_SERVER_URL],
      msg[AppNotificationKey.TURN_SERVER_URL],
      msg[AppNotificationKey.TURN_SERVER_USER],
      msg[AppNotificationKey.TURN_SERVER_PASSWORD]);

    // it must be called ASAP when receiving a call due to it must give a feedback whether the call is rejected
    setupSignalingSocket({ callInfo, commit, dispatch, state, getters });

    await dispatch(WEBRTC_TYPES.ACTIONS.CREATE_CALL_INFO, { callInfo });

    // Send the first registration message without sdpOffer when the call is received.
    // this is important, so that the server knows every tab that has received the incoming call
    // a second registration will be called when the call is established
    sendSignalingRegistrationMessage(callInfo, commit, dispatch, { withSdpOffer: false });

    dispatch(WEBRTC_TYPES.ACTIONS.LOAD_CHAT_INFO_FOR_WEBRTC, callInfo.calleeChatBeteiligterId);

  } catch (error) {
    dispatch(LOG_TYPES.ACTIONS.ERROR, { message: "failed to receive audio/video input", error });
  }
}

async function webRtcAvailabilityHandler({ msg, dispatch }) {
  // the availability of a potential call-partner changed
  dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_AVAILABILITY, {
    calleeChatBeteiligterId: msg[AppNotificationKey.WRA_CHAT_BETEILIGTER_ID],
    available: msg[AppNotificationKey.WRA_IS_AVAILABLE] == "1",
  })
}

async function logoutHandler({ msg, dispatch, getters }) {
  // the broker has logged out in the main tab, so this message is sent to all their open tabs
  if (getters[CORE_TYPES.GETTERS.ORIGINAL_TOKEN] === msg[AppNotificationKey.CREATOR_JWT_ID]) {
    dispatch(CORE_TYPES.ACTIONS.LOGOUT);
  }
}

async function suggestedActionMessageHandler({ msg, dispatch }) {
  dispatch(WEBRTC_TYPES.ACTIONS.HANDLE_ACTION_SUGGESTION, {
    actionId: msg[AppNotificationKey.SUGGESTED_ACTION_ID]
  })
}

async function counterUpdateHandler({ msg, dispatch }) {
  dispatch(MENU_TYPES.ACTIONS.LOAD_PARENTS_MENU_BADGE, {
    badgeName: msg[AppNotificationKey.COUNTER_UPDATE_NAME],
  })

  document.dispatchEvent(new CustomEvent('counterUpdate', {
    detail: {
      badgeName: msg[AppNotificationKey.COUNTER_UPDATE_NAME]
    }
  }))
}

async function defaultHandler({ msg, dispatch }) {
  dispatch(LOG_TYPES.ACTIONS.WARN, { message: "pushSocket received unexpected notification type", msg });
}

// ----------- export functions ------------

export async function handlePushSocket({ msg, dispatch, commit, getters, state }) {
  const nextHandler = HANDLER[parseInt(msg[AppNotificationKey.NOTIFICATION_TYPE])] || defaultHandler;

  await nextHandler({ msg, dispatch, commit, getters, state });
}