var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    this.dat
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "naija-flag" },
              [
                _c("InputToggleSwitch", {
                  attrs: {
                    label: "format",
                    value: _vm.formatText,
                    inLineLabel: "",
                    suppressValidationMessage: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.changeFormat($event)
                    }
                  }
                }),
                this.haveSql
                  ? _c("InputToggleSwitch", {
                      attrs: {
                        label: "full sql",
                        value: _vm.fullSql,
                        inLineLabel: "",
                        suppressValidationMessage: ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.changeFullSQL($event)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("Table", {
              attrs: {
                tableId: "d85dc90c-0816-11ed-861d-0242ac120002",
                title: _vm.title,
                headers: _vm.headers,
                rows: _vm.rows,
                hidePagination: ""
              },
              on: {
                "click-SID": _vm.clickSID,
                "click-SERIAL": _vm.clickSID,
                "click-STATUS": _vm.clickSID,
                "click-CLIENT_IDENTIFIER": _vm.clickSID,
                "click-TABLESPACE_NAME": _vm.clickTbs
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "INTERFACE_STATUS",
                    fn: function(row) {
                      return [
                        _c("div", [
                          _c("label", [
                            _c("progress", {
                              attrs: {
                                max: row.INTERFACE_STATUS.split(";")[1]
                              },
                              domProps: {
                                value: row.INTERFACE_STATUS.split(";")[0]
                              }
                            })
                          ]),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                row.INTERFACE_STATUS.replace(";", " from ") +
                                  " " +
                                  Math.round(
                                    100 *
                                      (row.INTERFACE_STATUS.split(";")[0] /
                                        row.INTERFACE_STATUS.split(";")[1]),
                                    5,
                                    2
                                  ) +
                                  "%"
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "USED_PROC",
                    fn: function(row) {
                      return [
                        _c("div", [
                          _c("label", [
                            _c("progress", {
                              attrs: { max: row.SIZE_MB },
                              domProps: { value: row.USED_MB }
                            })
                          ]),
                          _c("br"),
                          _vm._v(" " + _vm._s(row.USED_PROC + "%") + " ")
                        ])
                      ]
                    }
                  },
                  {
                    key: "SESSION_RECID",
                    fn: function(row) {
                      return [
                        _c("DownloadLink", {
                          attrs: {
                            target: "_blank",
                            rel: "noopener noreferer",
                            title: row.SESSION_RECID,
                            downloadServicePath: "/getRmanOutput",
                            queryParams: {
                              recId: row.SESSION_RECID,
                              stam: row.SESSION_STAMP
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                1965233444
              )
            }),
            _c("DbmSessionDialog", { ref: "DbmSessionDialog" })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }