var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Ich handle auf",
          validateUntouched: true,
          values: [
            { value: "EIGEN", label: "eigene Rechnung" },
            { value: "FREMD", label: "fremde Rechnung" }
          ],
          disabled: _vm.isDisabled
        },
        on: {
          input: function($event) {
            _vm.updateStore(_vm.getCategory(), "rechnungEigenFremd", $event)
          }
        },
        model: {
          value: _vm.rechnungEigenFremd,
          callback: function($$v) {
            _vm.rechnungEigenFremd = $$v
          },
          expression: "rechnungEigenFremd"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Besteht ein Wohnsitz im Ausland?",
          validateUntouched: true,
          values: [
            { value: "0", label: "Nein" },
            { value: "1", label: "Ja, um welches Land handelt es sich?" }
          ],
          disabled: _vm.isDisabled
        },
        on: {
          input: function($event) {
            return _vm.updateResidenceCountry($event)
          }
        },
        model: {
          value: _vm.hasResidenceCountry,
          callback: function($$v) {
            _vm.hasResidenceCountry = $$v
          },
          expression: "hasResidenceCountry"
        }
      }),
      _vm.hasResidenceCountry === "1"
        ? _c("ComboBox", {
            attrs: {
              values: _vm.countries,
              disabled: _vm.isDisabled,
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                _vm.updateStore(_vm.getCategory(), "residenceCountry", $event)
              }
            },
            model: {
              value: _vm.residenceCountry,
              callback: function($$v) {
                _vm.residenceCountry = $$v
              },
              expression: "residenceCountry"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }