var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-panel__widgets" }, [
    _c(
      "div",
      { staticClass: "box__container dashboard-panel-widget" },
      [
        _c("InputField", {
          attrs: { label: "Name", validateUntouched: "" },
          on: {
            change: function($event) {
              return _vm.doChanges("title", $event)
            }
          },
          model: {
            value: _vm.form.title,
            callback: function($$v) {
              _vm.$set(_vm.form, "title", $$v)
            },
            expression: "form.title"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Geburtstag",
            placeholder: "DD.MM.JJJJ",
            isValueAsString: ""
          },
          on: {
            change: function($event) {
              return _vm.doChanges("geburtsdatum", $event)
            }
          },
          model: {
            value: _vm.form.geburtsdatum,
            callback: function($$v) {
              _vm.$set(_vm.form, "geburtsdatum", $$v)
            },
            expression: "form.geburtsdatum"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Beginn Ruhestand (Alter)",
            type: "number",
            precision: 0
          },
          on: {
            change: function($event) {
              return _vm.doChanges("alterRente", $event)
            }
          },
          model: {
            value: _vm.form.alterRente,
            callback: function($$v) {
              _vm.$set(_vm.form, "alterRente", $$v)
            },
            expression: "form.alterRente"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Lebenserwartung in Jahren",
            type: "number",
            precision: 0
          },
          on: {
            change: function($event) {
              return _vm.doChanges("lebenserwartung", $event)
            }
          },
          model: {
            value: _vm.form.lebenserwartung,
            callback: function($$v) {
              _vm.$set(_vm.form, "lebenserwartung", $$v)
            },
            expression: "form.lebenserwartung"
          }
        }),
        _c("InputField", {
          attrs: { label: "Inflation", type: "percent" },
          on: {
            change: function($event) {
              return _vm.doChanges("inflation", $event)
            }
          },
          model: {
            value: _vm.form.inflation,
            callback: function($$v) {
              _vm.$set(_vm.form, "inflation", $$v)
            },
            expression: "form.inflation"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container dashboard-panel-widget" },
      [
        _c("InputField", {
          attrs: {
            label:
              "monatlicher Bedarf in Euro in Rente (nach heutigem Geldwert)",
            type: "currency"
          },
          on: {
            change: function($event) {
              return _vm.doChanges("bedarfMonatlich", $event)
            }
          },
          model: {
            value: _vm.form.bedarfMonatlich,
            callback: function($$v) {
              _vm.$set(_vm.form, "bedarfMonatlich", $$v)
            },
            expression: "form.bedarfMonatlich"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "geschätzte Renteneinnahmen pro Monat",
            type: "currency"
          },
          on: {
            change: function($event) {
              return _vm.doChanges("renteMonatlichErwartet", $event)
            }
          },
          model: {
            value: _vm.form.renteMonatlichErwartet,
            callback: function($$v) {
              _vm.$set(_vm.form, "renteMonatlichErwartet", $$v)
            },
            expression: "form.renteMonatlichErwartet"
          }
        }),
        _c("InputField", {
          attrs: { label: "Restkapital", type: "currency", precision: 0 },
          on: {
            change: function($event) {
              return _vm.doChanges("restKapital", $event)
            }
          },
          model: {
            value: _vm.form.restKapital,
            callback: function($$v) {
              _vm.$set(_vm.form, "restKapital", $$v)
            },
            expression: "form.restKapital"
          }
        }),
        _c("InputField", {
          attrs: { label: "Rendite im Ruhestand", type: "percent" },
          on: {
            change: function($event) {
              return _vm.doChanges("renditeRuhestand", $event)
            }
          },
          model: {
            value: _vm.form.renditeRuhestand,
            callback: function($$v) {
              _vm.$set(_vm.form, "renditeRuhestand", $$v)
            },
            expression: "form.renditeRuhestand"
          }
        }),
        _c("InputTextArea", {
          attrs: { label: "Notizen", autoGrow: "" },
          on: {
            change: function($event) {
              return _vm.doChanges("bemerkung", $event)
            }
          },
          model: {
            value: _vm.form.bemerkung,
            callback: function($$v) {
              _vm.$set(_vm.form, "bemerkung", $$v)
            },
            expression: "form.bemerkung"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }