<template>
  <div class="box__container">
    <div class="box__title">{{ selectedBereich.beschreibungKurz }}</div>

    <GhostLoading v-if="loading" type="table" />
    <NoData v-else-if="!rows.length" />
    <Table
      v-else
      :headers="headers"
      :rows="rows"
      @action-TOGGLE_SICHTBAR_STRUKTUR="toggleSichtbarStruktur($event)"
      @action-EDIT="editMaklerTexte($event)"
      @action-DELETE="deleteMaklerTexte($event)"
    />

    <AddMaklerTexteModal ref="addMaklerTexteModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MAKLER_TEXTE_TYPES from '@/store/maklerTexte/types';

import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from '@/components/table2/Table.vue';
import { PillColumn, TextColumn, ActionColumn, SimpleAction, ConfirmedAction, } from '@/components/table2/table_util';
import UnterstrukturIcon from './UnterstrukturIcon.vue';
import { PhPencilLine, PhTrash, } from 'phosphor-vue';
import AddMaklerTexteModal from './AddMaklerTexteModal.vue';

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      listByBereich: MAKLER_TEXTE_TYPES.GETTERS.LIST_BY_BEREICH,
      selectedBereich: MAKLER_TEXTE_TYPES.GETTERS.SELECTED_BEREICH,
    }),
    bereich() {
      return this.selectedBereich.id;
    },
    list() {
      return this.listByBereich(this.bereich);
    },
    headers() {
      return {
        lockedLeft: [
          PillColumn('unterstruktur', 'Unterstruktur'),
          TextColumn('betreff', 'Betreff').addCellProps({ lineClamp: 2, }),
        ],
        center: [
          TextColumn('text', 'Text').addCellProps({ lineClamp: 2, }),
        ],
        lockedRight: [
          ActionColumn('actions'),
        ],
      };
    },
    rows() {
      return [ ...this.list, ].map(item => ({
        ...item,
        unterstruktur: {
          type: item.sichtbarStruktur ? 'success' : 'primary',
          label: item.sichtbarStruktur ? 'freigegeben' : 'gesperrt',
        },
        actions: [
          SimpleAction('TOGGLE_SICHTBAR_STRUKTUR', UnterstrukturIcon, item.sichtbarStruktur ? 'für Unterstruktur sperren' : 'für Unterstruktur freigeben'),
          SimpleAction('EDIT', PhPencilLine, 'Bearbeiten'),
          ConfirmedAction('DELETE', PhTrash, 'Löschen', 'Soll der Eintrag wirklich gelöscht werden?', 'Eintrag löschen'),
        ],
      }));
    },
  },
  methods: {
    init() {
      this.findList();
    },
    async findList() {
      try {
        this.loading = true;
        await this.$store.dispatch(MAKLER_TEXTE_TYPES.ACTIONS.FIND_LIST, { bereich: this.bereich, });
      } finally {
        this.loading = false;
      }
    },
    toggleSichtbarStruktur(row) {
      this.$store.dispatch(MAKLER_TEXTE_TYPES.ACTIONS.SAVE_SICHTBAR_STRUKTUR, {
        ids: [row.id],
        freigabe: !row?.sichtbarStruktur,
      })
    },
    editMaklerTexte(row) {
      this.$refs.addMaklerTexteModal.open(row);
    },
    deleteMaklerTexte(row) {
      this.$store.dispatch(MAKLER_TEXTE_TYPES.ACTIONS.DELETE_MAKLER_TEXTE, { ...row, });
    },
  },
  mounted() {
    this.init();
  },
  components: {
    GhostLoading,
    NoData,
    Table,
    AddMaklerTexteModal,
  },
}
</script>
