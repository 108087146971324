<template>
  <div v-if="details">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="isinWkn"
      hidePagination
    >
    </Table>
  </div>
</template>
<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhStar } from "phosphor-vue";
import { mapGetters } from "vuex";
import { sanitize } from "@/helpers/string-helper.js";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  CurrencyColumnm,
  SlotColumn,
} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    PhStar,
    Table,
  },
  name: "TabDetail",
  props: {
    selectedTabLabel: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      fundsDetail: FONDSINFO_TYPES.GETTERS.FONDSINFO_DETAILS,
      allgemeinDescription: FONDSINFO_TYPES.GETTERS.FONDSINFO_ALGEMEIN,
    }),
    details() {
      if (this.fundsDetail) {
        const records = Object.values(this.fundsDetail).slice(1);
        return records;
      }
      return [];
    },
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("anlageziele", "Anlageziele"));
      result.center.push(TextColumn("benoetigteKenntnisseUndErfahrungen", "Benötigte Kenntnisse und Erfahrungen"));
      result.center.push(TextColumn("geeignetFuer", "Geeignet für"));
      result.center.push(TextColumn("geeignetFuerGeringsteRisikoneigung", "Geeignet für geringste Risikoneigung"));
      result.center.push(TextColumn("kundenklassifizierung", "Kundenklassifizierung"));
      result.center.push(TextColumn("verlustrisiko", "Verlustrisiko"));
      result.center.push(TextColumn("zeithorizont", "Zeithorizont"));

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      const fieldValue = (whatArray) => (whatLabel) => (whatType) =>
        whatArray
          ?.find((w) => w?.label == whatLabel)
          ?.records?.find((r) => r.type == whatType)?.value;

      row.anlageziele = fieldValue(this.details)("Zielmarktdaten (MiFID)")("Anlageziele");
      row.benoetigteKenntnisseUndErfahrungen = fieldValue(this.details)("Zielmarktdaten (MiFID)")("Benötigte Kenntnisse und Erfahrungen");
      row.geeignetFuer = fieldValue(this.details)("Zielmarktdaten (MiFID)")("Geeignet für");
      row.geeignetFuerGeringsteRisikoneigung = fieldValue(this.details)("Zielmarktdaten (MiFID)")("Geeignet für geringste Risikoneigung");
      row.kundenklassifizierung = fieldValue(this.details)("Zielmarktdaten (MiFID)")("Kundenklassifizierung");
      row.verlustrisiko = fieldValue(this.details)("Zielmarktdaten (MiFID)")("Verlustrisiko");
      row.zeithorizont = fieldValue(this.details)("Zielmarktdaten (MiFID)")("Zeithorizont");


      rows.push(row);

      return rows;
    },
    tableId() {
      const uniqueUUID = "b3096280-de61-4e4f-8bda-5f508c5bcf6e";
      return uniqueUUID;
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    getStars(value) {
      const starQuantity = parseInt(value.charAt());
      return isNaN(starQuantity) ? 0 : starQuantity;
    },
    detailLabel(detail) {
      if (detail && detail.label) {
        return detail.label;
      }
      return "";
    },
  },
  mounted() {
    if (!this.fundsDetail || !this.allgemeinDescription) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_ALGEMEIN, {
        isin: this.$route.params?.isin,
        depotid: this.$route.params?.depotid,
      });
    }
  },
};
</script>

<style>
.value--item-label {
  width: 25%;
}
</style>
