<template>
  <div>
    <div class="dashboard-panel__widgets">
      <div class="dashboard-panel-widget">
        <img style="height: 300px"
          alt="Platzhalter"
          class="image-cover-vertically"
          :src="defaultMyGoalImage"
          />
      </div>
      <div class="dashboard-panel-widget">
        <Table
          ref="myGoalCardTable"
          tableId="dac58861-120a-3a41-a469-f55f487e86a4"
          rowId="name"
          hidePagination
          :headers="headers"
          :rows="rows"
        >
          <template v-slot:value="row">
            <div>{{ formatValue(row.value, row.type) }}</div>
          </template>
        </Table>
        <div class="row justify-content-center">
          <PieChart
            style="width: 150px; height: 150px"
            :chartData="eChartData(goal)" 
            :isTooltip="false"
            :customColors="eChartColors"
            :radiusProp="['75%', '100%']"
            doughnut
          />
        </div>
      </div>
    </div>
    <div class="bottom-row text-right">
      <router-link :to="openLink">
        Mein Ziel anzeigen
      </router-link>
    </div>
  </div>
</template>

<script>
import mixin from '@/components/myGoals/my-goals-mixin.js';
import PieChart from '@/components/charts/echarts/PieChart.vue'
import { PhCircle } from 'phosphor-vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn} from "@/components/table2/table_util.js";

export default {
  mixins: [mixin],
  props: {
    idProp: {}
  },
  data () {
    return {
      labels: ['angespartes Kapital', 'Finanzierungsbedarf'],
      headers: {
        lockedLeft: [
          TextColumn("name", ""),
          SlotColumn("value", ""),
        ],
        center: [],
        lockedRight: [],
      },
      rowStyle: {fontWeight: 'bold', paddingTop: '2px', paddingBottom: '2px'},
    }
  },
  computed: {
    openLink() {
      return `${this.isCustomerLogin ? '/beratung' : '/service'}/meineziele/${this.id}`;
    },
    rows() {
      const rows = [
        { name: 'Sparziel', value: this.goal.zielsumme, key: 'zielsumme', type: 'currency', customRowStyle: this.rowStyle },
        { name: 'Vorhandenes Kapital', value: this.goal.startkapital, key: 'startkapital',type: 'currency', customRowStyle: this.rowStyle },
        { name: 'Sparrate', value: this.goal.sparrate, key: 'sparrate',type: 'currency', customRowStyle: this.rowStyle },
        // { name: 'Anzahl an Raten', value: this.anzahlRaten },
        { name: 'Beginn', value: this.goal.startDate, key: 'startDate',type: 'date', customRowStyle: this.rowStyle },
        { name: 'Ende', value: this.goal.endDate, key: 'endDate',type: 'date', customRowStyle: this.rowStyle },
        { name: 'Wertentwicklung', value: this.goal.rendite, key: 'rendite',type: 'percent', customRowStyle: this.rowStyle },
      ];
      // if (this.isImmobilie) {
      //   rows.push(... [{ name: 'Angespartes Kapital', value: this.calculateAngespartesKapital, type: 'currency' },
      //   { name: 'Finanzierungsbedarf', value: this.calculateFinanzierungsbedarf, type: 'currency' }])
      // }
      return rows;
    }
  },
  components: {
    PieChart,
    PhCircle,
    Table,
  },
};
</script>

<style lang="scss" scoped>
.dashboard-panel__widgets {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.dashboard-panel-widget {
  margin: 0 12px 24px;
  width: calc(50% - 24px);
  &.box__container {
    .dashboard-panel-widget__content {
      .box__container,
      .box__shadow {
        background: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
.chart-container {
  text-align: right;
}
</style>