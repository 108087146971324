<template>
  <div></div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';

export default {
  mounted() {
    const token = this.$route.query.token;
    const tabsBroadcastId = this.$route.query.tabsBroadcastId;
    const kundennrSteckbrief = this.$route.query.kundennrSteckbrief;
    const maklernr = this.$route.query.maklernr;
    const path = this.$route.query.path;
    const kundennrVersicherungen = this.$route.query.kundennrVersicherungen;
    const isMaklerzugang = (this.$route.query.isMaklerzugang+'') === 'true';
    const maklerzugangTabsBroadcastId = this.$route.query.maklerzugangTabsBroadcastId;
    const isSuperCustomer = (this.$route.query.isSuperCustomer+'') === 'true';
    const kundennrBeratungsmappe = this.$route.query.kundennrBeratungsmappe;
    const isOpenCurrentLoggedUser = (this.$route.query.isOpenCurrentLoggedUser+'') === 'true';
    const fillUnterNr = this.$route.query.fillUnterNr;

    const userId = this.$route.query.userId;
    const isIntern = (this.$route.query.isIntern+'') === 'true';
    if (token) {
      const loginPayload = {
        token,
        tabsBroadcastId,
        nextUrl: path,
        kundennrSteckbrief,
        kundennrVersicherungen,
        maklernr,
        isMaklerzugang,
        maklerzugangTabsBroadcastId,
        isSuperCustomer,
        kundennrBeratungsmappe,
        isOpenCurrentLoggedUser,
        fillUnterNr,
        userId,
        isIntern,
      };
      this.$store.dispatch(CORE_TYPES.ACTIONS.TOKEN_LOGIN, loginPayload);
    }
  },
}
</script>

<style scoped>

</style>