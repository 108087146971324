<template>
  <div v-if="fields.length" class="mb-3">
    
    <Fields ref="refFields"
      :fields="fields" :valuesProp="values" :combos="combos" 
      :tableData="tableData" :rowsCount="rowsCount" 
      :isNotReactive="isNotReactive" :validators="validators"
      actionKeys="delete,duplicate,wiedervorlage"
      @onBtClick="onBtClick" @gotoEdit="gotoEdit" @doAction="doAction"
    >
    </Fields>
    
    <BaseModal 
      ref="modalMessage"
      :modalTitle="modalArg.title"
      :showConfirmButton="modalArg.showConfirmButton"
      :labelButtonConfirm="modalArg.labelButtonConfirm"
      :labelButtonCancel="modalArg.labelButtonCancel"
      @onConfirmButton="modalArg.onConfirm" > 
        <div v-html="sanitize(modalArg.body)"></div>
    </BaseModal>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import Fields from '@/components/begleitscheine/Fields.vue';
import {tableMetaFromArrays} from '@/helpers/commonfunctions.js';
import BaseModal from "@/components/core/BaseModal.vue";
import { regex, required, iban } from "@/mixins/validator/rules";
import {sanitize} from '@/helpers/string-helper.js';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

export default {
  name: 'BegleitscheinData',
  components: { Fields, BaseModal,
  },
  props: {
    currentStepKey: {
      type: String,
      default: 'POSITION',
    },
  },
  data: function() {
    return {
      stepKey: '',
      tableData: {},
      tableName: '',
      tableKey: '',
      show: 'fields',
      values: {},
      formValues: {},
      lastValidateField: '',
      modalArg: {
        title: '',
        showConfirmButton: false,
        labelButtonConfirm: '',
        labelButtonCancel: '',
        body: '',
        onConfirm: () => {}
      },
      isNotReactive: true,
      validators: {
        inputisin: [regex(/^\w{12,12}$/, 'Bitte ISIN eingeben', {forceTouch: true})],
        inputbezug: [required('Bitte Lagerstelle eingeben', {forceTouch: true})],
      },
    }
  },
  mounted() {
    this.onChangeStep();
    this.loadValues();
  },
  beforeDestroy() {
    this.saveData();
  },
  computed: {
    ...mapGetters({
      bgs: DEPOTPOSITIONENEDIT_TYPES.GETTERS.BGS,
    }),
    fields() {
      if (this.currentStepKey && this.bgs?.steps && this.bgs.steps[this.currentStepKey] ) {
        const fields = this.bgs.steps[this.currentStepKey][this.show].map(field => {
          if (field.key == 'input_man_lagerstelle') {
            field.disabled = !this.isManLagerstelle
          }
          return field;
        });
        return fields;
      }
      return [];
    },
    dbValues() {
      if (this.bgs?.values ) {
        if (this.show === 'fields') {
          return this.bgs.values;
        } else if (this.show === 'form') {
          return this.formValues;
        }
      }
      return {};
    },
    isFremdAnlage() {
      return this.values?.checkboxfremdanlage || false;
    },
    isManLagerstelle() {
      return this.values?.inputbezug == 'Manuell';
    },
    combos() {
      if (this.bgs?.combos ) {
        if (this.isFremdAnlage) {
          const combos = JSON.parse(JSON.stringify(this.bgs.combos));
          combos.inputbezug.push({label: '*Manuell*', value: 'Manuell'});
          return combos;
        }
        return this.bgs.combos;
      }
      return {};
    },
    rowsCount() {
      let cnt = null;
      if ( this.fields ) {
        this.fields.forEach(field => {
          if (field.dataType === 'table') {
            this.tableName = field.data2;
            this.tableKey = field.key;
            if (field.data3) {
              if (field.data3.records.length) {
                this.tableData = tableMetaFromArrays(field.data3);
                cnt = field.data3.records.length;
              } else {
                cnt = 0;
              }
            }
          }
        });
      }
      return cnt;
    },
  },
  watch: {
    'currentStepKey': 'onChangeStep',
    dbValues: {
      handler: 'loadValues',
      deep: true,
    },
    values: {
      handler() {
        if (this.values.inputisin?.length == 12 && this.values.inputisin != this.dbValues.inputisin) {
          this.loadISIN();
        } else if (this.values.inputwertpapier  && this.values.inputisin != this.dbValues.inputisin) {
          this.removeISIN();
        }
      },
      deep: true,
    },
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    setHinweise() {
      const hinweise = [];
      switch (this.stepKey) {
        case 'POSITION': {
          if (!this.values.inputisin) {
            hinweise.push(`Die Eingabe eines Wertpapiers ist unbedingt notwendig.
Kann ausgewählt werden mittels "Wertpapiersuche" oder durch einen direkten Eintrag von ISIN.`);
          }
          const wpi = this.fields.find(f => f.key==='buttoninfofonds');
          if(wpi) {
            if (wpi.hidden !== !this.values.inputisin) {
              wpi.hidden = !this.values.inputisin;
            }
          }
          if (!this.values.inputbezug) {
            hinweise.push(`Die Lagerstelle ist unbedingt notwendig.`);
          }
        }
        break;
        case 'TRANSAKT': {
          if (!this.values.inputisin) {
            hinweise.push(`Die Eingabe eines Wertpapiers ist unbedingt notwendig.
Kann ausgewählt werden mittels "Wertpapiersuche" oder durch einen direkten Eintrag von ISIN.`);
          }
        }
        break;
      }
      this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.HINWEIS, {texte: hinweise, show: false});
    },
    pushError(field_key, text) {
      if ( this.$refs.refFields.$pushErrors ){
        this.$refs.refFields.$pushErrors(field_key, text);
      }
    },
    async loadISIN() {
      if (this.stepKey === 'POSITION' && this.bgs?.bgsnr == '0') {
        this.bgs.values = this.values;
      }
      let response = await this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_VAL, {isin: this.values.inputisin});
      if (response.data?.ok && response.data.isin === this.values.inputisin) {
        this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS, {fund: response.data });
      } else {
        this.removeISIN();
        this.pushError('inputisin', "ISIN wurde nicht gefunden");
      }
      this.setHinweise();
    },
    removeISIN() {
      this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS, {fund: {
        isin: this.values.inputisin,
        inputwertpapier: '',
        inputwkn: '',
        gesellschaft: '',
        input_schwerpunkt: '',
      } });
    },
    onBtClick(field) {
      if (field.key === 'buttoninfofonds'){
        const isin = this.values.inputisin;
        if (isin?.length === 12) {
          const bgsnr = this.values.param_bgsnr;
          if (bgsnr) {
            this.$router.push({path: `/shared/vermogensubersicht/fondsinfo/${isin}/${bgsnr}` });
          } else {
            this.$router.push({path: `/shared/vermogensubersicht/fondsinfo/${isin}` });
          }
        }
      } else if (field.key === 'buttonfindfonds'){
        if (this.stepKey === 'POSITION' && this.bgs?.bgsnr == '0') {
          this.bgs.values = this.values;
        }
        this.$router.push({path: `/home/vermogensubersicht/begleitscheinwpsuche`});
      } else if (field.key === 'button_portfolio_produkt_delete') {
        if(!this.values.input_portfolio_produkt_auswahl) {
          return;
        }
        this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.DEL_PF_KND, 
            {delete_pf: this.values.input_portfolio_produkt_auswahl, bgsnr: this.values.param_bgsnr})
          .then(message => {
            let name = this.combos.input_portfolio_produkt_auswahl.find(p => p.value === this.values.input_portfolio_produkt_auswahl);
            name = name ? name.label : this.values.input_portfolio_produkt_auswahl;
            this.modalArg.title = message ? message : 'Die Zuordnung '+ name +' löschen?';
            this.modalArg.showConfirmButton = message ? false : true;
            this.modalArg.labelButtonConfirm = 'Ja';
            this.modalArg.labelButtonCancel =  message ? 'Schließen' : 'Nein';
            this.modalArg.body = '';
            this.modalArg.onConfirm = () => {
              const params =  {
                delete_pf: this.values.input_portfolio_produkt_auswahl,
                bgsnr: this.values.param_bgsnr || '',
                canDelete: 'ok',
                inputbezug: this.values.inputbezug,
                inputisin: this.values.inputisin};
              this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.DEL_PF_KND, params).then(combos => {
                if (combos) {
                  this.values.input_portfolio_produkt_auswahl = 'EINZELFONDS';
                  this.values.input_portfolio_produkt_name = '';
                  this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS, 
                    {combos: {...this.bgs.combos, ...combos} });
                 }
              })
            };
            this.$refs.modalMessage.open();
          }
        );
      } else if (field.key === 'button_abbrechen') {
      } else if (field.key === 'button_save') {
        const swForm = this.saveData(true);
        if (!swForm) {
        }
      }
    },
    loadValues() {
      if (this.dbValues && Object.keys(this.dbValues).length ) {
        this.fields.forEach(field => {
          if (this.dbValues[field.key] === undefined && field.dataType != 'button') {
            if (field.dataType == 'check') {
              this.dbValues[field.key] = false;
            } else {
              this.dbValues[field.key] = '';
            }
          }
        })
        this.isNotReactive = false;
        this.values = JSON.parse(JSON.stringify(this.dbValues));
        this.$nextTick( () => {this.isNotReactive = true;})
        this.setHinweise();
      } else {
        this.values = {};
      }
    },
    canInsertBgs() {
      return this.dbValues.inputisin && this.values.inputbezug 
          && (this.values.inputbezug != 'Manuell' || this.values.input_man_lagerstelle);
    },
    
    saveData(saveButton) {
      let diff = {};
      Object.keys(this.values).forEach(key => {
        if (typeof this.values[key] !== 'object' && (this.dbValues[key] !== this.values[key] || this.bgs?.bgsnr == '0' && this.values[key] != '')) {
          diff[key] = this.values[key];
        }
      });
      if (Object.keys(diff).length || this.stepKey === 'POSITION' && !this.values.param_bgsnr ) {
        if (this.stepKey === 'POSITION') {
          if (this.canInsertBgs()) {
            diff = this.values.param_bgsnr ? diff : this.values;
            diff.bgsnr = this.values.param_bgsnr || '';
            diff.ask = 'save_bgs';
            return this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.SAVE_BGS, diff);
          }
        }
      }
      return false;
    },
    canChangeStep(step) {
      if (this.bgs?.bgsnr == '0' && step != 'POSITION'){
        if (this.canInsertBgs()) {
          return 'save'
        }
        let texte = [];
        if (!this.dbValues.inputisin) {
          texte.push('Wertpapier');
        }
        if (!this.values.inputbezug) {
          texte.push('Lagerstelle');
        }
        if (this.values.inputbezug == 'Manuell' && !this.values.input_man_lagerstelle) {
          texte.push('manuelle Lagerstelle');
        }
        this.modalArg.title = 'Depotposition';
        this.modalArg.showConfirmButton = false;
        this.modalArg.labelButtonConfirm = '';
        this.modalArg.labelButtonCancel = 'Schließen';
        this.modalArg.body = 'Bitte ' + texte.join(', ') + ' eingeben.';
        this.$refs.modalMessage.open();
        return 'nein';
        // saveData
      }
      return true;
    },
    onChangeStep() {
      if (this.stepKey === this.currentStepKey) {
        return;
      }
      if (this.stepKey && this.stepKey !== this.currentStepKey && this.show === 'fields') {
        this.saveData();
      }
      this.stepKey = this.currentStepKey;
      this.tableData = {};
      this.fields.forEach(field => {
        if (field.dataType === 'table') {
          this.tableName = field.data2;
          this.tableKey = field.key;
          if (field.data3) {
            if (field.data3.records.length) {
              this.tableData = tableMetaFromArrays(field.data3);
            }
          }
        }
      });
      this.setHinweise();
      this.isNotReactive = false; 
      this.$nextTick( () => {this.isNotReactive = true;})
    },
    getAktionLink(actions, visible) {
      if (!actions) {
        return [];
      } else if (visible) {
        return actions.filter(b => b.key === 'delete');
      } else {
        const result = [];
        actions.forEach(a => {
          if (a.key !== 'edit' && a.key !== 'delete') {
            result.push(a);
          }
        })
        return result;
      }
    },
    setDatum(key, datum) {
      if ( null != datum) {
        if (typeof datum === 'object') {
          this.values[key] = datum.toLocaleDateString('de-DE',  { day: '2-digit', month: '2-digit', year: 'numeric' });
        } else if (datum === '') {
          this.values[key] = null;
        }
      }
    },
    gotoEdit(field, row) {
      switch (field.key) {
        case 'transaktionen': {
          this.$router.push({path: `/home/vermogensubersicht/bgstransakt/${this.values.param_bgsnr}/${row.actions[0].data?.paramtransnr}/TRANSAKT` });
        }
        break;

        case 'aktivitaeten': { // BegleitscheinAktivitaet.vue
          const action = row.actions.find( a => a.key === 'edit');
          if (action.data?.param_sid) {
            this.$router.push({path: `/home/vermogensubersicht/bgsaktivit/${this.values.param_bgsnr}/${action.data.param_sid}/DATEN` });
          }
        }
        break;
      
        case 'dokumente': {
            if (typeof row.actions[0].data === 'string') {
              row.actions[0].data = JSON.parse( row.actions[0].data.replace(/'/g, '"') );
            }
            if (row.actions[0].data?.param_fileid) {
              this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, {
                ask: 'get_dok',
                bgsnr: this.values.param_bgsnr || '',
                fileid: row.actions[0].data.param_fileid,
              })
              .then(response => {
                if ( response.file) {
                  let contentType = '';
                  const test = row.editierbar.toUpperCase();
                  if (test.indexOf(".PDF")) {
                    contentType = 'application/pdf';
                  } else if (test.indexOf(".XLS")) {
                    contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                  } else if (test.indexOf(".CSV")) {
                    contentType = 'text/csv;charset=utf-8;';
                  } else if (test.indexOf(".GIF")) {
                    contentType = 'image/gif';
                  } else if (test.indexOf(".PNG")) {
                    contentType = 'image/png';
                  } else if (test.indexOf(".JPEG") || test.indexOf(".JPG")) {
                    contentType = 'image/jpeg';
                  }
                  viewDocument({
                    data: response.file,
                    filename: row.editierbar,
                    contentType
                  });
                } else if ( response.message) {
                  this.modalArg.title = response.message;
                  this.modalArg.showConfirmButton = false;
                  this.modalArg.labelButtonConfirm = 'Ja';
                  this.modalArg.labelButtonCancel = 'Schließen';
                  this.modalArg.body = '';
                  this.$refs.modalMessage.open();
                }
              });
            }
        }
        break;
      
        case 'sparplaene': {
          this.$router.push(`/home/vermogensubersicht/sparplan/${this.values?.param_bgsnr}/${row.actions[0]?.data?.param_planid}`);
        }
        break;
      
        case 'courtagezubringer': {
          this.$router.push({path: `/home/vermogensubersicht/courtage/${this.values.param_bgsnr}/${row.actions[0].data?.parameter_id}` });          
        }
        break;
      }
    },
    doAction(key, row) {
      {
        const arg = {
          showConfirmButton: true,
          labelButtonConfirm: 'Ja',
          labelButtonCancel: 'Nein',
        };
        if ( this.tableKey === 'dokumente' && row.actions[0].data?.param_fileid) {
          arg.message = 'Bestätigung';
          arg.body = 'Das Dokument '+ row.editierbar +' löschen?';
          arg.params = {
            ask: 'del_dok',
            bgsnr: this.values.param_bgsnr || '',
            fileid: row.actions[0].data?.param_fileid,
          };
        } else if ( this.tableKey === 'sparplaene' && row.actions[1].data?.param_planid) {
          arg.message = 'Bestätigung';
          arg.body = 'Soll der Spar-/Entnahmeplan wirklich gelöscht werden?';
          arg.params = {
            ask: 'del_item',
            item: 'Splarplan',
            planid: row.actions[1].data?.param_planid,
          };
        } else if ( this.tableKey === 'courtagezubringer' && row.actions[0].data?.parameter_id) {
          arg.message = 'Bestätigung';
          arg.body = 'Die Zubringercourtage für '+ row.editierbar +' löschen?'
          arg.params = {
            ask: 'del_item',
            item: 'Zubringercourtage',
            id: row.actions[0].data?.parameter_id,
          };
        } else if ( this.tableKey === 'transaktionen' && row.actions[0].data?.paramtransnr) {
          if (key === 'delete') {
            arg.message = 'Bestätigung';
            arg.body = 'Die Transaktion für '+ row.editierbar +' löschen?'
            arg.params = {
              ask: 'del_item',
              item: 'Transaktion',
              paramtransnr: row.actions[0].data?.paramtransnr,
            };
          } else if (key === 'duplicate') {
            const action = row.actions.find( a => a.key === 'duplicate');
            arg.message = 'Bestätigung';
            arg.body = action.data.confirm;
            arg.params = {
              ask: 'duplicate_transakt',
              paramtransnr: row.actions[0].data?.paramtransnr,
            };
          }
        } else if ( this.tableKey === 'aktivitaeten' && row.actions[0].data?.param_sid) {
          const action = row.actions.find( a => a.key === key);
          if (key === 'delete') {
            if (action.data.confirm) {
              arg.message = 'Bestätigung';
              arg.body = action.data.confirm;
              arg.params = {
                ask: 'del_item',
                item: 'Wiedervorlage',
                param_sid: action.data.param_sid,
                param_id: action.data.param_id,
              };
            } else {
              arg.message = 'Absage';
              arg.body = action.data.error || 'Sie dürfen diesen Eintrag leider nicht löschen.';
              arg.showConfirmButton = false;
              arg.labelButtonCancel = 'Schlissen';
            }
          } else if (key === 'wiedervorlage') {
            arg.params = {
              ask: 'chg_akt',
              param_sid: action.data?.param_sid,
              status_value: action.data.bestaetigt ? 'Nein' : 'Ja',
              status_datum: row.editierbar
            };
            if (action.data.confirm) {
              arg.body = action.data.confirm;
              arg.message = 'Bestätigung';
            } else {
              this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, arg.params)
              .then(response => {
                if (response.ok){
                  this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: this.values.param_bgsnr});
                }
              });
            }
          }
        }
        if (arg.message || arg.body) {
          this.modalArg.title = arg.message;
          this.modalArg.showConfirmButton = arg.showConfirmButton;
          this.modalArg.labelButtonConfirm = arg.labelButtonConfirm;
          this.modalArg.labelButtonCancel = arg.labelButtonCancel;
          this.modalArg.body = arg.body || '';
          this.modalArg.onConfirm = () => {
            this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, arg.params)
            .then(response => {
              if ( response.message) {
                this.modalArg.title = response.message;
                this.modalArg.showConfirmButton = false;
                this.modalArg.labelButtonConfirm = 'Ja';
                this.modalArg.labelButtonCancel = 'Schließen';
                this.modalArg.body = '';
                this.$refs.modalMessage.open();
              } else if (response.ok){
                this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: this.values.param_bgsnr});
              }
            });
          };
          this.$refs.modalMessage.open();
        }
      }
    },
    openModalEditCustomerSearchResultTable() {
      this.$refs.tableResult.openColumnsConfig();
    },
  },
}
</script>

<style scoped>
</style>