import GELDWAESCHEBELEHRUNG_TYPES from './types';
import axios from 'axios';

export default {
  [GELDWAESCHEBELEHRUNG_TYPES.ACTIONS.GET_LAYOUT_METADATA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/geldwaeschebelehrung/getLayoutMetadata';

      let params = {};
      
      if(payload?.isMitarbeiter) {
        params.isMitarbeiter = payload.isMitarbeiter;
      }
      
      axios.get(rootState.core.apiAddress + serviceUrl, {params, config}).then(response => {
        if (response && response.data) {
  
          commit(GELDWAESCHEBELEHRUNG_TYPES.MUTATIONS.GET_LAYOUT_METADATA_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });
  },

  [GELDWAESCHEBELEHRUNG_TYPES.ACTIONS.GET_WAG_PRUEFUNG_LINK]( {rootState, commit}) {
    axios.get(`${rootState.core.apiAddress}/wagpruefung/get_helpmaster_link`).then(response => 
      commit(GELDWAESCHEBELEHRUNG_TYPES.MUTATIONS.GET_WAG_PRUEFUNG_LINK_SUCCESS, response?.data)
    )
  },

}