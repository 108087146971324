<template>
  <div>
    <OptionMenu 
      :id="$appNavigation.parentOptionMenuId" 
      :defaultMenu="$appNavigation.parentOptionMenu"
        />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Feststellung der BiPRO Kategorien" :actions="headerActions" @action-ADD="addNewCategory()"  />

    <BaseFilter 
      filterId="d0c9a283-6380-4709-933b-943bd6b7f908"
      title="Bipro Kategorien filtern" 
      :metadata="filterMetadata" 
      :defaultOptions="defaultOptionsData"
      v-if="mscCategories && mscCategories.length"
      @onFilter="filterForm($event)">
    </BaseFilter>
    <GhostLoading v-else-if="!mscCategories || !mscCategories.length" type="block" :config="{ block: { height: 100, }, }" useBoxContainer />

    <div class="box__container" tid="7bf88b18-1485-4130-a282-2101d77f8608">

      <BigTable  
        tableId="907ebeec-ab09-4154-ac82-a817dfa73d67"
        :title="TABLE_TITLE"
        v-if="!loading && rows.length"
        :headers="headers"
        :rows="rows"
        :page="page"
        :sortingState="sortingState"
        :initialPageSize="minNumRows"
        @onClickHeader="onClickHeader"
        @click-link-gevoArt="bearbeiten"
        @action="executeAction($event)"
      >
        
      </BigTable>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    

    <BaseModal @onConfirmButton="removeItem()" ref="removeItemConfirmation" modalTitle="Gevo Art löschen" labelButtonConfirm="Löschen">
      <template v-if="selectedRow">
        Soll die Gevo Art {{selectedRow.gevoArt}} wirklich gelöscht werden?
      </template>
    </BaseModal>

    <BaseModal 
      ref="editModal"
      modalTitle="Feststellung der BiPRO Kategorien"
      :confirmDisabled="isFormInvalid"
      :autoClose = "false"
      @onConfirmButton="saveOrUpdate()">

      <div>
        <InputField
          id="gevoArt"
          v-model="form.gevoArt"
          type="number"
           isInteger
          label="Gevo Art"
          :disabled="form.editing"
          :validateUntouched="true"
        />  

        <InputField
          id="hauptKategorie"
          v-model="form.hauptKategorie"
          label="Hauptkategorie"
          :validateUntouched="true"
        />      

        <InputField
          id="unterKategorie"
          v-model="form.unterKategorie"
          label="Unterkategorie"
          :validateUntouched="true"
        />                     

        <ComboBox
          id="kategorieFK"
          label="MSC Dateikategorie"
          :firstEmpty="true"
          v-model="form.kategorieFK"
          :values="mscCategories"
          :validateUntouched="true"
        />

        <InputField
          id="transferNorm"
          v-model="form.transferNorm"
          label="Transfernorm"
        />   

        <InputField
          id="zuordnungsAttribute"
          v-model="form.zuordnungsAttribute"
          label="Zuordnungsattribute"
        />  

      </div>
    </BaseModal>    

  </div>
    
</template>

<script>
import BIPRO_CATEGORY_TYPES from "../../store/biproCategory/types";
import { mapGetters } from "vuex";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, DateColumn} from "@/components/table2/table_util.js";
import { PhPencil, PhTrash} from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import validator from '@/mixins/validator'
import InputField from '@/components/core/forms/InputField.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'
import { required } from '@/mixins/validator/rules';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, ACTION_COLUMN, FIXED_LEFT, NUMBER_COLUMN, DATE_COLUMN, LINK_COLUMN} from '@/components/bigTable/BigTable.vue';
import { PageHeaderConfirmedAction} from '@/components/core/header-title-navigation/page-header-utils';



const TABLE_TITLE = 'Kategorien';

export default {
  mixins: [validator],
  validators: {},
  components: {
    GhostLoading,
    NoData,
    BaseModal,
    BaseFilter,
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    ComboBox,
    BigTable,
  },
  created() {
    // Store the initial state of the form
    this.initialFormState = JSON.parse(JSON.stringify(this.form));
  },  
  data: function () {
      return {
        TABLE_TITLE,
        loading: true,
        form: {
          gevoArt: null,
          hauptKategorie: null,
          unterKategorie: null,
          kategorieFK: null,
          statusFK: null,
          transferNorm: null,
          zuordnungsAttribute: null,
          editing: false,
        },
        initialFormState: {},
        savedFilters: {},
        headers: [
          { name: "Gevo Art", label: "Gevo Art", key: "gevoArt", width: 200, type: LINK_COLUMN, position: FIXED_LEFT },
          { name: "Hauptkategorie", label: "Hauptkategorie", key: "hauptKategorie", width: 200, type: STRING_COLUMN, visible: true },
          { name: "Unterkategorie", label: "Unterkategorie", key: "unterKategorie", width: 400, type: STRING_COLUMN, visible: true },
          { name: "Kategorie MSC", label: "Kategorie MSC", key: "kategorieFK", width: 250, type: STRING_COLUMN, visible: true },
          { name: "Transfernorm", label: "Transfernorm", key: "transferNorm", width: 400, type: STRING_COLUMN, visible: true },
          { name: "Zuordnungsattribute", label: "Zuordnungsattribute", key: "zuordnungsAttribute", width: 400, type: STRING_COLUMN, visible: true },
          { name: "Datum", label: "Datum", key: "dateCreated", width: 200, type: DATE_COLUMN, visible: true },
          { name: "Aktionen", label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
        ],
        sortingState: { key: "gevoArt", direction: "desc" },
        minNumRows: 100,
        page: 0,
        rows: [], 

      }
  },
  computed: {
    ...mapGetters({
      biproCategories: BIPRO_CATEGORY_TYPES.GETTERS.BIPRO_CATEGORIES,
      selectedRow: BIPRO_CATEGORY_TYPES.GETTERS.SELECTED_ROW,
    }),
    headerActions() {
      if(this.canSeeSpecialColumns) {
        return [
          PageHeaderSimpleAction('ADD', 'Kategorie hinzufügen')
        ];
      }
      return [];
    },    
    isFormInvalid() {
      return this.validation.invalid;
    },
    fieldsValidators() {
      
      let validators = {
        gevoArt : [required('Gevo Art ist erforderlich!')],
        hauptKategorie: [required('Hauptkategorie ist erforderlich!')],
        unterKategorie: [required('Unterkategorie ist erforderlich!')],
        kategorieFK: [required('MSC Dateikategorie ist erforderlich!')],
      }

      return {form: validators};
    },    
    mscCategories() {
      return this.biproCategories?.mscCategories || [];
    },
    canSeeSpecialColumns() {
      return this.biproCategories?.darfVersdokumenteKategoriefestlegen;
    },
    defaultOptionsData() {
      return {
          gevoArt: {
              value: "",
          },
          hauptKategorie: {
              value: "",
          },                    
        }; 
    }, 
    filterMetadata() {
         return [
            {
              type: "group",
              key: "allgemein",
              label: "Allgemein",
              menuItems: [
                {
                  type: 'text',
                  key: 'gevoArt',
                  label: 'Gevo Art',
                  inputmode: 'numeric',
                },
                {
                  type: 'text',
                  key: 'hauptKategorie',
                  label: 'Hauptkategorie',
                },
                {
                  type: 'text',
                  key: 'unterKategorie',
                  label: 'Unterkategorie',
                },
                {
                  type: 'combobox',
                  key: 'kategorieFK',
                  label: 'Kategorie MSC',
                  comboOptions: this.mscCategories || [],
                  emptyDenied: false,
                },                   
                {
                  type: 'text',
                  key: 'transferNorm',
                  label: 'Transfernorm',
                },
                {
                  type: 'text',
                  key: 'zuordnungsAttribute',
                  label: 'Zurdnungsattribute',
                },
                {
                    type: 'dateRange',
                    label: 'Datum',
                    key: 'datum',
                },                                                                                     
              ],
            },     
          ];  
    }
  },
  mounted() {
    this.retrieveData({});  
    this.$configureValidators(this.fieldsValidators);
  },
  methods: {
    resetForm() {
        // Reset the form to its initial state
        this.form = JSON.parse(JSON.stringify(this.initialFormState));
    },    
    async saveOrUpdate() {
      this.loading = true;
      this.$refs.editModal.close();
      try {
        if (this.form?.editing) {
          await this.$store.dispatch(BIPRO_CATEGORY_TYPES.ACTIONS.UPDATE_BIPRO_CATEGORY, this.form);
        } else {
          await this.$store.dispatch(BIPRO_CATEGORY_TYPES.ACTIONS.SAVE_BIPRO_CATEGORY, this.form);
        }
        await this.retrieveData(this.savedFilters || {});
      } catch (error) {
        console.error("An error occurred while saving or updating:", error);
      } finally {
        this.loading = false;
        this.resetForm();
      }
    },
    executeAction(actionData) {
      switch (actionData.key) {
        case 'EDIT_ITEM':
          this.bearbeiten(actionData.row);
          break;
        case 'REMOVE_ITEM':
          this.confirmItemRemoval(actionData.row);
          break;     
      }
    },   
    confirmItemRemoval(row) {
      this.$store.commit(BIPRO_CATEGORY_TYPES.MUTATIONS.SELECT_ROW, row);
      this.$refs.removeItemConfirmation.open();
    },
    async removeItem() {
      this.loading = true;
      await this.$store.dispatch(BIPRO_CATEGORY_TYPES.ACTIONS.REMOVE_ROW, {id: this.selectedRow?.gevoArt});
      await this.retrieveData(this.savedFilters || {});  
      this.loading = false;
    },
    async retrieveData(payload) {
      this.loading = true;
      await this.$store.dispatch(BIPRO_CATEGORY_TYPES.ACTIONS.RETRIEVE_BIPRO_CATEGORIES, payload);

      const fullActions = [
          SimpleAction("EDIT_ITEM", PhPencil, "Bearbeiten"),
          SimpleAction("REMOVE_ITEM", PhTrash, "Löschen"),
      ];

      this.rows = this.biproCategories?.rows?.map(data => ({
        ...data,
          actions: this.biproCategories?.darfVersdokumenteKategoriefestlegen ? fullActions : [],
      }));


      this.loading = false;
    },
    bearbeiten(row) {
      this.$store.commit(BIPRO_CATEGORY_TYPES.MUTATIONS.SELECT_ROW, row);

      // Filter the row object to include only keys that are in the form object
      const filteredRow = Object.keys(this.form)
          .filter(key => key in row)
          .reduce((obj, key) => {
              obj[key] = row[key];
              return obj;
          }, {});

      filteredRow.editing = true;

      // Assign the filtered properties to the form
      Object.assign(this.form, filteredRow);
      this.$configureValidators(this.fieldsValidators);

      this.$refs.editModal.open();
    },
    addNewCategory() {
      this.resetForm();
      this.$refs.editModal.open();
    },
    async filterForm(filterConfig) {
      const getValueOrNull = (key) => {
        const value = filterConfig.find(fc => fc.key == key)?.value;
        return value === "" ? null : value;
      };

      const keys = ["gevoArt", "hauptKategorie", "unterKategorie", "kategorieFK", "transferNorm", "zuordnungsAttribute"];
      const data = {};

      keys.forEach(key => {
        const value = getValueOrNull(key);
        if (value !== null) {
          data[key] = value;
        }
      });

      Object.assign(this.savedFilters, data);
      await this.retrieveData(data);
    },
    onClickHeader(headerMetadata) {
      this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
      // TODO: save the sorting state
    }, 


  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Bipro Kategorienübersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    next();
  },


    
}
</script>