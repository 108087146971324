<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Fondsdepotbank"
      subtitle="Übertragung"
    />

    <div v-if="searchingPositions">
      <div class="row">
        <div class="col-12">
          <h2>Wertpapiersuche</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <FondsFinderSuchMaske/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <FondsFinderPositionsList @goBack="addFonds($event)" zurueckButtonText="Abbrechen" :addPositionsOnBack="false"/>
        </div>
      </div>
    </div>
    <div v-else class="antrag-components__container">
      <div class="box__container">
        <FormHeader label="Wertpapiere"/>
        <p></p>
        <InputField v-model="positionData.bisherDepotnr" label="bisherige Depotnummer"/>


        <InputToggleSwitch v-model="show" label="Investmentanteile des/der folgen den/folgender Investmentvermögen/s:"/>
        
        <Table class="table-container" v-if="show == true"
          tableId="f168f001-d40a-4a9a-ba2b-aa8d3ce74e30"
          :tableData="tableData"
          :cardViewEnabled="false"
          :filterEnabled="false"
          :exportEnabled="false"
          :paginationEnabled="false">
          <template v-slot:action="props">
            <InputField v-model="positionData['anteile' + props.data.row.index]" v-if="props.data.key == 'Anteile'"/>
            <InputCheckboxItem v-model="positionData['alle' + props.data.row.index]" v-if="props.data.key == 'Gesamtbestand'"/>
          </template>

          <template v-slot:columnActions="props">
            <BaseButton @click="deleteFond(props.data.row.index - 1)" isSecondary>
              <ph-trash :size="16" />
            </BaseButton>
          </template>
        </Table>
        
        <BaseButton v-if="show == true" @click="searchingPositions=true">Wertpapiere hinzufügen</BaseButton>

        <FormDivider/>
        <FormHeader label="Sonstiges"/>
        <p></p>
        <FormLabel label="Weiterhin bitte/n ich/wir Sie,"/>
        <InputToggleSwitch v-model="positionData.sparUebernehmen" label="den/die Sparplan/-pläne zu meinem/unserem bestehenden Depot zu übernehmen, sonst"/>

        <InputRadioBoxGroup v-model="positionData.sparLoeschen" v-if="!positionData.sparUebernehmen" :values="spar" @change="sparChanged()"/>
        
        <InputToggleSwitch v-model="positionData.entnahmeUebernehmen" label="den/die Auszahlplan/-pläne zu meinem/unserem bestehenden Depot zu übernehmen, sonst"/>
        <InputRadioBoxGroup v-model="positionData.entnahmeLoeschen" v-if="!positionData.entnahmeUebernehmen" :values="entnahme" @change="onchange()"/>

        <FormLabel label="Hinweis:"/>
        <FormLabel label="Bei Übertrag des gesamten Anteilbestandes dieses/dieser Investmentvermögen/s 
            wird/werden der/die diesbezüglich evtl. bestehende Auszahlplan/-pläneautomatisch und ohne zusätzliche 
            Mitteilung an den Depotinhaber gelöscht. Entsprechendes gilt für regelmäßige Tauschaufträge von 
            Investmentvermögen, die ggf. zuLasten des Bestandes von Anteilen dieses/dieser o. g. Investmentvermögen/s 
            vorgemerkt sind. Ggf. ist ein neuer Auftrag einzureichen."/>

        <InputToggleSwitch v-model="positionData.uebersendung" label="um Übersendung eines entsprechenden Vordrucks, damit ich/wir meinen/unseren Freistellungsauftrag für Kapitalerträge ändern kann/können."/>
        <FormLabel label="falls der gesamte Depotbestand (alle Anteile aller Investmentvermögen im jeweiligen Depot) übertragen werden soll"/>
        <InputToggleSwitch v-model="positionData.depotLoeschung" label="um Löschung des o. g. Depots nach Übertragung des Bestandes, d. h. auch Löschung aller Spar- und Auszahlpläne "/>
        <FormLabel label="sowie:"/>
        <InputToggleSwitch v-model="positionData.befristung" label="Befristung des Freistellungsauftrages für Kapitalerträge zum Jahresende. (Hierfür sind die Unterschriften aller Depotinhaber erforderlich.)" />
        <!--<EntitySelector v-model="positionData.bankverbindungId" :values="bankverbindungSelection" label="Verwende Konto" v-if="positionData.kontoAufloesen" @new="editBankverbindung" @edit="editBankverbindung"/>-->
        <div class="row">
          <div class="col">
            <BaseButton @click="onBackButton">Zurück zum Formular</BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex';
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import ANTRAG_TYPES from '@/store/antrag/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import FormHeader from '@/components/core/forms/FormHeader.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import Table from '@/components/table/Table.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import {PhTrash} from 'phosphor-vue';
import InputCheckboxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';

export default {
  mixins: [antragNavigationMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    FormHeader,
    FormLabel,
    InputRadioBoxGroup,
    InputField,
    InputToggleSwitch,
    BaseButton,
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    Table,
    FormDivider,
    PhTrash,
    InputCheckboxItem
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
    antrag () {
      return this.antraege[this.antragId]
    },
    component() {
      return this.antrag.components[this.step].find(component => component.id == this.categoryId)
    },
    componentData() {
      return this.antraegeData[this.antragId][this.component.id]
    },
    /*bankverbindungSelection() {
      return this.antrag && this.antrag.comboboxSelections && this.antrag.comboboxSelections[this.bankverbindungId]
    },*/
    tableData() {
      const records = []
      if (this.antrag)
        for (let i = 1; i <= this.component.config.anzahlFonds; i++)
          if (this.positionData["isin" + i])
            records.push({
              ISIN: this.positionData["isin" + i],
              Wertpapiername: this.positionData["fondsname" + i],
              Anteile: this.positionData["anteile" + i],
              Gesamtbestand: this.positionData["alle" + i],
              index: i,
            })
      return {
        headers: {
          Wertpapiername: {
            label: "Fondsname",
            key: "Wertpapiername",
            dataType: "String",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          ISIN: {
            label: "ISIN",
            key: "ISIN",
            dataType: "String",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          Anteile: {
            label: "Stückzahl",
            key: "Anteile",
            dataType: "Action",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          Gesamtbestand: {
            label: "Gesamtbestand",
            key: "Gesamtbestand",
            dataType: "Action",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          columnActions: {
            label: 'Aktionen',
            key: 'columnActions',
            dataType: 'Slot',
            visible: true,
          },
        },
        records,
      }
    }
  },
  data() {
    return {
      antragId: null,
      fondIndex: -1,
      categoryId: null,
      positionData: {
      },
      show: false,
      step: null,
      wertpapierConfig: {
        type: "FFBUEBERTRAGUNG",
        buttonText: "Wertpapier hinzufügen",
      },
      spar: [
        {value: false, label: "nicht übernehmen"},
        {value: true, label: "zu löschen:"},
      ],
      entnahme: [
        {value: false, label: "nicht übernehmen"},
        {value: true, label: "zu löschen:"},
      ],
      //bankverbindungId: "INPUT_KONTO",
      searchingPositions: false,
    }
  },
  mounted() {
    const antragsName = this.$route.params.antragsName
    this.antragId = calculateAntragID({antragsName: antragsName, lagerstelle: this.$route.params.lagerstelle})
    this.fondIndex = this.$route.params.fondIndex
    this.categoryId = this.$route.params.categoryId
    this.step = this.$route.params.step
    this.positionData = {
      ...this.componentData[this.fondIndex]
    }
  },
  methods: {
    onBackButton() {
      this.navigateBackToCurrentStep()
    },
    savePosition() {
      const newData = this.componentData.slice()
      newData[this.fondIndex] = this.positionData
      const payload = {
        id: this.antragId,
        data: {
          [this.component.id]: newData
        }
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
    },
    addFonds(fonds) {
      this.searchingPositions = false
      if (!fonds || fonds.length == 0)
        return
      // combine previously selected fonds with the new fonds
      const positionList = this.getPositions()
      fonds.forEach(fond => {
        positionList.push({
          isin: fond.isin,
          name: fond.wertpapiername,
          anteile: fond.stueckzahl,
          alle: fond.alle,
        })
      })
      this.setPositions(positionList)
    },
    deleteFond(index) {
      const positionList = this.getPositions()
      positionList.splice(index, 1)
      this.setPositions(positionList)
    },
    getPositions() {
      const positionList = []
      for (let i = 1; i <= this.component.config.anzahlFonds; i++)
        if (this.positionData["isin" + i])
          positionList.push({
            isin: this.positionData["isin" + i],
            name: this.positionData["fondsname" + i],
            anteile: this.positionData["anteile" + i],
            alle: this.positionData["alle" + i],
          })
      return positionList
    },
    setPositions(positionList) {
      for (let i = 1; i <= this.component.config.anzahlFonds; i++) {
        Vue.set(this.positionData, "isin" + i, positionList.length >= i ? positionList[i-1].isin : null)
        Vue.set(this.positionData, "fondsname" + i, positionList.length >= i ? positionList[i-1].name : null)
        Vue.set(this.positionData, "anteile" + i, positionList.length >= i ? positionList[i-1].anteile : null)
      }
    },
    sparChanged(event) {
      spar.value = false
     if(positionData.sparUebernehmen == "false")
      positionData.sparLoeschen = true
     else
      positionData.sparLoeschen = false
    },   
    sparChanged() {
    if(data.sparUebernehmen == "false")
      data.sparLoeschen = true
     else
      data.sparLoeschen = false
    },
    onchange() {
     if(data.entUebernehmen == "false")
      data.entLoeschen = true
     else
      data.entLoeschen = false
    },
  },
  beforeDestroy() {
    this.savePosition()
  }
}
</script>
<style scoped>

</style>