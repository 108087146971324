var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SignoViewerLink", {
    attrs: {
      asButton: !_vm.isShowAsLink,
      title: _vm.label,
      requestURL: "",
      isDeepLink: "",
      extraParams: "noRedirect=true",
      href: _vm.servicePath,
      disabled: _vm.disabled
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }