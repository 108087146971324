var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hideNavigation
        ? [
            _c("PageHeaderTitleNavigation", {
              attrs: {
                title: "Kredite",
                defaultMenu: _vm.$appNavigation.currentTabMenu,
                actions: _vm.headerActions
              },
              on: {
                "action-ADD": function($event) {
                  return _vm.newCredit()
                }
              }
            })
          ]
        : _vm._e(),
      _c(
        "div",
        [
          _c("BaseFilter", {
            ref: "filter",
            attrs: {
              title: "Kreditefilter",
              filterId: "6f7d47af-1dd5-4700-a532-f3b65f9c4fe4",
              hasSmartSearch: "",
              showSaveButton: true,
              metadata: _vm.searchMenu,
              configFilter: _vm.configFilter,
              immidiateSearch: !_vm.isBroker && !_vm.isIntern
            },
            on: { onFilter: _vm.filterForm }
          })
        ],
        1
      ),
      _vm.filtered
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "c491e8bd-0178-4765-8fab-df73f7d3f5db" }
            },
            [
              _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      title: _vm.TABLE_TITLE,
                      tableId: "18ac3d80-ee79-4eb7-97bd-c27f0eb2332d",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      mobileConfig: _vm.mobileConfig,
                      hidePagination: ""
                    },
                    on: {
                      "click-form": _vm.editCredit,
                      "action-DELETE": _vm.deleteCredit
                    }
                  })
                : _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.TABLE_TITLE }
                  })
                : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }