<template>
  <PortfolioSelectorButton>{{ responsiveLabel }}</PortfolioSelectorButton>
</template>

<script>
import actionMixin from './header-actions-mixin.js';
import PortfolioSelectorButton from '@/components/depotpositions/PortfolioSelectorButton.vue';

export default {
  mixins: [actionMixin],
  components: {
    PortfolioSelectorButton,
  },
}
</script>
