<template>
  <div>
    <PageHeaderTitleNavigation :title="row.bezeichnung + ' abschließen'" />


    
    <template v-if="loading">
      <GhostLoading useBoxContainer>
        <Block height="130" />
      </GhostLoading>  
      <GhostLoading useBoxContainer>
        <Block type="title" />
        <Block height="250" />
      </GhostLoading>
    </template>
    <template v-else-if="pages.length">
      <HinweiseUndFehler v-if="hints" class="dicht" :hints="hints" />
      <div v-for="(page, index) in pages" :key="index">
        <BoxContainer :title="page.label">
          <ul v-html="page.text" class="ffb-consent-page-text"></ul>
          <InputToggleSwitch :label="'Bestätigung ' + page.label" :inLineLabel="true" :value="page.confirm" @input="chgConfirm(index, $event)"/>
        </BoxContainer>
        
      </div>
    </template>
    <div v-else class="box__container"><NoData /></div>
    <div class="box__container">
      <div class="tool-bar">
        <div><a class="mr-2" :href="row.videoidentlink1" :class="{'link-disabled': !isConfirmed}" target="videoidentlink1"
        >Videolegitimation Depotinhaber 1</a></div>
        <div><a v-if = "row.videoidentlink2" class="mr-2"  :href="row.videoidentlink2" :class="{'link-disabled': !isConfirmed}" target="videoidentlink2"
        >Videolegitimation Depotinhaber 2</a></div>
        <div :class="{'tool-disabled': !isConfirmed}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from '@/store/antrag/types';
import { mapGetters } from 'vuex'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BoxContainer from '@/components/core/BoxContainer.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import {sanitize} from '@/helpers/string-helper.js';


export default {
  components: {
    OptionMenu, PageHeaderTitleNavigation, BoxContainer, InputToggleSwitch, HinweiseUndFehler, NoData, GhostLoading, Block
  },
  mounted() {
    if ( this.$route.query?.ffbId) {
      this.ffbId = this.$route.query.ffbId;
      this.getFFBTexte();
    }
    
    
  },
  data: function () {
    return {
      ffbId: '',
      pages: [],
      loading: true,
      isConfirmed: false,
    }
  },
  computed: {
    ...mapGetters({
      videoIndent: ANTRAG_TYPES.GETTERS.GET_TODO_VIDEO_INDENT,
    }),
    row() {
      if (this.videoIndent?.rows?.length){
        const row = this.videoIndent.rows.filter(r => r.gesellschaft === 'FFB' && ('' + r.ffbId) === this.ffbId);
        if (row.length) {
          return row[0]
        }
      }
      return {};
    },
    hints() {
      const link = !this.row.videoidentlink2 ? '' : ' und auf "Videolegitimation Depotinhaber 2"';
      return [{
        title: 'Depoteröffnung abschließen',
        message: `<ul>
          <li>Wir sind gesetzlich verpflichtet, Sie zu identifizieren. Dazu bedienen wir uns der Videoidentifikation der Deutschen Post. Bitte halten Sie dazu Ihr Ausweisdokument bereit.</li>
          <li>Bitte lesen Sie und geben Sie Ihr Einverständnis für die folgenden Punkte.</li>
          <li>Klicken auf "Videolegitimation Depotinhaber 1"${link}.</li>
        </ul>`
      }]
    }
  },
  methods: {
    async getFFBTexte() {
      const response = await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.LOAD_FFB_DOCS_DATA, this.ffbId);
      this.pages = response.data.pages.map(page => ({...page, text: sanitize(page.text)}));
      this.loading = false;
    },
    chgConfirm(index, confirm) {
      this.pages[index].confirmed = confirm;
      this.isConfirmed =  this.pages.every(p => p.confirmed);
    },
  },
}
</script>

<style scoped>
.tool-bar {
  position: relative
}
.link-disabled {
  opacity: 0.4;
}
.tool-disabled {
  position:absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
}
</style>

<style>

.ffb-consent-page-text .portfolio-struct3, .ffb-consent-page-text .portfolio-struct {
  width: auto;
  text-align: left;
  border-spacing: 16px 1px;
  border-collapse: separate;
}
.ffb-consent-page-text .portfolio-struct3 th:nth-child(2), .ffb-consent-page-text .portfolio-struct3 td:nth-child(2) {
  padding-left: 1rem !important;
}

.ffb-consent-page-text .text-right {
  text-align: right;
}
.ffb-consent-page-text .broker-data td:nth-child(1)  {
  max-width: 12rem;
  font-weight: 500;
  padding-right: 10px;
}
.ffb-consent-page-text li  {
  padding-top: 8px;
}
.ffb-consent-page-text li:nth-child(1)  {
  padding-top: 0;
}

@media screen and (min-width: 769px) {
  .ffb-consent-page-text .portfolio-struct2 {
    display: none;
  }
  .ffb-consent-page-text .portfolio-struct td:nth-child(4) {
    text-align: right;
  }
  .ffb-consent-page-text .portfolio-struct th:nth-child(2),  .ffb-consent-page-text .portfolio-struct td:nth-child(2) {
    padding: 0 1rem !important;
  }
}
@media screen and (max-width: 768px) {
  .ffb-consent-page-text .portfolio-struct, .ffb-consent-page-text .portfolio-struct3 {
    display: none;
  }
  .ffb-consent-page-text .portfolio-struct2 td:nth-child(2) {
    padding-left: 1rem !important;
  }
}
</style>