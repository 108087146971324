var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: !_vm.isSmallScreen
        ? "box__container"
        : "box__container__smallscreen"
    },
    [
      _c("BaseCollapsable", {
        attrs: { showSection: _vm.showExpanded },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v(" Hinweise und Fehler ")
                ]),
                _vm.hasHinweis
                  ? _c("span", [
                      _vm._v(
                        " |  (" +
                          _vm._s(_vm.hinweise.length) +
                          " Hinweis" +
                          _vm._s(_vm.hinweise.length > 1 ? "e" : "") +
                          ")"
                      )
                    ])
                  : _vm._e(),
                _vm.hasWarnings
                  ? _c("span", { staticClass: "color-danger" }, [
                      _vm._v(" |  Das Formular enthält Fehler")
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                !_vm.hasHinweis && !_vm.hasWarnings
                  ? _c(
                      "div",
                      { staticClass: "antrag-sidetext__container mt-8px" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("label", { staticClass: "col-12" }, [
                            _vm._v(" Keine Hinweise vorhanden. ")
                          ])
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.hasHinweis || _vm.hasWarnings
                  ? _c(
                      "div",
                      { staticClass: "antrag-sidetext__container mt-8px" },
                      [
                        _vm.hasWarnings
                          ? [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("span", { staticClass: "font-bold" }, [
                                    _vm._v("Fehler:")
                                  ])
                                ])
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.warnings, function(warning, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "row" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12",
                                          class: {
                                            clickable: _vm.isWarningNavigable(
                                              warning
                                            ),
                                            "color-danger": true
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.navigateToStepWithWarning(
                                                warning
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("li", [
                                            _c(
                                              "span",
                                              { staticClass: "font-bold" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getWarningTitle(warning)
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getWarningMessage(warning)
                                                )
                                              )
                                            ]),
                                            _vm.isWarningNavigable(warning)
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("ph-pencil-line", {
                                                      attrs: { size: 16 }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          : _vm._e(),
                        _vm.hasHinweis
                          ? [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("span", { staticClass: "font-bold" }, [
                                    _vm._v("Hinweise:")
                                  ])
                                ])
                              ]),
                              _vm._l(_vm.hinweise, function(hinweis, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "row" },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      hinweis && hinweis.label
                                        ? _c(
                                            "span",
                                            { staticClass: "font-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(hinweis.label + " : ")
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(hinweis.text) + " ")
                                    ])
                                  ]
                                )
                              })
                            ]
                          : _vm._e(),
                        _vm.hasFinanceSummary
                          ? [_c("FinanceSummary")]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }