<template>
  <div>
    <div class="antrag-step__container">
      <Stepper3
        :steps="steps"
        :stepType="stepType"
        :visible="showStepper"
        :stepperName="stepperName"
        :selectedStepKey="routeStep"
        :selectedSubstepKey="routeSubStep"
        :stepperService="getStepperMediator"
        :antragData="antragData"
        @setStep="setStepOnMobile"
        @setSubstep="setSubstepOnMobile"
      ></Stepper3>
    </div>
  </div>
</template>

<script>
import { StepperMediator } from '@/components/stepper/utils';
import Stepper3 from '@/components/stepper/Stepper3.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';

export default {
  mixins: [antragNavigationMixin],
  props: {
    antragStep: {
    },
    antragData: {
    },
  },
  data() {
    return {
      stepType: 'step',
      showStepper: true,
      stepperName: 'name?',
    }
  },
  computed: {
    routeStep() {
      return this.$route.params.step || 
        // wertpapierorder
        this.$route.fullPath.lastIndexOf('/') > 0 && this.$route.fullPath.lastIndexOf('/') < this.$route.fullPath.length 
        && this.$route.fullPath.substring(this.$route.fullPath.lastIndexOf('/') + 1);
    },
    routeSubStep() {
      return this.$route.params.substep;
    },
    steps() {
      if (this.antragStep) {
        return this.antragStep
      }
      return null;
    },
    getStepperMediator() {
      if (this.steps) {
        return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
      }
      return null;
    },
  },
  components: {
    Stepper3
  },
  methods: {
    setStepByKey(stepKey) {
      this.navigateToStep(stepKey);
    },
    async setStepOnMobile(eventData) {
      this.$emit('setStep', eventData)
    },
    setSubstepOnMobile(eventData) {
      this.$emit('setSubstep', eventData)
    },    
  }
}
</script>

<style scoped>
  .antrag-step__container {
    border: none;
  }
</style>