var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _vm.title
            ? _c(
                "div",
                {
                  staticClass:
                    "input-forms__label-content input-forms__label-content--bigger"
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e()
        ])
      ])
    ]),
    _c("div", { staticClass: "input-forms__input-container" }, [
      _vm.value && _vm.value.length
        ? _c("div", [
            _c(
              "div",
              [
                _c("Table", {
                  attrs: {
                    tableId: "807a4783-84f1-4fe4-a265-4653aeaa4ec5",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowId: "index",
                    hidePagination: ""
                  },
                  on: {
                    "action-EDIT": function($event) {
                      return _vm.openPosition($event.index)
                    },
                    "action-DELETE": function($event) {
                      return _vm.deletePosition($event.index)
                    }
                  }
                })
              ],
              1
            )
          ])
        : _c("div", [_c("div", [_vm._v("Depotauswahl ist leer")])])
    ]),
    _c("hr"),
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("ComboBox", {
            attrs: { values: _vm.config.depots, firstEmpty: "" },
            model: {
              value: _vm.depot,
              callback: function($$v) {
                _vm.depot = $$v
              },
              expression: "depot"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "BaseButton",
            {
              attrs: { disabled: !_vm.depot },
              on: {
                click: function($event) {
                  return _vm.openNewPosition(_vm.depot)
                }
              }
            },
            [_vm._v("Formular für Depot erstellen")]
          )
        ],
        1
      )
    ]),
    _vm._m(1),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.openNewPosition()
                }
              }
            },
            [_vm._v("Formular erstellen")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_vm._v(" Übertrag eines Depots ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm._v(
          " Freies Übertragsformular (Hier können Sie alle Daten selbst eingeben.) "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }