import { render, staticRenderFns } from "./Kontakt.vue?vue&type=template&id=b6789008&scoped=true&"
import script from "./Kontakt.vue?vue&type=script&lang=js&"
export * from "./Kontakt.vue?vue&type=script&lang=js&"
import style0 from "./Kontakt.vue?vue&type=style&index=0&id=b6789008&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6789008",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b6789008')) {
      api.createRecord('b6789008', component.options)
    } else {
      api.reload('b6789008', component.options)
    }
    module.hot.accept("./Kontakt.vue?vue&type=template&id=b6789008&scoped=true&", function () {
      api.rerender('b6789008', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cms/template5/Kontakt.vue"
export default component.exports