import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const AUTHENTICATION_TYPES = {
  'AUTH-FACE': 'Gesichtserkennung',
  'AUTH-FINGERPRINT': 'Fingerabdruck',
  'AUTH-IRIS': 'Iris-Scanner',
  'TOUCH-ID': 'Touch ID',
  'FACE-ID': 'Face ID',
}

export const MOBILE_NATIVE_CONTEXT = {
  IOS: 'ios',
  ANDROID: 'android'
}

export const COLOR_THEME = {
  LIGHT: 'LIGHT', 
  DARK: 'DARK',
}

// bridge native stop call cause
export const STOP_CALL_CAUSE = {
  ANSWERED_ELSEWHERE: 'ANSWERED-ELSEWHERE',
  REJECTED: 'REJECTED',
  BUSY: 'Busy',
  CANCELED: 'CANCELED',
  HANG_UP_LOCAL: 'HANG-UP-LOCAL',
  HANG_UP_REMOTE: 'HANG-UP-REMOTE',
  MISSED: 'MISSED',
  RESTRICTED: 'RESTRICTED',
  ERROR: 'ERROR',
  UNKNOWN: 'UNKNOWN',
}

export const CloseExtraWebInstanceEvent = {
  name: 'close-extra-web-instance',
  dispatch() {
    const { name: eventName } = this;
    window.dispatchEvent(new CustomEvent(eventName));
  },
}

Object.freeze(AUTHENTICATION_TYPES)
Object.freeze(MOBILE_NATIVE_CONTEXT)
Object.freeze(COLOR_THEME)
Object.freeze(STOP_CALL_CAUSE)
Object.freeze(CloseExtraWebInstanceEvent);

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    loadedLoginData: {},
    hasLoadedLoginData: false,
    hasPasswordPreviouslySaved: false, 
    mobileNativeContext: null,
    clientCallId: null,
    mobileNativeSpec: null,
    virtualKeyboardDetected: false,
    receivedDbPrefix: null,
    showButtonSwitchAudioOutput: false,
    audioOutput: 'RECEIVER',
    biometricLoginSettings: {},
    deeplink: {
      ready: true,
      deferredPath: null,
    },
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}