<template>
<BaseModal
    ref="modal"
    labelButtonConfirm="Ok"
    labelButtonCancel="Abbrechen"
    autoClose
    @onConfirmButton="save"
    @close="close"
>
    <template v-slot:modalTitle>
        Datensatz bearbeiten
    </template>
    <div>
        <span class="info-header">Zeilennummer</span> {{line.id}}<br>
        <span class="info-header">Dateiname</span> {{line.fileName}}<br>
        <span class="info-header">Gesellschaft</span> {{line.gesellschaft}}<br>
        <span class="info-header">Interface Name</span> {{line.interfaceName}}<br>
        <br>
        <ComboBox
            label="Datensatz"
            v-model="use4Booking" 
            :values="use4BookingOptions"
            :disabled="!loaded"
        />
        <InputTextArea
            label="Text"
            v-model="text"
            :disabled="!loaded || textDisabled"
            autoGrow
        />
    </div>
</BaseModal>
</template>

<script>
import LOG_TYPES from '@/store/log/types';

import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";

export default {
    components: {
        BaseModal,
        ComboBox,
        InputTextArea,
    },
    props: {
        line: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            loaded: false,
            use4Booking: 1,
            use4BookingOptions: [
                {label: "aktiv", value: "1"},
                {label: "zurückgestellt", value: "0"},
                {label: "nicht berücksichtigt", value: "-1"},
            ],
            text: "",
        }
    },
    mounted() {
        axios.get(`${process.env.VUE_APP_API}/bipro_import/line_edit?id=${this.line.id}`)
        .then(response => {
            this.use4Booking = response.data.use4Booking;
            this.text = response.data.line;
            this.textDisabled = response.data.disabled;
            this.loaded = true;
            this.$refs.modal.open();
        });
    },
    methods: {
        save() {
            axios.post(`${process.env.VUE_APP_API}/bipro_import/line_edit?id=${this.line.id}`, {
                use4Booking: this.use4Booking,
                line: this.text,
            }).catch(e => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Der Datensatz konnte nicht gespeichert werden!', 'danger'));
            });
        },
        close() {
            this.$emit("close");
        },
    },
}
</script>

<style scoped>
.info-header {
    display: inline-block;
    width: 120px;
    font-weight: bold;
}
</style>
