var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { size: "full", showConfirmButton: false },
    on: {
      close: _vm.onClose,
      onConfirmButton: function($event) {
        return _vm.onConfirm()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass:
                  "options-menu-config-view-diff-modal--title-container clearfix"
              },
              [
                _c(
                  "span",
                  { staticClass: "options-menu-config-view-diff-modal--title" },
                  [_vm._v("Konfigurationen vergleichen")]
                ),
                _c("OptionsMenuConfigViewDiffHelpDropdown", {
                  staticClass:
                    "options-menu-config-view-diff-modal--help-button"
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _vm.isListsIdentical
              ? _c("div", { staticClass: "fc-alert fc-alert-primary" }, [
                  _vm._v(" Konfigurationen sind identisch ")
                ])
              : !_vm.diff.dest.length
              ? _c("div", { staticClass: "fc-alert" }, [
                  _vm._v(" Konfiguration nicht gefunden ")
                ])
              : _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
                  _vm._v(" Daten-Konflikt ")
                ]),
            _c("div", { staticClass: "compare-list" }, [
              _c("div", { staticClass: "source-list" }, [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Aktuelle Konfiguration")
                ]),
                _c("div", { staticClass: "compare-list--item" }, [
                  _c(
                    "ul",
                    { staticClass: "list list-bordered" },
                    _vm._l(_vm.diff.source, function(item) {
                      return _c(
                        "li",
                        {
                          key: item.id,
                          staticClass: "list-bordered-item",
                          class: _vm.itemClass(item),
                          attrs: { "data-item": item.id }
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.sanitize(item.label))
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm.diff.sourceToSelect.length > 0
                  ? _c("div", { staticClass: "compare-list--item" }, [
                      _c("div", { staticClass: "compare-list--item-title" }, [
                        _vm._v("ausgeblendet")
                      ]),
                      _c(
                        "ul",
                        { staticClass: "list list-bordered" },
                        _vm._l(_vm.diff.sourceToSelect, function(item) {
                          return _c(
                            "li",
                            {
                              key: item.id,
                              staticClass: "list-bordered-item",
                              class: ["status-" + item.status],
                              attrs: { "data-item": item.id }
                            },
                            [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.sanitize(item.label))
                                }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "dest-list" },
                [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v(
                      "Konfiguration (Nummer: " +
                        _vm._s(_vm.config.nummer) +
                        ")"
                    )
                  ]),
                  _vm.diff.dest.length > 0 || _vm.diff.destToSelect.length > 0
                    ? [
                        _c("div", { staticClass: "compare-list--item" }, [
                          _c(
                            "ul",
                            { staticClass: "list list-bordered" },
                            _vm._l(_vm.diff.dest, function(item) {
                              return _c(
                                "li",
                                {
                                  key: item.id,
                                  staticClass: "list-bordered-item",
                                  class: _vm.itemClass(item),
                                  attrs: { "data-item": item.id }
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.sanitize(item.label)
                                      )
                                    }
                                  })
                                ]
                              )
                            }),
                            0
                          )
                        ]),
                        _vm.diff.destToSelect.length > 0
                          ? _c("div", { staticClass: "compare-list--item" }, [
                              _c(
                                "div",
                                { staticClass: "compare-list--item-title" },
                                [_vm._v("ausgeblendet")]
                              ),
                              _c(
                                "ul",
                                { staticClass: "list list-bordered" },
                                _vm._l(_vm.diff.destToSelect, function(item) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.id,
                                      staticClass: "list-bordered-item",
                                      class: ["status-" + item.status],
                                      attrs: { "data-item": item.id }
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.sanitize(item.label)
                                          )
                                        }
                                      })
                                    ]
                                  )
                                }),
                                0
                              )
                            ])
                          : _vm._e()
                      ]
                    : _c("NoData", {
                        attrs: {
                          noIcon: "",
                          content: "Konfiguration nicht gefunden"
                        }
                      })
                ],
                2
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }