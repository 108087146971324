var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "box__container",
        attrs: { tid: "a822115c-09fd-4d06-981d-b935279f97df" }
      },
      [
        _c(
          "div",
          { staticClass: "layout__negative-margin--8" },
          [
            _vm.isKundeWechselInternVisible
              ? _c("InputToggleSwitch", {
                  attrs: { label: "Kunde hat Vertrag gekündigt zum" },
                  model: {
                    value: _vm.kundeWechselIntern.kuendigungErfolgt,
                    callback: function($$v) {
                      _vm.$set(_vm.kundeWechselIntern, "kuendigungErfolgt", $$v)
                    },
                    expression: "kundeWechselIntern.kuendigungErfolgt"
                  }
                })
              : _vm._e(),
            _vm.isKundeWechselInternVisible
              ? _c("DatePickerField", {
                  attrs: { isComponentHalfSize: "", isValueAsString: "" },
                  model: {
                    value: _vm.kundeWechselIntern.kuendigungDatum,
                    callback: function($$v) {
                      _vm.$set(_vm.kundeWechselIntern, "kuendigungDatum", $$v)
                    },
                    expression: "kundeWechselIntern.kuendigungDatum"
                  }
                })
              : _vm._e(),
            !_vm.pin.zugangFrei
              ? _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.saving },
                    on: {
                      click: function($event) {
                        return _vm.changeAccess(!_vm.pin.zugangFrei)
                      }
                    }
                  },
                  [_vm._v(" Zugang freischalten ")]
                )
              : _vm._e(),
            _vm.pin.zugangFrei
              ? _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: "", disabled: _vm.saving },
                    on: {
                      click: function($event) {
                        return _vm.changeAccess(!_vm.pin.zugangFrei)
                      }
                    }
                  },
                  [_vm._v(" Zugang sperren ")]
                )
              : _vm._e(),
            !_vm.pin.checkKundeInaktiv
              ? _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: "", disabled: _vm.saving },
                    on: {
                      click: function($event) {
                        return _vm.changeActive(!_vm.pin.checkKundeInaktiv)
                      }
                    }
                  },
                  [_vm._v(" Kunde auf inaktiv setzen ")]
                )
              : _vm._e(),
            _vm.pin.checkKundeInaktiv
              ? _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.saving },
                    on: {
                      click: function($event) {
                        return _vm.changeActive(!_vm.pin.checkKundeInaktiv)
                      }
                    }
                  },
                  [_vm._v(" Kunde auf aktiv setzen ")]
                )
              : _vm._e()
          ],
          1
        ),
        _c("AskForSendEmailModal", {
          ref: "askForSendEmailModal",
          attrs: { pin: _vm.pin }
        }),
        _c(
          "BaseModal",
          {
            ref: "activationLetterModal",
            attrs: { labelButtonConfirm: "Ja" },
            on: {
              onConfirmButton: function($event) {
                return _vm.generateActivationLetter()
              },
              close: function($event) {
                return _vm.closeActivationLetterModal()
              }
            }
          },
          [
            _vm._v(
              " Es wurde bereits ein Aktivierungsbrief erstellt, dessen Freischaltcode noch gültig ist. Wenn Sie einen Neuen erstellen, kann der Code aus dem alten Brief nicht mehr verwendet werden. Soll trotzdem ein neuer Brief mit neuem Code erstellt werden? "
            )
          ]
        )
      ],
      1
    ),
    _vm.usesKsc2 || _vm.showActivationLetterButton
      ? _c("div", { staticClass: "box__container" }, [
          _vm.usesKsc2
            ? _c(
                "div",
                [
                  _c("InputField", {
                    attrs: {
                      label: "Passwort",
                      isComponentHalfSize: true,
                      disabled: true
                    },
                    model: {
                      value: _vm.pin.kundenzugangPin,
                      callback: function($$v) {
                        _vm.$set(_vm.pin, "kundenzugangPin", $$v)
                      },
                      expression: "pin.kundenzugangPin"
                    }
                  }),
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        disabled: _vm.loadingNewPin,
                        tid: "157301cd-56ae-4512-8bbf-3e7c9ade5816"
                      },
                      on: {
                        click: function($event) {
                          return _vm.newPin()
                        }
                      }
                    },
                    [
                      _vm._v(" Neue PIN "),
                      _vm.loadingNewPin ? _c("AnimatedSpinner") : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.showActivationLetterButton
            ? _c(
                "div",
                [
                  _vm._v(" " + _vm._s(_vm.activationLetterDescription)),
                  _c("br"),
                  _c(
                    "BaseButton",
                    { on: { click: _vm.clickActivationLetterButton } },
                    [_vm._v(" Aktivierungsbrief erstellen ")]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }