<template>
  <ResizableModal 
    ref="resizablePopup" 
    :modalTitle="modalTitle" 
    :initialWidth="initialWidth"
    :initialHeight="initialHeight"
    :sizeIsPercentage="sizeIsPercentage"
    :startPosition="startPosition"
    :id="id"
    @onMinimize="resizeIMGContainer" 
    @onMaximize="resizeIMGContainer" 
    @onResize="resizeIMGContainer">
      <div class="object-container"  ref="objectContainer">
          <img v-if="imgUrl" :src="imgUrl"/>
       </div>
  </ResizableModal> 
</template>

<script>
import ResizableModal from '@/components/core/ResizableModal.vue';

export default {
name: 'PreviewPDF',
components: {
  ResizableModal,
},  
props: {
  modalTitle: {
    default: 'PDF-Vorschau'
  },
  initialHeight: {
    type: Number,
    default: 400, 
  },
  initialWidth: {
    type: Number,
    default: 600, 
  }, 
  imgUrl: {
    type: String,
  },
  sizeIsPercentage: {
    type: Boolean,
    default: false, 
  },
  startPosition: {
    type: String,
    default: 'TOP_LEFT',
    validator(value) {
      return ['TOP_RIGHT', 'TOP_LEFT', 'BOTTOM_RIGHT', 'BOTTOM_LEFT'].includes(value);
    }
  },
  id: {
    type: String,
    required: true,
    validator: (value) => {
      const notEmpty = !!value && !!value.trim();
      return notEmpty;
    },
  },                 
},  
data() {
  return { };
},

methods: {
  resizeIMGContainer(size) {
    let pdfContainer = document.querySelector('.object-container');
    pdfContainer.style.setProperty("min-height", `${size?.height - 90}px`, 'important');
  },
  showPopup() {
    this.$refs.resizablePopup.showPopup();
  },
},
};
</script>

<style scoped>

.object-container {
position: relative;
min-width: 100%;
min-height: 100vh;
width: 100%;
height: 100%;
}

.object-container img {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border: none;
padding: 0;
}
</style>
