
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import STOPPUHR_TYPES from "@/store/stoppuhr/types";
import dayjs from 'dayjs';

export default {
    data() {
        return {
            timer: {
              intervalId: null,
            },
          };
    },
    computed: {
        ...mapGetters({
            stoppuhr: STOPPUHR_TYPES.GETTERS.INIT_STOPPUHR,
            isBroker: CORE_TYPES.GETTERS.IS_BROKER,
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
            name: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
            stoppuhrHour: STOPPUHR_TYPES.GETTERS.STOPPUHR_HOUR,
            stoppuhrMinutes: STOPPUHR_TYPES.GETTERS.STOPPUHR_MINUTES,
            stoppuhSeconds: STOPPUHR_TYPES.GETTERS.STOPPUHR_SECONDS,
            stoppuhrForm: STOPPUHR_TYPES.GETTERS.STOPUHR_FORM,
            stoppuhrIntervalId: STOPPUHR_TYPES.GETTERS.INTERVAL_ID,
            isStoppuhrRunning: STOPPUHR_TYPES.GETTERS.IS_STOPPUHR_RUNNING,
            currentInterval: STOPPUHR_TYPES.GETTERS.CURRENT_INTERVAL,
            listInterval: STOPPUHR_TYPES.GETTERS.LIST_INTERVAL,
            
        }),
        displayingStoppuhrForm() {
          const stoppuhrFormRoute = `/communication/stoppuhr`;
          return this.$route.path.includes(stoppuhrFormRoute);
        },

    },
    methods: {
        startTimer() {
          if (this.isStoppuhrRunning) return;
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_STATE, {running: true});
          this.timer.intervalId = setInterval(() => {
            this.updatetimeLapsed()
          }, 500);

          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_INTERVAL_ID, {intervalId: this.timer.intervalId});

          let currentInterval = {
            begin: new Date(),
            end: null,
          };

          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_CURRENT_INTERVAL, currentInterval);
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.UPDATE_LIST_INTERVAL, currentInterval);

        },
        stopTimer() {
          if (!this.isStoppuhrRunning) return;
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_STATE, {running: false});

          let currentInterval = this.currentInterval;
          currentInterval.end = new Date();
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_CURRENT_INTERVAL, currentInterval);

          clearInterval(this.stoppuhrIntervalId);
          this.openStoppuhrForm();
        },
        getDeltaTime() {
          let clock = {};
          let diffInMilliseconds = 0;
          this.listInterval?.forEach(eachInterval => {
            if (eachInterval.begin && eachInterval.end) {

              const startDate = dayjs(eachInterval.begin);
              const endDate = dayjs(eachInterval.end);

              // Calculate the difference between the two dates
              diffInMilliseconds +=  endDate.diff(startDate);
             

            } else if (eachInterval.begin && !eachInterval.end) {
              const endTemporary = new Date();
        
              const startDate = dayjs(eachInterval.begin);
              const endDate = dayjs(endTemporary);

              // Calculate the difference between the two dates
              diffInMilliseconds += endDate.diff(startDate);
            }
          })

          clock = this.msToTime(diffInMilliseconds);

          return clock;
        },     
        msToTime(s) {

          var ms = s % 1000;
          s = (s - ms) / 1000;
          var secs = s % 60;
          s = (s - secs) / 60;
          var mins = s % 60;
          var hrs = (s - mins) / 60;

          return {
            hour: this.pad(hrs),
            mins: this.pad(mins),
            secs: this.pad(secs),
            ms: this.pad(ms, 3),
            formatedTime: this.pad(hrs) + ' : ' + this.pad(mins) + ' : ' + this.pad(secs)
          }


        },
        pad(n, z) {
          z = z || 2;
          return ('00' + n).slice(-z);
        },   
        saveForm(courtage = false) {
          let payload = {
            courtage: !!courtage
          }
          this.updatetimeLapsed();
          this.$store.dispatch(STOPPUHR_TYPES.ACTIONS.PERSIST, payload); 
        },
        updatetimeLapsed() {
          const deltaTime = this.getDeltaTime();
          
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_HOUR, {hour: deltaTime?.hour});
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_MINUTES, {minutes: deltaTime?.mins});
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_SECONDS, {seconds: deltaTime?.secs});
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_TIME_LAPSED, deltaTime.formatedTime);
    

        } ,
        resetStoppuhr() {
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_HOUR, {hour: "00"});
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_MINUTES, {minutes : "00"});
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_SECONDS, {seconds : "00"});
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_STATE, {running: false});
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.RESET_STOPUHR_FORM);
        },
        openStoppuhrForm() {
          const stoppuhrFormRoute = `/communication/stoppuhr`;

          if (!this.$route.path.includes(stoppuhrFormRoute) ) {
            this.$router.push(stoppuhrFormRoute);
          }
          
        },
        processFormChange(field, content) {
          this.$store.commit(STOPPUHR_TYPES.MUTATIONS.PROCESS_FORM_CHANGE, {field, content});
        }
    },
};
