var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "SchadenVereinigenModal",
          attrs: {
            modalTitle: "Schadensmeldungen vereinigen",
            labelButtonConfirm: "Vereinigen "
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            },
            onCloseButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [
                _vm._v("Hier können Sie zwei Schadensmeldungen vereinigen.")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("hr"),
              _c("p", [
                _c("span", { staticClass: "color-danger font-bold" }, [
                  _vm._v("ACHTUNG!")
                ])
              ]),
              _c("p", [
                _c("span", { staticClass: "color-danger font-bold" }, [
                  _vm._v(
                    'Vereinigte Schadensmeldungen können im Nachgang nicht mehr "getrennt" werden. Sind Sie sich sicher, dass Sie die Verträge vereinigen möchten?'
                  )
                ])
              ]),
              _c("hr")
            ])
          ]),
          _c("div", { staticClass: "main__container" }, [
            _c("div", { staticClass: "header" }, [
              _vm._v(" Quell-Schadensmeldung ")
            ]),
            _c("div", { staticClass: "control" }, [_vm._v("   ")]),
            _c("div", { staticClass: "header" }, [
              _vm._v(" Ziel-Schadensmeldungen ")
            ])
          ]),
          _vm.vertragList &&
          _vm.vertragList.length == 2 &&
          !_vm.changingPosition
            ? _c("div", { staticClass: "main__container" }, [
                _c("div", { staticClass: "item" }, [
                  _vm._v(" " + _vm._s(_vm.vertragList[0]["schadenId"]) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.bezechnug(_vm.vertragList[0])) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "clickable control" },
                  [
                    _c("ph-arrows-clockwise", {
                      attrs: { size: 16, weight: "bold" },
                      on: {
                        click: function($event) {
                          return _vm.changePositions()
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "item" }, [
                  _vm._v(" " + _vm._s(_vm.vertragList[1]["schadenId"]) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.bezechnug(_vm.vertragList[1])) + " ")
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }