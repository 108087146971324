import VUBLOCK_TYPES from './types';
import axios from 'axios';

const axiosSpinner = { defaultSpinner: true };

export default {

  async [VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK_ALL]({ rootState }) {
    return axios.get(rootState.core.apiAddress + `/vublock/getVublockAll`, axiosSpinner);
  },

  async [VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK]({ commit, rootState }, payload) {
    return axios.get(rootState.core.apiAddress + `/vublock/getVublock?id=${payload.id}`, axiosSpinner)
      .then(response => {
        if (response?.data) {
          commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_SELECTED, response.data);
        }
      });
  },

  async [VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK_GESELL_STATUS_ENTRIES]({rootState }) {
    return axios.get(rootState.core.apiAddress + `/vublock/getVublockStatusEntries`, axiosSpinner);      
  },
  
  async [VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK_GESELL]({ commit, rootState }, payload) {
    return axios.get(rootState.core.apiAddress + `/vublock/getVublockGesell?id=${payload.id}`, axiosSpinner)
      .then(response => {
        if (response?.data) {
          commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL, response.data);
        }
      });
  },

  async [VUBLOCK_TYPES.ACTIONS.DELETE_VUBLOCK]({ rootState }, payload) {
    return axios.delete(rootState.core.apiAddress + `/vublock/deleteVublock?id=${payload.id}`, axiosSpinner);
  },

  async [VUBLOCK_TYPES.ACTIONS.CREATE_VUBLOCK]({ rootState }) {
    return axios.post(rootState.core.apiAddress + `/vublock/createVublock`, axiosSpinner);
  },

  async [VUBLOCK_TYPES.ACTIONS.SAVE_VUBLOCK]({ rootState }, payload) {
    return axios.post(rootState.core.apiAddress + `/vublock/saveVublock`, {
      id: payload.id,
      mitRechtenUndPflichten: payload.mitRechtenUndPflichten
    }, axiosSpinner);
  },

  async [VUBLOCK_TYPES.ACTIONS.UPLOAD_VUBLOCK_FILE]({ commit, rootState }, payload) {
    return axios.post(rootState.core.apiAddress + `/vublock/saveFile?vuBlockId=${payload.vuBlockId}&type=${payload.type}`, payload.file, axiosSpinner)
      .then(response => {
        commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_FILE, { type: payload.type, data: response.data });
      });
  },

  async [VUBLOCK_TYPES.ACTIONS.DELETE_VUBLOCK_FILE]({ commit, rootState }, payload) {
    return axios.delete(rootState.core.apiAddress + `/vublock/deleteFile?vuBlockId=${payload.vuBlockId}&type=${payload.type}`, axiosSpinner)
      .then(() => {
        commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_FILE, { type: payload.type, data: {} });
      });
  },

  async [VUBLOCK_TYPES.ACTIONS.DELETE_VUBLOCK_GESELL_FILE]({ commit, rootState }, payload) {
    return axios.delete(rootState.core.apiAddress + `/vublock/deleteFileGesell?vubGesellId=${payload.vubGesellId}&fileId=${payload.fileId}`, axiosSpinner)
      .then((response) => {
        if (response?.data)
          commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL, response.data);
      });
  },

  async [VUBLOCK_TYPES.ACTIONS.SET_VUBLOCK_GESELL_FEHLER_ERLEDIGT]({ commit, rootState }, payload) {
    return axios.get(rootState.core.apiAddress + `/vublock/setGesellFehltFinish?vubGesellId=${payload.vuBlockGesellId}`, axiosSpinner)
      .then((response) => {
        if (response?.data)
          commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL, response.data);
      });
  },

  async [VUBLOCK_TYPES.ACTIONS.SAVE_VUBLOCK_GESELL_BEMERKUNG]({ commit, rootState }, payload) {
    return axios.post(rootState.core.apiAddress + `/vublock/saveGesellBemerkung?vubGesellId=${payload.vuBlockGesellId}`, payload.dataEditGesell, axiosSpinner)
      .then(response => {
        commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL, response.data);
      });
  },

  async [VUBLOCK_TYPES.ACTIONS.SAVE_VUBLOCK_GESELL_BEMERKUNG_FOR_BROKER]({ commit, rootState }, payload) {
    return axios.post(rootState.core.apiAddress + `/vublock/saveGesellBemerkungFuerMakler?vubGesellId=${payload.vuBlockGesellId}`, payload.dataEditGesell, axiosSpinner)
      .then(response => {
        commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL, response.data);
      });
  },

  async [VUBLOCK_TYPES.ACTIONS.VERS_UEBERTRAG_BLOCK_GESELL_MAIL_IS_SENT]({ commit, rootState }, payload) {
    return axios.get(rootState.core.apiAddress + `/vublock/setGesellMailSent?vubGesellId=${payload.vubGesellId}`, axiosSpinner)
      .then(response => {
        commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL, response.data);
      });
  },
}