<template>
  <div class="datepicker__selector-container" :class="{ 'disabled': disabled, }">
    <div class="datepicker__controllers">
      <button class="datepicker__controllers-item btn-clear" type="button" :disabled="disabled" @click="subtractPeriodReferenceDay()">
        <ph-caret-double-left :size="controlsSize" />
      </button>
      <button class="datepicker__controllers-month btn-clear" type="button" :disabled="disabled" @click="togglePeriodSelector()">
        {{currentMonthYear}} <ph-caret-down v-show="showDaySelector || showMonthSelector" :size="controlsSize" class="ml-1" />
      </button>
      <button class="datepicker__controllers-item btn-clear" type="button" :disabled="disabled" @click="addPeriodReferenceDay()">
        <ph-caret-double-right :size="controlsSize" />
      </button>
    </div>

    <div v-show="showDaySelector">
      <div class="datepicker__day-container">
        <div class="datepicker__week">
          <button v-for="day in daysName" :key="day" type="button" class="datepicker__day-unit btn-clear" :disabled="disabled">
            {{day}}
          </button>
        </div>
      </div>
      
      <div class="datepicker__day-container">
        <transition-group name="reference-transition">
        <div 
          v-for="(daysOfWeek, index) in daysOfMonthByWeek" :key="currentMonthYear+index"
          class="datepicker__week"
          :class="{
            'selectable': isHighlightWeek,
            'work-week': isHighlightWorkWeek,
            'no-work-week': !isHighlightWorkWeek,
            'datepicker__selected-week': isHighlightWeek && isWeekSelected(daysOfWeek),
          }"
        >
          <button 
            v-for="day in daysOfWeek" :key="day.date.unix()"
            type="button"
            :disabled="disabled"
            class="datepicker__day-unit selectable btn-clear"
            :class="{ 
              'text-muted': !day.isSameMonth,
              'work-day': day.isWorkDay,
              'datepicker__selected-day': !isHighlightWeek && isDateSelected(day.date, 'day') && day.isSameMonth,
              'is-today': day.isToday,
            }"
            @click="selectDay(day.date)"
          >
            <span class="datepicker__day-unit" :class="{
              'day-unit__in-range': isDayInBetweenSelectedRange(day.date) && day.isSameMonth,
              'day-unit__begin-range': isDayBeginOfSelectedRange(day.date) && day.isSameMonth,
              'day-unit__end-range': isDayEndOfSelectedRange(day.date) && day.isSameMonth
            }">
              {{day.day}}
            </span>
          </button>
        </div>
        </transition-group>
      </div>
    </div>

    <div v-show="showMonthSelector">
      <div 
        class="datepicker__month-container">
        <button
          type="button"
          class="datepicker__month-unit selectable btn-clear"
          :class="{ 
            'datepicker__selected-day': isDateSelected(month.date, 'month'),
            'is-current-month': isCurrentMonth(month.date),
          }"
          :disabled="disabled"
          @click="selectMonth(month.date)"
          v-for="month in monthsOfYear" :key="month.month">
          {{month.month}}
        </button>
      </div>
    </div>

    <div v-show="showYearSelector">
      <div 
        class="datepicker__month-container">
        <button
          type="button"
          class="datepicker__month-unit selectable btn-clear"
          :class="{ 
            'datepicker__selected-day': isDateSelected(year, 'year'),
            'is-current-year': isCurrentYear(year),
          }"
          :disabled="disabled"
          @click="selectYear(year)"
          v-for="year in years" :key="year">
          {{year}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PhCaretDoubleRight, PhCaretDoubleLeft, PhCaretDown, } from 'phosphor-vue'
import dayjs from 'dayjs'
import { isSame } from '@/helpers/date-helper'

const WEEK_SIZE_IN_DAYS = 7
const YEAR_SIZE_IN_DAYS = 12
const YEARS_ON_SELECTOR = 12

const WORK_DAYS = [ 'Mo', 'Di', 'Mi', 'Do', 'Fr', ];

export const PERIOD_SELECTORS = {
  DAY: 'day', 
  MONTH: 'month', 
  YEAR: 'year'
}

const WORK_WEEK = 'work-week'

export default {
  props: {
    controlsSize: {
      type: Number,
      default: 16,
    },
    monthPicker: {
      type: Boolean,
      default: false
    },
    isRangePicker: {
      type: Boolean,
      default: false,
    },
    highlightWeek: {
      type: [String, Boolean],
      default: false,
    },
    selectedRange: {

    },
    selectedDay: {

    },
    disabled: {
      type: Boolean,
      default: false
    },
    isSecondDateSelector: {
      type: Boolean,
      default: false
    },
    isFocused: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      currentPeriodSelector: null,
      referenceDay: null,
    }
  },
  watch: {
    selectedDay() {
      this.changeReferenceDay(this.getReferenceDay());
    },
    selectedRange: {
      handler() {
        this.changeReferenceDay(this.getReferenceDay());
      },
      deep: true,
    },
  },
  computed: {
    showDaySelector() {
      return !this.monthPicker && (!this.currentPeriodSelector || this.currentPeriodSelector === PERIOD_SELECTORS.DAY)
    },
    showMonthSelector() {
      return (!this.currentPeriodSelector && this.monthPicker) || this.currentPeriodSelector === PERIOD_SELECTORS.MONTH
    },
    showYearSelector() {
      return this.currentPeriodSelector === PERIOD_SELECTORS.YEAR
    },

    isHighlightWeek() {
      return !!this.highlightWeek;
    },
    isHighlightWorkWeek() {
      return this.highlightWeek === WORK_WEEK;
    },

    isWeekSelected() {
      return (daysOfWeek) => {
        const isSomeDayOfWeekSelected = daysOfWeek?.some(day => this.isDateSelected(day.date, 'day') && day.isSameMonth);
        return isSomeDayOfWeekSelected;
      };
    },
    isDateSelected() {
      return (date, period) => {
        if (period === 'year') {
          return this.isRangePicker 
            ? (date === this.selectedRange?.from?.year?.() || date === this.selectedRange?.to?.year?.()) 
            : date === this.selectedDay?.year?.()
        }

        if (this.isRangePicker) {
          return ((this.selectedRange.from && date?.isSame(this.selectedRange.from, period, 'day')) 
            || (this.selectedRange.to && date?.isSame(this.selectedRange.to, period, 'day')))
        } else {
          return this.selectedDay && date?.isSame(this.selectedDay, period, 'day')
        }
      }
    },
    isDayInBetweenSelectedRange() {
      return (date) => {
        if (!this.isRangePicker) {
          return
        }

        return date.isSameOrBefore(this.selectedRange.to, 'day') && date.isSameOrAfter(this.selectedRange.from, 'day')
      }
    },
    isDayBeginOfSelectedRange() {
      return (date) => {
        if (!this.isRangePicker) {
          return
        }

        return date.isSame(this.selectedRange.from, 'day')
      }
    },
    isDayEndOfSelectedRange() {
      return (date) => {
        if (!this.isRangePicker) {
          return
        }

        return date.isSame(this.selectedRange.to, 'day')
      }
    },
    isCurrentYear() {
      const currentDate = dayjs();
      return (value) => currentDate.year() === value;
    },
    isCurrentMonth() {
      const currentDate = dayjs();
      return (date) => currentDate.year() === date?.year() && currentDate.month() === date?.month();
    },
    
    currentMonthYear() {
      if (this.showDaySelector) {
        if (this.isRangePicker) {
          return this.referenceDay.format('MMMM YYYY')
        }
        return dayjs().isSame(this.referenceDay, 'year') ? this.referenceDay.format('MMMM') : this.referenceDay.format('MMMM YYYY')
      } else if (this.showMonthSelector) {
        return this.referenceDay.format('YYYY')
      } else if (this.showYearSelector) {
        return `${this.years[0]} - ${this.years[this.years.length - 1]}`
      }

      return ''
    },

    years() {
      const years = []
      const startDay = this.referenceDay.clone()

      const firstYear = startDay.subtract(Math.floor(YEARS_ON_SELECTOR / 2), 'year').year()
      const lastYear = firstYear + YEARS_ON_SELECTOR

      for (let i = firstYear; i < lastYear; i++) {
        years.push(i)
      }

      return years
    },
    
    daysOfMonth() {
      const days = []
      const startDate = this.referenceDay.clone()
      let baseDay = startDate.startOf('month').startOf('week')
      
      do {
        for (let i = 0; i < WEEK_SIZE_IN_DAYS; i++) {
          days.push({ 
            day: baseDay.date(), 
            date: baseDay.clone(),
            isSameMonth: baseDay.isSame(startDate, 'month'),
            isWorkDay: WORK_DAYS.includes(baseDay.format('dd')),
            isToday: baseDay.isToday(),
          })
          baseDay = baseDay.add(1, 'day')
        }
      } while (baseDay.isSameOrBefore(startDate, 'month'))
      
      return days 
    },

    daysOfMonthByWeek() {
      const { daysName, } = this;
      const daysCount = daysName.length;
      return this.daysOfMonth.reduce((result, day, index) => {
        if(result.length === 0 || index % daysCount === 0) {
          result.push([]);
        }

        const lastIndex = result.length - 1;
        result[lastIndex].push(day);
        return result;
      }, []);
    },

    monthsOfYear() {
      const months = []
      const startDate = this.referenceDay.clone()
      let baseDay = startDate.startOf('year')
      
      for (let i = 0; i < YEAR_SIZE_IN_DAYS; i++) {
        months.push({ 
          month: baseDay.format('MMM'), 
          date: baseDay.clone(),
        })
        baseDay = baseDay.add(1, 'month')
      }
      
      return months 
    },

    daysName() {
      const daysName = []
      const startDate = dayjs()
      let baseDay = startDate.startOf('week')

      for (let i = 0; i < WEEK_SIZE_IN_DAYS; i++) {
          daysName.push(baseDay.format('dd'))
          baseDay = baseDay.add(1, 'day')
        }

      return daysName
    },
  },
  methods: {
    getReferenceDay() {
      if (this.selectedDay) {
        if(this.isFocused) {
          return dayjs(this.selectedDay).utc()
        } else if(this.isSecondDateSelector) {
          return dayjs(this.selectedDay).add(1, 'month').utc()
        } else {
          return dayjs(this.selectedDay).subtract(1, 'month').utc()
        }
      }

      if (!this.isSecondDateSelector && this.selectedRange?.from) {
        return dayjs(this.selectedRange.from).utc()
      } else if(this.isSecondDateSelector) {
        if (!this.selectedRange?.to && this.selectedRange?.from) {
          return dayjs(this.selectedRange?.from).add(1, 'month').utc()
        } else if (this.selectedRange?.to) {
          const toDayjs = dayjs(this.selectedRange.to)
          return isSame(this.selectedRange?.from, this.selectedRange.to, 'month') 
            ? toDayjs.add(1, 'month').utc() 
            : toDayjs.utc()
        } else {
          return dayjs().add(1, 'month').utc()
        }
      }

      return dayjs().utc()
    },
    getParamsToChangePeriodReferenceDay() {
      if (this.showDaySelector) {
        return { amount: 1, period: 'month'}
      } else if (this.showMonthSelector) {
        return { amount: 1, period: 'year'}
      } else if (this.showYearSelector) {
        return { amount: YEARS_ON_SELECTOR, period: 'year'}
      }
    },
    addPeriodReferenceDay() {
      const params = this.getParamsToChangePeriodReferenceDay()
      this.changeReferenceDay(this.referenceDay.add(params.amount, params.period))
    },
    subtractPeriodReferenceDay() {
      const params = this.getParamsToChangePeriodReferenceDay()
      this.changeReferenceDay(this.referenceDay.subtract(params.amount, params.period))
    },

    selectDay(date) {
      const { selectedRange, isSecondDateSelector, } = this

      let range = undefined;
      if(isSecondDateSelector) {
        range = { from: selectedRange.from, to: date, }
      } else {
        range = { from: date, to: selectedRange.to }
      }

      const payload = {
        date,
        range,
      }

      this.$emit('onSelectDay', payload)
      setTimeout(() => this.changeReferenceDay(this.getReferenceDay()), 0)
    },

    selectMonth(date) {
      
      if (this.monthPicker) {
        this.changeReferenceDay(this.referenceDay.month(date.month()))
        this.$emit('onSelectMonth', this.referenceDay)
      } else {
        this.changePeriodSelector(PERIOD_SELECTORS.DAY)
        this.changeReferenceDay(this.referenceDay.month(date.month()))
      }
    },

    selectYear(year) {
      this.changePeriodSelector(PERIOD_SELECTORS.MONTH)
      this.changeReferenceDay(this.referenceDay.year(year))
    },

    changeReferenceDay(referenceDay, emit = true) {
      this.referenceDay = referenceDay;

      if(emit) {
        this.$emit('referenceDayChanged', {
          referenceDay: this.referenceDay,
          period: this.currentPeriodSelector,
        });
      }
    },
    changePeriodSelector(period) {
      this.currentPeriodSelector = period
    },
    togglePeriodSelector() {
      if (this.showDaySelector) {
        this.changePeriodSelector(PERIOD_SELECTORS.MONTH)
      } else if (this.showMonthSelector) {
        this.changePeriodSelector(PERIOD_SELECTORS.YEAR)
      }
    }
  },
  created() {
    this.changePeriodSelector(this.monthPicker ? PERIOD_SELECTORS.MONTH : PERIOD_SELECTORS.DAY);
    this.changeReferenceDay(this.getReferenceDay())
  },
  components: {
    PhCaretDoubleRight,
    PhCaretDoubleLeft,
    PhCaretDown,
  }
}
</script>



<style scoped>

.reference-transition-leave-active {
  display: none !important;
}

.datepicker__week {
  display: flex;
  flex-wrap: wrap;
  width: calc(34px * 7);
  user-select: none;
}

.datepicker__week.selectable {
  border-radius: 4px;
}

.datepicker__week.selectable.datepicker__selected-week {
  position: relative;
}

.datepicker__week.selectable.datepicker__selected-week::before {
  background-color: var(--color-primary);
  border-radius: 2px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.datepicker__week.selectable.datepicker__selected-week.work-week::before {
  width: calc(100% - 68px);
}

.datepicker__week.selectable.datepicker__selected-week .datepicker__day-unit {
  position: relative;
}

.datepicker__week.selectable.datepicker__selected-week.no-work-week .datepicker__day-unit,
.datepicker__week.selectable.datepicker__selected-week.work-week .work-day {
  color: var(--color-primary-text);
  font-weight: bold;
}

.datepicker__week.selectable.datepicker__selected-week.no-work-week .datepicker__day-unit:hover,
.datepicker__week.selectable.datepicker__selected-week.work-week .work-day:hover {
  color: var(--color-text);
}

.datepicker__month-container {
  display: flex;
  flex-wrap: wrap;
  width: calc(80px * 3);
}

.datepicker__day-unit {
  width: 34px;
  height: 34px;
  flex: 0 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.datepicker__month-unit {
  width: 80px;
  height: 44px;
  flex: 0 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: none;
}

.datepicker__day-unit.selectable,
.datepicker__month-unit.selectable {
  cursor: pointer;
}

.day-unit__in-range {
  background-color: var(--color-background);
}

.datepicker__day-unit.selectable:hover,
.datepicker__month-unit.selectable:hover {
  background-color: var(--color-background);
  font-weight: bold;
  border-radius: 18px;
  color: var(--color-text);
}

.is-current-year,
.is-current-month,
.is-today {
  color: var(--color-danger);
  font-weight: bold;
}

.datepicker__day-unit.selectable:hover.datepicker__selected-day,
.datepicker__selected-day {
  font-weight: bold;
  border-radius: 18px;
  background-color: var(--color-primary);
  color: var(--color-primary-text);
}

.datepicker__container {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.datepicker__controllers {
  display: flex;
  height: 34px;
  justify-content: space-between;
}

.datepicker__selector-container {
  user-select: none;
}

.datepicker__controllers-item {
  width: 34px;
  height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  flex: 0 0 auto;
}

.datepicker__controllers-month {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  cursor: pointer;
}

.datepicker__preset-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-muted {
  color: #aaaaaa;
}

.day-unit__begin-range { 
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.day-unit__end-range {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.datepicker__selector-container.disabled .selectable {
  cursor: not-allowed;
}
.datepicker__selector-container.disabled .selectable:not(.datepicker__selected-day) {
  background: none;
  font-weight: normal;
}

@media (max-width: 480px) {
  .datepicker__container {
    flex-direction: column;
  }
}

</style>