var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _vm.isEmpty
      ? _c("div", [_vm._v("Keine zusatzliche Daten erforderlich")])
      : _c(
          "div",
          [
            _c("ComboBox", {
              attrs: {
                title: "Geschäftstyp",
                values: _vm.geschaeftTypValues,
                disabled:
                  _vm.readOnly ||
                  (_vm.form.INPUT_FODB_MIX_BERATUNGVERMITTLUNG &&
                    _vm.form.INPUT_FODB_MIX_BERATUNGVERMITTLUNG.length > 0)
              },
              on: {
                change: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_FODB_MIX_BERATUNGVERMITTLUNG",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_FODB_MIX_BERATUNGVERMITTLUNG,
                callback: function($$v) {
                  _vm.$set(_vm.form, "INPUT_FODB_MIX_BERATUNGVERMITTLUNG", $$v)
                },
                expression: "form.INPUT_FODB_MIX_BERATUNGVERMITTLUNG"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [_c("FormLabel", { attrs: { label: "Hinweis" } })],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [_c("FormLabel", { attrs: { label: _vm.hinweisText } })],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 my-3" },
              [_c("FormHeader", { attrs: { label: "Kauf und Sparplan" } })],
              1
            ),
            _c("InputRadioBoxGroup", {
              attrs: {
                validateUntouched: true,
                values: _vm.kaufKeuerValues,
                disabled: _vm.readOnly
              },
              on: {
                input: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_FODB_MIX_KAUF_KEUER",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_FODB_MIX_KAUF_KEUER,
                callback: function($$v) {
                  _vm.$set(_vm.form, "INPUT_FODB_MIX_KAUF_KEUER", $$v)
                },
                expression: "form.INPUT_FODB_MIX_KAUF_KEUER"
              }
            }),
            _c("InputRadioBoxGroup", {
              attrs: {
                title:
                  "Unzureichende Information zur Angemessenheit/Unangemessenheit (nur bei Bedarf auszufüllen)",
                validateUntouched: true,
                values: _vm.kaufAngemessenValues,
                disabled: _vm.readOnly
              },
              on: {
                input: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_FODB_MIX_KAUF_ANGEMESSEN",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_FODB_MIX_KAUF_ANGEMESSEN,
                callback: function($$v) {
                  _vm.$set(_vm.form, "INPUT_FODB_MIX_KAUF_ANGEMESSEN", $$v)
                },
                expression: "form.INPUT_FODB_MIX_KAUF_ANGEMESSEN"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [_c("FormLabel", { attrs: { label: "nur Anlageberatung" } })],
              1
            ),
            _c("InputToggleSwitch", {
              attrs: {
                inLineLabel: "",
                label: _vm.labelKaufBeratung,
                disabled:
                  _vm.readOnly ||
                  (_vm.data &&
                    _vm.data.INPUT_FODB_MIX_BERATUNGVERMITTLUNG !==
                      "VALUE_FODB_MIX_TYP_BERATUNG")
              },
              on: {
                input: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_FODB_MIX_KAUF_BERATUNG",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_FODB_MIX_KAUF_BERATUNG,
                callback: function($$v) {
                  _vm.$set(_vm.form, "INPUT_FODB_MIX_KAUF_BERATUNG", $$v)
                },
                expression: "form.INPUT_FODB_MIX_KAUF_BERATUNG"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [_c("FormLabel", { attrs: { label: "nur Anlagevermittlung" } })],
              1
            ),
            _c("InputToggleSwitch", {
              attrs: {
                label: _vm.labelKaufVerm,
                inLineLabel: "",
                disabled:
                  _vm.readOnly ||
                  (_vm.data &&
                    _vm.data.INPUT_FODB_MIX_BERATUNGVERMITTLUNG !==
                      "VALUE_FODB_MIX_TYP_VERMITTLUNG")
              },
              on: {
                input: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_FODB_MIX_KAUF_VERMITTLUNG",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_FODB_MIX_KAUF_VERMITTLUNG,
                callback: function($$v) {
                  _vm.$set(_vm.form, "INPUT_FODB_MIX_KAUF_VERMITTLUNG", $$v)
                },
                expression: "form.INPUT_FODB_MIX_KAUF_VERMITTLUNG"
              }
            }),
            _c(
              "div",
              [
                _c("FormHeader", {
                  attrs: {
                    label:
                      "Ich/Wir wurde/n über die Möglichkeit informiert, die konstenlose Aushändigung der Basisinformationsblätter in Papierform verlangen zu können. Diese habe/n ich/wir rechtzeitig wie folgt erhalten:"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "Das/Die Basisinformationsblatt/-blätter zu der/zu den erwerbenden ISIN / Fonds wurde/n mir/uns in Papierform übergeben.",
                    disabled: _vm.readOnly
                  },
                  on: {
                    input: function($event) {
                      return _vm.updateStoreData({
                        componentId: "INPUT_CHK_PAPIER",
                        value: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.form.INPUT_CHK_PAPIER,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "INPUT_CHK_PAPIER", $$v)
                    },
                    expression: "form.INPUT_CHK_PAPIER"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "Das/Die Basisinformationsblatt/-blätter zu der/zu den erwerbenden ISIN / Fonds wurde/n mir/uns auf meine/unsere E-Mail-Addresse zur Verfügung gestellt.",
                    disabled: _vm.readOnly
                  },
                  on: {
                    input: function($event) {
                      return _vm.updateStoreData({
                        componentId: "INPUT_CHK_EMAIL",
                        value: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.form.INPUT_CHK_EMAIL,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "INPUT_CHK_EMAIL", $$v)
                    },
                    expression: "form.INPUT_CHK_EMAIL"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "Das/Die Basisinformationsblatt/-blätter zu der/zu den erwerbenden ISIN / Fonds wurde/n mir/uns in digitaler Form über eine Webseite zur Verfügung gestellt. Ich/Wir habe/n die Möglichkeit des Einsehens und/oder Downloads dieser Unterlagen/Informationen genutzt.",
                    disabled: _vm.readOnly
                  },
                  on: {
                    input: function($event) {
                      return _vm.updateStoreData({
                        componentId: "INPUT_CHK_WEB",
                        value: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.form.INPUT_CHK_WEB,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "INPUT_CHK_WEB", $$v)
                    },
                    expression: "form.INPUT_CHK_WEB"
                  }
                })
              ],
              1
            ),
            _c("FormHeader", { attrs: { label: "Verkauf und Entnahmeplan" } }),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [_c("FormLabel", { attrs: { label: "Anlageberatung" } })],
              1
            ),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Dieser Auftrag basiert auf einer Beratung, die der Berater/Vermittler der Fondsdepot Bank in eigenem Namen und auf eigene Rechnung gegenüber dem Anleger erbringt. Dieser Berater/Vermittler ist für die Durchführung der Geeignetheitsprüfung und -dokumentation verantwortlich.",
                inLineLabel: "",
                disabled:
                  _vm.readOnly ||
                  _vm.isVerkaufDisabled("VALUE_FODB_MIX_TYP_BERATUNG")
              },
              on: {
                input: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_FODB_MIX_VERKAUF_BERATUNG",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_FODB_MIX_VERKAUF_BERATUNG,
                callback: function($$v) {
                  _vm.$set(_vm.form, "INPUT_FODB_MIX_VERKAUF_BERATUNG", $$v)
                },
                expression: "form.INPUT_FODB_MIX_VERKAUF_BERATUNG"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [_c("FormLabel", { attrs: { label: "Anlagevermittlung" } })],
              1
            ),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Im Rahmen dieser Vermittlung wird weder durch den Berater/Vermittler noch die depotführende Stelle eine Beratungsleistung erbracht.",
                inLineLabel: "",
                disabled:
                  _vm.readOnly ||
                  _vm.isVerkaufDisabled("VALUE_FODB_MIX_TYP_VERMITTLUNG")
              },
              on: {
                input: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_FODB_MIX_VERKAUF_VERMITTLUNG",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_FODB_MIX_VERKAUF_VERMITTLUNG,
                callback: function($$v) {
                  _vm.$set(_vm.form, "INPUT_FODB_MIX_VERKAUF_VERMITTLUNG", $$v)
                },
                expression: "form.INPUT_FODB_MIX_VERKAUF_VERMITTLUNG"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }