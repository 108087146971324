import EINGEGANGENE_DOKUMENTE_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue'

export default {
  [EINGEGANGENE_DOKUMENTE_TYPES.MUTATIONS.UPDATE_EINGEGANGENE_DOKUMENTE](state, data) {
    Vue.set(state, 'eingegangeneDokumente', data);
  }, 
  [EINGEGANGENE_DOKUMENTE_TYPES.MUTATIONS.UPDATE_EINGEGANGENES_DOKUMENT_RESULT](state, data) {
    Vue.set(state, 'eingegangenesDokumentResult', data);
  }, 
  [EINGEGANGENE_DOKUMENTE_TYPES.MUTATIONS.UPDATE_SIGNATURES](state, data) {
    Vue.set(state, 'signatures', data);
  },
}