var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "774.13137",
        height: "669.89848",
        viewBox: "0 0 774.13137 669.89848",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M824.24088,170.37071c-.87-.58-1.75-1.13-2.62-1.69a380.24064,380.24064,0,0,0-80.31-38.93l-6.54,90.22h-41.46v-103.39c-2.61-.54-5.24-1.05-7.87-1.53v104.92H636.17077A102.26991,102.26991,0,0,1,533.90086,117.70082v-.0001c-2.64.57-5.26,1.18-7.88,1.83v100.44h-40.13l-6.23-86.03a380.85838,380.85838,0,0,0-81.94,43.53c-.44.3-.88.61-1.31.91-2.21,1.55-4.39,3.13-6.56,4.73005v344.19h118.27l-2.09-28.95-11.5-158.95h231.59l-11.5,158.95-2.09,28.95h116.96v-353.45Q826.88081,172.08075,824.24088,170.37071Zm-298.22,142.78h-33.39l-6.17-85.3h39.56Zm159.42,0h-56.08v-1.69a6.17976,6.17976,0,0,0-6.19-6.18h-24.38a6.17757,6.17757,0,0,0-6.18,6.18v1.69h-58.71v-85.3h151.54Zm7.87,0v-85.3h40.89l-6.17,85.3Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M952.68408,740.6l.78168-17.57605a56.76523,56.76523,0,0,1,26.47721-6.7024c-12.71655,10.39672-11.12736,30.438-19.74889,44.41914a34.14439,34.14439,0,0,1-25.03846,15.77861L924.514,783.03481a57.2153,57.2153,0,0,1,12.05952-46.36369,55.26678,55.26678,0,0,1,10.157-9.43749C949.27916,733.954,952.68408,740.6,952.68408,740.6Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M381.73337,721.33965l-25.68044-7.775a86.5724,86.5724,0,0,1,.06719-41.65384c10.57528,22.709,40.792,27.90878,58.20681,45.916a52.07345,52.07345,0,0,1,13.888,42.94605l5.621,18.18083A87.25862,87.25862,0,0,1,369.85924,743.669a84.28671,84.28671,0,0,1-10.12172-18.565C370.62924,723.86851,381.73337,721.33965,381.73337,721.33965Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M932.70084,780.16074a2.038,2.038,0,0,1-.59,1.42,1.98779,1.98779,0,0,1-1.41.58h-8.04a1.9931,1.9931,0,0,1-2-2v-243.14c0-5.36-1.8-9.71-4.01-9.72h-602.49c-2.22.01-4.01,4.36-4.02,9.72v241.65a2.00629,2.00629,0,0,1-1.81,1.99c-1.62.16-3.24.3-4.85.43-.04,0-.08.01-.12.01-.88.08-1.76.13995-2.64.21h-.01c-.46-.83-.9-1.68-1.35-2.53a2.0878,2.0878,0,0,1-.22-.93v-253.44c0-1.48.06-2.97.16-4.44a56.85378,56.85378,0,0,1,.75-6.02c1.69-9.29,5.51-15.58,9.85-15.6h611a4.683,4.683,0,0,1,1.84.38995,7.174,7.174,0,0,1,2.27,1.59,14.07149,14.07149,0,0,1,2.23,3.05c2.14,3.77,3.67,9.39,4.21,15.93,0,.06.01.12.01.18.14,1.62006.21,3.27.2,4.92l.14,21.43.09,12.78.27,40.1.1,14.24Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M985.875,784.64191l-275.75.30733a1.19069,1.19069,0,0,1,0-2.38137l275.75-.30733a1.19069,1.19069,0,0,1,0,2.38137Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#cacaca"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M643.60243,427.312h-48.8382a2.60579,2.60579,0,0,0-2.60184,2.60856v77.15H646.211v-77.15A2.60719,2.60719,0,0,0,643.60243,427.312Zm-24.25479,43.83567a5.794,5.794,0,0,1-5.767-5.76695v-8.89858a5.767,5.767,0,0,1,11.534,0v8.89858a5.794,5.794,0,0,1-5.767,5.76695Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M724.4741,306.91074H515.70984a6.77694,6.77694,0,0,0-6.76615,6.76611v140.875a6.77263,6.77263,0,0,0,6.76615,6.76611H724.4741a6.77263,6.77263,0,0,0,6.76615-6.76611v-140.875A6.77694,6.77694,0,0,0,724.4741,306.91074Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M722.728,311.79652H517.45046a3.62642,3.62642,0,0,0-3.61851,3.626V452.80584a3.62478,3.62478,0,0,0,3.61851,3.61851H722.728a3.62478,3.62478,0,0,0,3.61851-3.61851V315.42256A3.62642,3.62642,0,0,0,722.728,311.79652Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M591.99474,504.6028v7.44344a1.45814,1.45814,0,0,0,1.45518,1.45514h51.47357a1.46248,1.46248,0,0,0,1.45514-1.45514V504.6028Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M682.74694,516.09149H560.618a2.24664,2.24664,0,0,1-2.19846-2.70942l1.895-9.0003a2.25634,2.25634,0,0,1,2.1981-1.78375H680.85226a2.25633,2.25633,0,0,1,2.19809,1.78375l1.89506,9.0003a2.24664,2.24664,0,0,1-2.19847,2.70942Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "350.44566",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "357.96856",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "365.49147",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "373.01437",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "380.53728",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "388.06018",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "395.58308",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "403.10599",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "410.62889",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "418.1518",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "425.6747",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "433.19761",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "440.72051",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "448.24341",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "455.76632",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "463.28922",
          y: "389.16791",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "350.35677",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "357.87967",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "365.40257",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "372.92548",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "380.44838",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "387.97129",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "395.49419",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "403.0171",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "410.54",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "418.0629",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "425.58581",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "433.10871",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "440.63162",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "448.15452",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "455.67743",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "463.20033",
          y: "392.92937",
          width: "3.76145",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "386.46671",
          y: "397.44311",
          width: "30.09162",
          height: "2.25687",
          rx: "0.48819",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M712.41257,330.72615H520.33525a.764.764,0,0,1,0-1.52767H712.41257a.764.764,0,0,1,0,1.52767Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#cacaca"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "320.47588",
          cy: "205.67152",
          rx: "4.47645",
          ry: "4.57621",
          fill: "#3f3d56"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "335.93998",
          cy: "205.67152",
          rx: "4.47645",
          ry: "4.57621",
          fill: "#3f3d56"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "351.40408",
          cy: "205.67152",
          rx: "4.47645",
          ry: "4.57621",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M703.10187,317.28032H692.117a.83192.83192,0,0,0,0,1.66351h10.98489a.83192.83192,0,0,0,0-1.66351Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M703.10187,320.40247H692.117a.83192.83192,0,0,0,0,1.66351h10.98489a.83192.83192,0,0,0,0-1.66351Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M703.10187,323.52052H692.117a.83192.83192,0,0,0,0,1.66351h10.98489a.83192.83192,0,0,0,0-1.66351Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "391.51776",
          y: "260.29965",
          width: "30.52939",
          height: "6.52835",
          rx: "3.26417",
          fill: _vm.color
        }
      }),
      _c("rect", {
        attrs: {
          x: "376.01293",
          y: "273.35635",
          width: "61.53906",
          height: "6.52835",
          rx: "3.26417",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "376.01293",
          y: "286.41305",
          width: "61.53906",
          height: "6.52835",
          rx: "3.26417",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M522.21085,525.91074h-60a8,8,0,1,0,0,16h22v62h16v-62h22a8,8,0,1,0,0-16Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M561.81193,638.24766H401.329a64.80541,64.80541,0,0,1-63.91651-55.63574l-5.68067-20.89566a469.07705,469.07705,0,0,0-53.18135-122.90313c-10.74732-17.45376-16.27894-34.34887-3.71483-50.61525q.16047-.20776.31562-.40969c10.07715-13.11622,60.23438-15.95557,76.58545-16.50782q.969-.033,1.94531-.03271h99.81885q.97705,0,1.94483.03271a48.84663,48.84663,0,0,1,42.23632,26.9209,50.33121,50.33121,0,0,1-2.85595,51.52979c-35.06348,52.6709-45.79883,85.69726-34.80811,107.085,10.63135,20.6875,43.22022,31.26465,102.5586,33.28515,15.94629.543,23.26464,12.96,23.31591,24.26661.04981,11.01464-6.98632,23.17578-22.55273,23.84765Q562.581,638.24767,561.81193,638.24766Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M428.59155,780.74756h60a8,8,0,0,0,0-16h-22v-135h-16v135h-22a8,8,0,1,0,0,16Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M423.79975,376.2586l1.25772,7.643c.595,3.706,2.74964,29.88575,1.15338,33.00913-.14068-5.83148-11-15-18.03619-12.90658l-11.04232,2.45342-6.85346,1.52484-5.57807,1.22307-16.2091,3.54126c-.02828-.18816-.04881-.38805-.0771-.5762-.03831-.23719-.07662-.47431-.12473-.70943a14.09051,14.09051,0,0,0-1.62929-4.32109,10.78041,10.78041,0,0,1-1.54249,5.08237.86887.86887,0,0,0-.06608.12585,12.734,12.734,0,0,1-.95113,1.33782.21693.21693,0,0,0-.043.03938s-3.33049-53.09-2.98161-53.5799c.25537-.3483.51275-.68674.78194-1.01744.26719-.34044.52658-.66913.80551-1.00182a.43524.43524,0,0,0,.08972-.11019,35.99747,35.99747,0,0,1,2.47894-2.65079c.25581-.24628.52143-.49457.787-.74286a36.94156,36.94156,0,0,1,14.17836-8.17831c.59539-.18307,1.21439-.35053,1.84314-.52006.621-.15766,1.25383-.30758,1.8906-.43788l.21556-.04411c.30371-.06214.61922-.11649.92494-.16882.384-.06837.77014-.127,1.14845-.17376a37.23742,37.23742,0,0,1,14.71449.827c.00981-.002.01181.0078.02162.00579a37.94319,37.94319,0,0,1,11.27552,5.02155c5.98346,3.95072,8.31252,10.39459,9.96652,17.62988C422.77845,371.068,423.2665,373.65257,423.79975,376.2586Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "269.963 611.271 280.299 616.886 307.661 579.054 292.405 570.768 269.963 611.271",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M487.87263,712.8416l18.764,5.87566-2.98659,7.861L516.51682,745.253a5.25274,5.25274,0,0,1-6.19089,7.891l-21.84388-8.299-.81093-9.20623-4.42428,7.21725-8.236-3.129Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M541.44384,557.1108a11.61436,11.61436,0,0,1-14.33957-10.5616l-88.16145-31.7164,16.17957-14.07964,83.67774,33.3169a11.67734,11.67734,0,0,1,2.64371,23.04074Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M414.31435,436.26007s-14.84842.3272-16.16854,17.279,31.07893,58.88191,36.09866,65.7965,60.77675,15.42525,60.77675,15.42525l18.954-13.76c-19.91614-12.905-38.0922-22.80011-50.29029-22.355Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M396.10358,511.05773l3.61818,15.54613s.03555,14.3349,2.57427,16.978c2.521,2.6308,57.95539-2.6514,57.95539-2.6514s-5.87747-23.0997-5.79928-40.57841Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M513.21085,709.91074l-20-11c-.3804-26.02478,15.27314-61.24347,33.83954-98.13244-41.523,7.14129-80.69613,9.429-114.57918.79013a21.90127,21.90127,0,0,1-15.66277-26.4577q.19782-.8223.4575-1.62714c4.05516-12.37247-5.03763-20.128,3.32559-33.73605,21.9964-7.51227,42.89209-9.63367,62-3L474.564,563.21891l59.34251-7.6791a21.8818,21.8818,0,0,1,24.34359,19.29367q.11962.96627.1563,1.94042C546.40726,633.02659,532.95267,683.64243,513.21085,709.91074Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "393.87913",
          cy: "379.32884",
          r: "24.8051",
          transform: "translate(-280.98129 -28.39138) rotate(-11.5642)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M388.04818,412.63474l22.861-1.34982a4.64105,4.64105,0,0,1,4.56391,2.8632l6.46045,15.66164,26.85015,22.19208s10.63071,13.528,10.65544,30.9991,3.77169,57.9098,3.77169,57.9098-15.515-21.83866-25.09122-16.03023c-1.62919.98818-2.20224,3.009-3.90878,4.03023-.85124.50941-2.81783.01422-3.67748.52454-13.19586,7.83347-26.95778,15.48647-28.24366,14.14458-2.5247-2.63467-5.58735-16.09761-5.58735-16.09761l-20.92619-76.88719a28.47663,28.47663,0,0,1,4.04936-23.96177c1.46194-2.05831,2.99367-3.58959,4.41714-3.84286C387.43879,422.10991,385.73869,414.94653,388.04818,412.63474Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "369.583 643.84 379.233 637.131 357.372 595.887 343.13 605.789 369.583 643.84",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M583.97591,731.21686l-15.12358,13.51825C544.80455,717.93945,526.625,680.35933,511.2189,637.6824c-40.36583-11.8222-70.807-30.88836-97.38186-53.5265-9.01533-7.6285-15.77354-17.42855-8.08468-26.7212q.53917-.65162,1.126-1.2603c9.07832-9.33,32.10064-7.552,45.58458-16.09964l30.12137,32.19638-1.89466,8.63161,56.5772,19.1649a21.85218,21.85218,0,0,1,13.33557,28.01715q-.31785.92028-.71351,1.81124Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M576.891,756.17082l15.44747-12.16493,4.79986,6.90485,22.678-.069a5.25274,5.25274,0,0,1,3.01458,9.56592L603.644,773.74523l-8.05494-4.531,3.45652,7.72758-7.23422,5.02881Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M421.80643,606.40346a11.61441,11.61441,0,0,1-5.19028-17.03619l-51.75429-78.10162,21.38625-1.62572,47.20245,76.70663a11.67734,11.67734,0,0,1-11.64413,20.0569Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M392.0494,433.54131s-12.10319-8.60787-23.28869,4.19806-10.25152,65.78667-10.35662,74.33058c-.03292,2.67541,3.83042,8.44836,9.15311,15.11659,2.38959,2.99366,5.07331,6.16777,7.83066,9.323,1.59565,1.82593.22076,5.62645,1.823,7.40115,1.59344,1.765,6.16418,1.50458,7.67984,3.14269,7.19414,7.77543,13.0386,13.69413,13.0386,13.69413l23.42024.288c.26422-17.41016-11.65851-32.95068-26.97577-47.97046Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M424.88167,367.27942c-.91777.4532-1.80819.89058-2.683,1.30433a83.13946,83.13946,0,0,1-10.8551,4.41576,13.66562,13.66562,0,0,0-2.7776-4.54547,10.86019,10.86019,0,0,1-.46033,5.43255,30.70286,30.70286,0,0,1-17.03773-.65784c3.59893,6.6638,6.25792,20.70585,6.06444,33.22883-.08438,6.62136-.9806,12.81674-2.93769,17.26949l-2.51486-7.65117a47.65706,47.65706,0,0,0-1.40091-8.09348c-1.6863-7.443-3.26064-2.46642-5.57807,1.22307l-16.2091,3.54126c-.02828-.18816-.04881-.38805-.0771-.5762-.03831-.23719-.07662-.47431-.12473-.70943a14.09051,14.09051,0,0,0-1.62929-4.32109,10.78041,10.78041,0,0,1-1.54249,5.08237.86887.86887,0,0,0-.06608.12585,12.734,12.734,0,0,1-.95113,1.33782.21693.21693,0,0,0-.043.03938c-10.46491,1.40035-17.801-52.566-2.97962-53.57015.43088-.0292.51277-.68668.77995-1.02719.26719-.34044.52658-.66913.80551-1.00182a.43524.43524,0,0,0,.08972-.11019c.79218-.91748,1.61195-1.79967,2.47894-2.65079.25581-.24628.52143-.49457.787-.74286a37.49718,37.49718,0,0,1,14.18037-8.1685c.59538-.18307,1.21238-.36034,1.84113-.52987.621-.15766,1.25383-.30758,1.8906-.43788l.21556-.04411c.30371-.06214.61922-.11649.92494-.16882.384-.06837.77014-.127,1.14845-.17376a9.39322,9.39322,0,0,1,3.53212,3.09474l10.35538-2.11894q.41153-.08419.829-.139l.01961-.004C413.88488,343.76663,422.1974,354.16122,424.88167,367.27942Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M368.44232,357.73831c-2.2173,1.6004-4.55,3.24914-7.25562,3.64571s-5.87314-.91137-6.62192-3.54141c-.91552-3.21573,1.98828-6.14372,4.59815-8.23362l8.2759-6.627a20.01088,20.01088,0,0,1,5.0741-3.2807,6.76234,6.76234,0,0,1,5.85435.19572,6.28705,6.28705,0,0,1,2.7631,5.47358,11.68688,11.68688,0,0,1-2.04963,5.99576,21.08854,21.08854,0,0,1-13.6911,9.30674",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M431.21085,529.91074h-60a8,8,0,1,0,0,16h22v62h16v-62h22a8,8,0,0,0,0-16Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M652.875,783.64191l-438.75.30733a1.19069,1.19069,0,0,1,0-2.38137l438.75-.30733a1.19069,1.19069,0,0,1,0,2.38137Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#cacaca"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "567.621 535.776 577.388 546.42 623.092 513.812 608.676 498.102 567.621 535.776",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M800.77085,665.89072v-.01l-.99-10.63-.45-.21-2.63-1.25-12.41-5.87-.26-.28-2.49-2.72-13.79,12.66,18.68,20.36,12.81006,13.96.43-.4a18.11155,18.11155,0,0,0,1.1-25.61Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "517.018 641.96 530.84 646.159 553.614 594.841 533.213 588.644 517.018 641.96",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M765.74088,768.50071a17.95663,17.95663,0,0,0-10.72-8.8l-6.27-8.65-.49.04-16.57,1.52-.37-.11005-3.52-1.07-5.44,17.91,44.56994,13.54.17-.57A18.05329,18.05329,0,0,0,765.74088,768.50071Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          id: "b2eb929e-713e-4b71-a146-193a455bcc79-406",
          "data-name": "Path 17",
          d:
            "M843.93174,488.31824s88.25133,85.20743,32.46032,111.582H859.14813s-5.182-18.41014-17.04545-33.93528c-1.62919-2.13206-7.73258-1.699-9.42755-3.77681-10.34924-12.6871-21.56911-23.583-29.318-23.15039l1.85373,58.873,3,21c1.57523,10.85587-42.8823,105.64293-42.8823,105.64293l-27.94326-10.9822s29.11333-78.07121,29.45437-89.3263L743.509,500.49065Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M851.29087,492.52073a5.258,5.258,0,0,1-1.72,1.56,12.15105,12.15105,0,0,1-3.61,1.34c-19.87,4.51-84.78,13.86-101.63,9.4-2.07-.54-3.42-1.3-3.86-2.3-1.41-3.16-1.23-16.51-.4-32.63.15-3.02,3.48-6.29,3.68-9.45.14-2.37-2.85-4.6-2.69-7.01,1.1-16.3,2.52-32.91,3.47-43.41.6-6.75,1.01-10.97,1.01-10.97l-10.25-64.63.48-.79.54-.26995,33.05-16.36,1.8-4.6a7.1182,7.1182,0,0,1,6.59-4.51l21.77-.09a7.09451,7.09451,0,0,1,5.79,2.93994l5.9,8.17,39,25-.15.46-18.3,55.69-.09,4.34v.01l-.68,34.9,1.89,4.73-2.13,7.76s.98,9.06995,6.23,15.81c4.76,6.12,10.82,12.5,13.56,17.68v.01C852.06083,488.16074,852.57084,490.64072,851.29087,492.52073Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          id: "e9cec503-1de8-4692-84d1-4d2e854ea0bd-407",
          "data-name": "Path 18",
          d:
            "M880.62942,594.83867l-4.71711,5.96044s-43.3834,30.35164-44.87313,33.18812l-6.85625,6.48779-16.58951-17.73338,8.62719-10.81331a67.72416,67.72416,0,0,1,25.97924-19.88955h0l13.22175-11.73036Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M853.06311,509.65128,845.16283,433.181,843.0776,401.7083l22.11265-3.99841s-.87618,22.50391,2.0206,35.20085c2.85864,12.52919.37923,75.06164.20259,76.73371a10.75842,10.75842,0,1,1-14.35033.00683Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M874.63334,449.02771l-28.42249,5.883-2.48527-9.90756-2.89721-3.07361-5.78863-30.32336-8.30507-17.763,2.15885-60.7439,21.09371,11.18744.02574.0198c3.22624,3.50215,5.44984,8.51459,9.147,13.54426,11.65,15.84875,12.942,54.35986,12.58948,75.45422-.0058.34622.3424.71166.76073,1.0962a11.69542,11.69542,0,0,1,2.12319,14.62647Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M724.3586,512.65128l7.90028-76.47031,2.08523-31.47267-22.11265-3.99841s.87618,22.50391-2.02061,35.20085c-2.85863,12.52919-.37922,75.06164-.20258,76.73371a10.75841,10.75841,0,1,0,14.35033.00683Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M750.69083,396.84074l-6.16,13.18-2.15,4.59-5.79,30.31994-2.89,3.07-2.49,9.91-1.17-.24-21.1-4.37-6.15-1.27a11.69074,11.69074,0,0,1,2.12-14.63c.42-.38.76995-.75.76-1.1-.35-21.09.94-59.6,12.59-75.45,3.7-5.03,5.92-10.04,9.15-13.54.01-.00995.01-.02.01995-.02l7.86005-12.87.48-.79.54-.26995Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          id: "e944ea38-edcc-43c9-bf24-dbaf21228ba4",
          "data-name": "Ellipse 5",
          cx: "575.07324",
          cy: "157.58665",
          r: "28.96563",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M820.57084,259.9307c-.1-4.94-.66-11.26-4.12-16.3-2.92-4.24-8.81-7.6-14.3-5.92a5.94553,5.94553,0,0,0-2.96-5.48c-2.08-1.27-4.58-1.5-6.79-1.71-5.57-.52-11.34-1.06-16.98.01-6.33,1.2-11.51,4.46-14.2,8.95l-.06.14-.55,2.66a2.52491,2.52491,0,0,1-1.25,1.7,2.48125,2.48125,0,0,1-2.11005.15,2.29045,2.29045,0,0,0-3.07,2.48005l.15,1.06-1.59-.3a2.28362,2.28362,0,0,0-2.57,3.05l.44,1.21a2.44768,2.44768,0,0,0-1.75.77,2.23725,2.23725,0,0,0-.3,2.62,20.81224,20.81224,0,0,0,7.34,7.38,17.30344,17.30344,0,0,0,4.37,1.87q.48.135.99.24a103.06214,103.06214,0,0,0,37.35,1.12,21.15736,21.15736,0,0,0-.83,7.56c.4,3.19,2.27,5.82,4.77,6.7a10.00084,10.00084,0,0,0,1.74.42,10.734,10.734,0,0,1,1.67.41,4.86346,4.86346,0,0,1,1.42,8.32s2.62,2.17,2.95,2.06a6.73331,6.73331,0,0,0,3.08-2.74C818.40086,281.1807,820.81083,271.62071,820.57084,259.9307Z",
          transform: "translate(-212.93432 -115.05076)",
          fill: "#2f2e41"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }