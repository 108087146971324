<template>
  <div class="no-data__container">
    <div v-if="!noIcon" class="no-data__icon">
      <div class="no-data__icon--wrap">
        <UndrawWarning />
      </div>
    </div>
    <div class="no-data__content">
      <div class="no-data__title">{{ title }}</div>
      <div v-if="$slots.text" class="no-data__text">
        <slot name="text" />
      </div>
    </div>
  </div>
</template>

<script>

import UndrawWarning from '@/components/icons/undraw/UndrawWarning.vue';

const DEFAULT_TITLE = 'Es ist ein Fehler aufgetreten.';

export default {
  props: {
    title: {
      type: String,
      default: DEFAULT_TITLE,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UndrawWarning,
  },
}
</script>

<style lang="scss" scoped>
.no-data__container {
  margin: 32px 0;
  text-align: center;

  > * {
    margin-bottom: 16px;
  }

  .no-data__icon {
    .no-data__icon--wrap {
      margin: 0 auto;
      max-width: 250px;
    }
  }

  .no-data__title {
    font-size: 1rem;
  }
}
</style>
