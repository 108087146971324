<template>
  <div id="fin_calc_app">
    <Rechner />
  </div>
</template>

<script>
import Rechner from "./fincalc_components/Rechner.vue";

export default {
  name: "FinCalcApp",
  components: {
    Rechner
  },

 
  data() {
    return {};
  }
};
</script>

<style scoped>
#fin_calc_app {
  --blueish-main-color: rgb(2, 0, 128);
  --blueish-light-color: rgb(187, 208, 255);
  --blueish-dark-color: rgb(1, 0, 59);
  --blueish-button-color: rgb(75, 75, 75);
  --blueish-buttonhover-color: rgb(56, 56, 56);
  --blueish-buttontext-color: rgb(255, 255, 255);
  --blueish-labeltext-color: rgb(29, 0, 97);

  --reddish-main-color: rgb(102, 3, 3);
  --reddish-light-color: rgb(245, 191, 191);
  --reddish-dark-color: rgb(54, 0, 0);
  --reddish-button-color: rgb(75, 75, 75);
  --reddish-buttonhover-color: rgb(56, 56, 56);
  --reddish-buttontext-color: rgb(255, 255, 255);
  --reddish-labeltext-color: rgb(77, 0, 0);

  --orangeish-main-color: rgb(192, 38, 0);
  --orangeish-light-color: rgb(254, 255, 197);
  --orangeish-dark-color: rgb(107, 70, 0);
  --orangeish-button-color: rgb(226, 102, 0);
  --orangeish-buttonhover-color: rgb(17, 1, 75);
  --orangeish-buttontext-color: rgb(255, 255, 255);
  --orangeish-labeltext-color: rgb(92, 69, 18);
}

#fin_calc_app {
  display: flex;
  justify-content: center;
}
</style>
