var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "font-bold" }, [_vm._v(_vm._s(_vm.person1))]),
    _vm.warningsPerson1()
      ? _c("div", { staticClass: "fc-form-danger" }, [
          _vm._v(_vm._s(_vm.warningsPerson1()))
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputToggleSwitch", {
          attrs: {
            label: "Weitere Personen dem Anlegerprofil hinzufügen *",
            inLineLabel: "",
            disabled: _vm.isDisabled,
            validateUntouched: ""
          },
          on: {
            input: function($event) {
              return _vm.updatePersonenStepper($event)
            }
          },
          model: {
            value: _vm.hasExtraPersons,
            callback: function($$v) {
              _vm.hasExtraPersons = $$v
            },
            expression: "hasExtraPersons"
          }
        }),
        _vm.hasExtraPersons
          ? _c(
              "div",
              { staticClass: "ml-2" },
              [
                _c("InputToggleSwitch", {
                  attrs: {
                    inLineLabel: "",
                    suppressValidationMessage: "",
                    label: _vm.getPerson2Label,
                    disabled: _vm.isDisabled
                  },
                  on: {
                    input: function($event) {
                      return _vm.personChanged("person2")
                    }
                  },
                  model: {
                    value: _vm.selectPerson2,
                    callback: function($$v) {
                      _vm.selectPerson2 = $$v
                    },
                    expression: "selectPerson2"
                  }
                }),
                _vm.selectPerson2
                  ? _c("EntitySelector", {
                      attrs: {
                        isPersonPicker: "",
                        isComponentHalfSize: "",
                        id: "person2",
                        values: _vm.personListDepotOwner,
                        disabled: _vm.isDisabled
                      },
                      on: {
                        new: function($event) {
                          return _vm.newPerson({ personType: "person2" })
                        },
                        edit: _vm.editPerson,
                        change: function($event) {
                          return _vm.personComboChanged("person2", $event)
                        }
                      },
                      model: {
                        value: _vm.person2,
                        callback: function($$v) {
                          _vm.person2 = $$v
                        },
                        expression: "person2"
                      }
                    })
                  : _vm._e(),
                _vm.isChildOrFirm
                  ? _c("InputToggleSwitch", {
                      attrs: {
                        inLineLabel: "",
                        suppressValidationMessage: "",
                        label: _vm.getPerson3Label,
                        disabled: _vm.isDisabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.personChanged("person3")
                        }
                      },
                      model: {
                        value: _vm.selectPerson3,
                        callback: function($$v) {
                          _vm.selectPerson3 = $$v
                        },
                        expression: "selectPerson3"
                      }
                    })
                  : _vm._e(),
                _vm.selectPerson3
                  ? _c("EntitySelector", {
                      attrs: {
                        isPersonPicker: "",
                        isComponentHalfSize: "",
                        id: "person3",
                        values: _vm.personListDepotOwner,
                        disabled: _vm.isDisabled
                      },
                      on: {
                        new: function($event) {
                          return _vm.newPerson({ personType: "person3" })
                        },
                        edit: _vm.editPerson,
                        change: function($event) {
                          return _vm.personComboChanged("person3", $event)
                        }
                      },
                      model: {
                        value: _vm.person3,
                        callback: function($$v) {
                          _vm.person3 = $$v
                        },
                        expression: "person3"
                      }
                    })
                  : _vm._e(),
                _vm.zeigeBM1
                  ? _c("InputToggleSwitch", {
                      attrs: {
                        inLineLabel: "",
                        suppressValidationMessage: "",
                        label: _vm.getBM1Label,
                        disabled: _vm.isDisabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.personChanged("bm1")
                        }
                      },
                      model: {
                        value: _vm.selectBM1,
                        callback: function($$v) {
                          _vm.selectBM1 = $$v
                        },
                        expression: "selectBM1"
                      }
                    })
                  : _vm._e(),
                _vm.selectBM1
                  ? _c("EntitySelector", {
                      attrs: {
                        isPersonPicker: "",
                        isComponentHalfSize: "",
                        id: "bm1",
                        values: _vm.personListBM,
                        disabled: _vm.isDisabled
                      },
                      on: {
                        new: function($event) {
                          return _vm.newPerson({ personType: "bm1" })
                        },
                        edit: _vm.editPerson,
                        change: function($event) {
                          return _vm.personComboChanged("bm1", $event)
                        }
                      },
                      model: {
                        value: _vm.bm1,
                        callback: function($$v) {
                          _vm.bm1 = $$v
                        },
                        expression: "bm1"
                      }
                    })
                  : _vm._e(),
                _vm.zeigeBM2
                  ? _c("InputToggleSwitch", {
                      attrs: {
                        inLineLabel: "",
                        suppressValidationMessage: "",
                        label: _vm.getBM2Label,
                        disabled: _vm.isDisabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.personChanged("bm2")
                        }
                      },
                      model: {
                        value: _vm.selectBM2,
                        callback: function($$v) {
                          _vm.selectBM2 = $$v
                        },
                        expression: "selectBM2"
                      }
                    })
                  : _vm._e(),
                _vm.selectBM2
                  ? _c("EntitySelector", {
                      attrs: {
                        isPersonPicker: "",
                        isComponentHalfSize: "",
                        id: "bm2",
                        values: _vm.personListBM,
                        disabled: _vm.isDisabled
                      },
                      on: {
                        new: function($event) {
                          return _vm.newPerson({ personType: "bm2" })
                        },
                        edit: _vm.editPerson,
                        change: function($event) {
                          return _vm.personComboChanged("bm2", $event)
                        }
                      },
                      model: {
                        value: _vm.bm2,
                        callback: function($$v) {
                          _vm.bm2 = $$v
                        },
                        expression: "bm2"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-2" },
      [
        _c("InputField", {
          attrs: {
            label: "Ort",
            disabled: _vm.isDisabled,
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.updateStore("startStep", "fillPlace", $event)
            }
          },
          model: {
            value: _vm.fillPlace,
            callback: function($$v) {
              _vm.fillPlace = $$v
            },
            expression: "fillPlace"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Datum",
            dateFormat: "DD.MM.YYYY",
            placeholder: "DD.MM.JJJJ",
            disabled: _vm.isDisabled,
            isComponentHalfSize: "",
            isValueAsString: ""
          },
          on: {
            input: function($event) {
              _vm.updateStore("startStep", "fillDate", _vm.getDateStr($event))
            }
          },
          model: {
            value: _vm.fillDate,
            callback: function($$v) {
              _vm.fillDate = $$v
            },
            expression: "fillDate"
          }
        }),
        _c("TimePicker", {
          attrs: {
            label: "Uhrzeit/Anfang",
            disabled: _vm.isDisabled,
            isComponentHalfSize: ""
          },
          on: {
            input: function($event) {
              return _vm.updateStore("startStep", "fillTime", $event)
            }
          },
          model: {
            value: _vm.fillTime,
            callback: function($$v) {
              _vm.fillTime = $$v
            },
            expression: "fillTime"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }