<template>
  <div>
    <InsuranceOptionMenu title="Wertpapierpositionen" />

    <div class="box__container">
      <div class="row">
        <div class="col-12 col-sm-6">
          <InputField
            type="text"
            label="ISIN / WKN"
            v-model="wertpapierepositionen.isin"
            :validateUntouched="true"
            @onBlur="validateFond"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <InputField
            type="text"
            label="Anlageart"
            v-model="wertpapierepositionen.anlageart"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <InputField
            type="number"
            label="Anteile"
            v-model="wertpapierepositionen.anteile"
            :validateUntouched="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <InputField
            type="currency"
            label="Anlagesumme"
            v-model="wertpapierepositionen.anlagesumme"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <DatePickerField
            label="Datum Anteile"
            isValueAsString
            v-model="wertpapierepositionen.anteileDatum"
          />
        </div>
      </div>
    </div>
    <div>
      <BaseModal ref="verlaufwertpapierModal" 
        labelButtonCancel="Abbrechen"
        @onConfirmButton="saveVerlaufWertpapierposition" 
        @onCancelButton="closeModal()">

        <template v-slot:modalTitle>
          Verlauf Wertpapierpositionen
        </template>
        <div class="row">
          <div class="col-12">
            <InputField
              type="text"
              label="Wertpapierposition"
              v-model="verlaufAnteileDescription"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <DatePickerField
              label="Stand Anteile"
              isValueAsString
              v-model="verlaufAnteileEdit.datum"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <InputField
              type="number"
              label="Anteile"
              :precision="2"
              v-model="verlaufAnteileEdit.anteile"
              :validateUntouched="true"
            />
          </div>
        </div>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue';
import { mapGetters } from 'vuex';
import Table from '@/components/table2/Table.vue';
import {NumberColumn, CurrencyColumn, ActionColumn, TextColumn, SimpleAction } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import EINGEBUNDENE_WERTPAPIERE_TYPES from "@/store/eingebundeneWertpapiere/types"
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import { required, regex } from '@/mixins/validator/rules';
import validator from '@/mixins/validator'
import { addParamToURL } from '@/helpers/utils-helper';
import InsuranceOptionMenu from '@/views/versicherungen/insurance-group/InsuranceOptionMenu.vue';

export default {
  mixins: [validator],
  validators: {
    wertpapierepositionen : {
      isin : [required('ISIN / WKN ist erforderlich!'), 
              regex(/((?=(\d{6}|[a-z0-9]{6}))(?!([a-z]){6}))|(\b([A-Z]{2})((?![A-Z]{10}\b)[A-Z0-9]{10})\b)/i, 'Ungültige ISIN / WKN')],
      anteile : [required('Anteile sind erforderlich!')],
    },
    verlaufAnteileEdit: {
      anteile : [required('Anteile sind erforderlich!')]
    }
  },
  components: {
    PageHeaderActionCard,
    Table,
    GhostLoading,
    NoData,
    InputField,
    DatePickerField,
    BaseModal,
    BaseButton,
    InsuranceOptionMenu,
  },
  data() {
    return {
      loading: false,
      wertpapierepositionen: {
        isin : null,
        anlageart : null,
        anteile : null,
        anlagesumme : null,
        anteileDatum : null,
        verlaufAnteile : []
      },
      verlaufAnteileEdit: {
        id: null,
        datum: null,
        anteile: null
      },
      verlaufAnteileDescription: '',
      currentSparte: null,
      currentInsurance: null,
    }
  },
  computed: {
    ...mapGetters({
      eingebundeneWertpapiereEdit: EINGEBUNDENE_WERTPAPIERE_TYPES.GETTERS.EINGEBUNDENE_WERTPAPIERE_EDIT,
    }),
    verlaufAnteileHeaders() {
      return {
        lockedLeft: [TextColumn("datum", "Datum")],
        center: [NumberColumn("anteile", "Anteile", 2)],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      }
    },
    verlaufAnteileRows() {
      if(this.wertpapierepositionen?.verlaufAnteile?.length) {
        const rows = [...this.wertpapierepositionen?.verlaufAnteile];
        rows.forEach((element, index) => {
          element.actions = [SimpleAction("EDIT", 'PhPencilLine', "Bearbeiten"), SimpleAction("DELETE", 'PhTrash', "Löschen")]
          element.index = index
        });
        return rows;
      }
      return [];
    }
  },
  methods: {
    async validateFond() {
      const isinPath = 'wertpapierepositionen.isin';

      this.validation.reset(isinPath);
      if(!this.wertpapierepositionen.isin) {
        return;
      }

      const addValidationErrorToField = (error) => {
        const errorObject = error?.response?.data;
         if(this.wertpapierepositionen.isin) {
          this.$pushErrors(isinPath, errorObject.message);
        } else {
          this.validation.reset(isinPath);
        }
      }

      const isValid = await this.$store.dispatch(EINGEBUNDENE_WERTPAPIERE_TYPES.ACTIONS.VALIDATE_FOND_ID, { isinWkn: this.wertpapierepositionen.isin })
        .then(response => {
          this.validation.reset(isinPath);
          this.verlaufAnteileDescription = response || null;
        })
        .catch(addValidationErrorToField);
    },
    async save() {
      await this.$store.dispatch(EINGEBUNDENE_WERTPAPIERE_TYPES.ACTIONS.SAVE_EINGEBUNDENE_WERTPAPIERE, 
        {
          wertpapierepositionen: this.wertpapierepositionen,
          versicherungId: this.currentInsurance,
          sparteId: this.currentSparte
        })
    },
    neuVerlaufAnteile() {
      this.resetVerlaufAnteile();
      this.$refs?.verlaufwertpapierModal?.open();
    },
    saveVerlaufWertpapierposition() {
      if(this.validation.verlaufAnteileEdit.valid) {

        if(!this.verlaufAnteileEdit.id){
          // if is new verlauf anteile (without id) and is editing, here we are using the same reference for the table object, so nothing is required to do
          // once the reference is already updated.
          if(this.wertpapierepositionen.verlaufAnteile.includes(this.verlaufAnteileEdit)) {
            return;
          }
          this.wertpapierepositionen.verlaufAnteile.push(this.verlaufAnteileEdit);
        } else {
          const index = this.wertpapierepositionen.verlaufAnteile.findIndex(item => item.id === this.verlaufAnteileEdit.id)
          if(index > -1) {
            this.wertpapierepositionen.verlaufAnteile.splice(index, 1, this.verlaufAnteileEdit)
          }
        }
        Vue.set(this.wertpapierepositionen,'verlaufAnteile', [...this.wertpapierepositionen.verlaufAnteile]);
      }
      this.validation.reset('verlaufAnteileEdit.anteile');
    },
    editVerlaufAnteile(value) {
      this.verlaufAnteileEdit = value.id ? {...value} : value;
      this.$refs?.verlaufwertpapierModal?.open();
    },
    deleteVerlaufAnteile(value) {
      if(value?.id) {
        this.$store.dispatch(EINGEBUNDENE_WERTPAPIERE_TYPES.ACTIONS.DELETE_VERLAUF_ANTEILE, 
        {
          verlaufAnteileId: value.id,
          versicherungId: this.currentInsurance
        })
      } else {
        this.wertpapierepositionen?.verlaufAnteile.splice(value.index, 1)
      }
    },
    resetVerlaufAnteile() {
      this.verlaufAnteileEdit = {
        id: null,
        datum: null,
        anteile: null
      }
    }
  },
  mounted() {
    if(this.eingebundeneWertpapiereEdit) {
      this.wertpapierepositionen = {
        id: this.eingebundeneWertpapiereEdit.id,
        isin : this.eingebundeneWertpapiereEdit.isin,
        anlageart : this.eingebundeneWertpapiereEdit.anlageart,
        anteile : this.eingebundeneWertpapiereEdit.anteile,
        anlagesumme : this.eingebundeneWertpapiereEdit.anlagesumme,
        anteileDatum : this.eingebundeneWertpapiereEdit.anteileDatum,
        verlaufAnteile : [...this.eingebundeneWertpapiereEdit?.verlaufAnteile || []]
      }
      this.validateFond();
    }
    this.currentSparte = this.$route?.params?.sparteId;
    this.currentInsurance = this.$route?.params?.vertragId;
  },
  async beforeRouteLeave(to, from, next) {
    if(this.validation.valid) {
      await this.save();
    }

    let url = from.fullPath;
    if(from.fullPath.includes('stepKey')){
      url = from.fullPath.replaceAll(/stepKey\=.*(\&)/gi, `stepKey=sparteUbersicht$1`)
    } else {
      url = addParamToURL(from.fullPath, 'stepKey=sparteUbersicht')
    }
    // this.$addBreadcrumb({
    //   label: 'zurück zu Details',
    //   fullPath: url,
    //   breadcrumb: 'Wertpapierepositionen',
    // });
    next();
  }

}
</script>

<style>

</style>
