<template>
  <div>
    <MultiBankingNavigation subtitle="monatliches Sparpotential" />

    <div class="box__container">
      <template v-if="hasSavingsPotentialByAccount || filteredAccounts.length">
        <BarChart v-if="hasSavingsPotentialByAccount" :labels="chartLabels" :chartData="chartData" height="400px" />

        <KontoverwaltungAccountTable  v-if="filteredAccounts.length"
            :accounts="filteredAccounts"
            v-model="selectedAccounts"
            :useBoxContainer="false"
        />
      </template>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MULTIBANKING_TYPES from '@/store/multiBanking/types';

import BarChart from '@/components/charts/echarts/BarChart.vue';
import KontoverwaltungAccountTable from '@/components/multiBanking/KontoverwaltungAccountTable.vue';
import NoData from '@/components/core/NoData.vue'
import { getAccountTitle } from './multiBanking-utils';
import MultiBankingNavigation from '@/components/multiBanking/MultiBankingNavigation.vue';

const ACCOUNTS_ALL = 'Alle';
const COLOR_SUCCESS = document.documentElement.style.getPropertyValue('--color-success');

export default {
  data() {
    return {
      selectedAccounts: [],
    };
  },
  computed: {
    ...mapGetters({
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      savingsPotential: MULTIBANKING_TYPES.GETTERS.SAVINGS_POTENTIAL,
      savingsPotentialByAccount: MULTIBANKING_TYPES.GETTERS.SAVINGS_POTENTIAL_BY_ACCOUNT,
    }),
    hasSavingsPotentialByAccount() {
      return Object.keys(this.savingsPotentialByAccount || {}).length > 0;
    },
    filteredAccounts() {
      return this.accounts.filter(account => account.accountType === 'BANKACCOUNT');
    },
    selectedAccountsExternalId() {
      return this.selectedAccounts.map(item => item.externalId+'');
    },
    chartLabels() {
      const chartLabels = this.filteredAccounts
        .filter(account => this.selectedAccountsExternalId.includes(account.externalId))
        .map(account => getAccountTitle(account))

      return [ ...chartLabels, ACCOUNTS_ALL ];
    },
    chartData() {
      const chartData = this.filteredAccounts
        .filter(account => this.selectedAccountsExternalId.includes(account.externalId))
        .map(account => {
          const { avgTotal } = this.savingsPotentialByAccount[account.externalId] || {};
          return {
            value: [getAccountTitle(account), avgTotal],
          };
        });

      const { avgTotal } = this.savingsPotential || {};
      const overall = { 
        value: [ACCOUNTS_ALL, avgTotal],
        itemStyle: { color: COLOR_SUCCESS, borderColor: COLOR_SUCCESS, },
      };
      return [ ...chartData, overall ];
    },
  },
    mounted() {
        this.selectedAccounts = [...this.accounts];
    },
  components: {
    BarChart,
    KontoverwaltungAccountTable,
    NoData,
    MultiBankingNavigation,
  },
}
</script>
