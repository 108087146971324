<template>
  <div class="box__container">
    <Makler34fConfigLoading v-if="isLoadingData" />
    <template v-else>
      <div class="box__title">Liste der erlaubten Gesellschaften für {{ gesellschaft34fTyp.title }}</div>

      <div>
        <InputToggleSwitch 
          :label="gesellschaft34fTyp.erlaubnisLabel" inLineLabel v-model="form.erlaubnis" :disabled="isDisabled"
          @input="dataChanged($event)" 
        />
      </div>

      <p class="text-small">
        Hier sehen Sie eine Liste aller Gesellschaften, die im MSC angeboten werden. 
        Sie können hier einzelne (oder alle) Gesellschaften deaktivieren, falls Sie diese nicht vertreiben. 
        Gesellschaften, die neu in den Datenbestand übernommen werden, sind zunächst als "Erlaubt" gekennzeichnet.
      </p>

      <Makler34fGesellschaftenTable :disabled="isDisabled || !isAllowed" />
    </template>
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import MAKLER34F_TYPES from '@/store/makler34f/types';

import Makler34fConfigLoading from '@/views/core/configs/makler34fConfig/components/Makler34fConfigLoading.vue';

import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import Makler34fGesellschaftenTable from './../components/Makler34fGesellschaftenTable.vue';

export default {
  data() {
    return {
      form: {
        erlaubnis: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      data: MAKLER34F_TYPES.GETTERS.DATA,
      isLoadingData: MAKLER34F_TYPES.GETTERS.IS_LOADING_DATA,
      gesellschaft34fTyp: MAKLER34F_TYPES.GETTERS.GESELLSCHAFT34F_TYP_SELECTED,
    }),
    isDisabled() {
      return !this.gesellschaft34fTyp?.erlaubnisLabelEnabled;
    },
    isAllowed() {
      return !!this.form?.erlaubnis;
    },
  },
  watch: {
    gesellschaft34fTyp: {
      handler() {
        this.setData();
      },
      immediate: true,
    },
  },
  methods: {
    setData() {
      this.$set(this.form, 'erlaubnis', this.gesellschaft34fTyp?.erlaubnis);
    },
    dataChanged(value) {
      this.$store.commit(MAKLER34F_TYPES.MUTATIONS.SET_GESELLSCHAFTEN34F_TYP_EDITED, {
        [this.gesellschaft34fTyp.id]: value,
      });
    },
  },
  components: {
    Makler34fConfigLoading,
    InputToggleSwitch,
    Makler34fGesellschaftenTable,
  },
}
</script>
