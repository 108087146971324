export const MUTATION_PREFIX = 'E_MAIL_ACCESS: ';
export const ACTIONS_PREFIX = 'E_MAIL_ACCESS_ACTIONS_';
export const GETTERS_PREFIX = 'E_MAIL_ACCESS_GETTERS_';

export default {
  MUTATIONS: {
    GET_E_MAIL_SERVER: MUTATION_PREFIX + 'GET_E_MAIL_SERVER',
    UPDATE_MS_GRAPH_EMAIL_DATA: MUTATION_PREFIX + 'UPDATE_MS_GRAPH_EMAIL_DATA',
    GET_MS_GRAPH_EMAIL_DATA: MUTATION_PREFIX + 'GET_MS_GRAPH_EMAIL_DATA',
    GET_IGNORED_ADDRESSES_SUCCESS: MUTATION_PREFIX + 'GET_IGNORED_ADDRESSES_SUCCESS',
    GET_FOLDER_STRUCTURE_SUCCESS: MUTATION_PREFIX + 'GET_FOLDER_STRUCTURE_SUCCESS',
    RESET_MS_GRAPH: MUTATION_PREFIX + 'RESET_MS_GRAPH',
  },
  ACTIONS: {
    GET_E_MAIL_SERVER: ACTIONS_PREFIX + 'GET_E_MAIL_SERVER',
    GET_MS_GRAPH_EMAIL_DATA: ACTIONS_PREFIX + 'GET_MS_GRAPH_EMAIL_DATA',
    GET_MS_GRAPH_EMAIL_DATA_OUTPUT: ACTIONS_PREFIX + 'GET_MS_GRAPH_EMAIL_DATA_OUTPUT',
    SET_E_MAIL_SERVER: ACTIONS_PREFIX + 'SET_E_MAIL_SERVER',
    SET_E_MAIL_OUTPUT_SERVER: ACTIONS_PREFIX + 'SET_E_MAIL_OUTPUT_SERVER',
    DELETE_E_MAIL_SERVER: ACTIONS_PREFIX + 'DELETE_E_MAIL_SERVER',
    GET_IGNORED_ADDRESSES: ACTIONS_PREFIX + 'GET_IGNORED_ADDRESSES',
    DELETE_IGNORED_ADDRESS: ACTIONS_PREFIX + 'DELETE_IGNORED_ADDRESS',
    SAVE_IGNORED_ADDRESS: ACTIONS_PREFIX + 'SAVE_IGNORED_ADDRESS',
    GET_FOLDER_STRUCTURE: ACTIONS_PREFIX + 'GET_FOLDER_STRUCTURE',
    SAVE_FOLDER_STRUCTURE: ACTIONS_PREFIX + 'SAVE_FOLDER_STRUCTURE',
    RESET_FOLDER_STRUCTURE: ACTIONS_PREFIX + 'RESET_FOLDER_STRUCTURE',
  },
  GETTERS: {
    GET_E_MAIL_SERVER: GETTERS_PREFIX + 'GET_E_MAIL_SERVER',
    GET_MS_GRAPH_EMAIL_DATA: GETTERS_PREFIX + 'GET_MS_GRAPH_EMAIL_DATA',
    IGNORED_ADDRESSES: GETTERS_PREFIX + 'IGNORED_ADDRESSES',
    FOLDER_STRUCTURE: GETTERS_PREFIX + 'FOLDER_STRUCTURE',
  }
}
