<template>
	<CoreCard v-if="isContainerRendered" ref="coreCard" :headers="headers" :rows="rows" :showToolbar="showToolbar" :listView="listView">
		<template v-if="showTitle" v-slot:header>
			{{chartTitle}}
		</template>
		<template v-slot:alternative>
		</template>
    <div class="content">
      <div>
		    <Chart :data="chartDataPercent" type="radialMultiple" :isDataLabels="false" :isLegend="false" :isTooltip="false" :colors="customColors" :mixedColors="false"></Chart>
        <div class="legend" v-if="chartLabels">
          <div v-for="(item, index) of chartLabels" class="legend-item" :key="index" :style="{color: getColor(index)}">
            &bull; {{item.label}}: {{item.value | number}}
          </div>
        </div>
      </div>
    </div>
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard'
import Chart from '../../charts/Chart.vue'
import { getColorsMixedContrastDecreasing } from '../../../helpers/colors-helper';
import cardMixin from '../CoreCard/card-mixin';
import {TextColumn, NumberColumn} from "@/components/table2/table_util.js";


export default {
  mixins: [cardMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    option: {
      type: String,
      default: [],
    },
    title: {
      type: String,
      default: [],
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function() {
    return {
      isContainerRendered: false,
      colorsApex: getColorsMixedContrastDecreasing(),
    };
  },

  computed: {
    chartName: function() {
      return this.propsData && this.propsData.option || this.option;
    },
    chartTitle: function() {
      return this.propsData && this.propsData.title || this.propsData && this.propsData.props && this.propsData.props.title || this.title || this.chartName;
    },
    customColors: function() {
      return this.propsData.customColors;
    },
    chartLabels: function() {
      return this.propsData && Object.keys(this.propsData.labels).length && Object.keys(this.propsData.labels).map((key) => ({
        label: `${key}`,
        value: +this.propsData.labels[key]
      })) || this.chartData.data;
    },
    chartNameClean: function() {
      return this.chartName; //.replace(/[0-9]/g, '').replace('(', '').replace(')', '').replace(/\./g, '');
    },
    initData: function() {
      return this.propsData && this.propsData.data || this.data;
    },
    chartData: function() {
      if (!this.initData) {return {name: '', data: []}}
      let resultData = [];
      if (this.initData[this.chartNameClean]) {
        if (this.initData[this.chartNameClean].fonds) {
          this.initData[this.chartNameClean].fonds.map(fond => {
            resultData.push({
              label: `${fond.Name} (${fond.isin}) ${fond.Gesellschaft}`,
              value: +fond.Bestand
            })
          });
          resultData.push({
            label: 'Rest',
            value: +this.initData[this.chartNameClean].rest || 0,
          })
        } else {
          Object.keys(this.initData[this.chartNameClean]).map(key => {
            resultData.push({
              label: `${key}`,
              value: +this.initData[this.chartNameClean][key]
            })
          });
        }
      }
      let result = {
        name: this.chartName,
        data: resultData
      };
      return result;
    },
    chartDataPercent: function() {
      let numAll = this.chartTitle.replace( /^\D+/g, '').replace(')', '').replace('(', '').replace( /\./g, '').replace(',', '.');
      numAll = numAll ? numAll : this.initData[this.chartNameClean] && Object.values(this.initData[this.chartNameClean]).reduce((acc, curr) => acc + curr, 0);
      if (!this.initData) {return {name: '', data: []}}
      let resultData = [];
      if (this.initData[this.chartNameClean]) {
        let all = this.propsData.props && this.propsData.props.all || 0;
        numAll = all ? all : numAll;
        if (this.initData[this.chartNameClean].fonds) {
          this.initData[this.chartNameClean].fonds.map(fond => {
            resultData.push({
              label: `${fond.Name} (${fond.isin}) ${fond.Gesellschaft}`,
              value: +fond.Bestand/numAll
            })
          });
          resultData.push({
            label: 'Rest',
            value: +this.initData[this.chartNameClean].rest || 0,
          })
        } else {
          Object.keys(this.initData[this.chartNameClean]).map(key => {
            const val = numAll ? +this.initData[this.chartNameClean][key]/numAll : 0;
            resultData.push({
              label: `${key}`,
              value: all ? (1 - val) * 100 : val * 100
            })
          });
        }
      }
      let result = {
        name: this.chartName,
        data: resultData
      };
      return result;
    },
    headers() {
        return {
            lockedLeft: [
                TextColumn("label", ""),
            ],
            lockedRight: [
                NumberColumn("value", ""),
            ],
        };
    },
    rows() {
        return this.chartData.data;
    },
  },

  methods: {
    getColor(index) {
      return this.customColors[index] || this.colorsApex[index];
    },
  },

  mounted: function () {
    this.isContainerRendered = false;
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },
  filters: {
    currency(value, numberFixed = 2) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '€';
    },
    number(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // + '%';
    },
  },

  components: {
    CoreCard,
    Chart
  },
}
</script>

<style scoped>
/* .content {
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 350px;
  position: relative;
} */
</style>