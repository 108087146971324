import RUHESTANDSPLANUNG_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true,
}

export default {
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_SCENARIOS]({ commit }) {
    axios.get(`${process.env.VUE_APP_API}/ruhestandsplanung/getRuhestandsplanungList`, config).then(response => {
      if (response?.data) {
        commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIOS_SUCCESS, response.data);
        commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.SCENARIOS_URSPRUNG, response.data);
      }
    })
  },
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_VERMOEGEN]({ commit }) {
    axios.get(`${process.env.VUE_APP_API}/ruhestandsplanung/getVermoegen`, config).then(response => {
      if (response?.data) {
        commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_VERMOEGEN_SUCCESS, response.data);
      }
    })
  },
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.SAVE_SCENARIO]({ commit, state}, id = '') {
    if (state.dataHasChanged || !id || id === 'new') {
      const scenario = state.scenarios?.find(scenario => scenario.id === id);
      if (scenario?.title) {
        return axios.post(`${process.env.VUE_APP_API}/ruhestandsplanung/saveScenario`, scenario, config).then(response => {
          if (response?.data) {
            commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIOS_SUCCESS, response.data);
          }
          return response.data
        })
      }
    }
  },
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.SAVE_GOAL_SCENARIO]({ commit, state}, {scenarioId, goal}) {
    const scenario = state.scenarios?.find(scenario => scenario.id === scenarioId);
    if (goal && scenario?.id) {
      goal.scenarioId = scenario.id;
      return axios.post(`${process.env.VUE_APP_API}/ruhestandsplanung/saveScenarioGoal`, goal, config).then(response => {
        if (response?.data) {
          commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIOS_SUCCESS, response.data);
        }
        return response.data
      })
    }
  },
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.REMOVE_GOAL_SCENARIO]({ commit}, id) {
    if (id) {
      return axios.delete(`${process.env.VUE_APP_API}/ruhestandsplanung/deleteScenarioGoal?id=${id}`, config).then(response => {
        if (response?.data) {
          commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIOS_SUCCESS, response.data);
        }
      })
    }
  },
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.DELETE_SCENARIO]({ commit, state, dispatch, rootState }, id) {
    if (id) {
      return axios.delete(`${process.env.VUE_APP_API}/ruhestandsplanung/deleteScenario?id=${id}`, config).then(response => {
        if (response?.data) {
          commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIOS_SUCCESS, response.data);
        }
      })
    }
  },
}
