import BERATUNGSMAPPE_TYPES from './types';

export default {
  [BERATUNGSMAPPE_TYPES.GETTERS.BERATUNGSMAPPELIST_DATA](state) {
    return state.beratungsmappeList;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.BERATUNGSMAPPE_CARDS](state) {
    return state.beratungsmappeCards;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.BERATUNGSMAPPE_ACTIONS](state) {
    return state.beratungsmappeActions;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.EXECUTE_ACTION_RESULT](state) {
    return state.executeActionResult;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.ORDER_POSITION](state) {
    return state.orderPosition;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.ORDER_POSITION_HEADERS](state) {
    return state.orderPositionHeaders;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.ORDER_POSITION_RECORDS](state) {
    return state.orderPositionRecords;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.LAGERSTELLE_LISTE](state) {
    return state.lagerstelleListe;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.BERATUNGSMAPPE_SELECTIONS](state) {
    return state.beratungsmappeSelections;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.LAST_SAVED_ANTRAGS_POSITION](state) {
    return state.lastSavedAntragsPosition;
  },  
  [BERATUNGSMAPPE_TYPES.GETTERS.LAST_DELETED_ANTRAGS_POSITION](state) {
    return state.lastDeletedAntragsPosition;
  },    
}