var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container-wrap" },
    [
      !_vm.loading && _vm.hasCharts
        ? [
            _c("div", { staticClass: "box__container-rows" }, [
              _c(
                "div",
                { staticClass: "d-flex justify-content-center mb-3" },
                [
                  _c("ComboBox", {
                    attrs: {
                      value: _vm.selectedChart,
                      values: _vm.chartsValues
                    },
                    on: {
                      change: function($event) {
                        return _vm.setSelectedChart($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { key: _vm.selectedChart },
                [
                  _vm.selectedChart !== "depotid"
                    ? _c("DepotpositionsChart", {
                        attrs: { chart: _vm.chart, showTitle: false }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "bottom-row text-right" }, [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.openDiagrammePage()
                    }
                  }
                },
                [_vm._v("weitere...")]
              )
            ])
          ]
        : _vm.loading
        ? _c("GhostLoading", {
            attrs: { type: "block", config: { block: { height: 350 } } }
          })
        : _vm.isLoaded
        ? _c("NoData", { attrs: { noIcon: "" } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }