import Vue from 'vue';

import LOG_TYPES from '@/store/log/types';
import store from '@/store';
import { parseBoolean } from '@/helpers/utils-helper';

const CALLBACKS = new Map();
const resizeObserver = new ResizeObserver(handleResizeObserver);

function handleResizeObserver(entries) {
  for (const entry of entries) {
    const el = entry.target;
    const callback = CALLBACKS.get(el);
    callback?.(entry);
  }
}

/**
 * v-resize-observer directive
 * 
 * Example: <div v-resize-observer="callbackFn"></div>
 * 
 * > make sure that the function is passed as 'callbackFn' instead of as 'callbackFn()'
 */
Vue.directive('resize-observer', {
  bind(el, binding) {
    if (binding.value && typeof binding.value === 'function') {
      CALLBACKS.set(el, binding.value);
      resizeObserver.observe(el);
    } else {
      store.dispatch(LOG_TYPES.ACTIONS.ERROR, {
        message: `resize-observer >>> please, check the usage of the 'v-resize-observer' directive`,
        el,
      });
    }
  },
  unbind(el) {
    CALLBACKS.delete(el);
    resizeObserver.unobserve(el);
  },
});
