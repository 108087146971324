<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="801.19718"
    height="614.27258"
    viewBox="0 0 801.19718 614.27258"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M743.1742,755.17457c-.78106.66616-1.587,1.31586-2.40141,1.95737H641.90134c-.56742-.64151-1.13525-1.29121-1.69424-1.95737a109.34753,109.34753,0,0,1-11.999-17.641c-8.11741-14.6227-10.914-33.79337-10.914-53.31768,0-1.19253.00843-2.385.03293-3.57752.09076-5.47737.39515-10.96291.84732-16.358.08232-.93757.16465-1.86691.255-2.79625.73207-7.739,1.7601-15.27235,2.92787-22.31229.14778-.89646.296-1.78469.45218-2.66469a365.995,365.995,0,0,1,9.55665-41.42544s.13171.03293.38631.09869c.73207.20561,2.48373.69086,5.05822,1.45571,7.089,2.1054,20.404,6.34913,35.75893,12.6571.83889.33717,1.68581.6908,2.54116,1.05267A283.26673,283.26673,0,0,1,712.72809,629.237c.74853.45232,1.48863.91288,2.22873,1.37343v.06581l-1.61994,52.44587,14.47472-43.6624q4.42855,3.3678,8.46276,6.98236c.7401.65793,1.4802,1.33232,2.19581,2.03141a81.02719,81.02719,0,0,1,15.46139,19.28578C775.586,706.79966,766.4984,735.50218,743.1742,755.17457Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#f2f2f2"
    />
    <path
      d="M729.23437,757.13194h-2.63191c-.01646-.64974-.041-1.29944-.08232-1.95737-1.06056-19.72991-9.52332-41.51594-21.04567-62.54524-.41122-.7649-.83046-1.54616-1.2746-2.31106-5.59272-10.00064-11.82674-19.78749-18.266-29.08909-.49353-.72374-1.00353-1.45566-1.51353-2.17939-8.56958-12.22943-17.41866-23.546-25.41278-33.20944-.5594-.69086-1.13485-1.36525-1.70228-2.03141-11.85124-14.21144-21.63-24.56577-25.55293-28.62854-.58389-.60035-1.03606-1.06091-1.34848-1.38166-.28793-.31253-.46061-.477-.49354-.5099l.92121-.92111.008-.02465.93768-.92116c.03293.04116.60035.58394,1.60348,1.62843.84732.86354,2.00666,2.07248,3.42984,3.58576,4.96747,5.26352,13.18326,14.21967,22.69052,25.57733.551.658,1.11838,1.34055,1.68581,2.02317,6.489,7.83772,13.52058,16.71987,20.48668,26.26819q2.62749,3.60219,5.09918,7.14686c.518.72373,1.028,1.44747,1.52116,2.17121q10.44915,15.075,18.24992,28.99863l1.29909,2.32743c13.25755,24.17925,20.39633,45.59512,21.31754,64.02564C729.193,755.8325,729.21791,756.4822,729.23437,757.13194Z"
      transform="translate(-199.40141 -142.86371)"
      :fill="secondColor"
    />
    <path
      d="M674.01679,607.36871c-.46863.64975-.9457,1.29121-1.44727,1.9245a70.24156,70.24156,0,0,1-13.06841,12.92023c-.71561.52636-1.44767,1.06919-2.19581,1.5955a72.0404,72.0404,0,0,1-6.33281,3.94767,70.82539,70.82539,0,0,1-29.16308,8.75054c-.32889.03293-.66622.05757-.9951.074-.14818.88-.28793,1.76-.44414,2.65641.32888-.01642.65777-.03288.98706-.06576a73.30553,73.30553,0,0,0,30.89829-9.10423,75.17819,75.17819,0,0,0,6.75206-4.22726c.7401-.51813,1.46373-1.06091,2.17934-1.60374a73.267,73.267,0,0,0,13.92376-13.89073c.47707-.63323.9457-1.27474,1.40631-1.91621C675.66164,608.0678,674.83118,607.71417,674.01679,607.36871Z"
      transform="translate(-199.40141 -142.86371)"
      :fill="secondColor"
    />
    <path
      d="M713.6248,627.37828c-.28792.625-.59232,1.24186-.89671,1.85868a70.84226,70.84226,0,0,1-25.95531,28.4147c-.77343.48525-1.55449.954-2.35242,1.39813-.23853.1398-.49313.28783-.73167.41944a71.27838,71.27838,0,0,1-65.25933,2.0149c-.247-.1151-.4855-.23026-.724-.34541-.09035.93758-.17267,1.87515-.23813,2.82091.2301.10692.46864.22207.70717.32075a73.88,73.88,0,0,0,66.78893-2.50014c.32889-.181.64974-.36187.9706-.55106.79753-.46056,1.587-.92934,2.36005-1.40631a73.40144,73.40144,0,0,0,26.66288-29.14667c.32086-.61682.62525-1.24187.92121-1.87515C715.1299,628.32408,714.38137,627.84706,713.6248,627.37828Z"
      transform="translate(-199.40141 -142.86371)"
      :fill="secondColor"
    />
    <path
      d="M736.71007,644.57512c-.13975.625-.2795,1.25009-.43571,1.86691a71.08037,71.08037,0,0,1-29.7305,42.37947c-.765.51814-1.55449,1.01157-2.344,1.49677-.84732.51-1.69424,1.01985-2.56605,1.49682a71.45879,71.45879,0,0,1-84.30678-11.1767c-.18914-.17273-.36985-.35364-.551-.5346,0,1.2254-.008,2.43439.00843,3.65156.16425.15626.33692.31253.5096.46056a74.08268,74.08268,0,0,0,85.62273,9.91018c.87182-.477,1.72717-.97045,2.55763-1.49676.806-.477,1.58742-.97051,2.36848-1.4804a73.80814,73.80814,0,0,0,30.62722-42.67549c.15621-.59217.31242-1.18429.45217-1.78469C738.20714,645.965,737.4586,645.28244,736.71007,644.57512Z"
      transform="translate(-199.40141 -142.86371)"
      :fill="secondColor"
    />
    <path
      d="M667.75692,510.65184H199.40141v-351.389H667.75692Z"
      transform="translate(-199.40141 -142.86371)"
      :fill="secondColor"
    />
    <circle cx="113.49141" cy="71.09365" r="18.64295" fill="#e6e6e6" />
    <path
      d="M355.94913,201.52872a3.10716,3.10716,0,0,0,0,6.21431H582.80785a3.10716,3.10716,0,0,0,0-6.21431Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#e6e6e6"
    />
    <path
      d="M355.94913,220.17163a3.10716,3.10716,0,1,0-.01311,6.21431H499.35845a3.10716,3.10716,0,1,0,0-6.21431Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#e6e6e6"
    />
    <path
      d="M667.75692,510.65184H199.40141v-351.389H667.75692Zm-463.11344-5.24207H662.51485V164.50494H204.64348Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#e6e6e6"
    />
    <path
      d="M225.57906,266.42192v209h416v-209Zm414,51.75h-101.5v-49.75h101.5Zm-205,0v-49.75h101.5v49.75Zm101.5,2v49.75h-101.5v-49.75Zm-103.5-2h-101.5v-49.75h101.5Zm0,2v49.75h-101.5v-49.75Zm-103.5,49.75h-101.5v-49.75h101.5Zm0,2v49.75h-101.5v-49.75Zm2,0h101.5v49.75h-101.5Zm101.5,51.75v49.75h-101.5v-49.75Zm2,0h101.5v49.75h-101.5Zm0-2v-49.75h101.5v49.75Zm103.5-49.75h101.5v49.75h-101.5Zm0-2v-49.75h101.5v49.75Zm-209-101.5v49.75h-101.5v-49.75Zm-101.5,155.25h101.5v49.75h-101.5Zm310.5,49.75v-49.75h101.5v49.75Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#e4e4e4"
    />
    <path
      d="M396.35088,322.64939l-41.39272-.93718c-4.14034-16.03871-2.27631-31.30987,1.0277-45.38227l41.39252.93715A90.6291,90.6291,0,0,0,396.35088,322.64939Z"
      transform="translate(-199.40141 -142.86371)"
      :fill="color"
    />
    <polygon
      points="163.676 143.496 163.698 141.921 185.143 142.687 185.12 144.262 163.676 143.496"
      :fill="secondColor"
    />
    <polygon
      points="163.586 149.796 163.608 148.221 185.052 148.988 185.03 150.563 163.586 149.796"
      :fill="secondColor"
    />
    <polygon
      points="163.495 156.096 163.518 154.521 184.962 155.288 184.94 156.863 163.495 156.096"
      :fill="secondColor"
    />
    <polygon
      points="163.405 162.396 163.427 160.821 184.872 161.588 184.849 163.163 163.405 162.396"
      :fill="secondColor"
    />
    <polygon
      points="163.315 168.696 163.337 167.121 184.782 167.888 184.759 169.463 163.315 168.696"
      :fill="secondColor"
    />
    <path
      d="M306.35088,438.64939l-41.39272-.93718c-4.14034-16.03871-2.27631-31.30987,1.0277-45.38227l41.39252.93715A90.6291,90.6291,0,0,0,306.35088,438.64939Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#f0f0f0"
    />
    <polygon
      points="73.676 259.496 73.698 257.921 95.143 258.687 95.12 260.262 73.676 259.496"
      :fill="secondColor"
    />
    <polygon
      points="73.586 265.796 73.608 264.221 95.052 264.988 95.03 266.563 73.586 265.796"
      :fill="secondColor"
    />
    <polygon
      points="73.495 272.096 73.518 270.521 94.962 271.288 94.94 272.863 73.495 272.096"
      :fill="secondColor"
    />
    <polygon
      points="73.405 278.396 73.427 276.821 94.872 277.588 94.849 279.163 73.405 278.396"
      :fill="secondColor"
    />
    <polygon
      points="73.315 284.696 73.337 283.121 94.782 283.888 94.759 285.463 73.315 284.696"
      :fill="secondColor"
    />
    <path
      d="M522.35088,385.64939l-41.39272-.93718c-4.14034-16.03871-2.27631-31.30987,1.0277-45.38227l41.39252.93715A90.6291,90.6291,0,0,0,522.35088,385.64939Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#f0f0f0"
    />
    <polygon
      points="289.676 206.496 289.698 204.921 311.143 205.687 311.12 207.262 289.676 206.496"
      :fill="secondColor"
    />
    <polygon
      points="289.586 212.796 289.608 211.221 311.052 211.988 311.03 213.563 289.586 212.796"
      :fill="secondColor"
    />
    <polygon
      points="289.495 219.096 289.518 217.521 310.962 218.288 310.94 219.863 289.495 219.096"
      :fill="secondColor"
    />
    <polygon
      points="289.405 225.396 289.427 223.821 310.872 224.588 310.849 226.163 289.405 225.396"
      :fill="secondColor"
    />
    <polygon
      points="289.315 231.696 289.337 230.121 310.782 230.888 310.759 232.463 289.315 231.696"
      :fill="secondColor"
    />
    <path
      d="M504.35088,375.64939l-41.39272-.93718c-4.14034-16.03871-2.27631-31.30987,1.0277-45.38227l41.39252.93715A90.6291,90.6291,0,0,0,504.35088,375.64939Z"
      transform="translate(-199.40141 -142.86371)"
      :fill="color"
    />
    <polygon
      points="271.676 196.496 271.698 194.921 293.143 195.687 293.12 197.262 271.676 196.496"
      :fill="secondColor"
    />
    <polygon
      points="271.586 202.796 271.608 201.221 293.052 201.988 293.03 203.563 271.586 202.796"
      :fill="secondColor"
    />
    <polygon
      points="271.495 209.096 271.518 207.521 292.962 208.288 292.94 209.863 271.495 209.096"
      :fill="secondColor"
    />
    <polygon
      points="271.405 215.396 271.427 213.821 292.872 214.588 292.849 216.163 271.405 215.396"
      :fill="secondColor"
    />
    <polygon
      points="271.315 221.696 271.337 220.121 292.782 220.888 292.759 222.463 271.315 221.696"
      :fill="secondColor"
    />
    <path
      d="M657.017,466.999l-41.39273-.93718c-4.14034-16.03871-2.27631-31.30987,1.02771-45.38227l41.39251.93715A90.6291,90.6291,0,0,0,657.017,466.999Z"
      transform="translate(-199.40141 -142.86371)"
      :fill="color"
    />
    <polygon
      points="424.342 287.845 424.365 286.27 445.809 287.037 445.786 288.612 424.342 287.845"
      :fill="secondColor"
    />
    <polygon
      points="424.252 294.145 424.274 292.57 445.719 293.337 445.696 294.912 424.252 294.145"
      :fill="secondColor"
    />
    <polygon
      points="424.161 300.446 424.184 298.871 445.628 299.637 445.606 301.212 424.161 300.446"
      :fill="secondColor"
    />
    <polygon
      points="424.071 306.746 424.094 305.171 445.538 305.937 445.515 307.512 424.071 306.746"
      :fill="secondColor"
    />
    <polygon
      points="423.981 313.046 424.003 311.471 445.448 312.238 445.425 313.813 423.981 313.046"
      :fill="secondColor"
    />
    <path
      d="M612.35088,330.64939l-41.39272-.93718c-4.14034-16.03871-2.27631-31.30987,1.0277-45.38227l41.39252.93715A90.6291,90.6291,0,0,0,612.35088,330.64939Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#f0f0f0"
    />
    <polygon
      points="379.676 151.496 379.698 149.921 401.143 150.687 401.12 152.262 379.676 151.496"
      :fill="secondColor"
    />
    <polygon
      points="379.586 157.796 379.608 156.221 401.052 156.988 401.03 158.563 379.586 157.796"
      :fill="secondColor"
    />
    <polygon
      points="379.495 164.096 379.518 162.521 400.962 163.288 400.94 164.863 379.495 164.096"
      :fill="secondColor"
    />
    <polygon
      points="379.405 170.396 379.427 168.821 400.872 169.588 400.849 171.163 379.405 170.396"
      :fill="secondColor"
    />
    <polygon
      points="379.315 176.696 379.337 175.121 400.782 175.888 400.759 177.463 379.315 176.696"
      :fill="secondColor"
    />
    <path
      d="M654.78481,433.55383a10.05579,10.05579,0,0,0,6.786-13.84578L687.67,395.29875l-18.0556-4.33922-22.01607,23.84474a10.11028,10.11028,0,0,0,7.1865,18.74956Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#ffb6b6"
    />
    <path
      d="M668.26737,413.892l-12.17171-17.16138,45.71846-47.28659,10.61553-74.53553a25.35292,25.35292,0,0,1,33.19643-20.44981h0a25.37994,25.37994,0,0,1,15.92679,32.12217l-27.08463,80.35815Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#e4e4e4"
    />
    <polygon
      points="554.708 588.617 541.196 588.616 534.768 536.499 554.711 536.5 554.708 588.617"
      fill="#ffb6b6"
    />
    <path
      d="M761.37838,757.13629h-9.58777l-1.71137-9.052-4.38308,9.052h-25.429a5.71622,5.71622,0,0,1-3.24815-10.42013l20.30688-14.02466v-9.15125l21.35929,1.27486Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#2f2e41"
    />
    <polygon
      points="638.069 574.867 626.2 581.325 595.643 538.619 613.16 529.088 638.069 574.867"
      fill="#ffb6b6"
    />
    <path
      d="M856.11741,736.79167l-8.42164,4.58269-5.82984-7.133.47662,10.046-22.33622,12.1544a5.71622,5.71622,0,0,1-7.83363-7.60025l11.13363-22.025-4.37405-8.03822,19.37081-9.08937Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#2f2e41"
    />
    <path
      d="M759.802,712.92192H731.87838V613.48735l-17.28711-64.82617a371.10613,371.10613,0,0,1-9.709-142.30127l.02417-.19482.15015-.126c.44385-.373,44.68872-36.957,72.67529-8.9707,18.864,18.86425,1.13843,86.6958.14649,90.41894V604.42192Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#2f2e41"
    />
    <path
      d="M696.37838,424.42192l9-90c-1.16919-2.4541-18-18-.40186-45.33252l19.0083-44.61767,35.59741-.55323.14966.14991a71.70284,71.70284,0,0,1,19.6482,64.894l-10.48926,52.44727,9.08838,36.354Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#e4e4e4"
    />
    <polygon
      points="543.742 471.137 601.106 551.824 623.111 536.179 575.202 437.728 543.742 471.137"
      fill="#2f2e41"
    />
    <circle cx="539.05571" cy="59.66845" r="32.87" fill="#ffb6b6" />
    <polygon
      points="530.977 178.558 539.977 232.558 530.977 277.558 512.977 277.558 521.159 232.558 530.977 178.558"
      opacity="0.1"
    />
    <path
      d="M738.965,466.787a10.05579,10.05579,0,0,0-.53235-15.41014l11.5323-33.82285-17.97207,4.67314-8.196,31.40231A10.11027,10.11027,0,0,0,738.965,466.787Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#ffb6b6"
    />
    <path
      d="M741.602,443.09282l-18.81836-9.40918L740.853,370.44048l-25.729-70.75489a25.35292,25.35292,0,0,1,19.658-33.67138h0A25.38,25.38,0,0,1,763.95723,286.854l13.94117,83.646Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#e4e4e4"
    />
    <path
      d="M1000.59859,755.94194a1.18663,1.18663,0,0,1-1.18994,1.19h-647.29a1.19,1.19,0,1,1,0-2.38h647.29A1.193,1.193,0,0,1,1000.59859,755.94194Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#cacaca"
    />
    <path
      d="M866.29571,200.92492c-4.147,6.73212-12.93122,9.69989-20.72236,8.35193-7.79113-1.3479-14.56121-6.37658-19.56158-12.50146-5.00043-6.12494-8.45929-13.33454-11.8631-20.47119-3.40381-7.13672-6.84692-14.35181-11.82226-20.497-4.97535-6.14527-11.70966-11.21259-19.48957-12.624-4.23407-.76812-9.02972-.261-12.11914,2.73444a11.80248,11.80248,0,0,0-3.2381,8.3313,8.70266,8.70266,0,0,0-11.58221,8.63678c-.11523-.0022-.22913-.00983-.34436-.00983-14.49615-8.24164-36.4989-3.45392-36.4989,5.54608-7.9541,0-17.67578,7.85529-17.67578,19.78363v.00006c16.44848,0,26.12823,21.07471,16.52612,36.18561a103.28775,103.28775,0,0,1-8.18018,11.0307H730.293a17.13739,17.13739,0,0,0,3.01819-8.37134,38.50432,38.50432,0,0,1,1.34637,8.37134s56.18494,7.23975,41.68341-48.76294a5.868,5.868,0,0,0,2.71784-.82373c2.18976-1.31976,3.38232-4.111,3.59112-6.9433q.813.21735,1.62116.43366c3.86566,1.04352,7.88092,2.39563,10.53418,5.39441,4.48419,5.06817,3.54095,12.76068,5.16919,19.3291,1.96362,7.92108,7.83789,14.32794,14.36352,19.22845,7.01123,5.26514,15.20142,9.26526,23.93006,10.09559,8.7287.83032,18.01672-1.824,24.06482-8.17218S870.487,208.6264,866.29571,200.92492Z"
      transform="translate(-199.40141 -142.86371)"
      fill="#2f2e41"
    />
  </svg>
</template>

<script>
import undraw from "@/components/icons/undraw/undraw-mixin.js";

export default {
  mixins: [undraw],
};
</script>