<template>
    <BaseModal ref="modal"
        modalTitle="Passwort ändern"
        @onCloseButton="close"
        :showDefaultButtons="false">
        <template #default>
            <InputField label="Aktuelles Passwort" v-model="oldPassword" type="password" allowPlainPassword />
            <InputField label="Neues Passwort" v-model="newPassword" type="password" allowPlainPassword />
            <InputField label="Neues Passwort bestätigen" v-model="newPasswordRetype" type="password" allowPlainPassword validateUntouched />
            <PasswordPolicy />

            <div v-if="errorStr" class="color-danger mt-2">{{ errorStr }}</div>
        </template>
        <template #footer>
            <BaseButton @click="changePassword" :disabled="isFormInvalid || changing" :animated="changing" isPrimary>Passwort ändern</BaseButton>
        </template>
    </BaseModal>
</template>
<script>
import SSO_TYPES from '@/store/sso/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import PasswordPolicy, { NEW_PASSWORD_COMMON_RULES, confirmPasswordRule } from '@/views/core/change-password/PasswordPolicy.vue';
import validator from '@/mixins/validator/index.js';
import { required, createRule } from '@/mixins/validator/rules';

export default {
    mixins: [validator],
    validators: {
        oldPassword: [required()],
        newPassword: [ 
            ...NEW_PASSWORD_COMMON_RULES, 
            createRule((value, self) => !value || value !== self.oldPassword)
                .withMessage('gleich dem aktuellen Passwort'),
        ],
        newPasswordRetype: [
            confirmPasswordRule((self) => self.newPassword),
        ]
    },
    components: {
        BaseModal,
        InputField,
        BaseButton,
        PasswordPolicy,
    },
    emits: ['close'],
    computed: {
        isFormInvalid() {
            return this.validation.updated && (this.validation.isInvalid('oldPassword') 
                || this.validation.isInvalid('newPassword') || this.validation.isInvalid('newPasswordRetype'));
        },
    },
    data() {
        return {
            changing: false,
            oldPassword: "",
            newPassword: "",
            newPasswordRetype: "",
            errorStr: "",
        };
    },
    watch: {
        'newPassword'() {
            const currentValidator = this.validation.getValidator('newPasswordRetype')
            if (currentValidator?.watchCallback) {
                currentValidator.watchCallback(this.newPasswordRetype, false)
            }
        },
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        async changePassword() {
            try {
                this.changing = true;
                this.errorStr = '';

                await this.$store.dispatch(SSO_TYPES.ACTIONS.CHANGE_PASSWORD, {
                    newPassword: this.newPassword,
                    newPasswordRetype: this.newPasswordRetype,
                    password: this.oldPassword,
                }).then(response => {
                    if (response?.data?.errorStr) {
                        if (response.data.newPassword) {
                            this.$pushErrors('newPassword', response.data.errorStr);
                        } else if (response.data.newPasswordRetype) {
                            this.$pushErrors('newPasswordRetype', response.data.errorStr);
                        } else if (response.data.oldPassword) {
                            this.$pushErrors('oldPassword', response.data.errorStr);
                        } else {
                            this.errorStr = response.data.errorStr;
                        }
                    } else {
                        this.$store.dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
                        this.close();
                    }
                }).catch(error => {
                    this.errorStr = "Das Passwort konnte nicht geändert werden. Bitte überprüfen Sie ihre Eingaben.";
                });
            } catch(e) {
                // empty block
            } finally {
                this.changing = false;
            }
        },
        close() {
            this.$refs.modal.close();
            this.$emit('close');
        },
    },
}
</script>
<style scoped>

</style>