var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container" },
    [
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row mt-0" }, [
          _c("div", { staticClass: "col" }, [
            _vm.title
              ? _c(
                  "div",
                  {
                    staticClass:
                      "input-forms__label-content input-forms__label-content--bigger"
                  },
                  [
                    _c("ph-bank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.title) + " ")
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.config && _vm.config.buttonText
            ? _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                    _vm._v(_vm._s(_vm.config.buttonText))
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _vm.positions && _vm.positions.length
        ? _c(
            "div",
            { staticClass: "input-forms__input-container" },
            [
              _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowId: "index",
                      hidePagination: ""
                    },
                    on: { "action-DELETE": _vm.openModalDeleteFond },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "einmalBetrag",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "currency",
                                  precision: 2,
                                  value: row.einmalBetrag,
                                  id: "einmalBetrag" + row.index,
                                  placeholder: "mind. 500,-EUR",
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "einmalBetrag"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "sparplanBetrag",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "currency",
                                  precision: 2,
                                  value: row.sparplanBetrag,
                                  id: "sparplanBetrag" + row.index,
                                  disabled: _vm.disabled,
                                  placeholder: "mind. 10,-EUR"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "sparplanBetrag"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2927431485
                    )
                  })
                : _vm._e(),
              _vm.config.mitSummenbildung &&
              this.positions &&
              this.positions.length
                ? _c(
                    "div",
                    [
                      _c("Summenbildung", {
                        attrs: {
                          antragId: _vm.antragId,
                          summe: _vm.summe,
                          summeSpar: _vm.summeSparplan,
                          mitSparplan: true
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.positions &&
              _vm.positions.length &&
              _vm.config &&
              _vm.config.positionFields
                ? _c("WertpapierAuswahlPositionFields", {
                    staticClass: "mt-3",
                    attrs: {
                      config: _vm.config,
                      categoryId: _vm.categoryId,
                      antragId: _vm.antragId
                    }
                  })
                : _vm._e(),
              _c(
                "BaseModal",
                {
                  ref: "fehlerModal",
                  attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "modalTitle",
                        fn: function() {
                          return [
                            _c("ph-warning", {
                              staticClass: "color-danger mr-2",
                              attrs: { size: 16 }
                            }),
                            _c("span", { staticClass: "color-danger" }, [
                              _vm._v("Fehler")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    996223707
                  )
                },
                [
                  _vm._v(
                    " Fehler: Sie dürfen nur " +
                      _vm._s(_vm.config.anzahlFonds) +
                      " Positionen wählen. "
                  )
                ]
              )
            ],
            1
          )
        : _c("div", [_c("div", [_vm._v("Wertpapierauswahl ist leer")])]),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.doDeletePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }