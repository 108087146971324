<template>
<!-- if you change this table component, you may also need to change PrimitiveTable.vue -->
    <div class="table" ref="table">
        <div v-for="{key, style, classes} in headers" :key="key" class="column" :style="style" :ref="key">
            <div class="header-cell">
                <slot :name="'header_' + key" :collapsed="false"/>
            </div>
            <div v-for="({row, id, customRowStyle}, index) in rows" :key="index" class="table-cell" :class="classes" :style="customRowStyle">
                <slot :name="key" :row="row" :id="id">
                    {{row[key]}}
                </slot>
            </div>
            <slot name="bottom__table"></slot>
        </div>
    </div>
</template>
<script>

export default {
    components: {
    },
    props: {
        headers: { 
            type: Array,
            default: () => [],
        },
        rows: { 
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            resizeObserver: null,
            lastEmit: null,
        };
    },
    mounted() {
        this.observeResize();
    },
    updated() {
        this.observeResize();
    },
    methods: {
        observeResize() {
            this.resizeObserver?.disconnect();
            this.resizeObserver = new ResizeObserver(this.emitSizes);
            this.headers.forEach(header => {
                this.resizeObserver.observe(this.$refs[header.key][0]);
            })
            this.emitSizes();
        },
        emitSizes() {
            const widths = {};
            let totalWidth = 0;
            let differsFromLastEmit = !this.lastEmit;
            this.headers.forEach(header => {
                widths[header.key] = Math.ceil(this.$refs[header.key][0].getBoundingClientRect().width);
                totalWidth += widths[header.key];
                differsFromLastEmit = differsFromLastEmit || widths[header.key] != this.lastEmit.widths[header.key];
            })
            if (differsFromLastEmit || this.lastEmit.totalWidth != totalWidth) {
                this.lastEmit = {totalWidth, widths};
                this.$emit("resize", this.lastEmit);
            } else {
                //console.log("blocked emitSizes because no change happened ", {...this.lastEmit}, {totalWidth, widths});
            }
        },
    },
    beforeDestroy() {
        this.resizeObserver?.disconnect();
    },
}
</script>
<style scoped>
.table {
    display: flex;
    flex-direction: row;
    font-size: 0.9em;
}
.column {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}
</style>
