<template>
  <div>
    <InputField v-if="!isDialogFVV"
      label="Bargeld"
      v-model="cash"
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @input="updateStore('finance', 'cash', $event || 0)" />
    <InputField class="font-bold"
      label="täglich verfügbare Bankeinlagen (z.B. Tagesgeld, Kontokorrent)"
      v-model="freeBankDeposit"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'freeBankDeposit', $event || 0)" />
    
    <div class="sum d-flex justify-content-between">
      <div class="my-2">Summe aktueller liquider Barvermögen</div>
      <div class="my-2 pr-3"><CurrencyLabel :value="summeVermoegen"/></div>
    </div>
  </div>
</template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
  mixins: [anlegerprofilMixin],
  validators: {},
  components: {
    InputField,
    CurrencyLabel,
  },
  data() {
    return {
      cash: '',
      freeBankDeposit: '',
    };
  },
  computed: {
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'finanzen');
    },
  },
  mounted() {
    this.createForm();
    this.updateWarnings();
  },
  methods: {
    createForm() {
        this.cash = this.data?.finance?.cash || '';
        this.freeBankDeposit = this.data?.finance?.freeBankDeposit || '';
    },
  },
}
</script>

<style scoped>
  .sum {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 1.25rem;
    font-weight: bold;
  }
</style>