<template>
  <BaseModal modalTitle="" :showDefaultButtons="false" ref="finCalcModal">
    <fin-calc-app/>
  </BaseModal>
</template>
<script>
import BaseModal from "@/components/core/BaseModal.vue";
import FinCalcApp from "@/components/financialCalculator/FinCalcApp.vue";
export default {
  components: {
    BaseModal,
    FinCalcApp
  },
  mounted() {
    this.$refs.finCalcModal.open();
  }
};
</script>