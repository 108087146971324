import SEARCH_CONFIGS_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index';

export default {
  [SEARCH_CONFIGS_TYPES.MUTATIONS.SAVE_TABLE_SORT_OPTIONS](state, payload) {
    Vue.set(state.tableSortOptions, payload.tableId, payload.onSortPayload);
  },

  [SEARCH_CONFIGS_TYPES.MUTATIONS.SAVE_FILTER_OPTIONS](state, payload) {
    Vue.set(state.filterOptions, payload.filterId, payload.filterOptions);
  },

  [SEARCH_CONFIGS_TYPES.MUTATIONS.DELETE_FILTER](state, {searchKey, searchId}) {
    const index = state.loadedFilters?.[searchKey]?.searchParameters?.findIndex(item => item.id === searchId)
    if (index > -1)
      Vue.delete(state.loadedFilters[searchKey].searchParameters, index);
  },

  [SEARCH_CONFIGS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [SEARCH_CONFIGS_TYPES.MUTATIONS.SAVE_FILTER_INTERNAL](state, payload) {
    state.presetFilter = payload;
  },
}
