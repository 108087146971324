var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.positions && _vm.positions.length
        ? _c(
            "div",
            [
              _vm.tableData.records && _vm.tableData.records.length
                ? _c("Table", {
                    attrs: {
                      tableId: "092c3a63-545b-4316-8d02-803a6e7d3258",
                      tableData: _vm.tableData,
                      cardViewEnabled: false,
                      filterEnabled: false,
                      exportEnabled: false,
                      paginationEnabled: false,
                      actions: _vm.columnActions,
                      rowsPerPage: _vm.tableData.records.length
                    },
                    on: {
                      "execute-action": function($event) {
                        return _vm.handleTableAction($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "betrag",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: _vm.betragType,
                                  value: props.data.row[_vm.getBetragId],
                                  id: "betrag" + props.data.row.index,
                                  placeholder: _vm.betragPlaceholder
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      _vm.getBetragId
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "aa",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "percent",
                                  value: props.data.row.aa,
                                  id: "aa" + props.data.row.index,
                                  placeholder: props.data.row.defaultAA
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "aa"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "startmonth",
                          fn: function(props) {
                            return [
                              _c("ComboBox", {
                                attrs: {
                                  type: "startmonth",
                                  value: props.data.row.startmonth,
                                  id: "startmonth" + props.data.row.index,
                                  placeholder: "",
                                  values: _vm.getMonths()
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "startmonth"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1921602534
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _c("div", [_vm._v(" Wertpapierauswahl ist leer ")]),
      _vm.config &&
      _vm.config.buttonText &&
      (!_vm.positions || _vm.positions.length < _vm.maxCount)
        ? _c(
            "div",
            { staticClass: "addPositionBtn" },
            [
              _c(
                "BaseButton",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("fondsfinder")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.config.buttonText))]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.config && _vm.positions && _vm.positions.length > _vm.maxCount
        ? _c("div", { staticClass: "color-danger" }, [
            _vm._v(
              " Es sind maximal " +
                _vm._s(_vm.maxCount) +
                " Fonds erlaubt. Bitte löschen Sie die überzählige Fonds! "
            )
          ])
        : _vm._e(),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.deletePos()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }