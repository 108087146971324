var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editTransactionSplit",
          attrs: {
            modalTitle: "Buchung aufteilen",
            size: "lg",
            showDefaultButtons: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.close()
            },
            onCancelButton: function($event) {
              return _vm.close()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    {
                      staticClass: "editsplits-button",
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _vm.categorySplit.length === 0
                    ? _c(
                        "BaseButton",
                        {
                          staticClass: "editsplits-button",
                          attrs: {
                            disabled:
                              !_vm.originalSplit || !_vm.originalSplit.length
                          },
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        },
                        [_vm._v(" Aufteilung löschen ")]
                      )
                    : _c(
                        "BaseButton",
                        {
                          staticClass: "editsplits-button",
                          attrs: { disabled: !!_vm.errorMessage },
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        },
                        [_vm._v(" Speichern ")]
                      )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "text-muted" }, [_vm._v("Konto")]),
              _c("div", [_vm._v(_vm._s(_vm.accountName))])
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "text-muted" }, [_vm._v("Empfänger")]),
              _c("div", [_vm._v(_vm._s(_vm.name))])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "text-muted" }, [
                _vm._v("Verwendungszweck")
              ]),
              _c("div", [_vm._v(_vm._s(_vm.purpose))])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "text-muted" }, [_vm._v("Buchung")]),
              _c("div", [_vm._v(_vm._s(_vm._f("date")(_vm.entryDate)))])
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "text-muted" }, [
                _vm._v("Original Betrag")
              ]),
              _c("div", [_vm._v(_vm._s(_vm._f("currency")(_vm.totalAmount)))])
            ])
          ]),
          _c("Table", {
            attrs: {
              tableId: "ba077a63-72bb-4bc2-901c-7377e3349676",
              headers: _vm.headers,
              rows: _vm.rows,
              rowId: "categoryKey",
              rowsPerPage: 10
            },
            on: {
              "action-REMOVE_SPLIT": function($event) {
                return _vm.removeSplit($event.index)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "categoryKey",
                fn: function(row) {
                  return [
                    _c("ComboBox", {
                      attrs: { values: _vm.categoriesComboBox },
                      model: {
                        value: _vm.categorySplit[row.index].categoryKey,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.categorySplit[row.index],
                            "categoryKey",
                            $$v
                          )
                        },
                        expression: "categorySplit[row.index].categoryKey"
                      }
                    })
                  ]
                }
              },
              {
                key: "amount",
                fn: function(row) {
                  return [
                    _c("InputField", {
                      attrs: { type: "currency", precision: 2 },
                      model: {
                        value: _vm.categorySplit[row.index].amount,
                        callback: function($$v) {
                          _vm.$set(_vm.categorySplit[row.index], "amount", $$v)
                        },
                        expression: "categorySplit[row.index].amount"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm.errorMessage
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "d-flex justify-content-end" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-auto verbleibend__label" }, [
                _c("span", [
                  _vm._v(
                    "verbleibend: " + _vm._s(_vm._f("currency")(_vm.amountLeft))
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.addSplitTransaction()
                        }
                      }
                    },
                    [_vm._v(" Kategorie hinzufügen ")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }