<template>
  <div>
    <DragnDropArea 
      @files="dropFiles"
      hoverText="Datei hier ablegen"
      :disabled="isDisabled || !hochladen"
    >
      <Table
        :title="title"
        :headers="headers"
        :rows="rows"
        hidePagination
        @action-DELETE="deleteAttachment"
      >
        <template #name="row">
          <DownloadLink
            :title="row.dateiname || row.filename"
            :filename="row.fileName || row.filename"
            downloadServicePath="/get_simple_file"
            :queryParams="{
              fileId: row.id,
            }"
          />
        </template>
      </Table>

      <div class="mt-3">
        <BaseFileSelect
          v-if="hochladen"
          :disabled="isDisabled"
          :accept="acceptFileTypes"
          @files="dropFiles"
        >
          Datei hochladen
        </BaseFileSelect>
        

        <BaseButton
          v-if="dokumentenarchiv"
          :disabled="isDisabled"
          label="Dokumentenarchiv"
          @click="handleAddDocuments"
        />
      </div>
    </DragnDropArea>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';

import BaseButton from "@/components/core/BaseButton.vue";
import Table from "@/components/table2/Table.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import { mapGetters } from "vuex";
import {
  SlotColumn,
  ActionColumn,
  SimpleAction,
} from "@/components/table2/table_util";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import axios from 'axios';
import { uploadFileChunked } from '@/helpers/upload-helper'

export default {
  components: {
    BaseButton,
    Table,
    DownloadLink,
    DragnDropArea,
    BaseFileSelect,
  },
  props: {
    componentId: {},
    antragId: "",
    antragData: {},
    config: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    antrag: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "Anhänge",
    },
    //"image/png, image/jpeg, .pdf"
    acceptFileTypes: {
      type: String,
      default: "*/*"
    },  
  },
  computed: {
    ...mapGetters({
      attachmentsTemp: ANTRAG_TYPES.GETTERS.GET_ATTACHMENTS,
      orderAntrag: WERTPAPIERORDER_TYPES.GETTERS.ANTRAG,
    }),
    dokumentenarchiv() {
      return !this.config?.disableDokumentenarchiv
    },
    hochladen() {
      return !this.config?.disableHochladen
    },
    isDisabled() {
      return this.disabled || this.isDataReadOnly || this.isAlreadySigned
    },
    isAlreadySigned() {
      return this.antragData && this.antragData['isSigniert'] === '1';
    },
    isDataReadOnly() {
      return !!(this.antragData && this.antragData['READ_ONLY']);
    },
    attachments() {
      return this.realAntrag?.attachments || []
    },
    rows() {
      const actions = [SimpleAction("DELETE", "PhTrash", "Löschen")];
      return this.attachments.map(row => ({
        ...row,
        actions,
      }));
    },
    headers() {
      return {
        lockedLeft: [SlotColumn("name", "Dateiname", 200, 1)],
        lockedRight: [ActionColumn("actions", "")],
      };
    },
    isWertpapierorder() {
      return !this.antragId
    },
    realAntrag() {
      if (this.isWertpapierorder)
        return this.orderAntrag
      return this.antrag
    },
    fileTyp() {
      return this.config?.fileTyp
    },
  },
  methods: {
    async dropFiles(files) {
        const tempFileIds = []
        const result = files.map(file => {
            return new Promise((resolve, reject) => {
                uploadFileChunked(file).then(tempFileId => {
                  tempFileIds.push(tempFileId)
                  resolve()
                }).catch(() => reject())
            });
        });

        Promise.all(result).then(() => {
          this.addAttachments(tempFileIds)
        })
    },
    
    /**
     * We need HIDDEN_DATA because we are calling the getAntragFile from the old MSC. If we don't change any data in the antrag
     * it does not update the PDF. I also had to add the 'INPUT_HIDDEN_DATA' in the ÄnderungsformularServlet. Forms using
     * our saving process should not have that problem.
     */
    async addAttachments(tempFileIds) {
      if (tempFileIds && tempFileIds.length) {
        return axios.post(`${process.env.VUE_APP_API}/antraegeService/attachment`, {}, {
          defaultSpinner: true, 
          params: { antragsnrIntern: this.antragData.antragsnrIntern, tempFileIds, fileTyp: this.fileTyp }
        }).then(response => {
          if (response && response.data) {
            if (!this.isWertpapierorder) {
              this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG, { id: this.antragId, data: { attachments: response.data }});
              this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, {id: this.antragId, data: { HIDDEN_DATA: Date.now() }})
            } else {
              this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_ANTRAG, { attachments: response.data });
              this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA, { HIDDEN_DATA: Date.now() });
            }
           
          }
        })
      }
    },
    deleteAttachment(row) {
      axios.delete(`${process.env.VUE_APP_API}/antraegeService/attachment`, { 
        defaultSpinner: true, 
        params: { antragsnrIntern: this.antragData.antragsnrIntern, fileId: row.id }
      }).then(response => {
          if (response && response.status === 200) {
            const current = [...this.attachments]
            current.splice(current.findIndex(item => item.id === row.id), 1)

            if (!this.isWertpapierorder) {
              this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG, { id: this.antragId, data: { attachments: current }});
              this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, {id: this.antragId, data: { HIDDEN_DATA: Date.now() }})

            } else {
              this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_ANTRAG, { attachments: current });
              this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA, { HIDDEN_DATA: Date.now() });
            }
          }
      })
    },
    handleAddDocuments() {
      let antragsName = this.$route.params.antragsName ?? 'wertpapierorder'
      this.$router.push({
        path: `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${antragsName}/${this.$route.params.step}/antrag-dokumentenarchiv/`,
        query: {
          componentId: this.componentId,
          antragId: this.antragId,
        },
      });
    },
  },
  mounted() {
    // save attachments that were added from documentsarchive store
    if (this.attachmentsTemp && this.attachmentsTemp.length) {
        this.addAttachments(this.attachmentsTemp.map(a => a.id))
        .then(() => this.$store.commit(ANTRAG_TYPES.MUTATIONS.RESET_ATTACHMENTS))
    }
  },
};
</script>
