<template>
  <div class="antrag-components__container">
    <div class="box__container">

      <WertpapierauswahlDepotUebertragung
        :positions="positionData"
        :anzahlFonds="anzahlFonds"
        :titleAnteile="antragName === 'uebertragungsauftrag' ? 'Stückzahl/Nominal' : 'Stückzahl'"
        @save="$emit('save', $event)"
        @fondsfinder="$emit('fondsfinder', $event)">
      </WertpapierauswahlDepotUebertragung>

      <BaseButton class="col-12" @click="$emit('back')">Zurück</BaseButton>
    </div>
  </div>
</template>
<script>

import BaseButton from '@/components/core/BaseButton.vue';
import WertpapierauswahlDepotUebertragung from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierauswahlDepotUebertragung.vue';

export default {
  components: {
    BaseButton,
    WertpapierauswahlDepotUebertragung,
  },
  props: {
    positionData: {
    },
    bankverbindungSelection: {
    },
    anzahlFonds: {
      type: Number,
      default: 4,
    },
    warnings: {
    },
  },
  data () {
    return {
      antragName: '',
    };
  },
    mounted() {
    this.antragName = this.$route.params.antragsName;
  },
}
</script>
<style scoped>

</style>