var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    !_vm.$isSmallScreen
      ? _c("div", { staticClass: "box__title" }, [
          _vm._v("Kommunikationsdaten")
        ])
      : _vm._e(),
    _c(
      "div",
      [
        _vm.isEditable ||
        (this.personId == "person1" &&
          (this.isCustomerLogin || this.isKundenzugang))
          ? _c(
              "BaseButton",
              {
                staticClass: "mb-3",
                on: {
                  click: function($event) {
                    return _vm.openModalAddContact()
                  }
                }
              },
              [_vm._v("Hinzufügen")]
            )
          : _vm._e(),
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                tableId: "14c08a36-4128-40e7-b316-a5281f899506",
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "value",
                rowsPerPage: 10,
                mobileConfig: {
                  title: "Kommunikationsdaten",
                  headers: ["title", "value", "hauptkommunikation"]
                },
                exportConfig: { roottext: "Kommunikationsdaten" }
              },
              on: {
                "click-value": function($event) {
                  return _vm.openLink($event)
                },
                "action-EDIT": _vm.openEditModal,
                "action-DELETE": _vm.openDeleteModal
              }
            })
          : _c("div", [_vm._v("Keine Daten")]),
        _c(
          "BaseModal",
          {
            ref: "addContact",
            attrs: {
              size: "sm",
              modalTitle: "Neue Kommunikationsdaten",
              closeButtonLabel: "Speichern",
              showConfirmButtonSmallScreen: false
            },
            on: {
              onConfirmButton: function($event) {
                return _vm.saveContact()
              },
              onCloseButton: function($event) {
                return _vm.saveContact()
              }
            }
          },
          [
            _c("ComboBox", {
              attrs: {
                label: "Typ",
                values: _vm.contactTypes,
                disabled: !_vm.isEditable && !_vm.isNeededFor2FA,
                firstEmpty: true
              },
              on: {
                change: function($event) {
                  return _vm.formatValue($event, _vm.formAddContact.value)
                }
              },
              model: {
                value: _vm.formAddContact.type,
                callback: function($$v) {
                  _vm.$set(_vm.formAddContact, "type", $$v)
                },
                expression: "formAddContact.type"
              }
            }),
            _c("InputField", {
              attrs: {
                label: _vm.getBeschreibungLabel(_vm.formAddContact.type),
                type: _vm.valueInputType,
                disabled: !_vm.isEditable && !_vm.isNeededFor2FA
              },
              on: {
                change: function($event) {
                  return _vm.formatValue(_vm.formAddContact.type, $event)
                }
              },
              model: {
                value: _vm.formAddContact.value,
                callback: function($$v) {
                  _vm.$set(_vm.formAddContact, "value", $$v)
                },
                expression: "formAddContact.value"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Hauptkommunikation",
                inLineLabel: true,
                disabled:
                  (!_vm.isEditable && !_vm.isNeededFor2FA) ||
                  (_vm.contactToEdit &&
                    _vm.contactToEdit.standart &&
                    _vm.contactToEdit.title === _vm.formAddContact.type)
              },
              model: {
                value: _vm.formAddContact.standart,
                callback: function($$v) {
                  _vm.$set(_vm.formAddContact, "standart", $$v)
                },
                expression: "formAddContact.standart"
              }
            }),
            _c("InputField", {
              attrs: { label: "Bemerkung", disabled: !_vm.isEditable },
              model: {
                value: _vm.formAddContact.comment,
                callback: function($$v) {
                  _vm.$set(_vm.formAddContact, "comment", $$v)
                },
                expression: "formAddContact.comment"
              }
            }),
            _vm.checkFormatCommunication && _vm.checkFormatCommunication.text
              ? _c("div", { staticClass: "fc-form-danger" }, [
                  _vm._v(" " + _vm._s(_vm.checkFormatCommunication.text) + " ")
                ])
              : _vm._e()
          ],
          1
        ),
        _c(
          "BaseModal",
          {
            ref: "deleteContact",
            attrs: {
              size: "sm",
              modalTitle: "Eintrag löschen",
              labelButtonConfirm: "Löschen",
              closeButtonLabel: "Abbrechen",
              showCancelButtonSmallScreen: false
            },
            on: {
              onConfirmButton: function($event) {
                return _vm.deleteContact(_vm.contactToDelete)
              }
            }
          },
          [
            _vm.contactToDelete
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.contactToDelete.title) +
                      " " +
                      _vm._s(_vm.contactToDelete.value) +
                      " wirklich löschen? "
                  )
                ])
              : _vm._e()
          ]
        ),
        _c(
          "BaseModal",
          {
            ref: "showMeldung",
            attrs: {
              size: "md",
              modalTitle: "Hinweis",
              closeButtonLabel: "Weiter",
              showConfirmButtonSmallScreen: false
            },
            on: {
              onConfirmButton: function($event) {
                return _vm.saveContact()
              },
              onCloseButton: function($event) {
                return _vm.saveContact()
              },
              onCancelButton: function($event) {
                _vm.isFAMeldung = false
              }
            }
          },
          [
            _c("p", [
              _vm._v(
                "Wir weisen darauf hin, dass bei den Kundendaten im MSC nur tatsächlich vom Kunden angegebene E-Mailadressen hinterlegt werden dürfen. Es ist nicht zulässig, dass Vertriebspartner ihre E-Mailadressen eintragen, da FinanzAdmin ihren gesetzlichen Verpflichtungen nicht nachkommen kann, welche wir auf diesem Wege erfüllen. Hierzu gehört insbesondere die Kopie der Vertragsunterlagen (Depoteröffnung/Orders/Anlegerprofile etc.) und der jährliche Ex-Post Kostenausweis. "
              )
            ]),
            _c("p", [
              _vm._v(
                "Hat der Kunde keine persönliche E-Mailadresse, sind beispielsweise die Vertrags/Antragsunterlagen in Kopie auszuhändigen, und die Übergabe ist auf der dafür vorgesehenen Übernahmebestätigung von Kunden zu bestätigen. Ex-Post Kostenausweise werden in diesen Fällen von FinanzAdmin per Post übermittelt. "
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }