<template>
  <div v-if="customerDataEdited">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="id"
      :headersSize="isCustomerOnly ? null : 5"
      showWeiterLink
      @onNavigate="navigateToCard"
      hidePagination
    >
    </Table>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import { isTrue } from "@/helpers/mapping.js";
import NoData from '@/components/core/NoData.vue';

const PRECISION = 2;

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "PEP - Politisch exponierte Person",
      pepActive: false,
      fatca: {
        aktiv: false,
        text: null,
      },
      pep: {
        pep_aktiv: "0",
        pep_funktion: "",
        pep_ehe_aktiv: "0",
        pep_ehe_text: "",
        vip_aktiv: "0",
        vip_funktion: "",
        vip_ehe_aktiv: "0",
        vip_ehe_text: "",
        finanzielle_verh_n1: null,
        finanzielle_verh_n2: null,
        finanzielle_verh_n3: null,
        finanzielle_verh_n4: null,
        anfang_betrag: "",
        anfang_herkunft: "",
        anfang_bank: "",
        wirt_ber: false,
      },
      precision: PRECISION,
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
    }),
    /*     pep() {
      return this.customerDataEdited?.pep || this.customerData?.pep;
    }, */
    pepText() {
      if (isTrue(this.pep?.pep_aktiv) || isTrue(this.pep?.vip_aktiv)) {
        return isTrue(this.pep?.pep_aktiv)
          ? this.pep?.pep_funktion
          : this.pep?.vip_funktion;
      } else if (
        isTrue(this.pep?.pep_ehe_aktiv) ||
        isTrue(this.pep?.vip_ehe_aktiv)
      ) {
        const text =
          (isTrue(this.pep?.pep_ehe_aktiv)
            ? this.pep?.pep_ehe_text
            : this.pep?.vip_ehe_text) || "";
        return `(Ehepartner): ${text}`;
      }
      return "";
    },
    headers() {
      const result = {
        center: [],
      };

      result.center.push(
        TextColumn("pepActive", "Politisch exponierte Person / VIP")
      );

      if (this.pepActive) {
        result.center.push(TextColumn("pepText", "Funktion"));
      }

      // Fatca
      result.center.push(
        TextColumn("fatcaAktiv", "FATCA - Foreign Account Tax Compliance Act")
      );

      if (this.fatca.akiv) {
        result.center.push(TextColumn("fatcaText", "Art des USA-Bezugs"));
      }

      if (this.pepActive) {
        if (this.isFirma) {
          // Personendaten öffentliche Person
          result.center.push(
            TextColumn("fullName", "Name der juristischen Person")
          );

          result.center.push(
            TextColumn("pep.handelsregister", "Handelsregister")
          );
          result.center.push(
            TextColumn("pep.wirt_ber", "Wirtschaftlich Berechtigter")
          );
          result.center.push(
            TextColumn(
              "pep.wirt_ber_text",
              "Name und Adresse des / der wirtschaftlich Berechtigten"
            )
          );

          // Öffentliche Funktion des Geschäftsführers / wirtschaftlich Berechtigten
          result.center.push(
            TextColumn("pep.pep_aktiv", "PEP – Politisch exponierte Person")
          );
          result.center.push(
            TextColumn("pep.pep_funktion", "Folgende Funktion")
          );
          result.center.push(
            TextColumn(
              "pep.pep_ehe_aktiv",
              "Ist der Depotinhaber ein Ehepartner/Lebensgefährte oder verwandt mit einer Person, die diese Funktion ausübt?"
            )
          );
          result.center.push(
            TextColumn("pep.pep_ehe_text", "Detailierte Angaben")
          );
          result.center.push(
            TextColumn("pep.vip_aktiv", "VIP – Very important Person")
          );
          result.center.push(
            TextColumn("pep.vip_funktion", "Folgende Funktion")
          );
          result.center.push(
            TextColumn(
              "pep.vip_ehe_aktiv",
              "Ist der Depotinhaber ein Ehepartner/Lebensgefährte oder verwandt mit einer Person, die diese Funktion ausübt?"
            )
          );
          result.center.push(
            TextColumn("pep.vip_ehe_text", "Detailierte Angaben")
          );

          // Finanzielle Verhältnisse
          result.center.push(
            TextColumn("pep.finanzielle_verh_f1", "Bilanzsumme €")
          );
          result.center.push(TextColumn("pep.finanzielle_verh_f2", "Umsatz €"));
          result.center.push(TextColumn("pep.finanzielle_verh_f3", "Gewinn €"));
          result.center.push(
            TextColumn("pep.finanzielle_verh_f4", "Anzahl der Mitarbeiter")
          );

          // Angaben über die anfänglich eingebrachten Vermögenswerte
          result.center.push(
            TextColumn("pep.anfang_betrag", "Währung / Betrag")
          );
          result.center.push(TextColumn("pep.anfang_herkunf", "Herkunft"));
          result.center.push(TextColumn("pep.anfang_bank", "Hausbank"));
          result.center.push(
            TextColumn("pep.sonstiges", "Sonstige Informationen")
          );
        } else {
          // Personendaten öffentliche Person
          result.center.push(TextColumn("fullName", "Name"));

          // PEP – Politisch exponierte Person
          result.center.push(
            TextColumn("pep.pep_aktiv", "PEP – Politisch exponierte Person")
          );
          result.center.push(
            TextColumn("pep.pep_funktion", "Folgende Funktion")
          );
          result.center.push(
            TextColumn(
              "pep.pep_ehe_aktiv",
              "Ist der Depotinhaber ein Ehepartner/Lebensgefährte oder verwandt mit einer Person, die diese Funktion ausübt?"
            )
          );
          result.center.push(
            TextColumn("pep.pep_ehe_text", "Detailierte Angaben")
          );

          // VIP – Very important Person<
          result.center.push(
            TextColumn("pep.vip_aktiv", "VIP – Very important Person")
          );
          result.center.push(
            TextColumn("pep.vip_funktion", "Folgende Funktion")
          );
          result.center.push(
            TextColumn(
              "pep.vip_ehe_aktiv",
              "Ist der Depotinhaber ein Ehepartner/Lebensgefährte oder verwandt mit einer Person, die diese Funktion ausübt?"
            )
          );
          result.center.push(
            TextColumn("pep.vip_ehe_text", "Detailierte Angaben")
          );

          // Finanzielle Verhältnisse
          result.center.push(
            TextColumn("pep.finanzielle_verh_n1", "Einkommen €")
          );
          result.center.push(
            TextColumn("pep.finanzielle_verh_n2", "Vermögen €")
          );
          result.center.push(
            TextColumn("pep.finanzielle_verh_n3", "Immobilien €")
          );
          result.center.push(
            TextColumn("pep.finanzielle_verh_n4", "Verpflichtungen €")
          );

          // Angaben über die anfänglich eingebrachten Vermögenswerte
          result.center.push(
            TextColumn("pep.anfang_betrag", "Währung / Betrag")
          );
          result.center.push(TextColumn("pep.anfang_herkunft", "Herkunft"));
          result.center.push(TextColumn("pep.anfang_bank", "Hausbank"));
          result.center.push(
            TextColumn("pep.wirt_ber", "Wirtschaftlich Berechtigter")
          );
        }
      }

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      row.pepActive = this.pepActive ? "Ja" : "Nein";
      row.pepText = this.education?.schoolTime1 || "./.";

      // Fatca
      row.fatcaAktiv = this.fatca.aktiv ? "Ja" : "Nein";

      if (this.fatca.akiv) {
        row.fatcaText = this.fatca.text;
      }

      if (this.pepActive) {
        if (this.isFirma) {
          row.fullName = this.fullName || "-";
          row["pep.handelsregister"] = this.pep.handelsregister ? "Ja" : "Nein";
          row["pep.wirt_ber"] = this.pep.wirt_ber ? "Ja" : "Nein";
          row["pep.wirt_ber_text"] = this.pep.wirt_ber_text || "-";
          row["pep.pep_aktiv"] = this.pep.pep_aktiv ? "Ja" : "Nein";
          row["pep.pep_funktion"] = this.pep.pep_funktion || "-";
          row["pep.pep_ehe_aktiv"] = this.pep.pep_ehe_aktiv ? "Ja" : "Nein";
          row["pep.pep_ehe_text"] = this.pep.pep_ehe_text || "-";
          row["pep.vip_aktiv"] = this.pep.vip_aktiv ? "Ja" : "Nein";
          row["pep.vip_funktion"] = this.pep.vip_funktion || "-";
          row["pep.vip_ehe_aktiv"] = this.pep.vip_ehe_aktiv ? "Ja" : "Nein";
          row["pep.vip_ehe_text"] = this.pep.vip_ehe_text || "-";
          row["pep.finanzielle_verh_f1"] = this.pep.finanzielle_verh_f1 || "-";
          row["pep.finanzielle_verh_f2"] = this.pep.finanzielle_verh_f2 || "-";
          row["pep.finanzielle_verh_f3"] = this.pep.finanzielle_verh_f3 || "-";
          row["pep.finanzielle_verh_f4"] = this.pep.finanzielle_verh_f4 || "-";
          row["pep.anfang_betrag"] = this.pep.anfang_betrag || "-";
          row["pep.anfang_herkunft"] = this.pep.anfang_herkunft || "-";
          row["pep.anfang_bank"] = this.pep.anfang_bank || "-";
          row["pep.sonstiges"] = this.pep.sonstiges || "-";
        } else {
          row.fullName = this.fullName || "-";
          row["pep.pep_aktiv"] = this.pep.pep_aktiv ? "Ja" : "Nein";
          row["pep.pep_funktion"] = this.pep.pep_funktion || "-";
          row["pep.pep_ehe_aktiv"] = this.pep.pep_ehe_aktiv ? "Ja" : "Nein";
          row["pep.pep_ehe_text"] = this.pep.pep_ehe_text || "-";
          row["pep.vip_aktiv"] = this.pep.vip_aktiv ? "Ja" : "Nein";
          row["pep.vip_funktion"] = this.pep.vip_funktion || "-";
          row["pep.vip_ehe_aktiv"] = this.pep.vip_ehe_aktiv ? "Ja" : "Nein";
          row["pep.vip_ehe_text"] = this.pep.vip_ehe_text || "-";
          row["pep.finanzielle_verh_n1"] = this.pep.finanzielle_verh_n1 || "-";
          row["pep.finanzielle_verh_n2"] = this.pep.finanzielle_verh_n2 || "-";
          row["pep.finanzielle_verh_n3"] = this.pep.finanzielle_verh_n3 || "-";
          row["pep.finanzielle_verh_n4"] = this.pep.finanzielle_verh_n4 || "-";
          row["pep.anfang_betrag"] = this.pep.anfang_betrag || "-";
          row["pep.anfang_herkunft"] = this.pep.anfang_herkunft || "-";
          row["pep.anfang_bank"] = this.pep.anfang_bank || "-";
          row["pep.wirt_ber"] = this.pep.wirt_ber || "-";
        }
      }

      rows.push(row);
      return rows;
    },
    tableId() {
      const uniqueUUID = "ddb2e216-6845-444d-b57c-5714bba06736";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(`/persoenlichedaten/customer-data/steps/pep`);
    },
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
    updateGroupsIntoData() {
      this.updateFieldIntoData("pepActive");
      this.updateGroupIntoData("fatca");
      this.updateGroupIntoData("pep");
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>