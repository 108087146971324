<template>
  <div>
    <BaseModal
        ref="editMappeModal"
        :modalTitle="isEdit ? 'Beratungsmappe bearbeiten' : 'Beratungsmappe erstellen'"
        :labelButtonConfirm="isEdit ? 'Speichern' : 'Erstellen'"
        :confirmDisabled="(!bezeichnungValid || !mappeData.mappeTyp) && !isBeratungsmappeClosed || (isMappeTypVVSchema && (isMissingMaklerLegitimations || !!beratungsmappeSelections.errorsWealthKonzept))"
        @onConfirmButton="confirmButton"
    >
      <GhostLoading v-if="loading">
        <Block type="form-input" />
        <Block type="form-input" />
        <Block type="form-input" />
        <Block height="100" />
      </GhostLoading>

      <template v-else>

        <InputField
          label="Titel (max. 99 Zeichen)"
          :renderDanger="!bezeichnungValid"
          v-model="mappeData.bezeichnung"
          :disabled="isBeratungsmappeClosed"
        />

        <ComboBox
          label="Verwende"
          v-model="mappeData.mappeTyp"
          :values="mappeTypenValues"
          :disabled="isBeratungsmappeClosed"
          @change="setDefaultLagerstelle"
        />

        <ComboBox
          label="Lagerstelle"
          v-if="showLagerstelle"
          v-model="mappeData.lagerstelle"
          :values="lagerstellenValues"
          :disabled="isBeratungsmappeClosed"
        />

        <InputTextArea
          label="Bemerkung zur Beratung / Vermittlung (für Berater des Kunden sichtbar)"
          v-model="mappeData.bemerkung"
        />

        <div v-if="isMappeTypVVSchema && this.beratungsmappeSelections.errorsWealthKonzept" class="error">
            <p>{{ beratungsmappeSelections.errorsWealthKonzept }}</p>
        </div>

        <div v-if="isMappeTypVVSchema && isMissingMaklerLegitimations" class="error">
            <p>Leider liegen nicht alle benötigten Ausweiskopien und/oder Daten vor. Bitte klicken Sie auf den Link um diese zu hinterlegen. Ohne die vollständig hinterlegten Anforderungen können leider keine Beratungsmappen für die Vermögensverwaltung erstellt werden.</p>
            <div v-for="(makler, personId) in missingMaklerLegitimations">
              <h5><a @click="redirectToMitarbeiter(personId)">{{ makler.name }}</a></h5>
              <ul>
                <li v-for="error in getErrorList(makler.errors)">{{ error.trim() }}</li>
              </ul>
            </div>
        </div>

      </template>
    </BaseModal> 
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import BERATUNGSMAPPE_TYPES from "@/store/beratungsmappe/types";
import VV_TYPES from '@/store/vermoegensverwaltung/types';

import ROLES from '@/router/roles';

import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue'
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

export default {
  mixins: [],
  components: {
    BaseModal,
    ComboBox,
    InputField,
    InputTextArea,
    GhostLoading,
    Block,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isBeratungsmappeClosed: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      loading: false,
      mappeData: {
          bezeichnung: null,
          mappeTyp: null,
          lagerstelle: null,
          bemerkung: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      beratungsmappeSelections: BERATUNGSMAPPE_TYPES.GETTERS.BERATUNGSMAPPE_SELECTIONS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      missingMaklerLegitimations: VV_TYPES.GETTERS.MISSING_LEGITIMATIONS,

    }),
    isMissingMaklerLegitimations() {
      return this.missingMaklerLegitimations && Object.keys(this.missingMaklerLegitimations).length > 0;
    },
    isMappeTypVVSchema() {
      return this.mappeData.mappeTyp === 'WEALTH_KONZEPT_2018' || this.mappeData.mappeTyp === 'DIALOG_FVV_2018_SCHEMA';
    },
    mappeTypenValues() {
      return this.beratungsmappeSelections.typen || []
    },
    maklernr() {
      return this.loginData?.maklernr;
    },
    lagerstellen() {
      return this.beratungsmappeSelections.lagerstellen || {}
    },
    lagerstellenValues() {
      return this.lagerstellen[this.mappeData.mappeTyp]?.comboboxSelection || []
    },
    showLagerstelle() {
      return !!this.lagerstellenValues.length
    },
    bezeichnungValid() {
      return this.mappeData.bezeichnung && this.mappeData.bezeichnung.length < 100
    }
  },
  methods: {
    open(initData = {}) {
      if (!this.isBroker) {
        if (!Object.keys(this.beratungsmappeSelections).length) {
          this.loading = true
          this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNGSMAPPE_SELECTIONS)
            .finally(() => {
              this.loading = false;

              if (this.mappeTypenValues.some(typ => typ.value.includes('WEALTH_KONZEPT'))) {
                this.$store.dispatch(VV_TYPES.ACTIONS.GET_MISSING_LEGITIMATIONS);
              }
            })
        }


        this.mappeData = {
          bezeichnung: null,
          mappeTyp: null,
          lagerstelle: null,
          bemerkung: null,
          ...initData,
          mappeTyp: initData.mappeTyp || (this.hasRoles([ROLES.IS_VERMITTLUNGSKNUDE]) ? 'VERMITTLUNG_2018' :'BERATUNG_2018'),
        }

        this.$refs.editMappeModal.open();
      }
    },
    confirmButton() {
      this.$emit('confirm', this.isBeratungsmappeClosed ? { bemerkung: this.mappeData?.bemerkung } :  this.mappeData)
    },
    setDefaultLagerstelle(data) {
      this.mappeData.lagerstelle = this.lagerstellen[data]?.defaultValue
    },
    redirectToMitarbeiter(mitarbeiterId) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId: this.maklernr, path: `/intern/ansprechpartner/${mitarbeiterId}` });

    },
    getErrorList(errorString) {
      return errorString[0].split(':')[1].split(',');
    }
  },
}
</script>
<style>
.error {
  color: var(--color-danger);
}
</style>
