<template>
  <Tabs 
    v-if="tabList.length > 1"
    :tabList="tabList" 
    :selected="selected" 
    :disabled="disabled" 
    class="mt-1" 
    @tabSelected="$emit('tabSelected', $event)" 
  />
</template>

<script>
import Tabs from '@/components/tabs/Tabs.vue';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';

import { VIEW_ROLES, ROLES } from '@/router/roles';

import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig.js'

export default {
  components: {
    Tabs,
  },
  props: {
    selected: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      allowConfigGlobal: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ALLOW_CONFIG_GLOBAL,
    }),
    tabList() {
      const { hasRoles, allowConfigGlobal, } = this;

      const tabs = [];

      if(allowConfigGlobal) {
        tabs.push({ key: FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR, label: 'global' });
      }

      if(hasRoles([[VIEW_ROLES.VIEW_BROKER_AS_INTERN], [VIEW_ROLES.VIEW_BROKER, ROLES.IS_MAKLERZUGANG], [VIEW_ROLES.VIEW_BROKER, ROLES.IS_BYPASS_SLASH]])) {
        tabs.push({ key: FC_CONFIG_USER_LEVEL.MAKLER, label: 'Vermittler' });
      } else if(hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS], [ROLES.IS_KUNDENZUGANG], [VIEW_ROLES.VIEW_CUSTOMER, ROLES.IS_BYPASS_SLASH]])) {
        tabs.push({ key: FC_CONFIG_USER_LEVEL.KUNDE, label: 'Kunden' });
        tabs.push({ key: FC_CONFIG_USER_LEVEL.MAKLER, label: 'alle Kunden' });
      }

      return [ ...tabs, ];
    },
  },
}
</script>
