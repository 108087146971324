<template>
  <div>
    <DepotpositionsChartsDashboard
      :depotid="depotid"
      :subtitle="depotTitle" 
      :headerActions="headerActions"
      headerTitle="Diagramme"
    />
  </div>
</template>
<script>
import DepotpositionsCharts from '@/components/depotpositions/DepotpositionsCharts.vue'
import DepotpositionsChartsDashboard from '@/components/depotpositions/DepotpositionsChartsDashboard.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
import { mapGetters } from "vuex";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderPortfolioSelectorAction, } from '@/components/core/header-title-navigation/page-header-utils';
import CORE_TYPES from '@/store/core/types'

export default {
  mixins: [],
  computed: {
    ...mapGetters({
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      depotTitle: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
    }),
    headerActions() {
      return [
        PageHeaderPortfolioSelectorAction('SELECT-DEPOT'),
      ];
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
  components: {
    DepotpositionsCharts,
    DepotpositionsChartsDashboard,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
}
</script>
