export const MUTATION_PREFIX = 'AUFTRAEGE_TYPES: ';
export const ACTIONS_PREFIX = 'AUFTRAEGE_TYPES_ACTIONS_';
export const GETTERS_PREFIX = 'AUFTRAEGE_TYPES_GETTERS_';

export default {
  MUTATIONS: {
    GET_AUFTRAEGE_SUCCESS: MUTATION_PREFIX + 'GET_AUFTRAEGE_SUCCESS',
    SET_DEFAULT_FILTERS: MUTATION_PREFIX + 'SET_DEFAULT_FILTERS',
    REMOVE_AUFTRAG_SUCCESS: MUTATION_PREFIX + 'REMOVE_AUFTRAG_SUCCESS',
    UPDATE_AUFTRAG_SUCCESS: MUTATION_PREFIX + 'UPDATE_AUFTRAG_SUCCESS',
    GET_AENDERUNGS_LOG_SUCCESS: MUTATION_PREFIX + 'GET_AENDERUNGS_LOG_SUCCESS',
    GET_EMAILS_SENT_SUCCESS: MUTATION_PREFIX + 'GET_EMAILS_SENT_SUCCESS',
    AUFTRAG_BESTAETIGUNG_SUCCESS: MUTATION_PREFIX + 'AUFTRAG_BESTAETIGUNG_SUCCESS',
    IST_BEARBEITER_SUCCESS: MUTATION_PREFIX + 'IST_BEARBEITER_SUCCESS',
  },
  ACTIONS: {
    GET_AUFTRAEGE: ACTIONS_PREFIX + 'GET_AUFTRAEGE',
    GET_PDF_LIST: ACTIONS_PREFIX + 'GET_PDF_LIST',
    REMOVE_AUFTRAG: ACTIONS_PREFIX + 'REMOVE_AUFTRAG',
    UPDATE_AUFTRAG: ACTIONS_PREFIX + 'UPDATE_AUFTRAG',
    RESET_VERSAND: ACTIONS_PREFIX + 'RESET_VERSAND',
    GET_VERTRAEGE: ACTIONS_PREFIX + 'GET_VERTRAEGE',
    SAVE_NEW_AUFTRAG: ACTIONS_PREFIX + 'SAVE_NEW_AUFTRAG',
    AUFTRAG_STORNIEREN: ACTIONS_PREFIX + 'AUFTRAG_STORNIEREN',
    GET_AENDERUNGS_LOG: ACTIONS_PREFIX + 'GET_AENDERUNGS_LOG',
    GET_EMAILS_SENT: ACTIONS_PREFIX + 'GET_EMAILS_SENT',
    AUFTRAG_BESTAETIGUNG: ACTIONS_PREFIX + 'AUFTRAG_BESTAETIGUNG',
    IST_BEARBEITER: ACTIONS_PREFIX + 'IST_BEARBEITER',
  },
  GETTERS: {
    AUFTRAEGE: GETTERS_PREFIX + 'AUFTRAEGE',
    IST_BEARBEITER: GETTERS_PREFIX + 'IST_BEARBEITER',
    HAS_ESTIMATED_DONE: GETTERS_PREFIX + 'HAS_ESTIMATED_DONE',
    DEFAULT_FILTERS: GETTERS_PREFIX + 'DEFAULT_FILTERS',
    AENDERUNGS_LOG: GETTERS_PREFIX + 'AENDERUNGS_LOG',
    GET_EMAILS_SENT: GETTERS_PREFIX + 'GET_EMAILS_SENT',
  }
}
