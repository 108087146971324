import Vue from 'vue';
import store from '@/store';
import MENU_TYPES from '@/store/menu/types'
import router from '@/router';
import { navigateResettingBreadcrumb } from '@/router/breadcrumb';

const TrackMenu = (groupMenu) => {
  const createTrackMenu = (groupMenu, selectedSubMenu) => ({
    groupMenu,
    selectedSubMenu,
    selectSubMenu(menu) {
      this.selectedSubMenu = menu;
    },
    isActive(menu) {
      if (!menu?.path) return false;
      return [this.groupMenu?.path, this.selectedSubMenu?.path].indexOf(menu.path) >= 0;
    },
    copy() {
      return createTrackMenu(this.groupMenu, this.selectedSubMenu);
    },
  });

  return createTrackMenu(groupMenu, null);
};

// TODO replace it to use Composition API
const track = new Vue({
  data() {
    return {
      started: false,
      list: [], 
      index: -1, 
    };
  },
  computed: {
    _currentList() {
      const { list, index } = this;
      if (index >= 0) {
        return [ ...list.slice(0, index) ];
      }
      return [ ...list ];
    },
    isBackingActive() {
      return this.index >= 0;
    },
    showBackButton() {
      return this._currentList.length > 1;
    },
    _currentHead() {
      return this._currentList?.[0] || {};
    },
    _currentTail() {
      return this._currentList?.[this._currentList.length - 1] || {};
    },
    currentPath() {
      return this._currentTail?.groupMenu?.path;
    },
    currentSubmenuPath() {
      return this._currentTail?.selectedSubMenu?.path;
    },
    asGrid() {
      return !this._currentTail?.selectedSubMenu || this._currentTail?.selectedSubMenu?.group;
    },
  },
  methods: {
    start(path) {
      if (!path) {
        console.error('menu-track.js >>> a path must be passed to "menuTrack.start(<path>)"');
        return;
      }

      if (this.started) return;

      this.started = true;
      if (!start(path))  {
        this.started = false; // revert when starting has not succeed
      }
    },
    close() {
      this.started = false;
      this._reset();
    },
    copy() {
      return [ ...this.list || [] ]
        .map(item => item.copy());
    },
    _reset() {
      this.list = [];
      this.index = -1;
    },
    _resetKeepingCurrentList() {
      this.list = [ ...this._currentList ];
      this.index = -1;
    },
    prev() {
      const { index } = this;
      if (index < 0) {
        this.index = this.list.length - 1;
      } else {
        this.index = index - 1;
      }
    },
    next() {
      const { index } = this;
      this.index = index + 1;
    },
    async _navigateOnly(menu) {
      const isWorkspace = !menu.parent;
      const isGroup = isWorkspace || menu.group;

      const { path } = menu;
      await navigateResettingBreadcrumb(path, false, isGroup);
    },
    async navigate(menu) {
      const isWorkspace = !menu.parent;
      const isGroup = isWorkspace || menu.group;
      const { path } = menu;
      await this._navigateOnly(menu);

      if (isWorkspace) {
        this._reset();
      } else {
        this._resetKeepingCurrentList();
      }
      this.add(menu);

      if (isGroup) {
        // when menu has redirect
        const { currentMenu } = store.getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_ROUTE_PATH](router?.currentRoute?.path);
        if (currentMenu?.path !== path) {
          this.add(currentMenu);
        }
      }
    },
    async navigateToPath(path) {
      const { currentMenu } = store.getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_ROUTE_PATH](path);
      await this.navigate(currentMenu);
    },
    async navigateRestarting(menu) {
      await this._navigateOnly(menu);
      start(menu.path);
    },
    async navigateRestartingToPath(path) {
      const { currentMenu } = store.getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_ROUTE_PATH](path);
      await this.navigateRestarting(currentMenu);
    },
    add(menu) {
      if (!menu) return;

      if (!menu.parent) {
        this.list.push(TrackMenu(menu));
      } else {
        const tail = this.list[this.list.length - 1];
        tail.selectSubMenu(menu);

        // when it is a group menu start a new track menu item
        if (menu.group) {
          this.list.push(TrackMenu(menu));
        }
      }
    },
    addAll(menus) {
      if (!menus?.length) return;
      menus.forEach(menu => this.add(menu));
    },
    isMenuActive(menu) {
      // when it is a primary menu it checks the head of the list
      const track = !menu.parent ? this._currentHead : this._currentTail;
      return track?.isActive?.(menu);
    },
  },
});

function findMenusFromPath(path) {
  if (!path) return [];

  const appNavigation = store.getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_ROUTE_PATH](path);
  const currentMenu = appNavigation?.currentMenu?.$isTabMenu 
    ? appNavigation?.currentMenu?.parent 
    : appNavigation?.currentMenu;

  if (!currentMenu?.path) return;

  const appNavigationByMenuPathFn = store.getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_MENU_PATH];

  const isGroup = menu => !!menu.group;

  const findParents = (menu) => {
    if (menu?.parent) {
      return [
        ...findParents(menu.parent),
        menu.parent,
      ];
    }
    return [];
  };

  const generateMenus = menu => ([
    ...findParents(menu), 
    menu, 
  ]);

  const extractCurrentMenuStructure = menuList => {
    return menuList.flatMap(menuItem => {
      const { currentOptionMenu } = appNavigationByMenuPathFn(menuItem.path);
      return currentOptionMenu?.map(item => ({
        ...item,
        parent: menuItem,
      }));
    });
  };

  let menus = generateMenus(currentMenu);

  const isDefaultMenuStructureVisible = [ ...menus ]
    .reverse()
    .every(menu => {
      if (!menu.parent) return true;

      const parentNavigation = appNavigationByMenuPathFn(menu.parent.path);
      return parentNavigation?.currentOptionMenu
        ?.findIndex(parentItem => parentItem.path === menu.path) >= 0;
    });

  if (!isDefaultMenuStructureVisible) {
    // when it is moved by configuration, tries to find the current menu inside a primary / group menu
    const extractedMenuStructure = [];
    const primaryMenus = store.getters[MENU_TYPES.GETTERS.PRIMARY_MENU];
    let menuStructureCurrent = extractCurrentMenuStructure(primaryMenus);
    extractedMenuStructure.push(...menuStructureCurrent);
    while (menuStructureCurrent.some(isGroup)) {
      const groupsMenu = menuStructureCurrent.filter(isGroup);
      menuStructureCurrent = extractCurrentMenuStructure(groupsMenu);
      extractedMenuStructure.push(...menuStructureCurrent);
    }

    // find its first occurrence in the new structure
    const currentMenuChanged = extractedMenuStructure.find(menuConfig => menuConfig.path === currentMenu.path);
    if (currentMenuChanged) {
      menus = generateMenus(currentMenuChanged);
    }
  }

  return menus;
}

function start(path) {
  track._reset();
  track.addAll(findMenusFromPath(path));
  return track.list.length > 0;
}

export function recoverList(list) {
  const listCopy = [ ...list || [] ].map(item => item.copy());
  track.$set(track, 'list', listCopy);
}

export default track;
