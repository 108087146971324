<template>
  <BaseModal 
    ref="modal" 
    modalTitle="Fotografieren" 
    :showDefaultButtons="false" 
    @close="cancel"
    :actions="modalActions"
    @action-TAKE-PHOTO="takePhoto"
    @action-CANCEL="cancel"
    @action-ACCEPT-PHOTO="acceptPhoto"
    @action-DISCARD-PHOTO="discardPhoto"
    @action-HIDE-EDGE-SELECTION="hideEdgeSelection=true"
    @action-SHOW-EDGE-SELECTION="hideEdgeSelection=false"
  >
    <template v-if="isVideoVisible" #modalHeaderBottom>
      <div v-if="$isSmallScreen" class="toggle-camera-button__container">
        <BaseButton 
          isClear
          @click="toggleCamera()" 
          :disabled="cameraList.length <= 1">
          <PhCameraRotate :size="32" />
        </BaseButton>
      </div>
      <Combobox 
        v-else
        label="Kamera:" 
        inlineContainer
        @change="changeCamera($event)" 
        :value="deviceId" 
        :values="cameraList" 
        :disabled="cameraList.length <= 1" />
    </template>
    <template #default>
      <div class="input-container" v-if="isVideoVisible">
        <div class="center">
          <video ref="videoElement" autoplay playsinline />
        </div>
        <div v-if="edgeCorrection && inputVideo" class="center" style="position:absolute">
          <EdgeDetection 
            @change="updateCorners" 
            :videoInput="inputVideo" 
            :debugStage="debugStep"/>
        </div>
      </div>
      <div class="input-container" v-else-if="edgeCorrection">
        <div class="center">
          <EdgeSelectionDialog 
            @change="updateImg" 
            :imageData="rawImgUrl" 
            :corners="corners" 
            :hideSelection="hideEdgeSelection" 
            :hideControls="!hideEdgeSelection"/>
        </div>
      </div>
      <div class="input-container" v-else>
        <div class="center">
          <img :src="improvedImgUrl" class="preview"/>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
import WEBRTC_TYPES from '@/store/webrtc/types'
import LOG_TYPES from '@/store/log/types';
import EdgeDetection from './EdgeDetection.vue';
import EdgeSelectionDialog from './EdgeSelectionDialog.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import BaseModal from '@/components/core/BaseModal.vue'
import Combobox from '@/components/core/forms/ComboBox.vue';
import { PhCameraRotate } from 'phosphor-vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

import { FRONT_CAMERA_VALUE, BACK_CAMERA_VALUE, PROP_CAMERA_VALIDS, getCameraMediaDevice, } from './camera-utils';
import { buildMessage } from '@/helpers/log-message-helper';


export default {
  components: {
    EdgeDetection,
    EdgeSelectionDialog,
    BaseButton,
    BaseModal,
    Combobox,
    PhCameraRotate,
  },
  props: {
    camera: {
      type: String,
      default: FRONT_CAMERA_VALUE,
      validator: (value) => {
        return PROP_CAMERA_VALIDS.indexOf(value) >= 0;
      },
    },
    edgeCorrection: {
      type: Boolean,
    },
    debug: {
      type:Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      cameraOptions: WEBRTC_TYPES.GETTERS.CAMERA_OPTIONS,
    }),
    isVideoVisible() {
      return !this.rawImgUrl;
    },
    cameraList() {
      return (this.cameraOptions || []).filter(c => !!c.value); // ignores camera with empty value
    },
    modalActions() {
      const acceptPhotoAction = BaseModalSimpleAction('ACCEPT-PHOTO', 'Fertig');
      const discardPhotoAction = BaseModalSimpleAction('DISCARD-PHOTO', 'Zurück').withPrimary(() => false);

      if (this.isVideoVisible) {
        return [
          BaseModalSimpleAction('TAKE-PHOTO', 'Fotografieren'),
          BaseModalSimpleAction('CANCEL', 'Abbrechen')
            .withVisibleOnSmallScreen(() => false)
            .withPrimary(() => false),
        ];
      } else if (!this.edgeCorrection) {
        return [
          acceptPhotoAction,
          discardPhotoAction,
        ];
      } else if (!this.hideEdgeSelection) {
        return [
          BaseModalSimpleAction('HIDE-EDGE-SELECTION', 'Weiter'),
          discardPhotoAction,
        ];
      } else {
        return [
          acceptPhotoAction,
          BaseModalSimpleAction('SHOW-EDGE-SELECTION', 'Zurück')
            .withPrimary(() => false),
        ]
      }
    },
  },

  data() {
    return {
      corners: null,
      inputVideo: null,
      rawImgUrl: null,
      improvedImgUrl: null,
      debugStep: "-1",
      hideEdgeSelection: false,
      deviceId: null,
      currentCamera: this.camera,
      currentStream: null,
    };
  },

  mounted() {
    window.addEventListener('visibilitychange', this.resumeCamera);

    this.$refs.modal.open()
      .then(() => this.startCamera())
      .then(() => this.$store.commit(WEBRTC_TYPES.MUTATIONS.ENUMERATE_DEVICES));
  },
  methods: {
    // start the virtual camera
    async startCamera() {
      const constraints = {
        video: {
          facingMode: getCameraMediaDevice(this.camera),
        },
      };

      await this.getUserMedia(constraints);
    },
    async getUserMedia(constraints) {
      this.stopCurrentStream();

      return await navigator.mediaDevices.getUserMedia(constraints)
        .then(this.configureVideoStream)
        .catch(this.onGetUserMediaError);
    },
    configureVideoStream(stream) {
      this.stopCurrentStream();

      this.currentStream = stream;
      this.setDeviceId(stream);

      const { videoElement } = this.$refs;
      if (videoElement) {
        this.inputVideo = videoElement;
        videoElement.srcObject = stream;
      } else {
        this.stopCurrentStream();
      }
    },
    onGetUserMediaError(error) {
      this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "getUserMedia error", error});
      if(error.name === 'NotAllowedError') {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Bitte erlauben sie die Verwendung ihrer Kamera.", 'danger'));
      } else {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die Kamera konnte nicht gestartet werden", 'danger'));
      }
    },
    resumeCamera() {
      this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, 'VirtualCamera.vue >>> resumeCamera !!!');

      const isDocumentVisible = document.visibilityState === 'visible';
      const { isVideoVisible } = this;
      if (isDocumentVisible && isVideoVisible) {
        this.$nextTick(() => this.changeCamera(this.deviceId));
      }
    },
    setDeviceId(stream) {
      if(!stream) return;

      const tracks = stream.getVideoTracks();
      if(!tracks?.length) return;

      for(const track of tracks) {
        const settings = track.getSettings();
        if(settings?.deviceId) {
          this.deviceId = settings.deviceId;
          break;
        }
      }
    },
    changeCamera(deviceId) {
      this.deviceId = deviceId;
      const settings = {
        video: {
          deviceId: deviceId ? {exact: deviceId} : undefined,
          ...(!deviceId ? { facingMode: getCameraMediaDevice(this.camera), } : {}),
        },
      };
      this.getUserMedia(settings).catch(error => {
        if (deviceId)
          this.changeCamera('');
        else
          this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'changeCamera getUserMedia error', error});
      })
    },
    getVideoStream(){
      const { videoElement } = this.$refs;
      return videoElement?.srcObject;
    },
    stopCurrentStream() {
      const streams = [this.getVideoStream(), this.currentStream].filter(stream => !!stream);
      if (streams.length > 0) {
        streams.flatMap(stream => stream.getVideoTracks()).forEach(track => track.stop());
      }
    },
    toggleCamera() {
      const currentCamera = this.currentCamera === FRONT_CAMERA_VALUE ? BACK_CAMERA_VALUE : FRONT_CAMERA_VALUE;
      const settings = {
        video: {
          facingMode: getCameraMediaDevice(currentCamera),
        },
      };
      this.getUserMedia(settings);
      this.currentCamera = currentCamera;
    },
    updateCorners (corners) {
      const currentStream = this.getVideoStream();

      if (currentStream.active && currentStream.getVideoTracks()[0].enabled) {
        this.corners = corners;
      }
    },
    takePhoto () {
      const fotoCanvas = document.createElement("canvas");
      const { videoElement } = this.$refs;

      fotoCanvas.width = videoElement.videoWidth;
      fotoCanvas.height = videoElement.videoHeight;
      const ctx = fotoCanvas.getContext("2d");
      ctx.drawImage(videoElement, 0, 0, fotoCanvas.width, fotoCanvas.height);
      this.rawImgUrl = fotoCanvas.toDataURL('image/jpeg');
      this.hideEdgeSelection = false;
      this.improvedImgUrl = this.rawImgUrl;

      this.stopCurrentStream();
    },
    updateImg(urlData) {
      this.improvedImgUrl = urlData;
    },
    discardPhoto () {
      URL.revokeObjectURL(this.rawImgUrl);
      URL.revokeObjectURL(this.improvedImgUrl);
      this.rawImgUrl = null;
      this.improvedImgUrl = null;
      this.$nextTick(() => this.changeCamera(this.deviceId));
    },
    acceptPhoto () {
      this.$emit("input", this.improvedImgUrl);
      this.$refs.modal.close();
    },
    cancel() {
      this.$emit("cancel");
      this.$refs.modal?.close?.();
      this.stopCurrentStream();
    },
  },

  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.resumeCamera);
    this.cancel();
  },

}
</script>

<style scoped>
.toggle-camera-button__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 12px 0 4px;
}

.input-container {
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}
.center {
  -position:absolute;
  top: 0;
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
video, canvas, .preview {
  max-width: 100%;
  max-height: 100%;
}
.camera-label {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex: 1 0 220px;
}
.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>