<template>
  <div class="box__container">
    <div class="box__title">Homepage</div>
    <div>
      <FormDivider />
      <FormLabel
        label="Anforderung: Verfügen Sie im Wertpapierbereich über eine eigene Homepage, hat diese den Hinweis zu beinhalten, dass Sie Wertpapierdienstleistungen im Namen und auf Rechnung der FinanzAdmin durchführen bzw. wenn Sie als Wertpapiervermittler für mehrere Rechtsträger tätig sind, sind diese alle namentlich anzuführen. Der Wortlaut hat wie folgt zu lauten:"
      />
    </div>
    <div>
      <FormLabel :label="label" :config="{ bold: true }" />
    </div>

    <FormLabel
      label="FinanzAdmin bietet die „nicht unabhängige” Anlageaberatung an. Die Homepage darf keine Inhalte, welche auf „unabhängige“ Anlageberatung hinweisen, enthalten."
    />

    <InputRadioBoxGroup
      :value="form.INPUT_HOMEPAGE"
      :values="homepageValues"
      labelClass="font-bold"
      :disabled="this.disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_HOMEPAGE')"
    />

    <InputField
      :value="form.INPUT_HOMEPAGE_TEXT"
      label="Link Homepage:"
      isComponentHalfSize
      :disabled="form.INPUT_HOMEPAGE != 'hp_2' || this.disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_HOMEPAGE_TEXT')"
    />
    <InputField
      :value="form.INPUT_KEINE_HOMEPAGE_BUERO_TEXT"
      isComponentHalfSize
      :disabled="form.INPUT_HOMEPAGE != 'hp_3' || this.disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_KEINE_HOMEPAGE_BUERO_TEXT')"
    />
    <p>
      angeführt werde und dort der genannte Wortlaut unter folgendem Link
      genannt wird:
    </p>
    <InputField
      :value="form.INPUT_KEINE_HOMEPAGE_TEXT"
      isComponentHalfSize
      :disabled="form.INPUT_HOMEPAGE != 'hp_3' || this.disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_KEINE_HOMEPAGE_TEXT')"
    />
    <ChecklisteDocsUpload
      :paramId="this.form.PARAM_ID"
      :topic="'homepage'"
      :form="this.form"
    />

    <div class="box__title">E-Mail Signatur</div>
    <FormLabel
      label="Anforderung: Die E-Mail Korrespondenz mit Ihren Kunden im Rahmen der Finanzdienstleistungen hat den oben angeführten Wortlaut ebenfalls
zu beinhalten."
    />

    <FormLabel
      label="Bestätigung: Mit meiner Unterschrift bestätige ich, dass"
    />

    <InputRadioBoxGroup
      :value="form.INPUT_SIGNATUR_BEST_1"
      :values="signaturValues"
      labelClass="font-bold"
      :disabled="this.disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_SIGNATUR_BEST_1')"
    />

    <ChecklisteDocsUpload
      :paramId="this.form.PARAM_ID"
      :topic="'E-Signatur'"
      :form="this.form"
    />

    <div class="box__title">Visitenkarte</div>
    <FormLabel
      label="Anforderung: Um für den Kunden klar erkennbar zu machen, für wen Sie im Bereich der Finanzdienstleistungen gemäß WAG 2018 tätig werden, ist der oben angeführte Zusatz auf Ihrer Visitenkarte ebenfalls anzuführen (z.B. Rückseite)."
    />
    <InputRadioBoxGroup
      :value="form.INPUT_VISITENKARTE_BEST_1"
      :values="visitenKartenValues"
      labelClass="font-bold"
      :disabled="this.disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_VISITENKARTE_BEST_1')"
    />
    <ChecklisteDocsUpload
      :paramId="this.form.PARAM_ID"
      :topic="'Visitenkarten'"
      :form="this.form"
    />
    <FormDivider />
    <InputToggleSwitch
      label="Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben."
      :inLineLabel="true"
      :config="{ bold: true }"
      isComponentHalfSize
      :disabled="this.disabledStatusOK"
      :value="true"
    />
  </div>
</template>

<script>
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import InputField from "@/components/core/forms/InputField.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import ChecklisteDocsUpload from "./ChecklisteDocsUpload.vue";
import { mapGetters } from "vuex";
import MAKLER_ANTRAG_TYPES from "@/store/maklerAntrag/types";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";

export default {
  components: {
    InputField,
    InputToggleSwitch,
    FormLabel,
    FormDivider,
    ChecklisteDocsUpload,
    InputRadioBoxGroup,
  },
  props: {
    form: {},
  },
  data() {
    return {
      homepageValues: [
        {
          label: "ich über keine eigene Homepage verfüge",
          value: "hp_1",
        },
        {
          label:
            "Ich verfüge über eine eigene Homepage, welche keine Inhalte auf unabhängige Anlageberatung beinhaltet und den angeführten Wortlaut unter folgendem Link angeführt habe",
          value: "hp_2",
        },
        {
          label:
            "ich über keine eigene Homepage verfüge, jedoch im Zuge der Büroorganisation gemeinsam im Team",
          value: "hp_3",
        },
      ],
      signaturValues: [
        {
          label:
            "ich den angeführten Wortlaut in meiner E-Mail Signatur eingefügt habe",
          value: true,
        },
        {
          label: "ich über keine E-Mail Signatur verfüge",
          value: false,
        },
      ],
      visitenKartenValues: [
        {
          label:
            "Ich habe den angeführten Wortlaut auf meinen Visitenkarten angeführt.",
          value: "vk_1",
        },
        { label: "Ich verfüge über keine Visitenkarten.", value: "vk_3" },
        {
          label: "Ich händige im Veranlagungsbereich keine Visitenkarten aus.",
          value: "vk_2",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      maklerAntragConfigData: MAKLER_ANTRAG_TYPES.GETTERS.GET_CONFIG_DATA,
    }),
    label() {
      return (
        "„Die FinanzAdmin ist mein gemäß WAG 2018 konzessionierter Partner im Rahmen der Beratung über und Vermittlung von Finanzinstrumenten. In diesem Tätigkeitsbereich ist " +
        this.maklerAntragConfigData?.nameBerater +
        " ein Erfüllungsgehilfe der FinanzAdmin gemäß § 1313a ABGB und im Namen und Auftrag sowie auf Rechnung der FinanzAdmin tätig.”"
      );
    },
    disabledStatusOK() {
      return (
        this.form.INPUT_STATUS_OK == "1" || this.form.INPUT_STATUS_OK == "true"
      );
    },
  },
  mounted() {
    this.loadConfigData();
  },
  methods: {
    fieldChanged(newValue, propName) {
      const payload = { name: propName, value: newValue };
      console.log("emit " + payload);
      this.$emit("fieldChanged", payload);
    },
    async loadConfigData() {
      await this.$store.dispatch(MAKLER_ANTRAG_TYPES.ACTIONS.LOAD_CONFIG_DATA);
    },
  },
};
</script>
