import { render, staticRenderFns } from "./ChecklistePunkt3.vue?vue&type=template&id=7a036e36&"
import script from "./ChecklistePunkt3.vue?vue&type=script&lang=js&"
export * from "./ChecklistePunkt3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a036e36')) {
      api.createRecord('7a036e36', component.options)
    } else {
      api.reload('7a036e36', component.options)
    }
    module.hot.accept("./ChecklistePunkt3.vue?vue&type=template&id=7a036e36&", function () {
      api.rerender('7a036e36', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/maklerAntraege/ChecklistePunkt3.vue"
export default component.exports