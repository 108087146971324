<template>
<div>
    <PageHeaderTitleNavigation title="BiPRO Lieferungen" />

    <BaseFilter
        title="BiPRO Lieferungen"
        filterId="BiPRO Lieferungen"
        :configFilter="filterConfig"
        :metadata="metadata"
        :defaultOptions="defaultOptions"
        showSaveButton
        :immidiateSearch="true"
        @onFilter="onFilter"
    />

    <div class="box__container">
        <GhostLoading v-if="loading" type="table"/>
        <BigTable v-else-if="rows.length"
            tableId="dcee9bf4-af71-494f-b86d-1688368d345e"
            :headers="headers"
            :rows="rows"
            :page="page"
            :sortingState="sortingState"
            :initialPageSize="minNumRows"
            @onClickHeader="onClickHeader"

        />
        <NoData v-else/>
    </div>
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';
import BIPRO_TYPES from '@/store/bipro/types'

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import { mapGetters } from 'vuex';
import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';

import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, ACTION_COLUMN, FIXED_LEFT, INTEGER_COLUMN, DATETIME_COLUMN, NUMBER_COLUMN} from '@/components/bigTable/BigTable.vue';


const CONFIG = {
    defaultSpinner: true,
};

export default {
    mixins: [],
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        BaseFilter,
        GhostLoading,
        BigTable,
        NoData,
    },
    data() {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        return {
            loading: false,
            rows: [],
            filterConfig: {
                checkDefaultSearchInput: false,
                cbDefSearchInput: null,
                hideFirstColumn: true,
                filterZurucksetzen: null,
                noResetOnDefaultSearchInputExit: true,
            },
            defaultOptions: {
                zeitraum: {
                    value: [{
                        key: "min",
                        value: startDate,
                    },
                    {
                        key: "max",
                        value: new Date(),
                    }],
                },
            },
            filterParams: {},
            headers: [
                { label: "Gesellschaft", key: "extZugangBzeichnung", width: 200, type: STRING_COLUMN, position: FIXED_LEFT },
                { label: "Lieferungsnr", key: "transferId", width: 200, type: STRING_COLUMN, visible: true },
                { label: "Einlesen gestartet", key: "dateStarted", width: 200, type: DATETIME_COLUMN, visible: true },
                { label: "BiPRO Gevo Art", key: "gevoArt", width: 200, type: INTEGER_COLUMN, visible: true },
                { label: "Gesamtanzahl Dateien", key: "allInputFiles", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "PDFs  eingelesen", key: "persistedFiles", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "PDFs nicht eingelesen", key: "nichtzugeordnetePdfFiles", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "Versichung mehrmals vorhanden", key: "nonUniquePolices", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "Versicherung nicht gefunden", key: "notFoundPolices", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "BiPRO liefert keine VersNr", key: "inputUnassigned", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "PDFs schon vorhanden", key: "dbAlreadyExistFiles", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "Schadensmeldung angelegt", key: "schadenLeistungMeldungen", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "Einlesen beendet", key: "dateFinished", width: 200, type: DATETIME_COLUMN, visible: true },
                { label: "BiPRO Lieferungeinstellzeitpunkt", key: "einstellzeitpunkt", width: 200, type: DATETIME_COLUMN, visible: true },
            ], 
            sortingState: { key: "gevoArt", direction: "desc" },
            minNumRows: 100,
            page: 0,

        };
    },
    computed: {
        ...mapGetters({
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
            gesellschaftOptions: BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS_WITH_ALL,
        }),
        metadata() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'dateRange',
                            label: 'Zeitraum',
                            key: 'zeitraum',
                            emptyDenied: true,
                        },
                        {
                            type: 'combobox',
                            label: 'Gesellschaft',
                            key: 'gesellschaft',
                            comboOptions: this.gesellschaftOptions || [],
                        },
                    ],
                },
            ];
        },
    },
    mounted() {
        if (!this.gesellschaftOptions)
            this.$store.dispatch(BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS);

        if (this.isIntern) {
            const newHeaders = [
                { label: "Gesamt Zip-Dateien", key: "inputZips", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "Zip-Dateien eingelesen", key: "abgelegteZipFiles", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "Defekte Zip-Dateien", key: "defekteZipFiles", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "Dateien veraltet", key: "schonErledigteFiles", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "Geschäftsvorfall (CSV erzeugt)", key: "numberGeschaeftsvorfallTransfers", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "Inkasso Transfer (CSV erzeugt)", key: "numberInkassoTransfers", width: 200, type: NUMBER_COLUMN, visible: true },
                { label: "fehlende Gevo Art", key: "fehlendeGevoArts", width: 200, type: NUMBER_COLUMN, visible: true },
            ];

            this.headers = [
                ...this.headers,
                ...newHeaders,                
                ...this.headers.slice(9) 
            ];

            this.headers.push({ label: "Fehlermeldung", key: "error", width: 200, type: STRING_COLUMN, visible: true });

            // action column should always be the last one
            this.headers.push({ label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true });
        }            
    },
    methods: {
        onFilter(filterConfig) {
            if (!this.gesellschaftOptions)
                return;
            this.filterParams = {};

            filterConfig.forEach(fc => {
                let value = fc.value;
                if (fc.key == "zeitraum") {
                    this.filterParams.dateFrom = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
                    this.filterParams.dateTo = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
                } else {
                    this.filterParams[fc.key] = value;
                }
            });
            this.loadRows();
        },
        async loadRows() {
            this.loading = true;
            try {
                const response = await axios.post(`${process.env.VUE_APP_API}/bipro_lieferungen/filter`, this.filterParams, CONFIG)
                this.rows = response.data || [];
            } catch (_) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Suche ist ein Fehler aufgetreten.', 'danger'));
            } finally {
                this.loading = false;
            }
        },
        onClickHeader(headerMetadata) {
            this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
            // TODO: save the sorting state
        },        
    },
}
</script>
