<template>
  <div class="mday" :class="{today: istoday, not_in_month: !inmonth }">
    <div class="innerday">
      <div class="title" :class="[istoday && 'today']">
        <button type="button" class="btn-clear clickable" @click="chooseDay">{{displayLabel}}</button>
      </div>
      <div class="month-events">
        <ExpandedEventView v-for="(alldayevent,alldidx) in getAlldayEventsTrim" :key="alldidx" :event="alldayevent" isOneLineContainer>
          <div data-event-view 
            :style="{ 'background-color': alldayevent.lighterColor }"
            :class="['allday-events', 'event-' + alldayevent.type]" 
            @click="setSelectedEvent(alldayevent)"
          >
            <div class="sameline">
              <component v-if="alldayevent.introIcon" :is="alldayevent.introIcon"/>
              <span v-else-if='alldayevent.startTime !== "00:00" && alldayevent.startTime !== "23:59"'>
                {{ alldayevent.startTime }}
              </span> 
              <span class="event-label">{{alldayevent.label}}</span>
            </div>
          </div>
        </ExpandedEventView>
        <ExpandedEventView v-for="(timedevent,timedidx) in getTimedEventsTrim" :key='timedidx+"timedidx"' :event="timedevent" isOneLineContainer>
          <div data-event-view class="timed-events" @click="setSelectedEvent(timedevent)">
            <div class="sameline">
              <div class="dot" :style="{background: timedevent.color}"></div>
              <span class="event-label">{{timedevent.label}}</span>
            </div>
              <component v-if="timedevent.introIcon" :is="timedevent.introIcon"/>
              <span v-else-if='timedevent.startTime !== "00:00" && timedevent.startTime !== "23:59"'>
                {{ timedevent.startTime }}
              </span> 
          </div>
        </ExpandedEventView>
        <div
          class="timed-events"
          v-if="isShowMore"
        >
          <div class="sameline pl-25" @click="chooseDay">
            {{moreCount}} weitere...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CALENDAR_TYPES from "@/store/calendar/types";
import { PhGift, PhBriefcase } from 'phosphor-vue';
import ExpandedEventView from '@/components/calendar/ExpandedEventView.vue';

export default {
  components: {
    ExpandedEventView,
    PhGift,
    PhBriefcase,
  },
  props: {
    istoday: {
      type: Boolean,
      default: () => false,
      required: true
    },
    inmonth: {
      type: Boolean,
      default: () => true,
      required: true
    },
    daylabel: {
      type: String,
      default: () => "",
      required: true
    },
    verticalLimitCount: {
      type: Number,
      default: 4
    },
  },
  data() {
    return {
      properIndex: 0
    };
  },
  computed: {
    ...mapGetters({
      compGetCalendarData: CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA
    }),
    getAlldayEvents() {
      for (let ae = 0; ae < this.compGetCalendarData.length; ae++) {
        if (this.compGetCalendarData[ae].date.slice(0, -5) === this.daylabel)
          return this.compGetCalendarData[ae].allDayEvents;
      }
      return [];
    },
    getTimedEvents() {
      for (let ae = 0; ae < this.compGetCalendarData.length; ae++) {
        if (this.compGetCalendarData[ae].date.slice(0, -5) === this.daylabel)
          return this.compGetCalendarData[ae].timedEvents;
      }
      return [];
    },
    isShowMore() {
      return this.getAlldayEvents && (this.getAlldayEvents.length + this.getTimedEvents.length) > this.verticalLimitCount;
    },
    moreCount() {
      return this.getAlldayEvents && (this.getAlldayEvents.length + this.getTimedEvents.length) - this.verticalLimitCount;
    },
    getAlldayEventsTrim() {
      return this.getAlldayEvents && this.getAlldayEvents.slice(0, this.verticalLimitCount)
        .map(this.mapEvent);
    },
    getTimedEventsTrim() {
      const len = this.getAlldayEvents && this.getAlldayEvents.length > this.verticalLimitCount ? 0 : this.verticalLimitCount - (this.getAlldayEvents && this.getAlldayEvents.length || 0);
      return this.getTimedEvents && this.getTimedEvents.slice(0, len)
        .map(this.mapEvent);
    },
    displayLabel() {
      const label = this.daylabel.split('.');
      return label && label[0] || this.daylabel;
    }
  },
  methods: {
    mapEvent(event) {
        let introIcon = null;
        if (event.type === "birthday")
            introIcon = PhGift;
        else if (event.type === "appoitment" && (event.startTime === "00:00" || event.startTime === "23:59"))
            introIcon = PhBriefcase;
        return {
            ...event,
            introIcon,
            label: event.label || 'Termin'
        }
    },
    chooseDay() {
      this.$emit('chooseDay', true);
    },
    setSelectedEvent(allDayEvent) {
      if (allDayEvent.type === "appoitment") {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_CLICKED_ON_EVENT, true);
        this.$store.dispatch(
          CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT,
          allDayEvent.id
        );
        this.$emit('editEvent', allDayEvent);
      }
    },
  }
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.innerday {
  width: 100%;
  box-sizing: border-box;
}
.title,
.title > button {
  font-weight: bold;
  text-align: right;
}
.title.today,
.title.today > button {
  color: var(--color-danger);
  font-weight: bold;
}
.title > button {
  display: inline-block;
  padding: 0.35rem 0.5rem;
  min-width: 22px;
}

.month-events {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.5em;
  text-align: left;
  height: calc(100% - 32px); /** 100% - .title height */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.month-events::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.not_in_month {
  color: var(--color-text);
}
.allday-events {
  margin-top: 1px;
  color: var(--color-text);
  background-color: var(--color-success-background);
  border-radius: 8px;
  padding: 0;
  line-height: 1;
}

@media (prefers-color-scheme: dark) {
  .allday-events {
    color: var(--color-box);
  }
}
.allday-events .sameline {
  padding: 0.25rem 0.4rem;
}
.sameline {
  display: inline-block;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
}
.dot {
  display: inline-block;
  background: var(--color-primary);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 0.25rem;
  vertical-align: middle;
}
.pl-25 {
  padding-left: 0.25rem;
}
.timed-events {
  display: flex;
}
.timed-events .time {
  font-size: 0.8rem;
  display: inline-block;
  color: #cccccc;
  float: right;
  padding-right: 0.25rem;
}
.event-label {
  vertical-align: middle;
  margin: 0 0 0 2px;
}
@media only screen and (max-width: 400px) {
  .title {
    font-weight: bold;
    font-size: small;
    height: 20%;
  }

  .sameline{
      font-size: x-small;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
  }
}
</style>