<template>
  <div>
    <div class="row mt-4 mb-5 justify-content-center">
      <div class="linear-scale">
        <div class="top-cursor" :style="{left: topCursorPosition + '%'}"></div>
        <span class="scale-label">geringes Risiko</span>
        <div class="sections">
          <div class="section-item sr1"></div>
          <div class="section-item sr2"></div>
          <div class="section-item sr3"></div>
          <div class="section-item sr4"></div>
          <div class="section-item sr5"></div>
          <div class="section-item sr6"></div>
          <div class="section-item sr7"></div>
        </div>
        <span class="scale-label label-right">hohes Risiko</span>
        <div class="bottom-cursor" :style="{left: bottomCursorPosition + '%'}"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-10 col-md-11">
        <span class="top-cursor-color">Risikoverteilung aktuell:</span>
      </div>
      <div class="col-2 col-md-1 text-right">
        <span class="top-cursor-color">{{getLabelValue(risk.GSRI)}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-10 col-md-11">
        <span class="bottom-cursor-color" v-if="displayAnlegerprofilLink">Risikoverteilung gemäß <router-link to="/beratung/anlegerprofil/person1/anlageziele/anlageziele">Anlegerprofil</router-link>:</span>
        <span class="bottom-cursor-color" v-else>Risikoverteilung gemäß Anlegerprofil:</span>
      </div>
      <div class="col-2 col-md-1 text-right">
        <span class="bottom-cursor-color">{{getLabelValue(risk.SRIInvestorProfile)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import RISIKOVERTEILUNG_TYPES from "@/store/risikoverteilung/types";
import { mapGetters } from 'vuex';

export default {
    props: {
      displayAnlegerprofilLink: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapGetters({risk: RISIKOVERTEILUNG_TYPES.GETTERS.RISIKOVERTEILUNG}),
      topCursorPosition() {
        if (this.risk && this.risk.GSRI) {
          const value = this.risk.GSRI;
          return this.calculatePosition(value);
        }
      },
      bottomCursorPosition() {
        if (this.risk && this.risk.SRIInvestorProfile) {
          const value = this.risk.SRIInvestorProfile;
          return this.calculatePosition(value);
        }
      },
    },
    mounted(){
      this.$store.dispatch(RISIKOVERTEILUNG_TYPES.ACTIONS.GET_RISIKOVERTEILUNG);
    },
    methods: {
      calculatePosition(value) {
        const safeValue = isNaN(value) ? 0 : value;
        const totalWidth = 100;
        const totalSections = 7;
        const position = ((totalWidth/totalSections).toFixed() * safeValue).toFixed();
        return position;
      },
      getLabelValue(value) {
        if(isNaN(value)) {
          return 0;
        }
        return value?.toString().replace(/\./g, ',');
      }
    }

}
</script>

<style>
.linear-scale {
  position: relative;
  width: 100%;
}

.sections {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  height: 16px;
}

.section-item {
  flex: 0 1 auto;
  width: calc(100% / 7);
  border-right: var(--color-box) 2px solid;
}

.top-cursor {
  position: absolute;
  left: 0px;
  top: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 7.5px 0 7.5px;
  border-color: var(--color-text) transparent transparent transparent;
  -webkit-transform: rotate(360deg);
}
.top-cursor-color {
  color: var(--color-text);
}

.top-cursor:hover {
  transform: scale(1.5);
}

.bottom-cursor {
  position: absolute;
  left: 0px;
  bottom: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 15px 7.5px;
  border-color: transparent transparent var(--color-text) transparent;
  -webkit-transform: rotate(360deg);
}

.bottom-cursor-color {
  color: var(--color-text);
}

.bottom-cursor:hover {
  transform: scale(1.5);
}

.scale-label {
  position: absolute;
  bottom: -24px;
  font-size: 12px;
}

.label-right {
  right: 14px
}

.sr1 {
  background-color: #1E7B33;
}
.sr2 {
  background-color: #28A745;
}
.sr3 {
  background-color: #28A745;
}
.sr4 {
  background-color: #FFA823;
}
.sr5 {
  background-color: #E54128;
}
.sr6 {
  background-color: #E54128;
}
.sr7 {
  background-color: #B62B16;
}

.text-right {
  text-align: right;
  justify-content: flex-end;
}

</style>