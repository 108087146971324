<template>
  <div class="explorer__container">
    <DBM :act="this.act" :showLoadButton="this.selected !=null && this.selected.sid!=null"
      @onLoadButton="clickSID"    />
    
    <div v-if="this.data1">
    
      <Tree
        :items="this.data1"
        childrenKey="children"
        idKey="sid"
        :itemSelectedId="this.selected  &&this.selected.sid && this.selected.serial ?this.selected.sid+'':null"
        @itemSelect="selectNode($event)"
        :defaultCollapsed="false"
        class="explorer__tree_nodes"
      >
        <template #default="{ item }">
          <div class="explorer__tree_node_content">
            <span class="explorer__tree_node_label">{{ item.txt }}</span>
          </div>
        </template>
      </Tree>
    </div>
    <DbmSessionDialog ref="DbmSessionDialog" />
  </div>
</template>

<script>
import DB_TYPES from "@/store/dbm/types";

import { mapGetters } from "vuex";
import Tree from "@/components/core/Tree.vue";

import DBM from "@/views/dbm/Dbm.vue";

import BaseButton from "@/components/core/BaseButton.vue";
import DbmSessionDialog from "@/views/dbm/DbmSessionDialog.vue";

export default {
  props: {
   
    act: {
      type: String,
      default: DB_TYPES.ACTIONS.BLOCKERS,
    },
  },
  components: {
    DBM,
    Tree,
    BaseButton,
    DbmSessionDialog,
  },

  computed: {
    ...mapGetters({
      data1: DB_TYPES.GETTERS.DATA,
      selected: DB_TYPES.GETTERS.SELECTED_NODE,
    }),

    title() {
      if (this.data != null) {
        return this.data.label;
      } else {
        return null;
      }
    },

    mounted() {
      this.selected = null;
    },
  },
  methods: {
    selectNode(whatNode) {
      //this.selectedId = whatNode.sid;
      //this.selected = whatNode;
      this.$store.commit(DB_TYPES.MUTATIONS.SELECTED_NODE, whatNode);
    },

    clickSID() {
      this.$refs.DbmSessionDialog?.open(
        
        this.selected.sid,
        this.selected.serial
      );
      this.$store.commit(DB_TYPES.MUTATIONS.START_TIME, null);
    },
  },

  data() {
    return {
      apiAddress: process.env.VUE_APP_API,
      selectedId: null,
      
      timer: null,
    };
  },
};
</script>


<<style scoped>
.header_buttons {
  display: flex;
  align-items: flex-end;
}

.explorer__container {
  display: flex;
  flex-direction: column;
}

.explorer__body {
  width: 100%;
}

.explorer__header {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;
  padding: 0.8em;
}

.explorer__header * {
  float: right;
}

.explorer__header i {
  font-size: 1.8em;
  cursor: pointer;
  float: right;
}

.explorer__header .explorer__header__search {
  position: relative;
  float: right;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.explorer__header .explorer__header__search input {
  flex-grow: 1;
}

.explorer__header .explorer__schadensmeldung_checkbox {
  margin-right: 4px;
  margin-left: 4px;
}

.explorer__header .explorer__header__search i {
  position: absolute;
  left: 8px;
  font-size: 1em;
}

.explorer__header .explorer__header__search input {
  text-indent: 26px;
}

.explorer__sidebar {
  white-space: nowrap;
  padding: 4px;
  flex-grow: 0;
}

.explorer__sidebar div {
  cursor: pointer;
}

.explorer__content {
  position: relative;
  background-color: unset;
  flex-grow: 1;
  max-width: 100%;
}

.content__item {
  display: flex;
  max-width: 98px;
  min-width: 98px;
  min-height: 98px;
  text-align: center;
  /* background-color: aqua; */
  margin: 2px;
  flex-direction: column;
  word-wrap: break-word;
  padding: 2px;
}

.content__item i:nth-child(2) {
  font-size: 1.2em;
}

.content__detailed {
  display: flex;
  flex-wrap: nowrap;
  /* border: 1px black solid; */
}

.content__detailed:nth-child(1) div {
  font-weight: bold;
  border-left: 1px #e5e5e5 solid;
  cursor: pointer;
}

.content__detailed:nth-child(1) div i {
  font-size: 1em;
}

.content__detailed:nth-child(1) div:nth-child(1) {
  border-left: none;
}

.content__item__expanded__column {
  word-wrap: break-word;
  text-align: left;
  width: 120px;
}

.content__item__expanded__column__icon {
  display: flex;
  /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.content__item__expanded__column:nth-child(1) {
  width: 32px;
}

.content__item__expanded__column:nth-child(2) {
  width: 32px;
}

.content__item__expanded__column:nth-child(3) {
  width: 32px;
}

.content__item__expanded__column:nth-child(4) {
  width: 32px;
}

.content__item__expanded__column:nth-child(6) {
  width: 80px;
}

.content__item__expanded__column:nth-child(8) {
  width: 180px;
}

.explorer__navigation__item {
  display: inline-block;
  cursor: pointer;
  flex: 1 1 0px;
  margin-bottom: 4px;
  font-size: 1.1em;
}

.explorer__content__node {
  display: flex;
  flex-wrap: wrap;
}

.explorer__content__node i {
  cursor: pointer;
}

.explorer__content__node a {
  color: var(--color-link)
}

.explorer__content i {
  font-size: 3em;
}

.content__detailed i {
  font-size: 2em;
}

.explorer__content__smaller {
  display: none;
}

.explorer__search__string {
  font-weight: bold;
  font-style: italic;
}

.break-text {
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;
}

.vertrag__ordner.tooltip {
  position: relative;
  display: inline-block;
}

.vertrag__ordner.tooltip .tooltiptext {
  visibility: hidden;
  background: rgb(204, 204, 204);
  background: rgba(204, 204, 204, 0.5);
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  display: inline-block;
  z-index: 1;
  min-width: 600px;
}

.vertrag__ordner.tooltip:hover .tooltiptext {
  visibility: visible;
}

.no__vertrag__ordner {
  display: none;
}

.selected__node {
  font-weight: bold;
}

.unselected__node {
  font-weight: bold;
}

.color-text {
  color: var(--color-primary);
}

.color-primary {
  color: var(--color-primary);
}

.color-success {
  color: var(--color-success);
}

.color-warning {
  color: var(--color-warning);
}

.color-info {
  color: var(--color-info);
}

.color-danger {
  color: var(--color-danger);
}

.td-icon i {
  font-size: 1em;
  padding: 2px;
}

th,
tr,
td {
  vertical-align: middle;
}

.icons-inline {
  display: inline;
  font-size: 1.8em;
}

.explorer__tree_nodes {
  margin: 8px 0 8px;
}

.explorer__tree_node_content {
  display: flex;
}

.explorer__tree_node_label {
  flex: 1 1 auto;
  word-break: break-word;
}

.explorer__tree_node_total {
  align-self: center;
  flex: 0 0 auto;
  margin: 0 0 0 12px;
}


.box__title {
  margin-bottom: 8px;
}

.primary {
  background-color: var(--color-primary);
  color: var(--color-box);
}

.success {
  background-color: var(--color-success);
  color: var(--color-box);
}

.warning {
  background-color: var(--color-warning);
  color: var(--color-box);
}

.info {
  background-color: var(--color-info);
  color: var(--color-box);
}

.danger {
  background-color: var(--color-danger);
  color: var(--color-box);
}

.explorer__navigation {
   width: 100%;
}

.header__button {
  margin-right: 16px;
}

.name-filter {
  width: 300px;
}

/* Large desktops and laptops */
@media (min-width: 1861px) {
  .explorer__sidebar {
    display: block;
  }
  .explorer__content__smaller {
    display: none;
  }

  .content__item__expanded__column:nth-child(5) {
    width: 320px;
  }
  .content__item__expanded__column:nth-child(6) {
    width: 80px;
  }
  .content__item__expanded__column:nth-child(7) {
    display: block;
  }
  .content__item__expanded__column:nth-child(8) {
    display: block;
  }
  .content__item__expanded__column:nth-child(9) {
    display: block;
    min-width: 200px;
  }

  .status-icons-many-columns {
    display: table-cell;
  }

  .status-icons-single-column {
    display: none;
  }

  tr.line-separator {
    border-bottom: none;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 1021px) and (max-width: 1860px) {
  .explorer__sidebar {
    display: block;
  }
  .explorer__content__smaller {
    display: none;
  }
  .content__item__expanded__column:nth-child(5) {
    width: 250px;
  }
  .search__mode__on .content__item__expanded__column:nth-child(7) {
    display: block;
  }
  .search__mode__on .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(9) {
    display: block;
    min-width: 300px;
  }

  .search__mode__off .content__item__expanded__column:nth-child(7) {
    display: block;
  }
  .search__mode__off .content__item__expanded__column:nth-child(8) {
    display: block;
  }
  .search__mode__off .content__item__expanded__column:nth-child(9) {
    display: none;
  }

  .status-icons-many-columns {
    display: table-cell;
  }

  .status-icons-single-column {
    display: none;
  }

  tr.line-separator {
    border-bottom: none;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 1020px) {
  .explorer__sidebar {
    display: block;
  }
  .explorer__content__smaller {
    display: none;
  }
  .content__item__expanded__column .vertrag__ordner:nth-child(4) {
    width: 180px;
  }
  .search__mode__on .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(9) {
    display: block;
    min-width: 240px;
  }

  .search__mode__off .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__off .content__item__expanded__column:nth-child(8) {
    display: block;
  }
  .search__mode__off .content__item__expanded__column:nth-child(9) {
    display: none;
  }

  .status-icons-many-columns {
    display: table-cell;
  }

  .status-icons-single-column {
    display: none;
  }

  tr.line-separator {
    border-bottom: none;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .explorer__sidebar {
    display: none;
  }
  .explorer__content__smaller {
    display: flex;
    flex-wrap: wrap;
  }
  .content__item__expanded__column:nth-child(5) {
    width: 180px;
  }
  .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .explorer__content {
    border-left: none;
  }

  .status-icons-many-columns {
    display: none;
  }

  .status-icons-single-column {
    display: table-cell;
  }

  tr.line-separator {
    border-bottom: 1px solid var(--color-secondary);
  }
}

/* Portrait phones and smaller */
@media (max-width: 640px) {
  .explorer__sidebar {
    display: none;
  }
  .explorer__content__smaller {
    display: flex;
    flex-wrap: wrap;
  }

  /* Search mode on */
  .search__mode__on .content__item__expanded__column:nth-child(5) {
    min-width: 150px;
  }
  .search__mode__on .content__item__expanded__column:nth-child(6) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(9) {
    display: block;
    min-width: 120px;
  }

  /* Search mode off */
  .search__mode__off .content__item__expanded__column:nth-child(5) {
    min-width: 150px;
  }
  .search__mode__off .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__off .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .search__mode__off .content__item__expanded__column:nth-child(9) {
    display: none;
  }

  .explorer__navigation__item {
    font-size: 1em;
  }

  .status-icons-many-columns {
    display: none;
  }

  .status-icons-single-column {
    display: table-cell;
  }

  tr.line-separator {
    border-bottom: 1px solid var(--color-secondary);
  }
}
</style>