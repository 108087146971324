var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "actions_container" }, [
    _vm.actions && _vm.actions.length
      ? _c(
          "div",
          { staticClass: "action-icons" },
          [
            _vm._l(_vm.buttonsAllwaysVisible, function(action) {
              return [
                !_vm.showDownloadLink(action)
                  ? _c(
                      "button",
                      {
                        key: action.legend.label,
                        staticClass: "btn-clean clickable",
                        attrs: {
                          type: "button",
                          tid: _vm._generateTidFromString(
                            action.legend.label + _vm.rowIndex
                          ),
                          disabled:
                            (action.disabled && action.disabled(_vm.element)) ||
                            (action.loading && action.loading(_vm.element)),
                          title: action.legend.label
                        },
                        on: {
                          click: function($event) {
                            return _vm.executeAction(action)
                          }
                        }
                      },
                      [
                        action.loading && action.loading(_vm.element)
                          ? _c(
                              "AnimatedSpinner",
                              _vm._b(
                                {},
                                "AnimatedSpinner",
                                _vm.iconProperties(action),
                                false
                              )
                            )
                          : _c(
                              _vm.dynamicIconComponent(
                                action.legend.icon,
                                action
                              ),
                              _vm._b(
                                { tag: "component" },
                                "component",
                                _vm.iconProperties(action),
                                false
                              )
                            ),
                        action.legend.labelShowAlways
                          ? _c("span", [_vm._v(_vm._s(action.legend.label))])
                          : _vm._e()
                      ],
                      1
                    )
                  : [
                      _vm.showDownloadLink(action)
                        ? _c("DownloadLink", {
                            key: action.legend.label,
                            attrs: {
                              href: action.legend.link || action.value,
                              title: _vm.getTitle(action.legend.label)
                            }
                          })
                        : _c(
                            "a",
                            {
                              key: action.legend.label,
                              attrs: {
                                target: "_blank",
                                href: action.legend.link
                              }
                            },
                            [
                              action.loading && action.loading(_vm.element)
                                ? _c(
                                    "AnimatedSpinner",
                                    _vm._b(
                                      {},
                                      "AnimatedSpinner",
                                      _vm.iconProperties(action),
                                      false
                                    )
                                  )
                                : _c(
                                    _vm.dynamicIconComponent(
                                      action.legend.icon
                                    ),
                                    _vm._b(
                                      { tag: "component" },
                                      "component",
                                      _vm.iconProperties(action),
                                      false
                                    )
                                  ),
                              _c("span", [_vm._v(_vm._s(action.legend.label))])
                            ],
                            1
                          )
                    ]
              ]
            }),
            _vm.remainingButtons.length > 0
              ? _c("BaseContextMenu", {
                  attrs: {
                    tid: _vm._generateTidFromString(
                      "ph-dots-three-vertical" + _vm.rowIndex
                    ),
                    placement: "bottom-right"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _vm._l(_vm.remainingButtons, function(action) {
                              return [
                                !_vm.showDownloadLink(action)
                                  ? _c(
                                      "ContextMenuItem",
                                      {
                                        key: action.legend.label,
                                        attrs: {
                                          tid: _vm._generateTidFromString(
                                            "BaseDropdownMenu" +
                                              action.legend.label +
                                              _vm.rowIndex
                                          ),
                                          disabled:
                                            (action.disabled &&
                                              action.disabled(_vm.element)) ||
                                            (action.loading &&
                                              action.loading(_vm.element))
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.executeAction(
                                              action,
                                              true
                                            )
                                          }
                                        }
                                      },
                                      [
                                        action.loading &&
                                        action.loading(_vm.element)
                                          ? _c(
                                              "AnimatedSpinner",
                                              _vm._b(
                                                {},
                                                "AnimatedSpinner",
                                                _vm.iconProperties(action),
                                                false
                                              )
                                            )
                                          : _c(
                                              _vm.dynamicIconComponent(
                                                action.legend.icon
                                              ),
                                              _vm._b(
                                                { tag: "component" },
                                                "component",
                                                _vm.iconProperties(action),
                                                false
                                              )
                                            ),
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.createColorizedLabel(
                                                action.legend
                                              )
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c(
                                      "ContextMenuItem",
                                      { key: action.legend.label },
                                      [
                                        _vm.showDownloadLink(action)
                                          ? _c(
                                              "DownloadLink",
                                              {
                                                staticClass:
                                                  "btn-clean clickable",
                                                attrs: {
                                                  href:
                                                    action.legend.link ||
                                                    action.value,
                                                  title: _vm.getTitle(
                                                    action.legend.label
                                                  )
                                                }
                                              },
                                              [
                                                action.loading &&
                                                action.loading(_vm.element)
                                                  ? _c(
                                                      "AnimatedSpinner",
                                                      _vm._b(
                                                        {},
                                                        "AnimatedSpinner",
                                                        _vm.iconProperties(
                                                          action
                                                        ),
                                                        false
                                                      )
                                                    )
                                                  : _c(
                                                      _vm.dynamicIconComponent(
                                                        action.legend.icon
                                                      ),
                                                      _vm._b(
                                                        { tag: "component" },
                                                        "component",
                                                        _vm.iconProperties(
                                                          action
                                                        ),
                                                        false
                                                      )
                                                    ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(action.legend.label)
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _c(
                                              "a",
                                              {
                                                key: action.legend.label,
                                                staticClass:
                                                  "btn-clean clickable",
                                                attrs: {
                                                  target: "_blank",
                                                  href: action.legend.link
                                                }
                                              },
                                              [
                                                action.loading &&
                                                action.loading(_vm.element)
                                                  ? _c(
                                                      "AnimatedSpinner",
                                                      _vm._b(
                                                        {},
                                                        "AnimatedSpinner",
                                                        _vm.iconProperties(
                                                          action
                                                        ),
                                                        false
                                                      )
                                                    )
                                                  : _c(
                                                      _vm.dynamicIconComponent(
                                                        action.legend.icon
                                                      ),
                                                      _vm._b(
                                                        { tag: "component" },
                                                        "component",
                                                        _vm.iconProperties(
                                                          action
                                                        ),
                                                        false
                                                      )
                                                    ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(action.legend.label)
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                      ],
                                      1
                                    )
                              ]
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    204747875
                  )
                })
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }