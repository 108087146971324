<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Zusätzliche Dokumente" 
      :actions="headerActions"
      @action-UPLOAD_DOCUMENT_MODAL="files=[]"
      @action-UPLOAD_DOCUMENT="saveChangesAndUpload"
    />
    
    <div class="box__container">  
      <h3>{{title}}</h3>

      <div class="grid" v-if="additionalDocumentInputs">

        <DragnDropArea @files="dropFile" hoverText="Neues Dokument hochladen" class="explorer__content" :disabled="isReadOnly || !isValid">

          <InputToggleSwitch
            label="Gelöschte Dokumente anzeigen"
            v-model="showDeleted"
            inLineLabel
            @input="retrieveAdditionalDocuments($event)"
            v-if="isIntern"
          />

          <div class="row mb-4">
            <UploadInputList v-model="formData" :inputs="additionalDocumentInputs" :disabled="isReadOnly"/>
          </div>

          <hr>

          <h3>{{tableTitle}}</h3>
          <Table class="table-container"
            v-if="rows.length"
            :headers="headers"
            :rows="rows"
            hidePagination
            @action="executeAction($event)">

            <template v-slot:name="row"> 
              <span v-if="listHasAction(row.actionsObjects, 'DOKUMENT_ANZEIGEN')">
                <ph-trash :size="16" class="color-danger mr-1 icon" v-if="!row.exists"/>
                <DownloadLink target="_blank" rel="noopener noreferer" 
                    :href="makeLink(row)"
                    :title="row.name || row.bezeichnung || row.fileName || 'unbenannt'"
                />
              </span>
              <a v-else-if="listHasAction(row.actionsObjects, 'ZUSATZ_DOKUMENTE_ANZEIGEN')" @click="openSubviewTable(row)">{{row.name}}</a>

              <span v-else>
                <ph-trash :size="16" class="color-danger mr-1 icon" v-if="!row.exists"/>
                {{row.name || row.bezeichnung || row.fileName || 'unbenannt'}}
              </span>
            </template>

            <template v-slot:vorlagestatus="row"> 
              <WorkflowPill :currentStatusIcons="row.statusActions" :statusType="'VORLAGE'"></WorkflowPill>
            </template>
            <template v-slot:signaturstatus="row"> 
                <WorkflowPill :currentStatusIcons="row.statusActions" :statusType="'SIGNATUR'"></WorkflowPill>
            </template>
            <template v-slot:aktueller_scan="row">
              <InputToggleSwitch v-if="row.aktueller_scan != null" :disabled="isReadOnly || !row.exists" :value="row.aktueller_scan" @input="updateAktuellerScan(row, $event)"></InputToggleSwitch>
              <span v-else>
                Ist kein Scan
              </span>
            </template>
          </Table>
        </DragnDropArea>
      </div>
      <GhostLoading v-else-if="loading" type="block" :config="{ block: { height: 280, }, }"/>
      <NoData v-else content="Keine Daten vorhanden" />
    </div>

    <DocumentsUploadModal v-if="files && additionalUploadInputs"
      :files="files"
      @close="files=null"
      :additionalInputs="additionalUploadInputs"
      :parentId="nodeId"
      :uploadType="uploadType"
      :documentString="fileId"
      :multipleFileUpload="false"
      />

    <EditDocumentModal
      ref="editDocumentModal"
      v-model="fileForm"
      @confirm="confirmEditDocument"
    />

    <PictureAttachmentsModal
      ref="pictureAttachmentsModal"
      :selectedDoc="selectedDoc">
    </PictureAttachmentsModal>

    <BaseModal
      ref="subviewModal"
      labelButtonCancel="Zurück"
      :showConfirmButton="false"
      @close="closeSubviewModal"
      size="xl">
      <template v-slot:modalTitle>
        {{ subviewModalTitle }}
      </template>
        <Table class="table-container"
          :headers="headers"
          :rows="subviewTableData.records || []"
          :rowsPerPage="subviewTableData.records && subviewTableData.records.length + 1 || 1"
          hidePagination
          @action="executeAction($event)">

            <template v-slot:name="row"> 
              <DownloadLink 
                v-if="listHasAction(row.actionsObjects, 'DOKUMENT_ANZEIGEN')"
                :href="makeLink(row)"
                :title="row.bezeichnung || row.fileName || row.name"
                />
              <span v-else>{{row.bezeichnung || row.fileName || row.name || 'unbenannt'}}</span>
            </template>
        </Table>
    </BaseModal>

    <BaseModal
      ref="noSignoFilesModal"
      labelButtonCancel="Zurück"
      :showConfirmButton="false">

      <template #modalTitle>
        <b>Das Dokument ist nicht zur e-Signatur geeignet</b>
      </template>
      <template #default>
        <p>Sie können jedoch das Dokument herunterladen</p>

        <div class="grid">
          <div class="row">
            <div class="col">
              <DownloadLink 
                :href="makeLink(currentAction && currentAction.row)"
                :title="currentAction && currentAction.row && (currentAction.row.name || currentAction.row.filename) || 'unbenannt'"/>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>

    <BaseModal
      ref="warningModal"
      :modalTitle="warningTitle"
      labelButtonConfirm="Ja"
      labelButtonCancel="Nein"
      @onConfirmButton="confirmAbschliessen()"
      >
      <label>{{warningMessage}}</label>
    </BaseModal> 

    <ProhypDokumenteUpload
      ref="prohypDokumenteUploadModal"
      :nodeId="nodeId"
      :documentId="fileId"
      @selectionDone="retrieveAdditionalDocuments"
    />
  </div>
</template>

<script>
import axios from 'axios';
import DOKUMENTENARCHIV_TYPES from "../../store/dokumentenarchiv/types";
import DOCUMENT_TYPES from "@/store/documents/types";
import CORE_TYPES from '@/store/core/types';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import Table from '@/components/table2/Table.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import WorkflowPill from "@/components/core/WorkflowPill.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import UploadInputList from '@/components/fileUpload/UploadInputList.vue';
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import { mapGetters } from "vuex";
import expressionEvaluator from '@/mixins/expression/mixin.js';
import PictureAttachmentsModal from "@/views/documents/PictureAttachmentsModal.vue";
import DownloadLink from '@/components/core/download/DownloadLink.vue' 
import { PhWarning, PhTrash } from 'phosphor-vue'
import {IconColumn, PillColumn, SlotColumn, ActionColumn, Icon, SimpleAction, ConfirmedAction, LinkAction, oldToNewColumn} from "@/components/table2/table_util.js";
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';
import { VIEW_ROLES } from '@/router/roles.js'
import EditDocumentModal from '@/components/documents/EditFileModal.vue'
import { PageHeaderSimpleAction} from '@/components/core/header-title-navigation/page-header-utils';
import { toDateString } from '@/filters.js';
import ProhypDokumenteUpload from '@/components/documents/ProhypDokumenteUpload.vue';

export const UPLOAD_TYPE = Object.freeze({"DEFAULT":1, "DOKUMENTE_HINZUFUGEN": 2, "CUSTOM": 3});

export default {
  mixins: [expressionEvaluator],
  components: {
    InputToggleSwitch,
    Table,
    DragnDropArea,
    OptionMenu,
    PageHeaderTitleNavigation,
    WorkflowPill,
    BaseModal,
    UploadInputList,
    DocumentsUploadModal,
    DownloadLink,
    PictureAttachmentsModal,
    PhWarning,
    PhTrash,
    GhostLoading,
    NoData,
    EditDocumentModal,
    ProhypDokumenteUpload,
  },
  data() {
    return {
      tableHeaders: [],
      files: null,
      nodeId: null,
      fileId: null,
      additionalDocumentInputs: null,
      additionalUploadInputs: null,
      tableRows: null,
      formData: null,
      title: "",
      tableTitle: null,
      fileForm: {
        id: null,
        filename: null,
        bezeichnung: null,
        bemerkung: null,
        stand: null,
        aktueller_scan: false
      },
      actions: null,
      statusActions: null,
      availableStatusIcons: null,
      selectedDoc: null,
      subviewTableRows: {},
      subviewModalTitle: null,
      paramSubview: null,
      currentAction: null,
      warningTitle: '',
      warningMessage: '',
      showDeleted: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      attachmentLinkMaker: DOCUMENT_TYPES.GETTERS.GET_ANTRAG_SCANS_LINK_MAKER,
      additionalDocuments: DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_DOCUMENTS,
      tempUploadFiles: DOKUMENTENARCHIV_TYPES.GETTERS.TEMP_UPLOAD_FILES,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
    }),
    isCustomerLogin() {
      return this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_ONLY) && !this.hasRoles(VIEW_ROLES.VIEW_KUNDENSICHT)
    },
    exists() {
      return this.additionalDocuments && this.additionalDocuments.exists || false
    },
    headers() {
      const headers = {
        lockedLeft: [],
        center: [],
        lockedRight: []
      };
      if (this.tableHeaders) {
        this.tableHeaders.filter(h => h.visible)
        .forEach(header => {
          switch (header.lockedColumn) {
            case 'left':
              headers.lockedLeft.push(oldToNewColumn(header.key, header.label, header.dataType))
              break;
            case 'right':
              headers.lockedRight.push(oldToNewColumn(header.key, header.label, header.dataType))
              break;
            default:
              headers.center.push(oldToNewColumn(header.key, header.label, header.dataType))
              break;
          }
          });
      }

      if (this.hasStatusAction('ANTRAG_STATUS')) 
          headers.lockedRight.push(IconColumn('status', 'Status'))
      if (this.hasStatusAction('ANLEGERPROFIL_BESTATIGT'))
          headers.lockedRight.push(IconColumn('apstatus', 'Status'))
      if (this.hasStatusAction('VORLAGE_STATUS'))
          headers.lockedRight.push(SlotColumn('vorlagestatus', 'Vorlage', 200, 0))
      if (this.hasStatusAction('SIGN_STATUS'))
          headers.lockedRight.push(SlotColumn('signaturstatus', 'e-Signatur', 200, 0))
      if (this.hasAction('DOKUMENTE_HINZUFUGEN'))
          headers.lockedRight.push(PillColumn('dokumente', 'Dokumente', 120))
      if (this.hasStatusAction('DOKUMENT_FREIGABE'))
          headers.lockedRight.push(IconColumn('sichtbar', 'Sichtbar').makeLink())

      if (!this.isReadOnly)
        headers.lockedRight.push(ActionColumn('actions', 'Aktionen'))
      return headers;
    },  
    rows() {
      return (this.tableRows || []).map(document => {
        let row = {
            data: document,
            ...document,
        };
        if (this.headers && document.additionalColumns) {
            row = {
                ...row,
                ...document.additionalColumns,
            };
        }
        //row.bezeichnung = document.bezeichnung || row.bezeichnung || row.fileName || 'unbenannt';
        if (this.hasAction('DOKUMENTE_HINZUFUGEN')) {
            row.dokumente = document.actions.find(a => a.legend.key == 'KEINE_DOKUMENTE')
                        ? {type: "info", label: "keine Dokumente"}
                        : undefined;
        }
        if (this.hasStatusAction('FREIGABE_KUNDE')) {
            row.sichtbar = null;
            if (document.statusActions.find(a => a == 'FREIGABE_KUNDE_VERWEIGERT' || a == 'FREIGABE_MAKLER_VERWEIGERT'))
                row.sichtbar = [Icon(PhEyeSlash, "Freigabe verweigert", 16, "bold", "color-danger")];
            else if (document.statusActions.find(a => a == 'FREIGABE_KUNDE_VORHANDEN' || a == 'FREIGABE_MAKLER_VORHANDEN'))
                row.sichtbar = [Icon(PhEye, "freigegeben", 16, "bold", "color-text")];
        }
        if (this.hasStatusAction('ANTRAG_STATUS')) {
            row.status = null;
            if (document.statusActions.find(a => a == 'ANTRAG_ORDNUNG'))
                row.status = [Icon(PhCheck, "Gut", 16, "bold", "color-success")];
            else if (document.statusActions.find(a => a == 'ANTRAG_FEHLER'))
                row.status = [Icon(PhWarning, "Fehler", 16, "bold", "color-danger")];
        }
        if (this.hasStatusAction('ANLEGERPROFIL_BESTATIGT')) {
            row.apstatus = null;
            if (document.statusActions.find(a => a == 'ANLEGERPROFIL_FEHLERHAFT'))
                row.apstatus = [Icon(PhWarning, "fehlerhaft", 16, "bold", "color-danger")];
            else if (document.statusActions.find(a => a == 'ANLEGERPROFIL_BESTATIGT'))
                row.apstatus = [Icon(PhCheck, "bestätigt", 16, "bold", "color-success")];
            else if (document.statusActions.find(a => a == 'ANLEGERPROFIL_BESTATIGT_OK'))
                row.apstatus = [Icon(PhChecks, "bestätigt", 16, "bold", "color-success")];
            else if (document.statusActions.find(a => a == 'ANLEGERPROFIL_BESTATIGT_PENDING'))
                row.apstatus = [Icon(PhCheck, "unbestätigt", 16, "bold", "color-warning")];
        }

        row.actions = [];
        row.actionsObjects = [];
        if (document.actions) {
            document.actions.filter(action => this.isIntern && !row.exists ? action.showIfDeleted : true).forEach(action => {
                if (action?.legend?.key !== 'DOKUMENT_ANZEIGEN' && action?.legend.key !== 'ZUSATZ_DOKUMENTE_ANZEIGEN') {
                  row.actions.push(action.confirmationMessage 
                    ? ConfirmedAction(action.legend.key, action.legend.icon, action.legend.label, action.confirmationMessage, action.confirmationTitle)
                    : SimpleAction(action.legend.key, action.legend.icon, action.legend.label));
                }
                row.actionsObjects.push(action);
            });
        };
        return row;
      });
    },
    subviewTableData() {
      if (!this.headers)
        return {}

      return {
        headers: this.headers,
        records: (this.subviewTableRows && this.paramSubview && this.subviewTableRows[this.paramSubview] || []).map (document => {
          let row = {
              data: document,
              ...document,
          };
          if (this.headers && document.additionalColumns) {
              row = {
                  ...row,
                  ...document.additionalColumns,
              };
          }
          row.actions = [];
          row.actionsObjects = [];
          document.actions?.forEach(action => {
              if (action?.legend?.key !== 'DOKUMENT_ANZEIGEN') {
                row.actions.push(action.confirmationMessage 
                  ? ConfirmedAction(action.legend.key, action.legend.icon, action.legend.label, action.confirmationMessage, action.confirmationTitle)
                  : SimpleAction(action.legend.key, action.legend.icon, action.legend.label));
              }
              row.actionsObjects.push(action);
          });
          return row;
        }),
      }
    },
    uploadType() {
      return UPLOAD_TYPE.DOKUMENTE_HINZUFUGEN;
    },
    isReadOnly() {
      return !this.exists || this.isCustomerLogin
    },
    isValid() {
      return !this.additionalDocumentInputs?.some(input => input.valid && !this.evaluateUploadExpression(input.valid))
    },
    headerActions() {
      const actions = []

      if (!this.isReadOnly && this.additionalDocumentInputs) {
        if (this.fileId) {
          actions.push(PageHeaderSimpleAction('UPLOAD_DOCUMENT_MODAL', 'Neues Dokument hochladen').withDisabled(() => !this.isValid))
        } else {
          actions.push(PageHeaderSimpleAction('UPLOAD_DOCUMENT', 'Neues Dokument hochladen').withDisabled(() => !this.isValid))
        }
      }
      return actions
    },
  },
  watch: {
    additionalDocuments: function (newValue, oldValue) {
      this.readExistingData(newValue);
    }
  },  
  async mounted() {
    this.nodeId = this.$route.query.nodeId;
    this.fileId = this.$route.query.fileId
    await this.retrieveAdditionalDocuments();

    if (this.$route.query.hasFilesUploaded && this.isValid && this.tempUploadFiles.length) {
      this.dropFile(this.tempUploadFiles)
    }
  },
  methods: {
    updateAktuellerScan(row, event) {
      if (this.isReadOnly || !row.exists) {
        return
      }
      this.tableRows.forEach((item, index, theArray) => {
        if (item.data === row.data)
          theArray[index].aktueller_scan = event
      })
    },
    async retrieveAdditionalDocuments(showDeleted = this.showDeleted) {
      this.loading = true

      return this.$store.dispatch(
        DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_DOCUMENTS,
        {
          showDeleted: showDeleted,
          nodeId: this.$route.query.nodeId,
          fileId: this.$route.query.fileId,
        }
      ).finally(() => this.loading = false)
    },    
    readExistingData(response) {
      if (!this.additionalDocumentInputs) {
        this.additionalDocumentInputs = response.additionalDocumentInputs

        this.formData = {}
        this.additionalDocumentInputs && this.additionalDocumentInputs.forEach(input => {
          if (input.type == 'DATE') {
            this.formData[input.key] = toDateString(input.value)
          }
          else if (input.type != 'LABEL')
            this.formData[input.key] = input.value
        })
      }
      this.additionalUploadInputs = response.additionalUploadInputs
      this.actions = response.availableActions;
      this.statusActions = response.statusActions;
      this.availableStatusIcons = response.availableStatusIcons;
      this.tableHeaders = response.tableColumns;
      this.tableTitle = response.tableTitle
      this.tableRows = response.tableRows
      this.subviewTableRows = response.subviewTableRows
      this.title = response.title

    },
    hasStatusAction(whatAction) {
      if(!this.availableStatusIcons?.length) {
        return false;
      }
      return this.availableStatusIcons.some(icon => icon?.key === whatAction);
    },
    dropFile(files) {
      if (!this.fileId) {
        this.saveChangesAndUpload(files)
      } else {
        this.files = files
      }
    },
    evaluateUploadExpression(expression) {
      return this.evaluateExpression(this.formData, expression);
    },
    saveChangesAndUpload(files) {
      this.saveChanges().then((response) => {
        this.fileId = encodeURIComponent(response.data)
        this.files = files || []
      })
    },
    saveChanges() {
      if (!this.additionalDocumentInputs || this.isReadOnly || !this.isValid) {
        return;
      }
      const formData = {...this.formData}
      this.additionalDocumentInputs.forEach(input => {
        if (input.ignore && this.evaluateUploadExpression(input.ignore)) {
          formData[input.key] = null
          return
        }
      })

      let serviceUrl = '/dokumentenarchiv/additionalDocuments'

      if (this.fileId) {
        serviceUrl += `?fileId=${this.fileId}`
      }

      return axios.post(`${process.env.VUE_APP_API}${serviceUrl}`, {
        parentId: this.nodeId,
        formData,
        filesIsAktuellData: this.rows,
      }, {
        defaultSpinner: true
      })
    },
    confirmEditDocument() {
      const index = this.rows.findIndex(r => r.id == this.fileForm.id);

      this.rows[index].bezeichnung = this.fileForm.bezeichnung;
      this.rows[index].bemerkung = this.fileForm.bemerkung;
      this.rows[index].date_stand = this.fileForm.stand;
      this.saveChanges().then(() => this.retrieveAdditionalDocuments())
    },
    executeAction(actionData = {}) {
      if (this.isReadOnly) {
        return
      }

      this.currentAction = actionData
      const row = actionData.row;
      switch(actionData.key) {
        case "DOKUMENT_BEARBEITEN":
          this.fileForm.id = row.id;
          this.fileForm.filename = row.filename;
          this.fileForm.bezeichnung = row.bezeichnung;
          this.fileForm.bemerkung = row.bemerkung;
          this.fileForm.stand = row.date_stand?.split(' ')[0] || row.stand
          this.$refs.editDocumentModal.open();
          break;
        case "DOKUMENT_UNTERSCHREIBEN":
          const sendData = this.sendData(row, actionData.key);
          axios.post(`${process.env.VUE_APP_API}/dokumentenarchivSign/additionalDocumentsSignAction?nodeId=${this.nodeId}`, sendData,
          {
            headers: {'Content-Type': 'application/json'},
            defaultSpinner: true
          })
            .then(response => {
              if (response && response.data) {
                const responseData = response.data
                const signoFile = response.data.signatureOutputs && response.data.signatureOutputs[0]
                if (responseData?.errorMessage) {
                  this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorMessage, 'danger'));
                } else if (signoFile.signoElement) {
                  this.openZusatzDokModal(signoFile);
                } else if (signoFile.url) {
                  this.openSignPdFile(signoFile.url);
                } else {
                  this.$refs.noSignoFilesModal.open()
                }
              }
            })
          break 
        case "NICHT_VORGELEGT":
        case "ZUR_UNTERSCHRIFT":  
        case "NUR_VERMITTLER":
        case "ANTRAG_LOSCHEN":
        case "DOKUMENT_LOSCHEN":
        case "DOKUMENT_WIEDERHERSTELLEN":
        case "DOKUMENT_ABSCHLIESSEN":
        case "DOKUMENT_NICHT_VORLEGEN":
        case "DOKUMENT_VORLEGEN":
            this.onConfirmActionModal()
        break;   
        case "ZUSATZ_DOKUMENTE_ANZEIGEN":
          this.openSubviewTable(row)
        break;
        case "KREDIT_PROHYP_UPLOAD":
          this.uploadProhypDocument(row)
          break;
        default:
          console.log("action not yet implemented: " + actionData.key, actionData);                  
      }
    },
    openSubviewTable(row) {
      this.$refs.subviewModal.open();
      let subviewItem = this.sendData(row, 'ZUSATZ_DOKUMENTE_ANZEIGEN')?.find(x => x.key === 'param_subview_documents')
      this.paramSubview = subviewItem.value
      this.subviewModalTitle = row.name
    },
    onConfirmActionModal() {
      switch (this.currentAction.key) {
        case "NICHT_VORGELEGT":
        case "ZUR_UNTERSCHRIFT":  
        case "NUR_VERMITTLER":
        case "ANTRAG_LOSCHEN":
        case "DOKUMENT_LOSCHEN":
        case "DOKUMENT_WIEDERHERSTELLEN":
        case "DOKUMENT_ABSCHLIESSEN":
        case "DOKUMENT_NICHT_VORLEGEN":
        case "DOKUMENT_VORLEGEN":
          axios.post(`${process.env.VUE_APP_API}/dokumentenarchiv/additionalDocumentsActions?nodeId=${this.nodeId}&fileId=${this.fileId}`,
            { sendData: this.sendData(this.currentAction.row, this.currentAction.key), legend: { key: this.currentAction.key } }, 
            { headers: {'Content-Type': 'application/json'},
              defaultSpinner: true
            }).then(response => {
              if (response?.data) {
                if (response.data.errorMessage) {
                  this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorMessage, 'danger'));
                } else if (response.data.warningMessage) {
                  this.warningTitle = response.data.warningTitle;
                  this.warningMessage = response.data.warningMessage;
                  this.$refs.warningModal.open();
                } else {
                  this.retrieveAdditionalDocuments();
                }
              }
            })
          break;
        default:                
          break;
      }
    },
    confirmAbschliessen() {
      if (this.currentAction) {
        const data = this.sendData(this.currentAction.row, this.currentAction.key);
        data.push({ key: 'PARAM_IS_CONFIRMED_SUBMIT_MIT_BLANK_SIGNATURES', value: '1' });
          axios.post(`${process.env.VUE_APP_API}/dokumentenarchiv/additionalDocumentsActions?nodeId=${this.nodeId}&fileId=${this.fileId}`,
            { sendData: data, legend: { key: this.currentAction.key } }, 
            { headers: {'Content-Type': 'application/json'}, defaultSpinner: true }).then(response => {
              if (response?.data) {
                this.retrieveAdditionalDocuments();
              }
          });
      }
    },
    openSignPdFile(urlStr) {
      if (urlStr) {
        window.open(urlStr, "_blank");
      }
    }, 
    hasAction(whatAction) {
      return this.actions && this.actions.some(r=> r.legend?.key == whatAction);
    },
    rowHasAction(row, whatAction) {
      return row && row[whatAction] || row?.actions?.some(r=> r.legend && r.legend.key == whatAction);
    },
    listHasAction(actions, whatAction) {
      return actions?.some(r=> r.legend?.key == whatAction);
    },  
    closeSubviewModal() {
      this.paramSubview = null;
      this.subviewModalTitle = ''
    },    
    openZusatzDokModal(item) {
      if (item.signoElement) {
        this.selectedDoc = item.signoElement
        setTimeout(() => {
          this.$refs.pictureAttachmentsModal.open();
        }, 100);
      }
    },
    sendData(row, aktionKey) {
      let sendData;
      if (aktionKey) {
        sendData = row?.actionsObjects?.find(act => act.legend?.key === aktionKey)?.sendData || [];
      } else {
        sendData = [];
        row?.actionsObjects?.forEach(act => sendData.push(...act.sendData || []));
      }
      return sendData;
    },
    makeLink(row) {
      const filename = row?.filename || row?.name;
      let sendList = []
      const sendData = this.sendData(row, "DOKUMENT_ANZEIGEN");
      if (sendData?.length) {
        sendData.forEach(x => {
          sendList.push(x.key)
          sendList.push(x.value)
        })
      }
      return this.attachmentLinkMaker(filename, this.nodeId, sendList)
    },
    uploadProhypDocument(row) {
      // fileId is in row.data
      this.$refs.prohypDokumenteUploadModal.open(row.data)
    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to,
      from,
    });

    await this.saveChanges();

    this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RESET_DATA);
    
    next();
  },  

}
</script>
