<template>
  <BaseModal 
    ref="modal" 
    modalTitle="Standard wiederherstellen"
    labelButtonConfirm="Bestätigen"
    @onConfirmButton="onResetDefault"
  >
    <template #default>
      <div class="config-item pb-3">
        <div>{{configContextLabel}} (<strong>nur {{ currentMenuLabel }}</strong>)</div>
        <InputToggleSwitch label="Einstellungen" v-model="form.resetConfig" class="p-0" />
        <div v-if="form.resetConfig" class="config-item-levels">
          <InputToggleSwitch 
            v-for="(tab, index) in tabList" :key="index"
            :label="tab.label" 
            v-model="form.configUserLevels[tab.key]"
            class="p-0" />
        </div>
      </div>

      <div v-if="enablePermissionConfig" class="config-item">
        <div>{{configContextLabel}} (<strong>Alle</strong>)</div>
        <InputToggleSwitch label="Zugriff" v-model="form.resetPermissionConfig" class="p-0" />
        <div v-if="form.resetPermissionConfig" class="config-item-levels">
          <InputToggleSwitch 
            v-for="(tab, index) in permissionUserLevels" :key="index"
            :label="tab.label" 
            v-model="form.permissionConfigUserLevels[tab.key]"
            class="p-0" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  props: {
    configContext: {
      type: String,
      default: null,
    },
    configContextLabel: {
      type: String,
      default: null,
    },
    currentMenu: {
      type: Object,
      default: null,
    },
    userLevel: {
      type: String,
      default: null,
    },
    enablePermissionConfig: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        resetConfig: true,
        configUserLevels: {},
        resetPermissionConfig: false,
        permissionConfigUserLevels: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      tabListFn: MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_TABS,
      isOptionsMenuPermissionConfigurableFn: MENU_CONFIG_TYPES.GETTERS.IS_OPTIONS_MENU_PERMISSION_CONFIGURABLE,
    }),
    tabList() {
      return this.tabListFn(this.configContext);
    },
    currentMenuLabel() {
      return this.currentMenu?.label;
    },
    permissionUserLevels() {
      return this.enablePermissionConfig && this.tabList.filter(tab => this.isOptionsMenuPermissionConfigurableFn(this.configContext, tab.key));
    },
  },
  methods: {
    init() {
      this.form.resetConfig = true;
      this.form.configUserLevels = this.userLevel ? { [this.userLevel]: true, } : {};
      this.form.resetPermissionConfig = false;
      this.form.permissionConfigUserLevels = {};
    },
    open() {
      this.init();
      this.$refs.modal?.open();
    },
    close() {
      this.$refs.modal?.close();
    },
    onResetDefault() {
      const { resetConfig, configUserLevels, resetPermissionConfig, permissionConfigUserLevels, } = this.form;

      const configUserLevelsChecked = Object.keys(configUserLevels || {})
        .filter(userLevel => resetConfig && configUserLevels[userLevel] === true);

      const permissionConfigUserLevelsChecked = Object.keys(permissionConfigUserLevels || {})
        .filter(userLevel => resetPermissionConfig && permissionConfigUserLevels[userLevel] === true);

      this.$emit('onResetDefault', {
        configUserLevels: configUserLevelsChecked,
        permissionConfigUserLevels: permissionConfigUserLevelsChecked,
      });
    },
  },
  components: {
    BaseModal,
    InputToggleSwitch,
  },
}
</script>

<style scoped>
.config-item .config-item-levels {
  margin: 0 0 0 16px;
}
</style>
