
export const SPECIAL_TYPE_REVENUE_PENSION_INCOME = 'Renteneinnahmen';
export const SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME = 'Sparquote bis Renteneintritt';
export const SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL = 'weitere Vermögenswerte (nicht im System)';
export const SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT = 'Lebenshaltungskosten ab Renteneintritt';

export const zieleTypes = [
  SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT,
  'Immobilienkauf',
  'Urlaub',
  'größere Anschaffung',
  'Sonstiges',
  'weiderkehrende Ausgaben',
];

export const revenueTypes = [
  SPECIAL_TYPE_REVENUE_PENSION_INCOME,
  SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME,
  SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL,
  'Einmalige Einnahme',
  'Regelmässite Einnahme',
];

export const periodTypes = [
  'pro Monat',
  'pro Jahr'
];

export const repeatTypes = [
  'Keine Wiederholung',
  'Wiederholung',
];

export const goalTypes = {
  reserve: 'reserve', // Rücklage
  holiday: 'holiday', // Urlaub
  purchase: 'purchase', // Größere Anschaffung
  estate: 'estate', // Immobilie
  retirement: 'retirement' // Altersvorsorge
}