export const MUTATION_PREFIX = 'BIPRO: ';
export const ACTIONS_PREFIX = 'BIPRO_ACTIONS_';
export const GETTERS_PREFIX = 'BIPRO_GETTERS_';

export default {
    MUTATIONS: {
        RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
        GESELLSCHAFT_OPTIONS: MUTATION_PREFIX + 'GESELLSCHAFT_OPTIONS',
        GESELLSCHAFT_OPTIONS_INTERFACE: MUTATION_PREFIX + 'GESELLSCHAFT_OPTIONS_INTERFACE',
        SAVE_FILTERS: MUTATION_PREFIX + 'SAVE_FILTERS',
        SAVE_SORTING: MUTATION_PREFIX + 'SAVE_SORTING',
        PARAM_RETURN_VERS_VERTRAG: MUTATION_PREFIX + 'PARAM_RETURN_VERS_VERTRAG',
        UPDATE_DEEPLINKS: MUTATION_PREFIX + 'UPDATE_DEEPLINKS',
    },
    ACTIONS: {
        LOAD_GESELLSCHAFT_OPTIONS: ACTIONS_PREFIX + 'LOAD_GESELLSCHAFT_OPTIONS',
        LOAD_GESELLSCHAFT_OPTIONS_INTERFACE: ACTIONS_PREFIX + 'LOAD_GESELLSCHAFT_OPTIONS_INTERFACE',
        FIND_INSURANCE_DEEPLINKS: ACTIONS_PREFIX + 'FIND_INSURANCE_DEEPLINKS',
    },
    GETTERS: {
        GESELLSCHAFT_OPTIONS: GETTERS_PREFIX + 'GESELLSCHAFT_OPTIONS',
        GESELLSCHAFT_OPTIONS_WITH_ALL: GETTERS_PREFIX + 'GESELLSCHAFT_OPTIONS_WITH_ALL',
        GESELLSCHAFT_OPTIONS_INTERFACE: GETTERS_PREFIX + 'GESELLSCHAFT_OPTIONS_INTERFACE',
        SAVED_FILTERS: GETTERS_PREFIX + 'SAVED_FILTERS',
        SAVED_SORTING: GETTERS_PREFIX + 'SAVED_SORTING',
        PARAM_RETURN_VERS_VERTRAG: GETTERS_PREFIX + 'PARAM_RETURN_VERS_VERTRAG',
        DEEPLINKS: GETTERS_PREFIX + 'DEEPLINKS',
    }
}
