var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "prohypDocumentsModal",
          attrs: {
            modalTitle: "Prohyp-Dokument Auswählen",
            labelButtonConfirm: "Übernehmen",
            confirmDisabled: !_vm.selectedRow,
            confirmLoading: _vm.confirmLoading,
            autoClose: false,
            size: "xl"
          },
          on: { onConfirmButton: _vm.uploadProhypDocument }
        },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10
                },
                on: {
                  "action-CONFIRM": function($event) {
                    return _vm.$emit("confirm", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "selected",
                    fn: function(row) {
                      return [
                        _c("InputRadioBoxItem", {
                          attrs: { name: "selectFonds" },
                          on: {
                            change: function($event) {
                              _vm.selectedRow = row
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }