const DEFAULT_PRECISION = 2;
import FinancialCalculator from "@/components/retirementScenario/financialCalculator";

// function newFixed(num, fixed) {
//   var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
//   return num.toString().match(re)[0];
// }

export function formatNumber(value, precision) {
  if(!value && value !== 0) {
    return value;
  }

  let numericValue = value;

  try {
    numericValue = typeof value === 'number' ? value : parse(value)
  } catch (error) {
    // empty block
  }
  if (isNaN(numericValue))
    return "";
  return numericValue.toLocaleString('de-DE', {style: 'decimal', maximumFractionDigits: precision, minimumFractionDigits: precision });
}

export function parse(value, precision) {
  if (value && value !== undefined && value.replace) {
    const precisionAux = precision || DEFAULT_PRECISION;
    const regex = new RegExp(`[,][0-9]{0,${precisionAux}}`, 'gm');

    let newValue = value;
    if (newValue.match(regex)) { // it is comma separated e.g. 0,50
      newValue = value.replace(/\./g, '').replace(/,/g, '.');
    }
    if(precision) {
      return parseFloat(parseFloat(newValue).toFixed(precision)); // parseFloat twice because .toFixed returns a string
    } else {
      return parseFloat(newValue);
    }
  }

  return value;
}

export function isNumber(val) {
  const newVal = FinancialCalculator.convertStringToNumber(val);
  return typeof newVal === 'number' && !isNaN(newVal);
}
