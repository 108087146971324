var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _vm.isInternalRemark
      ? _c("div", [
          _c("b", [_vm._v("Interne Bemerkung - nur für Sie selbst sichtbar")])
        ])
      : _vm._e(),
    _c("div", { staticClass: "d-flex", staticStyle: { gap: "8px" } }, [
      _c(
        "div",
        { staticClass: "chat-editor" },
        [
          _vm.richText
            ? _c("HtmlEditor", {
                staticClass: "text-input",
                attrs: {
                  isFontParams: "",
                  isPreviewButton: "",
                  suppressValidationMessage: "",
                  startEditorFocused: "",
                  validateUntouched: "",
                  textPlaceholderOptions: _vm.placeholdersOptions,
                  previewHTML: _vm.previewHtml.text,
                  disabled: _vm.isSending
                },
                on: {
                  onTogglePreviewEnabled: _vm.handlePreview,
                  change: _vm.onChange,
                  isEditorValid: _vm.setEditorValid
                },
                model: {
                  value: _vm.textInput,
                  callback: function($$v) {
                    _vm.textInput = $$v
                  },
                  expression: "textInput"
                }
              })
            : _vm._e()
        ],
        1
      ),
      (_vm.richText && _vm.isSmallScreen) || (_vm.richText && _vm.embedded)
        ? _c("div", { staticClass: "chat-footer__send-button-container" }, [
            _c(
              "div",
              [
                _c(
                  "BaseButton",
                  {
                    staticClass: "m-0",
                    attrs: { disabled: !_vm.isEditorValid },
                    on: {
                      click: function($event) {
                        return _vm.onSendChat()
                      }
                    }
                  },
                  [_c("PhPaperPlaneRight", { attrs: { size: 18 } })],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]),
    (_vm.chatInfo && _vm.chatInfo.readonly) || _vm.disabled || _vm.chatId == 0
      ? _c(
          "div",
          {
            staticClass:
              "fc-alert fc-alert-primary mt-3 mb-0 text-centered text-small"
          },
          [
            _vm._v(
              " Es muss zuerst ein Text erfasst und gesendet werden, bevor ein Dokument hinzugefügt werden kann. "
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }