var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dashboard-panel__widgets" }, [
      _c("div", { staticClass: "dashboard-panel-widget" }, [
        _c("img", {
          staticClass: "image-cover-vertically",
          staticStyle: { height: "300px" },
          attrs: { alt: "Platzhalter", src: _vm.defaultMyGoalImage }
        })
      ]),
      _c(
        "div",
        { staticClass: "dashboard-panel-widget" },
        [
          _c("Table", {
            ref: "myGoalCardTable",
            attrs: {
              tableId: "dac58861-120a-3a41-a469-f55f487e86a4",
              rowId: "name",
              hidePagination: "",
              headers: _vm.headers,
              rows: _vm.rows
            },
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function(row) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.formatValue(row.value, row.type)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "row justify-content-center" },
            [
              _c("PieChart", {
                staticStyle: { width: "150px", height: "150px" },
                attrs: {
                  chartData: _vm.eChartData(_vm.goal),
                  isTooltip: false,
                  customColors: _vm.eChartColors,
                  radiusProp: ["75%", "100%"],
                  doughnut: ""
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "bottom-row text-right" },
      [
        _c("router-link", { attrs: { to: _vm.openLink } }, [
          _vm._v(" Mein Ziel anzeigen ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }