var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "date-picker-dropdown__container",
      class: { "is-range-picker": _vm.isRangePicker }
    },
    [
      _c("BaseDropdownMenu", {
        ref: "dropdownMenu",
        staticClass: "date-picker-dropdown--dropdown-menu",
        attrs: { disabled: _vm.disabled, closeWithInnerClick: false },
        on: {
          onShow: function($event) {
            return _vm.$emit("showedPicker", true)
          },
          onHide: function($event) {
            return _vm.$emit("showedPicker", false)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "hook-target",
              fn: function() {
                return [_vm._t("field")]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [_vm._t("container")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "div",
        { staticClass: "date-picker-dropdown--modal" },
        [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.openModal()
                }
              }
            },
            [_vm._t("field")],
            2
          ),
          _c("BaseModal", {
            ref: "modal",
            attrs: {
              modalTitle: _vm.label,
              size: _vm.modalSize,
              showDefaultButtons: false,
              actions: _vm.modalActions
            },
            on: {
              "action-ENABLE-KEYBOARD": function($event) {
                return _vm.$emit("enableKeyboard")
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [_vm._t("container")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }