<template>
  <div>
    <BaseModal ref="deleteModal" 
      :modalTitle="message ? title : 'Vertrag löschen?'"
      labelButtonConfirm="Vertrag löschen"
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()">
      
      <div v-if="message">
        <span>{{message}}</span>
      </div>
      <div v-else>
        Soll die Versicherung <span class="font-bold">{{ insuranceDescription }}</span> wirklich entfernt werden?
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';

export default {
    components: {
      BaseModal,
    },
    props: {
      insurance: {
      },
      insuranceLabel: {
      }
    },
    data() {
      return {
        message: '',
        title: 'Aktiver Maklerauftrag gefunden'
      }
    },
    computed: {
      insuranceDescription() {
        return this.insuranceLabel
      },
    },
    methods: {
        open(message) {
          this.message = message;
          this.$refs.deleteModal.open();
        },
        onConfirmModal() {
          this.$emit('delete');
          this.$refs.deleteModal.close();
        },
        onCancelModal() {
           this.$refs.deleteModal.close();
        },
    }
}
</script>

<style>

</style>