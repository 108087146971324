var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c(
        "div",
        { staticClass: "input-forms__container" },
        [
          _c("ComboBox", {
            attrs: {
              label: "Bereits vorhandene Depots",
              values: _vm.vorhandeneDepotsAuswahl,
              value: _vm.antragData.INPUT_VORHANDENE_KONTEN_AUSSETZNG,
              sortComboboxValues: false
            },
            on: {
              input: function($event) {
                return _vm.updateStore(
                  "INPUT_VORHANDENE_KONTEN_AUSSETZNG",
                  $event
                )
              }
            }
          }),
          _vm.selectedDepot
            ? _c("WertpapierorderAnteileInfo", {
                attrs: { position: _vm.selectedDepot }
              })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row mt-0" }, [
          _c("div", { staticClass: "col" }, [
            _vm.label
              ? _c(
                  "div",
                  {
                    staticClass:
                      "\n              input-forms__label-content input-forms__label-content--bigger\n            "
                  },
                  [
                    _c("ph-bank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.label) + " ")
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.config && _vm.config.buttonText
            ? _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                    _vm._v(_vm._s(_vm.config.buttonText))
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.rows && _vm.rows.length
          ? _c("Table", {
              attrs: {
                hidePagination: "",
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "index"
              },
              on: { "action-DELETE": _vm.openModalDeleteFond },
              scopedSlots: _vm._u(
                [
                  {
                    key: "dateAb",
                    fn: function(row) {
                      return [
                        _c("DatePickerField", {
                          attrs: {
                            dateFormat: "DD.MM.YYYY",
                            isValueAsString: ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "dateAb"
                              )
                            }
                          },
                          model: {
                            value: row.dateAb,
                            callback: function($$v) {
                              _vm.$set(row, "dateAb", $$v)
                            },
                            expression: "row.dateAb"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "dateBis",
                    fn: function(row) {
                      return [
                        _c("DatePickerField", {
                          attrs: {
                            dateFormat: "DD.MM.YYYY",
                            isValueAsString: ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "dateBis"
                              )
                            }
                          },
                          model: {
                            value: row.dateBis,
                            callback: function($$v) {
                              _vm.$set(row, "dateBis", $$v)
                            },
                            expression: "row.dateBis"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "betrag",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: { type: "currency" },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "betrag"
                              )
                            }
                          },
                          model: {
                            value: row.betrag,
                            callback: function($$v) {
                              _vm.$set(row, "betrag", $$v)
                            },
                            expression: "row.betrag"
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                1406622307
              )
            })
          : _vm._e(),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: { position: _vm.positionToDelete },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }