<template>
    <BaseModal
      ref="editDocumentModal"
      modalTitle="Dokument bearbeiten"
      labelButtonConfirm="Speichern"
      @onConfirmButton="confirmButton()">

        <InputField
            label="Datei"
            :value="data.filename"
            disabled
            @input="update('filename', $event)"
        />
        <InputField
            label="Bezeichnung"
            :value="data.bezeichnung"
            @input="update('bezeichnung', $event)"
        />
        <InputField
            label="Bemerkung"
            :value="data.bemerkung"
            @input="update('bemerkung', $event)"
        />
        <DatePickerField
            label="Stand"
            dateFormat="DD.MM.YYYY"
            :value="data.stand"
            isValueAsString
            @input="update('stand', $event)"
        />
    </BaseModal>  
</template>

<script>

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
    components: {
        BaseModal,
        InputField,
        DatePickerField,
        ComboBox,
    },
    model: {
        prop: 'data',
        event: 'data',
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        open() {
            this.$refs.editDocumentModal.open()
        },
        confirmButton() {
            this.$emit('confirm')
        },
        update(key, value) {
            this.$emit('data', { ...this.data, [key]: value })
        },
    }

}
</script>