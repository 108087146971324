var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderActionCard", {
        attrs: { title: "Ausgang", showBreadcrumb: false },
        scopedSlots: _vm._u([
          {
            key: "actionButtons",
            fn: function() {
              return [
                _c("div", { staticClass: "mb-3" }, [
                  _vm._v(
                    ' Hier können Sie Ihren E-Mail Server für den Versand Ihrer Kunden-E-Mails eintragen. Dies hat den Vorteil, dass Ihre E-Mail Absenderadresse genau zu dem versendenden Server passt und somit Spam Filter des Kunden Ihre E-Mails unproblematischer durchlassen. Wenn Sie dies nicht wünschen, dann lassen Sie die Einträge auf dieser Seite einfach leer und/ oder entfernen Sie den Haken hinter "Senden per externen Mailserver". Es wird dann der Systemserver für den Versand der Mails verwendet. '
                  )
                ]),
                _c("div", [
                  _vm._v(
                    ' Wenn Sie nur vorübergehend die Mails über den Systemserver versenden wollen, dann nehmen Sie nur den Haken "Senden per externen Mailserver" heraus. Später können Sie den Versand über Ihren Server dann wieder aktivieren. '
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.form && _vm.form.externen && _vm.isMicrosoftlinkVisible
            ? _c(
                "BaseButton",
                {
                  attrs: { isPrimary: "" },
                  on: {
                    click: function($event) {
                      return _vm.getMicrosoftLink()
                    }
                  }
                },
                [_vm._v("(Microsoft) Berechtigungen erteilen")]
              )
            : _vm._e(),
          _vm.form && _vm.form.externen && _vm.isGooglelinkVisible
            ? _c(
                "BaseButton",
                {
                  attrs: { isPrimary: "" },
                  on: {
                    click: function($event) {
                      return _vm.giveGoogleMailSendRights()
                    }
                  }
                },
                [_vm._v("(Google) Berechtigung erteilen")]
              )
            : _vm._e(),
          _c("ComboBox", {
            attrs: {
              label: "Servertyp",
              values: _vm.types,
              firstEmpty: false,
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                return _vm.typeChanged($event)
              }
            },
            model: {
              value: _vm.form.type,
              callback: function($$v) {
                _vm.$set(_vm.form, "type", $$v)
              },
              expression: "form.type"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Server",
              id: "form.server",
              disabled: _vm.disabled || _vm.isMicrosoft || _vm.isGoogle,
              isComponentHalfSize: "",
              validateUntouched: ""
            },
            model: {
              value: _vm.form.server,
              callback: function($$v) {
                _vm.$set(_vm.form, "server", $$v)
              },
              expression: "form.server"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Port",
              disabled: _vm.disabled || _vm.isMicrosoft || _vm.isGoogle,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.port,
              callback: function($$v) {
                _vm.$set(_vm.form, "port", $$v)
              },
              expression: "form.port"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Benutzer",
              id: "form.name",
              disabled: _vm.disabled || _vm.isGoogle,
              hidden: _vm.isGoogle && !_vm.form.name,
              isComponentHalfSize: "",
              validateUntouched: ""
            },
            on: {
              input: function($event) {
                return _vm.updateMicrosoftConfirmed()
              }
            },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Passwort",
              type: _vm.passwordType,
              disabled: _vm.disabled || _vm.isMicrosoft,
              hidden: _vm.isGoogle,
              isComponentHalfSize: ""
            },
            on: {
              focus: function($event) {
                return _vm.handlePswField(true)
              }
            },
            model: {
              value: _vm.form.passwd,
              callback: function($$v) {
                _vm.$set(_vm.form, "passwd", $$v)
              },
              expression: "form.passwd"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: {
              label: "Zertifikatsprüfung durchführen",
              disabled: _vm.disabled,
              inLineLabel: ""
            },
            model: {
              value: _vm.form.zertifikatCheck,
              callback: function($$v) {
                _vm.$set(_vm.form, "zertifikatCheck", $$v)
              },
              expression: "form.zertifikatCheck"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: {
              label: "Senden per externen Mailserver",
              disabled: _vm.disabled,
              inLineLabel: ""
            },
            model: {
              value: _vm.form.externen,
              callback: function($$v) {
                _vm.$set(_vm.form, "externen", $$v)
              },
              expression: "form.externen"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: {
            modalTitle: "Speichern nicht möglich",
            labelButtonCancel: "Abbrechen",
            labelButtonConfirm: "Zurück ohne speichern",
            showConfirmButton: true
          },
          on: {
            onCancelButton: function($event) {
              return _vm.$refs.errorModal.close()
            },
            onCloseButton: function($event) {
              return _vm.$refs.errorModal.close()
            },
            onConfirmButton: function($event) {
              _vm.canLeavePage = true
              _vm.goBack()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "accessTitle",
              fn: function() {
                return [_vm._v(" Fehler ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              " Die Daten können nicht gespeichert werden. Bitte korrigieren Sie die rot umrandeten Bereiche. "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }