<template>
  <div>
    <div class="box__title" >Stornoreserve Versicherungen</div>
    <div class="box__container" >
      <BaseFilter
        title="Kunden Filter"
        :metadata="searchMetadata"
        :configFilter="configFilter"
        :showSaveButton="false"
        :defaultOptions="defaultOptions"
        @onFilter="search($event)"
        @onFilterZurucksetzen="search(null)"
      />
      <HandleTablePin keyOfPIN="TABELLE_STORNORESERVE_VERS" />
    
      <Table
        v-if="loaded > 0 && rows.length"
        title="Stornoreserve"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="25"
        :exportConfig="{roottext: 'Stornoreserve Versicherungen'}"
      />
      <GhostLoading v-else-if="loaded < 0" type="table" />
      <NoData v-else-if="loaded > 0" noIcon />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BROKERDATA_TYPES from "@/store/brokerData/types";
import BaseFilter from '@/components/core/BaseFilter.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, NumberColumn, DateColumn } from '@/components/table2/table_util';
import HandleTablePin from '@/components/core/HandleTablePin.vue';

export default {
  components: {
    Table, BaseFilter, GhostLoading, NoData, HandleTablePin,
  },
  props: {
  },
  data() {
    return {
      searchMetadata: [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'text',
              label: 'Name',
              key: 'name',
            },
            {
              type: 'text',
              label: 'Vorname',
              key: 'vorname',
            },
          ],
        }
      ],
      configFilter: {
        placeholderForDefSearchInput: 'Kundenname',
        checkDefaultSearchInput: false,
      },
      defaultOptions: {
        name: { value: '' },
      },
    };
  },
  mounted() {
  },
  watch: {},
  computed: {
    ...mapGetters({
      stornoreserveVers: BROKERDATA_TYPES.GETTERS.GET_STORNORESERVE_VERS,
    }),
    loaded() {
      if (this.stornoreserveVers.askPIN) {
        return 0;
      }
      return this.stornoreserveVers.loaded;
    },
    headers() {
      return {
        lockedLeft: [
          TextColumn('KUNDE', 'Kunde'),
        ],
        center: [
          DateColumn('DATUM', 'Datum'),
          NumberColumn('PROVISIONSUMME', 'Stornoreserve', 2),
          TextColumn('GESELLSCHAFT', 'Gesellschaft'),
          TextColumn('POL_NR', 'Pol. Nummer'),
          TextColumn('VERTRAG_ID', 'Interne Nr.'),
        ],
        lockedRight: [
        ],
      };
    },
    rows() {
      return this.stornoreserveVers.rows || [];
    },
  },
  methods: {
    search(filters) {
      let query = '';
      if ( filters?.length ) {
        filters.forEach(f => {
          if (f.value) {
            query += (!query ? '?' : '&') + f.key + '=' + f.value;
          }
        })
      }
      this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.LOAD_STORNORESERVE_VERS, query)
    },
  },
};

</script>

<style scoped>
</style>