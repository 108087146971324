<template>
  <div class="box__container-wrap">
    <template v-if="!loading && hasCharts">
      <div class="box__container-rows">
        <div class="d-flex justify-content-center mb-3">
          <ComboBox
            :value="selectedChart"
            :values="chartsValues"
            @change="setSelectedChart($event)"
          />
        </div>
        <div :key="selectedChart">
          <DepotpositionsChart v-if="selectedChart !== 'depotid'" :chart="chart" :showTitle="false" />
        </div>
      </div>
      <div class="bottom-row text-right">
        <a @click="openDiagrammePage()">weitere...</a>
      </div>
    </template>
    <GhostLoading v-else-if="loading" type="block" :config="{ block: { height: 350, }, }" />
    <NoData v-else-if="isLoaded" noIcon />
  </div>
</template>

<script>
import {mapGetters } from "vuex";
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";

import ComboBox from '@/components/core/forms/ComboBox.vue';
import DepotpositionsChart from './DepotpositionsChart.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import { 
  ALL_DEPOTS,
  MAP_CHART_TYPE_TO_TITLE,
} from './types';
const ChartDistributionFunds = 'ChartDistributionFunds';

export default {
  data() {
    return {
      loading: false,
      isLoaded: false,
      // depotid: ALL_DEPOTS,
    };
  },
  computed: {
    ...mapGetters({
      charts: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CHARTS,
      config: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CONFIG,
      selectedChart: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_CHART_WIDGET,
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
    }),
    depotCharts() {
      return this.charts && this.charts[this.depotid] && this.charts[this.depotid].charts || {};
    },
    hasCharts() {
      return Object.keys(this.depotCharts).length > 0;
    },
    chartsValues() {
      return Object.keys(this.depotCharts).map(key => ({
        label: this.getTitle(key),
        value: key,
      }));
    },
    chart() {
      return this.depotCharts[this.selectedChart] || {};
    },
  },
  watch: {
    depotid () {
      this.retrieveCharts();
      this.$store.commit(DEPOTPOSITIONS_TYPES.MUTATIONS.CHANGE_ID, this.depotid);
    },
    config: 'retrieveCharts',
    depotCharts: {
      handler() {
        this.setSelectedChartIfNeeded();
      },
      immediate: true,
    },
  },
  methods: {
    async retrieveConfig() {
      // reload every time in case the user has changed some config
      await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CONFIG, {});
    },
    async retrieveCharts() {
      if (this.depotid && this.charts && !this.charts[this.depotid] && this.config && this.config.charts) {
        try {
          this.loading = true;
          await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CHARTS, {
            depotid: this.depotid,
            charts: this.config.charts
          });
        } finally {
          this.loading = false;
          this.isLoaded = true;
        }
      }
    },
    getTitle(key) {
      return MAP_CHART_TYPE_TO_TITLE[key] || key || '';
    },
    setSelectedChart(chart) {
      this.$store.commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_SELECTED_CHART_WIDGET, { chart });
    },
    setSelectedChartIfNeeded() {
      const chartsKeys = Object.keys(this.depotCharts);
      if(chartsKeys.length && !this.selectedChart) {
        const key = chartsKeys.find(v => v === ChartDistributionFunds) && ChartDistributionFunds || chartsKeys[0];
        this.setSelectedChart(key);
      }
    },
    openDiagrammePage() {
      this.$router.push('/home/berichte/diagramme', () => {
        window.scrollTo(0, 0);
      });
    },
  },
  async mounted() {
    this.loading = true;
    await this.retrieveConfig();
    await this.retrieveCharts();
    this.loading = false;
  },
  components: {
    ComboBox,
    DepotpositionsChart,
    GhostLoading,
    NoData,
  },
};
</script>
