<template>
    <div>
      <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
  
      <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Zuordnung Gesellschaften zu BiPRO Zugängen" />
  
   
      <BaseFilter 
        filterId="8ad723f9-8cca-421e-91b5-05066e59725e"
        title="Bipro Gesellschaften filtern" 
        :metadata="gesellschaftFilterMetadata" 
        :defaultOptions="gesellschaftFilterDefaultOptionsData"
        @onFilter="filterGesellschaftForm($event)">
      </BaseFilter>
	  
      <div class="box__container" tid="13cd1ac7-d05e-4508-971b-1a4e18f0a0b2">

        <Table
          tableId="354e2f99-56e1-4f4a-a8c1-4f59915d92d5"
          title="BiPRO Gesellschaften"
          class="table-container"
          v-if="!loadingGesellschaften && gesellschaftTableRows.length"
          :headers="gesellschaftTableHeaders"
          :rows="gesellschaftTableRows"
          :rowsPerPage="20"
          @click-ICON="addEntry"
          @action="executeAction($event)"
        >
          
        </Table>
        <GhostLoading v-else-if="loadingGesellschaften" type="table" title="BiPRO Gesellschaften" />
        <NoData v-else title="BiPRO Gesellschaften" />
      </div> 

   
  
    </div>
      
  </template>
  
  <script>
  import ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES from "../../store/zuordnungGesellschaftenBipro/types";
  import { mapGetters } from "vuex";
  import GhostLoading from '@/components/core/loading/GhostLoading.vue';
  import NoData from '@/components/core/NoData.vue';
  import Table from "@/components/table2/Table.vue";
  import {TextColumn, DateColumn, CurrencyColumn, SlotColumn, ActionColumn, SimpleAction, IconColumn, Icon, dateToSortable} from "@/components/table2/table_util.js";
  import { PhPencil, PhTrash} from "phosphor-vue";
  import BaseModal from '@/components/core/BaseModal.vue';
  import BaseFilter from "@/components/core/BaseFilter.vue";
  import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
  import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
  import validator from '@/mixins/validator'
  import InputField from '@/components/core/forms/InputField.vue'
  import ComboBox from '@/components/core/forms/ComboBox.vue'
  import { required } from '@/mixins/validator/rules';
  import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
  
  const TABLE_TITLE = 'Zuordnung Gesellschaften zu BiPRO Zugängen';
  const DEFAULT_GESELLSCHAFT = 'Adam Riese';
  
  export default {
    mixins: [validator],
    validators: {},
    components: {
      Table,
      GhostLoading,
      NoData,
      BaseModal,
      BaseFilter,
      OptionMenu,
      PageHeaderTitleNavigation,
      InputField,
      ComboBox,
    },
    data: function () {
        return {
          TABLE_TITLE,
          loading: true,
          savedFilters: {},
          loadingGesellschaften: false,
        }
    },
    computed: {
      ...mapGetters({
        searchResult: ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.GETTERS.SEARCH_RESULT,
      }),
      gesellschaftTableHeaders() {
        const lockedLeftColumns = [
          IconColumn("ICON", "").makeLink(),
          TextColumn("gesellschaftId", "Gesellschaft ID").makeAlwaysVisible(),
          TextColumn("gesellschaftName", "Gesellschaft Name"),
          TextColumn("vuNrBafin", "Bafin Kennun (VuNr.)"),
          TextColumn("ort", "Ort"),
        ];
  
        const lockedRightColumns = [ActionColumn("actions", "Aktionen")];
  
        return {
          lockedLeft: lockedLeftColumns,
          lockedRight: lockedRightColumns,
        };
      },      
      gesellschaftTableRows () {
        if (!this.searchResult?.length ) {
          return [];
        }
  
        const fullActions = [
            SimpleAction("ADD_ENTRY", PhTrash, "Eintrag hinzufügen"),
        ];
  
        return this.searchResult?.map(data => ({
             ...data,
            ICON: Icon("PhArrowFatRight", "Interne Nr. übernehmen und Versicherungsdokument direkt zuordnen"),
            actions: fullActions,
        }));
  
      },      
      gesellschaftFilterDefaultOptionsData() {
        return {
            name: {
                value: "",
            },
            id: {
                value: "",
            },            
          }; 
      },       
      gesellschaftFilterMetadata() {
           return [
              {
                type: "group",
                key: "allgemein",
                label: "Allgemein",
                menuItems: [
                {
                  type: 'text',
                  key: 'name',
                  label: 'Name',
                },
                {
                  type: 'text',
                  key: 'id',
                  label: 'Id',
                },
              ],

              },     
            ];  
      }      
    },
    methods: {
      async addEntry(row) {
        this.loadingGesellschaften = true;
        try {
          await this.$store.dispatch(ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.ACTIONS.SAVE, 
            {
              extZugangBezeichnung: this.$route.query.extZugangBezeichnung,
              gesellschaftId: row?.gesellschaftId,
              vuNrBafin: row?.vuNrBafin
            });
        } catch (error) {
          console.error("An error occurred while saving or updating:", error);
        } finally {
          this.loadingGesellschaften = false;
          this.$router.go(-1);
        }
      },
      async executeAction(actionData) {
        switch (actionData.key) {
          case 'ADD_ENTRY':
            await this.addEntry(actionData.row);
            break;     
        }
      },   
      async retrieveGesellschaftenData(payload) {
        this.loadingGesellschaft = true;
        await this.$store.dispatch(ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.ACTIONS.SEARCH, payload);
        this.loadingGesellschaft = false;
      },      
      async filterGesellschaftForm(filterConfig) {
        const getValueOrNull = (key) => {
          const value = filterConfig.find(fc => fc.key == key)?.value;
          return value === "" ? null : value;
        };

        const keys = ["id", "name",];
        const data = {};

        keys.forEach(key => {
          const value = getValueOrNull(key);
          if (value !== null) {
            data[key] = value;
          }
        });

        await this.retrieveGesellschaftenData(data);
      },
  
    },
    async beforeRouteLeave(to, from, next) {
      this.$addBreadcrumb({
        label: 'zurück zu Gesellschaften Hinzufügen Übersicht', 
        fullPath: from.fullPath,
        breadcrumb: to?.meta?.breadcrumb?.label,
      });

      this.$store.commit(ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.MUTATIONS.RESET_STATE);
  
      next();
    },
  
  
      
  }
  </script>