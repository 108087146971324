var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MailComposer", {
        attrs: {
          custom: true,
          customFunctions: _vm.customFunctions,
          skipPreInit: _vm.$route.meta.beratungsmappen
        },
        on: { callbacks: _vm.setCallbacks }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }