<template>
  <div class="customer-search__result-icon">
    <span v-if="isKSC">
      <ph-monitor :size="16"/> {{dateKSCNutzung}}
    </span>
    <br v-if="isKSC && isAPP">
    <span v-if="isAPP">
      <ph-device-mobile-speaker :size="16"/> {{dateAppNutzung}}
    </span>
  </div>
</template>

<script>
import { PhMonitor, PhDeviceMobileSpeaker } from 'phosphor-vue'
export default {
  props: {
    isAPP: {
      type: Boolean
    },
    isKSC: {
      type: Boolean
    },
    dateAppNutzung: {
      type: String
    },
    dateKSCNutzung:{
      type: String
    }
  },
  computed: {
    /*appClasses() {
      return [this.isAPP ? 'customer-search__result-icon--active' : 'customer-search__result-icon--inactive']
    },
    fcClasses() {
      return [this.isKSC ? 'customer-search__result-icon--active' : 'customer-search__result-icon--inactive']
    },*/
    getDateApp(){
      return this.dateAppNutzung ? "Letzte Nutzung am " + this.dateAppNutzung : '';
    },
    getDateKSC(){
      return this.dateKSCNutzung ? "Letzte Nutzung am " + this.dateKSCNutzung : '';
    }
  },
  components: {
    PhMonitor,
    PhDeviceMobileSpeaker
  }
}
</script>

<style scoped>

</style>