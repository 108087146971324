var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ticket-bewertung__container",
      class: { "ticket-bewertung--centered": _vm.centered }
    },
    [
      _c(
        "label",
        { staticClass: "bewertung" },
        [
          _c("input", {
            attrs: { type: "radio", name: "bewertung", disabled: _vm.disabled },
            domProps: { checked: _vm.bewertung == 1, value: 1 },
            on: {
              change: function($event) {
                return _vm.setBewertung(1)
              }
            }
          }),
          _c("PhSmiley", {
            attrs: {
              size: 36,
              color:
                _vm.bewertung == 1
                  ? "var(--color-success)"
                  : "var(--color-text)"
            }
          })
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "bewertung mx-3" },
        [
          _c("input", {
            attrs: { type: "radio", name: "bewertung", disabled: _vm.disabled },
            domProps: { checked: _vm.bewertung == 3, value: 3 },
            on: {
              change: function($event) {
                return _vm.setBewertung(3)
              }
            }
          }),
          _c("PhSmileyMeh", {
            attrs: {
              size: 36,
              color:
                _vm.bewertung == 3
                  ? "var(--color-warning)"
                  : "var(--color-text)"
            }
          })
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "bewertung" },
        [
          _c("input", {
            attrs: { type: "radio", name: "bewertung", disabled: _vm.disabled },
            domProps: { checked: _vm.bewertung == 5, value: 5 },
            on: {
              change: function($event) {
                return _vm.setBewertung(5)
              }
            }
          }),
          _c("PhSmileySad", {
            attrs: {
              size: 36,
              color:
                _vm.bewertung == 5 ? "var(--color-danger)" : "var(--color-text)"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }