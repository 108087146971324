
import BROKERDATA_TYPES from '@/store/brokerData/types';
import { mapGetters } from 'vuex';

export default {
  mounted() {
    if ( this.tablePageSize === -1){
      this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.LOAD_BROKER_PAGE_TABLE_SIZE);
      console.log('LOAD_BROKER_PAGE_TABLE_SIZE')
    }
  },
  computed: {
    ...mapGetters({
      tablePageSize: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
    }),
    maxCount() {
      if (this.tablePageSize > 0) {
        return this.tablePageSize;
      }
      return  20;
    },
  }
}