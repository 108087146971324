var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MultiBankingNavigation", {
        attrs: { subtitle: "monatliches Sparpotential" }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.hasSavingsPotentialByAccount || _vm.filteredAccounts.length
            ? [
                _vm.hasSavingsPotentialByAccount
                  ? _c("BarChart", {
                      attrs: {
                        labels: _vm.chartLabels,
                        chartData: _vm.chartData,
                        height: "400px"
                      }
                    })
                  : _vm._e(),
                _vm.filteredAccounts.length
                  ? _c("KontoverwaltungAccountTable", {
                      attrs: {
                        accounts: _vm.filteredAccounts,
                        useBoxContainer: false
                      },
                      model: {
                        value: _vm.selectedAccounts,
                        callback: function($$v) {
                          _vm.selectedAccounts = $$v
                        },
                        expression: "selectedAccounts"
                      }
                    })
                  : _vm._e()
              ]
            : _c("NoData")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }