<template>
	<svg
		data-name="Layer 1"
		xmlns="http://www.w3.org/2000/svg"
		width="504.87298"
		height="452.10516"
		viewBox="0 0 504.87298 452.10516"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<path
			d="M380.33038,251.43847c-28.9381,64.49813-58.82538,234.46429,5.67275,263.40239A128,128,0,1,0,490.79726,281.27247C426.29913,252.33437,409.26848,186.94034,380.33038,251.43847Z"
			transform="translate(-347.56351 -223.94742)"
			:fill="secondColor"
		/>
		<path
			d="M700.13329,527.60074a6.87629,6.87629,0,0,1-6.37348-4.33133l-31.66189-68.21892a14.07286,14.07286,0,1,1,26.16889-10.362l27.66142,68.2194a6.79153,6.79153,0,0,1,.4781,2.82512h0a6.86544,6.86544,0,0,1-4.32513,6.06645l-13.4303,5.31765C697.83662,527.4397,700.9846,527.60074,700.13329,527.60074Z"
			transform="translate(-347.56351 -223.94742)"
			:fill="color"
		/>
		<path
			d="M531.83685,541.41086a6.85973,6.85973,0,0,1-1.951-1.341l-10.67743-10.441.01176-.012a6.86028,6.86028,0,0,1,.23-9.3334l79.12054-80.91132a14.07374,14.07374,0,0,1,21.1284,18.53486h0a14.33266,14.33266,0,0,1-1.005,1.14312l-79.12053,80.91133a6.86333,6.86333,0,0,1-7.73671,1.44939Z"
			transform="translate(-347.56351 -223.94742)"
			:fill="color"
		/>
		<path
			d="M664.54717,675.36134H650.10261a6.85832,6.85832,0,0,1-6.85062-6.85062V548.1216a6.85831,6.85831,0,0,1,6.85062-6.85062h14.44456a6.85832,6.85832,0,0,1,6.85063,6.85062V668.51072A6.85832,6.85832,0,0,1,664.54717,675.36134Z"
			transform="translate(-347.56351 -223.94742)"
			:fill="color"
		/>
		<path
			d="M632.06334,675.36134H617.61853a6.85832,6.85832,0,0,1-6.85062-6.85062V548.1216a6.85831,6.85831,0,0,1,6.85062-6.85062h14.44481a6.85831,6.85831,0,0,1,6.85062,6.85062V668.51072A6.85832,6.85832,0,0,1,632.06334,675.36134Z"
			transform="translate(-347.56351 -223.94742)"
			:fill="color"
		/>
		<circle cx="293.08787" cy="136.39001" r="49.91169" :fill="color" />
		<path
			d="M613.48661,383.28952c-3.23536-.08984-7.26194-.20214-10.364-2.46816a7.95918,7.95918,0,0,1-3.13179-5.94317,5.35406,5.35406,0,0,1,1.82066-4.39726c1.62018-1.36909,3.98608-1.69021,6.53571-.94093l-2.64162-19.30465,1.93916-.26569,3.10551,22.69508-1.61936-.74308c-1.8774-.8611-4.4545-1.2993-6.05594.054a3.44012,3.44012,0,0,0-1.12811,2.83372,6.01564,6.01564,0,0,0,2.33041,4.43121c2.414,1.76331,5.6236,1.99078,9.26383,2.09256Z"
			transform="translate(-347.56351 -223.94742)"
			fill="#2f2e41"
		/>
		<rect
			x="238.01025"
			y="127.9528"
			width="10.54175"
			height="1.95732"
			fill="#2f2e41"
		/>
		<rect
			x="271.28471"
			y="127.9528"
			width="10.54175"
			height="1.95732"
			fill="#2f2e41"
		/>
		<path
			d="M677.32137,515.44178l13.69886-61.97967a22.03153,22.03153,0,0,0-22.03133-22.03145H609.31354a22.03153,22.03153,0,0,0-22.03134,22.03145l16.49627,61.50874c-9.48842,1.23855,5.99159,38.98292,5.99159,38.98292l60.677.49364S685.35662,517.09948,677.32137,515.44178Z"
			transform="translate(-347.56351 -223.94742)"
			fill="#2f2e41"
		/>
		<rect x="261.04287" y="321.00636" width="31" height="73" fill="#2f2e41" />
		<rect x="294.04287" y="321.00636" width="31" height="73" fill="#2f2e41" />
		<path
			d="M626.2558,336.09636c-1.539-4.80215-5.79733-8.0447-10.54059-9.37129-6.19687-1.73314-12.70081-.14-18.83063,1.081-5.80772,1.15682-12.28662,2.14123-17.68036-.96145-3.97959-2.28922-6.74085-6.89151-4.4-11.34188a6.04278,6.04278,0,0,1,4.60081-3.47192c2.46986-.21677,4.8574,1.0709,7.33225,1.06273a5.18052,5.18052,0,0,0,5.12712-3.6288c.6446-2.1212-.0029-4.28908.148-6.44171.33746-4.81291,6.18031-.34936,8.26286.54062a7.50337,7.50337,0,0,0,6.04364.35851c2.25048-.973,3.60618-3.12761,4.95253-5.06492a12.42993,12.42993,0,0,1,13.91278-5.13543c5.33583,1.681,8.59782,7.361,8.04686,12.7943-.1946,1.91906,2.80677,1.9055,3,0a15.35206,15.35206,0,0,0-6.40031-13.78022,15.05122,15.05122,0,0,0-20.54346,3.75122c-1.25884,1.70968-2.58495,4.596-4.94952,4.92951-1.97682.27878-4.01627-1.13907-5.66718-2.01768-1.73805-.925-3.70369-1.9555-5.738-1.579a5.01977,5.01977,0,0,0-3.86667,4.60734,17.27188,17.27188,0,0,0,.03469,3.12345,6.77951,6.77951,0,0,1-.24752,3.42156c-1.14245,2.2157-4.80159.66717-6.5837.33181a8.77752,8.77752,0,0,0-6.88671,1.09848,10.57457,10.57457,0,0,0-3.48261,12.94147c2.204,4.834,7.43075,7.64117,12.50738,8.33984,7.96459,1.09612,15.54047-2.15111,23.40115-2.64641,5.966-.37593,13.4909,1.418,15.55431,7.8564.58692,1.83137,3.48443,1.04843,2.89283-.79752Z"
			transform="translate(-347.56351 -223.94742)"
			fill="#2f2e41"
		/>
		<path
			d="M851.35188,676.05258h-426.298a1.10905,1.10905,0,0,1,0-2.21757h426.298a1.10905,1.10905,0,0,1,0,2.21757Z"
			transform="translate(-347.56351 -223.94742)"
			fill="#3f3d56"
		/>
		<path
			d="M499.395,435.89612c-12.30856-4.53042-26.55932-.094-34.79762,9.92116a33.03947,33.03947,0,0,0-3.3811,36.56632,30.41546,30.41546,0,0,0,15.3813,13.62976,27.80391,27.80391,0,0,0,18.87426.39981,4.59988,4.59988,0,0,0,4.03911.88158,4.66445,4.66445,0,1,0-5.818-4.52008,4.59,4.59,0,0,0,.05515.547c-9.62177,3.00939-20.25132-.6871-26.68372-8.50062a29.37294,29.37294,0,0,1-3.41084-31.187A28.913,28.913,0,0,1,489.22164,437.607c11.16358.03658,21.72694,7.40324,23.95721,18.65215a20.40811,20.40811,0,0,1-4.06322,16.57937c-1.86234,2.25952-4.52529,4.48851-7.60756,4.51562a2.49865,2.49865,0,0,1-1.43622-.587,6.45425,6.45425,0,0,1-2.43665-5.92342c.35045-3.1344.9234-8.25881,1.2687-11.34735a24.12216,24.12216,0,0,1,.95462-5.419,1.94022,1.94022,0,0,0,.38259-.94416c.69778-1.326,2.04516-2.72622,3.627-2.51266a1.813,1.813,0,0,0,2.15148-1.22156,1.7595,1.7595,0,0,0-1.22156-2.15141,7.00562,7.00562,0,0,0-6.25648,2.25517,8.5636,8.5636,0,0,0-.935,1.24242c-9.21484-3.75628-20.83886-.21927-25.56876,8.84449-2.61371,5.00873-2.97872,11.1638-.09835,16.1442,2.56624,4.43712,7.51875,7.671,12.7609,7.24177a14.2086,14.2086,0,0,0,8.48619-3.80415,2.45536,2.45536,0,0,1,3.19353-.1025l.01867.01473a8.781,8.781,0,0,0,8.64146,1.09218c5.88769-2.21482,9.837-8.35943,11.27766-14.258C519.44629,453.1093,511.47236,440.34149,499.395,435.89612Zm-13.2809,41.1378a8.17053,8.17053,0,0,1-9.32786-4.66393c-2.332-4.66393-.807-15.9929,7.43911-18.72211a17.31,17.31,0,0,1,12.02761.3164,20.82077,20.82077,0,0,0-.48194,2.45715c-.65878,4.77751-1.97279,14.81944-2.06551,15.132C492.81274,475.51645,490.0651,476.206,486.11411,477.03392Z"
			transform="translate(-347.56351 -223.94742)"
			fill="#3f3d56"
		/>
		<path
			d="M412.45626,352.15222c-10.86743-4-23.44966-.083-30.7234,8.75956a29.17108,29.17108,0,0,0-2.98522,32.285,26.85421,26.85421,0,0,0,13.5804,12.03394,24.54856,24.54856,0,0,0,16.6644.353,4.0613,4.0613,0,0,0,3.56619.77836,4.11832,4.11832,0,1,0-5.13678-3.99085,4.0515,4.0515,0,0,0,.0487.48294c-8.49522,2.657-17.88023-.60666-23.5595-7.50534a25.93385,25.93385,0,0,1-3.01149-27.53549A25.52779,25.52779,0,0,1,403.474,353.6628c9.85651.0323,19.18308,6.53645,21.15222,16.4683a18.01865,18.01865,0,0,1-3.58748,14.6382c-1.64429,1.995-3.99546,3.963-6.71684,3.98692a2.2062,2.2062,0,0,1-1.26807-.51825,5.69857,5.69857,0,0,1-2.15135-5.22988c.30942-2.76741.81528-7.29184,1.12015-10.01876a21.2982,21.2982,0,0,1,.84285-4.78452,1.713,1.713,0,0,0,.33779-.83362c.61609-1.17077,1.80571-2.407,3.20238-2.21846a1.60069,1.60069,0,0,0,1.89958-1.07854,1.5535,1.5535,0,0,0-1.07854-1.89952,6.18541,6.18541,0,0,0-5.524,1.99113,7.56159,7.56159,0,0,0-.82557,1.09695c-8.13593-3.31648-18.399-.19359-22.57508,7.809a14.8722,14.8722,0,0,0-.08684,14.254c2.26578,3.91761,6.63843,6.7729,11.26681,6.39388a12.545,12.545,0,0,0,7.4926-3.35874,2.16787,2.16787,0,0,1,2.81962-.0905l.01648.013a7.75288,7.75288,0,0,0,7.62969.96431c5.19835-1.95551,8.68523-7.38068,9.95724-12.58864C430.15987,367.35,423.11956,356.07711,412.45626,352.15222Zm-11.72593,36.32124a7.21388,7.21388,0,0,1-8.23572-4.11785c-2.05893-4.11786-.71253-14.1204,6.56812-16.53007a15.28332,15.28332,0,0,1,10.61937.27936,18.38458,18.38458,0,0,0-.42551,2.16946c-.58165,4.21814-1.74181,13.08433-1.82368,13.36029C406.64466,387.13367,404.21872,387.74252,400.73033,388.47346Z"
			transform="translate(-347.56351 -223.94742)"
			fill="#3f3d56"
		/>
		<path
			d="M652.45626,464.15222c-10.86743-4-23.44966-.083-30.7234,8.75956a29.17108,29.17108,0,0,0-2.98522,32.285,26.85421,26.85421,0,0,0,13.5804,12.03394,24.54856,24.54856,0,0,0,16.6644.353,4.0613,4.0613,0,0,0,3.56619.77836,4.11832,4.11832,0,1,0-5.13678-3.99085,4.0515,4.0515,0,0,0,.0487.48294c-8.49522,2.657-17.88023-.60666-23.5595-7.50534a25.93385,25.93385,0,0,1-3.01149-27.53549A25.52779,25.52779,0,0,1,643.474,465.6628c9.85651.0323,19.18308,6.53645,21.15222,16.4683a18.01865,18.01865,0,0,1-3.58748,14.6382c-1.64429,1.995-3.99546,3.963-6.71684,3.98692a2.2062,2.2062,0,0,1-1.26807-.51825,5.69857,5.69857,0,0,1-2.15135-5.22988c.30942-2.76741.81528-7.29184,1.12015-10.01876a21.2982,21.2982,0,0,1,.84285-4.78452,1.713,1.713,0,0,0,.33779-.83362c.61609-1.17077,1.80571-2.407,3.20238-2.21846a1.60069,1.60069,0,0,0,1.89958-1.07854,1.5535,1.5535,0,0,0-1.07854-1.89952,6.18541,6.18541,0,0,0-5.524,1.99113,7.56159,7.56159,0,0,0-.82557,1.09695c-8.13593-3.31648-18.399-.19359-22.57508,7.809a14.8722,14.8722,0,0,0-.08684,14.254c2.26578,3.91761,6.63843,6.7729,11.26681,6.39388a12.545,12.545,0,0,0,7.4926-3.35874,2.16787,2.16787,0,0,1,2.81962-.0905l.01648.013a7.75288,7.75288,0,0,0,7.62969.96431c5.19835-1.95551,8.68523-7.38068,9.95724-12.58864C670.15987,479.35,663.11956,468.07711,652.45626,464.15222Zm-11.72593,36.32124a7.21388,7.21388,0,0,1-8.23572-4.11785c-2.05893-4.11786-.71253-14.1204,6.56812-16.53007a15.28332,15.28332,0,0,1,10.61937.27936,18.38458,18.38458,0,0,0-.42551,2.16946c-.58165,4.21814-1.74181,13.08433-1.82368,13.36029C646.64466,499.13367,644.21872,499.74252,640.73033,500.47346Z"
			transform="translate(-347.56351 -223.94742)"
			:fill="secondColor"
		/>
		<path
			d="M621.11234,447.24173a14.21482,14.21482,0,0,0-15.59058,4.445,14.80285,14.80285,0,0,0-1.51485,16.383,13.62718,13.62718,0,0,0,6.89137,6.10662,12.45716,12.45716,0,0,0,8.45634.17913,2.06093,2.06093,0,0,0,1.80966.395,2.08984,2.08984,0,1,0-2.60665-2.02516,2.05612,2.05612,0,0,0,.02471.24507,11.33067,11.33067,0,0,1-11.95526-3.80858A13.16012,13.16012,0,0,1,605.0989,455.189a12.95407,12.95407,0,0,1,11.4554-7.1807c5.00168.01639,9.73445,3.31692,10.73369,8.35683a9.1436,9.1436,0,0,1-1.82047,7.42816,4.81081,4.81081,0,0,1-3.40846,2.02315,1.1195,1.1195,0,0,1-.64348-.263,2.89171,2.89171,0,0,1-1.0917-2.6539c.157-1.40432.41371-3.70024.56842-5.084a10.80786,10.80786,0,0,1,.4277-2.4279.8692.8692,0,0,0,.17142-.423c.31263-.59411.9163-1.22144,1.625-1.12576a.81226.81226,0,0,0,.96394-.5473.78832.78832,0,0,0-.5473-.96391A3.13878,3.13878,0,0,0,620.73,453.338a3.83607,3.83607,0,0,0-.41893.55664,9.18987,9.18987,0,0,0-11.45572,3.96265,7.54693,7.54693,0,0,0-.04407,7.23318,6.25629,6.25629,0,0,0,5.71734,3.24457,6.36594,6.36594,0,0,0,3.80212-1.70439,1.10009,1.10009,0,0,1,1.43082-.04593l.00836.0066a3.93418,3.93418,0,0,0,3.87168.48934c2.6379-.99232,4.40732-3.74532,5.0528-6.3881C630.096,454.95385,626.52342,449.23342,621.11234,447.24173Zm-5.95032,18.4312a3.66069,3.66069,0,0,1-4.17922-2.0896c-1.0448-2.08961-.36157-7.16539,3.333-8.38818a7.75551,7.75551,0,0,1,5.3888.14176,9.32759,9.32759,0,0,0-.21593,1.10089c-.29515,2.1405-.88388,6.63964-.92542,6.77968C618.16324,464.99305,616.9322,465.302,615.162,465.67293Z"
			transform="translate(-347.56351 -223.94742)"
			:fill="secondColor"
		/>
		<path
			d="M677.13869,454.73126A14.21481,14.21481,0,0,0,666.282,442.69149a14.80287,14.80287,0,0,0-15.3759,5.85488,13.62722,13.62722,0,0,0-2.44767,8.87641,12.45719,12.45719,0,0,0,3.56327,7.67105,2.06088,2.06088,0,0,0,.44234,1.79867,2.08984,2.08984,0,1,0,.67025-3.23213,2.05611,2.05611,0,0,0-.20914.13011,11.33067,11.33067,0,0,1-1.84561-12.41077,13.16013,13.16013,0,0,1,11.87194-7.52553,12.95408,12.95408,0,0,1,11.49172,7.12243c2.188,4.49776,1.309,10.20039-2.7758,13.317a9.14357,9.14357,0,0,1-7.47076,1.63686,4.81086,4.81086,0,0,1-3.31746-2.16916,1.11945,1.11945,0,0,1-.04727-.69353,2.8917,2.8917,0,0,1,1.90191-2.14889l4.81479-1.72862a10.808,10.808,0,0,1,2.36814-.68524.86926.86926,0,0,0,.45528-.03239,1.79131,1.79131,0,0,1,1.72637.9632.81227.81227,0,0,0,.91588.62441.78832.78832,0,0,0,.62438-.91587,3.13877,3.13877,0,0,0-2.14161-2.0717,3.83641,3.83641,0,0,0-.68426-.131,9.18986,9.18986,0,0,0-8.60268-8.53991,7.547,7.547,0,0,0-6.5134,3.14586,6.25629,6.25629,0,0,0-.39513,6.56194,6.3659,6.3659,0,0,0,3.20463,2.663,1.10009,1.10009,0,0,1,.67135,1.26437l-.00225.01042a3.93421,3.93421,0,0,0,1.26573,3.69152c2.05261,1.93131,5.30351,2.30751,7.96047,1.72317C674.171,466.19322,677.73353,460.46649,677.13869,454.73126ZM657.97058,457.506a3.66068,3.66068,0,0,1,.03558-4.67237c1.41594-1.85827,6.27389-3.48019,8.99877-.70169a7.75553,7.75553,0,0,1,2.2459,4.90053,9.32775,9.32775,0,0,0-1.08348.291c-2.05173.67766-6.35036,2.13048-6.49439,2.15485C659.90269,459.90106,659.08316,458.93188,657.97058,457.506Z"
			transform="translate(-347.56351 -223.94742)"
			:fill="secondColor"
		/>
		<path
			d="M493.69537,322.55466c-8.60281-3.16644-18.56307-.06571-24.32105,6.93419a23.09222,23.09222,0,0,0-2.36315,25.55724,21.25823,21.25823,0,0,0,10.75043,9.52623,19.433,19.433,0,0,0,13.19176.27944,3.215,3.215,0,0,0,2.82305.61616,3.26012,3.26012,0,1,0-4.06635-3.15921,3.20833,3.20833,0,0,0,.03855.3823c-6.72493,2.10335-14.15422-.48024-18.65-5.94133a20.52959,20.52959,0,0,1-2.38394-21.79746,20.20813,20.20813,0,0,1,17.87025-11.20176c7.80254.02556,15.18558,5.17434,16.74438,13.03652a14.26381,14.26381,0,0,1-2.8399,11.5878c-1.30164,1.57924-3.16286,3.13714-5.31714,3.15609a1.74639,1.74639,0,0,1-1.00382-.41025,4.511,4.511,0,0,1-1.703-4.14c.24494-2.19072.64539-5.77232.88673-7.931a16.8595,16.8595,0,0,1,.66721-3.7875,1.356,1.356,0,0,0,.2674-.6599c.4877-.9268,1.42942-1.90543,2.535-1.75616a1.26715,1.26715,0,0,0,1.50374-.85379,1.22978,1.22978,0,0,0-.85379-1.50368,4.89644,4.89644,0,0,0-4.37283,1.5762,5.98529,5.98529,0,0,0-.65353.86836c-6.44052-2.62537-14.56488-.15325-17.87074,6.18167a11.77306,11.77306,0,0,0-.06874,11.28364,9.7597,9.7597,0,0,0,8.91895,5.06148,9.93076,9.93076,0,0,0,5.93125-2.65882,1.71613,1.71613,0,0,1,2.232-.07165l.013.0103a6.1373,6.1373,0,0,0,6.03977.76336c4.11508-1.548,6.87534-5.84265,7.88228-9.96534C507.70978,334.58544,502.13658,325.66166,493.69537,322.55466ZM484.413,351.307a5.7106,5.7106,0,0,1-6.51951-3.25975c-1.62987-3.25975-.56405-11.17789,5.19941-13.08542a12.09845,12.09845,0,0,1,8.40644.22115,14.549,14.549,0,0,0-.33684,1.71737c-.46044,3.33913-1.37884,10.35773-1.44365,10.57618C489.09483,350.24644,487.17443,350.72842,484.413,351.307Z"
			transform="translate(-347.56351 -223.94742)"
			fill="#3f3d56"
		/>
		<path
			d="M650.97017,509.31069a6.87631,6.87631,0,0,1,6.34437-4.37386l45.41616-1.87068a14.07286,14.07286,0,1,1,.18865,28.14508l-45.41644,1.87007a6.7915,6.7915,0,0,1-2.80664-.57676h0a6.86543,6.86543,0,0,1-4.08989-6.22747l-.09656-14.44441A6.82723,6.82723,0,0,1,650.97017,509.31069Z"
			transform="translate(-347.56351 -223.94742)"
			:fill="color"
		/>
	</svg>
</template>

<script>
import undraw from "@/components/icons/undraw/undraw-mixin.js";

export default {
	mixins: [undraw],
};
</script>