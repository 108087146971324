var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseModal", {
        ref: "modal",
        attrs: {
          size: "sm",
          esc: false,
          showCloseButton: false,
          showDefaultButtons: false
        },
        on: {
          closed: function($event) {
            return _vm.handleClosedModal()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Neue Pin anlegen")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("InputField", {
                  attrs: { label: "Internet PIN", disabled: true },
                  model: {
                    value: _vm.pin.kundenzugangPin,
                    callback: function($$v) {
                      _vm.$set(_vm.pin, "kundenzugangPin", $$v)
                    },
                    expression: "pin.kundenzugangPin"
                  }
                }),
                _c("div", [_vm._v("Neue Pin dem Kunden jetzt zuschicken?")])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.resetPinEmail(false)
                      }
                    }
                  },
                  [_vm._v("Nein")]
                ),
                _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        return _vm.resetPinEmail(true)
                      }
                    }
                  },
                  [_vm._v("Ja")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "resultMessageModal",
        attrs: {
          size: "sm",
          showCancelButton: false,
          labelButtonConfirm: "Ok"
        },
        on: {
          close: function($event) {
            _vm.resultMessage = null
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Information")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [_vm._v(_vm._s(_vm.resultMessage))]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }