<template>
  <div>
    <BaseExplorer
      :additionalActions="additionalActions"
      @headerAction-CONFIRM_SELECTION="handleAuswahlUebernehmen"
    />
  </div>
</template>

<script>
import BaseExplorer from "@/components/core/BaseExplorer.vue";
import ANTRAG_TYPES from "@/store/antrag/types";
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import { mapGetters } from 'vuex';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types'; 
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default {
  mixins: [antragNavigationMixin],
  computed:{
     ...mapGetters({
             selectedRows: DOKUMENTENARCHIV_TYPES.GETTERS.SELECTED_ROWS,
             dokTreeLoading: DOKUMENTENARCHIV_TYPES.GETTERS.TREE_LOADING,
             isLoadingNode: DOKUMENTENARCHIV_TYPES.GETTERS.IS_LOADING_NODE,
             isSelectedAllInFolder: DOKUMENTENARCHIV_TYPES.GETTERS.IS_SELECTED_ALL_IN_FOLDER,
        }),
    additionalActions() {
      return  [
        BaseModalSimpleAction('CONFIRM_SELECTION', 'Auswahl übernehmen')
          .withDisabled(() => !this.isSelectedAllInFolder && !this.selectedRows.some(row => row.exists) || this.dokTreeLoading || this.isLoadingNode),
      ];
    },
  },
  components: {
    BaseExplorer,
  },
  methods: {
    async handleAuswahlUebernehmen(attachments) {  
      if (attachments.length) {
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.SET_ATTACHMENTS, attachments.map(v => ({...v, preloaded: true})));
      }
      
       this.navigateBackToCurrentStepFromExternal();
    },
    
   
  }
}
</script>

<style scoped>

</style>