import MARKTBERICHTUPLOADEN_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';

import { makeQueryParam } from '@/helpers/utils-helper';

const config = {
  defaultSpinner: true
};

export default {
  [MARKTBERICHTUPLOADEN_TYPES.ACTIONS.GET_FIELDS_DEFINITION]({ commit, rootState }) {
    let url = rootState.core.apiAddress + '/marktberichtUpload/fieldsDefinition';

    axios.get(url).then(response => {
      if (response && response.data) {
        commit(MARKTBERICHTUPLOADEN_TYPES.MUTATIONS.FIELDS_DEFINITION_SUCCESS, response.data);
      }
    })
  },

  async [MARKTBERICHTUPLOADEN_TYPES.ACTIONS.FIND_DOCUMENTS]({ getters, commit }, payload) {

    if(!payload) return

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marktberichtUpload/listDokumente`, {params: {isVVUploadModus: payload.modusVVUpload}, config})
      .then(response => {
        const documents = response.data || [];
        commit(MARKTBERICHTUPLOADEN_TYPES.MUTATIONS.FIND_DOCUMENTS_SUCCESS, [...documents]);
      })
      .catch(error => console.log(error));
  },

  async [MARKTBERICHTUPLOADEN_TYPES.ACTIONS.UPLOAD_DOCUMENT]({ getters, commit }, payload) {
    
    if(!payload?.tempFileId) return;

    const params = {
      tempFileId: payload.tempFileId,
      quartal: payload.quartal,
      year: payload.year,
      isVVUploadModus: payload.isVVUploadModus,
    }
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marktberichtUpload/uploadDokument`, {}, {params, config})
      .catch(error => console.log(error));
  },

  async [MARKTBERICHTUPLOADEN_TYPES.ACTIONS.DOWNLOAD_DOCUMENT]({ getters, }, payload) {

    let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/download_service/marktberichtDownloadFile/${payload.datei}`;

    const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]
    window.open(url + '?' + makeQueryParam({isVVUploadModus: payload.modusVVUpload, token}))

  },

  async [MARKTBERICHTUPLOADEN_TYPES.ACTIONS.DELETE_DOCUMENT]({ getters }, payload) {
    if(!payload.fileName) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marktberichtUpload/deleteDokumente`, {params: {...payload}, config})
      .catch(error => console.log(error));
  },
}
