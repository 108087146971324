var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DepotpositionsNavigation", {
        attrs: {
          title: _vm.depotTitle,
          subtitle: "Beteiligungen",
          headerActions: _vm.headerActions
        }
      }),
      !_vm.$isSmallScreen
        ? _c(
            "div",
            { staticClass: "row space-between" },
            _vm._l(_vm.resultSums, function(sumItem, index) {
              return _c(
                "div",
                { key: index, staticClass: "flex-item" },
                [
                  _c(
                    "BoxContainer",
                    {
                      staticClass: "text-center",
                      attrs: { tid: _vm._generateTidFromString("sum" + index) }
                    },
                    [
                      _c("span", { staticClass: "text-small mp-0" }, [
                        _vm._v(_vm._s(sumItem.label))
                      ]),
                      _c("br"),
                      sumItem.type.includes("currency")
                        ? _c(
                            "span",
                            [
                              _c("CurrencyLabel", {
                                attrs: { value: sumItem.value }
                              })
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(sumItem.value))])
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        : _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm._l(_vm.resultSums, function(sumItem, index) {
                return [
                  _c("MobileInfoRow", {
                    key: index,
                    attrs: { label: sumItem.label },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "value",
                          fn: function() {
                            return [
                              sumItem.type.includes("currency")
                                ? _c(
                                    "span",
                                    [
                                      _c("CurrencyLabel", {
                                        attrs: { value: sumItem.value }
                                      })
                                    ],
                                    1
                                  )
                                : _c("span", [_vm._v(_vm._s(sumItem.value))])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ]
              })
            ],
            2
          ),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "479e6147-3aa6-45b1-a2da-fae77eccae52" }
        },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("Table", {
                attrs: {
                  tableId: "6eef4684-474d-4080-af40-51309952a843",
                  rowId: "bgsNr",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  hidePagination: "",
                  mobileConfig: _vm.mobileConfig
                },
                on: {
                  "click-fondsname": _vm.navigateToFondsinfo,
                  "action-TRANSACTIONS": _vm.navigateToTransactions
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }