var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Vermögensverwaltungen",
          subtitle: "Strategien abonnieren",
          defaultMenu: _vm.$appNavigation.currentTabMenu
        }
      }),
      _c("HinweiseUndFehler", { attrs: { hints: _vm.hints } }),
      _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              type: "table",
              title: _vm.TABLE_TITLE,
              useBoxContainer: ""
            }
          })
        : _c(
            "div",
            { staticClass: "box__container" },
            [
              !_vm.rows || _vm.rows.length == 0
                ? _c("NoData", {
                    attrs: {
                      title: _vm.TABLE_TITLE,
                      content: "Es wurden keine Einträge gefunden"
                    }
                  })
                : [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label:
                          "nur ausgewählte Vermögensverwaltung in der Struktur anzeigen",
                        inLineLabel: ""
                      },
                      model: {
                        value: _vm.hasAboStruktur,
                        callback: function($$v) {
                          _vm.hasAboStruktur = $$v
                        },
                        expression: "hasAboStruktur"
                      }
                    }),
                    _vm.hasAboStruktur
                      ? _c("InputToggleSwitch", {
                          attrs: {
                            label:
                              "diese Einschränkung nur in der Struktur anwenden",
                            inLineLabel: ""
                          },
                          model: {
                            value: _vm.hasOnlyInStruktur,
                            callback: function($$v) {
                              _vm.hasOnlyInStruktur = $$v
                            },
                            expression: "hasOnlyInStruktur"
                          }
                        })
                      : _vm._e(),
                    _c("Table", {
                      attrs: {
                        title: _vm.TABLE_TITLE,
                        headers: _vm.headers,
                        rows: _vm.rows,
                        rowId: "id",
                        rowsPerPage: 25
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "hasSchema",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { staticClass: "check-box-center" },
                                [
                                  _c("InputCheckBoxItem", {
                                    attrs: { value: row.hasSchema },
                                    on: {
                                      input: function($event) {
                                        return _vm.onChangeSchema($event, row)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "hasRobo",
                          fn: function(row) {
                            return [
                              row.hasRobo != null
                                ? _c(
                                    "div",
                                    { staticClass: "check-box-center" },
                                    [
                                      _c("InputCheckBoxItem", {
                                        attrs: { value: row.hasRobo },
                                        on: {
                                          input: function($event) {
                                            return _vm.onChangeRobo($event, row)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ]
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }