import dayjs from "dayjs";

import {toDateString, toDateTimeString} from '@/filters.js';
import { parse } from '@/helpers/number-formatter';

import Action from './actionTypes/Action.vue';
import ConfirmedAct from './actionTypes/ConfirmedAction.vue';
import LinkAct from './actionTypes/LinkAction.vue';
import LoadingAct from './actionTypes/LoadingAction.vue';
import DownloadLinkAct from './actionTypes/DownloadLinkAction.vue'
import HtmlAct from './actionTypes/HtmlAction.vue';

import ActionCell from './cellTypes/ActionCell.vue';
import NumberCell from './cellTypes/NumberCell.vue';
import IconCell from './cellTypes/IconCell.vue';
import PillCell from './cellTypes/PillCell.vue';
import TextCell from './cellTypes/TextCell.vue';
import HtmlCell from './cellTypes/HtmlCell.vue';
import PhoneCell from './cellTypes/PhoneCell.vue';

export const MENU_ROWS_PER_PAGE = [
    {value: 10, label: '10 Zeilen pro Seite'}, 
    {value: 25, label: '25 Zeilen pro Seite'}, 
    {value: 50, label: '50 Zeilen pro Seite'}, 
    {value: 100, label: '100 Zeilen pro Seite'},
]

/**
 * The following Column functions each return an object that describes a header with
 * useful presets for Table-columns of its kind.
 * 
 * If a type of Table-column is used in multiple tables, it should be made into its
 * own ...Column function and added here (the functions are sorted alphabetically).
 * 
 * Extra attention has to be paid for SlotColumn:
 * Both minWidth and flexGrow need to be set manually!
 * 
 * @param {*} key the key under which data for this column is stored in row objects
 * @param {*} label shown in the header of the table as text
 * @param {*} minWidth in pixel. if less space than this is available but more is needed to render its content, the column gets hidden
 * @param {*} flexGrow if all columns fit into the table and there is space left over, how fast should this column grow relative to other columns
 * @returns an object that can be further configured (see definition in the 'column' function at the bottom)
 */

/**
 * Shows available actions for each row of the table if any are available.
 * This column is always visible by default and should be the last column in the 'lockedRight' headers.
 * Set row[key] to an array of actions
 * (See below for functions that create action objects like 'SimpleAction')
 * @param {*} key 
 * @param {*} label 
 * @returns 
 */
export function ActionColumn(key, label = "Aktionen", minWidth = 80, flexGrow = 0) {
    return column(ActionCell, key, label, minWidth, flexGrow, null)
        .alignRight()
        .makeAlwaysVisible()
        .setHideOnModalSheet(true)
        .onSmallScreen({
            hideHeaderLabel: true,
        });
}

/**
 * For each row in the table it renders row[key] as number of € with 'precision' digits after the comma.
 * @param {String} key 
 * @param {String} label 
 * @returns 
 */
export function CurrencyColumn(key, label, precision = 2, minWidth = 200, flexGrow = 0.5) {
    return column(NumberCell, key, label, minWidth, flexGrow, numberToSortable)
        .alignRight()
        .addCellProps({
            symbol: '€',
            precision,
        });
}

/**
 * For each row in the table it renders row[key] as date.
 * @param {String} key 
 * @param {String} label 
 * @returns 
 */
export function DateColumn(key, label, minWidth = 200, flexGrow = 0) {
    return column(TextCell, key, label, minWidth, flexGrow, dateToSortable)
        .alignRight()
        .addCellProps({
            filter: (value) => toDateString(value),
        });
}

/**
 * For each row in the table it renders row[key] as date and time.
 * @param {String} key 
 * @param {String} label 
 * @returns 
 */
export function DateTimeColumn(key, label, minWidth = 200, flexGrow = 0) {
    return column(TextCell, key, label, minWidth, flexGrow, dateToSortable)
        .addCellProps({
            filter: (value) => toDateTimeString(value),
        });
}

/**
 * A simple Html Column. For each row in the table it renders row[key] as html
 * @param {String} key 
 * @param {String} label 
 * @returns 
 */
export function HtmlColumn(key, label, minWidth = 200, flexGrow = 1) {
    return column(HtmlCell, key, label, minWidth, flexGrow, textToSortable);
}

/**
 * For each row in the table it renders row[key] as Phosphor icon if row[key] is set.
 * Set row[key] to {
 *     icon: <Phosphor icon> or "String name of Phospor icon",
 *     size: 16,
 *     ...         // you can set any props like class, size, ... here and they will be applied to the icon
 * }
 * @param {String} key 
 * @param {String} label 
 * @returns 
 */
export function IconColumn(key, label, minWidth = 300, flexGrow = 0) {
    return column(IconCell, key, label, minWidth, flexGrow, null)
        .alignCenter();
}

/**
 * For each row in the table it renders row[key] as number with 'precision' digits after the comma.
 * @param {String} key 
 * @param {String} label 
 * @returns 
 */
export function NumberColumn(key, label, precision = 0, minWidth = 100, flexGrow = 1) {
    return column(NumberCell, key, label, minWidth, flexGrow, numberToSortable)
        .alignRight()
        .addCellProps({
            precision,
        });
}

/**
 * For each row in the table it renders row[key] as phonenumber. If tapi call is allowed it allows phone calls.
 * @param {String} key 
 * @param {String} label 
 * @param {String} userType 
 * @param {String} userId 
 * @returns 
 */
export function PhoneColumn(key, label, userType, userIdColumn, minWidth = 200, flexGrow = 1) {
    return column(PhoneCell, key, label, minWidth, flexGrow, textToSortable)
        .addCellProps({
            userType,
            userIdColumn,
        });
}

/**
 * For each row in the table it renders row[key] as percentage number with 'precision' digits after the comma.
 * @param {String} key 
 * @param {String} label 
 * @returns 
 */
export function PercentageColumn(key, label, precision = 2, minWidth = 200, flexGrow = 1) {
    return column(NumberCell, key, label, minWidth, flexGrow, numberToSortable)
        .alignRight()
        .addCellProps({
            symbol: '%',
            precision,
        });
}

/**
 * For each row in the table it renders a Pill.vue component if row[key] is set
 * Set row[key] to {type: "...", label: "..."} to supply the Pill component's props
 * @param {String} key 
 * @param {String} label 
 * @returns 
 */
export function PillColumn(key, label, minWidth = 200, flexGrow = 0) {
    return column(PillCell, key, label, minWidth, flexGrow, textToSortable);
}

/**
 * In this kind of column, you get a Vue slot for each row in the table, usable as
 *      <template v-slot:key="row">
 *          ...
 *      </template>
 * where key is the same string as used in this function's parameter
 * 
 * WARNING: minWidth and flexGrow have to be set manually for SlotColumn !!!
 * @param {*} key 
 * @param {*} label 
 * @param {*} minWidth 
 * @param {*} flexGrow 
 * @returns 
 */
export function SlotColumn(key, label, minWidth = 999, flexGrow) {
    return column(null, key, label, minWidth, flexGrow, null);
}
/**
 * A simple Text Column. For each row in the table it renders row[key] as text
 * @param {String} key 
 * @param {String} label 
 * @returns 
 */
export function TextColumn(key, label, minWidth = 200, flexGrow = 1) {
    return column(TextCell, key, label, minWidth, flexGrow, textToSortable);
}


/**
 * The internal method for column creation
 * Don't use this method directly!
 * Instead use one of the ...Column functions above.
 * If no fitting Column type exists yet, you can create a new one!
 * @param {*} rowElement 
 * @param {*} key 
 * @param {*} label 
 * @param {*} minWidth 
 * @param {*} flexGrow 
 * @param {*} toSortable 
 * @param {*} classes 
 * @returns 
 */
function column(rowElement, key, label, minWidth, flexGrow, toSortable) {
    let exportFn = null;
    if (rowElement && rowElement != ActionCell) {
        exportFn = (_, row, column) => {
            const props = {
                ...(column.props || {}),
                row,
                column: key,
            }
            return rowElement.exportAsString(props);
        };
    }
    return {
        rowElement,
        key,
        label,
        minWidth,
        grow: flexGrow,
        classes: "",
        align: "left",
        hideOnModalSheet: false,
        setHideOnModalSheet(value = false) {
            this.hideOnModalSheet = value;
            return this;
        },
        alignLeft() {
            this.align = "left";
            return this;
        },
        alignCenter() {
            this.align = "centered";
            return this;
        },
        alignRight() {
            this.align = "right";
            return this;
        },
        toSortable,
        /**
         * Makes the Column sortable. Where it makes sense (like e.g. TextColum) the column is sortable by default.
         * If you want to make a column NOT sortable, call this method with parameter false: .makeSortable(false)
         * 
         * The parameter 'toSortable' should either be false or a method that converts some value into a sortable object.
         * Examples are given in table_util.js. See 'textToSortable' or 'dateToSortable'
         * 
         * @param {*} toSortable false or converter method
         * @returns self
         */
        makeSortable(toSortable) {
            if (toSortable === undefined || toSortable === true)
                toSortable = textToSortable;
            this.toSortable = toSortable;
            return this;
        },
        visible: true,
        /**
         * Makes this column invisible by default.
         * @returns self
         */
        makeHidden() {
            this.visible = false;
            return this;
        },
        neverHide: false,
        /**
         * If the table doesn't have enough space to render all LOCKED columns, some locked columns
         * for which this method wasn't called may be hidden as well
         * ActionColumns have this called by default.
         * @returns self
         */
        makeAlwaysVisible() {
            this.neverHide = true;
            return this;
        },
        isLink: false,
        isLinkIf: null,
        /**
         * Makes cells in this column clickable links.
         * If one of these links gets clicked, the table will emit a "click-[column key]" event containing the row element.
         * for example, if this column has the key "name", the event can be listened to with "@click-name"
         * If only some cells of this column should be links, you can instead use makeConditionalLink(...)
         * @param {boolean} value: if set to false, this column won't 
         */
        makeLink(isLink = null) {
            if (isLink == null)
                isLink = true;

            if (isLink instanceof Function) {
                this.isLink = false;
                this.isLinkIf = isLink;
            } else {
                this.isLink = !!isLink;
                this.isLinkIf = null;
            }

            return this;
        },
        /**
         * Like makeLink, but cells in this column will only be rendered as link if for the corresponding row
         * item row[necessaryRowProp] is true-y. Otherwise they will be rendered as plain-text.
         * @param {String} necessaryRowProp key to check for whether the row should render this cell as link
         * @returns self
         */
        makeConditionalLink(necessaryRowProp) {
            this.isLink = necessaryRowProp == null;
            this.isLinkIf = necessaryRowProp;
            return this;
        },

        footer: null,
        /**
         * If at least one "footer"-method is called, the table will show an additional row of values at the bottom.
         * This row contains only values provided by "footer"-methods. In this case this is a constant. This constant
         * could be a string, html or a function which will be evaluated in the rendering. In case of the column declaration
         * have a `withSumFooter(dataType)` this adicional row of value will be added just below the sumFooter.
         * @param {*} constant 
         * @returns self
         */
        withConstantFooter(constant) {
            if(!this.footer) {
                this.footer = {};
            }
            this.footer.constant = constant;
            return this;
        },
        /**
         * If at least one "footer"-method is called, the table will show an additional row of values at the bottom.
         * This row contains only values provided by "footer"-methods. In this case this is a constant. This constant
         * could be a string, html or a function which will be evaluated in the rendering. In case of the column declaration
         * have a `withSumFooter(dataType)` this adicional row of value will be added just below the sumFooter.
         * @param {*} constant 
         * @returns self
         */
        withFunctionFooter(fn) {
            if(!this.footer) {
                this.footer = {};
            }
            this.footer.fn = fn;
            return this;
        },
        /**
         * This method automatically sums the values of columns containing numbers and shows the sum in the footer.
         * Because it sums up numbers, it should only be used in columns that contain numbers.
         * Also, it will probably not work correctly in PaginatedTable, because not all the necessary values will be available.
         * You can add the dataType of that result in order to add some symbol for Slot columns, for example % or €. The dataType
         * is not necessary if the column uses the `PercentageColumn` or `CurrencyColumn` components.
         * The dataTypes accepted are: "percent" or "currency". The dataType is optional, if no dataType value were informed 
         * the value will be rendered as simple text.
         * @param {String} dataType
         * @returns self
         */
        withSumFooter(dataType) {
            if(!this.footer) {
                this.footer = {};
            }
            this.footer.fn = (cumulative, item) => (numberToSortable(cumulative) || 0) + (numberToSortable(item) || 0);
            this.footer.initialValue = 0;
            // in case the column be a Slot cell
            if(dataType === 'percent') {
                this.footer.symbol = '%';
                this.addCellProps({symbol: '%', precision: 2});
            }
            if(dataType === 'currency') {
                this.footer.symbol = '€';
                this.addCellProps({symbol: '€', precision: 2});
            }
            return this;
        },
        props: {},
        addCellProps(additionalProps) {
            Object.assign(this.props, additionalProps);
            return this;
        },
        exportFn,
        /**
         * export function for when the table is exported to PDF or XLS.
         * By default, cells like TextColumn or NumberColumn just use their rendered values, but
         * if you need some custom value, use this method to supply your own export function.
         * If you don't want this column to be exported, use withExporter(null) to remove the export function
         * See ExportButtons.vue for how the supplied function is used
         * @param {*} exportFn 
         * @returns 
         */
        withExporter(exportFn) {
            this.exportFn = exportFn;
            return this;
        },
        colorFn: (row) => { return ''},
        /**
         * Marks the content of the cell with the color.
         * return values of the function could be: 'color-danger', 'color-success', 'color-info' etc'
         * @param {Function} color (optional) function to define color
         * @returns self
         */
        setColorFn(color) {
            if (color) {
                this.colorFn = color;
            }
            return this;
        },
        /**
         * Small Screen default options
         */
        smallScreenOptions: {
            hideHeaderLabel: false,
        },
        /**
         * Set a config for a column on small screen
         */
        onSmallScreen(options) {
            if(options) {
                this.smallScreenOptions = {
                    ...this.smallScreenOptions,
                    ...options,
                };
            }
            return this;
        },
        /**
         * Set a title to display instead of label
         */
        setTitle(title) {
            this.title = title;
            return this;
        },
    }
}





/**
 * convenience method for cells in an IconColumn
 * @param {*} icon Phosphor icon or name of a Phosphor icon
 * @param {*} title This string will be shown in exported files or on hover
 * @param {*} size 
 * @param {*} weight "fill", "bold", ... or undefined usually
 */
export function Icon(icon, title, size = 20, weight, classString) {
    return {
        icon,
        title,
        size,
        weight,
        class: classString,
    }
}




/**
 * A simple Action for Cells in an ActionColumn.
 * When clicked, the table will emit an "action-[key]" event containing the row element.
 * for example, if the given key is "DELETE", the event can be listened to with "@action-DELETE"
 * @param {*} key 
 * @param {*} icon 
 * @param {*} label 
 * @returns 
 */
export function SimpleAction(key, icon, label) {
    label = label === 'KID' ? 'PRIIP-BIB' : label;
    return action(key, Action, icon, label);
}
/**
 * Same as SimpleAction, except before emitting the event, the user is asked for confirmation
 * @param {*} key 
 * @param {*} icon 
 * @param {*} label 
 * @returns 
 */
export function ConfirmedAction(key, icon, label, confirmationMessage, confirmationTitle="Bestätigung", labelButtonConfirm="Ok") {
    return {
        ...action(key, ConfirmedAct, icon, label),
        confirmationMessage,
        confirmationTitle,
        labelButtonConfirm,
    };
}
/**
 * This action won't emit an event.
 * If the user clicks it, the given href will instead be opened in a new tab.
 * @param {*} key 
 * @param {*} icon 
 * @param {*} label 
 * @param {*} href 
 */
export function LinkAction(key, icon, label, href) {
    label = label === 'KID' ? 'PRIIP-BIB' : label;
    return {
        ...action(key, LinkAct, icon, label),
        href,
    };
}
/**
 * This action won't emit an event.
 * It handle the download link according to the platform
 * @param {*} key 
 * @param {*} icon 
 * @param {*} label 
 * @param {*} filename
 * @param {*} downloadServicePath - the path into /download_service. e.g.: /get_simple_file
 * @param {*} queryParams - an Object with all parameters of the link. e.g.: { fileId: 0123, dokId: 0123 }.
 */
export function DownloadLinkAction(key, icon, label, filename, downloadServicePath, queryParams, href) {
    label = label === 'KID' ? 'PRIIP-BIB' : label;
    return {
        ...action(key, DownloadLinkAct, icon, label),
        filename,
        downloadServicePath,
        queryParams,
        href,
    };
}

/**
 * This action won't emit an event.
 * DownloadLinkAction href wrapper
 * 
 * @param {*} key 
 * @param {*} icon 
 * @param {*} label 
 * @param {*} filename
 * @param {*} href
 */
export function DownloadLinkHrefAction(key, icon, label, filename, href) {
    return DownloadLinkAction(key, icon, label, filename, null, null, href);
}

/**
 * An action that may be displayed as disabled or loading if the callbacks
 * given to the respective parameters return true.
 * Otherwise it behaves the same as SimpleAction
 * 
 * @param {*} disabled (optional) a function that receives a row-object and returns true if the action should be shown as disabled
 * @param {*} loading (optional) a function that receives a row-object and returns true if the action should be shown as loading
 * @returns 
 */
export function LoadingAction(key, icon, label, disabled, loading) {
    return {
        ...action(key, LoadingAct, icon, label),
        disabled,
        loading,
    };
}
/**
 * Works like SimpleAction but the label can be html text
 * @param {*} key 
 * @param {*} icon 
 * @param {*} label 
 * @returns 
 */
 export function HtmlAction(key, icon, label) {
    return action(key, HtmlAct, icon, label);
}


/**
 * The internal method for action creation
 * Don't use this method directly!
 * Instead use one of the ...Action functions above.
 * If no fitting Action type exists yet, you can create a new one!
 * @param {*} actionKey 
 * @param {*} component 
 * @param {*} icon 
 * @param {*} label 
 * @returns 
 */
function action(actionKey, component, icon, label) {
    return {
        actionKey,
        component,
        icon,
        label,
    };
}


/**
 * The following functions force values into a format that's sortable.
 * 
 */
export function numberToSortable(value) {
    if (typeof(value) == "string")
        value = parse(value);
    if ((!value && value !== 0) || isNaN(value))
        value = undefined;
    return value;
}
// turns text upper case for sorting since we don't want 'a' to end up after 'Z'
export function textToSortable(value) {
    if (!value)
        return "";
    if (value.label != null)
        value = value.label;
    value = "" + value; // ensure we're dealing with text
    return value.toUpperCase();
}
// converts date to milliseconds since epoch for sorting
export function dateToSortable(value) {
    if (!value)
        return 0;
    if (typeof(value) == "string")                      // handle String
        value = dayjs(value, ["DD.MM.YYYY hh:mm:ss", "DD.MM.YYYY hh:mm", "DD.MM.YYYY"]);
    if (dayjs.isDayjs(value))                           // handle dayjs
        value = value.valueOf();                        // this can return NaN if the dayjs object was invalid
    if (typeof(value) == "object" && value.getTime)     // handle Date object
        return value.getTime();
    if (isNaN(value))
        return 0;
    else
        return value;                                   // assume numbers are in milliseconds
}


/**
 * converts headers received from the server into the current format
 * please verify that the resulting columns behave the same as the old table component
 * 
 * @param {*} headers 
 * @param {*} sumFooters (optional) header keys for which footer with automatically generated sum should be added
 * @param {*} isActionAvailable (optional) whether columns of type "Action" should be kept
 * @param {*} constantFooters (optional) a map from header key to the constant footer value that should be added
 * @returns 
 */
export function oldToNewColumns(headers, sumFooters = {}, isActionAvailable = false, constantFooters = {}) {
    const result = headers
    .filter(v => isActionAvailable || v.dataType !== 'Action')
    .map(({key, label, dataType, minWidth}) => {
        let header = oldToNewColumn(key, label, dataType, minWidth);
        if (constantFooters[key])
            header.withConstantFooter(constantFooters[key]);
        else if (sumFooters[key])
            header.withSumFooter();
        return header;
    });
    return result;
}
/**
 * converts a single header received from the server into the current format
 * @param {*} key 
 * @param {*} label 
 * @param {*} dataType 
 * @returns 
 */
export function oldToNewColumn(key, label, dataType, cellProps, minWidth, clickable, sortable = true) {
    let result = null;
    switch(dataType.toLowerCase()) {
        case "string":
        case "text":
            result = TextColumn(key, label, minWidth || 200);
            break;
        case "column":
            result = TextColumn(key, label, minWidth || 200);
            result.makeSortable(false);
            break;
        case "currency":
            result = CurrencyColumn(key, label, cellProps?.precision || 2, minWidth || 200);
            break;
        case "procent":
        case "percentage":
            result = PercentageColumn(key, label, cellProps?.precision || 2, minWidth || 200);
            break;
        case "integer":
        case "double":
        case "number":
            result = NumberColumn(key, label, cellProps?.precision || 2, minWidth || 200);
            break;
        case "date":
            result = DateColumn(key, label, minWidth || 200);
            break;
        case "datetime":
            result = DateTimeColumn(key, label, minWidth || 200);
            break;
        case "slot":
            result = SlotColumn(key, label, minWidth || 200, 1);
            break;
        case "pill":
            result = PillColumn(key, label);
            break;
        case "actions":
            result = ActionColumn(key, label);
            break;
        default:
            throw "Unknown dataType in 'oldToNewColumn': " + dataType;
    }
    
    if (cellProps) {
        result.addCellProps(cellProps)
    }

    if (clickable) {
        result.makeLink();
    }

    if (!sortable) {
        result.makeSortable(false);
    }

    return result;
}

/**
 * converts a list of TableAction objects (usually received from the server) into a usable format for the table
 * @param {*} actions 
 * @returns 
 */
export function convertTableActions(actions) {
    return actions.map(action => convertTableAction(action))
}

/**
 * converts a single TableAction object (usually received from the server) into a usable format for the table
 * @param {*} action 
 * @returns 
 */
export function convertTableAction(action) {
    const {actionType, key, label, icon, href, } = action
    let result = null;

    switch(actionType.toLowerCase()) {
        case "simple":
            result = SimpleAction(key, icon, label);
            break;
        case "confirmed":
            result = ConfirmedAction(key, icon, label, action.confirmationMessage, action.confirmationTitle || "Bestätigung", action.labelButtonConfirm || "Ok");
            break;
        case "loading":
            result = LoadingAction(key, icon, label, action.disabled, action.loading);
            break;
        case "html":
            result = HtmlAction(key, icon, label);
            break;
        case "link":
            result = LinkAction(key, icon, label, href);
            break;
        case "downloadlink":
            result = DownloadLinkAction(key, icon, label, action.filename, action.downloadServicePath, action.queryParams, href);
            break;
        default:
            throw "Unknown actionType in 'convertTableAction': " + actionType;
    }

    return result;
}




