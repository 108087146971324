<template>
  <div>
        <PageHeaderTitleNavigation title="Verwaltung der Facsimiles" />

        <Facsimile suffix="STEMPEL" />
        <Facsimile />
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Facsimile from '@/components/brokerData/Facsimile.vue';
import CORE_TYPES from "../../store/core/types";
import BROKERDATA_TYPES from "../../store/brokerData/types";

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        Facsimile,
    },
    mounted() {
        // this.$store.commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { facsimile: false });
        // this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_FACSIMILE_MAKLER, this.personId || '' );
    },
    computed: {
        personId() {
            return this.$route.params.personId
        }
    }
}
</script>

<style>

</style>