<template>
  <div class="anlageassistent_container">
    <div class="banner">
      <editable-frame type="anlageassistent_title_page" />
    </div>
    <hr />
    <div class="anlageassistent_content">
      <div class="anlageassistent_image">
        <EditableImageOnly type="anlageassistent_image1" />
      </div>
      <div class="anlageassistent_text">
        <editable-frame type="anlageassistent_text1" />
        <base-button @click="$router.push('assistent')">JETZT ANLAGEASSISTENT ENTDECKEN!</base-button>
      </div>
    </div>
  </div>
</template>
<script>
import EditableFrame from "@/components/cms/EditableFrame.vue";
import EditableImageOnly from "@/components/cms/EditableImageOnly.vue";
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: {
    EditableFrame,
    BaseButton,
    EditableImageOnly
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style  scoped>
.anlageassistent_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}

.anlageassistent_content {
  margin: 0 auto;
  padding-top: 2em;
  padding-bottom: 2em;
  display: grid;
  gap: 4em;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "anlageassistent_image anlageassistent_text";
}
.anlageassistent_image {
  grid-area: anlageassistent_image;
}
.anlageassistent_text {
  grid-area: anlageassistent_text;
}
a {
  color: var(--color-link);
}

@media only screen and (max-width: 1024px) {
  .anlageassistent_content {
    display: block;
  }
}
</style>