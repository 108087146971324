<template>
  <Table
    tableId="e95d085e-eddb-4c2e-9095-f06e4ef0524a"
    cardView
    :headers="headers"
    :rows="rows"
    hidePagination
  />
</template>

<script>
import BERICHTE_TYPES from '@/store/berichte/types';
import { TextColumn, DateColumn, NumberColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Table,
  },
  computed: {
    ...mapGetters({
      altersvorsorge: BERICHTE_TYPES.GETTERS.ALTERSVORSORGE,
    }),
    headers() {
      return {
        center: [
          TextColumn("asBStatus", "Bearbeitungsstatus"),
          DateColumn("asBDatum", "Bearbeitungsdatum"),
          DateColumn("asLeistungsBeginn", "Leistungsbeginn"),
          DateColumn("asZahlungsbeginn", "Zahlungsbeginn"),
          DateColumn("asGarantiezeit", "Garantiezeit"),
          NumberColumn("asGarantiedauer", "Garantiedauer"),
          CurrencyColumn("asGarantierente", "Garantierente"),
          CurrencyColumn("asUeberschuss", "Überschuss"),
        ]
      };
    },
    rows() {
      return [
        {...(this.altersvorsorge?.zulagen || {})}
      ]
    },
  },
};
</script>