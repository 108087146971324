<template>
  <div class="agb_container">
    <div class="agb_wrapper">
      <div class="banner">AGB</div>
      <hr />
      <div class="agb_text" v-if="isAGBReady">
        <div v-html="getAGB"></div>
      </div>
       <div class="fc-alert fc-alert-warning" v-if="isIntern">Hier kommen die für den Makler bestimmten AGB.</div>
    </div>
  </div>
</template>
<script>
import CMS_TYPES from "@/store/cms/types";
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";

export default {
  computed: {
    ...mapGetters({
      getAGB: CMS_TYPES.GETTERS.GET_PRIVACY_LINK,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    isAGBReady() {
      return this.getAGB !== "";
    }
  },

  mounted() {
    window.scrollTo(0, 0);
    if (!this.isIntern){
      this.$store.dispatch(CMS_TYPES.ACTIONS.RETRIEVE_PRIVACY_LINK, "agb");
    }
  }
};
</script>
<style scoped>
.agb_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-primary);
}
.agb_wrapper {
  margin: 0 auto;
}
.agb_text {
  text-align: justify;
  padding: 2em;
}
@media only screen and (max-width: 1024px) {
  .datenschutz-container {
    margin-top: 17em;
  }
}
</style>