<template>
  <div>
    <GhostLoading v-if="loading" type="table"/>
    <template v-else>
      <div class="box__container">
        <div class="box__title">Zugangsdaten</div>
        <InputToggleSwitch
          label="Zugang gestattet" 
          :value="ansprechpartner.zugang"
          @input="zugangFreischalten"
        />
        <InputToggleSwitch
          label="Zugang wegen falscher Passworteingabe gesperrt" 
          disabled
        />
        <InputField 
          label="Persönliche Nutzerkennung"
          disabled
          :value="ansprechpartner.zugang_nutzerkennung"
        />
      </div>

      <div class="box__container">
        <div class="box__title">Sichtbarkeit</div>
        <InputField
          v-if="ansprechpartner.maklerBesitzer"
          label="Maklernummer Besitzer"
          disabled
          :value="ansprechpartner.maklerBesitzer"
        />
        <InputToggleSwitch
          label="Für Makler sichtbar (Ansprechpartner)" 
          :value="ansprechpartner.visibleToMakler"
          @input="updateAP('visibleToMakler', $event)"
        />
        <InputToggleSwitch
          v-if="ansprechpartner.maklerBesitzer"
          label="Freigabe für die Vermittler-Struktur"
          :disabled="!ansprechpartner.visibleToMakler"
          :value="ansprechpartner.struktur"
          @input="updateAP('struktur', $event)"
        />
        <InputToggleSwitch
          v-if="!ansprechpartner.zugang"
          label="Ansprechpartner ist inaktiv" 
          :value="ansprechpartner.ansprechpartnerInaktiv"
          @input="updateAP('ansprechpartnerInaktiv', $event)"
        />
        <Table
          title="Einschränkungen der Sichtbarkeit auf bestimmte Strukturen"
          rowId="maklernr"
          :rows="sichtbarkeitenRows"
          :headers="sichtbarkeitenHeaders"
          :headerActions="sichtbarkeitenTableHeaderActions"
          @action-DELETE="deleteSichtbarkeit"
          @headerAction-NEW_SICHTBARKEIT="openCreateSichtbarkeitModal"
          :rowsPerPage="20"
        />
      </div>

      <div class="box__container">
        <Table 
          title="Termine"
          rowId="commId"
          :rows="termineRows"
          :headers="termineHeaders"
          :headerActions="termineTableHeaderActions"
          @click-betreff="editOrCreateAppointment"
          @action-DELETE="deleteTermin"
          @headerAction-CREATE_TERMIN="editOrCreateAppointment"
          :rowsPerPage="20"
        />
      </div>
    </template>


    <BaseModal 
      ref="createSichtbarkeitModal"
      modalTitle="Neue Einschränkung"
      :showDefaultButtons="false"
    > 
      <InputField
        label="Maklernr Strukturleiter"
        isComponentHalfSize
        v-model="editSichtbarkeit.maklernr"
      />

      <template #footer>
        <BaseButton
          label="Abbrechen"
          isSecondary
          @click="closeCreateSichtbarkeitModal()"
        />
        <BaseButton
          label="Nicht sichtbar"
          @click="addSichtbarkeit(false)"
        />
        <BaseButton
          label="Nur sichtbar"
          @click="addSichtbarkeit()"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script>

import ANSPRECHPARTNER_TYPES from '@/store/ansprechpartner/types';
import CALENDAR_TYPES from "@/store/calendar/types";
import COMMUNICATION_TYPES from '@/store/communication/types';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";

import { mapGetters } from "vuex";
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import InputField from '@/components/core/forms/InputField.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import BaseModal from '@/components/core/BaseModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn, PillColumn, ActionColumn, ConfirmedAction, } from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

const config = {
  defaultSpinner: true
};

export default {
  components: {
    InputField,
    InputToggleSwitch,
    BaseButton,
    Table,
    BaseModal,
    GhostLoading,
  },
  props: {
    loading: {
      type : Boolean,
      default: false,
    },
  },
  watch: {
    ansprechpartner: {
      handler() {
        this.sichtbarkeiten = this.ansprechpartner.sichtbarkeiten || [];
      },
      immediate: true,
    }
  },
  data() {
    return {
      editSichtbarkeit: {},
      sichtbarkeiten: [],
    };
  },
  computed: {
    ...mapGetters({
      ansprechpartner: ANSPRECHPARTNER_TYPES.GETTERS.GET_ANSPRECHPARTNER,
    }),
    sichtbarkeitenHeaders() {
      return {
        lockedLeft: [
          TextColumn("maklernr", "Maklernr"),
          TextColumn("name", "Name"),
          PillColumn("sichtbar", "Sichtbarkeit"),
        ],
        center: [],
        lockedRight: [
          ActionColumn('actions')
        ],
      }
    },
    sichtbarkeitenRows() {
      return (this.ansprechpartner.sichtbarkeiten || []).map(sichtbarkeit => ({
        ...sichtbarkeit,
        sichtbar: {
          label: sichtbarkeit.sichtbarkeitLabel, 
          type: sichtbarkeit.sichtbarkeit === 'NEIN' ? 'danger' : 'success',
        },
        actions: [
          ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll der Eintrag für Vermittler "${sichtbarkeit.name}" wirklich gelöscht werden?`, `Eintrag löschen`, "Löschen")
        ]
      }))
    },
    sichtbarkeitenTableHeaderActions() {
      return [
        PageHeaderSimpleAction('NEW_SICHTBARKEIT', 'Neue Einschränkung')
      ];
    },
    termineHeaders() {
      return {
        lockedLeft: [
          TextColumn("betreff", "Betreff").makeLink(),
          DateColumn("beginn", "Beginn", undefined, 1),
          DateColumn("ende", "Ende", undefined, 1),
        ],
        center: [],
        lockedRight: [
          ActionColumn('actions')
        ],
      }
    },
    termineRows() {
      return (this.ansprechpartner.termine || []).map(termin => ({
        ...termin,
        betreff: termin.betreff || '[Kein Betreff]',
        actions: [
          ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll der Termin "${termin.betreff || '[Kein Betreff]'}" wirklich gelöscht werden?`, `Termin löschen`, "Löschen")
        ]
      }))
    },
    termineTableHeaderActions() {
      return [
        PageHeaderSimpleAction('CREATE_TERMIN', 'Neuer Termin')
      ];
    },
  },
  methods: {
    openCreateSichtbarkeitModal() {
      this.editSichtbarkeit = {}
      this.$refs.createSichtbarkeitModal.open()
    },
    closeCreateSichtbarkeitModal() {
      this.$refs.createSichtbarkeitModal.close()
    },
    addSichtbarkeit(nurSichtbar = true, maklernr = this.editSichtbarkeit.maklernr) {
      const params = makeQueryParam({ maklernr, sichtbarkeit: nurSichtbar ? 'JA' : 'NEIN' })

      axios.get(`${process.env.VUE_APP_API}/gesellschaftPersonenDatenService/initEinschraenkung?${params}`, config)
      .then(response => {
        const index = this.ansprechpartner.sichtbarkeiten.findIndex(record => record.maklernr === maklernr)

        if (index >= 0) {
          this.$set(this.sichtbarkeiten, index, response.data)
        } else {
          this.sichtbarkeiten.push(response.data)
        }

        this.$store.commit(ANSPRECHPARTNER_TYPES.MUTATIONS.UPDATE_ANSPRECHPARTNER_DATA, {fieldId: 'sichtbarkeiten', value: this.sichtbarkeiten});
      })
      this.$refs.createSichtbarkeitModal.close()
    },
    deleteSichtbarkeit(row) {
      const index = this.sichtbarkeiten.findIndex(record => record.maklernr === row.maklernr)

      if (index >= 0) {
        this.sichtbarkeiten.splice(index, 1);
        this.$store.commit(ANSPRECHPARTNER_TYPES.MUTATIONS.UPDATE_ANSPRECHPARTNER_DATA, {fieldId: 'sichtbarkeiten', value: this.sichtbarkeiten});
      }
    },
    doSave() {
      if (this.ansprechpartner.type && this.ansprechpartner.lastName) {
        return this.$store.dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.SAVE_ANSPRECHPARTNER);
      }
    },
    async editOrCreateAppointment(row) {
      if (!this.ansprechpartner.type || !this.ansprechpartner.lastName) {
        this.$confirmModal({
          title: 'Fehler', 
          message: 'Bitte alle Pflichtfelder ausfüllen.', 
          labelButtonConfirm: 'Ok',
          showCancelButton: false,
        })
      }

      if (!this.ansprechpartner.id) {
        await this.doSave()
      }

      if (this.ansprechpartner.id) {
        if (row?.commId) {
          this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, row.commId);
        } else {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {});
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_TERMIN);
        }

        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
          bereich: 'Termin',
          title: row?.commId ? 'Termin bearbeiten' : 'Termin erstellen',
          back: this.$route.fullPath,
          additionalBeteiligte: [
            {
              personId: this.ansprechpartner.id,
              name: this.ansprechpartner.firstName + ' ' + this.ansprechpartner.lastName,
              canChange: false,
              alarmMail: true,
              alarmMsc: true,
              alarmDailyMail: true,
              rolle: 'TEILNEHMER',
            }
          ]
        })

        this.$addBreadcrumb({
          fullPath: this.$route.fullPath,
          breadcrumb: 'Termine',
        });

        this.$router.push({ path: `/communication/appointment` });
      }
    },
    deleteTermin(row) {
      this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.DELETE_FROM_POSTFACH, {id: row.commId, url: 'termin'}).then(() => 
        this.ansprechpartner.termine = this.ansprechpartner.termine.filter(act => act.commId !== row.commId)
      )
    },
    updateAP(fieldId, value) {
      this.$emit('change', 
      {
        fieldId: fieldId,
        value: value,
      })
    },
    zugangFreischalten(value) {
      this.updateAP('zugang', value)

      if (value) {
        this.updateAP('ansprechpartnerInaktiv', false)
        this.$confirmModal({
            title: 'E-Mail versenden', 
            message: 'Möchten Sie an diesen Ansprechpartner eine E-Mail für den Login versenden?', 
            labelButtonConfirm: 'Versenden',
        }).then(() => {
            this.sendEmail();
        })
      }
    },
    async sendEmail() {
      if (!this.ansprechpartner.type || !this.ansprechpartner.lastName || !this.ansprechpartner.email) {
          this.$confirmModal({
            title: 'Fehler', 
            message: 'Für den E-Mail Versand sind folgende Angaben erforderlich: Typ, Name, E-Mail',
            labelButtonConfirm: 'Ok',
            showCancelButton: false,
        })
      } else {

        if (!this.ansprechpartner.id) {
          await this.doSave()
        }

        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
          type: 'mailcomposer-ansprechpartner',
          action: 'clear',
        });

        this.$addBreadcrumb({
          label: `Zurück`,
          fullPath: this.$route.fullPath,
          breadcrumb: 'AnsprechpartnerEdit',
        });

        this.$router.push(`/communication/mailcomposer-ansprechpartner/${this.ansprechpartner.id}`);
      }
    },
  },
}
</script>
