<template>
  <div class="box__container">
    <div class="box__title">
      Zusätzliche Einstellungen für Ordern bei der DAB
    </div>
    <ComboBox
      v-model="form.INPUT_DAB_ANSPRECHPARTNER_COMBO"
      label="Ansprechpartner"
       :disabled="readOnly"
      :values="
        comboboxValues ? comboboxValues.INPUT_DAB_ANSPRECHPARTNER_COMBO : []
      "
      @change="
        updateStoreData({
          componentId: 'INPUT_DAB_ANSPRECHPARTNER_COMBO',
          value: $event,
        })
      "
    />
    <InputField
      v-if="form.INPUT_DAB_ANSPRECHPARTNER_COMBO == 'freie Eingabe:'"
      v-model="form.INPUT_DAB_ANSPRECHPARTNER_TEXT"
       :disabled="readOnly"
      @change="
        updateStoreData({
          componentId: 'INPUT_DAB_ANSPRECHPARTNER_COMBO',
          value: $event,
        })
      "
    />
    <InputField
      label="Telefon aus Personenauswahl, sonst:"
      v-model="form.INPUT_DAB_ANSPRECHPARTNER_TELEFON"
       :disabled="readOnly"
      @change="updateStoreData({
          componentId: 'INPUT_DAB_ANSPRECHPARTNER_TELEFON',
          value: $event,
        })"
      inputmode="tel"
    />

    <div class="col-12 mt-3">
      <div class="box__title">Hinweis für Sparpläne</div>
      <p>
        Bei Ihrem Sparplan muss das DAB Konto 1 Tag vor Ausführungstermin
        Deckung aufweisen. Das erstmalige Ausführungsdatum bei Sparplänen mit
        Lastschrift muss mindestens 3 Arbeitstage in der Zukunft liegen. Der
        Einzug Ihrer Lastschrift kann nur von einem Konto im europäischen
        Zahlungsverkehrsraum (SEPA) in Euro ausgeführt werden. Mit der
        Bestätigung dieses Auftrages versichern Sie, dass Sie nur Ihr eigenes
        Konto belasten. Der Einzug Ihrer Lastschrift erfolgt taggleich bei
        Ausführung des Sparplanes von Ihrem angegebenen Konto. Für
        Lastschriftbeträge > EUR 1.000,00 setzen Sie sich bitte mit unserem
        Kundenservice unter 089/8895-6000 in Verbindung. Ihre Neuanlage,
        Änderung oder Löschung von Sparplänen ist bis 3 Arbeitstage vor der
        nächsten Ausführung möglich. Bitte beachten Sie, dass Sie den Sparplan
        nur zusammen mit der Lastschrift ändern oder streichen können. Aufträge
        für Ihren Auszahlplan müssen mind. 2 Tage vor Ausführungstermin bei uns
        vorliegen. Ich bin damit einverstanden, dass die DAB Spar- und
        Auszahlpläne gemäß den Ausführungsgrundsätzen auch außerbörslich
        ausführen kann.
      </p>
    </div>
  </div>
</template>
<script>
import ComponentList from "@/components/wertpapierorder/ComponentList.vue";
import WERTPAPIERORDER_TYPES from "@/store/wertpapierorder/types";
import mixin from "@/mixins/wertpapierorder/mixin.js";
import { mapGetters } from "vuex";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";

export default {
  mixins: [mixin],
  components: {
    ComponentList,
    FormLabel,
    ComboBox,
    InputField,
  },
  data() {
    return {
      disabled: false,
      form: {},
    };
  },
  computed: {
    ...mapGetters({
      comboboxValues: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
    }),
  },
  methods: {
    initForm() {
      this.form = {
        INPUT_DAB_ANSPRECHPARTNER_TEXT:
          this.data["INPUT_DAB_ANSPRECHPARTNER_TEXT"],
        INPUT_DAB_ANSPRECHPARTNER_COMBO:
          this.data["INPUT_DAB_ANSPRECHPARTNER_COMBO"],
        INPUT_DAB_ANSPRECHPARTNER_TELEFON:
          this.data["INPUT_DAB_ANSPRECHPARTNER_TELEFON"],
      };
    },
  },
  mounted() {
    this.initForm();
  },
};
</script>
