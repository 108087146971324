var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card box__shadow" }, [
    _c("div", { staticClass: "card-header" }, [
      _c(
        "div",
        { staticClass: "title" },
        [
          _vm._t("header"),
          _vm.isError
            ? _c("div", [_vm._v("Es gab ein Problem bei dem Abruf der Daten.")])
            : _vm._e()
        ],
        2
      ),
      _vm.allowConfig
        ? _c("div", { staticClass: "switcher" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedKey,
                    expression: "selectedKey"
                  }
                ],
                staticClass: "form-control",
                attrs: { name: "switchOptions" },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedKey = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      return _vm.onChange($event)
                    }
                  ]
                }
              },
              [
                _c("option", { attrs: { value: "" } }),
                _vm._l(_vm.switchOptions, function(item) {
                  return [
                    _c(
                      "option",
                      { key: item.name, domProps: { value: item.name } },
                      [_vm._v(_vm._s(item.title))]
                    )
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _vm.showToolbar
        ? _c(
            "div",
            { staticClass: "list-switcher" },
            [
              _vm.allowListView && _vm.rows && !_vm.isListView
                ? _c("ph-text-align-justify", {
                    attrs: { size: 16 },
                    on: {
                      click: function($event) {
                        _vm.isListView = !_vm.isListView
                      }
                    }
                  })
                : _vm._e(),
              _vm.allowListView && _vm.rows && _vm.isListView
                ? _c("ph-table", {
                    attrs: { size: 16 },
                    on: {
                      click: function($event) {
                        _vm.isListView = !_vm.isListView
                      }
                    }
                  })
                : _vm._e(),
              _c("ph-question", {
                attrs: { size: 16 },
                on: {
                  click: function($event) {
                    return _vm.showInfo()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        !_vm.isListView
          ? _c("div", { staticClass: "card-body-main" }, [_vm._t("default")], 2)
          : _vm._e(),
        _vm.isListView
          ? _c(
              "div",
              { staticClass: "card-body-alternative" },
              [_vm._t("alternative")],
              2
            )
          : _vm._e(),
        _vm.allowListView && _vm.isListView && _vm.rows
          ? [
              _c("Table", {
                attrs: {
                  tableId: "5542f528-f246-462a-8817-2fcbbef61eca",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  hidePagination: ""
                }
              }),
              _vm.rows.length > 5
                ? _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.showMore()
                        }
                      }
                    },
                    [_vm._v(" Details ")]
                  )
                : _vm._e()
            ]
          : _vm.allowListView && _vm.isListView
          ? _c("div", [_vm._v("Keine Daten")])
          : _vm._e(),
        _c(
          "BaseModal",
          {
            ref: "modalInfo",
            attrs: {
              showDefaultButtons: false,
              modalTitle: !_vm.isInfoModal ? "Details" : ""
            },
            on: {
              onCancelButton: function($event) {
                return _vm.hideModal()
              }
            }
          },
          [
            [
              _vm.isInfoModal
                ? [_c("div", { domProps: { innerHTML: _vm._s(_vm.info) } })]
                : [
                    _c("Table", {
                      attrs: {
                        tableId: "d8ef0adc-f212-4981-bf4b-e34a3e634962",
                        headers: _vm.headers,
                        rows: _vm.rows,
                        hidePagination: ""
                      }
                    })
                  ]
            ]
          ],
          2
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }