var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "text-centered" },
              [_c("AnimatedSpinner")],
              1
            )
          : [
              _vm.selectedNav === _vm.CHART_BENCHMARKING
                ? _c(
                    "div",
                    { ref: "advice" },
                    [
                      _c(
                        "div",
                        { staticClass: "tool-charts" },
                        [
                          _c(
                            "BaseButton",
                            {
                              staticClass: "btn btn-primary mr-3 my-1",
                              attrs: { disabled: _vm.loading },
                              on: { click: _vm.aktualisieren }
                            },
                            [_vm._v(" Aktualisieren ")]
                          ),
                          _c("div", { staticClass: "tool-gap" }),
                          _c("InputToggleSwitch", {
                            attrs: {
                              label: "Relativ zur Anlagesumme",
                              inLineLabel: ""
                            },
                            model: {
                              value: _vm.chartRelativ,
                              callback: function($$v) {
                                _vm.chartRelativ = $$v
                              },
                              expression: "chartRelativ"
                            }
                          })
                        ],
                        1
                      ),
                      _c("ChartFunds", {
                        attrs: {
                          type: "Anlageempf-advice",
                          chartData: _vm.chartAdvice,
                          relativ: _vm.chartRelativ
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedNav === _vm.CHART_DETAILS
                ? _c(
                    "div",
                    { ref: "details" },
                    [
                      _c(
                        "div",
                        { staticClass: "tool-charts" },
                        [
                          _c("div", { staticClass: "tool-gap" }),
                          _c("InputToggleSwitch", {
                            attrs: {
                              label: "Relativ zur Anlagesumme",
                              inLineLabel: ""
                            },
                            model: {
                              value: _vm.chartRelativ,
                              callback: function($$v) {
                                _vm.chartRelativ = $$v
                              },
                              expression: "chartRelativ"
                            }
                          })
                        ],
                        1
                      ),
                      _c("ChartFunds", {
                        attrs: {
                          type: "Anlageempf-details",
                          chartData: _vm.chartDetails,
                          relativ: _vm.chartRelativ
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedNav === _vm.CHART_BESTANDSDEPOT
                ? _c(
                    "div",
                    { ref: "actual" },
                    [
                      _c("ChartFunds", {
                        attrs: {
                          type: "Anlageempf-actual",
                          chartData: _vm.chartActual
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }