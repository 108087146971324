<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Kunden-Aktionen" 
      :subtitle="selectedSimpleAction && selectedSimpleAction.title" 
      :defaultMenu="customOptionMenu" 
      :actions="headerActions"
      @action-EXECUTE-ACTION="executeAction(selectedSimpleAction)"
    />

    <div class="box__container" v-if="!usesKsc2">
        <div class="box__title">Aktivierungsbrief für Kundenzugang verwenden</div>
        <div>{{activationLetterDescription}}</div>
        <div>Wollen Sie Ihren Kunden neben dem SMS-Freischaltcode auch die Möglichkeit eines solchen Aktivierungsbriefes als zusätzliche Authentisierungsmöglichkeit anbieten?</div>
        <InputRadioBoxGroup 
            :values="[
                {value: true, label: 'Ja'},
                {value: false, label: 'Nein'}
            ]"
            :value="activationLetterValue"
            isComponentHalfSize
            @input="onActivationLetter"
            style="width: 100%"
        />
    </div>
    <div v-if="selectedSimpleAction" class="box__container" :key="selectedSimpleAction.id">
      <div class="box__title">{{selectedSimpleAction.titel}}</div>
      <div v-html="getBeschreibung(selectedSimpleAction.type)"></div>
      <div v-if="selectedSimpleAction.statusMessage" v-html="getStatusMessage(selectedSimpleAction.type, selectedSimpleAction.status)"></div>

      <InputRadioBoxGroup 
          v-if="selectedSimpleAction.rdYesNo"
          :values="[
                    {value: 'YES', label: 'Ja'},
                    {value: 'NO', label: 'Nein'}
                ]"
          :value="selectedSimpleAction.status"
          isComponentHalfSize
          @input="onRadioInput($event, selectedSimpleAction)"
          style="width: 100%"/>
    </div>
    <GhostLoading v-else type="paragraph" useBoxContainer />
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import PENDING_ACTIONS_TYPES from "@/store/pendingActions/types";
import messages from "@/views/communication/kundenAktionen/kunden-aktionen-mixing.js";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import axios from 'axios';

export default {
  mixins: [messages],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputRadioBoxGroup,
    GhostLoading,
  },
  data() {
    return {
      activationLetterValue: false,
      activationLetterDescription: "",
    }
  },
  computed: {
    ...mapGetters({
      pendingActions: CORE_TYPES.GETTERS.PENDING_ACTIONS,
      actionId: PENDING_ACTIONS_TYPES.GETTERS.ACTION_ID,
      simpleActionListe: PENDING_ACTIONS_TYPES.GETTERS.SIMPLE_ACTION_LISTE,
      usesKsc2: CORE_TYPES.GETTERS.CUSTOMERS_USE_KSC2,
    }),
    currentActionType() {
      return this.$route.query.actionType || this.actionListe?.[0]?.type;
    },
    actionListe() {
      return [ ...this.simpleActionListe || [], ].map(action => ({
        ...action,
        title: this.getActionTitle(action),
      }));
    },
    customOptionMenu() {
      return this.actionListe?.map(action => ({
        path: action.type,
        label: action.title,
        textBold: () => action?.type === this.selectedSimpleAction?.type,
        action: () => this.selectSimpleAction(action),
      }));
    },
    selectedSimpleAction() {
      return this.actionListe.find(action => action.type === this.currentActionType);
    },
    headerActions() {
      const { selectedSimpleAction, } = this;

      if(!selectedSimpleAction?.buttonAction) return [];

      return [
        PageHeaderSimpleAction('EXECUTE-ACTION', selectedSimpleAction?.buttonActionLabel),
      ];
    },
  },
  mounted: function () {
    this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.GET_SIMPLE_ACTION_LISTE);  
    if (!this.usesKsc2) {
      axios.get(`${process.env.VUE_APP_API}/activation_letter/enabled`)
      .then(response => {
        this.activationLetterValue = response.data.value;
        this.activationLetterDescription = response.data.description;
      });
    }
  },
  methods: {
    getActionTitle(action) {
      switch (action?.type) {
        case 'SimpleActionKundenzugang': 
          return 'Kunden-App versenden';
        case 'SimpleActionFondsshop': 
          return 'Re-Balancing für den FFB-Fondsshop'
        default: 
          return '';
      }
    },
    selectSimpleAction(action) {
      if(!action || action.type === this.currentActionType) return;
      this.$router.push({ path: this.$route.path,  query: { actionType: action.type, }, });
    },
    executeAction(action) {
      switch (action?.type) {
          case 'SimpleActionKundenzugang': 
            this.$router.push({path: '/kundenzugang-und-kundenapp',  query: {simpleActionId: action?.id}});
            break;
          case 'SimpleActionFondsshop': 
            this.$router.push({path: '/re-balancing-ffb-fondsshop',  query: {simpleActionId: action?.id}});
            break;
          default: 
            break;
      }
        

    },
    onRadioInput(value, action) {
      this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.EXECUTE_SIMPLE_ACTION, {
        simpleActionId: action?.id,
        type: action?.type,
        actionCommand: value == 'NO' ? 'BUTTON_NO' : 'BUTTON_YES',
        }).then(response => {
            this.$store.commit(PENDING_ACTIONS_TYPES.MUTATIONS.EXECUTE_SIMPLE_ACTION_SUCCESS, response);
            // this.$router.push('/home');
        }); 

    },
    onActivationLetter(value) {
        axios.post(`${process.env.VUE_APP_API}/activation_letter/set_enabled?value=${value}`, {})
        .then(response => {});
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu den Kunden-Aktionen', 
      to,
      from,
      breadcrumb: "Kunden-Aktionen"
    });

    next();
  },
}
</script>

<style scoped>

</style>