// Define the default list of allowed cookies
const allowedCookies = ['clientIdToken'];

// Backup original document.cookie descriptor
const originalCookieDescriptor = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie') || Object.getOwnPropertyDescriptor(HTMLDocument.prototype, 'cookie');

Object.defineProperty(document, 'cookie', {
    get: function() {
        const allCookies = originalCookieDescriptor.get.call(document).split('; ')
            .filter(cookie => allowedCookies.includes(cookie.split('=')[0]))
            .join('; ');
        return allCookies;
    },
    set: function(cookie) {
        const cookieName = cookie.split('=')[0];
        if (allowedCookies.includes(cookieName))
            originalCookieDescriptor.set.call(document, cookie);
    },
});