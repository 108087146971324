import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import { makeQueryParam } from '@/helpers/utils-helper';
import { redirectToFirstAllowedChild, } from '@/router';

import { VIEW_ROLES, ROLES, BROKER_STRUCTURE_ROLES, EMPLOYEE_ROLES} from '@/router/roles';

export default [
  {
    path: 'berichte',
    component: () => import(/* webpackChunkName: "berichte" */ '@/views/vermogen/Berichte.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]],
      },
    },
    children: [
      {
        path: '',
        redirect: redirectToFirstAllowedChild,
      },
      {
        path: 'diagramme',
        component: () => import(/* webpackChunkName: "versicherungen2" */ '@/views/vermogen/WertpapiereDiagramme.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          }
        },
      },
      {
        path: 'diagramme/:id',
        component: () => import(/* webpackChunkName: "versicherungen2" */ '@/views/vermogen/WertpapiereDiagramme.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          }
        },
      },
      {
        path: 'performance',
        component: () => import(/* webpackChunkName: "versicherungen3" */ '@/components/performance/Performance.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          }
        },
      },
      {
        path: 'performance/:id',
        component: () => import(/* webpackChunkName: "versicherungen3" */ '@/components/performance/Performance.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          }
        },
      },
      {
        path: 'wertentwicklung/:tab',
        component: () => import(/* webpackChunkName: "versicherungen6" */ '@/components/performance/Performance.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          }
        },
      },
      {
        path: 'year-performance',
        name: 'historische-wertentwicklung',
        component: () => import(/* webpackChunkName: "performance" */ '@/components/performance/Performance.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER, ROLES.SHOW_HISTORICAL_PERFORMANCE]]
          }
        },
      },
      {
        path: 'year-performance/:id',
        name: 'historische-wertentwicklung-id',
        component: () => import(/* webpackChunkName: "performance" */ '@/components/performance/Performance.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          }
        },
      },
      {
        path: 'year-performance-details',
        component: () => import('@/components/performance/PerformanceDetails.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER, ROLES.SHOW_HISTORICAL_PERFORMANCE]]
          }
        },
      },
      {
        path: 'profits',
        name: 'realisierte-gewinne',
        component: () => import(/* webpackChunkName: "versicherungen5" */ '@/components/performance/Performance.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER, ROLES.DEPOTAUSZUG_LISTE_REALISIERT]]
          }
        },
      },
      {
        path: 'profits/:id',
        name: 'realisierte-gewinne-id',
        component: () => import(/* webpackChunkName: "versicherungen5" */ '@/components/performance/Performance.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          }
        },
      },
      {
        path: 'spar_entnahmeplane',
        component: () => import(/* webpackChunkName: "Spar-/Entnahmepläne" */ '@/components/spar_entnahmeplane/SparEntnahmeplane.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER, ROLES.SHOW_SAVINGS_AND_WITHDRAWAL_PLANS_SEPARATELY]]
          } 
        },
      },
      {
        path: 'quartalsberichte',
        component: () => import(/* webpackChunkName: "Quartalsberichte" */ '@/components/quartalsberichte/Quartalsberichte.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_CUSTOMER]
          } 
        },
      },  
      {
        path: 'transaktionen',
        component: () => import(/* webpackChunkName: "Transactions" */ '@/views/BaseRouterView.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          },
        },
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "Transactions" */ '@/components/transactions/Transactions.vue'),
          },
          {
            path: ':category',
            component: () => import(/* webpackChunkName: "Transactions" */ '@/components/transactions/Transactions.vue'),
          },
          {
            path: ':category/:id',
            component: () => import(/* webpackChunkName: "Transactions" */ '@/components/transactions/Transactions.vue'),
          },
        ],
      },
      {
        path: 'ex-post-berechnung',
        component: () => import(/* webpackChunkName: "ExPostBerechnung" */ '@/views/berichte/ExPostBerechnung.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, ROLES.HAS_EX_POST_BERECHNUNG]]
          } 
        },
      },  
    ]
  },
  {
    path: 'courtagestatistik',
    // beforeEnter: () => {
    //   const token = store.getters[CORE_TYPES.GETTERS.GET_TOKEN];
    //   const apiAddress = store.getters[CORE_TYPES.GETTERS.API_ADDRESS];
    //   const params = makeQueryParam({ token });
    //   const url = `${apiAddress}/download_service/getCourtagestatistikKunde?${params}`;
    //   window.open(url);
    //   return false;
    // }
    component: () => import(/* webpackChunkName: "Kundencourtagen" */ '@/views/berichte/Kundencourtagen.vue'),
    meta: {
      menu: { 
        label: 'Courtagestatistik',
        // component: UndrawSiteStats,
      },
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, EMPLOYEE_ROLES.ZUGRIFF_COURTAGEHISTORIE]]
      } 
    },
  },
  {
    path: 'altersvorsorge',
    component: () => import(/* webpackChunkName: "Kundencourtagen" */ '@/views/berichte/Altersvorsorge.vue'),
    meta: {
      menu: { 
        label: 'Altersvorsorge',
      },
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, ROLES.SHOW_ALTERSVORSORGE]]
      } 
    },
  },
]
