<template>
  <div>
  <BaseModal
    ref="zusatzDok"
    labelButtonConfirm="Weiter zur Unterschrift"
    labelButtonCancel="Abbrechen"
    @onConfirmButton="sendZusatzDoks"
    @onCancelButton="closeZusatzListModal"
    size="lg">
    <template v-slot:modalTitle>
      <ph-warning :size="16" class="mr-2" style="color: orangered"/>
      Zusätzliche Dokumente
    </template>
    <div class="mb-1" v-if="pictureAttachMsg">
      {{ pictureAttachMsg }}
    </div>
    <div class="font-strong mb-1">
      Wenn Sie möchten können Sie hier Dokumente auswählen, die vor der e-Signatur zum Antrag hinzugefügt werden.
    </div>
    <div class="font-strong mb-1">
      Andernfalls können Sie direkt mit der Unterschrift fortfahren.
    </div>
    <div v-for="(message, index) of bodyMessages" :key="'body' + index">
      <div class="mb-1">
        {{ message }}
      </div>
    </div>
    <div class="mb-1">
      Bitte beachten Sie, dass das Anfügen der Dokumente dauerhaft ist, auch wenn Sie den Antrag doch nicht signieren.
    </div>
    <div class="font-strong mb-1" v-if="webServiceMsg">
      {{ webServiceMsg }}
    </div>
    <div class="row" v-for="(dok, index) of zusatzList" :key="index">
      <div class="col">
        <div class="container">
            <InputCheckBoxItem 
              :disabled="dok.appended"
              v-model="zusatzDokForm[dok.id]"/> 
          <div class="ml-1">
            <DownloadLink 
              v-if="dok.id" 
              :href="attachmentLinkMaker(dok.filename, dok.id)"
              :title="`${dok.bezeichnung} (${dok.herkunft})`"
            />
            <div v-else>{{dok.bezeichnung}} ({{dok.herkunft}}</div>
          </div> 
        </div>
      </div>
    </div>
  </BaseModal>

  <BaseModal
    ref="errorModal"
    labelButtonConfirm="Ok"
    :showCancelButton="false">

      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Fehler
      </template>

      <label>{{ errorMessage }}</label>
  </BaseModal>  
  </div>
</template>

<script>
import axios from 'axios';
import DOCUMENT_TYPES from "@/store/documents/types";
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import { mapGetters } from "vuex";
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import { PhWarning } from 'phosphor-vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import { openSignoViewDocument } from '@/components/core/download/SignoViewerLink.vue';
import EVENT_BUS, { ANTRAG_CLOSE } from '@/event-bus';
import ANTRAG_TYPES from '@/store/antrag/types';

export default {
  mixins: [],
  components: {
    BaseButton,
    BaseModal,
    InputCheckBoxItem,
    PhWarning,
    DownloadLink,
  },
  props: {
    selectedDoc: {
    },
    emitClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      apiAddress: process.env.VUE_APP_API,
      zusatzDokForm: {},
      zusatzList: [],
      pictureAttachMsg: null,
      webServiceMsg: null,
      bodyMessages: null,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      attachmentLinkMaker: DOCUMENT_TYPES.GETTERS.GET_SIMPLE_FILE_LINK_MAKER,
    }),    
  },  
  mounted() {
  },
  methods: { 
    closeZusatzListModal() {
      this.$refs.zusatzDok.close();
      this.zusatzList = null
      this.pictureAttachMsg = null
      this.webServiceMsg = null
      this.bodyMessages = null
      this.zusatzDokForm = {};
    },
   sendZusatzDoks() {
      let concatIds;
      if (this.zusatzDokForm) {
        concatIds = Object.keys(this.zusatzDokForm).filter(id => this.zusatzDokForm[id] === true);
        this.zusatzDokForm = {}
      }
      if (this.selectedDoc) {
        const payload = {
          concatIds: concatIds,
          dokId: this.selectedDoc['PARAMETER_DOK_ID'],
          antragsnrIntern: this.selectedDoc['PARAMETER_ANTRAGSNR_INTERN'],
          antragsdatenPdfId: this.selectedDoc['PARAMETER_ANTRAGSDATEN_PDF_ID'],
        }
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING, 'zusatzDok');
        axios.post(`${process.env.VUE_APP_API}/dokumentenarchivSign/additionalDocumentsConcatSign`,
          payload, {
          headers: {'Content-Type': 'application/json'},
          defaultSpinner: true
        }).then(response => {
          const responseData = response.data
          if (responseData.signoUri) {
            openSignoViewDocument(responseData.signoUri, `Zusaetzliche Dokumente ${payload.dokId}`);
            if (this.emitClose) {
              EVENT_BUS.$emit(ANTRAG_CLOSE);
            }
          } else if (responseData.errorMessage) {
            this.errorMessage = responseData.errorMessage;
            this.$refs.errorModal.open();
          }
        }).finally(() => this.$store.commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, 'zusatzDok'))
      }
      this.closeZusatzListModal();
    },    
    open() {
      if (this.selectedDoc) {
        this.$emit('updateLoadingStatus', true);
        let sendList = []
        for (const prop in this.selectedDoc) {
          sendList.push(prop)
          sendList.push(this.selectedDoc[prop])
        }
        axios.get(`${process.env.VUE_APP_API}/dokumentenarchivSign/getPictureAttachments?${sendList.map((n) => `sendData=${n}`).join('&')}`,
        { headers: {'Content-Type': 'application/json'},
          defaultSpinner: true
        }).then(response => {
            if (response && response.data) {
              this.zusatzDokForm = {}
              this.zusatzList = response.data.attachments
              this.pictureAttachMsg = response.data.pictureAttachMsg
              this.webServiceMsg = response.data.webServiceMsg
              this.bodyMessages = response.data.messages
              this.zusatzList.forEach(dok => this.zusatzDokForm[dok.id] = dok.appended  || false);
              this.$emit('updateLoadingStatus', false);
              this.$refs.zusatzDok.open();
            }
          })      
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex
}
</style>
