var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              title: _vm.$appNavigation.currentMenu.label,
              id: _vm.$appNavigation.currentOptionMenuId,
              actions: _vm.pageHeaderActions
            }
          },
          _vm.pageHeaderActionListeners
        )
      ),
      _c(
        "BaseFilter",
        _vm._g(
          _vm._b(
            { attrs: { filterId: _vm.componentIds.filterId } },
            "BaseFilter",
            _vm.baseFilterConfigs,
            false
          ),
          _vm.baseFilterListeners
        )
      ),
      _c(
        "div",
        { staticClass: "box__container", attrs: { tid: _vm.componentIds.tid } },
        [
          !_vm.loading.list && _vm.tableConfigs.rows.length
            ? _c(
                "Table",
                _vm._g(
                  _vm._b(
                    { attrs: { tableId: _vm.componentIds.tableId } },
                    "Table",
                    _vm.tableConfigs,
                    false
                  ),
                  _vm.tableListeners
                )
              )
            : _vm.loading.list
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.tableConfigs.title }
              })
            : _vm.tableConfigs.rows.length === 0
            ? _c("NoData", { attrs: { title: _vm.tableConfigs.title } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }