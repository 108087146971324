import IZF from './types';
import Vue from 'vue'
import { getInitialState } from './index'

export default {
  [IZF.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [IZF.MUTATIONS.COMBO](state, payload) {
    state.COMBO = payload
  },
  [IZF.MUTATIONS.RESULT](state, payload) {
    state.RESULT = payload
  },
  
}