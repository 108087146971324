var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "breadcrumb__navigation" }, [
    _c(
      "div",
      { staticClass: "breadcrumb__navigation__item wide__screen" },
      [
        _vm._l(_vm.navigationArray, function(node, index) {
          return [
            !(_vm.ignoreFirst && index === 0)
              ? _c(
                  "div",
                  {
                    key: index,
                    class: index == 0 ? "first__label" : "",
                    on: {
                      click: function($event) {
                        return _vm.selectNavigationNode(node)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.concatenatedLabel(node.label, index)) +
                        "  "
                    )
                  ]
                )
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }