var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: {
          "action-SELECT_ALL": _vm.selectAll,
          "action-DESELECT_ALL": _vm.deselectAll
        }
      }),
      _vm.loading
        ? _c("GhostLoading", {
            attrs: { title: _vm.PAGE_TITLE, useBoxContainer: "", type: "table" }
          })
        : [
            _c("DashboardPanel", {
              attrs: {
                id: "f4cfdd24-f99c-4a66-bfaa-94faa9724d22",
                hidePageHeader: "",
                ignoreUserLevelConfig: "",
                isMasonry: "",
                editDisabled: "",
                data: _vm.dashboardData
              },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.versSparten, function(item) {
                    return {
                      key: item.name,
                      fn: function() {
                        return _vm._l(item.values, function(sparte) {
                          return _c(
                            "div",
                            { key: sparte.id },
                            [
                              _c("InputToggleSwitch", {
                                staticClass: "mb-2",
                                attrs: { label: sparte.bezeichnung },
                                model: {
                                  value: sparte.selected,
                                  callback: function($$v) {
                                    _vm.$set(sparte, "selected", $$v)
                                  },
                                  expression: "sparte.selected"
                                }
                              })
                            ],
                            1
                          )
                        })
                      },
                      proxy: true
                    }
                  })
                ],
                null,
                true
              )
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }