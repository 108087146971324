var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedBuchungsnr
    ? _c(
        "div",
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v(
              "Verteilung der Abrechnung auf die Struktur (Buchungsnr: " +
                _vm._s(_vm.selectedBuchung.buchungsnr) +
                ")"
            )
          ]),
          !_vm.loading && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "e5326abc-fa73-49dd-a385-05d6d3843074",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10,
                  rowId: "lfdNr"
                }
              })
            : _vm.loading
            ? _c(
                "div",
                { staticClass: "text-centered" },
                [_c("AnimatedSpinner")],
                1
              )
            : _c("div", [_vm._v("Keine Daten")])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }