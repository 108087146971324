<template>
  <BaseToolbarButton 
    title="Align center"
    :icon="icon"
    :active="active"
    :disabled="disabled"
    @click="action"
    content="Zentriert ausrichten"
    v-tippy="{ arrow: true }"/>
</template>

<script>
import { PhTextAlignCenter } from 'phosphor-vue';
import BaseToolbarButton from '../base-components/BaseToolbarButton.vue';

export default {
  components: {
    BaseToolbarButton,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    icon() {
      return PhTextAlignCenter;
    },
    active() {
      return this.editor?.isActive({ textAlign: 'center' })
    }
  },

  methods: {
    action() {
      this.editor?.chain().focus().setTextAlign('center').run()
    }
  }

}
</script>
