var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Eingebundene Wertpapiere")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "BaseButton",
              {
                on: {
                  click: function($event) {
                    return _vm.neuEigebundeneWertpapiere()
                  }
                }
              },
              [_vm._v("Neues Wertpapier anlegen")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.loading
              ? _c("GhostLoading", { attrs: { type: "table" } })
              : _vm.eigebundeneWertpapiere &&
                _vm.eigebundeneWertpapiere.rows.length == 0
              ? _c("NoData")
              : _c("Table", {
                  attrs: {
                    tableId: "1a4fb9da-4982-4f0f-98fc-8514438eb29a",
                    headers: _vm.eigebundeneWertpapiereHeaders,
                    rows: _vm.eigebundeneWertpapiere.rows
                  },
                  on: {
                    "action-EDIT": function($event) {
                      return _vm.editEingebundeneWertpapiere($event)
                    },
                    "action-DELETE": function($event) {
                      return _vm.deleteEingebundeneWertpapiere($event)
                    }
                  }
                })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [_vm._v("Rückkaufswerte")]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "BaseButton",
              {
                on: {
                  click: function($event) {
                    return _vm.newRueckkaufswerte()
                  }
                }
              },
              [_vm._v("Neuen Rückkaufswert anlegen")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.loading
              ? _c("GhostLoading", { attrs: { type: "table" } })
              : _vm.ruckkaufswerte && _vm.ruckkaufswerte.rows.length == 0
              ? _c("NoData")
              : _c("Table", {
                  attrs: {
                    tableId: "1a4fb9da-4982-4f0f-98fc-8514438eb29a",
                    headers: _vm.ruckkaufswerteHeaders,
                    rows: _vm.ruckkaufswerte.rows
                  },
                  on: {
                    "action-EDIT": function($event) {
                      return _vm.editRueckkaufswerte($event)
                    },
                    "action-DELETE": function($event) {
                      return _vm.deleteRueckkaufswerte($event)
                    }
                  }
                })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      [
        _c(
          "BaseModal",
          {
            ref: "ruckkaufswerteEditModal",
            attrs: { labelButtonCancel: "Abbrechen" },
            on: {
              onConfirmButton: _vm.saveRuckkaufswerte,
              onCancelButton: function($event) {
                return _vm.closeModal("ruckkaufswerteEditModal")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "modalTitle",
                fn: function() {
                  return [_vm._v(" Rückkaufswert ")]
                },
                proxy: true
              }
            ])
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputField", {
                    attrs: {
                      type: "number",
                      isInteger: "",
                      label: "Jahr",
                      validateUntouched: true
                    },
                    model: {
                      value: _vm.ruckkaufswerteEdit.jahr,
                      callback: function($$v) {
                        _vm.$set(_vm.ruckkaufswerteEdit, "jahr", $$v)
                      },
                      expression: "ruckkaufswerteEdit.jahr"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputField", {
                    attrs: {
                      type: "currency",
                      label: "Rückkaufswerte",
                      precision: 2,
                      validateUntouched: true
                    },
                    model: {
                      value: _vm.ruckkaufswerteEdit.betrag,
                      callback: function($$v) {
                        _vm.$set(_vm.ruckkaufswerteEdit, "betrag", $$v)
                      },
                      expression: "ruckkaufswerteEdit.betrag"
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }