var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home_container" }, [
    _c(
      "div",
      { staticClass: "home_header_image" },
      [
        _c("EditableImageOnly", {
          staticClass: "home_image",
          attrs: { type: "home_header_image" }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "but_image" },
      [
        _c("div", { staticClass: "zweispalte" }, [
          _c(
            "div",
            { staticClass: "home_text" },
            [
              _c("editable-frame", { attrs: { type: "home_intro" } }),
              _c(
                "base-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push("assistent")
                    }
                  }
                },
                [_vm._v("ZUM ANLAGEASSISTENT")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "makler_info_outer" },
            [_c("makler-info")],
            1
          )
        ]),
        _c("news-titles", { staticClass: "news" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }