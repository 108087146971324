var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DocumentsNavigation", {
        attrs: {
          nodeId: _vm.selectedNodeId,
          versicherungId: _vm.$route.params.id,
          navigationArray: _vm.navigationArray,
          additionalActions: _vm.additionalActions,
          maxFileSizeMB: _vm.getMaxFileSizeAllowed
        },
        on: {
          headerAction: _vm.handleHeaderActions,
          selectNavigationNode: function($event) {
            return _vm.selectNavigationNode($event)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "explorer_container" },
        [
          _vm.files && _vm.currentAdditionalInputs
            ? _c("DocumentsUploadModal", {
                attrs: {
                  files: _vm.files,
                  additionalInputs: _vm.currentAdditionalInputs,
                  parentId: _vm.selectedNode.nodeId,
                  maxFileSizeMB: _vm.getMaxFileSizeAllowed
                },
                on: {
                  close: function($event) {
                    _vm.files = null
                  }
                }
              })
            : _vm._e(),
          _c("div", { staticClass: "explorer__container" }, [
            _c(
              "div",
              { staticClass: "explorer__filter" },
              [
                _c("DocumentsFilter", {
                  on: {
                    onFilter: function($event) {
                      return _vm.retrieveDokumentenarchivTree("???")
                    }
                  }
                })
              ],
              1
            ),
            _vm.hasAdditionalData
              ? _c(
                  "div",
                  { staticClass: "explorer__navigation box__container" },
                  [
                    _c(
                      "div",
                      [
                        _c("DocumentsHeaderInputs", {
                          attrs: { inputs: _vm.selectedNode.additionalData }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.explorerData
              ? _c(
                  "div",
                  {
                    staticClass:
                      "explorer__body search__mode__off box__container",
                    attrs: {
                      tid: _vm._generateTidFromString(
                        "explorer__body search__mode__off box__container"
                      )
                    }
                  },
                  [
                    _c(
                      "DragnDropArea",
                      {
                        staticClass: "explorer__content",
                        attrs: {
                          hoverText: "Datei hier ablegen",
                          disabled:
                            !_vm.selectedExplorerNode ||
                            !_vm.selectedExplorerNode.uploadable,
                          maxFileSizeMB: _vm.getMaxFileSizeAllowed
                        },
                        on: { files: _vm.dropFile }
                      },
                      [
                        _c("ContentWithSidebar", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "sidebar",
                                fn: function() {
                                  return [
                                    _vm.treeNodes.length
                                      ? _c("Tree", {
                                          staticClass: "explorer__tree_nodes",
                                          attrs: {
                                            items: _vm.treeNodes,
                                            childrenKey: "nodes",
                                            idKey: "nodeId",
                                            itemSelectedId: _vm.selectedNodeId,
                                            defaultCollapsed: false,
                                            uniqueId:
                                              "36f10422-475a-4457-84b2-ea2ae2fb6dd0"
                                          },
                                          on: {
                                            itemSelect: function($event) {
                                              return _vm.selectNode($event)
                                            },
                                            dropped: _vm.onDropAction
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "explorer__tree_node_content"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "explorer__tree_node_label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.label)
                                                            )
                                                          ]
                                                        ),
                                                        item.numberOfRecords
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "explorer__tree_node_total"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.numberOfRecords
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2868835845
                                          )
                                        })
                                      : _vm.dokTreeLoading
                                      ? _c("GhostLoading", {
                                          staticClass: "mt-2",
                                          attrs: {
                                            type: "block",
                                            config: { block: { height: 24 } },
                                            repeat: 10
                                          }
                                        })
                                      : _c("div", [_vm._v("Keine Daten")])
                                  ]
                                },
                                proxy: true
                              },
                              !_vm.$isSmallScreen
                                ? {
                                    key: "content",
                                    fn: function() {
                                      return [
                                        _vm.selectedNodeViewType ===
                                          _vm.VIEW_TYPE_NODES &&
                                        _vm.selectedNodeSubfolderAllowed
                                          ? _c("PrivateDokNodeTree", {
                                              attrs: {
                                                nodeId: _vm.selectedNodeId
                                              }
                                            })
                                          : _vm._e(),
                                        _c("DocumentsTable", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.selectedNodeViewType !==
                                                _vm.VIEW_TYPE_NODES,
                                              expression:
                                                "selectedNodeViewType !== VIEW_TYPE_NODES"
                                            }
                                          ],
                                          attrs: {
                                            nodeId: _vm.selectedNodeId,
                                            rowsPerPage: _vm.rowsPerPage,
                                            maxFileSizeMB:
                                              _vm.getMaxFileSizeAllowed,
                                            linkVertrag: "",
                                            loadAntraege: "",
                                            enableDraggable: "",
                                            noHeaderActions: ""
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c("DocumentsTableModal", {
        ref: "documentsModal",
        attrs: {
          selectedNodeViewType: _vm.selectedNodeViewType,
          selectedNodeSubfolderAllowed: _vm.selectedNodeSubfolderAllowed,
          nodeId: _vm.selectedNodeId,
          title: _vm.documentsTableModalTitle,
          additionalActions: _vm.additionalActions,
          rowsPerPage: _vm.rowsPerPage,
          maxFileSizeMB: _vm.getMaxFileSizeAllowed,
          linkVertrag: "",
          loadAntraege: "",
          enableDraggable: ""
        },
        on: {
          close: function($event) {
            _vm.selectedNodeId = null
          },
          headerAction: _vm.handleHeaderActions
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }