var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Nicht zugeordnete E-Mail-Adressen" }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _vm.error
            ? _c("UnexpectedError")
            : _vm.rows.length == 0
            ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
            : _c("Table", {
                attrs: {
                  tableId: "0f076559-668a-42a6-83e1-e3a9de3aa9e8",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20
                },
                on: {
                  "action-SEARCH": _vm.openCustomerSearch,
                  "action-DELETE": _vm.deleteEmailRecognized
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }