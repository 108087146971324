var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.row[_vm.column] === "" || isNaN(_vm.numberValue)
    ? _c("span", [_vm._v(_vm._s(_vm.row[_vm.column]))])
    : _vm.numberValue != null
    ? _c("span", { staticStyle: { "white-space": "nowrap" } }, [
        _vm._v(" " + _vm._s(_vm.leftOfComma)),
        _vm.precision > 0 ? _c("span", [_vm._v(",")]) : _vm._e(),
        _vm.precision > 0
          ? _c("span", { staticClass: "fractional" }, [
              _vm._v(_vm._s(_vm.rightOfComma))
            ])
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.symbol) + " ")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }