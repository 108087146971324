<template>
    <div>
        <PageHeaderTitleNavigation 
            :title="title" 
            :actions="headerActions"
            @action-NEW="newFactsheet"
        />

         <div class="box__container">
            <Table
                :headers="headers"
                :rows="rows"
                :rowsPerPage="20"
                rowId="vvfactsheethistory"
                @click-name="openFactsheet"
            />
        </div>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import VV_TYPES from '@/store/vermoegensverwaltung/types';
import {TextColumn, DateColumn} from "@/components/table2/table_util.js";
import mixin from '@/mixins/vermoegensverwaltung/vv-mixin.js';
import Table from "@/components/table2/Table.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    mixins: [mixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        Table,
        BaseButton,
    },
    data() {
        return {
            tableRows: [],
        }
    },
    mounted() {
        this.$store.dispatch(VV_TYPES.ACTIONS.FACTSHEET_HISTORY, this.schemaId).then(response => {
            this.tableRows = response.data;
        })
    },
    computed: {
        headerActions() {
            return [
                PageHeaderSimpleAction('NEW', 'Neu'),
            ];
        },
        title() {
            return '"' + decodeURIComponent(this.$route.params.title) + '" Factsheet History';
        },
        headers() {
            return { 
                lockedLeft: [],
                center: [
                    TextColumn("name", "Name").makeLink(),
                    DateColumn("datum", "Datum"),
                ],
                lockedRight: [],
            };
        },
        rows() {
            const result = this.tableRows;
            return result;
        },
    },
    methods: {
        openFactsheet(row) {
            if (row.dokId) {
                this.handlePdf(VV_TYPES.ACTIONS.GET_PDF_FACTSHEET, { dokId: row.dokId});
            }
        },
        newFactsheet() {
            this.$store.dispatch(VV_TYPES.ACTIONS.NEW_FACTSHEET, this.schemaId).then(response => {
                this.tableRows = response.data;
            })
        }
    }
}
</script>

<style>

</style>