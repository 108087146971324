var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editableInlineInput",
          attrs: {
            size: "xl",
            modalTitle: "Inhalt bearbeiten",
            showCancelButton: true,
            confirmDisabled: false
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            }
          }
        },
        [
          _vm.internalValue !== undefined
            ? _c("div", { staticClass: "defaultcard-container" }, [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v("Inhalt des Eingabefeldes")
                ]),
                _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "grow-1" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-inline-block placeholder-control",
                        on: {
                          click: function($event) {
                            _vm.showValuePlaceholder = !_vm.showValuePlaceholder
                          }
                        }
                      },
                      [
                        _vm._v(" Platzhalter "),
                        !_vm.showValuePlaceholder
                          ? _c("PhCaretDown", { attrs: { size: "20" } })
                          : _vm._e(),
                        _vm.showValuePlaceholder
                          ? _c("PhCaretUp", { attrs: { size: "20" } })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _vm.showValuePlaceholder
                  ? _c("div", { staticClass: "placeholders" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between" },
                        _vm._l(_vm.placeholdersOptions, function(p, i) {
                          return _c(
                            "div",
                            { key: "p" + i, staticClass: "grow-1" },
                            [
                              _c("ComboBox", {
                                attrs: {
                                  label: p.name,
                                  values: p.data,
                                  firstEmpty: true
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.insertPlaceholder($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c("InputField", {
                      attrs: { isComponentHalfSize: "" },
                      model: {
                        value: _vm.internalValue,
                        callback: function($$v) {
                          _vm.internalValue = $$v
                        },
                        expression: "internalValue"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }