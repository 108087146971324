<template>
  <div class="confirmed-2fa-message_container">
    <div class="my-4">
      <p>Die Bestätigung Ihrer Anmeldung ist abgeschlossen.</p>
      <p>Bitte kehren Sie zu dorthin zurück wo Sie Ihre Anmeldung begonnen haben.</p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.confirmed-2fa-message_container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
}
</style>
