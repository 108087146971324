import BERATUNGSMAPPE_TYPES from './types';
import { getInitialState } from './index'

export default {
  [BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNGSMAPPELIST_SUCCESS](state, payload) {
    state.beratungsmappeList = payload;
  }, 
  [BERATUNGSMAPPE_TYPES.MUTATIONS.RESET_DATA](state, payload) {
    Object.assign(state, getInitialState())
  },  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.EDIT_BERATUNGSMAPPE_SUCCESS](state, payload) {
    state.beratungsmappeCards = payload;
  }, 
  [BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNGSMAPPEACTIONS_SUCCESS](state, payload) {
    state.beratungsmappeActions = payload;
  },   
  [BERATUNGSMAPPE_TYPES.MUTATIONS.EXECUTE_BERATUNGSMAPPEACTION_SUCCESS](state, payload) {
    state.executeActionResult = payload;
  }, 
  [BERATUNGSMAPPE_TYPES.MUTATIONS.DELETE_ANTRAG_SUCCESS](state, payload) {

    const tableData = state.beratungsmappeCards.antraege;

    tableData.antraegeList = tableData.antraegeList.filter(antrag => antrag.Id !== payload.antragId);
    state.executeActionResult = payload;
    },

  [BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_ORDER_POSITION_SUCCESS](state, payload) {
    state.orderPosition = payload?.position;
    state.orderPositionHeaders = payload?.headers || [];
    state.orderPositionRecords = payload?.records || [];
  }, 
  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.UPDATE_ORDER_POSITION](state, payload) {
    state.orderPosition = payload?.position;
    state.orderPositionHeaders = payload?.headers || [];
    state.orderPositionRecords = payload?.records || [];
  }, 
  [BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_LAGERSTELLE_SUCCESS](state, payload) {
    state.lagerstelleListe = payload;
  },   
  [BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNGSMAPPE_SELECTIONS_SUCCESS](state, payload) {
    state.beratungsmappeSelections = payload;
  },
  [BERATUNGSMAPPE_TYPES.MUTATIONS.SAVE_ANTRAGS_POSITION_SUCCESS](state, payload) {
    state.lastSavedAntragsPosition = payload;
  },  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.DELETE_POSITION_SUCCESS](state, payload) {
    state.lastDeletedAntragsPosition = payload;
  },    
}