var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: { label: "Anmeldename", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderAnmeldename", $event)
          }
        },
        model: {
          value: _vm.form.anwenderAnmeldename,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderAnmeldename", $$v)
          },
          expression: "form.anwenderAnmeldename"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Kennwort",
          isComponentHalfSize: "",
          validateUntouched: ""
        },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderKennwort", $event)
          }
        },
        model: {
          value: _vm.form.anwenderKennwort,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderKennwort", $$v)
          },
          expression: "form.anwenderKennwort"
        }
      }),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.createPassword()
                }
              }
            },
            [_vm._v("Kennwort generieren")]
          )
        ],
        1
      ),
      _c("InputField", {
        attrs: { label: "Login-Schlüssel", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderLoginKey", $event)
          }
        },
        model: {
          value: _vm.form.anwenderLoginKey,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderLoginKey", $$v)
          },
          expression: "form.anwenderLoginKey"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }