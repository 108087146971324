import SESSION_TIME_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import dayjs from 'dayjs'
import axios from 'axios'
import { makeQueryParam } from '@/helpers/utils-helper';
import { buildMessage } from "@/helpers/log-message-helper";

export default {
  async [SESSION_TIME_TYPES.ACTIONS.ADD_CLICK]({ commit, getters, dispatch }, { x, y, path }) {

    const payload = {
      x, y, path,
      eventDate: dayjs().utc().format(),
    }
    
    const parallelTimeoutTime = dayjs().add(getters[CORE_TYPES.GETTERS.TIMEOUT_SECONDS], 'seconds').toDate();
    
    commit(SESSION_TIME_TYPES.MUTATIONS.ADD_CLICK, payload);
    commit(SESSION_TIME_TYPES.MUTATIONS.UPDATE_PARALLEL_TIMEOUT_TIME, parallelTimeoutTime)

    dispatch(SESSION_TIME_TYPES.ACTIONS.SCHEDULE_NEXT_SAVE_CLICK_BATCH)
  },

  async [SESSION_TIME_TYPES.ACTIONS.LOAD_CLICKS]({ commit, getters }) {
    if (!getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
      return;
    }

    const payload = {
      begin: dayjs().utc().subtract(1, 'week').valueOf(),
      end: dayjs().utc().valueOf(),
    }

    const params = makeQueryParam(payload)
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/click_points?${params}`);
    
    const clicksByPath = response.data ? response.data : {};
    commit(SESSION_TIME_TYPES.MUTATIONS.ADD_CLICK_BY_PATH, clicksByPath);
  },

  [SESSION_TIME_TYPES.ACTIONS.SCHEDULE_NEXT_SAVE_CLICK_BATCH]({ state, commit, dispatch, getters }) {

    if (!state.nextScheduledSaveClickBatch && null != getters[CORE_TYPES.GETTERS.SUPPOSED_TIMEOUT_TIME]) {

      const supposedTimeoutTime = dayjs(getters[CORE_TYPES.GETTERS.SUPPOSED_TIMEOUT_TIME]);
      const difference = supposedTimeoutTime.diff(dayjs(), 'seconds');
      const nextTimeout = difference < 360 ? 1 : 300;

      const nextScheduledSaveClickBatch = setTimeout(() => {
        dispatch(SESSION_TIME_TYPES.ACTIONS.SEND_NEXT_SAVE_CLICK_BATCH)
      }, 1000 * nextTimeout)

      commit(SESSION_TIME_TYPES.MUTATIONS.UPDATE_NEXT_SCHEDULED_SAVE_CLICK_BATCH, nextScheduledSaveClickBatch)
    }
    
  },

  async [SESSION_TIME_TYPES.ACTIONS.SEND_NEXT_SAVE_CLICK_BATCH]({ commit, getters, state, dispatch }) {
    commit(SESSION_TIME_TYPES.MUTATIONS.UPDATE_NEXT_SCHEDULED_SAVE_CLICK_BATCH, null)
    const payload = state.queueClicks

    if (!payload?.length || !getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
      return;
    }

    try {
      await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/save_points`, payload);
      commit(SESSION_TIME_TYPES.MUTATIONS.REMOVE_CLICKS_FROM_QUEUE, payload)

      if (state.queueClicks?.length) {
        dispatch(SESSION_TIME_TYPES.ACTIONS.SCHEDULE_NEXT_SAVE_CLICK_BATCH)
      }
      
    } catch (error) {
      if (error?.response?.status !== 401) {
        dispatch(SESSION_TIME_TYPES.ACTIONS.SCHEDULE_NEXT_SAVE_CLICK_BATCH);
      }
      
      await dispatch(CORE_TYPES.ACTIONS.LOGOUT, { message: buildMessage('Die Sitzung ist abgelaufen.', 'danger'), timeout: true, });
    }
  }
}