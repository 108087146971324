import BETEILIGUNGEN_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import { buildMessage } from "@/helpers/log-message-helper";
import axios from 'axios';

export default {
  async [BETEILIGUNGEN_TYPES.ACTIONS.GET_BETEILIGUNGEN]({ commit, getters}, payload) {
    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/../mrsclosedfunds`;
    await axios.get(url, { params: { depotId: payload?.depotId, } }).then(response => {
      if (response && response.data) {
        commit(BETEILIGUNGEN_TYPES.MUTATIONS.GET_BETEILIGUNGEN_SUCCESS, response.data);
      }
    }).catch(error => {
      commit(BETEILIGUNGEN_TYPES.MUTATIONS.GET_FAILTURE);
    })
  },
  
}