var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasAccess("/communication/postfach/overview") &&
      this.hasAccess("/communication/postfach")
        ? _c("DashboardPanel", {
            attrs: {
              id: "3f6a2d8a-406b-4d4a-85f9-7204579b313n",
              data: _vm.dashboardData
            },
            on: { executeAction: _vm.handleExecuteAction }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }