import BALANCE_SHEET_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_FAILTURE](state, payload) {
    // Object.assign(state, getInitialState())
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_OTHER_ASSETS_SUCCESS](state, payload) {
    if (payload.bankAccount && payload.bankAccount.error === 403) {
      state = {
          ...state,
          data: {
            isLoaded: true,
            isError: false,
            isRestricted: true,
            tableData: []
          },
          meta: {
            updatedAt: Date.now()
          }
      };
    }
    const idData = (payload.id) ? {
      ...state.idData,
      [payload.id]: {
        ...payload.bankAccount,
        isLoaded: true
      }
    } : {};
    // state = {
    //     ...state,
    //     data: (!payload.id || payload.id === 'ALLE_DEPOTS_ANZEIGEN') ? {
    //       ...payload.bankAccount,
    //       isLoaded: true,
    //       isError: false,
    //     } : {...state.data},
    //     idData,
    //     meta: {
    //       updatedAt: Date.now()
    //     }
    // };
    state.data = (!payload.id || payload.id === 'ALLE_DEPOTS_ANZEIGEN') ? {
          ...payload.bankAccount,
          isLoaded: true,
          isError: false,
        } : {...state.data};
    state.meta = {
      updatedAt: Date.now()
    }
    state.idData = idData;
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_CREDIT_SUCCESS](state, payload) {
    if (payload && payload.error === 403) {
      state = {
        ...state,
        credit: {
          isLoaded: true,
          isError: false,
          isRestricted: true,
        },
        meta: {
          updatedAt: Date.now()
        }
      };
    }
    // state = {
    //   ...state,
    //   credit: {
    //     ...payload,
    //     isLoaded: true,
    //   },
    //   meta: {
    //     updatedAt: Date.now()
    //   }
    // };
    state.meta = {
      updatedAt: Date.now()
    }
    state.credit = {
      ...payload,
      isLoaded: true,
    };
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_BANK_ACCOUNT_SUCCESS](state, payload) {
    if (payload.values && payload.values.error === 403) {
      state = {
        ...state,
        bankAccount: {
          isLoaded: true,
          isError: false,
          isRestricted: true,
        },
        meta: {
          updatedAt: Date.now()
        }
      };
    }
    const idData = (payload.id) ? {
      [payload.id]: {...payload.values, isLoaded: true}
    } : {};
    // state = {
    //   ...state,
    //   bankAccount: {
    //     ...state.bankAccount,
    //     ...((!payload.id || payload.id === 'ALLE_DEPOTS_ANZEIGEN') ?
    //       prepareTableMeta(payload.values, TableNames.bankAccount) : {...state.bankAccount}),
    //     ...idData,
    //     isLoaded: true,
    //     isError: false,
    //   },
    //   meta: {
    //     updatedAt: Date.now()
    //   }
    // };
    state.meta = {
      updatedAt: Date.now()
    }
    state.bankAccount = {
      ...state.bankAccount,
      ...prepareTableMeta(payload.values, TableNames.bankAccount),
      ...idData,
      isLoaded: true,
      isError: false,
    }
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_CLOSED_FUNDS_SUCCESS](state, payload) {
    if (payload.values && payload.values.error === 403) {
      state = {
        ...state,
        closedFunds: {
          isLoaded: true,
          isError: false,
          isRestricted: true,
        },
        meta: {
          updatedAt: Date.now()
        }
      };
    }
    const idData = (payload.id) ? {
      [payload.id]: {...payload.values, isLoaded: true}
    } : {};
    // state = {
    //   ...state,
    //   closedFunds: {
    //     ...state.closedFunds,
    //     ...((!payload.id || payload.id === 'ALLE_DEPOTS_ANZEIGEN') ?
    //       prepareTableMeta(payload.values, TableNames.closedFunds) : {...state.closedFunds}),
    //     ...idData,
    //     isLoaded: true,
    //     isError: false,
    //   },
    //   meta: {
    //     updatedAt: Date.now()
    //   }
    // };
    
    state.meta = {
      updatedAt: Date.now()
    }
    state.closedFunds = {
      ...state.closedFunds,
      ...((!payload.id || payload.id === 'ALLE_DEPOTS_ANZEIGEN') ?
        prepareTableMeta(payload.values, TableNames.closedFunds) : {...payload.values}),
      ...idData,
      isLoaded: true,
      isError: false,
    }
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_DEPOSIT_SUM_SUCCESS](state, payload) {
    if (payload && payload.error === 403) {
      state = {
        ...state,
        depositSum: {
          isLoaded: true,
          isError: false,
          isRestricted: true,
        }
      };
    }
    state.meta = {
      updatedAt: Date.now()
    }
    state.depositSum = {
      ...payload,
      isLoaded: true,
      isError: false,
    }
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_ACCOUNTS_SUCCESS](state, payload) {
    state.accounts = {
        ...payload,
        isLoaded: true,
        isError: false,
        isRestricted: true,
      }
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_ACCOUNTS_DETAILS_SUCCESS](state, payload) {
    state.accountsDetails = {
        ...payload,
        isLoaded: true,
        isError: false,
        isRestricted: true,
      }
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_INSURANCE_TABLE_SUCCESS](state, payload) {
    if (payload && payload.error === 403) {
      state = {
        ...state,
        insuranceTable: {
            data: {
                isLoaded: true,
                isError: false,
                isRestricted: true,
            },
            id: payload.id,
        },
        isLoaded: true,
        isRestricted: true,
      };
    }
    payload = {
      data: {
        ...(payload.data ? payload.data : {})
      },
      ...payload
    }
    payload.data.isLoaded = true;
    state.meta = {
      updatedAt: Date.now()
    }
    state.insuranceTable = {
        data: {
            ...payload.data,
        },
        id: payload.id,
        visibleCategories: payload.visibleCategories,
        insuranceTable: {
          ...payload.data,
        }
    };
    state.visibleCategories = payload.visibleCategories;
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_DEPOTPOSITIONS_SUCCESS](state, payload) {
    // state = {
    //   ...state,
    //   depotPositions: {
    //     data: {
    //       ...payload.data,
    //       isLoaded: true,
    //       isError: false,
    //     },
    //     allData: {
    //       ...payload.data,
    //       isLoaded: true,
    //       isError: false,
    //     },
    //   },
    //   meta: {
    //     updatedAt: Date.now(),
    //   }
    // };
    state.meta = {
      updatedAt: Date.now()
    }
    state.depotPositions = {
      data: {
        ...payload.data,
        isLoaded: true,
        isError: false,
      },
      allData: {
        ...payload.data,
        isLoaded: true,
        isError: false,
      },
    };
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_TRANSACTIONS_SUCCESS](state, payload) {
    Vue.set(state, 'transactions', payload);
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_SPAR_ENTNAHMEPLANE_SUCCESS](state, payload) {
    state.spar_entnahmeplane = payload;
  },  
  [BALANCE_SHEET_TYPES.MUTATIONS.GET_VERMOGENSUBERSICHT_PDF_SUCCESS](state, payload) {
    state.vermogensubersichtPdf = payload;
  },  
}

const TableNames = {
  historicalPerformance: 'historicalPerformance',
  realisedProfit: 'realisedProfit',
  closedFunds: 'closedFunds',
  bankAccount: 'bankAccount',
  historicalAction: 'historicalAction',
}

export function prepareTableMeta(data, tableName = '') {
  switch (tableName) {
    case TableNames.historicalPerformance:
      break;
    case TableNames.realisedProfit:
      break;
    case TableNames.closedFunds:
      data.meta = {
        wertKey: 'Betrag',
        headers: [
          {key: 'Gesellschaft', label: 'Gesellschaft'},
          {key: 'Name', label: 'Name'},
          {key: 'Betrag', label: 'Betrag'}
        ]
      };
      break;
    case TableNames.bankAccount:
      data.meta = {
        wertKey: 'Kontostand',
        headers: [
          {key: 'Gesellschaft', label: 'Bank'},
          {key: 'Kontonummer', label: 'Kontonummer'},
          {key: 'Kontostand', label: 'Kontostand'}
        ]
      };
      break;
    case TableNames.historicalAction:
      break;
  }
  return data;
}