import dayjs from 'dayjs';
import { isDate } from '@/helpers/commonfunctions.js';


const DEFAULT_SEARCH_INPUT_KEY = 'defaultSearchInput';
const DEFAULT_PROPERTY_KEY = 'kundennr';

export const VALUE_EMPTY = '__EMPTY__'

export const GRANT_VALUES = [
  {value: VALUE_EMPTY, label: ' '},
  {value: 'NICHT_UNTERSCHRIEBEN', label: 'nicht unterschrieben'},
  {value: 'NICHT_MAKLER_UNTERSCHRIEBEN', label: 'Vermittlerunterschrift offen'},
  {value: 'TEILWEISE_UNTERSCHRIEBEN', label: 'Teilweise unterschrieben'},
  {value: 'KOMPLETT_UNTERSCHRIEBEN', label: 'komplett unterschrieben, aber noch nicht abgeschlossen'}
]

export function getSearchMetadata() {
  return [
    {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: [
        {
          type: 'text',
          label: 'Kundennr',
          key: 'kundennr',
        },
        {
          type: 'text',
          label: 'Name',
          key: 'name',
        },
        {
          type: 'text',
          label: 'Vorname',
          key: 'vorname',
        },
        {
          type: 'switcher',
          label: 'inkl. Warnung Deaktivieren',
          key: 'includeDisabled',
        },
        {
          type: 'combobox',
          label: 'Status',
          key: 'status',
          allowDuplicated: true,
          maxOccurences: 3,
          comboOptions: [ ...GRANT_VALUES],
        },
        {
          type: 'dateRange',
          label: 'Zeitraum',
          key: 'zeitraum',
        },
      ],
    }
  ]
}


export function prepareFilters(filters) {
  if(!filters || !filters.length) {
    return null;
  }

  return filters.reduce((result, f) => {
    const key = f.key;
    if(!key) {
      return result;
    }

    switch(key) {
      case DEFAULT_SEARCH_INPUT_KEY:
        result[DEFAULT_PROPERTY_KEY] = f.value;
        break;
      case 'zeitraum':
        result['zeitraumFrom'] = isDate(f.value[0]?.value) && dayjs(f.value[0]?.value).format('DD.MM.YYYY') || f.value[0]?.value;
        result['zeitraumTo'] = isDate(f.value[1]?.value) && dayjs(f.value[1]?.value).format('DD.MM.YYYY') || f.value[1]?.value;
        break;
      default:
        result[key] = f.value;
        break;
    }

    return result;
  }, {})
}