export const MUTATION_PREFIX = 'PENDING_ACTIONS_MUTATIONS_';
export const ACTIONS_PREFIX = 'PENDING_ACTIONS_ACTIONS_';
export const GETTERS_PREFIX = 'PENDING_ACTIONS_GETTERS_';

export default {
  MUTATIONS: {
    SIMPLE_ACTION_KUNDENZUGANG_SUCCESS: MUTATION_PREFIX + 'SIMPLE_ACTION_KUNDENZUGANG_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    GET_ACTION_ID_SUCCESS: MUTATION_PREFIX + 'GET_ACTION_ID_SUCCESS',
    GET_SIMPLE_ACTION_LISTE_SUCCESS: MUTATION_PREFIX + 'GET_SIMPLE_ACTION_LISTE_SUCCESS',
    EXECUTE_SIMPLE_ACTION_SUCCESS: MUTATION_PREFIX + 'EXECUTE_SIMPLE_ACTION_SUCCESS',
    GET_SIMPLE_ACTION_FONDSSHOP_DATA_SUCCESS: MUTATION_PREFIX + 'GET_SIMPLE_ACTION_FONDSSHOP_DATA_SUCCESS',
  },
  ACTIONS: {
    SIMPLE_ACTION_KUNDENZUGANG: ACTIONS_PREFIX + 'SIMPLE_ACTION_KUNDENZUGANG',
    GET_ACTION_ID: ACTIONS_PREFIX + 'GET_ACTION_ID',
    GET_SIMPLE_ACTION_LISTE: ACTIONS_PREFIX + 'GET_SIMPLE_ACTION_LISTE',
    EXECUTE_SIMPLE_ACTION: ACTIONS_PREFIX + 'EXECUTE_SIMPLE_ACTION',
    GET_SIMPLE_ACTION_FONDSSHOP_DATA: ACTIONS_PREFIX + 'GET_SIMPLE_ACTION_FONDSSHOP_DATA',
  },
  GETTERS: {
    SIMPLE_ACTION_KUNDENZUGANG: GETTERS_PREFIX + 'SIMPLE_ACTION_KUNDENZUGANG',
    ACTION_ID: GETTERS_PREFIX + 'ACTION_ID',
    SIMPLE_ACTION_LISTE: GETTERS_PREFIX + 'SIMPLE_ACTION_LISTE',
    EXECUTE_SIMPLE_ACTION: GETTERS_PREFIX + 'EXECUTE_SIMPLE_ACTION',
    SIMPLE_ACTION_FONDSSHOP_DATA: GETTERS_PREFIX + 'SIMPLE_ACTION_FONDSSHOP_DATA',

  }
}