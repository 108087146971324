var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ComboBox", {
        attrs: {
          label: "Gesellschaft",
          validateUntouched: "",
          values: _vm.gesellschaftValues
        },
        on: {
          change: function($event) {
            _vm.clearAntragsart()
            _vm.findAntragsart($event),
              _vm.dataChangedCombo("gesellschaft", $event)
          }
        },
        model: {
          value: _vm.form.gesellschaft,
          callback: function($$v) {
            _vm.$set(_vm.form, "gesellschaft", $$v)
          },
          expression: "form.gesellschaft"
        }
      }),
      _vm.deckblattTypeConfig.hatAntragTypAuswahl
        ? _c("ComboBox", {
            attrs: { label: "Antragsart", values: _vm.antragsartValues },
            on: {
              change: function($event) {
                return _vm.dataChangedCombo("antragsart", $event)
              }
            },
            model: {
              value: _vm.form.antragsart,
              callback: function($$v) {
                _vm.$set(_vm.form, "antragsart", $$v)
              },
              expression: "form.antragsart"
            }
          })
        : _vm._e(),
      _c("InputField", {
        attrs: { label: "Depotnummer (falls vorhanden)" },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.depotnummer,
          callback: function($$v) {
            _vm.$set(_vm.form, "depotnummer", $$v)
          },
          expression: "form.depotnummer"
        }
      }),
      _c("div", { staticClass: "row mt-0 mb-0" }, [
        _c(
          "div",
          { staticClass: "col col-sm-8 col-12" },
          [
            _c("DatePickerField", {
              attrs: { label: "Stand vom", isValueAsString: "" },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.form.standVom,
                callback: function($$v) {
                  _vm.$set(_vm.form, "standVom", $$v)
                },
                expression: "form.standVom"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col col-sm-4 col-12" },
          [
            _c("TimePicker", {
              attrs: { label: "Uhrzeit" },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.form.uhrzeit,
                callback: function($$v) {
                  _vm.$set(_vm.form, "uhrzeit", $$v)
                },
                expression: "form.uhrzeit"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }