import { mapGetters } from 'vuex';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import MENU_CONFIG_TYPES, { CONFIGURE_MENU_CONFIG_LOADING_ID } from '@/store/menuConfig/types';
import LOADING_TYPES from '@/store/loading/types';

import OptionMenuConfig from './OptionMenuConfig.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';

function usesInitialUserLevel(initialUserLevel) {
  if(initialUserLevel === FC_CONFIG_USER_LEVEL.GLOBAL 
    || initialUserLevel === FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR 
    || initialUserLevel === FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR
    || initialUserLevel === FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN) {
      return null;
  }

  return initialUserLevel;
}

export default {
  mixins: [],
  props: {
    configContext: {
      type: String,
      default: null,
      required: true,
    },
    configId: {
      type: String,
    },
    defaultMenu: {
      type: Array,
      default: () => [],
      required: true,
    },
    optionMenu: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onSaveConfig', 'onRestoreDefault'],
  data() {
    return {
      selectedUserLevel: null,

      loadingInitialData: false,
      loading: false,
      saving: false,
      deleting: false,
    };
  },
  computed: {
    ...mapGetters({
      userLevelDefault: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT,
      isLoadingFn: LOADING_TYPES.GETTERS.IS_LOADING,
    }),
    isConfigureMenuLoading() {
      return this.isLoadingFn(CONFIGURE_MENU_CONFIG_LOADING_ID);
    },
    isLoading() {
      return this.loadingInitialData || this.loading || this.saving || this.deleting || this.isConfigureMenuLoading;
    },
  },
  methods: {
    init(initialUserLevel = '') {
      this.selectedUserLevel = this.selectedUserLevel ? this.selectedUserLevel : (usesInitialUserLevel(initialUserLevel) || this.userLevelDefault);

      this.loadingInitialData = false;
      this.loading = false;
      this.saving = false;
      this.deleting = false;

      this.resetLoadedConfig();
      this.findFCConfigByUserLevel(this.selectedUserLevel, true)
    },
    resetLoadedConfig() {
      this.$store.commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_BY_USER_LEVEL, {
        configContext: this.configContext,
        configId: this.configId,
        userLevel: this.selectedUserLevel,
      });
    },
    resetAll() {
      this.selectedUserLevel = '';

      this.loadingInitialData = false;
      this.loading = false;
      this.saving = false;
      this.deleting = false;

      this.$store.commit(MENU_CONFIG_TYPES.MUTATIONS.RESET_OPTIONS_MENU_CONFIG_EDITED, { 
        configContext: this.configContext,
      });
    },
    async selectUserLevel(selectedUserLevel) {
      if(!selectedUserLevel) return;

      this.selectedUserLevel = selectedUserLevel;

      await this.findFCConfigByUserLevel(this.selectedUserLevel);
    },
    async findFCConfigByUserLevel(userLevel, forceReload = false) {
      // this method must be overridden
    },
    async onRestoreDefault() {
      await this.restoreDefault();
      await this.saveConfigEdited();

      this.$emit('onRestoreDefault');
    },
    async restoreDefault() {
      try {
        this.deleting = true;
        const { selectedUserLevel, } = this;
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.DELETE_OPTIONS_MENU_CONFIG, { 
          configContext: this.configContext,
          configId: this.configId, 
          userLevels: [selectedUserLevel],
        });
      } finally {
        this.deleting = false;
      }
    },
    async onSaveConfig() {
      await this.saveConfigEdited();
      this.$emit('onSaveConfig');
    },
    async saveConfigEdited() {
      try {
        this.saving = true;
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.SAVE_ALL_OPTIONS_MENU_CONFIG, { 
          configContext: this.configContext, 
        });
      } finally {
        this.saving = false;
      }
    },
  },
  components: {
    OptionMenuConfig,
    GhostLoading,
    Block,
  }
}
