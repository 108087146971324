export const MUTATION_PREFIX = 'CUSTOMER_FILTERS: ';
export const ACTIONS_PREFIX = 'CUSTOMER_FILTERS_ACTIONS_';
export const GETTERS_PREFIX = 'CUSTOMER_FILTERS_GETTERS_';

export default {
  MUTATIONS: {
    SET_CUSTOMER_FILTERS_SETUP: MUTATION_PREFIX + 'SET_CUSTOMER_FILTERS_SETUP',
    SET_CFDATA: MUTATION_PREFIX + 'SET_CFDATA',
    ADD_ROW: MUTATION_PREFIX + 'ADD_ROW',
    REMOVE_ROW: MUTATION_PREFIX + 'REMOVE_ROW',
    SET_FIRST_SELECTED: MUTATION_PREFIX + 'SET_FIRST_SELECTED',
    SET_SECOND_SELECTED: MUTATION_PREFIX + 'SET_SECOND_SELECTED',
    SET_THIRD_SELECTED: MUTATION_PREFIX + 'SET_THIRD_SELECTED',    
    ADD_SELECTED_CUSTOMER: MUTATION_PREFIX + 'ADD_SELECTED_CUSTOMER',    
    REMOVE_SELECTED_CUSTOMER: MUTATION_PREFIX + 'REMOVE_SELECTED_CUSTOMER',    
    RENEW_SELECTED_CUSTOMERS: MUTATION_PREFIX + 'RENEW_SELECTED_CUSTOMERS',    
    CREATE_ROLLE_PROPERTY: MUTATION_PREFIX + 'CREATE_ROLLE_PROPERTY',    
    SET_ROLLE: MUTATION_PREFIX + 'SET_ROLLE',    
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },

  ACTIONS: {
    RETRIEVE_CUSTOMER_FILTERS_SETUP: ACTIONS_PREFIX + 'RETRIEVE_CUSTOMER_FILTERS_SETUP',
  },

  GETTERS: {
    GET_CUSTOMER_FILTERS_SETUP: GETTERS_PREFIX + 'GET_CUSTOMER_FILTERS_SETUP',
    GET_SELECTED_CUSTOMERS: GETTERS_PREFIX + 'GET_SELECTED_CUSTOMERS',
    GET_CFDATA: GETTERS_PREFIX + 'GET_CFDATA',
  }
}