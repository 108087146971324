import { MyGoalCalculationAbstract } from './my-goal-calculations.abstract';
import { FinancialCalculator } from '../../retirementScenario/financialCalculator.js';

export class MyGoalCalculationRetirement extends MyGoalCalculationAbstract {
    id;
    age;
    monthlyRetirementWanted;
    monthlyBruttoIncome;
    monthlyRetirementPrivate;
    monthlyRetirementProx; // Default null
    retirementBegin; // Default 67

    /** Create new Retirement Calculation. MonthlyRetirementProx can be null if not known or can be calculated with calculateMonthlyRetirement() */
    constructor(age, monthlyRetirementWanted, monthlyBruttoIncome, monthlyRetirementPrivate, monthlyRetirementProx = null, retirementBegin = 67){
        super();
        this.age = age;
        this.monthlyRetirementWanted = monthlyRetirementWanted;
        this.monthlyBruttoIncome = monthlyBruttoIncome;
        this.monthlyRetirementPrivate = monthlyRetirementPrivate;
        this.monthlyRetirementProx = monthlyRetirementProx;
        this.retirementBegin = retirementBegin;
    }

    setId(id){
        this.id = id;
    }

    getId(){
        return this.id;
    }

    /** Calculates the proximate monthly Retirement from the brutto income 
     * See https://www.finanzrechner.org/finanztipps/rententipps-1-die-eigene-rente-berechnen/
    */
    calculateMonthlyRetirement(){
        return FinancialCalculator.calculateMonthlyRetirementProx(this.monthlyBruttoIncome, (this.retirementBegin - this.age));
    }
}

export const lifetimeArray = [
    { year: 2016, gender: { male: 78.36, female: 83.18 } },
    { year: 2015, gender: { male: 78.64, female: 83.44 } },
    { year: 2014, gender: { male: 78.66, female: 83.46 } },
    { year: 2013, gender: { male: 78.67, female: 83.47 } },
    { year: 2012, gender: { male: 78.68, female: 83.48 } },
    { year: 2011, gender: { male: 78.69, female: 83.49 } },
    { year: 2010, gender: { male: 78.69, female: 83.49 } },
    { year: 2009, gender: { male: 78.70, female: 83.50 } },
    { year: 2008, gender: { male: 78.71, female: 83.50 } },
    { year: 2007, gender: { male: 78.71, female: 83.51 } },
    { year: 2006, gender: { male: 78.72, female: 83.51 } },
    { year: 2005, gender: { male: 78.72, female: 83.52 } },
    { year: 2004, gender: { male: 78.73, female: 83.52 } },
    { year: 2003, gender: { male: 78.73, female: 83.53 } },
    { year: 2002, gender: { male: 78.74, female: 83.54 } },
    { year: 2001, gender: { male: 78.75, female: 83.54 } },
    { year: 2000, gender: { male: 78.75, female: 83.55 } },
    { year: 1999, gender: { male: 78.77, female: 83.56 } },
    { year: 1998, gender: { male: 78.79, female: 83.57 } },
    { year: 1997, gender: { male: 78.81, female: 83.58 } },
    { year: 1996, gender: { male: 78.83, female: 83.60 } },
    { year: 1995, gender: { male: 78.86, female: 83.61 } },
    { year: 1994, gender: { male: 78.88, female: 83.62 } },
    { year: 1993, gender: { male: 78.91, female: 83.63 } },
    { year: 1992, gender: { male: 78.93, female: 83.64 } },
    { year: 1991, gender: { male: 78.96, female: 83.65 } },
    { year: 1990, gender: { male: 78.98, female: 83.66 } },
    { year: 1989, gender: { male: 79.01, female: 83.67 } },
    { year: 1988, gender: { male: 79.03, female: 83.69 } },
    { year: 1987, gender: { male: 79.06, female: 83.70 } },
    { year: 1986, gender: { male: 79.09, female: 83.71 } },
    { year: 1985, gender: { male: 79.12, female: 83.73 } },
    { year: 1984, gender: { male: 79.15, female: 83.75 } },
    { year: 1983, gender: { male: 79.18, female: 83.77 } },
    { year: 1982, gender: { male: 79.21, female: 83.78 } },
    { year: 1981, gender: { male: 79.25, female: 83.80 } },
    { year: 1980, gender: { male: 79.29, female: 83.82 } },
    { year: 1979, gender: { male: 79.32, female: 83.85 } },
    { year: 1978, gender: { male: 79.37, female: 83.87 } },
    { year: 1977, gender: { male: 79.41, female: 83.89 } },
    { year: 1976, gender: { male: 79.45, female: 83.92 } },
    { year: 1975, gender: { male: 79.50, female: 83.95 } },
    { year: 1974, gender: { male: 79.55, female: 83.98 } },
    { year: 1973, gender: { male: 79.61, female: 84.01 } },
    { year: 1972, gender: { male: 79.66, female: 84.05 } },
    { year: 1971, gender: { male: 79.73, female: 84.09 } },
    { year: 1970, gender: { male: 79.80, female: 84.13 } },
    { year: 1969, gender: { male: 79.87, female: 84.18 } },
    { year: 1968, gender: { male: 79.95, female: 84.23 } },
    { year: 1967, gender: { male: 80.03, female: 84.29 } },
    { year: 1966, gender: { male: 80.13, female: 84.35 } },
    { year: 1965, gender: { male: 80.23, female: 84.42 } },
    { year: 1964, gender: { male: 80.34, female: 84.49 } },
    { year: 1963, gender: { male: 80.46, female: 84.56 } },
    { year: 1962, gender: { male: 80.59, female: 84.65 } },
    { year: 1961, gender: { male: 80.73, female: 84.74 } },
    { year: 1960, gender: { male: 80.88, female: 84.83 } },
    { year: 1959, gender: { male: 81.05, female: 84.94 } },
    { year: 1958, gender: { male: 81.22, female: 85.05 } },
    { year: 1957, gender: { male: 81.42, female: 85.16 } },
    { year: 1956, gender: { male: 81.62, female: 85.28 } },
    { year: 1955, gender: { male: 81.83, female: 85.42 } },
    { year: 1954, gender: { male: 82.05, female: 85.56 } },
    { year: 1953, gender: { male: 82.29, female: 85.70 } },
    { year: 1952, gender: { male: 82.54, female: 85.85 } },
    { year: 1951, gender: { male: 82.80, female: 86.00 } },
    { year: 1950, gender: { male: 83.07, female: 86.17 } },
    { year: 1949, gender: { male: 83.35, female: 86.34 } },
    { year: 1948, gender: { male: 83.64, female: 86.53 } },
    { year: 1947, gender: { male: 83.94, female: 86.72 } },
    { year: 1946, gender: { male: 84.25, female: 86.91 } },
    { year: 1945, gender: { male: 84.57, female: 87.12 } },
    { year: 1944, gender: { male: 84.89, female: 87.34 } },
    { year: 1943, gender: { male: 85.23, female: 87.56 } },
    { year: 1942, gender: { male: 85.58, female: 87.79 } },
    { year: 1941, gender: { male: 85.93, female: 88.03 } },
    { year: 1940, gender: { male: 86.30, female: 88.27 } },
    { year: 1939, gender: { male: 86.68, female: 88.53 } },
    { year: 1938, gender: { male: 87.08, female: 88.81 } },
    { year: 1937, gender: { male: 87.49, female: 89.10 } },
    { year: 1936, gender: { male: 87.92, female: 89.42 } },
    { year: 1935, gender: { male: 88.38, female: 89.77 } },
    { year: 1934, gender: { male: 88.86, female: 90.15 } },
    { year: 1933, gender: { male: 89.38, female: 90.56 } },
    { year: 1932, gender: { male: 89.91, female: 90.99 } },
    { year: 1931, gender: { male: 90.47, female: 91.45 } },
    { year: 1930, gender: { male: 91.07, female: 91.95 } },
    { year: 1929, gender: { male: 91.69, female: 92.48 } },
    { year: 1928, gender: { male: 92.34, female: 93.04 } },
    { year: 1927, gender: { male: 93.01, female: 93.63 } },
    { year: 1926, gender: { male: 93.70, female: 94.26 } },
    { year: 1925, gender: { male: 94.42, female: 94.92 } },
    { year: 1924, gender: { male: 95.16, female: 95.61 } },
    { year: 1923, gender: { male: 95.94, female: 96.34 } },
    { year: 1922, gender: { male: 96.72, female: 97.09 } },
    { year: 1921, gender: { male: 97.55, female: 97.88 } },
    { year: 1920, gender: { male: 98.40, female: 98.71 } },
    { year: 1919, gender: { male: 99.27, female: 99.54 } },
    { year: 1918, gender: { male: 100.14, female: 100.4 } },
    { year: 1917, gender: { male: 101.02, female: 101.24 } },
    { year: 1916, gender: { male: 101.91, female: 102.11 } }
];

export function getLifeExpectation(birthYear, gender) {
    if(birthYear === undefined || birthYear === NaN) return 85;
    let expactation = lifetimeArray.filter(lt => lt.year === birthYear);
    return expactation[0] ? 
        (gender === 'Herr' ? expactation[0].gender.male : expactation[0].gender.female)
        : 85;
}
