import { REGEX_COLOR_HEX } from "@/helpers/colors-helper.js";

export class ColorListWrapper {
  constructor() {
    this._colorList = [];
  }

  remove(color) {
    const index = this._colorList.findIndex(current => current === color.value || current === color);
    if (index >= 0) {
      this._colorList.splice(index, 1);
    }
  }

  add(color) {
    if (REGEX_COLOR_HEX.test(color?.trim?.())) {
      this._colorList.push(color);
    } else if (REGEX_COLOR_HEX.test(color?.value?.trim?.())) {
      this._colorList.push(color.value);
    } else if (color?.value?.matchAll) {
      const matches = color.value.match(/#(?:[0-9a-f]{3}){1,2}/gi)
      matches?.map(colorTrim => colorTrim?.trim?.())
        .filter(colorFilter => REGEX_COLOR_HEX.test(colorFilter))
        .forEach(colorAdd => this._colorList.push(colorAdd));
    }
  }

  set(colors) {
    try {
      if (Array.isArray(colors)) {
        this._colorList = [ ...colors ];
      } else {
        const colorList = JSON.parse(colors);
        if (Array.isArray(colorList)) {
          this._colorList = [ ...colorList ];
        }
      }
    } catch (e) {
      // empty block
    }
  }

  get colorString() {
    if (!this._colorList) {
      return '';
    }

    return JSON.stringify(this._colorList);
  }

  get colorInputChips() {
    return this._colorList?.map(item => ({ label: item, value: item }));
  }

  get colors() {
    return this._colorList;
  }
}