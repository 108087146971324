import Vue from 'vue';

import OTHER_PRODUCTS_TYPES from './types';
import { getInitialState } from './index';


export default {

  [OTHER_PRODUCTS_TYPES.MUTATIONS.RESET_DATA](state) {
    Object.assign(state, getInitialState());
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.SET_CURRENT_TYPE](state, payload) {
    Vue.set(state, 'currentType', payload);
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.GET_FILTER_COMBOS_SUCCESS](state, { type, data, }) {
    if(!type) return;
    Vue.set(state.filterCombos, type, { ...data, });
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_OTHER_PRODUCTS_SUCCESS](state, { type, data }) {
    if(!type) return;
    Vue.set(state.otherProducts, type, [ ...data ?? [] ]);
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.GET_COMBOS_SUCCESS](state, { type, data }) {
    if(!type) return;
    Vue.set(state.combos, type, { ...data });
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.GET_OTHER_PRODUCT_SUCCESS](state, { sprod }) {
    Vue.set(state, 'otherProduct', { ...sprod });
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.APPEND_OTHER_PRODUCT_EDITED](state, payload) {
    if(payload.id) {
      Vue.set(state, 'otherProductEdited', Object.assign({}, state.otherProductEdited, payload));
    }
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.RESET_OTHER_PRODUCT_EDITED](state) {
    Vue.set(state, 'otherProductEdited', null);
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.SET_SELECTED_SPROD_ID](state, payload) {
    Vue.set(state, 'selectedSprodId', payload);
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.RESET_OTHER_PRODUCT_STATE](state) {
    const type = state.currentType;
    if(type) {
      Vue.set(state.combos, type, {});
    }
    Vue.set(state, 'updates', []);
    Vue.set(state, 'documents', []);
    Vue.set(state, 'otherProductEdited', null);
    Vue.set(state, 'otherProduct', {});
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_UPDATES_SUCCESS](state, payload) {
    Vue.set(state, 'updates', [...payload]);
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_DOCUMENTS_SUCCESS](state, payload) {
    Vue.set(state, 'documents', [...payload]);
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.ADD_DOCUMENT_SUCCESS](state, { fileId, }) {
    const type = state.currentType;
    if(!type) return;
    Vue.set(state.combos[type], 'fileId', fileId);
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_APPOINTMENTS_SUCCESS](state, payload) {
    Vue.set(state, 'appointments', [...payload]);
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_BUCHUNGEN_SUCCESS](state, payload) {
    Vue.set(state, 'buchungen', [...payload]);
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.SET_SELECTED_BUCHUNG](state, payload) {
    Vue.set(state, 'selectedBuchung', { ...payload });
  },

  [OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_BUCHUNG_PROV_STRUKTUREN_SUCCESS](state, payload) {
    const selectedBuchung = state.selectedBuchung;
    if(selectedBuchung?.id) {
      Vue.set(state, 'buchungProvStrukturen', Object.assign({}, state.buchungProvStrukturen, {
        [selectedBuchung.id]: [...payload]
      }));
    }
  },

}
