<template>
  <div>
    <div class="box__container">
      <div class="box__title">Gebühren Finanzservicevertrag</div>

      <InputField label="Basismodul - SERVICEMODUL 1" type="currency"
        v-model="feePropertiesForm.satzBasismodul1" isComponentHalfSize 
        @change="dataChanged()" />
    </div>

    <div class="box__container">
      <div class="box__title">SERVICEMODUL 3</div>

      <InputToggleSwitch label="SERVICEMODUL 3" inLineLabel
        v-model="feePropertiesForm.hatModul3" 
        @input="dataChanged()" />
      
      <div>
        <div class="mb-3">Beratung und Betreuung auf Provisionsbasis</div>

        <InputField label="EUR 1,- bis EUR 49.999,99" type="currency"
          v-model="feePropertiesForm.betragModul31" isComponentHalfSize 
          @change="dataChanged()" />

        <InputField label="EUR 50.000,- bis EUR 99.999,99" type="currency"
          v-model="feePropertiesForm.betragModul32" isComponentHalfSize 
          @change="dataChanged()" />

        <InputField label="EUR 100.000,- bis EUR 199.999,99" type="currency"
          v-model="feePropertiesForm.betragModul33" isComponentHalfSize 
          @change="dataChanged()" />

        <div>ab EUR 200.000,-</div>
      </div>

      <div>
        <div class="box__title">zu berücksichtigende Depots</div>

        <div class="forms__input--half-size">
          <div class="row">
            <div class="col-12 col-sm-7">Gesellschaft</div>
            <div class="col-12 col-sm-5">Nummer</div>
          </div>

          <div v-for="lfdnr in MAX_DEPOTS" :key="lfdnr" class="row">
            <div class="col-12 col-sm-7">
              <ComboBox v-model="feePropertiesForm['depotGesellschaft' + lfdnr]"
                :values="feePropertiesCombos.gesellschaftValues" firstEmpty
                @change="dataChangedCombo('depotGesellschaft' + lfdnr, $event)" />
            </div>
            <div class="col-12 col-sm-5">
              <InputField type="text"
                v-model="feePropertiesForm['depotnummer' + lfdnr]"
                @change="dataChanged()" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box__container">
      <div class="box__title">SERVICEMODUL 4</div>

      <InputToggleSwitch label="SERVICEMODUL 4" inLineLabel
        v-model="feePropertiesForm.hatModul4" 
        @input="dataChanged()" />
      
      <div>
        <div class="mb-3">Beratung und Betreuung Servicegebühr (ab  50.000,-)</div>

        <InputField label="ab EUR 50.000,-" type="currency"
          v-model="feePropertiesForm.satzModul41" isComponentHalfSize 
          @change="dataChanged()" />

        <InputField label="mind." type="currency"
          v-model="feePropertiesForm.minBetragModul41" isComponentHalfSize 
          @change="dataChanged()" />

        <InputField label="ab EUR 100.000,-" type="currency"
          v-model="feePropertiesForm.satzModul42" isComponentHalfSize 
          @change="dataChanged()" />

        <InputField label="ab EUR 250.000,-" type="currency"
          v-model="feePropertiesForm.satzModul43" isComponentHalfSize 
          @change="dataChanged()" />

        <InputField label="ab EUR 500.000,-" type="currency"
          v-model="feePropertiesForm.satzModul44" isComponentHalfSize 
          @change="dataChanged()" />

        <InputField label="ab EUR 1 Mio" type="currency"
          v-model="feePropertiesForm.satzModul45" isComponentHalfSize 
          @change="dataChanged()" />
      </div>

      <div>
        <div class="box__title">zu berücksichtigende Depots</div>

        <div class="forms__input--half-size">
          <div class="row">
            <div class="col-12 col-sm-7">Gesellschaft</div>
            <div class="col-12 col-sm-5">Nummer</div>
          </div>

          <div v-for="lfdnr in MAX_DEPOTS" :key="lfdnr" class="row">
            <div class="col-12 col-sm-7">
              <ComboBox v-model="feePropertiesForm['depotGesellschaft4' + lfdnr]"
                :values="feePropertiesCombos.gesellschaftValues" firstEmpty
                @change="dataChangedCombo('depotGesellschaft4' + lfdnr, $event)" />
            </div>
            <div class="col-12 col-sm-5">
              <InputField type="text"
                v-model="feePropertiesForm['depotnummer4' + lfdnr]"
                @change="dataChanged()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';

import feePropertiesStepMixin from '../fee-properties-step-mixin';

const MAX_DEPOTS = 5;

export default {
  mixins: [feePropertiesStepMixin],
  data() {
    return {
      MAX_DEPOTS,
      feePropertiesForm: {
        satzBasismodul1: null,
        hatModul3: null,
        betragModul31: null,
        betragModul32: null,
        betragModul33: null,
        depotGesellschaft1: null,
        depotnummer1: null,
        depotGesellschaft2: null,
        depotnummer2: null,
        depotGesellschaft3: null,
        depotnummer3: null,
        depotGesellschaft4: null,
        depotnummer4: null,
        depotGesellschaft5: null,
        depotnummer5: null,
        hatModul4: null,
        satzModul41: null,
        minBetragModul41: null,
        satzModul42: null,
        satzModul43: null,
        satzModul44: null,
        satzModul45: null,
        depotGesellschaft41: null,
        depotnummer41: null,
        depotGesellschaft42: null,
        depotnummer42: null,
        depotGesellschaft43: null,
        depotnummer43: null,
        depotGesellschaft44: null,
        depotnummer44: null,
        depotGesellschaft45: null,
        depotnummer45: null,
      },
    };
  },
  components: {
    InputField,
    InputToggleSwitch,
    ComboBox,
  },
}
</script>
