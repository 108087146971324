import TAN_TYPES from './types';

export default {
  [TAN_TYPES.GETTERS.GET_COMDIRECT_SESSION](state) {
    return state.getComdirectSession;
  },

  [TAN_TYPES.GETTERS.IS_COMDIRECT_TAN_REQUIRED](state){
    return state.isComdirectTanRequired;
  },
}