<template>
  <div class="label resleinzahlungen">
    <div class="result">{{getEinzahlungen}} €</div>
    <div class="truelabel">Einzahlungen</div>
  </div>
</template>

<script>
//import store from "./../../store.js";
export default {
  name: "ResLEinzahlungen",
  computed: {
    getEinzahlungen() {
      return this.$store.getters.getEinzahlungen;
    }
  },
};
</script>

<style scoped>
.resleinzahlungen {
  grid-area: resleinzahlungen;
}

</style>