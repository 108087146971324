var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.text)) } }),
    _c(
      "div",
      { staticClass: "base-modal__footer" },
      [
        _c(
          "BaseButton",
          {
            staticClass: "base-modal__footer--button",
            attrs: { isSecondary: "", disabled: _vm.loading },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Abbrechen")]
        ),
        _c(
          "BaseButton",
          {
            staticClass: "base-modal__footer--button",
            attrs: { disabled: _vm.loading },
            on: {
              click: function($event) {
                return _vm.onConfirm()
              }
            }
          },
          [
            _vm._v(" Akzeptieren "),
            _vm.loading ? _c("AnimatedSpinner") : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }