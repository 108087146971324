export const MUTATION_PREFIX = 'TEMPLATES_EDIT: ';
export const ACTIONS_PREFIX = 'TEMPLATES_EDIT_';
export const GETTERS_PREFIX = 'TEMPLATES_EDIT_';

export default {
  MUTATIONS: {
    GET_TEMPLATES_TREE: MUTATION_PREFIX + 'GET_TEMPLATES_TREE',
    GET_TEMPLATES: MUTATION_PREFIX + 'GET_TEMPLATES',
    GET_STANDARD_TEMPLATE: MUTATION_PREFIX + 'GET_STANDARD_TEMPLATE',
    GET_PREVIEW: MUTATION_PREFIX + 'GET_PREVIEW',
    GET_LETTER_TEMPLATES_HTML: MUTATION_PREFIX + 'GET_LETTER_TEMPLATES_HTML',
    GET_LETTER_TEMPLATES_WORD: MUTATION_PREFIX + 'GET_LETTER_TEMPLATES_WORD',
    GET_LETTER_TEMPLATE_EDIT: MUTATION_PREFIX + 'GET_LETTER_TEMPLATE_EDIT',
    GET_WORD_TEMPLATE_EDIT: MUTATION_PREFIX + 'GET_WORD_TEMPLATE_EDIT',
    GET_LETTER_CATEGORIES: MUTATION_PREFIX + 'GET_LETTER_CATEGORIES',
    COPY_LETTER_TEMPLATE: MUTATION_PREFIX + 'COPY_LETTER_TEMPLATE',
    DELETE_WORD_TEMPLATE: MUTATION_PREFIX + 'DELETE_WORD_TEMPLATE',
    DELETE_TEMPLATE: MUTATION_PREFIX + 'DELETE_TEMPLATE',
    SAVE_LETTER_TEMPLATE: MUTATION_PREFIX + 'SAVE_LETTER_TEMPLATE',
    SAVE_WORD_LETTER_TEMPLATE: MUTATION_PREFIX + 'SAVE_WORD_LETTER_TEMPLATE',
    UPDATE_TEMPLATE_GRANT: MUTATION_PREFIX + 'UPDATE_TEMPLATE_GRANT',
    UPDATE_TEMPLATE_VISIBLE: MUTATION_PREFIX + 'UPDATE_TEMPLATE_VISIBLE',
    UPDATE_TEMPLATE_TAGS: MUTATION_PREFIX + 'UPDATE_TEMPLATE_TAGS',
    GET_BACKGROUND_TEMPLATE: MUTATION_PREFIX + 'GET_BACKGROUND_TEMPLATE',
    DELETE_FILE_BACKGROUND_TEMPLATE: MUTATION_PREFIX + 'DELETE_FILE_BACKGROUND_TEMPLATE',
    SET_HTML_SHORTCUTS: MUTATION_PREFIX + 'SET_HTML_SHORTCUTS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_TEMPLATES_TREE: ACTIONS_PREFIX + 'GET_TEMPLATES_TREE',
    GET_TEMPLATES: ACTIONS_PREFIX + 'GET_TEMPLATES',
    GET_STANDARD_TEMPLATE: ACTIONS_PREFIX + 'GET_STANDARD_TEMPLATE',
    GET_PREVIEW: ACTIONS_PREFIX + 'GET_PREVIEW',
    SAVE_TEMPLATE_SETUP: ACTIONS_PREFIX + 'SAVE_TEMPLATE_SETUP',
    SAVE_TEMPLATE_GRANT: ACTIONS_PREFIX + 'SAVE_TEMPLATE_GRANT',
    RESET: ACTIONS_PREFIX + 'RESET',
    GET_LETTER_TEMPLATES_HTML: ACTIONS_PREFIX + 'GET_LETTER_TEMPLATES_HTML',
    GET_LETTER_TEMPLATES_WORD: ACTIONS_PREFIX + 'GET_LETTER_TEMPLATES_WORD',
    GET_LETTER_TEMPLATE_EDIT: ACTIONS_PREFIX + 'GET_LETTER_TEMPLATE_EDIT',
    GET_WORD_TEMPLATE_EDIT: ACTIONS_PREFIX + 'GET_WORD_TEMPLATE_EDIT',
    GET_LETTER_CATEGORIES: ACTIONS_PREFIX + 'GET_LETTER_CATEGORIES',
    COPY_LETTER_TEMPLATE: ACTIONS_PREFIX + 'COPY_LETTER_TEMPLATE',
    DELETE_WORD_TEMPLATE: ACTIONS_PREFIX + 'DELETE_WORD_TEMPLATE',
    DELETE_TEMPLATE: ACTIONS_PREFIX + 'DELETE_TEMPLATE',
    SAVE_LETTER_TEMPLATE: ACTIONS_PREFIX + 'SAVE_LETTER_TEMPLATE',
    SAVE_WORD_LETTER_TEMPLATE: ACTIONS_PREFIX + 'SAVE_WORD_LETTER_TEMPLATE',
    CHANGE_TEMPLATE_VISIBILITY: ACTIONS_PREFIX + 'CHANGE_TEMPLATE_VISIBILITY',
    GET_BACKGROUND_TEMPLATE: ACTIONS_PREFIX + 'GET_BACKGROUND_TEMPLATE',
    SAVE_BACKGROUND_TEMPLATE: ACTIONS_PREFIX + 'SAVE_BACKGROUND_TEMPLATE',
    DELETE_BACKGROUND_TEMPLATE: ACTIONS_PREFIX + 'DELETE_BACKGROUND_TEMPLATE',
    GET_HTML_SHORTCUTS: ACTIONS_PREFIX + 'GET_HTML_SHORTCUTS',
    SAVE_HTML_SHORTCUTS: ACTIONS_PREFIX + 'SAVE_HTML_SHORTCUTS',
    DELETE_HTML_SHORTCUTS: ACTIONS_PREFIX + 'DELETE_HTML_SHORTCUTS',
  },
  GETTERS: {
    GET_TEMPLATES_TREE: GETTERS_PREFIX + 'GET_TEMPLATES_TREE',
    GET_TEMPLATES: GETTERS_PREFIX + 'GET_TEMPLATES',
    GET_STANDARD_TEMPLATE: GETTERS_PREFIX + 'GET_STANDARD_TEMPLATE',
    GET_PREVIEW: GETTERS_PREFIX + 'GET_PREVIEW',
    GET_LETTER_TEMPLATES: GETTERS_PREFIX + 'GET_LETTER_TEMPLATES',
    GET_LETTER_TEMPLATE_EDIT: GETTERS_PREFIX + 'GET_LETTER_TEMPLATE_EDIT',
    GET_WORD_TEMPLATE_EDIT: GETTERS_PREFIX + 'GET_WORD_TEMPLATE_EDIT',
    GET_LETTER_CATEGORIES: GETTERS_PREFIX + 'GET_LETTER_CATEGORIES',
    GET_BACKGROUND_TEMPLATE: GETTERS_PREFIX + 'GET_BACKGROUND_TEMPLATE',
    COPY_LETTER_TEMPLATE: GETTERS_PREFIX + 'COPY_LETTER_TEMPLATE',
    DELETE_WORD_TEMPLATE: GETTERS_PREFIX + 'DELETE_WORD_TEMPLATE',
    DELETE_TEMPLATE: GETTERS_PREFIX + 'DELETE_TEMPLATE',
    SAVE_LETTER_TEMPLATE: GETTERS_PREFIX + 'SAVE_LETTER_TEMPLATE',
    SAVE_WORD_LETTER_TEMPLATE: GETTERS_PREFIX + 'SAVE_WORD_LETTER_TEMPLATE',
    HTML_SHORTCUTS: GETTERS_PREFIX + 'HTML_SHORTCUTS',
  },
}
