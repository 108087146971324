<template>
	<CoreCard v-if="isContainerRendered" ref="coreCard" :headers="headers" :rows="rows" :showToolbar="showToolbar" :listView="listView">
		<template v-if="showTitle" v-slot:header>
			{{chartTitle}}
		</template>
		<template v-slot:alternative>
		</template>
    <div class="content">
      <div>
		    <Chart :data="chartData" type="donut" :isDataLabels="false" :isLegend="false" :isTooltip="false" :colors="customColors" :mixedColors="false"></Chart>
        <div class="legend" v-if="chartData && chartData.data && chartData.data[0] && chartData.data[1]">
          <!-- <div v-for="(item, index) of chartData.data" class="legend-item" :key="index" :style="{color: getColor(index)}">
            &bull; {{item.label}}: {{item.value}}€
          </div> -->
          <div class="legend-item" v-if="!colorsGrey" :style="{color: getColor(0)}">
            &bull; {{chartData.data[0].label}}: {{formatNumber(chartData.data[0].value)}}
          </div>
          <div class="legend-item" v-if="!colorsGrey" :style="{color: getColor(1)}">
            &bull; {{chartData.data[1].label}}: {{formatNumber(chartData.data[1].value)}}
          </div>
          <div class="legend-item" v-if="colorsGrey" :style="{color: getColor(0)}">
            &bull; {{keys[0]}}: {{formatNumber(chartData.data[1].value)}}
          </div>
        </div>
      </div>
    </div>
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard'
import Chart from '../../charts/Chart.vue'
import { getColorsMixedContrastDecreasing } from '../../../helpers/colors-helper';
import { formatNumber } from '@/helpers/number-formatter.js';
import cardMixin from '../CoreCard/card-mixin';
import { TextColumn, NumberColumn } from '@/components/table2/table_util';


export default {
  mixins: [cardMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    option: {
      type: String,
      default: [],
    },
    title: {
      type: String,
      default: [],
    },
  },
  data: function() {
    return {
      isContainerRendered: false,
      colorsGrey: false,
      colorsApex: getColorsMixedContrastDecreasing(),
    };
  },

  computed: {
    chartName: function() {
      return this.propsData && this.propsData.option || this.option;
    },
    chartNameClean: function() {
      return this.chartName; // .replace(/[0-9]/g, '').replace('(', '').replace(')', '').replace(/\./g, '');
    },
    customColors: function() {
      return this.propsData.customColors;
    },
    chartTitle: function() {
      return this.propsData && this.propsData.title || this.propsData && this.propsData.props && this.propsData.props.title || this.title || this.chartName;
    },
    initData: function() {
      return this.propsData && this.propsData.data;
    },
    chartData: function() {
      if (!this.initData) {return {name: '', data: []}}
      let all = this.propsData.props && this.propsData.props.all || 0;
      // all = this.initData[this.chartNameClean].data[0].value + this.initData[this.chartNameClean].data[1].value > all ? this.initData[this.chartNameClean].data[0].value + this.initData[this.chartNameClean].data[1].value : all;
      let resultData = [];
      if (this.initData[this.chartNameClean]) {
        if (Object.keys(this.initData[this.chartNameClean]).every(k => +this.initData[this.chartNameClean][k] === 0)) {
            resultData.push({
              label: ``,
              value: 1
            });
            this.colorsGrey = true;
        } else {
          Object.keys(this.initData[this.chartNameClean]).map(key => {
            resultData.push({
              label: `${key}`,
              value: +this.initData[this.chartNameClean][key] // all - +this.initData[this.chartNameClean][key]
            })
          });
          this.colorsGrey = false;
        }
      }
      let result = {
        name: this.chartName,
        data: resultData
      };
      return result;
    },
    keys() {
      return Object.keys(this.initData[this.chartNameClean]);
    },
    headers() {
        return {
            lockedLeft: [
                TextColumn("label", ""),
            ],
            lockedRight: [
                NumberColumn("value", ""),
            ],
        };
    },
    rows() {
        return this.chartData.data;
    },
  },

  methods: {
    getColor(index) {
      return this.colorsApex[index];
    },
    formatNumber(v) {
      return formatNumber(v);
    }
  },

  mounted: function () {
    this.isContainerRendered = false;
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },

  components: {
    CoreCard,
    Chart
  },
}
</script>

<style scoped>
/* .content {
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 350px;
  position: relative;
} */
</style>