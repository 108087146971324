<template>
  <div>
    <BaseButton v-if="editMode" @click="neueZahlung()" class="mb-16px">Neue Zahlung hinzufügen</BaseButton>

    <div>
      <Table
        v-if="tableData.records.length"
        tableId="3cd44c85-12b2-4625-b32a-520e4bf6564a"
        :tableData="tableData"
        :actions="tableActions"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :rowsPerPage="tableData.records.length"
        @execute-action="handleTableAction($event)"
      />
      <div v-else>Keine Daten</div>
    </div>

    <BaseModal ref="zahlungModal" :showDefaultButtons="false" @close="cancelZahlungEditing()">
      <template #modalTitle>{{ zahlungInserting === true ? 'Neue Zahlung' : 'Zahlung Bearbeiten' }}</template>
      <template #default>
        <InputField label="Zugehöriger Schadensfall" type="text" :disabled="true" :value="sparte" />
        <ComboBox label="Zugehöriger Beteiligter" v-model="zahlung_form.schaden_beteiligter_id" :values="driversValues" 
          :firstEmpty="true" :validateUntouched="true" />
        <ComboBox label="Zahlgrund" v-model="zahlung_form.zahlgrund" :values="zahlungsGrundValues" :firstEmpty="true" />
        <InputField label="Betrag (in EUR)" v-model="zahlung_form.zahlbetrag" />
        <DatePickerField label="Datum" v-model="zahlung_form.zahldat" />
        <ComboBox label="Zahlungsart" v-model="zahlung_form.zahlweg" :values="zahlungsWebSelectionValues" :firstEmpty="true" />
        <InputField label="Bemerkung" v-model="zahlung_form.bemerkung" />
      </template>
      <template #footer>
        <BaseButton @click="closeZahlungModal()" isSecondary :disabled="loading" class="mr-16px">Abbrechen</BaseButton>
        <BaseButton @click="zahlungSpeichern()" :disabled="loading">
          Zahlung speichern <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>

    <BaseModal ref="zahlungDeleteModal" modalTitle="Eintrag löschen" 
      @onConfirmButton="deleteZahlung(zahlungToDelete)" @close="clearZahlungToDelete()">
      <div v-if="zahlungToDelete">
        "{{zahlungToDelete.zahldat}} - {{zahlungToDelete.zahlbetrag}} {{zahlungToDelete.waehrung}}" wirklich löschen?
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { PhPencilLine, PhTrash } from 'phosphor-vue';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";

import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";

import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import Table from '@/components/table/Table.vue';

const TABLE_HEADERS = {
  zahldat: {
    label: 'Datum',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'zahldat',
    visible: true,
  },
  zahlbetrag: {
    label: 'Betrag',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'zahlbetrag',
    visible: true,
  },
  waehrung: {
    label: 'Waehrung',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'waehrung',
    visible: true,
  },
  beschreibung: {
    label: 'Zugehöriger Beteiligter',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'beschreibung',
    visible: true,
  },
  zahlgrund: {
    label: 'Zahlungsgrund',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'zahlgrund',
    visible: true,
  },
  zahlweg: {
    label: 'Zahlungsweg',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'zahlweg',
    visible: true,
  },
  bemerkung: {
    label: 'Bermerkung',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'bemerkung',
    visible: true,
  },
};
const TABLE_HEADER_ACTION = {
  actions: {
    label: 'Aktionen',
    key: 'actions',
    dataType: 'tableAction',
    visible: true,
  },
};
const TABLE_ACTIONS = [{
  legend: {
    icon: PhPencilLine,
    index: 1,
    key: 'EDIT',
    label: 'Bearbeiten',
  }
}, {
  legend: {
    icon: PhTrash,
    index: 3,
    key: 'DELETE',
    label: 'Löschen',
  }
}];


export default {
  mixins: [validator],
  props: {
    schadenId: {
      type: [String, Number],
    },
    editMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      zahlung_form: {},
      zahlungToDelete: null,
      zahlungEditing: false,
      zahlungInserting: false,
      tableActions: TABLE_ACTIONS,
    };
  },
  computed: {
    ...mapGetters({
      schadensmeldung: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
      detailsData: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
      configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
    }),
    zahlungen() {
      const zahlungen = this.schadensmeldung?.zahlungen || [];
      return [...zahlungen];
    },
    tableHeaders() {
      return {
        ...TABLE_HEADERS,
        ...(this.editMode ? TABLE_HEADER_ACTION : {})
      };
    },
    tableData() {
      return {
        headers: this.tableHeaders,
        records: this.zahlungen,
      };
    },
    driversValues() {
      const drivers = this.schadensmeldung?.beteiligte || [];
      const driversValues = drivers.map((dr) => ({
        label: `${dr?.nachname}, ${dr?.vorname}`,
        value: dr.id,
      }));

      return driversValues;
    },
    sparte(){
       return this.configData?.sparte || '';
    },
    zahlungsGrundValues() {
      const zahlungsGrund = this.schadensmeldung?.zahlungsGrund || [];
      const zahlungsGrundValues = zahlungsGrund.map((gr) => ({
        label: gr[1],
        value: gr[0],
      }));

      return zahlungsGrundValues;
    },
    wahrungenValues() {
      const wahrungen = this.schadensmeldung?.wahrungen || [];
      const wahrungenValues = wahrungen.map((wa) => ({
        label: wa,
        value: wa,
      }));

      return wahrungenValues;
    },
    zahlungsWebSelectionValues() {
      const zahlungsWebSelection = this.schadensmeldung?.zahlungsWebSelection;
      const zahlungsWebSelectionValues = zahlungsWebSelection?.map((zws) => ({
        label: zws[1],
        value: zws[0],
      }));

      return zahlungsWebSelectionValues;
    },
  },
  methods: {
    openZahlungModal() {
      this.$refs.zahlungModal.open();
    },
    closeZahlungModal() {
      this.$refs.zahlungModal.close();
    },
    openZahlungDeleteModal(whatZahlung) {
      this.zahlungToDelete = whatZahlung;
      this.$refs.zahlungDeleteModal.open();
    },
    getNewZahlung() {
      return {
        id: null,
        zahlbetrag: "",
        waehrung: "EUR",
        zahldat: "",
        zahlgrund: "",
        zahlweg: "",
        schaden_beteiligter: {
          id: null
        },
        bemerkung: ""
      };
    },
    neueZahlung() {
      this.zahlung_form = this.getNewZahlung();
      this.zahlungEditing = false;
      this.zahlungInserting = true;

      this.openZahlungModal();
    },
    editZahlung(whatZahlung) {
      const zahlung_form = Object.assign({}, whatZahlung);
      zahlung_form.schaden_beteiligter_id = zahlung_form?.schaden_beteiligter?.id;
      if(zahlung_form.zahldat) {
        zahlung_form.zahldat = dayjs(zahlung_form.zahldat, 'DD.MM.YYYY').toDate();
      }
      this.zahlung_form = zahlung_form;
      this.zahlungEditing = true;
      this.zahlungInserting = false;

      this.openZahlungModal();
    },
    deleteZahlung(whatZahlung) {
      this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.DELETE_ZAHLUNG, {
        schadenId: this.schadenId,
        zahlungId: whatZahlung.id
      });
    },
    clearZahlungToDelete() {
      this.zahlungToDelete = null;
    },
    sendFormZahlung() {
      this.loading = true;
      const zahlung_form = this.zahlung_form;
      zahlung_form.schaden_beteiligter = {
        id: this.zahlung_form.schaden_beteiligter_id,
      };
      if(zahlung_form.zahldat) {
        zahlung_form.zahldat = dayjs(zahlung_form.zahldat).format('DD.MM.YYYY');
      }
      this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.SEND_ZAHLUNG, zahlung_form)
        .then(() => this.closeZahlungModal())
        .catch((error) => console.log(error))
        .finally(() => this.loading = false);
    },
    zahlungSpeichern() {
      if(!this.zahlung_form?.schaden_beteiligter_id) {
        return ;
      }

      this.zahlungEditing = false;
      this.zahlungInserting = false;
      this.sendFormZahlung();
    },
    cancelZahlungEditing() {
      this.zahlungEditing = false;
      this.zahlungInserting = false;
      this.zahlung_form = this.getNewZahlung();
    },
    handleTableAction(event) {
      if(event.action?.legend?.key === 'EDIT') {
        this.editZahlung(event?.value);
      } else if(event.action?.legend?.key === 'DELETE') {
        this.openZahlungDeleteModal(event?.value);
      }
    },
  },
  validators: {
    zahlung_form: {
      schaden_beteiligter_id: [required()],
    },
  },
  components: {
    BaseButton,
    InputField,
    ComboBox,
    DatePickerField,
    BaseModal,
    AnimatedSpinner,
    Table,
  },
}
</script>
