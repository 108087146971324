<template>
  <div>
    <InputToggleSwitch v-model="showOnlyMenuEntry" label="Show only menu entries" inLineLabel/>
    <InputToggleSwitch v-model="allCollapsed" label="All Collapsed" inLineLabel/>
    <RouterTree :treeData="menuList" :allCollapsed="allCollapsed"/>
  </div>
</template>

<script>
import MENU_TYPES from '@/store/menu/types';
import RouterTree from '@/components/core/router-viewer/RouterTree.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'

// TODO refactor to work with the new menu structure
export default {
  computed: {
    menuList() {
      return this.$store.getters[MENU_TYPES.GETTERS.PRIMARY_MENU];
    },
    allMenuWithoutIconsAsList() {
      const recursivelyGet = function(node = [], deep = 0) {
        const test = []

        for (const item of node) {
          test.push('.'.repeat(deep * 4 ) + item?.label + ';' + !!(item?.component) )

          if (item?.subMenu?.length) {
            test.push(...recursivelyGet(item?.subMenu, deep + 1))
          }

          
        }

        return test;
      }

      return recursivelyGet(this.menuList)
    }
  },
  data() {
    return {
      showOnlyMenuEntry: true,
      allCollapsed: false
    }
  },
  components: {
    RouterTree,
    InputToggleSwitch,
  }
}
</script>

<style scoped>

</style>