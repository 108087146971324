var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputRadioBoxGroup", {
        attrs: {
          validateUntouched: true,
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          values: _vm.values
        },
        on: {
          input: function($event) {
            return _vm.updateStore("risikoneigung", "riskLevel", $event)
          }
        },
        model: {
          value: _vm.riskLevel,
          callback: function($$v) {
            _vm.riskLevel = $$v
          },
          expression: "riskLevel"
        }
      }),
      _c("hr"),
      _c("InputTextArea", {
        attrs: {
          label:
            "Beschreibung der Anlageziele / Anlagedauer / Verlusttragfähigkeit und Risikoneigung mit eigenen Worten falls vom Kunden gewünscht und obige Fallgruppen nicht ausreichend sind",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          change: function($event) {
            return _vm.updateStore(
              "risikoneigung",
              "annotationRisikoneigung",
              $event
            )
          }
        },
        model: {
          value: _vm.annotationRisikoneigung,
          callback: function($$v) {
            _vm.annotationRisikoneigung = $$v
          },
          expression: "annotationRisikoneigung"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }