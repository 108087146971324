var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasProperTemplate
    ? _c(_vm.currentHomeTemplate, { tag: "component" }, [_vm._t("default")], 2)
    : _c("HomeTemplateGhost")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }