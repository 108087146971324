import PORTFOLIO_TYPES from './types';

export default {
  [PORTFOLIO_TYPES.MUTATIONS.GET_FAILTURE](state, payload) {
    // Object.assign(state, getInitialState())
  },
  [PORTFOLIO_TYPES.MUTATIONS.GET_DEPOT_CONFIG_SUCCESS](state, payload) {
    state.config = {
      ...payload,
    };
  },
  [PORTFOLIO_TYPES.MUTATIONS.GET_HISTORICAL_PERFORMANCE_DETAILS_SUCCESS](state, payload) {
    state.historicalPerformanceDetails = {
      ...payload,
    };
  },
  [PORTFOLIO_TYPES.MUTATIONS.GET_REALISED_PROFIT_SUCCESS](state, payload) {
    state.realisedProfit = {
      data: payload.data,
      id: payload.id
    }
  },
  [PORTFOLIO_TYPES.MUTATIONS.GET_HISTORICAL_PERFORMANCE_SUCCESS](state, payload) {
    state.historicalPerformance = {
      data: {
        ...state.historicalPerformance.data,
        ...payload.data,
        [payload.id]: {
          ...payload.data,
          isLoaded: true,
        },
        isLoaded: true,
      }
    }
  },
  [PORTFOLIO_TYPES.MUTATIONS.GET_PERFORMANCE_FILTERED_SUCCESS](state, payload) {
    state.performanceFiltered = {
      collection: {
        ...payload.data,
      },
      id: payload.id
    }
  },
  [PORTFOLIO_TYPES.MUTATIONS.SET_IS_LOADED](state, payload) {
    state.isLoaded = payload;
  },
}

const TableNames = {
  historicalPerformance: 'historicalPerformance',
  realisedProfit: 'realisedProfit',
  closedFunds: 'closedFunds',
  bankAccount: 'bankAccount',
  historicalAction: 'historicalAction',
}

export function prepareTableMeta(data, tableName = '') {
  switch (tableName) {
    case TableNames.historicalPerformance:
      break;
    case TableNames.realisedProfit:
      break;
    case TableNames.closedFunds:
      data.meta = {
        wertKey: 'Betrag',
        headers: [
          {key: 'Gesellschaft', label: 'Gesellschaft'},
          {key: 'Name', label: 'Name'},
          {key: 'Betrag', label: 'Betrag'}
        ]
      };
      break;
    case TableNames.bankAccount:
      data.meta = {
        wertKey: 'Kontostand',
        headers: [
          {key: 'Gesellschaft', label: 'Bank'},
          {key: 'Kontonummer', label: 'Kontonummer'},
          {key: 'Kontostand', label: 'Kontostand'}
        ]
      };
      break;
    case TableNames.historicalAction:
      break;
  }
  return data;
}