var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              title: _vm.$appNavigation.currentMenu.label,
              id: _vm.$appNavigation.currentOptionMenuId,
              actions: _vm.headerActions
            },
            on: {
              "action-ADD-NEW": function($event) {
                return _vm.addShortcut()
              }
            }
          },
          _vm.$listeners
        )
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.pageLoading && _vm.rows.length
            ? _c("Table", {
                ref: "tableResults",
                attrs: {
                  tableId: "aec0b9a7-812c-48f2-9889-6e9b64ca283c",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20
                },
                on: {
                  "action-EDIT": _vm.handleEdit,
                  "action-DELETE": _vm.handleDelete
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "forBroker",
                      fn: function(shortcut) {
                        return [
                          _c("InputToggleSwitch", {
                            on: {
                              input: function($event) {
                                return _vm.changeShortcut(
                                  shortcut,
                                  $event,
                                  "forBroker"
                                )
                              }
                            },
                            model: {
                              value: shortcut.forBroker,
                              callback: function($$v) {
                                _vm.$set(shortcut, "forBroker", $$v)
                              },
                              expression: "shortcut.forBroker"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2614108847
                )
              })
            : _vm.pageLoading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  content: "Keine Shortcuts vorhanden"
                }
              })
        ],
        1
      ),
      _vm.showEditModal
        ? _c("EditorShortcutsEditModal", {
            attrs: { shortcut: _vm.shortcut },
            on: {
              close: _vm.closeAddNewPlaceholder,
              changeShortcutValue: _vm.changeShortcutValue
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }