var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Nebentätigkeiten und sonstige Funktionen")
        ]),
        _c("FormDivider"),
        _c("FormLabel", {
          attrs: {
            label:
              "Anforderung: jährliche Meldepflicht sämtlicher Nebentätigkeiten, welche nicht dem WAG 2018 unterliegen"
          }
        }),
        _c("InputRadioBoxGroup", {
          attrs: {
            value: _vm.form.INPUT_SONST_BEST1,
            values: _vm.meldepflichtValues,
            labelClass: "font-bold",
            disabled: _vm.disabledStatusOK
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_SONST_BEST1")
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Vermittlung von Lebensversicherungen",
            inLineLabel: true,
            isComponentHalfSize: "",
            disabled: !_vm.form.INPUT_SONST_BEST1 || _vm.disabledStatusOK,
            value: _vm.form.INPUT_VERMITTLUNG_LEBENSVERS
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_LEBENSVERS")
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "seit",
            value: _vm.form.INPUT_VERMITTLUNG_LEBENSVERS_DATUM,
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_LEBENSVERS ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_LEBENSVERS_DATUM"
              )
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Im Ausmaß von wie vielen Stunden im Jahr",
            value: _vm.form.INPUT_VERMITTLUNG_LEBENSVERS_DAUER,
            isInteger: "",
            inLineLabel: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_LEBENSVERS ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_LEBENSVERS_DAUER"
              )
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Vermittlung von Sachversicherungen",
            inLineLabel: true,
            isComponentHalfSize: "",
            disabled: !_vm.form.INPUT_SONST_BEST1 || _vm.disabledStatusOK,
            value: _vm.form.INPUT_VERMITTLUNG_SACHVERS
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_SACHVERS")
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "seit",
            value: _vm.form.INPUT_VERMITTLUNG_SACHVERS_DATUM,
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_SACHVERS ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_SACHVERS_DATUM"
              )
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Im Ausmaß von wie vielen Stunden im Jahr",
            value: _vm.form.INPUT_VERMITTLUNG_SACHVERS_DAUER,
            isInteger: "",
            inLineLabel: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_SACHVERS ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_SACHVERS_DAUER"
              )
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Vermittlung von Finanzierung",
            inLineLabel: true,
            isComponentHalfSize: "",
            value: _vm.form.INPUT_VERMITTLUNG_FINANZ,
            disabled: !_vm.form.INPUT_SONST_BEST1 || _vm.disabledStatusOK
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_FINANZ")
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "seit",
            value: _vm.form.INPUT_VERMITTLUNG_FINANZ_DATUM,
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_FINANZ ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_FINANZ_DATUM")
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Im Ausmaß von wie vielen Stunden im Jahr",
            value: _vm.form.INPUT_VERMITTLUNG_FINANZ_DAUER,
            isInteger: "",
            inLineLabel: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_FINANZ ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_FINANZ_DAUER")
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Vermittlung von Nachrangdarlehen",
            inLineLabel: true,
            isComponentHalfSize: "",
            value: _vm.form.INPUT_VERMITTLUNG_DARLEHNEN,
            disabled: !_vm.form.INPUT_SONST_BEST1 || _vm.disabledStatusOK
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_DARLEHNEN")
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "seit",
            value: _vm.form.INPUT_VERMITTLUNG_DARLEHNEN_DATUM,
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_DARLEHNEN ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_DARLEHNEN_DATUM"
              )
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Im Ausmaß von wie vielen Stunden im Jahr",
            value: _vm.form.INPUT_VERMITTLUNG_DARLEHNEN_DAUER,
            isInteger: "",
            inLineLabel: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_DARLEHNEN ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_DARLEHNEN_DAUER"
              )
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Aktivitäten im Bereich Crowdfunding und/oder Crowdinvesting",
            inLineLabel: true,
            isComponentHalfSize: "",
            value: _vm.form.INPUT_VERMITTLUNG_CROWDFUNDING,
            disabled: !_vm.form.INPUT_SONST_BEST1 || _vm.disabledStatusOK
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_CROWDFUNDING")
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "seit",
            value: _vm.form.INPUT_VERMITTLUNG_CROWDFUNDING_DATUM,
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_CROWDFUNDING ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_CROWDFUNDING_DATUM"
              )
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Im Ausmaß von wie vielen Stunden im Jahr",
            value: _vm.form.INPUT_VERMITTLUNG_CROWDFUNDING_DAUER,
            isInteger: "",
            inLineLabel: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_CROWDFUNDING ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_CROWDFUNDING_DAUER"
              )
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Vermittlung von Kryptowährungen",
            inLineLabel: true,
            isComponentHalfSize: "",
            value: _vm.form.INPUT_VERMITTLUNG_CRYPTO,
            disabled: !_vm.form.INPUT_SONST_BEST1 || _vm.disabledStatusOK
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_CRYPTO")
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "seit",
            value: _vm.form.INPUT_VERMITTLUNG_CRYPTO_DATUM,
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_CRYPTO ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_CRYPTO_DATUM")
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Im Ausmaß von wie vielen Stunden im Jahr",
            value: _vm.form.INPUT_VERMITTLUNG_CRYPTO_DAUER,
            isInteger: "",
            inLineLabel: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_CRYPTO ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_CRYPTO_DAUER")
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Beteiligungen",
            inLineLabel: true,
            isComponentHalfSize: "",
            value: _vm.form.INPUT_VERMITTLUNG_BETEILIGUNG,
            disabled: !_vm.form.INPUT_SONST_BEST1 || _vm.disabledStatusOK
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_VERMITTLUNG_BETEILIGUNG")
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "seit",
            value: _vm.form.INPUT_VERMITTLUNG_BETEILIGUNG_DATUM,
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_BETEILIGUNG ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_BETEILIGUNG_DATUM"
              )
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Im Ausmaß von wie vielen Stunden im Jahr",
            value: _vm.form.INPUT_VERMITTLUNG_BETEILIGUNG_DAUER,
            isInteger: "",
            inLineLabel: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_BETEILIGUNG ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_BETEILIGUNG_DAUER"
              )
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Vorsorgewohnungen und/oder Bauherrenmodelle",
            inLineLabel: true,
            isComponentHalfSize: "",
            value: _vm.form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG,
            disabled: !_vm.form.INPUT_SONST_BEST1 || _vm.disabledStatusOK
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG"
              )
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "seit",
            value: _vm.form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG_DATUM,
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG_DATUM"
              )
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Im Ausmaß von wie vielen Stunden im Jahr",
            value: _vm.form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG_DAUER,
            isInteger: "",
            inLineLabel: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged(
                $event,
                "INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG_DAUER"
              )
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Sonstige nicht im FDL-Bereich ausgeübte Tätigkeiten und/oder Funktionen",
            inLineLabel: true,
            isComponentHalfSize: "",
            value: _vm.form.INPUT_NICHT_FDL,
            disabled: !_vm.form.INPUT_SONST_BEST1 || _vm.disabledStatusOK
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_NICHT_FDL")
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "seit",
            value: _vm.form.INPUT_NICHT_FDL_DATUM,
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_NICHT_FDL ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged($event, "INPUT_NICHT_FDL_DATUM")
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Im Ausmaß von wie vielen Stunden im Jahr",
            value: _vm.form.INPUT_NICHT_FDL_DAUER,
            isInteger: "",
            inLineLabel: "",
            isComponentHalfSize: "",
            disabled:
              !_vm.form.INPUT_NICHT_FDL ||
              !_vm.form.INPUT_SONST_BEST1 ||
              _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged($event, "INPUT_NICHT_FDL_DAUER")
            }
          }
        }),
        _c("InputField", {
          attrs: {
            value: _vm.form.INPUT_ERKLAERUNG_TEXT,
            label: "Erklärung",
            isComponentHalfSize: "",
            disabled: _vm.disabledStatusOK
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged($event, "INPUT_ERKLAERUNG_TEXT")
            }
          }
        }),
        _c("FormLabel", {
          attrs: {
            label:
              "Anforderung: Weiters bitten wir Sie um Bekanntgabe, bei welchen auch im WAG 2018 Bereich tätigen Firmen Sie tätig sind bzw. Funktionen ausüben."
          }
        }),
        _c("InputRadioBoxGroup", {
          attrs: {
            value: _vm.form.INPUT_WEITERE_TAETIGKEIT,
            values: _vm.weitereTaetigkeitValues,
            labelClass: "font-bold",
            disabled: _vm.disabledStatusOK
          },
          on: {
            input: function($event) {
              return _vm.fieldChanged($event, "INPUT_WEITERE_TAETIGKEIT")
            }
          }
        }),
        _c("InputField", {
          attrs: {
            value: _vm.form.INPUT_WEITERE_TAETIGKEIT_TEXT,
            disabled:
              !_vm.form.INPUT_WEITERE_TAETIGKEIT || _vm.disabledStatusOK,
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.fieldChanged($event, "INPUT_WEITERE_TAETIGKEIT_TEXT")
            }
          }
        }),
        _c("FormDivider"),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben.",
            inLineLabel: true,
            config: { bold: true },
            isComponentHalfSize: "",
            disabled: _vm.disabledStatusOK,
            value: true
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }