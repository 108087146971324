<template>
  <div>
    <div class="container" v-if="renderComponent">
      <template>
        <div class="stepper-row" v-for="(step, index) of stepsComputed" :key="'nav' + index + lastUpdated">

          <div class="box__container">  
            <div class="stepper-section" @click="onClickStep(step.stepKey)">
              <div v-if="getSubsteps(step).length <=1" class="stepper-section__arrow"><PhCaretRight :size="20" /></div>

              <div class="stepper-section__label">
                <ph-circle 
                  class="color-primary status__icon" 
                  v-if="stepperStatus(step) === 'default'" 
                  :size="16" />
                <ph-check-circle 
                  class="color-success status__icon" 
                  v-else-if="stepperStatus(step) === 'success'"
                  :size="16" />
                <ph-warning-circle 
                  class="color-danger status__icon" 
                  v-else-if="stepperStatus(step) === 'warning'"
                  :size="16" />


                <div v-bind:class="['clickable', { 'font-bold': selectedStepKey == step.stepKey }, { 'substep-sm': isMobileNativeContext }, ]" v-html="sanitize(step.label)" ></div>

              </div>
            </div>

            <template v-if="getSubsteps(step).length > 1">
                <span  v-for="(substep, subIndex) of getSubsteps(step)" 
                  class="stepper-substep"
                  @click="onClickSubstep(step.stepKey, substep.substepKey)"
                  :key="'sub' + subIndex + lastUpdated"
                >
                    <div class="line-separator-substeps" v-bind:class="['clickable', {'font-bold': selectedSubstepKey == substep.stepKey, }, { 'substep-sm': isMobileNativeContext }, ]">
                      <div class="substep-label" :class="{'substep-label__warning': hasSubstepWarning(substep)}" v-if="showLastSubstep(index, step) && !substep.hidden">
                        <ph-warning-circle v-if="hasSubstepWarning(substep)" class="color-danger substep-warning__icon" :size="12" />
                        <span v-html="sanitize(substep.title)"></span>
                      </div>
                      <span class="substep-arrow" type="button" :class="['btn-clear', 'd-block', 'd-lg-none']" ><PhCaretRight :size="20" /></span>
                    </div>
                </span>
            </template>

          </div>
        </div>


      </template>
    </div>
  </div>
</template>
<script>
import BaseDropdownMenu from "@/components/core/BaseDropdownMenu.vue";
import { PhCaretDown, PhCaretRight,  PhCircle, PhCheckCircle, PhWarningCircle } from "phosphor-vue";
import { getStepVisible } from "../antrag/antrag-utils";
import DoughnutProgress from "@/components/stepper/DoughnutProgress.vue";
import { sanitize } from "@/helpers/string-helper.js";
import verticalStepperItemMixin from "@/components/vertical-stepper/vertical-stepper-item-mixin";
import BaseCollapsable from "@/components/core/BaseCollapsable.vue";
import { mapGetters } from "vuex";
import BRIDGE_TYPES from "@/store/bridge/types";

export default {
  mixins: [verticalStepperItemMixin],
  props: {
    steps: {
      type: Array,
      default: function () {
        return [];
      },
    },
    currentStep: {
      type: Object,
      default: function () {
        return {};
      },
    },
    stepperName: {
      type: String,
      default: "",
    },
    stepType: {
      type: String,
      default: "step", // 'step' | 'tab'
    },
    selectedStepKey: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledUpdate: {
      type: Boolean,
      default: false,
    },
    stepperService: {
      default: () => null,
    },
    antragData: {},
    selectedSubstepKey: {
      type: String,
      default: "",
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subs: [],
      stepsComputed: [],
      renderComponent: true,
      lastUpdated: Date.now(),
    };
  },
  watch: {
    steps() {
      this.updateSteps();
    },
    currentStep: function (newVal) {
      newVal.step && this.progress(newVal.step.stepKey);
      this.updateSteps();
    },
    selectedStepKey: {
      handler(newVal) {
        this.progress(newVal);
        this.updateSteps();
      },
      immediate: true,
    },
    "stepperService.stepListSource": {
      handler() {
        this.updateSteps();
      },
    },
    stepperService() {
      this.updateSteps();
    },
    antragData() {
      this.updateSteps();
    },
  },
  mounted: function () {
    this.updateSteps();
    this.currentStep.step && this.progress(this.currentStep.step.stepKey);
  },
  computed: {
    ...mapGetters({
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    getSelectedStepLabel() {
      if (!this.stepperService) {
        return "";
      }
      if (this.selectedStepKey) {
        const currentStep = this.stepperService.getCurrentStep(
          this.selectedStepKey
        );

        if (this.selectedSubstepKey) {
          return (
            currentStep?.substeps?.find(
              (st) => st.substepKey === this.selectedSubstepKey
            )?.title || ""
          );
        }

        return currentStep?.label || "";
      }
      return this.stepperService.currentStep &&
        this.stepperService.currentStep.label
        ? this.stepperService.currentStep.label
        : this.stepperService.getFirstStep().label;
    },
    percentage() {
      const currentIndex = this.stepsComputed.findIndex((step) => step.current);
      return ((currentIndex + 1) / this.stepsComputed.length) * 100;
    },
    labelProgress() {
      const currentIndex = this.stepsComputed.findIndex((step) => step.current);
      return `${currentIndex + 1} / ${this.stepsComputed.length}`;
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    updateSteps() {
      const completeStepList = this.stepperService.getCurrentStepList();
      const resultList = getStepVisible(completeStepList, this.antragData);
      this.stepsComputed = resultList;
      this.lastUpdated = Date.now();
      this.forceRerender();
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
    isActive(stepKey) {
      return this.currentStep && this.currentStep.step.stepKey === stepKey;
    },
    isLastStep(index) {
      return this.steps.length - 1 === index;
    },
    subscribeToCurrentStepperList() {
      if (!this.stepperService) {
        return "";
      }
      this.stepsComputed = this.stepperService.getCurrentStepList();
    },
    progress(stepKey, index = 0) {
      if (this.stepperService) {
        this.stepperService.progress(stepKey, index, this.disabledUpdate);
        if (this.stepperService.currentStep) {
          if (this.stepperService.currentStep.actionFunc) {
            this.stepperService.currentStep.actionFunc();
          }
          const link = this.stepperService.currentStep.link;
          if (link && link !== this.$route.path) {
            this.$router.push({ path: link });
          }
        }
        this.updateSteps();
      }
    },
    onClickSubstep(stepKey, substepKey) {
      if (substepKey && !this.disabled) {
        this.$emit("setSubstep", { stepKey, substepKey });
        this.progress(stepKey, substepKey);
        this.stepClicked = stepKey;
      }
    },
    showLastSubstep(index, step) {
      const isLast = index === this.stepsComputed?.length - 1;
      if (!isLast) {
        return true;
      }

      const substepList = this.getSubsteps(step);
      return substepList.length > 0 && substepList[0].title;
    },
    onClickStep(stepKey, substepKey = "", index = 0) {
      if (!this.disabled) {
        this.$emit("setStep", stepKey, substepKey);
        this.progress(stepKey, index);
      }
    },
    getSubsteps(step) {
      if (step?.substeps?.length > 0) {
        return step.substeps;
      }

      return [{}];
    },
    stepperStatus(step) {
      const { stepType } = this;
      if(stepType === 'tab') {
        if (step.warning) {
          return 'warning';
        }

        return 'default';
      }

      if (step.warning) {
        return "warning";
      } else if (step.success) {
        return "success";
      } else {
        return "default";
      }
    },
    hasSubstepWarning(substep) {
      return !!substep?.warning;
    },
  },
  components: {
    BaseDropdownMenu,
    PhCaretDown,
    DoughnutProgress,
    BaseCollapsable,
    PhCaretRight,
    PhCircle, 
    PhCheckCircle, 
    PhWarningCircle,
  },
};
</script>
<style scoped>

.stepper-section {
  display: -webkit-flex;
  display: flex;
  flex-direction: row-reverse;
  padding: 4px 4px;
  border-bottom: 1px solid #c4c4c4;
  font-size: 1em;
  line-height: 24px;
  
  
}

.stepper-section .stepper-section__arrow {
  flex: 0 0 auto;
}

.stepper-section > .stepper-section__label {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  max-width: 100%;
  

}

.stepper-section .stepper-section__arrow + .stepper-section__label {
  max-width: calc(100% - 20px);
}

.stepper-section > .stepper-section__label > div:nth-child(2) {
  
  font-weight: 500;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;  
  white-space: nowrap; 

}

div.stepper-section:last-child  {
  border-bottom:none;
}



.status-icon {
  padding: 4px 4px;;
}



.stepper-row > div > div {
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;  
  white-space: nowrap; 
  
}

div.line-separator-substeps {
  display: flex;
}

div.line-separator-substeps:not(:empty) {
  border-bottom: 1px solid #c4c4c4; 
  padding: 4px 4px;
  line-height: 24px;
}


/* div.line-separator-substeps:last-child  {
  border-bottom:none;
} */

.box__container > span:last-child > div  {
  border-bottom:none;
}

/* .box__container > span:last-child > div:empty  {
  border-bottom:none;
}
 */

.substep-label {
  flex: 1 1 auto;
  text-align: left;
  font-weight: 500;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;  
  white-space: nowrap;
  padding-left: 16px; 
}
.substep-label__warning {
  padding-left: 0px;
}

.substep-arrow {
  flex: 1 1 auto;
  text-align: right;
  min-width: fit-content;
}

.status__icon {
  flex: 0 0 auto;
  margin: 4px 4px;
}

.substep-warning__icon {
  flex: 0 0 auto;
  margin: 0px 2px 5px 0px;
}

.modal__container {
  /* padding: 24px; */
  padding: 0px 24px;
}




@media only screen and (max-width: 720px) {
  .stepper-section > .stepper-section__label > div:nth-child(2) {
    font-size: 1.2rem;
  }


}



</style>
