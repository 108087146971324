<template>
  <div v-if="customerDataEdited">
    <FlexibleList
      :rows="rows"
      v-if="rows && rows.length"
      :linkInFirstRow="isEditable"
      @onRowClick="onRowClick"
      :pageSize="isCustomerOnly ? 0 : 5"
      showWeiterLink
      @onNavigate="navigateToCard"
    >
      <template #title="row">
        {{ row.bankName }}
      </template>

      <template #value="row">
        {{ row.iban || "-" }}
      </template>
    </FlexibleList>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    FlexibleList,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "Bankverbindung",
      uniqueString: "",
      usedStrings: new Set(),
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    bankAccounts() {
      return (
        this.customerData?.bankAccounts?.sort(
          (a, b) =>
            (a.bic || "").localeCompare(b.bic || "") &&
            (a.iban || "").localeCompare(b.iban || "")
        ) || []
      );
    },
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("bankName", "Bank"));
      result.center.push(TextColumn("owner", "Inhaber"));
      result.center.push(TextColumn("iban", "IBAN"));
      result.center.push(TextColumn("bic", "SWIFT-BIC"));
      result.center.push(TextColumn("bankCity", "Ort der Bank"));
      result.center.push(TextColumn("typeOfAccount", "Bankverbindungstyp"));
      result.center.push(TextColumn("annotation", "Bemerkung"));
      result.center.push(TextColumn("standard", "Standard"));

      return result;
    },
    rows() {
      return this.bankAccounts.map((ba) => ({
        bankName: ba.bankName || "-",
        owner: `${ba.ownerFirstName || ""} ${ba.ownerLastName}` || "-",
        iban: ba.iban || "-",
        bic: ba.bic || "-",
        bankCity: ba.bankCity || "-",
        typeOfAccount: ba.typeOfAccount || "-",
        annotation: ba.annotation || "-",
        ownerFirstName: ba.ownerFirstName || "-",
        ownerLastName: ba.ownerLastName || "-",
        id: this.generateUniqueString(), //ba.id || this.generateUniqueString(),
        standard: ba.standard ? "Ja" : "Nein",
      }));
    },
    tableId() {
      const uniqueUUID = "adb3516e-f9dc-42da-8ddc-9d7fa04b0dab";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(`/persoenlichedaten/customer-data/steps/bankverbindung`);
    },
    generateRandomLetter() {
      const alphabet = "abcdefghijklmnopqrstuvwxyz";
      return alphabet[Math.floor(Math.random() * alphabet.length)];
    },
    generateRandomNumber() {
      return Math.floor(Math.random() * 10);
    },
    generateUniqueString() {
      let newString = this.generateRandomLetter();

      while (true) {
        for (let i = 0; i < 5; i++) {
          if (i % 2 === 0) {
            newString += this.generateRandomLetter();
          } else {
            newString += this.generateRandomNumber();
          }
        }

        if (!this.usedStrings.has(newString)) {
          this.usedStrings.add(newString);
          this.uniqueString = newString;
          break;
        }
      }
    },
    onRowClick(whatRow) {
      // alert(JSON.stringify(whatRow));
      // Edit window must be implemented for the broker perspective in the app view
    },
  },
  mounted() {
    // this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>