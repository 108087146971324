import Vue from 'vue'
import LOADING_TYPES from './types';
import { getInitialState, LOADING_STATE, } from './index';

export default {

  [LOADING_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, { ...getInitialState(), });
  },

  [LOADING_TYPES.MUTATIONS.ADD_LOADING](state, id) {
    if(!id) return;
    const value = id in state.loading ? LOADING_STATE.ACTIVE : LOADING_STATE.INITIAL;
    Vue.set(state, 'loading', {
      ...state.loading,
      [id]: value,
    });
  },

  [LOADING_TYPES.MUTATIONS.STOP_LOADING](state, id) {
    Vue.set(state, 'loading', {
      ...state.loading,
      [id]: LOADING_STATE.STOPPED,
    });
  },

}
