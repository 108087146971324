<template   >
  <div class="event-body">
    <BaseModal
      ref="modal"
      @onCancelButton="close"
      :autoClose="true"
      :showConfirmButton="false"
      size="md"
    >
      <template v-slot:modalTitle>Prüfcenter Beratungsmappe</template>
      <BoxContainer>
        <div>
          <ComboBox
            v-if="comboData && comboData.length > 0"
            :values="comboData"
            v-model="val"
            @change="onChangeMape($event)"
          ></ComboBox>

          <span v-if="data && data.noProfil" class="color-danger">{{
            data.noProfil
          }}</span>
          <span v-if="data && data.noProtokol" class="color-danger">{{
            data.noProtokol
          }}</span>
          <Table
            v-if="data &&data.list&& data.list.length"
            :headers="headers"
            :rows="rows"
            :paginationEnabled="false"
            :hidePagination="true"
          >
          </Table>
        </div>
      </BoxContainer>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputField from "@/components/core/forms/InputField.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import V_P_TYPES from "@/store/versandProtokolle/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import LOG_TYPES from "@/store/log/types";
import { buildMessage } from "@/helpers/log-message-helper";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import Table from "@/components/table2/Table.vue";
import { TextColumn, PillColumn } from "@/components/table2/table_util.js";

export default {
  data() {
    return {
      maps: null,
      val: null,
    };
  },
  computed: {
    ...mapGetters({
      comboData: V_P_TYPES.GETTERS.MAPPE_COMBO,
      data: V_P_TYPES.GETTERS.MAPPE_TABLE,
    }),

    headers() {
      return {
        lockedLeft: [
          TextColumn("dokument", "Dokument").makeSortable(false),
          TextColumn("pruefnummer", "Prüfnummer").makeSortable(false),
          TextColumn("barcode", "Barcode").makeSortable(false),
          PillColumn("status", "Status").makeSortable(false),
        ],
        center: [],
        lockedRight: [],
      };
    },

    disabledColumns() {
      return [];
    },
    rows() {
      if (!this.data || !this.data.list) return [];
      return this.data.list.map((record) => {
        const row = {
          ...record,
          rowid: record.dokument,
        };
        row.rowid = record.dokument + "";

        return row;
      });
    },
  },
  mounted() {},

  methods: {
    async open(maps) {
      this.maps = maps;
      this.val = maps[0];
      await this.loadData();

      this.$refs.modal?.open();
    },
    close() {
      this.$refs.modal.close();
    },

    async onChangeMape(event) {
      if (event) {
        await $store.dispatch(V_P_TYPES.ACTIONS.MAPPE_TABLE, event);
      }
    },
    async loadData() {
      await this.$store.dispatch(V_P_TYPES.ACTIONS.MAPPE_COMBO, this.maps);
    },
  },
  components: {
    BaseModal,

    InputField,
    InputTextArea,
    DownloadLink,
    BoxContainer,
    ComboBox,
    InputField,
    Table,
  },
};
</script>

<style scoped>
