<template >
  <div class="event-body" v-if="this.isLoaded" >
  <BaseModal ref="modal"
    @onConfirmButton="save" @onCancelButton="close"
     
     :showConfirmButton="this.lines==null&&this.linesNumber==null?true: tapiData !=null && tapiData.line  != null"
   >
    <template v-slot:modalTitle>
      TAPI Service Einstellungen
    </template>
        <BoxContainer>
    <InputField label="Host mit MSC TAPI Service" v-model="tapiData.host"   />
    <InputField label="Username" v-model="tapiData.sessionId"   />
    <InputField label="Passwort" v-model="tapiData.password" type="password"   />
    <InputField label="Vorwahl" maxlength=3 v-model="tapiData.prefix"   />
    
    <div>
    <InputRadioBoxGroup 
    title="Tapi-Line auswählen"
    @input="updateRadio( $event)"
    v-model="tapiData.selectLineByTelNr" class= "box__container"
            :values="[{label:'Tapi-Line fest auswählen', value: false},{label:'flexibel nach Telefonnummer', value: true}]"/>
    </div>  
    <InputField label="Line" v-model="tapiData.line" :disabled=true   />
    </BoxContainer> 
        
         
                        <ComboBox
                           
                            :values="tapiData.selectLineByTelNr ? (linesNumber? linesNumber:[]): (lines?lines:[])"
                            v-model="tapiData.line"
                             @change="onChangeInputField($event)"
                            >
                        </ComboBox>

    

      </BaseModal>
     </div> 
</template>

<script>
import { mapGetters } from 'vuex'
import InputField from "@/components/core/forms/InputField.vue"
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '../core/BaseButton'
import TAPI from '../../store/tapi/types'
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BoxContainer from '@/components/core/BoxContainer.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';
export default {
  data() {
    
    return {
        tapiData:null,
        isLoaded:false,
        lines :null,
        linesNumber :null
    };
  },
  computed: {
   
  ...mapGetters({
   
   
      lineTapi: TAPI.GETTERS.TAPI_PARAMS,
      
      
    }),
    
  
  }, 
  mounted() {
      
  },
  watch: {
 
  },
  methods: {
   onChangeInputField($event) {
     
     this.tapiData.line=$event
    },
    updateRadio( $event){
       this.tapiData.line= null;
    }
    ,

    async open(){
        await this.loadData();  
      
        this.$refs.modal?.open();
    },
    close() {
      
        this.$refs.modal.close();
    },
    
    async save(){
      if(this.tapiData.line != null){
         await this.$store.dispatch(TAPI.ACTIONS.SAVE_TAPI_PARAMS,this.tapiData);
         this.$store.dispatch(TAPI.ACTIONS.START_WEB_SOCKET,this.tapiData);
         return true;
     

        
      } else {
          this.$store.dispatch(LOG_TYPES.ACTIONS.WARN, buildMessage("Beim Ändern des Barcodes ist ein unerwarteter Fehler aufgetreten.", 'danger'));
           return false; 
      }  
    },

    async loadData(){
       
         await this.$store.dispatch(TAPI.ACTIONS.TAPI_PARAMS);
         this.tapiData =this.$store.getters[TAPI.GETTERS.TAPI_PARAMS];
        if(this.$store.getters[TAPI.GETTERS.LINES]){
          this.lines=this.$store.getters[TAPI.GETTERS.LINES].lineNames;  
          this.linesNumber =this.$store.getters[TAPI.GETTERS.LINES].lineNumbers;
        }
         this.isLoaded= true;
    }
  },
  components: {
    BaseModal,
    BaseButton,
    InputField,
    ComboBox,
    InputRadioBoxGroup,
    BoxContainer
  },
  
}
</script>

<style scoped>
