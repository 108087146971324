<template>
  <div class="autocomplete">
    <form @submit.prevent="onChange(true)">
      <InputField
        type="text"
        :label="label"
        v-model="search"
        :disabled="disabled"
        @input="onChange(false)"
        @keydown.enter="onChange(true)"
        :placeholder="placeholder"
        autofocus
      >
      </InputField>
    </form>

    <SlideInInformation ref="slideInInfo" />

    <div v-show="showResults" class="results-container">
      <AutocompleteResult
        title="Formulare"
        :results="resultsForms"
        @click="navigateToForm($event); closeModal();"
      />

      <AutocompleteResult
        title="Kunden"
        :results="resultsCustomers"
        :loading="isLoading"
        navigationType="NewWindow"
        showAdvancedSearch
        @click="setResult($event)"
        @advancedSearch="setSearch()"
      />

      <AutocompleteResult
        v-if="isCompaniesEnabled"
        title="Gesellschaften"
        :results="resultsCompany"
        :loading="isLoading"
        @click="navigateToCompany($event.gesellschaftId, $event.gesellschaftArt); closeModal();"
      />

      <AutocompleteResult
        title="Fonds"
        :results="resultsFonds"
        :loading="isLoading"
        @click="openFonds($event); closeModal();"
      />

      <AutocompleteResult
        title="Makler"
        :results="resultsBrokers"
        navigationType="NewWindow"
        :loading="isLoading"
        @click="openBroker($event.brokerId); closeModal();"
      />

      <AutocompleteResult
        title="Versicherungen"
        :results="resultsInsurances"
        :loading="isLoading"
        showAdvancedSearch
        @click="navigateToVersicherungen($event); closeModal();"
        @advancedSearch="navigateTo('/home/versicherungen/insurance-group')"
      />

      <AutocompleteResult
        title="Depots"
        :results="resultsDepots"
        navigationType="NewWindow"
        :loading="isLoading"
        @click="openDepots($event); closeModal();"
      />

      <AutocompleteResult
        title="Kundendaten"
        :results="resultsCustomerData"
        navigationType="NewWindow"
        :loading="isLoading"
        @click="selectCustomerData($event.data);"
      />

      <AutocompleteResult
        title="Kundendaten Suchoptionen"
        :results="resultsCustomerSearchCommands"
        navigationType="NewWindow"
        :loading="isLoading"
        @click="addSearchTermToInputField($event);"
      />

      <AutocompleteResult
        title="Unterdepots"
        :results="resultsUnterdepots"
        navigationType="NewWindow"
        :loading="isLoading"
        @click="openUnterdepots($event); closeModal();"
      />

      <AutocompleteResult
        title="Kalender"
        :results="resultsCalendar"
        :loading="isLoading"
        @click="openAppointment($event.appointment.sid); closeModal();"
      />

      <AutocompleteResult
        title="Exception"
        :results="resultsExceptions"
        :loading="isLoading"
        @click="openException($event.id); closeModal();" 
      />

      <AutocompleteResult
        title="Navigation"
        :results="resultsRoutes"
        @click="selectNavigation($event.path); closeModal();"
      />

      <div v-if="showResults && isLoading.length === 0 && noResult && hasSearch" class="results-container--no-results">
        Keine Treffer!
      </div>

      <div v-if="isLoading.length !== 0" class="results-container--loading">
        <div class="loading">
          <AnimatedSpinner/> Suche läuft...
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import CORE_TYPES from '@/store/core/types';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import { mapGetters } from 'vuex';
import { VIEW_ROLES } from '@/router/roles';
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import {sanitize} from '@/helpers/string-helper.js';
import BaseImage from '@/components/core/BaseImage.vue';
import AutocompleteResult, { ResultItemBuilder } from '@/components/core/navbar-workspaces/AutocompleteResult.vue';
import FormUtil from '@/components/beratung/formsMenu/forms-menu';
import SlideInInformation from "@/components/Tools/SlideInInformation.vue";
import NAVIGATION_LOG_TYPES from "@/store/navigation-log/types";
import CALENDAR_TYPES from "@/store/calendar/types";
import menuTrack from '@/menu/menu-track';
import { makeQueryParam } from '@/helpers/utils-helper';
import { GS_TYPES } from '@/store/navigation-log/index'

  export default {
    name: 'Autocomplete',

    props: {
      itemsCustomers: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsCompanies: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsRoutes: {
        type: Array,
        required: false,
        default: () => [],
      },

       itemsFonds: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsBrokers: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsInsurances: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsForms: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsDepots: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsCustomerData: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsUnterdepotData: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsCustomerSearchCommands: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsCalendar: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemsExceptions: {
        type: Array,
        required: false,
        default: () => [],
      }, 
      placeholder: {
        type: String,
        required: false,
        default: '',
      },
      flatMenuCustomer: {
        type: Array,
        required: false,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Array,
        required: false,
        default: () => [],
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
    },

    components: {
      InputField,
      AnimatedSpinner,
      BaseImage,
      AutocompleteResult,
      SlideInInformation,
    },

    data() {
      return {
        showResults: false,
        resultsCustomers: [],
        resultsCompany: [],
        resultsRoutes: [],
        resultsFonds: [],
        resultsBrokers: [],
        resultsInsurances: [],
        resultsForms: [],
        resultsDepots: [],
        resultsCustomerData: [],
        resultsCustomerSearchCommands: [],
        resultsUnterdepots: [],
        resultsCalendar: [],
        resultsExceptions: [],
        search: '',
        timeoutRef: null,
        debounceTime: 1000,
        customers: [],
        openedCustomerId: '',
      };
    },

    methods: {
      sanitize(htmlString) {
        return sanitize(htmlString);
      },
      onChange(isEnter = false) {
        this.showResults = false;

        this.timeoutRef && clearTimeout(this.timeoutRef);
        if (!this.search) {
          this.loaded = [];
          this.resultsCustomers = [];
          this.resultsCompany = [];
          this.resultsRoutes = [];
          this.resultsFonds = [];
          this.resultsBrokers = [];
          this.resultsInsurances = [];
          this.resultsForms = [];
          this.resultsDepots = [];
          this.resultsUnterdepots = [];
          this.resultsCustomerData = [];
          this.resultsCustomerSearchCommands = [];
          this.resultsCalendar = [];
          this.resultsExceptions = [];
          return;
        }
        if (this.search.length < 3) return;
        this.search = this.search.trim();
        this.timeoutRef = setTimeout(() => {
          this.showResults = true;

          if (isEnter) {
            this.runCommand(this.search)
            const searchParams = this.search.split('/');
            if (searchParams.length > 1 && searchParams[1]) {
              const found = this.flatMenuCustomer.filter(v => (v.label.toLowerCase()).includes(searchParams[1].toLowerCase()))
              if (found.length) {
                this.openedCustomerId = searchParams[0];
                this.setOpenedCustomer();
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: searchParams[0], path: found[0].path })
                this.$emit('selected', this.search);
                this.$emit('input', this.search);
                return;
              }
            }
          }

          this.$emit('input', this.search);
          this.loaded = [];
          this.resultsCustomers = [];
          this.resultsCompany = [];
          this.resultsRoutes = [];
          this.resultsFonds = [];
          this.resultsBrokers = [];
          this.resultsInsurances = [];
          this.resultsForms = [];
          this.resultsDepots = [];
          this.resultsUnterdepots = [];
          this.resultsCustomerData = [];
          this.resultsCustomerSearchCommands = [];
          this.resultsCalendar = [];
          this.resultsExceptions = [];

          this.showResults = true;
        }, this.debounceTime);
      },
      selectCustomerData(text) {
        this.logGlobalSearch({ target: text, type: GS_TYPES.CUSTOMER_DATA });
        this.copyToClipboard(text);
      },
      copyToClipboard(text) {
        navigator.clipboard.writeText(text);
        let textToDisplay = "In die Zwischenablage kopiert!";
        this.$refs.slideInInfo.showInfoBox(3000, textToDisplay);
      },
      openUnterdepots($event) {
        this.logGlobalSearch({ target: $event.DepotNr, type: GS_TYPES.UNTERDEPOTS });
        this.openCustomer($event.DepotNr, '/home/vermogensubersicht/depot')
      },
      addSearchTermToInputField(text){
        this.search += text;
        this.onChange(true);
      },
      setOpenedCustomer(val) {
        const searchParams = this.search.split('/');
        if (this.openedCustomerId && searchParams.length > 1) {
          const found = this.customers.find(v => v.customerID === this.openedCustomerId);
          if (found) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: this.openedCustomerId })
          }
        }
      },
      setResult(result) {
        this.showResults = false;
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: result.customerID })
        this.logGlobalSearch({ target: result.customerID, type: GS_TYPES.CUSTOMER });
        this.$emit('selected', this.search);
      },
      handleOpenCustomer(values) {
        if(!values?.customerID) return;

        this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, {
          data: {
            person: {
              personalDataAddress: { ...values },
            },
            ...values,
          },
        });
      },
      openCustomer(customerId, path){
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId,
          path: path,
        });
      },
      async openAppointment(appointmentId) {
        if (appointmentId) {
          this.logGlobalSearch({ target: appointmentId, type: GS_TYPES.APPOINTMENT });
          await this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, appointmentId);
          await this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
            title: `Termin bearbeiten`,
            mode: "Termin",
            selectedDate: null,

            back: this.$router.currentRoute.fullPath,
          })
          await this.$router.push({path: `/communication/appointment/${appointmentId}`});
        }
      },
      async openException(id) {
        if(id){
          await this.$router.push({path: `/intern/exception-log/${id}`});
        }
      },
      closeModal() {
        this.$emit('selected', this.search);
      },
      setSearch() {
        this.showResults = false;
        this.$emit('gotosearch', this.search);
      },
      resetBreadcrumbs() {
        this.$store.commit(CORE_TYPES.MUTATIONS.SAVE_BACK_TO_PREVIOUS_PAGE_SHIFT);
      },
      navigateToCompany(gesellschaftId, gesellschaftArt) {
        this.resetBreadcrumbs();
        this.$store.commit(GESELLSCHAFT_TYPES.MUTATIONS.RESET_STATE);

        let path = `/beratung/gesellschaften/${gesellschaftArt || 'NONE'}/${gesellschaftId}`;
        if (this.isIntern) {
          path = `/intern/gesellschaften/${gesellschaftId}`
        }

        this.logGlobalSearch({ target: path, type: GS_TYPES.COMPANY });

        this.$router
          .push('/noop') // prevents navigation issues when route is already open
          .catch(() => {})
          .finally(() => {
            this.$route.push({ path });
          });
      },
      navigateTo(route) {
        this.resetBreadcrumbs();
        const searchParams = this.search.split('/');
        if (searchParams.length > 1) {
          this.openedCustomerId = searchParams[0];
          this.setOpenedCustomer();
          const customerID = this.itemsCustomers && this.itemsCustomers.length === 1 ?
            this.itemsCustomers[0].customerID : searchParams[0];
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: customerID, path: route })
          this.$emit('selected', this.search);
        } else {
          menuTrack.navigateRestartingToPath(route);
        }
      },
      openBroker(brokerId) {
        this.logGlobalSearch({ target: brokerId, type: GS_TYPES.BROKER });
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId })
      },
      openFonds(item) {
        const path = `/shared/vermogensubersicht/fondsinfo/${item.isin}/ALLE_DEPOTS_ANZEIGEN`
        this.logGlobalSearch({ target: path, type: GS_TYPES.FONDS });
        this.$router.push({ path });
      },
      runCommand(search) {
        switch (search) {
          case 'heatmap':
            document.dispatchEvent(new CustomEvent('onToggleHeatmap'))
            this.closeModal()
            break;
          default:
            break;
        }
      },
      navigateToForm(form) {
        FormUtil.openFormGlobalSearch(form._lagerstelle, form);
        const target = `${form?.link?.path}?${makeQueryParam(form?.link?.query || {})}`;
        this.logGlobalSearch({ target, type: GS_TYPES.FORM });
      },
      navigateToVersicherungen($event) {
        const path = `/home/versicherungen/insurance-group/details/${$event.id}`
        this.logGlobalSearch({ target: path, type: GS_TYPES.VERSICHERUNGEN });
        this.navigateTo(path)
      },
      openDepots($event) {
        this.logGlobalSearch({ target: $event.kundenNr, type: GS_TYPES.DEPOTS });
        this.openCustomer($event.kundenNr, '/home' );
      },
      logGlobalSearch({ target, type }) {
        const payload = {
          searchTerm: this.search,
          target,
          type,
        };

        this.$store.dispatch(NAVIGATION_LOG_TYPES.ACTIONS.GLOBAL_SEARCH, payload);
      },
      selectNavigation(path) {
        this.logGlobalSearch({ target: path, type: GS_TYPES.NAVIGATION });
        this.navigateTo(path)
      },
      buildTeilnehmerString(input){
        if(!input.beteiligteOutput)
          return "";

        let return_String = "Teilnehmer: ";
        input.beteiligteOutput?.forEach(beteiligter => {
            if(beteiligter.beteiligte.name !== this.loggedInUsername)
              return_String += beteiligter.beteiligte.name + ", ";
        });

        if (return_String.length > 100)
          return_String = return_String.substring(0, 100 - 2) + "...";
        else
          return_String = return_String.substring(0, return_String.length - 2);

        if(return_String === "Teilnehmer")
          return "";
        else
          return return_String;
      },
    },
    computed: {
      ...mapGetters({
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        isIntern: CORE_TYPES.GETTERS.IS_INTERN,
        loggedInUsername: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      }),
      hasSearch() {
        return this.search?.length > 0;
      },
      isCompaniesEnabled() {
        return this.hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN]);
      },
      noResult() {
        return !this.resultsRoutes?.length && !this.resultsForms?.length && this.noAsyncResult;
      },
      noAsyncResult() {
        return !this.resultsCustomers?.length && !this.resultsCompany?.length && !this.resultsFonds?.length
          && !this.resultsBrokers?.length && !this.resultsInsurances?.length && !this.resultsDepots?.length
          && !this.resultsCustomerData?.length && !this.resultsCustomerSearchCommands?.length
          && !this.resultsUnterdepots?.length && !this.resultsCalendar?.length
          && !this.resultsExceptions?.length;
      },
    },
    watch: {
      itemsCustomers: function (val) {
        const searchParams = this.search.split('/');
        if (searchParams.length > 1) {
          this.customers = val;
          this.setOpenedCustomer();
          return;
        }
        this.resultsCustomers = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.firstName)
            .addTitle(result.lastName)
            .addSubtitle(result.superKundeString)
            .addDescription(result.inactive ? '(inaktiv)' : null, 'var(--color-danger)')
            .addDescription(result.street !== '.' ? result.street : null)
            .addDescription(`${result.zip || ''} ${result.city || ''}`.trim())
            .build());
      },
      itemsCompanies: function (val, oldValue) {
        this.resultsCompany = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.gesellschaftId)
            .addDescription(result.lastName)
            .addDescription(result.gesellschaftArtLabel)
            .build());
      },
      itemsRoutes: function (val, oldValue) {
        this.resultsRoutes = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.label)
            .addDescription(result.parentLabel)
            .build());
      },
      itemsFonds: function (val, oldValue) {
        this.resultsFonds = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.isin)
            .addDescription(result.wkn)
            .addDescription(result.name)
            .build());
      },
      itemsBrokers: function (val, oldValue) {
        this.resultsBrokers = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.brokerId)
            .addDescription(result.inactive ? '(inaktiv)': null, 'var(--color-danger)')
            .addDescription(result.name)
            .build());
      },
      itemsInsurances: function (val, oldValue) {
        this.resultsInsurances = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.bezeichnung)
            .addDescription(result.nummer)
            .build());
      },
      itemsForms: function (val, oldValue) {
        this.resultsForms = (val || [])
          .map(result => ResultItemBuilder(result)
            .withIcon(BaseImage, {
              src: result._lagerstelle.logoURL,
              placeholder: { height: '40px' },
            })
            .addTitle(result.label)
            .addDescription(result.childGroup || 'Sonstiges')
            .addDescription(result._lagerstelle.label)
            .build());
      },
      itemsDepots: function (val, oldValue, newValue){
        this.resultsDepots = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.kundenName + " (" + result.kundenNr + ") - Wert: " + result.depotWert + " EUR")
            .addDescription("DepotId: " + result.DepotId + " - ISIN: " + result.depotIsin + " - Begleitschein Nr: " + result.BegleitscheinNr)
            .build());
      },
      itemsCustomerData: function (val, oldValue, newValue){
        this.resultsCustomerData = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.searchCategory + ": " + result.data || "Keine Daten")
            .addDescription(result.kundenname + " | " + result.kundennr)
            .build());
      },
      itemsCustomerSearchCommands: function (val, oldValue, newValue){
        this.resultsCustomerSearchCommands = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result)
            .addDescription("")
            .build()
          );
      },
      itemsUnterdepotData: function (val, oldValue, newValue){
        this.resultsUnterdepots = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.depotName + " (" + result.DepotNr + ") - Wert: " + parseFloat(result.depotWert).toFixed(2) + " EUR")
            .addDescription("Kundenname: " + result.kundenName + " - KundenNr: " + result.kundenNr + " - Beschreibung: " + (result.Musterbeschreibung || "Keine Beschreibung"))
            .build()
          );
      },
      itemsCalendar: function (val, oldValue, newValue){
        this.resultsCalendar = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.appointment.label + " - " + result.appointment.begin.slice(0, result.appointment.begin.length - 3))
            .addDescription(this.buildTeilnehmerString(result))
            .build()
          );
      },
      itemsExceptions: function (val, oldValue, newValue){
        this.resultsExceptions = (val || [])
          .map(result => ResultItemBuilder(result)
            .addTitle(result.id + ": " + new Date (result.dateCreated).toLocaleString() || "Keine Daten")
            .addDescription( "" )
            .build());
      },
    },
  };
</script>

<style scoped>
  .autocomplete {
    position: relative;
    width: 100%;
  }
  .results-container {
    padding: 0 8px 4px;
  }
  .results-container--no-results,
  .results-container--loading {
    padding-top: 16px;
  }
</style>
