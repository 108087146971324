<template>
  <div class="box__container antrag-bottom-buttons-container">
    <div class="row">
      <div class="col-12 my-1 col-sm-auto hide-on-small-screen" v-if="hasPreviousStep">
        <BaseButton 
          isSecondary 
          isBlock
          @click="onButtonPrevious()">
            Zurück <AnimatedSpinner v-if="showPreviousSpinner"/>
        </BaseButton>
      </div>
      <div class="col-12 my-1 col-sm-auto" v-if="hasNextStep">
        <BaseButton 
          isBlock
          @click="onButtonNext()">
            Weiter <AnimatedSpinner v-if="showNextSpinner"/>
        </BaseButton>
      </div>

      <div class="col-12 my-1 col-sm-auto" v-if="routeStep === 'aktionen' || !hasNextStep">
        <BaseBackButtonBreadcrumb 
          showAsButton 
          fixedLabel="Schließen"
          @beforeBackTrigged="beforeBackTrigged()"/>
      </div>
    </div>

    <div class="row" v-if="hasBachToPreviousFormLink">
      <div class="col-12">
        <BaseButton 
          class="antrag-bottom-buttons-back-previous-antrag-button"
          isSecondary 
          @click="navigateToPreviousAntrag()">
            Zurück zur {{ backToPreviousFormLabel }}/Fertig
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue'

import ANTRAG_TYPES from '@/store/antrag/types';

export default {
  mixins: [antragNavigationMixin],
  props: {
    antragStep: {
    },
    routeStep: {
    },
    antragId:{
    },
    antragData: {
    },
  },
  data() {
    return {
      lastClickedButton: ''
    }
  },
  watch: {
    saveAntragState(currentSaveAntragState) {
      if (!currentSaveAntragState) {
        this.lastClickedButton = null
      }
    }
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      saveAntragState: ANTRAG_TYPES.GETTERS.SAVE_ANTRAG_STATE,
    }),
    antrag() {
      return this.antraege[this.antragId];
    },
    stepIndex() {
      return this.antragStep 
        && Array.isArray(this.antragStep) 
        && this.antragStep.map(st => st.stepKey).indexOf(this.routeStep) 
        || -1;
    },
    hasPreviousStep() {
      return this.stepIndex > 0;
    },
    hasNextStep() {
      return this.antragStep && this.stepIndex < this.antragStep.length - 1;
    },
    showNextSpinner() {
      return this.saveAntragState && this.lastClickedButton === 'next'
    },
    showPreviousSpinner() {
      return this.saveAntragState && this.lastClickedButton === 'previous'
    }
  },
  methods: {
    onButtonNext() {
      this.lastClickedButton = 'next'
      this.navigateNextStep()
    },
    onButtonPrevious() {
      this.lastClickedButton = 'previous'
      this.navigatePreviousStep()
    },
    beforeBackTrigged() {
      const payload = {
          id: this.antragId,
          data: {}
      }
      if(!this.antragData?.isWebserviceAktiv){
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
      }
     
      // this.save(true) it gets saved twice because of 'beforeRouteLeave' in BaseAntragView
    }
  },
  components: {
    BaseButton,
    AnimatedSpinner,
    BaseBackButtonBreadcrumb,
  }
}
</script>

<style scoped>

</style>