var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InsuranceOptionMenu", {
        attrs: {
          title: "Verteilung der Abrechnung auf die Struktur",
          headerActions: _vm.headerActions
        },
        on: {
          "action-NEW-STRUKTUR": function($event) {
            return _vm.openNewStruktur()
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              !_vm.loading && _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 10
                    },
                    on: {
                      "click-lfdNr": _vm.openEditStruktur,
                      "action-DELETE": function($event) {
                        return _vm.deleteStruktur($event)
                      }
                    },
                    model: {
                      value: _vm.selectedRows,
                      callback: function($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows"
                    }
                  })
                : _vm.loading
                ? _c("GhostLoading", { attrs: { type: "table" } })
                : _c("NoData", {
                    attrs: { content: "Es wurden keine Einträge gefunden" }
                  })
            ],
            1
          )
        ])
      ]),
      _c("BaseModal", {
        ref: "verteilungStrukturModal",
        attrs: {
          size: "sm",
          modalTitle: "Verteilung auf die Struktur",
          showDefaultButtons: false
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.isNewStruktur
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("ComboBox", {
                            attrs: {
                              id: "previousLfdNr",
                              label: "Lfd. Nummer",
                              values: _vm.comboboxValues,
                              validateUntouched: true
                            },
                            model: {
                              value: _vm.form.previousLfdNr,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "previousLfdNr", $$v)
                              },
                              expression: "form.previousLfdNr"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("InputField", {
                            attrs: {
                              id: "lfdNr",
                              label: "Lfd. Nummer",
                              disabled: ""
                            },
                            model: {
                              value: _vm.form.lfdNr,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "lfdNr", $$v)
                              },
                              expression: "form.lfdNr"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("InputField", {
                        attrs: {
                          label: "Vermittlernummer",
                          validateUntouched: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.findMaklerDataDebounce($event)
                          },
                          onBlur: function($event) {
                            return _vm.checkIfMaklerDataIsValid()
                          }
                        },
                        model: {
                          value: _vm.form.maklernr,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "maklernr", $$v)
                          },
                          expression: "form.maklernr"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("InputField", {
                        attrs: {
                          id: "provSatz",
                          label: "Courtagesatz",
                          type: "percent",
                          precision: 2,
                          validateUntouched: true
                        },
                        model: {
                          value: _vm.form.provSatz,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "provSatz", $$v)
                          },
                          expression: "form.provSatz"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-3",
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.closeEditStruktur()
                      }
                    }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      disabled:
                        _vm.validation.isInvalid("form") ||
                        _vm.isMaklernrInvalid
                    },
                    on: {
                      click: function($event) {
                        _vm.saveForm()
                        _vm.closeEditStruktur()
                      }
                    }
                  },
                  [_vm._v("Speichern")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "deleteModal",
        attrs: {
          size: "sm",
          modalTitle: "Provisionssatz löschen",
          labelButtonConfirm: "Löschen"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.deleteStrukturItem()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [_vm._v(" Soll der Datensatz wirklich gelöscht werden? ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }