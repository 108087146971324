var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseImage", {
    staticClass: "qr-code__container",
    attrs: { src: _vm.qrCodeUrl, placeholder: _vm.placeholderSize }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }