<template>
  <div>
    <BaseExplorer
      :additionalActions="additionalActions"
      @headerAction-CONFIRM_SELECTION="handleSchadensmeldung"
    />
  </div>
</template>

<script>
import BaseExplorer from "@/components/core/BaseExplorer.vue";
import { mapGetters } from 'vuex';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default {
  mixins: [],
  beforeRouteLeave (to, from, next) {
    if (to.fullPath.includes('mailcomposer') && !to.fullPath.includes('dokumentenarchiv')) {
      this.$addBreadcrumb({
        label: 'zurück zur Kommunikationsübersicht',
        fullPath: '/communication/kommunikationsubersicht/',
        breadcrumb: 'E-Mail an Kunde ',
      });
    }
    next()
  },
  components: {
    BaseExplorer,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      selectedRows: DOKUMENTENARCHIV_TYPES.GETTERS.SELECTED_ROWS,
      isSelectedAllInFolder: DOKUMENTENARCHIV_TYPES.GETTERS.IS_SELECTED_ALL_IN_FOLDER,
    }),
    additionalActions() {
      return  [
        BaseModalSimpleAction('CONFIRM_SELECTION', 'Auswahl übernehmen')
          .withDisabled(() => !this.isSelectedAllInFolder && !this.selectedRows.some(row => row.exists)),
      ];
    },
    nodeId() {
      return this.$route.query.nodeId;
    },
    insuranceId() {
      return this.$route.query.insuranceId;
    },
    schadenId() {
      return this.$route.query.schadenId;
    },
  },
  methods: {
    async handleSchadensmeldung(attachments) {

      if (attachments.length) {
        this.$store.commit(SCHADENSMELDUNG_TYPES.MUTATIONS.SET_ATTACHMENTS, {action: 'clear'});
        this.$store.commit(SCHADENSMELDUNG_TYPES.MUTATIONS.SET_ATTACHMENTS, attachments.map(v => ({...v, preloaded: true})));

        this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS, {
          nodeId: this.nodeId,
          showOnlySchadenDocs: true,
        });

      }
      this.goBack();
    },
    goBack() {
      if (this.$route.query && this.$route.query.back) {
        let returnPath = this.$route.query.back;

        if (this.$route.query.back.indexOf('stepKey') <0) {
          returnPath += '?stepKey=dokumenteUndFotos';
        }

        this.$router.push({ path: returnPath, query: {backAction: true} });
      }
    },
  }
}
</script>

<style scoped>
.d-inline-block {
  display: inline-block;
}
</style>