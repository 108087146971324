import { render, staticRenderFns } from "./AllgemeineAngaben.vue?vue&type=template&id=5b7a7529&"
import script from "./AllgemeineAngaben.vue?vue&type=script&lang=js&"
export * from "./AllgemeineAngaben.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b7a7529')) {
      api.createRecord('5b7a7529', component.options)
    } else {
      api.reload('5b7a7529', component.options)
    }
    module.hot.accept("./AllgemeineAngaben.vue?vue&type=template&id=5b7a7529&", function () {
      api.rerender('5b7a7529', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/schadensmeldung/steps/AllgemeineAngaben.vue"
export default component.exports