var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Vertragsdaten")]),
        _c("InputField", {
          attrs: {
            label: "Kategorie",
            isComponentHalfSize: true,
            disabled: true
          },
          model: {
            value: _vm.brokerData.kategorie,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "kategorie", $$v)
            },
            expression: "brokerData.kategorie"
          }
        }),
        _c("ComboBox", {
          attrs: {
            label: "Potential",
            values: _vm.brokerDataConfig.potentialValues,
            isComponentHalfSize: true
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEditedCombo(
                "brokerData",
                "potential",
                $event
              )
            }
          },
          model: {
            value: _vm.brokerData.potential,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "potential", $$v)
            },
            expression: "brokerData.potential"
          }
        }),
        _c("InputCheckboxItem", {
          attrs: { label: "Interessent" },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            },
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.checkMaklerInteressent,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "checkMaklerInteressent", $$v)
            },
            expression: "brokerData.checkMaklerInteressent"
          }
        }),
        _c("InputRadioBoxGroup", {
          attrs: {
            title: "Homepage",
            inlineLabelValues: false,
            values: [
              { label: "Ja", value: "Ja" },
              { label: "Nein", value: "Nein" }
            ]
          },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.radioFAHomepage,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "radioFAHomepage", $$v)
            },
            expression: "brokerData.radioFAHomepage"
          }
        }),
        _c("InputField", {
          attrs: { label: "Homepage URL", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.homepageUrl,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "homepageUrl", $$v)
            },
            expression: "brokerData.homepageUrl"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Homepage geprüft",
            isValueAsString: "",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.homepageGeprueft,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "homepageGeprueft", $$v)
            },
            expression: "brokerData.homepageGeprueft"
          }
        }),
        _vm.isStructure10000
          ? _c("InputRadioBoxGroup", {
              attrs: {
                title: "Jahrescheckliste erforderlich",
                inlineLabelValues: false,
                values: [
                  { label: "Ja", value: "Ja" },
                  { label: "Nein", value: "Nein" }
                ]
              },
              on: {
                input: function($event) {
                  return _vm.addBrokerDataEdited("brokerData")
                }
              },
              model: {
                value: _vm.brokerData.jclErforderlich,
                callback: function($$v) {
                  _vm.$set(_vm.brokerData, "jclErforderlich", $$v)
                },
                expression: "brokerData.jclErforderlich"
              }
            })
          : _vm._e(),
        _vm.isStructure10000
          ? _c("InputCheckboxItem", {
              attrs: { label: "Persönliches Geschäft erforderlich" },
              on: {
                input: function($event) {
                  return _vm.addBrokerDataEdited("brokerData")
                }
              },
              model: {
                value: _vm.brokerData.persoenlichesGeschaeft,
                callback: function($$v) {
                  _vm.$set(_vm.brokerData, "persoenlichesGeschaeft", $$v)
                },
                expression: "brokerData.persoenlichesGeschaeft"
              }
            })
          : _vm._e(),
        _vm.isStructure10000
          ? _c("InputCheckboxItem", {
              attrs: {
                label: "Persönliches Geschäft erforderlich (Unternummer)"
              },
              on: {
                input: function($event) {
                  return _vm.addBrokerDataEdited("brokerData")
                }
              },
              model: {
                value: _vm.brokerData.persoenlichesGeschaeftUnternummer,
                callback: function($$v) {
                  _vm.$set(
                    _vm.brokerData,
                    "persoenlichesGeschaeftUnternummer",
                    $$v
                  )
                },
                expression: "brokerData.persoenlichesGeschaeftUnternummer"
              }
            })
          : _vm._e(),
        _c("FormDivider"),
        _c("div", { staticClass: "box__title" }, [_vm._v("GISA")]),
        _c("InputField", {
          attrs: {
            label: "GISA 1 Vermögensberater",
            isComponentHalfSize: true
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa1,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa1", $$v)
            },
            expression: "brokerData.gisa1"
          }
        }),
        _c("InputCheckboxItem", {
          attrs: { label: "Geprüft" },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa1Checked,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa1Checked", $$v)
            },
            expression: "brokerData.gisa1Checked"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled: !_vm.brokerData.gisa1Checked
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa1Date,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa1Date", $$v)
            },
            expression: "brokerData.gisa1Date"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "GISA 2 Vermögensverwalter",
            isComponentHalfSize: true
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa2,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa2", $$v)
            },
            expression: "brokerData.gisa2"
          }
        }),
        _c("InputCheckboxItem", {
          attrs: { label: "Geprüft" },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa2Checked,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa2Checked", $$v)
            },
            expression: "brokerData.gisa2Checked"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled: !_vm.brokerData.gisa2Checked
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa2Date,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa2Date", $$v)
            },
            expression: "brokerData.gisa2Date"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "GISA 3 Versicherungsmakler",
            isComponentHalfSize: true
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa3,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa3", $$v)
            },
            expression: "brokerData.gisa3"
          }
        }),
        _c("InputCheckboxItem", {
          attrs: { label: "Geprüft" },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa3Checked,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa3Checked", $$v)
            },
            expression: "brokerData.gisa3Checked"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled: !_vm.brokerData.gisa3Checked
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa3Date,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa3Date", $$v)
            },
            expression: "brokerData.gisa3Date"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "GISA 4 Versicherungsagent",
            isComponentHalfSize: true
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa4,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa4", $$v)
            },
            expression: "brokerData.gisa4"
          }
        }),
        _c("InputCheckboxItem", {
          attrs: { label: "Geprüft" },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa4Checked,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa4Checked", $$v)
            },
            expression: "brokerData.gisa4Checked"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled: !_vm.brokerData.gisa4Checked
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gisa4Date,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gisa4Date", $$v)
            },
            expression: "brokerData.gisa4Date"
          }
        }),
        _c("InputTextArea", {
          attrs: { label: "Gewerbeumfang", isComponentHalfSize: "" },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gewerbeumfang,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gewerbeumfang", $$v)
            },
            expression: "brokerData.gewerbeumfang"
          }
        }),
        _c("FormDivider"),
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Vermittlereinstufungen")
        ]),
        _c("div"),
        _c("InputCheckboxItem", {
          attrs: { label: "Juristische Person" },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.juristischePerson,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "juristischePerson", $$v)
            },
            expression: "brokerData.juristischePerson"
          }
        }),
        _c("InputField", {
          attrs: { label: "Firmenbuch-Nr.", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.firmenbuchNr,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "firmenbuchNr", $$v)
            },
            expression: "brokerData.firmenbuchNr"
          }
        }),
        _c("InputField", {
          attrs: { label: "Gericht", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.gericht,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "gericht", $$v)
            },
            expression: "brokerData.gericht"
          }
        }),
        _c("InputRadioBoxGroup", {
          attrs: {
            title: "Makler ist Konzessioniert",
            inlineLabelValues: false,
            values: [
              { label: "Ja", value: true },
              { label: "Nein", value: false }
            ]
          },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konzessioniert,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "konzessioniert", $$v)
            },
            expression: "brokerData.konzessioniert"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Stand",
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled: true
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konzessioniertStand,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "konzessioniertStand", $$v)
            },
            expression: "brokerData.konzessioniertStand"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Ersterfassung",
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled: true
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.ersterfassung,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "ersterfassung", $$v)
            },
            expression: "brokerData.ersterfassung"
          }
        }),
        _c("InputRadioBoxGroup", {
          attrs: {
            title: "Vermittlereinstufung",
            values: _vm.vermittlereinstufungValues,
            inlineLabelValues: false
          },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vermittlereinstufung,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vermittlereinstufung", $$v)
            },
            expression: "brokerData.vermittlereinstufung"
          }
        }),
        _c("ComboBox", {
          attrs: {
            label: "Gewerbeschein",
            values: _vm.gewerbescheinValues,
            isComponentHalfSize: true
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEditedCombo(
                "brokerData",
                "maklerGewerbeschein",
                $event
              )
            }
          },
          model: {
            value: _vm.brokerData.maklerGewerbeschein,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "maklerGewerbeschein", $$v)
            },
            expression: "brokerData.maklerGewerbeschein"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Versicherungsmakler",
            config: { forceValueAsString: true },
            disabled: _vm.disableVersMakler
          },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.versicherungsmakler,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "versicherungsmakler", $$v)
            },
            expression: "brokerData.versicherungsmakler"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Versicherungsagent",
            config: { forceValueAsString: true },
            disabled: _vm.disableVersAgent
          },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.versicherungsagent,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "versicherungsagent", $$v)
            },
            expression: "brokerData.versicherungsagent"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Bei fehlenden Unterlagen trotzdem nicht inaktiv setzen",
            config: { forceValueAsString: true }
          },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.fehlendenUnterlagenInaktiv,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "fehlendenUnterlagenInaktiv", $$v)
            },
            expression: "brokerData.fehlendenUnterlagenInaktiv"
          }
        }),
        _c(
          "div",
          { staticClass: "row mt-3 mb-5" },
          [
            _c("InputRadioBoxGroup", {
              attrs: {
                title: "Vermittlung von Privatkrediten",
                inlineLabelValues: false,
                values: [
                  { label: "Ja", value: "Ja" },
                  { label: "Nein", value: "Nein" }
                ]
              },
              on: {
                input: function($event) {
                  return _vm.addBrokerDataEdited("brokerData")
                },
                change: function($event) {
                  return _vm.addBrokerDataEdited("brokerData")
                }
              },
              model: {
                value: _vm.brokerData.vermittlungPrivatkredite,
                callback: function($$v) {
                  _vm.$set(_vm.brokerData, "vermittlungPrivatkredite", $$v)
                },
                expression: "brokerData.vermittlungPrivatkredite"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "row mt-3 mb-3" }, [
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("InputCheckboxItem", {
                attrs: { label: "Strafregisterauszug" },
                on: {
                  input: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.strafregisterauszug,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "strafregisterauszug", $$v)
                  },
                  expression: "brokerData.strafregisterauszug"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c("DatePickerField", {
                attrs: {
                  label: "",
                  isValueAsString: "",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.strafregisterauszugDatum,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "strafregisterauszugDatum", $$v)
                  },
                  expression: "brokerData.strafregisterauszugDatum"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row mt-3 mb-3" }, [
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("InputCheckboxItem", {
                attrs: { label: "Gewerbeanmeldung" },
                on: {
                  input: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.gewerbeanmeldung,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "gewerbeanmeldung", $$v)
                  },
                  expression: "brokerData.gewerbeanmeldung"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c("DatePickerField", {
                attrs: {
                  label: "",
                  isValueAsString: "",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.gewerbeanmeldungDatum,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "gewerbeanmeldungDatum", $$v)
                  },
                  expression: "brokerData.gewerbeanmeldungDatum"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row mt-3 mb-3" }, [
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("InputCheckboxItem", {
                attrs: { label: "KSV Auskunft", isComponentHalfSize: "" },
                on: {
                  input: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.kcvAuskunft,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "kcvAuskunft", $$v)
                  },
                  expression: "brokerData.kcvAuskunft"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c("DatePickerField", {
                attrs: {
                  label: "",
                  isValueAsString: "",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.kcvAuskunftDatum,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "kcvAuskunftDatum", $$v)
                  },
                  expression: "brokerData.kcvAuskunftDatum"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row mt-5" }, [
          _c("div", { staticClass: "col-2" }, [
            _vm._v("Vermögensschadenshaftung")
          ]),
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c("DatePickerField", {
                attrs: {
                  label: "",
                  isValueAsString: "",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.vertragVMshpflDatum,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "vertragVMshpflDatum", $$v)
                  },
                  expression: "brokerData.vertragVMshpflDatum"
                }
              })
            ],
            1
          )
        ]),
        _c("InputRadioBoxGroup", {
          attrs: { values: _vm.vertragVMshpflValues, inlineLabelValues: false },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vertragVMshpfl,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vertragVMshpfl", $$v)
            },
            expression: "brokerData.vertragVMshpfl"
          }
        }),
        _c("InputField", {
          attrs: { label: "Polizzennummer", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.polizzennummer,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "polizzennummer", $$v)
            },
            expression: "brokerData.polizzennummer"
          }
        }),
        _c("InputField", {
          attrs: { label: "wo eingerichtet", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.woEingerichtet,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "woEingerichtet", $$v)
            },
            expression: "brokerData.woEingerichtet"
          }
        }),
        _c("FormDivider"),
        _c("div", { staticClass: "box__title" }, [_vm._v("WAG")]),
        _c("DatePickerField", {
          attrs: {
            label: "Vertriebspartnervertrag abgeschickt",
            isValueAsString: "",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vertragAusgangDatum,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vertragAusgangDatum", $$v)
            },
            expression: "brokerData.vertragAusgangDatum"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Vertriebspartnervertrag zurückerhalten",
            isValueAsString: "",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vertragZurueckDatum,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vertragZurueckDatum", $$v)
            },
            expression: "brokerData.vertragZurueckDatum"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Vertriebspartnervertrag gekündigt",
            isValueAsString: "",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vertragGekuendigtDatum,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vertragGekuendigtDatum", $$v)
            },
            expression: "brokerData.vertragGekuendigtDatum"
          }
        }),
        _c("div", { staticClass: "row mt-3 mb-3" }, [
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("InputCheckboxItem", {
                attrs: { label: "Schulung Einzeltitel" },
                on: {
                  input: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  },
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.schulungEinzeltitelChk,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "schulungEinzeltitelChk", $$v)
                  },
                  expression: "brokerData.schulungEinzeltitelChk"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c("DatePickerField", {
                attrs: {
                  label: "",
                  isValueAsString: "",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.schulungEinzeltitelDatum,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "schulungEinzeltitelDatum", $$v)
                  },
                  expression: "brokerData.schulungEinzeltitelDatum"
                }
              })
            ],
            1
          )
        ]),
        _c("FormDivider"),
        _c("div", { staticClass: "box__title" }, [_vm._v("Versicherungen")]),
        _c("DatePickerField", {
          attrs: {
            label: "Vertriebspartnervertrag abgeschickt",
            isValueAsString: "",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vertragVersichAusgangDatum,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vertragVersichAusgangDatum", $$v)
            },
            expression: "brokerData.vertragVersichAusgangDatum"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Vertriebspartnervertrag zurückerhalten",
            isValueAsString: "",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vertragVersichZurueckDatum,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vertragVersichZurueckDatum", $$v)
            },
            expression: "brokerData.vertragVersichZurueckDatum"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Vertriebspartnervertrag gekündigt",
            isValueAsString: "",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vertragVersichGekuendigtDatum,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vertragVersichGekuendigtDatum", $$v)
            },
            expression: "brokerData.vertragVersichGekuendigtDatum"
          }
        }),
        _c("div", { staticClass: "row mt-3 mb-3" }, [
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("InputCheckboxItem", {
                attrs: { label: "Status" },
                on: {
                  input: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.vertragStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "vertragStatus", $$v)
                  },
                  expression: "brokerData.vertragStatus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c("InputField", {
                attrs: { isComponentHalfSize: true },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.vertragStatusText,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "vertragStatusText", $$v)
                  },
                  expression: "brokerData.vertragStatusText"
                }
              })
            ],
            1
          )
        ]),
        _c("InputCheckboxItem", {
          attrs: { label: "Agent" },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.istMehrfachAgent,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "istMehrfachAgent", $$v)
            },
            expression: "brokerData.istMehrfachAgent"
          }
        }),
        _c("div", { staticClass: "row mt-3 mb-3" }, [
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("InputCheckboxItem", {
                attrs: { label: "Erlaube Maklerauftrag über" },
                on: {
                  input: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.versMehrfachAgentErlaubeAuftrag,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.brokerData,
                      "versMehrfachAgentErlaubeAuftrag",
                      $$v
                    )
                  },
                  expression: "brokerData.versMehrfachAgentErlaubeAuftrag"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c("ComboBox", {
                attrs: {
                  label: "",
                  values: _vm.brokerDataConfig.versMehrfachAgentAuftragValues,
                  isComponentHalfSize: true
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEditedCombo(
                      "brokerData",
                      "versMehrfachAgentAuftrag",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.brokerData.versMehrfachAgentAuftrag,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "versMehrfachAgentAuftrag", $$v)
                  },
                  expression: "brokerData.versMehrfachAgentAuftrag"
                }
              })
            ],
            1
          )
        ]),
        _c("FormDivider"),
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Vermittlung in Deutschland")
        ]),
        _c("DatePickerField", {
          attrs: {
            label: "Anmeldung bei Behörde in Deutschland",
            isValueAsString: "",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vermittlungAnmeldungDate,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vermittlungAnmeldungDate", $$v)
            },
            expression: "brokerData.vermittlungAnmeldungDate"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Bestätigung von Behörde in Deutschland",
            isValueAsString: "",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.vermittlungBestaetigungDate,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "vermittlungBestaetigungDate", $$v)
            },
            expression: "brokerData.vermittlungBestaetigungDate"
          }
        }),
        _c("InputCheckboxItem", {
          attrs: { label: "Formulare von Deutschland freigeben" },
          on: {
            input: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.freigabeFormulare,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "freigabeFormulare", $$v)
            },
            expression: "brokerData.freigabeFormulare"
          }
        }),
        _c("DatePickerField", {
          attrs: {
            isValueAsString: "",
            isComponentHalfSize: "",
            disabled: !_vm.brokerData.freigabeFormulare
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.freigabeFormulareDate,
            callback: function($$v) {
              _vm.$set(_vm.brokerData, "freigabeFormulareDate", $$v)
            },
            expression: "brokerData.freigabeFormulareDate"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }