var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "Höhe des derzeit regelmäßigen Nettoeinkommens (pro Monat)",
          type: "currency",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "financeCompact",
              "einkommenMtlGesamt",
              $event || 0
            )
          }
        },
        model: {
          value: _vm.einkommenMtlGesamt,
          callback: function($$v) {
            _vm.einkommenMtlGesamt = $$v
          },
          expression: "einkommenMtlGesamt"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Aktuelle liquide Vermögenswerte – täglich verfügbare Bankeinlagen (z. B. Tagesgeld, Kontokorrent)",
          type: "currency",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "financeCompact",
              "vermoegenVerfuegbar",
              $event || 0
            )
          }
        },
        model: {
          value: _vm.vermoegenVerfuegbar,
          callback: function($$v) {
            _vm.vermoegenVerfuegbar = $$v
          },
          expression: "vermoegenVerfuegbar"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
          type: "currency",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "financeCompact",
              "vermoegenWertpapiere",
              $event || 0
            )
          }
        },
        model: {
          value: _vm.vermoegenWertpapiere,
          callback: function($$v) {
            _vm.vermoegenWertpapiere = $$v
          },
          expression: "vermoegenWertpapiere"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Immobilienvermögen (ggf. geschätzt)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "financeCompact",
              "vermoegenImmo",
              $event || 0
            )
          }
        },
        model: {
          value: _vm.vermoegenImmo,
          callback: function($$v) {
            _vm.vermoegenImmo = $$v
          },
          expression: "vermoegenImmo"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "gebundene Anlageprodukte / Sonstige Vermögenswerte",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "financeCompact",
              "vermoegenAnlageGebunden",
              $event || 0
            )
          }
        },
        model: {
          value: _vm.vermoegenAnlageGebunden,
          callback: function($$v) {
            _vm.vermoegenAnlageGebunden = $$v
          },
          expression: "vermoegenAnlageGebunden"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Verbindlichkeiten fällig in den nächsten 5 Jahren",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "financeCompact",
              "schuldenKurzfristig",
              $event || 0
            )
          }
        },
        model: {
          value: _vm.schuldenKurzfristig,
          callback: function($$v) {
            _vm.schuldenKurzfristig = $$v
          },
          expression: "schuldenKurzfristig"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Verbindlichkeiten fällig später als 5 Jahre",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "financeCompact",
              "schuldenLangfristig",
              $event || 0
            )
          }
        },
        model: {
          value: _vm.schuldenLangfristig,
          callback: function($$v) {
            _vm.schuldenLangfristig = $$v
          },
          expression: "schuldenLangfristig"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Regelmäßige finanzielle Verpflichtungen (pro Monat)",
          type: "currency",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "financeCompact",
              "ausgabenMtlGesamt",
              $event || 0
            )
          }
        },
        model: {
          value: _vm.ausgabenMtlGesamt,
          callback: function($$v) {
            _vm.ausgabenMtlGesamt = $$v
          },
          expression: "ausgabenMtlGesamt"
        }
      }),
      _vm.showHinweisBetragNiedrig
        ? _c("InputTextArea", {
            attrs: {
              label: _vm.labelHinweisBetragNiedrig,
              disabled: _vm.isDisabled || _vm.isRefusedPage
            },
            on: {
              change: function($event) {
                return _vm.updateStore(
                  "financeCompact",
                  "textHinweisBetragNiedrig",
                  $event
                )
              }
            },
            model: {
              value: _vm.textHinweisBetragNiedrig,
              callback: function($$v) {
                _vm.textHinweisBetragNiedrig = $$v
              },
              expression: "textHinweisBetragNiedrig"
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "row py-1 mx-2 font-bold" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell" },
          [
            _c("CurrencyLabel", {
              attrs: { value: _vm.summeFreiMonatlichKompakt }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row py-1 mx-2 font-bold" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeKapitalKompakt } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c("label", { staticClass: "col ml-0" }, [
        _vm._v(
          " Summe des monatlich für Anlagezwecke frei zur Verfügung stehenden Einkommens "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c("label", { staticClass: "col ml-0" }, [
        _vm._v(
          " Summe des für einmalige Anlagezwecke frei zur Verfügung stehenden Kapitals "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }