<template>
    <div>
        <PageHeaderTitleNavigation
            title="Limits"
            subtitle="Automatisches Depotpositionslimit"
        />

        <div class="box__container">
            <div  class="box__title">{{title}} Automatisches Depotpositionslimit</div>
            <div>
                <div class="paragraph">
                    Zum Setzen von automatischen Limits muss eine prozentuale Abweichung, basierend auf dem Ausgangswert, eingegeben werden. 
                    Soll ein Limit (oberes oder unteres) nicht beachtet werden, so ist dieser Wert leer bzw. 
                    auf 0 zu setzen.
                </div>
                <div class="paragraph">
                    Beispiel: unteres Limit 5% bedeutet eine Abweichung des Wertpapierpreises um 5% nach unten.
                </div>
                <div class="paragraph">
                    Wenn Sie Dynamik auswählen und ein unteres Limit gesetzt ist, so steigt bei steigenden Wertpapierkursen 
                    das untere Limit im gleichen Verhältnis an. Bei sinkenden Kursen bleiben die Limits unverändert.
                </div>
                <div class="font-bold" v-if="!isCustomerOrigin">
                    <div class="paragraph">Kundennummer: {{ form.kundennr }}</div>
                    <div class="paragraph">Kundenname: {{ form.kundenLinkName }}</div>
                </div>
                <ComboBox
                    v-model="form.depot" 
                    :values="depotValues || []"
                    isComponentHalfSize
                />
                <InputField 
                    v-model="form.abweichungUnten"
                    label="Abweichung nach Unten"
                    type="percent"
                    :precision="4"
                    isComponentHalfSize
                />
                <InputField 
                    v-model="form.abweichungOben"
                    label="Abweichung nach Oben"
                    type="percent"
                    :precision="4"
                    isComponentHalfSize
                />
                <InputToggleSwitch v-model="form.isDynamisch" label="Dynamik" inLineLabel suppressValidationMessage isComponentHalfSize />
                <InputToggleSwitch v-model="form.includeNewPositions" label="Gilt auch für neue Depotpositionen <span class='text-small'>(Sollte ein Limit manuell gelöscht werden, so wird dieses auch wieder neu angelegt.)</span>" inLineLabel suppressValidationMessage isComponentHalfSize />
                <InputToggleSwitch v-model="form.removeOldLimits" label="Vor dem Anlegen alte Limits löschen <span class='text-small'>(Wenn ein Virtuelles Depot ausgewählt wurde, werden nur dort Limits gelöscht, ansonsten werden alle Limits für Depotpositionen dieses Kunden gelöscht.)</span>" inLineLabel suppressValidationMessage isComponentHalfSize />
                <div class="text-small my-2">
                    Es werden nur Limits angelegt, wenn die entsprechende Depotposition noch kein Limit besitzt. Wenn es mehrere automatische Limits gibt, hat ein Limit, 
					bei dem ein Depot angegeben wurde, eine höhere Priorität als wenn "Alle Depotpositionen" ausgewählt wurde. Es gibt immer maximal ein Limit pro Depotposition. 
                </div>
                <InputTextArea v-if="!isCustomerOrigin"
                    label="Bemerkung (für Kunden nicht sichtbar)"
                    v-model="form.bemerkung"
                    isComponentHalfSize
                />
            </div>
        </div>
        <BaseModal
            ref="modalError"
            modalTitle="Fehler"
            labelButtonConfirm="Ok"
            labelButtonCancel="Änderungen verwerfen"
            :showCloseButton="false"
            @onCancelButton="confirmError"
            @onConfirmButton="nextRoute = false">
            <div class="mb-2">Diese Fehler müssen korrigiert werden um speichern zu können:</div>
            <span v-html="sanitize(infoFromService)"></span>
        </BaseModal>
        <BaseModal
            ref="modalInfo"
            modalTitle="Hinweis"
            labelButtonConfirm="Trotzdem speichern"
            labelButtonCancel="Änderungen verwerfen"
            :showCloseButton="false"
            @onCancelButton="confirmError"
            @onConfirmButton="confirmSave">
            <!-- <div>Diese Fehler müssen korrigiert werden um speichern zu können:</div> -->
            <span v-html="sanitize(infoFromService)"></span>
        </BaseModal>
    </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import mixin from '@/mixins/limit/limit-mixin.js';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import LIMIT_TYPES from '@/store/limit/types';
import { mapGetters } from 'vuex';
import BaseModal from "@/components/core/BaseModal.vue";
import {sanitize} from '@/helpers/string-helper.js';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from "@/helpers/log-message-helper";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'

export default {
    mixins: [mixin],
    components: {
        ComboBox,
        InputField,
        InputToggleSwitch,
        InputTextArea,
        BaseModal,
        PageHeaderTitleNavigation,
    },
    data() {
        return {
            form: {},
            depotValues: [],
            infoFromService: null,
            nextRoute: false,
        }
    },
    mounted() {
        this.initForm();
        this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_DEPOTPOSITION_LIMIT_AUTOMATIC, {id: this.id, kundennr: this.kundennr});
    },
    watch: {
        limit(value) {
            this.form = Object.assign(this.form, value);
            this.depotValues = value?.depotValues || [{label: 'Alle Depotpositionen', value: ''}];
        }
    },
    computed: {
        ...mapGetters({
            limit: LIMIT_TYPES.GETTERS.DEPOTPOSITION_LIMIT_AUTOMATIC,
        }),
        title() {
            return this.id === 'neu' ? 'Neue: ' : 'Bearbeiten von: ';
        },
        id() {
            return this.$route.params.id || '';
        },
        kundennr() {
            return this.$route.query.kundennr || '';
        },
    },
    methods: {
        initForm() {
            this.form = {
                depot: '',
                abweichungUnten: '',
                abweichungOben: '',
                isDynamisch: '0',
                includeNewPositions: '0',
                removeOldLimits: '0',
                bemerkung: '',
            }
        },
        sanitize(htmlString) {
            return sanitize(htmlString);
        },
        confirmError() {
            this.nextRoute();
        },
        confirmSave() {
            const data = Object.assign(this.form, {kundennr: this.kundennr, id: this.id, saveConfirmed: '1'});
            this.save(data);
            this.nextRoute();
        },
        save(data = {}) {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.SAVE_DEPOTPOSITION_LIMIT_AUTOMATIC, data).then(response => {
                if (response?.data?.errors?.length) {
                    this.infoFromService = response.data.errors;
                    this.$refs.modalError.open();
                } else if (response?.data?.hinweise?.length) {
                    this.infoFromService = response.data.hinweise;
                    this.$refs.modalInfo.open();
                } else {
                    if (response?.data?.message) {
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, 'info'));
                    }
                    this.nextRoute ? this.nextRoute() : this.$router.push(this.mainPath);
                }
            });
        }
    },
    beforeRouteLeave(to, from, next) {
        this.nextRoute = next;
        const data = Object.assign(this.form, {kundennr: this.kundennr, id: this.id });
        this.save(data);
    },
}
</script>

<style>
.paragraph {
    margin-bottom: 0.80rem;
}
</style>