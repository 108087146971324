var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasNews ? _c("hr") : _vm._e(),
      _c("EditSourceNewsFeed"),
      _vm.hasNews
        ? _c("div", { staticClass: "news_header" }, [
            _vm._v("Aktueller Marktbericht")
          ])
        : _vm._e(),
      _vm.hasNews
        ? _c(
            "div",
            { staticClass: "news-content" },
            _vm._l(_vm.getNews, function(news, idx) {
              return _c("div", { key: idx, staticClass: "news-row" }, [
                _c("div", { staticClass: "news_date" }, [
                  _vm._v(_vm._s(_vm.getDate(news.pubDate)))
                ]),
                _c("div", { staticClass: "news_title" }, [
                  _c("a", { attrs: { href: news.link, target: "_blank" } }, [
                    _vm._v(_vm._s(news.title))
                  ])
                ]),
                _c("div", { staticClass: "news_desc" }, [
                  _vm._v(_vm._s(news.desc) + " [...]")
                ]),
                _c("hr")
              ])
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }