<template>
  <InputCheckBoxGroup 
    :title="title"
    :value="checkBoxValue"
    :values="checkBoxValues"
    :validateUntouched="validateUntouched"
    :suppressValidationMessage="suppressValidationMessage"
    :disabled="disabled"
    @checkBoxToggle="onCheckBoxToggle"
  />
</template>

<script>
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'

const VALUE_SEPARATOR = '&';

export default {
  mixins: [validatorComponentUtils],
  props: {
    title: {
      type: String,
      default: ''
    },
    values: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: () => '',
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internValue: [],
    };
  },
  computed: {
    checkBoxValue() {
      return this.internValue.reduce((acc, value) => ({
          ...acc,
          [value]: true,
        }), {});
    },
    checkBoxValues() {
      return this.values.map(v => ({
          ...v,
          path: 'path' in v ? v.path : v.value,
        }));
    },
  },
  watch: {
    value: {
      handler() {
        this.internValue = this.value ? this.value.split(VALUE_SEPARATOR).map(v => decodeURIComponent(v).replace(/\+/g, ' ')) : [];
      },
      immediate: true,
    },
  },
  methods: {
    onInput($event) {
      this.$emit('input', $event);
      this.$runValidator($event);
    },
    onCheckBoxToggle(objValue) {
      const value = Object.keys(objValue).join('');
      const index = this.internValue.findIndex(v => v == value);

      if(Object.keys(objValue).every(key => objValue[key])) {
        if(index >= 0) {
          this.internValue[index] = value;
        } else {
          this.internValue.push(value);
        }
      } else if(index >= 0) {
        this.internValue.splice(index, 1);
      }

      this.onInput(this.internValue.map(v => encodeURIComponent(v).replace(/(%20|\s)/g, '+')).join(VALUE_SEPARATOR));
    },
  },
  components: {
    InputCheckBoxGroup,
  },
}
</script>

<style scoped>

</style>
