var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.stepParameters &&
    _vm.stepParameters.data &&
    _vm.stepParameters.data.parameterData
      ? _c("div", [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("ComboBox", {
                attrs: {
                  label: "SRI",
                  values: _vm.stepParameters.data.sriAuswahl,
                  hint:
                    "Hier wird die SRI des Kunden laut Anlegerprofil übernommen",
                  isComponentHalfSize: true
                },
                on: { change: _vm.markModified },
                model: {
                  value: _vm.stepParameters.data.parameterData.sri,
                  callback: function($$v) {
                    _vm.$set(_vm.stepParameters.data.parameterData, "sri", $$v)
                  },
                  expression: "stepParameters.data.parameterData.sri"
                }
              }),
              _c("input-field", {
                attrs: {
                  label: "Wunschrendite ",
                  hint: "Geben Sie hier die Zielrendite Ihres Kunden ein",
                  validateUntouched: true,
                  type: "percent",
                  isComponentHalfSize: true
                },
                on: { change: _vm.markModified },
                model: {
                  value: _vm.stepParameters.data.parameterData.wunschrendite,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.stepParameters.data.parameterData,
                      "wunschrendite",
                      $$v
                    )
                  },
                  expression: "stepParameters.data.parameterData.wunschrendite"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Zugrunde liegende Zeitperiode",
                  values: _vm.stepParameters.data.timeperiodAuswahl,
                  hint:
                    "Hier wird die SRI des Kunden laut Anlegerprofil übernommen",
                  isComponentHalfSize: true
                },
                on: { change: _vm.markModified },
                model: {
                  value: _vm.stepParameters.data.parameterData.timeperiod,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.stepParameters.data.parameterData,
                      "timeperiod",
                      $$v
                    )
                  },
                  expression: "stepParameters.data.parameterData.timeperiod"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("FormHeader", { attrs: { label: "Limitierung" } }),
              _c("label", { staticClass: "input-forms__label-container" }, [
                _c("div", { staticClass: "input-forms__label-content" }, [
                  _vm._v("pro Fonds")
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "input-forms__input-container forms__input--half-size"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row my-0" },
                      [
                        _c("input-field", {
                          staticClass: "col-6 pb-0",
                          attrs: { validateUntouched: true, type: "percent" },
                          on: { change: _vm.markModified },
                          model: {
                            value:
                              _vm.stepParameters.data.parameterData
                                .percentagePerFundMin,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.stepParameters.data.parameterData,
                                "percentagePerFundMin",
                                $$v
                              )
                            },
                            expression:
                              "stepParameters.data.parameterData.percentagePerFundMin"
                          }
                        }),
                        _c("input-field", {
                          staticClass: "col-6 pb-0",
                          attrs: { validateUntouched: true, type: "percent" },
                          on: { change: _vm.markModified },
                          model: {
                            value:
                              _vm.stepParameters.data.parameterData
                                .percentagePerFundMax,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.stepParameters.data.parameterData,
                                "percentagePerFundMax",
                                $$v
                              )
                            },
                            expression:
                              "stepParameters.data.parameterData.percentagePerFundMax"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "input-forms__hint-content col-12 mt-0" },
                  [
                    _vm._v(
                      "Limitieren Sie hier den Anteil je Einzelfonds innerhalb der Depotoptimierung (gilt nicht für die gesperrten Positionen)"
                    )
                  ]
                )
              ]),
              _c("label", { staticClass: "input-forms__label-container" }, [
                _c("div", { staticClass: "input-forms__label-content" }, [
                  _vm._v("pro Assetklasse")
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "input-forms__input-container forms__input--half-size"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row my-0" },
                      [
                        _c("input-field", {
                          staticClass: "col-6 pb-0",
                          attrs: { validateUntouched: true, type: "percent" },
                          on: { change: _vm.markModified },
                          model: {
                            value:
                              _vm.stepParameters.data.parameterData
                                .percentagePerAssetclassMin,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.stepParameters.data.parameterData,
                                "percentagePerAssetclassMin",
                                $$v
                              )
                            },
                            expression:
                              "stepParameters.data.parameterData.percentagePerAssetclassMin"
                          }
                        }),
                        _c("input-field", {
                          staticClass: "col-6 pb-0",
                          attrs: { validateUntouched: true, type: "percent" },
                          on: { change: _vm.markModified },
                          model: {
                            value:
                              _vm.stepParameters.data.parameterData
                                .percentagePerAssetclassMax,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.stepParameters.data.parameterData,
                                "percentagePerAssetclassMax",
                                $$v
                              )
                            },
                            expression:
                              "stepParameters.data.parameterData.percentagePerAssetclassMax"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "input-forms__hint-content col-12 mt-0" },
                  [
                    _vm._v(
                      "Limitieren Sie hier den maximalen Anteil einer Assetklasse innerhalb der Optimierung"
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("FormHeader", { attrs: { label: "Max. Anzahl" } }),
              _c("input-field", {
                attrs: {
                  label: "Fonds im Depot",
                  validateUntouched: true,
                  type: "number",
                  isComponentHalfSize: true
                },
                on: { change: _vm.markModified },
                model: {
                  value: _vm.stepParameters.data.parameterData.candidatesMax,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.stepParameters.data.parameterData,
                      "candidatesMax",
                      $$v
                    )
                  },
                  expression: "stepParameters.data.parameterData.candidatesMax"
                }
              }),
              _c("input-field", {
                attrs: {
                  label: " Fonds Pro Assetklasse",
                  validateUntouched: true,
                  type: "number",
                  isComponentHalfSize: true
                },
                on: { change: _vm.markModified },
                model: {
                  value:
                    _vm.stepParameters.data.parameterData
                      .candidatesPerAssetclassMax,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.stepParameters.data.parameterData,
                      "candidatesPerAssetclassMax",
                      $$v
                    )
                  },
                  expression:
                    "stepParameters.data.parameterData.candidatesPerAssetclassMax"
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Einschränkungen")]),
        _vm.loading
          ? _c("GhostLoading", [_c("Block", { attrs: { height: "200" } })], 1)
          : _vm.rows.length
          ? _c("Table", {
              ref: "tblRestriction",
              attrs: {
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "unique",
                hidePagination: ""
              },
              on: {
                "action-FACTSHEET": function($event) {
                  return _vm.viewDocument($event.FACTSHEET)
                },
                "action-FACTSHEET_KAG": function($event) {
                  return _vm.viewDocument($event.FACTSHEET_KAG)
                },
                "action-PRIIP_BIB": function($event) {
                  return _vm.viewDocument($event.PRIIP_BIB_URL)
                },
                "action-EDIT": function($event) {
                  return _vm.edit($event.isin)
                },
                "click-name": _vm.gotoFondsinfo
              },
              scopedSlots: _vm._u([
                {
                  key: "current",
                  fn: function(row) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "edited-row",
                          on: {
                            click: function($event) {
                              return _vm.edit(row.isin)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { class: { selected: _vm.edit_isin == row.isin } },
                            [
                              _c("PhPencilLine", {
                                style: {
                                  opacity:
                                    _vm.edit_isin == row.isin ? "1" : "0.3"
                                },
                                attrs: { size: 18 }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          : _c("NoData", { attrs: { noIcon: true } })
      ],
      1
    ),
    _vm.rows.length
      ? _c("div", { staticClass: "box__container" }, [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Positionen bearbeiten")
          ]),
          _c("div", { staticClass: "box__title" }, [
            _vm._v(_vm._s(_vm.edit_name))
          ]),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("InputField", {
                staticClass: "col-12 col-sm-6",
                attrs: {
                  type: "percent",
                  precision: "6",
                  label: "minimaler Anteil"
                },
                on: { change: _vm.rowEdited },
                model: {
                  value: _vm.minProc,
                  callback: function($$v) {
                    _vm.minProc = $$v
                  },
                  expression: "minProc"
                }
              }),
              _c("InputField", {
                staticClass: "col-12 col-sm-6",
                attrs: {
                  type: "percent",
                  precision: "6",
                  label: "maximaler Anteil"
                },
                on: { change: _vm.rowEdited },
                model: {
                  value: _vm.maxProc,
                  callback: function($$v) {
                    _vm.maxProc = $$v
                  },
                  expression: "maxProc"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { isSecondary: true, disabled: _vm.edit_idx <= 0 },
                  on: {
                    click: function($event) {
                      return _vm.changeRow(-1)
                    }
                  }
                },
                [_vm._v("Vorherige Zeile")]
              ),
              _c(
                "BaseButton",
                {
                  attrs: {
                    isSecondary: true,
                    disabled:
                      _vm.edit_idx < 0 || _vm.edit_idx > _vm.rows.length - 2
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeRow(1)
                    }
                  }
                },
                [_vm._v("Nächste Zeile")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }