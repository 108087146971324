var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "zusaetzlichenModal",
          attrs: { modalTitle: "Zusätzliche Daten", showDefaultButtons: false },
          on: {
            close: function($event) {
              return _vm.resetModalState()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Zusätzliche Daten ")]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "BaseButton",
                        {
                          on: {
                            click: function($event) {
                              return _vm.hinzufuegen()
                            }
                          }
                        },
                        [_vm._v("Hinzufügen")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "" },
                          on: {
                            click: function($event) {
                              return _vm.abbrechen()
                            }
                          }
                        },
                        [_vm._v("Abbrechen")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _vm.selectedStrukturZusaetzlicheInfos.length
                        ? _c(
                            "BaseButton",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.anwenden()
                                }
                              }
                            },
                            [_vm._v("Anwenden")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "BaseButton",
                        {
                          on: {
                            click: function($event) {
                              return _vm.zusaetzlicheInformationenAuswaehlen()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.showingKundenZusaetzlicheInformationen
                                ? "Zurück"
                                : "ausgewählten Zusätzliche Informationen"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _vm.mainTableRowsCalculated.length
                ? _c("Table", {
                    attrs: {
                      tableId: "289595ed-2dca-40af-90d6-ff9330d88005",
                      headers: _vm.mainTableHeaders,
                      rows: _vm.mainTableRowsCalculated,
                      rowsPerPage: 5
                    },
                    on: {
                      "click-kundennr": _vm.loadSubtable,
                      "action-delete": function($event) {
                        return _vm.deleteMainTableRow($event)
                      }
                    }
                  })
                : _vm.rows.length === 0
                ? _c("NoData")
                : _vm._e(),
              !_vm.showingKundenZusaetzlicheInformationen
                ? [
                    !_vm.selectedCustomer
                      ? _c("p", [
                          _vm._v(
                            "Kunden Zusätzliche Informationen (für aktuell ausgewählten Kunden)"
                          )
                        ])
                      : _c("p", [
                          _vm._v(
                            "Kunden Zusätzliche Informationen (für Kunde " +
                              _vm._s(_vm.selectedCustomer) +
                              ")"
                          )
                        ]),
                    _vm.secondaryTableRowsCalculated.length
                      ? _c("Table", {
                          attrs: {
                            tableId: "6490907e-d880-42c3-b5ca-a91c93ccabea",
                            headers: _vm.secondaryTableHeaders,
                            rows: _vm.secondaryTableRowsCalculated,
                            rowsPerPage: 5
                          },
                          on: {
                            "action-delete": function($event) {
                              return _vm.deleteSecondaryTableRow($event)
                            }
                          }
                        })
                      : _vm.rows.length === 0
                      ? _c("NoData")
                      : _vm._e(),
                    _c("InputField", {
                      attrs: {
                        type: "text",
                        placeholder: "Neuen Eintrag anlegen"
                      },
                      model: {
                        value: _vm.inputZusaetzlicheDaten,
                        callback: function($$v) {
                          _vm.inputZusaetzlicheDaten = $$v
                        },
                        expression: "inputZusaetzlicheDaten"
                      }
                    })
                  ]
                : _vm._e(),
              _vm.showingKundenZusaetzlicheInformationen
                ? [
                    _c("p", [_vm._v("Kunden zusätzliche Informationen")]),
                    _c("p", [
                      _vm._v(
                        "Achtung: Es können nur Informationen hinzugefügtwerden, es werden keine Einträge beim Kunden gelöscht"
                      )
                    ]),
                    _vm.alleStrukturZusaetzlicheInfosCalculated.length
                      ? _c("Table", {
                          attrs: {
                            tableId: "2f85b4a9-f5a2-4c5d-9914-62b5bd023210",
                            headers: _vm.kundenZusaetzlicheInformationenHeaders,
                            rows: _vm.alleStrukturZusaetzlicheInfosCalculated,
                            rowsPerPage: 5,
                            selected: _vm.selectedStrukturZusaetzlicheInfos
                          },
                          on: { selected: _vm.onSelect }
                        })
                      : _vm.rows.length === 0
                      ? _c("NoData")
                      : _vm._e(),
                    _c("InputField", {
                      attrs: {
                        type: "text",
                        placeholder: "Neuen Eintrag anlegen"
                      },
                      model: {
                        value: _vm.inputZusaetzlicheDaten,
                        callback: function($$v) {
                          _vm.inputZusaetzlicheDaten = $$v
                        },
                        expression: "inputZusaetzlicheDaten"
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }