import { mapGetters } from 'vuex';
import RUHESTANDSPLANUNG_TYPES from '@/store/ruhestandsplanung/types';
import CORE_TYPES from '@/store/core/types';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import {formatNumber, parse} from '@/helpers/number-formatter.js';
import dayjs from 'dayjs';
import { chartColorScheme } from '@/components/retirementScenario/RetirementChart.vue';
import {DatePickerUtils} from '@/components/core/forms/DatePicker/date-picker-utils.js';
import FinancialCalculator, { manLifeYears, womanLifeYears } from '@/components/retirementScenario/financialCalculator.js';

const mixin = {
  data() {
    return {
      selectedVermoegen: [],
      selectedAusgaben: [],
      selectedGoals: [],
      apexMarkers: {},
      now: new Date(),
      dataPoints: [],
    }
  },
  computed: {
    ...mapGetters({
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      screenSize: CORE_TYPES.GETTERS.SCREEN_WIDTH,
      scenarios: RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIOS,
      vermoegen: RUHESTANDSPLANUNG_TYPES.GETTERS.VERMOEGEN,
      ausgaben: RUHESTANDSPLANUNG_TYPES.GETTERS.AUSGABEN,
      goals: MY_GOALS_TYPES.GETTERS.GOALS,
      scenariosUrsprung: RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIOS_URSPRUNG,
    }),
    id () {
      return this.$route.params?.id || '';
    },
    isNewScenario() {
      return this.id === 'new' || false;
    },
    path() {
      return this.$route.matched[1]?.path || '/service/ruhestandsplanung';
    },
    scenario() {
      const scenario = this.id && this.scenarios?.find(scenario => scenario?.id === this.id) || {};
      // set all the vermögen and ausgaben as selected for the new scenario
      if (this.id === 'new' && !Object.keys(scenario)?.find(key => key === 'vermoegen')) {
        this.onSelectedVermoegen(this.vermoegen)
        this.onSelectedAusgaben(this.ausgaben)
      } else {
        this.selectedVermoegen = scenario?.vermoegen?.filter(vermoegen => vermoegen.selected) || [];
        this.selectedAusgaben = scenario?.ausgaben?.filter(ausgabe => ausgabe.selected) || [];
        this.initSelectedGoals(scenario);
      }
      return scenario;
    },
    scenarioUrsprung() {
      return !this.isNewScenario && this.scenariosUrsprung?.find(scenario => scenario.id === this.scenario.id) || null;
    },
    lifeLength() {
      const mapLifeYears = this.scenario.gender === 'm' ? manLifeYears.map(v => Math.round(v)) : womanLifeYears.map(v => Math.round(v));
      return mapLifeYears[this.getAgeTo(new Date().getFullYear())] + this.getAgeTo(new Date().getFullYear()) || 70;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    dauerRenteInMonaten() {
      return (this.lebenserwartung(this.scenario) - this.alterRente) * 12 || 0;
    },
    alterRente() {
      return this.scenario.alterRente || 67;
    },
    renditeDurchschnitlich() {
      return 4 / 100;
    },
    sparrate() {
      return this.scenario.scenarioGoalList?.filter(goal => this.isSparrate(goal) && goal.selected)?.map(goal => goal.betrag / (goal.frequenz === 'JAERLICH' ? 12 : 1))?.reduce(
        (acc, curr) => acc + curr, 0);
    },
    transaktionen() {
      const trans = {};
      this.goals?.filter(goal => this.scenario.myGoalList?.some(g => g.id === goal.id))?.filter(goal => goal.endDate && goal.zielsumme)?.forEach(goal => {
        let jahr = dayjs(goal.endDate, 'DD.MM.YYYY', true).toDate().getFullYear(); 
        trans[jahr] = (trans[jahr] || []).concat(-goal.zielsumme);
      });
      this.scenario.scenarioGoalList?.filter(goal => !this.isSparrate(goal) && goal.selected)?.forEach(goal => {
        const startDate = dayjs(goal.startDate, 'DD.MM.YYYY', true).toDate().getFullYear();  
        if (this.isPeriodischeTransaktion(goal)) {
          const endDate = goal.endDate || this.retirementYear;
          for(let jahr = startDate; jahr <= endDate; jahr++) {
            trans[jahr] = (trans[jahr] || []).concat(goal.betrag * (goal.frequenz === 'JAERLICH' ? 1 : 12) * (goal.zuflussType === 'AUSGABE' ? -1 : 1));
          }
        } else {
          if (goal?.startDate) {
            trans[startDate] = (trans[startDate] || []).concat(goal.zuflussType === 'AUSGABE' ? -goal.betrag : goal.betrag);
          }
        }
      })
     
      return trans;
    },
    tilgungsplanRowsCurrentScenario() {
      return this.tilgungsplanRows(this.scenario) || [];
    },
    apexSeriesCurrentScenario() {
      return this.apexSeries(this.scenario);
    },
    apexXaxisCurrentScenario() {
      return this.apexXaxis(this.scenario);
    },
    apexAnnotationsCurrentScenario() {
      return this.apexAnnotations(this.scenario);
    },
    retirementYearCurrentScenario() {
      return this.retirementYear(this.scenario);
    },
    renditeDepotGewichtetCurrentScenario() {
      return this.renditeDepotGewichtet(this.scenario);
    },
  },
  methods: {
    onSelectedVermoegen(data = []) {
      const unselected = this.selectedVermoegen?.filter(sel => !data?.some(elem => elem.name === sel.name));
      this.selectedVermoegen = data || [];
      this.selectedVermoegen?.forEach(sel => sel?.name && this.updateVermoegenAusgaben( { name: sel.name, selected: true }, 'vermoegen'));
      unselected?.forEach(sel => sel?.name &&  this.updateVermoegenAusgaben({ name: sel.name, selected: false }, 'vermoegen'));
    },
    onSelectedAusgaben(data = []) {
      const unselected = this.selectedAusgaben?.filter(sel => !data?.some(elem => elem.name === sel.name));
      this.selectedAusgaben = data || [];
      this.selectedAusgaben?.forEach(sel => sel?.name && this.updateVermoegenAusgaben({ name: sel.name,  selected: true }, 'ausgaben'));
      unselected?.forEach(sel => sel?.name && this.updateVermoegenAusgaben({ name: sel.name, selected: false }, 'ausgaben'));
    },
    updateVermoegenAusgaben(row, type) {
      if (row && type) {
        if (type === 'ausgaben' && Object.keys(row)?.includes('value') && row.value > 0) {
          row.value *= -1;
        }
        this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_VERMOEGEN_AUSGABEN, {
          id: this.id, 
          type: type,
          row: row
        });
      }
    },
    lebenserwartung(scenario = {}) {
      return scenario.lebenserwartung || this.lifeLength;
    },
    gesamtVermoegen(scenario = {}) {
      const result = (scenario.vermoegen?.filter(v => v.selected) || [])?.reduce((acc, curr) => {
        const value = this.vermoegen?.find(verm => verm.name === curr.name)?.value || 0;
        acc = parseFloat(acc) + (parseFloat(value) || 0);
        return acc;
      }, 0.0) 
        + (scenario.ausgaben?.filter(v => v.selected) || [])?.reduce((acc, curr) => {
        const value = this.ausgaben?.find(verm => verm.name === curr.name)?.value || 0;
        acc = parseFloat(acc) + (parseFloat(value) || 0);
        return acc;
      }, 0.0)
      return result;
    },
    wunschrenteMitInflation(scenario = {}) {
      const rente = scenario.bedarfMonatlich 
        ? FinancialCalculator.calculateFutureValue(scenario.bedarfMonatlich, this.lebenserwartungJahr(scenario) - this.retirementYear(scenario) + 1, 0, scenario.inflation/100, 1) : 0;
      return parseFloat(parseFloat(rente).toFixed(0))
    },
    lebenserwartungJahr(scenario = {}) {
      return this.geburtsjahr(scenario) + this.lebenserwartung(scenario);
    },
    retirementYear(scenario = {}) {
      return this.geburtsjahr(scenario) + (scenario.alterRente || 67) + 1;
    },
    geburtsjahr(scenario = {}) {
      const geburtstag = dayjs(scenario.geburtsdatum || '', 'DD.MM.YYYY').toDate();
      return geburtstag?.getFullYear() || 0;
    },
    chartAusgaben(scenario = {}) {
      const ausgaben = {};
      const retirementYear = this.retirementYear(scenario);
      const wunschrenteMitInflation = this.wunschrenteMitInflation(scenario) * 12;
      this.goals?.filter(goal => scenario.myGoalList?.some(g => g.id === goal.id))?.filter(goal => goal.endDate && goal.zielsumme)?.forEach(goal => {
        let jahr = dayjs(goal.endDate, 'DD.MM.YYYY', true).toDate().getFullYear(); 
        ausgaben[jahr] = (ausgaben[jahr] || 0) - goal.zielsumme;
      });
      scenario.scenarioGoalList?.filter(goal => goal.selected && goal.zuflussType === 'AUSGABE')?.forEach(goal => {
        const endDate = dayjs(goal.endDate, 'MM.YYYY', true).toDate().getFullYear() || (retirementYear - 1);
        if (this.isPeriodischeTransaktion(goal)) {
          const startDate = dayjs(goal.startDate, 'MM.YYYY', true).toDate().getFullYear() || null;  
          for(let jahr = startDate; jahr <= endDate; jahr++) {
            ausgaben[jahr] = (ausgaben[jahr] || 0) - goal.betrag * (goal.frequenz === 'JAERLICH' ? 1 : 12) ;
          }
        } else {
          if (endDate) {
            ausgaben[endDate] = (ausgaben[endDate] || 0) - goal.betrag;
          }
        }
      })
     
      for (let j = retirementYear; j <= this.lebenserwartungJahr(scenario); j++) {
        ausgaben[j] = ( ausgaben[j] || 0) - wunschrenteMitInflation;
      }

      return ausgaben;
    },
    chartEinnhamen(scenario = {}) {
      const einnahmen = {};
      scenario.scenarioGoalList?.filter(goal => goal.selected && goal.zuflussType === 'EINNAHME')?.forEach(goal => {
        let endDate = dayjs(goal.endDate, 'MM.YYYY', true).toDate().getFullYear(); 
        if (this.isPeriodischeTransaktion(goal)) {
          endDate = endDate || (this.retirementYear(scenario) - 1);
          const startDate = dayjs(goal.startDate, 'MM.YYYY', true).toDate().getFullYear(); 
          for(let jahr = startDate; jahr <= endDate; jahr++) {
            einnahmen[jahr] = (einnahmen[jahr] || 0) + goal.betrag * (goal.frequenz === 'JAERLICH' ? 1 : 12);
          }
        } else {
          if (endDate) {
            einnahmen[endDate] = (einnahmen[endDate] || 0) + goal.betrag;
          }
        }
      })
      for (let j = this.retirementYear(scenario); j <= this.lebenserwartungJahr(scenario); j++) {
        einnahmen[j] = (einnahmen[j] || 0) + (scenario.renteMonatlichErwartet || 0) * 12;
      }
      return einnahmen;
    },
    renditeDepotGewichtet(scenario = {}) {
      const vermoegenList = scenario.vermoegen?.filter(curr => curr.selected && curr.value);
      const summe = vermoegenList?.reduce((acc, curr) => acc + curr.value, 0);
      const rendite = vermoegenList?.reduce((acc, curr) => acc + (curr.rendite || 1) * curr.value / summe, 0) || 0;
      return parse(formatNumber((rendite / 100 || this.renditeDurchschnitlich) - (this.scenario.inflation / 100 || 0), 5));
    },
    apexXaxis(scenario = {}) {
      const jahre = []
      for (let jahr = this.currentYear; jahr <= this.lebenserwartungJahr(scenario); jahr++) {
        jahre.push(jahr);
      }
      return {
        categories: jahre,
      }
    },
    apexSeries(scenario = {}) {  
      return [{
        // name: 'Euro',
        data: this.tilgungsplanRows(scenario).map(row => row.summe || 0)
      }];
    },
    apexAnnotations(scenario = {}) {
      const events = {};
      events[this.retirementYear(scenario) - 1] = {
        x: this.retirementYear(scenario) - 1,
        label: {
          text: 'Renteneintritt'
        }
      };
      events[this.lebenserwartungJahr(scenario)] = {
        x: this.lebenserwartungJahr(scenario),
        label: {
          text: 'Lebenserwartung'
        }
      };
      scenario.myGoalList?.concat(...scenario.scenarioGoalList?.filter(goal => goal.selected && !this.isPeriodischeTransaktion(goal)) || [])
        .filter(goal => goal.endDate)
        .forEach(goal => {
          const jahr = dayjs(goal.endDate, goal.zuflussType ? 'MM.YYYY' : 'DD.MM.YYYY', true).toDate().getFullYear();
          if (jahr && !events[jahr]?.label?.text?.endsWith('...')) {
            events[jahr] = {
              x: jahr,
              label: {
                text: events[jahr]?.label?.text && !events[jahr]?.label?.text.endsWith('...') ? (events[jahr]?.label?.text + ' und weitere ...') : goal.title
              }
            };
          }
        });
      const annotations = { xaxis: Object.values(events)};
      return annotations;
    },
    tilgungsplanRows(scenario = {}) {
      const rows = [];
      let depotwert = this.gesamtVermoegen(scenario);
      const retirementYear = this.retirementYear(scenario);
      const lebenserwartungJahr = this.lebenserwartungJahr(scenario);
      const renditeDepotGewichtet = this.renditeDepotGewichtet(scenario);
      const ausgaben = this.chartAusgaben(scenario) || {};
      const einnahmen = this.chartEinnhamen(scenario) || {};
      let summe = depotwert + (einnahmen[this.currentYear] || 0) + (ausgaben[this.currentYear] || 0);

      rows.push({jahr: this.currentYear, depotwert: depotwert, einnahmen: einnahmen[this.currentYear] || 0, ausgaben: ausgaben[this.currentYear] || 0, 
        summe: summe, rendite: renditeDepotGewichtet * 100});

      for (let jahr = this.currentYear + 1; jahr <= lebenserwartungJahr; jahr++) {      
        const rendite = jahr >= retirementYear ? (this.scenario.renditeRuhestand || 0) / 100 : renditeDepotGewichtet;
        
        depotwert = summe 
          + (summe > 0 ? summe * rendite : 0)
          + (einnahmen[jahr] || 0 > 0 ? (einnahmen[jahr] || 0) * rendite : 0);

        summe = depotwert + (einnahmen[jahr] || 0) + (ausgaben[jahr] || 0);
        rows.push({jahr: jahr, depotwert: depotwert, einnahmen: einnahmen[jahr] || 0, ausgaben: ausgaben[jahr] || 0, summe: summe, rendite: rendite * 100});
      }
      return rows;
    },
    async saveScenario() {
      await this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.SAVE_SCENARIO, this.id).then(response => {
        if (this.id === 'new' && response?.id) {
          this.$router.push(`${this.path}/${response.id}/${this.stepKey}`)
        }
      });
    },
    isPeriodischeTransaktion(goal) {
      return goal?.frequenzType === 'PERIODISCH';
    },
    isSparrate(goal) {
      return goal?.type === 'sparquote' || goal.type === 'periodisch_einnahme';
    },
    initSelectedGoals(scenario) {
      this.selectedGoals = scenario?.myGoalList?.map(goal => { return {
        ...goal, 
        betrag: goal.zielsumme,
        goalType: 'MY_GOAL'
      }}) || [];
      this.selectedGoals.push(...scenario?.scenarioGoalList?.filter(goal => goal.selected)
        .map(goal => { return {
          ...goal, 
          goalType: 'SCENARIO_GOAL'
        }}) || []);
    },
    formatValue(value = '', type = '') {
        switch (type) {
            case 'percent':
                return !value ? '' : (formatNumber(value) + ' %');
            case 'currency': 
                return !value ? '' : (formatNumber(value) + ' €');
            case 'age': 
                return !value ? '' : (value + ' Jahre');
            default:
                return value;
        }
    },
    getAgeTo(year) {
        if (!this.geburtsjahr || !year) {
          return;
        }
        const yearVal = year.split && year.split('.');
        const theYear = yearVal && yearVal[2] ? yearVal[2] : year;
        return theYear - this.geburtsjahr || 0;
    },
    getColor(index) {
        return chartColorScheme[index % chartColorScheme.length];
    },
    getMonthsFromCurrent(date) {
      if (date === undefined || date === null) {
        return 0;
      }
      if (date instanceof Date) {
        if (date < this.now) {
          return 0;
        }
        return DatePickerUtils.safeDate(date).getMonth() - DatePickerUtils.safeDate(this.now).getMonth() +
          (12 * (DatePickerUtils.safeDate(date).getFullYear() - DatePickerUtils.safeDate(this.now).getFullYear()));
      } else {
        const stDate = DatePickerUtils.getDateFromString(date);
        if (stDate < this.now) {
          return 0;
        }
        return stDate.getMonth() - DatePickerUtils.safeDate(this.now).getMonth() +
          (12 * (stDate.getFullYear() - this.now.getFullYear()));
      }
    },
  },
}

export default mixin;