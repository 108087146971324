<template>
  <div @click="openPersonDetails()" class="person-overview__container box__container-wrap">
    <Table
      :table-id="'2b46bde5-af6d-448a-bed9-6b875fb6ef92'"
      :headers="tableHeaders"
      :rows="tableRows"
      hide-pagination
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Table from "@/components/table2/Table.vue"
import {TextColumn} from "@/components/table2/table_util";


export default {
  components: {
    Table,
  },
  props: {
    personList: {},
  },
  data() {
    return {
      collapsed: true,
      tableHeaders : {
        center: [
          TextColumn("Name", "Name"),
          TextColumn("Adresse", "Adresse"),
          TextColumn("Geburtstag", "Geburtstag"),
        ]
      },
    };
  },
  computed: {
    tableRows() {
      let tableRows = [];
      this.personList.forEach((person) => {
        tableRows.push({
          Name: this.getFullName(person),
          Adresse: this.getPersonStreet(person) + " " + this.getPersonCity(person),
          Geburtstag: this.getBirthdate(person) + " " + this.getAge(person),
        });
      });
      return tableRows;
    },
  },
  methods: {
    openPersonDetails(){
      this.$router.push({path: `/persoenlichedaten/customer-data/steps/zusaetzliche-personen`});
    },
    getFullName(person){
      const fullName = person.personalDataAddress || {};
      return `${fullName.titles || ''} ${fullName.firstName || ''} ${fullName.lastName || ''}`.trim();
    },
    getPersonStreet(person){
      const personAddress = person.personalDataAddress;
      const address = [];
      if(personAddress.street) {
        address.push(personAddress.street);
      }
      return address.join(', ');
    },
    getPersonCity(person){
      const personAddress = person.personalDataAddress;
      const address = [];
      if(personAddress.zip || personAddress.city) {
        address.push(`${personAddress.zip || ''} ${ personAddress.city || '' }`.trim());
      }
      return address.join(', ');
    },
    getBirthdate(person){
      const dayOfBirth = person.personalDataBirth?.dayOfBirth;
      if(dayOfBirth) {
        return dayOfBirth;
      }
      return '';
    },
    getAge(person){
      let dayOfBirth = person.personalDataBirth?.dayOfBirth;
      if(dayOfBirth){
        dayOfBirth = dayjs(dayOfBirth, "DD.MM.YYYY");
        const dayEnd = person.personalDataAddress?.dateOfDeath
          ? dayjs(person.personalDataAddress?.dateOfDeath, "DD.MM.YYYY")
          : dayjs();

        return `(${dayEnd.diff(dayOfBirth, 'year')} Jahre)`;
      }
      return "";
    },
    getDeathDate(person) {
      const dateOfDeath = person.dateOfDeath;
      return dateOfDeath ? dayjs(dateOfDeath).format('DD.MM.YYYY') : '';
    },
  },
}
</script>

<style lang='scss' scoped>
table {
  border-collapse: collapse;
}

table th {
  text-align: left;
}

table tr {
  border-bottom: 1px solid #000;
}

table td, table th {
  padding: 10px;
}

.compressedRow p {
  line-height: 2px;
  margin-bottom: 2px;
}

.name{
  word-wrap: break-word;
}
</style>
