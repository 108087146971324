var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { attrs: { title: _vm.accountTypeDescription } },
    [
      _vm.accountType === "BANKACCOUNT"
        ? _c("ph-bank", { attrs: { size: 24 } })
        : _vm.accountType === "CREDITCARD"
        ? _c("ph-credit-card", { attrs: { size: 24 } })
        : _vm.accountType === "DEPOT"
        ? _c("ph-chart-line", { attrs: { size: 24 } })
        : _c("ph-bank", { attrs: { size: 24 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }