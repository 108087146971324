var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.buttonLabel && _vm.rowId
    ? _c(
        "div",
        [
          _c(
            "BaseButton",
            {
              staticClass: "m-0",
              attrs: {
                isSecondary: "",
                disabled: _vm.disabled || _vm.isLoading,
                animated: _vm.isLoading
              },
              on: { click: _vm.applyConfig }
            },
            [_vm._v(_vm._s(_vm.buttonLabel))]
          ),
          _c("OptionsMenuApplyConfigModal", {
            ref: "applyConfigModal",
            attrs: {
              title: _vm.applyConfirmModalTitle,
              rowId: _vm.rowId,
              configContext: _vm.configContext,
              configId: _vm.configId,
              userLevel: _vm.userLevel,
              showFilter: _vm.isPartial
            },
            on: { onConfirm: _vm.confirmApply }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }