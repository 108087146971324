import { v4 as uuidv4 } from 'uuid';

function generateTabId() {
  let value = window.sessionStorage.getItem('unique-id');

  if(!value || !window.tabId) {
    value = uuidv4();
    window.sessionStorage.setItem('unique-id', value);
    window.tabId = value;
  }

  return value;
}

const tabId = generateTabId();

export {
  tabId,
}