<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div class="input-forms__label-content input-forms__label-content--bigger" v-if="title">
            <ph-bank :size="16" />&nbsp;{{title}}
          </div>
        </div>
        <div class="col-auto" v-if="config && config.buttonText">
          <BaseButton :disabled="disabled" @click="openFormsFinder">{{config.buttonText}}</BaseButton>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <div v-if="hasPositions">
        <div>
          <WertpapierAuswahlPositionsFODBKaufTop 
          v-if="config.topSelect"
            :positions="positions"
            :config="config"
            :categoryId="categoryId"
            :antragId="antragId"
            :disabled="disabled"
          />
          <WertpapierAuswahlPositionsFODBKauf 
          v-else
            :positions="positions"
            :config="config"
            :categoryId="categoryId"
            :antragId="antragId"
            :disabled="disabled"
          />

          <WertpapierAuswahlPositionFields 
            v-if="config.positionFields"
            :config="config"
            :categoryId="categoryId"
            :antragId="antragId"
            :disabled="disabled" />
        </div>
      </div>
      <div v-else>
        <div>Wertpapierauswahl ist leer</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import BaseButton from '@/components/core/BaseButton.vue';
import WertpapierAuswahlPositionsFODBKauf from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsFODBKauf.vue';
import WertpapierAuswahlPositionFields from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import WertpapierAuswahlPositionsFODBKaufTop from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsFODBKaufTop.vue';
import { PhBank } from 'phosphor-vue';

export default {
  mixins: [antragNavigationMixin],
  props: {
    title: {
    },
    antragData: {
    },
    config: {
    },
    categoryId: {
    },
    antragId: {
    },
    antragTyp: {
    },
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    BaseButton,
    WertpapierAuswahlPositionsFODBKauf,
    WertpapierAuswahlPositionFields,
    WertpapierAuswahlPositionsFODBKaufTop,
    PhBank,
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA
    }),
    positions() {
      if (this.positionsAll && this.antragId && this.categoryId 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.categoryId]) {
        return this.positionsAll[this.antragId][this.categoryId]
      }
    },
    positionData() {
      if (this.positionDataAll && this.antragId && this.categoryId 
          && this.positionDataAll[this.antragId]
          && this.positionDataAll[this.antragId][this.categoryId]) {
        return this.positionDataAll[this.antragId][this.categoryId]
      }
    },
    hasPositions() {
      return Array.isArray(this.positions) && this.positions.length > 0
    },
    configType() {
      return this.config && this.config.type
    }
  },
  methods: {
   openFormsFinder() {
     this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle);
   }
  }
}
</script>

<style scoped>

</style>