var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: { modalTitle: "Link" },
          on: {
            onCloseButton: function($event) {
              return _vm.close()
            },
            close: function($event) {
              return _vm.close()
            },
            onConfirmButton: _vm.onConfirmButton
          }
        },
        [
          _c("ComboBox", {
            attrs: {
              label: "Link-Typ",
              values: [
                { value: "URL", label: "URL" },
                { value: "Email", label: "E-Mail" }
              ],
              firstEmpty: false
            },
            model: {
              value: _vm.linkType,
              callback: function($$v) {
                _vm.linkType = $$v
              },
              expression: "linkType"
            }
          }),
          _vm.htmlLinkForm.currentSelectionIsEmpty &&
          !_vm.htmlLinkForm.previousUrl
            ? _c("InputField", {
                attrs: { label: "Anzeigentext", placeholder: "" },
                model: {
                  value: _vm.htmlLinkForm.linkText,
                  callback: function($$v) {
                    _vm.$set(_vm.htmlLinkForm, "linkText", $$v)
                  },
                  expression: "htmlLinkForm.linkText"
                }
              })
            : _vm._e(),
          _vm.linkType === "URL"
            ? [
                _c("InputField", {
                  attrs: { label: "URL", placeholder: "https://" },
                  model: {
                    value: _vm.htmlLinkForm.url,
                    callback: function($$v) {
                      _vm.$set(_vm.htmlLinkForm, "url", $$v)
                    },
                    expression: "htmlLinkForm.url"
                  }
                })
              ]
            : [
                _c("InputField", {
                  attrs: { label: "E-Mail-Adresse", placeholder: "" },
                  model: {
                    value: _vm.linkTextEmail,
                    callback: function($$v) {
                      _vm.linkTextEmail = $$v
                    },
                    expression: "linkTextEmail"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Betreffzeile", placeholder: "" },
                  model: {
                    value: _vm.linkTextSubj,
                    callback: function($$v) {
                      _vm.linkTextSubj = $$v
                    },
                    expression: "linkTextSubj"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Nachrichtentext", placeholder: "" },
                  model: {
                    value: _vm.linkTextMsg,
                    callback: function($$v) {
                      _vm.linkTextMsg = $$v
                    },
                    expression: "linkTextMsg"
                  }
                })
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }