import EX_POST_MANUELLE_TYPES from './types';
import { getInitialState } from './index';

import Vue from 'vue';

export default {

  [EX_POST_MANUELLE_TYPES.MUTATIONS.SET_LIST](state, payload) {
    Vue.set(state, 'list', [ ...payload || [], ]);
  },

  [EX_POST_MANUELLE_TYPES.MUTATIONS.SET_COMBOS](state, payload) {
    Vue.set(state, 'combos', { ...payload || {}, });
  },

  [EX_POST_MANUELLE_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

}
