<template>
  <div >
    <PageHeaderTitleNavigation
      :title="pageTitle"
      :actions="actions"
      @action-PRINT="printScreen('printSection')" />

    <div class="box__container">
      <GhostLoading v-if="loading" type="paragraph" />
      <div class="break_word" v-else-if="result" v-html="sanitize(result)" id="printSection">
      </div>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import BRIDGE_TYPES from '@/store/bridge/types';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import {sanitize} from '@/helpers/string-helper.js';
import { mapGetters } from 'vuex';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, PageHeaderDownloadLinkAction } from '@/components/core/header-title-navigation/page-header-utils';

const DEFAULT_TITLE = 'Datenschutz&shy;erklärung';

export default {
  props: {
    registerCode: {
      type: [String, Number],
      default: null,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { 
      version: null,
      type: null,
      result: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      isPendingActionActiveFn: CORE_TYPES.GETTERS.IS_PENDING_ACTION_ACTIVE,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT
    }),
    pageTitle() {
      return this.type === 'AGB_HTML' ? 'AGB' : 'Datenschutz&shy;erklärung';
    },
    actions() {
      return [
        PageHeaderSimpleAction('PRINT', 'Drucken').withVisible(() => !this.isMobileNativeContext),
        PageHeaderDownloadLinkAction('PRINT_DOWNLOAD', 'Drucken', `${this.type}.pdf`, '/service_agb_pdf', {
          type: this.type,
          version: this.version,
          registerCode: this.registerCode,
        }).withVisible(() => this.isMobileNativeContext)
      ]
    },
  },
  mounted() {
    this.version = this.$route.query?.version
    this.type = this.$route.query?.type

    this.findContent();
  },
  methods: {
    async findContent() {
      if (!this.version) return;

      const payload = {
        type: this.type,
        version: this.version
      }

      if(this.registerCode) {
        payload.registerCode = this.registerCode;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(CORE_TYPES.ACTIONS.GET_PRIVACY_CONDITIONS, payload).then(result => { 
          this.result = result
        })
      } finally {
        this.loading = false;
      }
    },
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    printScreen(printSectionId) {
      const printContent = document.getElementById(printSectionId)?.innerHTML;

      if (!printContent) {
        return;
      }

      let printWindow;
      printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write(`<html><head></head><body onload="window.print();requestAnimationFrame(() => window.close())">${printContent}</body></html>`);
      printWindow.document.close();
    }
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    GhostLoading,
  }
}
</script>

<style scoped>
.break_word {
  word-break: break-word;
}
</style>