 <template>
  <div class="zahlwrapper">
    <div @click="addExpresion('1')" class="box norm_button z1">1</div>
    <div @click="addExpresion('2')" class="box norm_button z2">2</div>
    <div @click="addExpresion('3')" class="box norm_button z3">3</div>
    <div @click="addExpresion('4')" class="box norm_button z4">4</div>
    <div @click="addExpresion('5')" class="box norm_button z5">5</div>
    <div @click="addExpresion('6')" class="box norm_button z6">6</div>
    <div @click="addExpresion('7')" class="box norm_button z7">7</div>
    <div @click="addExpresion('8')" class="box norm_button z8">8</div>
    <div @click="addExpresion('9')" class="box norm_button z9">9</div>
    <div @click="addExpresion('0')" class="box norm_button z0">0</div>
    <div @click="addExpresion('.')" class="box norm_button zkomma">.</div>
  </div>
</template>

<script>
import functionMixins from "../functionMixins.js";

export default {
  name: "Zahlen",
  mixins: [functionMixins]
 
};
</script>

<style scoped>
@media only screen and (min-width: 400px) {
  .zahlwrapper {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "z1 z2 z3"
      "z4 z5 z6"
      "z7 z8 z9"
      "z0 z0 zkomma";
  }
}

@media only screen and (max-width: 400px) {
  .zahlwrapper {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "z1 z2"
      "z3 z4"
      "z5 z6"
      "z7 z8"
      "z9 zkomma"
      "z0 z0";
  }
}

.zahlwrapper {
  display: grid;
  grid-gap: 0.5em;
}

.z1 {
  grid-area: z1;
}
.z2 {
  grid-area: z2;
}
.z3 {
  grid-area: z3;
}
.z4 {
  grid-area: z4;
}
.z5 {
  grid-area: z5;
}
.z6 {
  grid-area: z6;
}
.z7 {
  grid-area: z7;
}
.z8 {
  grid-area: z8;
}
.z9 {
  grid-area: z9;
}
.z0 {
  grid-area: z0;
}
.zkomma {
  grid-area: zkomma;
}
</style>  */