import BROKERDATA_TYPES from './types';


export default {
  [BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_EDITED](state) {
    return state.brokerDataEdited
  },
  [BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA](state) {
    return state.brokerData || {};
  },
  [BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_CONFIG](state) {
    return state.brokerDataConfig || {};
  },
  [BROKERDATA_TYPES.GETTERS.GET_ANSPRECHPARTNER_BY_ID](state) {
    return state.ansprechpartner || {};
  },
  [BROKERDATA_TYPES.GETTERS.ANSPRECHPARTNER_LIST](state) {
    return [ ...state.ansprechpartnerList || [], ];
  },
  [BROKERDATA_TYPES.GETTERS.GET_MAKLER_PICTURE](state) {
    return state.maklerPicture || {};
  },
  [BROKERDATA_TYPES.GETTERS.GET_BROKER_BY_ID](state) {
    return state.broker || {};
  },
  [BROKERDATA_TYPES.GETTERS.GET_MAKLER_ANSPRECHPARTNER_DATA_EDITED](state) {
    return state.ansprechpartnerEdited
  },
  [BROKERDATA_TYPES.GETTERS.BEMERKUNGEN](state) {
    return state.bemerkungen? [...state.bemerkungen] : [];
  },
  [BROKERDATA_TYPES.GETTERS.BESUCHSBERICHTE](state) {
    return state.besuchsberichte || []
  },
  [BROKERDATA_TYPES.GETTERS.GET_BROKER_PRIMAER_PERSONEN](state) {
    return state.primaerPersonen
  },
  /* [BROKERDATA_TYPES.GETTERS.GET_TERMINE](state){
    return state.termine;
  }, */
  [BROKERDATA_TYPES.GETTERS.GET_ACTIVITIES](state){
    return state.activities;
  }, 
  [BROKERDATA_TYPES.GETTERS.BIRTHDAYS](state) {
    return [...state.birthdays || []];
  },
 
  [BROKERDATA_TYPES.GETTERS.FACSIMILE](state) {
    return state.facsimile;
  },

  [BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE](state) {
    return state.pageTableSize;
  },

  [BROKERDATA_TYPES.GETTERS.GET_STORNORESERVE_VERS](state) {
    return state.stornoreserveVers;
  },

  [BROKERDATA_TYPES.GETTERS.GET_SONSTIGES](state) {
    return state.sonstiges;
  },

}
