import MAKLER34F_TYPES from './types';

import CORE_TYPES from '@/store/core/types';

import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {

  async [MAKLER34F_TYPES.ACTIONS.FIND_DATA]({ getters, commit, }) {
    commit(MAKLER34F_TYPES.MUTATIONS.SET_LOADING_DATA, true);

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/makler34f/data`, config);
    commit(MAKLER34F_TYPES.MUTATIONS.SET_DATA, response?.data || {});
    commit(MAKLER34F_TYPES.MUTATIONS.SET_LOADING_DATA, false);
  },

  async [MAKLER34F_TYPES.ACTIONS.FIND_GESELL34F_TYP]({ getters, commit, }, { gesell34fTyp, }) {
    if(!gesell34fTyp) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/makler34f/data/${gesell34fTyp}`, config);
    commit(MAKLER34F_TYPES.MUTATIONS.UPDATE_GESELL34F_TYP_DATA, { ...response?.data || {}, });
  },

  async [MAKLER34F_TYPES.ACTIONS.IMPORT_GESELLSCHAFT]({ getters, commit, dispatch, }, { ankreuzung, }) {
    commit(MAKLER34F_TYPES.MUTATIONS.SET_LOADING_DATA, true);

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/makler34f/import?ankreuzung=${!!ankreuzung}`, {}, config);
    commit(MAKLER34F_TYPES.MUTATIONS.SET_LOADING_DATA, false);
    dispatch(MAKLER34F_TYPES.ACTIONS.FIND_DATA);
  },

  async [MAKLER34F_TYPES.ACTIONS.MAKE_GESELLSCHAFT_VISIBLE]({ getters, dispatch, }, { id, gesell34fTyp, }) {
    if(!id) return;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/makler34f/gesellschaft/${id}/makeVisible`, {}, config);
    dispatch(MAKLER34F_TYPES.ACTIONS.FIND_GESELL34F_TYP, { gesell34fTyp, });
  },

  async [MAKLER34F_TYPES.ACTIONS.SAVE_GESELLSCHAFT]({ getters, dispatch, }, { gesellName, gesell34fTyp, }) {
    if(!gesellName || !gesell34fTyp) return;

    const payload = {
      gesellName,
      gesell34fTyp,
    };

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/makler34f/gesellschaft`, payload, config);
    dispatch(MAKLER34F_TYPES.ACTIONS.FIND_GESELL34F_TYP, { gesell34fTyp, });
  },

  async [MAKLER34F_TYPES.ACTIONS.DELETE_GESELLSCHAFT]({ getters, dispatch, }, { id, gesell34fTyp, }) {
    if(!id) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/makler34f/gesellschaft/${id}`, config);
    dispatch(MAKLER34F_TYPES.ACTIONS.FIND_GESELL34F_TYP, { gesell34fTyp, });
  },

  async [MAKLER34F_TYPES.ACTIONS.SAVE_CHECKED_GESELLSCHAFTEN]({ getters, commit, dispatch, }) {
    const checkedGesellschaften = getters[MAKLER34F_TYPES.GETTERS.MAKLER34F_GESELLSCHAFTEN_EDITED];
    if(!Object.keys(checkedGesellschaften || {}).length) return;

    const gesellschaft34fTypSelected = getters[MAKLER34F_TYPES.GETTERS.GESELLSCHAFT34F_TYP_SELECTED];
    const payload = Object.keys(checkedGesellschaften).map(id => ({
      id,
      angekreuzt: checkedGesellschaften[id],
    }));

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/makler34f/checkedGesellschaften`, payload, config);

    commit(MAKLER34F_TYPES.MUTATIONS.RESET_MAKLER34F_GESELLSCHAFTEN_EDITED);
    dispatch(MAKLER34F_TYPES.ACTIONS.FIND_GESELL34F_TYP, { 
      gesell34fTyp: gesellschaft34fTypSelected.id, 
    });
  },

}
