<template>
  <div>
    <MultiBankingNavigation subtitle="Einnahmen & Ausgaben" />

    <div class="box__container">
      <div v-if="filteredAccounts && filteredAccounts.length">
        <BarChart :labels="chartLabels" :chartSeries="chartSeries" height="400px" />
        <div class="kontoverwaltung-account__separator"></div>
        <KontoverwaltungAccountTable v-if="filteredAccounts.length" :accounts="filteredAccounts"
          v-model="selectedAccounts" :useBoxContainer="false" />
      </div>
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MULTIBANKING_TYPES from '@/store/multiBanking/types';
import CORE_TYPES from '@/store/core/types';

import BarChart from '@/components/charts/echarts/BarChart.vue';
import KontoverwaltungAccountTable from '@/components/multiBanking/KontoverwaltungAccountTable.vue';
import NoData from '@/components/core/NoData.vue'
import { getAccountTitle } from './multiBanking-utils';
import MultiBankingNavigation from '@/components/multiBanking/MultiBankingNavigation.vue';

const ACCOUNTS_ALL = 'Alle';
const COLOR_SUCCESS = document.documentElement.style.getPropertyValue('--color-success');
const COLOR_DANGER = document.documentElement.style.getPropertyValue('--color-danger');

const CHART_COLOR_SUCCESS = {
  itemStyle: {
    color: COLOR_SUCCESS,
  },
};
const CHART_COLOR_DANGER = {
  itemStyle: {
    color: COLOR_DANGER,
  },
};
const SMALL_SCREEN_LABEL_OPTIONS = {
  rotate: 90,
  textStyle: {
    align: 'left',
    verticalAlign: 'middle'
  },
}

export default {
  data() {
    return {
      selectedAccounts: [],
    };
  },
  computed: {
    ...mapGetters({
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      summary: MULTIBANKING_TYPES.GETTERS.SUMMARY,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN
    }),
    filteredAccounts() {
      return this.accounts.filter(account => account.accountType !== 'DEPOT');
    },
    selectedAccountsId() {
      return this.selectedAccounts.map(item => item.id + '');
    },
    filteredSelectedAccounts() {
      return this.filteredAccounts
        .filter(account => this.selectedAccountsId.includes(account.id + ''));
    },
    chartLabels() {
      const chartLabels = this.filteredSelectedAccounts
        .map(account => getAccountTitle(account))

      return [...chartLabels, ACCOUNTS_ALL];
    },
    chartSeries() {
      const lastMonthChartSerie = {
        data: this.filteredSelectedAccounts
          .flatMap(account => {
            const lastMonthData = this.summary?.lastMonthByAccount?.[account.id] || {};
            return [
              {
                value: [getAccountTitle(account), lastMonthData?.income],
                ...this.chartLabelLastMonth,
                ...CHART_COLOR_SUCCESS,
              },
              {
                value: [getAccountTitle(account), lastMonthData?.expenses],
                ...CHART_COLOR_DANGER,
              },
            ];
          }),
      };

      lastMonthChartSerie.data.push({
        value: [ACCOUNTS_ALL, this.summary?.lastMonth?.income],
        ...this.chartLabelLastMonth,
        ...CHART_COLOR_SUCCESS,
      });
      lastMonthChartSerie.data.push({
        value: [ACCOUNTS_ALL, this.summary?.lastMonth?.expenses],
        ...CHART_COLOR_DANGER,
      });

      const lastThreeMonthChartSerie = {
        data: this.filteredSelectedAccounts
          .flatMap(account => {
            const lastThreeMonthData = this.summary?.lastThreeMonthByAccount?.[account.id] || {};
            return [
              {
                value: [getAccountTitle(account), lastThreeMonthData?.incomeAverage],
                ...this.chartLabelLastThreeMonths,
                ...CHART_COLOR_SUCCESS,
              },
              {
                value: [getAccountTitle(account), lastThreeMonthData?.expensesAverage],
                ...CHART_COLOR_DANGER,
              },
            ];
          }),
      };

      lastThreeMonthChartSerie.data.push({
        value: [ACCOUNTS_ALL, this.summary?.lastThreeMonth?.incomeAverage],
        ...this.chartLabelLastThreeMonths,
        ...CHART_COLOR_SUCCESS,
      });
      lastThreeMonthChartSerie.data.push({
        value: [ACCOUNTS_ALL, this.summary?.lastThreeMonth?.expensesAverage],
        ...CHART_COLOR_DANGER,
      });

      return [
        lastMonthChartSerie,
        lastThreeMonthChartSerie,
      ];
    },
    chartLabelLastMonth() {
      return {
        label: {
          show: true,
          position: 'top',
          formatter: () => '30 Tage',
          ...(this.isSmallScreen) && SMALL_SCREEN_LABEL_OPTIONS
        },
      };
    },
    chartLabelLastThreeMonths() {
      return {
        label: {
          show: true,
          position: 'top',
          formatter: () => 'Ø 90 Tage',
          ...(this.isSmallScreen) && SMALL_SCREEN_LABEL_OPTIONS
        },
      };
    }
  },
    mounted() {
      this.selectedAccounts = [...this.accounts];
    },
    methods: {
      prepareMonthData(monthDataByAccount) {
        return Object.keys(monthDataByAccount || {})
          .filter(accountId => this.selectedAccountsId.includes(accountId + ''))
          .reduce((acc, accountId) => {
            const monthData = monthDataByAccount[accountId] || {};
            Object.keys(monthData).forEach(key => {
              acc[key] = monthData[key] + (acc[key] || 0);
            });
            return acc;
          }, {});
      },
    },
    components: {
      BarChart,
      KontoverwaltungAccountTable,
      NoData,
      MultiBankingNavigation,
    },
  }
</script>
<style scoped>
.kontoverwaltung-account__separator {
  background-color: aqua;
  border-top: 1px solid #c4c4c4;
  margin-bottom: 16px;
  margin-top: 16px;
}
</style>
