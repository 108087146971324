import DCS_SYNCHRONISATION from './types';
import axios from 'axios';
import CORE_TYPES from '../core/types';
const config = {
    defaultSpinner: true
  };
  
  const SERVICE_PATH = '/dcs_synchronisation'

export default{

   async [DCS_SYNCHRONISATION.ACTIONS.SEND_VERTRAEGE]({ getters }, payload){
       return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/sendVertraege?mandat=` + payload, config );      
    },
    async [DCS_SYNCHRONISATION.ACTIONS.GET_VERTRAEGE]({ getters }, payload){
      return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/getVertraege?mandat=`+ payload,config);
    },
    async [DCS_SYNCHRONISATION.ACTIONS.GET_PROVISION_ABRECHNUNGEN]({getters},payload){
      return await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/getProvisionAbrechnungen?mandat=`+ payload,config);
    },
    async [DCS_SYNCHRONISATION.ACTIONS.GET_ZUORDNUNGSLISTE]({getters,commit},payload){
      if(payload){
        try{
          const paramGesellschaftDC = payload.gesell ||'';
          const paramSparteMSC = payload.sparte || '';
          let response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/getZuordnungsListe?paramGesellschaftDC=` + paramGesellschaftDC + `&paramSparteMSC=` + paramSparteMSC)
         if(response.data){
          commit(DCS_SYNCHRONISATION.MUTATIONS.SET_ZUORDUNGSLISTE, {payload: response.data});
         }
        }catch{
          console.log('error loading Zuordnungsliste')
        }
      }
    },
    async [DCS_SYNCHRONISATION.ACTIONS.GET_PRODUCT_BY_ID]({getters, commit}, payload){
      if(!payload){
        return
      }
      let response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/getProductById?productId=` + payload);
      if(response && response.data){
        commit(DCS_SYNCHRONISATION.MUTATIONS.SET_PRODUCT, {payload: response.data})
      }
    },

   async [DCS_SYNCHRONISATION.ACTIONS.LOAD_COMBOBOXVALUES]({getters, commit}){
     let response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/getComboboxValues`)
      if(response && response.data){
        commit(DCS_SYNCHRONISATION.MUTATIONS.SET_COMBOBOXVALUES, {payload: response.data})
      }
    },
    async [DCS_SYNCHRONISATION.ACTIONS.SAVE_PRODUCT]({getters, commit},payload){
      if(!payload){
        return
      }
      try{
        let response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/saveProduct`, payload)
        if(response && response.data){
          commit(DCS_SYNCHRONISATION.MUTATIONS.SAVE_PRODUCT_SUCCESS, {payload: response.data})
        }
      }catch{
          console.log('Error saving product')
      }
    },
    async [DCS_SYNCHRONISATION.ACTIONS.DELETE_ZUORDNUNG]({getters},payload){
      if(!payload){
        return
      }
      try{
        await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/delete?id=` + payload)      
      }catch(e){
        console.log(e)
      }
    },
    async [DCS_SYNCHRONISATION.ACTIONS.ZUORDNUNGSLISTE_EINLESEN]({getters},payload){
      if(!payload){
        return
      }
      return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/zuordnungslisteEinlesen?tempId=${payload.id}&fileType=${payload.fileType}` )
    }
}