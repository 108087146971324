import WERT_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import axios from 'axios';
import dayjs from 'dayjs';

function getFilteredOptions(filterOption) {
    let innerPerformanceFilteredRequest = {};
    switch (filterOption) {
        case 'bymonth':
            innerPerformanceFilteredRequest = {
                modus: 'month',
                value: config.months || 1,
            };
            break;
        case 'byyear':
            innerPerformanceFilteredRequest = {
                modus: 'year',
                value: config.years || 1,
            };
            break;
        case 'bybegin':
            innerPerformanceFilteredRequest = {
                modus: 'begin',
            };
            break;
        case 'byrange':
            const parts = state.progressDetailsConfig.args.match(/\d+/g);
            innerPerformanceFilteredRequest = {
                dateBegin: `${parts[1]}.${parts[2]}.${parts[3].substr(0, 4)}`,
                dateEnd: `${parts[3].substr(4)}.${parts[4]}.${parts[5]}`,
            };
            break;
    }
    return innerPerformanceFilteredRequest;
}

function mapNewTableStructure(tableData, priorityConfig = {}, sumConfig = {}) {
    return {
        ...tableData,
        records: tableData.records.map(record =>
            record.reduce((acc, row, index) => ({
                ...acc,
                [tableData.tableHeaders[index].key]: row
            }), {})
        ),
        headers: tableData.tableHeaders.reduce((acc, curr) => ({
            ...acc,
            [curr.key]: {
                dataType: curr.dataType,
                filterable: false,
                label: curr.label,
                priority: priorityConfig[curr.key] || 'VISIBLE_ALWAYS',
                visible:  priorityConfig[curr.key] === 'VISIBLE_ALWAYS' || true,
                sortable: false,
                sum: sumConfig[curr.key] || false,
            }
        }), {})
    }
}



const performancePriorities = {
    'Einzahlungen': 'COLLAPSED',
    'Auszahlungen': 'COLLAPSED',
    'Wechsel': 'COLLAPSED',
};
const performanceSum = {
    'Anfangsbestand': true,
    'Endbestand': true,
    'G_V_Saldo': true,
};
const historicalPriorities = {
    'Details': 'HIDDEN',
};
const historicalSum = {
    'Anfangsbestand': true,
    'Endbestand': true,
    'G_V_Saldo': true,
};
const yearHistoricalPriorities = {
    "Aktuelle_Anteile": "COLLAPSED",
    "Aktueller_Bestand": "COLLAPSED",
    "Anfangsbestand": "COLLAPSED",
    "Auszahlungen": "COLLAPSED",
    "Einzahlungen": "COLLAPSED",
    "Endbestand": "COLLAPSED",
    "Kurs": "VISIBLE_ALWAYS",
    "Rendite": "VISIBLE_ALWAYS",
    "Wechsel": "COLLAPSED",
    "Wertpapier": "VISIBLE_ALWAYS",
    "g_v": "VISIBLE_ALWAYS",
    "vom": "COLLAPSED",
};

const realisedProfitPriorities = {
    "Action": "HIDDEN",
};
const realisedProfitSum = {
    "Einzahlungen": true,
    "Erlös": true,
    "G_VBetrag": true,
};

const transactionsPriorities = {
    'Umsatzart': 'VISIBLE_ALWAYS',
    'Datum': 'VISIBLE_MEDIUM',
    'ISIN': 'COLLAPSED',
    'Wertpapiername': 'COLLAPSED',
    'Kontonr': 'VISIBLE_MEDIUM',
    'Anteile': 'VISIBLE_MEDIUM',
    'Preis': 'COLLAPSED',
    'aktueller_Kurs': 'VISIBLE_MEDIUM',
    'Währung': 'VISIBLE_MEDIUM',
    'Nettoanlage': 'COLLAPSED',
    'Bruttoanlage': 'COLLAPSED',
    'Wechselkurs': 'COLLAPSED',
    'Bruttoanlage_EUR': 'VISIBLE_ALWAYS',
    'AA_IN_PROC': 'VISIBLE_LARGE',
    'Rabatt': 'VISIBLE_LARGE',
    'Gesellschaft': 'COLLAPSED',
    'Lagerstelle': 'COLLAPSED',
    'Kundenname': 'COLLAPSED',
    'Eröffnungsdatum': 'COLLAPSED',
    'Erstellung_Depotposition': 'COLLAPSED',
  };

export default {


    [WERT_TYPES.ACTIONS.RETRIEVE_PERFORMANCE_SEQUENCE]({ state, dispatch }) {
        dispatch(WERT_TYPES.ACTIONS.RETRIEVE_DEPOT_CONFIGS);
        if (state.performancePayload.dateBegin !== undefined || state.performancePayload.modus !== undefined) {
            dispatch(WERT_TYPES.ACTIONS.RETRIEVE_PERFORMANCE_FILTERED, state.performancePayload);
        }
        else {
            dispatch(WERT_TYPES.ACTIONS.RETRIEVE_PROGRESS_DETAILS_CONFIG).then(response => {
                let performanceFilteredRequest = {};
                performanceFilteredRequest = getFilteredOptions(state.progressDetailsConfig.filterOption);
                performanceFilteredRequest['depotId'] = "ALLE_DEPOTS_ANZEIGEN";
                dispatch(WERT_TYPES.ACTIONS.RETRIEVE_PERFORMANCE_FILTERED, performanceFilteredRequest);
            });
        }
    },


    [WERT_TYPES.ACTIONS.RETRIEVE_PERFORMANCE_FILTERED]({ rootState, state, commit }) {
        const config = {
            defaultSpinner: true
        };
        let params={};
        if (Object.keys(state.performancePayload).length===0){
            commit(WERT_TYPES.MUTATIONS.SET_PERFORMANCE_PAYLOAD, getFilteredOptions(state.progressDetailsConfig.filterOption));
        }
        params=state.performancePayload;
        params['depotId'] = state.depotid;
      //  Object.keys(params).forEach((key) => (params[key] == null || params[key] === undefined) && delete params[key]);
        axios.post(rootState.core.apiAddress + '/wertentwicklung/performance', params, config).then(response => {
            commit(WERT_TYPES.MUTATIONS.SET_PERFORMANCE_DATA, mapNewTableStructure(response.data.data, performancePriorities, performanceSum));
        })
    },


    [WERT_TYPES.ACTIONS.RETRIEVE_PROGRESS_DETAILS_CONFIG]({ rootState, state, commit }) {
        const config = {
            defaultSpinner: true
        };
        return axios.post(rootState.core.apiAddress + '/wertentwicklung/progressDetailsConfig', {}).then(response => {
            commit(WERT_TYPES.MUTATIONS.SET_PROGRESS_DETAILS_CONFIG, response.data);
        });

    },

    [WERT_TYPES.ACTIONS.RETRIEVE_HISTORICAL_PERFORMANCE]({ rootState, commit }, depotid) {
        let payload = {};
        if (depotid !== undefined && depotid !== null) payload.depotId = depotid;
        else payload.depotId = 'ALLE_DEPOTS_ANZEIGEN';
        return axios.post(rootState.core.apiAddress + '/wertentwicklung/historische', payload).then(response => {
            commit(WERT_TYPES.MUTATIONS.SET_HISTORICAL_PERFORMANCE, mapNewTableStructure(response.data, historicalPriorities, historicalSum));
        });
    },


    [WERT_TYPES.ACTIONS.RETRIEVE_REALIZED_PROFIT]({ rootState, state, commit }, depotid) {
        let payload = {};
        if (depotid !== undefined && depotid !== null) payload.depotId = depotid;
        else payload.depotId=state.depotid;
    axios.post(rootState.core.apiAddress + '/wertentwicklung/realisedProfit', payload).then(response => {
            //commit(WERT_TYPES.MUTATIONS.SET_REALIZED_PROFIT,mapNewTableStructure(response.data,realisedProfitPriorities, realisedProfitSum));
            commit(WERT_TYPES.MUTATIONS.SET_REALIZED_PROFIT, response);
        });
    },
    [WERT_TYPES.ACTIONS.RETRIEVE_YEAR_PERFORMANCE_DATA]({ rootState, commit }, payload) {
        axios.get(rootState.core.apiAddress + '/wertentwicklung/getYearPerformance?year=' + payload.year + '&NeedQueryYearsList=' + payload.NeedQueryYearsList).then(response => {
            commit(WERT_TYPES.MUTATIONS.SET_YEAR_PERFORMANCE_DATA, mapNewTableStructure(response.data.data, yearHistoricalPriorities, {}));
        });
    },

    [WERT_TYPES.ACTIONS.RETRIEVE_TRANSACTIONS]({ rootState, commit }, payload) {
        axios.post(rootState.core.apiAddress + '/wertentwicklung/getRealizedProfitsTransactions', payload).then(response => {
            commit(WERT_TYPES.MUTATIONS.SET_TRANSACTIONS,  mapNewTableStructure(response.data, transactionsPriorities));
        });
    },

    [WERT_TYPES.ACTIONS.RETRIEVE_DEPOT_CONFIGS]({ rootState, commit }) {
        axios.post(rootState.core.apiAddress + '/wertentwicklung/depotConfigs', {}).then(response => {
            commit(WERT_TYPES.MUTATIONS.SET_DEPOT_CONFIGS, response);
        });
    },
};

