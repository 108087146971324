var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.document && _vm.document.steps && _vm.document.steps.length
            ? [
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "right", "font-size": "40px" }
                  },
                  [
                    _c("ph-plus", {
                      staticClass: "clickable",
                      on: {
                        click: function($event) {
                          return _vm.edit()
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "SortableList",
                  {
                    attrs: { items: _vm.pdfComponentList },
                    on: {
                      orderChanged: function($event) {
                        return _vm.onOrderChanged($event)
                      }
                    }
                  },
                  [
                    _c(
                      "ul",
                      {
                        staticClass: "list-bordered",
                        attrs: { "data-sortable-container": "" }
                      },
                      _vm._l(_vm.pdfComponentList, function(component) {
                        return _c(
                          "li",
                          {
                            key: component.componentId,
                            staticClass: "list-bordered-item",
                            attrs: { "data-sortable-item": "" }
                          },
                          [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              component.editable
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "uk-text-small edit",
                                      on: {
                                        click: function($event) {
                                          return _vm.edit(component)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v("bearbeiten "),
                                      _c("ph-pencil-line", {
                                        attrs: { title: "Bearbeiten", size: 16 }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "uk-text-small edit",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteComponent(component)
                                    }
                                  }
                                },
                                [_vm._v("löschen "), _c("ph-trash")],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "auto" },
                                  attrs: { "data-sortable-item-handler": "" }
                                },
                                [
                                  _c("ph-list", {
                                    attrs: { size: 20, weight: "bold" }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "body_class",
                                staticStyle: { display: "flex" }
                              },
                              [
                                _c("span", {
                                  class: {
                                    centered:
                                      component.htmlCardType === "EINZELSPARTEN"
                                  },
                                  staticStyle: { width: "100%" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getViewForComponent(component)
                                    )
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            : _c("NoData", { attrs: { noIcon: "", title: "" } })
        ],
        2
      ),
      _c("DynamicComponentModal", { ref: "editComponentModal" }),
      _c("InlineInputModal", { ref: "editInlineInputModal" }),
      _c("BaseModal", {
        ref: "deleteModal",
        attrs: {
          modalTitle: "Komponente löschen",
          showCancelButton: true,
          labelButtonConfirm: "Bestätigen"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.onConfirmDeleteModal()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [_vm._v(" Soll die Komponente wirklich gelöscht werden? ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }