import { render, staticRenderFns } from "./Buchung.vue?vue&type=template&id=faba7f66&"
import script from "./Buchung.vue?vue&type=script&lang=js&"
export * from "./Buchung.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('faba7f66')) {
      api.createRecord('faba7f66', component.options)
    } else {
      api.reload('faba7f66', component.options)
    }
    module.hot.accept("./Buchung.vue?vue&type=template&id=faba7f66&", function () {
      api.rerender('faba7f66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/versicherungen/Buchung.vue"
export default component.exports