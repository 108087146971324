import FONDSFINDER_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue'

export default {
  [FONDSFINDER_TYPES.MUTATIONS.GET_FONDS_V2_SUCCESS](state, payload) {
    if (Array.isArray(payload.fonds)) {
      Vue.set(state, 'fonds', [...payload.fonds]);
    }
  },

  [FONDSFINDER_TYPES.MUTATIONS.ADD_FONDS_INFO](state, payload) {
    if (Array.isArray(payload.positionInfo)) {
      payload.positionInfo.forEach(fond => {
        Vue.set(state.positionInfo, fond.isin, fond);
      });
    }
  },

  [FONDSFINDER_TYPES.MUTATIONS.CLEAR_FONDS](state, payload) {
    Vue.set(state, 'fonds', []);
  },

  [FONDSFINDER_TYPES.MUTATIONS.DEPOTPOSITION](state, {antragId, altPositionInfo, aenderungTyp}) {
    state.storedDepotPosition.aenderungTyp = aenderungTyp
    state.storedDepotPosition.antragId = antragId
    state.storedDepotPosition.altPositionInfo = altPositionInfo
    //Vue.set(state.storedDepotPosition, antragId, altPositionInfo);
  },

  [FONDSFINDER_TYPES.MUTATIONS.GET_COMBOBOX_SELECTIONS_V2_SUCCESS](state, payload) {
    Vue.set(state, 'comboboxSelections', {...payload.comboboxSelections})
  },

  [FONDSFINDER_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [FONDSFINDER_TYPES.MUTATIONS.GET_MARKTUBERSICHT_CHART_DATA_SUCCESS](state, { chartData }) {
    Vue.set(state.marktubersichtChart, 'chartData', { ...chartData });
  },

  [FONDSFINDER_TYPES.MUTATIONS.SET_MARKTUBERSICHT_CHART_SELECTED_FOND](state, { fond }) {
    Vue.set(state.marktubersichtChart, 'selectedFond', { ...fond });
  },

}