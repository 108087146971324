import D_B from './types';
import Vue from 'vue'
import { getInitialState } from './index'

export default {
  
  [D_B.MUTATIONS.DATA](state, payload) {
    state.DATA = payload
  },
  [D_B.MUTATIONS.START_TIME](state, payload) {
    state.START_TIME = payload
  },
  [D_B.MUTATIONS.SESSION](state, payload) {
    state.SESSION = payload
  },
  [D_B.MUTATIONS.SELECTED_NODE](state, payload) {
    state.SELECTED_NODE = payload
  },
  [D_B.MUTATIONS.DATA_SEGMENTS](state, payload) {
    state.DATA_SEGMENTS = payload
  },
  [D_B.MUTATIONS.STAT_DATA](state, payload) {
    state.STAT_DATA = payload
  },
  [D_B.MUTATIONS.FORMAT_SWITCH](state, payload) {
    state.FORMAT_SWITCH = payload
  },
  [D_B.MUTATIONS.FULL_SQL](state, payload) {
    state.FULL_SQL = payload
  },
  [D_B.MUTATIONS.RUNNIG](state, payload) {
    state.RUNNIG = payload
  },
  [D_B.MUTATIONS.STAT](state, payload) {
    state.STAT = payload
  },
  [D_B.MUTATIONS.MINUTS](state, payload) {
    state.MINUTS = payload
  }
}