<template>
  <div>
    <div v-show="!$isSmallScreen">
      <slot/>
    </div>

    <BaseModal
      ref="modal"
      :modalTitle="modalTitle"
      :closeButtonLabel="closeButtonLabel"
      :showDefaultButtons="false"
      :actions="modalActions"
      @action="handleModalAction"
      @onCloseButton="goToLastAction"
    >
      <slot/>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from "vuex";

export default {
  components: {
    BaseModal,
  },
  props: {
    modalTitle: {
      type: String,
      default: '',
    },
    closeButtonLabel: {
      type: String,
      default: '',
    },
    // When the component gets opened and is the first page visited we do not have a 'back' route
    // happens for example when using deep links
    defaultLastAction: {
      type: String,
      default: '/home',
    },
    modalActions: {
      type: Array,
      default: [],
    },
  },
  watch: {
    $isSmallScreen(newVal) {
      if (newVal) {
        this.$refs.modal?.open()
      } else {
        this.$refs.modal?.close()
      }
    }
  },
  computed: {
    ...mapGetters({
      backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
    }),
    backButtonPeek() {
      return this.backToPreviousData?.slice(-1)?.pop();
    },
  },
  mounted() {
    if (this.$isSmallScreen) {
      this.$refs.modal.open()
    }
  },
  methods: {
    goToLastAction() {
      const { backButtonPeek, } = this;
      const query = { backAction: true};
      const fullPath = backButtonPeek?.fullPath || this.defaultLastAction

      this.$router.push({ path: fullPath, query: query, });
    },
    handleModalAction({ key, value, }) {
      this.$emit('action', { key, value, });
      this.$emit(`action-${key}`, value);
    },
  },
}
</script>