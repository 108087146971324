import IZF from './types';

export default {

  [IZF.GETTERS.COMBO](state) {

    return state.COMBO;
  },
  [IZF.GETTERS.RESULT](state) {

    return state.RESULT;
  },

}
