<template>
  <div class="input-forms__container">
    <div class="input-forms__container">
      <ComboBox
        v-if="!config.aenderung_alt"
        label="Bereits vorhandende Depots"
        :values="vorhandendeDepots"
        v-model="antragData.INPUT_VORHANDENE_KONTEN"
      />
    </div>
    <div class="input-forms__label-container">
      <div class="input-forms__label-container">
        <div class="row mt-0">
          <div class="col">
            <div
              class="
                input-forms__label-content input-forms__label-content--bigger
              "
              v-if="label"
            >
              <ph-bank :size="16" />&nbsp;{{ label }}
            </div>
          </div>
          <div class="col-auto" v-if="config && config.buttonText">
            <BaseButton @click="openFormsFinder">{{
              config.buttonText
            }}</BaseButton>
          </div>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <Table
        v-if="tableData && tableData.records && tableData.records.length"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="false"
        :actions="columnActions"
        :rowsPerPage="tableData.records.length"
        @execute-action="handleTableAction($event)"
      >
        <template v-slot:betragAlt="props">
          <InputField
            v-if="props.data.row"
            type="currency"
            v-model="props.data.row.betragAlt"
            @input="onChangeInputField($event, props.data.row, 'betragAlt')"
          />
        </template>

        <template v-slot:betrag="props">
          <InputField
            v-if="props.data.row"
            type="currency"
            v-model="props.data.row.betrag"
            @input="onChangeInputField($event, props.data.row, 'betrag')"
          />
        </template>

        <template v-slot:frequenz="props">
          <ComboBox
            :values="comboOptions"
            :value="props.data.row.frequenz"
            @input="onChangeInputField($event, props.data.row, 'frequenz')"
          />
        </template>

        <template v-slot:abbuchungsKonto="props">
          <InputRadioBoxGroup
            v-if="props.data.row"
            type="text"
            v-model="props.data.row.abbuchungsKonto"
            :values="abbuchungsKontoValues"
            @input="
              onChangeInputField($event, props.data.row, 'abbuchungsKonto')
            "
          />
        </template>

        <template v-slot:AA="props">
          <InputField
            type="number"
            :precision="4"
            :value="props.data.row.AA"
            :id="'AA' + props.data.row.index"
            @input="onChangeInputField($event, props.data.row, 'AA')"
          />
        </template>

        <template v-slot:restlaufzeit="props">
          <InputField
            :value="props.data.row.restlaufzeit"
            :id="'restlaufzeit' + props.data.row.index"
            @input="onChangeInputField($event, props.data.row, 'restlaufzeit')"
          />
        </template>

        <template v-slot:dateAb="props">
          <DatePickerField
            :value="props.data.row.dateAb"
            @input="onChangeInputField($event, props.data.row, 'dateAb')"
          />
        </template>

        <template v-slot:dateBis="props">
          <DatePickerField
            :value="props.data.row.dateBis"
            @input="onChangeInputField($event, props.data.row, 'dateBis')"
          />
        </template>
      </Table>

      <WertpapierAuswahlPositionFields
        v-if="positions && positions.length && config && config.positionFields"
        :config="config"
        :categoryId="categoryId"
        :antragId="antragId"
      />

      <BaseModal
      ref="fehlerModal"
      labelButtonCancel="Ok"
      :showConfirmButton="false"
      >
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="color-danger mr-2" />
        <span class="color-danger"> Bitte wählen Sie ein bereits vorhandenes Depot</span>
      </template>
     </BaseModal>

      <DeletePositionModal
        ref="deleteModal"
        :position="positionToDelete"
        @delete="doDeletePosition()"
      ></DeletePositionModal>
    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import Table from "@/components/table/Table.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import { mapGetters } from "vuex";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import { PhBank } from "phosphor-vue";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import { PhWarning } from "phosphor-vue";

export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {
    label: {},
    antragData: {},
    config: {},
    antragTyp: {},
    antragId: {},
    aenderungTyp: {
      type: String,
      default: "UNBEKANNT",
    },
    comboboxSelections: {},
    categoryId: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
    warnings: {},
  },
  components: {
    BaseButton,
    ComboBox,
    InputField,
    Table,
    WertpapierAuswahlPositionFields,
    DeletePositionModal,
    DatePickerField,
    PhBank,
    InputRadioBoxGroup,
    BaseModal,
    PhWarning
  },
  data() {
    return {
      vorhandendeDepots: [],
      abbuchungsKontoValues: [
        { value: "keine", label: "keine Änderung." },
        { value: "referenzkonto", label: "Bestehendes Referenzkonto." },
        {
          value: "verrechnungskonto", label: "Verrechnungskonto bei der Schelhammer Capital Bank.",
        },
        { value: "kontoplus", label: "KONTO plus, IBAN:" },
      ],
      columnActions: [
        {
          legend: {
            icon: "PhTrash",
            index: 1,
            key: "DELETE",
            label: "Löschen",
          },
        },
      ],
      positionToDelete: {},
      posVorhanden: {},
    };
  },
  watch: {
    positions(newValue) {
      if (
        (newValue && newValue.length && this.categoryId === "SPARPLAN") ||
        this.categoryId === "ENTNAHMEPLAN"
      ) {
        let isinAlt =
          this.antragData.INPUT_VORHANDENE_KONTEN !== "NICHT_VERWENDEN"
            ? this.antragData.INPUT_VORHANDENE_KONTEN
            : null;
        // when the combobox is selected by the change in depotnr we get an object here for some reason
        if (isinAlt instanceof Object) {
          isinAlt = isinAlt.value;
        }
        if (isinAlt) {
          newValue.forEach((p, index) => {
            this.positions[index].isinAlt = p.isinAlt ? p.isinAlt : isinAlt;
          });
        }
      }
      if (this.positions != undefined && this.positions.length > 0) {
        const typ = this.config.aenderungTyp;
        const result = this.positions.filter(
          (p) => p.aenderungTyp == typ && p.isinAlt == null
        );
        if (this.positions.length > 0 && result.length > 0) {
          this.save(true);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
    }),
    tableData() {
      const result = {
        headers: {
          isin: {
            label: "ISIN",
            key: "isin",
            dataType: "String",
            visible: true,
            sum: false,
            fixed: true,
          },
          fondsName: {
            label: "Fondsname",
            key: "fondsName",
            dataType: "String",
            visible: true,
            sum: false,
            fixed: true,
          },
          isinAlt: {
            label: "ISIN (Alt)",
            key: "isinAlt",
            dataType: "String",
            visible: this.config.aenderung,
            sum: false,
            fixed: true,
          },
          betragAlt: {
            label: "Alter Betrag",
            key: "betragAlt",
            dataType: "Slot",
            dataSymbol: "€",
            visible: this.config.reduktion,
            sum: false,
            fixed: true,
          },
          betrag: {
            label: this.config.reduktion ? "Neuer Betrag" : "Betrag",
            key: "betrag",
            dataType: "Slot",
            dataSymbol: "€",
            visible:
              !this.config.aussetz &&
              !this.config.aenderung_alt &&
              !this.config.abbuchung &&
              !this.config.beenden,
            sum: false,
            fixed: true,
          },
          abbuchungsKonto: {
            label: "Frequenz",
            key: "abbuchungsKonto",
            dataType: "Slot",
            visible: this.config.abbuchung,
            sum: false,
            fixed: true,
          },
          frequenz: {
            label: "Frequenz",
            key: "frequenz",
            dataType: "Slot",
            visible: this.config.konto,
            sum: false,
            fixed: true,
          },
          AA: {
            label: "Effektenprovision",
            key: "AA",
            dataType: "Slot",
            visible: !this.config.isAuszahlung,
            sum: false,
            fixed: true,
          },
          restlaufzeit: {
            label: "Restlaufzeit (Pflichtfeld bei Plansumme)",
            key: "restlaufzeit",
            dataType: "Slot",
            visible: !this.config.isAuszahlung,
            sum: false,
            fixed: true,
          },
          dateAb: {
            label: "Ab Datum",
            key: "dateAb",
            dataType: "Slot",
            visible: this.config.aussetz,
            sum: false,
            fixed: true,
          },
          dateBis: {
            label: "Bis Datum",
            key: "dateBis",
            dataType: "Slot",
            visible: this.config.aussetz,
            sum: false,
            fixed: true,
          },
          actions: {
            label: "",
            key: "actions",
            priority: 1,
            dataType: "tableAction",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
        },
        records:
          this.positions &&
          this.positions.map((pos, index) =>
            Object.assign(pos, {
              index: index,
              fondsName: this.getFondsName(pos, this.positionInfo),
              lagerstelle: "CAPITALBANK",
            })
          ),
      };
      return result;
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.categoryId &&
        this.positionsAll[this.antragId] &&
        this.positionsAll[this.antragId][this.categoryId]
      ) {
        const pos = this.positionsAll[this.antragId][this.categoryId].filter(
          (p) =>
            p.aenderungTyp == this.config.aenderungTyp ||
            p.aenderungTyp == "UNBEKKANT" ||
            p.aenderungTyp == undefined
        );
        if (pos != undefined && pos.length > 0) {
         
            pos.map((x) => (x.aenderungTyp = "SPARPLAN_AENDERUNG_ISIN"));
          
        }
        return pos;
      }
    },
    positionData() {
      if (
        this.positionDataAll &&
        this.antragId &&
        this.categoryId &&
        this.positionDataAll[this.antragId] &&
        this.positionDataAll[this.antragId][this.categoryId]
      ) {
        return this.positionDataAll[this.antragId][this.categoryId];
      }
    },
    comboOptions() {
      return [
        {
          value: "MONATLICH",
          label: "monatlich",
        },
        {
          value: "VIERTELJ",
          label: "quartalsweise",
        },
      ];
    },
  },
  mounted() {
    this.getCapitalbankDeotsVorhanden();
  },
  methods: {
    commitUpdatePositionsFromEvent(positionChange, position) {
      const payloadData = {
        id: this.antragId,
        categoryId: this.categoryId,
      };
      this.$store.commit(
        ANTRAG_TYPES.MUTATIONS.UPDATE_POSITION_DATA,
        payloadData
      );
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        additionalIdentifier: ["aenderungTyp", "isinAlt"],
        fonds: [
          {
            ...positionChange,
            isin: position.isin,
            isinAlt: position.isinAlt,
            aenderungTyp: this.config.aenderungTyp,
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField($event, position, field) {
      const positionChange = { [field]: $event };
      position.aenderungTyp = this.config.aenderungTyp;
      this.commitUpdatePositionsFromEvent(positionChange, position);
    },
    handleTableAction(event) {
      if (event.action?.legend?.key === "DELETE") {
        this.openModalDeleteFond(event.value);
      }
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      this.deletePosition(
        this.antragId,
        this.categoryId,
        this.positionToDelete
      );
      this.positionToDelete = null;
    },
    async getCapitalbankDeotsVorhanden() {
      const response = await this.$store.dispatch(
        ANTRAG_TYPES.ACTIONS.CAPITALBANK_DEPOTS_VORHANDEN,
        { depotnr: this.antragData.INPUT_DEPOTNR }
      );
      if (response) {
        this.vorhandendeDepots = response;
      }
    },
    addToPositions(posInfo) {
    const pos = this.addPositionCBAenderingVermoegensplaene(posInfo, this.config.aenderungTyp)
      if (this.positionsAll[this.antragId] == null) {
         this.$set(this.positionsAll, this.antragId,{})
         if(this.config.isAuszahlung){
            this.$set(this.positionsAll[this.antragId], 'UNDEFINIERT',[])
        }else{
           this.$set(this.positionsAll[this.antragId], 'KAUF',[])
        }
      }else if(this.positionsAll[this.antragId][this.categoryId] == null){
         this.$set(this.positionsAll[this.antragId], this.categoryId ,[])
      }
      this.positionsAll[this.antragId][this.categoryId].push(pos);
    },
    openFormsFinder() {
      let posInfo =
        this.antragData.INPUT_VORHANDENE_KONTEN !== "NICHT_VERWENDEN"
          ? this.antragData.INPUT_VORHANDENE_KONTEN
          : null;
          if(posInfo == null){
            this.$refs.fehlerModal.open();
            return
          }
        if (posInfo != null && this.config.aenderung_alt) {
           this.addToPositions(posInfo);
         } else {
          const wpConfig = {};
          wpConfig.SINGLE_SELECTION = true
          this.goToFormsFinder(
          this.categoryId,
          wpConfig,
          this.suppressFilterLagerstelle
        );
      }
    },
  },
};
</script>

<style scoped>
.input-forms__label-content--bigger {
  font-size: 1.5rem;
}
.input-forms__label-content--bigger > i {
  font-size: 2rem;
}
</style>