<template>
<div style="display: inline-block">
    <div v-if="!rendered" @click="startRendering" :style="{width: resolutions[resolutionId].width}" class="placeholder">
        click me!
    </div>
    <iframe v-else :src="baseUrl + path" :width="resolutions[resolutionId].width" :height="frameHeight" ref="frame"></iframe>
    <br>
    <label>
        Width
        <select v-model="resolutionId" :disabled="!rendered">
            <option v-for="(resolution, index) in resolutions" :key="resolution.title" :value="index">
                {{resolution.title}} ({{resolution.width}})
            </option>
        </select>
    </label>
</div>
</template>

<script>

export default {
    inheritAttrs: false,
    props: {
        path: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            rendered: false,
            baseUrl: "/docs/",
            resolutionId: 3,
            browserWidth: 1000,
            frameHeight: 100,
            interval: null,
        };
    },
    mounted() {
        for (let a in this.$attrs) {
            this.$watch('$attrs.' + a, value => this.sendProp(a, value));
        }
        window.addEventListener("message", this.receiveEvent.bind(this));
    },
    computed: {
        resolutions() {
            return [
                {title: "smallest: Galaxy S10 vertical", width: 360},
                {title: "sm: Galaxy S10 horizontal", width: 760},
                {title: "md: iPad vertical", width: 810},
                {title: "lg: iPad horizontal", width: 1080},
                {title: "xl: full screen", width: this.browserWidth},
            ];
        },
    },
    methods: {
        startRendering() {
            if (this.rendered)
                return;
            this.rendered = true;
            this.interval = setInterval(() => {
                this.updateFrameHeight();
            }, 500);
        },
        updateFrameHeight() {
            const frame = this.$refs.frame;
            if (frame) {
                this.frameHeight = Math.min(500, frame.contentWindow.document.body.scrollHeight);
                this.browserWidth = frame.parentElement.parentElement.clientWidth;
            } else if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        },
        receiveEvent(message) {
            const isMine = message.source.document.URL.endsWith(this.path);
            if (isMine) {
                //switch (message.data?.type)
                for (let a in this.$attrs) {
                    this.sendProp(a, this.$attrs[a]);
                }
            }
        },
        sendProp(key, value) {
            const frame = this.$refs.frame;
            if (frame) {
                frame.contentWindow.postMessage({component_viewer: true, key, value}, '*');
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener("message", this.receiveEvent.bind(this));
    },
}
</script>

<style scoped>
.placeholder {
    outline: 1px solid black;
    height: 100px;
}
iframe {
    outline: 1px solid black;
}
label {
    font-size: smaller;
}
</style>
