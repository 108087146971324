import CMS_TYPES from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';


export function getInitialState() {
  return {
    template: "0",
    components: [],
    componentGroups: [],
    componentCurrentContent: {},
    componentContentUpdated: false,
    userID: null,
    cmsNews: [],
    privacyLink: "",
    selectedFond: "",
    portfolios: [],
    firstPageRoute:"",
    componentsReady:false,

    existingInsurances: {},
    tablerecords: [],
    areRecordsReady: false,
    tariffDetails: [],
    areTariffDetailsReady: false,

    fondsshopParams: {},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}