var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v(_vm._s(_vm.selectedBereich.beschreibungKurz))
      ]),
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : !_vm.rows.length
        ? _c("NoData")
        : _c("Table", {
            attrs: { headers: _vm.headers, rows: _vm.rows },
            on: {
              "action-TOGGLE_SICHTBAR_STRUKTUR": function($event) {
                return _vm.toggleSichtbarStruktur($event)
              },
              "action-EDIT": function($event) {
                return _vm.editMaklerTexte($event)
              },
              "action-DELETE": function($event) {
                return _vm.deleteMaklerTexte($event)
              }
            }
          }),
      _c("AddMaklerTexteModal", { ref: "addMaklerTexteModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }