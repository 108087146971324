import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    fahrzeugdatenEdit: {
      fzgart : null,
      marke : null,
      handelsbez : null,
      leistung : null,
      hubraum : null,
      plaetze : null,
      nutzlast : null,
      verwendzweck : null,
      nutzerkreis : null,
      antriebsart : null,
      erstzulassdat : null,
      zulassung_vn_seit : null,
      kilometerstand : null,
      kilometerstand_am : null,
      kilometerleistung : null,
      leasing : null,
      kat : null,
      abs : null,
      neuwert : null,
      zeitwert : null,
      zeitwert_vom : null,
      motornr : null,
      kennzeichen : null,
      stellplatz : null,
      wegfahrsperre : null,
      fahrgestnr : null,
      schluesselnr_fzg : null,
      schluesselnr_herst : null,
      schluesselnr_typ : null,
    },
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}