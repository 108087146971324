<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <FormLabel label="Bitte tragen Sie hier die zu übertragenden Depots ein"/>
      <FormHeader label="Aktuelle Depotverbindung"/>
      <InputField v-model="form.bisherManuellName" label="Name der Bank"
        @change="$emit('save', { bisherManuellName: $event })"/>
      <InputField v-model="form.bisherManuellStrasse" label="Straße"
        @change="$emit('save', { bisherManuellStrasse: $event })"/>
      <InputField v-model="form.bisherManuellPLZ" label="PLZ"
        @change="$emit('save', { bisherManuellPLZ: $event })"/>
      <InputField v-model="form.bisherManuellOrt" label="Ort"
        @change="$emit('save', { bisherManuellOrt: $event })"/>
      <InputField v-model="form.bisherDepotnr" label="Nummer"
        @change="$emit('save', { bisherDepotnr: $event })"/>

      <FormDivider/>

      <FormHeader label="Depotinhaber 1"/>
      <InputField v-model="form.altInhaber1Name" label="Name"
        @change="$emit('save', { altInhaber1Name: $event })"/>
      <InputField v-model="form.altInhaber1Vorname" label="Vorname"
        @change="$emit('save', { altInhaber1Vorname: $event })"/>
      <InputField v-model="form.altInhaber1Strasse" label="Straße"
        @change="$emit('save', { altInhaber1Strasse: $event })"/>
      <InputField v-model="form.altInhaber1Plz" label="PLZ"
        @change="$emit('save', { altInhaber1Plz: $event })"/>
      <InputField v-model="form.altInhaber1Ort" label="Ort"
        @change="$emit('save', { altInhaber1Ort: $event })"/>
      <DatePickerField v-model="form.altInhaber1Geburtsdatum" 
        isValueAsString @input="$emit('save', { altInhaber1Geburtsdatum: $event })" label="Geburtsdatum"/>
      <InputField v-model="form.altInhaber1Steuernummer" label="Steuer-Id (TIN)"
        @change="$emit('save', { altInhaber1Steuernummer: $event })"/>

      <FormDivider/>

      <FormHeader label="Depotinhaber 2"/>
      <InputField v-model="form.altInhaber2Name" label="Name"
        @change="$emit('save', { altInhaber2Name: $event })"/>
      <InputField v-model="form.altInhaber2Vorname" label="Vorname"
        @change="$emit('save', { altInhaber2Vorname: $event })"/>
      <InputField v-model="form.altInhaber2Strasse" label="Straße"
        @change="$emit('save', { altInhaber2Strasse: $event })"/>
      <InputField v-model="form.altInhaber2Plz" label="PLZ"
        @change="$emit('save', { altInhaber2Plz: $event })"/>
      <InputField v-model="form.altInhaber2Ort" label="Ort"
        @change="$emit('save', { altInhaber2Ort: $event })"/>
      <DatePickerField 
        v-model="form.altInhaber2Geburtsdatum" 
        label="Geburtsdatum"
        isValueAsString
        @input="$emit('save', { altInhaber2Geburtsdatum: $event })"/>
      <InputField v-model="form.altInhaber2Steuernummer" label="Steuer-Id (TIN)"
        @change="$emit('save', { altInhaber2Steuernummer: $event })"/>

      <BaseButton @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>
import FormHeader from '@/components/core/forms/FormHeader.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';

export default {
  props: {
    positionData: {
    },
    warnings: {
    },
  },
  components: {
    FormHeader,
    FormLabel,
    InputField,
    DatePickerField,
    BaseButton,
    FormDivider,
  },
  computed: {
  },
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
  },
  methods: {
  }
}
</script>
<style scoped>

</style>