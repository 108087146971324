var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm.loading && !_vm.scrollLoading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : _c(
            "ScrollLoading",
            {
              attrs: {
                hasScroll:
                  !!_vm.maxRowCount && _vm.rows.length !== _vm.maxRowCount
              },
              on: {
                loading: function($event) {
                  _vm.scrollLoading = $event
                },
                scroll: function($event) {
                  return _vm.$emit("onScroll", $event)
                }
              }
            },
            [
              _c("Table", {
                attrs: {
                  title: "Bemerkungen",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  headerActions: _vm.titleActions,
                  mobileConfig: _vm.mobileConfig,
                  selectionAsBlacklist: _vm.invertSelection
                },
                on: {
                  clickRow: _vm.editBemerkung,
                  selectionAsBlacklist: function($event) {
                    _vm.invertSelection = $event
                  },
                  "click-subject": _vm.editBemerkung,
                  "action-DELETE": _vm.deleteRow,
                  "action-EDIT": _vm.editBemerkung,
                  "action-PDF": _vm.showPDF,
                  "headerAction-BULK_DELETE": _vm.deleteSelected,
                  "action-ATTACHMENTS": _vm.openBemerkungAnhangModal
                },
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
            ],
            1
          ),
      _c("BaseModal", {
        ref: "bemerkungAnhangModal",
        attrs: {
          modalTitle: "Anhänge",
          labelButtonCancel: "Zurück",
          showConfirmButton: false
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("BemerkungFiles", {
                  attrs: { files: _vm.form.files },
                  on: {
                    deleteFile: function($event) {
                      return _vm.deleteAttachment($event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }