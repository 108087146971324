<template>
  <div>
    <div class="container">
      <div class="logo"  v-if="logoUrl">
        
        <a href="#">
          <img alt="Makler Logo" v-bind:src="logoURL" id="logo" width="50%" />
        </a>
      </div>
      <div class="navi">
        <div class="home">
          <a href="#home">Home</a>
        </div>
        <div class="ueber-uns">
          <a href="#ueberuns">Über Uns</a>
        </div>
        <div class="streitbeilegung">
          <a href="#streitbeilegung">Streitbeilegung</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["logoURL"]
};
</script>
<style scoped>
.container {
  display: grid;
  align-items: center;
  grid-template-columns: 20% auto 60%;
  grid-template-areas: "logo . navi";
  justify-content: space-between;
  padding: 0.5em;
}

.navi {
  font-size: 2em;
  grid-area: navi;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "home ueber-uns streitbeilegung";
}
.logo {
  grid-area: logo;
  justify-self: start;
}
.home {
  grid-area: home;
  justify-self: end;
}
.ueber-uns {
  grid-area: ueber-uns;
}
.streitbeilegung {
  grid-area: streitbeilegung;
  justify-self: start;
}
@media only screen and (max-width: 1024px) {  .container {
    grid-template-columns: 1fr;
    grid-template-areas:
      "logo"
      "navi";
  }
  .navi {
    grid-template-columns: 1fr;
    grid-template-areas:
      "home"
      "ueber-uns"
      "streitbeilegung";
  }
  .home {
    justify-self: center;
  }
  .logo {
    justify-self: center;
    width: 40%;
    height: auto;
  }
  .streitbeilegung {
    justify-self: center;
  }
}
</style>