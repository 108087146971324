<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container" >
      <FormLabel :label="label" :config="config"/>

      <div v-if="config && config.subtitle">
        {{config.subtitle}}
      </div>

      <div class="input-forms__input-container mt-4 mb-2">
        <SortableList :items="items" @orderChanged="onOrderChanged($event)">
          <ul class="list-bordered" data-sortable-container>
            <li v-for="(componentItem) in items" :key="componentItem.id + '' + componentItem.position" class="list-bordered-item" data-sortable-item>
              <div class="d-flex justify-content-between">
                <div style="width: 100%;">
                  <AntragComponent
                    :component="componentItem"
                    :data="antragData"
                    isComponentHalfSizeEnabled
                    :comboboxSelection="componentItem.values"
                    @dataChange="onComponentDataChange($event)"
                  >
                  </AntragComponent>
                </div>

                <div data-sortable-item-handler><ph-list :size="16" weight="bold" /></div>
              </div>
            </li>
          </ul>
        </SortableList>
      </div>
    </div>
  </div>
</template>

<script>
import FormLabel from '@/components/core/forms/FormLabel.vue'
import SortableList from '@/components/core/SortableList.vue'
import { sanitize } from '@/helpers/string-helper.js';
import { PhList } from 'phosphor-vue'
import AntragComponent from '@/components/antrag/AntragComponent.vue';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
    antragData: {
      type: Object,
      default: () => {},
    },
    componentFields: {
      type: Array,
      default: () => [],
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    config: {
    }
  },
  data() {
    return {
      items: [],
    }
  },
  watch: {
    value() {
      this.updateItems()
    }
  },
  computed: {
    visibilityOptions() {
      return [
        { label: 'Ja', value: true },
        { label: 'Nein', value: false },
      ]
    },
    itemStringify() {
      if (!this.items) {
        return null;
      }

      return JSON.stringify(this.items.map(item => ({
        id: item.id,
        configId: item.configId,
        configType: item.configType,
        position: item.position
      })))
    },
    valueParse() {
      return this.value ? JSON.parse(this.value) : []
    },
  },
  methods: {
    onComponentDataChange(content) {
      // emit item data
      this.$emit('inputItem', content);
    },
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onOrderChanged(items) {
      this.items = items ? items.map((item, index) => ({ ...item, position: index })) : null;

      // emit order
      this.emitCurrentItems()
    },
    emitCurrentItems() {
      this.$emit('input', this.itemStringify);
    },
    updateItems() {
      const items = this.config?.componentFields ? [ ...this.config?.componentFields ] : []

      if (this.valueParse) {
        items?.sort((a, b) => this.findComponentInValueParse(a).position - this.findComponentInValueParse(b).position);
      }

      this.items = items;
    },
    findComponentInValueParse(component) {
      const found = this.valueParse?.find(item  => item.id === component.id || (item.configId === component.configId && item.configType === component.configType))
      return found || {}
    }
  },
  mounted() {
    this.updateItems();
  },
  components: {
    SortableList,
    PhList,
    FormLabel,
    AntragComponent,
  },
}
</script>
