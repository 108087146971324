export const MUTATION_PREFIX = 'HONORARE_MUTATIONS_';
export const ACTIONS_PREFIX = 'HONORARE_ACTIONS_';
export const GETTERS_PREFIX = 'HONORARE_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    CREATE_FEES_SUCCESS: MUTATION_PREFIX + 'CREATE_FEES_SUCCESS',
    CONFIRM_FEES_SUCCESS: MUTATION_PREFIX + 'CONFIRM_FEES_SUCCESS',
    GET_FEES_SUCCESS: MUTATION_PREFIX + 'GET_FEES_SUCCESS',
    SELECTED_ROW: MUTATION_PREFIX + 'SELECTED_ROW',
    MARK_CONFIRMED_SUCCESS: MUTATION_PREFIX + 'MARK_CONFIRMED_SUCCESS',
    ALLE_LOESCHEN_SUCCESS: MUTATION_PREFIX + 'ALLE_LOESCHEN_SUCCESS',
    LEERE_LOESCHEN_SUCCESS: MUTATION_PREFIX + 'LEERE_LOESCHEN_SUCCESS',
    BESTAETIGUNG_VORBEREITEN_SUCCESS: MUTATION_PREFIX + 'BESTAETIGUNG_VORBEREITEN_SUCCESS',
    GET_EDITOR_LAYOUT_DATA_SUCCESS: MUTATION_PREFIX + 'GET_EDITOR_LAYOUT_DATA_SUCCESS',
    EDIT_ROW: MUTATION_PREFIX + 'EDIT_ROW',
    SAVE_EDIT_POSITION_SUCCESS: MUTATION_PREFIX + 'SAVE_EDIT_POSITION_SUCCESS',
    DELETE_POSITION_SUCCESS: MUTATION_PREFIX + 'DELETE_POSITION_SUCCESS',
  },
  ACTIONS: {
    CREATE_FEES: ACTIONS_PREFIX + 'CREATE_FEES',
    CONFIRM_FEES: ACTIONS_PREFIX + 'CONFIRM_FEES',
    MARK_CONFIRMED: ACTIONS_PREFIX + 'MARK_CONFIRMED',
    GET_FEES: ACTIONS_PREFIX + 'GET_FEES',
    ALLE_LOESCHEN: ACTIONS_PREFIX + 'ALLE_LOESCHEN',
    LEERE_LOESCHEN: ACTIONS_PREFIX + 'LEERE_LOESCHEN',
    BESTAETIGUNG_VORBEREITEN: ACTIONS_PREFIX + 'BESTAETIGUNG_VORBEREITEN',
    GET_EDITOR_LAYOUT_DATA: ACTIONS_PREFIX + 'GET_EDITOR_LAYOUT_DATA',
    SAVE_EDIT_POSITION: ACTIONS_PREFIX + 'SAVE_EDIT_POSITION',
    DELETE_POSITION: ACTIONS_PREFIX + 'DELETE_POSITION',
    DELETE_HONORARE: ACTIONS_PREFIX + 'DELETE_HONORARE',
  },
  GETTERS: {
    FEES: GETTERS_PREFIX + 'FEES',
    SELECTED_ROW: GETTERS_PREFIX + 'SELECTED_ROW',
    EDIT_ROW: GETTERS_PREFIX + 'EDIT_ROW',
    EDITOR_LAYOUT_DATA: GETTERS_PREFIX + 'EDITOR_LAYOUT_DATA',
  }
}