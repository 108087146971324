<template>
    <a :href="'tel:' + phoneNumber.replace(/\s/g, '')"> {{ phoneNumber }} </a>
</template>

<script>
    export default {
        props: {
            phoneNumber: {
                default: {}
            }
        } 
    }
</script>

