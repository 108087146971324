var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "box__container",
        attrs: {
          tid: "2aea4560-8d7e-4815-93aa-1d0382a18f1c-" + _vm.showAccounts
        }
      },
      [
        _c(
          "div",
          { staticClass: "box__title" },
          [_c("AccountTypeIcon"), _vm._v(" " + _vm._s(_vm.title) + " ")],
          1
        ),
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                rowId: "index",
                headers: _vm.headers,
                rows: _vm.rows,
                rowsPerPage: 20,
                mobileConfig: {
                  title: "Name",
                  headers: ["Kontonummer", "Kontostand"]
                }
              },
              on: {
                "action-TRANSACTIONS": _vm.openTransactions,
                "action-INFO": _vm.openInfo
              }
            })
          : _vm.isLoading
          ? _c("GhostLoading", { attrs: { type: "table" } })
          : _c("NoData")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }