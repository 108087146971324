var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation"),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("Table", {
                attrs: {
                  rowId: "spartentypId",
                  title: _vm.TABLE_TITLE,
                  rows: _vm.rows,
                  headers: _vm.headers,
                  rowsPerPage: 20
                },
                on: {
                  "click-bezeichnung": _vm.openEditVorgabeModal,
                  "action-DELETE": _vm.deleteProvisionVorgabe
                }
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "editVorgabeModal",
          attrs: {
            modalTitle: "Provisionsvorgaben bearbeiten",
            labelButtonConfirm: "Speichern"
          },
          on: { onConfirmButton: _vm.saveProvisionVorgabe }
        },
        [
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(" Sparte: "),
            _c("span", { staticClass: "text-bold" }, [
              _vm._v(_vm._s(_vm.editProvisionVorgabe.bezeichnung))
            ])
          ]),
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(
              " Falls bei der Versicherung 'Vorgabe' gewählt wird, so werden die Sätze/Berechnungsverfahren in folgender Priorität angewendet: "
            )
          ]),
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Prio 1: Prämie und Laufzeit mit Maximierung")
          ]),
          _c("InputField", {
            attrs: {
              label: "Jahresprämie*Laufzeit / 1,04 in %o",
              type: "percent"
            },
            model: {
              value: _vm.editProvisionVorgabe.provsatzLaufzeit,
              callback: function($$v) {
                _vm.$set(_vm.editProvisionVorgabe, "provsatzLaufzeit", $$v)
              },
              expression: "editProvisionVorgabe.provsatzLaufzeit"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Maximum in % der Jahresnettopämie / 1,04",
              type: "percent"
            },
            model: {
              value: _vm.editProvisionVorgabe.provsatzDeckelung,
              callback: function($$v) {
                _vm.$set(_vm.editProvisionVorgabe, "provsatzDeckelung", $$v)
              },
              expression: "editProvisionVorgabe.provsatzDeckelung"
            }
          }),
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Prio 2: Provschema Abschluss")
          ]),
          _c("InputField", {
            attrs: { label: "in % der Jahresnettoprämie", type: "percent" },
            model: {
              value: _vm.editProvisionVorgabe.provsatz1,
              callback: function($$v) {
                _vm.$set(_vm.editProvisionVorgabe, "provsatz1", $$v)
              },
              expression: "editProvisionVorgabe.provsatz1"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "in % der Präme je Zahlweise (Bestand)",
              type: "percent"
            },
            model: {
              value: _vm.editProvisionVorgabe.provsatz1Bestand,
              callback: function($$v) {
                _vm.$set(_vm.editProvisionVorgabe, "provsatz1Bestand", $$v)
              },
              expression: "editProvisionVorgabe.provsatz1Bestand"
            }
          }),
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Prio 3: Provschema erhöhter Bestand")
          ]),
          _c("InputField", {
            attrs: { label: "in % der Präme je Zahlweise", type: "percent" },
            model: {
              value: _vm.editProvisionVorgabe.provsatz2,
              callback: function($$v) {
                _vm.$set(_vm.editProvisionVorgabe, "provsatz2", $$v)
              },
              expression: "editProvisionVorgabe.provsatz2"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Laufzeit Stornohaftung (in Monaten)",
              type: "number"
            },
            model: {
              value: _vm.editProvisionVorgabe.laufzeitStornohaftung,
              callback: function($$v) {
                _vm.$set(_vm.editProvisionVorgabe, "laufzeitStornohaftung", $$v)
              },
              expression: "editProvisionVorgabe.laufzeitStornohaftung"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }