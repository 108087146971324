import EXTERNE_ZUGANG_BANK_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import { openLink } from '@/link-resolvers';

import { ResolverId } from '@/link-resolvers/types';

const config = {
  defaultSpinner: true
};

const SERVICE_PATH = '/externe_zugang_bank'

export default {
  async [EXTERNE_ZUGANG_BANK_TYPES.ACTIONS.GET_LIST]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/list`, config);
    commit(EXTERNE_ZUGANG_BANK_TYPES.MUTATIONS.GET_LIST_SUCCESS, response?.data || []);
  },

  async [EXTERNE_ZUGANG_BANK_TYPES.ACTIONS.OPEN_URL](ctx, { company, id, payload }) {
    if(!company) return;

    openLink(ResolverId.ExterneZugangBank, {
      company, 
      id, 
      payload, 
    });
  },

}