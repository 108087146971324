import { mapGetters } from "vuex";
import CALENDAR_TYPES from "@/store/calendar/types";
import dayjs from "dayjs";

export default{
    computed: {
        ...mapGetters({
          getShownDays: CALENDAR_TYPES.GETTERS.GET_SHOWN_DAYS,
          compGetCalendarData: CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA,
          compGetWeekCalendarData: CALENDAR_TYPES.GETTERS.GET_WEEK_CALENDAR_DATA,
          compGetCalendarDataReady: CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA_READY,
          getHoursStart: CALENDAR_TYPES.GETTERS.GET_HOURS_START,
          getHoursStop: CALENDAR_TYPES.GETTERS.GET_HOURS_STOP,
          getShownHours: CALENDAR_TYPES.GETTERS.GET_SHOWN_HOURS
        }),
    
        hoursstart: {
          set(value) {
            let startHours = Number(value);
            let stopHours = Number(this.getHoursStop);
            this.makeShownHours(startHours, stopHours);
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_HOURS_START, value);
          },
          get() {
            return this.$store.state.hoursStart;
          }
        },
    
        hoursstop: {
          set(value) {
            let startHours = Number(this.getHoursStart);
            let stopHours = Number(value);
    
            this.makeShownHours(startHours, stopHours);
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_HOURS_STOP, value);
          },
          get() {
            return this.$store.state.hoursStop;
          }
        },
    },
    methods:{
        makeShownHours(startHours, stopHours) {
            let wholeHourLabels = [];
            for (let hstop = startHours; hstop <= stopHours; hstop++) {
              wholeHourLabels.push(
                dayjs()
                  .hour(hstop)
                  .minute(0)
                  .format("HH:mm")
              );
            }
            this.$store.commit(
              CALENDAR_TYPES.MUTATIONS.SET_SHOWN_HOURS,
              wholeHourLabels
            );
          },

          getMonthCalendarData(thisday, searchTerm = '') {
            var firstday = dayjs(thisday)
              .date(1)
              .day(0);
            var last = dayjs(thisday).daysInMonth();
            var lastday = dayjs(thisday)
              .date(last)
              .day(6);
            this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_CALENDAR_DATA, {
              datesToSend: {
                fromDate: dayjs(firstday).format("DD.MM.YYYY"),
                toDate: dayjs(lastday).format("DD.MM.YYYY"),
                searchTerm: searchTerm
              },
              createHours: false,
              createWeeks: false
            });
          },

          getWeekCalendarData(thisday, searchTerm = '') {
            this.makeShownHours(Number(0), Number(23));
            var firstday = dayjs(thisday).weekday(0);
            var lastday = dayjs(thisday).weekday(6);
           
            if (
              this.compgetWeekCalendarData === undefined || this.compgetWeekCalendarData.length === 0 ||  
              firstday.isBefore(
                dayjs(this.compgetWeekCalendarData[0].date, "D.MM.YYYY"),
                "date"
              ) ||
              lastday.isAfter(
                dayjs(
                  this.compgetWeekCalendarData[this.compgetWeekCalendarData.length - 1].date,
                  "D.MM.YYYY"
                ),
                "date"
              )
            ) 
            {
              this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_CALENDAR_DATA, {
                datesToSend: {
                  fromDate: dayjs(firstday).format("DD.MM.YYYY"),
                  toDate: dayjs(lastday).format("DD.MM.YYYY"),
                  searchTerm: searchTerm
                },
                createHours: true,
                createWeeks: false
              });
            } else {
              this.$store.commit(CALENDAR_TYPES.MUTATIONS.RESET_HOURS_CONTENT);
              this.$store.dispatch(CALENDAR_TYPES.ACTIONS.CREATE_WEEK_CALENDAR_DATA, {
                fromDate: dayjs(firstday).format("D.MM.YYYY"),
                toDate: dayjs(lastday).format("D.MM.YYYY"),
                searchTerm: searchTerm
              });
            }



          },
    },
}