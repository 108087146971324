var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          actions: _vm.headerActions
        },
        on: { "action-LOGIN": _vm.login }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.subtitle))
          ]),
          _c("InputField", {
            attrs: { label: "Nutzerkennung", isComponentHalfSize: "" },
            model: {
              value: _vm.form.loginName,
              callback: function($$v) {
                _vm.$set(_vm.form, "loginName", $$v)
              },
              expression: "form.loginName"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Passwort",
              type: "password",
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.password,
              callback: function($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: { label: "Passwort merken", inLineLabel: "" },
            model: {
              value: _vm.form.savePassword,
              callback: function($$v) {
                _vm.$set(_vm.form, "savePassword", $$v)
              },
              expression: "form.savePassword"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }