var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feiertage-gruppe-spezial__container" },
    [
      _c(
        "div",
        { staticClass: "feiertage-gruppe-spezial--header" },
        [
          _c("span", { staticClass: "box__title" }, [
            _vm._v("Eigene Feiertage")
          ]),
          _c(
            "BaseContextMenu",
            [
              _c(
                "ContextMenuItem",
                { on: { click: _vm.openFeiertageGruppeSpezialModal } },
                [_vm._v("Neue Gruppe hinzufügen")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isBrokerView
        ? _c("div", { staticClass: "text-italic mb-2" }, [
            _vm._v(
              "Achtung: Die angelegten Feiertage und Feiertagsgruppen sind ersichtlich für Ihre Unterstruktur"
            )
          ])
        : _vm._e(),
      _c("Table", {
        attrs: { headers: _vm.headers, rows: _vm.rows, hidePagination: "" },
        on: {
          "action-EDIT": _vm.openFeiertageGruppeSpezialModal,
          "action-CONFIG": _vm.openFeiertageSpezialModal,
          "action-DELETE": _vm.deleteGruppe
        },
        scopedSlots: _vm._u([
          {
            key: "active",
            fn: function(row) {
              return [
                _c("InputToggleSwitch", {
                  attrs: { value: _vm.isSelected(row.id) },
                  on: {
                    input: function($event) {
                      return _vm.select(row.id, $event)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("FeiertageGruppeSpezialModal", { ref: "feiertageGruppeSpezialModal" }),
      _c("FeiertageSpezialModal", { ref: "feiertageSpezialModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }