var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.spartenForm, function(sparte, index) {
        return _c(
          "div",
          { key: index, staticStyle: { "margin-bottom": "8px" } },
          [
            _c("InputToggleSwitch", {
              staticClass: "mt-2",
              attrs: {
                value: _vm.selection[sparte.stepKey],
                label: sparte.label,
                inLineLabel: "",
                suppressValidationMessage: "",
                disabled: _vm.getDisableRule(
                  sparte.stepKey,
                  sparte.config && sparte.config.primary
                )
              },
              on: {
                input: function($event) {
                  return _vm.selectionChanged(sparte.stepKey, $event)
                }
              }
            })
          ],
          1
        )
      }),
      _vm.canAddNewSparten
        ? _c("BaseButton", { on: { click: _vm.neueSparteAnlegen } }, [
            _vm._v(" Neue Sparte anlegen ")
          ])
        : _vm._e(),
      _c("AddSparte", { ref: "addSparteModal" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }