<template>
  <div class="expanded-event-view__container" @mouseenter="show($event)" @mouseleave="hide()">
    <slot></slot>

    <div data-expanded-event-view class="expanded-view" :class="{'is-multiple-days': isMultipleDays}">
      <div class="expanded-view--wrap">
        <div class="expanded-view--title" :style="{ 'background-color': event.lighterColor }">
          <div class="event-cell-before" :style="{ 'background-color': event.color }"></div>
          <div>
            <span>{{event.label}}</span>
          </div>
        </div>

        <div v-if="event.bereichDescription" class="expanded-view--info expanded-view--desc text-small">
          <span class="expanded-view--info--icon"><PhTextAlignLeft size="20" /></span> <div>{{event.bereichDescription}}</div>
        </div>

        <div v-if="event.place" class="expanded-view--info text-small">
          <span class="expanded-view--info--icon"><PhMapPinLine size="20" /></span> <div v-html="sanitize(event.place)" />
        </div>
        <div class="expanded-view--info expanded-view--time text-small">
          <span class="expanded-view--info--icon"><PhClock size="20" /></span> <div>{{ dateTimeText }}</div>
        </div>
        <div v-if="event.repetition" class="expanded-view--info expanded-view--repetition text-small">
          <span class="expanded-view--info--icon"><PhArrowsClockwise size="20" /></span> 
          <div>
            <template v-if="event.repetitionMaxEndDate">bis {{ event.repetitionMaxEndDate }} ({{ event.repetitionName }})</template>
            <template v-else>{{ event.repetitionName }}</template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import BrowserSupport from '@/browser-support';

import {sanitize} from '@/helpers/string-helper.js';
import { PhTextAlignLeft, PhMapPinLine, PhClock, PhArrowsClockwise,  } from 'phosphor-vue';

const CALENDAR_HOVER_ACTIVE_CLASS = 'calendar-hover-active';

const EXPANDED_VIEW_ARROW_OFFSET = 32;
const EXPANDED_VIEW_ARROW_SIZE = 16;
const MOUSE_EVENT_NO_PASSIVE_OPTIONS = BrowserSupport.supportsPassive ? { passive: false, capture: false, } : false;

const POSITIONED_LEFT_CLASS = 'positioned-left';
const POSITIONED_RIGHT_CLASS = 'positioned-right';
const POSITIONED_TOP_CLASS = 'positioned-top';

const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
const TIME_FORMAT = 'HH:mm';

export default {
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: undefined,
    },
    isOneLineContainer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastXPosition: null,
      lastYPosition: null,
    };
  },
  computed: {
    eventPeriod() {
      const { event, } = this;

      const startDateEvent = event.repetitionStartDate || event.startDate;
      const endDateEvent = event.repetitionEndDate || event.endDate;

      if(!startDateEvent) return {};

      const startDate = dayjs(startDateEvent, DATE_TIME_FORMAT);
      const endDate = endDateEvent ? dayjs(endDateEvent, DATE_TIME_FORMAT) : null;
      const startTime = startDate.format(TIME_FORMAT);
      const endTime = endDate ? endDate.format(TIME_FORMAT) : event.endTime;

      return {
        startDate,
        endDate,
        startTime,
        endTime,
      };
    },
    isWholeDay() {
      return !!this.event?.wholeDay;
    },
    isMultipleDays() {
      const { startDate, endDate, } = this.eventPeriod;
      return !startDate?.isSame?.(endDate, 'day');
    },
    dateTimeText() {
      const { startDate, endDate, startTime, endTime, } = this.eventPeriod;
      if(!startDate) return '';

      if(this.isWholeDay) {
        return `${startDate.format('dddd, D MMMM YYYY')} (ganztägig)`;
      } else if(this.isMultipleDays) {
        return `von ${startDate.format('dddd, D MMMM YYYY')} ${startTime} bis ${endDate.format('dddd, D MMMM YYYY')} ${endTime}`;
      } else {
        return `von ${startDate.format('dddd, D MMMM YYYY')} ${startTime} bis ${endTime}`;
      }
    },
  },
  methods: {
    sanitize(html) {
      return sanitize(html);
    },
    getViewElements() {
      const rootEl = this.$el;

      const expandedViewEl = rootEl.querySelector('[data-expanded-event-view]');
      const viewEl = rootEl.querySelector('[data-event-view]');
      if(!expandedViewEl || !viewEl) {
        console.error(`${!viewEl ? '[data-event-view]' : '[data-expanded-event-view]'} is missing`);
        return {};
      }
      const scrollEl = rootEl.closest('[data-scroll-container]') || document;
      return { expandedViewEl, viewEl, scrollEl, };
    },
    isPositionedToRight(viewBounding, expandedViewBounding) {
      const remainingRightContextWidth = window.innerWidth - viewBounding.right - EXPANDED_VIEW_ARROW_OFFSET;
      return remainingRightContextWidth > expandedViewBounding.width;
    },
    isPositionedToLeft(viewBounding, expandedViewBounding) {
      const remainingLeftContextWidth = viewBounding.left - EXPANDED_VIEW_ARROW_OFFSET;
      return remainingLeftContextWidth > expandedViewBounding.width;
    },
    makeVisible(pageX, pageY) {
      const { expandedViewEl, viewEl, } = this.getViewElements();

      // add css class to make the element visible
      const rootEl = this.$el;
      rootEl.classList.add('hover-active');
      document.body.classList.add(CALENDAR_HOVER_ACTIVE_CLASS);

      const expandedViewBounding = expandedViewEl.getBoundingClientRect();
      const viewBounding = viewEl.getBoundingClientRect();

      // margin top
      if(this.isOneLineContainer) {
        expandedViewEl.style.marginTop = `-${expandedViewBounding.height / 2 - viewBounding.height / 2}px`; // set at the center
      } else {
        expandedViewEl.style.marginTop = `-${expandedViewBounding.height / 2}px`; // set at the top
      }

      // position class
      if(this.isPositionedToRight(viewBounding, expandedViewBounding)) {
        expandedViewEl.classList.add(POSITIONED_RIGHT_CLASS);
      } else if(this.isPositionedToLeft(viewBounding, expandedViewBounding)) {
        expandedViewEl.classList.add(POSITIONED_LEFT_CLASS);
      } else {
        expandedViewEl.classList.add(POSITIONED_TOP_CLASS);
      }

      this.positionExtendedView(this.lastXPosition || pageX, this.lastYPosition || pageY);
    },
    positionExtendedView(pageX, pageY) {
      const { expandedViewEl, viewEl, } = this.getViewElements();
      if(!viewEl || !expandedViewEl) return;

      // calculate position
      const viewBounding = viewEl.getBoundingClientRect();
      const expandedViewBounding = expandedViewEl.getBoundingClientRect();

      let positionX = null, positionY = this.isOneLineContainer ? viewBounding.y : pageY;
      if(this.isPositionedToRight(viewBounding, expandedViewBounding)) {
        positionX = viewBounding.x + viewBounding.width + EXPANDED_VIEW_ARROW_SIZE
      } else if(this.isPositionedToLeft(viewBounding, expandedViewBounding)) {
        positionX = viewBounding.x - expandedViewBounding.width - EXPANDED_VIEW_ARROW_SIZE;
      } else {
        positionX = viewBounding.x - EXPANDED_VIEW_ARROW_SIZE;
        const oneLineContainerHeight = this.isOneLineContainer ? viewBounding.height : 0;
        positionY = positionY - (expandedViewBounding.height / 2) - oneLineContainerHeight;
      }

      // set position
      this.setPositionExtendedView(positionX, positionY);

      this.lastXPosition = pageX;
      this.lastYPosition = pageY;
    },
    setPositionExtendedView(x, y) {
      const { expandedViewEl, } = this.getViewElements();
      expandedViewEl.style.transform = `translate(${x}px, ${y}px)`;
    },
    show(event) {
      clearTimeout(this.mouseOverTimeoutId);

      const { expandedViewEl, viewEl, scrollEl, } = this.getViewElements();
      if(!expandedViewEl || !viewEl) return;

      this.mouseOverTimeoutId = setTimeout(() => this.makeVisible(event.x, event.y), 300);

      if(!this.isOneLineContainer) {
        document.addEventListener('mousemove', this.move, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
        scrollEl?.addEventListener('scroll', this.scroll);
      }
    },
    move(event) {
      this.positionExtendedView(event.x, event.y);
    },
    scroll() {
      if(this.lastXPosition === null || this.lastXPosition === null) return;

      const elementFromPoint = document.elementFromPoint(this.lastXPosition, this.lastYPosition);
      const rootEl = this.$el;
      const currentEl = elementFromPoint.classList.contains('expanded-event-view__container') || elementFromPoint.closest('.expanded-event-view__container');
      if(currentEl === rootEl) {
        this.positionExtendedView(this.lastXPosition, this.lastYPosition);
      } else {
        clearTimeout(this.mouseOverTimeoutId);
        rootEl.dispatchEvent(new Event('mouseleave'));
      }
    },
    hide() {
      clearTimeout(this.mouseOverTimeoutId);

      const rootEl = this.$el;

      rootEl.classList.remove('hover-active');
      document.body.classList.remove(CALENDAR_HOVER_ACTIVE_CLASS);

      const { expandedViewEl, scrollEl, } = this.getViewElements(rootEl);
      if(expandedViewEl) {
        expandedViewEl.classList.remove(POSITIONED_LEFT_CLASS);
        expandedViewEl.classList.remove(POSITIONED_RIGHT_CLASS);
        expandedViewEl.classList.remove(POSITIONED_TOP_CLASS);
      }

      if(!this.isOneLineContainer) {
        document.removeEventListener('mousemove', this.move, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
        scrollEl?.removeEventListener('scroll', this.scroll);
      }
    },
  },
  components: {
    PhTextAlignLeft,
    PhMapPinLine,
    PhClock,
    PhArrowsClockwise,
  },
}
</script>

<style scoped>
.expanded-event-view__container {
  position: relative;
}

.expanded-view {
  background: var(--color-box);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 2px 16px rgba(85, 85, 85, 0.25);
  color: var(--color-link);
  line-height: 1.2em;
  opacity: 0;
  overflow: inherit;
  padding: 0;
  pointer-events: none;
  position: fixed;
  top: -999px;
  left: -999px;
  z-index: 1000;
  width: 0;
  height: 0;
}

.expanded-view--title {
  color: var(--color-text);
}

@media (prefers-color-scheme: dark) {
  .expanded-view--title {
    color: var(--color-box);
  }
}

.expanded-view.positioned-right {
  left: calc(100% + 16px);
}
.expanded-view.positioned-left {
  right: calc(100% + 16px);
}
.expanded-view.positioned-top {
    right: calc(100% + 16px);
}
.hover-active .expanded-view {
  margin-top: 0;
  padding: 8px;
  width: 350px;
  height: auto;
  min-height: 4em;
  opacity: 1;
  top: 0;
  left: 0;
  transition: opacity .25s ease;
}
.hover-active .expanded-view:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  box-sizing: border-box;

  border: 0.5em solid black;
  border-color: transparent transparent var(--color-box) var(--color-box);

  transform-origin: 0 0;
  box-shadow: -2px 2px 2px 0 rgba(85, 85, 85, 0.10);
}
.hover-active .expanded-view.positioned-right:after {
  top: calc(50% - 12px);
  left: 1px;
  transform: rotate(45deg);
}
.hover-active .expanded-view.positioned-left:after {
  top: calc(50% + 12px);
  left: calc(100% - 1px);
  transform: rotate(225deg);
}
.hover-active .expanded-view.positioned-top:after {
    top: calc(100% - 1px);
    left: 8px;
    transform: rotate(315deg);
}
.calendar-hover-active .event-cell {
  opacity: 0.88;
}
.expanded-view .expanded-view--info {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding: 0;
}
.expanded-view .expanded-view--info:last-child {
  margin-bottom: 0;
}
.expanded-view--info .expanded-view--info--icon {
  flex: 0 0 auto;
  margin: 0 4px 0 0;
}
.expanded-view--title {
  border-radius: 4px;
  margin: 0 0 8px !important;
  padding: 8px 0 8px 8px;
  position: relative;
  height: auto !important;
}

@media screen and (max-width: 1074px) {
  .hover-active .expanded-view,
  .hover-active .expanded-view.is-multiple-days {
    width: 320px;
  }
}
</style>
