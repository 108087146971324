export const MUTATION_PREFIX = 'RETIREMENT_SCENARIO_MUTATIONS_';
export const ACTIONS_PREFIX = 'RETIREMENT_SCENARIO_ACTIONS_';
export const GETTERS_PREFIX = 'RETIREMENT_SCENARIO_GETTERS_';

export default {
  MUTATIONS: {
    GET_SCENARIOS_SUCCESS: MUTATION_PREFIX + 'GET_SCENARIOS_SUCCESS',
    GET_SCENARIOS_FAILTURE: MUTATION_PREFIX + 'GET_SCENARIOS_FAILTURE',
    DELETE_SCENARIO_SUCCESS: MUTATION_PREFIX + 'DELETE_SCENARIO_SUCCESS',
    DELETE_SCENARIO_FAILTURE: MUTATION_PREFIX + 'DELETE_SCENARIO_FAILTURE',
    SET_SCENARIO_SUCCESS: MUTATION_PREFIX + 'SET_SCENARIO_SUCCESS',
    SET_SCENARIO_FAILTURE: MUTATION_PREFIX + 'SET_SCENARIO_FAILTURE',
    GET_PERSONAL_DATA_SUCCESS: MUTATION_PREFIX + 'GET_PERSONAL_DATA_SUCCESS',
    GET_PERSONAL_DATA_FAILURE: MUTATION_PREFIX + 'GET_PERSONAL_DATA_FAILURE',
  },
  ACTIONS: {
    GET_SCENARIOS: ACTIONS_PREFIX + 'GET_SCENARIOS',
    DELETE_SCENARIO: ACTIONS_PREFIX + 'DELETE_SCENARIO',
    SET_SCENARIO: ACTIONS_PREFIX + 'SET_SCENARIO',
    GET_PERSONAL_DATA: ACTIONS_PREFIX + 'GET_PERSONAL_DATA',
  },
  GETTERS: {
    SCENARIOS: GETTERS_PREFIX + 'SCENARIOS',
    CURRENT_SCENARIO: GETTERS_PREFIX + 'CURRENT_SCENARIO',
    PERSONAL_DATA: GETTERS_PREFIX + 'PERSONAL_DATA',
  }
}