var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "aktueller Bestand gem. Depotpositionen:",
          disabled: true,
          isComponentHalfSize: "",
          type: "currency"
        },
        model: {
          value: _vm.depotbestand,
          callback: function($$v) {
            _vm.depotbestand = $$v
          },
          expression: "depotbestand"
        }
      }),
      _vm.showBondDepotRelevant
        ? _c(
            "div",
            [
              _c("InputField", {
                attrs: {
                  label:
                    "beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
                  type: "currency",
                  isComponentHalfSize: "",
                  disabled: _vm.isDisabled || _vm.isRefusedPage
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore("finance", "bondDepot", $event || 0)
                  }
                },
                model: {
                  value: _vm.bondDepot,
                  callback: function($$v) {
                    _vm.bondDepot = $$v
                  },
                  expression: "bondDepot"
                }
              }),
              _c("InputField", {
                attrs: {
                  label:
                    "nicht beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
                  type: "currency",
                  isComponentHalfSize: "",
                  disabled: _vm.isDisabled || _vm.isRefusedPage
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore(
                      "finance",
                      "bondDepotNotRelevant",
                      $event || 0
                    )
                  }
                },
                model: {
                  value: _vm.bondDepotNotRelevant,
                  callback: function($$v) {
                    _vm.bondDepotNotRelevant = $$v
                  },
                  expression: "bondDepotNotRelevant"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("InputField", {
                staticClass: "font-bold",
                attrs: {
                  label:
                    "Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
                  type: "currency",
                  isComponentHalfSize: "",
                  disabled: _vm.isDisabled || _vm.isRefusedPage
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore("finance", "bondDepot", $event || 0)
                  }
                },
                model: {
                  value: _vm.bondDepot,
                  callback: function($$v) {
                    _vm.bondDepot = $$v
                  },
                  expression: "bondDepot"
                }
              })
            ],
            1
          ),
      _c("InputField", {
        attrs: {
          label: "gebundene Spareinlagen (z.B. Sparbuch, Festgeld)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "boundBankDeposit", $event || 0)
          }
        },
        model: {
          value: _vm.boundBankDeposit,
          callback: function($$v) {
            _vm.boundBankDeposit = $$v
          },
          expression: "boundBankDeposit"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Alternative Investments",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "finance",
              "alternativeInvestment",
              $event || 0
            )
          }
        },
        model: {
          value: _vm.alternativeInvestment,
          callback: function($$v) {
            _vm.alternativeInvestment = $$v
          },
          expression: "alternativeInvestment"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Kunstgegenstände, Antiquitäten",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "art", $event || 0)
          }
        },
        model: {
          value: _vm.art,
          callback: function($$v) {
            _vm.art = $$v
          },
          expression: "art"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Bausparverträge (aktuelle Werte)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "buildingSaving", $event || 0)
          }
        },
        model: {
          value: _vm.buildingSaving,
          callback: function($$v) {
            _vm.buildingSaving = $$v
          },
          expression: "buildingSaving"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Lebensversicherungen, Kapitalversicherungen (aktuelle Rückkaufswerte)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "insurance", $event || 0)
          }
        },
        model: {
          value: _vm.insurance,
          callback: function($$v) {
            _vm.insurance = $$v
          },
          expression: "insurance"
        }
      }),
      _c("InputField", {
        staticClass: "font-bold",
        attrs: {
          label: "Sonstiges",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "otherInvestment", $event || 0)
          }
        },
        model: {
          value: _vm.otherInvestment,
          callback: function($$v) {
            _vm.otherInvestment = $$v
          },
          expression: "otherInvestment"
        }
      }),
      _c("div", { staticClass: "sum d-flex justify-content-between" }, [
        _c("div", { staticClass: "my-2" }, [
          _vm._v("Summe Kapitalvermögen / sonstiger Vermögenswerte")
        ]),
        _c(
          "div",
          { staticClass: "my-2 pr-3" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeAnlage } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }