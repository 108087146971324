export const MUTATION_PREFIX = 'INTERNESTATISTIKEN_MUTATIONS_';
export const ACTIONS_PREFIX = 'INTERNESTATISTIKEN_ACTIONS_';
export const GETTERS_PREFIX = 'INTERNESTATISTIKEN_GETTERS_';

export default {
  MUTATIONS: {
    SET_STATISTICGROUPS_LIST: MUTATION_PREFIX + 'SET_STATISTICGROUPS_LIST',
    SET_GESELLSCHAFTENCOMBO: MUTATION_PREFIX + 'SET_GESELLSCHAFTENCOMBO',
    SET_FILTER_BROKER_NUMBER: MUTATION_PREFIX + 'SET_FILTER_BROKER_NUMBER',
  },
  ACTIONS: {
    RETRIEVE_STATISTICGROUPS_LIST: ACTIONS_PREFIX + 'RETRIEVE_STATISTICGROUPS_LIST',
  },
  GETTERS: {
    STATISTICSGROUPS_LIST: GETTERS_PREFIX + 'STATISTICSGROUPS_LIST',
    GESELLSCHAFTENCOMBO: GETTERS_PREFIX + 'GESELLSCHAFTENCOMBO',
    FILTER_BROKER_NUMBER: GETTERS_PREFIX + 'FILTER_BROKER_NUMBER',
  }
  
}