var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DragnDropArea",
        {
          staticClass: "box-container",
          attrs: { hoverText: "Neues Dokument hochladen" },
          on: {
            files: function($event) {
              return _vm.openAddDocumentModal($event)
            }
          }
        },
        [
          _vm.editMode
            ? _c(
                "BaseButton",
                {
                  staticClass: "mb-16px",
                  on: {
                    click: function($event) {
                      return _vm.openAddDocumentModal($event)
                    }
                  }
                },
                [_vm._v(" Neuer Anhang ")]
              )
            : _vm._e(),
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  rows: _vm.rows,
                  headers: _vm.headers,
                  tableId: "3cd44c85-12b2-4625-b32c-520e4bf6564g"
                },
                on: {
                  "click-bezeichnung": function($event) {
                    return _vm.openAntrag($event)
                  },
                  "action-DELETE": function($event) {
                    return _vm.deleteAntrag($event)
                  }
                }
              })
            : _c("div", [_vm._v("Keine Daten")])
        ],
        1
      ),
      _c("BaseModal", {
        ref: "addDocumentModal",
        attrs: { showDefaultButtons: false },
        on: {
          close: function($event) {
            return _vm.cancelEditing()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.inserting == true ? "Neu Anhang" : "Anhang Bearbeiten"
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Datei hochladen")
                ]),
                _c(
                  "div",
                  { staticClass: "group-buttons" },
                  [
                    _c(
                      "BaseFileSelect",
                      {
                        staticClass: "mb-16px",
                        on: {
                          files: function($event) {
                            return _vm.sendAnhang($event)
                          }
                        }
                      },
                      [_vm._v(" Datei hinzufügen ")]
                    ),
                    _vm.cameraAvailable
                      ? _c(
                          "FotoButton",
                          {
                            staticClass: "mb-1",
                            attrs: { isSecondary: "", edgeCorrection: "" },
                            on: {
                              file: function($event) {
                                return _vm.sendAnhang([$event])
                              }
                            }
                          },
                          [_vm._v(" Fotografieren ")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("span", [_vm._v(_vm._s(_vm.filename))]),
                    _c("br")
                  ])
                ]),
                [
                  _c("InputField", {
                    attrs: { label: "Bezeichnung", validateUntouched: true },
                    model: {
                      value: _vm.fileForm.bezeichnung,
                      callback: function($$v) {
                        _vm.$set(_vm.fileForm, "bezeichnung", $$v)
                      },
                      expression: "fileForm.bezeichnung"
                    }
                  })
                ],
                [
                  _c("InputField", {
                    attrs: { label: "Bemerkung" },
                    model: {
                      value: _vm.fileForm.bemerkung,
                      callback: function($$v) {
                        _vm.$set(_vm.fileForm, "bemerkung", $$v)
                      },
                      expression: "fileForm.bemerkung"
                    }
                  })
                ],
                [
                  _c("ComboBox", {
                    attrs: {
                      label: "Kategorie",
                      values: _vm.kategories(),
                      firstEmpty: true
                    },
                    model: {
                      value: _vm.fileForm.kategorie,
                      callback: function($$v) {
                        _vm.$set(_vm.fileForm, "kategorie", $$v)
                      },
                      expression: "fileForm.kategorie"
                    }
                  })
                ],
                [
                  _c("DatePickerField", {
                    attrs: {
                      label: "Stand",
                      isComponentHalfSize: true,
                      dateFormat: "DD.MM.YYYY"
                    },
                    model: {
                      value: _vm.fileForm.dateStand,
                      callback: function($$v) {
                        _vm.$set(_vm.fileForm, "dateStand", $$v)
                      },
                      expression: "fileForm.dateStand"
                    }
                  })
                ]
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.closeAddDocumentModal()
                      }
                    }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.speichernFileForm()
                      }
                    }
                  },
                  [
                    _vm._v(" Anhang speichern "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }