var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.$appNavigation.currentMenu.label }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        _vm._l(_vm.goalsTypes, function(item, index) {
          return _c("div", { key: "goal" + index, staticClass: "img-card" }, [
            _c(
              "div",
              {
                staticClass: "card mb-4 mr-4 img-card",
                on: {
                  click: function($event) {
                    return _vm.chooseGoal(item)
                  }
                }
              },
              [
                _c("div", { staticClass: "rel" }, [
                  _c("img", {
                    staticClass: "card-img-top",
                    attrs: { alt: "Platzhalter", src: item.img }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-img-overlay text-white card-overlay-dark"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "overlay-panel overlay-panel-bottom color-white mb-auto"
                        },
                        [_c("h3", [_vm._v(_vm._s(item.name))])]
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }