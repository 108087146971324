import { render, staticRenderFns } from "./AllgemeineVertragsdaten.vue?vue&type=template&id=ab203d50&scoped=true&"
import script from "./AllgemeineVertragsdaten.vue?vue&type=script&lang=js&"
export * from "./AllgemeineVertragsdaten.vue?vue&type=script&lang=js&"
import style0 from "./AllgemeineVertragsdaten.vue?vue&type=style&index=0&id=ab203d50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab203d50",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab203d50')) {
      api.createRecord('ab203d50', component.options)
    } else {
      api.reload('ab203d50', component.options)
    }
    module.hot.accept("./AllgemeineVertragsdaten.vue?vue&type=template&id=ab203d50&scoped=true&", function () {
      api.rerender('ab203d50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/versicherungen/AllgemeineVertragsdaten.vue"
export default component.exports