var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "li",
        {
          staticClass: "sortable-list-item__container list-bordered-item",
          attrs: { "data-sortable-item": "" }
        },
        [
          _c("div", { staticClass: "sortable-list-item__actions" }, [
            _vm.removable
              ? _c(
                  "button",
                  {
                    staticClass: "btn-clear clickable btn-action",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.confirmRemove()
                      }
                    }
                  },
                  [
                    _c("PhProhibitInset", {
                      staticClass: "color-danger rotate-prohibit-inset",
                      attrs: { size: 18, weight: "fill" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("span", { staticClass: "sortable-list-item__label" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c(
            "div",
            { staticClass: "sortable-list-item__actions" },
            [
              _c("transition-group", { attrs: { name: "slide" } }, [
                _c(
                  "button",
                  {
                    key: 1,
                    staticClass: "btn-clear clickable btn-action",
                    attrs: { type: "button", "data-sortable-item-handler": "" }
                  },
                  [_c("PhList", { attrs: { size: 18 } })],
                  1
                ),
                _vm.showConfirmRemove
                  ? _c(
                      "button",
                      {
                        key: 2,
                        staticClass: "ml-2 clickable confirm-delete-button",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.remove()
                          }
                        }
                      },
                      [_vm._v(" Löschen ")]
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }