var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c(
        "DragnDropArea",
        {
          attrs: { hoverText: "Datei hier ablegen" },
          on: { files: _vm.dropFiles }
        },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Zusätzliche Dokumente")
          ]),
          _c("div", { staticClass: "font-strong mb-1" }, [
            _vm._v(
              " Wenn Sie möchten können Sie hier Dokumente auswählen, die dem Antrag hinzugefügt werden. "
            )
          ]),
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  headerActions: _vm.tableHeaderActions,
                  rowsPerPage: 10,
                  selected: _vm.antragAttachments
                },
                on: {
                  selected: _vm.setSelected,
                  "headerAction-UPLOAD": function($event) {
                    return _vm.dropFiles([])
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "bezeichnung",
                      fn: function(row) {
                        return [
                          row.id
                            ? _c("DownloadLink", {
                                attrs: {
                                  title: row.bezeichnung,
                                  downloadServicePath: "/get_simple_file",
                                  filename:
                                    row.bezeichnung + " (" + row.herkunft + ")",
                                  queryParams: {
                                    fileId: row.id
                                  }
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(row.bezeichnung))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  528095699
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c("DocumentsUploadModal", {
        ref: "uploadModal",
        attrs: {
          openOnMount: false,
          suppressReload: "",
          additionalInputs: _vm.currentAdditionalInputs,
          parentId: _vm.nodeId,
          accept: ".pdf",
          allowFotoUpload: false
        },
        on: { uploadComplete: _vm.reloadAttachmentList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }