var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "a751cec9-7d0e-4c8a-a2d9-3a6c65a20454",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "799",
        height: "667.07575",
        viewBox: "0 0 799 667.07575"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M849.5,746.61987h-485l.21874-51.42174c.55348-130.11522,104.40844-237.32948,234.506-239.54236q2.10824-.03587,4.22346-.0359h.0004a245.33553,245.33553,0,0,1,85.75247,15.40589c92.054,34.28252,153.84089,121.38358,157.951,219.52807Z",
          transform: "translate(-200.5 -116.46212)",
          fill: _vm.color
        }
      }),
      _c("rect", {
        attrs: { y: "630.15774", width: "799", height: "2", fill: "#2f2e41" }
      }),
      _c("polygon", {
        attrs: {
          points:
            "398.287 400.165 419.657 415.708 441.028 598.33 491.54 598.33 474.055 390.451 404.115 363.252 398.287 400.165",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M509.4184,458.34384s-5.82836,66.05475,17.48508,67.99754,112.68163-19.42787,112.68163-19.42787l42.74131,151.53736,64.112-33.02737s-38.85573-170.96523-62.16917-180.67916-101.02491-29.1418-101.02491-29.1418l-56.34082,9.71393Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M649.29905,710.90611s-11.65673,44.68409-3.88558,48.56967a140.53483,140.53483,0,0,1,13.59951,7.77114v-7.77114s9.71393,9.71393,9.71393,17.48508,52.45524,9.71393,52.45524,0-1.94278-21.37066-11.65672-27.199-21.37065-38.85573-21.37065-38.85573Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "505.14 514.79 509.025 551.703 522.625 575.016 555.652 538.103 530.396 497.305 505.14 514.79",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M736.72445,668.1648s7.15372-24.46025,14.26219-21.94406,34.30747,27.77242,34.30747,27.77242L804.722,685.64988s38.85573,3.88558,27.199,15.5423-54.398,27.199-73.82589,19.42786-23.31344-9.71393-23.31344-9.71393l-5.82836,3.88557-23.31344-5.82836,3.88557-40.79852S715.35379,685.64988,736.72445,668.1648Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "374.97315",
          cy: "54.34929",
          r: "44.68409",
          fill: "#9f616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M547.30274,197.039s3.88558,40.79852-7.77114,40.79852S541.47438,288.35,572.559,288.35s42.74131-60.22639,42.74131-60.22639-21.37066-7.77115-17.48508-33.02737S547.30274,197.039,547.30274,197.039Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#9f616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M544.20687,234.72539l-16.332,6.99774L483.5501,260.47592a8.99955,8.99955,0,0,0-5.43656,9.29441l16.734,148.74639s-17.48508,36.91294,9.71394,38.85573L508.447,470.972l31.08459-36.913,116.5672-3.88557,1.94279-188.45031L604.70588,220.647S597.45181,260.755,544.20687,234.72539Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M489.99054,266.008l-7.17945-2.05127a5.919,5.919,0,0,0-7.47123,4.7594L456.288,388.22347a41.5016,41.5016,0,0,0,4.05469,25.91676c5.8867,11.14588,18.11259,21.25444,43.24739,9.23345l17.48508-126.28113Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M635.69954,238.809l23.31344,3.88557,64.112,89.36819L728.9533,347.605l36.913-11.65672s-1.94279,17.48508,17.48508,15.5423c0,0-23.31344,64.112-71.88311,44.68409s-64.112-77.71147-64.112-77.71147Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#3f3d56"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "462.399 161.203 374.973 126.232 371.088 137.889 411.886 172.859 462.399 161.203",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M548.56969,173.66c1.65033-.44064,1.89277-2.63175,1.94808-4.339.29622-9.14249,5.80151-18.52371,14.553-21.18523a24.46655,24.46655,0,0,1,10.313-.5675,39.06274,39.06274,0,0,1,13.96661,4.65951c2.27932,1.28145,4.51509,2.82482,7.10047,3.21626,1.70747.25852,9.10059,2.40806,10.80081,2.71057,3.7381.66508,7.2121,4.00959,10.79913,2.765,3.43173-1.19068,4.19139-5.634,4.27434-9.26547.189-8.27324-6.09157-19.72372-11.62353-25.87838-4.198-4.67054-10.519-6.82653-16.698-7.9476a111.168,111.168,0,0,0-21.83787-1.32849c-9.88858.17636-20.05125.93961-28.968,5.21828s-16.40657,12.79425-16.834,22.67516c-.089,2.05828.1233,4.12515-.04976,6.17808-.42218,5.00809-3.08153,9.5316-4.329,14.40017a26.35017,26.35017,0,0,0,3.67977,21.05747c3.30926,4.87005,16.54275,9.29112,16.35142,15.176,2.39183-2.478-3.24866-4.97242-.85683-7.45041a5.89248,5.89248,0,0,0,1.75244-7.19911l-2.53985-9.90672c-.46505-1.814-.92235-3.77752-.21992-5.51327C542.87485,164.40725,545.81807,174.39469,548.56969,173.66Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "371.088 137.889 334.175 281.655 477.941 332.168 561.481 262.227 613.936 104.862 528.453 167.031 371.088 137.889",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "647.35642",
          y: "216.98683",
          width: "1.94246",
          height: "144.66914",
          transform: "translate(2.65912 725.90593) rotate(-75.21727)",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "643.47085",
          y: "226.70077",
          width: "1.94246",
          height: "144.66914",
          transform: "translate(-9.62744 729.38435) rotate(-75.21727)",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "641.52806",
          y: "236.4147",
          width: "1.94246",
          height: "144.66914",
          transform: "translate(-20.46692 734.74125) rotate(-75.21727)",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "639.58528",
          y: "246.12863",
          width: "1.94246",
          height: "144.66914",
          transform: "translate(-31.3064 740.09815) rotate(-75.21727)",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "637.64249",
          y: "255.84257",
          width: "1.94246",
          height: "144.66914",
          transform: "translate(-42.14588 745.45505) rotate(-75.21727)",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "635.6997",
          y: "265.5565",
          width: "1.94246",
          height: "144.66914",
          transform: "translate(-52.98536 750.81195) rotate(-75.21727)",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "633.75692",
          y: "275.27043",
          width: "1.94246",
          height: "144.66914",
          transform: "translate(-63.82485 756.16885) rotate(-75.21727)",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "631.81413",
          y: "284.98437",
          width: "1.94246",
          height: "144.66914",
          transform: "translate(-74.66433 761.52575) rotate(-75.21727)",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "629.87134",
          y: "294.6983",
          width: "1.94246",
          height: "144.66914",
          transform: "translate(-85.50381 766.88265) rotate(-75.21727)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M512.35043,360.10426s43.69485-26.09876,63.12272-8.61368-56.34081,42.7413-56.34081,42.7413Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#9f616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M464.73431,372.86123l56.34081-21.37065s-9.71393,36.91294,9.71394,38.85573L503.59,423.37368S449.192,423.37368,464.73431,372.86123Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#3f3d56"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "412.494 255.602 487.255 280.799 469.562 319.365 394.801 294.169 412.494 255.602",
          fill: "#e6e6e6"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "544.967 173.831 601.308 131.089 585.766 183.545 527.482 226.286 544.967 173.831",
          fill: "#ccc"
        }
      }),
      _c("rect", {
        attrs: {
          x: "711.07913",
          y: "339.83365",
          width: "70.7185",
          height: "1.9432",
          transform: "translate(-254.72546 403.94235) rotate(-37.18317)",
          fill: "#e6e6e6"
        }
      }),
      _c("rect", {
        attrs: {
          x: "707.19355",
          y: "361.2043",
          width: "70.7185",
          height: "1.9432",
          transform: "translate(-268.43105 405.93854) rotate(-37.18317)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M777.523,353.43336s48.56966-29.1418,17.48508-40.79852-31.08459,33.02738-31.08459,33.02738S761.98067,355.37615,777.523,353.43336Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#9f616a"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "292.405 171.888 290.462 247.657 320.575 239.063 296.29 239.885 292.405 171.888",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M280.90863,712.35433c6.07317,22.45006,26.87512,36.35395,26.87512,36.35395s10.95541-22.49485,4.88225-44.94491-26.87512-36.354-26.87512-36.354S274.83547,689.90427,280.90863,712.35433Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#8985a8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M289.83272,707.52957c16.66484,16.22256,18.70033,41.1604,18.70033,41.1604s-24.98356-1.36411-41.64841-17.58667-18.70033-41.1604-18.70033-41.1604S273.16787,691.307,289.83272,707.52957Z",
          transform: "translate(-200.5 -116.46212)",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "626",
          y: "379.15774",
          width: "30",
          height: "30",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "506",
          y: "39.15774",
          width: "30",
          height: "30",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: { cx: "159", cy: "328.15774", r: "15", fill: _vm.secondColor }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }