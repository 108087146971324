var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no-data__container" }, [
    !_vm.noIcon
      ? _c("div", { staticClass: "no-data__icon" }, [
          _c(
            "div",
            { staticClass: "no-data__icon--wrap" },
            [_c("UndrawWarning")],
            1
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "no-data__content" }, [
      _c("div", { staticClass: "no-data__title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm.$slots.text
        ? _c("div", { staticClass: "no-data__text" }, [_vm._t("text")], 2)
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }