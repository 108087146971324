var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InsuranceOptionMenu", { attrs: { title: "Buchungsdaten" } }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: { id: "buchungsnr", label: "Buchungsnr", disabled: "" },
                model: {
                  value: _vm.form.buchungsnr,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "buchungsnr", $$v)
                  },
                  expression: "form.buchungsnr"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("ComboBox", {
                attrs: {
                  id: "sparte",
                  label: "Sparte",
                  values: _vm.buchungFieldsDefinition.sparte,
                  validateUntouched: "",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.spartenID,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "spartenID", $$v)
                  },
                  expression: "form.spartenID"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("DatePickerField", {
                attrs: {
                  id: "zeichnungsdatum",
                  label: "Zeichnungsdatum",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.zeichnungsdatum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "zeichnungsdatum", $$v)
                  },
                  expression: "form.zeichnungsdatum"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("DatePickerField", {
                attrs: {
                  id: "courtageeingang",
                  label: "courtageeingang",
                  validateUntouched: "",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.courtageeingDatum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "courtageeingDatum", $$v)
                  },
                  expression: "form.courtageeingDatum"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("ComboBox", {
                attrs: {
                  id: "courtageart",
                  label: "Courtageart",
                  values: _vm.buchungFieldsDefinition.courtageart,
                  validateUntouched: "",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.courtageart,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "courtageart", $$v)
                  },
                  expression: "form.courtageart"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: {
                  id: "courtagesumme",
                  label: "Courtagesumme",
                  type: "currency",
                  precision: 2,
                  validateUntouched: "",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.courtagesumme,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "courtagesumme", $$v)
                  },
                  expression: "form.courtagesumme"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("ComboBox", {
                attrs: {
                  id: "courtagewaehrung",
                  label: "Courtagewährung",
                  values: _vm.buchungFieldsDefinition.courtagewaehrung,
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.courtagewaehrung,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "courtagewaehrung", $$v)
                  },
                  expression: "form.courtagewaehrung"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: {
                  id: "bewertungssumme",
                  label: "Bewertungssumme",
                  type: "currency",
                  precision: 2,
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.bewertungssumme,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bewertungssumme", $$v)
                  },
                  expression: "form.bewertungssumme"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: {
                  id: "monatsbeitrag",
                  label: "Monatsbeitrag",
                  type: "currency",
                  precision: 2,
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.monatsbeitrag,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "monatsbeitrag", $$v)
                  },
                  expression: "form.monatsbeitrag"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputRadioBoxGroup", {
                attrs: {
                  values: _vm.buchungFieldsDefinition.stornoreserve,
                  title: "Stornoreserve",
                  disabled: !_vm.form.editable
                },
                on: {
                  input: function($event) {
                    return _vm.updateStornoreserve()
                  }
                },
                model: {
                  value: _vm.form.defaultStornoreserveSatz,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "defaultStornoreserveSatz", $$v)
                  },
                  expression: "form.defaultStornoreserveSatz"
                }
              })
            ],
            1
          )
        ]),
        _vm.isManualStornoreserve
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("InputField", {
                    attrs: {
                      id: "stornoreserve",
                      type: "currency",
                      precision: 2,
                      validateUntouched: _vm.isManualStornoreserve,
                      disabled: !_vm.form.editable
                    },
                    model: {
                      value: _vm.form.stornoreserve,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "stornoreserve", $$v)
                      },
                      expression: "form.stornoreserve"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputCheckBoxItem", {
                attrs: {
                  label: "Stornoreserve bei Gesellschaft",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.stornoreserveBeiGesellschaft,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "stornoreserveBeiGesellschaft", $$v)
                  },
                  expression: "form.stornoreserveBeiGesellschaft"
                }
              })
            ],
            1
          )
        ]),
        _vm.isFK
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("InputRadioBoxGroup", {
                    attrs: {
                      title: "Einbehalt Versicherung",
                      values:
                        _vm.buchungFieldsDefinition.versicherungseinbehalt,
                      disabled: !_vm.form.editable
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateVersicherungseinbehalt()
                      }
                    },
                    model: {
                      value: _vm.form.defaultVersicherungseinbehaltSatz,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "defaultVersicherungseinbehaltSatz",
                          $$v
                        )
                      },
                      expression: "form.defaultVersicherungseinbehaltSatz"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.isFK && _vm.isManualVersicherungseinbehalt
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("InputField", {
                    attrs: {
                      id: "versicherungseinbehalt",
                      type: "currency",
                      precision: 2,
                      validateUntouched: _vm.isManualVersicherungseinbehalt,
                      disabled: !_vm.form.editable
                    },
                    model: {
                      value: _vm.form.versicherungseinbehalt,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "versicherungseinbehalt", $$v)
                      },
                      expression: "form.versicherungseinbehalt"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputCheckBoxItem", {
                attrs: {
                  label: "Overhead Provision",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.istOverhead,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "istOverhead", $$v)
                  },
                  expression: "form.istOverhead"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("ComboBox", {
                attrs: {
                  id: "status",
                  label: "Status",
                  values: _vm.buchungFieldsDefinition.status,
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.status,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: {
                  id: "Wechselkurs",
                  label: "Wechselkurs",
                  type: "currency",
                  precision: 4,
                  validateUntouched: "",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.wechselkurs,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "wechselkurs", $$v)
                  },
                  expression: "form.wechselkurs"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: {
                  id: "bemerkung",
                  label: "Bemerkung",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.bemerkung,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bemerkung", $$v)
                  },
                  expression: "form.bemerkung"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: {
                  id: "externeBuchungsnr",
                  label: "Externe Buchungsnr",
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.externeBuchungsnr,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "externeBuchungsnr", $$v)
                  },
                  expression: "form.externeBuchungsnr"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("ComboBox", {
                attrs: {
                  id: "mwstSatz",
                  label: "MwSt Satz",
                  values: _vm.buchungFieldsDefinition.mwstsatz,
                  disabled: !_vm.form.editable
                },
                model: {
                  value: _vm.form.mwstSatz,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "mwstSatz", $$v)
                  },
                  expression: "form.mwstSatz"
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }