import DATENSCHUTZKUNDENAENDERUNGEN_TYPES from './types';
import { getInitialState } from './index'

export default {
  [DATENSCHUTZKUNDENAENDERUNGEN_TYPES.MUTATIONS.GET_MAIN_LISTING_SUCCESS](state, payload) {
    state.mainListing = payload?.listing;
    state.textModus = payload?.textModus;
  }, 
  [DATENSCHUTZKUNDENAENDERUNGEN_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [DATENSCHUTZKUNDENAENDERUNGEN_TYPES.MUTATIONS.GET_DETAILS_LISTING_SUCCESS](state, payload) {
    state.detailsListing = payload;
  },
  
}