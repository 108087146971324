import EINGEGANGENE_DOKUMENTE_TYPES from './types';



export default {
  [EINGEGANGENE_DOKUMENTE_TYPES.GETTERS.EINGEGANGENE_DOKUMENTE](state) {
    return state.eingegangeneDokumente;
  },
  [EINGEGANGENE_DOKUMENTE_TYPES.GETTERS.EINGEGANGENES_DOKUMENT_RESULT](state) {
    return state.eingegangenesDokumentResult;
  },
  [EINGEGANGENE_DOKUMENTE_TYPES.GETTERS.SIGNATURES](state) {
    return state.signatures;
  },
}