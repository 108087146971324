<template>
<div class="day-calendar__container">
  <div v-if="compgetWeekCalendarDataReady">
    <div class="daycalendarapp daycontainer">
      <div v-if="dayElementsReady" class="d-flex justify-content-between">
        <div class="w-100">
          <div class="text-centered mb-2" :class="{ 'text-bold': isCurrentLine, }">{{ header.label }}</div>

          <div class="event-column d-block d-md-none">
            <div v-for="(week, i) in weeks" :key="'w2'+i">
              <div class="d-flex justify-content-between">
                <div
                  :class="{'week-day': true, 'week-day-active': isActiveDay(day.day), 'week-day-current': isToday(day)}"
                  v-for="(day, di) in week"
                  :key="i+'d2'+di">
                  <div @click="moveSelectedDay(day.day)"><span :class="{'not-in-month': !day.inMonth}">{{day.label}}</span></div>
                </div>
              </div>
            </div>
          </div>

          <div class="daygrid wholeday-appointments">
            <div class="d-time-column d-none d-md-block clearfix">
              <div class="d-wholedaytitle">Ganztägig</div>
            </div>
            <CalendarWeekDayColumn
              :isAllDay="true"
              @click.native="setEvents"
              class="daycolumn"
              :istoday="getDayElements.day.isToday"
              calledfrom="day"
              :inmonth="getDayElements.day.inMonth"
              :dindex="getDayElements.dayIndex"
              :daylabel="getDayElements.day.label"
              :hours="getShownHours"
              :date="getDayElements.day.day"
              @editEvent="editEvent"
            />
          </div>

          <div ref="dayGridHoursEl" data-scroll-container class="daygrid daygrid-hours" :style="{maxHeight: `${dayGridHoursHeight}px`}">
            <div class="redline" v-if="isCurrentLine" :style="currentLineStyle" />
            <div class="d-time-column d-none d-md-block clearfix">
              <div
                :style="setStyle"
                class="wholehour"
                v-for="wholehour in getShownHours"
                :key="wholehour"
              >{{wholehour}}</div>
            </div>
            <CalendarWeekDayColumn
              :isAllDay="false"
              @click.native="setEvents"
              class="daycolumn"
              :istoday="getDayElements.day.isToday"
              calledfrom="day"
              :inmonth="getDayElements.day.inMonth"
              :dindex="getDayElements.dayIndex"
              :daylabel="getDayElements.day.label"
              :hours="getShownHours"
              :date="getDayElements.day.day"
              @editEvent="editEvent"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CalendarGhostLoading v-else type="calendar" class="d-lg-none" />
  <CalendarGhostLoading v-if="!compgetWeekCalendarDataReady" class="d-lg-none" type="day" />

  <CalendarGhostLoading v-if="!compgetWeekCalendarDataReady" class="d-none d-md-block" type="day" :style="{maxHeight: `${containerHeight}px`, overflow: 'hidden',}" />
  
  <div v-if="compgetWeekCalendarDataReady"  ref="dayGridSmEl" class="day-grid-sm d-lg-none">
    <div class="daycalendarapp daycontainer">
      <div v-if="dayElementsReady" class="d-flex justify-content-between">
        <div class="w-100">
          <div>
            <div 
              v-for="(ev, i) in allDayEvents" 
              :key="'all'+i"
              :class="['event-mob', 'type-' + ev.type]" 
              :style="{ 'border-color': ev.color, }"
              @click="editEventMobile(ev)"
            >
              <div class="d-flex justify-content-between">
                <div><ph-gift :size="16" v-if="ev.type == 'birthday'"/>{{ev.label}}</div>
                <div class="time">Ganztägig</div>
              </div>
            </div>
            <div 
              v-for="(ev, i) in timedEvents" 
              :key="'time'+i"
              :class="['event-mob', 'type-' + ev.type]" 
              :style="{ 'border-color': ev.color, }"
              @click="editEventMobile(ev)"
            >
              <div class="d-flex justify-content-between">
                <div><b>{{ev.label}}</b></div>
                <div class="time">{{ev.startTime}}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div>{{ev.bereichDescription}}</div>
                <div class="time">{{ev.endTime}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import CalendarWeekDayColumn from "@/components/calendar/CalendarWeekDayColumn.vue";
import CalendarMixins from "@/components/calendar/CalendarMixins";
import { mapGetters } from "vuex";
import CALENDAR_TYPES from "@/store/calendar/types";
import CUSTOMER_FILTERS_TYPES from "@/store/customerFilters/types";
import dayjs from "dayjs";
import { PhGift } from 'phosphor-vue';
import CalendarGhostLoading from '@/components/calendar/CalendarGhostLoading.vue';

const MIN_CONTAINER_HEIGHT = 160;
const BOTTOM_OFFSET_SIZE = 28;
const HOUR_CELL_CLASS = '.hour-cell';
const DEFAULT_HOUR_CELL_HEIGHT = 64;
const BORDER_TOP_SIZE = 1;

export default {
  props: {
    next: {
        type: Boolean,
        default: false,
    },
    prev: {
        type: Boolean,
        default: false,
    },
    current: {
        type: Boolean,
        default: false,
    },
    doRenew: {
        type: Boolean,
        default: false,
    },
    searchTerm: {
      type: String,
      default: ''
    }
  },
  components: {
    CalendarWeekDayColumn,
    PhGift,
    CalendarGhostLoading,
  },
  mixins: [CalendarMixins],
  data() {
    return {
      thisday: {},
      weeks: [],
      selectedEvent: '',
      containerHeight: null,
      dayGridHoursHeight: null,
    };
  },
  computed: {
    ...mapGetters({
      getDayElements: CALENDAR_TYPES.GETTERS.GET_DAY_ELEMENTS,
      getSelectedEvent: CALENDAR_TYPES.GETTERS.GET_SELECTED_APPOINTMENT_DATA,
      getClickedOnEvent: CALENDAR_TYPES.GETTERS.GET_CLICKED_ON_EVENT,
      compgetWeekCalendarDataReady:
        CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA_READY,
      getEmptyAppointment: CALENDAR_TYPES.GETTERS.GET_EMPTY_APPOINTMENT,
      inDayCompGetWeekCalendarData:
        CALENDAR_TYPES.GETTERS.GET_WEEK_CALENDAR_DATA,
      calendarSetup:
        CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_SETUP,
    }),
    header() {
      return {
        label: dayjs(this.thisday).format("dddd, DD.MM.YYYY"),
        labelShortening: dayjs(this.thisday).format("ddd, DD.MM.YYYY"),
      };
    },
    allDayEvents() {
      if (!this.inDayCompGetWeekCalendarData || !this.inDayCompGetWeekCalendarData[this.getDayElements.dayIndex]) {
        return [];
      }
      return this.inDayCompGetWeekCalendarData[this.getDayElements.dayIndex].allDayEvents.map(ev => ({
        ...ev,
        label: ev.label || 'Termin'
      }));
    },
    timedEvents() {
      if (!this.inDayCompGetWeekCalendarData || !this.inDayCompGetWeekCalendarData[this.getDayElements.dayIndex]) {
        return [];
      }
      return this.inDayCompGetWeekCalendarData[this.getDayElements.dayIndex].timedEvents.map(ev => ({
        ...ev,
        label: ev.label || 'Termin'
      }));
    },
    dayElementsReady() {
      return Object.keys(this.getDayElements).length !== 0;
    },
    setStyle() {
      return "height:4em";
    },
    isCurrentLine() {
      const today = dayjs();
      return this.thisday && this.thisday.format && today.format("DD.MM.YYYY") === this.thisday.format("DD.MM.YYYY");
    },
    currentLineStyle() {
      if(!this.compgetWeekCalendarDataReady) return {};

      const today = dayjs();
      const oneHourEl = document.querySelector(HOUR_CELL_CLASS);
      const hourHeight = oneHourEl?.offsetHeight || DEFAULT_HOUR_CELL_HEIGHT;
      return {
        top: `${+(hourHeight * +today.format('HH')) + (hourHeight/60 * today.format('mm'))}px`,
      };
    },
  },
  watch: {
    next(v) {
      if (v) {
        this.moveNextDay();
      }
    },
    prev(v) {
      if (v) {
        this.movePreviousDay();
      }
    },
    current(v) {
      if (v) {
        this.moveThisDay();
        this.$emit('setNextPrev', true);
      }
    },
    doRenew(v) {
      if (v) {
        this.renewView();
        this.$emit('setNextPrev', true);
      }
    },
    calendarSetup(val) {
      setTimeout(() => {
        this.calculateSizes();
        this.scrollToConfiguredTime();
      });
    },
    compgetWeekCalendarDataReady(v) {
      setTimeout(() => {
        this.calculateSizes();
        this.scrollToConfiguredTime();
      });
    },
    getDayElements(val) {
      const theDate = this.getDayElements.day.day && this.getDayElements.day.day.isValid() && this.getDayElements.day.day
        || dayjs(this.date);
      this.thisday = theDate;
      this.emitTitle();
    },
    thisday() {
      this.$emit('dateChanged', this.thisday?.toDate());

      this.setMonthShownDays(this.thisday);
    },
  },
  methods: {
    emitTitle() {
      this.$emit('title', `${this.header.label}`);
      this.$emit('titleShortening', `${this.header.labelShortening}`);
    },
    calculateSizes() {
      const rootEl = this.$el;
      const { dayGridHoursEl, } = this.$refs;
      if(rootEl) {
        const containerHeightCalc = window.innerHeight - rootEl.offsetTop - BOTTOM_OFFSET_SIZE;
        this.containerHeight = containerHeightCalc >= MIN_CONTAINER_HEIGHT ? containerHeightCalc : MIN_CONTAINER_HEIGHT;
      }
      if (dayGridHoursEl) {
        const dayGridHoursHeightCalc = window.innerHeight - dayGridHoursEl.offsetTop - BOTTOM_OFFSET_SIZE || 0;
        this.dayGridHoursHeight = dayGridHoursHeightCalc >= MIN_CONTAINER_HEIGHT ? dayGridHoursHeightCalc : MIN_CONTAINER_HEIGHT;
      }
    },
    scrollToConfiguredTime() {
      const { calendarSetup, } = this;
      if(!calendarSetup) return;

      const { dayGridHoursEl, } = this.$refs;
      if (dayGridHoursEl && this.dayGridHoursHeight) {
        const configuredHour = calendarSetup.beginTime?.split(':')?.[0];
        const today = configuredHour ? dayjs().hour(configuredHour) : dayjs();
        const currentHourEl = document.querySelector(`${HOUR_CELL_CLASS}[data-hour="${today.format('HH')}:00"]`);
        dayGridHoursEl.scrollTop = currentHourEl?.offsetTop - BORDER_TOP_SIZE;
      }
    },
    editEventMobile(ev) {
      if (ev.type === "appoitment") {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_CLICKED_ON_EVENT, true);
        this.$store.dispatch(
          CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT,
          ev.id
        );
        this.editEvent(ev);
      }
    },
    editEvent(e) {
      this.$emit('editEvent', e);
      this.selectedEvent = e;
    },
    closeModal() {
      this.selectedEvent = '';
    },
    isToday(day) {
      return day.isToday;
    },
    isActiveDay(day) {
      return day.date() === this.thisday.date() && day.month() === this.thisday.month();
    },
    isCurrentDay(day) {
      return day.date() === dayjs().date();
    },
    setMonthShownDays(thisday) {
      let alldays = [];
      let weeks = [];
      var last = dayjs(thisday).daysInMonth();
      let firstday = dayjs(thisday)
        .date(1)
        .weekday(0);
      let lastday = dayjs(thisday)
        .date(last)
        .weekday(6);
      for (let dd = 0; dd < 44; dd++) {
        let nextday = firstday.add(dd, "day");
        if (nextday.isSameOrBefore(lastday)) {
          alldays.push({
            label: nextday.format("D"),
            day: nextday,
            inMonth: nextday.month() === dayjs(thisday).month(),
            isToday: dayjs().isSame(nextday, "date"),
            isSelected: false
          });
        }
      }
      for (let i = 0; i < alldays.length; i += 7) {
        let tempweek = alldays.slice(i, i + 7);
        weeks.push(tempweek);
      }
      this.weeks = weeks;
    },
    shallowCopy(src) {
      return Object.assign({}, src);
    },
    createAppointment() {
      this.$store.commit(
        CUSTOMER_FILTERS_TYPES.MUTATIONS.RENEW_SELECTED_CUSTOMERS
      );
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA,
        {
          appointment: {
            appointment: this.shallowCopy(this.getEmptyAppointment)
          },
          day: this.thisday
        }
      );
    },
    editAppointment() {},

    deleteAppointment() {
      if (this.getSelectedEvent.updateable) {
        if (confirm("Soll dieser Termin gelöscht werden?")) {
          this.$store
            .dispatch(CALENDAR_TYPES.ACTIONS.DELETE_APPOINTMENT)
            .then(isDeleted => {
              this.getWeekCalendarData(this.thisday, this.searchTerm);
            });
          this.createAppointment();
        }
        this.renewView();
      }
    },

    setEvents() {
      if (this.getClickedOnEvent) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_CLICKED_ON_EVENT,
          false
        );
      }
    },
    makeDayElements(thisday) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_DAY_ELEMENTS, {
        day: {
          day: dayjs(thisday),
          isSelected: true,
          inMonth: dayjs(thisday).month() === dayjs().month(),
          isToday: dayjs(thisday).date() === dayjs().date(),
          label: dayjs(thisday).format("D.MM")
        },
        dayIndex: dayjs(thisday).weekday()
      });
    },
    moveThisDay() {
      this.thisday = dayjs();
      this.getWeekCalendarData(this.thisday, this.searchTerm);
      this.makeDayElements(this.thisday);
      this.createAppointment();
      this.$emit('setNextPrev', true);
    },
    moveSelectedDay(day) {
      this.thisday = day;
      this.getWeekCalendarData(this.thisday, this.searchTerm);
      this.makeDayElements(this.thisday);
      this.createAppointment();
      this.emitTitle();
    },
    movePreviousDay() {
      this.thisday = dayjs(this.thisday).subtract(1, "day");
      this.getWeekCalendarData(this.thisday, this.searchTerm);
      this.makeDayElements(this.thisday);
      this.createAppointment();
      this.$emit('setNextPrev', true);
    },
    movePreviousWeek() {
      this.thisday = dayjs(this.thisday).subtract(1, "week");
      this.getWeekCalendarData(this.thisday, this.searchTerm);
      this.makeDayElements(this.thisday);
      this.createAppointment();
    },
    moveNextDay() {
      this.thisday = dayjs(this.thisday).add(1, "day");
      this.getWeekCalendarData(this.thisday, this.searchTerm);
      this.makeDayElements(this.thisday);
      this.createAppointment();
      this.$emit('setNextPrev', true);
    },
    moveNextWeek() {
      this.thisday = dayjs(this.thisday).add(1, "week");
      this.getWeekCalendarData(this.thisday, this.searchTerm);
      this.makeDayElements(this.thisday);
      this.createAppointment();
    },
    renewView() {
      setTimeout(() => {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA);
        this.getWeekCalendarData(this.thisday, this.searchTerm);
      }, 300);
    }
  },

  beforeMount() {
    this.$store.commit(
      CUSTOMER_FILTERS_TYPES.MUTATIONS.RENEW_SELECTED_CUSTOMERS
    );
    this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_WEEK_CALENDAR_DATA, []);
    this.createAppointment();
  },

  mounted() {
    this.calculateSizes();
    window.addEventListener('resize', this.calculateSizes);

    const thisday = dayjs(this.date);

    this.setMonthShownDays(thisday);
    if (Object.keys(this.getDayElements).length === 0) {
      this.thisday = thisday;
      this.makeDayElements(thisday);
    } else {
      const theDate = this.getDayElements.day.day && this.getDayElements.day.day.isValid() && this.getDayElements.day.day
        || thisday;
      this.thisday = theDate;
      if (this.getDayElements.appointment) {
        this.$emit('editEvent', this.getDayElements.appointment);
        this.$store.dispatch(
          CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT,
          this.getDayElements.appointment
        );
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_DAY_ELEMENTS, {
          ...this.getDayElements,
          appointment: null,
        });
      }
    }

    this.getWeekCalendarData(this.thisday, this.searchTerm);
    this.makeShownHours(Number(0), Number(23));

    this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_APPOINTMENT_COMBOS);
    this.emitTitle();
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateSizes);
  },
};
</script>

<style  scoped>
* {
  box-sizing: border-box;
}

.daycalendarapp {
  --weekdayBorderStyle: solid;
  --dayBorder: solid 1px var(--color-primary);
  --borderColor: #cccccc;
}

.d-time-column {
  min-width: 74px;
  position: relative;
}
.redline {
  width: 100%;
  background: var(--color-danger);
  height: 1px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.wholehour {
  text-align: right;
  padding: 0 0.5rem;
}

hr {
  border-width: 1px;
  color: var(--color-text);
}

.d-wholedaytitle {
  min-height: 4em;
  float: right;
  padding: 0 0.5rem;
}

.d-wholeday {
  height: 8em;
  border-style: none none dotted none;
  border-width: thin;
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.daygrid {
  display: grid;
  grid-template-columns: 0.5fr 9.5fr;
  position: relative;
}

.daycolumn{
  min-width: 10%;
  max-width: 100%;
  border-top: 1px solid var(--borderColor);
}

.edit-icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 2em;
}

.edit-icon {
  justify-self: center;
}

.icon_available {
  color: var(--color-active);
}
.icon_available :hover {
  color: var(--color-link);
}

.icon_unavailable {
  color: var(--color-text);
}

.hour-cell {
  display: flex;
  flex: auto;
  border-bottom: 1px solid var(--borderColor);
  border-bottom: 1px solid #cccccc;
}
.dayweekday {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-top-nav-text);
  border-color: var(--borderColor);
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
}

.week-day {
  width: 30px;
  padding: 2px;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
}

.week-day-active {
  color: var(--color-primary-text);
  background: var(--color-primary);
}
.week-day-current {
  color: var(--color-primary-text);
  background: var(--color-danger);
}
.not-in-month {
  color: #cccccc;
}

.event-column {
  margin-bottom: 0.5rem;
  padding: 0 1rem 0.5rem 1rem;
}

.event-mob {
  border-left: 0.25rem solid var(--color-success);
  padding: 0.25rem;
  margin-bottom: 0.5rem;
}
.event-mob div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.event-mob div.time {
  text-align: right;
}
.type-nationalHoliday {
  border-color: var(--color-success);
}
.type-appoitment,
.type-appointment {
  border-color:var(--color-primary);
}
.type-birthday {
  border-color: var(--color-danger);
}
.d-lg-none {
  display: none;
}
.w-100 {
  width: 100%;
}
#event-column-id {
  min-width: 350px;
}
.appointment-create {
  margin-top: 1rem;
}
.daygrid-hours {
  overflow-y: auto;
  min-height: 100px;
}
@media only screen and (max-width: 767px) {
  .d-lg-none {
    display: block;
  }
  .daygrid {
    display: none;
    grid-template-columns: 1fr 18fr;
  }
}

.ghost-loading-calendar--inline {
  display: flex;
  margin: 0 -4px 8px;
}
.ghost-loading-calendar--inline:last-child {
  margin-bottom: 0;
}
.ghost-loading-calendar--inline > div {
  margin: 0 4px;
  flex: 1 auto;
}
</style>