<template>
  <div class="box__container">
    <div class="box__title">Finanzielle Verhältnisse</div>

    <div class="box__title">1. Grundlage und Höhe des derzeit regelmäßigen Einkommens</div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Einnahmen aus unselbständiger Arbeit (netto pro Monat / Gehaltsabrechnung)" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.salary" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Einnahmen aus selbständiger Arbeit (laut Einkommensteuerbescheid / netto pro Monat)" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.freelancerRevenue" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Einnahmen aus Kapitalvermögen (z.B. Zinsen, Dividenden, Ausschüttungen pro Monat)" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.capitalRevenue" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Sonstiges (z.B. Unterhaltsbeträge, Mieteinnahmen usw. netto pro Monat)" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.otherRevenue" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>

    <div class="box__title">2. aktuelle liquide Vermögenswerte</div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Bargeld" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.cash" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="täglich verfügbare Bankeinlagen (z.B. Tagesgeld, Kontokorrent)" 
          labelClass="font-bold" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.freeBankDeposit" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>

    <div class="box__title">3. Immobilienvermögen</div>
    <FinanceImmobilienvermoegen :isRefusedPage="!isEditable" :finance="finances" @updateImmo="immobilienChanged($event)"/>

    <div class="box__title">4. Kapitalanlage / sonstige Vermögenswerte</div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="aktueller Bestand gem. Depotpositionen" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.depotbestand" 
          :disabled="true" />
      </div>
    </div>
    <div v-if="showBondDepotRelevant">
      <div class="row">
        <div class="col-12">
          <InputField
            label="beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)" 
            labelClass="font-bold" 
            :isComponentHalfSize="true"
            type="currency" 
            v-model="finances.bondDepot" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputField
            label="nicht beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)" 
            :isComponentHalfSize="true"
            type="currency" 
            v-model="finances.bondDepotNotRelevant" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12">
          <InputField
            label="Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)" 
            labelClass="font-bold" 
            :isComponentHalfSize="true"
            type="currency" 
            v-model="finances.bondDepot" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="gebundene Spareinlagen (z.B. Sparbuch, Festgeld)" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.boundBankDeposit" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Alternative Investments" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.alternativeInvestment" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Kunstgegenstände, Antiquitäten" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.art" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Bausparverträge (aktuelle Werte)" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.buildingSaving" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Lebensversicherungen, Kapitalversicherungen (aktuelle Rückkaufswerte)" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.insurance" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Sonstiges" 
          labelClass="font-bold" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.otherInvestment" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>

    <div class="box__title">5. Verbindlichkeiten / Schulden</div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="langfristige Kredite / Darlehen" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.longTermCredit" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="kurzfristige Kredite (Kontokorrent, Zwischenfinanzierungen)" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.shortTermCredit" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField
          label="Sonstige Verpflichtungen zu Gunsten Dritter (Verpfändungen, Bürgschaften)" 
          :isComponentHalfSize="true"
          type="currency"
          v-model="finances.otherCredit" 
          :disabled="!isEditable"
          @change="dataChanged()" />
      </div>
    </div>

    <div class="box__title">6. regelmäßige finanzielle Verpflichtungen (pro Monat)</div>
    <div class="forms__input--half-size">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <InputField
            label="Miete" 
            type="currency"
            v-model="finances.rent" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <InputField
            label="Leasing" 
            type="currency"
            v-model="finances.leasing" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
      </div>
    </div>
    <div class="forms__input--half-size">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <InputField
            label="Kreditrückzahlung" 
            type="currency"
            v-model="finances.creditPayment" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <InputField
            label="Haushalt" 
            type="currency"
            v-model="finances.household" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
      </div>
    </div>
    <div class="forms__input--half-size">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <InputField
            label="Unterhalt" 
            type="currency"
            v-model="finances.maintainance" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <InputField
            label="Versicherungsbeiträge" 
            type="currency"
            v-model="finances.insurancePayment" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
      </div>
    </div>
    <div class="forms__input--half-size">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <InputField
            label="Sonstiges" 
            type="currency"
            v-model="finances.otherPayment" 
            :disabled="!isEditable"
            @change="dataChanged()" />
        </div>
      </div>
    </div>

    <div v-if="!isFA">
      <div class="box__title">Zulagenberechtigung</div>
      <div class="row">
        <div class="col-12">
          <InputToggleSwitch
            label="Arbeitnehmersparzulage" 
            :inLineLabel="true"
            v-model="finances.employeeSavingAllowance" 
            :disabled="!isEditable"
            @input="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputToggleSwitch
            label="Riester-Rente" 
            :inLineLabel="true"
            v-model="finances.riesterPension" 
            :disabled="!isEditable"
            @input="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputToggleSwitch
            label="Rürup-Rente" 
            :inLineLabel="true"
            v-model="finances.ruerupPension" 
            :disabled="!isEditable"
            @input="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputToggleSwitch
            label="Wohnungsbauprämie" 
            :inLineLabel="true"
            v-model="finances.buildingSubsidy" 
            :disabled="!isEditable"
            @input="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputToggleSwitch
            label="bAV" 
            :inLineLabel="true"
            v-model="finances.fedInsuranceComm" 
            :disabled="!isEditable"
            @input="dataChanged()" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CORE_TYPES from '@/store/core/types.js';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import FinanceImmobilienvermoegen from '@/components/anlegerprofil/FinanceImmobilienvermoegen.vue';

import persoenlicheDatenMixin from './persoenliche-daten-mixin.js';

const PRECISION = 2;

const HOLLY_OWNED = true;
const CO_OWNER = false;

const PROPRIETARY_OPTIONS = [
  { value: HOLLY_OWNED, label: 'Alleineigentümer' },
  { value: CO_OWNER, label: 'Miteigentümer' },
];

export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      finances: {},
      precision: PRECISION,
      proprietary: [...PROPRIETARY_OPTIONS],
    };
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    isFA() {
      return this.loginData?.rights?.isFA;
    },
    showBondDepotRelevant() {
      return this.finances?.showBondDepotRelevant;
    },
  },
  watch: {
    customerData: {
      handler() {
        this.copyFinancesData();
      },
      immediate: true,
    },
    customerDataEdited(data) {
      if(!Object.keys(data).length) {
        this.discardChanges();
      }
    },
  },
  methods: {
    copyFinancesData() {
      this.finances = Object.assign({}, this.customerData?.finances);
    },
    dataChanged() {
      const finances = this.finances;

      // Currency
      finances.salary = finances?.salary === '' ? null : finances.salary;
      finances.freelancerRevenue = finances?.freelancerRevenue === '' ? null : finances.freelancerRevenue;
      finances.capitalRevenue = finances?.capitalRevenue === '' ? null : finances.capitalRevenue;
      finances.otherRevenue = finances?.otherRevenue === '' ? null : finances.otherRevenue;
      finances.cash = finances?.cash === '' ? null : finances.cash;
      finances.freeBankDeposit = finances?.freeBankDeposit === '' ? null : finances.freeBankDeposit;
      finances.boundBankDeposit = finances?.boundBankDeposit === '' ? null : finances.boundBankDeposit;
      finances.home = finances?.home === '' ? null : finances.home;
      finances.appartments = finances?.appartments === '' ? null : finances.appartments;
      finances.otherProperty = finances?.otherProperty === '' ? null : finances.otherProperty;
      finances.bondDepot = finances?.bondDepot === '' ? null : finances.bondDepot;
      finances.bondDepotNotRelevant = finances?.bondDepotNotRelevant === '' ? null : finances.bondDepotNotRelevant;
      finances.alternativeInvestment = finances?.alternativeInvestment === '' ? null : finances.alternativeInvestment;
      finances.art = finances?.art === '' ? null : finances.art;
      finances.buildingSaving = finances?.buildingSaving === '' ? null : finances.buildingSaving;
      finances.insurance = finances?.insurance === '' ? null : finances.insurance;
      finances.otherInvestment = finances?.otherInvestment === '' ? null : finances.otherInvestment;
      finances.longTermCredit = finances?.longTermCredit === '' ? null : finances.longTermCredit;
      finances.shortTermCredit = finances?.shortTermCredit === '' ? null : finances.shortTermCredit;
      finances.otherCredit = finances?.otherCredit === '' ? null : finances.otherCredit;
      finances.rent = finances?.rent === '' ? null : finances.rent;
      finances.leasing = finances?.leasing === '' ? null : finances.leasing;
      finances.creditPayment = finances?.creditPayment === '' ? null : finances.creditPayment;
      finances.household = finances?.household === '' ? null : finances.household;
      finances.maintainance = finances?.maintainance === '' ? null : finances.maintainance;
      finances.insurancePayment = finances?.insurancePayment === '' ? null : finances.insurancePayment;
      finances.otherPayment = finances?.otherPayment === '' ? null : finances.otherPayment;

      // Percent
      finances.homePercentage = finances?.homePercentage === '' ? null : finances.homePercentage;
      finances.appartmentsPercentage = finances?.appartmentsPercentage === '' ? null : finances.appartmentsPercentage;
      finances.otherPropertyPercentage = finances?.otherPropertyPercentage === '' ? null : finances.otherPropertyPercentage;

      const payload = {
        personId: this.personId,
        finances,
      };

      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload);
    },
    discardChanges() {
      this.copyFinancesData();
    },
    immobilienChanged(event) {
      if (event?.compId) {
        this.finances[event.compId] = event.value;
        this.dataChanged();
      }
    }
  },
  components: {
    InputRadioBoxGroup,
    InputToggleSwitch,
    InputField,
    FinanceImmobilienvermoegen,
  },
}
</script>
