<template>
  <BaseFilter
    v-if="hasSearchMetadata"
    :filterId="filterId"
    :showAlwaysPrimaryselection="false"
    :metadata="searchMetadata"
    :defaultOptions="defaultOptionsFilter"
    immidiateSearch
    :showSaveButton="!!filterId"
    @onFilter="search"
  />
</template>

<script>
import dayjs from 'dayjs';

import { mapGetters, } from 'vuex';
import MULTIBANKING_TYPES from '@/store/multiBanking/types'

import BaseFilter from '@/components/core/BaseFilter.vue';
import { getAccountTitle, } from '@/views/multiBanking/multiBanking-utils';

const EINNAHMEN_TYPE = 'Einnahmen';
const AUSGABEN_TYPE = 'Ausgaben';

const DATE_FORMAT = 'DD.MM.YYYY';
const START_OF_YEAR = dayjs().startOf('year').format(DATE_FORMAT);
const CURRENT_DATE = dayjs().format(DATE_FORMAT);

export default {
  name: 'MultiBankingFilter',
  props: {
    filterId: {
      type: String,
    },
    accountId: {
      type: String,
    },
    showType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categoryTabOptions: [EINNAHMEN_TYPE, AUSGABEN_TYPE],
    };
  },
  computed: {
    ...mapGetters({
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      filters: MULTIBANKING_TYPES.GETTERS.FILTERS,
    }),
    nonDepotAccounts() {
      if (!this.accounts) return [];
      return this.accounts.filter(acc => acc.accountType != 'DEPOT' && !acc.authNeeded);
    },
    hasSearchMetadata() {
      return this.searchMetadata.some(item => item.menuItems.length > 0);
    },
    searchMetadata() {
      const allgemein = {
        type: 'group',
        key: 'allgemein',
        label: 'Allgemein',
        menuItems: [],
      };

      allgemein.menuItems.push({
        type: 'combobox',
        label: 'Bankkonto',
        key: 'accountId',
        comboOptions: [
          { value: "", label: 'Alle Bankkonten', },
          ...this.nonDepotAccounts?.map(account => ({ value: account.externalId, label: getAccountTitle(account), })) || [],
        ],
      });

      if(this.showType) {
        allgemein.menuItems.push({
          type: 'combobox',
          label: 'Umsatzart',
          key: 'type',
          comboOptions: [ ...this.categoryTabOptions.map(category => ({ value: category, label: category, })), ],
        });
      }

      allgemein.menuItems.push({
        type: 'dateRange',
        label: 'Zeitraum',
        key: 'period',
      });

      return [
        allgemein,
      ];
    },
    defaultOptionsFilter() {
      const defaultOptions = {};

      defaultOptions.accountId = this.accountId || this.filters?.accountId || '';

      if(this.showType) {
        defaultOptions.type = { value: this.filters?.type || AUSGABEN_TYPE, };
      }

      const { dateFrom, dateTo, } = this.filters || {};
      defaultOptions.period = {
        value: [
          { key: 'min', value: dateFrom && dayjs(dateFrom).format(DATE_FORMAT) || START_OF_YEAR, },
          { key: 'max', value: dateTo && dayjs(dateTo).format(DATE_FORMAT) || CURRENT_DATE, },
        ],
      };

      return { ...defaultOptions, };
    },
  },
  methods: {
    search(filters) {
      const payload = filters?.reduce((acc, filter) => {
        if(filter.key === 'period') {
          const dateFrom = filter.value?.[0]?.value;
          const dateTo = filter.value?.[1]?.value;

          acc['selectedPeriod'] = 'custom';
          acc['dateFrom'] = typeof dateFrom === 'string' ? dayjs.utc(dateFrom, DATE_FORMAT).toDate() : dayjs(dateFrom).toDate();
          acc['dateTo'] = typeof dateTo === 'string' ? dayjs.utc(dateTo, DATE_FORMAT).toDate() : dayjs(dateTo).toDate();
        } else {
          acc[filter.key] = filter.value;
        }

        return acc;
      }, {});

      this.$store.commit(MULTIBANKING_TYPES.MUTATIONS.UPDATE_FILTERS, payload)
    },
  },
  components: {
    BaseFilter,
  },
}
</script>
