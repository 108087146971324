var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container-wrap" }, [
    _c(
      "div",
      { staticClass: "box__container-rows" },
      [
        _vm.rows.length
          ? [
              !_vm.$isSmallScreen
                ? _c("OverviewTable", {
                    attrs: { records: _vm.rows },
                    on: {
                      title: _vm.openCustomerNewTab,
                      subject: _vm.clickBetreff
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "status",
                          fn: function(row) {
                            return [_c("span", [_vm._v(_vm._s(row.status))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3051435337
                    )
                  })
                : _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowId: "rowId",
                      mobileConfig: {
                        title: "title",
                        headers: ["subject", "date"]
                      }
                    },
                    on: { clickRow: _vm.clickBetreff }
                  })
            ]
          : _vm.loading
          ? _c("GhostLoading", {
              attrs: { type: "table", config: { table: { rows: 5 } } }
            })
          : _c("NoData", { attrs: { noIcon: _vm.$isSmallScreen } })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "bottom-row text-right mt-3" },
      [
        _c(
          "router-link",
          { attrs: { to: "/communication/postfach/" + _vm.routeStep } },
          [_vm._v(" weitere... ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }