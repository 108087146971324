var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loginName
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v(" Zugangsinformation ")
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "infoLabel" }, [
                  _vm._v("Login Name:")
                ]),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.changeInfo = "loginName"
                      }
                    }
                  },
                  [
                    _vm._v(_vm._s(_vm.loginName) + " "),
                    _c("PhPencil", { attrs: { size: 16 } })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "infoLabel" }, [
                  _vm._v("Telefonnummer:")
                ]),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.changeInfo = "phoneNumber"
                      }
                    }
                  },
                  [
                    _vm._v(_vm._s(_vm.phoneNumber) + " "),
                    _c("PhPencil", { attrs: { size: 16 } })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "infoLabel" }, [
                  _vm._v("E-Mail Adresse:")
                ]),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.changeInfo = "email"
                      }
                    }
                  },
                  [
                    _vm._v(_vm._s(_vm.email) + " "),
                    _c("PhPencil", { attrs: { size: 16 } })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "infoLabel" }),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.changeInfo = "password"
                      }
                    }
                  },
                  [
                    _vm._v("Passwort ändern "),
                    _c("PhPencil", { attrs: { size: 16 } })
                  ],
                  1
                )
              ]),
              _vm.changeInfo == "loginName"
                ? _c("ChangeLoginNameModal", {
                    on: {
                      close: function($event) {
                        _vm.changeInfo = null
                      }
                    }
                  })
                : _vm._e(),
              _vm.changeInfo == "phoneNumber"
                ? _c("ChangePhoneNumberModal", {
                    on: {
                      close: function($event) {
                        _vm.changeInfo = null
                      }
                    }
                  })
                : _vm._e(),
              _vm.changeInfo == "email"
                ? _c("ChangeEmailModal", {
                    on: {
                      close: function($event) {
                        _vm.changeInfo = null
                      }
                    }
                  })
                : _vm._e(),
              _vm.changeInfo == "password"
                ? _c("ChangePasswordModal", {
                    on: {
                      close: function($event) {
                        _vm.changeInfo = null
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          !_vm.activated
            ? _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Single-Sign-On Zugang aktivieren")
                ]),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.activateSsoAccount.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("InputField", {
                      attrs: { label: "E-Mail Code" },
                      model: {
                        value: _vm.emailToken,
                        callback: function($$v) {
                          _vm.emailToken = $$v
                        },
                        expression: "emailToken"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "SMS Code" },
                      model: {
                        value: _vm.smsToken,
                        callback: function($$v) {
                          _vm.smsToken = $$v
                        },
                        expression: "smsToken"
                      }
                    }),
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          disabled: _vm.tokenButtonsDisabled,
                          isSecondary: ""
                        },
                        on: { click: _vm.requestEmailCode }
                      },
                      [_vm._v("E-Mail Code erneut senden")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          disabled: _vm.tokenButtonsDisabled,
                          isSecondary: ""
                        },
                        on: { click: _vm.requestSMSCode }
                      },
                      [_vm._v("SMS Code erneut senden")]
                    ),
                    _c("br"),
                    _c("br"),
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          type: "submit",
                          disabled: !_vm.emailToken || !_vm.smsToken,
                          isPrimary: ""
                        }
                      },
                      [_vm._v("Aktivieren")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v(" Ihre Zugänge ")
              ]),
              _vm.fkAccounts && _vm.fkAccounts.length
                ? _c("Table", {
                    attrs: {
                      tableId: "fc5b97e6-e605-4281-9be0-25d5dc9c7dfe",
                      headers: _vm.fkHeaders,
                      rows: _vm.fkRows,
                      hidePagination: ""
                    },
                    on: {
                      "click-displayedName": function($event) {
                        return _vm.loginFk($event.id)
                      },
                      "action-DELETE": _vm.fkDelete
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "isDefault",
                          fn: function(row) {
                            return [
                              _c("input", {
                                attrs: { type: "radio" },
                                domProps: {
                                  checked:
                                    row.isDefault ||
                                    (_vm.fkAccounts &&
                                      _vm.fkAccounts.length === 1)
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.changeDefaultFkAccess(row)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1854332814
                    )
                  })
                : _c("div", [_vm._v(" Keine Zugänge. ")])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("An Sie gerichtete Einladungen")
              ]),
              _vm.ssoInvitations && _vm.ssoInvitations.length
                ? _c("Table", {
                    attrs: {
                      tableId: "fc5b97e6-e605-4281-9be0-25d5dc9c7df2",
                      headers: _vm.invitationHeaders,
                      rows: _vm.invitationRows,
                      hidePagination: ""
                    },
                    on: { "action-DELETE": _vm.deleteInvitation },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "accept",
                          fn: function(row) {
                            return [
                              _c(
                                "BaseButton",
                                {
                                  directives: [
                                    {
                                      name: "fc-tooltip",
                                      rawName: "v-fc-tooltip",
                                      value: "Einladung akzeptieren",
                                      expression: "'Einladung akzeptieren'"
                                    }
                                  ],
                                  attrs: { isLink: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.acceptInvitation(row)
                                    }
                                  }
                                },
                                [
                                  _c("PhCheck", {
                                    staticClass: "color-success mr-1",
                                    attrs: { size: 16 }
                                  }),
                                  _c("span", { staticClass: "color-success" }, [
                                    _vm._v("akzeptieren")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1013084766
                    )
                  })
                : _c("div", [_vm._v(" Keine Einladungen. ")]),
              _vm.ssoInvitation
                ? _c("SsoInvitationModal", {
                    attrs: { invitation: _vm.ssoInvitation },
                    on: {
                      close: function($event) {
                        _vm.ssoInvitation = null
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "errorModal",
              attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
              on: { onCancelButton: _vm.closeErrorModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return [
                        _c("PhInfo", { staticStyle: { color: "orange" } }),
                        _vm._v(" Fehler ")
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3456869615
              )
            },
            [_c("div", [_vm._v(" " + _vm._s(_vm.errorStr) + " ")])]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }