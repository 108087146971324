var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputCheckBoxItem", {
        staticClass: "option-menu-permission--toggle-switch",
        attrs: {
          label: "Zugriff",
          value: _vm.indeterminate ? false : _vm.isVisible,
          disabled: _vm.isDisabled,
          indeterminate: _vm.isVisible ? _vm.indeterminate : false
        },
        on: { input: _vm.onInput }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }