<template>
  <div class="box__container-wrap">
    <div class="box__container-rows">
        <Table v-if="rows.length"
            tableId="f0470771-ba6e-49a6-b5d9-7d60142b3cc4"
            :headers="headers"
            :rows="rows"
            rowId="kontonr"
            ignoreTablePageSize
            :rowsPerPage="5"
            :tableRowsPerPage="[]"
            :hidePagination="$isSmallScreen"
            :mobileConfig="mobileTableConfig"
        >
            <template #gebuehrProz="row">
                <template v-if="row.gebuehrProzMin != row.gebuehrProzMax">
                    <NumberCell :row="row" column="gebuehrProzMin" symbol="%" />
                    <span>-</span>
                </template>
                <NumberCell :row="row" column="gebuehrProzMax" symbol="%" />
            </template>
            <template #rabatt="row">
                <template v-if="row.rabattMin != row.rabattMax">
                    <NumberCell :row="row" column="rabattMin" symbol="%" />
                    <span>-</span>
                </template>
                <NumberCell :row="row" column="rabattMax" symbol="%" />
            </template>
        </Table>
        <NoData v-else noIcon />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import STECKBRIEF_TYPES from '../store/types';
import NumberCell from '@/components/table2/cellTypes/NumberCell.vue'
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn} from "@/components/table2/table_util.js";

export default {
  data() {
    return {
        headers: {
            lockedLeft: [
                TextColumn("kontonr", "Depotnr").makeAlwaysVisible(),
            ],
            center: [
                TextColumn("gesellschaft", "Lagerstelle"),
            ],
            lockedRight: [
                SlotColumn("gebuehrProz", "Servicegebühr").makeAlwaysVisible(),
                SlotColumn("rabatt", "Rabatt").makeAlwaysVisible(),
            ],
        },
        mobileTableConfig: {
         title: '',
         headers: ['kontonr', 'gesellschaft', 'gebuehrProz','rabatt'],
         disableClickRow: true,
        }
    };
  },
  computed: {
    ...mapGetters({
        info: STECKBRIEF_TYPES.GETTERS.STECKBRIEF_CUSTOMER_INFO,
    }),
    rows() {
        return (this.info?.steckBreifZusatz?.depotsDatenOutput || []).map(depotsDaten => {
          // important check if the value exists because zero is a valid number for this case and the table column uses a named slot.
          // As there is no column with same name of the slot, the column is not displayed on smallscreens
          depotsDaten.gebuehrProz = depotsDaten.gebuehrProzMin != null || depotsDaten.gebuehrProzMin != undefined || depotsDaten.gebuehrProzMax != null || depotsDaten.gebuehrProzMax != undefined;
          depotsDaten.rabatt = depotsDaten.rabattMin != null || depotsDaten.rabattMin != undefined || depotsDaten.rabattMax != null || depotsDaten.rabattMax != undefined;
          return depotsDaten;
        });
    },
  },
  components: {
    NumberCell,
    NoData,
    Table,
  },
}
</script>

<style src='../steckbrief.scss' lang='scss' scoped></style>

<style scoped>
.value-range {
  display: flex;
  justify-content: flex-end;
}
.depot-gesellschaft {
  white-space: normal;
}
</style>
