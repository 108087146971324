<template>
    <div><h5 class="my-4">Zusammenfassung</h5><div>
    <div class="row py-1 mx-2">
      <div class="col-12 col-xl-8">
        <label class="co ml-0">
          1. regelmäßiges Einkommen
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell">
        <CurrencyLabel :value="summeEinkommen"/>
      </div>
    </div>
    <div class="row py-1  mx-2">
      <div class="col-12 col-xl-8">
        <label class="co ml-0">
          2. aktuelle liquide Vermögenswerte
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell">
         <CurrencyLabel :value="summeVermoegen"/>
      </div>
    </div>
    <div class="row py-1  mx-2">
      <div class="col-12 col-xl-8">
        <label class="co ml-0">
          3. Immobilienvermögen
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell">
         <CurrencyLabel :value="sumImmobilien"/>
      </div>
    </div>
    <div class="row py-1  mx-2">
      <div class="col-12 col-xl-8">
        <label class="co ml-0" 
          appPopover="Kapitalanlage / sonstige Vermögenswerte">
          4. Kapitalanlage / sonstige Vermögenswerte
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell">
         <CurrencyLabel :value="summeAnlage"/>
      </div>
    </div>
    <div class="row py-1  mx-2">
      <div class="col-12 col-xl-8">
        <label class="co ml-0">
          5. Verbindlichkeiten / Schulden
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell">
         <CurrencyLabel :value="summeSchulden"/>
        </div>
    </div>
    <div class="row py-1  mx-2">
      <div class="col-12 col-xl-8">
        <label class="co ml-0">
          6. regelmäßige finanzielle Verpflichtungen
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell">
        <CurrencyLabel :value="summeAusgaben"/>
      </div>
    </div>
    <div class="row py-1  mx-2 font-bold">
      <div class="col-12 col-xl-8">
        <label class="col ml-0">
          Summe des monatlich für Anlagezwecke frei zur Verfügung stehenden Einkommens
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell">
         <CurrencyLabel :value="summeFreiMonatlich"/>
        </div>
    </div>
    <div class="row py-1  mx-2 font-bold">
      <div class="col-12 col-xl-8">
        <label class="col ml-0">
          Summe des für Anlagezwecke frei zur Verfügung stehenden Kapitals
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell  ">
        <CurrencyLabel :value="sumCapital"/>
      </div>
    </div>
  </div>
    </div>
</template>

<script>
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
  components: {
    CurrencyLabel
  },
  mixins: [anlegerprofilMixin],
}
</script>

<style scoped>
.footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    /* font-size: 1.25rem; */
    font-weight: bold;
  }
</style>