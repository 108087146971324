var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DownloadLink", {
    attrs: {
      asButton: !_vm.isShowAsLink,
      disabled: _vm.isDisabled,
      isPrimary: _vm.isPrimary,
      isSecondary: !_vm.isPrimary,
      title: _vm.label,
      filename: _vm.filename,
      href: _vm.href,
      downloadServicePath: _vm.downloadServicePath,
      queryParams: _vm.queryParams
    },
    on: {
      clicked: function($event) {
        return _vm.onClick()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }