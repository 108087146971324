import CORE_TYPES from '../../store/core/types';
import { v5 as uuidv5 } from 'uuid';

var InteractiveHelpCommonsMixin = {
    methods: {
        _resetTutorial() {
            this.highlightElementsWithTidAttribute(false);
            this.$store.dispatch(CORE_TYPES.ACTIONS.REMOVE_ALL_RECORDED_ITEMS, {});
            this.elementsIdArray.items.length = 0;
            this.elementsArray.length = 0;
            this.slideItems.length = 0;
            this.recordedItems.length = 0;
        },
        _generateTidFromString(whatString) {
            const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
            if ((typeof whatString === 'number')) {
                return uuidv5(whatString.toString(), MY_NAMESPACE);
            } else if ((whatString && whatString.length)) {
                return uuidv5(whatString, MY_NAMESPACE);
            }
            return '';
            
        },
        highlightElementsWithTidAttribute(highlight) {
            let elementsWithTidAttribute = document.querySelectorAll('[tid]:not([value=""]');
            elementsWithTidAttribute.forEach(e => {
               if (highlight) {
                    if (e.className && typeof e.className === 'string') {
                        if (e.className.indexOf(" recordable_items") == -1) {
                            e.className = e.className + " recordable_items";     
                        }
                    } else if(!e.className) {
                        e.className = " recordable_items";     
                    } else {
                        // SVG??
                        if (e.getAttribute("class").indexOf(" recordable_items") == -1) {
                            e.setAttribute("class",e.getAttribute("class")+ " recordable_items");
                        }
                    }
               } else {
                    if (e.className && typeof e.className === 'string') {
                       e.className = e.className.replaceAll(" recordable_items", "");     
                    } else if(!e.className) {
                        // do nothing 
                    } else {
                        // SVG??
                        if (e.getAttribute("class")) {
                            e.setAttribute("class",e.getAttribute("class").replaceAll(" recordable_items", ""));
                        }
                    }
               }
            });
        },
        _addTutorialSpinner(targetElement) {
            let spinnerAnimation = document.createElement('div');
            spinnerAnimation.className = '__interactive-help-spinner';
           
            targetElement.appendChild(spinnerAnimation);               
        },
        _removeTutorialSpinner(targetElement) {
            let spinnerAnimation = targetElement.querySelector('.__interactive-help-spinner');
            if (spinnerAnimation) {
                targetElement.removeChild(spinnerAnimation); 
            }
        }      
    }
}

export default InteractiveHelpCommonsMixin;