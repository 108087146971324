<template>
<div>
    <Table v-if="!loading && rows.length"
        tableId="df281da6-df02-43ec-a3f1-146f4f93993f"
        rowId="index"
        ignoreTablePageSize
        :headers="headers"
        :rows="rows"
        :rowsPerPage="rowsPerPage"
        :tableRowsPerPage="[]"
        :mobileConfig="mobileConfig"
        @clickRow="openDepotposition"
        @click-text="openDepotposition"
    />
    <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: rowsPerPage, }, }" />
    <NoData v-else noIcon />
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const LAST_DAYS = 7;
const ROWS_PER_PAGE = 5;

export default {
  data() {
    return {
        loading: false,
        rowsPerPage: ROWS_PER_PAGE,
        headers: {
          center: [
              DateColumn("date", "Datum"),
              TextColumn("text", "Beschreibung").makeLink().addCellProps({tooltip: row => row.isin}),
              TextColumn("comment", "Bemerkung"),
          ],
        },
      mobileConfig: {
        title: '',
        headers: ['date','text','comment'],
      }
    };
  },
  computed: {
    ...mapGetters({
        depotpositionsOutOfLimits: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_OUT_OF_LIMITS,
    }),
    rows() {
        return [...this.depotpositionsOutOfLimits].map((depot, index) => ({
            index,
            ...depot,
            text: this.getText(depot),
        }));
    },
  },
  methods: {
    async findDepotpositionsOutOfLimits() {
      try {
        this.loading = true;
        await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_OUT_OF_LIMITS, {
          lastDays: LAST_DAYS,
        });
      } finally {
        this.loading = false;
      }
    },
    getText(depot) {
      switch(depot.art) {
        case 'DEPOT':
          return`Depotlimit gerissen bei ${depot.firstName || ''} ${depot.lastName || ''}`.trim();
        case 'FONDS':
          return `Globales Wertpapierlimit gerissen bei ${depot.firstName || ''} ${depot.lastName || ''}`.trim();
        case 'GLOBAL':
          return `Globales Wertpapierlimit gerissen ${depot.isin || ''}`.trim();
        case 'RISIKO':
          return `Risikoklassenlimit gerissen bei ${depot.firstName || ''} ${depot.lastName || ''}`.trim();
        case 'BGS':
          return `Depotpositionslimit gerissen bei ${depot.firstName || ''} ${depot.lastName || ''}`.trim();
        default:
          return '';
      }
    },
    openDepotposition(depot) {
        const customer = {
          customerID: depot.customerID,
        };
      switch(depot.art) {
        case 'DEPOT':
          if (depot?.customerID) {
            this.$addBreadcrumb({
              label: `zurück zur Übersicht`,
              fullPath: this.$route.fullPath,
              breadcrumb: 'Depotlimit',
            });
            this.$router.push({ path: '/customer/limit/depotlimit', query: { kundennr: depot.customerID} });
          }
          break;
        case 'FONDS':
          this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, { data: customer, });
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
            customerId: customer.customerID,
            path: '/service/limit',
          });
          break;
        case 'RISIKO':
          if (depot?.customerID) {
            this.$addBreadcrumb({
              label: `zurück zur Übersicht`,
              fullPath: this.$route.fullPath,
              breadcrumb: 'Risikoklassenlimit',
            });
            this.$router.push({ path: '/customer/limit/risikoklassenlimit', query: { kundennr: depot.customerID} });
          }
          break;
        case 'BGS':
          const path = depot?.isin && depot.bgsNr ? `/home/vermogensubersicht/wertpapiere/limits/${depot?.isin}/${depot?.bgsNr}` : '/persoenlichedaten/customer-data';
          this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, { data: customer, });
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
            customerId: customer.customerID,
            path: path,
          });
          break;
        case 'GLOBAL':
          this.$addBreadcrumb({
            label: `zurück zur Übersicht`,
            fullPath: this.$route.fullPath,
            breadcrumb: 'Depotlimit',
          });
          this.$router.push(`/customer/limit/wertpapierlimit/${depot?.isin}`);
          break;
        default:
          this.$store.dispatch(LOG_TYPES.ACTIONS.WARN, `action not implemented yet: ${depot.art}`);
          break;
      }
    },
  },
  mounted() {
    this.findDepotpositionsOutOfLimits();
  },
  components: {
    Table,
    GhostLoading,
    NoData,
  },
}
</script>
