import MAILCOMPOSER_TYPES from './types';

export default {
  [MAILCOMPOSER_TYPES.GETTERS.GET_SENDERS](state) {
    return state.senders;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_PLACEHOLDERS](state) {
    return state.placeholders;
  },  
  [MAILCOMPOSER_TYPES.GETTERS.GET_UNSET_PLACEHOLDERS](state) {
    return state.unsetPlaceholders;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_HTML_TEMPLATES](state) {
    return state.templates;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_HTML_TEMPLATE](state) {
    return state.template;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_HTML_TEXT](state) {
    return state.text;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_WORD_TEMPLATE](state) {
    return state.wordTemplate;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_MAILCOMPOSER_CONFIG](state) {
    return state.data;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_PREVIEW](state) {
    return state.preview;
  },
  [MAILCOMPOSER_TYPES.GETTERS.SEND_MAIL](state) {
    return state.sendMail;
  },
  [MAILCOMPOSER_TYPES.GETTERS.SEND_TEST_MAIL](state) {
    return state.sendTestMail;
  },
  [MAILCOMPOSER_TYPES.GETTERS.APPZUSENDEN](state) {
    return state.appzusenden;
  },
  [MAILCOMPOSER_TYPES.GETTERS.SET_PARTICIPANTS](state) {
    return state.participants;
  },
  [MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS](state) {
    return state.attachments;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_ATTACHMENTS_CACHE](state) {
    return state.attachmentsCache;
  },
  [MAILCOMPOSER_TYPES.GETTERS.SET_PDF](state) {
    return state.pdf;
  },
  [MAILCOMPOSER_TYPES.GETTERS.SEND_FAX](state) {
    return state.fax;
  },
  [MAILCOMPOSER_TYPES.GETTERS.SEND_BRIEF](state) {
    return state.brief;
  },
  [MAILCOMPOSER_TYPES.GETTERS.WORD_TEMPLATES](state) {
    return state.wordTemplates;
  },
  [MAILCOMPOSER_TYPES.GETTERS.TEST_SENDERS](state) {
    return state.testSenders;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_INIT](state) {
    return state.init;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_MAIL_LINKED_OBJECT](state) {
    return state.linkedObject;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_CANCEL_INSURANCE](state) {
    return state.insuranceCancel;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_CONSTANTS](state) {
    return state.constants;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_MAIL_MODUS](state) {
    return state.modus;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_ANTRAG](state) {
    return state.mailAntrag;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_ANSPRECHPARTNER](state) {
    return state.mailAnsprechpartner;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_APPOINTMENT](state) {
    return state.mailAppointment;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_SCHADENSMELDUNG](state) {
    return state.mailSchadensmeldung;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_ANLAGEEMPFELUNG](state) {
    return state.mailAnlageempfehlung;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_BERATUNGSMAPPEN](state) {
    return state.mailBeratungsmappen;
  },  
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_VERSDOKU](state) {
    return state.mailVersDoku;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL](state) {
    return state.mailVersUebertragBlockGesell;
  },
  [MAILCOMPOSER_TYPES.GETTERS.SET_ERROR](state) {
    return state.errorMessage;
  },
  [MAILCOMPOSER_TYPES.GETTERS.GET_BERICHT_PDF](state) {
    return state.mailAuftragsdokumente;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_VERMITTLERNUMMERN](state) {
    return state.mailVermittlernummern;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL](state) {
    return state.mailAktuellerQuartalsberichte;
  },   
  [MAILCOMPOSER_TYPES.GETTERS.BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN](state) {
    return state.mailHonorareAbrechnungenVerschicken;
  },   
  [MAILCOMPOSER_TYPES.GETTERS.ANTRAG_CHECK_MAIL](state) {
    return state.ANTRAG_CHECK_MAIL;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_VERTRAGSSPIEGEL](state) {
    return state.mailVertragsspiegel;
  },
  [MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_FONDSHOP_ACTION](state) {
    return state.mailFondshopAction;
  },

  
}