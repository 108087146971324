<template>
  <CurrencyLabel v-if="type === 'percent'" :value="value" symb="%" />
  <CurrencyLabel v-else-if="type === 'double'" :value="value" symb="" />
  <CurrencyLabel v-else-if="type === 'currency'" :value="value" />
  <div v-else>{{ value }}</div>
</template>

<script>
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';


export default {
  props: {
    value: {},
    type: {
      type: String,
    },
  },
  components: {
    CurrencyLabel,
  },
}
</script>
