<template>
  <router-link :to="step.path" class="vertical-stepper-item__container clickable">
    <ph-circle class="color-primary vertical-stepper-item__icon" :size="16" />
    <span class="vertical-stepper-item__label" :class="{'font-bold': isCurrent}">
      {{ step.label }} <AnimatedSpinner v-if="isLoading" />
    </span>
  </router-link>
</template>

<script>
import { PhCircle } from 'phosphor-vue';

import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'


export default {
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    currentStep: {
      type: Object,
      default: () => {},
    },
    stepsLoading: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isCurrent() {
      return this.step?.path === this.currentStep?.path;
    },
    isLoading() {
      return this.stepsLoading && this.stepsLoading[this.step?.path];
    },
  },
  components: {
    PhCircle,
    AnimatedSpinner,
  },
}
</script>

<style scoped>
.vertical-stepper-item__container {
  display: flex;
  text-decoration: none;
}
.vertical-stepper-item__container,
.vertical-stepper-item__container:hover {
  color: var(--color-text);
}
.vertical-stepper-item__icon {
  flex-shrink: 0;
}
.vertical-stepper-item__label {
  margin-left: 8px;
}
</style>
