<template>
    <div v-if="vertragsdatenFieldsDefinition">

        <Table v-if="rows.length"
            :tableId="tableId"
            ref="table"
            cardView
            :headers="headers"
            :rows="rows"
            rowId="id"
            :hidePagination="true"
        >


        </Table>
        


    </div>
</template>
<script>
import {TextColumn} from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from 'vuex'
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import CORE_TYPES from '@/store/core/types';


export default {
//   mixins: [InsuranceDetailMixin],
    components: {
        Table,
    },
    data: function () {
            return {}
    },
    computed: {
        ...mapGetters({
        versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
        isFA: CORE_TYPES.GETTERS.IS_FA,
        isFK: CORE_TYPES.GETTERS.IS_FK,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        vertragsdatenFieldsDefinition: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
        fahrzeugdatenList: VERSICHERUNG_TYPES.GETTERS.FAHRZEUGDATEN_LIST,

        }),
        fahrzeugdaten() {
            return this.fahrzeugdatenList?.length ? this.fahrzeugdatenList[0] : {};
        },
        headers() {
            const result = {
            center: [],
            };

            result.center.push(TextColumn("id", "Id").makeHidden());

            if (this.vertragsdatenFieldsDefinition?.fzgart?.visible) {
                result.center.push(TextColumn("fzgart", "Fahrzeugart (WKZ)"));
            }
            if (this.vertragsdatenFieldsDefinition?.marke?.visible) {
                result.center.push(TextColumn("marke", "Marke"));
            }
            if (this.vertragsdatenFieldsDefinition?.handelsbez?.visible) {
                result.center.push(TextColumn("handelsbez", "Handelsbezeichnung"));
            }
            if (this.vertragsdatenFieldsDefinition?.leistung?.visible) {
                result.center.push(TextColumn("leistung", "Leistung (kW)"));
            }
            if (this.vertragsdatenFieldsDefinition?.hubraum?.visible) {
                result.center.push(TextColumn("hubraum", "Hubraum (cm³)"));
            }
            if (this.vertragsdatenFieldsDefinition?.plaetze?.visible) {
                result.center.push(TextColumn("plaetze", "Plätze"));
            }
            if (this.vertragsdatenFieldsDefinition?.nutzlast?.visible) {
                result.center.push(TextColumn("nutzlast", "Nutzlast in kg"));
            }
            if (this.vertragsdatenFieldsDefinition?.nutzerkreis?.visible) {
                result.center.push(TextColumn("nutzerkreis", "Nutzerkreis"));
            }
            if (this.vertragsdatenFieldsDefinition?.erstzulassdat?.visible) {
                result.center.push(TextColumn("erstzulassdat", "Erstzulassung"));
            }
            if (this.vertragsdatenFieldsDefinition?.zulassung_vn_seit?.visible) {
                result.center.push(TextColumn("zulassung_vn_seit", "Zulassung auf VN seit"));
            }
            if (this.vertragsdatenFieldsDefinition?.kilometerstand?.visible) {
                result.center.push(TextColumn("kilometerstand", "Kilometerstand"));
            }
            if (this.vertragsdatenFieldsDefinition?.kennzeichen?.visible) {
                result.center.push(TextColumn("kennzeichen", "Kennzeichen"));
            }            

            return result;
        },
        rows() {
            const rows = [];
            const row = {};

            row.id = this.fahrzeugdaten?.id;

            if (this.vertragsdatenFieldsDefinition?.fzgart?.visible) {
                const fzgart = this.vertragsdatenFieldsDefinition?.fzgart?.value?.find(vd => vd.value == this.fahrzeugdaten?.fzgart);
                row.fzgart = fzgart?.label || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.marke?.visible) {
                row.marke = this.fahrzeugdaten?.marke || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.handelsbez?.visible) {
                row.handelsbez = this.fahrzeugdaten?.handelsbez || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.leistung?.visible) {
                row.leistung = this.fahrzeugdaten?.leistung || '-';
            }          
            if (this.vertragsdatenFieldsDefinition?.hubraum?.visible) {
                row.hubraum = this.fahrzeugdaten?.hubraum || '-';
            }   
            if (this.vertragsdatenFieldsDefinition?.plaetze?.visible) {
                row.plaetze = this.fahrzeugdaten?.plaetze || '-';
            }    
            if (this.vertragsdatenFieldsDefinition?.nutzlast?.visible) {
                row.nutzlast = this.fahrzeugdaten?.nutzlast || '-';
            }    
            if (this.vertragsdatenFieldsDefinition?.nutzerkreis?.visible) {
                row.nutzerkreis = this.fahrzeugdaten?.nutzerkreis || '-';
            }     
            if (this.vertragsdatenFieldsDefinition?.erstzulassdat?.visible) {
                row.erstzulassdat = this.fahrzeugdaten?.erstzulassdat || './.'
            }    
            if (this.vertragsdatenFieldsDefinition?.zulassung_vn_seit?.visible) {
                row.zulassung_vn_seit = this.fahrzeugdaten?.zulassung_vn_seit || './.';
            }   
            if (this.vertragsdatenFieldsDefinition?.kilometerstand?.visible) {
                row.kilometerstand = this.fahrzeugdaten?.kilometerstand || '-';
            }      
            if (this.vertragsdatenFieldsDefinition?.kennzeichen?.visible) {
                row.kennzeichen = this.fahrzeugdaten?.kennzeichen || '-';
            }                  
            
            rows.push(row);
            return rows;
        },
        tableId() {
            const uniqueUUID = '549f5cf5-abb4-485d-b29f-a4bab99e75b0';
            const vertragsparte = this.vertragsdatenFieldsDefinition?.vertragssparte?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.description?.id)
            return `${uniqueUUID}-${vertragsparte?.label}`;
        },


    },  
    methods: {
        currency(value) {
            if (isNaN(value)) {
                return value;
            }
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €';
        },
       
    },
    mounted() {

    },
    validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>