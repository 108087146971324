import Action from './actionTypes/Action.vue';
import BaseFileSelectAction from './actionTypes/BaseFileSelectAction.vue';

/**
 * A simple Action modal.
 * When clicked, the modal will emit an "action-[key]" event.
 * for example, if the given key is "DELETE", the event can be listened to with "@action-DELETE"
 * @param {*} key 
 * @param {*} label 
 * @returns 
 */
export function DropdownSimpleAction(key, label) {
  return action(key, Action, label);
}

/**
* This action emits an event with the given key and the list of selected files
* It handle the download link according to the platform
* @param {*} key 
* @param {*} label 
* @param {*} accept 
*/
export function DropdownBaseFileSelectAction(key, label, accept) {
  return {
      ...action(key, BaseFileSelectAction, label),
      accept
  };
}


/**
* The internal method for action creation
* Don't use this method directly!
* Instead use one of the ...Action functions above.
* If no fitting Action type exists yet, you can create a new one!
* @param {*} actionKey 
* @param {*} component 
* @param {*} label 
* @returns 
*/
function action(actionKey, component, label) {
  return {
    actionKey,
    component,
    label,
    withValue(value) {
      this.value = value;
      return this;
    },
    withDisabled(disabled) {
      this.disabled = disabled
      return this;
    },
    withLoading(loading) {
      this.loading = loading
      return this;
    },
    withIcon(icon, size) {
      this.icon = icon
      this.size = size
      return this;
    },
    withVisible(visible) {
      this.visible = visible
      return this;
    },
    withPrimary(primary) {
      this.primary = primary
      return this;
    },
    withReplacementActionForCloseOnSmallScreen(replacementActionForCloseOnSmallScreen) {
      this.replacementActionForCloseOnSmallScreen = replacementActionForCloseOnSmallScreen;
      return this;
    },
    withVisibleOnSmallScreen(visibleOnSmallScreen) {
      this.visibleOnSmallScreen = visibleOnSmallScreen
      return this;
    },
    withVisibleOnLargeScreen(visibleOnLargeScreen) {
      this.visibleOnLargeScreen = visibleOnLargeScreen
      return this;
    },
  };
}