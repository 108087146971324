<template>
  <div>   
    <div  class="box__container">
      <div class="input-forms__label-content">Lineare Wertentwicklung (p.a.)</div>
      <div v-if="initialized">  
        <div v-for="(wertentwicklung, index) of stepParameters.data.parameterData.lineareWertentwicklung"
          :key="'LW'+index" :class="{ 'mt-16px': index > 0 }">
          <input-field
            :label="index===0 && false ? 'Lineare Wertentwicklung (p.a.)' : ''"
            v-model="stepParameters.data.parameterData.lineareWertentwicklung[index]"
            @change="markModified"
            type="percent"
            :isComponentHalfSize="true"
          ></input-field>
        </div>
      </div>
    </div>
    <div  class="box__container">
      <InputChips
        class="p-0 indices"
        label="Indizes"
        v-model="selectedIndizes"
        :availableOptions="indizesValues"
        :showToggle="true"
        @input="markModified"
      />
    </div>  
    <div  class="box__container">
      <ComboBox v-if="initialized"
        label="Musterdepot"
        v-model="myDepotId"
        :values="sources"
        :isComponentHalfSize="true"
        :sortComboboxValues="false"
        @change="markModified"
      ></ComboBox>
    </div>  
    <div  class="box__container">
      <div  class="box__title">Zusammensetzung</div>
      <div v-if="initialized">  
          <div v-for="(wertentwicklung, index) of stepParameters.data.parameterData.indexKombinations"
            :key="'K'+index">
            <div class="mt-3 mb-2">
              <InputToggleSwitch
                  v-model="stepParameters.data.parameterData.indexKombinations[index].active"
                  :label="stepParameters.data.parameterData.indexKombinations[index].name"
                  :inLineLabel="true"
                  @input="markModified(); resetIndexKombinationValues(index)"
              />
            </div>
            <div>
              <InputField
                  label="Bezeichnung" 
                  v-model="stepParameters.data.parameterData.indexKombinations[index].label" 
                  isComponentHalfSize
                  :disabled="!isIndexKombinationActive(index)"
                  trimValue
                  @input="markModified" 
              />
            </div>
            <div v-for="(wertentwicklung, i) of stepParameters.data.parameterData.indexKombinations[index].indizes"
              :key="'KI'+i" class="forms__input--half-size">
              <div  class="row">
                <div class="col-12 col-sm-8 mb-2 mb-sm-0">
                  <ComboBox 
                    :label="`Index ${i+1}`"
                    :firstEmpty="true"
                    :values="stepParameters.data.indexAuswahl"
                    v-model="stepParameters.data.parameterData.indexKombinations[index].indizes[i]"
                    :disabled="!isIndexKombinationActive(index)"
                    @change="markModified"></ComboBox>
                </div>
                <div class="col-12 col-sm-4">
                  <InputField 
                    label="Gewichtung" 
                    v-model="stepParameters.data.parameterData.indexKombinations[index].gewichtung[i]"
                    type="percent"
                    :disabled="!isIndexKombinationActive(index)"
                    @change="markModified" />
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    
   <div v-if="debug">BM {{ id }} params: {{ stepParameters.data }}</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import StepMixin from "./StepMixin";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputChips from '@/components/core/input-chips/InputChips.vue';


export default {
  components: {
    InputToggleSwitch,
    InputChips,
  },
  data() {
    return {
      step: INVESTMENT_ADVICE.STEPS.BENCHMARK,
      selectedIndizes: [],
      myDepotId: '-1',
    };
  },
  mixins: [StepMixin],
  computed: {
    ...mapGetters({
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
    }),
    initialized() {
      return this.stepParameters?.data?.parameterData;
    },
    indizesSelection() {
      return this.stepParameters?.data?.parameterData?.indizesSelection || [];
    },
    selectedIndizesValues() {
      return this.selectedIndizes?.map((item) => item.value);
    },
    indizesValues() {
      const indizesValues = this.indizesSelection?.map((item, index) => ({
        label: item?.label || '',
        value: index,
      }));
      return indizesValues?.filter((item) => !this.selectedIndizesValues.includes(item.value));
    },
    sources() {
      if (this.stepParameters?.data?.sources) {
        return this.stepParameters.data.sources;
      } else {
        return [{value: '-1', label: 'Kein Depot' }]
      }
    },
  },
  watch: {
    indizesSelection: {
      handler() {
        const selectedIndizes = [];
        this.indizesSelection
          .forEach((item, index) => {
            if(item.value+'' === '1') {
              selectedIndizes.push({
                label: item?.label || '',
                value: index,
              });
            }
          });
        this.selectedIndizes = [...selectedIndizes];
      },
      immediate: true,
    },
    'initialized': 'initMusterDepot',
  },
  mounted() {
    this.initMusterDepot();
  },
  methods: {
    initMusterDepot() {
      if (this.initialized?.musterDepot) {
        const part = this.initialized.musterDepot.split('\t');
        this.myDepotId = part[0];
      }
    },
    save(makePersist, source) {
      if (this.loading) {
        return Promise.resolve();
      }
      const parameterData = this.stepParameters.data.parameterData;

      // Selected Indizes
      parameterData.indizesSelection.forEach((item, index) => {
        item.value = this.selectedIndizesValues.includes(index) ? '1' : '0';
      });

      let musterDepot = this.myDepotId;
      if (this.myDepotId != '-1') {
        const option = this.sources.find(d => d.value==this.myDepotId);
        if (option) {
          musterDepot += '\t' + option.label;
        }
      }
      this.setRefreshOutcome();

      return this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SAVE_PARAMETERS, { 
        adviceId: this.id,
        parameters: {
          step: this.step,
          benchmarkingData: {
            ...parameterData,
            indizes: this.stepParameters.data.parameterData.indizesSelection.filter(i=>i.value === '1' || i.value === true).map(pair=>pair.label),
            indizesSelection: undefined,
            musterDepot,
          },
        }
       });
    },
    isIndexKombinationActive(index) {
      return this.stepParameters?.data?.parameterData?.indexKombinations?.[index]?.active ?? false;
    },
    resetIndexKombinationValues(index) {
      const indexKombinations = this.stepParameters.data.parameterData.indexKombinations;
      this.$set(indexKombinations[index], 'label', null);
      this.$set(indexKombinations[index], 'indizes', ['', '', '']);
      this.$set(indexKombinations[index], 'gewichtung', [0, 0, 0]);
    },
  }
};
</script>

<style scoped>

::v-deep .indices .input-chips__input-container .input-chips__chips-container > .input-chips__chips > .color-danger {
  color: var(--color-text);
}
</style>