<template>
  <div>
    <div class="box__container" v-if="hasHauptdaten" >
      <Hauptdaten 
        :disabled="false" 
        :data="getSpartenDataHauptdata" 
        :sparteId="getSparteId"
        :comboboxValues="comboboxValues" 
        :stepConfig="stepConfig"
        :layoutModus="layoutModus"
      />
    </div>

    <div v-if="components && components.length">
      <ComponentList
        :components="components"
        :data="getSpartenData"
        :comboboxValues="getComboboxValues"
        :disabled="false"
        @change="inputChanged($event)"
      ></ComponentList>
    </div>
  </div>
</template>


<script>
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import Hauptdaten from '@/components/versicherungen/Haupdaten.vue'
import ComponentList from "@/components/wertpapierorder/ComponentList.vue";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    Hauptdaten,
    ComponentList,
  },
  props: {
    spartenValues: {
      type: Array,
      default: () => []
    },
    comboboxValues: {
      type: Object,
      default: () => {}
    },
    spartenComponents: {
      type: Array,
      default: () => []
    },
    hasHauptdaten: {
      type: Boolean,
      default: false,
    },
    sparteId: {
      type: String,
      default: ''
    },
    stepConfig: {
      type: Object,
      default: () => {}
    },
    layoutModus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selection: {},
    }
  },
  mounted() {
  },
  computed: {
    getSparteId() {
      return this.sparteId;
    },
    components() {
      return this.spartenComponents || [];
    },
    isEditable() {
      return this.getSparteId && this.spartenComponents && this.spartenValues?.some(sp => sp.sparteId ===this.getSparteId);
    },
    getComboboxValues() {
      return this.comboboxValues && this.comboboxValues[this.getSparteId] || {}
    },
    getSpartenData() {
      const data = {};
      const params = (this.spartenValues?.find(sp => sp.sparteId ===this.getSparteId) || {})?.parameters || {};
      Object.keys(params).forEach(key => data[key] = params[key] === "true" ? true : (params[key] === "false" ? false : params[key]));
      return data;
    },
    getSpartenDataHauptdata() {
      return this.spartenValues?.find(sp => sp.sparteId ===this.getSparteId) || {};
    },
  },
  methods: {
    inputChanged(event) {
      if (event?.componentId) {
        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.UPDATE_STORE_SPARTEN_DATA, Object.assign(event, { sparteId: this.getSparteId }));
      }
    },
  }
};
</script>

<style scoped>
</style>