<template>
  <div>
    <h1>TEST TEST TEST</h1>
    <FotoButton :edgeCorrection="true" @input="setImage"/>
    <BaseFileSelect accept="image/*" @files="onFileChange" buttonText="Bild bearbeiten"/><br>
    <EdgeSelectionDialog v-if="rawImgUrl" :imageData="rawImgUrl" @change="setImage"/>
    <img v-else-if="image" :src="image"/>
    <span v-else>no image yet</span><br>
    <BaseButton @click="upload" :disabled="!image">Hochladen</BaseButton>
  </div>
</template>

<script>
import FILEUPLOAD_TYPES from '@/store/fileUpload/types';
import FotoButton from '@/components/fileUpload/FotoButton';
import EdgeSelectionDialog from '@/components/fileUpload/EdgeSelectionDialog';
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect';
import BaseButton from '@/components/core/BaseButton.vue'
import {uploadFileChunked, fileFromUrlData} from '@/helpers/upload-helper'

export default {
  components: {
    FotoButton,
    EdgeSelectionDialog,
    BaseFileSelect,
    BaseButton,
  },
  data() {
    return {
      image: null,
      rawImgUrl: null,
    };
  },
  methods: {
    setImage(urlData) {
      this.image = urlData;
    },
    upload() {
      const newFile = fileFromUrlData(this.image, "photo_" + Math.round(Math.random() * 100000) + ".jpeg", 'image/jpeg');
      uploadFileChunked(newFile).then(id => {
        this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.UPLOAD_IMAGE, id);
      })
    },
    onFileChange(files) {
      if (files.length >= 1) {
        const reader = new FileReader();
        reader.onload = () => {
          this.rawImgUrl = reader.result;
        }
        reader.readAsDataURL(files[0]);
      }
    },
  }
}
</script>

<style scoped>

</style>
