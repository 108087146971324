<template>
  <div>
    <BaseButton v-if="editMode" @click="neuObject()" class="mb-16px">Neues Objekt hinzufügen</BaseButton>

    <div>
      <Table
        v-if="tableData.records.length"
        tableId="1bfd6aa4-c4fd-11eb-8529-0242ac130003"
        :tableData="tableData"
        :actions="tableActions"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :rowsPerPage="tableData.records.length"
        :paginationEnabled="false"
        @execute-action="handleTableAction($event)"
      />
      <div v-else>Keine Daten</div>
    </div>

    <BaseModal ref="beschaedigtModal" :showDefaultButtons="false" @close="cancelBeschaedigtEditing()">
      <template #modalTitle>{{ beschaedigtInserting === true ? 'Beschädigtes Objekt' : 'Beschädigtes Objekt Bearbeiten' }}</template>
      <template #default>
        <InputField label="Zugehöriger Schadensfall" :disabled="true" type="text" :value="sparte"/>
        
        <InputTextArea label="Beschreibung" v-model="beschaedigt_form.schadenbeschreibung" :validateUntouched="true"/>
        <InputField label="KFZ Kennzeichen" v-model="beschaedigt_form.kennz_geschkfz" v-if="istKFZSparte"/>

      </template>
      <template #footer>
        <BaseButton @click="closeBeschaedigtModal" isSecondary :disabled="loading" class="mr-16px">Abbrechen</BaseButton>
        <BaseButton @click="beschaedigtSpeichern()" :disabled="loading">
          Beschädigtes Objekt speichern <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>

    <BaseModal ref="beschaedigtDeleteModal" modalTitle="Eintrag löschen" 
      @onConfirmButton="deleteBeschaedigt(beschaedigtToDelete)" @close="clearBeschaedigtToDelete()">
      <div v-if="beschaedigtToDelete">
        "{{beschaedigtToDelete.beschreibung}}" wirklich löschen?
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { PhPencilLine, PhTrash } from 'phosphor-vue';
import { mapGetters } from 'vuex';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";

import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";

import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import Table from '@/components/table/Table.vue';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";

const TABLE_HEADERS = {
  
  id: {
    label: 'ID',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'id',
    visible: false,
  },
  
  schadenbeschreibung: {
    label: 'Beschreibung',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'schadenbeschreibung',
    visible: true,
  },
};
const TABLE_HEADER_ACTION = {
  actions: {
    label: 'Aktionen',
    key: 'actions',
    dataType: 'tableAction',
    visible: true,
  },
};
const TABLE_ACTIONS = [{
  legend: {
    icon: PhPencilLine,
    index: 1,
    key: 'EDIT',
    label: 'Bearbeiten',
  }
}, {
  legend: {
    icon: PhTrash,
    index: 3,
    key: 'DELETE',
    label: 'Löschen',
  }
}];


export default {
  mixins: [validator],
  props: {
    schadenId: {
      type: [String, Number],
    },
    editMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      beschaedigt_form: {},
      beschaedigtToDelete: null,
      beschaedigtEditing: false,
      beschaedigtInserting: false,
      tableActions: TABLE_ACTIONS,
    };
  },
  computed: {
    ...mapGetters({
      schadensmeldung: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
       configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
       detailsData: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
    }),
    beschaedigt() {
      const beschaedigt = this.schadensmeldung?.beschaedigt || [];
      return [...beschaedigt];
    },
    tableHeaders() {
      return {
        ...TABLE_HEADERS,
        ...(this.editMode ? TABLE_HEADER_ACTION : {})
      };
    },
    tableData() {
      return {
        headers: this.tableHeaders,
        records: this.beschaedigt,
      };
    },
    sparte(){
      return this.configData?.sparte || '';
    },
    istKFZSparte() {
      return 'KFZ-Versicherung'.toLowerCase() === this.sparte?.toLowerCase();
    },
  },
  
  methods: {
    openBeschaedigtModal() {
      this.$refs.beschaedigtModal.open();
    },
    closeBeschaedigtModal() {
      this.$refs.beschaedigtModal.close();
    },
    openBeschaedigtDeleteModal(whatBeschaedigt) {
      this.beschaedigtToDelete = whatBeschaedigt;
      this.$refs.beschaedigtDeleteModal.open();
    },
    getNewObject() {
      return {
        id: null,
        schadenbeschreibung:"",
        kennz_geschkfz:"",
       schadenId: null,
      };
    },
    neuObject() {
      this.beschaedigt_form = this.getNewObject();
      this.beschaedigtEditing = false;
      this.beschaedigtInserting = true;

      this.openBeschaedigtModal();
    },
    editBeschaedigt(whatBeschaedigt) {
       const beschaedigt_form = Object.assign({}, whatBeschaedigt);
     
      this.beschaedigt_form =  beschaedigt_form;
      this.beschaedigtEditing = true;
      this.beschaedigtInserting = false;

      this.openBeschaedigtModal();
    },
    deleteBeschaedigt(whatBeschaedigt) {
      const beschaedigt_form = this.beschaedigt_form;
      this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.DELETE_BESCHAEDIGT, {
        schadenId: this.schadenId, 
        beschaedigtId: whatBeschaedigt.id
      });
    },
    clearBeschaedigtToDelete() {
      this.beschaedigtToDelete = null;
    },
    sendFormBeschaedigt() {
      this.loading = true;
      const beschaedigt_form = this.beschaedigt_form;
      this.beschaedigt_form.schadenId = this.schadenId;
     
      this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.SEND_BESCHAEDIGT, beschaedigt_form)
        .then(() => this.closeBeschaedigtModal())
        .catch((error) => console.log(error))
        .finally(() => this.loading = false);
    },
    beschaedigtSpeichern() {
  
      this.beschaedigtEditing = false;
      this.beschaedigtInserting = false;
      this.sendFormBeschaedigt();
    },
    cancelBeschaedigtEditing() {
      this.beschaedigtEditing = false;
      this.beschaedigtInserting = false;
      this.beschaedigt_form = this.getNewObject();
    },
    handleTableAction(event) {
      if(event.action?.legend?.key === 'EDIT') {
        this.editBeschaedigt(event?.value);
      } else if(event.action?.legend?.key === 'DELETE') {
        this.openBeschaedigtDeleteModal(event?.value);
      }
    },
  },
  validators: {
    beschaedigt_form: {
      schadenbeschreibung: [required()]
    }
  },
  components: {
    BaseButton,
    InputField,
    BaseModal,
    AnimatedSpinner,
    Table,
    InputTextArea,
  },
}
</script>
