<template>
  <div class="footer">
    <div class="editor-wrapper">
      <HtmlEditor
        :value="value"
        :textPlaceholderOptions="placeholdersOptions"
        :previewHTML="previewHtml.text"
        :disabled="disabled"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
        @onTogglePreviewEnabled="handlePreview"
        isFontParams
        :isPreviewButton="!ignoreSenders"
        applySavedSettings/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import HtmlEditor from "@/components/html-editor/HtmlEditor.vue";

export default {
  components: {
    HtmlEditor,
  },
  props: {
    placeholders: {
      type: Object,
    },
    value: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ignoreSenders: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isPreview: false,
    };
  },
  computed: {
    ...mapGetters({
      previewHtml: MAILCOMPOSER_TYPES.GETTERS.GET_PREVIEW,
      senders: MAILCOMPOSER_TYPES.GETTERS.GET_SENDERS,
    }),
    placeholdersOptions() {
      const keys = this.placeholders && Object.keys(this.placeholders);
      return (
        (this.placeholders &&
          Object.values(this.placeholders).map((v, i) => ({
            name: keys[i],
            data: v.map((p) => ({
              value: Object.values(p)[0],
              label: Object.keys(p)[0],
            })),
          }))) ||
        []
      );
    },
  },
  mounted() {
    if (!this.ignoreSenders) {
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_SENDERS);
    }
  },
  methods: {
    handlePreview(value, setValue = true) {
      if (setValue) {
        this.isPreview = value;
      }
      if (value) {
        const senders = this.senders.filter((v) => v.email === this.sender);
        const sender =
          (senders && senders[0]) || (this.senders && this.senders[0]);
        
        return this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PREVIEW, {
          briefInput: {
            html: this.value,
            vorlageTyp: "BRIEF",
            saveInArchive: false,
            queryFormat: false,
            hintergruend: false,
            worldId: null,
            fileName: '1',
            subject: '',
          },
          subject: '',
          text: this.value,
          modus: 'ONE_MAIL',
          confidential: false,
          userTypeSender: sender?.user?.type || 'MAKLER',
          userIdSender: sender?.user?.userId,
          emailSender: sender.email,
          paramTextAlredyIncluded: true,
          participants: [],
        });
      } else {
        return Promise.resolve();
      }
    },
  },
};
</script>

<style scoped>
.align-center {
  align-items: center;
}
.editor-wrapper {
  width: 99%;
}
.button__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.nowrap {
  white-space: nowrap;
  padding: 0;
}
</style>
