<template>
  <div>
    <PageHeaderTitleNavigation title="Wertpapiersuche" />

    <FondsFinderSuchMaske/>

    <FondsFinderPositionsList 
        ref="fondsfinderResult"
        :lagerstelle="lagerstelle"
        :goBackEnabled="false"
        :hasDefaultButton="false"
        showMobileSelectionAlways
    />

    <div class="box__container">
      <BaseButton @click="addPositions()">
        {{detailedAddPositionsButtonText}}
      </BaseButton>
    </div>
    
    <BaseModal
        ref="confirmAddPositions"
        modalTitle="Wertpapier hinzugefügt!"
        labelButtonConfirm="Ok"
        :showCancelButton="false">
        <label>Wertpapier hinzugefügt!</label>
    </BaseModal>

    <BaseModal
      ref="emptySelection"
      modalTitle="Kein Auswahl"
      labelButtonConfirm="Ok"
      :showCancelButton="false">
      <label>Bitte wählen Sie eine Position aus.</label>
    </BaseModal>
  </div>
</template>

<script>
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import { mapGetters } from 'vuex';
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import BaseModal from "@/components/core/BaseModal.vue";
import mixin from '@/mixins/wertpapierorder/mixin.js';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
    mixins: [mixin],
    components: {
      FondsFinderSuchMaske,
      FondsFinderPositionsList,
      BaseModal,
      OptionMenu,
      PageHeaderTitleNavigation,
      BaseButton,
    },
    data() {
        return {
            changed: false,
            categoryId: '',
        };
    },
    computed: {
        ...mapGetters({
            lagerstellen: WERTPAPIERORDER_TYPES.GETTERS.LAGERSTELLEN  || [],
        }),
        detailedAddPositionsButtonText() {
            return this.categoryId === 'NEU_SPARPLAN' ? 'Sparplan anlegen' : 'Einmalanlage kaufen';
        },
        depotNr() {
            return this.data && this.data['depotNr'];
        },
    },
    mounted() {
        this.categoryId = this.$route.query.isSparplan ? 'NEU_SPARPLAN' : 'NEU_KAUF';
    },
    methods: {
        goBack() {
            if (this.changed) {
                const payload = {};
                payload.lagerstelle = this.lagerstelle;
                payload.depotNr = this.depotNr;
                payload.kontoFreeText = this.kontoFreeText;
                this.doSave(payload);
            }
            this.$router.push({path: this.$route.path.includes(this.PATH_CUSTOMER) ? `${this.PATH_CUSTOMER}main` : `${this.PATH_BROKER}main`, query: { 
                lagerstelleFixed: this.$route.query.lagerstelleFixed, 
                backAction: true, 
            }});
        },
        addPositions() {
            const data = this.$refs.fondsfinderResult?.getSelected();
            if (data && this.categoryId) {
                const isins = data.checkPositions && data.checkPositions.isins;
                if (isins && Object.keys(isins).length) {
                    const positions = [];
                    Object.keys(isins).forEach(isin => {
                        const info = data.positionInfo && data.positionInfo.length && data.positionInfo.find(elem => elem.isin == isin) || {};
                        positions.push({ isin: isin, positionTyp: this.categoryId, wertpapiername: info.wertpapiername, wkn: info.wkn, sri: info.sri,
                        depotNr: this.depotNr, lagerstelle: this.lagerstelle, betragModus: 'VALUE_BETRAG_MODUS_EURO' });
                    });

                    this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.ADD_POSITIONS, { positions: positions });
                    this.changed = true;
                    this.goBack()
                }
            } else {
            this.$refs.emptySelection.open();
          }
        },
    },
}
</script>

<style>
    .title-row {
        border-top: 1px solid #f7f3f3;
        background-color: #f7f3f3;
        padding: 1rem;
    }
</style>