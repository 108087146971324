var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputRadioBoxGroup", {
          attrs: {
            value: _vm.form.steuerklasse,
            title:
              "Soweit bekannt, können Sie das persönliche Verhältnis zwischen Übertragendem und Empfänger angeben.",
            values: _vm.steuerklasseValues
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { steuerklasse: $event })
            }
          }
        }),
        _c(
          "BaseButton",
          {
            staticClass: "col-12",
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v("Zurück")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }