import { setObject, getObject } from "@/helpers/local-storage-helper"

export const WEBRTC_CONFIG_CAMERA_KEY = 'WebRTCConfig/camera'
export const WEBRTC_CONFIG_MICROPHONE_KEY = 'WebRTCConfig/microphone'
export const WEBRTC_CONFIG_SPEAKER_KEY = 'WebRTCConfig/speaker'

export function saveWebRTCSettings(microphone, camera, speaker) {
  setObject(WEBRTC_CONFIG_CAMERA_KEY, camera)
  setObject(WEBRTC_CONFIG_MICROPHONE_KEY, microphone)
  setObject(WEBRTC_CONFIG_SPEAKER_KEY, speaker)
}

export function getSavedCameraId() {
  return getObject(WEBRTC_CONFIG_CAMERA_KEY) || ''
}

export function getSavedMicrophoneId() {
  return getObject(WEBRTC_CONFIG_MICROPHONE_KEY) || ''
}

export function getSavedSpeakerId() {
  return getObject(WEBRTC_CONFIG_SPEAKER_KEY) || ''
}