<template>
  <div>

    <PageHeaderTitleNavigation 
      :title="selectedSpartenTyp.bezeichnung" 
      :actions="headerActions" 
    />
  
    <ContentWithStepper :stepperCollapsed="stepperCollapsed">
      <template #stepper>
        <ConfigEditVerticalStep
          ref="stepper"
          :configStep="steps"
          :configData="emptyData"
          :selectedStep="selectedStep"
          :disabled="isLoading"
          :actionsConfig="actionsConfig"
          @collapsed="stepperCollapsed = $event"
          @setStep="setSelectedStep"
          @setSubstep="setSelectedSubstep"
          @stepEdited="stepEdited($event)"
          @removeStep="removeStep($event)"
          @addStep="addStep()"
          @orderChanged="onStepOrderChanged($event)"
        />
      </template>

      <template #content>
        <div v-if="loading || selectedStep" class="box__container">
          <GhostLoading v-if="loading" type="block" :config="{ block: { height: '120px', }, }" />
          <SortableList v-else-if="selectedStepForm.configComponents && selectedStepForm.configComponents" class="configs-components__sortable" :key="(selectedStepForm.configComponents && selectedStepForm.configComponents.length || 0)" 
            :items="selectedStepForm.configComponents" @orderChanged="onOrderChanged($event)"
          >
            <div class="configs-components" data-sortable-container>
                <div v-for="(component, index) in selectedStepForm.configComponents" :key="component._uuid || component.id" 
                  class="configs-component--item" 
                  :class="{ 'active': editEnabled[component._uuid || component.id], }"
                  data-sortable-item
                >
                  <template>
                    <div class="configs-component--item-input">
                      <AntragComponent 
                        :component="component"
                        :data="emptyData"
                        disabled
                        isComponentHalfSizeEnabled />
                    </div>
                  </template>

                  <div class="configs-component--item-actions">
                    <div>
                      <button type="button" class="btn-clear" :disabled="isLoading" data-sortable-item-handler>
                        <PhList :size="16" />
                      </button>
                    </div>

                    <BaseDropdownMenu ref="dropdownMenu" :disabled="isLoading" placement="bottom-right">
                      <template #hook-target>
                        <button type="button" class="btn-clear clickable" :disabled="isLoading">
                          <PhDotsThreeVertical :size="16" />
                        </button>
                      </template>
                      <template #default>
                        <ul class="configs-component--item-actions--list list">
                          <template>
                            <li><button type="button" class="btn-clear clickable" :disabled="isLoading" @click="editParameter(index, component)">Bearbeiten</button></li>
                          </template>
                          <li class="item-divider"></li>
                          <li><button type="button" class="btn-clear clickable" :disabled="isLoading" @click="removeComponent(component,index)">Löschen</button></li>
                        </ul>
                      </template>
                    </BaseDropdownMenu>
                  </div>
                </div>
            </div>
          </SortableList>

          <div v-if="selectedStep" class="configs-component--actions">
            <button type="button" class="btn-clear clickable" @click="insertParameter()" :disabled="isLoading">
              <PhPlus :size="16" /> neuer Parameter
            </button>
          </div>
        </div>
      </template>
    </ContentWithStepper>

    <BaseModal 
      :showDefaultButtons="false"
      ref="editParameterModal" 
      modalTitle="Parameter bearbeiten">

      <div>

        <ComboBox
          v-if="!addingNewTab &&  selectedStep!=='Neue Gruppe'"
          label="Tab"
          v-model="configDefinitionForm.gruppe"
          :values="tabs"
          :firstEmpty="false"
          :validateUntouched="true"
        />

        <InputField v-model="configDefinitionForm.gruppe" label="Gruppe" v-if="addingNewTab || selectedStep=='Neue Gruppe'"/>
  
        <InputField :disabled="!addingNewComponent" v-model="configDefinitionForm.name" label="Name"/>
        <InputTextArea label="Bezeichnung" v-model="configDefinitionForm.bezeichnung"/>


        <ComboBox
          label="Typ"
          v-model="configDefinitionForm.type"
          :values="moeglicheTypen"
          :firstEmpty="true"
          :validateUntouched="true"
          :disabled="!addingNewComponent"
          @input="setEinheit()"
        />

        <ComboBox
          v-if="configDefinitionForm.type == 'TEXT_CURRENCY'  || configDefinitionForm.type == 'TEXT_PROZENT'"
          label="Einheit"
          v-model="configDefinitionForm.einheit"
          :values="einheitTypen"
          :firstEmpty="true"
          disabled
          :validateUntouched="true"
        />

        <InputField 
          v-if="configDefinitionForm.type == 'TEXT_CURRENCY' || configDefinitionForm.type == 'TEXT_PROZENT'"
           v-model="configDefinitionForm.nachkommastellen" 
           label="Nachkommastellen" 
           type="number"
           :validateUntouched="true"/>           

        <div  v-if="!addingNewComponent">Typ: {{typLabel}}</div>

        <div  v-if="!addingNewComponent && configDefinitionForm.type == 'TEXT_CURRENCY'  || configDefinitionForm.type == 'TEXT_PROZENT'">Einheit: {{einheitLabel}}</div>  
        
        <InputToggleSwitch v-model="configDefinitionForm.visible" label="Sichtbar" inLineLabel suppressValidationMessage />       


        <div class="col-12 form-control" v-if="configDefinitionForm.type=='COMBOBOX'">
          <InputField
            class="align-end"
            v-model="neuerWert"
            label="neuer Wert"
          />
          <a class="align-end" style="flex-grow: 0;" @click="hinzufuegen()" v-if="(neuerWert || neuerWert.length)" >
            <PhPlus :size="24"/>
          </a>

          <PhProhibit :size="24" v-if="(!neuerWert || !neuerWert.length)"/>
        </div>
          

        <Table
          tableId="0f6ea022-b13a-484d-a191-cd101dfa9177"
          title="Mögliche Werte"
          class="table-container"
          v-if="tableRows.length && configDefinitionForm.type=='COMBOBOX'"
          :headers="tableHeaders"
          :rows="tableRows"
          :rowsPerPage="20"
          count="25"
          @action="executeAction($event)"
        >
          
        </Table>        

        <div style="display: flex">
            <BaseButton isPrimary :disabled="!this.validation.isValid('configDefinitionForm')" @click="updateParameter()">
              Bestätigen
            </BaseButton>

            <BaseButton isSecondary @click="cancelUpdateParameter()">
              Abbrechen
            </BaseButton>

        </div>
      </div>
      
    </BaseModal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SPARTENTYP_EINSTELLUNG_TYPES from '@/store/spartentypeinstellung/types';

import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import ConfigEditVerticalStep from '@/components/core/configs/ConfigEditVerticalStep.vue';
import { PhList, PhDotsThreeVertical, PhPlus, PhTrash, PhProhibit } from 'phosphor-vue';
import AntragComponent from '@/components/antrag/AntragComponent.vue';
import SortableList from '@/components/core/SortableList.vue';
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { replaceUmlaute, } from '@/helpers/commonfunctions';
import { sanitize } from '@/helpers/string-helper.js';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { required, regex } from '@/mixins/validator/rules';
import validator from '@/mixins/validator'
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from "@/helpers/log-message-helper";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';


const DEFAULT_STEP_LABEL = 'Hauptdaten';
const NEW_STEP_LABEL = 'Neue Gruppe';

export default {
  mixins: [validator],
  validators: {
    configDefinitionForm : {
      // name : [required('NAME ist erforderlich!'), 
      //         regex((/^[_0-9A-Z]*$/), 'NAME sollte nur Großbuchstaben, Unterstriche und Zahlen enthalten')],
      gruppe:  [required('TAB ist erforderlich!')],      
      bezeichnung:  [required('BEZEICHNUNG muss gesetzt sein!')],  
      
    },
  },  
  components: {
    PageHeaderActionCard,
    ContentWithStepper,
    ConfigEditVerticalStep,
    AntragComponent,
    PhList,
    PhDotsThreeVertical,
    PhPlus,
    SortableList,
    BaseDropdownMenu,
    GhostLoading,
    BaseModal,
    BaseButton,
    InputToggleSwitch,
    InputField,
    ComboBox,
    InputTextArea,
    Table,
    PhTrash,
    PhProhibit,
    OptionMenu,
    PageHeaderTitleNavigation,

  },
  data() {
    return {
      configDefinitionForm: {
        id: null,
        gruppe: 'Hauptdaten',
        name: '',
        bezeichnung: '',
        type:  '',
        value: '',
        possibleValues: [],
        visible: true,
        index: 0,
        breite: null,
        originalType: {label: "Text", value: "TEXT"},
        sparteId: null,
        forceLinebreak: false,
        einheit: 'CURRENCY',
        nachkommastellen: null,
      },
      stepperCollapsed: null,
      selectedStep: null,
      loading: false,
      saving: false,
      editEnabled: {},
      addingNewTab: false,
      moeglicheTypen: [
        {label: "Ganzzahlwert", value: "TEXT_NUMBER"},
        {label: "Betrag (Währung)", value: "TEXT_CURRENCY"},
        {label: "Betrag (Prozentsatz)", value: "TEXT_PROZENT"},
        {label: "Text", value: "TEXT"},
        {label: "Auswahl", value: "COMBOBOX"},
        {label: "Datum", value: "DATE_PICKER"},
        {label: "Wahrheitswert (Ja/Nein/Unbekannt)", value: "SINGLE_SELECTION"},
        {label: "Wahrheitswert (Ja/Nein)", value: "SWITCHER"},
      ],
      einheitTypen: [
        {label: "Währung", value: "CURRENCY"},
        {label: "Prozentsatz", value: "PERCENTAGE"},
      ],
      addingNewComponent: false,
      neuerWert: '',
      newSteps: [],
      errors: [],
    };
  },
  computed: {
    ...mapGetters({
      selectedSpartenTyp: SPARTENTYP_EINSTELLUNG_TYPES.GETTERS.SELECTED_SPARTENTYP,
      spartenTypen: SPARTENTYP_EINSTELLUNG_TYPES.GETTERS.SPARTEN_TYPEN,
    }),
    steps() {
      const gruppen = Object.keys(this.spartenTypen);

      return [...gruppen?.map(g => ({
          stepKey: g,
          label: g,
          itemCount: this.spartenTypen[g].length
      })), ...this.newSteps];
    },
    tabs() {
      const gruppen = Object.keys(this.spartenTypen)?.map(g => ({
          value: g,
          label: g,
      })) || [];

      const newSteps = this.newSteps?.map(g => ({
          value: g?.stepKey,
          label: g?.label,
      })) || [];

      return [...gruppen, ...newSteps];

    },
    isLoading() {
      return this.loading || this.saving;
    },
    isValid() {
      return /* this.configDefinitionForm?.name &&  */this.validation?.isValid("configDefinitionForm");
    },
    configDefinitionId() {
      return this.$route.params.id || this.selectedSpartenTyp.spartenId;

      
    },
    emptyData() {
      return [];
    },
    selectedStepForm() {
      const components = this.spartenTypen[this.selectedStep || DEFAULT_STEP_LABEL]?.map(c=> ({
        id: c.id,
        label: c.bezeichnung,
        type: c.type,
        precision: c.precision,
        values: c.possibleValues || [],
        config: {
          precision: c.precision,
        },
        ...c
      }));

      return {
        ... { configComponents: components, },
      };     
    },
    tableHeaders() {
        return {
          lockedLeft: [
            TextColumn("wert", "Wert").makeAlwaysVisible(),
          ],
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],   
        };


    
    },        
    tableRows() {


      const actions = [
          SimpleAction("REMOVE_ITEM", PhTrash, "Löschen"),
      ];

      return this.configDefinitionForm?.possibleValues?.map(c => ({wert: c.value, actions})) || [];
    },
    typLabel() {
      return this.moeglicheTypen.find(t => t.value == this.configDefinitionForm.type)?.label || "Text";
    },
    einheitLabel() {
      return this.einheitTypen.find(t => t.value == this.configDefinitionForm.einheit)?.label || "Währung";
    },    
    actionsConfig() {
      let result = {};

      this.steps.forEach(s => {
        result[s.stepKey] = {
            editable: () =>  s.stepKey !== DEFAULT_STEP_LABEL , // return value
            removable: () => s.itemCount == 0, // return value
            sortable: () => s.stepKey !== DEFAULT_STEP_LABEL, 
          }              
        });

      return result;


    },
    headerActions() {
      return [];
    },

  },
  methods: {
    setEinheit() {
      if (this.configDefinitionForm.type === "TEXT_CURRENCY") {
        this.configDefinitionForm.einheit = "CURRENCY";
      } else if (this.configDefinitionForm.type === "TEXT_PROZENT") {
        this.configDefinitionForm.einheit = "PERCENTAGE";
      } else {
        this.configDefinitionForm.einheit = null;
      }
    },
    executeAction(actionData) {
      switch (actionData.key) {
        case 'REMOVE_ITEM':
          this.configDefinitionForm.possibleValues = this.configDefinitionForm?.possibleValues.filter(c => c.value != actionData.row.wert);

          this.setConfigDefinitionEdited();
          this.autoSave();

          break;     
      }
    }, 
    hinzufuegen() {
      this.configDefinitionForm?.possibleValues?.push({value: this.neuerWert, label: this.neuerWert});
      this.neuerWert = '';
    },
    sanitize(html) {
      return sanitize(html);
    },
    setSelectedStep(stepKey) {
      this.selectedStep = stepKey;

    },
    setSelectedStepByIndex(index) {
      this.setSelectedStep(this.configDefinitionForm.steps?.[index]?.stepKey || '');
    },
    setSelectedSubstep(event) {
      const { stepKey, } = event;
      this.setSelectedStep(stepKey);
    },
    stepEdited({ stepKey, label }) {

      if (this.selectedStepForm?.configComponents?.length) {
        this.renameGroup(stepKey, label, this.configDefinitionId);
        
        this.loadData();
      } else {
        let newStep = this.newSteps?.find(ns => ns.stepKey == stepKey);
        if (newStep) { {
          newStep.stepKey = label;
          newStep.label = label;
          this.setSelectedStep(newStep.stepKey);
        }

        }
      }

    },
    async renameGroup(oldGroupName, newGroupName, spartenArt) {
      await this.$store.dispatch(SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.RENAME_GROUP, {oldGroupName, newGroupName, spartenArt});
    },
    removeStep(stepKey) {
      const stepIndex = this.newSteps.findIndex(step => step.stepKey === stepKey);
      this.newSteps.splice(stepIndex, 1);

      if(this.selectedStep === stepKey) {
        this.setSelectedStepByIndex(0);
      }

    },
    async addStep() {
      // set step
      const countNewSteps = this.steps.filter(s => s.label.indexOf(NEW_STEP_LABEL) > -1);
      this.setSelectedStep(NEW_STEP_LABEL + countNewSteps?.length || '');

      const newName = NEW_STEP_LABEL + countNewSteps?.length || ("_" + 1);

      let newStep = {stepKey: newName, label: newName, itemCount: 0};
      this.newSteps.push(newStep);
      this.setSelectedStep(newStep.stepKey);
    },
    async onStepOrderChanged(orderedList) {
      

      const payload = {
        spartenArt: this.configDefinitionId,
        steps: orderedList.slice(1).map(ol => ol.stepKey)
      };

      await this.$store.dispatch(SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.CHANGE_STEP_POSITION, payload);

    },
    setConfigDefinitionEdited() {

      if (!this.configDefinitionForm?.id) {
        this.configDefinitionForm.index = this.selectedStepForm?.configComponents?.length ? Math.max(...this.selectedStepForm.configComponents.filter(cc => cc.index).map(o => o.index))+1 : 0;
      }

      this.$store.commit(SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.SET_CONFIG_DEFINITION_EDITED, {
        ...this.configDefinitionForm,
      });
    },
    async removeComponent(component, index) {
      const configComponent = this.selectedStepForm.configComponents[index];
      this.$confirmModal({
        message: `Möchten Sie den Parameter <b>"${configComponent.label}"</b> wirklich löschen?`,
        title: 'Parameter löschen',
        labelButtonConfirm: 'Löschen'
      })
        .then(() => {
          this.deleteDefinition(component?.id, encodeURIComponent(this.configDefinitionId));
        });
    },
    async deleteDefinition(id, spartenArt) {
      await this.$store.dispatch(SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.REMOVE_SPARTENTYP, {id, spartenArt});
      this.loadData();
    },
    async onOrderChanged(components) {

      let changedComponents = [];


      // checkes which components had the index attribute changed and then add them do the "changedComponents" array
      components.forEach((c,index) => {
        let changedComponent = this.selectedStepForm?.configComponents?.find(cod => cod.id == c.id && cod.index !== index);

        if (changedComponent) {
          let newIndex = index;

          changedComponent.index = newIndex;
          changedComponents.push(changedComponent);

        }
        
      });

      const stepIndex = this.steps?.findIndex(s=> s.stepKey == this.selectedStep);

      let startIndex = 0;

      // counts the number of items in the previous steps so the startIndex can be calculated
      if (stepIndex) {
        startIndex = this.steps.reduce((accumulator, item, index) => {

          if (index < stepIndex) {
            accumulator += item?.itemCount;
          }

          return accumulator;
        }, 0);

      }

      // sets the new index
      changedComponents = changedComponents?.map(cc => (
          {...cc, 
            index: cc.index +  startIndex 
          }
          ));

      await this.changeComponentPosition(changedComponents);
      await this.loadData();
    },
    async changeComponentPosition(changedComponents) {
      await this.$store.dispatch(SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.CHANGE_COMPONENT_POSITION, { spartenArt: encodeURIComponent(this.configDefinitionId), changedComponents });
    },
    async saveConfigDefinition() {
      await this.$store.dispatch(SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.SAVE_CONFIG_DEFINITION);
    },
    async autoSave() {
      if(this.isValid) {
        await this.saveData();
        this.loadData();
        
      }
    },
    async saveData() {
      try {
        this.saving = true;
        await this.saveConfigDefinition();
      } finally {
        this.saving = false;
        this.newSteps = [];
      }
    },
    editParameter(index, component) {
      this.addingNewComponent = false;
      this.configDefinitionForm = component;
      this.configDefinitionForm.sparteId = this.configDefinitionId;
      this.setConfigDefinitionEdited();

      this.$store.commit(SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.SET_CONFIG_DEFINITION_EDITED, {
        ...this.configDefinitionForm,
      });

      this.$refs.editParameterModal.open();
    },
    insertParameter() {
      this.addingNewComponent = true;
      this.configDefinitionForm = {
        id: null,
        gruppe: this.selectedStep,
        name: '',
        bezeichnung: '',
        type:  'TEXT',
        key: '',
        value: '',
        possibleValues: [],
        visible: true,
        index: null,
        breite: null,
        originalType: {label: "Text", value: "TEXT"},
        sparteId: null,
        forceLinebreak: false,
      };

      this.configDefinitionForm.sparteId = this.configDefinitionId;
      this.setConfigDefinitionEdited();

      this.$store.commit(SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.SET_CONFIG_DEFINITION_EDITED, {
        ...this.configDefinitionForm,
      });

      this.$refs.editParameterModal.open();
    },
    
    updateParameter() {
      if (!this.parameterExists()) {
        this.setConfigDefinitionEdited();
        this.autoSave();
        this.addingNewComponent = false;
        this.$refs.editParameterModal.close();
      } else {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Parametername existiert bereits für dies Sparte!", 'danger'));
      }


    },
    parameterExists() {

      return this.selectedStepForm?.configComponents?.find(cod => cod?.bezeichnung == this.configDefinitionForm?.bezeichnung && cod.id != this.configDefinitionForm?.id);
      
    },
    cancelUpdateParameter() {
      this.addingNewTab = false;
      this.addingNewComponent = false;
      this.$refs.editParameterModal.close();
    },
    async loadData() {
      await this.$store.dispatch(SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.LOAD_SPARTEN_TYPEN, { 
        spartenArt: encodeURIComponent(this.configDefinitionId),
      });
    },
  },
  async beforeRouteLeave(to, from, next) {

    next();
  },
  mounted() {

    this.loadData();

    
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.configs-component--item {
  display: flex;
  min-height: 32px;
  margin: 0 0 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &.active {
    background-color: #f5f5f5;
  }

  .configs-component--item-editor {
    width: 100%;
  }

  .configs-component--item-input {
    flex: 1 1 auto;
    padding: 4px;
  }

  .configs-component--item-actions {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin: 0 0 0 12px;
  }
}

.configs-component--item-actions--list {
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 144px;

  li {
    button {
      cursor: pointer;
      display: block;
      line-height: 1.4em;
      text-align: left;
      width: 100%;
    }
  }
}

.configs-component--actions {
  display: flex;
  justify-content: center;
}

.configs-component--actions button {
  margin: 0 8px;
}
</style>

<!-- GLOBAL STYLE -->
<style lang="scss">
.configs-component--item-input {
  em {
    color: inherit;
  }

  .input-forms__label-content {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>
