var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-search__result-icon" },
    [
      _c("ph-chats", {
        directives: [
          {
            name: "fc-tooltip",
            rawName: "v-fc-tooltip",
            value: _vm.emailLabel,
            expression: "emailLabel"
          }
        ],
        class: _vm.emailClasses,
        attrs: { size: 16, weight: _vm.emailLabel ? "fill" : "regular" }
      }),
      _c("ph-phone", {
        directives: [
          {
            name: "fc-tooltip",
            rawName: "v-fc-tooltip",
            value: _vm.telefonLabel,
            expression: "telefonLabel"
          }
        ],
        class: _vm.telefonClasses,
        attrs: { size: 16, weight: _vm.telefonLabel ? "fill" : "regular" }
      }),
      _c("ph-file-text", {
        directives: [
          {
            name: "fc-tooltip",
            rawName: "v-fc-tooltip",
            value: _vm.faxLabel,
            expression: "faxLabel"
          }
        ],
        class: _vm.faxClasses,
        attrs: { size: 16, weight: _vm.faxLabel ? "fill" : "regular" }
      }),
      _c("ph-envelope", {
        directives: [
          {
            name: "fc-tooltip",
            rawName: "v-fc-tooltip",
            value: _vm.postLabel,
            expression: "postLabel"
          }
        ],
        class: _vm.postClasses,
        attrs: { size: 16, weight: _vm.postLabel ? "fill" : "regular" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }