var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navi_header_container" }, [
    _c(
      "div",
      { staticClass: "navi_header_horizontal_wrapper" },
      [
        _c("div", { staticClass: "logo" }, [
          _vm.logoURLProp
            ? _c("a", { on: { click: _vm.goToFirstRoute } }, [
                _c("img", {
                  attrs: {
                    alt: "Makler Logo",
                    src: _vm.logoURLProp,
                    id: "logo_image"
                  },
                  on: { error: _vm.hideImage }
                })
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "navi" }, [
          _vm.hasHome
            ? _c(
                "div",
                {
                  staticClass: "navi_link home",
                  class: { navi_link_visited: _vm.isHomeVisited },
                  on: {
                    click: function($event) {
                      _vm.gotoRoute(_vm.getHomeLink())
                    }
                  }
                },
                [_c("a", [_vm._v("Home")])]
              )
            : _vm._e(),
          _vm.hasFondsshop
            ? _c(
                "div",
                {
                  staticClass: "navi_link fondsshop",
                  class: { navi_link_visited: _vm.isFondsshopVisited },
                  on: {
                    click: function($event) {
                      return _vm.gotoRoute("fondsshop")
                    }
                  }
                },
                [_c("a", [_vm._v("Fondsshop")])]
              )
            : _vm._e(),
          _vm.hasSofunktionierts
            ? _c(
                "div",
                {
                  staticClass: "navi_link so_funktionierts",
                  class: { navi_link_visited: _vm.isSofunktioniertsVisited },
                  on: {
                    click: function($event) {
                      return _vm.gotoRoute("sofunktionierts")
                    }
                  }
                },
                [_c("a", [_vm._v("So funktioniert's")])]
              )
            : _vm._e(),
          _vm.hasModellportfolios
            ? _c(
                "div",
                {
                  staticClass: "navi_link modellportfolios",
                  class: { navi_link_visited: _vm.isModellportfoliosVisited },
                  on: {
                    click: function($event) {
                      return _vm.gotoRoute("modellportfolios")
                    }
                  }
                },
                [_c("a", [_vm._v("Modellportfolios")])]
              )
            : _vm._e(),
          _vm.hasEinzelfonds
            ? _c(
                "div",
                {
                  staticClass: "navi_link einzelfonds",
                  class: { navi_link_visited: _vm.isEinzelfondsVisited },
                  on: {
                    click: function($event) {
                      return _vm.gotoRoute("einzelfonds")
                    }
                  }
                },
                [_c("a", [_vm._v("Einzelfonds")])]
              )
            : _vm._e(),
          _vm.hasDetails
            ? _c(
                "div",
                {
                  staticClass: "navi_link details",
                  class: { navi_link_visited: _vm.isDetailsVisited },
                  on: {
                    click: function($event) {
                      return _vm.gotoRoute("details")
                    }
                  }
                },
                [_c("a", [_vm._v("Details")])]
              )
            : _vm._e(),
          _vm.hasRoutes && _vm.brokerInfo.maklerHomepage
            ? _c(
                "div",
                {
                  staticClass: "navi_link homepage",
                  class: { navi_link_visited: _vm.isHomepageVisited }
                },
                [
                  _c("a", { attrs: { href: _vm.brokerInfo.maklerHomepage } }, [
                    _vm._v("Homepage")
                  ])
                ]
              )
            : _vm._e()
        ]),
        _vm.hasRoutes
          ? _c(
              "BaseFullscreenMenu",
              {
                ref: "baseFullscreenMenu",
                staticClass: "hamburger",
                attrs: { icon: _vm.burgerMenuIcon }
              },
              [
                _vm.hasHome
                  ? _c(
                      "div",
                      {
                        staticClass: "vert_link",
                        on: {
                          click: function($event) {
                            _vm.gotoRouteModal(_vm.getHomeLink())
                          }
                        }
                      },
                      [_c("a", [_vm._v(" Home")])]
                    )
                  : _vm._e(),
                _vm.hasFondsshop
                  ? _c(
                      "div",
                      {
                        staticClass: "vert_link",
                        on: {
                          click: function($event) {
                            return _vm.gotoRouteModal("fondsshop")
                          }
                        }
                      },
                      [_c("a", [_vm._v("Fondsshop")])]
                    )
                  : _vm._e(),
                _vm.hasSofunktionierts
                  ? _c(
                      "div",
                      {
                        staticClass: "vert_link",
                        on: {
                          click: function($event) {
                            return _vm.gotoRouteModal("sofunktionierts")
                          }
                        }
                      },
                      [_c("a", [_vm._v("So funktioniert's")])]
                    )
                  : _vm._e(),
                _vm.hasModellportfolios
                  ? _c(
                      "div",
                      {
                        staticClass: "vert_link",
                        on: {
                          click: function($event) {
                            return _vm.gotoRouteModal("modellportfolios")
                          }
                        }
                      },
                      [_c("a", [_vm._v("Modellportfolios")])]
                    )
                  : _vm._e(),
                _vm.hasEinzelfonds
                  ? _c(
                      "div",
                      {
                        staticClass: "vert_link",
                        on: {
                          click: function($event) {
                            return _vm.gotoRouteModal("einzelfonds")
                          }
                        }
                      },
                      [_c("a", [_vm._v("Einzelfonds")])]
                    )
                  : _vm._e(),
                _vm.hasDetails
                  ? _c(
                      "div",
                      {
                        staticClass: "vert_link",
                        on: {
                          click: function($event) {
                            return _vm.gotoRouteModal("details")
                          }
                        }
                      },
                      [_c("a", [_vm._v("Details")])]
                    )
                  : _vm._e(),
                _vm.hasRoutes &&
                _vm.brokerInfo.maklerHomepage &&
                _vm.isCmsFondsshopOhneHomeSeite
                  ? _c(
                      "div",
                      {
                        staticClass: "vert_link",
                        on: {
                          click: function($event) {
                            return _vm.gotoRouteModal(
                              _vm.brokerInfo.maklerHomepage
                            )
                          }
                        }
                      },
                      [_c("a", [_vm._v("Homepage")])]
                    )
                  : _vm._e()
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }