<template>
    <div>
        <PageHeaderTitleNavigation 
            title="Zubringercourtage bearbeiten" 
            :actions="headerActions"
            @action-BACK="back"
            @action-CANCEL="abbrechen"
        />

        <div class="box__container">
            <div class="d-block">
                <InputField label="Bereich" v-model="form.combo_bereich" disabled/>
                <InputField label="Zubringer" v-model="form.input_zubringer" @input="onChangeZubringer"/>
                <InputField v-model="form.input_zubringer_name" disabled/>
                <InputField type="percent" :precision="4" label="Abschluss / Einmalige VV-Strategiegebühr" v-model="form.input_abschluss" />
                <InputField type="percent" :precision="4" label="Bestand" v-model="form.input_bestand" />
                <InputField type="percent" :precision="4" label="Servicegebühr / VV-Vermittlungsgebühr" v-model="form.input_servicegebuehr" />
                <InputField type="percent" label="MwSt. mit der die Courtage versteuert wird" v-model="form.input_mwst" disabled/>
            </div>
        </div>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from 'vuex';
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import { required, regex } from "@/mixins/validator/rules";
import CORE_TYPES from '@/store/core/types'
import validator from "@/mixins/validator";
import InputField from '@/components/core/forms/InputField.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    mixins: [validator],
    validators: {
        form: {
            input_zubringer: [regex(/^[0-9]{5}$/, 'Die Vermittlernummer des Zubringers ist nicht korrekt')],
        }
    },
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        InputField,
    },
    computed: {
        ...mapGetters({
            bgs: DEPOTPOSITIONENEDIT_TYPES.GETTERS.BGS,
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
        }),
        fields() {
            return this.bgs?.steps?.COURTAGE?.form || [];
        },
        isValid() {
            return !!this.form.input_zubringer_name;
        },
        id() {
            return this.$route.params.id;
        },
        headerActions() {
            const { isValid, } = this;

            const actions = [];
            actions.push(PageHeaderSimpleAction('CANCEL', 'Änderungen verwerfen'));
            return actions;
        },
    },
    data() {
        return {
            form: {
                combo_bereich: '',
                input_zubringer: '',
                input_zubringer_name: '',
                radio_absolut: '',
                input_abschluss: '',
                input_bestand: '',
                input_servicegebuehr: '',
                input_mwst: '',
            },
            combos: {},
            cancel: false,
            initial: {},
        }
    },
    mounted() {
        this.combos = this.bgs?.combos || {};
        if (this.id && this.id !== '0') {
            const data3 = (this.bgs?.steps?.COURTAGE?.fields || []).find(field => field?.data3)?.data3 || {};
            const record = (data3.records || []).filter(rec => rec?.length).find(rec => rec?.length >= 6 && (rec[6] || []).some(action => action?.data?.parameter_id === this.id));
            data3.headers?.forEach((hdr, index) => {
                if ('input_zubringer' === hdr.f_key) {
                    const pos = record[index].indexOf('/');
                    this.form.input_zubringer = record[index].substr(pos+1);
                    this.form.input_zubringer_name = record[index].substr(0, pos);
                } else if (hdr.f_key && hdr.f_key !== 'actions') {
                    this.form[hdr.f_key] = record[index];
                }
            })
        } else if (this.bgs?.values?.values_courtagezubringer_neu) {
            this.form = this.bgs?.values?.values_courtagezubringer_neu;
        }
        this.initial = JSON.parse(JSON.stringify(this.form))
    },
    methods: {
        abbrechen() {
            this.cancel = true;
            this.back();
        },
        back() {
            this.$router.push(`/home/depotpositionenedit/begleitscheinsteps/${this.$route.params.bgsnr}/COURTAGE?backAction=true`);
        },
        async onChangeZubringer() {
            this.form.input_zubringer_name = '';
            if ( /^[0-9]{5}$/.test(this.form.input_zubringer) ) {
                let response = await this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_VAL, {zubringer: this.form.input_zubringer});
                if (response.data?.ok ) {
                    this.form.input_zubringer_name = response.data.value;
                    this.validation.reset('form.input_zubringer');
                } else {
                    this.$pushErrors('form.input_zubringer', response.data.value);
                }
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        const anyChanges = Object.keys(this.form).some(key => this.initial[key] != this.form[key]);
        if (!this.cancel && anyChanges) {
            if (!this.isValid) {
                this.$confirmModal({
                    title: 'Fehler', 
                    message: 'Bitte alle Pflichtfelder ausfüllen.', 
                    labelButtonConfirm: 'Ok',
                    showCancelButton: false,
                    })
            } else {
                this.form.bgsnr = this.$route.params.bgsnr;
                this.form.parameter_id = this.id;
                this.form.ask = 'save_courtage';
                this.form.ask = 'save_courtage';
                this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.SAVE, this.form).then(response => {
                    if (response?.errorStr) {
                        // this.$pushErrors('input_zubringer', [response?.errorStr]);
                        this.$confirmModal({
                            title: 'Fehler', 
                            message: response?.errorStr, 
                            labelButtonConfirm: 'Ok',
                            showCancelButton: false,
                        })
                    } else {
                        this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
                        next();
                    }
                });
                
            }
        } else {
            this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
            next();
        }
    }

}
</script>

<style>

</style>