import CORE_TYPES from '../core/types';
import CUSTOMER_FILTERS_TYPES from './types';
import CUSTOMER_SEARCH_TYPES from '../customerSearch/types';
import axios from 'axios';
import router from '../../router';
import { buildMessage, } from '../../helpers/log-message-helper';

export default {


    [CUSTOMER_FILTERS_TYPES.ACTIONS.RETRIEVE_CUSTOMER_FILTERS_SETUP]({ commit, rootState }) {
        const config = {
            defaultSpinner: true
        };
        axios.get(rootState.core.apiAddress + '/customerFilters/getCustomerFiltersSetup', config).then(response => {
            commit(CUSTOMER_FILTERS_TYPES.MUTATIONS.SET_CUSTOMER_FILTERS_SETUP, response.data);
        })
    },


}