var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "entgelt-component" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-4" },
        [
          _c("InputField", {
            attrs: {
              label: "Ausgabeaufschlag",
              type: "percent",
              disabled: _vm.config.isAAReadOnly
            },
            on: {
              change: function($event) {
                return _vm.emitChangeEvent()
              }
            },
            model: {
              value: _vm.inputAA,
              callback: function($$v) {
                _vm.inputAA = $$v
              },
              expression: "inputAA"
            }
          })
        ],
        1
      ),
      !_vm.config.isAAReadOnly
        ? _c(
            "div",
            { staticClass: "col-12 col-md-4" },
            [
              _c("InputField", {
                attrs: { label: "normal", type: "percent", disabled: true },
                model: {
                  value: _vm.displayAANormal,
                  callback: function($$v) {
                    _vm.displayAANormal = $$v
                  },
                  expression: "displayAANormal"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-12 col-md-4" },
        [
          _c("InputField", {
            attrs: {
              label: "entspricht Rabatt",
              type: "percent",
              disabled: true
            },
            model: {
              value: _vm.calculateRabatt,
              callback: function($$v) {
                _vm.calculateRabatt = $$v
              },
              expression: "calculateRabatt"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }