var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Datenschutzerklärung (extern) eintragen",
          actions: _vm.headerActions
        },
        on: { "action-BACK": _vm.back, "action-CANCEL": _vm.abbrechen }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "61f5c7e1-d399-4079-8615-ef2be90c4fb7" }
        },
        [
          _c("p", [
            _vm._v(
              'Bitte erfassen Sie außerhalb des Systems vorliegene Kontaktwünsche des Kunden hier. Widersprüche / Widerrufe des Kunden (auch formlose) können ebenfalls hier eingetragen werden. Die gemachten Angaben sind dauerhaft gespeichert, können aber als "ungültig" markiert werden oder durch aktualisierte bzw. korrigierte Angaben ersetzt werden.'
            )
          ]),
          _c("InputField", {
            attrs: {
              label: "Beschreibung / Herkunft / Dokument der Erklärung",
              validateUntouched: "",
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.beschreibung,
              callback: function($$v) {
                _vm.$set(_vm.form, "beschreibung", $$v)
              },
              expression: "form.beschreibung"
            }
          }),
          _c("p", [_vm._v("Einwilligung zur Kundenansprache")]),
          _c("InputRadioBoxGroup", {
            attrs: { values: _vm.anspracheErlaubtOptions },
            model: {
              value: _vm.form.akzeptiert,
              callback: function($$v) {
                _vm.$set(_vm.form, "akzeptiert", $$v)
              },
              expression: "form.akzeptiert"
            }
          }),
          _c("InputCheckBoxGroup", {
            attrs: {
              title: _vm.istDialog
                ? "Sie wünschen den Kontakt"
                : "Ich/wir wünsche/n den Kontakt",
              values: [
                { label: "per Telefon", path: "einwilligungTel" },
                { label: "per Fax", path: "einwilligungFax" },
                { label: "per E-Mail", path: "einwilligungEmail" },
                { label: "per Post", path: "einwilligungPost" },
                {
                  label: "per Onlinekundenzugang / App:",
                  path: "einwilligungApp"
                }
              ]
            },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          }),
          _c("p", [
            _vm._v(
              "Falls die Kontaktaufnahme über E-Mail oder Fax verweigert wird ist ein E-Mail oder Faxversand aus dem System nicht möglich."
            )
          ]),
          _vm.istDialog
            ? _c("p", [
                _vm._v(
                  "Bitte beachten Sie, dass in diesem Fall Ihnen zusätzliche wichtige Dienstleistungen (z. B. Angebote zu aktuellen Produkten, Informationen zu Marktentwicklungen, die telefonische Beratung) nicht zur Verfügung stehen."
                )
              ])
            : _vm._e(),
          !_vm.istDialog
            ? _c("InputToggleSwitch", {
                attrs: {
                  label:
                    "Im Zuge eines Unternehmensverkaufs stimme/n ich/wir der Weitergabe unserer Daten an das weiterführende Unternehmen zu. Im Vorfeld werden wir über die Weiterverwendung unserer Daten durch das weiterführende Unternehmen rechtzeitig und in einem gesonderten Anschreiben informiert."
                },
                model: {
                  value: _vm.form.einwilligungVerkauf,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "einwilligungVerkauf", $$v)
                  },
                  expression: "form.einwilligungVerkauf"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }