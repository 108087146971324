import axios from 'axios';

import KUNDEN_GESELL_NR_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import { buildMessage, } from '@/helpers/log-message-helper';
import { makeQueryParam, } from '@/helpers/utils-helper';
import TAN_TYPES from "@/store/tan/types";

const SERVICE_PATH = '/kundenGesellNr';

const config = {
  defaultSpinner: true
};

export default {

  async [KUNDEN_GESELL_NR_TYPES.ACTIONS.FIND]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}`, config);
    commit(KUNDEN_GESELL_NR_TYPES.MUTATIONS.SET_LIST, [ ...response?.data || [], ]);
  },

  async [KUNDEN_GESELL_NR_TYPES.ACTIONS.FIND_COMBO_DATA]({ getters, commit, }) {
    if(Object.keys(getters[KUNDEN_GESELL_NR_TYPES.GETTERS.COMBO_DATA])?.length > 0) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/comboData`, config);
    commit(KUNDEN_GESELL_NR_TYPES.MUTATIONS.SET_COMBO_DATA, { ...response?.data || {}, });
  },

  async [KUNDEN_GESELL_NR_TYPES.ACTIONS.GET_DATA]({ getters, commit, }, { fremdnr, gesellId }) {
    if(!fremdnr || !gesellId) return;

    const params = makeQueryParam({ gesellId, fremdnr, }, true);
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/get?${params}`, config);
    commit(KUNDEN_GESELL_NR_TYPES.MUTATIONS.SET_DATA, { ...response?.data || {}, });
  },

  async [KUNDEN_GESELL_NR_TYPES.ACTIONS.GET_GESELLSCHAFT_BEISPIEL_KONTONR]({ getters, }, { gesellId, }) {
    if(!gesellId) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/${gesellId}/beispielKontonr`);
    return response?.data || '';
  },

  async [KUNDEN_GESELL_NR_TYPES.ACTIONS.SAVE_DATA]({ getters, dispatch, commit, }, { fremdnr, gesellId, }) {
    const edited = getters[KUNDEN_GESELL_NR_TYPES.GETTERS.DATA_EDITED];
    if(!Object.keys(edited)?.length) return;

    const params = makeQueryParam({ gesellId, fremdnr, }, true);
    let response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/save?${params}`, edited, config);

    ///// comdirect TAN START (move to comdirect folder?)
    if(response?.data?.requiresComdirectLogin) {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Für die Verknüpfung per Webservice ist eine TAN-Aktivierung notwendig.', 'warning'));
      await new Promise(resolve => setTimeout(resolve, 3000))
      const activationResponse = await dispatch(TAN_TYPES.ACTIONS.ACTIVATE_COMDIRECT_TAN)
      if (activationResponse?.success){
        // save again
        response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/save?${params}`, edited, config);
      } else {
        // display message
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es konnte keine TAN-Aktivierung festgestellt werden. Speichern Sie ggf. erneut', 'warning'));
      }
    }
    ///// comdirect TAN END

    if(response?.data?.warnings?.length) {
      response.data.warnings.forEach(warning => dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(`${warning.message}`, 'warning')));
    }
    commit(KUNDEN_GESELL_NR_TYPES.MUTATIONS.RESET_DATA_EDITED);
  },

  async [KUNDEN_GESELL_NR_TYPES.ACTIONS.DELETE]({ getters, }, { fremdnr, gesellId, }) {
    if(!fremdnr || !gesellId) return;

    const params = makeQueryParam({ gesellId, fremdnr, }, true);
    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/delete?${params}`, config);
  },

}
