var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CoreCard",
    {
      ref: "coreCard",
      attrs: {
        headers: _vm.headers,
        rows: _vm.rows,
        showToolbar: _vm.showToolbar,
        listView: _vm.listView
      },
      scopedSlots: _vm._u(
        [
          _vm.showTitle
            ? {
                key: "header",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.chartName) + " ")]
                },
                proxy: true
              }
            : null,
          {
            key: "alternative",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c("div", { staticClass: "content" }, [
        _vm.isContainerRendered
          ? _c("div", [
              _c(
                "div",
                { staticClass: "chart-container" },
                [
                  _c("Chart", {
                    attrs: {
                      data: _vm.chartData,
                      type: "pie",
                      isDataLabels: false,
                      isLegend: false,
                      colors: _vm.customColors,
                      mixedColors: false
                    }
                  })
                ],
                1
              ),
              !_vm.isEmptyChart()
                ? _c("div", { staticClass: "legend-container" }, [
                    _vm.chartData && _vm.chartData.data
                      ? _c(
                          "div",
                          { staticClass: "legend" },
                          _vm._l(_vm.chartData.data, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "legend-item",
                                style: { color: _vm.getColor(index) }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.label) +
                                    ": " +
                                    _vm._s(_vm._f("currency")(item.value)) +
                                    " "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }