<template>
  <div>
    <BaseModal ref="statusModal" 
      modalTitle="Vertragsstatus"
      :confirmDisabled="isFormInvalid"
      labelButtonConfirm="Status speichern"
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
      :autoClose = "false"
    >
      
      <div class="row">
        <div class="col-12">
          <InputField v-model="statusForm.beschreibung" label="Zugeh. Vertrag" :disabled="true"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputField v-model="vertragStatus.datumEingelesen" label="Datum eingelesen" :disabled="true"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ComboBox
            label="Status"
            v-model="vertragStatus.status"
            :values="statusForm.statusComboboxValues"
            :firstEmpty="true"
            :validateUntouched="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <DatePickerField
            label="Gültig ab"
            :validateUntouched="true"
            v-model="vertragStatus.gueltigab"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ComboBox
            label="Änderungsgrund"
            v-model="vertragStatus.aendergrund"
            :values="statusForm.aenderungsgrundComboboxValues"
            :firstEmpty="true"
            :validateUntouched="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputTextArea label="Änderungsdetails" v-model="vertragStatus.aenderdetails"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <DatePickerField
            label="Durchführungsdatum"
            v-model="vertragStatus.durchgef"
          />
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import dayjs from 'dayjs';
import validator from '@/mixins/validator'
import { required } from '@/mixins/validator/rules';

export default {
    mixins: [validator],
    validators: {},
    components: {
      BaseModal,
      InputField,
      ComboBox,
      InputTextArea,
      DatePickerField,
    },
    data() {
      return {
        vertragStatus: {status: '', gueltigab: null},
        statusForm: {beschreibung: '', statusComboboxValues: [], aenderungsgrundComboboxValues: []},
      }
    },
    computed: {
      getMessage() {
        return `Soll der Eintrag ${this.vertragStatus.status} ab ${this.vertragStatus.gueltigab} wirklich gelöscht werden?`;
      },
      isFormInvalid() {
        return this.validation.invalid;
      },
      fieldsValidators() {
        
        let validators = {
            gueltigab : [required('Gültib ab ist erforderlich!')],
            status : [required('Status Feld ist erforderlich!')],
        }
      
        return {vertragStatus: validators};
      },      
    },
    methods: {
      open(vetragStatus) {
        this.vertragStatus = vetragStatus.vertragStatusData;
        if (!this.vertragStatus?.gueltigab) {
          this.vertragStatus.gueltigab = dayjs().format('DD.MM.YYYY');
        }
        this.statusForm = vetragStatus;
        this.$configureValidators(this.fieldsValidators)
        this.$refs.statusModal.open();
      },
      close() {
        this.$refs.statusModal.close();
      },
      onConfirmModal() {
        this.vertragStatus.gueltigab = this.formatDate(this.vertragStatus.gueltigab);
        this.vertragStatus.durchgef = this.formatDate(this.vertragStatus.durchgef);
        this.$emit('save', this.vertragStatus);
      },
      onCancelModal() {
        this.$refs.statusModal.close();
      },
      formatDate(date) {
      if(date && !dayjs(date, 'DD.MM.YYYY').isValid()){
        return dayjs(date).format('DD.MM.YYYY');
      }
      return date;
    },
    }
}
</script>
