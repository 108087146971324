<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    data-name="Layer 1"
    width="888"
    height="544.35712"
    viewBox="0 0 888 544.35712"
  >
    <path
      d="M691.81666,659.77518l-32.03108,8.59237,32.26282-19.83964c-.694-31.6281-11.73343-64.42416-11.73343-64.42416s-68.94964,19.97485-87.0457,54.56423-3.85172,57.4245,21.514,70.69506c25.36535,13.27034,52.24662,11.95083,70.34268-22.63855C689.15381,679.02559,691.14674,669.66861,691.81666,659.77518Z"
      transform="translate(-156 -177.82144)"
      fill="#f2f2f2"
    />
    <path
      d="M653.66217,666.53634l-.892,1.70493c-10.4695,19.76515-21.90154,33.47938-34.15608,40.93644-.17673.1118-.35749.21074-.53427.32251l-.5003-.83148-.47089-.80541c13.42679-7.95112,24.69573-23.2235,33.832-40.46089.2999-.55268.6042-1.11375.8916-1.68367,3.80313-7.33114,7.22842-14.96737,10.27323-22.534.24082-.58359.47758-1.18.71434-1.77641,3.96734-10.00808,7.275-19.82376,9.92626-28.467.19018-.61009.37188-1.22454.54918-1.83059,4.42748-14.6481,6.88347-25.65114,7.40983-28.0957.0694-.29695.10222-.46253.11142-.50069l.92109.19159.01287-.004.92953.196c-.00923.03821-.11746.59439-.34923,1.61269-.84549,3.81487-3.246,14.11591-7.20329,27.28991-.18133.59319-.36306,1.20769-.55321,1.81772-2.1442,7.01643-4.70671,14.77084-7.6994,22.75164q-1.12264,3.0088-2.28139,5.90189c-.22836.6008-.46512,1.1972-.70593,1.78079Q658.952,656.32251,653.66217,666.53634Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M675.12753,611.80059c-.60525-.21986-1.21894-.44414-1.83222-.68979a52.55222,52.55222,0,0,1-4.88848-2.24569,51.1186,51.1186,0,0,1-17.26793-14.66734c-.58851.26187-1.177.52378-1.77841.78967a53.01144,53.01144,0,0,0,18.16319,15.56575,54.37247,54.37247,0,0,0,5.22245,2.3882c.60486.24118,1.21855.46546,1.82819.67692a52.56515,52.56515,0,0,0,15.5953,2.9664q-.18011-.98112-.34815-1.92373A50.94312,50.94312,0,0,1,675.12753,611.80059Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M664.59353,642.27184q-.90006-.40643-1.77366-.86343c-.18169-.08429-.36735-.18142-.54462-.27416a51.3897,51.3897,0,0,1-27.16055-39.07568c-.5981.32134-1.19628.64271-1.79035.97692a53.29793,53.29793,0,0,0,28.06775,39.78684c.23635.12365.47266.24728.71343.36249.59521.30063,1.18644.58842,1.78207.86781a53.016,53.016,0,0,0,29.61826,4.31468c-.00959-.63926-.01915-1.27858-.03718-1.92226A51.03685,51.03685,0,0,1,664.59353,642.27184Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M653.66217,666.53634c-.61728-.25844-1.22577-.53381-1.82987-.81757-.64627-.30589-1.28814-.62017-1.92117-.95135a51.5287,51.5287,0,0,1-26.58585-55.75026c-.73754.485-1.47062.96152-2.19123,1.45532a53.41569,53.41569,0,0,0,27.89393,55.983c.633.33118,1.27048.65392,1.91272.94694.60812.29663,1.21662.572,1.82951.83883a53.25942,53.25942,0,0,0,39.09794,1.09295c.1495-.71732.26928-1.43943.39348-2.17A51.36248,51.36248,0,0,1,653.66217,666.53634Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M271.18334,662.77518l32.03108,8.59237L270.9516,651.52791c.694-31.6281,11.73343-64.42416,11.73343-64.42416s68.94964,19.97485,87.0457,54.56423,3.85172,57.4245-21.514,70.69506c-25.36535,13.27034-52.24662,11.95083-70.34268-22.63855C273.84619,682.02559,271.85326,672.66861,271.18334,662.77518Z"
      transform="translate(-156 -177.82144)"
      fill="#f2f2f2"
    />
    <path
      d="M309.33783,669.53634l.892,1.70493c10.4695,19.76515,21.90154,33.47938,34.15608,40.93644.17673.1118.35749.21074.53427.32251l.5003-.83148.47089-.80541c-13.42679-7.95112-24.69573-23.2235-33.832-40.46089-.2999-.55268-.6042-1.11375-.8916-1.68367-3.80313-7.33114-7.22842-14.96737-10.27323-22.534-.24082-.58359-.47758-1.18-.71434-1.77641-3.96734-10.00808-7.275-19.82376-9.92626-28.467-.19018-.61009-.37188-1.22454-.54918-1.83059-4.42748-14.6481-6.88347-25.65114-7.40983-28.0957-.0694-.29695-.10222-.46253-.11142-.50069l-.92109.19159-.01287-.004-.92953.196c.00923.03821.11746.59439.34923,1.61269.84549,3.81487,3.246,14.11591,7.20329,27.28991.18133.59319.36306,1.20769.55321,1.81772,2.1442,7.01643,4.70671,14.77084,7.6994,22.75164q1.12264,3.0088,2.28139,5.90189c.22836.6008.46512,1.1972.70593,1.78079Q304.048,659.32251,309.33783,669.53634Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M273.17853,617.661q-.16806.94264-.34815,1.92373a52.56515,52.56515,0,0,0,15.5953-2.9664c.60964-.21146,1.22333-.43574,1.82819-.67692a54.37247,54.37247,0,0,0,5.22245-2.3882,53.01144,53.01144,0,0,0,18.16319-15.56575c-.60137-.26589-1.1899-.5278-1.77841-.78967a51.1186,51.1186,0,0,1-17.26793,14.66734,52.55222,52.55222,0,0,1-4.88848,2.24569c-.61328.24565-1.227.46993-1.83222.68979A50.94312,50.94312,0,0,1,273.17853,617.661Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M269.53132,649.44505c-.018.64368-.02759,1.283-.03718,1.92226a53.016,53.016,0,0,0,29.61826-4.31468c.59563-.27939,1.18686-.56718,1.78207-.86781.24077-.11521.47708-.23884.71343-.36249a53.29793,53.29793,0,0,0,28.06775-39.78684c-.59407-.33421-1.19225-.65558-1.79035-.97692a51.3897,51.3897,0,0,1-27.16055,39.07568c-.17727.09274-.36293.18987-.54462.27416q-.87359.457-1.77366.86343A51.03685,51.03685,0,0,1,269.53132,649.44505Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M270.73837,670.16423c.1242.73056.244,1.45267.39348,2.17a53.25942,53.25942,0,0,0,39.09794-1.09295c.61289-.26684,1.22139-.5422,1.82951-.83883.64224-.293,1.27969-.61576,1.91272-.94694a53.41569,53.41569,0,0,0,27.89393-55.983c-.72061-.4938-1.45369-.97031-2.19123-1.45532a51.5287,51.5287,0,0,1-26.58585,55.75026c-.633.33118-1.2749.64546-1.92117.95135-.6041.28376-1.21259.55913-1.82987.81757A51.36248,51.36248,0,0,1,270.73837,670.16423Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M421.35612,686.99534l19.06893,5.11526-19.20689-11.81105c.41318-18.829,6.98521-38.35337,6.98521-38.35337s41.04752,11.89154,51.82057,32.4835,2.293,34.1863-12.80787,42.08661c-15.10065,7.90018-31.10377,7.11464-41.87683-13.47732C422.94138,698.45562,421.75494,692.88516,421.35612,686.99534Z"
      transform="translate(-156 -177.82144)"
      fill="#f2f2f2"
    />
    <path
      d="M444.07048,691.02044l.531,1.015c6.23276,11.76671,13.03855,19.93115,20.334,24.37053.10521.06655.21282.12545.31807.192l.29783-.495.28033-.47948c-7.99331-4.7335-14.702-13.82555-20.14108-24.08742-.17854-.329-.35969-.663-.53079-1.00233-2.2641-4.36442-4.30327-8.91047-6.11593-13.41505-.14336-.34743-.28431-.70248-.42526-1.05754-2.36186-5.95807-4.331-11.8016-5.90936-16.94716-.11322-.3632-.22139-.729-.32694-1.0898-2.63579-8.72039-4.09791-15.27078-4.41126-16.72609-.04132-.17679-.06086-.27536-.06634-.29808l-.54834.11406-.00766-.0024-.55338.1167c.0055.02275.06993.35385.2079.96007.50335,2.27109,1.93241,8.40357,4.28831,16.24639.108.35314.21614.719.32934,1.08214,1.2765,4.17707,2.802,8.79347,4.58366,13.54464q.66833,1.79123,1.35816,3.51355c.13595.35767.2769.71273.42027,1.06016Q440.92128,684.93987,444.07048,691.02044Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M422.54391,660.13769q-.1.56118-.20727,1.14525a31.29351,31.29351,0,0,0,9.28429-1.766c.36293-.12589.72828-.25941,1.08837-.403a32.37024,32.37024,0,0,0,3.10906-1.42175,31.55916,31.55916,0,0,0,10.813-9.2667c-.358-.15829-.70838-.31422-1.05873-.47011a30.43228,30.43228,0,0,1-10.28005,8.73185,31.28691,31.28691,0,0,1-2.91024,1.33691c-.3651.14624-.73045.27977-1.09077.41065A30.32734,30.32734,0,0,1,422.54391,660.13769Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M420.37263,679.05957c-.01074.3832-.01643.7638-.02213,1.14437a31.56185,31.56185,0,0,0,17.63252-2.56863c.35459-.16634.70656-.33766,1.0609-.51664.14334-.06858.284-.14218.42473-.2158a31.7296,31.7296,0,0,0,16.70946-23.68614c-.35366-.199-.70977-.39028-1.06584-.58158a30.59363,30.59363,0,0,1-16.16938,23.26276c-.10553.05521-.21606.113-.32423.16322q-.52006.27208-1.05591.514A30.38347,30.38347,0,0,1,420.37263,679.05957Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M421.09122,691.39424c.07394.43492.14525.86481.23424,1.29185a31.70674,31.70674,0,0,0,23.276-.65066c.36487-.15886.72712-.32279,1.08915-.49938.38234-.17444.76183-.36657,1.13869-.56374a31.79973,31.79973,0,0,0,16.606-33.32815c-.429-.294-.86542-.57764-1.30449-.86638a30.67636,30.67636,0,0,1-15.82725,33.18957c-.37686.19717-.759.38427-1.14372.56637-.35964.16893-.72189.33286-1.08937.48672A30.57741,30.57741,0,0,1,421.09122,691.39424Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M802.23611,513.87822l-.08154-.00684c-15.16675-2.50439-32.01514-2.50342-51.50806.00293h-.00781a3.68561,3.68561,0,0,1-3.72925-3.66943V492.55156a3.67762,3.67762,0,0,1,3.67334-3.67334,199.87414,199.87414,0,0,1,51.71484.0039h.00684a3.65914,3.65914,0,0,1,3.605,3.66944v17.65332A3.67762,3.67762,0,0,1,802.23611,513.87822Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M802.23611,513.87822l-.08154-.00684c-15.16675-2.50439-32.01514-2.50342-51.50806.00293h-.00781a3.68561,3.68561,0,0,1-3.72925-3.66943V492.55156a3.67762,3.67762,0,0,1,3.67334-3.67334,199.87414,199.87414,0,0,1,51.71484.0039h.00684a3.65914,3.65914,0,0,1,3.605,3.66944v17.65332A3.67762,3.67762,0,0,1,802.23611,513.87822Zm-26.48242-25.6753a184.49964,184.49964,0,0,0-25.10279,1.67041,2.69885,2.69885,0,0,0-2.74145,2.67823v17.65332a2.67632,2.67632,0,0,0,2.67334,2.67334c19.50952-2.5127,36.43945-2.51319,51.69263-.00049a2.67622,2.67622,0,0,0,2.634-2.67285V492.55156a2.67632,2.67632,0,0,0-2.67334-2.67334A215.52784,215.52784,0,0,0,775.75369,488.20292Z"
      transform="translate(-156 -177.82144)"
      :fill="color"
    />
    <circle cx="620.40945" cy="322.7141" r="7" :fill="color" />
    <path
      d="M758.40945,496.87822h-6a1.50164,1.50164,0,0,1-1.5-1.5v-2a1.50164,1.50164,0,0,1,1.5-1.5h6a1.50164,1.50164,0,0,1,1.5,1.5v2A1.50164,1.50164,0,0,1,758.40945,496.87822Z"
      transform="translate(-156 -177.82144)"
      :fill="color"
    />
    <path
      d="M800.40945,510.87822h-6a1.50164,1.50164,0,0,1-1.5-1.5v-2a1.50164,1.50164,0,0,1,1.5-1.5h6a1.50164,1.50164,0,0,1,1.5,1.5v2A1.50164,1.50164,0,0,1,800.40945,510.87822Z"
      transform="translate(-156 -177.82144)"
      :fill="color"
    />
    <path
      d="M793.69914,506.57691l-.07246-.038c-12.999-8.20544-28.52151-14.75669-47.45472-20.02821l-.0072-.003a3.68562,3.68562,0,0,1-2.0087-4.83086l6.86518-16.26373a3.67763,3.67763,0,0,1,4.81272-1.95567,199.87381,199.87381,0,0,1,47.64257,20.11493l.0063.00266a3.65914,3.65914,0,0,1,1.89421,4.78253l-6.86518,16.26373A3.67763,3.67763,0,0,1,793.69914,506.57691Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M793.69914,506.57691l-.07246-.038c-12.999-8.20544-28.52151-14.75669-47.45472-20.02821l-.0072-.003a3.68562,3.68562,0,0,1-2.0087-4.83086l6.86518-16.26373a3.67763,3.67763,0,0,1,4.81272-1.95567,199.87381,199.87381,0,0,1,47.64257,20.11493l.0063.00266a3.65914,3.65914,0,0,1,1.89421,4.78253l-6.86518,16.26373A3.67763,3.67763,0,0,1,793.69914,506.57691Zm-14.413-33.953a184.49849,184.49849,0,0,0-23.77641-8.22327,2.69885,2.69885,0,0,0-3.56719,1.40128l-6.86518,16.26373a2.67632,2.67632,0,0,0,1.42327,3.50254c18.951,5.27213,34.54846,11.85555,47.62381,20.10224a2.67621,2.67621,0,0,0,3.46614-1.43811l6.86518-16.26373a2.67633,2.67633,0,0,0-1.42327-3.50254A215.53,215.53,0,0,0,779.28613,472.62394Z"
      transform="translate(-156 -177.82144)"
      :fill="color"
    />
    <circle cx="619.09426" cy="306.41937" r="7" :fill="color" />
    <path
      d="M759.93343,473.87137l-5.52771-2.33333a1.50165,1.50165,0,0,1-.7986-1.96526l.77778-1.84257a1.50164,1.50164,0,0,1,1.96526-.7986l5.52771,2.33334a1.50164,1.50164,0,0,1,.79859,1.96526l-.77777,1.84257A1.50164,1.50164,0,0,1,759.93343,473.87137Z"
      transform="translate(-156 -177.82144)"
      :fill="color"
    />
    <path
      d="M793.18294,503.10269l-5.52771-2.33333a1.50164,1.50164,0,0,1-.7986-1.96526l.77778-1.84257a1.50165,1.50165,0,0,1,1.96526-.7986l5.52771,2.33334a1.50164,1.50164,0,0,1,.79859,1.96526l-.77777,1.84257A1.50164,1.50164,0,0,1,793.18294,503.10269Z"
      transform="translate(-156 -177.82144)"
      :fill="color"
    />
    <path
      d="M807.47454,496.91253a10.0558,10.0558,0,0,0,2.38568-15.23366l17.70867-31.0384-18.53107,1.19717-13.97544,29.291a10.11027,10.11027,0,0,0,12.41216,15.78384Z"
      transform="translate(-156 -177.82144)"
      fill="#ffb6b6"
    />
    <polygon
      points="787.796 530.684 775.536 530.684 769.704 483.396 787.798 483.397 787.796 530.684"
      fill="#ffb6b6"
    />
    <path
      d="M946.92249,720.38967l-39.53076-.00146v-.5A15.3873,15.3873,0,0,1,922.77821,704.502h.001l24.144.001Z"
      transform="translate(-156 -177.82144)"
      fill="#2f2e41"
    />
    <polygon
      points="703.796 530.684 691.536 530.684 685.704 483.396 703.798 483.397 703.796 530.684"
      fill="#ffb6b6"
    />
    <path
      d="M862.92249,720.38967l-39.53076-.00146v-.5A15.3873,15.3873,0,0,1,838.77821,704.502h.001l24.144.001Z"
      transform="translate(-156 -177.82144)"
      fill="#2f2e41"
    />
    <path
      d="M828.27085,451.97414s-3.37214,48.02131,3.26935,72.2062,7.82743,170.44079,7.82743,170.44079h22.26172l19.29489-199.08529L925.40641,695.3232l21.20442-1.458L934.982,458.44264Z"
      transform="translate(-156 -177.82144)"
      fill="#2f2e41"
    />
    <path
      d="M925.78263,290.09648l-17.01855-12.419L880.24687,283.887l-7.71942,18.34224-13.30152,6.595a30.15158,30.15158,0,0,0-16.44681,22.69232l-19.00293,131.225,111.20579-4.2989,13.46287-154.54282a12.11573,12.11573,0,0,0-11.367-13.1468Z"
      transform="translate(-156 -177.82144)"
      fill="#e4e4e4"
    />
    <polygon
      points="696.189 141.253 685.918 155.099 641.182 291.544 662.152 299.94 695.729 236.359 696.189 141.253"
      fill="#e4e4e4"
    />
    <circle cx="732.96063" cy="57.7951" r="34.03722" fill="#2f2e41" />
    <circle cx="733.42059" cy="68.53439" r="24.83796" fill="#ffb9b9" />
    <path
      d="M908.27905,243.1361c-15.81555-5.7046-21.16736-9.20907-38.63684,0l-6.43945-25.75788H914.7185Z"
      transform="translate(-156 -177.82144)"
      fill="#2f2e41"
    />
    <circle cx="730.20086" cy="23.91805" r="19.31842" fill="#2f2e41" />
    <path
      d="M863.20276,197.13987a19.30232,19.30232,0,0,1,18.39849-19.272c-.307-.0145-.60931-.04643-.91992-.04643a19.31841,19.31841,0,1,0,0,38.63682c.31061,0,.61292-.03193.91992-.04643A19.30234,19.30234,0,0,1,863.20276,197.13987Z"
      transform="translate(-156 -177.82144)"
      fill="#2f2e41"
    />
    <polygon
      points="778.087 149.111 771.157 218.196 746.683 266.431 778.087 149.111"
      opacity="0.1"
    />
    <rect x="39.5" y="361.73676" width="6" height="5" fill="#f0f0f0" />
    <path
      d="M769.0293,544.5582H757.20605v-5H769.0293Zm-23.64649,0H733.55859v-5h11.82422Zm-23.64746,0H709.91211v-5h11.82324Zm-23.64648,0H686.26465v-5h11.82422Zm-23.64746,0H662.61816v-5h11.82325Zm-23.64746,0H638.9707v-5H650.794Zm-23.64649,0H615.32422v-5h11.82324Zm-23.64746,0H591.67676v-5H603.5Zm-23.647,0H568.02979v-5H579.853Zm-23.647,0H544.38232v-5h11.82373Zm-23.647,0H520.73535v-5h11.82373Zm-23.647,0h-11.8237v-5h11.82373Zm-23.647,0H473.44141v-5h11.82373Zm-23.64747,0H449.79443v-5h11.82325Zm-23.647,0H426.14746v-5H437.9707Zm-23.647,0H402.5v-5h11.82373Zm-23.647,0H378.853v-5h11.82373Zm-23.647,0H355.20605v-5h11.82374Zm-23.64746,0H331.55908v-5h11.82324Zm-23.647,0h-11.823v-5h11.82324Zm-23.647,0h-11.823v-5h11.82324Zm-23.647,0H260.61768v-5h11.82373Zm-23.647,0H236.9707v-5h11.82373Zm-23.647,0h-11.8234v-5h11.82373Z"
      transform="translate(-156 -177.82144)"
      fill="#f0f0f0"
    />
    <rect x="39.5" y="234.73675" width="6" height="5" fill="#f0f0f0" />
    <path
      d="M769.0293,417.5582H757.20605v-5H769.0293Zm-23.64649,0H733.55859v-5h11.82422Zm-23.64746,0H709.91211v-5h11.82324Zm-23.64648,0H686.26465v-5h11.82422Zm-23.64746,0H662.61816v-5h11.82325Zm-23.64746,0H638.9707v-5H650.794Zm-23.64649,0H615.32422v-5h11.82324Zm-23.64746,0H591.67676v-5H603.5Zm-23.647,0H568.02979v-5H579.853Zm-23.647,0H544.38232v-5h11.82373Zm-23.647,0H520.73535v-5h11.82373Zm-23.647,0h-11.8237v-5h11.82373Zm-23.647,0H473.44141v-5h11.82373Zm-23.64747,0H449.79443v-5h11.82325Zm-23.647,0H426.14746v-5H437.9707Zm-23.647,0H402.5v-5h11.82373Zm-23.647,0H378.853v-5h11.82373Zm-23.647,0H355.20605v-5h11.82374Zm-23.64746,0H331.55908v-5h11.82324Zm-23.647,0h-11.823v-5h11.82324Zm-23.647,0h-11.823v-5h11.82324Zm-23.647,0H260.61768v-5h11.82373Zm-23.647,0H236.9707v-5h11.82373Zm-23.647,0h-11.8234v-5h11.82373Z"
      transform="translate(-156 -177.82144)"
      fill="#f0f0f0"
    />
    <rect x="39.5" y="107.73675" width="6" height="5" fill="#f0f0f0" />
    <path
      d="M769.0293,290.5582H757.20605v-5H769.0293Zm-23.64649,0H733.55859v-5h11.82422Zm-23.64746,0H709.91211v-5h11.82324Zm-23.64648,0H686.26465v-5h11.82422Zm-23.64746,0H662.61816v-5h11.82325Zm-23.64746,0H638.9707v-5H650.794Zm-23.64649,0H615.32422v-5h11.82324Zm-23.64746,0H591.67676v-5H603.5Zm-23.647,0H568.02979v-5H579.853Zm-23.647,0H544.38232v-5h11.82373Zm-23.647,0H520.73535v-5h11.82373Zm-23.647,0h-11.8237v-5h11.82373Zm-23.647,0H473.44141v-5h11.82373Zm-23.64747,0H449.79443v-5h11.82325Zm-23.647,0H426.14746v-5H437.9707Zm-23.647,0H402.5v-5h11.82373Zm-23.647,0H378.853v-5h11.82373Zm-23.647,0H355.20605v-5h11.82374Zm-23.64746,0H331.55908v-5h11.82324Zm-23.647,0h-11.823v-5h11.82324Zm-23.647,0h-11.823v-5h11.82324Zm-23.647,0H260.61768v-5h11.82373Zm-23.647,0H236.9707v-5h11.82373Zm-23.647,0h-11.8234v-5h11.82373Z"
      transform="translate(-156 -177.82144)"
      fill="#f0f0f0"
    />
    <rect x="186" y="363.23676" width="49" height="180" fill="#3f3d56" />
    <rect x="195" y="363.23676" width="49" height="180" :fill="color" />
    <rect x="302" y="236.23675" width="49" height="307.00002" fill="#3f3d56" />
    <rect x="311" y="236.23675" width="49" height="307.00002" :fill="color" />
    <rect x="418" y="109.23675" width="49" height="434.00002" fill="#3f3d56" />
    <rect x="427" y="109.23675" width="49" height="434.00002" :fill="color" />
    <circle cx="40" cy="110.23675" r="12" fill="#f0f0f0" />
    <circle cx="40" cy="237.23675" r="12" fill="#f0f0f0" />
    <circle cx="40" cy="364.23676" r="12" fill="#f0f0f0" />
    <rect y="542.1164" width="888" height="2.24072" fill="#3f3d56" />
    <path
      d="M905.23611,491.87822l-.08154-.00684c-15.16675-2.50439-32.01514-2.50342-51.50806.00293h-.00781a3.68561,3.68561,0,0,1-3.72925-3.66943V470.55156a3.67762,3.67762,0,0,1,3.67334-3.67334,199.87414,199.87414,0,0,1,51.71484.0039h.00684a3.65914,3.65914,0,0,1,3.605,3.66944v17.65332A3.67762,3.67762,0,0,1,905.23611,491.87822Z"
      transform="translate(-156 -177.82144)"
      :fill="secondColor"
    />
    <path
      d="M905.23611,491.87822l-.08154-.00684c-15.16675-2.50439-32.01514-2.50342-51.50806.00293h-.00781a3.68561,3.68561,0,0,1-3.72925-3.66943V470.55156a3.67762,3.67762,0,0,1,3.67334-3.67334,199.87414,199.87414,0,0,1,51.71484.0039h.00684a3.65914,3.65914,0,0,1,3.605,3.66944v17.65332A3.67762,3.67762,0,0,1,905.23611,491.87822Zm-26.48242-25.6753a184.49964,184.49964,0,0,0-25.10279,1.67041,2.69885,2.69885,0,0,0-2.74145,2.67823v17.65332a2.67632,2.67632,0,0,0,2.67334,2.67334c19.50952-2.5127,36.43945-2.51319,51.69263-.00049a2.67622,2.67622,0,0,0,2.634-2.67285V470.55156a2.67632,2.67632,0,0,0-2.67334-2.67334A215.52784,215.52784,0,0,0,878.75369,466.20292Z"
      transform="translate(-156 -177.82144)"
      :fill="color"
    />
    <circle cx="723.40945" cy="300.7141" r="7" :fill="color" />
    <path
      d="M861.40945,474.87822h-6a1.50164,1.50164,0,0,1-1.5-1.5v-2a1.50164,1.50164,0,0,1,1.5-1.5h6a1.50164,1.50164,0,0,1,1.5,1.5v2A1.50164,1.50164,0,0,1,861.40945,474.87822Z"
      transform="translate(-156 -177.82144)"
      :fill="color"
    />
    <path
      d="M903.40945,488.87822h-6a1.50164,1.50164,0,0,1-1.5-1.5v-2a1.50164,1.50164,0,0,1,1.5-1.5h6a1.50164,1.50164,0,0,1,1.5,1.5v2A1.50164,1.50164,0,0,1,903.40945,488.87822Z"
      transform="translate(-156 -177.82144)"
      :fill="color"
    />
    <path
      d="M893.99376,468.7379a10.05579,10.05579,0,0,1,11.10029-10.70231l15.23759-32.32328,9.6632,15.85737-15.95135,28.26363a10.11027,10.11027,0,0,1-20.04973-1.09541Z"
      transform="translate(-156 -177.82144)"
      fill="#ffb6b6"
    />
    <path
      d="M935.42186,299.6605l6.27559-8.18388s15.29017-3.11417,17.88721,17.88722l-1.63525,93.36539L924.4436,455.4659l-21.761-5.55612,28.451-62.64745Z"
      transform="translate(-156 -177.82144)"
      fill="#e4e4e4"
    />
  </svg>
</template>

<script>
import undraw from "@/components/icons/undraw/undraw-mixin.js";

export default {
  mixins: [undraw],
};
</script>