export const MUTATION_PREFIX = 'CONFIG_DEFINITION: ';
export const ACTIONS_PREFIX = 'CONFIG_DEFINITION_ACTIONS_';
export const GETTERS_PREFIX = 'CONFIG_DEFINITION_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    GET_ALL_CONFIGS_DEFINITION_SUCCESS: MUTATION_PREFIX + 'GET_ALL_CONFIGS_DEFINITION_SUCCESS',
    GET_ALL_CONFIGS_AVAILABLE_SUCCESS: MUTATION_PREFIX + 'GET_ALL_CONFIGS_AVAILABLE_SUCCESS',
    GET_CONFIGS_DEFINITION_SUCCESS: MUTATION_PREFIX + 'GET_CONFIGS_DEFINITION_SUCCESS',
    GET_CONFIG_DEFINITION_SUCCESS: MUTATION_PREFIX + 'GET_CONFIG_DEFINITION_SUCCESS',
    RESET_CONFIG_DEFINITION_SELECTED: MUTATION_PREFIX + 'RESET_CONFIG_DEFINITION_SELECTED',
    SET_CONFIG_DEFINITION_EDITED: MUTATION_PREFIX + 'SET_CONFIG_DEFINITION_EDITED',
    RESET_CONFIG_DEFINITION_EDITED: MUTATION_PREFIX + 'RESET_CONFIG_DEFINITION_EDITED',

    FIND_CONFIG_PERMISSION_SUCCESS: MUTATION_PREFIX + 'FIND_CONFIG_PERMISSION_SUCCESS',
    RESET_CONFIG_PERMISSION_LIST: MUTATION_PREFIX + 'RESET_CONFIG_PERMISSION_LIST',
    SET_CHANGED_CONFIGS: MUTATION_PREFIX + 'SET_CHANGED_CONFIGS',
    RESET_CHANGED_CONFIGS: MUTATION_PREFIX + 'RESET_CHANGED_CONFIGS',

    SET_CONFIG_DEFINITION_OPTIONS_MENU: MUTATION_PREFIX + 'SET_CONFIG_DEFINITION_OPTIONS_MENU',
  },
  ACTIONS: {
    GET_ALL_CONFIGS_DEFINITION: ACTIONS_PREFIX + 'GET_ALL_CONFIGS_DEFINITION',
    GET_CONFIGS_DEFINITION: ACTIONS_PREFIX + 'GET_CONFIGS_DEFINITION',
    GET_ALL_CONFIGS_AVAILABLE: ACTIONS_PREFIX + 'GET_ALL_CONFIGS_AVAILABLE',
    GET_CONFIG_DEFINITION: ACTIONS_PREFIX + 'GET_CONFIG_DEFINITION',
    SAVE_CONFIG_DEFINITION: ACTIONS_PREFIX + 'SAVE_CONFIG_DEFINITION',
    DELETE_CONFIG_DEFINITION: ACTIONS_PREFIX + 'DELETE_CONFIG_DEFINITION',
    COPY_CONFIG_DEFINITION: ACTIONS_PREFIX + 'COPY_CONFIG_DEFINITION',

    SAVE_CONFIG_PERMISSION: ACTIONS_PREFIX + 'SAVE_CONFIG_PERMISSION',
    FIND_CONFIG_PERMISSION: ACTIONS_PREFIX + 'FIND_CONFIG_PERMISSION',
    DELETE_CONFIG_PERMISSION: ACTIONS_PREFIX + 'DELETE_CONFIG_PERMISSION',
    SAVE_CHANGED_CONFIGS: ACTIONS_PREFIX + 'SAVE_CHANGED_CONFIGS',

    FIND_CONFIG_DEFINITION_OPTIONS_MENU: ACTIONS_PREFIX + 'FIND_CONFIG_DEFINITION_OPTIONS_MENU',
    IMPORT_CONFIGS: ACTIONS_PREFIX + 'IMPORT_CONFIGS',
  },
  GETTERS: {
    ALL_CONFIGS_DEFINITION: GETTERS_PREFIX + 'ALL_CONFIGS_DEFINITION',
    CONFIGS_DEFINITION: GETTERS_PREFIX + 'CONFIGS_DEFINITION',
    ALL_CONFIGS_AVAILABLE: GETTERS_PREFIX + 'ALL_CONFIGS_AVAILABLE',
    CONFIG_DEFINITION_SELECTED: GETTERS_PREFIX + 'CONFIG_DEFINITION_SELECTED',
    CONFIG_DEFINITION_EDITED: GETTERS_PREFIX + 'CONFIG_DEFINITION_EDITED',

    CONFIG_PERMISSION_LIST: GETTERS_PREFIX + 'CONFIG_PERMISSION_LIST',
    CHANGED_CONFIGS: GETTERS_PREFIX + 'CHANGED_CONFIGS',
    HAS_CHANGED_CONFIGS: GETTERS_PREFIX + 'HAS_CHANGED_CONFIGS',

    CONFIG_DEFINITION_OPTIONS_MENU: GETTERS_PREFIX + 'CONFIG_DEFINITION_OPTIONS_MENU',
  },
}
