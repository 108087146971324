export const MUTATION_PREFIX = 'CUSTOMER: ';
export const ACTIONS_PREFIX = 'CUSTOMER_ACTIONS_';
export const GETTERS_PREFIX = 'CUSTOMER_GETTERS_';

export default {
  MUTATIONS: {
    TEMPLATE: MUTATION_PREFIX + 'TEMPLATE',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    TEMPLATE: ACTIONS_PREFIX + 'TEMPLATE',
    SEND_CUSTOMER_COMPLAINT: ACTIONS_PREFIX + 'SEND_CUSTOMER_COMPLAINT',

  },
  GETTERS: {
    TEMPLATE: GETTERS_PREFIX + 'TEMPLATE',
  }
}