import LOADING_TYPES from './types';
import { LOADING_STATE, } from './index';

export default {

  [LOADING_TYPES.GETTERS.IS_LOADING](state) {
    return (id) => id in state.loading && state.loading[id] !== LOADING_STATE.STOPPED;
  },

  [LOADING_TYPES.GETTERS.IS_INITIAL_LOADING](state) {
    return (id) => id in state.loading && state.loading[id] === LOADING_STATE.INITIAL;
  },

}
