var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Vermittlerwechsel Status Übersicht" }
      }),
      _vm.gesellschaften && _vm.gesellschaften.length
        ? _c("BaseFilter", {
            attrs: {
              filterId: "37451699-a251-4bfa-87ef-214acc7c37a6",
              title: "Vermittlerwechsel Status filtern",
              metadata: _vm.filterMetadata,
              configFilter: _vm.configFilter,
              defaultOptions: _vm.defaultOptionsData
            },
            on: {
              onFilter: function($event) {
                return _vm.filterForm($event)
              }
            }
          })
        : !_vm.gesellschaften || !_vm.gesellschaften.length
        ? _c("GhostLoading", { attrs: { type: "custom" } })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "8d199f94-18f2-445e-b934-515fba3ccf84" }
        },
        [
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "95b30d63-2bd2-4d05-bca1-a9ffab9d9828",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.tableHeaders,
                  rows: _vm.tableRows,
                  rowsPerPage: 20,
                  count: _vm.resultCount
                },
                on: {
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "status",
                      fn: function(row) {
                        return [
                          _c("ComboBox", {
                            attrs: { value: row.status, values: _vm.statuses },
                            on: {
                              change: function($event) {
                                return _vm.updateStatus(row, $event)
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "depotnummer",
                      fn: function(row) {
                        return [
                          _c("a", { attrs: { href: _vm.getPDFLink(row) } }, [
                            _vm._v(_vm._s(row.depotnummer))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2600254863
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "removeItemConfirmation",
          attrs: {
            modalTitle: "Eintrag löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeItem()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.deleteMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }