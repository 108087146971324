<template>
    <div>
        <div v-if="params" class="box__container">                
            <div>
                <BaseButton :disabled="pdfDisabled || loading || loadingPDF" :animated="loadingPDF" @click="createPdf">PDF anzeigen</BaseButton>
            </div>
            <div v-if="params">
                <HtmlEditor
                    v-model="html"
                    isFontParams
                    isPreviewButton
                    :disabled="loadingPDF"
                    :textPlaceholderOptions="placeholdersOptions"
                    :previewHTML="previewHtml.text"
                    @onTogglePreviewEnabled="handlePreview">
                </HtmlEditor>

                <div class="checkbox-input__container mt-2">
                    <input-check-box-item v-model="params.showBezeichnung" :disabled="loadingPDF" label='Die Bezeichnung auf dem Deckblatt andrucken'></input-check-box-item>
                    <input-check-box-item v-model="params.showDetailsChart" :disabled="loadingPDF" label='Wertentwicklung einzelner Fonds anzeigen'></input-check-box-item>
                    <input-check-box-item v-model="params.detailsRelativ" :disabled="loadingPDF || hatPlan" label='Fondsdetails in Relation zur Anlagesumme'></input-check-box-item>
                    <input-check-box-item v-model="params.anonymisieren" :disabled="loadingPDF" label='Anonymisieren'></input-check-box-item>
                </div>

                <h5 class="box__title mt-3">Fondsdokumente</h5>
                <div class="checkbox-input__container">
                    <input-check-box-item v-model="params.showFactsheets" :disabled="loadingPDF" label='Factsheets im Anhang'></input-check-box-item>
                    <input-check-box-item v-model="params.showKIDs" :disabled="loadingPDF" label='Basisinformationsblätter (PRIIP-BIBs) im Anhang'></input-check-box-item>
                    <input-check-box-item v-model="params.showDocuments" :disabled="loadingPDF" label='Links zu Berichten, Verkaufsprospekten, PRIIP-BIBs und Videospots'></input-check-box-item>
                    <input-check-box-item v-model="params.inclactual" label='zus. Fondsdokumente für bestehendes Depot' :disabled="loadingPDF || !params.inclactualEnabled"></input-check-box-item>
                </div>            
            </div>
        </div>
        <BaseModal ref="infoModal" :showDefaultButtons="false">
            <template v-slot:modalTitle>
                <PhFile style="color: var(--color-success)"/> Anlageempfehlung PDF
            </template>
            <div>
                <span>'Das Dokument konnte nicht geladen werden'</span>
            </div>
        </BaseModal>
    </div>      
</template>
<script>
import StepMixin from "./StepMixin";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import BaseButton from '@/components/core/BaseButton.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import HtmlEditor from "@/components/html-editor/HtmlEditor.vue";
import {sanitize} from '@/helpers/string-helper.js';
import { mapGetters } from 'vuex'
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { buildMessage } from "@/helpers/log-message-helper";
import LOG_TYPES from '@/store/log/types';

export default {
    components: {
        BaseButton,
        InputCheckBoxItem,
        BaseModal,
        HtmlEditor,
    },
    props: {
        fromEmail: {
            type:Boolean,
            default: false
        }
    },    
    data() {
        return {
            step: INVESTMENT_ADVICE.STEPS.PDF,
            params: undefined,
            html: '',
            anlage: false,
            performanceAnnualisiert: true,
            hatPlan: false,
            loadingPDF: false,
        }
    },
    mixins: [StepMixin],
    computed: {
        ...mapGetters({
            placeholders: MAILCOMPOSER_TYPES.GETTERS.GET_PLACEHOLDERS,
            previewHtml: MAILCOMPOSER_TYPES.GETTERS.GET_PREVIEW,
            senders: MAILCOMPOSER_TYPES.GETTERS.GET_SENDERS,
        }),
        pdfDisabled() {
            return !this.stepParameters?.data || !this.stepParameters?.loaded ;
        },
        placeholdersOptions() {
            const keys = this.placeholders && Object.keys(this.placeholders)
            return this.placeholders && Object.values(this.placeholders).map((v, i) => ({
                name: keys[i],
                data: v.map(p => ({
                        value: Object.values(p)[0],
                        label: Object.keys(p)[0]
                    })
                )
            })) || []
        },
        pdfQueryParameters() {
            return {
                ...this.params,
                introduction: encodeURIComponent(this.html),
                adviceId: this.id,
            }
        },
    },
    watch: {
        params: {
            handler(values) {
                if (values.introduction || !this.html) {
                    this.html = values.introduction;
                }
                if (!values.showDetailsChart) {
                    values.detailsRelativ = false;
                }
            },
            deep: true
        },        
        html(value) {
            this.params.introduction = value;
        }
    },
    mounted() {
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_SENDERS);
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS, {});
        // this.setAnlage();
    },
    methods: {
        parametersChanged(parameters) {
            if (!this.params && parameters && parameters.data && parameters.data.parameterData) {
                this.params = parameters.data.parameterData;
                if (parameters.data.hatPlan != null) {
                    this.hatPlan = parameters.data.hatPlan
                    if (this.hatPlan) {
                        this.params.detailsRelativ = false;
                    }
                }
                if (parameters.data.performanceAnnualisiert != null) {
                    this.performanceAnnualisiert = parameters.data.performanceAnnualisiert
                }
                
            }
        },
        async createPdf() {
            this.loadingPDF = true
            const payload = {
                adviceId: this.id,
                params: this.pdfQueryParameters
            }

            try {
                const result = await this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.CREATE_PDF, payload);
                
                if (this.fromEmail) {
                    this.$emit('createPDF', {dokId: result.data?.dokId, created: result.data?.created, url: result.data?.url});
                }
                if (result.data?.url) {
                    // viewDocument({ href: result.data.url, filename: 'Anlageempfehlung.pdf', contentType: 'application/pdf', });
                    window.open(result.data.url, '_blank');
                } else if (result.data?.pdf) {
                    viewDocument({ data: result.data.pdf, filename: 'Anlageempfehlung.pdf', contentType: 'application/pdf', });
                }
                if (result.data?.info) {
                    this.$store.dispatch( LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(result.data.info) );
                }
            } finally {
                this.loadingPDF = false
            }

        },
        handlePreview(value) {
          if (value) {
            const senders = this.senders.filter(v => v.email === this.sender);
            const sender = senders && senders[0] || this.senders && this.senders[0];
            this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PREVIEW, {
              subject: '',
              text: this.html,
              modus: 'ONE_MAIL',
              confidential: false,
              userTypeSender: sender.user && sender.user.type,
              userIdSender: sender.user && sender.user.userId,
              emailSender: sender.email,
              participants: [],
              paramTextAlredyIncluded: true,
              briefInput: {
                queryFormat: false,
                hintergruend: false,
                vorlageTyp: 'BRIEF',
                worldId: null,
                fileName: '1',
                subject: '',
                html: this.html,
                saveInArchive: false,
              },
            });
          }
        },
        sanitize(htmlString) {
            return sanitize(htmlString);
        },
    },
}
</script>
<style scoped>
.checkbox__container {
    justify-content: start
}
.div-textarea {
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 2px;
    padding: 0.65rem 1.25rem;   
    height: 370px; 
    overflow: scroll;
    margin-top: 16px;
    margin-bottom: 16px;
}
.d-right {
    float: right;
    clear: both;
}
.hidden {
    display: none;
}
.nowrap {
    white-space: nowrap;
    padding: 0;
}
</style>