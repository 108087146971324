<template>
  <div>
    <LoginNeeded :selectedAccounts="selectedAccounts"/>

    <MultiBankingNavigation>
      <template #subtitle>
        <span>{{ title }}</span>
        <span class="ml-2"><ph-warning v-if="showWarningAuthNeeded" :size="18" /></span>
      </template>
    </MultiBankingNavigation>

    <MultiBankingFilter v-if="showTransactions" filterId="5ddbe7f0-ebea-4a4c-8070-ddf8fd0db545" :accountId="accountIdParam" />

    <div v-if="showTransactions">
      <KontoverlaufGraph 
        :accountId="selectedAccountId" 
        :category="selectedCategory" 
        :currentBalance="currentBalance"
        hideDepotPositions/>
    </div>
    <NoMultibankingWarning v-else/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import KontoverlaufGraph from '@/components/multiBanking/KontoverlaufGraph.vue'
import NoMultibankingWarning from '@/components/multiBanking/NoMultibankingWarning.vue'
import { formatNumber } from '@/helpers/number-formatter'
import LoginNeeded from '@/components/multiBanking/LoginNeeded.vue'
import { PhWarning } from 'phosphor-vue'
import MultiBankingNavigation from '@/components/multiBanking/MultiBankingNavigation.vue';
import MultiBankingFilter from '@/components/multiBanking/MultiBankingFilter.vue';

export default {
  components: {
    KontoverlaufGraph,
    NoMultibankingWarning,
    LoginNeeded,
    PhWarning,
    MultiBankingNavigation,
    MultiBankingFilter,
  },

  props: {
    /*accountId: {
      default: null,
    },*/
  },

  data () {
    return {
      selectedTransactionId: "" + (this.$route.query.transactionId || ""),
      selectedCategory: this.$route.query.category || '',
    }
  },

  computed: {
    ...mapGetters({
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      brokerData: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      transactions: MULTIBANKING_TYPES.GETTERS.TRANSACTIONS,
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      accountsSum: MULTIBANKING_TYPES.GETTERS.ACCOUNTS_SUM,
      dailyAccountsHistory: MULTIBANKING_TYPES.GETTERS.DAILY_ACCOUNT_HISTORY,
      isMultibankingAccountUser: CORE_TYPES.GETTERS.IS_MULTIBANKING_ACCOUNT_USER,
      isUserAllowedToGetMultibankingData: CORE_TYPES.GETTERS.IS_ALLOWED_TO_GET_MULTIBANKING_DATA,
      userSettings: MULTIBANKING_TYPES.GETTERS.USER_SETTINGS,
      filters: MULTIBANKING_TYPES.GETTERS.FILTERS,
    }),
    accountIdParam() {
      return this.$route?.query?.accountId || '';
    },
    showWarningAuthNeeded() {
      const accountsToCheck = this.selectedAccounts ? this.selectedAccounts : this.accounts
      return accountsToCheck?.filter(account => account.authNeeded).length 
    },
    selectedAccountId() {
      return this.filters?.accountId || '';
    },
    selectedAccountIds() {
      return this.selectedAccountId ? [this.selectedAccountId] : null
    },
    selectedAccounts() {
      if (this.accounts && this.selectedAccountIds != null) {
        const result = this.accounts.filter(acc => this.selectedAccountIds.indexOf(acc.externalId) >= 0)
        if (result.length > 0)
          return result
      }
      return null
    },
    currentBalance () {
      if (this.selectedAccounts) {
        return this.selectedAccounts.reduce((sum, account) => sum + account.totalAmount, 0)
      }
      return this.accounts?.filter?.(account => account.accountType !== 'DEPOT')
        .reduce((sum, account) => sum + account.totalAmount, 0) || 0;
    },
    title() {
      return this.showTransactions ? `aktueller Kontostand: ${formatNumber(this.currentBalance)} €` : 'Kontoverlauf'
    },
    showTransactions() {
      return this.isMultibankingAccountUser && (!this.isBrokerOrBypass || this.userSettings?.shareAccounts) 
    },
    accountsFilterOptions() {
      return [
        { value: '', label: 'Alle Bankkonten', externalId: '' },
        ...this.accounts?.map(acc => ({ value: acc.externalId, label: acc.displayName, externalId: acc.externalId }))
      ]
    },
  },
}
</script>

<style scoped>
.kontoverlauf__account-filter {
  flex-grow: 1; 
  align-self: center;
}
</style>