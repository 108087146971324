var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _vm.isBroker || _vm.isIntern
          ? _c(
              "div",
              { staticClass: "text-small edit", on: { click: _vm.edit } },
              [
                _vm._v(" bearbeiten "),
                _c("ph-pencil-line", {
                  attrs: { title: "Bearbeiten", size: 16 }
                })
              ],
              1
            )
          : _vm._e(),
        _c("div", { attrs: { id: "innercontent" } })
      ]),
      _c(
        "BaseModal",
        {
          ref: "EditableImageOnly",
          attrs: { modalTitle: "Bild hochladen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "markup_class" },
            [_c("ImagePicker", { attrs: { type: _vm.getType } })],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }