var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "uploadModal",
          attrs: {
            modalTitle: "Datei hochladen",
            confirmDisabled: !_vm.canUpload,
            showDefaultButtons: false
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: { disabled: !_vm.fileList.length },
                      on: {
                        click: function($event) {
                          return _vm.doUploadAndSave()
                        }
                      }
                    },
                    [_vm._v("Hochladen und speichern")]
                  ),
                  _vm.fileList.length &&
                  _vm.selectedCategory &&
                  _vm.selectedCategory.istWeiterleitungMoeglich
                    ? _c(
                        "BaseButton",
                        {
                          on: {
                            click: function($event) {
                              return _vm.doUploadAndContinue()
                            }
                          }
                        },
                        [_vm._v("Speichern und neu Hochladen")]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm.additionalInputs
              ? _c(
                  "div",
                  { staticClass: "grid" },
                  [
                    _vm.uploadType != _vm.upload_Type.FROM_DOKUMENTENARCHIV &&
                    _vm.cameraAvailable &&
                    _vm.allowFotoUpload
                      ? _c(
                          "FotoButton",
                          {
                            staticClass: "mb-1",
                            staticStyle: { float: "left" },
                            attrs: {
                              isSecondary: "",
                              edgeCorrection: "",
                              highQuality: "",
                              camera: "back"
                            },
                            on: { file: _vm.onFoto }
                          },
                          [_vm._v(" Fotografieren ")]
                        )
                      : _vm._e(),
                    _vm.multipleFileUpload
                      ? _c("MultiFileSelect", {
                          attrs: {
                            disabled:
                              _vm.uploadType ==
                              _vm.upload_Type.FROM_DOKUMENTENARCHIV,
                            maxFileSizeMB: _vm.maxFileSizeMB,
                            accept: _vm.accept
                          },
                          on: { input: _vm.dropFiles },
                          model: {
                            value: _vm.fileList,
                            callback: function($$v) {
                              _vm.fileList = $$v
                            },
                            expression: "fileList"
                          }
                        })
                      : _c("BaseFileSelect", {
                          attrs: {
                            disabled:
                              _vm.uploadType ==
                              _vm.upload_Type.FROM_DOKUMENTENARCHIV,
                            accept: _vm.accept
                          },
                          on: { files: _vm.dropFiles }
                        }),
                    _vm.additionalInputs.length
                      ? _c("UploadInputList", {
                          attrs: { inputs: _vm.additionalInputs },
                          on: { input: _vm.setSelectedCategory },
                          model: {
                            value: _vm.formData,
                            callback: function($$v) {
                              _vm.formData = $$v
                            },
                            expression: "formData"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }