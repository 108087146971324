<template>
  <div>
    <div v-for="(sparte, index) of spartenForm" :key="index" style="margin-bottom: 8px;">
      <InputToggleSwitch
        class="mt-2"
        :value="selection[sparte.stepKey]"
        :label="sparte.label" inLineLabel suppressValidationMessage
        :disabled="getDisableRule(sparte.stepKey, sparte.config && sparte.config.primary)"
        @input="selectionChanged(sparte.stepKey, $event)"
      />
    </div>

    <BaseButton @click="neueSparteAnlegen" v-if="canAddNewSparten">
      Neue Sparte anlegen
    </BaseButton>

    <AddSparte  ref="addSparteModal"/>

  </div>
</template>


<script>
import InputField from "@/components/core/forms/InputField.vue";
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import AddSparte from "@/views/versicherungen/AddSparte.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import { ROLES } from '@/router/roles.js'
import { mapGetters } from 'vuex';
import CORE_TYPES from "@/store/core/types";

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    InputToggleSwitch,
    InputField,
    AddSparte,
    BaseButton
},
  props: {
    spartenSteps: {
      type: Array,
      default: () => []
    },
    spartenValues: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      selection: {},
      primarySparte: null,
    }
  },
  mounted() {
    this.selection = {};
    this.spartenSteps?.forEach(sparte => {
        this.$set(this.selection, sparte.stepKey, this.hasSparte(this.spartenValues, sparte));
        if (sparte.config?.primary) {
          this.primarySparte = sparte;
        }
    });
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),    
    spartenForm() {
      return (this.spartenSteps?.map(step => {
        const values = this.spartenValues?.find(sp => sp.sparteId === step.stepKey) || {};
        return Object.assign({}, step, { selected: this.hasSparte(this.spartenValues, step), onlyActivTarif: true }, values)
      }) || [])
      .sort((a,b) => {
        if (a.stepKey === this.primarySparte?.stepKey) {
          return -1;
        } else if (b.stepKey === this.primarySparte?.stepKey) {
          return 1;
        } else if (a.selected && b.selected || !a.selected && !b.selected) {
          return (a.label || '').localeCompare(b.label)
        } else {
          return a.selected ? -1 : 1;
        }
      });
    },
    getSelection() {
      return this.selection;
    },
    vertragId() {
        return this.$route?.params?.id;
    },
    canAddNewSparten() {
      return this.hasRoles(ROLES.CAN_ADD_NEW_SPARTEN);
    }
  },
  methods: {
    selectionChanged(sparteId, value) {

      let promise = Promise.resolve();
      const schadensmeldungenFiltered = this.versicherungenDetails?.schadenInfos?.filter(schaden => sparteId === schaden.spartenTypId);
      if(sparteId && value == false && schadensmeldungenFiltered?.length) {
        promise = this.$confirmModal({
          message: `Sie haben die Sparte deaktiviert, beim Speichern werden alle eingetragenen Daten - auch sämtliche dazugehörige Schadensdaten - gelöscht.\n
          Diese Aktion ist unumkehrbar!`,
          title: 'Hinweis',
          labelButtonConfirm: 'Trotzdem speichern'
        });
      }

      this.$set(this.selection, sparteId, value)

      promise.then(() => {
        this.$emit('changeStepVisibility', { sparteId: sparteId, value: value });
        this.selection = Object.assign({}, this.selection, { [sparteId]: value });
        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.UPDATE_STORE_SELECTED_SPARTEN, { id: sparteId, value: value })
      }).catch(result => {
        this.$set(this.selection, sparteId, !value)
      });
      
    },
    neueSparteAnlegen() {
        this.$refs.addSparteModal.open(this.vertragId)
    },
    getDisableRule(sparteKey, isPrimary) {
      //if the sparte key is enabled and it is the only one, must to disable edition otherwise there will not be any main category for the insurance
      return this.selection[sparteKey] && (isPrimary || Object.values(this.selection).filter(value => !!value).length === 1);
    }
  }
};
</script>

<style scoped>
</style>