import RETIREMENT_SCENARIO_TYPES from './types';
import axios from 'axios';

export default {
  [RETIREMENT_SCENARIO_TYPES.ACTIONS.GET_SCENARIOS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      ...payload ? {
        params: {
          retirementScenarioId: payload
        }
      } : {}
    };
    let url = 'mrsretirementscenario';
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(RETIREMENT_SCENARIO_TYPES.MUTATIONS.GET_SCENARIOS_SUCCESS, response.data);
      }
    }).catch(error => {
      commit(RETIREMENT_SCENARIO_TYPES.MUTATIONS.GET_SCENARIOS_FAILURE);
    })
  },
  [RETIREMENT_SCENARIO_TYPES.ACTIONS.DELETE_SCENARIO]({ commit, state, dispatch, rootState }, payload) {
    const retirementScenarioId = payload;
    const config = {
      defaultSpinner: true,
      ...retirementScenarioId ? {
        params: {
          retirementScenarioId
        }
      } : {}
    };
    let url = 'mrsretirementscenario';
    return axios.delete(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(RETIREMENT_SCENARIO_TYPES.MUTATIONS.DELETE_SCENARIO_SUCCESS, response.data);
      }
      dispatch(RETIREMENT_SCENARIO_TYPES.ACTIONS.GET_SCENARIOS)
    })
  },
  [RETIREMENT_SCENARIO_TYPES.ACTIONS.SET_SCENARIO]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };
    let url = 'mrsretirementscenario';
    const {asserts, ...retirementScenarioStandalone} = payload
    axios.post(rootState.core.apiAddress + '/../' + url, {retirementScenarioStandalone, asserts}, config).then(response => {
      if (response && response.data) {
        commit(RETIREMENT_SCENARIO_TYPES.MUTATIONS.SET_SCENARIO_SUCCESS, response.data);
        dispatch(RETIREMENT_SCENARIO_TYPES.ACTIONS.GET_SCENARIOS)
      }
    })
  },
  [RETIREMENT_SCENARIO_TYPES.ACTIONS.GET_PERSONAL_DATA]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };
    let url = 'mrscustomerdata';
    axios.get(rootState.core.apiAddress + '/../' + url, {retirementScenarioStandalone: payload}, config).then(response => {
      if (response && response.data) {
        commit(RETIREMENT_SCENARIO_TYPES.MUTATIONS.GET_PERSONAL_DATA_SUCCESS, response.data);
      }
    })
  },
}
