import EXPOST_TYPES from './types';

export default {
  [EXPOST_TYPES.GETTERS.GET_CONFIG](state) {
    return state.config;
  },
  [EXPOST_TYPES.GETTERS.GET_LIST](state) {
    return state.list;
  },
  [EXPOST_TYPES.GETTERS.BERECHNUNG](state) {
    return state.berechnung;
  },
}