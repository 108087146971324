<template>
  <div :style="style">
    <v-chart class="chart" :option="option" :theme="themeKey" autoresize @click="handleChartClick" />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { INIT_OPTIONS_KEY } from "vue-echarts";

import echartsMixin from './echarts-mixin';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: 'PieChart',
  mixins: [echartsMixin],
  components: {
    VChart
  },
  provide: {
    [INIT_OPTIONS_KEY]: { locale: 'DE' }
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    doughnut: {
      type: Boolean,
      default: false,
    }, 
    radiusProp: {
      type: Array,
      default: () => ['40%', '70%']
    }
  },
  data() {
    return {  
    };
  },
  computed: {
    checkedChartData() {
      let checkedChartData = this.chartData
      if (checkedChartData) {
        checkedChartData = checkedChartData.map(data => ({ ...data, value: Math.abs(data.value)}))
      }

      if (!checkedChartData.some(data => data.value)) {
        return []
      }

      return checkedChartData
    },
    option() {
      return {
        color: this.customColors || this.themeColors,
        backgroundColor: 'transparent',
        title: {
          show: false,
        },
        tooltip: {
          show: this.isTooltip,
          formatter: this.tooltipFormatter,
          position: { left: 'center', top: 'auto'},
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: '',
            type: 'pie',
            ...(this.radius && { radius: this.radius }),
            data: this.checkedChartData,
            label: {
              show: false,
            },
          }
        ],
      }
    },
    radius() {
      if (this.doughnut) {
        return  this.radiusProp;
      }
      return null;
    }
  },
  methods: {
    handleChartClick(e) {
      this.$emit('clickchart', e?.data?.name)
    }
  }
};
</script>
