<template>
  <div class="box__container">
    <div class="box__title">Vorlieben</div>
    <div class="row">
      <div class="col-12">
        <div v-if="isEditable" class="mb-8px">
          <ComboBox 
            :isComponentHalfSize="true"
            :values="customerDataConfig.vorlieben" 
            v-model="vorliebenCombo" 
            :sortComboboxValues="false" 
            @change="resetVorliebenField()" />
          <InputField 
            :isComponentHalfSize="true" 
            v-model="vorliebenField" 
            @input="resetVorliebenCombo()" />
          <BaseButton :disabled="!isValid" @click="addVorlieben()">Hinzufügen</BaseButton>
        </div>

        <div>
            <Table v-if="rows.length"
                tableId="8d815554-d015-4b7a-bab0-7479d125db2c"
                :headers="headers"
                :rows="rows"
                :rowsPerPage="10"
                rowId="vorlieben"
                @action-DELETE="removeVorlieben($event.vorlieben)"
            />
            <NoData v-else noIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import NoData from '@/components/core/NoData.vue';

import persoenlicheDatenMixin from '@/components/persoenliche-daten/persoenliche-daten-mixin';


export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
        ...this.initialState(),
        vorliebenCombo: '',
        vorliebenField: '',
        headers: {
            lockedLeft: [
                TextColumn("vorlieben", "Vorlieben"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ]
        },
    };
  },
  computed: {
    rows() {
        const actions = [
            SimpleAction("DELETE", 'PhTrash', "Löschen"),
        ];
        return (this.vorlieben || []).map(vorlieben => ({
            vorlieben,
            actions: this.isEditable ? actions : null,
        }))
    },
    currentValue() {
      return (this.vorliebenCombo || this.vorliebenField || '').trim();
    },
    isValid() {
      return !!this.currentValue;
    },
  },
  methods: {
    initialState() {
      return {
        vorlieben: [],
      };
    },
    resetVorliebenField() {
      this.vorliebenField = '';
    },
    resetVorliebenCombo() {
      this.vorliebenCombo = '';
    },
    updateGroupsIntoData() {
      this.updateListIntoData('vorlieben');
    },
    addVorlieben() {
      const vorliebenValue = this.currentValue;
      if (vorliebenValue && this.vorlieben.findIndex(v => v?.toLowerCase?.() === vorliebenValue?.toLowerCase?.()) < 0) {
        this.vorlieben.unshift(vorliebenValue);
        this.addCustomerDataEdited('vorlieben', this.vorlieben);
      }

      this.resetVorliebenField();
      this.resetVorliebenCombo();
    },
    removeVorlieben(vorlieben) {
      const i = this.vorlieben.findIndex((v)=> v === vorlieben);
      this.vorlieben.splice(i, 1);
      this.addCustomerDataEdited('vorlieben', this.vorlieben);
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  components: {
    Table,
    InputField,
    ComboBox,
    BaseButton,
    NoData,
  },
}
</script>
