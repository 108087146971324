var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isLoaded
    ? _c(
        "div",
        { staticClass: "event-body" },
        [
          _c(
            "BaseModal",
            {
              ref: "modal",
              attrs: {
                labelButtonCancel: "Schließen",
                showConfirmButton: false,
                size: "full"
              },
              on: { onCancelButton: _vm.close },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return [_vm._v(" Log ")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2740538706
              )
            },
            [
              _c(
                "BoxContainer",
                [
                  _c("InputTextArea", {
                    attrs: { rows: 12, hideScrollBar: true, autoGrow: true },
                    model: {
                      value: _vm.dat,
                      callback: function($$v) {
                        _vm.dat = $$v
                      },
                      expression: "dat"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }