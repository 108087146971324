<template>
<div :tid="_generateTidFromString('cards__container layout__negative-margin--8')" >
    <Table
        tableId="e91ac4a9-6761-429c-9190-58e6c3c9cb49"
        title="Kontakte"
        :headers="headers"
        :rows="rows"
        rowId="id"
        :rowsPerPage="20"
        :selected="selectedRowsValues"
        @selected="selectedRows"
    />
</div>
</template>

<script>
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";

export default {
    mixins: [InteractiveHelpCommonsMixin],
    components: {
        Table,
    },
    props: {
        records: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            recordsAp: [],
            canEdit: false,
            selectedRowsValues: [],
            headers: {
                lockedLeft: [
                    TextColumn("fullName", "Name"),
                    TextColumn("email", "E-Mail").addCellProps({ lineClamp: 2, }).makeAlwaysVisible(),
                ],
                center: [
                    TextColumn("typ", "Typ"),
                    TextColumn("telefon", "Telefon"),
                    TextColumn("mobil", "Mobil"),
                    TextColumn("zweigstelle", "Zweigstelle"),
                ],
            },
        }
    },
    computed: {
        rows() {
            return this.records?.map?.((row, index) => ({
                ...row,
                index,
                id: row?.user?.userId || index,
            })) || [];
        }
    },
    methods: {
        selectedRows(rows) {
            this.selectedRowsValues = rows;
            this.$emit('selectedRows', this.selectedRowsValues);
        },
    },
}
</script>