var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: "Spezialfeiertage",
      confirmDisabled: !_vm.form.bezeichnung,
      confirmLoading: _vm.loading,
      autoClose: false,
      size: "sm"
    },
    on: {
      onConfirmButton: function($event) {
        return _vm.save()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("InputField", {
              attrs: {
                label: "Bezeichnung",
                type: "text",
                autofocus: "",
                validateUntouched: ""
              },
              model: {
                value: _vm.form.bezeichnung,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bezeichnung", $$v)
                },
                expression: "form.bezeichnung"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }