var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: "SMS" } }),
      _vm.defaultOptions
        ? _c("BaseFilter", {
            attrs: {
              title: "SMS Filter",
              filterId: "PostfachSMSFilter",
              configFilter: _vm.filterConfig,
              metadata: _vm.metadata,
              defaultOptions: _vm.defaultOptions,
              showSaveButton: "",
              hasSmartSearch: "",
              immidiateSearch: ""
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 25,
                  rowId: "rowid",
                  exportConfig: { roottext: "Postfach" },
                  mobileConfig: {
                    title: "name",
                    headers: ["subject", "dateTime", "contact"]
                  }
                },
                on: {
                  clickRow: _vm.clickBetreff,
                  "click-subject": _vm.clickBetreff,
                  "click-name": function($event) {
                    return _vm.openCustomerNewTab($event.nummer)
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 5 } } }
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }