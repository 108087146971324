var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    _vm._b(
      {
        attrs: { disabled: _vm.disabled },
        on: {
          click: function($event) {
            return _vm.onClick()
          }
        }
      },
      "BaseButton",
      _vm.$attrs,
      false
    ),
    [_vm._v(_vm._s(_vm.label))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }