var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Zusätzliche Informationen")
      ]),
      _vm.isEditable
        ? _c(
            "div",
            { staticClass: "mb-8px" },
            [
              _c("ComboBox", {
                attrs: {
                  isComponentHalfSize: "",
                  values: _vm.zusaetzlicheInfosValues,
                  sortComboboxValues: false
                },
                on: {
                  change: function($event) {
                    return _vm.resetZusaetzlicheInfosField()
                  }
                },
                model: {
                  value: _vm.zusaetzlicheInfosCombo,
                  callback: function($$v) {
                    _vm.zusaetzlicheInfosCombo = $$v
                  },
                  expression: "zusaetzlicheInfosCombo"
                }
              }),
              _c("InputField", {
                attrs: { isComponentHalfSize: "" },
                on: {
                  input: function($event) {
                    return _vm.resetZusaetzlicheInfosCombo()
                  }
                },
                model: {
                  value: _vm.zusaetzlicheInfosField,
                  callback: function($$v) {
                    _vm.zusaetzlicheInfosField = $$v
                  },
                  expression: "zusaetzlicheInfosField"
                }
              }),
              _c("BaseButton", {
                attrs: { label: "Hinzufügen", disabled: !_vm.isValid },
                on: {
                  click: function($event) {
                    return _vm.addZusaetzlicheInfos()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("Table", {
        attrs: {
          tableId: "2bab20e1-b1e1-44ad-aad2-ebacbb3eb51c",
          rowId: "zusaetzlicheInfos",
          headers: _vm.headers,
          rows: _vm.rows,
          rowsPerPage: 10
        },
        on: {
          "action-DELETE": function($event) {
            return _vm.removeZusaetzlicheInfos($event.zusaetzlicheInfos)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }