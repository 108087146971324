<template>
    <div>
        <PageHeaderTitleNavigation 
            title="Verknüpfte virtuelle Depots:"
            id="virtuelleDepotsMeineZiele" 
            :actions="actions" 
            :noPrimaryAction="true"
            :showBackButton="false"
            @action-DEPOTS="getDepots"
            @action-REMOVE_ALL_DEPOTS="removeAllDepots"
          >
        </PageHeaderTitleNavigation> 
        <Table 
            :headers="depotHeaders"
            :rows="depotRows"
            rowId="id"
            hidePagination
            :mobileConfig="{}"
            noDataContent="Keine Virtuelle Depots sind verknüpft."
            @action-REMOVE="removeDepot"
          >
        </Table>
        <BaseModal
            ref="unterdepotsModal"
            modalTitle="Virtuelles Unterdepot zum Ziel hinzufügen"
            labelButtonConfirm="Übernehmen"
            size="lg"
            @onConfirmButton="addDepots"
            @onCloseButton="selectedRows = []"
            @onCancelButton="selectedRows = []"
            :confirmDisabled="!selectedRows.length"
            >
            <Table
                title="Virtuelle Unterdepots"
                :headers="modalHeaders"
                :rows="modalRows"
                rowId="id"
                hidePagination
                :mobileConfig="{selectionActive: true}"
                :selected="selectedRows"
                @selected="setSelected"
            >
            </Table>
        </BaseModal>
    </div>
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Table from "@/components/table2/Table.vue";
import { PageHeaderSimpleAction, PageHeaderConfirmedAction } from '@/components/core/header-title-navigation/page-header-utils';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import BaseModal from '@/components/core/BaseModal.vue';
import {TextColumn, CurrencyColumn, DateColumn, PercentageColumn, ActionColumn, ConfirmedAction} from "@/components/table2/table_util.js";
import mixin from './my-goals-mixin.js';
import { PhTrash } from 'phosphor-vue';

export default {
    mixins: [mixin],
    components: {
        PageHeaderTitleNavigation,
        Table,
        BaseModal,
    },
    data() {
        return {
            allDepotList: null,
            selectedRows: [],
            depotHeaders: {
                lockedLeft: [
                    TextColumn("name", "Depotname").makeAlwaysVisible(),
                    CurrencyColumn("wert", "Wert").makeAlwaysVisible().withSumFooter('currency'),
                ],
                center: [
                    CurrencyColumn("sparplanBetrag", "Monatlich").withSumFooter('currency'),
                    PercentageColumn("rendite", "Rendite"),
                    DateColumn("sparplanEndDatum", "Enddatum"),
                ],
                lockedRight: [
                  ActionColumn("actions", ""),
                ],
            },
            modalHeaders: {
                lockedLeft: [
                    TextColumn("vdname", "Name").makeAlwaysVisible(),
                    CurrencyColumn("depotGesamt", "Wert").makeAlwaysVisible(),
                ],
                center: [
                    CurrencyColumn("sparplanBetragMonatlich", "Sparbetrag monatlich"),
                    PercentageColumn("rendite", "Rendite"),
                    TextColumn("myGoalTitle", "Meine Ziele"),
                ],
                lockedRight: [],
            },
        }
    },
    computed: {
        actions() {
            const actions = [PageHeaderSimpleAction('DEPOTS', 'Depot verknüpfen')];
            if (this.hasDepots) {
                actions.push(PageHeaderConfirmedAction('REMOVE_ALL_DEPOTS', 'Alle Depots entfernen',
                'Wollen Sie die Verknüpfung zu allen virtuellen Depots wirklich löschen?', 'Alle Depots entfernen', 'Löschen'));
            }
            return actions;
        },
        depotRows() {
            return this.goal.depots?.map(row => ({
                ...row, 
                actions: [ConfirmedAction("REMOVE", PhTrash, "Löschen", `Wollen Sie die Verknüpfung zum virtuellen Depot "${row?.name || ''}" wirklich löschen?`)]
            })) || [];
        },
        modalRows() {
            return this.allDepotList?.map(row => ({
                ...row, 
                myGoalTitle: this.goals?.filter(goal => goal.depots?.some(depot => depot.id === row.id)).map(goal => goal.title).join(','),
            })) || [];
        }
    },
    methods: {
        async getDepots() {
            if (!this.goal?.title) {
                this.$confirmModal({
                    title: 'Bezeichnung', 
                    message: 'Ohne Bezeichnung wird mein Ziel nich gespeichert. Bitte geben Sie die Bezeichnung ein.', 
                    showCancelButton: false,
                    labelButtonConfirm: 'Ok'
                })
            } else {
                await this.saveGoal();
                setTimeout(() => {
                    this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_DEPOTS).then(response => {
                    this.allDepotList = response?.rows || null;
                    if (this.allDepotList?.length) {
                        this.$refs.unterdepotsModal.open();
                    } else {
                        this.$confirmModal({
                            title: '', 
                            message: 'Sie haben kein virtuelles Depot.', 
                            showConfirmButton: false
                        })
                    }
                });    
                }, 200)
            }
        },
        async addDepots() {
            if (this.selectedRows?.length) {
                await this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.ADD_DEPOTS, { goalId: this.id, depotIds: this.selectedRows.map(depot => depot.id)});
                if ((this.goal.startkapital || 0) >= (this.goal.zielsumme || 0)) {
                    this.$confirmModal({
                        title: 'Ziel erreicht', 
                        message: 'Glückwunsch! Sie haben Ihres Ziel erreicht!', 
                        showCancelButton: false,
                        labelButtonConfirm: 'Ok',
                    });
                    this.$store.commit(MY_GOALS_TYPES.MUTATIONS.UPDATE_STORE, { value: { endDate: null}, id: this.id });
                } else {
                    this.updateEndDate();
                }
                this.selectedRows = [];
                this.$refs.unterdepotsModal.close();
                this.$emit('depotChange');
            }
        },
        setSelected(selection) {
            this.selectedRows = selection;
        },
        async removeDepot(depot) {
            if (depot?.id) {
                await this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.REMOVE_DEPOT, {goalId: this.id, depotId: depot.id});
                this.updateEndDate();
                this.$emit('depotChange');
            }
        },
        async removeAllDepots() {
            await this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.REMOVE_ALL_DEPOTS, this.id);
            this.updateEndDate();
            this.$emit('depotChange');        
        }
    },
}
</script>

<style>

</style>