<template>
  <BaseModal 
    ref="modal"
    :modalTitle="lagerstelleName + ' Depotauswahl für Externer Zugang'"
    :labelButtonConfirm="'Externer ' + lagerstelleName + ' Zugang öffnen'"
    :confirmDisabled="!depotnummer"
    @onConfirmButton="openExterneZugangBank({ konsorte: konsorte, depotnr: depotnummer, lagerstelle: lagerstelleId })"
  >
    <template #default>
      <ComboBox
        label="Konsorte"
        :values="combos.konsorteCombo"
        v-model="konsorte"
        @change="updateDepotnr()"
      />
      <ComboBox
        label="Depotnr"
        :values="depotnummerCombo"
        v-model="depotnummer"
      />
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import mixin from './externeZugangBankUtils.js'

export default {
  mixins: [mixin],
  props: {
    externeZugangBank: {
      type: Object, 
      default: null,
    }
  },
  data() {
    return {
      konsorte: null,
      depotnummer: null,
    };
  },
  computed: {
    depotnummerCombo() {
      const depotnummerCombos = this.combos.depotnummerCombos || {};
      return depotnummerCombos[this.konsorte] || [];
    },
    lagerstelleName() {
      //return this.lagerstelleId === 'FFB' ? 'FFB' : "ebase Augsburg";
      return this.externeZugangBank?.label;
    },
    lagerstelleId() {
      return this.externeZugangBank?.id;
    },
    combos() {
      return this.externeZugangBank?.combos || {};
    }
  },
  methods: {
    open() {
      this.$refs.modal?.open();
      this.konsorte = this.combos.konsorteCombo?.[0] || '';
      this.updateDepotnr();
    },
    updateDepotnr() {
      this.depotnummer = this.depotnummerCombo?.[0] || '';
    },
    close() {
      this.$refs.modal.close();
    },
  },
  components: {
    BaseModal,
    ComboBox,
    BaseButton,
  },
}


</script>
