import FONDSFAVORITEN_TYPES from './types';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

const defaultSpinnerConfig = {
  defaultSpinner: true,
};

export default {
 [FONDSFAVORITEN_TYPES.ACTIONS.LOAD_FAVORITENLISTS]({ commit, rootState, }, payload) {

    const params = makeQueryParam({ isin: payload?.isin, })
    return axios.get(`${rootState.core.apiAddress}/favoritenlisteService/favoritenlist?${params}`, defaultSpinnerConfig).then(response => {
      if (response && response.data) {
        commit(FONDSFAVORITEN_TYPES.MUTATIONS.LOAD_FAVORITENLISTS_SUCCESS, response.data.favoritenList);
        commit(FONDSFAVORITEN_TYPES.MUTATIONS.LOAD_FAVORITENLISTS_OPTIONS_SUCCESS, response.data.options);
      }
    })
  },
  [FONDSFAVORITEN_TYPES.ACTIONS.ADD_FONDS]({ rootState, }, { isins, favoritenlistId, favoritenlistIds, }) {

    const params = makeQueryParam({ isins, favoritenlistId, favoritenlistIds, })
    return axios.post(`${rootState.core.apiAddress}/favoritenlisteService/addFonds?${params}`, null, defaultSpinnerConfig)
  },
}