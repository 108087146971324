<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Aufgaben" 
      :actions="headerActions"
      @action-NEW_AUFGABE="newTask"
    />

    <BaseFilter
      v-if="defaultOptions"
      title="Aufgaben Filter"
      filterId="PostfachTAAFilter"
      :isCache="hasFilterCache"
      :configFilter="filterConfig"
      :metadata="metadata"
      :defaultOptions="defaultOptions"
      showSaveButton
      hasSmartSearch
      immidiateSearch
      @onFilter="onFilter"
    />

    <div class="box__container">
      <Table 
        v-if="rows.length"
        v-model="selectedRows"
        rowId="rowid"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="25"
        :headerActions="tableHeaderActions"
        :exportConfig="{roottext: 'Postfach'}"
        :mobileConfig="mobileConfig"
        @click-subject="clickBetreff"   
        @action-DONE="doneRow"
        @action-DELETE="deleteRow"
        @headerAction-BULK_DELETE="deleteSelected"
        @headerAction-BULK_DONE="doneSelected"
      >

        <template v-slot:participants="row">
          <ul style="padding: 0; margin: 0">
            <li style="display:inline-block;" v-for="(participant, index) in row.participants" :key="participant.id">
              <a v-if="participant.isKunde && canOpenCustomer(participant) && !row.mobileTableContext" @click="openCustomerNewTab(participant.nummer)">{{participant.name}}</a>
              <span v-else>{{participant.name}}</span>
              <span v-if="index < row.participants.length - 1">;&nbsp;</span>
            </li>
          </ul>
        </template>

      </Table>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 10 } }"/>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import CALENDAR_TYPES from "@/store/calendar/types";
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, DateTimeColumn, ActionColumn, ConfirmedAction, PillColumn} from "@/components/table2/table_util.js";
import postfachTableMixin from '@/views/communication/postfach/tables/postfach-table-mixin.js';
import axios from 'axios';
import dayjs from 'dayjs';

import COMMUNICATION_TYPES from '@/store/communication/types';

import BaseFilter from "@/components/core/BaseFilter.vue";
import { mapGetters } from "vuex";
import { PageHeaderSimpleAction, PageHeaderConfirmedAction} from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [postfachTableMixin],
  components: {
    Table,
    BaseFilter,
  },
  data() {
    return {
      currentMode: '',
      category: 'termin',
      mailboxLabel: 'Termin',
      defaultOptions: null,
      hasFilterCache: true,
      filterConfig: {
        placeholderForDefSearchInput: 'Betreff, Besitzer, Teilnehmer',
        noResetOnDefaultSearchInputExit: true,
        checkDefaultSearchInput: false,
        hideFirstColumn: true,
      },
      mobileConfig: {
          title: 'name',
          headers: ['subject', 'date', 'participants', 'type', 'status'],
      },
    }
  },
  computed: {
    ...mapGetters({
      postfachDefaultFilters: COMMUNICATION_TYPES.GETTERS.POSTFACH_DEFAULT_FILTERS,
      getAppointmentCombos: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_COMBOS,
    }),
    headers() {
      return {
        lockedLeft: [
          DateTimeColumn("date", "Datum", undefined, 1),
          TextColumn("subject", "Betreff").makeLink(),
        ],
        center: [
          TextColumn("name", "Besitzer"),
          SlotColumn("participants", "Teilnehmer", 80, 1),
          TextColumn("status", "Status"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ]
      };
    },
    rows() {
      return this.records.map(record => {
        return {
          ...record,
          type: {
            label: record.category,
            type: "default",
          },
          rowid: (record.commId + record.category + record.date),
          subject: record.subject || "[kein Betreff]",
          actions: this.makeActions(record)
        }
      })
    },
    metadata() {
      const result = [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'text',
              label: 'Betreff',
              key: 'subject',
            },
            {
              type: 'default',
              label: 'nur mich als Besitzer',
              key: 'isOwner',
            },
            {
              type: 'default',
              label: 'nur zukünftige Termine',
              key: 'isFuture',
            },
            {
              type: 'text',
              label: 'Besitzer',
              key: 'owner',
            },
            {
              type: 'text',
              label: 'Teilnehmer',
              key: 'participant',
            },
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'zeitraum',
            },
            {
              type: 'datepicker',
              label: 'Datum',
              key: 'datum',
            },
          ],
        },
      ];
      return result;
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('NEW_AUFGABE', 'Neue Aufgabe'),
      ];
    },
    tableHeaderActions() {
      return [
        PageHeaderConfirmedAction('BULK_DELETE', 'Markierte löschen', 'Sollen die markierten Einträge wirklich gelöscht werden?', 'Markierte löschen', 'Löschen')
        .withDisabled(() => !this.selectedRows.length),
        PageHeaderSimpleAction('BULK_DONE', 'Markierte als Erledigt markieren')
        .withDisabled(() => !this.selectedRows.length),
      ];
    },
  },
  mounted() {
    this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_APPOINTMENT_COMBOS);

    const { fromDate, toDate, } = this.postfachDefaultFilters;
    const query = this.$route.query

    this.defaultOptions = {
      zeitraum: {
        value: [{
          key: "min",
          value: fromDate,
        },
        {
          key: "max",
          value: toDate,
        }],
      },
    };


    if(query.q) {
      this.defaultOptions.subject = {
        value: query.q,
      }
      this.hasFilterCache = false
    }
  },
  methods: {
    makeActions(row) {
      const article = row.category === "Termin" ? 'der' : 'die' 

      return [
        ConfirmedAction("DONE", 'PhCheck', "Erledigt", `Soll die Aufgabe "${row.subject || "[kein Betreff]"}" wirklich als erledigt markiert werden?`, `Aufgabe als Erledigt markieren`, "Erledigt"),
        ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll ${article} ${row.category} "${row.subject || "[kein Betreff]"}" wirklich gelöscht werden?`, `${row.category} löschen`, "Löschen")
      ]
    },
    clickBetreff(row) {
      if (row.commId) {
        this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, row.commId);
        this.openAppointment();
        if (row.category === 'Aufgabe') {
          this.isActivity = true;
          this.attachCustomer = true;
          this.getCurrentMode('Aufgabe');
          this.openAppointment();
        } else {
          this.attachCustomer = true;
          this.isActivity = false;
          this.getCurrentMode('Termin');
          this.openAppointment();
        }
      }
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: `${
          this.currentMode === "Termin" || this.currentMode === "Aufgabe"
            ? this.currentMode
            : "Aktivität"
        } bearbeiten`,
        mode: this.currentMode,
        isActivity: this.isActivity,
        attachCustomer: this.attachCustomer,

        back: this.$router.currentRoute.fullPath,
      });
      this.$router.push({ path: `/communication/appointment` });
    },
    newTask() {
      this.isActivity = true;
      this.attachCustomer = true;
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: false});
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE);
      this.getCurrentMode('Aufgabe');
      this.openAppointment(false);
    },
    newAppointment() {
      this.attachCustomer = true;
      this.isActivity = false;
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: false});
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_TERMIN);
      this.getCurrentMode('Termin');
      this.openAppointment(true);
    },
    getCurrentMode(bereich) {
      if (!bereich) {
        bereich = this.getSelectedEvent?.appointment?.bereich;
      }

      this.currentMode = bereich ? (bereich === 'Termin' || bereich === 'Aufgabe' ? 'Aufgabe' : 'Aktivität') : '';
    },
    doSearch(urlParams) {
      this.loading = true;

      const params = {
        ...urlParams,
      }

      /*
      console.log(params, urlParams.fromDate, this.postfachDefaultFilters.fromDate)

      if(urlParams.fromDate || this.postfachDefaultFilters.fromDate) {
        params.fromDate = urlParams.fromDate || dayjs(this.postfachDefaultFilters.fromDate).format('DD.MM.YYYY')
      }
      if(urlParams.toDate || this.postfachDefaultFilters.toDate) {
        params.toDate = urlParams.toDate || dayjs(this.postfachDefaultFilters.toDate).format('DD.MM.YYYY')
      }

      console.log(params)*/

      axios.post(`${process.env.VUE_APP_API}/postfachTermin/list/aufgaben`, params, { defaultSpinner: true, }) //TODO: Implement a vuex action for this request
      .then(response => this.records = response.data.records || [])
      .finally(() => {
        this.selectedRows = []
        this.loading = false
      })
    },
  },
}
</script>