var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "participants-input" }, [
        _c(
          "div",
          { staticClass: "w-100" },
          [
            _c("div", { staticClass: "label-content" }, [
              _c("span", { staticClass: "pr-3" }, [_vm._v(_vm._s(_vm.label))]),
              _vm.warning
                ? _c("span", { staticClass: "color-danger" }, [
                    _vm._v(_vm._s(_vm.warning))
                  ])
                : _vm._e()
            ]),
            !_vm.isUsersPreview || _vm.combinedDisplay
              ? [
                  _vm.multipleReceivers
                    ? _c(
                        "div",
                        [
                          _c("InputChips", {
                            attrs: {
                              availableOptions: _vm.availableUsers,
                              supportCustomValue: true,
                              warning: _vm.warning,
                              disableInput: _vm.disabled,
                              isClickable: _vm.isReceiveUserClickable,
                              maxItemsVisible: _vm.maxItemsVisible,
                              isMaklerOnly: _vm.isMaklerOnly,
                              notRemovableItem: "...",
                              redFlagWhenDifferentLabelValue: "",
                              loading: _vm.loading
                            },
                            on: {
                              inputText: function($event) {
                                return _vm.searchReceiverUsers($event)
                              },
                              clickItem: function($event) {
                                return _vm.clickReceiveUsers($event)
                              },
                              removeItem: function($event) {
                                return _vm.deleteReceiveUsers($event)
                              },
                              addItem: function($event) {
                                return _vm.$emit("addItem", $event)
                              }
                            },
                            model: {
                              value: _vm.receiver,
                              callback: function($$v) {
                                _vm.receiver = $$v
                              },
                              expression: "receiver"
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("InputField", {
                            attrs: { supportCustomValue: true },
                            model: {
                              value: _vm.receiverStr,
                              callback: function($$v) {
                                _vm.receiverStr = $$v
                              },
                              expression: "receiverStr"
                            }
                          })
                        ],
                        1
                      )
                ]
              : _vm._e()
          ],
          2
        ),
        _c("div", [
          _c(
            "div",
            { staticClass: "participants-actions" },
            [
              !_vm.combinedDisplay || _vm.isUserSelect
                ? _c("BaseDropdownMenu", {
                    attrs: { placement: "bottom-right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "hook-target",
                          fn: function() {
                            return [
                              _c("ph-dots-three-vertical", {
                                staticClass: "clickable",
                                attrs: { size: 24 },
                                on: {
                                  click: function($event) {
                                    _vm.showDropdown = !_vm.showDropdown
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c("div", { staticClass: "dropdown-content" }, [
                                _vm.isUserSelect && !_vm.isMaklerOnly
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "pr-2 clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "createManualReceiver"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("PhUserPlus", {
                                          attrs: { size: "20" }
                                        }),
                                        !_vm.isMaklerOnly
                                          ? _c(
                                              "span",
                                              { staticClass: "pl-2" },
                                              [_vm._v("Empfänger hinzufügen")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isUserSelect && !_vm.isMaklerOnly
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "pr-2 clickable",
                                        on: { click: _vm.handleAddUsers }
                                      },
                                      [
                                        _c("PhBookOpen", {
                                          attrs: { size: "20" }
                                        }),
                                        _c("span", { staticClass: "pl-2" }, [
                                          _vm._v("Empfängersuche")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.isUserSelect && _vm.isMaklerOnly
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "pr-2 clickable",
                                        on: { click: _vm.handleAddMakler }
                                      },
                                      [
                                        _c("PhBookOpen", {
                                          attrs: { size: "20" }
                                        }),
                                        _c("span", { staticClass: "pl-2" }, [
                                          _vm._v("Maklersuche")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.isTableViewOnly && !_vm.combinedDisplay
                                  ? _c("div", { staticClass: "clickable" }, [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleUserPreview()
                                            }
                                          }
                                        },
                                        [
                                          !_vm.isUsersPreview &&
                                          !_vm.isMaklerOnly
                                            ? _c("PhRows", {
                                                attrs: { size: "20" }
                                              })
                                            : _vm._e(),
                                          !_vm.isUsersPreview &&
                                          !_vm.isMaklerOnly
                                            ? _c(
                                                "span",
                                                { staticClass: "pl-2" },
                                                [
                                                  _vm._v(
                                                    "zur Tabellenansicht wechseln"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleUserPreview()
                                            }
                                          }
                                        },
                                        [
                                          _vm.isUsersPreview
                                            ? _c("PhColumns", {
                                                attrs: { size: "20" }
                                              })
                                            : _vm._e(),
                                          _vm.isUsersPreview
                                            ? _c(
                                                "span",
                                                { staticClass: "pl-2" },
                                                [
                                                  _vm._v(
                                                    "zur Einfügeansicht wechseln"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.isUserSelect || _vm.isMaklerOnly
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "pr-2 clickable",
                                        on: { click: _vm.handleDeleteAllUsers }
                                      },
                                      [
                                        _c("PhXCircle", {
                                          attrs: { size: "20" }
                                        }),
                                        _c("span", { staticClass: "pl-2" }, [
                                          _vm._v("Empfängerliste leeren")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      4103508097
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm.isUsersPreview || _vm.combinedDisplay
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("Table", {
                attrs: {
                  hidePagination: _vm.hidePagination,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: _vm.maxItemsVisible
                },
                on: {
                  "click-name": function($event) {
                    return _vm.clickReceiveUsers($event.data)
                  },
                  "action-EDIT": _vm.handleEdit,
                  "action-DELETE": _vm.handleDelete,
                  "action-COPY_ADDRESS": _vm.handleCopyAddress,
                  "action-SEND_EMAIL": _vm.handleSendEmail,
                  "action-SEND_EMAIL_SINGLE": _vm.handleSendEmailSingle,
                  "action-SET_ADDRESS": _vm.handleSetAddress,
                  "click-handleSendEmailSingle": _vm.handleSendEmailSingle,
                  "click-handleSetAddress": _vm.handleSetAddress
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "role",
                      fn: function(row) {
                        return [
                          _c("ComboBox", {
                            attrs: {
                              disabled: row.data.disabled,
                              label: "",
                              value: row.rolle,
                              values: _vm.rolleOptions,
                              firstEmpty: false
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChange(row, $event, "rolle")
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "canChange",
                      fn: function(row) {
                        return [
                          _c("InputCheckBoxItem", {
                            attrs: { label: "Ändern", value: row.canChange },
                            on: {
                              input: function($event) {
                                return _vm.onChange(row, $event, "canChange")
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "alarmMsc",
                      fn: function(row) {
                        return [
                          _c("InputCheckBoxItem", {
                            attrs: {
                              label: "anzeigen",
                              value: row.data.alarmMsc,
                              disabled: !row.canChangeValues
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChange(row, $event, "alarmMsc")
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "alarmMail",
                      fn: function(row) {
                        return [
                          _c("InputCheckBoxItem", {
                            attrs: {
                              label: "versenden",
                              value: row.alarmMail,
                              disabled: !row.canChangeValues
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChange(row, $event, "alarmMail")
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "alarmDailyMail",
                      fn: function(row) {
                        return [
                          _c("InputCheckBoxItem", {
                            attrs: {
                              label: "versenden",
                              value: row.alarmDailyMail,
                              disabled: !row.canChangeValues
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChange(
                                  row,
                                  $event,
                                  "alarmDailyMail"
                                )
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  904577088
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: { modalTitle: "", showDefaultButtons: false }
        },
        [
          _c("InputField", {
            attrs: { label: "Name", placeholder: "Name", type: "text" },
            model: {
              value: _vm.form.label,
              callback: function($$v) {
                _vm.$set(_vm.form, "label", $$v)
              },
              expression: "form.label"
            }
          }),
          _c("InputField", {
            attrs: {
              label: !_vm.isFax ? "E-Mail" : "Fax",
              placeholder: !_vm.isFax ? "E-Mail" : "Fax",
              type: "text"
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          }),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { type: "submit" },
                  on: { click: _vm.saveParticipant }
                },
                [_vm._v("Ok")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }