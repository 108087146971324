var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.$appNavigation.currentTabMenu
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "958f96aa-e0a6-4f21-8f14-1255805511c1" }
        },
        [
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Typ",
              value: "listingType",
              values: _vm.listingTypes,
              labelClass: "font-bold"
            },
            on: {
              input: function($event) {
                return _vm.retrieveData($event)
              }
            },
            model: {
              value: _vm.listingType,
              callback: function($$v) {
                _vm.listingType = $$v
              },
              expression: "listingType"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "3c197cb4-0331-425e-9a45-454239452fa8" }
        },
        [
          _vm.listingType == "Versicherungen"
            ? _c("p", [
                _vm._v(
                  "Bitte beachten Sie, dass von der Struktur geerbte Vermittlernummern nur sichtbar sind, wenn Sie den Makler öffnen und seine individuelle Liste anzeigen lassen."
                )
              ])
            : _vm._e(),
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "f075f490-577a-4b51-898d-787c1606a398",
                  title: _vm.moduleTitle,
                  rowId: "rowIndex",
                  headers: _vm.tableHeaders,
                  scrollHorizontally: "",
                  rows: _vm.tableRows,
                  rowsPerPage: 20,
                  count: _vm.resultCount,
                  exportConfig: _vm.exportConfig
                },
                on: {
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.moduleTitle }
              })
            : _c("NoData", { attrs: { title: _vm.moduleTitle } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }