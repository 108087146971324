import INTERNESTATISTIKEN_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';

export default {
  [INTERNESTATISTIKEN_TYPES.MUTATIONS.SET_STATISTICGROUPS_LIST](state, payload){
      state.groupListData = payload;
  },  
  [INTERNESTATISTIKEN_TYPES.MUTATIONS.SET_GESELLSCHAFTENCOMBO](state, payload){
    state.gesellschaftenCombo = payload;
  },  
  [INTERNESTATISTIKEN_TYPES.MUTATIONS.SET_FILTER_BROKER_NUMBER](state, payload){
    state.filterBrokerNumber = payload;
  },
  // [INTERNESTATISTIKEN_TYPES.MUTATIONS.RESET_STATE](state, payload) {
  //   Object.assign(state, getInitialState())
  // }, 
  // [INTERNESTATISTIKEN_TYPES.MUTATIONS.RESET_STATISTICGROUPS_LIST](state, payload) {
  //   Object.assign(state, 'groupListData',  [])
  // },   
}