var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "header-login__container",
      attrs: { "data-app-header": "" }
    },
    [
      _c("CallModal"),
      _c("IncomingCallDialog"),
      _c("HungUpCallDialog"),
      _c("div", { staticClass: "fc-container" }, [
        _c("div", { staticClass: "top-navbar__container" }, [
          _c(
            "div",
            {
              staticClass:
                "top-nav__navigation-controls d-flex header-navbar__container-icons--spaced"
            },
            [
              _c("BaseBackButtonBreadcrumb", {
                staticClass: "top-nav__navigation-controls--breadcrumb",
                attrs: { size: _vm.buttonsSize }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "header__container-item header__container--end" },
            [
              !_vm.isMobileNativeContext &&
              _vm.brokerInformation.showAppIconsOnLoginscreen
                ? [
                    _vm.appLinks && _vm.appLinks.IOS
                      ? _c(
                          "a",
                          {
                            attrs: { href: _vm.appLinks.IOS, target: "_blank" }
                          },
                          [_c("PhAppleLogo", { attrs: { size: 18 } })],
                          1
                        )
                      : _vm._e(),
                    _vm.appLinks && _vm.appLinks.google
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.appLinks.google,
                              target: "_blank"
                            }
                          },
                          [_c("PhGooglePlayLogo", { attrs: { size: 18 } })],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _c("a", { attrs: { href: _vm.urlImpressum, target: "_blank" } }, [
                _vm._v("Impressum")
              ]),
              _c("span", [_vm._v("|")]),
              _c(
                "a",
                { attrs: { href: _vm.urlDatenschutz, target: "_blank" } },
                [_vm._v("Datenschutz")]
              )
            ],
            2
          )
        ])
      ]),
      _c("BaseTopProgressBar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }