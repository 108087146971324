<template>
  <BaseModal 
    ref="modal" 
    modalTitle="Spezialfeiertage"
    :confirmDisabled="!form.bezeichnung"
    :confirmLoading="loading"
    :autoClose="false"
    size="sm"
    @onConfirmButton="save()"
  >
    <template #default>
      <InputField label="Bezeichnung" type="text" v-model="form.bezeichnung" autofocus validateUntouched />
    </template>
  </BaseModal>
</template>

<script>
import CALENDAR_SETTINGS_TYPES from '@/store/calendarSettings/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';

import validator from '@/mixins/validator/index';
import { required, } from '@/mixins/validator/rules';

export default {
  mixins: [validator],
  data() {
    return {
      loading: false,
      gruppe: null,
      form: {
        bezeichnung: null,
      },
    };
  },
  methods: {
    open(gruppe) {
      this.gruppe = { ...gruppe, };
      this.init(gruppe);
      this.$refs.modal?.open();
    },
    close() {
      this.$refs.modal?.close();
    },
    init(gruppe) {
      this.loading = false;
      this.form = {
        bezeichnung: gruppe?.bezeichnung,
      };
      this.validation.reset('form.bezeichnung');
    },
    async save() {
      try {
        this.loading = true;
        const payload = { 
          ...this.form,
          id: this.gruppe?.id,
        };
        await this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.SAVE_FEIERTAGE_GRUPPEN_SPEZIAL, payload);
        this.close();
      } finally {
        this.loading = false;
      }
    },
  },
  validators: {
    form: {
      bezeichnung: [required()],
    },
  },
  components: {
    BaseModal,
    InputField,
  },
}
</script>
