var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCallModalOpened
    ? _c(
        "div",
        {
          staticClass: "container",
          class: { "logged-in__container": _vm.isLoggedIn }
        },
        [
          _vm.videoSrc && !_vm.collapsedVideo
            ? _c("div", { staticClass: "video-container" }, [
                _c("div", { staticClass: "video-container-inner" }, [
                  _c("video", {
                    attrs: { autoplay: "", playsinline: "" },
                    domProps: { srcObject: _vm.videoSrc }
                  })
                ])
              ])
            : _vm._e(),
          _c("audio", {
            ref: "audioOut",
            attrs: { autoplay: "", playsinline: "" },
            domProps: { srcObject: _vm.audioSrc }
          }),
          _c("div", { staticClass: "header" }, [
            _c("span", { staticClass: "header-outer" }, [
              _c(
                "div",
                { staticStyle: { width: "max-content" } },
                [
                  !_vm.$isSmallScreen
                    ? [
                        _vm._v(" " + _vm._s(_vm.displayName) + " "),
                        _vm.kundennr
                          ? _c("span", [
                              _vm._v("(" + _vm._s(_vm.kundennr) + ")")
                            ])
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm.partnerMuted
                    ? _c("ph-microphone-slash", {
                        attrs: { size: 24, weight: "fill" }
                      })
                    : _vm._e()
                ],
                2
              )
            ]),
            _c(
              "div",
              {
                staticClass: "icon-button-container",
                class: {
                  "icon-button-container--tight":
                    _vm.$isSmallScreen && !_vm.isMobileNativeContext
                }
              },
              [
                _vm.inCallWith || _vm.calling
                  ? _c(
                      "ph-phone-disconnect",
                      {
                        staticClass: "icon-button",
                        attrs: {
                          size: _vm.iconSize,
                          color: "var(--color-danger)"
                        },
                        on: { click: _vm.clickPhone }
                      },
                      [_c("title", [_vm._v("Auflegen")])]
                    )
                  : _vm.available
                  ? _c(
                      "ph-phone",
                      {
                        staticClass: "icon-button",
                        attrs: {
                          size: _vm.iconSize,
                          color: "var(--color-success)"
                        },
                        on: { click: _vm.clickPhone }
                      },
                      [_c("title", [_vm._v("Anruf starten")])]
                    )
                  : _c(
                      "ph-phone",
                      {
                        staticClass: "icon-button",
                        attrs: { color: "gray", size: _vm.iconSize },
                        on: { click: _vm.clickPhone }
                      },
                      [
                        _c("title", [
                          _vm._v("Gesprächspartner ist nicht online")
                        ])
                      ]
                    ),
                _vm.allowedToRecord
                  ? _c(
                      "span",
                      {
                        staticClass: "icon-button",
                        attrs: {
                          title: _vm.recording
                            ? "Das Gespräch wird aufgezeichnet"
                            : "Aufzeichnung starten"
                        },
                        on: { click: _vm.clickRecord }
                      },
                      [
                        _c("div", {
                          staticClass: "record-button",
                          class: [
                            _vm.recording ? "pulsating-record-button" : ""
                          ],
                          style: {
                            "background-color": _vm.inCallWith
                              ? "var(--color-danger)"
                              : "grey"
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                !_vm.previewSrc || _vm.videoIsScreen
                  ? _c(
                      "ph-video-camera-slash",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: { click: _vm.clickCamera }
                      },
                      [_c("title", [_vm._v("Kameraübertragung starten")])]
                    )
                  : _c(
                      "ph-video-camera",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: { click: _vm.clickCamera }
                      },
                      [_c("title", [_vm._v("Kameraübertragung beenden")])]
                    ),
                !_vm.isMobileNativeContext
                  ? [
                      !_vm.previewSrc || !_vm.videoIsScreen
                        ? _c(
                            "ph-monitor",
                            {
                              staticClass: "icon-button",
                              attrs: { size: _vm.iconSize, weight: "fill" },
                              on: { click: _vm.clickScreenShare }
                            },
                            [
                              _c("title", [
                                _vm._v("Bildschirmübertragung starten")
                              ])
                            ]
                          )
                        : _c(
                            "ph-monitor",
                            {
                              staticClass: "icon-button",
                              attrs: { size: _vm.iconSize },
                              on: { click: _vm.clickScreenShare }
                            },
                            [
                              _c("title", [
                                _vm._v("Bildschirmübertragung beenden")
                              ])
                            ]
                          )
                    ]
                  : _vm._e(),
                _vm.muted
                  ? _c(
                      "ph-microphone-slash",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: { click: _vm.clickMute }
                      },
                      [_c("title", [_vm._v("Stummschaltung aufheben")])]
                    )
                  : _c(
                      "ph-microphone",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: { click: _vm.clickMute }
                      },
                      [_c("title", [_vm._v("Stummschalten")])]
                    ),
                !_vm.isMobileNativeContext
                  ? _c(
                      "ph-chats",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: {
                          click: function($event) {
                            _vm.showChat = !_vm.showChat
                          }
                        }
                      },
                      [_c("title", [_vm._v("Chat öffnen / schließen")])]
                    )
                  : _vm._e(),
                _vm.videoSrc && _vm.collapsedVideo
                  ? _c(
                      "ph-arrows-out-simple",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: {
                          click: function($event) {
                            _vm.collapsedVideo = false
                          }
                        }
                      },
                      [_c("title", [_vm._v("Empfangenes Video maximieren")])]
                    )
                  : _vm._e(),
                _vm.videoSrc && !_vm.collapsedVideo
                  ? _c(
                      "ph-arrows-in-simple",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: {
                          click: function($event) {
                            _vm.collapsedVideo = true
                          }
                        }
                      },
                      [_c("title", [_vm._v("Empfangenes Video minimieren")])]
                    )
                  : _vm._e(),
                !_vm.isMobileNativeContext
                  ? _c(
                      "ph-gear",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: { click: _vm.openSettings }
                      },
                      [_c("title", [_vm._v("Einstellungen")])]
                    )
                  : _vm._e(),
                _vm.showToggleToAudioOutputSpeaker
                  ? _c(
                      "ph-speaker-simple-slash",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: { click: _vm.toggleAudioOutput }
                      },
                      [_c("title", [_vm._v("Speaker")])]
                    )
                  : _vm._e(),
                _vm.showToggleToAudioOutputReceiver
                  ? _c(
                      "ph-speaker-simple-high",
                      {
                        staticClass: "icon-button",
                        attrs: { size: _vm.iconSize },
                        on: { click: _vm.toggleAudioOutput }
                      },
                      [_c("title", [_vm._v("Receiver")])]
                    )
                  : _vm._e()
              ],
              2
            ),
            _c("span", { staticClass: "header-outer" }, [
              _vm.callStart
                ? _c("span", [_vm._v(_vm._s(_vm.callDuration))])
                : !_vm.isCallingActive
                ? _c(
                    "span",
                    {
                      staticClass: "clickable",
                      on: { click: _vm.toggleCallModal }
                    },
                    [_vm._v(" Fertig ")]
                  )
                : _vm._e()
            ])
          ]),
          _c(
            "div",
            { staticClass: "preview-container" },
            [
              _vm.previewSrc && !_vm.isDummyStream
                ? _c("DraggableElement", [
                    _c("div", { staticClass: "preview-window" }, [
                      _c("div", { staticClass: "screen-title" }, [
                        _c("div", { staticClass: "title-label" }, [
                          _vm._v(" übertragenes Video ")
                        ]),
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                _vm.collapsedPreviewVideo = !_vm.collapsedPreviewVideo
                              }
                            }
                          },
                          [
                            _vm.collapsedPreviewVideo
                              ? _c(
                                  "ph-arrows-out-simple",
                                  {
                                    staticClass: "icon-button",
                                    attrs: { size: _vm.iconSize }
                                  },
                                  [
                                    _c("title", [
                                      _vm._v("übertragenes Video maximieren")
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            !_vm.collapsedPreviewVideo
                              ? _c(
                                  "ph-arrows-in-simple",
                                  {
                                    staticClass: "icon-button",
                                    attrs: { size: _vm.iconSize }
                                  },
                                  [
                                    _c("title", [
                                      _vm._v("übertragenes Video minimieren")
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      !_vm.collapsedPreviewVideo
                        ? _c("video", {
                            attrs: { autoplay: "", playsinline: "" },
                            domProps: { srcObject: _vm.previewSrc }
                          })
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm.videoSrc && _vm.collapsedVideo
                ? _c("DraggableElement", [
                    _c("div", { staticClass: "preview-window" }, [
                      _c("div", { staticClass: "screen-title" }, [
                        _vm._v(_vm._s(_vm.displayName))
                      ]),
                      _c("video", {
                        attrs: { autoplay: "", playsinline: "" },
                        domProps: { srcObject: _vm.videoSrc }
                      })
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.mediaSettings
            ? _c("MediaSettingsModal", {
                staticStyle: { "pointer-events": "auto" },
                on: {
                  close: function($event) {
                    _vm.mediaSettings = false
                  }
                }
              })
            : _vm._e(),
          _vm.showChat && _vm.chatInfo
            ? _c("ChatModal", {
                staticStyle: { "pointer-events": "auto" },
                attrs: {
                  beteiligterId: _vm.beteiligterId,
                  chatId: _vm.chatId,
                  channelId: _vm.channelId,
                  displayName: _vm.displayName,
                  kundennr: _vm.kundennr
                },
                on: { close: _vm.closeChat }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }