var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: "Standard wiederherstellen",
      labelButtonConfirm: "Bestätigen"
    },
    on: { onConfirmButton: _vm.onResetDefault },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "config-item pb-3" },
              [
                _c("div", [
                  _vm._v(_vm._s(_vm.configContextLabel) + " ("),
                  _c("strong", [_vm._v("nur " + _vm._s(_vm.currentMenuLabel))]),
                  _vm._v(")")
                ]),
                _c("InputToggleSwitch", {
                  staticClass: "p-0",
                  attrs: { label: "Einstellungen" },
                  model: {
                    value: _vm.form.resetConfig,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "resetConfig", $$v)
                    },
                    expression: "form.resetConfig"
                  }
                }),
                _vm.form.resetConfig
                  ? _c(
                      "div",
                      { staticClass: "config-item-levels" },
                      _vm._l(_vm.tabList, function(tab, index) {
                        return _c("InputToggleSwitch", {
                          key: index,
                          staticClass: "p-0",
                          attrs: { label: tab.label },
                          model: {
                            value: _vm.form.configUserLevels[tab.key],
                            callback: function($$v) {
                              _vm.$set(_vm.form.configUserLevels, tab.key, $$v)
                            },
                            expression: "form.configUserLevels[tab.key]"
                          }
                        })
                      }),
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm.enablePermissionConfig
              ? _c(
                  "div",
                  { staticClass: "config-item" },
                  [
                    _c("div", [
                      _vm._v(_vm._s(_vm.configContextLabel) + " ("),
                      _c("strong", [_vm._v("Alle")]),
                      _vm._v(")")
                    ]),
                    _c("InputToggleSwitch", {
                      staticClass: "p-0",
                      attrs: { label: "Zugriff" },
                      model: {
                        value: _vm.form.resetPermissionConfig,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "resetPermissionConfig", $$v)
                        },
                        expression: "form.resetPermissionConfig"
                      }
                    }),
                    _vm.form.resetPermissionConfig
                      ? _c(
                          "div",
                          { staticClass: "config-item-levels" },
                          _vm._l(_vm.permissionUserLevels, function(
                            tab,
                            index
                          ) {
                            return _c("InputToggleSwitch", {
                              key: index,
                              staticClass: "p-0",
                              attrs: { label: tab.label },
                              model: {
                                value:
                                  _vm.form.permissionConfigUserLevels[tab.key],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.permissionConfigUserLevels,
                                    tab.key,
                                    $$v
                                  )
                                },
                                expression:
                                  "form.permissionConfigUserLevels[tab.key]"
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }