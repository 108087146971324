var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.privacyRegistrationData.config
    ? _c(
        "div",
        { staticClass: "register-privacy-page p-24px" },
        [
          _c("GenericPrivacyTerms", {
            attrs: {
              info: _vm.privacyRegistrationData,
              contacts: _vm.contacts,
              hideAcceptLabelHtmlText: true
            },
            on: {
              "open-link": function($event) {
                return _vm.openLink($event)
              },
              "terms-accepted": function($event) {
                return _vm.termsAccepted($event)
              },
              "terms-rejected": function($event) {
                return _vm.termsRejected()
              }
            }
          }),
          _c("div", { staticClass: "box__container" }, [
            _vm._v(
              " * Ihre persönliche Kundenkennung und den Link zum Kundenzugang erhalten Sie im Zuge der Online-Registrierung per E-Mail. "
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }