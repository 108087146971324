import { render, staticRenderFns } from "./AntragBottomButtons.vue?vue&type=template&id=8f7e5492&scoped=true&"
import script from "./AntragBottomButtons.vue?vue&type=script&lang=js&"
export * from "./AntragBottomButtons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f7e5492",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8f7e5492')) {
      api.createRecord('8f7e5492', component.options)
    } else {
      api.reload('8f7e5492', component.options)
    }
    module.hot.accept("./AntragBottomButtons.vue?vue&type=template&id=8f7e5492&scoped=true&", function () {
      api.rerender('8f7e5492', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/antrag/AntragBottomButtons.vue"
export default component.exports