<template>
  <div>
    <PageHeaderTitleNavigation
        :actions="headerActions"
        title="Bemerkung"
        @action-FILE_SELECT="onFile"
        @action-PDF="showPDF"
    />
    
        <DragnDropArea hoverText="Datei hier ablegen" @files="onFile" :disabled="loading || isCustomerOnly">
            <div class="box__container">

                <!-- 
                    could also use in container:
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    grid-gap: 20px;
                -->

                <div class="d-flex" style="flex-wrap: wrap; gap: 20px">
                    <DatePickerField style="flex: 1 1 auto; padding-bottom: 0px" 
                        label="Datum"
                        v-model="bemerkungData.datum"
                        isValueAsString
                        :disabled="loading || isCustomerOnly"
                        :renderDanger="!bemerkungData.datum"
                        @input="bemerkungDataChanged = true"
                    />
                    <DatePickerField style="flex: 1 1 auto; padding-bottom: 0px" 
                        label="Erstellt am"
                        :value="bemerkung.dateCreated" 
                        disabled 
                        />
                    <DatePickerField style="flex: 1 1 auto" 
                        label="Zuletzt geändert am"
                        :value="bemerkung.dateModified" 
                        disabled 
                    />
                </div>

                <InputField 
                    v-model="bemerkungData.betreff"
                    label="Thema"
                    :disabled="loading || isCustomerOnly"
                    @input="bemerkungDataChanged = true"
                />

                <InputToggleSwitch 
                    v-if="!isCustomerOnly"
                    v-model="bemerkungData.important"
                    :label="sonderHinweisLabel"
                    inLineLabel 
                    :disabled="loading || isCustomerOnly"
                    @input="bemerkungDataChanged = true"
                />

                <InputToggleSwitch
                    v-if="isBrokerOrBypass && isCustomer"
                    label="Für Kunde sichtbar"
                    v-model="bemerkungData.sichtbar"
                    inLineLabel 
                    :disabled="loading || isCustomerOnly"
                    @input="bemerkungDataChanged = true"
                />

                <!-- BUG: In the HtmlEditor there's a watcher on 'disabled' which triggers the @input every time. I can't use ':disabled="loading"' property here -->
                <HtmlEditor
                    class="mb-3"
                    isFontParams
                    v-model="bemerkungData.text"
                    :disabled="isCustomerOnly"
                    @input="bemerkungDataChanged = true"
                />
            </div>

            <div class="box__container">
                <BemerkungFiles
                    title="Anhänge"
                    :files="bemerkungFiles"
                    @deleteFile="deleteAttachment($event)"
                />
            </div>
        </DragnDropArea>
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import COMMUNICATION_TYPES from '@/store/communication/types';

import { mapGetters } from "vuex";
import { PageHeaderFileSelectAction, PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { uploadFileChunked } from '@/helpers/upload-helper';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import HtmlEditor from '@/components/html-editor/HtmlEditor.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import BemerkungFiles from "@/components/persoenliche-daten/bemerkungen/BemerkungFiles.vue";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        InputField,
        DatePickerField,
        InputToggleSwitch,
        HtmlEditor,
        DragnDropArea,
        BemerkungFiles,
    },
    data() {
        return {
            bemerkungData: {
                id: null,
                datum: null,
                betreff: null,
                text: null,
                important: null,
                sichtbarkeit: null,
            },
            bemerkung: {},
            bemerkungDataChanged: false,
            typ: this.$route.query.typ,
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            isIntern: CORE_TYPES.GETTERS.IS_INTERN,
            isBroker: CORE_TYPES.GETTERS.IS_BROKER,
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
            isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
            isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
            loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
        }),
        sonderHinweisLabel() {
            if (this.isIntern)
                return "Sonderhinweise werden bei jedem Aufruf der Gesellschaft angezeigt.";
            else if (this.isBroker)
                return "Sonderhinweise werden bei jedem Aufruf des Maklers angezeigt."
            else
                return "Sonderhinweise werden bei jedem Aufruf des Kunden angezeigt.";
        },
        bemerkungFiles() {
            return this.bemerkung.files || []
        },
        bemerkungId() {
            return this.bemerkung.id
        },
        headerActions() {
            return [
                PageHeaderFileSelectAction('FILE_SELECT', 'Datei hochladen')
                .withVisible(() => !this.isCustomerOnly),
                PageHeaderSimpleAction('PDF', 'PDF anzeigen'),
            ]
        },
    },
    mounted() {
        this.loadBemerkung()
    },
    methods: {
        loadBemerkung() {
            this.loading = true

            this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_BEMERKUNG, {id: this.$route?.params?.commId, typ: this.typ, gesellschaftId: this.$route.params.gesellId})
                .then(response => {
                    if (!this.$route.params?.commId) {
                        this.$router.push({path: `${this.$route.path}/${response.id}`, query: this.$route.query})
                    }
                    this.bemerkung = response || {}
                    this.bemerkungData = response
                }).finally(() => this.loading = false)
        },
        save() {
            return new Promise((resolve) => {
                if (this.isCustomerOnly) {
                    resolve()
                } else if (this.bemerkungDataChanged) {
                    this.loading = true

                    this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.SAVE_BEMERKUNG, {data: this.bemerkungData, typ: this.typ})
                    .then(() => this.bemerkungDataChanged = false)
                    .finally(() => {
                        this.loading = false
                        resolve()
                    })
                } else {
                    resolve()
                }
            });
        },
        async onFile(files) {
            await this.save()

            const result = files.map(file => {
                return new Promise((resolve, reject) => {
                    uploadFileChunked(file).then(tempFileId => {
                        this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.UPLOAD_BEMERKUNG_ATTACHMENT, { bemerkungId: this.bemerkungId, tempFileId, typ: this.typ})
                        .then(() => resolve())
                        .catch(() => reject())
                    }).catch(() => reject())
                });
            });

            Promise.any(result).then(() => {
                this.loadBemerkung()
            })
        },
        deleteAttachment(fileId) {
            this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.DELETE_BEMERKUNG_ATTACHMENT, {
                bemerkungId: this.bemerkungId,
                fileId,
                typ: this.typ
            }).then(() => {
                const files = this.bemerkung.files || [];
                const fileIndex = files.findIndex((f) => f.fileId === fileId);
                files.splice(fileIndex, 1);
            })
        },
        async showPDF() {
            await this.save()
            const href =`${process.env.VUE_APP_API}/postfachBemerkung/bemerkung-pdf?id=${this.bemerkungId}&typ=${this.typ}&token=${this.loginData.token}`;
            viewDocument({href, filename: 'Bemerkung.pdf', contentType: 'application/pdf'});
        }
    },
    beforeRouteLeave(to, from, next) {
        this.save().finally(() => next())
    },
}
</script>