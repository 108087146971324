<template>
  <button class="button forms__input--padding" 
    :type="type" 
    :class="classObject" 
    :disabled="disabled"
    @click="$emit('click', $event)">
      <slot>{{label}}</slot> 
      <AnimatedSpinner class="ml-1" v-if="animated"/>
  </button>
</template>

<script>
import BRIDGE_TYPES from '@/store/bridge/types'
import { mapGetters } from 'vuex'
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

export default {
  components: {
    AnimatedSpinner,
  },
  props: {
    label:{
      default:'Button label'
    },
    type: {
      default: 'button'
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    isSecondary: {
      type: Boolean,
      default: false
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: false,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    isClear: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT
    }),
    classObject: function() {
      return {
        'button--primary': this.isPrimary && !this.isSecondary && !this.isLink,
        'button--secondary': this.isSecondary,
        'button--disabled': this.disabled,
        'button--mobile-native-size': this.isMobileNativeContext,
        'button--block': this.isBlock,
        'button--link': this.isLink,
        'button--small': this.isSmall,
        'button--clear': this.isClear,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    text-align: center;
    font-size: 1em;
    text-decoration: none;
    display: inline-block;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    line-height: 14px;
  }

  .button--mobile-native-size {
    min-height: 40px;
    min-width: 40px;
  }

  .button--block {
    display: block;
    width: 100%;
  }
  .button--primary {
    background-color: var(--color-primary);
    color: var(--color-primary-text);
    border: 1px solid;
    border-color: var(--color-primary);
  }
  .button--secondary {
    border: 1px solid;
    border-color: var(--color-primary);
    color: var(--color-primary);
    background-color: var(--color-box);
  }
  .button--disabled {
    color: var(--color-workspaces-nav-text);
    opacity: 0.3;
    cursor: not-allowed;
  }
  .button--link {
    color: var(--color-primary);
    background: none;
    border: none;
    padding-left: 0;
  }

  .button--link:hover {
    text-decoration-line: underline;
  }

  .button--small {
    font-size: 0.82rem;
    line-height: 10px;
  }

  .button--clear {
    background: none;
    border: none;
    color: inherit;
    line-height: 1em;
    padding: 0;
    outline: none;

    &.button--link {
      color: var(--color-link);
      transition: color .3s ease;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  .button > .animated-spinner__element,
  .button > svg {
    margin: -4px 0;
  }
</style>