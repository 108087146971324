var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseFileSelect",
    {
      attrs: { isClear: "", accept: _vm.accept, disabled: _vm.disabled },
      on: {
        files: function($event) {
          return _vm.onFileSelection($event)
        }
      }
    },
    [_vm._v(" " + _vm._s(_vm.label) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }