var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "horizontal-bullet__container d-flex" }, [
    _c("div", {
      staticClass: "horizontal-bullet__progress",
      class: [_vm.activeColorClass],
      style: { width: _vm.percentageDone + "%" }
    }),
    _vm.percentageDone < 100
      ? _c("div", {
          staticClass: "horizontal-bullet__available",
          style: { width: _vm.percentageAvailable + "%" }
        })
      : _vm._e(),
    _c("div", {
      staticClass: "horizontal-bullet__average",
      style: { left: _vm.percentageAverage + "%" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }