var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        today: _vm.istoday && _vm.shouldPaint,
        not_in_month: !_vm.inmonth
      }
    },
    [
      _c("div", { staticClass: "innerday" }, [
        _c("div", { staticClass: "week-events" }, [
          _vm.isAllDay
            ? _c(
                "div",
                {
                  class: {
                    upper: true,
                    "all-day-upper": _vm.isAllDay,
                    "upper-full-height": _vm.isAllVisible
                  }
                },
                [
                  _vm.eventsReady
                    ? _c(
                        "div",
                        {
                          staticClass: "allday-part",
                          on: {
                            click: function($event) {
                              return _vm.editEventNew(true)
                            },
                            touchstart: function($event) {
                              return _vm.touchstart(true)
                            }
                          }
                        },
                        [
                          _vm._l(_vm.allDayEventsVisible, function(
                            alldayevent,
                            index
                          ) {
                            return _c(
                              "ExpandedEventView",
                              {
                                key: index,
                                attrs: {
                                  event: alldayevent,
                                  isOneLineContainer: ""
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      "allday-events",
                                      "clickable",
                                      "event-" + alldayevent.type,
                                      {
                                        "has-repetition": alldayevent.repetition
                                      }
                                    ],
                                    style: {
                                      "background-color": _vm.isSelected(
                                        alldayevent
                                      )
                                        ? alldayevent.color
                                        : alldayevent.lighterColor
                                    },
                                    attrs: { "data-event-view": "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setSelectedEvent(
                                          alldayevent,
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "event-cell-before",
                                      style: {
                                        "background-color": alldayevent.color
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "allday-event--wrap sameline"
                                      },
                                      [
                                        alldayevent.introIcon
                                          ? _c(alldayevent.introIcon, {
                                              tag: "component"
                                            })
                                          : alldayevent.startTime !== "00:00" &&
                                            alldayevent.startTime !== "23:59"
                                          ? _c("span", [
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      alldayevent.startTime
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "event-label" },
                                          [_vm._v(_vm._s(alldayevent.label))]
                                        ),
                                        alldayevent.repetition
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "allday-repetition-icon"
                                              },
                                              [
                                                _c("PhArrowsClockwise", {
                                                  attrs: { size: "16" }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          _vm.isShowMore
                            ? _c("div", { staticClass: "timed-events" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-clear clickable",
                                    attrs: { type: "button" },
                                    on: { click: _vm.handleShowMore }
                                  },
                                  [
                                    !_vm.isAllVisible
                                      ? [
                                          _vm._v(
                                            _vm._s(_vm.moreCount) +
                                              " weitere..."
                                          )
                                        ]
                                      : [_vm._v("Reduzieren")]
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.eventsReady && !_vm.isAllDay
            ? _c(
                "div",
                { staticClass: "timed-part" },
                _vm._l(_vm.hours, function(hour, idx) {
                  return _c("WholeHourCell", {
                    key: idx,
                    staticClass: "whole-hour-cell",
                    style: _vm.setStyle,
                    attrs: {
                      hour: hour,
                      date: _vm.date,
                      daylabel: _vm.daylabel,
                      maxconcurrent: _vm.getConcurrentEvents,
                      dayindex: _vm.dindex,
                      allHourEvents: _vm.allHourEvents
                    },
                    on: { editEvent: _vm.editEvent },
                    nativeOn: {
                      click: function($event) {
                        return _vm.editEventNew(false, hour)
                      },
                      touchstart: function($event) {
                        return _vm.touchstart(false, hour)
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }