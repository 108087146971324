import FEE_TYPES from './types';

import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from "@/helpers/log-message-helper";

import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {

  async [FEE_TYPES.ACTIONS.FIND_FEE_PROPERTIES_COMBOS]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/feePropertiesCombos`, config);
    commit(FEE_TYPES.MUTATIONS.SET_FEE_PROPERTIES_COMBOS, response?.data || {});
  },

  async [FEE_TYPES.ACTIONS.GET_FEE_PROPERTIES]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/feeProperties`, config);
    commit(FEE_TYPES.MUTATIONS.SET_FEE_PROPERTIES, response?.data || {});
  },

  async [FEE_TYPES.ACTIONS.SAVE_FEE_PROPERTIES]({ getters, commit, }) {
    const edited = getters[FEE_TYPES.GETTERS.FEE_PROPERTIES_EDITED];
    if(!edited) return;

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/feeProperties`, edited, config);
    commit(FEE_TYPES.MUTATIONS.RESET_FEE_PROPERTIES_EDITED);
    commit(FEE_TYPES.MUTATIONS.SET_FEE_PROPERTIES, response?.data || {});

    if (!response?.message) {
      commit(FEE_TYPES.MUTATIONS.SET_FEHLERHAFT, false);
    } else {
      commit(FEE_TYPES.MUTATIONS.SET_FEHLERHAFT, true);
    }

  },

  async [FEE_TYPES.ACTIONS.COPY_FEE_PROPERTIES]({ getters, dispatch, commit, }) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/copyFeeProperties`, {}, config);
    await dispatch(FEE_TYPES.ACTIONS.GET_FEE_PROPERTIES);
    commit(FEE_TYPES.MUTATIONS.RESET_FEE_PROPERTIES_EDITED);

    if(response?.data) {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'info'));
    }
  },

  async [FEE_TYPES.ACTIONS.DELETE_FEE_PROPERTIES]({ getters, dispatch, commit, }) {
    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/feeProperties`, config);
    await dispatch(FEE_TYPES.ACTIONS.GET_FEE_PROPERTIES);
    commit(FEE_TYPES.MUTATIONS.RESET_FEE_PROPERTIES_EDITED);
  },

  async [FEE_TYPES.ACTIONS.FIND_ABRECHNUNGEN]({ getters, commit, }, { forceReload = false } = {}) {
    if(getters[FEE_TYPES.GETTERS.IS_ABRECHNUNGEN_LOADED] && !forceReload) {
      return;
    }

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/abrechnungen`, config);
    commit(FEE_TYPES.MUTATIONS.SET_ABRECHNUNGEN, response?.data || []);
  },

  async [FEE_TYPES.ACTIONS.FIND_KUNDE_HONORARE]({ getters, commit, }, { forceReload = false } = {}) {
    if(getters[FEE_TYPES.GETTERS.IS_KUNDE_HONORARE_LOADED] && !forceReload) {
      return;
    }
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/kundeHonorare`, config);
    commit(FEE_TYPES.MUTATIONS.SET_KUNDE_HONORARE, response?.data ||[]);
  },

  async [FEE_TYPES.ACTIONS.CALC_KUNDE_HONORARE]({ getters, commit, }, payload) {
    if(!payload) return;

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/calcKundeHonorare`, payload, config);
    commit(FEE_TYPES.MUTATIONS.SET_CALC_KUNDE_HONORARE_DATA, response?.data || {});
  },

  async [FEE_TYPES.ACTIONS.SAVE_KUNDE_HONORARE]({ getters, dispatch, }, payload) {
    if(!payload) return;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/kundeHonorare`, payload, config);
    dispatch(FEE_TYPES.ACTIONS.FIND_KUNDE_HONORARE, { forceReload: true, });
  },

  async [FEE_TYPES.ACTIONS.DELETE_KUNDE_HONORARE]({ getters, dispatch }, { id, }) {
    if(!id) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/kundeHonorare/${id}`, config);
    dispatch(FEE_TYPES.ACTIONS.FIND_KUNDE_HONORARE, { forceReload: true, });
  },

  async [FEE_TYPES.ACTIONS.FIND_KUNDE_BANKVERBINDUNG_DATA]({ getters, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fee/kundeBankverbindungData`, config);

    return response?.data || {};
  },

}
