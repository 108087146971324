var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container" },
    [
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row mt-0" }, [
          _c("div", { staticClass: "col" }, [
            _vm.title
              ? _c(
                  "div",
                  {
                    staticClass:
                      "\n            input-forms__label-content input-forms__label-content--bigger\n          "
                  },
                  [
                    _c("PhBank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.title) + " ")
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _c("Table", {
        attrs: {
          headers: _vm.headers,
          rows: _vm.rows,
          rowId: "isin",
          hidePagination: ""
        },
        scopedSlots: _vm._u([
          {
            key: "betrag",
            fn: function(row) {
              return [
                _c("InputField", {
                  attrs: {
                    value: row.betrag,
                    id: "betrag" + row.index,
                    type: "currency"
                  },
                  on: {
                    input: function($event) {
                      return _vm.updatePositionData($event, row, "betrag")
                    }
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }