<template>
  <div>
    <BaseModal
      ref="prohypDocumentsModal"
      modalTitle="Prohyp-Dokument Auswählen"
      labelButtonConfirm="Übernehmen"
      :confirmDisabled="!selectedRow"
      :confirmLoading="confirmLoading"
      :autoClose="false"
      size='xl'
      @onConfirmButton="uploadProhypDocument"
    >
      <GhostLoading v-if="loading" type="table" />
      <Table v-else
          :headers="headers"
          :rows="rows"
          :rowsPerPage="10"
          @action-CONFIRM="$emit('confirm', $event)"
      >
        <template #selected="row">
          <InputRadioBoxItem
            name="selectFonds"
            @change="selectedRow = row"
          />
        </template>
      </Table>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, DateColumn, IconColumn, Icon} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputRadioBoxItem from '@/components/core/forms/radiobox/InputRadioBoxItem';

export default {
  components: {
    Table,
    BaseModal,
    GhostLoading,
    InputRadioBoxItem,
  },
  props: {
    nodeId: {
      type: [String, Number],
      default: null,
    },
    documentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileId: null,
      loading: false,
      confirmLoading: false,
      selectedRow: null,
    };
  },
  computed: {
    ...mapGetters({
      prohypDocuments: DOKUMENTENARCHIV_TYPES.GETTERS.PROHYP_DOCUMENTS,
    }),

    headers() {
        const headers = {
            lockedLeft: [
              SlotColumn("selected", ""),
            ],
            center: [
              TextColumn("name", "Name"),
              DateColumn("receptionDate", "Erstellt am"),
              TextColumn("categoryInstance", "Kategorie"),
              IconColumn("completeIcon", "Abgeschlossen"),
              TextColumn("order", "Reihenfolge"),
              IconColumn("irrelevantIcon", "Irrelevant"),
              TextColumn("documentId", "DocumentId"),
              TextColumn("note", "Bezeichnung"),
              TextColumn("weight", "Wichtigkeit"),
            ],
            lockedRight: [],
        };

        return headers
    },
    rows() {
      return this.prohypDocuments.map(row => ({
        ...row,
        completeIcon: row.complete ? [Icon('PhCheck', null, 16, "bold", "color-success")] : [Icon('PhX', null, 16, "bold", "color-danger")],
        irrelevantIcon: row.irrelevant ? [Icon('PhCheck', null, 16, "bold", "color-success")] : [Icon('PhX', null, 16, "bold", "color-danger")],
      }));
    },
  },
  methods: {
    open(fileId) {
      this.fileId = fileId
      this.loadProhypDocuments(fileId)
      this.$refs.prohypDocumentsModal.open()
    },
    loadProhypDocuments(fileId) {
      this.loading = true;

      this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.GET_PROHYP_DOCUMENTS, {
        fileId,
        nodeId: this.nodeId,
        documentId: this.documentId,
      }).finally(() => this.loading = false)
    },
    uploadProhypDocument(row = this.selectedRow) {
      this.confirmLoading = true;

      this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_PROHYP_UPLOAD, {
        ehypForDocumentId: row.documentId,
        fileId: this.fileId,
        nodeId: this.nodeId,
        documentId: this.documentId,
      }).then(() => {
        this.selectedRow = null
        this.fileId = null
        this.$refs.prohypDocumentsModal.close()
        this.$emit('selectionDone')
      }).finally(() => this.confirmLoading = false)
    },
  },
}
</script>
