<template>
  <GhostLoading>
    <Block v-if="showTitle" type="title" :style="{ margin: '0 0 16px 0', }" />
    <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
    <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
    <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
    <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
    <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
    <Block height="40px" class="mb-0" />
  </GhostLoading>
</template>

<script>
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GhostLoading,
    Block,
  },
}
</script>
