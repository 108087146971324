<template>
    <div>
        <PageHeaderTitleNavigation title="Steuerliche Daten" />

        <div class="box__container">
            <div class="box__title">Auswahl der steuerlichen Daten</div>
            <div>{{subtitle}}</div>
        </div>

        <BaseFilter :filterId="saveKey" 
            title="Geben Sie an, ab welchem Datum die steuerliche Daten angezeigt werden sollen" 
            :metadata="searchMenu" :configFilter="configFilter"
            :defaultOptions="defaultOptions" 
            @onFilter="handleSearch($event)"
            @onFilterZurucksetzen="$emit('onFilterZurucksetzen')">
            <slot></slot>
        </BaseFilter>

        <div class="box__container">
            <div v-if="rows && rows.length">
                <Table
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    :exportConfig="{roottext: 'Steuerliche Daten'}"
                    rowId="steuerlicheDaten"
                    @click-kundenName="openCustomerNewTab"
                >
                </Table>
            </div>
            <NoData v-else content="Keine Informationen" />
        </div>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn } from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import BERICHTE_TYPES from '@/store/berichte/types';
import mixin from '@/mixins/limit/limit-mixin.js';
import CORE_TYPES from '@/store/core/types';
import BaseFilter from '@/components/core/BaseFilter.vue';
import NoData from '@/components/core/NoData.vue';

export default {
    mixins: [mixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        Table,
        BaseFilter,
        NoData,
    },
    data() {
        return {
            saveKey: "steuerlicheDatenSearchAdvanced",
            configFilter: { 
                noResetOnDefaultSearchInputExit: true,
            },
        }
    },
    mounted() {
        this.resetFilters();
        this.$store.dispatch(BERICHTE_TYPES.ACTIONS.GET_STEUERLICHE_DATEN);
    },
    computed: {
        ...mapGetters({
            list: BERICHTE_TYPES.GETTERS.STEUERLICHE_DATEN,
            savedFilters: BERICHTE_TYPES.GETTERS.FILTERS_STEUERLICHE_DATEN,
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
        }),
        headers() {
            const headers = { 
                lockedLeft: [
                    DateColumn('buchungsDatum', 'Buchungsdatum'),
                ],
                center: [
                    TextColumn('kontonr', 'Kontonr'),
                    TextColumn('isin', 'ISIN'),
                    TextColumn('fondsname', 'Fondsname'),
                    TextColumn('ertragsart', 'Ertragsart'),
                    TextColumn('ertrag', 'Steuerplichtiger Ertrag'),
                    TextColumn('kest', 'Kest'),
                    TextColumn('solz', 'Solz'),
                    TextColumn('kist', 'Kist'),
                    TextColumn('kest2', 'Kest MitInh.'),
                    TextColumn('solz2', 'Solz MitInh.'),
                    TextColumn('kist2', 'Kist MitInh.'),
                    TextColumn('gesamt', 'Gesamtsteuerdaten'),
                    TextColumn('lagerstelle', 'Lagerstelle'),
                    TextColumn('gesellschaft', 'Depot-Gesellschaft'),
                ],
            };
            if (!this.isCustomer) {
                headers.lockedLeft.splice(1, 0, 
                    TextColumn('maklernr', 'Vermittlernr'),
                    TextColumn('kundennr', 'Kundennr').makeAlwaysVisible(),
                    TextColumn('kundenName', 'Kunde').makeLink()
                );
            }
            return headers;
        },
        rows() {
            return this.list || [];
        },
        searchMenu() {
            const menuItems = [
                {
                    type: 'default',
                    label: 'ab letztem Monat',
                    key: 'lastMonth',
                },
                {
                    type: 'dateRange',
                    label: 'zwischen',
                    key: 'dateRange',
                },
                {
                    type: 'default',
                    label: 'dieses Jahr',
                    key: 'thisYear',
                },
            ];
            if (!this.isCustomer) {
                menuItems.push({
                    type: 'combobox',
                    label: 'Listenauswahl',
                    key: 'statementIdent',
                    comboOptions: [ 
                        {
                            label: 'eigene Kunden',
                            value: 'eigene',
                        },
                        {
                            label: 'Kunden der Struktur',
                            value: 'unterstruktur',
                        },
                        {
                            label: 'alle Kunden',
                            value: 'alle',
                        }]
                })
            }
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: menuItems,
                }
            ]
        },
        filters() {
            return this.savedFilters || {};
        }, 
        defaultOptions() {
            const filters = {};
            Object.keys(this.initialFilters).forEach(key => filters[key] = { value: this.initialFilters[key]});
            return filters;
        },
        subtitle() {
            return this.isCustomer ? 
                'Diese Liste gibt Ihnen einen Überblick über Ihre steuerlichen Daten. Zur weiteren Verwendung können Sie diese herunterladen oder ausdrucken.'
                : 'Diese Liste gibt Ihnen einen Überblick über die steuerlichen Daten Ihrer Kunden. Zur weiteren Verwendung können Sie diese herunterladen oder ausdrucken. Mit \"eigene Kunden\" zeigen Sie die steuerlichen Daten Ihrer Kunden an, über \"Kunden der Struktur\" die steuerlichen Daten der Kunden Ihrer Mitarbeiter und über \"alle Kunden\" die steuerlichen Daten Ihrer und die Kunden Ihrer Mitarbeiter.' 
        },
        initialFilters() {
            const filter = { lastMonth: true };
            if (!this.isCustomer) {
                filter['statementIdent']  = 'eigene';
            }
            return filter;
        },
    },
    methods: {
        handleSearch(filterArr = []) {
            const filters = {};
            filterArr.forEach(filter => filters[filter.key] = filter.value);
            this.$store.commit(BERICHTE_TYPES.MUTATIONS.SET_FILTERS_STEUERLICHE_DATEN, filters);
            this.$store.dispatch(BERICHTE_TYPES.ACTIONS.GET_STEUERLICHE_DATEN);
        },
        resetFilters() {
            this.$store.commit(BERICHTE_TYPES.MUTATIONS.SET_FILTERS_STEUERLICHE_DATEN, this.initialFilters);
        },
    },
}
</script>

<style>

</style>