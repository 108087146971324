var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: { labelButtonConfirm: "Annehmen", labelButtonCancel: "Ablehnen" },
      on: { onConfirmButton: _vm.acceptCall, onCancelButton: _vm.rejectCall },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [_vm._v(" Eingehender Anruf ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("strong", [_vm._v(" " + _vm._s(_vm.displayName) + " ")]),
      _vm._v(" ruft an. ")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }