<template>
  <div>
    <GesellschaftTitleNavigation/>

    <BaseFilter 
      title="Änderungslog Filter" 
      :metadata="metadata" 
      :configFilter="configFilter"
      hasSmartSearch
      @onFilter="doFilter"
    />

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
      <Table 
        v-else
        :title="TABLE_TITLE"
        :rows="rows"
        :headers="headers"
        :rowsPerPage="20"
        @click-entry="openTextModal"
      />
    </div>
  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { mapGetters } from 'vuex';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateTimeColumn } from "@/components/table2/table_util.js";
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';

const TABLE_TITLE = 'Änderungslog';

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    BaseFilter,
    GhostLoading,
    Table,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      configFilter: {
          placeholderForDefSearchInput: 'Suche nach Änderung',
          checkDefaultSearchInput: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    headers() {
      return {
        lockedLeft: [
          DateTimeColumn("date", "Datum"),
          TextColumn("user", "Nutzer"),
          TextColumn("entry", "Eintrag").makeLink(),
          TextColumn("text", "Änderung"),
        ],
        center: [],
        lockedRight: [],
      }
    },
    rows() {
      return this.gesellschaft.aenderungslog || []
    },
    metadata() {
      return [
        {
          type: "group",
          key: "allgemein",
          label: "Allgemein",
          menuItems: [
            {
              type: "datepicker",
              label: "Ab Datum",
              key: "datumAb",
            },
          ],
        },
      ]
    },
  },
  mounted() {

  },
  methods: {
    doFilter(filterParams) {
      let params = {};
      filterParams.forEach(fc => {
        if (fc.key === "datumAb") {
          params[fc.key] = DatePickerUtils.toDateStr(fc.value)
        } else {
          params[fc.key] = fc.value
        }
      })

      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_AENDERUNGSLOG, { gesellschaftId: this.$route.params.gesellId, ...params })
      .finally(() => this.loading = false);
    },
    openTextModal(row) {
        this.$confirmModal({
          title: 'Änderungslog', 
          message: row.htmlText, 
          labelButtonConfirm: 'Ok',
          showCancelButton: false,
        })
    },
  },
}
</script>