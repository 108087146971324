<template>
  <div class="label lanzahl">
    <div class="result">{{getAnzahl}}</div>
    <div class="truelabel">Anzahl</div>
  </div>
</template>

<script>
export default {
  name: "LAnzahl",
  computed: {
    getAnzahl() {
      return this.$store.getters.getAnzahl;
    }
  },
};
</script>

<style scoped>
.lanzahl {
  grid-area: lanzahl;
}
</style>