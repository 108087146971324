import FILEUPLOAD_TYPES from './types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';
import { uploadFilesChunked } from '@/helpers/upload-helper';

export default {
 async [FILEUPLOAD_TYPES.ACTIONS.UPLOAD_PDF]({ commit, state, dispatch, rootState }, dat) {
    const config = {
      defaultSpinner: true,
    };
    //commit(FILEUPLOAD_TYPES.MUTATIONS.UPLOAD_PDF);
    let path='/Barkodes/PutDokument?tempFileId=' + dat.tempFileId;
    if(dat.eingangId){
       path=path+"&eingangId="+dat.eingangId;
    }
    await axios.post(rootState.core.apiAddress + path, {}, config).then(response => {
      if (!response.data?.errorMessage) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Der Upload wurde mit Status "' + response.data.status + '" abgeschlossen.', 'success'));
        commit(FILEUPLOAD_TYPES.MUTATIONS.UPLOAD_PDF_SUCCESS, response.data);
      } else {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(`${response.data.errorTitle || ''}${response.data.errorTitle ? ': ' : ''}${response.data.errorMessage}`, 'danger'));
      }
    }).catch(error => {
      //commit(FILEUPLOAD_TYPES.MUTATIONS.UPLOAD_PDF_SUCCESS);
      if (error && error.response && error.response.data && error.response.data.message)
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
      else {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "UPLOAD_PDF", error})
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim Scannen des PDF Dokuments ist ein unerwarteter Fehler aufgetreten.", 'danger'));
      }
    })
  },


  async [FILEUPLOAD_TYPES.ACTIONS.GET_EINGANG]({ commit, state, dispatch, rootState }, eingangId) {
    const config = {
      defaultSpinner: true,
    };
    commit(FILEUPLOAD_TYPES.MUTATIONS.UPLOAD_PDF);
    axios.get(rootState.core.apiAddress + '/Barkodes/eingang?eingangId=' + eingangId, {}, config).then(response => {
      if (!response.data?.errorMessage) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Der Upload wurde mit Status "' + response.data.status + '" abgeschlossen.', 'success'));
        commit(FILEUPLOAD_TYPES.MUTATIONS.UPLOAD_PDF_SUCCESS, response.data);
      } else {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(`${response.data.errorTitle || ''}${response.data.errorTitle ? ': ' : ''}${response.data.errorMessage}`, 'danger'));
      }
    }).catch(error => {
      commit(FILEUPLOAD_TYPES.MUTATIONS.UPLOAD_PDF_SUCCESS);
      if (error && error.response && error.response.data && error.response.data.message)
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
      else {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "UPLOAD_PDF", error})
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim Scannen des PDF Dokuments ist ein unerwarteter Fehler aufgetreten.", 'danger'));
      }
    })
  },



  
  [FILEUPLOAD_TYPES.ACTIONS.UPLOAD_IMAGE]({ commit, state, dispatch, rootState }, tempFileId) {
    const config = {
      defaultSpinner: true,
    };
    axios.get(rootState.core.apiAddress + '/image_upload/where?tempFileId=' + tempFileId, config).then(response => {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Der Upload wurde mit Status "' + response.data.status + '" abgeschlossen.', 'success'));
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message)
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
      else {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "UPLOAD_PDF", error})
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim Upload des Bilds ist ein Fehler aufgetreten.", 'danger'));
      }
    })
  },

  [FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_TOGGLE_ACTIVE]({ commit, state, dispatch, rootState }, {eingangId, pageNo, active}) {
    const config = {
      defaultSpinner: true,
    };
    axios.post(rootState.core.apiAddress + '/Barkodes/page_activate?eingangId=' + eingangId + '&pageNo=' + pageNo + '&active=' + active, {}, config).then(response => {
      commit(FILEUPLOAD_TYPES.MUTATIONS.UPDATE_PDF_PAGE, response.data);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message)
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
      else {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "PDF_PAGE_TOGGLE_ACTIVE", error})
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim " + (active ? "Wiederherstellen" : "Löschen") + " der Seite ist ein unerwarteter Fehler aufgetreten.", 'danger'));
      }
    })
  },

  async [FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_CHANGE_BARCODE]({ commit, state, dispatch, rootState }, {eingangId, rownum, pageNo, barcode}) {
    const config = {
      defaultSpinner: true,
    };
    let url = rootState.core.apiAddress + '/Barkodes/setBarcode?eingangId=' + eingangId + '&rownum=' + rownum + '&barcode=' + barcode;
    if (pageNo != null && pageNo != "null")
      url += '&pageNo=' + pageNo;
    try {
        const response = await axios.post(url, {}, config);
        commit(FILEUPLOAD_TYPES.MUTATIONS.UPDATE_PDF_PAGE, response.data);
    } catch(error) {
      if (error && error.response && error.response.data && error.response.data.message)
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
      else {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "PDF_PAGE_CHANGE_BARCODE", error})
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim Ändern des Barcodes ist ein unerwarteter Fehler aufgetreten.", 'danger'));
      }
    }
  },

  [FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_SHOW]({ commit, state, dispatch, rootState }, {eingangId, pageNo}) {
    const config = {
      defaultSpinner: true,
      responseType: 'arraybuffer',
    };
    axios.get(rootState.core.apiAddress + '/Barkodes/page_download?eingangId=' + eingangId + '&pageNo=' + pageNo, config).then(response => {
      const file = new File([response.data], "page" + pageNo + ".pdf", {type: "application/pdf"})
      window.open(URL.createObjectURL(file))
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message)
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
      else {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "PDF_PAGE_SHOW", error})
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim Aufruf der PDF Seite ist ein unerwarteter Fehler aufgetreten.", 'danger'));
      }
    })
  },

  [FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_SET_DECKBLATT]({ commit, state, dispatch, rootState }, eingangId) {
    const config = {
      defaultSpinner: true,
    };

    return new Promise((resolve, reject) => { 
      axios.post(rootState.core.apiAddress + '/Barkodes/set_deckblatt?eingangId=' + eingangId, {}, config).then(response => {
        commit(FILEUPLOAD_TYPES.MUTATIONS.UPDATE_PDF_PAGE, response.data);
        commit(FILEUPLOAD_TYPES.MUTATIONS.UPDATE_PDF_DECKBLATT);
        resolve(response.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message)
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
        else {
          dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "PDF_PAGE_SET_DECKBLATT", error})
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim Einfügen fehlender Barcodes ist ein unerwarteter Fehler aufgetreten.", 'danger'));
        }
        reject();
      })


    });


  },

  [FILEUPLOAD_TYPES.ACTIONS.PDF_PAGE_SET_NEXT_BARCODES]({ commit, state, dispatch, rootState }, eingangId) {
    const config = {
      defaultSpinner: true,
    };

    return new Promise((resolve, reject) => { 
      axios.post(rootState.core.apiAddress + '/Barkodes/set_next_barcodes?eingangId=' + eingangId, {}, config).then(response => {
        commit(FILEUPLOAD_TYPES.MUTATIONS.UPDATE_PDF_PAGE, response.data);
        commit(FILEUPLOAD_TYPES.MUTATIONS.UPDATE_PDF_DECKBLATT);
        resolve(response.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message)
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
        else {
          dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "PDF_PAGE_SET_NEXT_BARCODES", error})
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim Einfügen fehlender Barcodes ist ein unerwarteter Fehler aufgetreten.", 'danger'));
        }
        reject();
      })

    });



  },



 async  [FILEUPLOAD_TYPES.ACTIONS.SEND_PDF]({ commit, state, dispatch, rootState }, {eingangId, noSend,noCutDeckblatt}) {
    const config = {
      defaultSpinner: true,
      disableDefaultErrorMessage: true,
    };
   await axios.post(rootState.core.apiAddress + '/Barkodes/send?eingangId=' + eingangId + '&noSend=' + noSend+'&noCutDeckblatt=' + noCutDeckblatt, {}, config).then(response => {
      if(response?.data?.error) {
        let actions = undefined
        if (response?.data?.message == 'Fehler bei der Einreichung per Webservice'){
          actions = [{
            label: 'Versandprotokolle anzeigen',
            isPrimary: true,
            path: `/communication/versandprtokolle/eingang/${eingangId}`
          }]
        }

        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Die Datei konnte nicht zugeordnet werden. Bitte beheben Sie Fehler oder wiederholen Sie den Vorgang! ', 'danger', true, undefined, actions));
      } else {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.status, 'success'));
        commit(FILEUPLOAD_TYPES.MUTATIONS.REMOVE_PDF, {eingangId, keepAsCollated: true});
        commit(FILEUPLOAD_TYPES.MUTATIONS.RESULT_TABLE,response.data.resultTable);
    }
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message)
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
      else {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SEND_PDF", error})
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim Zuordnen ist ein unerwarteter Fehler aufgetreten.", 'danger'));
      }
    })
  },

  async [FILEUPLOAD_TYPES.ACTIONS.GET_DECKBLATT_COMBOS]({ commit, rootState, state}, { customerID, }) {
    if(!customerID || customerID in state.deckblattCombos) return;

    const config = {
      defaultSpinner: true,
    };
    const response = await axios.get(`${rootState.core.apiAddress}/Barkodes/deckblattCombos`, { 
      ...config,
      params: {
        kundennr: customerID,
      },
    });
    commit(FILEUPLOAD_TYPES.MUTATIONS.GET_DECKBLATT_COMBOS_SUCCESS, {
      [customerID]: response?.data || {}
    });
  },

  async [FILEUPLOAD_TYPES.ACTIONS.GET_COMBO_VALUES]({ commit, rootState, state }, { customerID, type, ids }) {
    if(!customerID) return;

    const key = `${customerID}_${type}_${ids.join('_')}`;
    if(key in state.comboValues) return;

    const params = `kundennr=${customerID}&type=${type}&${ids.map(id => `ids=${id}`).join('&')}`;
    const config = {
      defaultSpinner: true,
    };
    const response = await axios.get(`${rootState.core.apiAddress}/Barkodes/comboValues?${params}`, config);
    commit(FILEUPLOAD_TYPES.MUTATIONS.GET_COMBO_VALUES_SUCCESS, {
      [key]: [ ...response?.data || [] ],
    });
  },

  async [FILEUPLOAD_TYPES.ACTIONS.UPLOAD_DECKBLATT]({ rootState, dispatch }, { customer, files, payload, }) {
    return new Promise((resolve, reject) => {
      const { customerID, name } = customer;
      if(!customerID || !files?.length) {
        reject();
        return;
      }

      uploadFilesChunked(files).then(async (tempFilesId) => {
        const tempFilesIdParam = tempFilesId.map(tempFileId => `tempFilesId=${tempFileId}`).join('&');
        const config = {
          defaultSpinner: true,
        };
        try {
          await axios.post(`${rootState.core.apiAddress}/Barkodes/uploadDeckblatt?kundennr=${customerID}&${tempFilesIdParam}`, payload, config);
          const customerMessage = `${name} (${customerID})`;
          const message = files.length > 1 ? `Die Dokumente wurden dem Kunde ${customerMessage} zugeordnet` : `Das Dokument wurde dem Kunde ${customerMessage} zugeordnet`;
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'success'));
          resolve();
        } catch(e) {
          reject();
        }
      });
    });
  },

}