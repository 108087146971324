var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-search__result-icon" }, [
    _vm.isKSC
      ? _c(
          "span",
          [
            _c("ph-monitor", { attrs: { size: 16 } }),
            _vm._v(" " + _vm._s(_vm.dateKSCNutzung) + " ")
          ],
          1
        )
      : _vm._e(),
    _vm.isKSC && _vm.isAPP ? _c("br") : _vm._e(),
    _vm.isAPP
      ? _c(
          "span",
          [
            _c("ph-device-mobile-speaker", { attrs: { size: 16 } }),
            _vm._v(" " + _vm._s(_vm.dateAppNutzung) + " ")
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }