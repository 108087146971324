<template>
  <BaseModal ref="updaterModal" 
    modalTitle=""
    labelButtonCancel="Schliessen"
    labelButtonConfirm="Abmelden"
    @onConfirmButton="refreshApp">
    Es gibt eine neues Update. Bitte melden Sie sich ab und erneut an!
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue'
import { mapGetters } from 'vuex'
import BRIDGE_TYPES from '@/store/bridge/types'
import templateRoutes from '@/views/cms/templateRoutes'
import { LIVE_UPDATE } from '@/configs/registerServiceWorker.js'

const PATH_ALLOWED_TO_REFRESH = [
  '/',
  '/login',
  '/login/nc',
  /\/page\//,
]

export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },

  watch: {
    updateExists(updateExists) {
      if (updateExists && !this.mobileNativeContext) {
        this.updateHandler();
      }
    },
    isAllowedToAutomaticallyRefresh(isAllowedToAutomaticallyRefresh) {
      if (this.updateExists && isAllowedToAutomaticallyRefresh) {
        this.refreshApp()
      }
    }
  },

  computed: {
    ...mapGetters({
      mobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT
    }),
    pathAllowedToRefresh() {
      return [
        ...PATH_ALLOWED_TO_REFRESH,
        ...templateRoutes.map(route => `/${route.path}`)
      ]
    },
    isAllowedToAutomaticallyRefresh() {
      return this.pathAllowedToRefresh.some(path => (path.test && path.test(this.$route.path)) || path === this.$route.path)
    },
    pathNameWithUpdatedFlag() {
      if (window.location.search.includes('swUpdated')) {
        return `${window.location.pathname}${window.location.search}`
      }

      const paramSeparator = window.location.search ? '&' : '?'
      return `${window.location.pathname}${window.location.search}${paramSeparator}swUpdated=true`
    }
  },

  methods: {
    updateHandler() {
      if (this.isAllowedToAutomaticallyRefresh) {
        this.refreshApp()
      } else {
        this.$refs.updaterModal.open()
      }
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return
      }

      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },

  created() {
    if (LIVE_UPDATE) {
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })
  
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) {
          return
        }
  
        this.refreshing = true
        window.location.replace(this.pathNameWithUpdatedFlag)
      })
    }
  },

  components: {
    BaseModal,
  }

}
</script>

