import E_MAIL_ACCESS_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from "@/store/log/types";
import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';
import { setSessionObject } from '@/helpers/local-storage-helper'

const config = {
  defaultSpinner: true
};

const SERVICE_PATH = '/email/eingang_settings'

export default {
  async [E_MAIL_ACCESS_TYPES.ACTIONS.GET_E_MAIL_SERVER]({ commit, getters }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/getMailServers`, config );
    
    if (response.data) {
      commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_E_MAIL_SERVER, response.data);
    }
  },
  
  [E_MAIL_ACCESS_TYPES.ACTIONS.GET_MS_GRAPH_EMAIL_DATA_OUTPUT]({ commit, getters }) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/getOutputMailServerData`, config ).then(response => {
      if (response.data) {
        commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_MS_GRAPH_EMAIL_DATA, response.data);
      }
    });
  },

  async [E_MAIL_ACCESS_TYPES.ACTIONS.GET_MS_GRAPH_EMAIL_DATA]({ commit, getters }, sid) {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/getMailServerData/${sid}`, config );
      
      if (response.data) {
        commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_MS_GRAPH_EMAIL_DATA, response.data);
      }
    },

  async [E_MAIL_ACCESS_TYPES.ACTIONS.SET_E_MAIL_SERVER]({ commit, getters, dispatch }, data) {

    let payload = {...data};
    if (payload.actions) {
      payload.actions = [];
    }
    if (payload.type) {
      payload.redirectUri = location.origin + location.pathname;
    }
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/setMailServers`, payload, config ).then(response => {
      if (response.data) {
        if (response.data.redirectUri) {
          const loginData = getters[CORE_TYPES.GETTERS.GET_LOGIN_DATA];
          setSessionObject('deepLink-loginData', loginData);
          location.href = response.data.redirectUri;
        } else {
          commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_MS_GRAPH_EMAIL_DATA, response.data);
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Der E-Mail Server wurde erfolgreich hinzugefügt", 'primary', false));
        }
      }
    }).catch(error => {
      if(error?.response?.data?.message?.includes('Es existiert bereits ein Eintrag')) {
        commit(E_MAIL_ACCESS_TYPES.MUTATIONS.UPDATE_MS_GRAPH_EMAIL_DATA, { microsoftConfirmed: true });
      }
    });
  },

  async [E_MAIL_ACCESS_TYPES.ACTIONS.SET_E_MAIL_OUTPUT_SERVER]({ getters, dispatch, commit }, {data, pathname = '', changeStep = false}) {
    return new Promise((resolve, reject) => {
    let payload = {...data};
    if (payload.actions) {
      payload.actions = [];
    }
    if (payload.type) {
      payload.redirectUri = location.origin + (pathname || location.pathname);
    }
    axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/setMailOutputServer/${changeStep}`, payload, config ).then(response => {
      if (response.data) {
        if (response.data.redirectUri) {
          const loginData = getters[CORE_TYPES.GETTERS.GET_LOGIN_DATA];
          setSessionObject('deepLink-loginData', loginData);
          location.href = response.data.redirectUri;
          resolve(false)
        } else {
          commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_MS_GRAPH_EMAIL_DATA, response.data);
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Der E-Mail Ausgang Server wurde erfolgreich hinzugefügt oder geändert.", 'primary', false));
          resolve(true)
        }
      } else {
        resolve(true)
      }
    });
  });
  },

  async [E_MAIL_ACCESS_TYPES.ACTIONS.DELETE_E_MAIL_SERVER]({ getters, dispatch }, mailServerId) {
    if (
        await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/delMailServer/${mailServerId}`, config)
        .then(res => {
          dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.GET_E_MAIL_SERVER);
        })
    ) {
      return true;
    }
    else return false;
},

  [E_MAIL_ACCESS_TYPES.ACTIONS.GET_IGNORED_ADDRESSES]({ commit, getters }, mailServerId) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/ignores?sid=${mailServerId}`, config).then(response => {
      commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_IGNORED_ADDRESSES_SUCCESS, response.data);
    });
  },

  [E_MAIL_ACCESS_TYPES.ACTIONS.SAVE_IGNORED_ADDRESS]({ commit, getters }, {sid, data}) {
    if (sid) {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/ignores/${sid}`, data, config).then(response => {
        commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_IGNORED_ADDRESSES_SUCCESS, response.data);
      });
    }
  },

  [E_MAIL_ACCESS_TYPES.ACTIONS.DELETE_IGNORED_ADDRESS]({ commit, getters }, data) {
    if (data?.sid && data?.eid) {
      axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/ignores/${data.sid}/${data.eid}`, config).then(response => {
        commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_IGNORED_ADDRESSES_SUCCESS, response.data);
      
      });
    }
  },
  
  [E_MAIL_ACCESS_TYPES.ACTIONS.GET_FOLDER_STRUCTURE]({ commit, getters, dispatch }, mailServerId) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/folderstructure?sid=${mailServerId}`, config).then(response => {
      commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_FOLDER_STRUCTURE_SUCCESS, response.data);
    }).catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Keine Daten gefunden. Vermutlich wurde die Berechtigung vom Benutzer abgelehnt.", 'danger', false));
    })
  },

  async [E_MAIL_ACCESS_TYPES.ACTIONS.SAVE_FOLDER_STRUCTURE]({ commit, getters, dispatch }, data) {
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/folderstructure`, data, config).then(response => {
      if (response.data) {
        commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_FOLDER_STRUCTURE_SUCCESS, response.data);
      } else {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Keine response data", 'danger', false));
      }
    });
  },

  [E_MAIL_ACCESS_TYPES.ACTIONS.RESET_FOLDER_STRUCTURE]({ commit, getters }, data) {
    axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/resetFolderstructure`, data, config).then(response => {
      commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_FOLDER_STRUCTURE_SUCCESS, response.data);
    });
  },

}