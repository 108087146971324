var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Finanzielle Verhältnisse")
      ]),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("1. Grundlage und Höhe des derzeit regelmäßigen Einkommens")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label:
                  "Einnahmen aus unselbständiger Arbeit (netto pro Monat / Gehaltsabrechnung)",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.salary,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "salary", $$v)
                },
                expression: "finances.salary"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label:
                  "Einnahmen aus selbständiger Arbeit (laut Einkommensteuerbescheid / netto pro Monat)",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.freelancerRevenue,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "freelancerRevenue", $$v)
                },
                expression: "finances.freelancerRevenue"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label:
                  "Einnahmen aus Kapitalvermögen (z.B. Zinsen, Dividenden, Ausschüttungen pro Monat)",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.capitalRevenue,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "capitalRevenue", $$v)
                },
                expression: "finances.capitalRevenue"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label:
                  "Sonstiges (z.B. Unterhaltsbeträge, Mieteinnahmen usw. netto pro Monat)",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.otherRevenue,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "otherRevenue", $$v)
                },
                expression: "finances.otherRevenue"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("2. aktuelle liquide Vermögenswerte")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Bargeld",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.cash,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "cash", $$v)
                },
                expression: "finances.cash"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label:
                  "täglich verfügbare Bankeinlagen (z.B. Tagesgeld, Kontokorrent)",
                labelClass: "font-bold",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.freeBankDeposit,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "freeBankDeposit", $$v)
                },
                expression: "finances.freeBankDeposit"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("3. Immobilienvermögen")
      ]),
      _c("FinanceImmobilienvermoegen", {
        attrs: { isRefusedPage: !_vm.isEditable, finance: _vm.finances },
        on: {
          updateImmo: function($event) {
            return _vm.immobilienChanged($event)
          }
        }
      }),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("4. Kapitalanlage / sonstige Vermögenswerte")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "aktueller Bestand gem. Depotpositionen",
                isComponentHalfSize: true,
                type: "currency",
                disabled: true
              },
              model: {
                value: _vm.finances.depotbestand,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "depotbestand", $$v)
                },
                expression: "finances.depotbestand"
              }
            })
          ],
          1
        )
      ]),
      _vm.showBondDepotRelevant
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputField", {
                    attrs: {
                      label:
                        "beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
                      labelClass: "font-bold",
                      isComponentHalfSize: true,
                      type: "currency",
                      disabled: !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.finances.bondDepot,
                      callback: function($$v) {
                        _vm.$set(_vm.finances, "bondDepot", $$v)
                      },
                      expression: "finances.bondDepot"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputField", {
                    attrs: {
                      label:
                        "nicht beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
                      isComponentHalfSize: true,
                      type: "currency",
                      disabled: !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.finances.bondDepotNotRelevant,
                      callback: function($$v) {
                        _vm.$set(_vm.finances, "bondDepotNotRelevant", $$v)
                      },
                      expression: "finances.bondDepotNotRelevant"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputField", {
                    attrs: {
                      label:
                        "Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
                      labelClass: "font-bold",
                      isComponentHalfSize: true,
                      type: "currency",
                      disabled: !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.finances.bondDepot,
                      callback: function($$v) {
                        _vm.$set(_vm.finances, "bondDepot", $$v)
                      },
                      expression: "finances.bondDepot"
                    }
                  })
                ],
                1
              )
            ])
          ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "gebundene Spareinlagen (z.B. Sparbuch, Festgeld)",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.boundBankDeposit,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "boundBankDeposit", $$v)
                },
                expression: "finances.boundBankDeposit"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Alternative Investments",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.alternativeInvestment,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "alternativeInvestment", $$v)
                },
                expression: "finances.alternativeInvestment"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Kunstgegenstände, Antiquitäten",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.art,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "art", $$v)
                },
                expression: "finances.art"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Bausparverträge (aktuelle Werte)",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.buildingSaving,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "buildingSaving", $$v)
                },
                expression: "finances.buildingSaving"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label:
                  "Lebensversicherungen, Kapitalversicherungen (aktuelle Rückkaufswerte)",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.insurance,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "insurance", $$v)
                },
                expression: "finances.insurance"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Sonstiges",
                labelClass: "font-bold",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.otherInvestment,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "otherInvestment", $$v)
                },
                expression: "finances.otherInvestment"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("5. Verbindlichkeiten / Schulden")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "langfristige Kredite / Darlehen",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.longTermCredit,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "longTermCredit", $$v)
                },
                expression: "finances.longTermCredit"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label:
                  "kurzfristige Kredite (Kontokorrent, Zwischenfinanzierungen)",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.shortTermCredit,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "shortTermCredit", $$v)
                },
                expression: "finances.shortTermCredit"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label:
                  "Sonstige Verpflichtungen zu Gunsten Dritter (Verpfändungen, Bürgschaften)",
                isComponentHalfSize: true,
                type: "currency",
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.finances.otherCredit,
                callback: function($$v) {
                  _vm.$set(_vm.finances, "otherCredit", $$v)
                },
                expression: "finances.otherCredit"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("6. regelmäßige finanzielle Verpflichtungen (pro Monat)")
      ]),
      _c("div", { staticClass: "forms__input--half-size" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-6 col-md-6 col-sm-12 col-12" },
            [
              _c("InputField", {
                attrs: {
                  label: "Miete",
                  type: "currency",
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.finances.rent,
                  callback: function($$v) {
                    _vm.$set(_vm.finances, "rent", $$v)
                  },
                  expression: "finances.rent"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-6 col-md-6 col-sm-12 col-12" },
            [
              _c("InputField", {
                attrs: {
                  label: "Leasing",
                  type: "currency",
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.finances.leasing,
                  callback: function($$v) {
                    _vm.$set(_vm.finances, "leasing", $$v)
                  },
                  expression: "finances.leasing"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "forms__input--half-size" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-6 col-md-6 col-sm-12 col-12" },
            [
              _c("InputField", {
                attrs: {
                  label: "Kreditrückzahlung",
                  type: "currency",
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.finances.creditPayment,
                  callback: function($$v) {
                    _vm.$set(_vm.finances, "creditPayment", $$v)
                  },
                  expression: "finances.creditPayment"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-6 col-md-6 col-sm-12 col-12" },
            [
              _c("InputField", {
                attrs: {
                  label: "Haushalt",
                  type: "currency",
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.finances.household,
                  callback: function($$v) {
                    _vm.$set(_vm.finances, "household", $$v)
                  },
                  expression: "finances.household"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "forms__input--half-size" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-6 col-md-6 col-sm-12 col-12" },
            [
              _c("InputField", {
                attrs: {
                  label: "Unterhalt",
                  type: "currency",
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.finances.maintainance,
                  callback: function($$v) {
                    _vm.$set(_vm.finances, "maintainance", $$v)
                  },
                  expression: "finances.maintainance"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-6 col-md-6 col-sm-12 col-12" },
            [
              _c("InputField", {
                attrs: {
                  label: "Versicherungsbeiträge",
                  type: "currency",
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.finances.insurancePayment,
                  callback: function($$v) {
                    _vm.$set(_vm.finances, "insurancePayment", $$v)
                  },
                  expression: "finances.insurancePayment"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "forms__input--half-size" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-6 col-md-6 col-sm-12 col-12" },
            [
              _c("InputField", {
                attrs: {
                  label: "Sonstiges",
                  type: "currency",
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.finances.otherPayment,
                  callback: function($$v) {
                    _vm.$set(_vm.finances, "otherPayment", $$v)
                  },
                  expression: "finances.otherPayment"
                }
              })
            ],
            1
          )
        ])
      ]),
      !_vm.isFA
        ? _c("div", [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Zulagenberechtigung")
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Arbeitnehmersparzulage",
                      inLineLabel: true,
                      disabled: !_vm.isEditable
                    },
                    on: {
                      input: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.finances.employeeSavingAllowance,
                      callback: function($$v) {
                        _vm.$set(_vm.finances, "employeeSavingAllowance", $$v)
                      },
                      expression: "finances.employeeSavingAllowance"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Riester-Rente",
                      inLineLabel: true,
                      disabled: !_vm.isEditable
                    },
                    on: {
                      input: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.finances.riesterPension,
                      callback: function($$v) {
                        _vm.$set(_vm.finances, "riesterPension", $$v)
                      },
                      expression: "finances.riesterPension"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Rürup-Rente",
                      inLineLabel: true,
                      disabled: !_vm.isEditable
                    },
                    on: {
                      input: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.finances.ruerupPension,
                      callback: function($$v) {
                        _vm.$set(_vm.finances, "ruerupPension", $$v)
                      },
                      expression: "finances.ruerupPension"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Wohnungsbauprämie",
                      inLineLabel: true,
                      disabled: !_vm.isEditable
                    },
                    on: {
                      input: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.finances.buildingSubsidy,
                      callback: function($$v) {
                        _vm.$set(_vm.finances, "buildingSubsidy", $$v)
                      },
                      expression: "finances.buildingSubsidy"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "bAV",
                      inLineLabel: true,
                      disabled: !_vm.isEditable
                    },
                    on: {
                      input: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.finances.fedInsuranceComm,
                      callback: function($$v) {
                        _vm.$set(_vm.finances, "fedInsuranceComm", $$v)
                      },
                      expression: "finances.fedInsuranceComm"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }