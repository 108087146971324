var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Infobriefe", actions: _vm.headerActions }
      }),
      _c("HinweiseUndFehler", { attrs: { hints: _vm.hints } }),
      _c("BaseFilter", {
        attrs: {
          filterId: "c53a8f35-ac8d-4fc5-bf24-4c6b81650e4a",
          title: "Liste filtern",
          metadata: _vm.filterMetadata,
          defaultOptions: _vm.defaultOptionsData
        },
        on: {
          onFilter: function($event) {
            return _vm.filterForm($event)
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "c7a34f98-596c-4b7f-bb28-dbcd05f4691e" }
        },
        [
          _c("InputToggleSwitch", {
            attrs: {
              label: "Infobriefe vor Versand freigeben",
              inLineLabel: "",
              suppressValidationMessage: ""
            },
            on: { input: _vm.vorVersandFreigeben },
            model: {
              value: _vm.inputFreigabe,
              callback: function($$v) {
                _vm.inputFreigabe = $$v
              },
              expression: "inputFreigabe"
            }
          }),
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "c035de14-5805-4bf5-81ec-8241ca88c0b8",
                  title: "Infobriefe",
                  headers: _vm.tableHeaders,
                  rows: _vm.tableRows,
                  rowsPerPage: 20,
                  count: _vm.resultCount
                },
                on: { "action-FREIGEBEN": _vm.freigeben },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "freigabe",
                      fn: function(row) {
                        return [
                          !row.freigabe && !_vm.inputFreigabe
                            ? _c("Pill", {
                                attrs: {
                                  label: "wird automatisch versendet",
                                  type: "danger"
                                }
                              })
                            : !row.freigabe
                            ? _c("Pill", {
                                attrs: {
                                  label: "nicht freigegeben",
                                  type: "danger"
                                }
                              })
                            : _vm._e(),
                          row.freigabe
                            ? _c("Pill", {
                                attrs: { label: "freigegeben", type: "success" }
                              })
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "fileName",
                      fn: function(row) {
                        return [
                          _c(
                            "div",
                            {
                              class: {
                                "background-orange": _vm.isFileDateAfterToday(
                                  row
                                )
                              }
                            },
                            [
                              _c("DownloadLink", {
                                attrs: {
                                  title: row.fileName,
                                  filename: row.fileName,
                                  downloadServicePath: "/get_infobrief",
                                  queryParams: { fileName: row.fileName }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  590200085
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }