import VIRTUALDEPOTS_TYPES from './types';
import axios from 'axios';

export default {
  async [VIRTUALDEPOTS_TYPES.ACTIONS.LIST]({ commit, state, rootState }) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/virtualsubdepot?get_list', {
        ask: 'get_list',
        params: {}
      }, {defaultSpinner: true});
      if(response.data.rows){
        commit(VIRTUALDEPOTS_TYPES.MUTATIONS.LIST, {loading: false, rows: response.data.rows});
      }
      return state.list;
    } catch (er) {
      console.log('VIRTUALDEPOTS_TYPES.ACTIONS.LIST', er);
    }
  },
  async [VIRTUALDEPOTS_TYPES.ACTIONS.DEL_VD]({ commit, state, rootState }, payload) {
    try {
      state.list.loading = true;
      let response = await axios.post(rootState.core.apiAddress + '/virtualsubdepot?del_vd', {
        ask: 'del_vd',
        params: {id: payload}
      }, {defaultSpinner: true});
      if(response.data.rows){
        commit(VIRTUALDEPOTS_TYPES.MUTATIONS.LIST, {loading: false, rows: response.data.rows});
      }
      return state.list;
    } catch (er) {
      console.log('VIRTUALDEPOTS_TYPES.ACTIONS.DEL_VD', er);
    }
  },

  async [VIRTUALDEPOTS_TYPES.ACTIONS.GET_VD]({ commit, state, rootState }) {
    try {
      const id = state.subdepot.id;
      let response = await axios.post(rootState.core.apiAddress + '/virtualsubdepot?get_vd', {
        ask: 'get_vd',
        params: {id: id}
      }, { defaultSpinner: true });
      // state.subdepot['orig_' + payload] = vd;
      commit(VIRTUALDEPOTS_TYPES.MUTATIONS.SET_VD, response.data);
      return state.subdepot;
    } catch (er) {
      console.log('VIRTUALDEPOTS_TYPES.ACTIONS.GET_VD', er);
    }
  },
  
  async [VIRTUALDEPOTS_TYPES.ACTIONS.SAVE_AUTO]({ commit, state, rootState }, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/virtualsubdepot?save_autozuord', {
        ask: 'save_autozuord',
        params: payload
      }, {defaultSpinner: true});
      commit(VIRTUALDEPOTS_TYPES.MUTATIONS.SET_VD, response.data);
      return state.list;
    } catch (er) {
      console.log('VIRTUALDEPOTS_TYPES.ACTIONS.SAVE_AUTO', er);
    }
  },

  async [VIRTUALDEPOTS_TYPES.ACTIONS.DEL_AUTO]({ commit, state, rootState }, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/virtualsubdepot?del_autozuord', {
        ask: 'del_autozuord',
        params: payload
      }, {defaultSpinner: true});
      commit(VIRTUALDEPOTS_TYPES.MUTATIONS.SET_VD, response.data);
      return state.list;
    } catch (er) {
      console.log('VIRTUALDEPOTS_TYPES.ACTIONS.SAVE_AUTO', er);
    }
  },

  async [VIRTUALDEPOTS_TYPES.ACTIONS.CREATE_VD]({ commit, state, rootState }, payload) {
    let response = await axios.post(rootState.core.apiAddress + '/virtualsubdepot?create_vd', {
        ask: 'create_vd',
        params: payload
      }, {defaultSpinner: true});
      commit(VIRTUALDEPOTS_TYPES.MUTATIONS.SET_VD, response.data);
      return state.list;
  },

  async [VIRTUALDEPOTS_TYPES.ACTIONS.SAVE_VD]({ commit, rootState, state }, payload) {
    let response = await axios.post(rootState.core.apiAddress + '/virtualsubdepot?save_vd', {
      ask: 'save_vd',
      params: payload
    }, { defaultSpinner: true });
    if (payload.id === state.subdepot.id){
      commit(VIRTUALDEPOTS_TYPES.MUTATIONS.SET_VD, {id: 0 });
    }
    return response;
  },
}