import VUBLOCK_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';

export default {
  [VUBLOCK_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_SELECTED](state, payload) {
    state.vuBlockSelected = payload;
    state.vuBlockGesellSelectedId = null;
  },

  [VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL](state, payload) {
    if (state.vuBlockSelected?.gesellschaften)
      state.vuBlockSelected.gesellschaften = state.vuBlockSelected.gesellschaften?.map(g => g.id == payload.id ? payload : g);
  },

  [VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_GESELL_SELECTED_ID](state, payload) {
    state.vuBlockGesellSelectedId = payload.id;
  },

  [VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_FILE](state, payload) {
    switch (payload?.type) {
      case VUBLOCK_TYPES.FILE_AUSKUNFTSVOLLMACHT:
        Vue.set(state.vuBlockSelected, 'fileNameAuskunftsvollmacht', payload?.data?.fileName);
        break;

      case VUBLOCK_TYPES.FILE_EINVERSTAENDNIS:
        Vue.set(state.vuBlockSelected, 'fileNameEinverstaendnis', payload?.data?.fileName);
        break;

      default:
        break;
    }
  },

}