var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modalEl",
    attrs: {
      modalTitle: "Reihenfolge der Bereiche festlegen",
      labelButtonCancel: "Zurücksetzen",
      labelButtonConfirm: "Schließen"
    },
    on: {
      onCancelButton: function($event) {
        return _vm.restoreSectionsConfig()
      },
      onConfirmButton: function($event) {
        return _vm.saveSectionsConfig()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "SortableList",
              {
                attrs: { items: _vm.sectionsIntern },
                on: {
                  orderChanged: function($event) {
                    return _vm.sectionsOrderChanged($event)
                  }
                }
              },
              [
                _c(
                  "ul",
                  {
                    staticClass:
                      "dashboard-section-order-config__items list-bordered",
                    attrs: { "data-sortable-container": "" }
                  },
                  _vm._l(_vm.sectionsIntern, function(section) {
                    return _c(
                      "li",
                      {
                        key: section.section,
                        staticClass:
                          "dashboard-section-order-config__item list-bordered-item",
                        attrs: { "data-sortable-item": "" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "dashboard-section-order-config__item--title"
                          },
                          [_vm._v(_vm._s(section.title))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "dashboard-section-order-config__item--actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn-clear",
                                attrs: {
                                  type: "button",
                                  "data-sortable-item-handler": ""
                                }
                              },
                              [
                                _c("PhList", {
                                  attrs: { size: 16, weight: "bold" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }