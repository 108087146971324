<template>
  <div class="">
    <PageHeaderTitleNavigation 
      title="Virtuelle Unterdepots" 
      :actions="headerActions" 
      @action-ADD="newVD()"
    />

    <GhostLoading v-if="list.loading" :title="TABLE_TITLE" useBoxContainer>
      <Block height="200" />
    </GhostLoading>
    <NoData v-else-if="!rows.length" :title="TABLE_TITLE" content="Es wurden keine virtuellen Depots definiert" useBoxContainer />
    <div class="box__container" v-else-if="rows.length">
      <Table
          :title="TABLE_TITLE"
          :headers="headers"
          :rows="rows"
          rowId="id"
          hidePagination
          :mobileConfig="{}"
          @action-DELETE="deleteVD"
          @action-EDIT="showVD"
          @click-vdname="showVD($event)"
      >
      </Table>
    </div>

    <BaseModal
      ref="modalRef"
      :modalTitle="deleteText"
      labelButtonConfirm="Ja"
      labelButtonCancel="Nein"
      @onConfirmButton="confitmDeleteVD" >
    </BaseModal>
  </div>
</template>

<script>
import VIRTUALDEPOTS_TYPES from "@/store/virtualsubdepots/types";
import { mapGetters } from 'vuex';
import Table from '@/components/table2/Table.vue';
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { PhTrash, PhPencil } from 'phosphor-vue';
import BaseModal from "@/components/core/BaseModal.vue";
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Vorhandene Depots';

export default {
  name: 'VirtualUnterdepots',
  components: {
    BaseModal,
    Table,
    GhostLoading, Block,
    NoData,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  mixins: [],
  props: {
  },
  data: function() {
    return {
      TABLE_TITLE,
      deleteId: null,
      deleteText: '',
      headers: {
          lockedLeft: [
              TextColumn("vdname", "Name").makeAlwaysVisible().makeLink(),
          ],
          center: [
              TextColumn("descript", "Beschreibung", 150),
          ],
          lockedRight: [
              ActionColumn("actions", ""),
          ],
      }
    }
  },
  created() {
    this.$store.dispatch(VIRTUALDEPOTS_TYPES.ACTIONS.LIST);
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      list: VIRTUALDEPOTS_TYPES.GETTERS.LIST,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Neues virtuelles Unterdepot'),
      ];
    },
    rows() {
      if (this.list.rows?.length) {
        return this.list.rows.map(row => ({...row, actions: [SimpleAction("DELETE", PhTrash, "Löschen"), SimpleAction("EDIT", PhPencil, "Bearbeiten")]}))
      }
      return [];
    },
  },
  methods: {
    newVD() {
      this.showVD({id: -1});
    },
    showVD(row) {
      const base = this.$route.path.substr(0, this.$route.path.indexOf('unterdepots'));      
      this.$addBreadcrumb({
        label: 'zurück zu virtuellen Unterdepots', 
        breadcrumb: row.id === -1 ? 'Neues Unterdepot' : 'Unterdepot bearbeiten',
        fullPath:  base + 'unterdepots',
      });
      this.$router.push({ path: base + 'unterdepots/subdepot/' + row.id });
    },
    deleteVD(row) {
      this.deleteId = '' + row.id;
      this.deleteText = 'Unterdepot '+ row.vdname +' löschen?'
      this.$refs.modalRef.open();
    },
    confitmDeleteVD() {
      this.$store.dispatch(VIRTUALDEPOTS_TYPES.ACTIONS.DEL_VD, this.deleteId);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to,from,
    });
    next();
  },
}
</script>

<style scoped>
.btn-clean {
  color: var(--color-text);
}
</style>
