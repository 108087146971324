var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v(
          "Bitte geben Sie die gewünschten Vermittlerdaten für den Nafi-Zugang ein"
        )
      ]),
      _c("InputField", {
        attrs: { label: "Firmenname 1", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("firmenname1", $event)
          }
        },
        model: {
          value: _vm.form.firmenname1,
          callback: function($$v) {
            _vm.$set(_vm.form, "firmenname1", $$v)
          },
          expression: "form.firmenname1"
        }
      }),
      _c("InputField", {
        attrs: { label: "Firmenname 2", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("firmenname2", $event)
          }
        },
        model: {
          value: _vm.form.firmenname2,
          callback: function($$v) {
            _vm.$set(_vm.form, "firmenname2", $$v)
          },
          expression: "form.firmenname2"
        }
      }),
      _c("InputField", {
        attrs: { label: "Straße", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("strasse", $event)
          }
        },
        model: {
          value: _vm.form.strasse,
          callback: function($$v) {
            _vm.$set(_vm.form, "strasse", $$v)
          },
          expression: "form.strasse"
        }
      }),
      _c("InputField", {
        attrs: { label: "PLZ", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("plz", $event)
          }
        },
        model: {
          value: _vm.form.plz,
          callback: function($$v) {
            _vm.$set(_vm.form, "plz", $$v)
          },
          expression: "form.plz"
        }
      }),
      _c("InputField", {
        attrs: { label: "Ort", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("ort", $event)
          }
        },
        model: {
          value: _vm.form.ort,
          callback: function($$v) {
            _vm.$set(_vm.form, "ort", $$v)
          },
          expression: "form.ort"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Telefon 1",
          isComponentHalfSize: "",
          inputmode: "tel"
        },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("telefon1", $event)
          }
        },
        model: {
          value: _vm.form.telefon1,
          callback: function($$v) {
            _vm.$set(_vm.form, "telefon1", $$v)
          },
          expression: "form.telefon1"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Telefon 2",
          isComponentHalfSize: "",
          inputmode: "tel"
        },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("telefon2", $event)
          }
        },
        model: {
          value: _vm.form.telefon2,
          callback: function($$v) {
            _vm.$set(_vm.form, "telefon2", $$v)
          },
          expression: "form.telefon2"
        }
      }),
      _c("InputField", {
        attrs: { label: "Telefax", isComponentHalfSize: "", inputmode: "tel" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("telefax", $event)
          }
        },
        model: {
          value: _vm.form.telefax,
          callback: function($$v) {
            _vm.$set(_vm.form, "telefax", $$v)
          },
          expression: "form.telefax"
        }
      }),
      _c("InputField", {
        attrs: { type: "email", label: "E-Mail", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("email", $event)
          }
        },
        model: {
          value: _vm.form.email,
          callback: function($$v) {
            _vm.$set(_vm.form, "email", $$v)
          },
          expression: "form.email"
        }
      }),
      _c("InputField", {
        attrs: { label: "Homepage", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("homepage", $event)
          }
        },
        model: {
          value: _vm.form.homepage,
          callback: function($$v) {
            _vm.$set(_vm.form, "homepage", $$v)
          },
          expression: "form.homepage"
        }
      }),
      _c("InputField", {
        attrs: {
          type: "number",
          label: "Agenturnummer",
          isComponentHalfSize: ""
        },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("agenturnr", $event)
          }
        },
        model: {
          value: _vm.form.agenturnr,
          callback: function($$v) {
            _vm.$set(_vm.form, "agenturnr", $$v)
          },
          expression: "form.agenturnr"
        }
      }),
      _c("InputField", {
        attrs: { label: "IHK-Nummer", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("ihknr", $event)
          }
        },
        model: {
          value: _vm.form.ihknr,
          callback: function($$v) {
            _vm.$set(_vm.form, "ihknr", $$v)
          },
          expression: "form.ihknr"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }