var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Wirtschaftsprüfung 2024 – Klagen, Beschwerden")
      ]),
      _c("FormDivider"),
      _c("FormLabel", {
        attrs: {
          label:
            "Anforderung: Bitte teilen Sie uns Ihre Klagen und Beschwerden im WAG 2018 Bereich bis zum 15.02.2025 mit. Sollten diese Punkte auf Sie zutreffen, \n     legen Sie bitte entsprechende Unterlagen/Sachverhaltsdarstellungen bei, auch bei bereits erfolgter Dokumentation."
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          value: _vm.form.INPUT_WIRTSCHAFTSPRUEFUNG_BEST1,
          values: _vm.wirtschaftspruefungValues,
          labelClass: "font-bold",
          disabled: _vm.disabledStatusOK
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_WIRTSCHAFTSPRUEFUNG_BEST1")
          }
        }
      }),
      _c("ChecklisteDocsUpload", {
        attrs: {
          paramId: this.form.PARAM_ID,
          topic: "Beschwerden",
          form: this.form
        }
      }),
      _c("FormDivider"),
      _c("InputToggleSwitch", {
        attrs: {
          label:
            "Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben.",
          inLineLabel: true,
          config: { bold: true },
          isComponentHalfSize: "",
          disabled: _vm.disabledStatusOK,
          value: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }