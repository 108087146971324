<template>
  <Firma v-if="isFirma" :substepProperty="substepProperty" ref="stammdaten" />
  <Privat  :substepProperty="substepProperty" v-else ref="stammdaten" />
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import { mapGetters } from 'vuex'

import Firma from '@/components/persoenliche-daten/stammdaten/Firma.vue'
import Privat from '@/components/persoenliche-daten/stammdaten/Privat.vue'

export default {
  props: {
    substepProperty: {
      type: String,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
    }),
    isEditable() {
      return this.customerData && this.customerData.isEditable
    },
  },
  methods: {
  },
  mounted() {
  },
  beforeRouteLeave(to, from, next) {
    if(to?.path?.search(/(person\-data|customer\-data)/gi) >= 0 
      && !to?.query?.backAction
      && !this.$refs.stammdaten?.checkData()) {
      return ;
    }

    next();
  },
  components: {
    Privat,
    Firma,
  }
}
</script>

<style scoped>

</style>