var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card box__container" },
    [
      _c(
        "div",
        {
          staticClass: "card-header",
          attrs: { draggable: "true" },
          on: {
            dragstart: _vm.onDragStart,
            dragover: function($event) {
              $event.preventDefault()
              return _vm.onDragOver.apply(null, arguments)
            },
            drop: _vm.onDrop
          }
        },
        [
          _c("div", { staticClass: "card-title" }, [
            _vm._v(" " + _vm._s(_vm.card.title) + " ")
          ]),
          _c(
            "div",
            { staticClass: "card-buttons" },
            [
              !_vm.isSmallOrMediumScreen
                ? _c(
                    "button",
                    {
                      key: "widget-order",
                      class: ["btn-clear", "clickable", "d-lg-block"],
                      attrs: {
                        type: "button",
                        "data-sortable-item-handler": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.doEmitClick()
                        }
                      }
                    },
                    [_c("PhArrowsOutCardinal", { attrs: { size: 20 } })],
                    1
                  )
                : _vm._e(),
              _vm.isLargeScreen
                ? _c(
                    "BaseContextMenu",
                    {
                      key: "widget-dropdown-menu",
                      ref: "dropdownMenu",
                      staticClass: "base-context-menu",
                      class: ["d-none", "d-lg-block"],
                      attrs: { containerClass: "dropdown-menu" }
                    },
                    [
                      [
                        _vm._l(_vm.actions, function(action, index) {
                          return [
                            _vm.isActionVisible(action)
                              ? _c(
                                  "ContextMenuItem",
                                  {
                                    key: index,
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("executeAction", {
                                          action: action,
                                          component: _vm.$refs[
                                            "comp-" + _vm.card.key
                                          ]
                                            ? _vm.$refs["comp-" + _vm.card.key]
                                            : _vm.card.key
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _vm.actionHasIcon(action)
                                      ? _c(_vm.actionIcon(action), {
                                          tag: "component",
                                          staticClass: "mr-1",
                                          attrs: { size: 16 }
                                        })
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(_vm._s(action.legend.label))
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }),
                        _c(
                          "ContextMenuItem",
                          {
                            on: {
                              click: function($event) {
                                return _vm.openRemoveCardtModal()
                              }
                            }
                          },
                          [_vm._v("löschen")]
                        )
                      ]
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm.card.component
        ? _c(
            _vm.card.component,
            _vm._b(
              { ref: "comp-" + _vm.card.key, tag: "component" },
              "component",
              _vm.card.props,
              false
            )
          )
        : _vm.card.key
        ? _vm._t(_vm.card.key)
        : _vm._e(),
      _c("BaseModal", {
        ref: "removeCardModal",
        attrs: { modalTitle: _vm.card.title + " löschen", size: "sm" },
        on: {
          onConfirmButton: function($event) {
            return _vm.$emit("remove", _vm.card)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  ' Soll die "' +
                    _vm._s(_vm.card.title) +
                    '" wirklich gelöscht werden? '
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }