import ZUSAETZLICHE_INFORMATIONEN_TYPES from './types';
import { getInitialState } from './index'

export default {
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.GET_ZUSAETZLICHE_DATEN_SUCCESS](state, payload) {
    state.zusaetzlicheDaten = payload;
  }, 
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS_SUCCESS](state, payload) {
    state.alleStrukturZusaetzlicheInfos = payload;
  },
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.NEW_ZUSAETZLICH_DATEN_SUCCESS](state, payload) {
  },  
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.UPDATE_OBJECT_ZUSAETZLICHE_TABELLE_SUCCESS](state, payload) {
  },   
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.UPDATE_MAIN_TABLE_ROWS](state, payload) {
    state.mainTableRows = payload;
  },    
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.UPDATE_SECONDARY_TABLE_ROWS](state, payload) {
    state.secondaryTableRows = payload;
  },     
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.REMOVE_MAIN_TABLE_ROW](state, payload) {
    const index = state.mainTableRows.findIndex(r => r.kundennr == payload);
    state.mainTableRows.splice(index, 1);
  },     
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.DELETE_ZUSAETZLICHE_DATEN_SUCCESS](state, payload) {
  },  
}