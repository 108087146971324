var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Provisionserstattung")
        ]),
        _c("InputField", {
          attrs: {
            label: "Bezeichnung der Provisionserstattung",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.buchungstextProverstattung,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "buchungstextProverstattung", $$v)
            },
            expression: "feePropertiesForm.buchungstextProverstattung"
          }
        }),
        _c(
          "div",
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Abschlussprovision")
            ]),
            _c("InputField", {
              attrs: {
                label: "Anteil der Erstattung an den Kunden",
                type: "percent",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.abschlussErsattungssatz,
                callback: function($$v) {
                  _vm.$set(
                    _vm.feePropertiesForm,
                    "abschlussErsattungssatz",
                    $$v
                  )
                },
                expression: "feePropertiesForm.abschlussErsattungssatz"
              }
            }),
            _vm.istHonorarberater
              ? [
                  _c("InputRadioBoxGroup", {
                    attrs: {
                      label: "Erstattung in Relation zur Courtage des/der",
                      values: _vm.feePropertiesCombos.erstattungsType
                    },
                    on: {
                      input: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.feePropertiesForm.abschlussErsattungstyp,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.feePropertiesForm,
                          "abschlussErsattungstyp",
                          $$v
                        )
                      },
                      expression: "feePropertiesForm.abschlussErsattungstyp"
                    }
                  })
                ]
              : _vm._e(),
            _c("InputRadioBoxGroup", {
              attrs: {
                title: "Erstattung der erhaltenen Courtage",
                values: _vm.feePropertiesCombos.gegenrechnungValues
              },
              on: {
                input: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.gegenrechnungAbschluss,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "gegenrechnungAbschluss", $$v)
                },
                expression: "feePropertiesForm.gegenrechnungAbschluss"
              }
            })
          ],
          2
        ),
        _c(
          "div",
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Abschlussfolgeprovision")
            ]),
            _c("InputField", {
              attrs: {
                label: "Anteil der Erstattung an den Kunden",
                type: "percent",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.bestandErsattungssatz,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "bestandErsattungssatz", $$v)
                },
                expression: "feePropertiesForm.bestandErsattungssatz"
              }
            }),
            _vm.istHonorarberater || _vm.istErstattungStruktur
              ? [
                  _c("InputRadioBoxGroup", {
                    attrs: {
                      title: "Erstattung in Relation zur Courtage des/der",
                      values: _vm.feePropertiesCombos.erstattungsType
                    },
                    on: {
                      input: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.feePropertiesForm.bestandErsattungstyp,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.feePropertiesForm,
                          "bestandErsattungstyp",
                          $$v
                        )
                      },
                      expression: "feePropertiesForm.bestandErsattungstyp"
                    }
                  })
                ]
              : _vm._e(),
            _c("InputRadioBoxGroup", {
              attrs: {
                title: "Erstattung der erhaltenen Courtage",
                values: _vm.feePropertiesCombos.gegenrechnungValues
              },
              on: {
                input: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.gegenrechnungBestand,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "gegenrechnungBestand", $$v)
                },
                expression: "feePropertiesForm.gegenrechnungBestand"
              }
            })
          ],
          2
        ),
        _vm._m(0),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Bei unmittelbarer Erstattung der Courtage dem Kunden einen Beleg zusenden (sofern Mailadresse vorhanden und Versand aktiviert)",
            inLineLabel: ""
          },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.erstattungDirektversand,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "erstattungDirektversand", $$v)
            },
            expression: "feePropertiesForm.erstattungDirektversand"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Zusammenfassung der Einzelpositionen",
            inLineLabel: ""
          },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.zusammenfassungErstattung,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "zusammenfassungErstattung", $$v)
            },
            expression: "feePropertiesForm.zusammenfassungErstattung"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "color-danger text-italic" }, [
      _c("p", [
        _vm._v(
          "Sollen dem Kunden die Provisionen direkt erstattet werden, so muss 'unmittelbar nach Gutschrift der Courtage' gewählt werden. Sie werden dann dem Kunden unmittelbar nach der Courtageabrechnung überwiesen."
        )
      ]),
      _c("p", [
        _c("span", { staticClass: "text-underline" }, [_vm._v("Achtung:")]),
        _vm._v(
          " Die Übeweisung erfolgt ohne eine weitere Bestätigung von Ihrer Seite."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }