var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internCount
    ? _c(
        "span",
        {
          staticClass: "badge__counter",
          class: {
            "more-than-99": _vm.internCount === _vm.MAX_BADGE_COUNT_TEXT
          }
        },
        [_vm._v(" " + _vm._s(_vm.internCount) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }