var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseToolbarButton", {
        directives: [
          {
            name: "tippy",
            rawName: "v-tippy",
            value: { arrow: true },
            expression: "{ arrow: true }"
          }
        ],
        staticClass: "mr-0",
        attrs: {
          title: "Link",
          icon: _vm.icon,
          active: _vm.active,
          disabled: _vm.disabled,
          content: "Link"
        },
        on: { click: _vm.action }
      }),
      _vm.showLinkModal
        ? _c("BaseToolbarLinkEditor", {
            attrs: { linkForm: _vm.linkForm },
            on: {
              close: function($event) {
                _vm.showLinkModal = false
              },
              onConfirmButton: _vm.setLink
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }