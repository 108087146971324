<template>
	<div class="rail">
		<template v-for="(item, index) in getTotalProgressArray()">
			<div
				:key="index"
				:class="[
					'step',
					isShowProgress(item) ? 'step-progress' : '',
					isShowEmpty(item) ? 'step-empty' : '',
					isShowWarning(item) ? 'step-warning' : '',
				]"
				@click="$emit('click', { stepKey: step.stepKey, substepKey: getSubstepKey(index), index: item })"
			></div>
		</template>
	</div>
</template>
<script>
export default {
	props: {
		step: {
			type: Object,
			default: () => {}
		},
		currentStep: {
			type: Object,
			default: function() {
				return {};
			}
		},
	},
	mounted: function () {
	},
	computed: {
	},
	methods: {
		isActive(stepKey) {
			return this.currentStep && this.currentStep.step.stepKey === stepKey;
		},
		getTotalProgressArray() {
			return Array(this.step.totalProgress).fill(0).map((x,i)=>i);
		},
		onClickStep(index) {
			if (this.step && this.step.alreadyVisitedStep && this.step.substeps && this.step.substeps[index]) {
			if (this.step.substeps[index].actionFunc) {
				this.step.substeps[index].actionFunc();
			}
			if (this.step.substeps[index].link) {
				this.router.navigate([this.step.substeps[index].link]);
			}
			}
		},
		getVisibleSubsteps() {
			return this.step && this.step.substeps && this.step.substeps.length && this.step 
				&& this.step.substeps && this.step.substeps.filter(sub => !sub.hidden) || [];
		},
		getSubstepKey(index) {
			const visibleSubsteps = this.getVisibleSubsteps();
			return visibleSubsteps.length > index ? visibleSubsteps[index].substepKey : '';
		},
		isShowProgress(index) {
			let showProgress = true;
			const visibleSubsteps = this.getVisibleSubsteps();
			// if there is a subStep, check for warnings, otherwise true
			if (visibleSubsteps.length > index  && visibleSubsteps[index]) {
				showProgress = !visibleSubsteps[index].warning;
			}

			if (this.step.current) {
				return showProgress && this.step.currentProgress > index;
			} else if (this.step.before) {
				return showProgress;
			}
		},
		isShowWarning(index) {
			let showWarning = false;
			const visibleSubsteps = this.getVisibleSubsteps();
			// if there is a subStep, check for warnings, otherwise true
			if (visibleSubsteps.length > index  && visibleSubsteps[index]) {
				showWarning = visibleSubsteps[index].warning;
			}

			if (this.step.current) {
			  return showWarning && this.step.currentProgress > index;
			} else if (this.step.before) {
			  return showWarning;
			}
		},
		isShowEmpty(index) {
			if (this.step.current) {
				return this.step.currentProgress <= index;
			} else if (this.step.after) {
				return true;
			} else if (this.step.before) {
				return false;
			} else {
				return true;
			}
		}
	},
}
</script>
<style scoped>
.rail{
  display: flex;
  height: 2px;
  width: 100%;

  flex: 2 100%;
}
.step {
  margin: 0 1px;
  height: 100%;
  width: 100%;
  transition: width 0.3s ease-in-out;
  outline: none;
}

.step:hover {
  height: 500%;
  transform: translateY(-50%);
  cursor: pointer;
}

.step-progress {
  background: rgb(40,167,69);
  /* background: linear-gradient(90deg, rgba(40,167,69,1) 0%, rgba(56,207,91,1) 50%, rgba(40,167,69,1) 100%); */
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.step-empty {
  background: #000000;/* #E8E8E8; */
}

.step-warning {
  background: #dc3545;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

@media (min-width: 768px) {
  .rail {
    padding: 0px 10px;
  }
  .step {
    margin: 0 2px;
  }
}
</style>
