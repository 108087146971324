import MSC_MARKETING_TYPES from './types';

export default {
  [MSC_MARKETING_TYPES.GETTERS.MARKETING_CAMPAIGNS](state) {
    return state.marketingCampaigns || [];
  },
  [MSC_MARKETING_TYPES.GETTERS.MARKETING_CAMPAIGNS_COUNT](state) {
    return state.marketingCampaignCount || 0;
  },
  [MSC_MARKETING_TYPES.GETTERS.PENDING_MARKETING_CAMPAIGNS](state) {
    const result = state.pendingMarketingCampaigns?.filter(item1 => !state.postponeMarketingCampaign?.some(item2 => item2.id === item1.id));
    return result || [];
  },
  [MSC_MARKETING_TYPES.GETTERS.TARGET_LIST](state) {
    return state.targetList || [];
  },
  [MSC_MARKETING_TYPES.GETTERS.MARKETING_CAMPAIGN_CHANGED](state) {
    return state.changed;
  },
  [MSC_MARKETING_TYPES.GETTERS.TARGET_LIST_EXCLUDED](state) {
    return state.targetList ? state.targetList.filter(item => item.type === 'EXCLUDED') : [];
  },
  [MSC_MARKETING_TYPES.GETTERS.TARGET_LIST_INCLUDED](state) {
    return state.targetList ? state.targetList.filter(item => item.type === 'INCLUDED') : [];
  },
  [MSC_MARKETING_TYPES.GETTERS.EDITING_MARKETING_CAMPAIGN](state) {
    return state.editingMarketingCampaign;
  }
}