<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row">
        <div class="col">
          <div
            class="input-forms__label-content input-forms__label-content--bigger"
            v-if="title"
          >
            <PhBank :size="16" />&nbsp;{{ title }}
          </div>
        </div>
        <div class="col-auto" v-if="config && config.buttonText">
          <BaseButton @click="openFormsFinder">{{
            config.buttonText
          }}</BaseButton>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <div v-if="rows.length">
        <Table
          tableId="092c3a63-545b-4316-8d07-803a6e7d3214"
          :headers="headers"
          :rows="rows"
          rowId="index"
          hidePagination
          @action-DELETE="openModalDeleteFond"
        >
          <template v-slot:prozent="row">
            <InputField
              type="percent"
              :value="row.prozent"
              :disabled="isMusterDepot"
              :id="'prozent' + row.index"
              @input="onChangeInputField($event, row, 'prozent')"
            />
          </template>
        </Table>
        <WertpapierAuswahlPositionFields
          v-if="
            positions && positions.length && config && config.positionFields
          "
          :config="config"
          :categoryId="categoryId"
          :antragId="antragId"
        />
      </div>
      <div v-else>Wertpapierauswahl ist leer</div>
    </div>
    <DeletePositionModal
      ref="deleteModal"
      :position="positionToDelete"
      @delete="doDeletePosition()"
    >
    </DeletePositionModal>
    <BaseModal
      ref="fehlerModal"
      labelButtonCancel="Ok"
      :showConfirmButton="false"
    >
      <template v-slot:modalTitle>
        <PhWarning :size="16" class="color-danger mr-2" />
        <span class="color-danger">Fehler</span>
      </template>

      Fehler: Sie dürfen nur 10 Positionen wählen.
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import antragMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  ActionColumn,
  SlotColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import InputField from "@/components/core/forms/InputField.vue";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import validator from "@/mixins/validator";
import { PhWarning, PhBank } from "phosphor-vue";

export default {
  mixins: [antragNavigationMixin, validator, antragMixin],
  validators: {},
  props: {
    title: {},
    antragData: {},
    config: {},
    categoryId: {},
    antragId: {},
    antragTyp: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Array,
      default: () => [],
    },
    hasBemerkung: {
      type: Boolean,
      default: true,
    },
    betragTitle: {
      type: String,
      default: "Verteilung",
    },
    isWkn: {
      type: Boolean,
      default: true,
    },
    isMusterDepot: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    Table,
    InputField,
    WertpapierAuswahlPositionFields,
    DeletePositionModal,
    BaseModal,
    PhWarning,
    PhBank,
  },
  data() {
    return {
      positionToDelete: {},
    };
  },
  watch: {
    positions: {
      immedate: true,
      handler(newValue) {
        if (newValue.length > 10) {
          this.$refs.fehlerModal.open();
        }
      },
    },
    percentageSum: {
      immediate: true,
      handler(newValue) {
        if (this.positions && this.positions.length) {
          this.positions.map((pos) => {
            if (pos.prozent == "" || !pos.prozent) {
              pos.prozent = 0;
            }
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
    }),
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.positionsAll[this.antragId]
      ) {
        const positions = (
          this.positionsAll[this.antragId][this.categoryId] || []
        ).map((pos, index) =>
          Object.assign(pos, {
            index: index,
            isin: this.getIsin(pos),
            fondsname: this.getFondsName(pos),
          })
        );
        this.updateWarnings(positions);
        return positions;
      }
    },
    positionData() {
      if (
        this.positionDataAll &&
        this.antragId &&
        this.categoryId &&
        this.positionDataAll[this.antragId] &&
        this.positionDataAll[this.antragId][this.categoryId]
      ) {
        return this.positionDataAll[this.antragId][this.categoryId];
      }
    },
    positininfos() {
      if (this.positionInfo) {
        return this.positionInfo;
      }
    },
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId];
      }
      return null;
    },
    positionWarnings() {
      if (this.antrag && this.antrag.warnings) {
        this.updateWarnings(
          this.antrag.warnings && this.antrag.warnings.positionWarnings
        );
        return this.antrag.warnings && this.antrag.warnings.positionWarnings;
      }
      return null;
    },
    headers() {
      return {
        lockedLeft: [
          TextColumn("isin", "ISIN"),
          TextColumn("fondsname", "Fondsname"),
          SlotColumn("prozent", this.betragTitle)
          .withConstantFooter(`Gesamt: ${this.percentageSum} %`),
        ],
        lockedRight: [ActionColumn("actions", "")],
      };
    },
    rows() {
      const actions = [SimpleAction("DELETE", "PhTrash", "Löschen")];
      return (this.positions || []).map((row) => ({
        ...row,
        actions,
      }));
    },
    percentageSum() {
      let sum = 0;
      this.rows.forEach((pos) => {
        if (isNaN(pos.prozent) || pos.prozent == "") {
          pos.prozent = 0;
        }
        sum += pos.prozent;
      });
      return sum;
    },
  },

  methods: {
    updateWarnings(positions) {
      if (
        positions &&
        this.antrag.warnings &&
        this.antrag.warnings.positionWarnings
      ) {
        for (const warn of this.antrag.warnings.positionWarnings) {
          if (warn.posGrpId) {
            if (!warn.id || warn.id.includes(this.categoryId)) {
              const index = positions.findIndex(
                (pos) =>
                  pos.posGrpId === warn.posGrpId || pos.isin === warn.posGrpId
              );
              if (index >= 0) {
                this.$pushErrors("prozent" + index, warn.message || "");
              }
            }
          }
        }
      }
    },
    getFondsName(pos) {
      return (
        (this.positionInfo &&
          this.positionInfo[pos.isin] &&
          this.positionInfo[pos.isin].wertpapiername) ||
        pos.fondsname ||
        pos.wertpapiername
      );
    },
    getIsin(pos) {
      return pos && pos.isin;
    },
   
    openFormsFinder() {
      if (this.positions == undefined) {
        this.goToFormsFinder(
          this.categoryId,
          this.config,
          this.suppressFilterLagerstelle
        );
      } else if (this.positions.length >= 10) {
        this.$refs.fehlerModal.open();
      } else {
        this.goToFormsFinder(
          this.categoryId,
          this.config,
          this.suppressFilterLagerstelle
        );
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField($event, position, field) {
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin);
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      if (this.positionToDelete) {
        const deletePayload = {
          id: this.antragId,
          categoryId: this.categoryId,
          isin: this.positionToDelete.isin,
          posGrpId: this.positionToDelete.posGrpId,
        };
        this.$store.dispatch(
          ANTRAG_TYPES.ACTIONS.DELETE_POSITION,
          deletePayload
        );
        this.positionToDelete = null;
      }
    },
  },
};
</script>

<style scoped>
.input-forms__container {
  margin-bottom: 20px;
}
</style>