export const MUTATION_PREFIX = 'BETEILIGUNGEN: ';
export const ACTIONS_PREFIX = 'BETEILIGUNGEN_ACTIONS_';
export const GETTERS_PREFIX = 'BETEILIGUNGEN_GETTERS_';

export default {
  MUTATIONS: {
    GET_BETEILIGUNGEN_SUCCESS: MUTATION_PREFIX + 'GET_BETEILIGUNGEN_SUCCESS',
    GET_FAILTURE: MUTATION_PREFIX + 'GET_FAILTURE',
  },
  ACTIONS: {
    GET_BETEILIGUNGEN: ACTIONS_PREFIX + 'GET_BETEILIGUNGEN',
  },
  GETTERS: {
    BETEILIGUNGEN: GETTERS_PREFIX + 'BETEILIGUNGEN',
  }
}
