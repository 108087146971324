var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cleanMaklername ||
    (_vm.brokerData && _vm.brokerData.cleanMaklername)
    ? _c("div", { staticClass: "form" }, [
        _c("h1", [
          _vm._v(_vm._s(_vm.cleanMaklername || _vm.brokerData.cleanMaklername))
        ]),
        _c("p", [_vm._v(_vm._s(_vm.strasse || _vm.brokerData.strasse))]),
        _c("p", [_vm._v(_vm._s(_vm.plzOrt || _vm.brokerData.plzOrt))]),
        _c("br"),
        _c("p", [_vm._v(_vm._s(_vm.telefon || _vm.brokerData.telefon))]),
        _c("p", [
          _vm._v(_vm._s(_vm.faxStandard || _vm.brokerData.faxStandard))
        ]),
        _c("div", { staticClass: "button", attrs: { id: "login_button" } }, [
          _vm._v("Erstinformationen")
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }