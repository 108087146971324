<template>
    <div class="action-order-step-view">
        <div v-if="params">
            <div class="box__container">
                <ComboBox
                    label='Ziellagerstelle'
                    @change="triggerSelectZiellagerstelle"
                    v-model="ziellagerstelle"
                    :isEmbedded="false"
                    :values="ziellagerstelleAuswahl"
                    :isComponentHalfSize="true"
                    :sortComboboxValues="false"
                    :disabled="completed || orderInitialized">
                </ComboBox>
                <ComboBox
                    label='Kontonummer'
                    @change="triggerSelectKonto"
                    v-model="zielkonto"
                    :isEmbedded="false"
                    :values="zielkontoAuswahl"
                    :isComponentHalfSize="true"
                    :sortComboboxValues="false"
                    :disabled="completed || orderInitialized">
                </ComboBox>
                <input-field
                    v-model="kontonummer"
                    :validateUntouched="true"
                    @change="markModified"
                    :isComponentHalfSize="true"
                    :sortComboboxValues="false"
                    :disabled="completed || orderInitialized || neuDepot">
                </input-field>
                <input-check-box-item v-model="params.erzeugeTausch" label='erzeuge Tauschaufträge' 
                    :disabled="completed || orderInitialized || !hasDepotPositionen">
                </input-check-box-item>
            
                <ComboBox v-if="params"
                    label="Rabatt auf Agio"
                    @change="triggerSelectRabatt"
                    v-model="params.rabatt"
                    :isEmbedded="false"
                    :values="stepParameters.data.rabattAuswahl"
                    :isComponentHalfSize="true"
                    :sortComboboxValues="false"
                    :disabled="completed || orderInitialized">
                </ComboBox>
            </div> 
            <div class="box__container">
                <div class="box__title">Beratungsmappe</div>
                    <InputRadioBoxGroup 
                        title=""
                        v-model="beratungsmappenart" 
                        :validateUntouched="true"
                        :disabled="orderDisabled"
                        :values="optionsBeratung"
                        labelClass="font-bold"
                        @input="setBMArt"
                    />
                    <p>{{selectedMappe}}</p>
            </div>
            <div class="box__container">
                <ActionOrderStepButtons :id="id" />
            </div>
        </div> 
        <GhostLoading v-else>
            <Block height="80" />
            <Block height="80" />
            <Block height="80" />
            <Block height="100" />
        </GhostLoading>
        
        <EditBeratungsmappeModal 
            ref="createBeratungsmappeModal"
            @confirm="createBeratungsmappe"
        />

    </div>      
</template>
<script>
import StepMixin from "./StepMixin";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import WERTPAPIERORDER from "@/store/wertpapierorder/types";
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import { mapGetters } from "vuex";
import BoxList from '@/components/core/BoxList.vue';
import EditBeratungsmappeModal from "@/components/beratungsmappe/EditBeratungsmappeModal.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import ActionOrderStepButtons from './ActionOrderStepButtons.vue';

export default {
    components: { 
        BaseButton,
        BaseModal, 
        InputCheckBoxItem,
        InputRadioBoxGroup,
        GhostLoading,
        Block,
        EditBeratungsmappeModal,
        BoxList,
        ActionOrderStepButtons,
    },
    props: {
    },    
    data() {
        return {
            step: INVESTMENT_ADVICE.STEPS.ORDER,
            orderList: [],
            depoteroeffnung: [],
            beratungsmappe: [],
            neuDepot: false,
            beratungsmappenart: -1,
        }
    },
    mixins: [StepMixin],
    computed: {
         ...mapGetters({
            order: INVESTMENT_ADVICE.GETTERS.ORDER,
            processOrder: INVESTMENT_ADVICE.GETTERS.INVESTMENT_ADVICE_ORDER,
        }),
        params() {
            if (this.stepParameters?.data?.parameterData) {
             return this.stepParameters.data.parameterData;
            }
            return undefined;
        },
        ziellagerstelle: {
            get() {
                return this.params.ziellagerstelle
            },
            set(ziellagerstelle) {
                this.params.ziellagerstelle = ziellagerstelle;
                if (!ziellagerstelle) {
                    this.neuDepot = false;
                    this.params.zielkonto = 'freie Eingabe:';
                } else {
                    this.neuDepot = !!this.stepParameters?.data.ziellagerstelleAuswahl.find( z => z.value === ziellagerstelle && -1 !== z.label.indexOf('(Kein Depot vorhanden)'))
                    if (this.neuDepot){
                        if (this.zielkontoAuswahl?.length){
                            this.params.zielkonto = this.zielkontoAuswahl[0].value;
                        }
                        this.params.kontonummer = null;
                    }
                }
            }
        },
        zielkonto: {
            get() {
                return this.params.zielkonto
            },
            set(zielkonto) {
                this.params.zielkonto = zielkonto;
            }
        },
        kontonummer: {
            get() {
                return this.params.kontonummer
            },
            set(kontonummer) {
                this.params.kontonummer = kontonummer;
            }
        },
        optionsBeratung() {
            // 0 - bestehende Mappe, 1 - neue Mappe, 2 - ohne Beratung     
            const result = [
                {label: 'Eine bestehende Mappe auswählen', value: 0},
                {label: 'Eine neue Mappe anlegen', value: 1},
                {label: 'Order ohne Beratung', value: 2},
            ];
            if (this.stepParameters?.data?.zusatz?.linked) {
                result.unshift({label: 'Mit der Anlageempfehlung verlinkte Mappe', value: -1})
            }
            return result;
        },
        ziellagerstelleAuswahl() { // (Kein Depot vorhanden)
            return (this.stepParameters?.data.ziellagerstelleAuswahl || []).map(item => {
                return {value: item.value, label: !item.label ? '' : item.label.replace('(Kein Depot vorhanden)', '') };
            })
        },
        zielkontoAuswahl() {
            const values = this.stepParameters.data.zielkontoAuswahl[this.params.ziellagerstelle];
            return values.map((item) => {
                const labelClear = item?.label?.replace(/\[c=([\w]+)\](.*)\[\/c\]/gi, '$2@@$1');
                const [label, color] = labelClear?.split('@@');
                return {
                    ...item,
                    label,
                    style: color ? { fontWeight: 'bold' } : {},
                };
            }).filter(f => !this.neuDepot || f.value != 'freie Eingabe:');
        },
        completed() {
            return false;
        },
        orderDisabled() {
            return !(this.params && this.params.ziellagerstelle && (this.params.kontonummer || this.params.zielkonto !== 'freie Eingabe:'));
        },
        orderInitialized() {
            return false && this.order && this.order.status === 'success' /*&& !this.order.data.fehlerData*/;
        },
        selectedMappe() {
            if (!this.orderDisabled && this.stepParameters?.data?.zusatz) {
                const zusatz = this.stepParameters.data.zusatz;
                if (this.beratungsmappenart === 0) {
                    if (zusatz.bmWahl) {
                        return (zusatz.bmWahl.beschreibung || zusatz.bmWahl.art) + ' erstellt am ' + zusatz.bmWahl.datum_erstellt;
                    }
                } else if (this.beratungsmappenart === 1) {
                    return 'Eine neue Mappe anlegen';
                } else if (this.beratungsmappenart === 2) {
                    return 'Order ohne Beratung';
                } else if ( this.beratungsmappenart == -1 && zusatz.linked) {
                     return (zusatz.linked.beschreibung || zusatz.linked.art) + ' erstellt am ' + zusatz.linked.datum_erstellt;
                }
                // if (zusatz.BM_link) {
            }
            return '';
        },
        mainParameters() {
            return this.parameters(INVESTMENT_ADVICE.STEPS.MAIN) || {};
        },
        hasDepotPositionen() {
            const paran = this.mainParameters?.data?.parameterData;
            if (paran?.bestandsdepot == '-4' || paran?.bestandsdepot == '-5' || paran?.bestandsdepot == '-6' ) {
                return false
            }
            return true;
        },
    },
    watch:{
        order: function(params) {
            this.loading = false;
            // this.initOrderCards(params);
        },
        stepParameters() {
            if (this.stepParameters?.data?.zusatz?.bmOption != undefined) {
                this.beratungsmappenart = this.stepParameters.data.zusatz.bmOption;
                if ( this.$route.query?.mappe && !this.stepParameters.data.zusatz.bmWahl) {
                    const mappe = decodeURI(this.$route.query?.mappe);
                    this.stepParameters.data.zusatz.bmWahl = JSON.parse(mappe);
                    // setTimeout(() => { this.commit(); }, 0);
                    this.commit();
                }
            } else if (this.stepParameters?.data?.zusatz?.linked)  {
                this.stepParameters.data.zusatz.bmOption = -1;
                // setTimeout(() => { this.commit(); }, 0);
                this.commit();
            }
        }
    },
    methods: {
        triggerSelectZiellagerstelle(event) {
        },
        triggerSelectKonto(event) {
        },
        triggerSelectRabatt(event) {
        },
        setBMArt(value) {
            this.stepParameters.data.zusatz.bmOption = value;
            if (value == 0) {
                this.$router.push({path: '/beratung/beratung/auswahl', query: {
                    typ: 'BERATUNG_2018,VERMITTLUNG_2018',
                    closed_status: 'Not Closed'
                }});
            } else if (value == 1) {
                if (!this.stepParameters.data.zusatz.newMappe) {
                    this.stepParameters.data.zusatz.newMappe = {};
                }
                this.$refs.createBeratungsmappeModal.open(this.stepParameters.data.zusatz.newMappe)

            }
            this.commit();
        },
        createBeratungsmappe(newMappe) {
            this.stepParameters.data.zusatz.newMappe = newMappe;
        },
        openOrder(lagerstelle, posBesitzer) {
            this.order.data.orderList[posBesitzer].checked = true;
            this.commit();
        },
        commit() {
            const params = JSON.parse(JSON.stringify(this.stepParameters.data));
            this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_PARAMETERS_SUCCESS, {data: params, key: 'ORDER'})
        }
    },
    updated() {},
    mounted(){
        // this.initOrderCards(this.order);
    },
}
</script>
<style scoped>
.div-textarea {
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 2px;
    padding: 0.65rem 1.25rem;   
    height: 370px; 
    overflow: scroll;
    margin-top: 16px;
    margin-bottom: 16px;
  }

.action-order-step-view >>> .box-list__item:last-child {
    margin-bottom: 0;
}
</style>