import MAKLER_ANTRAG_TYPES from './types';

export default{
    [MAKLER_ANTRAG_TYPES.GETTERS.GET_MAKLER_ANTRAG](state) {
        return state.maklerAntrag;
    },    
    [MAKLER_ANTRAG_TYPES.GETTERS.GET_AUSWERTUNG](state) {
        return state.maklerAntragAuswertung;
    },
    [MAKLER_ANTRAG_TYPES.GETTERS.GET_CONFIG_DATA](state){
        return state.maklerAntragConfigData;
    },
    [MAKLER_ANTRAG_TYPES.GETTERS.GET_JAHRESCHECKLISTE_DOCS](state){
        return state.attachments
    }
  
}