<template>
    <div>
        <FlexibleList :rows="rows" showThirdRow :linkInFirstRow="isEditable" @onRowClick="onRowClick">
      
            <template #title="row">
                {{ row.label }}
            </template>

            <template #value="row">
                {{ row.begin || "./." }}
            </template>

            <template #thirdRow="row">
                {{ row.bereichDisplay }}
            </template>      
  
        </FlexibleList>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CALENDAR_TYPES from '@/store/calendar/types';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';
import LOG_TYPES from '@/store/log/types';
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";


import { 
  PhCheck,
} from 'phosphor-vue';

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, DateTimeColumn, IconColumn, PillColumn, Icon, CurrencyColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import Pill from '@/components/core/Pill.vue';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';

const MAP_WV_STATUS_TO_PILL_TYPE = {
  UNBESTAETIGTE_VERGANGENHEIT: 'warning',
  UNBESTAETIGTE_ZUKUNFTIG: 'info',
  BESTAETIGTE: 'success',
};

const BEREICH = 'SonstigeProdukte';


export default {
  data() {
    return {
        loading: false,
        appointmentToDelete: null,
        MAP_WV_STATUS_TO_PILL_TYPE,
        BEREICH,
    };
  },
  computed: {
    ...mapGetters({
      sprodId: OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID,
      otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
      appointments: OTHER_PRODUCTS_TYPES.GETTERS.APPOINTMENTS,
      appointmentCombos: OTHER_PRODUCTS_TYPES.GETTERS.APPOINTMENT_COMBOS,
    }),
    rows() {
        return (this.appointments || []).map(item => ({
            ...item.appointment,
            retry: item.appointment.retry && item.appointment.retry !== 'Keine' ? [
                Icon(PhCheck, "Ja", 24),
            ] : undefined,
            wvStatusMessage: item.appointment.wvStatus ? {
                label: this.appointmentCombos?.wvStatusMessage?.[item.appointment.wvStatus],
                type: MAP_WV_STATUS_TO_PILL_TYPE[item.appointment.wvStatus],
            } : undefined,
            artText: item.appointment.art ? this.appointmentCombos?.artComboWV?.[item.appointment.art] : '',
            adressatText: this.getAdressatText(item.appointment, item.beteiligteOutput),
            bereichText: this.getBereichText(item.appointment),
            customerNames: item.beteiligteOutput.filter(o => !!o?.customerName).map(o => o?.customerName).join(', '),
            sellingPhaseText: this.getSellingPhaseText(item.appointment),
            statusText: this.getStatusText(item.appointment),
        }));
    },
    isEditable() {
        return true;
    },
  },
  watch: {
    sprodId: {
      handler() {
        this.findAppointments();
      },
      immediate: true,
    },
  },
  methods: {
    onRowClick(whatRow) {
      //alert(JSON.stringify(whatRow));
      // Edit window must be implemented for the broker perspective in the app view
    },  
    findAppointments() {
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_APPOINTMENTS);
    },

    getAdressatText(appointment, beteiligteOutput) {
      const adressatText = [];
      if(appointment?.ownerName) {
        adressatText.push(appointment.ownerName);
      }
      beteiligteOutput.forEach(o => adressatText.push(o.name));
      return adressatText.join(', ');
    },
    getBereichText(appointment) {
      const bereichText = this.appointmentCombos?.bereichCombo?.[appointment.bereich] || '';

      const description = [];
      if(this.otherProduct?.company) {
        description.push(this.otherProduct.company);
      }
      if(this.otherProduct?.produktart) {
        description.push(this.otherProduct.produktart);
      }

      const descriptionExtra = [];
      if(this.otherProduct?.anlageArtenImmobilie) {
        descriptionExtra.push(this.otherProduct.anlageArtenImmobilie);
      }
      if(this.otherProduct?.produktbez) {
        descriptionExtra.push(this.otherProduct?.produktbez);
      }
      if(this.otherProduct?.sprodNr) {
        descriptionExtra.push(this.otherProduct?.sprodNr);
      }

      return `${bereichText}: ${description.join(', ')}(${descriptionExtra.join(', ')})`;
    },
    getSellingPhaseText(appointment) {
      if(!appointment.sellingPhase) return '';
      return appointment.activity ? this.appointmentCombos?.verkaufsphaseWVCombo?.[appointment.sellingPhase] : '';
    },
    getStatusText(appointment) {
      if(!appointment.status) return '';
      return appointment.activity ? this.appointmentCombos?.statusComboWV?.[appointment.status] : this.appointmentCombos?.statusComboAufgabe?.[appointment.status];
    },
  },
  mounted() {
  },
  components: {
    PhCheck,
    Table,
    AnimatedSpinner,
    BaseModal,
    AppointmentEditCreate,
    Pill,
    FlexibleList,
  },
}
</script>