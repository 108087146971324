<template>
  <div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
    }),
  },
  methods: {
    async openCustomerNewTab() {
      if (this.$route.query.kundennr && this.$route.query.path) {
        await this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_SAME_TAB_FOR_TESTING, {
          customerId: this.$route.query.kundennr,
          path: this.$route.query.path
        });
      }

      this.navigateHome();
    },
    navigateHome() {
      this.$router.push('/home');
    }
  },
  mounted() {
    if (this.isBroker) {
      this.openCustomerNewTab();
    } else {
      this.navigateHome();
    }
  }
}

</script>