<template>
  <SignoViewerLink
    asButton
    :disabled="isDisabled"
    :isSecondary="!isMainAction"
    :title="responsiveLabel"
    :href="href"
    :requestURL="requestURL"
    :extraParams="extraParams"
    :isDeepLink="isDeepLink"/>
</template>
<script>
import actionMixin from './header-actions-mixin.js';
import SignoViewerLink from '@/components/core/download/SignoViewerLink.vue'

export default {
  mixins: [actionMixin],
  props: {
    href: {
        type: String,
    },
    requestURL: {
      type: Boolean,
      default: false,
    },
    extraParams: {
        type: Object,
    },
    isDeepLink: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SignoViewerLink,
  }
}
</script>
