<template>
    <div>
        <PageHeaderTitleNavigation
            title="Limits"
            subtitle="Depotlimit"
            :defaultMenu="hasRoles([VIEW_ROLES.VIEW_BROKER]) ? [] : $appNavigation.currentTabMenu"
            :actions="actions"
            @action-RESET="save('reset')"
            @action-REMOVE="remove()"
        />

        <div class="box__container">
            <div  class="box__title">{{title}}</div>
            <div class="mb-4">Zum Setzen der Limits muss ein prozentualer Wert, basierend auf dem aktuellen Depotwert, eingegeben werden. 
                Der Ausgangswert wird in Zukunft entsprechend getätigter Käufe oder Verkäufe angepasst. Um den Ausgangswert an den 
                aktuellen Depotwert anzupassen oder um bei einer Benachrichtigung das Limit neu zu setzen, ist der "Zurücksetzen"-Button zu drücken. 
                Soll ein Limit (oberes oder unteres) nicht beachtet werden, so ist dieser Wert leer bzw. auf 0 zu lassen. Mit dem 
                Aktivieren der Dynamik wird der Wert des unteren Limits immer vom Höchststand des Depots aus berechnet.
            </div>
        </div>

        <div class="box__container">
            <div class="info-block">
                <div v-if="!isCustomerOrigin">Kundennr: {{limit.kundennr}}</div>
                <div v-if="!isCustomerOrigin">Kunde: {{limit.kunde}}</div>
                <div>Aktueller Depotwert: {{formatCurrency(limit.depotWert)}}</div>
                <div>Ausgangswert (Depotwert): {{formatCurrency(limit.asgangswert)}}</div>
            </div>
            <InputField
                label="Abweichung nach unten"
                v-model="form.abweichungLow"
                type="percent"
                :precision="4"
                isComponentHalfSize />
            <InputField
                label="Abweichung nach oben"
                v-model="form.abweichungHigh"
                :precision="4"
                type="percent"
                isComponentHalfSize />
            <InputToggleSwitch
                v-model="form.isDynamisch" 
                label="Dynamik"
            />

            <div>Ausgangswert für Dynamik: {{formatCurrency(limit.dynamik)}}</div>
            <div v-if="limit.dateUpdated">Letzte Änderung: {{limit.dateUpdated}}</div>
            <div v-if="limit.dateOutOfLimit">Datum der Überschreitung: {{limit.dateOutOfLimit}}</div>

            <InputTextArea v-if="!isCustomerOrigin"
                label="Bemerkung (für Kunden nicht sichtbar)"
                v-model="form.bemerkung" 
                isComponentHalfSize />
        </div>
        <BaseModal
            ref="saveOrReset"
            labelButtonCancel="Verwerfen"
            labelButtonConfirm="Speichern"
            @onCancelButton="handleReset"
            @onConfirmButton="save('save')">
            <template v-slot:modalTitle>
                Möchte Sie Ihre Änderungen speichern?
            </template>
        </BaseModal>
    </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import CORE_TYPES from '@/store/core/types';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue' 
import {toCurrencyString} from '@/filters.js';
import mixin from '@/mixins/limit/limit-mixin.js';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import BaseModal from '@/components/core/BaseModal.vue';

export default {
    mixins: [mixin],
    components: {
        BaseButton,
        InputField,
        InputTextArea,
        InputToggleSwitch,
        PageHeaderTitleNavigation,
        BaseModal,
    },
    computed: {
        ...mapGetters({
            depotlimit: LIMIT_TYPES.GETTERS.DEPOTLIMIT,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        limit() {
            return this.depotlimit;
        },
        title() {
            return `Depotlimit ${this.limit?.notExist ? '(nicht gesetzt)' : ''}`;
        },
        actions() {
            return [
                PageHeaderSimpleAction('REMOVE', 'Löschen').withDisabled(() => this.limit?.notExist),
                PageHeaderSimpleAction('RESET', 'Zurücksetzen').withDisabled(() => this.limit?.notExist),
            ]
        },
        isChanged() {
            return this.limit && JSON.stringify(this.limit) !== JSON.stringify(this.form)
        },
    },
    data() {
        return {
            form: {
                abweichungLow: '',
                abweichungHigh: '',
                isDynamisch: '0',
                bemerkung: ''
            },
            kundennr: '',
            nextCallback: null,
        }
    },
    watch: {
        limit(value) {
            if (Object.keys(value).length) {
                this.form = Object.assign({}, value);
            }
        }
    },
    mounted() {
        this.kundennr = this.$route.query.kundennr || '';
        this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_DEPOTLIMIT, {kundennr: this.kundennr});
    },
    methods: {
        formatCurrency(value) {
            return toCurrencyString(value);
        },
        saveOrReset(callback) {
            if (this.isChanged) {
                this.nextCallback = callback;
                this.$refs.saveOrReset.open();
            } else {
                callback();
            }
        },
        handleReset() {
            this.$refs.saveOrReset.close();
            if (this.nextCallback) {
                this.nextCallback();
            }
        },
        save(action = 'save') {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.SAVE_DEPOTLIMIT, Object.assign(this.form, {action: action, kundennr: this.kundennr}));
            if (this.nextCallback) {
                this.nextCallback();
            }
        },
        remove() {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_DEPOTLIMIT);
        }
    },
    beforeRouteLeave(to, from, next) {
        this.saveOrReset(next);
    },
}
</script>

<style scoped>
.info-block {
    display: flex;
    flex-direction: column; 
    flex-grow: 2;
    justify-content: space-between;
}
</style>