var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "person-overview__container box__container-wrap",
      on: {
        click: function($event) {
          return _vm.openPersonDetails()
        }
      }
    },
    [
      _c("Table", {
        attrs: {
          "table-id": "2b46bde5-af6d-448a-bed9-6b875fb6ef92",
          headers: _vm.tableHeaders,
          rows: _vm.tableRows,
          "hide-pagination": ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }