<template>
  <div>
    <div class="box__container">
      <div class="box__title">Persönliche Daten</div>
      <InputToggleSwitch
        v-if="ansprechpartner.istEigenerAP"
        label="Freigabe für die Vermittler-Struktur"
        :value="ansprechpartner.struktur"
        :disabled="loading || readOnly"
        @input="updateAP('struktur', $event)"
      />
      <InputToggleSwitch
        v-if="isGesellschaft"
        label="Ansprechpartner inaktiv"
        :value="ansprechpartner.ansprechpartnerInaktiv"
        :disabled="loading || readOnly"
        @input="setInaktiv"
      />
      <ComboBox
        label="Typ" 
        isComponentHalfSize
        :values="comboboxSelections.typValues" 
        :value="ansprechpartner.type"
        :renderDanger="!loading && !ansprechpartner.type"
        :disabled="loading || readOnly"
        @change="updateAP('type', $event)"
      />
      <ComboBox
        label="Anrede" 
        isComponentHalfSize
        :values="comboboxSelections.anredeValues" 
        :value="ansprechpartner.anrede"
        :disabled="loading || readOnly"
        @change="updateAP('anrede', $event)"
      />
      <ComboBox
        label="Titel" 
        isComponentHalfSize
        :values="comboboxSelections.titleValues"
        :value="ansprechpartner.title"
        :disabled="loading || readOnly"
        @change="updateAP('title', $event)"
      />
      <InputField
        label="Vorname" 
        isComponentHalfSize
        :value="ansprechpartner.firstName"
        :disabled="loading || readOnly"
        @input="updateAP('firstName', $event)"
      />
      <InputField
        label="Name" 
        isComponentHalfSize
        :value="ansprechpartner.lastName"
        :renderDanger="!loading && !ansprechpartner.lastName"
        :disabled="loading || readOnly"
        @input="updateAP('lastName', $event)"
      />
      <InputField
        label="Beschreibung" 
        isComponentHalfSize
        :value="ansprechpartner.description"
        :disabled="loading || readOnly"
        @input="updateAP('description', $event)"
      />
      <InputField
        label="E-Mail" 
        isComponentHalfSize
        :value="ansprechpartner.email"
        :disabled="loading || readOnly"
        @input="updateAP('email', $event)"
      />
      <InputField
        label="Telefon" 
        isComponentHalfSize
        :value="ansprechpartner.phone"
        :disabled="loading || readOnly"
        @input="updateAP('phone', $event)"
        inputmode="tel"
      />
      <InputField
        label="Mobiltelefon" 
        isComponentHalfSize
        :value="ansprechpartner.mobile"
        :disabled="loading || readOnly"
        @input="updateAP('mobile', $event)"
        inputmode="tel"
      />
      <InputField
        label="Fax" 
        isComponentHalfSize
        :value="ansprechpartner.fax"
        :disabled="loading || readOnly"
        @input="updateAP('fax', $event)"
        inputmode="tel"
      />
      <DatePickerField
        label="Geburtsdatum"
        isValueAsString
        isComponentHalfSize
        :value="ansprechpartner.dayOfBirth"
        :disabled="loading || readOnly"
        @change="updateAP('dayOfBirth', $event)"
      />
      <InputField
        label="Briefanrede" 
        isComponentHalfSize
        :value="ansprechpartner.briefanrede"
        :disabled="loading || readOnly"
        @input="updateAP('briefanrede', $event)"
      />
      <ComboBox
        label="Zweigstelle" 
        isComponentHalfSize
        :values="comboboxSelections.zweigValues" 
        :value="ansprechpartner.zweigstelle"
        :disabled="loading || readOnly"
        @change="updateAP('zweigstelle', $event)"
      />
    </div>
    <div class="box__container">
      <div class="box__title">Private Adresse</div>
      <InputField
        label="Postleitzahl" 
        isComponentHalfSize
        :value="ansprechpartner.zip"
        :disabled="loading || readOnly"
        @input="updateAP('zip', $event)"
      />
      <InputField
        label="Ort" 
        isComponentHalfSize
        :value="ansprechpartner.city"
        :disabled="loading || readOnly"
        @input="updateAP('city', $event)"
      />
      <InputField
        label="Straße" 
        isComponentHalfSize
        :value="ansprechpartner.street"
        :disabled="loading || readOnly"
        @input="updateAP('street', $event)"
      />
      <ComboBox
        label="Land" 
        isComponentHalfSize
        :values="comboboxSelections.countryValues"
        :value="ansprechpartner.country"
        :disabled="loading || readOnly"
        @input="updateAP('country', $event)"
      />
      <InputToggleSwitch
        label="Reporting zusenden" 
        :value="ansprechpartner.sendReporting"
        :disabled="loading || readOnly"
        @input="updateAP('sendReporting', $event)"
      />
      <AnsprechpartnerPicture 
        :personId="ansprechpartner.id"
        :disabled="loading || readOnly"
      />
    </div>
  </div>
</template>

<script>

import ANSPRECHPARTNER_TYPES from '@/store/ansprechpartner/types';
import CORE_TYPES from '@/store/core/types'

import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue'
import { mapGetters } from 'vuex'
import AnsprechpartnerPicture from '@/views/gesellschaft/ansprechpartner/AnsprechpartnerPicture.vue';

export default {
  mixins: [],
  components: {
    ComboBox,
    InputField,
    DatePickerField,
    InputToggleSwitch,
    AnsprechpartnerPicture,
  },
  props: {
    loading: {
      type : Boolean,
      default: false,
    },
    readOnly: {
      type : Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      ansprechpartner: ANSPRECHPARTNER_TYPES.GETTERS.GET_ANSPRECHPARTNER,
      comboboxSelections: ANSPRECHPARTNER_TYPES.GETTERS.GET_ANSPRECHPARTNER_SELECTIONS,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isGesellschaft: CORE_TYPES.GETTERS.IS_GESELLSCHAFT,
    }),
  },
  methods: {
    setInaktiv(value) {
      if (value) {
        this.$confirmModal({
            title: 'Ansprechpartner inaktiv setzen', 
            message: 'Achtung: Sofern der Ansprechpartner inaktiv gesetzt wird, besteht im Nachgang keine Zugriffsmöglichkeit mehr', 
            labelButtonConfirm: 'Ok',
            showCancelButton: false,
        })
      }

      this.updateAP('ansprechpartnerInaktiv', value)
    },
    updateAP(fieldId, value) {
      this.$emit('change', 
      {
        fieldId: fieldId,
        value: value,
      })
    },
  },
}
</script>
