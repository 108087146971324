<template>
  <div>
    <div class="editable_link_container">
      <div class="text-small edit" v-if="isBroker" @click="edit">
        bearbeiten
        <ph-pencil-line title="Bearbeiten" :size="16" />
      </div>
      <div class="innerContent" v-if="isBroker"></div>
    </div>

    <BaseModal
      ref="editSourceNewsFeed"
      modalTitle="RSS-Feed"
      @onConfirmButton="onConfirmModal()">
      <div class="inner_modal">
        <div class="title">
          Bitte ergänzen Sie den RSS-Feed für Ihre Nachrichten mit kompletter URL.
          
        </div>
        <div>
          Wird keine URL eingetragen, wird der Nachrichten-Bereich ausgeblendet.
        </div>
        <div class="social">
          <ph-rss :size="16" />
          <div class="social_title">RSS-Feed:</div>
          <InputField v-model="rssSrc"/>
        </div>
      </div>
      <template v-slot:footer>
        <div class="col-auto">
          <BaseButton isSecondary @click="deleteCustomRssSrc">Standard RSS-Feed</BaseButton>
        </div>
      </template>
    </BaseModal>
  </div>
</template>
<script>
import { buildMessage } from "@/helpers/log-message-helper";
import CORE_TYPES from "@/store/core/types";
import CMS_TYPES from "@/store/cms/types";
import { mapGetters, dispatch, commit } from "vuex";
import BaseModal from "@/components/core/BaseModal.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import InputField from '../core/forms/InputField.vue';
import { PhPencilLine, PhRss } from 'phosphor-vue';

export default {
  components: {
    BaseModal,
    BaseButton,
    InputField,
    PhPencilLine,
    PhRss,
  },
  data() {
    return {
      rssSrc: ''
    };
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION
    }),
    storedRssSrc() {
      return this.maklerInfo && this.maklerInfo.rssSrc
    }
  },
  mounted() {
    if (this.storedRssSrc) {
      this.rssSrc = this.storedRssSrc;
    }
  },
  watch: {
    storedRssSrc(newValue) {
      this.rssSrc = newValue
    }
  },

  methods: {
    edit() {
      this.rssSrc = this.storedRssSrc;
      this.$refs.editSourceNewsFeed.open();
    },

    onConfirmModal() {
      this.$store.dispatch(CMS_TYPES.ACTIONS.UPDATE_RSS_SRC, this.rssSrc);
    },

    deleteCustomRssSrc() {
      this.$store.dispatch(CMS_TYPES.ACTIONS.DELETE_RSS_SRC);
    }
  }
};
</script>
<style scoped>
.inner_modal{
  width: 100%;
}
.edit {
  color: var(--color-danger);
  cursor: pointer;
}
.title {
  color: var(--color-text);
  font-weight: bold;
}
.input_class {
  justify-self: right;
}
input[type="text"] {
  width: 95%;
}
.social_title {
  justify-self: left;
}
.cancel_button {
  justify-self: end;
}
.social {
  margin-top: 1em;
  color: var(--color-text);
  display: grid;
  grid-template-columns: 1fr 3fr 15fr;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .social {
    margin-top: 1em;
    margin-bottom: 1em;
    color: var(--color-text);
    display: grid;
    gap: 0.3em;
    grid-template-columns: 1fr 4fr 8fr;
    align-items: center;
  }
}
</style>