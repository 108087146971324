var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AntragSidetext", {
        attrs: {
          antragId: _vm.antragId,
          antragData: _vm.getAntragData,
          warnings: _vm.warnings,
          steps: _vm.stepper.getCurrentStepList(),
          highestStepVisited:
            _vm.stepper.getCurrentStepList() &&
            _vm.stepper.getCurrentStepList().length,
          showMessageErrorMustBeFixed: "",
          showExpanded: ""
        },
        on: {
          executeAction: _vm.onExecuteAction,
          navigateToWarning: function($event) {
            return _vm.$emit("navigateToWarning", $event)
          },
          setSubstep: function($event) {
            return _vm.setSubstepByKey($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }