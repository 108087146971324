var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: {
          "action-UPLOAD_DOCUMENT": _vm.uploadNewDocument,
          "action-CREATE_KOMMUNIKATION": _vm.openEditOrCreateEinreichungModal
        }
      }),
      _vm.loading.init
        ? [
            _c("GhostLoading", {
              attrs: { useBoxContainer: "", type: "input" }
            }),
            _c("GhostLoading", {
              attrs: { useBoxContainer: "", type: "table" }
            }),
            _c("GhostLoading", {
              attrs: { useBoxContainer: "", type: "block" }
            })
          ]
        : [
            _vm.isIntern
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Faxserver")
                    ]),
                    _c("ComboBox", {
                      attrs: {
                        isComponentHalfSize: "",
                        sortComboboxValues: false,
                        values: _vm.faxServerValues
                      },
                      model: {
                        value: _vm.antragsbearbeitung.faxServer,
                        callback: function($$v) {
                          _vm.$set(_vm.antragsbearbeitung, "faxServer", $$v)
                        },
                        expression: "antragsbearbeitung.faxServer"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("Table", {
                  attrs: {
                    title: "Kommunikationen",
                    headers: _vm.kommunikationHeaders,
                    rows: _vm.kommunikationRows,
                    rowsPerPage: 10
                  },
                  on: {
                    "click-bezeichnung": _vm.openEditOrCreateEinreichungModal,
                    "action-DELETE": _vm.deleteGesellKommunikation
                  }
                })
              ],
              1
            ),
            _vm.isIntern
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Elektonische Signatur")
                    ]),
                    _c("InputToggleSwitch", {
                      attrs: {
                        label: "e-signiert Text mit Ort/Datum weglassen"
                      },
                      model: {
                        value: _vm.antragsbearbeitung.ohneESignaturText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.antragsbearbeitung,
                            "ohneESignaturText",
                            $$v
                          )
                        },
                        expression: "antragsbearbeitung.ohneESignaturText"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isIntern
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Spezielle Einstellungen Versicherungen")
                    ]),
                    _vm._v(
                      " Ist nichts eingetragen, erfolgen die Erinnerungen in beiden Fällen nach 14 Tagen. "
                    ),
                    _c("InputField", {
                      attrs: {
                        label: "Erste Erinnerung nach Anzahl Tagen",
                        isComponentHalfSize: "",
                        type: "number"
                      },
                      model: {
                        value: _vm.antragsbearbeitung.einreichungVersWartezeit1,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.antragsbearbeitung,
                            "einreichungVersWartezeit1",
                            $$v
                          )
                        },
                        expression:
                          "antragsbearbeitung.einreichungVersWartezeit1"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "Folgeerinnerungen nach Anzahl Tagen",
                        isComponentHalfSize: "",
                        type: "number"
                      },
                      model: {
                        value: _vm.antragsbearbeitung.einreichungVersWartezeit2,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.antragsbearbeitung,
                            "einreichungVersWartezeit2",
                            $$v
                          )
                        },
                        expression:
                          "antragsbearbeitung.einreichungVersWartezeit2"
                      }
                    }),
                    _c("InputTextArea", {
                      attrs: {
                        label: "Vorgehensweise der Antragsbearbeitung",
                        isComponentHalfSize: ""
                      },
                      model: {
                        value:
                          _vm.antragsbearbeitung.vorgehenAntragsbearbeitung,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.antragsbearbeitung,
                            "vorgehenAntragsbearbeitung",
                            $$v
                          )
                        },
                        expression:
                          "antragsbearbeitung.vorgehenAntragsbearbeitung"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isModusVersDaten
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("InputField", {
                      attrs: {
                        label: "Typischer Bearbeitungszeitraum (Tage)",
                        isComponentHalfSize: "",
                        type: "number"
                      },
                      model: {
                        value: _vm.antragsbearbeitung.estimatedDoneDate,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.antragsbearbeitung,
                            "estimatedDoneDate",
                            $$v
                          )
                        },
                        expression: "antragsbearbeitung.estimatedDoneDate"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isIntern
              ? _c(
                  "DragnDropArea",
                  {
                    attrs: {
                      hoverText: "Datei hier ablegen",
                      disabled: _vm.isSomethingLoading
                    },
                    on: {
                      files: function($event) {
                        return _vm.dropFiles($event)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("Table", {
                          attrs: {
                            title: "Beispielanträge",
                            headers: _vm.gesellAntragHeaders,
                            rows: _vm.gesellAntragRows,
                            rowsPerPage: 10
                          },
                          on: {
                            "action-EDIT": _vm.editDocument,
                            "action-DELETE": _vm.deleteDocument
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "bezeichnung",
                                fn: function(row) {
                                  return [
                                    _c("DownloadLink", {
                                      attrs: {
                                        title: row.bezeichnung,
                                        filename: row.bezeichnung,
                                        downloadServicePath: "/get_simple_file",
                                        queryParams: {
                                          fileId: row.id
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2640898708
                          )
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ],
      _c(
        "DokumentUploadModal",
        {
          ref: "uploadModal",
          attrs: {
            disableMultifile: "",
            disableFotoUpload: "",
            confirmDisabled: !_vm.fileData.bezeichnung
          },
          on: { upload: _vm.uploadDocuments },
          model: {
            value: _vm.files,
            callback: function($$v) {
              _vm.files = $$v
            },
            expression: "files"
          }
        },
        [
          _c("InputField", {
            attrs: {
              label: "Bezeichnung",
              renderDanger: !_vm.fileData.bezeichnung
            },
            model: {
              value: _vm.fileData.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "bezeichnung", $$v)
              },
              expression: "fileData.bezeichnung"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Typ",
              sortComboboxValues: false,
              values: _vm.fileTypValues,
              value: _vm.fileData.typ,
              disabled: ""
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.fileData.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "bemerkung", $$v)
              },
              expression: "fileData.bemerkung"
            }
          }),
          _c("DatePickerField", {
            attrs: { label: "Stand", isValueAsString: "" },
            model: {
              value: _vm.fileData.dateStand,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "dateStand", $$v)
              },
              expression: "fileData.dateStand"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "editOrCreateEinreichungModal",
          attrs: {
            modalTitle: "Kennung hinzufügen",
            autoClose: false,
            confirmDisabled:
              !_vm.editKommunikationRow.name ||
              (_vm.isVersicherung && !_vm.editKommunikationRow.zweigstelleId)
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveGesellKommunikation()
            }
          }
        },
        [
          _vm.isIntern
            ? _c("InputToggleSwitch", {
                attrs: { label: "Alle Antragsarten anzeigen" },
                model: {
                  value: _vm.editKommunikationRow.alleGesellschaften,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.editKommunikationRow,
                      "alleGesellschaften",
                      $$v
                    )
                  },
                  expression: "editKommunikationRow.alleGesellschaften"
                }
              })
            : _vm._e(),
          _c("ComboBox", {
            attrs: {
              label: "Antragsart",
              sortComboboxValues: false,
              values: _vm.currentArtValues,
              renderDanger: !_vm.editKommunikationRow.name
            },
            model: {
              value: _vm.editKommunikationRow.name,
              callback: function($$v) {
                _vm.$set(_vm.editKommunikationRow, "name", $$v)
              },
              expression: "editKommunikationRow.name"
            }
          }),
          _vm.isVersicherung
            ? [
                _c("ComboBox", {
                  attrs: {
                    label: "Versicherungsbereich",
                    sortComboboxValues: false,
                    values: _vm.versKategorieValues
                  },
                  model: {
                    value: _vm.editKommunikationRow.versKategorie,
                    callback: function($$v) {
                      _vm.$set(_vm.editKommunikationRow, "versKategorie", $$v)
                    },
                    expression: "editKommunikationRow.versKategorie"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Zweigstelle",
                    sortComboboxValues: false,
                    values: _vm.zweigstelleValues,
                    renderDanger: !_vm.editKommunikationRow.zweigstelleId
                  },
                  model: {
                    value: _vm.editKommunikationRow.zweigstelleId,
                    callback: function($$v) {
                      _vm.$set(_vm.editKommunikationRow, "zweigstelleId", $$v)
                    },
                    expression: "editKommunikationRow.zweigstelleId"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    disabled:
                      _vm.editKommunikationRow.name !== "Schadensmeldung",
                    label: "Telefon Ansprechpartner"
                  },
                  model: {
                    value: _vm.editKommunikationRow.appTelefonZusatz,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.editKommunikationRow,
                        "appTelefonZusatz",
                        $$v
                      )
                    },
                    expression: "editKommunikationRow.appTelefonZusatz"
                  }
                })
              ]
            : _vm._e(),
          _c("ComboBox", {
            attrs: {
              label: "Einreichung mittels",
              sortComboboxValues: false,
              values: _vm.einreichungArtValues
            },
            model: {
              value: _vm.editKommunikationRow.art,
              callback: function($$v) {
                _vm.$set(_vm.editKommunikationRow, "art", $$v)
              },
              expression: "editKommunikationRow.art"
            }
          }),
          _vm.editKommunikationRow.showSignForbidden
            ? _c("InputToggleSwitch", {
                attrs: { label: "digitale Signatur ist nicht erlaubt" },
                model: {
                  value: _vm.editKommunikationRow.signForbidden,
                  callback: function($$v) {
                    _vm.$set(_vm.editKommunikationRow, "signForbidden", $$v)
                  },
                  expression: "editKommunikationRow.signForbidden"
                }
              })
            : _vm._e(),
          _vm.istFPPlusMaster
            ? _c("ComboBox", {
                attrs: {
                  label: "Einreichung gilt für",
                  sortComboboxValues: false,
                  values: _vm.einreichungZusatzValues
                },
                model: {
                  value: _vm.editKommunikationRow.beschraenkungZusatz,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.editKommunikationRow,
                      "beschraenkungZusatz",
                      $$v
                    )
                  },
                  expression: "editKommunikationRow.beschraenkungZusatz"
                }
              })
            : _vm._e(),
          _c("InputField", {
            attrs: { label: "Mailadresse / Faxnummer" },
            model: {
              value: _vm.editKommunikationRow.adresse,
              callback: function($$v) {
                _vm.$set(_vm.editKommunikationRow, "adresse", $$v)
              },
              expression: "editKommunikationRow.adresse"
            }
          }),
          _c("InputField", {
            attrs: { label: "CC" },
            model: {
              value: _vm.editKommunikationRow.cc,
              callback: function($$v) {
                _vm.$set(_vm.editKommunikationRow, "cc", $$v)
              },
              expression: "editKommunikationRow.cc"
            }
          }),
          _c("InputField", {
            attrs: { label: "Betreff" },
            model: {
              value: _vm.editKommunikationRow.betreff,
              callback: function($$v) {
                _vm.$set(_vm.editKommunikationRow, "betreff", $$v)
              },
              expression: "editKommunikationRow.betreff"
            }
          }),
          _c("InputField", {
            attrs: {
              label:
                "Passwort für Verschlüsselung von PDF-Dokumenten in E-Mails"
            },
            model: {
              value: _vm.editKommunikationRow.passwort,
              callback: function($$v) {
                _vm.$set(_vm.editKommunikationRow, "passwort", $$v)
              },
              expression: "editKommunikationRow.passwort"
            }
          }),
          _c("InputTextHtml", {
            model: {
              value: _vm.editKommunikationRow.text,
              callback: function($$v) {
                _vm.$set(_vm.editKommunikationRow, "text", $$v)
              },
              expression: "editKommunikationRow.text"
            }
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }