<template>
  <component 
    :is="dynamicTemplate" 
    v-if="dynamicTemplate"
    v-on ="dynamicEvents"
    v-bind="dynamicBindings"
  />
</template>

<script>
export default {
  props: {
    focusOnPosition: {
      type: String,
      default: '',
    }
  },
  computed: {
    routeStep() {
      return this.$route.params.step;
    },
    dynamicTemplate() {
      if (this.routeStep) {
        switch (this.routeStep) {
          case 'main':
            return () => import('@/views/wertpapierorder/WertpapierorderMain.vue')
          case 'positions-data':
            return () => import('@/views/wertpapierorder/WertpapierorderPositionData.vue')
          case 'capitalbank':
            return () => import('@/views/wertpapierorder/WertpapierorderCapitalbankData.vue')  
          case 'capitalbank-kyc': 
            return () => import('@/views/wertpapierorder/WertpapierorderCapitalbankDataKYC.vue') 
          case 'hellobank':
            return () => import('@/views/wertpapierorder/WertpapierorderHellobankData.vue')
          case 'semperconstantia':
            return () => import('@/views/wertpapierorder/WertpapierorderSemperConstantiaData.vue') 
          case 'hellobank-selbst':
            return () => import('@/views/wertpapierorder/WertpapierorderHellobankSelbst.vue')
          case 'sonstiges':
            return () => import('@/views/wertpapierorder/WertpapierorderSonstiges.vue')
          //case 'FODB': //intended only for Firmen Kunden (ohne Webservice)
            //return () => import('@/views/wertpapierorder/WertpapierorderFODB.vue')
          case 'fodb-mix':
            return () => import('@/views/wertpapierorder/WertpapierorderFODBMix.vue')
          case 'ebase-edelmetal':
            return () => import('@/views/wertpapierorder/WertpapierorderEdelmetalData.vue')
          case 'dab':
            return () =>import('@/views/wertpapierorder/WertpapierorderDAB.vue')  
          case 'morgen-fund':
              return () =>import('@/views/wertpapierorder/WertpapierorderMorgenFund.vue')  
          case 'aktionen':
            return () => import('@/views/wertpapierorder/WertpapierorderAktionen.vue')
        }
      }
    },
    dynamicEvents(){
      if (this.routeStep) {
        switch (this.routeStep) {
          case 'main':
            return{
                ebaseEdelmetalFound: () => this.$emit('ebaseEdelmetalFound'),
            }
          case 'aktionen':
            return{
                navigateToWarning: ($event) => { 
                  this.$emit('navigateToWarning', $event) 
                }
            }
        }
      }
      return {};
    },
    dynamicBindings() {
      if (this.routeStep) {
        switch (this.routeStep) {
          case 'main':
            return {
              focusOnPosition: this.focusOnPosition,
            }
        }
      }
      return {}
    },
  }
}
</script>

<style>

</style>