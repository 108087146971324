<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <FormLabel label="Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen."
        :config="{'bold': true}" />
      <FormDivider/>

      <FormLabel label="Daten der bisher depotführenden Gesellschaft" :config="{'bold': true}"/>
      <InputField v-model="form.bisherManuellName" label="Name"
        @change="$emit('save', { bisherManuellName: $event })"/>
      <InputField v-model="form.bisherManuellStrasse" label="Straße"
        @change="$emit('save', { bisherManuellStrasse: $event })"/>
      <InputField v-model="form.bisherManuellPLZ" label="PLZ"
        @change="$emit('save', { bisherManuellPLZ: $event })"/>
      <InputField v-model="form.bisherManuellOrt" label="Ort"
        @change="$emit('save', { bisherManuellOrt: $event })"/>

      <FormDivider/>

      <FormLabel label="Daten des Depots bei der bisher depotführenden Gesellschaft" :config="{'bold': true}"/>
      <InputField v-model="form.bisherDepotnr" label="Depotnummer"
        @change="$emit('save', { bisherDepotnr: $event })"/>

      <FormLabel label="Bisheriger Depotinhaber 1." :config="{'bold': true}"/>
      <InputField v-model="form.bisherName" label="Nachname"
        @change="$emit('save', { bisherName: $event })"/>
      <InputField v-model="form.bisherVorname" label="Vorname"
        @change="$emit('save', { bisherVorname: $event })"/>
      <InputField v-model="form.bisherStr" label="Straße, HNr."
        @change="$emit('save', { bisherStr: $event })"/>
      <InputField v-model="form.bisherPlz" label="PLZ"
        @change="$emit('save', { bisherPlz: $event })"/>
      <InputField v-model="form.bisherOrt" label="Ort"
        @change="$emit('save', { bisherOrt: $event })"/>
      <InputField v-model="form.bisherTin" label="TIN"
        @change="$emit('save', { bisherTin: $event })"/>
      
      <FormDivider/>
      
      <FormLabel label="Bisheriger Depotinhaber 2." :config="{'bold': true}"/>
      <InputField v-model="form.bisherName2" label="Nachname"
        @change="$emit('save', { bisherName2: $event })"/>
      <InputField v-model="form.bisherVorname2" label="Vorname"
        @change="$emit('save', { bisherVorname2: $event })"/>
      <InputField v-model="form.bisherStr2" label="Straße, HNr."
        @change="$emit('save', { bisherStr2: $event })"/>
      <InputField v-model="form.bisherPlz2" label="PLZ"
        @change="$emit('save', { bisherPlz2: $event })"/>
      <InputField v-model="form.bisherOrt2" label="Ort"
        @change="$emit('save', { bisherOrt2: $event })"/>
      <InputField v-model="form.bisherTin2" label="TIN"
        @change="$emit('save', { bisherTin2: $event })"/>
      </div>
      <BaseButton @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';

export default {
  props: {
    positionData: {
    },
    warnings: {
    },
  },
  components: {
    FormLabel,
    InputField,
    BaseButton,
    FormDivider,
  },
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData || {});
    this.form = {
      bisherDepotnr: this.positionData.bisherDepotnr,
      bisherManuellName: this.positionData.bisherManuellName,
      bisherManuellStrasse: this.positionData.bisherManuellStrasse,
      bisherManuellPLZ: this.positionData.bisherManuellPLZ,
      bisherManuellOrt: this.positionData.bisherManuellOrt,

      bisherAnrede: this.positionData.bisherAnrede,
      bisherName: this.positionData.bisherName,
      bisherVorname: this.positionData.bisherVorname,
      bisherID: this.positionData.bisherID,
      bisherID: this.positionData.bisherID,
      bisherGebdat: this.positionData.bisherGebdat,
      bisherStrasse: this.positionData.bisherStrasse,
      bisherPlz: this.positionData.bisherPlz,
      bisherOrt: this.positionData.bisherOrt,
      bisherGebname: this.positionData.bisherGebname,

      bisherAnrede2: this.positionData.bisherAnrede2,
      bisherName2: this.positionData.bisherName2,
      bisherID2: this.positionData.bisherID2,
      bisherVorname2: this.positionData.bisherVorname2,
      bisherGebdat2: this.positionData.bisherGebdat2,
      bisherStrasse2: this.positionData.bisherStrasse2,
      bisherHausnr2: this.positionData.bisherHausnr2,
      bisherPlz2: this.positionData.bisherPlz2,
      bisherOrt2: this.positionData.bisherOrt2,
      bisherGebname2: this.positionData.bisherGebname2,
    };
   
  },
  methods: {
  }
}
</script>
<style scoped>

</style>