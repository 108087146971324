<template>
  <div data-app-footer class="base-footer__container" v-if="showBaseHeader">
    <div class="base-footer__content">
      <WorkspacesMenu />
    </div>
  </div>
</template>

<script>

import CORE_TYPES from '../../../store/core/types'
import { mapGetters } from 'vuex'
import WorkspacesMenu from "@/components/core/navbar-workspaces/WorkspacesMenu.vue";

export default {
  mixins: [],
  data: function() {
    return {
      showProfile: false
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      brokerInformation: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      isPendingActionActiveFn: CORE_TYPES.GETTERS.IS_PENDING_ACTION_ACTIVE,
    }),
    showBaseHeader() {
      return this.$route.path !== '/login' && this.isLoggedIn && !this.isPendingActionActiveFn('changePassword');
    },
  },
  components: {
    WorkspacesMenu
  }
}
</script>

<style scoped>
.base-footer__container {
  background-color: var(--color-top-nav-background);
  box-shadow: 0 5px 0 var(--color-top-nav-background); /** prevents to see unexpected space when zooming a browser */
  color: var(--color-top-nav-text);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  transform: translate(0, 0);
}

.base-footer__content {
  line-height: 50px;
  height: 50px;
}

@media only screen and (min-width: 768px) {
    .base-footer__container {
      margin-bottom: 0;
      display: none;
    }

}

.disabled {
  color: #cccccc;
}
</style>

<!-- GLOBAL STYLE -->
<style>
@media only screen and (max-width: 767px) {
  .base-footer__container ~ [data-app-content] {
    margin-bottom: calc(50px + 1em); /** footer-bar height + 1em */
  }
}
</style>
