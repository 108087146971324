<template>
  <div class="datepicker__selectors-container" :key="key" :class="{
    'datepicker__selectors--inline': datePickerSelectorsInline,
    'datepicker__selectors--month-picker': monthPicker,
  }">
    <DatePickerSelector 
      ref="datePickerSelector1"
      class="datepicker__selectors--item datepicker__selectors--item-1"

      v-bind="$attrs"
      :monthPicker="monthPicker"
      :isRangePicker="isRangePicker"
      :isFocused="lastSelectedDatePicker === DATE_PICKER_SOURCE_ONE"

      @onSelectMonth="$emit('onSelectMonth', $event)"
      @onSelectDay="onSelectDay($event, DATE_PICKER_SOURCE_ONE)"
      @referenceDayChanged="syncDatePickersPeriod($event, DATE_PICKER_SOURCE_ONE)"
    />
    <DatePickerSelector 
      v-if="isSecondDateSelectorEnabled"
      ref="datePickerSelector2"
      class="datepicker__selectors--item datepicker__selectors--item-2"

      v-bind="$attrs"
      isSecondDateSelector
      :isRangePicker="isRangePicker"
      :isFocused="lastSelectedDatePicker === DATE_PICKER_SOURCE_TWO"

      @onSelectMonth="$emit('onSelectMonth', $event)"
      @onSelectDay="onSelectDay($event, DATE_PICKER_SOURCE_TWO)"
      @referenceDayChanged="syncDatePickersPeriod($event, DATE_PICKER_SOURCE_TWO)"
    />
  </div>
</template>

<script>
import DatePickerSelector, { PERIOD_SELECTORS, } from '@/components/core/forms/date-picker2/DatePickerSelector.vue'

const DATE_PICKER_SOURCE_ONE = 1;
const DATE_PICKER_SOURCE_TWO = 2;

export default {
  props: {
    datePickerSelectorsInline: {
      type: Boolean,
      default: true,
    },
    monthPicker: {
      type: Boolean,
      default: false,
    },
    isRangePicker: {
      type: Boolean,
      default: false,
    },
    showSecondarySelector: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DATE_PICKER_SOURCE_ONE,
      DATE_PICKER_SOURCE_TWO,
      lastSelectedDatePicker: DATE_PICKER_SOURCE_ONE,
    };
  },
  computed: {
    key() { // forces rerender when the props changes
      const { datePickerSelectorsInline, monthPicker, } = this;
      return `${datePickerSelectorsInline}-${monthPicker}`;
    },
    isSecondDateSelectorEnabled() {
      return this.isRangePicker || this.isShowSecondarySelectorEnabled;
    },
    isShowSecondarySelectorEnabled() {
      return !this.monthPicker && this.showSecondarySelector;
    },
  },
  methods: {
    onSelectDay(event, source) {
      this.lastSelectedDatePicker = source;
      this.$emit('onSelectDay', event);
    },
    syncDatePickersPeriod(event, source) {
      if(!this.isShowSecondarySelectorEnabled) return;

      const { referenceDay, period } = event;
      const { datePickerSelector1, datePickerSelector2, } = this.$refs;

      if(!datePickerSelector2 || period !== PERIOD_SELECTORS.DAY) return; // only valid for when is day selector

      // sync reference day
      if(source === DATE_PICKER_SOURCE_TWO) { // sync the first date picker selector to one month before
        datePickerSelector1?.changeReferenceDay(referenceDay.add(-1, 'month'), false);
      } else { // sync the second date picker selector to one month after
        datePickerSelector2?.changeReferenceDay(referenceDay.add(1, 'month'), false);
      }

      // sync period selector view
      datePickerSelector1?.changePeriodSelector(period);
      datePickerSelector2?.changePeriodSelector(period);
    },
  },
  components: {
    DatePickerSelector,
  },
}
</script>

<style lang="scss" scoped>
.datepicker__selectors-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  .datepicker__selectors--item {
    max-width: 240px;
    min-height: 272px;
  }

  &.datepicker__selectors--inline {
    flex-direction: row;
    gap: 16px;

    .datepicker__selectors--item {
      min-height: none;
    }
  }

  &.datepicker__selectors--month-picker {
    .datepicker__selectors--item {
      min-height: 210px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .datepicker__selectors-container {
    &.datepicker__selectors--inline {
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
</style>
