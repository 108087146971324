<template>
  <div>
    <div class="box__container" style="display:flex; flex-direction:column">
      <div class="row" :style="styleOrder('kennzeichen')">
        <div class="col-12 col-sm-6">
          <InputField
            ref="kennzeichenInput"
            label="Kennzeichen"
            v-model="fahrzeugdaten.kennzeichen"
            :disabled="disabled"
          />
        </div>
      </div>      
      <div class="row" :style="styleOrder('fzgart')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Fahrzeugart (WKZ)"
            v-model="fahrzeugdaten.fzgart"
            :values="vertragsdatenFieldsDefinition && vertragsdatenFieldsDefinition.fzgart.value"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('marke')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Marke"
            v-model="fahrzeugdaten.marke"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('handelsbez')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Handelsbezeichnung"
            v-model="fahrzeugdaten.handelsbez"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('leistung')">
        <div class="col-12 col-sm-6">
          <InputField
            type="number"
            isInteger
            label="Leistung (kW)"
            v-model="fahrzeugdaten.leistung"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('hubraum')">
        <div class="col-12 col-sm-6">
          <InputField
            type="number"
            isInteger
            label="Hubraum (cm³)"
            v-model="fahrzeugdaten.hubraum"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('plaetze')">
        <div class="col-12 col-sm-6">
          <InputField
            type="number"
            isInteger
            label="Plätze"
            v-model="fahrzeugdaten.plaetze"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('nutzlast')">
        <div class="col-12 col-sm-6">
          <InputField
            type="number"
            isInteger
            label="Nutzlast in kg"
            v-model="fahrzeugdaten.nutzlast"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('verwendzweck')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Nutzung"
            v-model="fahrzeugdaten.verwendzweck"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('nutzerkreis')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Nutzerkreis"
            v-model="fahrzeugdaten.nutzerkreis"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('antriebsart')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Antriebsart"
            v-model="fahrzeugdaten.antriebsart"
            :values="vertragsdatenFieldsDefinition && vertragsdatenFieldsDefinition.antriebsart.value"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('erstzulassdat')">
        <div class="col-12 col-sm-6">
          <DatePickerField
            label="Erstzulassung"
            v-model="fahrzeugdaten.erstzulassdat"
            isValueAsString
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('zulassung_vn_seit')">
        <div class="col-12 col-sm-6">
          <DatePickerField
            label="Zulassung auf VN seit"
            v-model="fahrzeugdaten.zulassung_vn_seit"
            isValueAsString
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('kilometerstand')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Kilometerstand"
            v-model="fahrzeugdaten.kilometerstand"
            :disabled="disabled"
            type="number"
            isInteger
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('kilometerstand_am')">
        <div class="col-12 col-sm-6">
          <DatePickerField
            label="Kilometerstand am"
            v-model="fahrzeugdaten.kilometerstand_am"
            isValueAsString
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('kilometerleistung')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Kilometerleistung"
            v-model="fahrzeugdaten.kilometerleistung"
            :disabled="disabled"
            type="number"
            isInteger
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('leasing')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Leasing"
            v-model="fahrzeugdaten.leasing" 
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('kat')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="KAT"
            v-model="fahrzeugdaten.kat" 
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('abs')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="ABS"
            v-model="fahrzeugdaten.abs" 
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('neuwert')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Neuwert"
            v-model="fahrzeugdaten.neuwert"
            :disabled="disabled"
            type="currency"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('zeitwert')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Zeitwert"
            v-model="fahrzeugdaten.zeitwert"
            :disabled="disabled"
            type="currency"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('zeitwert_vom')">
        <div class="col-12 col-sm-6">
          <DatePickerField
            label="Zeitwert vom"
            v-model="fahrzeugdaten.zeitwert_vom"
            isValueAsString
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('motornr')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Motornummer"
            v-model="fahrzeugdaten.motornr"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('stellplatz')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Stellplatz"
            v-model="fahrzeugdaten.stellplatz" 
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('wegfahrsperre')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Wegfahrsperre"
            v-model="fahrzeugdaten.wegfahrsperre" 
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('fahrgestnr')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Fahrzeug-Ident-Nr"
            v-model="fahrzeugdaten.fahrgestnr"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('schluesselnr_fzg')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Schlüsselnr (1)"
            v-model="fahrzeugdaten.schluesselnr_fzg"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('schluesselnr_herst')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Hersteller-Schlüsselnr(2)"
            v-model="fahrzeugdaten.schluesselnr_herst"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="row" :style="styleOrder('schluesselnr_typ')">
        <div class="col-12 col-sm-6">
          <InputField
            label="Typ-Schlüsselnr(3)"
            v-model="fahrzeugdaten.schluesselnr_typ"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FAHRZEUGDATEN_TYPES from '@/store/fahrzeugdaten/types';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import InsuranceOptionMenu from '@/views/versicherungen/insurance-group/InsuranceOptionMenu.vue';
import { minValue, maxValue, maxLength } from '@/mixins/validator/rules';
import validator from '@/mixins/validator'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import CORE_TYPES from '@/store/core/types';

export default {
  mixins: [validator],
  props: {
    versicherungId : {
      type: String,
      default: null
    }
  },
  validators: {
    fahrzeugdaten : {
      leistung : [minValue(0, 'Die Leistung (kW) muss eine positive ganze Zahl sein', {emptyAllowed: true}), maxValue(10000, 'Die Leistung (kW) sollte weniger als 10000', {emptyAllowed: true})],
      plaetze : [minValue(0, 'Die Plätze muss eine positive ganze Zahl sein', {emptyAllowed: true}), maxValue(1000, 'Die Plätze sollte weniger als 1000', {emptyAllowed: true})],
      hubraum : [minValue(0, 'Die Hubraum (cm³) muss eine positive ganze Zahl sein', {emptyAllowed: true}), maxValue(1000000000, 'Die Hubraum (cm³) sollte weniger als 1000000000', {emptyAllowed: true})],
      kilometerstand : [minValue(0, 'Der kilometerstand muss eine positive ganze Zahl sein', {emptyAllowed: true}), maxValue(1000000000, 'Der kilometerstand sollte weniger als 1000000000', {emptyAllowed: true})],
      kilometerleistung : [minValue(0, 'Der kilometerleistung muss eine positive ganze Zahl sein', {emptyAllowed: true}), maxValue(1000000000, 'Der kilometerleistung sollte weniger als 1000000000', {emptyAllowed: true})],
      nutzlast : [minValue(0, 'Die Nutzlast in kg muss eine positive ganze Zahl sein', {emptyAllowed: true}), maxValue(1000000000, 'Der Nutzlast in kg sollte weniger als 1000000000', {emptyAllowed: true})],
      neuwert : [minValue(0, 'Der Neuwert muss eine positive ganze Zahl sein', {emptyAllowed: true}), maxValue(100000000.00, 'Der Neuwert sollte weniger als 100000000,00', {emptyAllowed: true})],
      zeitwert : [minValue(0, 'Der Zeitwert muss eine positive ganze Zahl sein', {emptyAllowed: true}), maxValue(1000000000.00, 'Der Zeitwert sollte weniger als 1000000000,00', {emptyAllowed: true})],
      motornr : [maxLength(20, 'Die Motornummer darf maximal 20 Zeichen lang sein')],
      kennzeichen : [maxLength(12, 'Das Kennzeichen darf maximal 12 Zeichen lang sein')],
      marke : [maxLength(50, 'Die Marke darf maximal 50 Zeichen lang sein')],
      handelsbez : [maxLength(50, 'Die Handelsbezeichnung darf maximal 50 Zeichen lang sein')],
    }
  },
  components: {
    ComboBox,
    InputField,
    DatePickerField,
    InputToggleSwitch,
    InsuranceOptionMenu,
  },
  data() {
    return {
      loading: false,
      fahrzeugdaten: {
        fzgart : null,
        marke : null,
        handelsbez : null,
        leistung : null,
        hubraum : null,
        plaetze : null,
        nutzlast : null,
        verwendzweck : null,
        nutzerkreis : null,
        antriebsart : null,
        erstzulassdat : null,
        zulassung_vn_seit : null,
        kilometerstand : null,
        kilometerstand_am : null,
        kilometerleistung : null,
        leasing : null,
        kat : null,
        abs : null,
        neuwert : null,
        zeitwert : null,
        zeitwert_vom : null,
        motornr : null,
        kennzeichen : null,
        stellplatz : null,
        wegfahrsperre : null,
        fahrgestnr : null,
        schluesselnr_fzg : null,
        schluesselnr_herst : null,
        schluesselnr_typ : null,
      },
      radioValues: [
        { value: 'true', label: 'Ja' },
        { value: 'false', label: 'Nein' },
        { value: 'null', label: 'Unbekannt' }
      ],
    }
  },
    watch: {
    fahrzeugdatenList: {
      handler(val) {
        if(val?.length) {
          this.$set(this, 'fahrzeugdaten', val[0]);
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters({
      vertragsdatenFieldsDefinition: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
      fahrzeugdatenList: VERSICHERUNG_TYPES.GETTERS.FAHRZEUGDATEN_LIST,
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
      fcConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG,
      isKunde: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      isInternOrBroker: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    tableId() {
      const uniqueUUID = '549f5cf5-abb4-485d-b29f-a4bab99e75b0';
      const vertragsparte = this.vertragsdatenFieldsDefinition?.vertragssparte?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.description?.id)
      return `${uniqueUUID}-${vertragsparte?.label}`;
    },    
    currentFieldOrder() {
      // the "tableId" should be a computed function in the corresponding caller component
      const config = this.fcConfig['TABLE_COLUMN_CONFIG']?.[this.tableId]?.['content'];
      const result = {};

      if (config) {
        JSON.parse( config)?.forEach((c,index) => {
          result[c.key] = index;
        })
      }

      return result;
    },
    disabled() {
      return this.isKunde && this.versicherungenDetails?.insurance?.creatorUserId !== this.loginData?.userid;
    }
  },
  methods: {
    saveOrUpdate() {

      if(this.validation.invalid) {
        return Promise.reject('Form invalid')
      }
      const payload = {
        fahrzeugdaten: this.fahrzeugdaten,
        versicherungId: this.versicherungId,
      };
      if(this.fahrzeugdaten?.id) {
        return this.$store.dispatch(FAHRZEUGDATEN_TYPES.ACTIONS.UPDATE_FAHRZEUGDATEN, payload);
      }
      return this.$store.dispatch(FAHRZEUGDATEN_TYPES.ACTIONS.CREATE_FAHRZEUGDATEN, payload);
    },
    retrieveInsuranceDetails() {
      return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS, {
        versVertrag: this.versicherungId,
      });
    },    
    styleOrder(whatKey) {
      let index = this.currentFieldOrder[whatKey];

      if (typeof index === 'undefined') {
        index = 999;
      }
      return `order:${index}`
    },    
  },
  async mounted() {
    this.$store.commit(FAHRZEUGDATEN_TYPES.MUTATIONS.CLEAN_FAHRZEUGDATEN_EDIT)
    await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_FAHRZEUGDATEN_LIST, {vertragId: this.versicherungId});
    this.$refs.kennzeichenInput.focus();
  },
  beforeDestroy() {
    let promise = Promise.resolve();
    // kunde can edit only if insurance was created by him
    if(!this.disabled) {
      promise = this.saveOrUpdate();
    }
    promise.finally(() => {
      this.$store.commit(FAHRZEUGDATEN_TYPES.MUTATIONS.CLEAN_FAHRZEUGDATEN_EDIT)
      this.retrieveInsuranceDetails();
    });
  }

}
</script>
