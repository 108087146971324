import { render, staticRenderFns } from "./DownloadAppInfo.vue?vue&type=template&id=684f6ac7&scoped=true&"
import script from "./DownloadAppInfo.vue?vue&type=script&lang=js&"
export * from "./DownloadAppInfo.vue?vue&type=script&lang=js&"
import style0 from "./DownloadAppInfo.vue?vue&type=style&index=0&id=684f6ac7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684f6ac7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('684f6ac7')) {
      api.createRecord('684f6ac7', component.options)
    } else {
      api.reload('684f6ac7', component.options)
    }
    module.hot.accept("./DownloadAppInfo.vue?vue&type=template&id=684f6ac7&scoped=true&", function () {
      api.rerender('684f6ac7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/DownloadAppInfo.vue"
export default component.exports