var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DashboardPanel",
        {
          attrs: {
            id: "ea95c832-346a-4069-9667-6971c1f3bf62",
            title: _vm.headerTitle,
            data: _vm.dashboardData,
            subtitle: _vm.subtitle,
            headerActions: _vm.headerActions,
            noPrimaryAction: false
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.depotCharts, function(chart, key) {
                return {
                  key: chart.type,
                  fn: function(slotProps) {
                    return [
                      chart
                        ? _c("DepotpositionsChart", {
                            key: key,
                            attrs: { chart: chart, showTitle: false }
                          })
                        : _vm._e()
                    ]
                  }
                }
              })
            ],
            null,
            true
          )
        },
        [
          [
            _vm.depotChartsArray
              ? _c("DepotpositionsChart", {
                  attrs: { chart: _vm.depotChartsArray[0] }
                })
              : _vm._e()
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }