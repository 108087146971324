var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputRadioBoxGroup", {
          attrs: {
            value: _vm.form.uebtrall,
            validateUntouched: true,
            values: [
              {
                value: true,
                label: "Übertragung aller Investmentfondsanteile"
              },
              {
                value: false,
                label: "Übertragung folgender Investmentfondsanteile:"
              }
            ]
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { alleDepot: $event })
            }
          }
        }),
        _c("WertpapierauswahlDepotUebertragung", {
          attrs: {
            hasGesamtbestand: true,
            positions: _vm.positionData,
            anzahlFonds: _vm.anzahlFonds
          },
          on: {
            save: function($event) {
              return _vm.$emit("save", $event)
            },
            fondsfinder: function($event) {
              return _vm.$emit("fondsfinder", $event)
            }
          }
        }),
        _c("FormDivider"),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "anschließende Löschung des Depots mit Verkauf der Anteilsbruchstücke"
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { depotAufloesen: $event })
            }
          },
          model: {
            value: _vm.form.depotAufloesen,
            callback: function($$v) {
              _vm.$set(_vm.form, "depotAufloesen", $$v)
            },
            expression: "form.depotAufloesen"
          }
        }),
        _c("FormLabel", {
          staticClass: "col-12",
          attrs: {
            label: "Der Verkaufserlös ist folgendem Konto gutzuschreiben:"
          }
        }),
        _c("EntitySelector", {
          attrs: {
            values: _vm.bankverbindungSelection,
            label: "Verwende Konto"
          },
          on: {
            new: _vm.editBankverbindung,
            edit: _vm.editBankverbindung,
            change: function($event) {
              return _vm.$emit("save", { bankverbindungId: $event })
            }
          },
          model: {
            value: _vm.form.bankverbindungId,
            callback: function($$v) {
              _vm.$set(_vm.form, "bankverbindungId", $$v)
            },
            expression: "form.bankverbindungId"
          }
        }),
        _c(
          "BaseButton",
          {
            staticClass: "col-12 mr-2",
            attrs: { isSecondary: true },
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v("Zurück")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }