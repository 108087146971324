<template>
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="881.50024" height="636.57254"
    viewBox="0 0 881.50024 636.57254" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>product_photography</title>
    <path
      d="M386.169,503.17359s1.84982,36.99614-77.07527,82.62469c0,0-.09369.20172,577.75625,0,0,0-65.97642-46.86175-70.29264-99.273"
      transform="translate(-159.24988 -131.71373)" opacity="0.2" />
    <path
      d="M814.1139,476.72487V165.844a17.34639,17.34639,0,0,0,12.99772-16.791v0a17.33921,17.33921,0,0,0-17.33925-17.3392H393.63032a17.33922,17.33922,0,0,0-17.33925,17.3392v0a17.33925,17.33925,0,0,0,17.33925,17.33925h.00377L384,477l-77.3497,98.99783s-.6503.00217,577.75624,0C884.40654,575.99783,884,577,814.1139,476.72487Z"
      transform="translate(-159.24988 -131.71373)" fill="#e6e6e6" />
    <ellipse cx="458.1012" cy="399.55668" rx="127.40581" ry="9.80045" opacity="0.2" />
    <polygon points="234.38 34.679 654.864 46.925 655.046 33.925 234.38 34.679" opacity="0.2" />
    <path
    d="M697.44049,275.419V313.6409a1.33622,1.33622,0,0,1-1.33267,1.33267h-.9485V514.085A20.77649,20.77649,0,0,1,674.384,534.86029H549.97065A20.77152,20.77152,0,0,1,529.20111,514.085V321.95687h-.68672a.94134.94134,0,0,1-.94274-.93695V299.267a.94257.94257,0,0,1,.94274-.94278h.68672v-8.19372h-.69255a.98724.98724,0,0,1-.98344-.98931V267.685a.986.986,0,0,1,.98344-.98348h.69255V255.51655h-.7042a.87176.87176,0,0,1-.87291-.87292V242.935a.87676.87676,0,0,1,.87291-.87873h.7042V218.34792a20.7703,20.7703,0,0,1,20.76954-20.76951H674.384a20.77527,20.77527,0,0,1,20.77536,20.76951v55.73841h.9485A1.33619,1.33619,0,0,1,697.44049,275.419Z"
    transform="translate(-159.24988 -131.71373)" fill="#3f3d56" />
  <rect x="437.22581" y="76.51818" width="23.17492" height="4.70583" rx="1.47923" fill="#e6e8ec" />
  <circle cx="466.57396" cy="78.87109" r="2.66916" fill="#e6e8ec" />
  <path
    d="M685.211,222.45633V515.80738a13.18125,13.18125,0,0,1-13.181,13.17517H552.93558a13.15807,13.15807,0,0,1-13.181-13.17517v-293.351a13.18044,13.18044,0,0,1,13.181-13.181h17.82488v2.287a10.8533,10.8533,0,0,0,10.85323,10.85323h60.56852a10.85328,10.85328,0,0,0,10.85319-10.85323v-2.287H672.03A13.17671,13.17671,0,0,1,685.211,222.45633Z"
    transform="translate(-159.24988 -131.71373)" :fill="secondColor"/>
  <rect x="392.95366" y="270.68425" width="121.04391" height="15.71243" fill="#e6e6e6" />
  <rect x="392.95366" y="298.61746" width="121.04391" height="15.71243" fill="#e6e6e6" />
  <rect x="392.95366" y="326.55067" width="121.04391" height="15.71243" fill="#e6e6e6" />
  <rect x="392.71097" y="157.78753" width="121.04391" height="80.30798" fill="#e6e6e6" />
  <path
    d="M386.384,177.90064l-62.07389.38555S222.13874,232.649,293.85145,345.23027c0,0,29.302-6.93994,61.68834,16.9643C355.53979,362.19457,374.43185,182.14171,386.384,177.90064Z"
    transform="translate(-159.24988 -131.71373)" :fill="color" />
  <path
    d="M367.919,178.46417c-11.12082,46.45358-23.69259,166.2664-23.69259,166.2664-28.93492-21.3567-55.389-18.09518-60.70289-17.15706a205.62807,205.62807,0,0,0,10.32794,18.1056s29.30193-6.93995,61.68831,16.96429c0,0,18.892-180.05284,30.84419-184.29391Z"
    transform="translate(-159.24988 -131.71373)" opacity="0.2" />
  <polygon
    points="134.216 104.405 128.818 130.623 60.984 118.293 60.961 118.285 59.419 87.441 72.528 87.441 134.216 104.405"
    fill="#3f3d56" />
  <polygon points="71.433 87.441 60.984 118.293 60.961 118.285 59.419 87.441 71.433 87.441" opacity="0.2" />
  <ellipse cx="131.9027" cy="115.97185" rx="7.71104" ry="17.7354" fill="#3f3d56" />
  <path
    d="M251.0552,622.48566a2.35677,2.35677,0,0,1-2.357-2.357v-372.443a2.35695,2.35695,0,1,1,4.7139,0v372.443A2.35677,2.35677,0,0,1,251.0552,622.48566Z"
    transform="translate(-159.24988 -131.71373)" fill="#3f3d56" />
  <path
    d="M283.43888,688.03008a2.35748,2.35748,0,0,1-2.31046-1.90581L248.742,520.09638a2.35705,2.35705,0,0,1,4.2844-1.74377L338.6187,648.90929a2.35722,2.35722,0,0,1-3.94237,2.58529l-79.009-120.51479L285.75486,685.222a2.35672,2.35672,0,0,1-1.86208,2.76481A2.38952,2.38952,0,0,1,283.43888,688.03008Z"
    transform="translate(-159.24988 -131.71373)" fill="#3f3d56" />
  <path
    d="M161.60475,658.72747a2.35706,2.35706,0,0,1-1.96888-3.64867l89.44814-136.485a2.35672,2.35672,0,0,1,3.94237,2.58344l-89.44814,136.485A2.35425,2.35425,0,0,1,161.60475,658.72747Z"
    transform="translate(-159.24988 -131.71373)" fill="#3f3d56" />
  <path
    d="M813.616,177.90064l62.07389.38555S977.86126,232.649,906.14855,345.23027c0,0-29.302-6.93994-61.68834,16.9643C844.46021,362.19457,825.56815,182.14171,813.616,177.90064Z"
    transform="translate(-159.24988 -131.71373)" :fill="color" />
  <path
    d="M832.081,178.46417c11.12082,46.45358,23.69259,166.2664,23.69259,166.2664,28.93492-21.3567,55.389-18.09518,60.70289-17.15706a205.62807,205.62807,0,0,1-10.32794,18.1056s-29.30193-6.93995-61.68831,16.96429c0,0-18.892-180.05284-30.84419-184.29391Z"
    transform="translate(-159.24988 -131.71373)" opacity="0.2" />
  <polygon
    points="747.284 104.405 752.682 130.623 820.516 118.293 820.539 118.285 822.081 87.441 808.973 87.441 747.284 104.405"
    fill="#3f3d56" />
  <polygon points="810.067 87.441 820.516 118.293 820.539 118.285 822.081 87.441 810.067 87.441" opacity="0.2" />
  <ellipse cx="749.59754" cy="115.97185" rx="7.71104" ry="17.7354" fill="#3f3d56" />
  <path
    d="M948.9448,622.48566a2.35677,2.35677,0,0,1-2.35695-2.357v-372.443a2.35695,2.35695,0,1,1,4.7139,0v372.443A2.35677,2.35677,0,0,1,948.9448,622.48566Z"
    transform="translate(-159.24988 -131.71373)" fill="#3f3d56" />
  <path
    d="M916.56158,688.03008a2.38246,2.38246,0,0,1-.4539-.04327,2.35772,2.35772,0,0,1-1.86254-2.76481l30.087-154.24313-79.0085,120.51571a2.35722,2.35722,0,1,1-3.94237-2.58529l85.59232-130.55668a2.35733,2.35733,0,0,1,4.28486,1.74377l-32.386,166.02789A2.35834,2.35834,0,0,1,916.56158,688.03008Z"
    transform="translate(-159.24988 -131.71373)" fill="#3f3d56" />
  <path
    d="M1038.39479,658.72747a2.35346,2.35346,0,0,1-1.973-1.06523l-89.44814-136.485a2.35672,2.35672,0,0,1,3.94237-2.58344l89.44814,136.485a2.35705,2.35705,0,0,1-1.96934,3.64867Z"
    transform="translate(-159.24988 -131.71373)" fill="#3f3d56" />
  <path
    d="M546.27705,459.002l16.88725-4.70176s6.61464-1.32049,2.5729-14.239-12.05785-9.303-12.05785-9.303l-14.98069,4.35227-5.9145,1.82653,8.32195,11.675.69757,7.78162Z"
    transform="translate(-159.24988 -131.71373)" fill="#3f3d56" />
  <polygon
    points="354.396 293.155 349.098 314.319 376.245 340.629 378.579 341.214 384.805 339.465 390.104 318.302 384.737 312.658 390.223 311.221 378.516 299.856 372.407 301.138 367.507 296.273 370.814 294.951 371.126 293.706 367.12 290.553 354.396 293.155"
    fill="#3f3d56" />
  <polygon points="379.831 323.006 376.324 337.011 360.144 321.715 364.196 307.515 379.831 323.006" :fill="color" />
  <polygon
    points="354.396 293.155 353.653 296.123 380.648 322.384 382.982 322.968 389.014 321.336 389.346 321.329 390.104 318.302 384.737 312.658 390.223 311.221 378.516 299.856 372.407 301.138 367.507 296.273 370.814 294.951 371.126 293.706 367.12 290.553 354.396 293.155"
    fill="none" stroke="#3f3d56" stroke-miterlimit="10" />
  <ellipse cx="526.116" cy="424.60113" rx="1.52396" ry="2.24584"
    transform="translate(-172.80352 700.12301) rotate(-75.94348)" :fill="color" />
  <ellipse cx="543.26592" cy="447.25101" rx="1.52396" ry="2.24584"
    transform="translate(-181.79062 733.90811) rotate(-75.94348)" :fill="color" />
  <path
    d="M557.49712,485.366l-3.68271-12.52121s9.575-22.83278-1.47308-25.04241-11.78467,21.35971-11.78467,21.35971l4.41925,13.99428Z"
    transform="translate(-159.24988 -131.71373)" fill="#ffb6b6" />
  <circle cx="329.87285" cy="300.04879" r="29.25747" fill="#ffb6b6" />
  <path
    d="M498.58838,452.41485s1.721,21.51285,6.0236,23.23387S458.14424,489.417,458.14424,489.417l12.9077-14.62874s2.58155-22.37336-3.442-27.53644Z"
    transform="translate(-159.24988 -131.71373)" fill="#ffb6b6" />
  <path
    d="M539.82012,478.73709l11.78466-1.47308s13.99429,7.36542,13.99429,16.94045,8.102,25.04241,8.102,25.04241,5.89233,20.62317-7.36542,22.09625-25.04241-22.09625-25.04241-22.09625Z"
    transform="translate(-159.24988 -131.71373)" :fill="color" />
  <path
    d="M546.449,595.84719s25.779-24.30587,33.14437-22.83279,22.09624,3.68271,22.09624,14.73083-24.30586,36.82707-24.30586,36.82707-18.41354,40.50978-36.09054,41.98287a305.05046,305.05046,0,0,0-34.61744,5.15579s8.10195,29.46165,3.6827,37.56361-21.3597,17.677-22.83278,16.94045-22.09625-19.88662-30.93474-47.13865-14.73083-47.87519-6.62888-49.34828S546.449,595.84719,546.449,595.84719Z"
    transform="translate(-159.24988 -131.71373)" fill="#2f2e41" />
  <path
    d="M599.48,585.53561s5.15579-.73655,4.41925,11.78466-2.94617,57.45023-6.62888,66.28873-4.41924,19.15008-2.94616,22.83278,4.41925,9.575-.73654,10.31159-26.51549-2.20963-28.72512-2.94617-4.41925-13.25775-2.94617-13.25775,5.1558-.73654,3.68271-5.89233-2.20962-5.15579,0-6.62887,8.102-3.68271,6.62888-5.15579-2.94617-36.09053-2.94617-36.09053Z"
    transform="translate(-159.24988 -131.71373)" fill="#2f2e41" />
  <path
    d="M592.11456,694.54374s12.52121-3.68271,13.99429,4.41925c0,0,2.20963,12.5212-7.36541,16.94045a118.32886,118.32886,0,0,0-17.677,10.31158s-15.46737,5.89234-16.20391-1.47308S567.8087,690.861,567.8087,690.861Z"
    transform="translate(-159.24988 -131.71373)" fill="#2f2e41" />
  <path
    d="M508.88538,703.38224l-23.56933-19.15008s4.41925,23.56933-1.47308,27.98858l-2.69154,7.86887,7.53706,6.16543S511.83155,722.53232,508.88538,703.38224Z"
    transform="translate(-159.24988 -131.71373)" fill="#2f2e41" />
  <path
    d="M475.741,670.97441s15.46738,4.41925,13.25775,19.88662-5.0427,32.27154-5.0427,32.27154-.84963,41.38261-11.89775,44.32877-13.99429-2.94616-13.99429-2.94616-12.55861-32.33761-8.8385-41.98286c4.503-11.67475,10.99166-24.78374,8.8385-28.72512C455.94538,689.92894,459.5371,668.76479,475.741,670.97441Z"
    transform="translate(-159.24988 -131.71373)" fill="#2f2e41" />
  <path
    d="M466.90252,467.689s5.89233.73654,15.46737,2.20963,16.20391-5.89233,21.3597-4.41925S551.60478,477.264,551.60478,477.264l-6.62887,50.82136s8.102,58.92332,6.62887,69.2349,17.677,8.8385,6.62888,21.3597-30.1982,23.56933-69.2349,19.88662-44.19249-2.94617-44.19249-8.8385,8.8385-55.97715,5.89234-60.3964-8.8385-9.575-5.89234-14.73083,5.89234-8.10195,1.47309-12.5212-6.62888-8.8385-6.62888-11.04812-10.31158-38.30016-10.31158-38.30016,4.41925-17.677,9.575-18.41353,9.575-4.41925,13.25775-4.41925,5.89233.73654,8.10195,0Z"
    transform="translate(-159.24988 -131.71373)" :fill="color" />
  <path
    d="M537.53373,421.50066c-.3789-1.70244-2.23382-7.394-6.005-8.78258-2.75915-1.01592-7.893,1.1306-10.45.77452l-.32964-.93652a21.25135,21.25135,0,0,0-1.36723-5.37631A27.51307,27.51307,0,0,0,504.28694,391.793c-8.42185-3.52064-21.743-6.43171-33.30044,2.7935-15.87479,12.67135-13.07748,31.63221-11.924,36.893q.28866,1.31635.49457,2.64938c.71141,4.634,2.76165,5.18683,2.76165,5.18683s-.04415-.13353-.11624-.37719l14.33856,1.39577,7.75206-1.323c.12968.7581.18,1.20626.18,1.20626,22.62147-3.45775,35.78554-15.373,37.92333-17.43929,1.87926,1.24589,5.3793,3.2803,8.77353,3.7266C536.14106,427.15845,537.91264,423.20309,537.53373,421.50066ZM479.247,432.89239l-18.21615-.66259c-.25824-4.97225,1.02581-10.78653,8.34792-11.08323,7.05976-.28607,10.91322,5.45367,12.95533,10.70894Z"
    transform="translate(-159.24988 -131.71373)" fill="#2f2e41" />
  <ellipse cx="483.80206" cy="389.17434" rx="3.22255" ry="1.21934"
    transform="translate(-217.82668 -44.77484) rotate(-9.68516)" fill="#2f2e41" />
</svg></template>

<script>
import undraw from "@/components/icons/undraw/undraw-mixin.js";

export default {
  mixins: [undraw],
};
</script>
