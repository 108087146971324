<template>
  <div class="box__container">
    <div class="box__title">{{ title }}</div>

    <div v-if="isMainPerson">
      <div class="mt-16px">
        <ComboBox 
          v-if="partner"
          label="" 
          :isComponentHalfSize="true"
          :values="comboPersons"
          v-model="internalData.personId"
          :disabled="!isEditable"
          @change="dataChangedCombo('personId', $event)" />
        <InputField 
          v-else
          label="" 
          :isComponentHalfSize="true" 
          v-model="fullName" 
          :disabled="true" />
      </div>

      <div class="mt-16px">
        <div class="box__title">Bruttoeinkommen</div>

        <InputField 
          type="currency" 
          label="mtl. Bruttoeinkommen" 
          :isComponentHalfSize="true"
          v-model="internalData.bruttoeinkommen"
          :precision="precision"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @input="bruttoeinkommmenpaDataChanged('bruttoeinkommen');dataChanged();" />

        <InputField 
          type="number"
          label="Anzahl Monate" 
          :isComponentHalfSize="true"
          v-model="internalData.anzahlmonate"
          :precision="0"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @input="bruttoeinkommmenpaDataChanged('anzahlmonate');dataChanged();" />

        <InputToggleSwitch 
          :label="bruttoeinkommenManuellLabel"
          :inLineLabel="true"
          v-model="internalData.bruttoeinkommenManuell"
          :precision="precision"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @input="bruttoeinkommmenpaDataChanged('bruttoeinkommenManuell');dataChanged();" />

        <InputField 
          type="currency" 
          label="Bruttoeinkommen p.a." 
          :isComponentHalfSize="true"
          v-model="internalData.bruttoeinkommmenpa"
          :precision="precision"
          :disabled="partner && !isPersonIdSelected || !isBruttoeinkommenManuellActive || !isEditable"
          @change="dataChanged()" />

        <InputField 
          type="currency" 
          label="zu versteuerndes Einkommen p.a." 
          :isComponentHalfSize="true"
          v-model="internalData.versteinkommenpa"
          :precision="precision"
          :disabled="partner && !isPersonIdSelected || !isEditable || isLoading"
          @change="dataChanged(); $emit('taxDataChanged');" />

        <ComboBox 
          v-if="!partner"
          label="steuerliche Veranlagung" 
          :isComponentHalfSize="true"
          :values="customerDataConfig.veranlagung"
          v-model="internalData.stVeranlagung"
          :disabled="!isEditable || isLoading"
          @change="dataChangedCombo('stVeranlagung', $event); $emit('taxDataChanged');" />

        <ComboBox 
          label="Religionsgemeinschaft" 
          :isComponentHalfSize="true"
          :values="customerDataConfig.religiousCommunities"
          v-model="internalData.religionsname"
          :disabled="partner && !isPersonIdSelected || !isEditable || isLoading"
          @change="dataChangedCombo('religionsname', $event); $emit('taxDataChanged');" />

        <div v-if="dataTaxResult.kirchensteuerFehler" class="color-danger">{{ dataTaxResult.kirchensteuerFehler }}</div>

        <InputField 
          type="percent" 
          label="Kirchensteuersatz in %" 
          :isComponentHalfSize="true"
          v-model="dataTaxResult.kirchensteuerSatz"
          :precision="precision"
          :disabled="true" />

        <InputField 
          type="currency" 
          label="Kirchensteuer" 
          :isComponentHalfSize="true" 
          v-model="dataTaxResult.kirchensteuerBetrag"
          :precision="precision"
          :disabled="true" />

        <InputField 
          type="percent" 
          label="Grenzsteuersatz in %" 
          :isComponentHalfSize="true"
          v-model="dataTaxResult.grenzsteuerSatz"
          :precision="precision"
          :disabled="true" />

        <InputField 
          type="percent" 
          label="Durchschnittssteuersatz in %" 
          :isComponentHalfSize="true"
          v-model="dataTaxResult.durchschnsteuerSatz"
          :precision="precision"
          :disabled="true" />

        <InputField 
          type="currency" 
          label="Einkommenssteuer" 
          :isComponentHalfSize="true" 
          v-model="dataTaxResult.einkommenssteuerBetrag"
          :precision="precision"
          :disabled="true" />

        <InputField 
          type="currency" 
          label="Solidaritätszuschlag" 
          :isComponentHalfSize="true" 
          v-model="dataTaxResult.solizuschlagBetrag"
          :precision="precision"
          :disabled="true" />
      </div>

      <div class="mt-16px">
        <div class="box__title">Rente</div>
         <InputField v-if="istFK"
          type="currency" 
          label="Rente wegen voller Erwerbsminderung (gem. Renteninformation)" 
          :isComponentHalfSize="true"
          v-model="internalData.erwerbsminderung"
          :precision="2"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChanged()" />

          <InputField v-if="istFK"
          type="currency" 
          label="Höhe der bislang erreichten Rentenanwartschaft (gem. Renteninformation)" 
          :isComponentHalfSize="true"
          v-model="internalData.rentenanwartschaftBislang"
          :precision="2"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChanged()" />

          <InputField v-if="istFK"
          type="currency" 
          label="Höhe Ihrer künftigen Regelaltersrente (ohne Berücksichtigung von Rentenanpassungen, gem. Renteninformation)" 
          :isComponentHalfSize="true"
          v-model="internalData.rentenanwartschaftZukunft"
          :precision="2"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChanged()" />
      
      </div>
      <div class="mt-16px">
        <div class="box__title">Rentenversicherung</div>

        <ComboBox 
          label="gesetzl. Rentenvers./Versorgungswerk" 
          :isComponentHalfSize="true"
          :values="customerDataConfig.pensionInsurance"
          v-model="internalData.gesetzlrentenversicherung"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChangedCombo('gesetzlrentenversicherung', $event)" />

        <ComboBox 
          label="Alte/Neue Bundesländer" 
          :isComponentHalfSize="true"
          :values="customerDataConfig.federalStates"
          v-model="internalData.altNeuBundeslaender"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChangedCombo('altNeuBundeslaender', $event)" />

        <InputField 
          label="Entgeltpunkte" 
          :isComponentHalfSize="true"
          v-model="internalData.entgeltPunkte"
          :precision="4"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChanged('entgeltPunkte', 4)" />
      </div>

      <div class="mt-16px">
        <div class="box__title">Krankenversicherung</div>

        <ComboBox 
          label="Krankenversicherungsstatus" 
          :isComponentHalfSize="true"
          :values="customerDataConfig.healthInsuranceStatus"
          v-model="internalData.krankenversicherungsstatus"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChangedCombo('krankenversicherungsstatus', $event)" />

        <InputField 
          type="currency" 
          label="Beitr. zur Kranken- und Pflegevers." 
          :isComponentHalfSize="true"
          v-model="internalData.beitragKrankenPflegeversicherung"
          :precision="precision"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChanged()" />

        <InputField 
          type="percent" 
          label="Beitragssatz GKV %" 
          :isComponentHalfSize="true"
          v-model="internalData.beitragssatzGKV"
          :precision="precision"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChanged()" />

        <InputField 
          type="currency" 
          label="Zuschlag Pflegevers. (ohne Kinder)" 
          :isComponentHalfSize="true"
          v-model="internalData.zuschlagPflegeversicherung"
          :precision="precision"
          :disabled="partner && !isPersonIdSelected || !isEditable"
          @change="dataChanged()" />
      </div>
      
      <div class="mt-16px">
        * Bei den berechneten Beträgen handelt es sich um einen Anhalt. Die exakten Beträge können, aufgrund von Freibeträgen etc., von den dargestellten Werten abweichen.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import persoenlicheDatenMixin from '@/components/persoenliche-daten/persoenliche-daten-mixin';

import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import CORE_TYPES from "@/store/core/types";
import validator from "@/mixins/validator";
import { maxValue, minValue } from '@/mixins/validator/rules'
import { parse } from '@/helpers/number-formatter.js'

const PRECISION = 2;


export default {
  mixins: [persoenlicheDatenMixin, validator],
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: {},
    },
    dataTaxResult: {
      type: Object,
      default: {},
    },
    partner: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  validators: {
    internalData:  {
      entgeltPunkte: [maxValue(999.9999, 'Die Zahl ist zu groß!')],
      anzahlmonate: [maxValue(15, 'Maximal 14 Monate!'), minValue(0, 'Die Zahl ist ungültig!')]
    }
  },
  emits: ['dataChanged', 'taxDataChanged', 'bruttoeinkommmenpaDataChanged'],
  data() {
    return {
      internalData: this.data,
      precision: PRECISION,
      comboPersons: [],
    };
  },
  computed: {
    ...mapGetters({
      persons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
      istFK: CORE_TYPES.GETTERS.IS_FK, 
    }),
    bruttoeinkommenManuellLabel() {
      return this.partner ? 'Manuelle Eingabe des Bruttoeinkommen p.a. des Partners' : 'Manuelle Eingabe des Bruttoeinkommen p.a.';
    },
    isPersonIdSelected() {
      return this.internalData?.personId;
    },
    isBruttoeinkommenManuellActive() {
      return this.internalData?.bruttoeinkommenManuell;
    },
    isLoading() {
      return this.loading;
    }
  },
  watch: {
    persons: {
      handler() {
        const personsLabelValue = this.persons?.map((p) => { 
          const firstName = p?.personalDataAddress?.firstName || '';
          const lastName = p?.personalDataAddress?.lastName || '';
          return {
            label: (firstName + ' ' + lastName).trim(), 
            value: p?.personId
          }
        });

        const comboPersons = [{ label: 'Nicht verwenden', value: ''}];
        this.comboPersons = [...comboPersons, ...personsLabelValue];
      },
      immediate: true,
    },
    data() {
      this.internalData = this.data;
    },
  },
  methods: {
    dataChanged(compId, precision) {
      if (compId && this.validation?.internalData?.[compId]?.invalid) {
        return;
      }
      if (precision) {
        this.internalData[compId] = parse(this.internalData[compId], precision);
      }
      this.$emit('dataChanged', this.internalData);
    },
    dataChangedCombo(field, value) {
      this.internalData[field] = value;
      this.dataChanged();
    },
    bruttoeinkommmenpaDataChanged(compId) {
      if (compId && this.validation?.internalData?.[compId]?.invalid) {
        return;
      }
      this.$emit('bruttoeinkommmenpaDataChanged');
    }
  },
  components: {
    InputField,
    ComboBox,
    InputToggleSwitch,
  },
}
</script>
