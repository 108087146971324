var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container" },
    [
      _c("label", { staticClass: "input-forms__label-container" }, [
        _vm.label
          ? _c("div", {
              staticClass: "input-forms__label-content",
              class: _vm.labelClass,
              domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
            })
          : _vm._e()
      ]),
      _c("InputRadioBoxGroup", {
        staticClass: "input-forms__radio-box-group__container",
        attrs: {
          name: _vm.name,
          value: _vm.internValue,
          values: _vm.dynamicValues,
          validateUntouched: _vm.validateUntouched,
          suppressValidationMessage: _vm.suppressValidationMessage,
          disabled: _vm.disabled,
          config: _vm.dynamicConfig
        },
        on: {
          input: function($event) {
            return _vm.onInput($event)
          }
        }
      }),
      _vm.isDynamicValueVisible
        ? _c(
            "div",
            { staticClass: "input-forms__input-dynamic-value__container" },
            [
              _c("AntragComponent", {
                attrs: {
                  component: _vm.dynamicInputComponent,
                  data: _vm.dynamicInputComponentData,
                  comboboxSelection: _vm.dynamicInputComponentComboboxSelection,
                  isComponentHalfSizeEnabled: "",
                  disabled:
                    _vm.disabled || _vm.isDynamicValueDisabledWhenNotSelected
                },
                on: {
                  change: function($event) {
                    return _vm.onComponentChange($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }