<template>
  <div class="table-container" :id="componentId" v-if="tableData && headers" ref="tableContainer">
    <div class="row" v-if="cardViewEnabled">
      <div class="col">
        <div class="mode-selector">
          <BaseButton 
            class="mode-selector-item"
            :isPrimary="activeView === 'list'"
            @click="setActiveView('list')">
            <ph-text-align-justify :size="16" />
          </BaseButton>
          <BaseButton class="mode-selector-item" 
            :isPrimary="activeView === 'cards'"
            @click="setActiveView('cards')">
            <ph-table :size="16" />
          </BaseButton>
        </div>
      </div>
    </div>
    <div v-if="activeView === 'list'">
      <div class="table--header">
        <div class="box__title">{{ title }}</div>

        <div class="export-links-align-right" v-if="showOpenColumnsConfig || showExport || paginationEnabled && rows.length > 10">
          <BaseContextMenu>
              <ContextMenuGroup v-if="showOpenColumnsConfig">
                  <ContextMenuItem @click="openColumnsConfig()">Spalten anpassen</ContextMenuItem>
              </ContextMenuGroup>

              <ContextMenuGroup v-if="showExport">
                  <ContextMenuItem v-if="exportTbl.pdf.show" @click="exportPDF()"><span>PDF</span></ContextMenuItem>
                  <ContextMenuItem v-if="exportTbl.xls.show" @click="exportTableToExcel()"><span>Excel</span></ContextMenuItem>
              </ContextMenuGroup>

              <ContextMenuGroup>
                  <template v-for="(item) in menuRowsPerPage">
                    <ContextMenuItem :key="item.value">
                      <div v-if="item.value == pageSize" class="mr-1 menu-disabled">
                        <PhList :size="16" class="mr-1"/>
                        <span>{{item.label}}</span>
                      </div>
                      <div v-else class="mr-1 clickable"  @click="tablePageSize=item.value">
                        <PhList :size="16" class="mr-1"/>
                        <span>{{item.label}}</span>
                      </div>
                    </ContextMenuItem>
                  </template>
              </ContextMenuGroup>
          </BaseContextMenu>
        </div>
      </div>

      <div class="row" v-if="allowColumnsPriorities">
        <div class="col-auto">
          <BaseButton @click="openColumnsConfig()" is-secondary>
            <ph-sort-ascending :size="16" />
          </BaseButton>
        </div>
      </div>

      <div class="row mb-0" v-if="filterEnabled">
        <div class="col-12 col-sm-6">
          <InputField 
            class="table-header__input-search mb-2" 
            :placeholder="searchPlaceHolder" 
            v-model="searchValue">
          </InputField>
        </div>
      </div>
      
      <table :id="title|lowercase" class="table__table-list" ref="table">
        <thead>
          <th data-table-col-key="collapse" class="table__control-col" :class = "{'hide' : !getIsSomeExpanded }"></th>

          <th data-table-col-key="checkbox" data-col-checkbox v-if="showCheckBoxBulkAction" class="table__control-col">
            <InputCheckBoxItem class="my-0" :value="isAllRowsSelected" @input="toggleSelectAllRows($event)" :indeterminate="!isAllRowsSelected && hasSomeRowsSelected" />
          </th>

          <template v-for="(col, key, index) in headers">
            <template v-if="col.visible">
              <th :key="key + index" 
                  :data-table-col-key="key" 
                  :data-col-checkbox="col.dataType === 'Boolean'" 
                  :class = "{ 'hide' : headersHiddenSmallScreen[key], 'actions-column': col.key === 'actions' }" 
              >

                <div :class="{
                  'tab-head-cell': col.key !== 'actions',
                  'text-right': col.align === 'right' || col.dataType === 'Currency' || col.dataType === 'Procent',
                  'text-left': col.align !== 'right' && (col.dataType === 'String' || col.dataType === 'Slot')
                }"
                :style="columnStyle(key)">
                  <template v-if="col.dataType === 'Boolean'">
                    <InputCheckBoxItem :value="isAllRowsSelectedByKey(key)" @input="toggleSelectAllRowsByKey(key, $event)" :indeterminate="!isAllRowsSelectedByKey(key) && hasSomeRowsSelectedByKey(key)" class="my-0 table__control-col" />
                  </template>
                  <template v-if="col.label === '_slot'">
                    <slot :name="'_'+key" :data="{col}"></slot>
                  </template>
                  <template v-else>
                      <span class="table__table-th-labels mr-1"
                            :class="{'clickable': col.key !== 'actions' && col.sortable}"
                            @click="toggleSort(col.sortKey || key, col.sortable)"
                            :tid="col && col.key ? _generateTidFromString(col.key) : _generateTidFromString(col.label)">
                        {{col.label}}
                      </span>
                      <span class="table__control-icon" v-if="col.key !== 'actions'">
                        <ph-caret-up :size="16" v-show="showArrowUp(col.sortKey || key)"/>
                        <ph-caret-down :size="16" v-show="showArrowDown(col.sortKey || key)"/>
                      </span>
                  </template>
                </div>
              </th>
            </template>
          </template>
        </thead>
        <tbody>
          <template class="data-row" v-for="(row, index) in currentPageData">
            <DragnDropArea
              :key="index"
              :id="'tr' + componentId + index"
              :style="rowStyle(row)"
              element="tr"
              hoverText="Datei hier ablegen"
              :disabled="!dragnDropFilesOnRow"
              @files="handleRowFilesDropped(row, $event)"
            >

              <td data-table-col-key="collapse" class="clickable table__control-col" :class = "{'hide' : !getIsSomeExpanded }">
                <span class="table__control-icon">
                  <ph-caret-right :size="16" v-if="!isExpanded(index)" @click="collapse(collapsedItems, index)"/>
                  <ph-caret-down :size="16" v-if="isExpanded(index)" @click="collapse(collapsedItems, index)"/>
                </span>
              </td>

              <td data-table-col-key="checkbox" v-if="showCheckBoxBulkAction" class="table__control-col">
                <template v-if="(!listEnabledCheckBoxBulkAction || listEnabledCheckBoxBulkAction && listEnabledCheckBoxBulkAction[row.id]) && !row.customCheckBoxDisabled">
                  <InputCheckBoxItem class="my-0" v-model="selectedRows[index+selectRowIndexPadding]" @input="onChangeSelected($event, index+selectRowIndexPadding, row)" />
                </template>
              </td>

              <template
                v-for="(col, key, hIndex) in headers">
                <template v-if="col.visible">
                  <td
                    :valign="valign"
                    :data-table-col-key="key"
                    :data-label="headers[key] && headers[key].label"
                    class="tab-rows-cell"
                    :class = "{'hide' : headersHiddenSmallScreen[key]}"
                    :key="key + '-' + hIndex"
                    >
                    <Cell
                      :content="rowContent(row, key, index+selectRowIndexPadding)"
                      :header="headers[key]"
                      :tableData="tableData"
                      :row="row"
                      :actions="actions.length ? actions : row['actions']"
                      :rowIndex="index"
                      :style="columnStyle(key)"
                      @execute-action="clickAction"
                      @selected-cell="selectedRowKey(row, index+selectRowIndexPadding, key, $event)"
                      @input="$emit('input', {event: $event, row, key, index})">
                      <template v-if="headers[key].dataType === 'Slot'" v-slot:[key]>
                        <div :class="[headers[key].align === 'right' ? 'text-right' : 'text-left']" class="tab-rows-cell">
                          <slot :name="key" :data="{tableData, row, index}"></slot>
                        </div>
                      </template>
                      <slot v-if="!actions.length" name="action" :data="{content: row[key], key, row}"></slot>
                    </Cell>
                  </td>
                </template>
              </template>
            </DragnDropArea>
            <tr v-if="isExpanded(index) && getIsSomeExpanded" :key="'collapse' + index">
              <td v-bind:colspan="calculateColspan()">
                <div class="collapsed collapsed--col-sm-2 collapsed--col-md-3 collapsed--col-lg-4">
                <template v-for="(col, key, colIndex) in headers">
                  <template v-if="(col.visible && headersHiddenSmallScreen[key]) && (showIds || (col.key !== 'nodeId' && col.key !=='id'))">
                    <div class="card-row" :key="colIndex">
                      <CardLabel :label="col.label">
                        <Cell
                          :content="row[key]"
                          :header="col"
                          :keepTextLeft="true"
                          :actions="actions.length ? actions : row['actions']">
                          <template v-if="headers[key].dataType === 'Slot'" v-slot:[key]>
                            <slot :name="key" :data="{tableData, row, colIndex}"></slot>
                          </template>
                          <slot v-if="!actions.length" name="action" :data="{content: row[key], key, row}"></slot>
                        </Cell>
                      </CardLabel>
                    </div>
                  </template>
                </template>
                <slot name="additionalCollapsed" :data="{tableData, row, index}"></slot>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-if="getIsSomeSum || getIsSomeAutoSum">
          <tr class="sum">
            <td v-if="getIsSomeExpanded" class="table__control-col"></td>

            <td v-if="showCheckBoxBulkAction" class="table__control-col"></td>

            <template v-for="(item, key, index) in headers">
              <td v-if="item.visible"
                :key="index"
                :data-label="headers[key] && headers[key].label"
                :class = "{ 'hide' : headersHiddenSmallScreen[key] }">
                <span class="no-wrap" v-if="getIsSum(key)">
                  <Cell
                    :content="+getSum(key)"
                    :header="headers[key]"
                    :tableData="tableData"
                    :row="{}">
                  </Cell>
                </span>
                <span class="no-wrap" v-if="getIsAutoSum(key)">
                  <Cell
                    :content="getAutoSum(key)"
                    :header="headers[key]"
                    :tableData="tableData"
                    :row="{}">
                  </Cell>
                </span>                
              </td>
            </template>
          </tr>
        </tfoot>
      </table>
      
      <div class="table-footer" v-if="paginationEnabled">
        <Pagination
          :totalPages="Math.round(pagesNumber)"
          :total="count || rows.length"
          :perPage="pageSize"
          :displayedItens="displayedItens"
          :currentPage="currentPageIndex"
          @pagechanged="onPageChange"
        />
      </div>
    </div>

    <ModalColumnConfig 
      ref="columnsConfig"
      :title="configModalTitle"
      :tableHeaders="tableHeadersList"
      :lockedColumns="lockedColumns"
      @orderChanged="setConfiguredHeaders"
      @onFinishConfig="onFinishColumnsConfig"
      @onRestoreDefault="restoreDefaultColumnConfiguration"/>
      
    <BaseModal 
      ref="modalDownloadPin"
      modalTitle="Download PIN geschützte Datei"
      :showConfirmButton="true"
      labelButtonConfirm="Herunterladen"
      labelButtonCancel="Abbrechen"
      :autoClose="false"
      @onConfirmButton="downloadPinFile"
      @close="() => {
        this.tempFilePin = '';
        this.errorPinFile = '';
        }" > 
        <div>
          <InputField 
            label="Bitte geben Sie die PIN ein"
            placeholder="Pin" 
            v-model="tempFilePin" />
        </div>
        <div class="fc-form-danger">
          {{ errorPinFile }}
        </div>
    </BaseModal>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cell from './Cell';
import Pagination from './Pagination.vue';
import InputField from '@/components/core/forms/InputField.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import CardLabel from './CardLabel.vue'
import { PhCaretRight, PhCaretUp, PhCaretDown, PhSortAscending, PhList, PhTextAlignJustify, PhTable } from 'phosphor-vue'
import ModalColumnConfig from '@/components/table/ModalColumnConfig.vue'
import BaseModal from "@/components/core/BaseModal.vue";
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import CORE_TYPES from '@/store/core/types'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig.js'
import screenSizeMixin from '@/mixins/screen-size/index.js';
import { parse, formatNumber } from '@/helpers/number-formatter.js'
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import SEARCH_CONFIGS_TYPES from '@/store/searchConfigs/types'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import dayjs from "dayjs";
import { ROLES } from '@/router/roles'
import { viewDocument } from '@/components/core/download/DownloadLink.vue'
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuGroup from '@/components/core/base-context-menu/ContextMenuGroup.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';

const CARD_EXPORT_PARTICLE = "Karten";

function getNumber(text) {
  if (text === '' || typeof text !== 'string' && typeof text !== 'number') {
    return 0;
  }
  return parse(text, 2);
  }

function isDate(text) {
  const regex = /\d{2}\.\d{2}\.\d{4}/g;
  if(text && regex.exec(text)) {
    const date = dayjs(text, 'DD.MM.YYYY');
    return date.isValid();
  }
  return false;
}

export default {
  mixins: [screenSizeMixin, InteractiveHelpCommonsMixin],
  props: {
    cardViewEnabled: {
      type: Boolean,
      default: true
    },
    filterEnabled: {
      type: Boolean,
      default: false
    },
    searchFieldKeys: { 
      type: Array,
      default: () => []
    },
    exportEnabled: {
      type: Boolean,
      default: true
    },
    exportConfig: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    tableData: {
      type: Object,
      default: () => ({
        headers: {},
        records: []
      })
    },
    tablePriorities: {
      type: Array,
      default: () => []
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    paginationEnabled: {
      type: Boolean,
      default: true
    },
    additionalCollapsed: {
      type: Boolean,
      default: true
    },
    rowsPerPage: {
      type: Number,
      default: 10
    },
    count: {
      type: Number,
    },
    displayedItens: {
      type: Number,
    },
    hasNext: {
      type: Boolean,
      default: false
    },
    allowColumnsPriorities: {
      type: Boolean,
      default: false
    },
    showCheckBoxBulkAction: {
      type: Boolean,
      default: false
    },
    listEnabledCheckBoxBulkAction: {
      type: Object,
      default: () => null,
    },
    configModalTitle: {
      type: String,
      default: 'Einstellungen'
    },
    tableId: {
      // tableId must be different and unique. This Id is being used to save the column config into backend
      // a UUID randomly generated is enough. https://www.uuidgenerator.net/version4 or any other generator.
      type: String,
    },
    showIds: {
      // Must show columns nodeId and ID in detail row ?
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    dragnDropFilesOnRow: {
      type: Boolean,
      default: false,
    },
    selectedRows: {
      type: Object,
      default: () => ({})
    },
    lockedColumns: {
      type: Object,
      default: () => ({})
    },
    defaultSortKey: {
      type: String,
      default: null,
    },
    defaultSortDirectionAscending: {
      type: Boolean,
      default: false,
    },
    clearSelectedRowsBeforeDestroy: {
      type: Boolean,
      default: true
    },
    valign: {
      type: String,
      default: 'center'
    },
    initialPage: {
      type: Number,
      default: 0,
    },
    selectAllRowsByDefault: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeView: 'list',
      componentId: '',
      searchValue: '',
      searchFieldValues: {},
      sortDirection: this.defaultSortKey ? (this.defaultSortDirectionAscending ? 'asc' : 'desc') : null,
      sortKey: this.defaultSortKey,
      currentPageIndex: this.initialPage,
      collapsedItems: [],
      dataTableExport: undefined,
      customHeader: null,
      window: {
        size: null
      },
      headersHiddenSmallScreen: {},
      headersInitialWidth: {},
      loading: false,
      selectedRowsInternal: {},
      selectedRowsByKey: {},
      tableInitialised: 0,
      tempXlsxId: 0,
      tempPdfId: 0,
      tempFilePin: '',
      errorPinFile: '',
      exportTbl: {
        pdf: {
          show: false,
        },
        xls: {
          show: false,
        }
      },
      showExport: false,
      tablePageSize: 25,
      menuRowsPerPage: [
        {value: 10, label: '10 Zeilen pro Seite'}, 
        {value: 25, label: '25 Zeilen pro Seite'}, 
        {value: 50, label: '50 Zeilen pro Seite'}, 
        {value: 100, label: '100 Zeilen pro Seite'}
      ]
    };
  },
  watch: {
    tableData: {
      handler() {
        this.$nextTick(() => this.updateTableResponsiveness(true));
      },
      deep: true
    },
    savedTableHeaders() {
      this.$nextTick(() => this.updateTableResponsiveness());
    },
    selectedRows: {
      handler(selectedRows) {
        const selectedRowsInternal = {};
        Object.keys(selectedRows).forEach((key) => selectedRowsInternal[key] = selectedRows[key]);
        this.selectedRowsInternal = selectedRowsInternal;
      },
      immediate: true,
    },
    'tableData.records'() {
      if (this.selectedRows && Object.keys(this.selectedRows).length) {
        return
      }
      this.clearSelectedRows();
      this.clearSelectedRowsByKey();
    },
    'exportConfig': 'getExportConfig'
  },
  filters: {
    lowercase(val) {
      return val.toString && val
        .toString()
        .toLowerCase()
        .replace(/ /g, '-');
    },
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString && val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
    },
    percent(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString && val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' %';
    },
    someNumber(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString && val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    ...mapGetters({
      columnsConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_TABLE_COLUMN,
      searchConfigs: SEARCH_CONFIGS_TYPES.GETTERS.TABLE_SORT_OPTIONS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      isConfigurationReadOnly: FC_CONFIG_TYPES.GETTERS.IS_CONFIGURATION_READ_ONLY,
    }),
    pageSize() {
      if (this.tablePageSize > 0 && this.rowsPerPage) {
          return this.tablePageSize;
      }
      return  this.rowsPerPage;
    },
    savedTableHeaders() {
      if (!this.tableId || !this.columnsConfig) {
        return null
      }
     
      const savedConfig = this.columnsConfig[this.tableId]
      if (!savedConfig?.content) {
        return null
      }

      return JSON.parse(savedConfig.content)
    },
    columns() {
      return Object.keys(this.headers).map(key => ({...this.headers[key], key}));
    },
    rows() {
      const visibleColumnsKeys = this.visibleColumns;
      const searchValue = this.searchValue.toLowerCase();
      let result = JSON.parse(JSON.stringify(this.tableData.records));
      if (searchValue && !this.searchFieldKeys?.length) {
        result = result.filter(item => {
          return Object.entries(item)
            .filter(([key, val]) => visibleColumnsKeys.includes(key))
            .some(([key, val]) => {
            return val && val.toString && val.toString().toLowerCase().includes(searchValue);
          })
        })
      }
      const searchFieldKeys = this.searchFieldKeys;
      if (searchFieldKeys.length) {
        result = result.filter(item => {
          return searchFieldKeys.every(key => {
            return item[key] && item[key].toString && item[key].toString().toLowerCase().includes(searchValue);
          })
        })
      }
      if (this.sortDirection && this.sortKey) {

        const header = this.headers[this.sortKey];
        const dataType = header ? header.dataType : Object.values(this.headers).find(header => header.sortKey === this.sortKey)?.dataType;
        result.sort(this.compareValues({key: this.sortKey, order: this.sortDirection, dataType}))
      }
      return result
    },
    headersNeverConfigured() {
      if(this.savedTableHeaders && this.tableData.headers) {
        return Object.keys(this.tableData.headers)
          .filter((key) => !this.savedTableHeaders[key])
          .reduce((result, key) => {
            result[key] = this.tableData.headers[key];
            return result;
          }, {});
      }
      return {};
    },
    headersConfiguredWasRemovedKeys() {
      if(this.savedTableHeaders && this.tableData.headers) {
        return Object.keys(this.savedTableHeaders).filter((key) => !this.tableData.headers[key]) || [];
      }
      return [];
    },
    headersAlwaysVisibleKeys() {
      return Object.keys(this.tableData.headers)
        .filter((key) => this.tableData.headers[key].alwaysVisible === true 
          || this.lockedColumns?.toLeft?.findIndex(c => c.key === key) >= 0 || this.lockedColumns?.toRight?.findIndex(c => c.key === key) >= 0);
    },
    headers() {
      if(this.loading){
        return null;
      }
      const headers = {
        ...(this.customHeader || this.savedTableHeaders || this.tableData && this.tableData.headers || {}),
        ...this.headersNeverConfigured,
      };

      // Keep headers always visible
      this.headersAlwaysVisibleKeys.forEach((key) => {
        headers[key] = headers[key] || this.tableData.headers[key] || {label: key};
        headers[key].visible = true;
        headers[key].alwaysVisible = true;
      });

      // Delete headers configured was removed
      this.headersConfiguredWasRemovedKeys.forEach((key) => delete headers[key]);

      this.assignMultableHeadersProps(headers);

      if (headers != null) {
        for (const [key, value] of Object.entries(headers)) {
          value.sortable = value.sortable !== undefined ? value.sortable : true;
        }
      }

      let resultHeaders = this.sortLockedColumns(headers);

      const lockedColumns = Object.values(this.lockedColumns).flatMap(column => column);

      lockedColumns.forEach(column => {
        if(column.width && resultHeaders[column.key]){
          resultHeaders[column.key].width = column.width;
        }
      })

      return resultHeaders || headers;
    },
    columnsTopPriority() {
      return Object.keys(this.headers)
        .filter(key => this.headers[key].filterable)
        .map(key => ({...this.headers[key], key}));
    },
    currentPageData() {
      const pageData = this.rows?.length > this.pageSize ? this.rows?.slice(this.currentPageIndex * this.pageSize, (this.currentPageIndex + 1) * this.pageSize) : this.rows;

      this.$emit('pageloaded', pageData);

      if (pageData?.length && this.selectAllRowsByDefault && this.tableInitialised < 2) {
        this.selectAllRows();
      }

      return pageData;
    },
    pagesNumber() {
      if (this.count) {
        return Math.max(1, Math.ceil(this.count / this.pageSize))
      }
      return Math.max(1, Math.ceil(this.rows.length / this.pageSize))
    },
    getIsSomeSum() {
      return Object.keys(this.headers).some(key => this.headers[key].sum);
    },
    /**
     * There are some sub totals that are auto-calculated (like percentage fields) and  these values 
     * usually they come from the backend
     */
    getIsSomeAutoSum() {
      return Object.keys(this.headers).some(key => typeof this.headers[key].autoSum === 'number');
    },    
    tableHeadersList() {
      return Object.values(this.headers)
    },
    visibleColumns() {
      const test = Object.entries(this.headers).filter(([header, value]) => {
         return value.visible
      });
      const test2 = test.map(([key,value]) => key)
      return Object.entries(this.headers).filter(([header, value]) => value.visible).map(([key,value]) => key);
    },
    getIsSomeExpanded() {
      const visibleColumns = this.visibleColumns;

      const isThereSomeVisibleByConfigButHiddenByResponsiveness = Object.entries(this.headersHiddenSmallScreen)
        .filter(([key, value]) => visibleColumns.includes(key) && value == true);

      return this.additionalCollapsed && isThereSomeVisibleByConfigButHiddenByResponsiveness.length;
    },
    isAllRowsSelected() {
      return this.rows?.length > 0 && this.rows.every((row, index) => this.selectedRowsInternal[index]);
    },
    hasSomeRowsSelected() {
      return this.rows?.length > 0 && this.rows.some((row, index) => this.selectedRowsInternal[index]);
    },
    searchPlaceHolder() {
      let placeholder = 'Suchen'
      if(this.filterEnabled && this.searchFieldKeys?.length && !this.searchValue) {
        const searchFieldLabels = this.searchFieldKeys.map(key => this.headers[key]?.label).join(', ');
        placeholder += ' nach ' + searchFieldLabels
      }
      return placeholder;
    },
    selectRowIndexPadding() {
      return this.currentPageIndex * this.pageSize;
    },
    showOpenColumnsConfig() {
      return this.title && this.tableId && !this.isConfigurationReadOnly;
    },
  },
  methods: {
    columnStyle(key) {
      if (this.headers && this.headers[key] && this.headers[key].width) {
        return { 
          'width': this.headers[key].width
        }
      }
      return ''
    },
    rowStyle(row) {
      return row?.customRowStyle || ''
    },
    handleResize(size) {
      if(size){
        this.window.size = size;
        this.$nextTick(() => this.updateTableResponsiveness());
      }
    },
    getIsSum(headerKey) {
      return this.headers[headerKey].sum;
    },
    getIsAutoSum(headerKey) {
      return typeof this.headers[headerKey].autoSum === 'number';
    },    
    isInHeaders(headerKey) {
      return !!this.headers[headerKey];
    },
    getSum(key) {
      let result = this.rows.map(r => getNumber(r[key])).reduce((acc, curr) => acc + curr, 0.00);
      if (typeof result === 'number') {
        return result.toFixed(2);
      }
      return '';
    },
    getAutoSum(headerKey) {
      return this.headers[headerKey].autoSum;
    },
    getTypeSign(key) {
      if (this.rows[0] && this.rows[0][key] && typeof this.rows[0][key] === 'string') {
        return isNaN(this.rows[0][key][this.rows[0][key].length - 1]) ? this.rows[0][key][this.rows[0][key].length - 1] : '';
      }
      if(this.headers && this.headers[key] && this.headers[key]['dataSymbol']){
        return this.headers[key]['dataSymbol'];
      }
      return '';
    },
    collapse(collapsedItems, index) {
      if (collapsedItems[index]) {
        collapsedItems[index] = !collapsedItems[index];
      } else {
        collapsedItems[index] = true;
      }
      this.collapsedItems = [...collapsedItems];
    },
    isExpanded(index) {
      return this.collapsedItems[index];
    },
    setActiveView(type) {
      this.activeView = type;
    },
    gotoFirstPage() {
      this.onPageChange(0);
    },
    gotoPrevPage() {
      this.onPageChange(Math.max(0, this.currentPageIndex - 1));
    },
    gotoNextPage() {
      this.onPageChange(Math.min(this.pagesNumber - 1, this.currentPageIndex + 1));
    },
    gotoLastPage() {
      this.onPageChange(this.pagesNumber - 1);
    },
    onPageChange(index) {
      this.currentPageIndex = Math.max(0, Math.min(this.pagesNumber - 1, index));
      this.$emit("pagechanged", { index: this.currentPageIndex });
    },
    compareValues({key, order, dataType = undefined}) {
      return function(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          return 0;
        }
        
        let aVar = a[key];
        let bVar = b[key];
        if(isDate(aVar) && isDate(bVar)) {
            aVar = dayjs(aVar, 'DD.MM.YYYY HH:mm:ss');
            bVar = dayjs(bVar, 'DD.MM.YYYY HH:mm:ss');
            let dateComparison = 0;
            dateComparison = aVar.isBefore(bVar) ? -1 : 1;
            return order === 'desc' ? dateComparison * -1 : dateComparison;
        } else if(typeof aVar === 'boolean' && typeof bVar === 'boolean') {
          return order === 'desc' ? aVar - bVar : bVar - aVar;
        } else if(dataType && (dataType === 'Currency' || dataType === 'Procent' || dataType === 'Number')) {
          aVar = getNumber(aVar);
          bVar = getNumber(bVar);
          return order === 'desc' ? aVar - bVar : bVar - aVar;
        } else {
          aVar = String(aVar);
          bVar = String(bVar);
        }

        var collator = new Intl.Collator([], {numeric: true});
        return order === 'desc' ? collator.compare(bVar, aVar) : collator.compare(aVar, bVar);

      };
    },
    toggleSort(key, sortable) {
      if (sortable) {
        switch (this.sortDirection) {
          case 'asc':
            this.sortDirection = 'desc'; break;
          case 'desc':
            this.sortDirection = null; break;
          default:
            this.sortDirection = 'asc'; break;
        }
        this.sortKey = key
        const onSortPayload = {sortDirection: this.sortDirection, key}
        this.$emit('onSort', onSortPayload)
        this.saveCachedSortOptions({ tableId: this.tableId, onSortPayload})
        this.$nextTick(() => this.updateTableResponsiveness());
        this.clearSelectedRows();
        this.clearSelectedRowsByKey();
        this.gotoFirstPage();
      }
    },
    showArrowUp(key) {
      return this.sortKey === key && this.sortDirection === 'asc'
    },
    showArrowDown(key) {
      return this.sortKey === key && this.sortDirection === 'desc'
    },
    exportColumn(dtype, value) {
      let precision = 2;
      if (/Decimal\d/.test(dtype)) {
        precision = parseInt(dtype.substr(7,1), 10);
        dtype = 'Decimal';
      }
      const nrType = {NumberInt:1,Integer:1,Currency:1,CurrencyNoLabel:1,Number:1,Double:1,Procent:1,Decimal:1}
      if (value !== undefined) {
        if (typeof value === 'string' && nrType[dtype] && isNaN(value)) {
          dtype = 'String';
        } else if (value === null) {
          dtype = 'String';
          value = '';
        }
        if (typeof value === 'boolean' && dtype !== 'Boolean') {
          value = value ? 'Ja' : 'Nein';
        }
        if ( value instanceof Date) {
          value =  dayjs(value, 'DD.MM.YYYY');
        } else if (typeof value === 'object') {
          if (value.label !== undefined && value.value !== undefined) {
            value = value.label
          } else if (value.value !== undefined) {
            value = value.value
          } else {
            value = '';
          }
        }
      }
      switch (dtype) {
        case 'Slot':
        case 'String':
          return value === undefined ? true : value;
        case 'Boolean':
          return value === undefined ? true : (value ? '1' : '0');
        case 'NumberInt':
        case 'Integer':
          return value === undefined ? true : formatNumber(value, 0);
        case 'Currency':
        case 'CurrencyNoLabel':
        case 'Number':
        case 'Double':
        case 'Procent':
        case 'Decimal':
          // if (value) console.log(value, formatNumber(value, precision));
          return value === undefined ? true : formatNumber(value, precision);
        case 'DateYMD':
          return value === undefined ? true : (value.length !== 8 ? value : value.substr(6,2) +'.'+ value.substr(4,2) +'.'+ value.substr(0,4));
      
        default:
          return value === undefined ? false : '';
      }
    },
    async exportTableToExcel() {
      let keys = null;
      if (this.exportTbl.xls?.keys) {
        if (typeof this.exportTbl.xls.keys === 'string') {
          keys = this.exportTbl.xls.keys.split(',').filter(key => !!this.headers[key]);
        } else if (Array.isArray(this.exportTbl.xls.keys)) {
          keys = this.exportTbl.pdf.keys.filter(key => !!this.headers[key]);
        } else if (typeof this.exportTbl.xls.keys === 'function') {
          keys = this.exportTbl.xls.keys();
        }
      }
      if (keys === null) {
        keys = this.columns.filter(c => this.exportColumn(c.dataType) && c.visible).map(c => c.key);
      }
      const name = this.exportTbl.xls.name || 'Dokument';
      let title = this.exportTbl.xls.title || '';
      if (title.indexOf('FULLNAME') !== -1) {
        title = title.replace(/FULLNAME/g, this.fullname);
      }
      // console.log('exportTbl', this.exportTbl.xls)
      const response = await this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, {
        create: 'xls',
        filename: name + '.xls',
        title: title,
        columns: keys.map(key => this.headers[key].label),
        rows: this.exportData(keys),
      });
      if (response.data.bytes) {        
        viewDocument({
          data: response.data.bytes,
          filename: name,
          contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileExtension: 'xlsx',
        }, true);
      } else if (response.data.tempFileId) {
        this.tempXlsxId = response.data.tempFileId;
        this.$refs.modalDownloadPin.open();
      }
    },
    exportData(keys) {
      const rows = this.rows.map(row => keys.map(key => '' + this.exportColumn(this.headers[key].dataType, row[key] || '')));
      if (this.getIsSomeSum || this.getIsSomeAutoSum) {
        const sumRow = [];
        keys.forEach( key => {
          if (this.getIsSum(key)) {
            sumRow.push('' + this.exportColumn(this.headers[key].dataType, this.getSum(key) || ''))
          } else if (this.getIsAutoSum(key)) {
            sumRow.push('' + this.exportColumn(this.headers[key].dataType, this.getAutoSum(key) || ''))
          } else {
            sumRow.push('');
          }
        })
        rows.push(sumRow);
      }
      return rows;
    },
    async exportPDF() {
      let keys = null;
      if (this.exportTbl.pdf?.keys) {
        if (typeof this.exportTbl.pdf.keys === 'string') {
          keys = this.exportTbl.pdf.keys.split(',').filter(key => !!this.headers[key]);
        } else if (Array.isArray(this.exportTbl.pdf.keys)) {
          keys = this.exportTbl.pdf.keys.filter(key => !!this.headers[key]);
        } else if (typeof this.exportTbl.pdf.keys === 'function') {
          keys = this.exportTbl.pdf.keys();
        }
      }
      if (keys === null) {
        keys = this.columns.filter(c => this.exportColumn(c.dataType) && c.visible).map(c => c.key);
      }
      const name = this.exportTbl.pdf.name || 'Dokument';
      let title = this.exportTbl.pdf.title || '';
      if (title.indexOf('FULLNAME') !== -1) {
        title = title.replace(/FULLNAME/g, this.fullname);
      }
      const response = await this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, {
        create: 'pdf',
        filename: name + '.pdf',
        title: title,
        columns: keys.map(key => this.headers[key].label),
        rows: this.exportData(keys),
      });
      if (response.data.bytes) {        
        viewDocument({
          data: response.data.bytes,
          filename: name,
          contentType: 'application/pdf',
        }, true);
      } else if (response.data.tempFileId) {
        this.tempPdfId = response.data.tempFileId;
        this.$refs.modalDownloadPin.open();
      }
    },
    downloadPinFile() {
      const tempFileId = this.tempXlsxId ? this.tempXlsxId : this.tempPdfId;
      const type = this.tempXlsxId ? 'xlsx' : 'pdf';
      const content = this.tempXlsxId ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf';
      if ( /\d\d\d/.test(this.tempFilePin) ) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.GET_PIN_DATA, {
          id: tempFileId,
          pin: parseInt(this.tempFilePin),
          type: type,
          contenttype: content,
        });
        this.$refs.modalDownloadPin.close();
      } else {
        this.errorPinFile = 'Bitte eine 3-steligen Nummer als Pin eingeben';
      }
    },
    exportPDF_cards: function() {
      // let title = this.configModalTitle + CARD_EXPORT_PARTICLE;
      
      // const dataTableExport = new DataTableExport(
      //   this.columns,
      //   this.rows,
      //   this.tableData,
      //   {name: title, pdf: {title: title}}
      // );
      // dataTableExport.createPDF_cards('save');
    },
    openColumnsConfig() {
      this.$refs.columnsConfig.open()
    },
    setConfiguredHeaders(cols) {
      this.customHeader = cols
        .map(c => c.key)
        .reduce((acc, curr, index) => { 
          acc[curr] = cols[index]; return acc 
        }, {});

        this.$nextTick(() => this.updateTableResponsiveness());
    },
    onFinishColumnsConfig(userLevelSaveConfig) {
      if (this.tableId && this.customHeader) {
        const savePayload = {
          configId: this.tableId,
          configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
          content: JSON.stringify(this.customHeader),
          userLevel: userLevelSaveConfig || null
        }

        this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, savePayload)
      }
    },
    async restoreDefaultColumnConfiguration(userLevelSaveConfig) {
      this.customHeader = null;
      
      if (this.tableId) {
        const savePayload = {
          configId: this.tableId,
          configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
          userLevel: userLevelSaveConfig || null,
        }

        await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_FC_CONFIG, savePayload);
        this.$nextTick(() => this.updateTableResponsiveness());
      }
    },
    isLockedColumnNeverHiden(columnKey) {
      return !!(Object.values(this.lockedColumns)
        .flatMap(side => side)
        .filter(column => column.key === columnKey && column.neverHide)
        .length)
    },
    updateTableResponsiveness(forceColumnSizeUpdate = false) {
      if(this.headers && this.$refs.table && this.$refs.tableContainer) {
        const containerSize = this.$refs.tableContainer.clientWidth -10;
        const tableElement = this.$refs.table;
        tableElement.classList.remove('table-full');
        tableElement.classList.remove('table-fixed');
        const rowColumnElements = [...this.$refs.table.querySelectorAll('tbody tr:first-child > td')];
        let totalSize = 0;

        const rowColumns = rowColumnElements.reduce((acc, curr) => ({...acc, [curr?.dataset?.tableColKey] : curr}), {});
        if(rowColumns['collapse']) {
          totalSize += this.headersInitialWidth['collapse'] || rowColumns['collapse'].offsetWidth;
        }
        if(rowColumns['checkbox']) {
          totalSize += this.headersInitialWidth['checkbox'] || rowColumns['checkbox'].offsetWidth;
        }
        if(rowColumns['actions']) {
          totalSize += this.headersInitialWidth['actions'] || rowColumns['actions'].offsetWidth;
        }

        const lockedColumns = Object.values(this.lockedColumns).flatMap(column => column).map(col => col.key);
        if(lockedColumns?.length) {
          lockedColumns.forEach(columnKey => {
            if (rowColumns[columnKey])
              totalSize += this.headersInitialWidth[columnKey] || rowColumns[columnKey].offsetWidth;
          })
        }

        for (let [colKey, headerElement] of Object.entries(rowColumns)) {

          if(!this.headersInitialWidth[colKey] || forceColumnSizeUpdate) {
            this.headersInitialWidth[colKey] = parseInt(headerElement.offsetWidth);
          }

          if(['collapse', 'checkbox'].includes(colKey) || lockedColumns.includes(colKey) || colKey === 'actions') {
            this.$set(this.headersHiddenSmallScreen, colKey, false);
            continue;
          }

          totalSize += this.headersInitialWidth[colKey];

          const isVisible = this.headers[colKey].visible;
          // unspected columns saved on database whitout width could be received by backend and concated in the end of table head
          const withoutSize = this.headersInitialWidth[colKey] === 0;
          this.$set(this.headersHiddenSmallScreen, colKey, !isVisible || withoutSize || totalSize > containerSize);

        }

        let position = 0;
        if(this.getIsSomeExpanded){
          position++;
        }
        if(this.showCheckBoxBulkAction) {
          position++;
        }

        let totalLockedColumns = 0;
        if(lockedColumns?.length) {
          totalLockedColumns = lockedColumns?.length + position;
          if(rowColumns['actions']) {
            totalLockedColumns++;
          }
        }

        let headersVisible = Object.values(this.headersHiddenSmallScreen).filter(col => !col);
        let makeTextTableEllipsis = false;

        if(headersVisible.length === totalLockedColumns) {
          let remainingColumnsSize = this.headersInitialWidth['collapse'] || 0 + this.headersInitialWidth['checkbox'] || 0 + this.headersInitialWidth['actions'] || 0;

          lockedColumns.forEach(columnKey => {
            remainingColumnsSize += this.headersInitialWidth[columnKey];
            const noSpaceLeft = remainingColumnsSize > containerSize;
            const neverHide = this.isLockedColumnNeverHiden(columnKey);
            makeTextTableEllipsis = makeTextTableEllipsis || (noSpaceLeft && neverHide);
            
            this.$set(this.headersHiddenSmallScreen, columnKey, noSpaceLeft && !neverHide);
          })

          // update the reference for visible columns
          headersVisible = Object.values(this.headersHiddenSmallScreen).filter(col => !col);
        }

        const minimumColumnCount = this.showCheckBoxBulkAction ? 4 : 3;
        // if the calculation or the total columns result is less than 3 columns, show the first one to avoid empty columns

        if(makeTextTableEllipsis || (rowColumnElements.length > 0 && (headersVisible.length < minimumColumnCount || Object.keys(rowColumns).length <= minimumColumnCount || !this.currentPageData.length))) {
          this.$set(this.headersHiddenSmallScreen, Object.keys(rowColumns)[position] , false);

          tableElement.classList.add('table-fixed');
        }

        /* this is important to allow the variable headersInitialWidth being initalizated with content size and only after that
         * expand the table to fit in whole table container 
         */
        if(tableElement && !tableElement.classList.contains('table-full')){
          tableElement.classList.add('table-full');
        }

      }
    },
    calculateColspan() {
      let remainingColls = Object.values(this.headersHiddenSmallScreen).filter(col => !col).length;
      if(this.getIsSomeExpanded){
        remainingColls++;
      }
      if(this.showCheckBoxBulkAction) {
        remainingColls++;
      }
      return remainingColls;
    },
    clickAction(event){
      this.$emit("execute-action", event);
    },
    onChangeSelected(value, index, row) {
      const checked = value ? row : false;
      this.selectedRowsInternal = {
        ...this.selectedRowsInternal,
        [index]: checked,
      };
      this.selectedRows[index] = checked;
      this.selectedRowsEmit(this.selectedRows);
      if(value) {
        this.$emit('selectedRow', { ...row });
      } else {
        this.$emit('unselectedRow', { ...row });
      }
    },
    handleRowFilesDropped(row, files) {
      this.$emit('row-files', { 
        row,
        files,
      });
    },
    assignMultableHeadersProps(headers) {
      if(this.savedTableHeaders && this.tableData.headers) {
        Object.keys(headers)
          .forEach((key) => {
            const configuredHeader = headers[key];
            const header = this.tableData.headers[key];
            configuredHeader.label = header.label;
            configuredHeader.sortable = header.sortable;
            configuredHeader.filterable = header.filterable;
            configuredHeader.dataType = header.dataType;
          });
      }
    },
    rowContent(row, key, index) {
      const selectedRow = this.selectedRowsByKey[index];
      if(selectedRow && key in selectedRow) {
        return !!selectedRow[key];
      }

      return row[key];
    },
    toggleSelectAllRows(value) {
      const rows = this.rows;
      const selectedRowsInternal = {};
      rows.forEach((row, index) => {
        const checked = value ? row : false;
        selectedRowsInternal[index] = checked;
        this.selectedRows[index] = checked;
      });
      this.selectedRowsInternal = selectedRowsInternal;
      this.selectedRowsEmit(this.selectedRows);
      if(value) {
        this.$emit('selectedAllRows', [...rows]);
      } else {
        this.$emit('unselectedAllRows', [...rows]);
      }
    },
    selectedRowsEmit(selectedRows) {
      this.$emit('selectedRows', selectedRows);
    },
    clearSelectedRows() {
      this.selectedRowsInternal = {};
      Object.keys(this.selectedRows).forEach(key => {
        delete this.selectedRows[key];
      });
      this.selectedRowsEmit(this.selectedRows);
      this.$emit('clearSelectedRows');
    },
    selectedRowByKeyObject(row, key, value) {
      return {
        [key]: value ? row : false,
      };
    },
    toggleSelectAllRowsByKey(key, value) {
      const rows = this.rows;
      this.selectedRowsByKey = rows.reduce((result, row, index) => {
        result[index] = this.selectedRowByKeyObject(row, key, value);
        return result;
      }, {});
      if(value) {
        this.$emit('selectedAllRowsByKey', {
          rows: [...rows],
          key,
        });
      } else {
        this.$emit('unselectedAllRowsByKey', {
          rows: [...rows],
          key,
        });
      }
    },
    selectedRowKey(row, index, key, value) {
      this.selectedRowsByKey = {
        ...this.selectedRowsByKey,
        [index]: this.selectedRowByKeyObject(row, key, value),
      };

      const obj = {
        row,
        key,
      };

      if(value) {
        this.$emit('selectedRowByKey', obj);
      } else {
        this.$emit('unselectedRowByKey', obj);
      }
    },
    isAllRowsSelectedByKey(key) {
      return this.rows?.length > 0 && this.rows.every((row, index) => {
        const selectedRow = this.selectedRowsByKey[index];
        return selectedRow ? !!selectedRow[key] : row[key];
      });
    },
    hasSomeRowsSelectedByKey(key) {
      return this.rows?.length > 0 && this.rows.some((row, index) => {
        const selectedRow = this.selectedRowsByKey[index];
        return selectedRow ? !!selectedRow[key] : row[key];
      });
    },
    clearSelectedRowsByKey() {
      this.selectedRowsByKey = {};
      this.$emit('clearSelectedRowsByKey');
    },
    sortLockedColumns(headers){
      let resultHeaders = null;
      let headerEntries = Object.entries(headers);
      const actionColumnIndex = headerEntries.findIndex(item => item[0] === 'actions');
      const actionsColumn = actionColumnIndex >= 0 ? headerEntries.splice(actionColumnIndex,1)[0] : null;
      let lockedLeft = [];
      let lockedRight = [];
      if(Object.values(this.lockedColumns).length) {
        if(this.lockedColumns?.toLeft?.length){
          this.lockedColumns.toLeft.forEach(column => {
            const foundAtindex = headerEntries.findIndex(item => item[0] === column.key);
            if(foundAtindex < 0) return;
            lockedLeft.push(headerEntries.splice(foundAtindex,1)[0]);
          })
        }
        const toRightLength = this.lockedColumns?.toRight?.length || 0;
        if(toRightLength){
          lockedRight = new Array(toRightLength);
          let lockedRightIndex = lockedRight.length -1;
          this.lockedColumns.toRight.forEach(column => {
            const foundAtindex = headerEntries.findIndex(item => item[0] === column.key);
            if(foundAtindex < 0) return;
            lockedRight.splice(lockedRightIndex, 1, headerEntries.splice(foundAtindex,1)[0]);
            lockedRightIndex--;
          })
        }

        const lockedLeftResult = lockedLeft.reduce((acc, curr) => ({...acc, [curr[0]] : curr[1] }), {});
        const lockedRightResult = lockedRight.reduce((acc, curr) => ({...acc, [curr[0]] : curr[1] }), {});
        const headerEntriesRemaining = headerEntries.reduce((acc, curr) => ({...acc, [curr[0]] : curr[1] }), {});

        resultHeaders = {...lockedLeftResult, ...headerEntriesRemaining, ...lockedRightResult};
        if(actionsColumn) {
          resultHeaders = {...resultHeaders, [actionsColumn[0]] : actionsColumn[1]};
        }
      }
      return resultHeaders;
    },
    saveCachedSortOptions(sortParams) {
      this.$store.dispatch(SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_TABLE_SORT_OPTIONS, sortParams)
    },
    loadCachedSortOptions() {
      if (!this.$route.query?.backAction) {
        return
      }
      if (this.tableId) {
        const sortOptions = this.searchConfigs?.(this.tableId)
        this.sortKey = sortOptions?.key
        this.sortDirection = sortOptions?.sortDirection
      }
    },
    selectAllRows() {
      this.toggleSelectAllRows(true);
      this.tableInitialised += 1;
    },
    getExportConfig() {
      if (this.exportConfig) {
        this.showExport = ( this.exportEnabled);
        
        if (this.exportConfig?.roottext) {
          this.exportTbl.pdf = {
            show: true,
            name: this.exportConfig.roottext + ' ' + dayjs(new Date()).format('DD-MMM-YYYY HH mm'),
            title: this.exportConfig.roottext + ' - FULLNAME',
          }
          this.exportTbl.xls = {
            show: true,
            name: this.exportConfig.roottext + ' ' + dayjs(new Date()).format('DD-MMM-YYYY HH mm'),
            title: this.exportConfig.roottext + ' - FULLNAME',
          }
        }
        if (this.exportConfig?.pdf) {
          this.exportTbl.pdf = Object.assign((this.exportTbl.pdf || {}), this.exportConfig.pdf );
        }
        if (this.exportConfig?.xls) {
          this.exportTbl.xls = Object.assign((this.exportTbl.xls || {}), this.exportConfig.xls );
        }
      }
    },
    forceUnselectRows() {
      this.clearSelectedRows();
    },
  },
  async created() {
    if (this.tableId) {
      const configPayload = {
        configId: this.tableId,
        configType: FC_CONFIG.TABLE_COLUMN_CONFIG
      }

      this.loading = true;
      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, configPayload)
      this.loading = false;
    }

    this.$nextTick(() => {
      this.screenSizeData.throttleLimit = 200;
      this.handleResize(this.screenSizeData.initialSize);
      this.$addResizeListener((event, currentSize) => this.handleResize(currentSize));
    });
  },
  mounted() {
    this.componentId = this.title;
    this.loadCachedSortOptions();
    if (this.exportEnabled) {
      this.exportTbl = {
        pdf: {
          show: true,
          name: 'Dokument',
          keys: '',
        },
        xls: {
          show: true,
          name: 'Dokument',
          title: '', // first row
          keys: '',
        }
      };
      this.getExportConfig();
    }

  },
  beforeDestroy() {
    if (this.clearSelectedRowsBeforeDestroy) {
      this.clearSelectedRows();
      this.clearSelectedRowsByKey();
    }
  },

  components: {
    Pagination,
    Cell,
    InputField,
    BaseButton,
    CardLabel,
    PhCaretRight, 
    PhCaretUp, 
    PhCaretDown,
    ModalColumnConfig,
    InputCheckBoxItem,
    DragnDropArea,
    BaseModal,
    PhSortAscending,
    PhList,
    PhTextAlignJustify,
    PhTable,
    BaseContextMenu,
    ContextMenuGroup,
    ContextMenuItem,
  }
};
</script>

<style src='./table.scss' lang='scss' scoped>
</style>
<style scoped>
.menu-disabled {
    opacity: 0.5;
}
.table--header {
  display: flex;
  justify-content: space-between;
}
</style>
