var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "data-name": "Layer 1",
        width: "952",
        height: "586",
        viewBox: "0 0 952 586"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "a163713d-a7fc-4807-a77b-111ad75719e9-608",
          "data-name": "Path 133",
          d:
            "M170.63353,697.233a158.3937,158.3937,0,0,0,7.4,43.785c.1.329.211.653.319.982h27.613c-.029-.295-.059-.624-.088-.982-1.841-21.166,8.677-148.453,21.369-170.483C226.13454,572.322,168.49254,629.979,170.63353,697.233Z",
          transform: "translate(-124 -157)",
          fill: "#f1f1f1"
        }
      }),
      _c("path", {
        attrs: {
          id: "a1d5a274-7181-45f3-aae5-db776f20014a-609",
          "data-name": "Path 134",
          d:
            "M172.70558,741.018c.231.329.471.658.717.982h20.716c-.157-.28-.339-.609-.55-.982-3.422-6.176-13.551-24.642-22.953-43.785-10.1-20.572-19.374-41.924-18.593-49.652C151.80058,649.323,144.80861,702.457,172.70558,741.018Z",
          transform: "translate(-124 -157)",
          fill: "#f1f1f1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M775.29126,277.577a14.42246,14.42246,0,0,0,21.04127,6.80778l39.97977,32.06957,2.47488-26.51836-38.34489-26.38825a14.50066,14.50066,0,0,0-25.151,14.02926Z",
          transform: "translate(-124 -157)",
          fill: "#9f616a"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "713.205 130.446 698.283 119.903 681.358 139.458 701.046 151.126 713.205 130.446",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M833.93681,280.29582l50.28691,19.349L932.63291,267.947a24.62075,24.62075,0,0,1,31.79547,37.06016l-.433.48083-74.454,39.9183-81.32649-37.15291Z",
          transform: "translate(-124 -157)",
          fill: "#3f3d56"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "695.76 583 171.76 583 190.76 212 272.76 212 348.374 265 714.76 265 695.76 583",
          fill: "#e5e5e5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M691.4891,626.86286,388.6242,502.13469a4.32609,4.32609,0,0,1-2.35009-5.64107L523.508,163.26232a4.3261,4.3261,0,0,1,5.64107-2.35009L832.014,285.6404a4.32609,4.32609,0,0,1,2.35009,5.64107L697.13017,624.51277A4.32609,4.32609,0,0,1,691.4891,626.86286Z",
          transform: "translate(-124 -157)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M691.4891,626.86286,388.6242,502.13469a4.32609,4.32609,0,0,1-2.35009-5.64107L523.508,163.26232a4.3261,4.3261,0,0,1,5.64107-2.35009L832.014,285.6404a4.32609,4.32609,0,0,1,2.35009,5.64107L697.13017,624.51277A4.32609,4.32609,0,0,1,691.4891,626.86286ZM528.49088,162.51046a2.59553,2.59553,0,0,0-3.38465,1.41006L387.87234,497.15182a2.59552,2.59552,0,0,0,1.41005,3.38464l302.8649,124.72817a2.59553,2.59553,0,0,0,3.38465-1.41L832.76583,290.62327a2.59552,2.59552,0,0,0-1.41005-3.38464Z",
          transform: "translate(-124 -157)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M614.03119,325.86654,538.9143,294.93132a4.32609,4.32609,0,0,1-2.35009-5.64107l30.93522-75.11689a4.3261,4.3261,0,0,1,5.64107-2.35009l75.11689,30.93522a4.3261,4.3261,0,0,1,2.35009,5.64107l-30.93522,75.11689A4.3261,4.3261,0,0,1,614.03119,325.86654ZM572.4823,213.4215a2.59553,2.59553,0,0,0-3.38464,1.41006l-30.93522,75.11689a2.59553,2.59553,0,0,0,1.41006,3.38464l75.11688,30.93522a2.59553,2.59553,0,0,0,3.38465-1.41006l30.93521-75.11689a2.59551,2.59551,0,0,0-1.41-3.38464Z",
          transform: "translate(-124 -157)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M588.67043,326.17072,513.55354,295.2355a3.89342,3.89342,0,0,1-2.11508-5.077l30.93522-75.11689a3.89343,3.89343,0,0,1,5.077-2.11508l75.11688,30.93522a3.89341,3.89341,0,0,1,2.11508,5.077l-30.93522,75.11689A3.89341,3.89341,0,0,1,588.67043,326.17072Z",
          transform: "translate(-124 -157)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M733.35509,428.74966,498.415,331.99483a3.889,3.889,0,1,1,2.96189-7.192L736.317,421.55762a3.889,3.889,0,0,1-2.96188,7.192Z",
          transform: "translate(-124 -157)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M666.41582,430.15629,488.213,356.76742a3.889,3.889,0,1,1,2.96188-7.192l178.20284,73.38887a3.889,3.889,0,1,1-2.96189,7.192Z",
          transform: "translate(-124 -157)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M770.07315,337.32136,666.18809,294.53861a3.889,3.889,0,1,1,2.96189-7.192l103.885,42.78275a3.889,3.889,0,0,1-2.96188,7.192Z",
          transform: "translate(-124 -157)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M733.94491,351.41683,655.98605,319.3112a3.889,3.889,0,1,1,2.96189-7.192l77.95886,32.10562a3.889,3.889,0,0,1-2.96189,7.192Z",
          transform: "translate(-124 -157)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M712.951,478.29485,478.011,381.54a3.889,3.889,0,1,1,2.96188-7.192L715.91289,471.1028a3.889,3.889,0,0,1-2.96188,7.192Z",
          transform: "translate(-124 -157)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M646.01174,479.70147,467.80891,406.3126a3.889,3.889,0,1,1,2.96188-7.192l178.20284,73.38887a3.889,3.889,0,1,1-2.96189,7.192Z",
          transform: "translate(-124 -157)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M692.54693,527.84,457.60687,431.0852a3.889,3.889,0,0,1,2.96188-7.192L695.50881,520.648a3.889,3.889,0,0,1-2.96188,7.192Z",
          transform: "translate(-124 -157)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M682.34489,552.61262,447.40483,455.85779a3.889,3.889,0,0,1,2.96188-7.192l234.94006,96.75484a3.889,3.889,0,0,1-2.96188,7.192Z",
          transform: "translate(-124 -157)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M567.73018,550.77248a84.70308,84.70308,0,0,0,14.09436-.43c4.21238-.49243,8.60066-1.17049,12.29309-3.38536a11.6831,11.6831,0,0,0,5.8212-8.83535,8.2218,8.2218,0,0,0-4.975-8.33661,9.80892,9.80892,0,0,0-9.95124,1.3943,12.959,12.959,0,0,0-4.44981,10.35166c.194,8.00444,6.52716,15.90938,13.55947,19.23894,7.92082,3.75025,18.73754.56318,20.76617-8.67189.42194-1.92082-2.19428-2.58835-3.288-1.35411a8.72086,8.72086,0,0,0,12.17216,12.44835l-2.94431-1.21255a20.9902,20.9902,0,0,0,14.43752,15.24852,19.46991,19.46991,0,0,0,5.37174.81856c2.2119.00621,4.48428,1.11646,6.59962,1.80561l15.24432,4.96639c2.24232.73052,3.665-2.67332,1.40427-3.40985l-13.80618-4.49787c-2.30073-.74955-4.59586-1.51967-6.90308-2.24893-1.64936-.52133-3.45545-.23905-5.176-.54579a17.28811,17.28811,0,0,1-13.5191-12.64511,1.86584,1.86584,0,0,0-2.9443-1.21254,5.04821,5.04821,0,0,1-7.02188-7.25421l-3.288-1.35411c-1.30866,5.95754-8.576,8.19323-13.81358,6.47055-5.85748-1.92656-10.892-7.53271-12.639-13.4049a10.8108,10.8108,0,0,1,.7867-8.60233,6.443,6.443,0,0,1,6.75034-3.30637,4.62264,4.62264,0,0,1,3.95027,5.07015,8.26891,8.26891,0,0,1-4.60472,6.264c-3.29662,1.7695-7.24432,2.21012-10.90575,2.61181a78.79791,78.79791,0,0,1-12.57356.3577c-2.35393-.11867-2.82641,3.54144-.44763,3.66136Z",
          transform: "translate(-124 -157)",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "695.76 290 695.76 583 171.76 583 171.76 237 253.76 237 329.37 290 695.76 290",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "203.75984",
          y: "528",
          width: "214",
          height: "17",
          fill: _vm.color
        }
      }),
      _c("rect", {
        attrs: {
          x: "203.75984",
          y: "495",
          width: "107",
          height: "17",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M820.75984,741h-526V393h83.31555l75.61414,53H820.75984Zm-524-2h522V448H453.05843l-75.61414-53H296.75984Z",
          transform: "translate(-124 -157)",
          fill: "#3f3d56"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "909.144 548.636 891.414 554.972 858.535 489.598 884.704 480.246 909.144 548.636",
          fill: "#9f616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M986.63848,741.638l-.25872-.72291a23.659,23.659,0,0,1,14.30045-30.20571l34.91779-12.47929,8.21153,22.97656Z",
          transform: "translate(-124 -157)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "793.848 566.172 775.019 566.172 766.062 493.546 793.851 493.547 793.848 566.172",
          fill: "#9f616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M922.64961,741.42424l-60.71214-.00225v-.76791A23.63085,23.63085,0,0,1,885.5687,717.0236h.0015l37.08053.0015Z",
          transform: "translate(-124 -157)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M960.75907,259.18645l-8.18861-16.18053s-30.50532,7.64282-33.27853,25.32289Z",
          transform: "translate(-124 -157)",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "870.547 287.198 848.278 411.467 907.407 516.036 872.083 529.858 812.186 428.494 804.507 400.849 799.899 545.216 768.042 544.249 753.435 409.445 776.862 284.127 870.547 287.198",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M989.9385,388.055l-.77018-72.87145c0-30.88384-20.32611-53.64707-23.14362-55.75276-1.32569-4.231-3.70431-7.15557-7.0678-8.68606-6.77553-3.07086-15.06191.61412-15.41273.77989L918.685,260.84434l-.11662.34118c-.26371.77988-25.3466,83.08812-31.64469,103.74554-.8968,2.96364-1.41327,4.6599-1.41327,4.6599s.09758,4.77688.27275,12.20538c.556,25.54152,1.87167,82.43492,2.39814,84.82336,0,0,45.91642,6.96052,64.09763,6.96052,32.70714,0,53.48166-6.25865,53.94958-6.36587l.75066-.156Z",
          transform: "translate(-124 -157)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "927.1367",
          cy: "204.30312",
          r: "33.60816",
          transform: "translate(-108.38042 312.74381) rotate(-28.66301)",
          fill: "#9f616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M930.86135,372.63255l-45.07817,9.16379c-.17517-7.4285-.27275-12.20538-.27275-12.20538s.51647-1.69626,1.41327-4.6599Z",
          transform: "translate(-124 -157)",
          opacity: "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M797.36935,363.02694a14.4225,14.4225,0,0,0,21.81522-3.63l50.24885,10.09416-9.99619-24.68652-46.18464-5.80088a14.50066,14.50066,0,0,0-15.88324,24.02326Z",
          transform: "translate(-124 -157)",
          fill: "#9f616a"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "732.887 186.321 714.788 183.82 708.751 208.968 731.599 210.276 732.887 186.321",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M850.69724,338.47437l53.55238-5.94132,28.41215-50.40785a24.62075,24.62075,0,0,1,45.27595,18.28923l-.16338.62608-47.76016,69.68386-89.30252,4.40419Z",
          transform: "translate(-124 -157)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M955.76343,233.48271l-27.895,1.00127c-1.70035.061-6.31513-18.28939-6.91921-22.09617a10.3896,10.3896,0,0,0-10.838-8.40483c-2.08811.19724-7.35359-3.70388-12.78705-8.32323-10.31547-8.76987-9.77883-25.241,1.55269-32.65157q.46458-.30381.91188-.55481c7.14855-4.00112,15.511-5.34475,23.70251-5.44721,7.42579-.09288,15.06209.84177,21.60406,4.35653,11.72836,6.30122,17.97016,20.07116,18.51843,33.37376s-3.71631,26.31011-8.55563,38.71336",
          transform: "translate(-124 -157)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d: "M1075,743H125a1,1,0,0,1,0-2h950a1,1,0,0,1,0,2Z",
          transform: "translate(-124 -157)",
          fill: "#cbcbcb"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }