<template>
  <div>
    <BaseModal 
      ref="editModal"
      modalTitle="Meta-Tags"
      :showConfirmButton="false"
      @onCloseButton="close()"
      @close="close()">

      <Table v-if="!loading && rows.length"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        @action-DELETE="deleteMetaTag"/>
      <GhostLoading v-else-if="loading" type="table" />
      <NoData v-else noIcon />

      <hr/>

      <div style="font-size: 0.8rem;">
        {{info}}
      </div>

      <template #footer>
        <BaseButton @click="$refs.modalAdd.open()">Meta-Tag hinzufügen</BaseButton>
      </template>
    </BaseModal>
    <BaseModal
      ref="modalAdd"
      modalTitle="Meta-Tags hinzufügen"
      @onConfirmButton="saveMetaTag()"
    >

      <InputField label="Name" v-model="metaTagForm.name"/>
      <InputTextArea label="Content" v-model="metaTagForm.content"/>
      
      <div style="font-size: 0.8rem;">
        {{info}}
      </div>

    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import BaseModal from '@/components/core/BaseModal.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, ConfirmedAction} from "@/components/table2/table_util.js";
import InputField from '@/components/core/forms/InputField.vue'
import InputTextArea from '@/components/core/forms/InputTextArea.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import { mapGetters } from 'vuex'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

function metaTagForm() {
  return {
    name: '',
    content: '',
  }
}

export default {
  props: {
  },

  data: function () {
    return {
      loading: false,
      metaTagForm: metaTagForm(),

      info: 'ein Meta-Tag "title" wird als <title></title> interpretiert',
    }
  },

  computed: {
    ...mapGetters({
      cmsMetaTags: CORE_TYPES.GETTERS.GET_CMS_META_TAGS
    }),

    headers() {
      return {
        lockedLeft: [
          TextColumn("name", "Name"),
        ],
        center: [
          TextColumn("content", "Content"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    },

    rows() {
      if (!this.cmsMetaTags) {
        return []
      }

      return this.cmsMetaTags.map(metaTag => ({
        ...metaTag,
        actions: [
          ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll der Eintrag "${metaTag.name}" wirklich gelöscht werden?`, 'Eintrag löschen', "Löschen"),
        ]
      }))
    },
  },

  methods: {
    deleteMetaTag(metaTag) {
      if (metaTag?.id) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.DELETE_CMS_META_TAG, metaTag.id)
      }
    },
    saveMetaTag() {
      if (this.metaTagForm?.name) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.SAVE_CMS_META_TAG, this.metaTagForm)
        this.metaTagForm = metaTagForm()
      }
    },
    close() {
      this.$emit("close")
    },
    async loadMetaTags() {
      this.loading = true
      await this.$store.dispatch(CORE_TYPES.ACTIONS.GET_ALL_CMS_META_TAG)
      this.loading = false
    }
  },
  mounted() {
    this.$refs.editModal.open()
    this.loadMetaTags()
  },

  components: {
    BaseModal,
    Table,
    InputField,
    BaseButton,
    InputTextArea,
    GhostLoading,
    NoData,
  },
}
</script>
