import Vue from 'vue';
import SESSION_TIME_TYPES from './types';
import { getInitialState } from './index';

export default {
  [SESSION_TIME_TYPES.MUTATIONS.RESET_STATE](state) {
    clearTimeout(state.nextScheduledSaveClickBatch)
    Object.assign(state, getInitialState())
  },

  [SESSION_TIME_TYPES.MUTATIONS.ADD_CLICK](state, payload) {
    const clicks = state.clicksByPath[payload.path] || []
    clicks.push(payload)
    Vue.set(state.clicksByPath, payload.path, clicks)

    state.queueClicks.push(payload)
  },

  [SESSION_TIME_TYPES.MUTATIONS.ADD_CLICK_BY_PATH](state, payload) {
    Vue.set(state, 'clicksByPath', payload)
  },

  [SESSION_TIME_TYPES.MUTATIONS.UPDATE_PARALLEL_TIMEOUT_TIME](state, payload) {
    Vue.set(state, 'parallelTimeoutTime', payload)
  },

  [SESSION_TIME_TYPES.MUTATIONS.UPDATE_NEXT_SCHEDULED_SAVE_CLICK_BATCH](state, payload) {
    Vue.set(state, 'nextScheduledSaveClickBatch', payload)
  },

  [SESSION_TIME_TYPES.MUTATIONS.REMOVE_CLICKS_FROM_QUEUE](state, payload) {
    const queueClicks = state.queueClicks.filter((el) => !payload.includes( el ));
    Vue.set(state, 'queueClicks', queueClicks)
  }
}
