import CORE_TYPES from '@/store/core/types';
import CUSTOMER_FILTERS_TYPES from '@/store/customerFilters/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { ALL_DEPOTS } from '@/components/depotpositions/types';

function getInitialState() {
  return {
    // reactive data
    sessions: {
      sites: ['page'],
      page: {
        kind: 'aggregate',
        smooth: true,
        beginDate: new Date(),
        endDate: new Date( new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()),
        depot: ALL_DEPOTS,
        ranges: [],
        charts: [],
        series: [],
        xLabels: [],
        xType: 0,
      },
      loading: {
        kind: 'aggregate',
        smooth: true,
        beginDate: new Date(),
        endDate: new Date( new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()),
        depot: ALL_DEPOTS,
        ranges: [],
        charts: [],
        series: [],
        xLabels: [],
        xType: 0,
      }
    },
    
    // containers
    config: {
      request: false,
    },
    aggregate: {
    },
    details: {
    },
    perf_chart: {
    },
  }
}

export default {
  state: {
    ...getInitialState()
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
    [CORE_TYPES.MUTATIONS.RESET_STATE](state) { // logout
      Object.assign(state, getInitialState());
    },
    [CUSTOMER_FILTERS_TYPES.MUTATIONS.SET_CUSTOMERS](state) { // select a (new) customer
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    ...actions
  }
}