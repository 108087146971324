export const MUTATION_PREFIX = 'MARKTBERICHT: ';
export const ACTIONS_PREFIX = 'MARKTBERICHT_ACTIONS_';
export const GETTERS_PREFIX = 'MARKTBERICHT_GETTERS_';

export default {
  MUTATIONS: {
    GET_MARKTBERICHT_SUCCESS: MUTATION_PREFIX + 'GET_MARKTBERICHT_SUCCESS',
  },
  ACTIONS: {
    GET_MARKTBERICHT: ACTIONS_PREFIX + 'GET_MARKTBERICHT',
  },
  GETTERS: {
    MARKTBERICHT: GETTERS_PREFIX + 'MARKTBERICHT',
  }
}
