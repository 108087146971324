var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showAddTable
    ? _c(
        "div",
        [
          _c("BaseToolbarButton", {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { arrow: true },
                expression: "{ arrow: true }"
              }
            ],
            staticClass: "mr-0",
            attrs: {
              title: "Neue Tabelle hinzufügen",
              icon: _vm.icon,
              disabled: _vm.disabled,
              showLabel: _vm.showLabel,
              content: "Tabelle"
            },
            on: { click: _vm.action }
          }),
          _vm.showTableModal
            ? _c("BaseToolbarTableEditor", {
                attrs: { tableForm: _vm.tableForm },
                on: {
                  close: function($event) {
                    _vm.showTableModal = false
                  },
                  onConfirmButton: _vm.setTable
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }