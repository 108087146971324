import RETIREMENT_SCENARIO_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [RETIREMENT_SCENARIO_TYPES.MUTATIONS.GET_SCENARIOS_SUCCESS](state, payload) {
    state.scenarios = payload;
    state.currentScenario = payload.retirementScenarioStandalone;
  },
  [RETIREMENT_SCENARIO_TYPES.MUTATIONS.SET_SCENARIO_SUCCESS](state, payload) {
    state.currentScenario = payload;
  },
  [RETIREMENT_SCENARIO_TYPES.MUTATIONS.DELETE_SCENARIO_SUCCESS](state, payload) {
    this.$store.dispatch(RETIREMENT_SCENARIO_TYPES.ACTIONS.GET_SCENARIOS);
    state.scenarios = payload;
  },
  [RETIREMENT_SCENARIO_TYPES.MUTATIONS.GET_PERSONAL_DATA_SUCCESS](state, payload) {
    state.personalData = payload;
  },
  [RETIREMENT_SCENARIO_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
}