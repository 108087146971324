import { BROKER_STRUCTURE_ROLES, ROLES, VIEW_ROLES } from '@/router/roles'

export default [
  {
    path: 'vermogensubersicht',
    component: () => import(/* webpackChunkName: "vermogensubersicht_home" */ '@/views/versicherungen/Ubersicht.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]
        ]
      },
    },
    children: [
      {
        path: '',
        redirect: 'depot'
      },
      {
        path: 'wertpapiere/limits/:id/:begleitscheinNr',
        component: () => import(/* webpackChunkName: "DepotpositionsLimit" */ '@/components/depotpositions/DepotpositionsLimit.vue'),
      },
      {
        path: 'wertpapiere/limits200/:id/:begleitscheinNr',
        component: () => import(/* webpackChunkName: "DepotpositionsLimit200" */ '@/components/depotpositions/DepotpositionsLimit200.vue'),
      },
      {
        path: 'depot',
        component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/components/depotpositions/Depotpositions.vue'),
        name: 'vermogensubersicht-depot',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          },
        },
      },
      {
        path: 'wertpapiere-vorsorge',
        component: () => import(/* webpackChunkName: "wertpapiere-vorsorge" */ '@/components/depotpositions/Depotpositions.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER, ROLES.SHOW_WERTPAPIERE_VORSORGE]]
          },
          depotId: 'Vorsorge',
        },
      },
      {
        path: 'versicherungsvermoegen',
        component: () => import(/* webpackChunkName: "wertpapiere-vorsorge" */ '@/components/depotpositions/Depotpositions.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.HAT_VERSICHERUNGSVERMOEGEN], [VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN,ROLES.HAT_RUECKKAUFSWERT]]

          },
          depotId: 'WERTPAPIER_VERSICHERUNG',
        },
      },
      {
        path: 'depotpositionen',
        component: () => import(/* webpackChunkName: "versicherungen1" */ '@/components/depotpositions/Depotpositions.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          }
        },
      },
      {
        path: 'versicherungen',
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]]
          },
        },
        component: () => import(/* webpackChunkName: "versicherungen_home" */ '@/views/versicherungen/balance-sheet/BalanceSheetInsurance.vue'),
      },
      {
        path: 'beteiligungen',
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS, ROLES.HAT_BETEILIGUNGEN]],
          }
        },
        component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "beteiligungen" */ '@/views/home/Beteiligungen.vue'),
          },
          {
            path: 'fondsinfooverview/:isin/:bgsNr',
            component: () => import(/* webpackChunkName: "fondsinfo" */ '@/components/fondsinfo/WertpapierinfoOverview.vue'),
          },          
        ]
      },
      {
        path: 'vermogensubersicht_pdf',
        component: () => import(/* webpackChunkName: "VermogensubersichtPdf" */ '@/components/vermogenspbersicht-pdf/VermogensubersichtPdf.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          } 
        },
      },
      {
        path: 'send-vermogensubersicht-pdf-by-email',
        component: () => import(/* webpackChunkName: "VermogensubersichtPdf" */ '@/components/vermogenspbersicht-pdf/VermogensubersichtPdf.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER],
          },
        },
        props: {
          type: 'MAIL',
        },
      },
      {
        path: 'erwartete_courtage',
        component: () => import(/* webpackChunkName: "ErwarteteCourtage" */ '@/components/depotpositions/ErwarteteCourtage.vue'),
        meta: {
          roles: {
            allowed: [ROLES.ZUGRIFF_COURTAGE_TABELLE_FONDS] 
          } 
        },
      },
      {
        path: 'fondsinfooverview/:isin',
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_CUSTOMER], 
              [VIEW_ROLES.VIEW_BROKER],
            ]
          },
        },
        component: () => import(/* webpackChunkName: "fondsinfo" */ '@/components/fondsinfo/WertpapierinfoOverview.vue'),
      },      
      {
        path: 'depot/:id',
        component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/components/balance-sheet/BalanceSheetView.vue'),
        name: 'Vermogensubersicht-depot',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          }
        },
      },
      {
        path: 'sparplan/:bgsnr/:plan_id',
        component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/views/vermogen/Sparplan.vue'),
        meta: {
          roles: {
            allowed:[[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
          },
        },
        props: true,
      },
      {
        path: 'manuelle-transaktionen/:bgsnr',
        component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/views/vermogen/ManuelleTransaktionen.vue'),
        meta: {
          roles: {
            allowed:[[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
          },
        },
        props: true,
      },
      {
        path: 'begleitscheinwpsuche',
        component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/components/begleitscheine/BegleitscheinWPSuche.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
          },
        },
      },
      {
        path: 'bgstransakt/:bgsnr/:transnr/:stepKey',
        component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/components/begleitscheine/BegleitscheinTransakt.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
          },
        }
      },
      {
        path: 'courtage/:bgsnr/:id',
        component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/views/vermogen/Courtage.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
          },
        }
      },
      {
        path: 'bgsaktivit/:bgsnr/:sid/:stepKey',
        component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/components/begleitscheine/BegleitscheinAktivitaet.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
          },
        }
      },
      {
        path: 'bgsmailaktivit',
        component: () => import( /* webpackChunkName: "vermogensubersicht" */ '@/components/mailcomposer/MailComposer.vue'),
        meta: {
          type: 'ONE_MAIL',
          mailData: ['depotpositionenedit', 'param'],
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
          },
        }
      },
      {
        path: 'weitere-vermoegen',
        component: () => import(/* webpackChunkName: "sprod-weitere-vermoegen-base" */ '@/views/otherProducts/BaseOtherProducts.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_CUSTOMER]
          },
        },
        children: [
          {
            path: '',
            redirect: 'search',
          },
          {
            path: 'search',
            component: () => import(/* webpackChunkName: "sprod-weitere-vermoegen-search" */ '@/components/otherProducts/WeitereVermoegenSearch.vue'),
          },
          {
            path: 'edit/:sprodId',
            redirect: 'edit/:sprodId/produktdaten',
          },
          {
            path: 'edit/:sprodId/:step',
            component: () => import(/* webpackChunkName: "sprod-weitere-vermoegen-view" */ '@/components/otherProducts/OtherProductView.vue'),
            props: {
              type: 'WeitereVermoegen',
            },
          },
          {
            path: 'overview/:sprodId',
            component: () => import(/* webpackChunkName: "sprod-weitere-vermoegen-overview" */ '@/components/otherProducts/OtherProductOverview.vue'),
            props: {
              type: 'WeitereVermoegen',
            },
          },            
        ],
      },
    ]
  }
]
