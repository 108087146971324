var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.rows.length
        ? _c("Table", {
            ref: "table",
            attrs: {
              tableId: _vm.tableId,
              cardView: "",
              headers: _vm.headers,
              rows: _vm.rows,
              rowId: "id",
              hidePagination: ""
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }