import FONDSFINDER_TYPES from './types';

export default {
  [FONDSFINDER_TYPES.GETTERS.FONDS](state) {
    return state.fonds;
  },
  [FONDSFINDER_TYPES.GETTERS.POSITION_INFO](state) {
    return state.positionInfo;
  },
  [FONDSFINDER_TYPES.GETTERS.COMBOBOX_SELECTIONS](state) {
    return state.comboboxSelections
  },
  [FONDSFINDER_TYPES.GETTERS.MARKTUBERSICHT_CHART_DATA](state) {
    return { ...state.marktubersichtChart?.chartData };
  },
  [FONDSFINDER_TYPES.GETTERS.MARKTUBERSICHT_CHART_SELECTED_FOND](state) {
    return { ...state.marktubersichtChart?.selectedFond };
  },
}