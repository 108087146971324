import ZUSAETZLICHE_INFORMATIONEN_TYPES from './types';

export default {
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.ZUSAETZLICHE_DATEN](state) {
    return state.zusaetzlicheDaten;
  },
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.ALLE_STRUKTUR_ZUSAETZLICHE_INFOS](state) {
    return state.alleStrukturZusaetzlicheInfos;
  },
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.MAIN_TABLE_ROWS](state) {
    return state.mainTableRows;
  },
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.SECONDARY_TABLE_ROWS](state) {
    return state.secondaryTableRows;
  },


  
  
  
}