import STECKBRIEF_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue'

export default {
  [STECKBRIEF_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [STECKBRIEF_TYPES.MUTATIONS.GET_TODO_VIDEO_INDENT](state, payload) {
    state.videoindent = payload.outputTable;
  },
  [STECKBRIEF_TYPES.MUTATIONS.SET_TODO_VIDEO_INDENT_COUNT](state, payload) {
    Vue.set(state, 'videoIndentCount', payload || 0);
  },
  [STECKBRIEF_TYPES.MUTATIONS.GET_STECKBRIEF_CUSTOMER_INFO](state, payload) {
    state.info = payload;
  },
  [STECKBRIEF_TYPES.MUTATIONS.GET_DEPOTPOSITIONS](state, payload) {
    state.depotpositions = payload;
  },
  [STECKBRIEF_TYPES.MUTATIONS.GET_INVESTMENT_BESTAND](state, payload) {
    state.investmentBestand = payload;
  },
  [STECKBRIEF_TYPES.MUTATIONS.GET_MULTIBANKING_DATA](state, payload) {
    state.multibanking = payload;
  },
  [STECKBRIEF_TYPES.MUTATIONS.SIMPLY_CHART_GV](state, payload) {
    state.simplyChartGV = payload;
  },
  [STECKBRIEF_TYPES.MUTATIONS.SET_FREISTELLUNGSANTRAG_SUBTOTALS](state, payload) {
    Vue.set(state, 'freistellungsantragSubtotals', payload);
  },
}