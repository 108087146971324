import KENNZAHLEN_TYPES from './types';
import Vue from 'vue';


export default {

  [KENNZAHLEN_TYPES.MUTATIONS.FIND_KENNZAHLEN_DATA_SUCCESS](state, payload) {
    if(!payload?.depotId) return;
    Vue.set(state.kennzahlenData, payload.depotId, { ...payload });
  },

}
