var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SortableList",
    {
      staticClass: "menu-structure-sortable__container",
      attrs: { items: _vm.menus, disabled: _vm.disabled },
      on: {
        orderChanged: function($event) {
          return _vm.$emit("orderChanged", {
            parent: _vm.parent,
            menus: $event
          })
        }
      }
    },
    [
      _c(
        "ul",
        {
          staticClass: "menu-list__container",
          attrs: { "data-sortable-container": "" }
        },
        [
          _vm._l(_vm.menus, function(menu, index) {
            return [
              _c(
                "li",
                {
                  key: menu.id,
                  staticClass: "menu-list--item",
                  class: {
                    "group-menu": _vm.isGroupMenu(menu),
                    "custom-menu": _vm.isCustomGroupMenu(menu),
                    "async-menu": _vm.isAsyncMenu(menu)
                  },
                  attrs: { "data-sortable-item": "" }
                },
                [
                  _c("div", { staticClass: "menu-list--item__container" }, [
                    _c(
                      "div",
                      { staticClass: "menu-list--item__container-wrap" },
                      [
                        !_vm.disabled
                          ? _c(
                              "div",
                              { staticClass: "menu-list--item__actions" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-clear",
                                    attrs: {
                                      type: "button",
                                      "data-sortable-item-handler": ""
                                    }
                                  },
                                  [_c("PhDotsNine", { attrs: { size: 16 } })],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "menu-list--item__content" }, [
                          _c(
                            "div",
                            { staticClass: "menu-list--item__position" },
                            [_vm._v(_vm._s(index + 1) + ".")]
                          ),
                          menu.component && _vm.isNonTabMenu(menu)
                            ? _c(
                                "div",
                                { staticClass: "menu-list--item__icon" },
                                [
                                  _c(
                                    menu.component,
                                    _vm._b(
                                      { tag: "component" },
                                      "component",
                                      menu.componentProps,
                                      false
                                    )
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("span", {
                            staticClass: "menu-list--item__label",
                            domProps: {
                              innerHTML: _vm._s(_vm.$sanitize(menu.label))
                            }
                          })
                        ]),
                        !_vm.disabled
                          ? _c(
                              "div",
                              { staticClass: "menu-list--item__actions" },
                              [
                                _vm.isGroupMenu(menu)
                                  ? _c(
                                      "AddOptionMenuDropdown",
                                      {
                                        attrs: { parent: menu },
                                        on: {
                                          add: function($event) {
                                            return _vm.$emit("add", $event)
                                          },
                                          addCustom: function($event) {
                                            return _vm.$emit(
                                              "addCustom",
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn-clear clickable",
                                            attrs: { type: "button" }
                                          },
                                          [
                                            _c("PhPlus", {
                                              attrs: { size: 16 }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.isNonTabMenu(menu)
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn-clear clickable",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("remove", {
                                              parent: _vm.parent,
                                              menu: menu
                                            })
                                          }
                                        }
                                      },
                                      [
                                        _c("PhTrashSimple", {
                                          attrs: { size: 16 }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ]),
                  menu.subMenu && menu.subMenu.length > 0
                    ? _c("MenuStructureSortable", {
                        attrs: {
                          parent: menu,
                          menus: menu.subMenu,
                          disabled: _vm.disabled
                        },
                        on: {
                          add: function($event) {
                            return _vm.$emit("add", $event)
                          },
                          remove: function($event) {
                            return _vm.$emit("remove", $event)
                          },
                          addCustom: function($event) {
                            return _vm.$emit("addCustom", $event)
                          },
                          orderChanged: function($event) {
                            return _vm.$emit("orderChanged", $event)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      !_vm.disabled && !_vm.parent && _vm.hasAvailablePrimary
        ? _c(
            "div",
            { staticClass: "menu-list__add-new" },
            [
              _c(
                "AddOptionMenuDropdown",
                {
                  attrs: { parent: _vm.parent },
                  on: {
                    add: function($event) {
                      return _vm.$emit("add", $event)
                    },
                    addCustom: function($event) {
                      return _vm.$emit("addCustom", $event)
                    }
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-clear clickable",
                      attrs: { type: "button" }
                    },
                    [
                      _c("PhPlus", { attrs: { size: 16 } }),
                      _vm._v(" neues hinzufügen")
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }