<template>
  <div class="hrtag">
   <hr class="hrcolour">
  </div>
</template>

<script>
export default {
  name: "HrTag"
};
</script>

<style scoped>
.hrtag {
  grid-area: hrtag;
}

</style>