var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label:
            "Einnahmen aus unselbständiger Arbeit (Gehaltsabrechnung / netto pro Monat)",
          type: "currency",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "salary", $event || 0)
          }
        },
        model: {
          value: _vm.salary,
          callback: function($$v) {
            _vm.salary = $$v
          },
          expression: "salary"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Einnahmen aus selbständiger Arbeit (laut Einkommensteuerbescheid / netto pro Monat)",
          type: "currency",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "freelancerRevenue", $event || 0)
          }
        },
        model: {
          value: _vm.freelancerRevenue,
          callback: function($$v) {
            _vm.freelancerRevenue = $$v
          },
          expression: "freelancerRevenue"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Einnahmen aus Kapitalvermögen (z.B. Zinsen, Dividenden, Ausschüttungen pro Monat)",
          type: "currency",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "capitalRevenue", $event || 0)
          }
        },
        model: {
          value: _vm.capitalRevenue,
          callback: function($$v) {
            _vm.capitalRevenue = $$v
          },
          expression: "capitalRevenue"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Sonstiges (z.B. Unterhaltsbeträge, Mieteinnahmen usw. pro Monat)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "otherRevenue", $event || 0)
          }
        },
        model: {
          value: _vm.otherRevenue,
          callback: function($$v) {
            _vm.otherRevenue = $$v
          },
          expression: "otherRevenue"
        }
      }),
      _c("div", { staticClass: "sum d-flex justify-content-between" }, [
        _c("div", { staticClass: "my-2" }, [
          _vm._v("Summe des derzeit regelmäßigen Einkommens (mtl.)")
        ]),
        _c(
          "div",
          { staticClass: "my-2 pr-3" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeEinkommen } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }