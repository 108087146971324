<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="input-forms__label-container">
        <div class="row mt-0">
          <div class="col">
            <div
              class="
                input-forms__label-content input-forms__label-content--bigger
              "
              v-if="label"
            >
              <ph-bank :size="16" />&nbsp;{{ label }}
            </div>
          </div>
          <div class="col-auto" v-if="config && config.buttonText">
            <BaseButton @click="openFormsFinder">{{
              config.buttonText
            }}</BaseButton>
          </div>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <Table
        v-if="tableData && tableData.records && tableData.records.length"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="false"
        :actions="columnActions"
        :rowsPerPage="tableData.records.length"
        @execute-action="handleTableAction($event)"
      >
        <template v-slot:betragSparplan="props">
          <InputField
            v-if="props.data.row"
            type="currency"
            v-model="props.data.row.betragSparplan"
            @input="
              onChangeInputField($event, props.data.row, 'betragSparplan')
            "
          />
        </template>
        <template v-slot:aa="props">
          <InputField
            v-if="props.data.row"
            type="number"
            :precision="4"
            :placeholder="props.data.row.defaultAA"
            v-model="props.data.row.aa"
            @input="onChangeInputField($event, props.data.row, 'aa')"
          />
        </template>
      </Table>

      <DeletePositionModal
        ref="deleteModal"
        :position="positionToDelete"
        @delete="doDeletePosition()"
      ></DeletePositionModal>

      <BaseModal
        ref="fehlerModal"
        labelButtonCancel="Ok"
        :showConfirmButton="false"
      >
        <template v-slot:modalTitle>
          <ph-warning :size="16" class="color-danger mr-2" />
          <span class="color-danger">
            Sie haben die maximale Anzahl von Fonds erreicht.</span>
        </template>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import BaseModal from "@/components/core/BaseModal.vue";
import Table from "@/components/table/Table.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import { mapGetters } from "vuex";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import { PhBank, PhWarning } from "phosphor-vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";

export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {
    label: {},
    antragData: {},
    config: {},
    antragTyp: {},
    antragId: {},
    categoryId: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
    warnings: {},
  },
  components: {
    BaseButton,
    BaseModal,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    PhBank, PhWarning,
    InputRadioBoxGroup,
  },
  data() {
    return {
      depotPosition: "",
      disabled: false,
      columnActions: [
        {
          legend: {
            icon: "PhTrash",
            index: 1,
            key: "DELETE",
            label: "Löschen",
          },
        },
      ],
      positionToDelete: {},
    };
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
    }),
    tableData() {
      const result = {
        headers: {
          isin: {
            label: "ISIN",
            key: "isin",
            dataType: "String",
            visible: true,
            sum: false,
            fixed: true,
          },
          fondsName: {
            label: "Fondsname",
            key: "fondsName",
            dataType: "String",
            visible: true,
            sum: false,
            fixed: true,
          },
          betragSparplan: {
            label: "Betrag",
            key: "betragSparplan",
            dataType: "Slot",
            dataSymbol: "€",
            visible: true,
            sum: false,
            fixed: true,
          },
          aa: {
            label: "Effektenprovision",
            key: "aa",
            dataType: "Slot",
            visible: true,
            sum: false,
            fixed: true,
          },
          actions: {
            label: "",
            key: "actions",
            priority: 1,
            dataType: "tableAction",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
        },
        records:
          this.positions &&
          this.positions.map((pos, index) =>
            Object.assign(pos, {
              index: index,
              fondsName: this.getFondsName(pos, this.positionInfo),
              lagerstelle: "CAPITALBANK",
               aa: pos.aa,
              defaultAA: this.positionInfo[pos.isin]?.defaultAusgabeaufschlag != null ? "" + this.positionInfo[pos.isin].defaultAusgabeaufschlag : "",
            })
          ),
      };
      return result;
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.categoryId &&
        this.positionsAll[this.antragId] &&
        this.positionsAll[this.antragId][this.categoryId]
      ) {
          return this.positionsAll[this.antragId][this.categoryId]
      }
    },
    positionData() {
      if (
        this.positionDataAll &&
        this.antragId &&
        this.categoryId &&
        this.positionDataAll[this.antragId] &&
        this.positionDataAll[this.antragId][this.categoryId]
      ) {
        return this.positionDataAll[this.antragId][this.categoryId];
      }
    },
  },
  watch: {
    positions() {
      if (this.config.maxFonds && this.positionsAll[this.antragId]) {
        if (
          this.positionsAll &&
          this.positionsAll[this.antragId][this.categoryId].length >
            this.config.maxFonds
        ) {
          this.$refs.fehlerModal.open();
          this.positionsAll[this.antragId][this.categoryId] = [];
        }
      }
    },
  },

  methods: {
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          {
            ...positionChange,

            isin,
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },

    onChangeInputField($event, position, field) {
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin);
    },
    handleTableAction(event) {
      if (event.action?.legend?.key === "DELETE") {
        this.openModalDeleteFond(event.value);
      }
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      this.deletePosition(
        this.antragId,
        this.categoryId,
        this.positionToDelete
      );
      this.positionToDelete = null;
    },
    openFormsFinder() {
      if (
        this.config.maxFonds &&
        this.positions &&
        this.positions.length >= this.config.maxFonds
      ) {
        this.$refs.fehlerModal.open();
      } else {
        this.goToFormsFinder(
          this.categoryId,
          this.config,
          this.suppressFilterLagerstelle
        );
      }
    },

  },
};
</script>

<style scoped>
.input-forms__label-content--bigger {
  font-size: 1.5rem;
}
.input-forms__label-content--bigger > i {
  font-size: 2rem;
}
</style>