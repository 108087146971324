var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard__container" },
    [
      _vm.isCustomer ? _c("DashboardCustomer") : _vm._e(),
      _vm.isBrokerOrByPass ? _c("DashboardBroker") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }