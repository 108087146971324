var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CoreCard",
    {
      ref: "coreCard",
      attrs: { showToolbar: _vm.showToolbar, listView: _vm.listView },
      scopedSlots: _vm._u(
        [
          _vm.showTitle
            ? {
                key: "header",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.chartName) + " ")]
                },
                proxy: true
              }
            : null,
          {
            key: "alternative",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c("div", { staticClass: "content" }, [
        _vm.isContainerRendered && _vm.data["" + _vm.chartName]
          ? _c("div", { staticClass: "w-100" }, [
              _c("div", { staticClass: "table w-100" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "table-cell" }, [
                    _vm._v("Verträge insgesamt")
                  ]),
                  _c("div", { staticClass: "table-cell right" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.data["" + _vm.chartName].insgesmat)
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "table-cell" }, [
                    _vm._v("Ø Verträge pro Kunde")
                  ]),
                  _c("div", { staticClass: "table-cell right" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.data["" + _vm.chartName].VertageKunde.toFixed(2)
                        )
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "table-cell" }, [
                    _vm._v("Ø Courtage pro Kunde")
                  ]),
                  _c("div", { staticClass: "table-cell right" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.data["" + _vm.chartName].UmsatzKunde.toFixed(2)
                        )
                      ) + "€"
                    )
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }