<template>
    <div>
        <Table v-if="!loading && rows.length"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="rowPerPage"
            :tableRowsPerPage="[]"
            ignoreTablePageSize
            hidePagination
            :mobileConfig="mobileConfig"
            rowId="index"
            @click-name="openCustomerNewTab"
            @action-email="createEmail"
        />
        <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: rowPerPage, }, }" />
        <NoData v-else noIcon />

        <div v-if="!loading && !$isSmallScreen" class="bottom-row text-right mt-3">
        <router-link :to="{ name: 'customer-search', params: { defaultFilter: defaultFilters}}">weitere...</router-link>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import CORE_TYPES from '@/store/core/types';
import dayjs from "dayjs";
import Table from '@/components/table2/Table.vue'
import {TextColumn, DateColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const NEXT_DAYS_DEFAULT = 7;
const ROWS_PER_PAGE = 5;

export default {
  props: {
    nextDays: {
      type: Number,
      default: NEXT_DAYS_DEFAULT,
    },
  },
  data() {
    return {
        rowPerPage: ROWS_PER_PAGE,
        loading: false,
        headers: {
            lockedLeft: [
              TextColumn("name", "Name").makeLink().makeAlwaysVisible(),
              DateColumn("birthDate", "Geburtsdatum").makeAlwaysVisible(),
              TextColumn("automaticEmail", "Automatisierte Glückwunschmail"),
            ],
            lockedRight: [
              ActionColumn("actions", ""),
            ],
        },
        mobileConfig: {
          title: '',
          headers: ['name','birthDate','automaticEmail'],
        }
    };
  },
  computed: {
    ...mapGetters({
      birthdays: CUSTOMERDATA_TYPES.GETTERS.BIRTHDAYS,
    }),
    rows() {
        const rows = this.birthdays.map((r, index) => ({
            index,
            ...r,
            name: `${r.lastName || ''}${r.firstName ? ', ' + r.firstName : ''}`.trim(),
            actions: r.automaticEmail === 'Nein' ? [SimpleAction("email", 'PhEnvelopeSimple', "Glückwünsche senden")] : []
        }));
        return rows;
    },
    defaultFilters(){
      return {
            dayOfBirthVonBis :{
              value: [
                {key: 'min', value: dayjs(new Date()).format('DD.MM.YYYY')},
                {key: 'max', value: dayjs(new Date().setDate(new Date().getDate() + 7)).format('DD.MM.YYYY') }
              ]
            },
            inklOhneVertrag: {
              value: true,
            },
            structure: {
              value: true,
            },
          }
    }
  },
  methods: {
    async findBirthdays() {
      try {
        this.loading = true;
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_BIRTHDAYS, { nextDays: this.nextDays, });
      } finally {
        this.loading = false;
      }
    },
    openCustomerNewTab(customer) {
      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, { data: customer, });
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: customer.customerID })
    },
    async createEmail(event) {
      if (event?.customerID) {
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_BIRTHDAY_EMAIL_DATA, {customerID: event.customerID});
        this.$router.push('/communication/mailcomposer-single');
      }
    },
  },
  mounted() {
    this.findBirthdays();
  },
  components: {
    Table,
    GhostLoading,
    NoData,
  },
}
</script>
