var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("DatePickerField", {
        attrs: {
          label: "Wann wurde der Schaden verursacht?",
          isComponentHalfSize: true
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.ereignisdatum,
          callback: function($$v) {
            _vm.$set(_vm.form, "ereignisdatum", $$v)
          },
          expression: "form.ereignisdatum"
        }
      }),
      _c("TimePicker", {
        attrs: { label: "Um wieviel Uhr?", isComponentHalfSize: true },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.ereignisUhrzeit,
          callback: function($$v) {
            _vm.$set(_vm.form, "ereignisUhrzeit", $$v)
          },
          expression: "form.ereignisUhrzeit"
        }
      }),
      _c("InputField", {
        attrs: { label: "Straße, Hausnummer", isComponentHalfSize: true },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.adresseStrasse,
          callback: function($$v) {
            _vm.$set(_vm.form, "adresseStrasse", $$v)
          },
          expression: "form.adresseStrasse"
        }
      }),
      _c("InputZIP", {
        attrs: { label: "PLZ", isComponentHalfSize: true },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          },
          city: function($event) {
            _vm.form.adresseOrt = $event
            _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.adressePlz,
          callback: function($$v) {
            _vm.$set(_vm.form, "adressePlz", $$v)
          },
          expression: "form.adressePlz"
        }
      }),
      _c("InputField", {
        attrs: { label: "Ort", isComponentHalfSize: true },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.adresseOrt,
          callback: function($$v) {
            _vm.$set(_vm.form, "adresseOrt", $$v)
          },
          expression: "form.adresseOrt"
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Land",
          isComponentHalfSize: true,
          values: _vm.configData.lands,
          firstEmpty: true
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.adresseLand,
          callback: function($$v) {
            _vm.$set(_vm.form, "adresseLand", $$v)
          },
          expression: "form.adresseLand"
        }
      }),
      _c("InputTextArea", {
        attrs: { label: "Schadensablauf", isComponentHalfSize: true },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.schadenKommentar,
          callback: function($$v) {
            _vm.$set(_vm.form, "schadenKommentar", $$v)
          },
          expression: "form.schadenKommentar"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }