import CORE_TYPES from '../../store/core/types';
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import Vue from 'vue'
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import store from '@/store'

var HelpRecordingMixin = {
    data() {
        return {
            recordedItems: [],
            _direction: 'forward',
            _currentStep: null,
            _targetElement: null,
            mode: {
                ON: { value: 0 },
                PAUSED: { value: 1 },
                OFF: { value: 2 }
            },
            recordingMode: null,
            _mousedownFunction: null,
            textAreaCaption: 'Bitte geben Sie den Hilfetext für dieses Element ein',
            finishLabel: 'Aufzeichnung stoppen',
            _title: ''

        }
    },
    computed: {
        ...mapGetters({
            tutorialRecordedItems: CORE_TYPES.GETTERS.TUTORIAL_RECORDED_ITEMS,
            recordingTutorialTitle: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_TITLE,
            recordingTutorialRoute: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_ROUTE,
            tutorialUserType: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_USER_TYPE,
        })
    },
    methods: {
        stopTutorialRecording() {
            this.highlightElementsWithTidAttribute(false);
            document.removeEventListener("mousedown", this._mousedownFunction, true);
            this.recordingMode = this.mode.OFF;
            this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_STOP);
            this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_FEATURE_DISABLE);
            this.hideRecordingButton();

        },
        startTutorialRecording(context) {
            this.recordingMode = this.mode.ON;
            this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_START);

            context = context || this.$root.$el;
            this._targetElement = context;
            let self = this;

            this.highlightElementsWithTidAttribute(true);

            let mousedownFunction = function (event) {

                if (event.target.className !== 'fc-textarea') {
                    event.preventDefault();

                    if (event.target.className && typeof event.target.className === 'string' && event.target.className.indexOf('Recbutton') > -1) {
                        
                        if (self.recordedItems && self.recordedItems.length > 0) {
                            self._saveTutorial();
                        }
                        
                        self.stopTutorialRecording();
                        return;
                    }
    
                    if (self.recordingMode == self.mode.OFF) {
                        self.stopTutorialRecording();
                        return;
                    }
    
                    let path = self.getDomPath(event.target);
    
                    if (self.recordingMode == self.mode.PAUSED || path === 'body > div') {
    
                        return;
                    }
    
                    let uuid = event.target.getAttribute('tid');
    
                    // only elements with a tid attribute will be processed
                    if (!uuid) {
    
                        // tries to find the tid attribute in the parent element,
                        // thus the user doesn't have to click exactly in the border of a div
                        let parentElement = self._upperParentWithTid(event.target);
                        uuid = parentElement && parentElement.getAttribute('tid');
    
                        if (!uuid) {
                            return;
                        }
                    }
    
                    if (self._dimWindow(context, 'help-recording', self._exitHelpRecording)) {
                        self._addElementToArray(path, uuid);
                        self._showRecordedItem(self.recordedItems.find( s => /* s.helpPath === path && */  s.stepKey === uuid), event);
                        self.recordingMode = self.mode.PAUSED;
                    }

                }

            };

            this._mousedownFunction = mousedownFunction;

            if (this._addEventListenerTimer) {
                window.clearTimeout(this._addEventListenerTimer);
            }

            this._addEventListenerTimer = window.setTimeout(function () {

                // self._targetElement.addEventListener("mousedown", mousedownFunction, true);
                document.addEventListener("mousedown", mousedownFunction, true);

            }, 950);

        },
        hideRecordingButton() {
            let elementsObjArray = this._targetElement.querySelectorAll('.Recbutton.Rec');
            let recordButtonElement = elementsObjArray[0];
            if (recordButtonElement) {
                recordButtonElement.className = 'Recbutton notRec';
            }
        },
        getDomPath(el) {
            if (!el) {
                return;
            }
            var stack = [];
            var isShadow = false;
            while (el.parentNode != null) {
                var sibCount = 0;
                var sibIndex = 0;
                // get sibling indexes
                for (var i = 0; i < el.parentNode.childNodes.length; i++) {
                    var sib = el.parentNode.childNodes[i];
                    if (sib.nodeName == el.nodeName) {
                        if (sib === el) {
                            sibIndex = sibCount;
                        }
                        sibCount++;
                    }
                }
                var nodeName = el.nodeName.toLowerCase();
                if (isShadow) {
                    nodeName += "::shadow";
                    isShadow = false;
                }
                if (sibCount > 1) {
                    stack.unshift(nodeName + ':nth-of-type(' + (sibIndex + 1) + ')');
                } else {
                    stack.unshift(nodeName);
                }
                el = el.parentNode;
                if (el.nodeType === 11) { // for shadow dom, we
                    isShadow = true;
                    el = el.host;
                }
            }
            stack.splice(0, 1); // removes the html element
            return stack.join(' > ');
        },
        /**
         * Add last clicked element to array
         */
        _addElementToArray(path, uuid) {

            let  currentElement = document.querySelector(`[tid="${uuid}"]`);                

            if (!currentElement) {
                return false;
            }

            let step = {
                element: currentElement,
                helpMessage: currentElement.getAttribute('help-message'),
                helpPath: path,
                stepKey: uuid,
                tooltipClass: currentElement.getAttribute('help-tooltipclass'),
                highlightClass: currentElement.getAttribute('help-highlightclass'),
                position: currentElement.getAttribute('help-position') || 'bottom',
                routerPath: this.$route.path,
                canvas: null
            };

            this.recordedItems.push(step);

            this._currentStep = this.recordedItems.findIndex(s => s.stepKey === uuid);

        },
        _exitHelpRecording() {
            let overlayLayers = this._targetElement.querySelectorAll('.help-recording-overlay');

            if (overlayLayers && overlayLayers.length) {
                this._iterate(overlayLayers, function (overlayLayer) {
                    overlayLayer.style.opacity = 0;
                    window.setTimeout(function () {
                        if (this.parentNode) {
                            this.parentNode.removeChild(this);
                        }
                    }.bind(overlayLayer), 500);
                }.bind(this));
            }

            let helperLayer = this._targetElement.querySelector('.help-recording-helperLayer');
            if (helperLayer) {
                helperLayer.parentNode.removeChild(helperLayer);
            }

            let referenceLayer = this._targetElement.querySelector('.help-recording-tooltipReferenceLayer');
            if (referenceLayer) {
                referenceLayer.parentNode.removeChild(referenceLayer);
            }

            this._removeHighlight('help-recording', /help-recording-[a-zA-Z]+/g);

            this._currentStep = undefined;

            if (this._turnOnTimer) {
                window.clearTimeout(this._turnOnTimer);
            }

            let self = this;

            this._turnOnTimer = window.setTimeout(function () {
                if (self.recordingMode === self.mode.PAUSED) {
                    self.recordingMode = self.mode.ON;
                    self.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_START);

                } else {
                    self._saveTutorial();
                }

            }, 350);


        },
        _saveTutorial() {

            let items = this.recordedItems.map(function (item) {
                return {
                    id: null,
                    canvas: item.canvas,
                    element: null,
                    helpMessage: item.helpMessage,
                    helpPath: item.helpPath,
                    highlightClass: item.highlightClass,
                    position: item.position,
                    routerPath: item.routerPath,
                    stepKey: item.stepKey,
                    tooltipClass: item.tooltipClass,
                    autoClick: item.autoClick,
                    noClick: item.noClick,
                };
            });

            this.$store.dispatch(CORE_TYPES.ACTIONS.SEND_TUTORIAL,
                {
                    title: this.recordingTutorialTitle,
                    routerPath: this.recordingTutorialRoute,
                    items: items,
                    userType: this.tutorialUserType,
                }
            );

            this._resetTutorial();

            if (this.$router.path !== '/home/tutorial-recorded-items') {
                this.$router.push({ path: '/home/tutorial-recorded-items' }).catch();
            }
        },
        _upperParent(element) {

            if (!element.parentNode || element.parentNode.nodeName === '#document') {
                return element;
            } else {
                this._upperParent(element.parentNode);
            }

        },
        _upperParentWithTid(element) {
            if (!element.parentNode || element.parentNode.nodeName === '#document' || element.getAttribute('tid') != null ) {
                return element;
            } else {
                return this._upperParentWithTid(element.parentNode);
            }

        },        
        _takeScreenshot() {
            let theElement = this.$root.$el;

            return html2canvas(theElement, {
                backgroundColor: 'unset',
                foreignObjectRendering: false,
                logging: false,
                removeContainer: false,
                allowTaint: false
            });           

        },
        _getPosition(string, subString, index) {
            return string.split(subString, index).join(subString).length;
        },
        _showRecordedItem(step, event) {

            const baseButtonConstructor = Vue.extend(BaseButton);
            const inputTextAreaConstructor = Vue.extend(InputTextArea);
            const inputToggleSwitchConstructor = Vue.extend(InputToggleSwitch);

            const bbSkipTooltip = new baseButtonConstructor({ class: 'btn btn-primary' });
            const bbFinishTooltip = new baseButtonConstructor({ class: 'btn btn-secondary' });


            let self = this,
                highlightClass = 'help-recording-helperLayer';

            let helperLayer = document.createElement('div'),
                referenceLayer = document.createElement('div'),
                arrowLayer = document.createElement('div'),
                tooltipLayer = document.createElement('div'),
                tooltipTextLayer = document.createElement('div'),
                bulletsLayer = document.createElement('div'),
                buttonsLayer = document.createElement('div');

            helperLayer.className = highlightClass;
            referenceLayer.className = 'help-recording-tooltipReferenceLayer';

            this._setHelperLayerPosition(helperLayer, this.recordedItems);
            this._setHelperLayerPosition(referenceLayer, this.recordedItems);

            this._targetElement.appendChild(helperLayer);
            this._targetElement.appendChild(referenceLayer);

            arrowLayer.className = 'help-recording-arrow';

            tooltipTextLayer.className = 'help-recording-tooltiptext';

            if (step.helpMessage== null) {
                step.helpMessage = '';
            }

            const inputTextArea =  new inputTextAreaConstructor(
                { 
                    class: 'help-recording-textarea',
                    propsData: {
                    label: this.textAreaCaption,
                    value: step.helpMessage,
                    autoFocus: true,
                }}
                );     
                
            
            inputTextArea.$on('input', value => {
                step.helpMessage = value
                });                
                
            inputTextArea.$mount();

            inputTextArea.$el.setAttribute('ref', 'testing');
            tooltipTextLayer.appendChild(inputTextArea.$el);            

            bulletsLayer.className = 'help-recording-bullets';

            let ulContainer = document.createElement('ul');
            ulContainer.setAttribute('role', 'tablist');

            this._iterate(this.tutorialRecordedItems, function (item, i) {

                let innerLi = document.createElement('li');
                let bullet = document.createElement('span');

                // innerLi.setAttribute('role', 'presentation');
                // bullet.setAttribute('role', 'tab');

                if (i === (step.step - 1)) {
                    bullet.className = 'active';
                }

                bullet.innerHTML = "&nbsp;";
                bullet.setAttribute('data-stepnumber', item.step);

                innerLi.appendChild(bullet);
                ulContainer.appendChild(innerLi);
            });

            bulletsLayer.appendChild(ulContainer);

            buttonsLayer.className = 'help-recording-tooltipbuttons';

            tooltipLayer.className = 'help-recording-tooltip';
            tooltipLayer.appendChild(tooltipTextLayer);
            tooltipLayer.appendChild(bulletsLayer);

            tooltipLayer.appendChild(arrowLayer);
            referenceLayer.appendChild(tooltipLayer);

             // "nur Erklärung, keine Aktion ausführen" switcher
             const itsNoClickSwitcher =  new inputToggleSwitchConstructor(
                { 
                    propsData: {
                    label: this.options.noClickLabel,
                    value: step.noClick,
                }}
                );      
                
                itsNoClickSwitcher.$on('input', value => {
                    step.noClick = value;
                });                 

            itsNoClickSwitcher.$mount();

            buttonsLayer.appendChild(itsNoClickSwitcher.$el);            

            //skip button
            bbSkipTooltip.$slots.default = [ this.options.skipLabel ];

            bbSkipTooltip.$on('click', () => { 
                
                self._addTutorialSpinner(self._targetElement);

                self._takeScreenshot().then(canvas => {
                    self._exitHelpRecording();
                    step.canvas = canvas.toDataURL();

                    // if the clicked element (or its parent) has an href attribute, should redirect to the new route
                    let hrefProperty = self._iterateParent(event.target, 'href');
                    if (event && event.target && hrefProperty) {
                        let n = hrefProperty.lastIndexOf('/');
                        let routerPath = hrefProperty.substring(n);
                        step.routerPath = routerPath;
    
                        if (self.$route.path !== routerPath) {
                            self.$router.push({ path: `${routerPath}` });
                        }
                    } else if (event && event.target && !step.noClick) {
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
    
                        event.target.dispatchEvent(clickEvent);
                    } 
    
                    self._removeTutorialSpinner(self._targetElement);
                    self.$store.dispatch(CORE_TYPES.ACTIONS.ADD_RECORDED_ITEM, step);                     

                }); 
                

             }); 

            //finish button -------------------------------------------------

            bbFinishTooltip.$slots.default = [ this.finishLabel ];

            bbFinishTooltip.$on('click', () => { 

                self._addTutorialSpinner(self._targetElement);

                self._takeScreenshot().then(canvas => {
                    step.canvas = canvas.toDataURL();
                    
                    self.elementsIdArray.items = [];
                    self.recordedItems.forEach(ri => {
                        self.elementsIdArray.items.push({ id: ri.stepKey, message: ri.helpMessage });
                    });
                    
                    self._exitHelpRecording();              
                    self.$store.dispatch(CORE_TYPES.ACTIONS.ADD_RECORDED_ITEM, step);
                    self.stopTutorialRecording();
                    self._removeTutorialSpinner(self._targetElement);


                });



             }); 

            bbFinishTooltip.$store = store
            bbFinishTooltip.$mount();
            buttonsLayer.appendChild(bbFinishTooltip.$el);

            tooltipLayer.appendChild(buttonsLayer);

            this._positionTooltip(step.element, tooltipLayer, arrowLayer, 'help-recording');


            bbSkipTooltip.$slots.default = [this.options.nextLabel];
            bbSkipTooltip.$store = store
            bbSkipTooltip.$mount();

            this._addClass(step.element, 'help-recording-showElement');
            this._addClass(step.element, 'help-recording-relativePosition');

            buttonsLayer.appendChild(bbSkipTooltip.$el);

        },

    }
}

export default HelpRecordingMixin;

