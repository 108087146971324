var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MailBriefVorlage"),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Hintergrund Briefvorlage hochgeladen")
          ]),
          _c(
            "DragnDropArea",
            {
              attrs: { hoverText: "Datei hier ablegen" },
              on: {
                files: function($event) {
                  return _vm.addDocument($event)
                }
              }
            },
            [
              _c("div", { staticClass: "d-block justify-content-between" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center"
                  },
                  [
                    _c(
                      "div",
                      [
                        _vm.backgroundTemplateForm &&
                        _vm.backgroundTemplateForm.backgroundTemplateFileId
                          ? _c("DownloadLink", {
                              key:
                                _vm.backgroundTemplateForm
                                  .backgroundTemplateFileId,
                              attrs: {
                                title:
                                  _vm.backgroundTemplateForm
                                    .backgroundTemplateFilename,
                                filename:
                                  _vm.backgroundTemplateForm
                                    .backgroundTemplateFilename,
                                downloadServicePath: "/get_simple_file",
                                queryParams: {
                                  fileId:
                                    _vm.backgroundTemplateForm
                                      .backgroundTemplateFileId
                                }
                              }
                            })
                          : _vm.backgroundTemplateForm
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.backgroundTemplateForm
                                      .backgroundTemplateFilename
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "layout__negative-margin--8" },
                        [
                          _c(
                            "BaseButton",
                            { staticClass: "upload-btn" },
                            [
                              _vm._t("default", function() {
                                return [_vm._v("Briefvorlage PDF hochladen")]
                              }),
                              _c("input", {
                                attrs: {
                                  type: "file",
                                  accept: _vm.acceptFileTypes
                                },
                                on: { change: _vm.onAddFiles }
                              })
                            ],
                            2
                          ),
                          _c("BaseButton", {
                            attrs: {
                              label: "Löschen",
                              isSecondary: "",
                              disabled: _vm.isDeleteButtonDisabled
                            },
                            on: {
                              click: function($event) {
                                return _vm.deletePDFBackgroundTemplate()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Seitenabstände (in mm):")
          ]),
          _c("InputToggleSwitch", {
            attrs: { label: "Standard verwenden" },
            on: {
              input: function($event) {
                return _vm.changeUseDefaultMeasurements($event)
              }
            },
            model: {
              value: _vm.backgroundTemplateForm.useDefaultMeasurements,
              callback: function($$v) {
                _vm.$set(
                  _vm.backgroundTemplateForm,
                  "useDefaultMeasurements",
                  $$v
                )
              },
              expression: "backgroundTemplateForm.useDefaultMeasurements"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: { label: "Hintergrundvorlage auf Folgeseiten verwenden" },
            on: {
              input: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value:
                _vm.backgroundTemplateForm.useBGTemplateFilesOnSubsequentPages,
              callback: function($$v) {
                _vm.$set(
                  _vm.backgroundTemplateForm,
                  "useBGTemplateFilesOnSubsequentPages",
                  $$v
                )
              },
              expression:
                "backgroundTemplateForm.useBGTemplateFilesOnSubsequentPages"
            }
          }),
          _c("InputField", {
            attrs: {
              disabled: _vm.backgroundTemplateForm.useDefaultMeasurements,
              precision: 1,
              isComponentHalfSize: "",
              label: "Links",
              type: "number"
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.backgroundTemplateForm.sideDistances.left,
              callback: function($$v) {
                _vm.$set(_vm.backgroundTemplateForm.sideDistances, "left", $$v)
              },
              expression: "backgroundTemplateForm.sideDistances.left"
            }
          }),
          _c("InputField", {
            attrs: {
              disabled: _vm.backgroundTemplateForm.useDefaultMeasurements,
              precision: 1,
              isComponentHalfSize: "",
              label: "Rechts",
              type: "number"
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.backgroundTemplateForm.sideDistances.right,
              callback: function($$v) {
                _vm.$set(_vm.backgroundTemplateForm.sideDistances, "right", $$v)
              },
              expression: "backgroundTemplateForm.sideDistances.right"
            }
          }),
          _c("InputField", {
            attrs: {
              disabled: _vm.backgroundTemplateForm.useDefaultMeasurements,
              precision: 1,
              isComponentHalfSize: "",
              label: "Oben",
              type: "number"
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.backgroundTemplateForm.sideDistances.top,
              callback: function($$v) {
                _vm.$set(_vm.backgroundTemplateForm.sideDistances, "top", $$v)
              },
              expression: "backgroundTemplateForm.sideDistances.top"
            }
          }),
          _c("InputField", {
            attrs: {
              disabled: _vm.backgroundTemplateForm.useDefaultMeasurements,
              precision: 1,
              isComponentHalfSize: "",
              label: "Unten",
              type: "number"
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.backgroundTemplateForm.sideDistances.bottom,
              callback: function($$v) {
                _vm.$set(
                  _vm.backgroundTemplateForm.sideDistances,
                  "bottom",
                  $$v
                )
              },
              expression: "backgroundTemplateForm.sideDistances.bottom"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }