<template>
  <div>
    <MailComposer
      custom
      :showExtendedActionButtons="false"
      :showDokArchiv="false"
      @callbacks="setCallbacks"
    />
  </div>
</template>
 
<script>
import { mapGetters } from 'vuex';
import MailComposer from '@/components/mailcomposer/MailComposer.vue'
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';

export default {
  name: 'AnsprechpartnerMailComposer',
  components: {
    MailComposer,
  },
  computed: {
    ...mapGetters({
      initAnsprechpartnerMail: MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_ANSPRECHPARTNER,
      savedConfiguration: MAILCOMPOSER_TYPES.GETTERS.GET_MAILCOMPOSER_CONFIG,
    }),
  },
  data() {
    return {
      callbacks: {},
    };
  },
  watch: {
    initAnsprechpartnerMail(value) {
      if (value) {
        if (!this.savedConfiguration.subject) {
          this.callbacks.setSubject(value.subject);
        }
        if (this.hasPasswort && !this.savedConfiguration.passwort) {
          this.callbacks.setPassword(value.passwort);
        }
        if (!this.savedConfiguration.getComputedText) {
          this.callbacks.setHtmlText(value.html);
        }
        if (value.attachments?.length) {
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, value.attachments)
        }
        this.callbacks.setEmailModus(value.emailModus)

        let receiver = value.participants
          .filter((p) => p.sendType != "FROM")
          .map((p) => ({
            value: p.email,
            label: p.email,
            email: p.email,
            type: p.user?.type,
          }));
        this.callbacks.setReceiver(receiver);

        let senders = value.participants.filter(p => p.sendType == 'FROM')

        if (senders.length) {
          this.callbacks.setSender(senders[0].email)
        }
      }
    },
  },
  mounted() {
    if (this.$route.meta.ansprechpartner) {
      const ansprechpartnerId = this.$route.params.id;
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_ANSPRECHPARTNER, { ansprechpartnerId });
    }
  },

  methods: {
    setCallbacks(value) {
      this.callbacks = value;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.callbacks.handleBeforeRouteLeave(to, from, next)
  }
};
</script>
