var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "no-data__container",
      class: { box__container: _vm.useBoxContainer }
    },
    [
      _vm.title
        ? _c("div", { staticClass: "box__title" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _c("div", { staticClass: "no-data__wrap" }, [
        !_vm.noIcon
          ? _c("div", { staticClass: "no-data__icon" }, [
              _c(
                "div",
                { staticClass: "no-data__icon--wrap" },
                [_c("UndrawEmpty")],
                1
              )
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "no-data__content" },
          [
            _vm._t("content", function() {
              return [_vm._v(_vm._s(_vm.content))]
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }