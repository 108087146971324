import { mapGetters, mapActions } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import MENU_TYPES from '@/store/menu/types';
import BRIDGE_TYPES from '@/store/bridge/types';

import { breadcrumbGlobalMixin } from './breadcrumb';
import { scrollGlobalMixin } from './scroll';
import { sanitize } from '@/helpers/string-helper';

const globalMixin = {};

// Computed
globalMixin.computed = {
  ...mapGetters({
    $showOnlyForTestUser: CORE_TYPES.GETTERS.IS_TEST,
    $isReadOnlyUser: CORE_TYPES.GETTERS.READ_ONLY,
    $screenWidth: CORE_TYPES.GETTERS.SCREEN_WIDTH,
    $isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
    $appNavigation: MENU_TYPES.GETTERS.CURRENT_APP_NAVIGATION,
    $isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    $isVirtualKeyboardDetected: BRIDGE_TYPES.GETTERS.IS_VIRTUAL_KEYBOARD_DETECTED,
    $hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
  }),
  $isExtraSmallScreen() {
    return this.$screenWidth === 'xs';
  },
  $isMediumScreen() {
    return this.$screenWidth === 'md';
  },
  ...breadcrumbGlobalMixin.computed,
};

// Methods
globalMixin.methods = {
  ...mapActions({
    $confirmModal: CORE_TYPES.ACTIONS.CONFIRM_MODAL,
  }),

  /**
   * It gets prop value with optional chaining.
   * Note: It must be used inside vue template, to avoid exception if you use optional chaining
   * 
   * @param {Object} obj 
   * @param {String} propChain 
   */
  $fcOptionalChain(obj, propChain) {
    if(!propChain)
      return ;

    const props = propChain.replace(/\[/gi, '.').replace(/(\]|'|")/gi, '').split('.');
    return props.reduce((result, prop) => result?.[prop], obj);
  },

  $sanitize: sanitize,

  /**
   * Breadcrumb global mixin methods
   */
  ...breadcrumbGlobalMixin.methods,

  /**
   * Scroll global mixin methods
   */
  ...scrollGlobalMixin.methods,
};

export default globalMixin;
