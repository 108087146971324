<template>
<div class="input-forms__container">
  <div class="input-forms__label-content" v-if="label">{{label}}</div>
  <div class="timepicker-container" :class="{'forms__input--half-size': this.isComponentHalfSize}">

    <BaseDropdownMenu ref="dropdown" :placement="'bottom-right'" :closeWithInnerClick="false" @onShow="onShowDropdown">
      <template v-slot:hook-target>
        <input type="text" v-model="currentTime" class="fc-input input-forms__input-field" :class="inputClass" :disabled="disabled" inputmode="numeric" @keydown="onKeyDown" @keyup="format($event)" />
        <span v-if="timeUnit && currentTime"
          class="inputfield__input-icon inputfield__input-icon--right-side">
          {{ timeUnit }}
        </span>
      </template>
      <div class="tp-selector__container" v-if="!disabled">
        <div class="tp-column__selector" ref="columnHour">
          <div class="tp-cell__selector tp-cell__hour" 
            @click="selectHour(hour)"
            v-for="hour in hours" :key="hour" 
            :class="{'tp-cell__selector--selected': isSelected(currentHour, hour)}">
            {{hour}}
          </div>
        </div>
        <div class="tp-column__selector" ref="columnMinute">
          <div class="tp-cell__selector tp-cell__minute" 
            @click="selectMinute(minute)"
            v-for="minute in minutes" :key="minute" 
            :class="{'tp-cell__selector--selected': isSelected(currentMinute, minute)}">
            {{minute}}
          </div>
        </div>
        <div v-if="withSeconds" class="tp-column__selector" ref="columnSecond">
          <div class="tp-cell__selector tp-cell__second" 
            @click="selectSecond(second)"
            v-for="second in minutes" :key="second" 
            :class="{'tp-cell__selector--selected': isSelected(currentSecond, second)}">
            {{second}}
          </div>
        </div>
      </div>
    </BaseDropdownMenu>

    <div v-if="isValidationConfigDone">
      <div class="input-forms__errors-container" :key="validation.updated" v-if="validation">
        <div class="fc-form-danger" 
          v-for="error in validation.getErrors(validationPath, validateUntouched)" 
          :key="error">
            {{ error }}
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue'

export const keysToCloseComponent = [
  'Escape', 'Esc', 27,
  'Enter', 13,
  'Tab', 9,
]

export const nullish = [undefined, null, '']

const KEYS_TO_DELETE = [
  'Backspace', 8, 
  'Delete', 46,
];

const FIRST_BUSINESS_TIME = '08:00'
const QUARTER_HOUR_MINUTES = ['00', '15', '30', '45']

import validatorComponentUtils from "@/mixins/validator/validator-component-utils";

export default {
  mixins: [validatorComponentUtils],
  components: {
    BaseDropdownMenu,
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String
    },
    timeUnit: {
      type: String
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withSeconds: {
      type: Boolean,
      default: false,
    },
    showQuarterHourAsMinute: {
      type: Boolean,
      default: true
    },
    defaultPopUpTime: {
      type: [Date, String],
      default: () => new Date()
    },
    ignoreDefaultPopUpTime: {
      type: Boolean,
      default: false,
    },
    validateUntouched: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    timePattern() {
      if (this.withSeconds)
        return /^([01]?[0-9]|2[0-3])[\D]+([0-5][0-9])[\D]+([0-5][0-9])$/i;
      return /^([01]?[0-9]|2[0-3])[\D]+([0-5][0-9])$/i;
    },
    hours() {
      return this.createNumberList(24)
    },
    minutes() {
      return this.showQuarterHourAsMinute ? QUARTER_HOUR_MINUTES : this.createNumberList(60)
    },
    currentTime: {
      get() {
        if (nullish.includes(this.currentHour) || nullish.includes(this.currentMinute)) {
          return null
        }
        let result = `${this.currentHour}:${this.currentMinute}`;
        if (this.withSeconds)
          result += `:${this.currentSecond}`
        return result;
      },
      set(time) {
        this.copyTime(time)
      }
    },
    defaultPopUpHour() {
      if (!this.defaultPopUpTime) {
        return FIRST_BUSINESS_TIME.split(':')[0]
      }
      if (this.defaultPopUpTime?.getHours) {
        return String(this.defaultPopUpTime.getHours()).padStart(2, '0');
      }
      if (this.defaultPopUpTime?.includes(':')) {
        this.defaultPopUpTime.split(':')[0]
      }
      return this.defaultPopUpTime
    },
    defaultPopUpMinute() {
      if (!this.defaultPopUpTime) {
        return FIRST_BUSINESS_TIME.split(':')[1]
      }
      if (this.defaultPopUpTime?.getMinutes) {
        let minutes = this.defaultPopUpTime.getMinutes();
        minutes = this.showQuarterHourAsMinute ? (Math.round(minutes /15) * 15) % 60 : minutes
        return String(minutes).padStart(2, '0');
      }
      if (this.defaultPopUpTime?.includes(':')) {
        this.defaultPopUpTime.split(':')[1]
      }
      return this.defaultPopUpTime
    },
    inputClass() {
      return {
        'fc-form-danger': this.isValidationConfigDone && this.validation.isInvalid(this.validationPath, this.validateUntouched),
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.copyTime(newValue)
        }
      }
    }
  },
  data() {
    const numbers = [];
    for (let i = 0; i < 60; i++) {
      numbers.push((i < 10 ? "0" : "") + i);
    }
    return {
      currentHour: "",
      currentMinute: "",
      currentSecond: "",
    }
  },
  mounted() {
    this.firstValidation = true;
    this.copyTime(this.value)
  },
  methods: {
    onShowDropdown() {
      if(!this.disabled) {
        if (!this.currentTime && !this.ignoreDefaultPopUpTime) {
          this.selectHour(this.defaultPopUpHour)
          this.selectMinute(this.defaultPopUpMinute)
          if (this.withSeconds) {
            this.selectSecond('00')
          }
        }

        const elHour =  this.$refs.columnHour // to reduce the scope of query selector
        const elMinute = this.$refs.columnMinute // to reduce the scope of query selector
        setTimeout(() => {
          const offsetTopHour = elHour.querySelector('.tp-cell__hour.tp-cell__selector--selected')?.offsetTop
          const offsetTopMinute = elMinute.querySelector('.tp-cell__minute.tp-cell__selector--selected')?.offsetTop
          this.$refs.columnHour.scrollTop = offsetTopHour
          this.$refs.columnMinute.scrollTop = offsetTopMinute
        }, 0)
      }
    },
    onKeyDown(event) {
      const keyPressed = event.key || event.keyCode;

      if (keysToCloseComponent.indexOf(keyPressed) > -1) {
        this.$refs.dropdown.hideContainer()
      }
    },
    format(event) {
      if (!event.target.value) {
        this.resetTime()
        this.emitTime()
      }
      const keyPressed = event.key || event.keyCode;
      if(KEYS_TO_DELETE.indexOf(keyPressed) > -1) return;

      const { value } = event.target;

      if(value.match(/^[0-9]{2}$/)) {
        event.target.value = `${value}:`;
      }
      if(value.match(/^[0-9]{2}:[0-9]{2}$/) && this.withSeconds) {
        event.target.value = `${value}:`;
      }
      if (this.timePattern.test(event.target.value)) {
        this.copyTime(event.target.value)
        this.emitTime()
      }
    },
    resetTime() {
      this.currentHour = null
      this.currentMinute = null
      this.currentSecond = null
    },
    createNumberList(amount) {
      return Array(amount).fill().map((element, index) => ('00' + index).slice(-2))
    },
    isSelected(currentTime, time) {
      return +currentTime === +time
    },
    copyTime(value) {
      const result = this.timePattern.exec(value)

      if (result) {
        this.currentHour = result[1]
        this.currentMinute = result[2]
        if (this.withSeconds)
          this.currentSecond = result[3]
      } 

      this.runValidation();
    },
    selectHour(hour) {
      this.currentHour = hour;
      this.emitTime()
    },
    selectMinute(minute) {
      this.currentMinute = minute;
      this.emitTime()
    },
    selectSecond(second) {
      this.currentSecond = second;
      this.emitTime()
    },
    emitTime() {
      this.$emit('input', this.currentTime)
      this.$emit('change', this.currentTime)
    },
    runValidation() {
      this.$runValidator(this.value, this.firstValidation)
      this.firstValidation = false
    },
  }
}
</script>>
<style scoped>
.tp-selector__container {
  display: flex;
  position: relative;
}
.tp-column__selector {
  flex: 1 1 50%;
  height: 250px;
  overflow-y: auto;
  padding-right: 16px;
}

.tp-cell__selector {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inputfield__input-icon--right-side {
  padding: 0 1rem;
  right: 0;
  padding-left: 4px;
}

.inputfield__input-icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
}

.tp-cell__selector--selected {
  font-weight: bold;
  color: var(--color-active);
}

.tp-cell__selector:hover {
  background-color: rgba(0,0,0,.1);
}

.timepicker-container {
  display: flex;
  /* padding-bottom: 16px; */
}
@media (max-width: 1024px) {
  .timepicker-container {
    display: block;
  }
}

@media (hover: hover) {
  .tp-column__selector {
    overflow: hidden;
  }

  .tp-column__selector:hover {
    overflow-y: auto;
  }
}

</style>