<template>
    <div>
        <div class="box__container">
            <Table
                v-if="positions && positions.length"
                :title="selectedPositionLabel"
                :headers="headers"
                :rows="rows"
                rowId="bgsNr"
                @action-EDIT="edit"
                @click-kundenname="openCustomerNewTab"
            >
                <template #aktiv="row">
                    <InputToggleSwitch
                        :value="row.aktiv" 
                        @input="updatePosition(row, { aktiv: $event})"
                    />
                </template>
                <template #mailversand="row">
                    <InputToggleSwitch v-if="row.hasMailversand"
                        :value="row.mailversand" 
                        :disabled="!row.aktiv"
                        @input="updatePosition(row, { mailversand: $event})"
                    />
                </template>
                <template #positionName="row">
                    <div class="font-bold">{{ row.positionName }} </div>
                </template>
                <template #tauschIn="row">
                    <div class="font-bold">{{ row.tauschIn }} </div>
                </template>
            </Table>
            <NoData v-else :title="selectedPositionLabel" noIcon />
        </div>

        <div>
            <BaseModal ref="editModal"
                @onCloseButton="closeEditModal"
                @onCancelButton="closeEditModal"
                @onConfirmButton="updateEditPosition(); closeEditModal()"
            >
                <template v-slot:modalTitle>
                    <div>{{editPosition.kundenname}}, Kontonr: {{ editPosition.depotNr }}</div>
                </template>
                <div>
                    <InputToggleSwitch
                        label="Aktiv"
                        v-model="editPosition.aktiv" 
                    />
                    <InputToggleSwitch v-if="editPosition.hasMailversand"
                        label="Mailversand"
                        v-model="editPosition.mailversand" 
                    />
                    <InputField 
                        type="number" 
                        label="Betrag"
                        v-model="editPosition.betrag" 
                        :disabled="betragDisabled(editPosition)"
                    />
                    <ComboBox
                        v-model="editPosition.betragModus"
                        :values="betragModusValues"
                    />
                    <div v-if="isVerkauf">
                        <DatePickerField 
                            label="Termin"
                            isValueAsString
                            showSofortOption
                            v-model="editPosition.terminVerkauf"
                        />
                    </div>
                    <div v-else>
                        <DatePickerField
                            label="Termin"
                            isValueAsString
                            showSofortOption
                            v-model="editPosition.terminTausch"
                        />
                        <InputField v-if="editPosition.aaModus === 'INPUT_AA' || editPosition.aaModus === 'INPUT_BONUS'"
                            labelClass="font-bold"
                            type="percent" 
                            :label="editPosition.aaLabel"
                            v-model="editPosition.inputAA" 
                        />
                        <ComboBox v-else-if="editPosition.aaModus === 'COMBO_BONUS' || editPosition.aaModus === 'COMBO_RABATT'"
                            :label="editPosition.aaLabel"
                            v-model="editPosition.inputAA" 
                            :values="!editPosition.lagerstelle ? [] : aaComboboxValues[editPosition.lagerstelle]"
                            :sortComboboxValues="false"
                            labelClass="font-bold"
                        />
                    </div>
                    <InputField 
                        label="Bemerkung"
                        v-model="editPosition.bemerkung" 
                    />
                    <div>{{isVerkauf ? 'Verkauf von' : 'Umschichtung von'}}: <span class="font-bold">{{ editPosition.wertpapiername }}</span></div>
                    <div>Anteile: {{ formatNumber(editPosition.anteile, 4) }}</div>
                    <div>Wert: {{ formatNumber(editPosition.gesamterWert, 2) }} €</div>
                    <div v-if="!isVerkauf">
                        <div class="mb-2">Umschichtung zu:</div>
                        <ComboBox
                            label="Gesellschaft"
                            v-model="editPosition.tauschInGesellschaft"
                            :values="!editPosition.lagerstelle ? [] : tauschInGesellschaftValues[editPosition.lagerstelle] || []"
                        />
                        <ComboBox
                            label="Wertpapier"
                            v-model="editPosition.tauschInISIN"
                            :values="!editPosition.lagerstelle ? [] : tauschInWertpapierValues[editPosition.lagerstelle] || []"
                        />
                    </div>
                </div>
            </BaseModal>
        </div>
    </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import WERTPAPIERE_ALLE_KUNDEN_TYPES from '@/store/wertpapiereAlleKunden/types';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import mixin from './wertpapiere-mixin.js';
import {formatNumber} from '@/helpers/number-formatter.js';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PercentageColumn, DateColumn, SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import BaseModal from '@/components/core/BaseModal.vue';

export default {
    mixins: [mixin],
    components: {
        InputField,
        InputToggleSwitch,
        ComboBox,
        DatePickerField,
        Table,
        GhostLoading,
        NoData,
        BaseModal,
    },
    data() {
        return {
            editPosition: {},
        }
    },
    mounted() {
        if (this.dataHasChanged) {
            this.$store.commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.RESET_POSITIONS);
            this.$store.dispatch(WERTPAPIERE_ALLE_KUNDEN_TYPES.ACTIONS.LIST_POSITIONS, this.paramModus);
        }
    },
    computed: {
        tauschInWertpapierValues() {
            return this.comboboxValues?.tauschInWertpapierValues || {};
        },
        tauschInGesellschaftValues() {
            return this.comboboxValues?.tauschInGesellschaftValues || {};
        },
        aaComboboxValues() {
            return this.comboboxValues?.aaComboboxValues || {};
        },
        headers() {
            const headers = {
                lockedLeft: [
                    SlotColumn('aktiv', 'Aktiv').makeAlwaysVisible(),
                    SlotColumn('mailversand', 'Mailversand').makeAlwaysVisible(),
                    TextColumn('kundenNr', 'Kundennummer'),
                    TextColumn('kundenname', 'Name').makeAlwaysVisible().makeLink(),
                ],
                lockedRight: [
                    ActionColumn('actions'),
                ],
            };
            const center = [
                TextColumn('depotNr', 'Kontonr.'),
                TextColumn('betragStr', 'Betrag'),
            ];
            if (this.isVerkauf) {
                center.push(DateColumn('terminVerkauf', 'Termin'));
            } else {
                center.push(DateColumn('terminTausch', 'Termin'));
                center.push(PercentageColumn('inputAA', this.rows?.[0].aaLabel || 'Ausbageaufschlag'));
            }
            center.push(TextColumn('bemerkung', 'Bemerkung'));
            center.push(SlotColumn('positionName', this.isVerkauf ? 'Verkauf von' : 'Umschichtung von'));
            if (!this.isVerkauf) {
                center.push(SlotColumn('tauschIn', 'Umschichtung zu'));
            }
            headers.center = center;
            return headers;
        },
        rows() {
            return this.positions.map(row => ({
                ...row,
                betragStr: row.betragModus === 'VALUE_BETRAG_MODUS_ALLE_ANTEILE' ? 'Alle Anteile' 
                    : (!row.betrag ? '' 
                        : row.betragModus === 'PROZENT' ? formatNumber(row.betrag) + '%' : formatNumber(row.betrag, 2) + '€'),
                positionName: `${row.wertpapiername}, Anteile: ${formatNumber(row.anteile, 4)}, Wert: ${formatNumber(row.gesamterWert, 2)} €`,
                tauschIn:  this.isVerkauf || (!row.tauschInISIN && row.tauschInGesellschaft === 'WERTPAPIERE' ) ? '' 
                    : `Geellschaft: ${row.tauschInGesellschaft || ''}, Wertpapier: ${row.tauschInWertpapiername || ''}`,
                actions: [
                    SimpleAction("EDIT", 'PhPencil', "Bearbeiten"),
                ]
            }))
        },
    },
    methods: {
        updateEditPosition() {
            if (Object.keys(this.editPosition).length) {
                if (this.editPosition.tauschInISIN && this.editPosition.lagerstelle) {
                    const test = this.tauschInWertpapierValues[this.editPosition.lagerstelle].find(item => item.value === this.editPosition.tauschInISIN)?.label || '';
                    this.editPosition.tauschInWertpapiername = this.tauschInWertpapierValues[this.editPosition.lagerstelle].find(item => item.value === this.editPosition.tauschInISIN)?.label || '';
                }
                this.$store.commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.UPDATE_POSITION_FROM_MODAL, this.editPosition);
            }
        },
        updatePosition(pos, value = {}) {
            if (pos) {
                this.$store.commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.UPDATE_POSITION_FROM_MODAL, Object.assign(pos, value));
            }
        },
        formatNumber(value, precision) {
            if (value) {
                return formatNumber(value, precision);
            }
        },
        betragDisabled(pos) {
            return pos?.betragModus === 'VALUE_BETRAG_MODUS_ALLE_ANTEILE';
        },
        edit(pos) {
            if (pos) {
                this.editPosition = pos;
                this.$refs.editModal?.open();
            }
        },
        closeEditModal() {
            this.editPosition = {};
            this.$refs.editModal?.close();
        },
    }
}

</script>

<style>

</style>