var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderActionCard", {
        attrs: {
          title: "Übersicht Steuer- und Verlustverrechnungstöpfe",
          showHeaderCard: true
        },
        scopedSlots: _vm._u([
          {
            key: "actionButtons",
            fn: function() {
              return [
                _c("BaseCollapsable", {
                  attrs: { showSection: _vm.showExpanded },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [_vm._v(" Hinweise: ")]
                      },
                      proxy: true
                    },
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c(
                            "ul",
                            _vm._l(_vm.hinweise, function(hinweis, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("li", [
                                      _c("span", { staticClass: "font-bold" }, [
                                        _vm._v(_vm._s(hinweis.label) + ": ")
                                      ]),
                                      _c("span", [_vm._v(_vm._s(hinweis.text))])
                                    ])
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      !_vm.isCustomer
        ? _c(
            "BaseFilter",
            {
              attrs: {
                filterId: "steuerVerlustSearchAdvanced",
                metadata: _vm.searchMenu,
                configFilter: _vm.configFilter,
                hasSmartSearch: "",
                defaultOptions: _vm.defaultOptions
              },
              on: {
                onFilter: function($event) {
                  return _vm.handleSearch($event)
                },
                onFilterZurucksetzen: function($event) {
                  return _vm.$emit("onFilterZurucksetzen")
                }
              }
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows && _vm.rows.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20,
                      rowId: "steuerVerlust"
                    },
                    on: { "click-kundennr": _vm.openCustomerNewTab }
                  })
                ],
                1
              )
            : _c("NoData", { attrs: { content: "Keine Informationen" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }