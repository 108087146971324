<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-xl-8">
              <ComboBox
                label="Wählen Sie die Positionen zum Verkauf aus:"
                v-model="depotPosition" 
                :values="config.valuesForDepot"
                  @input="onChangeInput($event)"
                >
              </ComboBox>
            </div>
           
          </div>
        </div>
      </div>
    </div>

    <div class="input-forms__input-container">
        <Table v-if="rows.length"
            tableId="46c30c0a-aa2b-4885-b3ef-cfedaeec59tr"
            :headers="headers"
            :rows="rows"
            rowId="isin"
            hidePagination
            @action-DELETE="openModalDeleteFond"
        >

            <template v-slot:betragEntnahmeplan="row">
                <InputField 
                    type="currency" 
                    :value="row.betragEntnahmeplan"
                    :id="'betragEntnahmeplan' + row.index"
                    @input="onChangeInputField($event, row, 'betragEntnahmeplan')"
                />
        
            </template>

            <template v-slot:frequenz="row">
                <ComboBox
                    :value="row.frequenz"
                    :id="'frequenz' + row.index"
                    :values="frequenzValues"
                    @input="updatePositionsData($event, row, 'frequenz')"
                />
            </template>

            <template v-slot:startdatum="row">
                <DatePickerField
                    :value="row.startdatum"
                    :id="'startdatum' + row.index"
                    @input="onChangeInputField($event, row, 'startdatum')"
                />
            </template>
        </Table>

  <DeletePositionModal ref="deleteModal" :position="positionToDelete"
    @delete="doDeletePosition()"></DeletePositionModal>

    <BaseModal ref="fehlerModal" labelButtonCancel="Ok" :showConfirmButton="false">
        <template v-slot:modalTitle>
          <i class="las la-exclamation-triangle mr-2" style="color: orangered"></i>
          Fehler
        </template>
        <div class="var(--color-danger) font-bold">
          <hr>
          Es ist möglich nur 1 Position einzutragen. Wenn Sie die Position bearbeiten möchten, löschen Sie bitte zuerst die vorhandene Position.
        </div>
      </BaseModal>

    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from '@/store/antrag/types';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import BaseButton from '@/components/core/BaseButton.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SlotColumn, SimpleAction} from "@/components/table2/table_util.js";
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import { mapGetters } from 'vuex';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";

export default {
  mixins: [antragNavigationMixin, antragMixin, validator],
  props: {
    antragData: {
    },
    config: {
    },
    antragTyp: {
    },
    antragId: {
    },
    comboboxSelections: {
    },
    categoryId: {
    },
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false
    },
    warnings: {
    },
  },
  components: {
    BaseButton,
    BaseModal,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    InputRadioBoxGroup,
    DatePickerField,
    BaseModal,
  },
  validators: {
      depotPosition: [required('Bitte wählen Sie den Fonds aus', {forceTouch: true})],
  },
  data() {
    return {
        headers: {
            lockedLeft: [
                TextColumn("isin", "ISIN"),
                TextColumn("fondsname", "Fondsname"),
            ],
            center: [
                SlotColumn("betragEntnahmeplan", "Betrag", 100),
                SlotColumn("frequenz", "Durchführungstermin", 100),
                SlotColumn("startdatum", "beginnend", 100),
            ],
            lockedRight: [
                ActionColumn("actions", ""),
            ],
        },
        depotPosition: '',
        disabled: false,
        positionToDelete : {},
        frequenzValues: [   
            {label: 'monatlich', value: 'm'},
            {label: 'vierteljährlich', value:'vj'},
            {label: 'halbjährlich', value:'h'},
            {label: 'jährlich', value: 'j'}
        ],
    }
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA
    }),
    rows() {
        const actions = [
            SimpleAction("DELETE", "PhTrash", "Löschen"),
        ];
        return (this.positions || []).map((row, index) => ({
            ...row,
            index,
            lagerstelle: 'CAPITALBANK',
            fondsname: this.getFondsName(row, this.positionInfo),
            actions,
        }));
    },
    positions() {
      if (this.positionsAll && this.antragId && this.categoryId 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.categoryId]) {
        return this.positionsAll[this.antragId][this.categoryId]
      }
    },
    positionData() {
      if (this.positionDataAll && this.antragId && this.categoryId 
          && this.positionDataAll[this.antragId]
          && this.positionDataAll[this.antragId][this.categoryId]) {
        return this.positionDataAll[this.antragId][this.categoryId]
      }
    },
  },
  mounted(){
    if(this.positionsAll && this.antragId && this.categoryId 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.categoryId]){
            this.depotPosition = this.positionsAll[this.antragId][this.categoryId][0].isin
    }
    
  },
  methods: {
    addPosition() {
      const payload = {
          antragTyp: this.antragTyp || null,
          antragId: this.antragId || null,
          categoryId: this.categoryId,
          positionInfo: this.config.positionInfo.filter(info => info.isin == this.depotPosition),
          saveType: '',
          checkPositions: {
            lagerstelle: 'CAPITALBANK',
            isins: {
              [this.depotPosition]: this.categoryId
            }
          }
        }
      this.$store.dispatch(FONDSFINDER_TYPES.ACTIONS.TAKE_SELECTED_ISINS, payload)
    },
    onChangeInput($event){
      if(this.rows && this.rows.length>=1){
         this.$refs.fehlerModal.open()
      }else{
        this.addPosition();
        this.depotPosition = $event
      }
     
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    },
    updatePositionsData($event,position, field){
         const positionChange = { [field]: $event };
        if(field == 'frequenz'){
        this.commitUpdatePositionsFromEvent(positionChange, position.isin, 'ENTNAHMEPLAN');
        const payload = {
          id: this.antragId,
          categoryId: 'ENTNAHMEPLAN',
          positionData: {'frequenz':$event},
        };
        
        this.$store.commit(
          ANTRAG_TYPES.MUTATIONS.UPDATE_POSITION_DATA,
          payload
        );
        }
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open()
    },
    doDeletePosition() {
      this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
      this.positionToDelete = null;
    }
  }
}
</script>

<style scoped>
  .input-forms__label-content--bigger {
    font-size: 1.5rem;
  }
  .input-forms__label-content--bigger > i {
    font-size: 2rem;
  }
</style>