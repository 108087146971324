var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseButton",
        {
          attrs: { isClear: "", disabled: _vm.disabled },
          on: {
            click: function($event) {
              _vm.isLayoutVisible = !_vm.isLayoutVisible
            }
          }
        },
        [
          _vm._v(" Layout "),
          !_vm.value ? _c("PhCaretDown", { attrs: { size: "20" } }) : _vm._e(),
          _vm.value ? _c("PhCaretUp", { attrs: { size: "20" } }) : _vm._e()
        ],
        1
      ),
      _vm.isLayoutVisible && _vm.editor ? _c("div", [_vm._m(0)]) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-4 col-md-2 col-lg-2" }, [
        _vm._v(" < ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }