<template>
  <BaseModal 
    ref="modal" 
    modalTitle="Honorar löschen" 
    :showDefaultButtons="false"
    @onCloseButton="emitCancelled()"
    @onEscButton="emitCancelled()"
  >
    <p>Das Honorar/Serviceleistung wird gelöscht! Wollen Sie das Datum der nächsten zu generierenden Rechnung zurücksetzen?</p>
    <p>Damit würden dieses und eventuell folgende Honorare beim nächsten Generierungslauf neu erstellt.</p>

    <BaseButton @click="emitConfirmed(true); close()">Datum zurücksetzen</BaseButton>
    <BaseButton @click="emitConfirmed(false); close()">Datum nicht zurücksetzen</BaseButton>    
    <BaseButton isSecondary @click="emitCancelled(); close()" class="mr-3">Abbrechen</BaseButton>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  methods: {
    open() {
      this.$refs.modal?.open();
    },
    close() {
      this.$refs.modal?.close();
    },
    emitCancelled() {
      this.$emit('cancelled');
    },
    emitConfirmed(resetInvoiceDate) {
      this.$emit('confirmed', resetInvoiceDate)
    },
  },
  components: {
    BaseModal,
    BaseButton,
  },
}
</script>
