import CORE_TYPES from '@/store/core/types';
import { VIEW_ROLES } from '@/router/roles';

/**
 * adds a post save listener by parameter id
 * 
 * Example:
 * > [parameter id] = function -> void
 */
export default {

  ['ADD_PARAMETER>>FC_COLOR_SCHEME']({ dispatch }, { content: value }) {
    dispatch(CORE_TYPES.ACTIONS.APPLY_CONFIGURED_COLOR_SCHEME, value);
  },

  ['ADD_PARAMETER>>FC_COLOR_SCHEME_CUSTOMER']({ dispatch, getters }, { content: value }) {
    if (getters[CORE_TYPES.GETTERS.HAS_ROLES]([VIEW_ROLES.VIEW_CUSTOMER_ONLY])) {
      dispatch(CORE_TYPES.ACTIONS.APPLY_CONFIGURED_COLOR_SCHEME, value);
    }
  },

}
