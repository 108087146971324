var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: { modalTitle: "Domain", showConfirmButton: false },
          on: {
            onCloseButton: function($event) {
              return _vm.close()
            },
            close: function($event) {
              return _vm.close()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$refs.modalAdd.open()
                        }
                      }
                    },
                    [_vm._v("Domain hinzufügen")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.loading && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10
                },
                on: { "action-DELETE": _vm.deleteDomain }
              })
            : _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("NoData", { attrs: { noIcon: "" } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "modalAdd",
          attrs: { modalTitle: "Domain hinzufügen", autoClose: false },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveDomain()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "URL" },
            model: {
              value: _vm.domainForm.domainUrl,
              callback: function($$v) {
                _vm.$set(_vm.domainForm, "domainUrl", $$v)
              },
              expression: "domainForm.domainUrl"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Verwendung",
              values: _vm.destinationValues,
              sortComboboxValues: false
            },
            model: {
              value: _vm.domainForm.destination,
              callback: function($$v) {
                _vm.$set(_vm.domainForm, "destination", $$v)
              },
              expression: "domainForm.destination"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }