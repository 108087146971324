export class MyGoalCalculationAbstract {
    id;
    myGoal;

    // Set the linked Goal
    setGoal(goal){
        this.myGoal = goal;
    }

    setId(id) {}

    getId() {}
}
