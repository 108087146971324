<template>
  <div class="grid" v-if="inputs" style="width: 100%">
    <template class="row" v-for="input in inputs">
      <label v-if="(!input.visible || evaluateUploadExpression(input.visible)) && (!input.ignore || !evaluateUploadExpression(input.ignore))"
            :class="{'invalid': input.valid && !evaluateUploadExpression(input.valid)}" :key="input.key ? input.key : input.label">
        
        <div v-if="input.type=='LABEL'" class="label-item" > 
          <span >{{input.label}}</span>
        </div>
        <InputCheckBoxItem v-if="input.type=='BOOLEAN'" 
          :value="value[input.key]" 
          isComponentHalfSize
          :disabled="input.disabled && input.disabled.value==true" 
          @input="emitChange(input.key, $event)" 
          :label="input.label" style="width: 100%"/>
        <InputField v-else-if="input.type=='STRING'" 
          :value="value[input.key]"
          isComponentHalfSize
          :disabled="input.disabled && input.disabled.value==true" 
          @change="emitChange(input.key, $event)" 
          :label="input.label" style="width: 100%"/>
        <InputField v-else-if="input.type=='INTEGER'" 
          type="number" 
          :value="value[input.key]" 
          isComponentHalfSize
          :disabled="input.disabled && input.disabled.value==true" 
          @change="emitInteger(input.key, $event)" 
          :label="input.label" style="width: 100%"/>
        <DatePickerField v-else-if="input.type=='DATE'" 
          :value="value[input.key]" 
          @change="emitChange(input.key, $event)" 
          :label="input.label" style="width: 100%"/>
        <ComboBox v-else-if="input.type=='COMBOBOX'" 
          :value="value[input.key]" 
          isComponentHalfSize
          :disabled="input.disabled && input.disabled.value==true" 
          @change="emitChange(input.key, $event)"
          :values="input.options" 
          :label="input.label" style="width: 100%" :customizable="true" />
        <InputRadioBoxGroup v-else-if="input.type=='RADIO'" 
          :value="value[input.key]" 
          isComponentHalfSize
          :disabled="input.disabled && input.disabled.value==true" 
          @input="emitChange(input.key, $event)" 
          :values="input.options" 
          :label="input.label" 
          style="width: 100%"/>
        <InputTextArea v-else-if="input.type=='TEXT'" 
          :value="value[input.key]" 
          isComponentHalfSize
          :disabled="input.disabled && input.disabled.value==true" 
          @input="emitChange(input.key, $event)" 
          :label="input.label" style="width: 100%"/>
      </label>
    </template>
  </div>
</template>
<script>
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import expressionEvaluator from '@/mixins/expression/mixin.js';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';


export default {
  mixins: [expressionEvaluator],
  components: {
    DatePickerField,
    ComboBox,
    InputField,
    InputRadioBoxGroup,
    InputCheckBoxItem,
    InputTextArea
  },
  props: {
    inputs: {
      required: true,
      type: Array,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    value() {
      //TODO do this in parent component if you want to save stuff
      const formData = {}
      this.inputs && this.inputs.forEach(input => {
      if (input.type == 'DATE') {
        const date = new Date(input.value)
        formData[input.key] = date
      }
      else if (input.type != 'LABEL')
        formData[input.key] = input.value
      })
      return formData;
    },
  },
  methods: {
    evaluateUploadExpression(expression) {
      return this.evaluateExpression(this.value, expression);
    },
    emitInteger(key, value) {
      this.$emit("input", {
        ...this.value,
        [key]: parseInt(value) || 0,
      });
    },
    emitChange(key, value) {
      this.$emit("input", {
        ...this.value,
        [key]: value,
      });
    },
  },
}
</script>
<style scoped>
.invalid {
  color: var(--color-danger);
}
.input-forms__container  {
  margin-top: 8px;
}

label {
  margin-top: 8px;
}

.label-item {
  margin-top: 8px;
   margin-bottom: 8px;
}

</style>
