var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vertical-step__icon-container" },
    [
      _vm.isCurrent && _vm.collapsed
        ? [
            _c("ph-circle", {
              staticClass: "color-primary vertical-stop__icon",
              attrs: { weight: "fill", size: 16 }
            })
          ]
        : [
            _vm.stepperStatus === "default"
              ? _c("ph-circle", {
                  staticClass: "color-primary vertical-stop__icon",
                  attrs: { size: 16 }
                })
              : _vm._e(),
            _vm.stepperStatus === "success"
              ? _c("ph-check-circle", {
                  staticClass: "color-success vertical-stop__icon",
                  attrs: { size: 16 }
                })
              : _vm._e(),
            _vm.stepperStatus === "warning"
              ? _c("ph-warning-circle", {
                  staticClass: "color-danger vertical-stop__icon",
                  attrs: { size: 16 }
                })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }