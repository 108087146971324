<template>
  <div>
    <div class="box__container">
      <div class="box__title">Provisionserstattung</div>

      <InputField label="Bezeichnung der Provisionserstattung" 
        v-model="feePropertiesForm.buchungstextProverstattung" isComponentHalfSize 
        @change="dataChanged()" />

      <div>
        <div class="box__title">Abschlussprovision</div>

        <InputField label="Anteil der Erstattung an den Kunden" type="percent"
          v-model="feePropertiesForm.abschlussErsattungssatz" isComponentHalfSize 
          @change="dataChanged()" />

        <template v-if="istHonorarberater">
          <InputRadioBoxGroup label="Erstattung in Relation zur Courtage des/der" 
            v-model="feePropertiesForm.abschlussErsattungstyp" :values="feePropertiesCombos.erstattungsType" 
            @input="dataChanged()" />
        </template>

        <InputRadioBoxGroup title="Erstattung der erhaltenen Courtage" 
          v-model="feePropertiesForm.gegenrechnungAbschluss" :values="feePropertiesCombos.gegenrechnungValues" 
          @input="dataChanged()" />
      </div>

      <div>
        <div class="box__title">Abschlussfolgeprovision</div>

        <InputField label="Anteil der Erstattung an den Kunden" type="percent"
          v-model="feePropertiesForm.bestandErsattungssatz" isComponentHalfSize 
          @change="dataChanged()" />

        <template v-if="istHonorarberater || istErstattungStruktur">
          <InputRadioBoxGroup title="Erstattung in Relation zur Courtage des/der" 
            v-model="feePropertiesForm.bestandErsattungstyp" :values="feePropertiesCombos.erstattungsType" 
            @input="dataChanged()" />
        </template>

        <InputRadioBoxGroup title="Erstattung der erhaltenen Courtage" 
          v-model="feePropertiesForm.gegenrechnungBestand" :values="feePropertiesCombos.gegenrechnungValues" 
          @input="dataChanged()" />
      </div>

      <div class="color-danger text-italic">
        <p>Sollen dem Kunden die Provisionen direkt erstattet werden, so muss 'unmittelbar nach Gutschrift der Courtage' gewählt werden. Sie werden dann dem Kunden unmittelbar nach der Courtageabrechnung überwiesen.</p>
        <p><span class="text-underline">Achtung:</span> Die Übeweisung erfolgt ohne eine weitere Bestätigung von Ihrer Seite.</p>
      </div>

      <InputToggleSwitch label="Bei unmittelbarer Erstattung der Courtage dem Kunden einen Beleg zusenden (sofern Mailadresse vorhanden und Versand aktiviert)" inLineLabel
        v-model="feePropertiesForm.erstattungDirektversand" 
        @input="dataChanged()" />
      
      <InputToggleSwitch label="Zusammenfassung der Einzelpositionen" inLineLabel
        v-model="feePropertiesForm.zusammenfassungErstattung" 
        @input="dataChanged()" />
    </div>
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import feePropertiesStepMixin from '../fee-properties-step-mixin';

export default {
  mixins: [feePropertiesStepMixin],
  data() {
    return {
      feePropertiesForm: {
        buchungstextProverstattung: null,
        abschlussErsattungssatz: null,
        abschlussErsattungstyp: null,
        gegenrechnungAbschluss: null,
        bestandErsattungssatz: null,
        bestandErsattungstyp: null,
        gegenrechnungBestand: null,
        erstattungDirektversand: null,
        zusammenfassungErstattung: null,
      },
    };
  },
  components: {
    InputField,
    InputRadioBoxGroup,
    InputToggleSwitch,
  },
}
</script>
