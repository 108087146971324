<template>
  <TeleportToBody>
    <div v-if="isOffline" class="offline__container">
      <div class="offline__message">
        <div class="offline__message--icon"><PhWifiX :size="36" /></div>
        <div class="offline__message--content">
          <div class="offline__message--title">Sie sind offline</div>
          <div class="offline__message--text">Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut</div>
        </div>
      </div>
    </div>
  </TeleportToBody>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import { PhWifiX } from 'phosphor-vue';
import TeleportToBody from '@/components/core/teleport/TeleportToBody.vue';

const IS_OFFLINE_CLASS = 'is-offline';

export default {
  name: 'Offline',
  components: {
    PhWifiX,
    TeleportToBody,
  },
  data() {
    return {
      offlineHandlerId: null,
      onlineHandlerId: null,
    };
  },
  computed: {
    ...mapGetters({
      isInternetActive: CORE_TYPES.GETTERS.IS_INTERNET_ACTIVE,
    }),
    isOffline() {
      return this.isInternetActive !== true;
    },
  },
  watch: {
    isInternetActive(value) {
      if (value) {
        this.prepareOnline();
      } else {
        this.prepareOffline();
      }
    },
  },
  methods: {
    handleOffline() {
      cancelAnimationFrame(this.onlineHandlerId);

      cancelAnimationFrame(this.offlineHandlerId);
      this.offlineHandlerId = requestAnimationFrame(() => this.$store.commit(CORE_TYPES.MUTATIONS.SET_INTERNET_ACTIVE, false));
    },
    prepareOffline() {
      document.documentElement.classList.add(IS_OFFLINE_CLASS);
      document.addEventListener('click', this.preventEvent, true);
      document.addEventListener('keypress', this.preventEvent, true);
      document.addEventListener('keydown', this.preventEvent, true);
      document.addEventListener('keyup', this.preventEvent, true);

      // blur from any focused element
      document.activeElement?.blur?.();
    },
    handleOnline() {
      cancelAnimationFrame(this.offlineHandlerId);

      cancelAnimationFrame(this.onlineHandlerId);
      this.onlineHandlerId = requestAnimationFrame(() => this.$store.commit(CORE_TYPES.MUTATIONS.SET_INTERNET_ACTIVE, true));
    },
    prepareOnline() {
      document.documentElement.classList.remove(IS_OFFLINE_CLASS);
      document.removeEventListener('click', this.preventEvent, true);
      document.removeEventListener('keypress', this.preventEvent, true);
      document.removeEventListener('keydown', this.preventEvent, true);
      document.removeEventListener('keyup', this.preventEvent, true);
    },
    preventEvent(event) {
      event.preventDefault();
      event.stopPropagation();
    },
  },
  mounted() {
    this.$store.commit(CORE_TYPES.MUTATIONS.SET_INTERNET_ACTIVE, navigator.onLine);

    window.addEventListener('offline', this.handleOffline);
    window.addEventListener('online', this.handleOnline);
  },
  destroyed() {
    window.removeEventListener('offline', this.handleOffline);
    window.removeEventListener('online', this.handleOnline);
  },
}
</script>

<style lang="scss" scoped>
.offline__container {
  color: var(--color-text);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;

  &::before {
    background: var(--color-background);
    content: "";
    display: block;
    opacity: .8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .offline__message {
    background-color: var(--color-danger-background);
    border-radius: 8px;
    box-shadow: 2px 2px 8px -4px rgba(0,0,0,.5);
    color: var(--color-danger);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    padding: 1rem 1.75rem;
    pointer-events: all !important;

    .offline__message--icon {
      flex: 0 0 auto;
      margin: 0 0 .25rem;
    }

    .offline__message--content {
      font-size: 1.25rem;
      line-height: 1.4em;
      flex: 1 1 auto;
      max-width: 424px;
      text-align: center;
    }

    .offline__message--title {
      font-weight: 600;
    }
  }
}
</style>

<!-- GLOBAL STYLE -->
<style lang="scss">
.is-offline,
.is-offline body,
.is-offline #app {
  overflow: hidden !important;
  user-select: none !important;
  pointer-events: none !important;
}

.is-offline {
  #app,
  #app ~ *:not(.offline__container) {
    filter: blur(1px);
  }
}
</style>
