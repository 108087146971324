export default {
	methods: {
		addExpresion: function (e) {
			if (e === "+" || e === "-" || e === "*" || e === "/") {
				if (this.$store.getters.getOperatorClicked) {
					var current = this.$store.getters.getCurrent;
					current = current.slice(0, -1);
					this.$store.commit("SET_CURRENT", current + e);
				}
				else {
					this.$store.commit("SET_CURRENT", this.$store.getters.getCurrent + e);
				}
				this.$store.commit("SET_OPERATORCLICKED", true);
				this.$store.commit("SET_KOMMACLICKED", false);
			}
			else if (e === ".") {
				if (!store.getters.getKommaClicked) {
					this.$store.commit("SET_CURRENT", this.$store.getters.getCurrent + e);
					this.$store.commit("SET_KOMMACLICKED", true);
				}
			}
			else {
				this.$store.commit("SET_CURRENT", this.$store.getters.getCurrent + e);
				this.$store.commit("SET_OPERATORCLICKED", false);
			}
		},

		getResult: function () {
			var result = eval(this.$store.getters.getCurrent);
			this.$store.commit("SET_CURRENT", result);
			this.$store.commit("SET_OPERATORCLICKED", false);
			if (result.toString().includes(".")) this.$store.commit("SET_KOMMACLICKED", true);
			else this.$store.commit("SET_KOMMACLICKED", false);
		},

		calcEndefromStart: function (zins, start, jahre) {
			var temp = 1 + 0.01 * zins;
			temp = Math.pow(temp, jahre);
			temp = start * temp * (-1);
			temp = Math.round(temp * 100) / 100;
			return temp;
		},
		calcStartfromEnde: function (zins, ende, jahre) {
			var temp = 1 + 0.01 * zins;
			temp = Math.pow(temp, jahre);
			temp = ende / temp * (-1);
			temp = Math.round(temp * 100) / 100;
			return temp;
		},

		calcEndefromRate: function (rate, zins, jahre, anzahl) {
			var zinsanzahl = zins / anzahl * 0.01;
			var jahreanzahl = jahre * anzahl;
			var einsplusetwas = 1 + zinsanzahl;
			var exptemp = Math.pow(einsplusetwas, jahreanzahl);
			var exptempminuseins = exptemp - 1;
			var ende = (-1) * rate / zinsanzahl * exptempminuseins;
			ende = Math.round(ende * 100) / 100;
			return ende;
		},

		calcStartfromRate: function (zins, rate, jahre, anzahl) {
			var zinsanzahl = zins / anzahl * 0.01;
			var jahreanzahl = jahre * anzahl;
			var einsplusetwas = 1 + zinsanzahl;
			var exptemp = Math.pow(einsplusetwas, jahreanzahl);
			var numerator = rate * (exptemp - 1);
			var denominator = zinsanzahl * exptemp;
			return numerator / denominator * (-1);
		},

		calcZinsfromStartEnde: function (start, ende, jahre) {
			start = Math.abs(start);
			var zinsen = 100 * (Math.pow(ende / start, 1 / jahre) - 1);
			zinsen = Math.round(zinsen * 100) / 100;
			return zinsen;
		},

		calcRatefromEnde: function (ende, zins, jahre, anzahl) {
			var zinsanzahl = zins / anzahl * 0.01;
			var jahreanzahl = jahre * anzahl;
			var einsplusetwas = 1 + zinsanzahl;
			var exptemp = Math.pow(einsplusetwas, jahreanzahl);
			var exptempminuseins = exptemp - 1;
			var rate = (-1) * (1 / (((1 / zinsanzahl) * exptempminuseins) / ende));
			return rate;
		},

		calcRatefromStart: function (start, zins, jahre, anzahl) {
			var zinsanzahl = zins / anzahl * 0.01;
			var jahreanzahl = jahre * anzahl;
			var einsplusetwas = 1 + zinsanzahl;
			var exptemp = Math.pow(einsplusetwas, jahreanzahl);
			var numarator = start * exptemp * zinsanzahl;
			var denominator = exptemp - 1;
			return -1 * numarator / denominator;
		},

		calcZinsfromRateEnde: function (ende, rate, jahre, anzahl) {
			rate = Math.abs(rate);
			var high = 100;
			var low = 0.01;
			var tolerance = 0.01;
			for (var i = 0; i < 50; i++) {
				var highGuessZins = high - ((high - low) / 4);
				var lowGuessZins = low + ((high - low) / 4);
				var highResult = this.calcRatefromEnde(ende, highGuessZins, jahre, anzahl);
				var lowResult = this.calcRatefromEnde(ende, lowGuessZins, jahre, anzahl);
				var highAbs = Math.abs(Math.abs(rate) - Math.abs(highResult));
				var lowAbs = Math.abs(Math.abs(rate) - Math.abs(lowResult));
				if (highAbs < tolerance) return highGuessZins;
				if (lowAbs < tolerance) return lowGuessZins;
				if (highAbs < lowAbs) {
					low = low + (high - low) / 2;
				}
				else {
					high = high - (high - low) / 2;
				}
			}
			alert('Zinsen höhere als 100% oder niedrigere als 0%')
		},

		calcZinsfromRateStart: function (start, rate, jahre, anzahl) {
			rate = Math.abs(rate);
			var high = 100;
			var low = 0.01;
			var tolerance = 0.01;
			for (var i = 0; i < 50; i++) {
				var highGuessZins = high - ((high - low) / 4);
				var lowGuessZins = low + ((high - low) / 4);
				var highResult = this.calcRatefromStart(start, highGuessZins, jahre, anzahl);
				var lowResult = this.calcRatefromStart(start, lowGuessZins, jahre, anzahl);
				var highAbs = Math.abs(Math.abs(rate) - Math.abs(highResult));
				var lowAbs = Math.abs(Math.abs(rate) - Math.abs(lowResult));
				if (highAbs < tolerance) return highGuessZins;
				if (lowAbs < tolerance) return lowGuessZins;
				if (highAbs < lowAbs) {
					low = low + (high - low) / 2;
				}
				else {
					high = high - (high - low) / 2;
				}
			}
			alert('Zinsen höhere als 100% oder niedrigere als 0%')
		},

		calcJahrefromStartEnde: function (zins, start, ende) {
			var startende = Math.abs(ende) / Math.abs(start);
			zins = zins * 0.01;
			var einsplusetwas = 1 + zins;
			var jahre = this.getLogfromArbitraryBase(einsplusetwas, startende);
			return jahre;
		},

		calcJahrefromRateStart: function (zins, rate, start, anzahl) {
			rate = Math.abs(rate);
			start = Math.abs(start);
			var zinsanzahl = zins / anzahl * 0.01;
			var einsplusetwas = 1 + zinsanzahl;
			var power = rate / (rate - (start * zinsanzahl));
			var log = this.getLogfromArbitraryBase(einsplusetwas, power);
			return log / anzahl;
		},

		calcJahrefromRateEnde: function (zins, rate, ende, anzahl) {
			rate = Math.abs(rate);
			var zinsanzahl = zins / anzahl * 0.01;
			var base = 1 + zinsanzahl;
			var power = 1 + (ende * zinsanzahl / rate);
			var numarator = this.getLogfromArbitraryBase(base, power);
			return numarator / anzahl;
		},

	

		getLogfromArbitraryBase: function (base, power) {
			return Math.log(power) / Math.log(base);
		},

		setRsLabels: function (start, ende) {
			if (Math.sign(start) < 0) {
				this.$store.commit("SET_AUSZAHLUNGEN", this.formatNumber(Math.abs(start)));
				this.$store.commit("SET_EINZAHLUNGEN", this.formatNumber(Math.abs(ende)));
			}
			else {
				this.$store.commit("SET_AUSZAHLUNGEN", this.formatNumber(Math.abs(ende)));
				this.$store.commit("SET_EINZAHLUNGEN", this.formatNumber(Math.abs(start)));
			}
			var leftover = Math.abs(Math.abs(ende) - Math.abs(start));
			leftover = Math.round(leftover * 100) / 100;
			this.$store.commit("SET_ZINSEN", this.formatNumber(Math.abs(leftover)));
		},


		formatNumber: function (x) {
			var amount = x.toString();
			if (!amount.includes(".")){
				amount = amount + ".00";
			}
			var parts = amount.split(".");
			if (parts[1].length<2){
				parts[1] = parts[1]  + "0";
			}
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
			return parts.join(",");
		},

	}
};