var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "diV",
    [
      _c("GesellschaftTitleNavigation"),
      _c("DocumentsFilter"),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  type: "table",
                  config: { table: { rows: 5 } }
                }
              })
            : [
                _c(
                  "DragnDropArea",
                  {
                    staticClass: "clearfix",
                    attrs: { hoverText: "Datei hier ablegen" },
                    on: { files: _vm.dropFiles }
                  },
                  [
                    _c("DocumentsTable", {
                      ref: "documentsTableEl",
                      attrs: {
                        title: _vm.TABLE_TITLE,
                        nodeId: _vm.nodeId,
                        rowsPerPage: _vm.rowsPerPage
                      }
                    })
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }