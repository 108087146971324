import MAKLER_TEXTE_TYPES from './types';
import { getInitialState } from './index';

import Vue from 'vue';

export default {

  [MAKLER_TEXTE_TYPES.MUTATIONS.SET_MAKLER_TEXT_BEREICH](state, payload) {
    Vue.set(state, 'maklerTextBereich', [ ...payload || [], ]);
  },

  [MAKLER_TEXTE_TYPES.MUTATIONS.SET_SELECTED_BEREICH](state, payload) {
    Vue.set(state, 'selectedBereich', payload);
  },

  [MAKLER_TEXTE_TYPES.MUTATIONS.SET_LIST_BY_BEREICH](state, { bereich, data, }) {
    if(!bereich) return;

    Vue.set(state, 'listByBereich', {
      ...state.listByBereich || {},
      [bereich]: [ ...data || [] ],
    });
  },

  [MAKLER_TEXTE_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

}
