import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=49820912&scoped=true&"
import script from "./Account.vue?vue&type=script&lang=js&"
export * from "./Account.vue?vue&type=script&lang=js&"
import style0 from "@/components/steckbrief/steckbrief.scss?vue&type=style&index=0&id=49820912&lang=scss&scoped=true&"
import style1 from "./Account.vue?vue&type=style&index=1&id=49820912&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49820912",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49820912')) {
      api.createRecord('49820912', component.options)
    } else {
      api.reload('49820912', component.options)
    }
    module.hot.accept("./Account.vue?vue&type=template&id=49820912&scoped=true&", function () {
      api.rerender('49820912', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/Account.vue"
export default component.exports