<template>
	<div>
    <div v-if="isContainerRendered">
      <h2>Investment</h2>
      <div class="small grid-container">
        
      <div class="card-1">
        <BrokerPieChart
          index="0"
          currentCard="BrokerPieChart"
          :option="cleanName(getCurrentCardOption(0))"
          v-bind:data="getCurrentCardData(0)"
          :cardSelected="cardSelected"
        />
      </div>
      <div class="card-2">
        <BrokerPieChart
          index="1"
          currentCard="BrokerPieChart"
          :option="cleanName(getCurrentCardOption(1))"
          v-bind:data="getCurrentCardData(1)"
          :cardSelected="cardSelected"
        />
      </div>
      <div class="card-3">
        <BrokerPieChart
          index="2"
          currentCard="BrokerPieChart"
          :option="cleanName(getCurrentCardOption(2))"
          v-bind:data="getCurrentCardData(2)"
          :cardSelected="cardSelected"
        />
      </div>
      <div class="card-4">
        <BrokerBarChart
          index="3"
          currentCard="BrokerBarChart"
          :option="cleanName(getCurrentCardOption(3))"
          v-bind:data="getCurrentCardData(3)"
          :cardSelected="cardSelected"
        />
      </div>
      <div class="card-5">
        <BrokerBarChart
          index="4"
          currentCard="BrokerBarChart"
          :option="cleanName(getCurrentCardOption(4))"
          v-bind:data="getCurrentCardData(4)"
          :cardSelected="cardSelected"
        />
      </div>
      </div>
    </div>
	</div>
</template>

<script>
import LOG_TYPES from '@/store/log/types';
import CoreCard from '../CoreCard/CoreCard';
import BrokerPieChart from './BrokerPieChart';
import BrokerBarChart from './BrokerBarChart';
import cardMixin from '../CoreCard/card-mixin';


export default {
  mixins: [cardMixin],

  data: function() {
    return {
      isContainerRendered: false,
      cardSelected: 0,
      allowConfig: false,
    };
  },

  computed: {
    chartName: function() {
      return this.propsData.option;
    },
    data: function() {
      return this.propsData.data[this.chartName];
    },
  },

  methods: {
    getCurrentCardOption(index) {
      return this.data[index].name;
    },
    cleanName(name) {
      return name.replace('pie#', '').replace('radialBarMultiple#', '').replace('investment#', '').replace('tbl#', '').replace('bar#', '').replace('num#', '');
    },
    getCurrentCardData(index) {
      const name = this.getCurrentCardOption(index)
      this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, {data: this.data});
      return {
        [this.cleanName(name)]: this.data[index].data
      };
    },
    getSwitcherOptions(index) {
      return [];
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },

  components: {
    CoreCard,
    BrokerPieChart,
    BrokerBarChart,
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas:
  "card-1 card-2 card-2"
  "card-3 card-4 card-5";
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 30px;
}

.grid-container > div {
  position: relative;
}

.large {
  margin-bottom: 30px;
}

.grid-container .top {
  grid-area: top;
}
.grid-container .card-1 {
  grid-area: card-1;
}
.grid-container .card-2 {
  grid-area: card-2;
}
.grid-container .card-3 {
  grid-area: card-3;
}
.grid-container .card-4 {
  grid-area: card-4;
}
.grid-container .card-5 {
  grid-area: card-5;
}
/* .content {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -12px;
  text-align: center;
} */
.text-lg {
  font-size: 3rem;
}
.text-sm {
  font-size: 1rem;
}
@media screen and (max-width: 767px) {
  .grid-container {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }
  .grid-container > div {
    margin-bottom: 30px;
  }
}
</style>
