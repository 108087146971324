var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c("div", { staticClass: "box__title" }, [_vm._v("Arbeitgeber")]),
    _vm.isMainPerson
      ? _c(
          "div",
          [
            _vm.isEditable
              ? _c(
                  "BaseButtonsContainer",
                  { staticClass: "mb-3" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.navigateToAddExistentPeople()
                          }
                        }
                      },
                      [_vm._v(" Weiteren Arbeitgeber auswählen ")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openModalNewArbeitgeber()
                          }
                        }
                      },
                      [_vm._v(" als Arbeitgeber hinzufügen ")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        on: {
                          click: function($event) {
                            return _vm.newPerson()
                          }
                        }
                      },
                      [_vm._v(" Neuer Arbeitgeber ")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.rows.length
              ? _c("Table", {
                  attrs: {
                    tableId: "0a01baf1-d9e6-4a75-878c-a3fb31231984",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowId: "personId",
                    hidePagination: "",
                    exportConfig: { roottext: "Arbeitgeber" }
                  },
                  on: {
                    "click-personName": function($event) {
                      return _vm.editPerson($event.personId)
                    },
                    "action-EDIT": function($event) {
                      return _vm.openModalEditArbeitgeber($event.personId)
                    },
                    "action-DELETE": _vm.deleteArbeitgeberConfirmation
                  }
                })
              : _c("div", [_vm._v("Keine Daten")]),
            _c(
              "BaseModal",
              {
                ref: "editNewArbeitgeber",
                attrs: {
                  size: "sm",
                  modalTitle: "Arbeitgeber",
                  labelButtonConfirm: "Als Arbeitgeber hinzufügen",
                  confirmDisabled: _vm.validation.arbeitgeber.personId.invalid
                },
                on: {
                  onConfirmButton: function($event) {
                    return _vm.saveNewArbeitgeber()
                  }
                }
              },
              [
                _c("ComboBox", {
                  attrs: { values: _vm.newPersonList },
                  model: {
                    value: _vm.arbeitgeber.personId,
                    callback: function($$v) {
                      _vm.$set(_vm.arbeitgeber, "personId", $$v)
                    },
                    expression: "arbeitgeber.personId"
                  }
                })
              ],
              1
            ),
            _c(
              "BaseModal",
              {
                ref: "editEmployee",
                attrs: {
                  size: "sm",
                  modalTitle: "Arbeitgeber",
                  confirmDisabled: !_vm.isEditable
                },
                on: {
                  onConfirmButton: function($event) {
                    return _vm.updateArbeitgeber()
                  }
                }
              },
              [
                _c("DatePickerField", {
                  attrs: {
                    label: "Arbeitnehmereintritt",
                    isValueAsString: "",
                    disabled: !_vm.isEditable
                  },
                  model: {
                    value: _vm.employee.jobStartDate,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "jobStartDate", $$v)
                    },
                    expression: "employee.jobStartDate"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Personalnummer", disabled: !_vm.isEditable },
                  model: {
                    value: _vm.employee.personalNr,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "personalNr", $$v)
                    },
                    expression: "employee.personalNr"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Abteilung", disabled: !_vm.isEditable },
                  model: {
                    value: _vm.employee.department,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "department", $$v)
                    },
                    expression: "employee.department"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Geschäftsführung",
                    disabled: !_vm.isEditable
                  },
                  model: {
                    value: _vm.employee.manager,
                    callback: function($$v) {
                      _vm.$set(_vm.employee, "manager", $$v)
                    },
                    expression: "employee.manager"
                  }
                })
              ],
              1
            ),
            _c(
              "BaseModal",
              {
                ref: "deleteArbeitgeberConfirmation",
                attrs: { size: "sm", modalTitle: "Arbeitgeber entfernen" },
                on: {
                  onConfirmButton: function($event) {
                    return _vm.deleteArbeitgeber()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.deleteMessage) + " ")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }