var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "circle" },
    [
      _vm.unread !== null
        ? [_vm._v(" " + _vm._s(_vm.unread) + " ")]
        : _c("AnimatedSpinner")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }