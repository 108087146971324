<template>
  <div>
    <PageHeaderTitleNavigation 
      :actions="headerActions"
      title="Anlageziele Depotpositionen planen"
      @action-PLANUNG_ABSCHLIESSEN="goToAnlegerprofil()"
    />

    <div class="box__container">
      <div>
        <div class="box__title">Anlageziele Depotpositionen planen</div>
        <span>&nbsp; (Insgesamt {{positions && positions.length || 0}} Position{{positions && positions.length > 1 ? 'en' : ''}}, davon {{positionsWithoutZeile && positionsWithoutZeile.length || 0 }} ungeordnet)</span>
      </div>
      <div class="col-12">
        <span>Bitte weisen Sie die Depotpositionen den Zeilen zu, so wie die Aufteilung der Anlageziele vom Kunden gewünscht ist. Anschließend können Sie die Neuanlage(n) eintragen und die gewünschten Zielmarktkriterien auswählen.</span>
      </div>
    </div>

    <div class="box__container" v-if="positions && positions.length">
      <BaseCollapsable :showSection="showExpanded">
        <template v-slot:title>
          <div class="row justify-content-between">
            <div class="col color-danger box__title">Positionen ohne Zuordnung - bitte einer Zeile zuweisen</div>
          </div>
          <div class="stock__row">
            <div class="stock__row__title">
              Bestand:
            </div>
            <div class="stock__row__amount">
              <NumberCell :row="{bestand: positionsWithoutZeileSum}" column='bestand' symbol="€" />
            </div>            
          </div>          
        </template>

        <template v-slot:content>
          <div class="mt-8px">
             <hr>
            <div v-if="positionsWithoutZeile && positionsWithoutZeile.length">
              <template>
                <div class="row" v-for="(pos, index) of positionsWithoutZeile" :key="index">
                  <div class="col-12">
                    <APAnlagezielePlanenZeile :position="pos" @zeileChanged="positionZeileChanged(pos, $event)"/>
                  </div>
                </div>
              </template>
            </div>
            <div v-else>
              Keine ungeordnete Position. Die Positionen sind augeteilt auf folgenden Zeilen: {{ getZeilen }}.
            </div>
          </div>
        </template>
      </BaseCollapsable>
    </div>

   
    <div v-for="(zeile, count) in zeilen" :key="count">
      <div class="box__container">
        <BaseCollapsable :showSection="true">
          <template v-slot:title>
            <div class="row justify-content-between">
              <div class="col box__title">Zeile {{count + 1}}</div>
            </div>
            <div class="stock__row">
              <div class="stock__row__title">
                Bestand Zielsumme
              </div>
              <div class="stock__row__amount">
                <NumberCell :row="{bestand: bestandTotal(zeile, count + 1)}" column='bestand' symbol="€" />
              </div>              
            </div>            
          </template>

          <template v-slot:content>
            <div class="mt-8px">
              <Table
                :mobileConfig="{title: 'AnlagezielePlanen', headers: ['bestand', 'einmalig', 'ratierlich', 'zeithorizont', 'anlageziel'], disableClickRow: false}"
                :headers="headers"
                :rows="[rows[zeile.zeile]]"
                rowId="apanlagezieleplanen1"
                @clickRow="openRowEditModal(zeile, count, $event)"
                hidePagination
              >
                <template v-slot:einmalig="row">
                  <InputField
                    :value="row[EINMALIG_STR + zeile.zeile]"
                    type="currency"
                    @change="updateStore(zeile.zeile, EINMALIG_STR, formatNumber($event))"
                  />
                </template>
                <template v-slot:ratierlich="row">  
                  <InputField
                    :value="row[RATIERLICH_STR + zeile.zeile]"
                    @change="updateStore(zeile.zeile, RATIERLICH_STR, formatNumber($event))"
                    type="currency"
                  />
                </template>
                <template v-slot:zeithorizont="row">  
                  <ComboBox
                    :value="row[HORIZONT_STR + zeile.zeile]"
                    @change="updateStore(zeile.zeile, HORIZONT_STR, $event)"
                    :values="comboboxValues(count+1,'horizont')"
                  />
                </template>
                <template v-slot:anlageziel="row">  
                  <ComboBox
                    :value="row[ZIEL_STR + zeile.zeile]"
                    @change="updateStore(zeile.zeile, ZIEL_STR, $event)"
                    :values="comboboxValues(count+1,'anlageziel')"
                    :firstEmpty="true"
                  />
                </template>
                <template v-slot:verlust="row">  
                  <ComboBox
                    :value="row[VERLUST_STR + zeile.zeile]"
                    @change="updateStore(zeile.zeile, VERLUST_STR, $event)"
                    :values="comboboxValues(count+1, 'verlust')"
                    :firstEmpty="true"
                  />
                </template>
              </Table>

              <div>
                <div class="row" v-for="(pos, index) of positionsProZeile(count + 1)" :key="index">
                  <div class="col-12">
                    <APAnlagezielePlanenZeile :position="pos" @zeileChanged="positionZeileChanged(pos, $event)"/>
                  </div>
                  <hr class="positionDivider" v-if="index < positionsProZeile(count + 1).length - 1">
                </div>
              </div>
            </div>
          </template>
        </BaseCollapsable>
      </div>
    </div>

    <BaseModal
        ref="zeileBearbeitenModal"
        modalTitle="Zeile bearbeiten"
        :showConfirmButton="false"
        v-if="rows && selectedZeile && selectedZeile.zeile"
      >

      <InputField
        label="Einmalig"
        :value="rows[selectedZeile.zeile][EINMALIG_STR + selectedZeile.zeile]"
        type="currency"
        @change="updateStore(selectedZeile.zeile, EINMALIG_STR, formatNumber($event))"
      />

      <InputField
        label="Ratierlich"
        :value="rows[selectedZeile.zeile][RATIERLICH_STR + selectedZeile.zeile]"
        @change="updateStore(selectedZeile.zeile, RATIERLICH_STR, formatNumber($event))"
        type="currency"
      />

      <ComboBox
        label="Zeithorizont"
        :value="rows[selectedZeile.zeile][HORIZONT_STR + selectedZeile.zeile]"
        @change="updateStore(selectedZeile.zeile, HORIZONT_STR, $event)"
        :values="comboboxValues(position+1,'horizont')"
      />

       <ComboBox
         label="Anlageziel"
        :value="rows[selectedZeile.zeile][ZIEL_STR + selectedZeile.zeile]"
        @change="updateStore(selectedZeile.zeile, ZIEL_STR, $event)"
        :values="comboboxValues(position+1,'anlageziel')"
        :firstEmpty="true"
      />

     <ComboBox
        label="Bereitschaft Verluste zu tragen"
        :value="rows[selectedZeile.zeile][VERLUST_STR + selectedZeile.zeile]"
        @change="updateStore(selectedZeile.zeile, VERLUST_STR, $event)"
        :values="comboboxValues(position+1, 'verlust')"
        :firstEmpty="true"
      />



      </BaseModal>    

  </div>
</template>

<script>
import BaseCollapsable from '@/components/core/BaseCollapsable.vue'
import APAnlagezielePlanenZeile, {ANLAGEZIELE_ZEILE_COMBO} from '@/components/anlageziele/APAnlagezielePlanenZeile.vue';
import {EINMALIG_STR, RATIERLICH_STR, HORIZONT_STR, ZIEL_STR, ZIEL_SPEZIELL_STR, VERLUST_STR} from '@/components/anlageziele/AnlagezieleZeile.vue';
import { mapGetters } from 'vuex';
import ANLAGEZIELE_TYPES from '@/store/anlageziele/types';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import { formatNumber, parse } from '@/helpers/number-formatter.js'
import { PhCurrencyEur } from "phosphor-vue";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import Table from "@/components/table2/Table.vue";
import {CurrencyColumn, SlotColumn} from "@/components/table2/table_util.js";
import BaseButton from '@/components/core/BaseButton.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import CORE_TYPES from '@/store/core/types'
import BaseModal from '@/components/core/BaseModal.vue'
import NumberCell from '@/components/table2/cellTypes/NumberCell.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    BaseCollapsable,
    APAnlagezielePlanenZeile,
    PhCurrencyEur,
    InputField,
    ComboBox,
    Table,
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
    AnimatedSpinner,
    BaseModal,
    NumberCell,
  },
  props: {
    totalRows: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      showExpanded: true,
      loading: false,
      EINMALIG_STR: EINMALIG_STR,
      RATIERLICH_STR: RATIERLICH_STR,
      HORIZONT_STR: HORIZONT_STR,
      ZIEL_STR: ZIEL_STR,
      ZIEL_SPEZIELL_STR: ZIEL_SPEZIELL_STR,
      VERLUST_STR: VERLUST_STR,
      suffix: 'zeile',
      sums: {},
      einmalig: {},
      headers: {
        lockedLeft: [],
        center: [
          CurrencyColumn("bestand", "Bestand").makeAlwaysVisible(),
          SlotColumn("einmalig", "Einmalig", 200, 1),
          SlotColumn("ratierlich", "Ratierlich", 200, 1),
          SlotColumn("zeithorizont", "Zeithorizont", 200, 1),
          SlotColumn("anlageziel", "Anlageziel", 200, 1),
          // SlotColumn("anlagezielSpeziell", "Anlageziel speziell", 200, 1),
          SlotColumn("verlust", "Bereitschaft Verluste zu tragen", 200, 1),
        ],
        lockedRight: [],
      },
      selectedZeile: {},
      position: 0,
    }
  },
  mounted() {
    this.$store.dispatch(ANLAGEZIELE_TYPES.ACTIONS.GET_ANLAGEZIELE_PLANEN, { schiene: this.$route.query.schiene || '' });
    this.suffix = this.$route.query.schiene === 'WEALTH_KONZEPT' ? 'wk' : 'zeile';
    this.initData();
  },
  computed: {
    ...mapGetters({
      apZeilen: ANLAGEZIELE_TYPES.GETTERS.ZEILEN,
      apPositions: ANLAGEZIELE_TYPES.GETTERS.POSITIONS_ANLAGEZIELE,
      schiene: ANLAGEZIELE_TYPES.GETTERS.SCHIENE,
    }),
    headerActions() {
      const actions = [PageHeaderSimpleAction('PLANUNG_ABSCHLIESSEN', 'Planung abschließen')];
      return actions;
    }, 
    positions() {
      this.initSums();
      this.apPositions?.forEach(pos => {
        this.sums['bestand_' + pos.zeile] += pos.betrag;
      });
      return this.apPositions || [];
    },
    rows() {
        const result = {};
        this.zeilen.forEach(zeile => result[zeile.zeile] = {
            bestand: this.bestand(zeile.zeile),
            ...zeile.values,
        });
        return result;
    },
    positionsWithoutZeile() {
      return this.positions?.length && this.positions?.filter(pos => !pos.zeile);
    },
    positionsWithoutZeileSum() {
      return formatNumber((this.positionsWithoutZeile || []).reduce((acc, pos) => acc += (pos.betrag || 0), 0), 2);
    },
    zeilen() {
      return this.apZeilen && (Object.keys(this.apZeilen) || []).sort((a,b) => a.localeCompare(b)).map(key => this.apZeilen[key]) || [];
    },
    maxRows() {
      return this.schiene === 'WEALTH_KONZEPT' ? 3 : 5; 
    },
    getZeilen() {
      const zeilen = this.positions?.filter(pos => pos.zeile).map(pos => pos.zeile);
      return zeilen?.length && [...new Set(zeilen)].sort((a,b) => a.localeCompare(b))
        .map(zeile => ANLAGEZIELE_ZEILE_COMBO.find(combo => combo.value === zeile).label).join(', ');
    },
  },
  methods: {
    openRowEditModal(zeile, count, row) {
      this.selectedZeile = zeile;
        this.position = count;
      
      this.$nextTick(() => {

        this.$refs.zeileBearbeitenModal.open();
      });
    },
    formatNumber(num) {
      if (num) {
        return formatNumber(num, 2);
      }
    },
    initSums() {
      this.sums = {};
      for(let i = 1; i <= 5; i++) {
        this.sums['bestand_' + this.suffix + i] = 0;
      }
    },
    bestand(zeileStr) {
      return this.sums['bestand_'+ zeileStr]
    },
    bestandTotal(zeile) {
      let einmalig = zeile?.values && zeile.values[EINMALIG_STR + zeile.zeile] || 0;
      if (typeof(einmalig) === 'string') {
        einmalig = parse(einmalig);
      }
      return (this.sums['bestand_' + zeile?.zeile] || 0) + einmalig;
    },
    initData() {
      this.zeilen?.forEach((zeile, index) => {
        if (zeile.values) {
          this.einmalig[index + 1] = zeile.values[this.EINMALIG_STR + this.suffix + index + 1] || 0;
        }
      });
    },
    comboboxValues(count, column) {
      return ((this.apZeilen && this.apZeilen[this.suffix + count] || {})['comboboxValues'] || {})[column] || [];
    },
    getWarningsProZeile(count) {
      if (this.warnings && this.warnings.length) {
        const warnings = this.warnings.filter(warn => warn.id && warn.id.includes(count + ''));
        return warnings;
      }
      return [];
    },
    positionsProZeile(count) {
      if (count && this.positions?.length) {
        return this.positions?.filter(pos => pos.zeile?.endsWith(count)) || [];
      }
      return [];
    },
    updateStore(suffix, param, value) {
      if (param) {
        this.$store.commit(ANLAGEZIELE_TYPES.MUTATIONS.UPDATE_ZEILE, { suffix: suffix, param: param + suffix, value });
      }
    },
    positionZeileChanged(pos, newZeile = '') {
      if (pos) {
        pos.zeile = newZeile;
        this.$store.commit(ANLAGEZIELE_TYPES.MUTATIONS.SET_POSITIONS_TEMP);
        setTimeout(() => {
          this.$store.commit(ANLAGEZIELE_TYPES.MUTATIONS.UPDATE_POSITION, { position: pos });
         }, 0);
      }
    },
    goToAnlegerprofil() {
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
      this.$router.push(`/beratung/${this.schiene === 'WEALTH_KONZEPT' ? 'anlegerprofilWK' : 'anlegerprofil'}/person1/anlageziele/anlageziele?schiene=${this.schiene}`)
    },
    async doSave() {
      this.loading = true;
      const anlageziele = {};
      let count = 1;
      const positions = Object.assign([], this.positions);
      this.zeilen?.forEach(zeile => {
        const einmalig = this.bestandTotal(zeile);
        // zeile is not empty
        if (einmalig || (zeile?.values && zeile?.values[RATIERLICH_STR + zeile.zeile])) {
          let data = {};
          // zeile doesn't change
          if (zeile.zeile.endsWith('' + count)) {
            data = zeile?.values || {};
            data[EINMALIG_STR + zeile.zeile] = einmalig;
          } else {
            // zeile changes its number
            data[EINMALIG_STR + this.suffix + count] = einmalig;
            data[RATIERLICH_STR + this.suffix + count] = zeile.values[RATIERLICH_STR + zeile.zeile];
            data[HORIZONT_STR + this.suffix + count] = zeile.values[HORIZONT_STR + zeile.zeile];
            data[ZIEL_STR + this.suffix + count] = zeile.values[ZIEL_STR + zeile.zeile];
            data[ZIEL_SPEZIELL_STR + this.suffix + count] = zeile.values[ZIEL_SPEZIELL_STR + zeile.zeile];
            data[VERLUST_STR + this.suffix + count] = zeile.values[VERLUST_STR + zeile.zeile];
            positions.filter(pos => pos.zeile === zeile.zeile)
              .forEach(pos => pos.zeile = this.suffix + count);
          }
          Object.assign(anlageziele, data)
          count++;
        }
      });
      this.$store.dispatch(ANLAGEZIELE_TYPES.ACTIONS.SAVE_ANLAGEZIELE_PLANEN, { positions: positions })
      await this.$store.dispatch(ANLEGERPROFIL_TYPES.ACTIONS.SAVE_ANLAGEZIELE, { zeilen: { anlageziele }})
    }

  },
  async beforeRouteLeave(to, from, next) {
    await this.doSave();
    next();
  }
}
</script>

<style scoped>
  .positionDivider {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .stock__row  {
    display: flex;
  }

  .stock__row > div.stock__row__title {
  flex: 1 1 auto;
  text-align: left;
  font-weight: 500;
}

.stock__row > div.stock__row__amount {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  /* text-align: right;
  align-items: center; */
}


.stock__row > div.stock__row__amount > * {
  margin-right: 2px;
}

</style>

