var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseFilter", {
    attrs: {
      filterId: "cae8986d-61ae-49d1-9eac-62082d86ba0a",
      metadata: _vm.searchMetadata,
      defaultOptions: _vm.defaultOptions,
      configFilter: _vm.configFilter,
      openFilterOnMount: false,
      showSaveButton: "",
      hasSmartSearch: ""
    },
    on: { onFilter: _vm.search }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }