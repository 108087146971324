<template>
  <div>
    <InvestmentAdviceTable :stepName="stepName" :stepAlloc="stepAlloc" :id="id" ref="invAdvTbl"/>
  </div>
</template>
<script>
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import StepAllocation from "./StepAllocation";
import InvestmentAdviceTable from './InvestmentAdviceTable.vue'

export default {
  props: {
    id: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      stepAlloc: [
        new StepAllocation(INVESTMENT_ADVICE.QUELLE.FONDSFAVORITEN, null)
      ],
      stepName: INVESTMENT_ADVICE.STEPS.FAVORITES,
    }
  },
  components: {
    InvestmentAdviceTable 
  },
  computed: {
  },
  methods: {
    submitChanges(persist) {
      return this.$refs.invAdvTbl.submitChanges(persist);
    },
    // save(arg1, arg2) {
    //   return this.$refs.invAdvTbl.save(arg1, arg2);
    // }
  }
}
</script>