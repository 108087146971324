var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "data-name": "Layer 1",
        width: "752.31651",
        height: "536.20806",
        viewBox: "0 0 752.31651 536.20806"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "a16eae09-ccfb-4b79-a9f9-10731758882a-250",
          "data-name": "Path 2960",
          d:
            "M273.70494,718.104h652.5532a1.247,1.247,0,0,0,0-2.494H273.70494a1.247,1.247,0,1,0,0,2.494Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M399.07906,416.00878c-38.16374-37.56944-54.28233-76.76379-45.38778-110.36206,11.41454-43.11628,62.1237-72.92754,139.12509-81.78985l.2844,2.47323c-75.92943,8.73834-125.86506,37.88056-137.00309,79.95345-8.65632,32.69771,7.22828,71.03587,44.72784,107.952Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M767.2948,549.3689c-47.63071,0-99.38994-7.0782-150.8016-20.68887-63.57726-16.83133-121.87081-42.23636-168.57846-73.46959l1.38311-2.06851c46.4828,31.08192,104.51805,56.37085,167.83222,73.1317,57.21612,15.14809,114.8613,22.16677,166.68248,20.31453l.09,2.48664Q775.69347,549.37009,767.2948,549.3689Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M876.56548,533.71645l-.79727-2.35777c42.59914-14.40309,69.24323-37.60469,77.05185-67.09873,9.92576-37.49407-11.63816-81.00353-60.72-122.51372-49.33222-41.7211-120.47011-76.33906-200.31235-97.47578l.63687-2.40638C772.61565,263.09376,844.1,297.891,893.70682,339.846c49.85721,42.16528,71.7055,86.57592,61.51967,125.05075C947.19419,495.2367,919.9935,519.03383,876.56548,533.71645Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M485.40583,550.40925C391.81432,508.90025,319.695,455.04757,282.334,398.77034l2.07339-1.377c37.10274,55.88776,108.8435,109.42141,202.00719,150.74078Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M814.46605,622.32011q-14.4802,0-29.4552-.64656l.107-2.48664c74.502,3.22555,140.23651-5.69027,190.10952-25.78845l.93093,2.30916C932.65256,613.23783,877.20108,622.32011,814.46605,622.32011Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M760.16308,226.08885c-15.469-5.0364-31.38027-9.72581-47.29163-13.93823C621.30775,187.9105,531.2997,179.304,452.57436,187.26639l-.25035-2.47688c79.01888-7.98848,169.335.63862,261.18431,24.95473,15.95631,4.22455,31.91262,8.92733,47.42528,13.97831Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M340.58681,419.74239c-38.16374-37.56944-54.28232-76.7638-45.38778-110.36206,11.41455-43.11628,62.1237-72.92754,139.12509-81.78985l.28441,2.47323c-75.92944,8.73834-125.865,37.88056-137.0031,79.95345-8.65632,32.69771,7.22829,71.03587,44.72784,107.952Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M708.80248,553.10244c-47.63071,0-99.38994-7.07817-150.80159-20.68887-63.57726-16.83133-121.87082-42.23636-168.57847-73.46959l1.38312-2.06851c46.4828,31.08192,104.51808,56.37088,167.83221,73.1317,57.21613,15.14808,114.8613,22.16676,166.68248,20.31453l.09,2.48664Q717.20126,553.10363,708.80248,553.10244Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M818.07324,537.45l-.79728-2.35776c42.59915-14.40309,69.24324-37.60469,77.05185-67.09873,9.92577-37.49407-11.63816-81.00346-60.72-122.51372-49.33215-41.72106-120.47011-76.339-200.31242-97.47573l.63686-2.40638c80.19109,21.22969,151.67536,56.0269,201.28226,97.982,49.8572,42.16524,71.7055,86.57591,61.51967,125.05075C888.70194,498.97024,861.50119,522.76737,818.07324,537.45Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M426.91355,554.14279c-93.59148-41.509-165.71082-95.36168-203.07181-151.63891l2.0734-1.377c37.10274,55.88776,108.8435,109.42141,202.00719,150.74078Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M755.9738,626.05372q-14.48031,0-29.4552-.64656l.107-2.48664c74.502,3.22555,140.23652-5.69027,190.10953-25.78845l.93092,2.30916C874.16031,616.97137,818.70884,626.05372,755.9738,626.05372Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M701.67091,229.82241c-15.469-5.0364-31.38027-9.72582-47.29163-13.93823-91.5637-24.2401-181.57172-32.84659-260.2971-24.88425l-.25038-2.47688c79.01888-7.98848,169.335.63862,261.18427,24.95475,15.95631,4.22455,31.91263,8.92732,47.42528,13.97833Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M266.266,353.07527c17.88654-20.05432,46.41854-32.27682,72.275-24.96025a123.80962,123.80962,0,0,0-48.31372,69.93857c-2.79358,10.91821-4.76387,23.35281-13.844,30.0284-5.64986,4.15384-13.136,5.14612-20.08414,4.20013-6.94844-.9462-13.51838-3.64828-19.99968-6.32588l-1.744.15111C239.56956,399.70742,248.37947,373.12962,266.266,353.07527Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M338.462,328.67337a105.824,105.824,0,0,0-55.49856,34.04578,45.5722,45.5722,0,0,0-7.68445,12.03417,26.13694,26.13694,0,0,0-1.54279,13.66913c.58072,4.314,1.67423,8.65955,1.1962,13.03954a16.00387,16.00387,0,0,1-6.78326,11.19338c-4.54051,3.31559-10.00158,4.80195-15.39256,6.06985-5.98564,1.4078-12.22517,2.77525-17.10443,6.75316-.59118.482-1.3045-.46353-.7142-.94476,8.48906-6.92085,20.287-6.03227,29.67939-11.0592,4.38266-2.34568,8.08494-6.0263,8.98511-11.07687.78712-4.41647-.33673-8.89858-.96246-13.26154a27.89057,27.89057,0,0,1,1.01053-13.42167,42.13046,42.13046,0,0,1,7.19345-12.28521,102.66721,102.66721,0,0,1,23.90413-21.40689,107.70091,107.70091,0,0,1,33.574-14.52512c.73894-.17867.874.99881.13983,1.17625Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M287.29978,357.00363a15.877,15.877,0,0,1-.48909-20.55318c.48144-.59065,1.42192.12972.93984.72118a14.70171,14.70171,0,0,0,.494,19.11781C288.75433,356.85593,287.80665,357.56686,287.29978,357.00363Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M273.1536,386.27384a30.60156,30.60156,0,0,0,21.26115-6.73213c.59287-.47983,1.30635.4655.71419.94476a31.83014,31.83014,0,0,1-22.13838,6.96042C272.22882,387.39408,272.396,386.22131,273.1536,386.27384Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M315.29546,336.84909a8.98707,8.98707,0,0,0,7.25276,4.48551c.76206.04312.594,1.21593-.163,1.17309a10.0708,10.0708,0,0,1-8.03448-4.94438.61209.61209,0,0,1,.11528-.82947.59517.59517,0,0,1,.82948.11528Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M375.24463,411.3663c-.47659-.01338-.95319-.0268-1.43589-.03224a118.35488,118.35488,0,0,0-19.16638,1.11827c-.49187.0611-.98946.13046-1.47863.20553a124.77337,124.77337,0,0,0-43.37984,15.1149,121.1709,121.1709,0,0,0-15.22211,10.35555c-6.63433,5.29374-13.40643,11.56136-21.17347,14.27443a20.78926,20.78926,0,0,1-2.46872.72423l-36.04473-24.90364c-.05352-.09769-.11308-.18755-.167-.28549l-1.4846-.9285c.23395-.215.48123-.43291.71521-.64789.1352-.12526.27847-.245.4136-.37027.09267-.08175.1858-.16329.26474-.24237.03076-.02732.06191-.05439.087-.07353.079-.07908.16069-.14415.23394-.215q2.06826-1.83306,4.16947-3.6435c.00569-.00822.00569-.00822.01939-.01092a178.16388,178.16388,0,0,1,34.71077-23.86231c.37487-.19054.755-.38958,1.14633-.5687a115.62279,115.62279,0,0,1,17.49921-7.05714,102.20744,102.20744,0,0,1,10.02658-2.53688,84.99927,84.99927,0,0,1,26.55683-1.01777c17.64385,2.10629,34.41763,9.832,45.353,23.5471C374.69889,410.66127,374.9708,411.007,375.24463,411.3663Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M374.84751,411.76778a105.82415,105.82415,0,0,0-64.8104-6.23033,45.57135,45.57135,0,0,0-13.381,4.982,26.1372,26.1372,0,0,0-9.46159,9.98518c-2.13369,3.79411-3.87682,7.92218-6.89564,11.13158a16.00383,16.00383,0,0,1-12.15524,4.85328c-5.62156-.08638-10.87681-2.18755-15.94459-4.42093-5.62685-2.47972-11.432-5.1445-17.72282-4.906-.76223.0289-.76248-1.15549-.00144-1.18436,10.9449-.4149,19.82989,7.39772,30.35575,9.03887,4.91154.76578,10.08365.056,13.84314-3.43463,3.28749-3.05243,5.0887-7.30779,7.21592-11.16806a27.89059,27.89059,0,0,1,8.88763-10.108,42.13014,42.13014,0,0,1,13.14011-5.47811,102.66889,102.66889,0,0,1,31.97453-2.70028,107.701,107.701,0,0,1,35.5522,8.61646c.69758.30224.0965,1.32363-.59655,1.02335Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M316.94058,403.58472a15.87693,15.87693,0,0,1,11.98391-16.705c.74-.18176,1.05724.95968.31622,1.14166a14.70171,14.70171,0,0,0-11.11578,15.56195C318.19092,404.34256,317.00617,404.33961,316.94058,403.58472Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M288.023,418.43839a30.60149,30.60149,0,0,0,21.029,7.42543c.7623-.0262.76279,1.15819.00144,1.18436a31.83006,31.83006,0,0,1-21.86691-7.77133c-.57642-.50079.26316-1.33651.83647-.83846Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M351.428,404.34779a8.98712,8.98712,0,0,0,3.09032,7.94806c.58251.49324-.25775,1.32848-.83646.83847a10.07082,10.07082,0,0,1-3.43825-8.78512.61205.61205,0,0,1,.59146-.59287.59516.59516,0,0,1,.59286.59146Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M967.12353,517.471c-10.10577-24.89935-32.87-46.00044-59.68135-47.79982A123.80957,123.80957,0,0,1,929.439,551.77941c-1.03852,11.222-3.36215,23.59538,2.94617,32.9345,3.925,5.81107,10.6422,9.26178,17.50408,10.70611,6.86224,1.44425,13.95814,1.10749,20.96239.764l1.59181.72852C976.59391,570.363,977.2293,542.37034,967.12353,517.471Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M907.329,470.22354a105.824,105.824,0,0,1,40.82726,50.71828,45.57106,45.57106,0,0,1,3.19275,13.91685,26.13731,26.13731,0,0,1-3.14113,13.39249c-1.99684,3.86789-4.48734,7.59307-5.50922,11.879a16.00391,16.00391,0,0,0,2.62654,12.82207c3.162,4.64873,7.80581,7.88411,12.457,10.89019,5.16423,3.33771,10.58124,6.72268,13.83965,12.10913.39478.65267,1.38442.0019.99021-.64978-5.66914-9.37142-17.0794-12.49984-24.23584-20.39112-3.33925-3.6822-5.58921-8.39309-4.73947-13.45236.74305-4.42413,3.308-8.26776,5.36369-12.16659a27.8905,27.8905,0,0,0,3.5593-12.98054,42.1295,42.1295,0,0,0-2.64593-13.98827,102.66767,102.66767,0,0,0-15.31867-28.19577A107.70047,107.70047,0,0,0,907.856,469.16268c-.63588-.41665-1.15883.64691-.527,1.06082Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M945.99308,514.10132a15.87694,15.87694,0,0,0,7.36861-19.19313c-.25494-.71812-1.3828-.35573-1.12758.36334a14.70162,14.70162,0,0,1-6.89082,17.83959C944.67278,513.47331,945.32636,514.46144,945.99308,514.10132Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M949.47856,546.42333A30.60125,30.60125,0,0,1,931.71693,532.937c-.3971-.65123-1.38681-.00064-.99021.64978A31.83045,31.83045,0,0,0,949.23787,547.583c.73519.20631.97187-.95445.24069-1.15964Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M926.39994,485.70985a8.98711,8.98711,0,0,1-8.33838,1.78692c-.73224-.21551-.96815.94554-.24069,1.15964a10.07073,10.07073,0,0,0,9.22892-1.95635.612.612,0,0,0,.17025-.82.59517.59517,0,0,0-.82-.17021Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M844.893,535.74324c.45335.14756.90677.29515,1.36321.45222a118.35416,118.35416,0,0,1,17.67552,7.49507c.44274.22288.88809.45542,1.32346.69052a124.77376,124.77376,0,0,1,35.77616,28.81556,121.17143,121.17143,0,0,1,10.856,14.86926c4.46915,7.21558,8.74074,15.39467,15.14415,20.56038a20.775,20.775,0,0,0,2.08168,1.51182l42.318-11.34024c.08323-.074.16954-.13857.25326-.21273l1.71036-.37553c-.14812-.28114-.30775-.56952-.4558-.85058-.08526-.16343-.17987-.32434-.26513-.48777-.05984-.10815-.1201-.21624-.16785-.31723-.01981-.036-.04-.072-.05724-.09847-.04776-.101-.10289-.18977-.14812-.28114q-1.33172-2.42153-2.70232-4.83288c-.0026-.00962-.0026-.00962-.01461-.01679a178.16178,178.16178,0,0,0-24.67142-34.14042c-.289-.30544-.58018-.62064-.8885-.92092a115.62872,115.62872,0,0,0-14.10935-12.528,102.22482,102.22482,0,0,0-8.59065-5.75925,85.00038,85.00038,0,0,0-24.66987-9.88436c-17.32535-3.9463-35.72-2.30775-50.62892,6.93412C845.6439,535.26264,845.27167,535.49687,844.893,535.74324Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M845.13205,536.25485a105.82363,105.82363,0,0,1,63.13417,15.91491,45.57063,45.57063,0,0,1,10.92811,9.18955,26.13715,26.13715,0,0,1,5.55515,12.58429c.73435,4.29055.98866,8.76434,2.75317,12.80163a16.00378,16.00378,0,0,0,9.817,8.65632c5.32353,1.808,10.97932,1.59539,16.50286,1.19528,6.13288-.44429,12.496-1.00292,18.34076,1.336.70815.28339,1.10651-.832.39941-1.115-10.16876-4.06939-21.16269.30256-31.62783-1.68958-4.88317-.92952-9.51589-3.33637-11.88341-7.88748-2.07037-3.97977-2.33655-8.593-3.04253-12.94364A27.89086,27.89086,0,0,0,921.03561,561.79a42.12934,42.12934,0,0,0-10.53454-9.57583,102.66761,102.66761,0,0,0-29.20686-13.28985,107.70079,107.70079,0,0,0-36.38-3.8339c-.75857.05018-.53573,1.21417.21786,1.16434Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M902.42066,548.01033a15.87693,15.87693,0,0,0-5.67216-19.761c-.63588-.41992-1.31825.54851-.68153.969a14.7017,14.7017,0,0,1,5.23875,18.39266c-.3173.69291.79946,1.08832,1.115.39941Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M924.66373,571.71914a30.6017,30.6017,0,0,1-22.30136-.07437c-.70914-.28086-1.1077.83442-.39941,1.115a31.83016,31.83016,0,0,0,23.20679.03027c.71124-.27791.20136-1.34726-.506-1.07083Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M869.683,537.13777a8.98707,8.98707,0,0,1-5.582,6.44706c-.7144.26878-.20374,1.33785.506,1.07083a10.07077,10.07077,0,0,0,6.19089-7.11848.61206.61206,0,0,0-.35776-.75714.59514.59514,0,0,0-.75717.3578Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "211.2241",
          cy: "134.24075",
          r: "9.95613",
          fill: "#fd6584"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "806.33162",
          cy: "422.72551",
          r: "9.95614",
          transform: "translate(36.06272 969.03618) rotate(-80.78252)",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "210.81937",
          cy: "22.19982",
          r: "9.95614",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M731.73249,321.2537h-2.8418v-77.85a45.05752,45.05752,0,0,0-45.0574-45.05755h-164.936a45.05749,45.05749,0,0,0-45.05762,45.05737h0V670.49518a45.05753,45.05753,0,0,0,45.05748,45.05755H683.83307a45.05752,45.05752,0,0,0,45.05762-45.0574V376.66872h2.8418Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M719.2972,243.71618V670.18223a33.64147,33.64147,0,0,1-33.637,33.64595H519.91837a33.644,33.644,0,0,1-33.64567-33.6423V243.71621a33.64407,33.64407,0,0,1,33.64216-33.646h20.11735a15.98447,15.98447,0,0,0,14.79819,22.02407H649.324a15.99145,15.99145,0,0,0,14.79818-22.02407h21.5293a33.644,33.644,0,0,1,33.64561,33.64249v0Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#f2f2f2"
        }
      }),
      _c("rect", {
        attrs: {
          x: "274.98962",
          y: "68.88366",
          width: "51.41439",
          height: "2.30137",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "353.23604",
          y: "68.88366",
          width: "51.41439",
          height: "2.30137",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M683.38759,526.33828H515.78908a4.30017,4.30017,0,1,1,0-8.60034H683.38759a4.30017,4.30017,0,1,1,0,8.60034Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M683.38759,556.256H515.78908a4.30017,4.30017,0,0,1,0-8.60034H683.38759a4.30017,4.30017,0,0,1,0,8.60034Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M683.38759,586.17375H515.78908a4.30017,4.30017,0,1,1,0-8.60034H683.38759a4.30017,4.30017,0,1,1,0,8.60034Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#ccc"
        }
      }),
      _c("rect", {
        attrs: {
          x: "431.48247",
          y: "68.88366",
          width: "51.41439",
          height: "2.30137",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "274.98962",
          y: "481.97877",
          width: "51.41439",
          height: "2.30137",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "353.23604",
          y: "481.97877",
          width: "51.41439",
          height: "2.30137",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "431.48247",
          y: "481.97877",
          width: "51.41439",
          height: "2.30137",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "378.9432",
          cy: "207.60001",
          r: "77.93445",
          fill: "#d1d3d4"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "378.94324",
          cy: "211.05365",
          r: "71.09969",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M596.57784,420.81634c-.15353,0-.30889-.00372-.46431-.0111a9.46978,9.46978,0,0,1-7.01808-3.64778l-11.206-14.406a9.48742,9.48742,0,0,1,1.66113-13.29993l.40138-.3126a9.4928,9.4928,0,0,1,13.30365,1.65927,5.68454,5.68454,0,0,0,4.21378,2.19015,5.576,5.576,0,0,0,4.40805-1.76841l22.75232-24.01769a9.48927,9.48927,0,0,1,13.398-.36258l.37.34962a9.4884,9.4884,0,0,1,.36437,13.39983l-35.30681,37.26757A9.46173,9.46173,0,0,1,596.57784,420.81634Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "245.21603",
          y: "98.52938",
          width: "9.20546",
          height: "49.47935",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "245.21603",
          y: "153.76216",
          width: "9.20546",
          height: "28.76707",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "245.21603",
          y: "188.28264",
          width: "9.20546",
          height: "28.76707",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "333.81862",
          cy: "35.24183",
          r: "4.60273",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M602.785,454.19989a71.09006,71.09006,0,0,1-70.90479-66.09494c-.11525,1.65523-.19489,3.32029-.19489,5.00488a71.09968,71.09968,0,0,0,142.19936,0c0-1.68459-.07965-3.34965-.1949-5.00488A71.09,71.09,0,0,1,602.785,454.19989Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#231f20",
          opacity: "0.16"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "479.182 268.426 535.565 270.727 542.469 232.754 494.14 217.796 479.182 268.426",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M724.334,368.07712l.54562-17.93957L697.5532,343.2866l-49.19878-14.35326a10.27074,10.27074,0,1,0-5.48164,12.55535l47.82991,19.66107Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M706.72539,367.95142,722.0977,346.026l.3836.05107c.24124.03183,24.34848,3.257,40.15256,7.96332a12.63351,12.63351,0,0,1,8.18076,6.80183,13.25027,13.25027,0,0,1-1.84274,14.40983,14.17281,14.17281,0,0,1-14.56665,4.84008Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "492.621 518.459 476.984 518.458 469.545 458.145 492.623 458.146 492.621 518.459",
          fill: "#ffb8b8"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "614.374 511.69 598.913 514.027 582.541 455.503 602.81 452.054 614.374 511.69",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M761.70816,441.11609l-54.08209-9.20546-1.92351,3.96487A183.65217,183.65217,0,0,0,687.706,504.08793c-1.30584,19.99608-2.0741,41.11515-2.0993,63.25389-.03905,34.301,1.71127,66.12865,4.47813,95.08942l29.68206-.18583a701.05428,701.05428,0,0,1,3.49468-92.11432c2.27179-21.81641,5.43055-42.19545,9.08269-61A419.62473,419.62473,0,0,0,760.887,589.25522c10.37279,21.9358,31.70832,47.29156,42.081,69.22736q15.94307-1.91322,31.88615-3.82638c-8.37624-22.15114-23.319-54.49822-31.69519-76.64935-11.9764-31.67166-17.38631-53.14723-29.36271-84.81889a97.61477,97.61477,0,0,0,.39857-17.936c-.558-9.96443-2.63061-21.92172-8.689-29.89328Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M770.06329,424.60665c2.70081,5.04847-3.71356,15.5073-8.35513,16.50944l-53.4815-17.28777,26.929-78.03259,13.54851,2.46246,21.66846,3.65071,7.686,6.98632a13.513,13.513,0,0,1,3.727,14.26283Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M733.24663,394.45l-15.12956-9.65517-19.87785,19.96339L660.71691,439.6668a10.27076,10.27076,0,1,0,7.97721,11.13774l41.38042-31.01482Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M724.12187,409.51008,713.16117,385.079l.24-.30344c.151-.19051,15.2663-19.24628,27.40135-30.41071a12.63382,12.63382,0,0,1,10.03144-3.54359,13.25745,13.25745,0,0,1,11.43348,8.96171,14.1712,14.1712,0,0,1-3.30149,14.98926Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "534.37189",
          cy: "134.89961",
          r: "23.78567",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M777.42309,351.26194l-.091-.023-34.56543-8.64529A7.90147,7.90147,0,0,1,737.91,330.86036l1.14169-1.89964a12.95982,12.95982,0,0,0,.53039-12.37883,13.64408,13.64408,0,0,0-7.84464-6.7007l-.11462-.05506-.72592-.45173a5.66354,5.66354,0,0,1-2.99807-3.46441,5.84347,5.84347,0,0,1,.572-4.7123,28.59783,28.59783,0,0,1,13.0643-11.5175,29.49379,29.49379,0,0,1,21.67756-.65119,3.84942,3.84942,0,0,1,3.53969-.89953c17.90076,4.33528,19.374,16.35059,15.83762,35.638l4.18584,16.88211a8.45854,8.45854,0,0,1-7.54574,10.4702Zm-14.40152-62.043-.00224.00224Zm.43151-.10563.00337.00112Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M720.2731,715.99017h-8.92427l-1.59294-8.42557-4.07977,8.42557h-23.6693a5.32064,5.32064,0,0,1-3.02337-9.699L697.88506,693.237v-8.518l19.88119,1.18664Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M840.70475,715.99017h-8.92427l-1.59294-8.42557-4.07977,8.42557h-23.6693a5.32064,5.32064,0,0,1-3.02337-9.699L818.31671,693.237v-8.518l19.88119,1.18664Z",
          transform: "translate(-223.84174 -181.89597)",
          fill: "#2f2e41"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }