<template>
  <div>
    <div class="box__container">
      <div class="text-bold">Maximale Richt-Bandbreite</div>
      <div>
        Die maximale Richt-Bandbreite ist der Wert, den die angegebene
        Wertpapierkategorie höchstens in der Strategie haben darf. Diese ist
        verpflichtend und wird auch in den Vermögensverwaltungsvertrag
        aufgenommen. Um eine hohe Flexibilität innerhalb der Vermögensverwaltung
        zu bewahren, empfehlen wir Ihnen den hier eingetragenen Wert möglichst
        hoch zu setzen (mindestens 5% über der gewünschten Allokationsgröße;
        Beispiel: Sie möchten 30% Aktienquote. Der eingestellte Wert bei
        "Aktien/Mischfonds aktienlastig" sollte mindestens 35% sein).
      </div>
      <div class="font-bold">
        Die hier eingestellten Werte gelten für die gesamte Lebensdauer der
        Vermögensverwaltung.
      </div>
      <div>
        Daher kann bei einer Kategorieeinstellung mit einem maximalem Anteil von
        0% auch nie ein Wertpapier der entsprechenden Kategorie hinzugefügt
        werden.
      </div>
      <div class="font-bold">
        Um stets einen ausreichenden Puffer für Kursänderungen sicherzustellen,
        müssen alle Werte hier mindestens 5% höher sein als die Verteilung in
        den Varianten.
      </div>
      <div>
        Ist diese Bedingung nicht einhalten, können keine Varianten beantragt
        werden.
      </div>
      <div>Eingestellter Wert</div>
      <InputField
        isComponentHalfSize
        v-model="form.maxAnteilGeldmarktfonds"
        id="form.maxAnteilGeldmarktfonds"
        type="percent"
        :precision="0"
        label="Geldmarktfonds/Liquidität"
        validateUntouched
        :disabled="form.disabled"
        @change="updateStore({ maxAnteilGeldmarktfonds: $event }, 'settings')"
      />
      <InputField
        isComponentHalfSize
        v-model="form.maxAnteilRentenMischfonds"
        id="form.maxAnteilRentenMischfonds"
        type="percent"
        :precision="0"
        label="Renten-/Mischfonds rentenlastig"
        validateUntouched
        :disabled="form.disabled"
        @change="updateStore({ maxAnteilRentenMischfonds: $event }, 'settings')"
      />
      <InputField
        isComponentHalfSize
        v-model="form.maxAnteilMischfonds"
        id="form.maxAnteilMischfonds"
        type="percent"
        :precision="0"
        label="Mischfonds ausgewogen"
        validateUntouched
        :disabled="form.disabled"
        @change="updateStore({ maxAnteilMischfonds: $event }, 'settings')"
      />
      <InputField
        isComponentHalfSize
        v-model="form.maxAnteilAktienMischfonds"
        id="form.maxAnteilAktienMischfonds"
        type="percent"
        :precision="0"
        label="Aktien-/Mischfonds aktienlastig"
        validateUntouched
        :disabled="form.disabled"
        @change="updateStore({ maxAnteilAktienMischfonds: $event }, 'settings')"
      />
      <InputField
        isComponentHalfSize
        v-model="form.maxAnteilRohstofffonds"
        id="form.maxAnteilRohstofffonds"
        type="percent"
        :precision="0"
        label="Rohstofffonds"
        validateUntouched
        :disabled="form.disabled"
        @change="updateStore({ maxAnteilRohstofffonds: $event }, 'settings')"
      />
      <InputField
        isComponentHalfSize
        v-model="form.maxAnteilAlternativeAnlagefonds"
        id="form.maxAnteilAlternativeAnlagefonds"
        type="percent"
        :precision="0"
        label="Alternative Anlagefonds"
        validateUntouched
        :disabled="form.disabled"
        @change="
          updateStore({ maxAnteilAlternativeAnlagefonds: $event }, 'settings')
        "
      />
    </div>
  </div>
</template>

<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";

export default {
  mixins: [mixin],
  components: {
    InputField,
    ComboBox,
  },
  data() {
    return {
      form: {
        maxAnteilGeldmarktfonds: "",
        maxAnteilRentenMischfonds: "",
        maxAnteilMischfonds: "",
        maxAnteilAktienMischfonds: "",
        maxAnteilRohstofffonds: "",
        maxAnteilAlternativeAnlagefonds: "",
      },
    };
  },
  mounted() {
    this.initValuesSettings();
    this.updateWarnings(true);
  },
  watch: {
    schemaData(value) {
      this.initValuesSettings();
    },
  },
};
</script>

<style scoped>
</style>