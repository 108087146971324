var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InsuranceOptionMenu", { attrs: { title: "Wertpapierpositionen" } }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: {
                  type: "text",
                  label: "ISIN / WKN",
                  validateUntouched: true
                },
                on: { onBlur: _vm.validateFond },
                model: {
                  value: _vm.wertpapierepositionen.isin,
                  callback: function($$v) {
                    _vm.$set(_vm.wertpapierepositionen, "isin", $$v)
                  },
                  expression: "wertpapierepositionen.isin"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { type: "text", label: "Anlageart" },
                model: {
                  value: _vm.wertpapierepositionen.anlageart,
                  callback: function($$v) {
                    _vm.$set(_vm.wertpapierepositionen, "anlageart", $$v)
                  },
                  expression: "wertpapierepositionen.anlageart"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: {
                  type: "number",
                  label: "Anteile",
                  validateUntouched: true
                },
                model: {
                  value: _vm.wertpapierepositionen.anteile,
                  callback: function($$v) {
                    _vm.$set(_vm.wertpapierepositionen, "anteile", $$v)
                  },
                  expression: "wertpapierepositionen.anteile"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { type: "currency", label: "Anlagesumme" },
                model: {
                  value: _vm.wertpapierepositionen.anlagesumme,
                  callback: function($$v) {
                    _vm.$set(_vm.wertpapierepositionen, "anlagesumme", $$v)
                  },
                  expression: "wertpapierepositionen.anlagesumme"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("DatePickerField", {
                attrs: { label: "Datum Anteile", isValueAsString: "" },
                model: {
                  value: _vm.wertpapierepositionen.anteileDatum,
                  callback: function($$v) {
                    _vm.$set(_vm.wertpapierepositionen, "anteileDatum", $$v)
                  },
                  expression: "wertpapierepositionen.anteileDatum"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        [
          _c(
            "BaseModal",
            {
              ref: "verlaufwertpapierModal",
              attrs: { labelButtonCancel: "Abbrechen" },
              on: {
                onConfirmButton: _vm.saveVerlaufWertpapierposition,
                onCancelButton: function($event) {
                  return _vm.closeModal()
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "modalTitle",
                  fn: function() {
                    return [_vm._v(" Verlauf Wertpapierpositionen ")]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("InputField", {
                      attrs: { type: "text", label: "Wertpapierposition" },
                      model: {
                        value: _vm.verlaufAnteileDescription,
                        callback: function($$v) {
                          _vm.verlaufAnteileDescription = $$v
                        },
                        expression: "verlaufAnteileDescription"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("DatePickerField", {
                      attrs: { label: "Stand Anteile", isValueAsString: "" },
                      model: {
                        value: _vm.verlaufAnteileEdit.datum,
                        callback: function($$v) {
                          _vm.$set(_vm.verlaufAnteileEdit, "datum", $$v)
                        },
                        expression: "verlaufAnteileEdit.datum"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("InputField", {
                      attrs: {
                        type: "number",
                        label: "Anteile",
                        precision: 2,
                        validateUntouched: true
                      },
                      model: {
                        value: _vm.verlaufAnteileEdit.anteile,
                        callback: function($$v) {
                          _vm.$set(_vm.verlaufAnteileEdit, "anteile", $$v)
                        },
                        expression: "verlaufAnteileEdit.anteile"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }