<template >
  <div>
    <h1>Ups!</h1>
    <p>
      Da ist etwas schiefgelaufen!
    </p>
    <UndrawLocationSearch />
  </div>
</template>

<script>
  import UndrawLocationSearch from '@/components/icons/undraw/UndrawLocationSearch.vue'

  export default {
    components: {
      UndrawLocationSearch,
    }
  } 
</script>

