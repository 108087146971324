<template>
  <div>
    <PageHeaderTitleNavigation 
        :id="position.isin" 
        :actions="headerActions" 
        :noPrimaryAction="true"
        :showBackButton="false"
        @action-DELETE="openModalDeleteFond(position)"
      >     
        <template #title><slot name="title">
          <div class="cards__item--header ">
            <a @click="gotoFondsinfo(position)">{{ position.fondsname }}</a>
          </div>
        </slot></template>
        <template #subtitle><slot name="subtitle">
          <div class="font-bold">
              <span>ISIN: {{position.isin}}</span>
          </div>
        </slot></template>
    </PageHeaderTitleNavigation>      

    <div class="cards__item--inputs">  
      <InputField
        v-if="position.art == 'KAUF'"
        label="Einmalanlage"
        type="currency"
        precision="2"
        id="einmalBetrag"
        placeholder="mind. 100,- EUR"
        :value="position.einmalBetrag"
        @input="onChangeInputField($event, position, 'einmalBetrag')"
        :disabled="disabled"
      />
      <InputField
        v-if="position.art == 'SPARPLAN'"
        label="Sparplan"
        type="currency"
        precision="2"
        id="planBetrag"
        placeholder="mind. 10,- EUR"
        :value="position.planBetrag"
        @input="onChangeInputField($event, position, 'planBetrag')"
        :disabled="disabled"
      />
      <InputField
        v-if="!isWebservice"
        label="Bemerkung"
        id="bemerkung"
        :value="position.bemerkung"
        @input="onChangeInputField($event, position, 'bemerkung')"
      />
      <ComboBox
        label="zum:"
        v-if="position.art == 'SPARPLAN'"
        id="planBeginnTag"
        :value="position.planBeginnTag"
        :values="[
          { label: '', value: '' },
          { label: '1.', value: '1' },
          { label: '15.', value: '15' },
        ]"
        @input="onChangeInputField($event, position, 'planBeginnTag')"
        :disabled="disabled"
      />
      <ComboBox
        label="Turnus"
        v-if="position.art == 'SPARPLAN'"
        :sortComboboxValues="false"
        id="planFrequenz"
        :value="position.planFrequenz"
        :values="[
          { label: '', value: '' },
          { label: 'monatlich', value: 'MONATLICH' },
          { label: 'vierteljährlich', value: 'VIERTELJ' },
          { label: 'halbjährlich', value: 'HALBJ' },
          { label: 'jährlich', value: 'JAEHRLICH' },
        ]"
        @input="onChangeInputField($event, position, 'planFrequenz')"
        :disabled="disabled"
      />
    </div>
    <DeletePositionModal
      ref="deleteModal"
      :position="position"
      :positionLabel="position.art"
      @delete="removePosition()"
    >
    </DeletePositionModal>
  </div>
</template>
<script>
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import ANTRAG_TYPES from "@/store/antrag/types";
import BaseCollapsable from "@/components/core/BaseCollapsable.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import {formatNumber} from '@/helpers/number-formatter.js';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import validator from "@/mixins/validator";

export default {
  mixins: [antragMixin, validator],
  validators: {},
  data() {
    return {
      sparplanDynamik: 3,
      spKontoFlex: false,
    };
  },
  components: {
    InputField,
    BaseCollapsable,
    FormLabel,
    DatePickerField,
    ComboBox,
    DeletePositionModal,
    PageHeaderTitleNavigation,
  },
  props: {
    position: {},
    antragId: {},
    positionDataAdditional: {},
    isWebservice: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    positionWarnings: [],
  },
  watch: {
    positionWarnings(newValue) {
      this.updateWarnings();
    },
  },
  computed:{
    headerActions() {
      return [PageHeaderSimpleAction('DELETE', 'Position löschen')];
    },
  },
  mounted() {
    if (
      this.position.sparplanDynamik != -1 &&
      this.position.sparplanDynamik != 3 &&
      this.position.sparplanDynamik != null
    ) {
      this.position.sparplanDynamikFrei = this.position.sparplanDynamik;
      this.position.sparplanDynamik = 0;
    } else if (!this.position.sparplanDynamik) {
      this.position.sparplanDynamik = 3;
    }
    if (!this.position.spKontoFlex) {
      this.position.spKontoFlex = false;
    }

    this.updateWarnings();
  },
  methods: {
    commitUpdatePositionsFromEvent(positionChange, isin, category) {
      const payload = {
        id: this.antragId,
        categoryId: category,
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField($event, position, field) {
      let positionChange = { [field]: $event };
      if (field === "sparplanDynamikFrei") {
        positionChange = { [field]: formatNumber($event) };
      }

      this.commitUpdatePositionsFromEvent(
        positionChange,
        position.isin,
        position.art
      );
    },
    openModalDeleteFond() {
      this.$refs.deleteModal.open();
    },
    removePosition() {
      this.deletePosition(this.antragId, this.position.art, this.position);
    },
    updateWarnings() {
      this.positionWarnings?.forEach(warn =>  {
        if (this.position?.posGrpId === warn.posGrpId) {   
          this.$pushErrors(warn.id, [warn.message]);
        }
      });
    },
  },
};
</script>

<style src='@/styles/antrag.scss' lang='scss' scoped></style>
