(function(context) {
  let _channels = null; 
  let _tabId = null; 
  const _prefix = 'polyBC_';

  const getRandomString = () => Math.random().toString(36).substr(2, 10)
  const isEmpty = obj => !Object.keys(obj).length;
  const getTimestamp = () => (new Date()).getTime();

  const buildResponse = data => {
    return {
      timestamp: getTimestamp(),
      isTrusted: true,
      target: null, 
      currentTarget: null,
      data,
      bubbles: false,
      cancelable: false,
      defaultPrevented: false,
      lastEventId: '',
      origin: context.location.origin
    };
  };

  /**
   * Handler of the 'storage' function.
   * Called when another window has sent a message.
   * @param {Object} ev - the message.
   * @private
   */
  const onStorage = ev => {
    const key = ev.key;
    const newValue = ev.newValue;
    const isRemoved = !newValue;
    let obj = null;

    if (key?.indexOf?.(`${_prefix}message_`) > -1 && !isRemoved) {

      try {
        obj = JSON.parse(newValue);
      } catch(ex) {
        throw 'Message conversion has resulted in an error.';
      }

      if (obj.tabId !== _tabId && _channels?.[obj?.channelId]) {

        const subscribers = _channels[obj.channelId];
        subscribers.forEach(sub => {
          if (!sub.closed) {
            const event = new MessageEvent('message', obj.message);
            sub.dispatchEvent(event);
            setTimeout(() => sub.onmessage?.(event))
          }
        });
        context.localStorage.removeItem(key);
      }
    }
  };

  /**
   * Creates a new BroadcastChannel
   * @param {String} channelName - the channel name.
   * return {BroadcastChannel}
   */
  class _BroadcastChannel extends EventTarget {

    channelId = '';
    channelName = '';
    name = '';
    closed = false;

    constructor(channelName = '') {
      super();
      if (!context.localStorage) {
        throw 'localStorage not available';
      }

      this.channelName = channelName;
      const _channelId = _prefix + channelName
      const isFirstChannel = (_channels === null);

      this.channelId = _channelId;

      _tabId = _tabId || getRandomString(); 
      _channels = _channels || {}; 
      _channels[_channelId] = _channels[_channelId] || [];

      _channels[_channelId].push(this);
      this.name = _channelId + '::::' + getRandomString() + getTimestamp();

      if (isFirstChannel) {
        context.addEventListener('storage', onStorage.bind(this), false);
      }
    }

    postMessage(data) {
      if (!_channels) return;
      if (this.closed) return

      // Build the event-like response.
      const msgObj = buildResponse(data);

      // SAME-TAB communication.
      const subscribers = _channels[this.channelId] || [];
      subscribers.forEach(sub => {
        if (sub.closed || sub.name === this.name) return;
        const event = new MessageEvent('message', msgObj);
        sub.dispatchEvent(event);
        setTimeout(() => sub.onmessage?.(event))
      });

      // CROSS-TAB communication.
      // Adds some properties to communicate among the tabs.
      const editedObj = {
        channelId: this.channelId,
        bcId: this.name,
        tabId: _tabId,
        message: msgObj
      };

      try {
        const editedJSON = JSON.stringify(editedObj);
        const lsKey = `${_prefix}message_${getRandomString()}_${this.channelId}`;
        // Set localStorage item (and, after that, removes it).
        context.localStorage.setItem(lsKey, editedJSON);
        setTimeout(() => context.localStorage.removeItem(lsKey), 1000);
      } catch (ex) {
        throw 'Message conversion has resulted in an error.';
      }
    }

    /**
     * Closes a Broadcast channel.
     */
    close() {
      this.closed = true;
      const subscribers = _channels[this.channelId];
      const index = subscribers?.indexOf(this) || -1;
      if (index > -1) {
        subscribers.splice(index, 1);
      }
      if (!subscribers?.length) {
        delete _channels[this.channelId];
      }
      // If we have no channels, remove the listener.
      if (isEmpty(_channels) ) {
        context.removeEventListener('storage', onStorage.bind(this));
      }
    }
  }

  context.BroadcastChannel = context.BroadcastChannel || _BroadcastChannel;
  
})(window.top);