var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPlaceholderVisible && _vm.editor
    ? _c(
        "div",
        {
          staticClass: "margin-0",
          staticStyle: { "background-color": "aqua" }
        },
        [
          _vm.editor
            ? _c(
                "bubble-menu",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.bubbleSectionHidden,
                      expression: "!bubbleSectionHidden"
                    }
                  ],
                  ref: "menu",
                  attrs: {
                    editor: _vm.editor,
                    "tippy-options": {
                      theme: "smartmsc",
                      duration: 100,
                      maxWidth: 1000
                    },
                    shouldShow: function(ref) {
                      var editor = ref.editor
                      var view = ref.view
                      var state = ref.state
                      var oldState = ref.oldState
                      var from = ref.from
                      var to = ref.to

                      return view.hasFocus()
                    }
                  }
                },
                [
                  _c(
                    "BaseButton",
                    {
                      staticClass: "bubble_close",
                      attrs: { isClear: "" },
                      on: { click: _vm.menuClose }
                    },
                    [_c("PhXCircle", { attrs: { size: "16" } })],
                    1
                  ),
                  !_vm.placeholderCategory
                    ? _c(
                        "div",
                        [
                          _c(
                            "BaseButton",
                            {
                              staticClass: "bubble_button info",
                              attrs: { isClear: "" }
                            },
                            [_vm._v(" Einfügen: ")]
                          ),
                          _vm._l(_vm.categories, function(categorie) {
                            return _c(
                              "BaseButton",
                              {
                                key: categorie.name,
                                staticClass: "bubble_button menu",
                                attrs: { isClear: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeCategory(categorie.name)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(categorie.name) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "BaseButton",
                            {
                              staticClass: "bubble_button",
                              attrs: { isClear: "" },
                              on: { click: _vm.menuBack }
                            },
                            [
                              _vm.bubbleSectionIndex > 0
                                ? _c("PhCaretLeft", { attrs: { size: "16" } })
                                : _c("PhRewind", { attrs: { size: "16" } })
                            ],
                            1
                          ),
                          _vm._l(_vm.bubbleSection, function(placeholder) {
                            return _c(
                              "BaseButton",
                              {
                                key: placeholder.label,
                                staticClass: "bubble_button menu",
                                attrs: { isClear: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.insertPlaceholder(
                                      placeholder.value,
                                      _vm.editor
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(placeholder.label) + " ")]
                            )
                          }),
                          _vm.bubbleSectionIndex + 1 < _vm.data.length
                            ? _c(
                                "BaseButton",
                                {
                                  staticClass: "bubble_button",
                                  attrs: { isClear: "" },
                                  on: { click: _vm.menuForward }
                                },
                                [_c("PhCaretRight", { attrs: { size: "16" } })],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }