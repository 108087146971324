var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Versicherung Block-Bestandsübertrag" }
      }),
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "block" } })
        : _c(
            "div",
            { staticClass: "box__container" },
            [
              _c(
                "div",
                { staticClass: "fileCard" },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Auskunftsvollmacht:")
                  ]),
                  _vm.hasFileAuskunftsvollmacht
                    ? _c("DownloadLink", {
                        attrs: {
                          title: _vm.selectedVuBlock.fileNameAuskunftsvollmacht,
                          target: "_blank",
                          rel: "noopener noreferer",
                          downloadServicePath: "/vers_uebertrag_block",
                          filename: "Auskunftsvollmacht.pdf",
                          queryParams: {
                            vuBlockId: _vm.selectedVuBlock.id,
                            type: _vm.FILE_AUSKUNFTSVOLLMACHT
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.hasFileAuskunftsvollmacht && _vm.isEditAllowed
                    ? _c("BaseButton", {
                        attrs: { label: "Löschen", isSecondary: true },
                        on: {
                          click: function($event) {
                            return _vm.deleteFile(
                              $event,
                              _vm.FILE_AUSKUNFTSVOLLMACHT
                            )
                          }
                        }
                      })
                    : _vm._e(),
                  !_vm.hasFileAuskunftsvollmacht && _vm.isEditAllowed
                    ? _c(
                        "BaseFileSelect",
                        {
                          attrs: {
                            accept: ".pdf",
                            isSecondary: "",
                            animated: _vm.loadingFileAuskunftsvollmacht
                          },
                          on: {
                            files: function($event) {
                              return _vm.onFileUpload(
                                $event,
                                _vm.FILE_AUSKUNFTSVOLLMACHT
                              )
                            }
                          }
                        },
                        [_vm._v(" Hochladen ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "fileCard" },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Einverständniserklärung:")
                  ]),
                  _vm.hasFileEinverstaendnis
                    ? _c("DownloadLink", {
                        attrs: {
                          title: _vm.selectedVuBlock.fileNameEinverstaendnis,
                          target: "_blank",
                          rel: "noopener noreferer",
                          downloadServicePath: "/vers_uebertrag_block",
                          filename: "Einverständniserklärung.pdf",
                          queryParams: {
                            vuBlockId: _vm.selectedVuBlock.id,
                            type: _vm.FILE_EINVERSTAENDNIS
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.hasFileEinverstaendnis && _vm.isEditAllowed
                    ? _c("BaseButton", {
                        attrs: { label: "Löschen", isSecondary: true },
                        on: {
                          click: function($event) {
                            return _vm.deleteFile(
                              $event,
                              _vm.FILE_EINVERSTAENDNIS
                            )
                          }
                        }
                      })
                    : _vm._e(),
                  !_vm.hasFileEinverstaendnis && _vm.isEditAllowed
                    ? _c(
                        "BaseFileSelect",
                        {
                          attrs: {
                            accept: ".pdf",
                            isSecondary: "",
                            animated: _vm.loadingFileEinverstaendnis
                          },
                          on: {
                            files: function($event) {
                              return _vm.onFileUpload(
                                $event,
                                _vm.FILE_EINVERSTAENDNIS
                              )
                            }
                          }
                        },
                        [_vm._v(" Hochladen ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("InputCheckboxItem", {
                attrs: {
                  label: "mit Rechten und Pflichten",
                  disabled: !_vm.isEditAllowed
                },
                model: {
                  value: _vm.selectedVuBlock.mitRechtenUndPflichten,
                  callback: function($$v) {
                    _vm.$set(_vm.selectedVuBlock, "mitRechtenUndPflichten", $$v)
                  },
                  expression: "selectedVuBlock.mitRechtenUndPflichten"
                }
              })
            ],
            1
          ),
      _vm.loading
        ? _c("GhostLoading", {
            attrs: { type: "table", title: _vm.TABLE_TITLE }
          })
        : !_vm.loading && _vm.tableRows.length
        ? _c("Table", {
            staticClass: "table-container",
            attrs: {
              tableId: "302248ec-ba7d-41e2-bead-c41ff109da2f",
              title: _vm.TABLE_TITLE,
              headers: _vm.tableHeaders,
              rows: _vm.tableRows,
              rowsPerPage: 20,
              hidePagination: _vm.tableRows.length < 21,
              exportConfig: { roottext: _vm.TABLE_TITLE },
              dragnDropFilesOnRow: _vm.isEditAllowed
            },
            on: {
              action: function($event) {
                return _vm.executeAction($event)
              },
              files: function($event) {
                return _vm.setFiles($event)
              },
              "click-statusText": function($event) {
                return _vm.showStatusModal($event)
              },
              "click-versStatusText": function($event) {
                return _vm.showVersStatusModal($event)
              },
              "click-filesText": function($event) {
                return _vm.showFilesModal($event)
              },
              "click-mailsText": function($event) {
                return _vm.showMailsModal($event)
              }
            }
          })
        : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } }),
      _c(
        "BaseModal",
        {
          ref: "showStatusModal",
          attrs: {
            labelButtonConfirm: "Ok",
            showCancelButton: false,
            size: "lg",
            modalTitle: _vm.selectedVuBlockGesell.gesellName
          }
        },
        [
          _vm.selectedVuBlockGesell.statusList &&
          _vm.selectedVuBlockGesell.statusList.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "bb109356-9f47-48d1-a0c2-b9542d74f644",
                  title: "Status",
                  rowsPerPage: 10,
                  hidePagination:
                    _vm.selectedVuBlockGesell.statusList.length < 11,
                  headers: _vm.tableStatusHeaders,
                  rows: _vm.selectedVuBlockGesell.statusList
                }
              })
            : _c("NoData")
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "showVersStatusModal",
          attrs: {
            labelButtonConfirm: "Ok",
            showCancelButton: false,
            size: "lg",
            modalTitle: _vm.selectedVuBlockGesell.gesellName
          }
        },
        [
          _vm.selectedVuBlockGesell.versDetails &&
          _vm.selectedVuBlockGesell.versDetails.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "cfc0f3e2-fe53-4452-ae82-c7615285f57d",
                  title: "Versicherungen",
                  rowsPerPage: 10,
                  hidePagination:
                    _vm.selectedVuBlockGesell.versDetails.length < 11,
                  headers: _vm.tableVersStatusHeaders,
                  rows: _vm.selectedVuBlockGesell.versDetails
                },
                on: {
                  "click-polNr": function($event) {
                    return _vm.openVersVertrag($event)
                  }
                }
              })
            : _c("NoData")
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "showFilesModal",
          attrs: {
            labelButtonConfirm: "Ok",
            showCancelButton: false,
            size: "md",
            modalTitle: _vm.selectedVuBlockGesell.gesellName
          }
        },
        [
          _vm.tableFilesRows && _vm.tableFilesRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "06727a80-9ad7-47ee-8047-35b7ef5865cb",
                  title: "zus. Dateien",
                  rowsPerPage: 10,
                  hidePagination: _vm.tableFilesRows.length < 11,
                  headers: _vm.tableFilesHeaders,
                  rows: _vm.tableFilesRows
                },
                on: {
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "fileName",
                      fn: function(row) {
                        return [
                          _c("DownloadLink", {
                            attrs: {
                              title: row.fileName,
                              filename: row.fileName,
                              downloadServicePath:
                                "/vers_uebertrag_block_gesell",
                              queryParams: {
                                vubGesellId: _vm.selectedVuBlockGesell.id,
                                fileId: row.id
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1340621994
                )
              })
            : _c("NoData")
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "showMailsModal",
          attrs: {
            labelButtonConfirm: "Ok",
            showCancelButton: false,
            size: "xl",
            modalTitle: _vm.selectedVuBlockGesell.gesellName
          }
        },
        [
          _vm.selectedVuBlockGesell.mails &&
          _vm.selectedVuBlockGesell.mails.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "e2f9fd03-9e94-4869-8432-8ad14acb4a92",
                  title: "E-Mails",
                  rowsPerPage: 10,
                  hidePagination: _vm.tableMailsRows.length < 11,
                  headers: _vm.tableMailsHeaders,
                  rows: _vm.tableMailsRows
                },
                on: {
                  "click-betreff": function($event) {
                    return _vm.viewEmail($event)
                  }
                }
              })
            : _c("NoData", { attrs: { noIcon: true } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "editGesellModal",
          attrs: {
            labelButtonConfirm: "Speichern",
            showCancelButton: true,
            size: "md",
            modalTitle: _vm.selectedVuBlockGesell.gesellName
          },
          on: { onConfirmButton: _vm.confirmEditGesell }
        },
        [
          _c("ComboBox", {
            attrs: { values: _vm.dataStatusEntries },
            model: {
              value: _vm.dataEditGesell.status,
              callback: function($$v) {
                _vm.$set(_vm.dataEditGesell, "status", $$v)
              },
              expression: "dataEditGesell.status"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.dataEditGesell.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.dataEditGesell, "bemerkung", $$v)
              },
              expression: "dataEditGesell.bemerkung"
            }
          }),
          _c(
            "BaseFileSelect",
            {
              attrs: { accept: ".pdf, .msg", isSecondary: "" },
              on: {
                files: function($event) {
                  return _vm.onFileUploadEdit($event)
                }
              }
            },
            [_vm._v(" zusätzliche Datei hochladen ")]
          ),
          _vm.dataEditGesell.file && _vm.dataEditGesell.file.fileName
            ? _c("BaseButton", {
                attrs: { label: "Löschen", isSecondary: true },
                on: {
                  click: function() {
                    return (_vm.dataEditGesell.file = {})
                  }
                }
              })
            : _vm._e(),
          _vm.dataEditGesell.file && _vm.dataEditGesell.file.fileName
            ? [
                _c("hr"),
                _vm._v(" " + _vm._s(_vm.dataEditGesell.file.fileName) + " "),
                _c("hr")
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "editCommentForMaklerModal",
          attrs: {
            labelButtonConfirm: "Speichern",
            showCancelButton: true,
            size: "md",
            modalTitle: _vm.selectedVuBlockGesell.gesellName
          },
          on: { onConfirmButton: _vm.confirmEditCommentForMakler }
        },
        [
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.dataEditGesell.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.dataEditGesell, "bemerkung", $$v)
              },
              expression: "dataEditGesell.bemerkung"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }