<template>
    <div>
        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.parent.label"
            :subtitle="$appNavigation.currentMenu.label"
            :defaultMenu="$appNavigation.currentTabMenu"
            :actions="actions"
            @action-REMOVE_OVERTAKEN="$refs.confirmDeleteOvertaken.open()"
            @action-RESET="doConfirm('zurücksetzen')"
        />

        <div class="box__container">
            <div  class="box__title">Depotpositionen GD200-Limits</div>
            <div class="layout__negative-margin--8 antrag-action-button__container">
                <InputToggleSwitch v-model="includeStruktur" label="Limits der Untermakler anzeigen" inLineLabel suppressValidationMessage @input="reload()"/>
            </div>
        </div>
        <div class="box__container">
            <div v-if="!loading && rows && rows.length">
                <Table
                    tableId="14393b6b-e694-40c5-a82b-a13541cf522e"
                    :title="TABLE_TITLE"
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    hidePagination
                    rowId="rowId"
                    :mobileConfig="{title: 'wertpapierName', headers: ['isin', 'aktuellKurs', 'unteresLimit', 'oberesLimit']}"
                    @action-edit="editLimit"
                    @action-delete="selectedLimit = $event; $refs.confirmDelete.open()"
                    @action-factsheet="viewDocument($event, 'FACTSHEET')"
                    @action-kag="viewDocument($event, 'MR')"
                    @action-kid="viewDocument($event, 'KIID')"
                    @action-fondsinfo="gotoFondsinfo($event && $event.isin)"
                    @click-kundenName="openCustomerNewTab"
                >
                </Table>
            </div>
            <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
            <NoData v-else :title="TABLE_TITLE" />
        </div>
        <BaseModal ref="confirmDelete" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onCancelButton="selectedLimit = null" 
            @onCloseButton="selectedLimit = null" 
            @onConfirmButton="removeLimit()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Löschen
            </template>
            <div>Wollen Sie wirklich das Limit löschen?</div>
        </BaseModal>
        
        <BaseModal ref="confirmDeleteOvertaken" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onConfirmButton="removeOvertaken()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Löschen
            </template>
            <div>Wollen Sie wirklich alle überschrittene Limits löschen?</div>
        </BaseModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { PhWarning } from "phosphor-vue";
import mixin from '@/mixins/limit/limit-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import {TextColumn} from "@/components/table2/table_util.js";
import {wpListeMixin} from '@/mixins/wp-suche.js';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const TABLE_TITLE = 'Depotpositionen GD200-Limits';

export default {
    mixins: [mixin, wpListeMixin],
    components: {
        BaseButton,
        Table,
        InputToggleSwitch,
        PhWarning,
        BaseModal,
        PageHeaderTitleNavigation,
        GhostLoading,
        NoData,
    },
    data() {
        return {
            TABLE_TITLE,
            loading: false,
            includeStruktur: '0',
            removeAction: null,
            selectedLimit: null,
            columnLabelAusgangskurs: 'GD200',
            modus: 'LIMIT_GD200_BGS',
        }
    },
    mounted() {
        this.reload();
    },
    computed: {
        ...mapGetters({
            depotpositionenGD200limits: LIMIT_TYPES.GETTERS.GD200_LIMIT_LIST,
        }),
        headers() {
            const headers = this.headersWertpapierLimits();
            const kundenLinkName = TextColumn("kundenName", "Kunde");
            if (!this.isCustomerView) {
                kundenLinkName.makeLink();
                 // insert after status
                headers.lockedLeft.splice(1, 0,
                    TextColumn("kundennr", "Kundennr").makeAlwaysVisible(),
                    kundenLinkName);
            }
            if ('1' === this.includeStruktur) {
                // insert after status
                headers.lockedLeft.splice(1, 0, TextColumn("maklernr", "Maklernr").makeAlwaysVisible());
            }
            return headers;
        },
        rows() {
            return this.addFondsActionsAndStatus(this.depotpositionenGD200limits);
        },
        actions() {
            return [
                PageHeaderSimpleAction('REMOVE_OVERTAKEN', 'Überschrittene Limits löschen'),
                PageHeaderSimpleAction('RESET', 'Überschrittene Limits zurücksetzen').withDisabled(() => !this.rows?.length ),
            ]
        }
    },
    methods: {
        editLimit(event) {
            if (event?.id) {
                this.$addBreadcrumb({
                    label: `zurück zu den Depotpositionen GD200-Limits`,
                    fullPath: this.$route.fullPath,
                    breadcrumb: 'Wertpapierlimit',
                });
                this.$router.push(`${this.mainPath}/gd200limit/LIMIT_GD200_BGS/${event.id || ''}`)
            }
        },
        removeLimit() {
            if (this.selectedLimit?.id) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_FONDS_LIMIT, { id: this.selectedLimit.id, modus: this.modus});
            }
        },
        async reload() {
            try {
                this.loading = true;
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_GD200_LIMIT_LIST, {includeStruktur: this.includeStruktur, modus: this.modus});
            } finally {
                this.loading = false;
            }
        },
        removeOvertaken() {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_OVERTAKEN_WERTPAPIER_LIMITS, {includeStruktur: this.includeStruktur, modus: this.modus });
        },
        async doConfirm(exec) {
            await this.doConfirmAction(exec, 'gd200LimitListB');
        },
    },

}
</script>

<style>

</style>