import { isRolesIncludes } from '@/router/guards';

import { VIEW_ROLES } from '../roles';

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "liste" */ '@/views/limit/LimitList.vue'),
    children: [
      {
        path: '',
        redirect: () => isRolesIncludes([VIEW_ROLES.VIEW_BROKER]) ? 'global-wertpapierlimits' : 'depotlimit',
      },
      {
        path: 'depotlimit',
        component: () => import(/* webpackChunkName: "depotlimit" */ '@/views/limit/Depotlimit.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_CUSTOMER], [VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'depotlimits',
        component: () => import(/* webpackChunkName: "depotlimit" */ '@/views/limit/DepotlimitList.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'depotpositionlimits',
        component: () => import(/* webpackChunkName: "depotpositionlimits" */ '@/views/limit/DepotpositionLimits.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_CUSTOMER], [VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'automatic-depotpositionlimits',
        component: () => import(/* webpackChunkName: "automatic-depotpositionlimits" */ '@/views/limit/AutomaticDepotpositionLimitList.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_CUSTOMER], [VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'automatic-depotpositionlimit/:id',
        component: () => import(/* webpackChunkName: "automatic-depotpositionlimit" */ '@/views/limit/AutomaticDepotpositionLimit.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_CUSTOMER], [VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'risikoklassenlimit',
        component: () => import(/* webpackChunkName: "risikoklassenlimit" */ '@/views/limit/RisikoklassenLimit.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_CUSTOMER],[VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'risikoklassenlimits',
        component: () => import(/* webpackChunkName: "risikoklassenlimits" */ '@/views/limit/RisikoklassenLimitList.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'global-wertpapierlimits',
        component: () => import(/* webpackChunkName: "global-wertpapierlimits" */ '@/views/limit/GlobalWertpapierlimitList.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'wertpapierlimit/:isin',
        component: () => import(/* webpackChunkName: "wertpapierlimit" */ '@/views/limit/Wertpapierlimit.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'global-gd200-limit',
        component: () => import(/* webpackChunkName: "global-gd200-limit" */ '@/views/limit/GlobalGD200LimitList.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'depotpositionen-gd200-limit',
        component: () => import(/* webpackChunkName: "depotpositionen-gd200-limit" */ '@/views/limit/DepotpositionGD200LimitList.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'gd200limit/:modus/:id',
        component: () => import(/* webpackChunkName: "gd200limit" */ '@/views/limit/GD200Limit.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'wertpapierkategorie-limit',
        component: () => import(/* webpackChunkName: "wertpapierkategorie-limit" */ '@/views/limit/WertpapierkategorieLimit.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_CUSTOMER], [VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
      {
        path: 'wertpapierkategorie-limits',
        component: () => import(/* webpackChunkName: "wertpapierkategorie-limits" */ '@/views/limit/WertpapierkategorieLimitList.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_CUSTOMER], [VIEW_ROLES.VIEW_BROKER]]
          },
        },
      },
    ]
  },

]