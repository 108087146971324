var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ComboBox", {
        attrs: {
          label: "Beziehung",
          isComponentHalfSize: _vm.isComponentHalfSize,
          values: _vm.beziehungArtValues,
          disabled: !_vm.isEditable
        },
        on: {
          change: function($event) {
            return _vm.dataChangedCombo("relationArt", $event)
          }
        },
        model: {
          value: _vm.form.relationArt,
          callback: function($$v) {
            _vm.$set(_vm.form, "relationArt", $$v)
          },
          expression: "form.relationArt"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Gesetzlicher Vertreter/in",
          inLineLabel: true,
          disabled: !_vm.isEditable
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.isLegalAgent,
          callback: function($$v) {
            _vm.$set(_vm.form, "isLegalAgent", $$v)
          },
          expression: "form.isLegalAgent"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Zusätzlicher Depotinhaber/in",
          inLineLabel: true,
          disabled: !_vm.isEditable
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.isExtraDepotOwner,
          callback: function($$v) {
            _vm.$set(_vm.form, "isExtraDepotOwner", $$v)
          },
          expression: "form.isExtraDepotOwner"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: _vm.vollmachtLabel,
          inLineLabel: true,
          disabled: !_vm.isEditable
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.isMandatory,
          callback: function($$v) {
            _vm.$set(_vm.form, "isMandatory", $$v)
          },
          expression: "form.isMandatory"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }