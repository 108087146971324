import WERTPAPIERORDER_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue'

export default {
  [WERTPAPIERORDER_TYPES.MUTATIONS.GET_WERTPAPIERORDER_SUCCESS](state, payload) {
    Vue.set(state, 'data', Object.assign({}, payload.data || {}, {dataHasChanged: false }));
    Vue.set(state, 'lagerstellenConfig', Object.assign({}, payload.data?.lagerstellenConfig || {}));
    Vue.set(state, 'comboboxValues', Object.assign({}, payload.comboboxValues));
    Vue.set(state, 'warnings', setWarningStepKey(payload.warnings));
    Vue.set(state, 'capitalbankKYCComponents', Object.assign([], payload.capitalbankKYCComponents || []));
    Vue.set(state, 'hellobankSelbstauskunftComponents', Object.assign([], payload.hellobankSelbstauskunftComponents || []));
    Vue.set(state, 'semperConstantiaComponents', Object.assign([], payload.semperConstantiaComponents || []));
    Vue.set(state, 'positions', Object.assign([], payload.positions));
    Vue.set(state, 'antrag', Object.assign([], payload));
    if (payload.beratungsMappeData && Object.keys(payload.beratungsMappeData).length) {
      Vue.set(state, 'beratungsMappeData', Object.assign({}, payload.beratungsMappeData));
    }
    Vue.set(state.data, 'hatPDF', payload.hatPDF || payload.data?.hatPDF);
  },
  
  [WERTPAPIERORDER_TYPES.MUTATIONS.GET_LAGERSTELLEN_SUCCESS](state, payload) {
    Vue.set(state, 'lagerstellen', Object.assign([], payload.lagerstellen || []));
    Vue.set(state, 'depotNumbers', Object.assign({}, payload.depotNumbers || {}));
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.GET_DEPOTS_SUCCESS](state, payload) {
    Vue.set(state, 'depots', Object.assign({}, payload.depots || {}));
    Vue.set(state, 'orderbooks', Object.assign({}, payload.orderbooks || {}));
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.GET_VENUES](state, payload) {
    Vue.set(state.venues, payload.isin, payload.value || {});
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.SAVE_WERTPAPIERORDER_SUCCESS](state, payload) {
    Vue.set(state, 'data', Object.assign({}, payload.data || {}, {dataHasChanged: false }));
    Vue.set(state, 'comboboxValues', Object.assign({}, state.comboboxValues || {}, payload.comboboxValues || {}));
    Vue.set(state, 'warnings', setWarningStepKey(payload.warnings));
    
    if (!state.data.dataHasChanged || !state.positions?.length) {
      Vue.set(state, 'positions', Object.assign([], payload.positions));
    } else {
      const positions = [];
      (payload.positions || []).forEach(pos => {
        const oldPos = state.positions.find(p => (p.posGrpId && pos.posGrpId === p.posGrpId) 
          || p.positionTyp === pos.positionTyp && p.isin === pos.isin);
        if (oldPos && (+oldPos.betrag !== +pos.betrag || oldPos.betragModus !== pos.betragModus)) {
          positions.push(Object.assign(pos, { betrag: oldPos.betrag, betragModus: oldPos.betragModus }));
        } else {
          positions.push(pos);
        }
      });
      Vue.set(state, 'positions', positions);
    }
    Vue.set(state.data, 'hatPDF', payload.hatPDF);
  },
  
  [WERTPAPIERORDER_TYPES.MUTATIONS.GET_POSITION_DATA_COMPONENTS_SUCCESS](state, payload) {
    if (payload?.data) {
      Vue.set(state, 'comboboxValues', Object.assign({}, state.comboboxValues || {}, payload.comboboxValues || {}));
      Vue.set(state, 'positionData', Object.assign({}, state.positionData || {}, payload.data || {}, {dataHasChanged: false}));
      Vue.set(state, 'positionDataComponents', Object.assign({}, payload.components || {}));
    }
  },
 
  [WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_POSITION_DATA_STORE](state, payload = {}) {
    const data = Object.assign({}, state.positionData || {}, payload, { positionDataHasChanged: true });
    Vue.set(state, 'positionData', data);
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.SAVE_WERTPAPIERORDER_FAILED](state, payload) {
    Vue.set(state, 'data', Object.assign({}, state.data, payload.data || {}));
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA](state, payload) {
    Vue.set(state,'data', Object.assign({}, state.data, payload, {dataHasChanged: true }));
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_ANTRAG](state, payload) {
    Vue.set(state, 'antrag', Object.assign({}, state.antrag, payload));
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
 
  [WERTPAPIERORDER_TYPES.MUTATIONS.ADD_POSITIONS](state, payload) {
    if (payload?.positions?.length) {
      const positions = state.positions?.slice();
      positions.push(...payload.positions);

      Vue.set(state, 'positions', positions);
      Vue.set(state,'data', Object.assign(state.data, {dataHasChanged: true }));
    }
  },
  
  [WERTPAPIERORDER_TYPES.MUTATIONS.REMOVE_POSITION_SUCCESS](state, payload) {
    Vue.set(state, 'positions', payload?.positions);
  },
  
  [WERTPAPIERORDER_TYPES.MUTATIONS.REMOVE_UNSAVED_POSITION](state, position) {
    if (position?.isin && state.positions?.length) {
      const positions = state.positions.slice();
      const index = positions.findIndex(pos => pos.index === position.index && pos.positionTyp === position.positionTyp && pos.isin === position.isin
        && pos.depotNr === position.depotNr);
      if (index >= 0) {
        positions.splice(index, 1); 
      }
      Vue.set(state, 'positions', positions);
      Vue.set(state,'data', Object.assign(state.data, {dataHasChanged: true }));
    }
  },
  
  [WERTPAPIERORDER_TYPES.MUTATIONS.REMOVE_ALL_VERKAUF_AND_TAUSCH_POSITIONS_SUCCESS](state) {
    if (state.positions && state.positions.length) {
      const remainPositions = state.positions.filter(pos => pos.positionTyp !== 'ALT_VERKAUF' && pos.positionTyp !== 'ALT_TAUSCH');
      Vue.set(state, 'positions', remainPositions);
      Vue.set(state,'data', Object.assign(state.data, {dataHasChanged: true }));
    }
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.SAVE_POSITION_DATA_SUCCESS](state, payload) {
    Vue.set(state, 'positions', Object.assign([], payload.positions || []));
    Vue.set(state, 'warnings', Object.assign({}, setWarningStepKey(payload.warnings)));
    Vue.set(state.data, 'hatPDF', payload.hatPDF);
    Vue.set(state, 'data', Object.assign({}, state.data, payload.data));
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_POSITION](state, position) {

    if (!state.positions) {
      Vue.set(state.positions, []);
    }
    const index = state.positions.findIndex(pos => pos.index === position.index && pos.isin === position.isin && pos.positionTyp === position.positionTyp);
    if (index >= 0 ) {
      Vue.set(state.positions, index, position);
      Vue.set(state, 'data', Object.assign(state.data, {dataHasChanged: true }));
    } 
  },
 
  [WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_HIGHEST_STEP_VISITED](state, index) {
      Vue.set(state, 'highestStepVisited', index || 0);
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.WERTPAPIERORDER_LOADING](state, payload) {
    Vue.set(state, 'isWertpapierorderLoading', payload === true);
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.GET_PERSONEN_AUSWAHL_SUCCESS](state, payload) {
    Vue.set(state, 'comboboxValues', Object.assign(state.comboboxValues || {}, payload));
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.SET_MIT_GELDWAESCHEPRUEFUNG](state, payload) {
    Vue.set(state, 'mitGeldwaesche', payload);
  },

  [WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_IS_VORGELEGT](state, payload) {
    if (!state.data?.READ_ONLY) {
      Vue.set(state, 'data', Object.assign(state.data || {}, {IS_VORGELEGT: payload?.IS_VORGELEGT }));
    }
  },

}

// function sortPositions(positions) {
//   return positions?.length && positions.sort((a,b) => {
//     return a?.wertpapiername?.localeCompare?.(b?.wertpapiername);
//   })
//   .map((pos, index) => Object.assign(pos, { index: index })) || [];
// }

function setWarningStepKey(warnings) {
  const antragWarnings = warnings?.antragWarnings?.map(warn =>  warn.stepKey ? warn : Object.assign(warn, { stepKey: getWarnStepKey(warn)}));
  const posWarnings = warnings?.positionWarnings?.map(warn => warn.stepKey ? warn : Object.assign(warn, { stepKey: 'main'}));
  if (warnings && antragWarnings?.length) {
    warnings = Object.assign({}, warnings, { antragWarnings: antragWarnings });
  }
  if (warnings && posWarnings?.length) {
    warnings.positionWarnings = posWarnings;
  }
  return warnings || {};
}

function getWarnStepKey(warn = {}) {
  if (warn.posGrpId || warn.id?.toLowerCase()?.toLowerCase()?.includes('inputaa') || warn.id?.toLowerCase()?.includes('comdirectbo')
    || warn.id?.toLowerCase()?.toLowerCase()?.includes('auftragart')) {
      return 'main';
  } else if(warn.id?.includes('PERSONEN_INHABER')) {
      return 'sonstiges';
  } else if(warn.id?.toLowerCase()?.includes('capitalbank') || warn.id?.includes('_CB_')) {
      return 'capitalbank';
  } else if (warn.id?.toLowerCase()?.includes('kyc') || CAPITALBANK_KYC_COMPONENT_IDS.includes(warn.id)) {
      return 'capitalbank-kyc';
  } else if (warn.id?.toLowerCase()?.includes('selbstauskunft')) {
      return 'hellobank-selbst';
  } else if (warn.id?.toLowerCase()?.includes('hellobank')) {
      return 'hellobank';
  } else if (warn.id?.toLowerCase()?.includes('fodb_mix')) {
      return 'fodb-mix'
  } else if (warn.id?.includes('FODB')) {
      return 'FODB'
  } else if (warn.id?.includes('INPUT_ERKLAERUNG')) {
      return 'sonstiges'
  } else if (warn.id) {
      return 'positions-data';
  } else {
      return 'main';
  }
}

const CAPITALBANK_KYC_COMPONENT_IDS = ['INPUT_DEPOTINHABER 1', 
  'INPUT_DEPOTINHABER 2', 'INPUT_EINKOMMEN_HOEHE1', 'INPUT_EINKOMMEN_HOEHE2', '_ERWERB_GSFT', '_ERWERB_GSFT', 'INPUT_SONSTIGE_HERKUNFT']