import MERGE_PEOPLE_TYPES from './types';

export default {
	[MERGE_PEOPLE_TYPES.GETTERS.DUPLICATED_PEOPLE_LIST](state){
		return state.duplicatedPeople
	},

	[MERGE_PEOPLE_TYPES.GETTERS.UNIFIABLE_PEOPLE_LIST](state){
		return state.unifiablePeople
	},

}