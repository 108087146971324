var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dashboard-panel__widgets" }, [
      _c(
        "div",
        { staticClass: "box__container dashboard-panel-widget" },
        [
          _c("InputField", {
            attrs: {
              label: "Bezeichnung",
              placeholder: "",
              type: "text",
              validateUntouched: ""
            },
            on: {
              change: function($event) {
                return _vm.doChanges("title", $event)
              }
            },
            model: {
              value: _vm.form.title,
              callback: function($$v) {
                _vm.$set(_vm.form, "title", $$v)
              },
              expression: "form.title"
            }
          }),
          _c("DatePickerField", {
            attrs: {
              label: "Zieldatum (Ende)",
              isValueAsString: "",
              monthPicker: "",
              disabled: _vm.hasDepots
            },
            on: { change: _vm.endDateChanged },
            model: {
              value: _vm.form.endDate,
              callback: function($$v) {
                _vm.$set(_vm.form, "endDate", $$v)
              },
              expression: "form.endDate"
            }
          }),
          !_vm.isRuecklage
            ? _c("InputField", {
                attrs: {
                  label: "Benötigtes Kapital",
                  type: "currency",
                  precision: 2
                },
                on: { change: _vm.zielsummeChanged },
                model: {
                  value: _vm.form.zielsumme,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "zielsumme", $$v)
                  },
                  expression: "form.zielsumme"
                }
              })
            : _vm._e(),
          _vm.isRuecklage || _vm.isImmobilie
            ? _c("InputField", {
                attrs: {
                  label: "Sparrate pro Monat",
                  validateUntouched: "",
                  disabled: _vm.hasDepots,
                  type: "currency",
                  precision: 2
                },
                on: {
                  change: function($event) {
                    return _vm.valueChanged("sparrate", $event)
                  }
                },
                model: {
                  value: _vm.form.sparrate,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sparrate", $$v)
                  },
                  expression: "form.sparrate"
                }
              })
            : _vm._e(),
          _c("InputField", {
            attrs: {
              label: "Vorhandenes Kapital",
              disabled: _vm.hasDepots,
              type: "currency",
              precision: 2
            },
            on: { change: _vm.startkapitalChanged },
            model: {
              value: _vm.form.startkapital,
              callback: function($$v) {
                _vm.$set(_vm.form, "startkapital", $$v)
              },
              expression: "form.startkapital"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Wertenwicklung",
              disabled: _vm.hasDepots,
              type: "percent",
              precision: 2
            },
            on: { change: _vm.renditeChanged },
            model: {
              value: _vm.form.rendite,
              callback: function($$v) {
                _vm.$set(_vm.form, "rendite", $$v)
              },
              expression: "form.rendite"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container dashboard-panel-widget" },
        [_c("VirtuelleDepots", { on: { depotChange: _vm.setForm } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }