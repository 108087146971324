var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: { "action-CREATE_KENNUNG": _vm.openCreateKennungModal }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("Table", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  rows: _vm.rows,
                  headers: _vm.headers,
                  rowsPerPage: 20
                },
                on: { "action-DELETE": _vm.deleteKennung }
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "createKennungModal",
          attrs: {
            modalTitle: "Kennung hinzufügen",
            confirmDisabled: !_vm.editRow.provider || !_vm.editRow.kennung
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveKennung(_vm.editRow)
            }
          }
        },
        [
          _c("ComboBox", {
            attrs: {
              label: "Datenlieferant",
              sortComboboxValues: false,
              values: _vm.providerValues
            },
            model: {
              value: _vm.editRow.provider,
              callback: function($$v) {
                _vm.$set(_vm.editRow, "provider", $$v)
              },
              expression: "editRow.provider"
            }
          }),
          _c("InputField", {
            attrs: { label: "Kennung" },
            model: {
              value: _vm.editRow.kennung,
              callback: function($$v) {
                _vm.$set(_vm.editRow, "kennung", $$v)
              },
              expression: "editRow.kennung"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }