var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Limits",
          subtitle: "Wertpapierkategorie Limit",
          actions: _vm.actions
        },
        on: {
          "action-RESET": function($event) {
            return _vm.reset()
          },
          "action-REMOVE": function($event) {
            return _vm.$refs.confirmDelete.open()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "info-block" }, [
            _c("div", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.limit.depot))
            ]),
            !_vm.isCustomerOrigin
              ? _c("div", [_vm._v("Kundennr: " + _vm._s(_vm.limit.kundennr))])
              : _vm._e(),
            !_vm.isCustomerOrigin
              ? _c("div", [
                  _vm._v("Kunde: " + _vm._s(_vm.limit.kundenLinkName))
                ])
              : _vm._e()
          ]),
          _vm.form.rows && _vm.form.rows.length
            ? _c(
                "div",
                _vm._l(_vm.form.rows, function(row, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("div", { staticClass: "my-2" }, [
                        _c("span", [_vm._v(_vm._s(row.kategorieStr) + ":")]),
                        _c(
                          "span",
                          { staticClass: "font-bold" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.formatNumber(row.wert, 2)) + " "
                            ),
                            _c("PhCurrencyEur", { attrs: { size: 16 } })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "font-bold" },
                          [
                            _vm._v(
                              " (" +
                                _vm._s(_vm.formatNumber(row.verteilung, 4)) +
                                " "
                            ),
                            _c("PhPercent", { attrs: { size: 16 } }),
                            _vm._v(")")
                          ],
                          1
                        )
                      ]),
                      _c("InputField", {
                        attrs: {
                          value: row.ausgangswert,
                          type: "percent",
                          precision: 4,
                          isComponentHalfSize: ""
                        },
                        on: {
                          change: function($event) {
                            row.ausgangswert = +$event
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _c("InputField", {
            attrs: {
              label: "Abweichung nach oben",
              precision: 4,
              type: "percent",
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.abweichungOben,
              callback: function($$v) {
                _vm.$set(_vm.form, "abweichungOben", $$v)
              },
              expression: "form.abweichungOben"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Abweichung nach unten",
              type: "percent",
              precision: 4,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.abweichungUnten,
              callback: function($$v) {
                _vm.$set(_vm.form, "abweichungUnten", $$v)
              },
              expression: "form.abweichungUnten"
            }
          }),
          _vm.limit.dateUpdated
            ? _c("div", [
                _vm._v("Letzte Änderung: " + _vm._s(_vm.limit.dateUpdated))
              ])
            : _vm._e(),
          _vm.limit.dateOutOfLimit
            ? _c("div", [
                _vm._v(
                  "Datum der Überschreitung: " +
                    _vm._s(_vm.limit.dateOutOfLimit)
                )
              ])
            : _vm._e(),
          !_vm.isCustomerOrigin
            ? _c("InputTextArea", {
                attrs: {
                  label: "Bemerkung (für Kunden nicht sichtbar)",
                  isComponentHalfSize: ""
                },
                model: {
                  value: _vm.form.bemerkung,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bemerkung", $$v)
                  },
                  expression: "form.bemerkung"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmDelete",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.remove()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhWarning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Löschen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Wollen Sie wirklich das Limit löschen?")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmReset",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.reset()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhWarning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Zurücksetzen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Wollen Sie wirklich das Limit zurücksetzen?")])]
      ),
      _c("LimitErrorModal", {
        ref: "modalError",
        attrs: { errorText: _vm.errorFromService },
        on: { closeLimit: _vm.confirmNextRoute }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }