var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: {
          "action-CREATE_ZWEIGSTELLE": _vm.openCreateZweigstelle,
          "action-DELETE_ZWEIGSTELLE": function($event) {
            return _vm.deleteZweigstelle(_vm.selectedZweigstelle)
          },
          "action-CREATE_TARIFGRUPPE": _vm.openCreateTarifgruppeModal
        }
      }),
      _c("StepperForm", {
        attrs: {
          stepType: "tab",
          stepperMediator: _vm.tabsStepperMediator,
          hasFertig: false,
          selectedStepKey: _vm.currentStep,
          selectedSubstepKey: _vm.currentSubstep
        },
        on: {
          "set-step": function($event) {
            return _vm.setStep($event)
          },
          "set-substep": function($event) {
            return _vm.setSubstep($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _vm.loading
                  ? _c("GhostLoading", {
                      attrs: { useBoxContainer: "", type: "table" }
                    })
                  : _vm.currentStep === "DATEN"
                  ? [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v("Gesellschaftsdaten")
                          ]),
                          _c("InputField", {
                            attrs: {
                              label: "ID",
                              isComponentHalfSize: "",
                              disabled: !_vm.isIntern
                            },
                            model: {
                              value: _vm.daten.id,
                              callback: function($$v) {
                                _vm.$set(_vm.daten, "id", $$v)
                              },
                              expression: "daten.id"
                            }
                          }),
                          _c("ComboBox", {
                            attrs: {
                              label: "Gesellschaftsart",
                              isComponentHalfSize: "",
                              sortComboboxValues: false,
                              disabled: !_vm.isIntern,
                              values: _vm.comboboxSelections.arten
                            },
                            model: {
                              value: _vm.daten.art,
                              callback: function($$v) {
                                _vm.$set(_vm.daten, "art", $$v)
                              },
                              expression: "daten.art"
                            }
                          }),
                          _vm.isIntern
                            ? _c("InputField", {
                                attrs: {
                                  label: "Nummer",
                                  isComponentHalfSize: ""
                                },
                                model: {
                                  value: _vm.daten.nummer,
                                  callback: function($$v) {
                                    _vm.$set(_vm.daten, "nummer", $$v)
                                  },
                                  expression: "daten.nummer"
                                }
                              })
                            : _vm._e(),
                          _vm.isIntern && _vm.isVersicherung
                            ? [
                                _vm.isFA
                                  ? [
                                      _c("InputField", {
                                        attrs: {
                                          label: "Hauptvermittlernummern",
                                          disabled: "",
                                          isComponentHalfSize: "",
                                          value: (
                                            _vm.daten.fremdnrnFA || []
                                          ).join(", ")
                                        }
                                      })
                                    ]
                                  : [
                                      _c("InputField", {
                                        attrs: {
                                          label: "Hauptvermittlernummern AK",
                                          disabled: "",
                                          isComponentHalfSize: "",
                                          value: (
                                            _vm.daten.fremdnrnAK || []
                                          ).join(", ")
                                        }
                                      }),
                                      _vm.isIntern
                                        ? _c("InputToggleSwitch", {
                                            attrs: {
                                              label: "Unternummern möglich"
                                            },
                                            model: {
                                              value: _vm.daten.unternrnMoeglich,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.daten,
                                                  "unternrnMoeglich",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "daten.unternrnMoeglich"
                                            }
                                          })
                                        : _vm._e(),
                                      _c("InputField", {
                                        attrs: {
                                          label: "Hauptvermittlernummern MFA",
                                          disabled: "",
                                          isComponentHalfSize: "",
                                          value: (
                                            _vm.daten.fremdnrnMFA || []
                                          ).join(", ")
                                        }
                                      })
                                    ]
                              ]
                            : _vm._e(),
                          _vm.isIntern
                            ? _c("InputToggleSwitch", {
                                attrs: { label: "Inaktiv" },
                                model: {
                                  value: _vm.daten.inaktiv,
                                  callback: function($$v) {
                                    _vm.$set(_vm.daten, "inaktiv", $$v)
                                  },
                                  expression: "daten.inaktiv"
                                }
                              })
                            : _vm._e(),
                          _vm.isIntern
                            ? _c("InputField", {
                                attrs: {
                                  label: "Name Kurzbeschreibung",
                                  isComponentHalfSize: ""
                                },
                                model: {
                                  value: _vm.daten.nameKurz,
                                  callback: function($$v) {
                                    _vm.$set(_vm.daten, "nameKurz", $$v)
                                  },
                                  expression: "daten.nameKurz"
                                }
                              })
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm.daten.hauptDaten
                        ? _c(
                            "div",
                            { staticClass: "box__container" },
                            [
                              _c("GesellschaftPersonenDaten", {
                                attrs: {
                                  comboboxSelections: _vm.comboboxSelections,
                                  nameDisabled: !_vm.isIntern
                                },
                                model: {
                                  value: _vm.daten.hauptDaten,
                                  callback: function($$v) {
                                    _vm.$set(_vm.daten, "hauptDaten", $$v)
                                  },
                                  expression: "daten.hauptDaten"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isIntern || _vm.isGesellschaft
                        ? _c(
                            "div",
                            { staticClass: "box__container" },
                            [
                              _c("ImageUpload", {
                                staticClass: "logo-container",
                                attrs: {
                                  image: _vm.getImgSrc(_vm.daten.logo),
                                  title: "Logo",
                                  loading: _vm.loading,
                                  config: _vm.imageUploadConfig,
                                  disabled: _vm.loading,
                                  hasDeleteButton: ""
                                },
                                on: {
                                  file: _vm.uploadImage,
                                  change: _vm.uploadImage,
                                  delete: _vm.deleteImage
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  : _vm.currentStep === "ZWEIGSTELLEN"
                  ? [
                      _vm.selectedZweigstelle
                        ? _c(
                            "div",
                            { staticClass: "box__container" },
                            [
                              _c("GesellschaftPersonenDaten", {
                                attrs: {
                                  comboboxSelections: _vm.comboboxSelections,
                                  nameDisabled: !_vm.isIntern
                                },
                                model: {
                                  value:
                                    _vm.zweigstellen[
                                      _vm.selectedZweigstelleIndex
                                    ],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.zweigstellen,
                                      _vm.selectedZweigstelleIndex,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "zweigstellen[selectedZweigstelleIndex]"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  : _vm.currentStep === "FWW_DATENIMPORT"
                  ? [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v("FWW Datenimport")
                          ]),
                          _c("InputToggleSwitch", {
                            attrs: {
                              label:
                                "Name und Homepage durch FWW-Daten überschreiben."
                            },
                            model: {
                              value: _vm.daten.fwwForbidChange,
                              callback: function($$v) {
                                _vm.$set(_vm.daten, "fwwForbidChange", $$v)
                              },
                              expression: "daten.fwwForbidChange"
                            }
                          }),
                          _c("div", { staticClass: "text-small" }, [
                            _vm._v(
                              '"Die Postanschrift und Kontaktdaten werden in einem Ansprechpartner "Allgemein", Typ "Service" gespeichert."'
                            )
                          ])
                        ],
                        1
                      ),
                      _c("div", { staticClass: "box__container" }, [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Anschrift")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.daten.fwwPlz) +
                              " " +
                              _vm._s(_vm.daten.fwwOrt)
                          )
                        ]),
                        _c("br"),
                        _c("span", [_vm._v(_vm._s(_vm.daten.fwwStrasse))])
                      ]),
                      _c("div", { staticClass: "box__container" }, [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Kontakt")
                        ]),
                        _c("span", [
                          _vm._v("Telefon: " + _vm._s(_vm.daten.fwwTelefon))
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v("Fax: " + _vm._s(_vm.daten.fwwFax))
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v("E-Mail: " + _vm._s(_vm.daten.fwwEmail))
                        ])
                      ])
                    ]
                  : _vm.currentStep === "DATENVERWALTUNG"
                  ? [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v("Datenverwaltung der Gesellschaft")
                          ]),
                          _c("InputField", {
                            attrs: {
                              label:
                                "E-Mail-Adresse zur Benachrichtigung bei automatischem Datenimport",
                              isComponentHalfSize: ""
                            },
                            model: {
                              value: _vm.daten.emailBenachrichtigung,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.daten,
                                  "emailBenachrichtigung",
                                  $$v
                                )
                              },
                              expression: "daten.emailBenachrichtigung"
                            }
                          }),
                          _c("InputField", {
                            attrs: {
                              label: "Externe ID (Zertifikateprofis)",
                              isComponentHalfSize: ""
                            },
                            model: {
                              value: _vm.daten.externeId,
                              callback: function($$v) {
                                _vm.$set(_vm.daten, "externeId", $$v)
                              },
                              expression: "daten.externeId"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : _vm.currentStep === "COURTAGE"
                  ? [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v("Courtageabrechnung Versicherungen")
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("ComboBox", {
                                attrs: {
                                  label: "Standard Tarifgruppe",
                                  sortComboboxValues: false,
                                  values: _vm.comboboxSelections.tarifgruppen
                                },
                                model: {
                                  value: _vm.daten.versCourtageTarifgruppe,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.daten,
                                      "versCourtageTarifgruppe",
                                      $$v
                                    )
                                  },
                                  expression: "daten.versCourtageTarifgruppe"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-3 clickable",
                                  staticStyle: { "align-self": "center" }
                                },
                                [
                                  _vm.daten.versCourtageTarifgruppe !==
                                  "MSC3_VALUE_OHNE_ZUORDNUNG"
                                    ? _c("ph-pencil-line", {
                                        attrs: {
                                          title: "Bearbeiten",
                                          size: 20
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editTarifgruppe()
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("InputTextArea", {
                            attrs: { label: "Bemerkung" },
                            model: {
                              value: _vm.daten.bemerkung,
                              callback: function($$v) {
                                _vm.$set(_vm.daten, "bemerkung", $$v)
                              },
                              expression: "daten.bemerkung"
                            }
                          }),
                          _c("InputTextArea", {
                            attrs: {
                              label:
                                "Text Courtagetabelle - der Platzhalter <AB_PROVSATZ> wird durch den aktuellen Provisionssatz des Maklers ersetzt"
                            },
                            model: {
                              value: _vm.daten.textCourtagetabelle,
                              callback: function($$v) {
                                _vm.$set(_vm.daten, "textCourtagetabelle", $$v)
                              },
                              expression: "daten.textCourtagetabelle"
                            }
                          }),
                          _c("InputTextArea", {
                            attrs: {
                              label: "Text Courtagetabelle Versicherung"
                            },
                            model: {
                              value: _vm.daten.textCourtagetabelleVers,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.daten,
                                  "textCourtagetabelleVers",
                                  $$v
                                )
                              },
                              expression: "daten.textCourtagetabelleVers"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "createZweigstelleModal",
          attrs: {
            modalTitle: "Neue Zweigstelle",
            confirmDisabled: !_vm.newZweigstelle.name
          },
          on: { onConfirmButton: _vm.createZweigstelle }
        },
        [
          _c("GesellschaftPersonenDaten", {
            attrs: { comboboxSelections: _vm.comboboxSelections },
            model: {
              value: _vm.newZweigstelle,
              callback: function($$v) {
                _vm.newZweigstelle = $$v
              },
              expression: "newZweigstelle"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "createTarifgruppeModal",
          attrs: {
            modalTitle: "Neue Tarifgruppe",
            confirmDisabled: !_vm.newTarifgruppe.bezeichnung
          },
          on: { onConfirmButton: _vm.createTarifgruppe }
        },
        [
          _c("InputField", {
            attrs: { label: "Bezeichnung", isComponentHalfSize: "" },
            model: {
              value: _vm.newTarifgruppe.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.newTarifgruppe, "bezeichnung", $$v)
              },
              expression: "newTarifgruppe.bezeichnung"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }