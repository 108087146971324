var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputToggleSwitch", {
        attrs: {
          label: "Eingang",
          inLineLabel: "",
          disabled:
            this.form.isMoveInbox || this.form.isMoveOutbox || _vm.loading
        },
        on: {
          input: function($event) {
            return _vm.save()
          }
        },
        model: {
          value: _vm.form.isEingang,
          callback: function($$v) {
            _vm.$set(_vm.form, "isEingang", $$v)
          },
          expression: "form.isEingang"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Ausgang",
          inLineLabel: "",
          disabled:
            this.form.isMoveInbox || this.form.isMoveOutbox || _vm.loading
        },
        on: {
          input: function($event) {
            return _vm.save()
          }
        },
        model: {
          value: _vm.form.isAusgang,
          callback: function($$v) {
            _vm.$set(_vm.form, "isAusgang", $$v)
          },
          expression: "form.isAusgang"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Gesendete E-Mails werden hier gespeichert",
          disabled: _vm.loading,
          inLineLabel: ""
        },
        on: {
          input: function($event) {
            return _vm.save()
          }
        },
        model: {
          value: _vm.form.isMSCSent,
          callback: function($$v) {
            _vm.$set(_vm.form, "isMSCSent", $$v)
          },
          expression: "form.isMSCSent"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Importierte eingegangene E-Mails werden hierhin verschoben",
          disabled: _vm.loading,
          inLineLabel: ""
        },
        on: {
          input: function($event) {
            return _vm.save()
          }
        },
        model: {
          value: _vm.form.isMoveInbox,
          callback: function($$v) {
            _vm.$set(_vm.form, "isMoveInbox", $$v)
          },
          expression: "form.isMoveInbox"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Importierte gesendete E-Mails werden hierhin verschoben",
          disabled: _vm.loading,
          inLineLabel: ""
        },
        on: {
          input: function($event) {
            return _vm.save()
          }
        },
        model: {
          value: _vm.form.isMoveOutbox,
          callback: function($$v) {
            _vm.$set(_vm.form, "isMoveOutbox", $$v)
          },
          expression: "form.isMoveOutbox"
        }
      }),
      _c(
        "BaseButton",
        {
          attrs: { isLink: "" },
          on: {
            click: function($event) {
              return _vm.standardSettings()
            }
          }
        },
        [_vm._v("Standardeinstellung")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }