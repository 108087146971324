var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "angeboteneGesellschaften",
          attrs: {
            modalTitle: "angebotene Gesellschaften",
            showDefaultButtons: false,
            showCloseButton: true
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isPrimary: "" },
                      on: {
                        click: function($event) {
                          return _vm.confirmOperation()
                        }
                      }
                    },
                    [_vm._v("Ja")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelOperation()
                        }
                      }
                    },
                    [_vm._v("Nein")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v(" " + _vm._s(_vm.rueckFrage) + " ")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }