<template>
  <div>
    <div class="box__title">Kontodaten Fonds</div>
    <div class="box__container" v-if="brokerData.hatInvestmentAccess">
      <InputField
        label="Stornoreservekonto"
        v-if="brokerData.hatInvestmentAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.stornoreservekonto"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="true"
      />
    </div>

    <div class="box__container" v-if="brokerData.hatInvestmentAccess">
      <InputField
        label="Stornoreservekonto"
        v-if="brokerData.hatInvestmentAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.stornoreservekontoStr"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="true"
      />
    </div>

    <div class="box__container" v-if="brokerData.hatInvestmentAccess">
      <InputField
        label="Stornorisiko"
        v-if="brokerData.hatInvestmentAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.stornorisiko"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="true"
      />
    </div>

    <div class="box__container" v-if="brokerData.hatInvestmentAccess">
      <InputField
        label="Stornorisiko Struktur"
        v-if="brokerData.hatInvestmentAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.stornorisikoStr"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="true"
      />
    </div>

    <div class="box__title"  v-if="brokerData.hatVersAccess">Kontodaten Versicherung</div>
    <div class="box__container"  v-if="brokerData.hatVersAccess">
      <InputField
        label="Stornoreservekonto (abgeschlossen)"
        v-if="brokerData.hatVersAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.stornoreservekontoVers"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="true"
      />
      <InputField
        label="Stornoreservekonto Struktur (abgeschlossen)"
        v-if="brokerData.hatVersAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.stornoreservekontoVersStr"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="true"
      />
    </div>
    <StornoreserveVersicherungen v-if="isFK && brokerData.hatVersAccess" />
  </div>
</template>
<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import { mapGetters } from "vuex";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import CORE_TYPES from "@/store/core/types";
import BaseModal from "@/components/core/BaseModal.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import vermittlerDatenMixin from "@/views/intern/vermittler-daten-mixin";
import StornoreserveVersicherungen from "@/components/broker/StornoreserveVersicherungen.vue";

export default {
  mixins: [vermittlerDatenMixin],

  data() {
    return {
      abrechnungServiceGebuehrValues: [
        { value: "1", label: "prozentual analog Abschlussfolge" },
        { value: "2", label: "prozentual gem. Vorgabe Struktur" },
        { value: "0", label: "fixer Einbehalt" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      brokerData: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isFK: CORE_TYPES.GETTERS.IS_FK,
    }),
  },

  components: {
    ComboBox,
    InputCheckboxItem,
    BaseButton,
    BaseModal,
    InputRadioBoxGroup,
    InputField,
    StornoreserveVersicherungen,
  },
  methods: {},
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges().then(() => next());
  },
};
</script>