var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulare-list__container" },
    [
      !_vm.isLagerstelleToRedirect && !_vm.isLagerstelleAsGroupMenu
        ? [
            _c("PageHeaderTitleNavigation", {
              attrs: {
                title: "Formulare",
                actions: _vm.headerActions,
                subtitle: _vm.subtitle
              },
              on: {
                "action-EDIT-ROWS": function($event) {
                  _vm.showConfigModal = true
                }
              }
            }),
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: { type: "table", useBoxContainer: "" }
                })
              : _vm.selectedLagerstelle && _vm.selectedLagerstelle.path
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "box__container",
                      attrs: {
                        tid: _vm._generateTidFromString("box__container")
                      }
                    },
                    [
                      _c("div", { staticClass: "box__title" }, [
                        _vm._v("Formularsuche")
                      ]),
                      _c("InputField", {
                        attrs: {
                          isComponentHalfSize: "",
                          placeholder: "Suchen nach Bezeichnung"
                        },
                        model: {
                          value: _vm.descriptionSearch,
                          callback: function($$v) {
                            _vm.descriptionSearch = $$v
                          },
                          expression: "descriptionSearch"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.loadingConfig
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "cards__container layout__negative-margin--8"
                        },
                        _vm._l(_vm.groupedForms, function(value, propName) {
                          return _c(
                            "div",
                            {
                              key: propName,
                              staticClass: "box__container cards__item",
                              attrs: {
                                tid: _vm._generateTidFromString(
                                  "box__container cards__item" + propName
                                )
                              }
                            },
                            [
                              _c("Table", {
                                attrs: {
                                  tableId:
                                    "0eca709b-022c-4078-871f-633007348bae",
                                  headers: _vm.header(propName),
                                  rows: value,
                                  rowId: "label",
                                  hidePagination: ""
                                },
                                on: { "click-label": _vm.handleLabelClick }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "cards__container layout__negative-margin--8"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "box__container cards__item" },
                            [_c("GhostLoading", { attrs: { type: "table" } })],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "box__container cards__item" },
                            [_c("GhostLoading", { attrs: { type: "table" } })],
                            1
                          )
                        ]
                      )
                ])
              : _vm._e()
          ]
        : _vm._e(),
      _vm.showConfigModal
        ? _c("FormulareListConfigModal", {
            attrs: {
              defaultList: _vm.forms,
              selectedLagerstelle: _vm.selectedLagerstelle
            },
            on: {
              close: function($event) {
                return _vm.closeFormulareListConfig()
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }