<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="DBM" 
      :subtitle="$appNavigation.currentMenu.label" 
    />

    <BaseFilter
      title="Avg Response Time per Endpoint"
      :filterId="baseFilterSaveKey"
      :metadata="searchMetadata"
      :configFilter="configFilter"
      :showSaveButton="true"
      immidiateSearch
      :defaultOptions="defaultOptions"
      @onFilter="search($event)"
      @onFilterZurucksetzen="search(null)"
    />

    <div class="box__container" v-if="endpoints">
      <Table
        :headers="headers"
        :rows="rows"
        :rowsPerPage="20"
      />
    </div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import Table from '@/components/table2/Table.vue';
import { TextColumn, NumberColumn } from '@/components/table2/table_util.js';
import BaseFilter from '@/components/core/BaseFilter.vue';
import { BASE_AXIOS_CONFIG as config } from "@/configs/http-request-interceptor";

export function getSearchMetadata() {
  return [
    {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: [
        {
          type: 'combobox',
          label: 'Zeitraum',
          key: 'period',
          emptyDenied: true,
          comboOptions: [
            { value: '15', label: 'vor 15 min' },
            { value: '60', label: 'vor 1 Stunde' },
            { value: '120', label: 'vor 2 Stunden' },
            { value: '240', label: 'vor 4 Stunden' },
            { value: '480', label: 'vor 8 Stunden'},
          ]
        },
        {
          type: 'text',
          label: 'Endpoint',
          key: 'endpoint',
          emptyDenied: true,
        },
      ],
    }
  ]
}

export default {
  props: {
  },
  data() {
    return {
      loading: false,
      chartData: null,
      endpoints: null,
      baseFilterSaveKey: 'c2953990-6429-4db0-a0c9-6777440214b9',
      configFilter: {
        placeholderForDefSearchInput: '',
        checkDefaultSearchInput: false,
      },
      defaultOptions: {
        period: {
          value: '15',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
    }),
    searchMetadata() {
      return getSearchMetadata()
    },
    headers() {
      return {
        center: [
          NumberColumn("avg_time", "Avg Execution Time (ms)"),
          NumberColumn("request_count", "Request Count"),
        ],
        lockedRight: [
          TextColumn("endpoint", "Endpoint"),
        ],
      }
    },
    rows() {
      return this.endpoints?.map(item => ({
        avg_time: item[0],
        request_count: item[1],
        endpoint: item[2],
      }));
    }
  },
  methods: {
    search(filter) {
      let params = filter.reduce((acc, curr) => ({...acc, [curr.key] : curr.value}), {});
      this.loadAvgTime(params);
    },
    async loadAvgTime(params) {
      try {
        this.loading = true;

        const response = await axios.get(`${this.apiAddress}/performance_reports/avg_response_time_endpoint`, { ...config, params });
        this.endpoints = response.data;
      } catch (error) {
        // empty block
      } finally {
        this.loading = false;
      }
    },

  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    BaseFilter,
  },
};
</script>

