var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container-wrap" },
    [
      _c(
        "div",
        { staticClass: "box__container-rows" },
        [
          _vm.isSichtbar != null
            ? _c("div", { staticClass: "bemerkung-content" }, [
                _c("span", {
                  staticClass: "bemerkung-text",
                  domProps: { innerHTML: _vm._s(_vm.BemerkungText) }
                }),
                !_vm.isCustomerOnly
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-clear clickable ml-2",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.edit()
                          }
                        }
                      },
                      [_c("PhPencilLine", { attrs: { size: 16 } })],
                      1
                    )
                  : _vm._e(),
                !_vm.isCustomerOnly
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: { label: "Sichtbar für Kunde" },
                          model: {
                            value: _vm.isBemSichtbar,
                            callback: function($$v) {
                              _vm.isBemSichtbar = $$v
                            },
                            expression: "isBemSichtbar"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _c("GhostLoading", { attrs: { type: "block" } })
        ],
        1
      ),
      _c("BaseModal", {
        ref: "BemerkungEditModal",
        attrs: {
          modalTitle: "Bemerkung bearbeiten",
          confirmDisabled: !_vm.edited
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.save()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("InputTextArea", {
                  attrs: { label: "", rows: 5, maxlength: 4000 },
                  model: {
                    value: _vm.BemerkungEdit,
                    callback: function($$v) {
                      _vm.BemerkungEdit = $$v
                    },
                    expression: "BemerkungEdit"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }