import A_C from './types';
import Vue from 'vue'
import { getInitialState } from './index'

export default {
  
  [A_C.MUTATIONS.SETUP](state, payload) {
    state.SETUP = payload
  },
  [A_C.MUTATIONS.RESET_FILTER](state) {
    state.FILTER = {}
    state.rowCount = 0
  },
  [A_C.MUTATIONS.FILTER](state, payload) {
    Vue.set(state.FILTER, payload.page, payload.records);
    state.rowCount = payload.rowCount
  },
  [A_C.MUTATIONS.UPDATE_ROW](state, payload) {
    let target = Object.values(state.FILTER).flat().find(row => row.antragCheckId === payload.antragCheckId)
    Object.assign(target, {...target, ...payload});
  },
  [A_C.MUTATIONS.INFO](state, payload) {
    state.INFO = payload
  },
  
}