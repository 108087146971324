var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseModal", {
        ref: "modal",
        attrs: { modalTitle: "Vermittler anlegen", showDefaultButtons: false },
        on: { close: _vm.onClose },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("InputField", {
                  attrs: {
                    label: "Firmenname",
                    validateUntouched: "",
                    autofocus: ""
                  },
                  model: {
                    value: _vm.form.firmenname,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "firmenname", $$v)
                    },
                    expression: "form.firmenname"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Postleitzahl", validateUntouched: "" },
                  model: {
                    value: _vm.form.plz,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "plz", $$v)
                    },
                    expression: "form.plz"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Staatsangehörigkeit",
                    values: _vm.brokerDataConfig.nationalities
                  },
                  model: {
                    value: _vm.form.nationality,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "nationality", $$v)
                    },
                    expression: "form.nationality"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Ort" },
                  model: {
                    value: _vm.form.ort,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "ort", $$v)
                    },
                    expression: "form.ort"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Land",
                    values: _vm.brokerDataConfig.countries
                  },
                  model: {
                    value: _vm.form.land,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "land", $$v)
                    },
                    expression: "form.land"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.close()
                      }
                    }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: !_vm.isValid || _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.saveBroker()
                      }
                    }
                  },
                  [
                    _vm._v(" Vermittler anlegen "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "haftungserklaerungsUntervermittlerModal",
          attrs: {
            size: "lg",
            modalTitle: "Haftungserklärung",
            showDefaultButtons: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.onClose()
            },
            onEscButton: function($event) {
              return _vm.onClose()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("HaftungserklaerungsUntervermittler", {
                on: {
                  confirmed: function($event) {
                    _vm.isHaftungConfirmed = true
                    _vm.open()
                  },
                  close: function($event) {
                    _vm.closeHaftungsModal()
                    _vm.onClose()
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }