<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Erwartete Courtage"
      :actions="headerActions"
      @action-BERECHNEN="berechnen()"
      @action-PLATTFORM="selectPlattform()" />

    <HinweiseUndFehler :hints="hints" />
     
		<div v-if="!loading" class="box__container">    
			<InputField	v-model="wertsteigerung" label='Berechnete Wertsteigerung p.a.' :isComponentHalfSize="true" />
			<InputToggleSwitch v-model="inclJahrescourtage" inLineLabel 
				label="jeweilige Jahrescourtagen anzeigen (Jc)" 	/>
		</div>
	   
		<div class="box__container">
			<GhostLoading v-if="loading">
				<Block height="100" />
			</GhostLoading>
			<Table v-else-if="rows.length"
				:title="title"
				:headers="headers"
				:rows="rows"
				rowId="Wertpapier"
				:rowsPerPage="25"
				:headerActions="tblActions"
				@headerAction="onChangeJahr"
			>
				<template #JAHR-AUSWAHL>
					<div v-for="year in years" :key="year.key"  class="mt-2 mb-2">
					<BaseButton :label="year.label" :isClear="true" :class="{'text-bold': (jahre==year.key)}"
						@click="jahre=year.key"
					/></div>
				</template>
			</Table>
		</div>
		
    <BaseModal ref="showPlattform" 
      labelButtonConfirm="Speichern"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="onChangePlattform">
      <template v-slot:modalTitle>
        Plattformen
      </template>
      <div>
				<div v-for="item in plattformen" :key="item[0]">
					<InputToggleSwitch v-model="item[1]" inLineLabel  :label="item[0]" class="plattform"	/>
				</div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from "vuex";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, PageHeaderSlotAction} from '@/components/core/header-title-navigation/page-header-utils';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";

export default {
  
	components: {
    OptionMenu, PageHeaderTitleNavigation, PageHeaderSimpleAction, Table, GhostLoading, Block, PageHeaderSlotAction,
    BaseModal, InputToggleSwitch, InputField, BaseButton, HinweiseUndFehler,
	},
	data() {
		return {
			inclJahrescourtage: false,
			jahre: '0',
			loading: true,
			headerActions: [
        PageHeaderSimpleAction('BERECHNEN', 'Berechnen'),
        PageHeaderSimpleAction('PLATTFORM', 'Plattform-Auswahl')
      ],
			tblActions: [PageHeaderSlotAction('JAHR-AUSWAHL')],
			lagerstellen: [],
			plattformen: [],
			wertsteigerung: null,
    }
  },
  computed: {
    ...mapGetters({
      courtage: DEPOTPOSITIONS_TYPES.GETTERS.GET_CALC_COURTAGE,
			isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
		title() {
			return 'Erwartete Courtage - ' + this.label(this.jahre)
		},
		hints() {
			const result = [{message: `Die angezeigten Anlagesummen sind bei Käufen die Bestände nach Ablauf des gewählten Zeitraums. 
			Bei Sparplänen werden die Einzahlungen im letzten Jahr gemittelt, die Anlagesumme ist der resultierende 
			Betrag auf dem die Courtageberechnung beruht. Alle angebenen Courtagen sind die insgesamt über den gesamten 
			Zeitraum ausgezahlten Courtagen, die Courtage im jeweiligen Jahr kann zusätzlich angezeigt werden.`}];
			if (this.isFA) {
				result.push({message: `Es werden nur die Plattformen angezeigt, die eine Bestandscourtage anbieten, für wenigstens einen Fonds aus dem Depot des Kunden.`})
			}
			return result;
		},
		rows() {
			if (this.courtage.rows?.length) {
			 return this.courtage.rows.filter( row => '' + row.accordance == this.jahre);
			}
			return [];
		},
		headers() {
			const headers = {
				lockedLeft: [
					TextColumn('type', ''),
					TextColumn('Wertpapier', 'Wertpapier'),
					CurrencyColumn('Anlagesumme', 'Anlagesumme / Bestand').withSumFooter(),
				],
				center: [],
				lockedRight: []
			}
			if (this.courtage.rows.length) {
				this.courtage.rows[0].lagerstellen.forEach(key => {
					headers.center.push(CurrencyColumn(key, key).withSumFooter() );
					if (this.inclJahrescourtage) {
						// headers.center.push(CurrencyColumn(key + '_Jahrescourtage', key + '-Jc').withSumFooter() );
						headers.center.push(CurrencyColumn(key + '_Jahrescourtage', '- Jahrescourtage').withSumFooter() );
					}
				});
			}
			return headers;
		},
		years() {
			const result = [];
			if (this.courtage.years) {
				this.courtage.years.forEach( j => {
					result.push( { key: ''+j, label: this.label(''+j) } )
				})
			}
			return result;
		}
  },
	mounted() {
		if (!this.courtage?.plattformen) {
			this.berechnen();
		}
	},
  methods: {
		label(j) {
			if (j == '0') {
				return 'Ohne Sparplan'
			} else if (j == '1') {
				return 'Nach einem Jahr'
			} else {
				return 'Nach ' + j +' Jahren'
			}
		},
		setPlttformen() {
			if (this.courtage?.plattformen) {
				this.lagerstellen = this.courtage.plattformen.filter(l => l[1] == '1').map(l => l[0]);
				this.plattformen = this.courtage.plattformen.map(l => ([l[0], l[1]]));
			}
		},
		onChangeJahr( {key}) {
			this.jahre = key;
		},
    berechnen(params = {}) {
			if (this.courtage.isinQuery) {
				params.isinQuery = this.courtage.isinQuery;
				params.bgsLst = this.courtage.bgsLst;
				params.wertsteigerung = ('' + this.wertsteigerung).replace();
			}
			this.loading = true;
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.LOAD_CALC_COURTAGE, params);
    },
		selectPlattform() {
			this.$refs.showPlattform.open();
		},
		async onChangePlattform(value) {

			let lsSet = [];
			let lsReset = [];
			this.courtage.plattformen.forEach( (p, rix) => {
				if (p[1] == '1' && this.plattformen[rix][1] == '0' ) {
					lsReset.push(p[0])
				}
				if (p[1] == '0' && this.plattformen[rix][1] == '1' ) {
					lsSet.push(p[0])
				}
			})
			const params = {};
			if (lsSet.length) {
				params.lsSet = lsSet.join('\t');
			}
			if (lsReset.length) {
				params.lsReset = lsReset.join('\t');
			}
			this.berechnen(params);
		},
		
  },
	watch: {
		courtage: {
			handler() {
				this.setPlttformen();
				if (this.courtage.wertsteigerung) {
					this.wertsteigerung = this.courtage.wertsteigerung.toLocaleString('de-DE', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 0 })
				}				
				if (this.courtage.rows?.length) {
					this.loading = false
				}
			},
			immediate: true,
		}
	}
}
</script>

<style scoped>
.plattform {
	margin-bottom: 7px;
}
::v-deep .plattform .input-forms__label-container{
	width: 100%;
}
</style>