var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("FATCA - Foreign Account Tax Compliance Act")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputToggleSwitch", {
              attrs: {
                label: "FATCA - Foreign Account Tax Compliance Act",
                inLineLabel: true
              },
              on: {
                input: function($event) {
                  return _vm.addCustomerDataEdited("fatca")
                }
              },
              model: {
                value: _vm.fatca.aktiv,
                callback: function($$v) {
                  _vm.$set(_vm.fatca, "aktiv", $$v)
                },
                expression: "fatca.aktiv"
              }
            })
          ],
          1
        )
      ]),
      _vm.fatca.aktiv
        ? _c("div", { staticClass: "forms__input--half-size" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputField", {
                    attrs: { label: "Art des USA-Bezugs" },
                    on: {
                      input: function($event) {
                        return _vm.addCustomerDataEdited("fatca")
                      }
                    },
                    model: {
                      value: _vm.fatca.text,
                      callback: function($$v) {
                        _vm.$set(_vm.fatca, "text", $$v)
                      },
                      expression: "fatca.text"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }