var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-end" }, [
    _c("div", { staticClass: "col col-12 col-sm-6 mb-4 mb-sm-0 mt-2" }, [
      _c(
        "div",
        { staticClass: "m-0", class: { box__container: !_vm.noContainer } },
        [
          _c(
            "div",
            [
              !_vm.ohneEinmal
                ? _c("CurrencyLabel", {
                    attrs: {
                      label: _vm.mitSparplan
                        ? "Einmalanlagen Gesamt:"
                        : _vm.labelDefault,
                      value: _vm.summe,
                      showInlineLabel: ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.mitSparplan && !_vm.ohneEinmal ? _c("hr") : _vm._e(),
          _vm.mitSparplan
            ? _c(
                "div",
                [
                  _c("CurrencyLabel", {
                    attrs: {
                      label: "Sparplan Gesamt:",
                      value: _vm.summeSpar,
                      showInlineLabel: ""
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }