<template>
  <div>
      <div class="box__container" v-if="stepData && !stepData.depotoptimierung">
        <BaseButton
          :disabled="loading || loadingPositions"
          class="btn btn-primary mr-3 my-1"
          @click="anlagesummeVerteilen()">Anlagesumme verteilen</BaseButton>
      </div>
      <div class="box__container" v-if="stepData && stepData.depotoptimierung">
        <BaseButton
          :disabled="loading || loadingPositions"
          class="btn btn-primary mr-3 my-1"
          @click="optimisieren()">Depotoptimierung</BaseButton>
      </div>

      <div v-if="stepData" class="row my-0">
        <div class="col-md-4 col-12 d-flex">
          <div class="box__container">
            <div class="text-centered mb-0">Einmalanlage:</div>
            <div class="row my-0">
              <div class="col-md-6 col-12">Gesamtbetrag</div>
              <div class="col-md-6 col-12" :class="stepData.einmalanlage.gesamtbetrag < 0 ? 'negative-number' : 'positive-number'"> 
                <CurrencyLabel :value="stepData.einmalanlage.gesamtbetrag" />
              </div>
            </div>
            <div v-if="stepData.ziel" class="row my-0">
              <div class="col-md-6 col-12">Errechneter Anfangsbetrag:</div>
              <div class="col-md-6 col-12 positive-number"><CurrencyLabel :value="stepData.einmalanlage.anfangsbetragZiel" /></div>
            </div>
            <div class="row my-0">
              <div class="col-md-6 col-12">Gesamtprozent</div>
              <div class="col-md-6 col-12" :class="stepData.einmalanlage.gesamtprozent.toFixed(2) != 100 ? 'negative-number' : 'positive-number'">
                <CurrencyLabel :value="stepData.einmalanlage.gesamtprozent" symb="%" />
              </div>
            </div>
            <div class="row my-0">
              <div class="col-md-6 col-12">Fehlbetrag</div>
              <div class="col-md-6 col-12" :class="stepData.einmalanlage.fehlbetrag.toFixed(2) > 0       ? 'negative-number' : 'positive-number'">
                <CurrencyLabel :value="stepData.einmalanlage.fehlbetrag" />
              </div>
            </div>
            <div class="row my-0">
              <div class="col-md-6 col-12">Durchschn. SRI</div>
              <div class="col-md-6 col-12 positive-number">{{ stepData.einmalanlage.durchschnittSRI.toFixed(1)}}</div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12 d-flex">
          <div class="box__container">
            <div class="text-centered mb-0">Sparplan:</div>
            <div class="row my-0">
              <div class="col-md-6 col-12">Jahressumme</div>
              <div class="col-md-6 col-12 positive-number"><CurrencyLabel :value="stepData.sparplan.jahressumme" /></div>
            </div>
            <div v-if="stepData.ziel" class="row my-0">
              <div class="col-md-6 col-12">Errechneter Anfangsbetrag</div>
              <div class="col-md-6 col-12 positive-number"><CurrencyLabel :value="stepData.sparplan.anfangsbetragZiel" /></div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12 d-flex">
          <div class="box__container">
            <div class="text-centered mb-0">Entnahmeplan:</div>
            <div class="row my-0">
              <div class="col-md-6 col-12">Jahressumme</div>
              <div class="col-md-6 col-12 positive-number"><CurrencyLabel :value="stepData.entnahmeplan.jahressumme" /></div>
            </div>
            <div class="row my-0">
              <div class="col-md-6 col-12">Anfangsbetrag</div>
              <div class="col-md-6 col-12 positive-number"><CurrencyLabel :value="stepData.entnahmeplan.anfangsbetrag" /></div>
            </div>
            <div v-if="stepData.ziel" class="row my-0">
              <div class="col-md-6 col-12">Errechneter Anfangsbetrag</div>
              <div class="col-md-6 col-12 positive-number"><CurrencyLabel :value="stepData.entnahmeplan.anfangsbetragZiel" /></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-if="rows.length">
          <div v-if="stepData.depotoptimierung" class="box__container">
            <Table ref="tblAllocation"
              tableId="a63c4e14-a545-4b34-9076-18d8fa461711"
              :headers="headers"
              :rows="rows"
              rowId="id"
              :rowsPerPage="10"
              :showColumnsConfigEvenNoTitle="true"
              :exportConfig="{roottext:'Anlageallokation'}"
              @action-FACTSHEET="viewDocument($event.FACTSHEET_URL)"
              @action-FACTSHEET_KAG="viewDocument($event.FACTSHEET_KAG_URL)"
              @action-PRIIP_BIB="viewDocument($event.PRIIP_BIB_URL)"
              @action-EDIT="edit($event.id)"
              @action-DELETE="deletePositions($event.id)"
              @click-name="gotoFondsinfo"
            >
              <template #active="row">
                <span class="edited-row" @click="edit(row.id)" >
                  <span :class="{selected: edit_id==row.id}">
                    <PhPencilLine :style = "{opacity: edit_id==row.id ? '1' : '0.3' }" :size="18"/>
                  </span>
                </span>
              </template>
            </Table>
          </div>
          <template v-else>
            <div class="box__container" v-for="(row,idx) in rows" :key="row[id]">  
              <div class="box__title d-flex">
                <a @click="gotoFondsinfo(row)" >{{row.name}}</a>
                <span v-if="row.lockingModePill.type != 'success'">&nbsp;| <span :class="'text-' + row.lockingModePill.type">{{row.lockingModePill.label}}</span></span>
                <span style="flex-grow: 1;">&nbsp;| {{row.isin}} | SRI {{row.Frs}}
                  <span v-if="row.isNichtHandelbar" class="nicht-handelbar">&nbsp;| nicht handelbar</span>
                </span>
                <BaseContextMenu>
                  <template v-for="action in row.actions">
                      <ContextMenuItem v-if="action.actionKey != 'EDIT'" :key="action.actionKey">
                        <component 
                            :is="action.component"
                            :row="row"
                            :tid="action.actionKey + row.id"
                            expanded
                            v-bind="action"
                            @action="doAction"
                        />
                      </ContextMenuItem>
                  </template>
                </BaseContextMenu>
              </div>
              <div class="row">
                <InputField label="Anteil im Zieldepot" :value="row.aufteilung" :disabled="row.lockingMode == 'FEST' || row.lockingMode == 'IMMER'"
                  @change="rowChanged('aufteilung',idx,$event)" class="col-12 col-sm-6 col-xl-3" type="percent" :precision="percentPrecision" />
                <InputField :label="zielLabel" :value="row.summe" :disabled="row.lockingMode == 'FEST' || row.lockingMode == 'IMMER'"
                  @change="rowChanged('summe',idx,$event)" class="col-12 col-sm-6 col-xl-3" type="currency" />
              
                <InputField label="Spar- / Entnahmerate" :value="row.rate" :disabled="stepData.depotoptimierung"
                  @change="rowChanged('rate',idx,$event)" class="col-12 col-sm-6 col-xl-3" type="currency" />
                <ComboBox label="Turnus" :value="row.turnus || 'MONATLICH'" :values="turnusList" :disabled="stepData.depotoptimierung"
                  @input="rowChanged('turnus',idx,$event)" class="col-12 col-sm-6 col-xl-3" />
              </div>
            </div>
          </template>
        </div>
        <GhostLoading v-else-if="allocationRows==null">
          <Block height="100" />
        </GhostLoading>
        <NoData :noIcon="true" v-else />
      </div>

      <!-- <div class="box__container" v-if="edit_id && stepData">  
        <div class="box__title">{{edit_name}} <span v-if="isNichtHandelbar" class="nicht-handelbar"> (nicht handelbar)</span></div>
        <div class="row">
          <InputField label="Anteil im Zieldepot *" v-model="aufteilung" :disabled="summeDisbled"
            @change="rowEdited" class="col-12 col-sm-6" type="percent" :precision="percentPrecision" />
          <InputField :label="zielLabel" v-model="summe" :disabled="summeDisbled"
            @change="rowEdited" class="col-12 col-sm-6" type="currency" />
        </div>
        <div class="row">
          <InputField label="Spar- / Entnahmerate **" v-model="rate" :disabled="stepData.depotoptimierung"
            @change="rowEdited" class="col-12 col-sm-6" type="currency" />
          <ComboBox label="Turnus **" v-model="turnus" :values="turnusList" :disabled="stepData.depotoptimierung"
            @input="rowEdited" class="col-12 col-sm-6" />
        </div>
        <div class="d-flex">
          <BaseButton :isSecondary="true" :disabled="edit_idx <= 0" @click="changeRow(-1)">Vorherige Zeile</BaseButton>
          <BaseButton :isSecondary="true" :disabled="edit_idx < 0 || edit_idx > rows.length - 2 " @click="changeRow(1)">Nächste Zeile</BaseButton>
        </div>
      </div>     -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import StepMixin from "./StepMixin";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import StepAllocation from "./StepAllocation";
import BaseButton from "@/components/core/BaseButton.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { PhFileArrowDown, PhPencilLine, PhTrash } from "phosphor-vue"
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, CurrencyColumn, PillColumn, PercentageColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import { formatNumber } from '@/helpers/number-formatter.js';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';

export default {
  props: {
    tab: {
      type: String,
      default: 'ergebnis-anlageallocation',
    },
  },
  data() {
    return {
      stepAllocation: [
        new StepAllocation(undefined, null),
      ],
      step: INVESTMENT_ADVICE.STEPS.OUTCOME,
      selectedNav: "ergebnis-anlageallocation",
      reset: false,
      percentPrecision: 5,
      gesamtbetrag: null,
      edit_id: 0,
      edit_idx: -1,
      edit_name: '',
      isNichtHandelbar: false,
      aufteilung: 0,
      summe: 0,
      rate: 0,
      turnus: '',
      summeDisbled: false,
      turnusList: [{value: '', label: ''},{ value: "MONATLICH", label: "monatlich"},{ value: "VIERTELJ", label: "vierteljährlich"},{ value: "JAEHRLICH", label: "jährlich"}],
      zielLabel: 'Anlagebetrag',
    };
  },
  components: {
    BaseButton, InputRadioBoxGroup, CurrencyLabel, ComboBox, InputField, Table, 
    PhPencilLine, GhostLoading, Block, NoData, BaseContextMenu, ContextMenuItem,
  },
  mixins: [StepMixin],
  mounted() {
    this.zielLabel = this.parameters('MAIN')?.data?.parameterData?.zielsummeberechnung ? 'Zielsumme' : 'Anlagebetrag';
    
    if (this.rows?.length && !this.edit_id && !this.stepData?.depotoptimierung) {
      this.edit(this.rows[0].id)
    }
  },
  computed: {
    stepData() {
      if (this.stepParameters && this.stepParameters.data) {
        return this.stepParameters.data.parameterData;
      } else {
        return undefined;
      }
    },
    allocationRows() {
      if (this.stepParameters.data?.rows) {
        return this.stepParameters.data.rows;
      } else {
        return null;
      }
    },
    actionsDisabled() {
      return this.loading || this.loadingPositions || (this.stepData?.entnahmeplan.jahressumme === 0.0 &&
      this.stepData.sparplan.jahressumme === 0.0 && this.stepData.einmalanlage.gesamtprozent.toFixed(2) != 100);
    },
    headers() {
      const headers = {
        lockedLeft: [
          PillColumn('lockingModePill', 'Einschränkungen', 150),
        ],
        center: [
          PillColumn('nichtHandelbarPill', '', 20, 0),
          TextColumn('name', 'Name').makeAlwaysVisible().makeLink(),
          PercentageColumn('aufteilung', 'Anteil im Zieldepot *', 5),
          CurrencyColumn('summe', this.zielLabel),
          CurrencyColumn('rate', 'Spar- / Entnahmerate **'),
          TextColumn('turnusDisp', 'Turnus **'),
          TextColumn('isin', 'ISIN'),
          TextColumn('Frs', 'SRI', 50, 0),
          PercentageColumn('anteil', 'Anteil **'),
          TextColumn('depot', 'Depotnummer'),
          TextColumn('lagerstelle', 'Lagerstelle'),
        ],
        lockedRight: [
          ActionColumn('actions', '')
        ]
      };
      if (!this.stepData?.depotoptimierung) {
        headers.lockedLeft.unshift(SlotColumn('active', null, 80));
      }
      return headers;
    },
    rows() {
      if (this.allocationRows?.length) {
        if (this.stepData?.depotoptimierung) {
          return this.allocationRows.map(row => {
            row.actions = row.actions.filter( a => a.actionKey != 'EDIT');
            return row;
          })
        }
        return this.allocationRows;
      } else {
        return []
      }
    },
  },
  methods: {    
    resetAllocation() {
      this.reset = true;
    },
    edit(id) {
      if (!this.rows?.length || this.stepData?.depotoptimierung) {
        return;
      }
      this.edit_id = id;
      this.edit_idx = this.rows.findIndex(row => row.id === id);
      if (this.edit_idx != -1) {
        const row = this.rows[this.edit_idx];
        this.aufteilung = row.aufteilung;
        this.summe = row.summe;
        this.rate = row.rate;
        this.turnus = row.turnus;
        this.edit_name = row.name;
        this.isNichtHandelbar = row.isNichtHandelbar;
        this.summeDisbled = row.lockingMode == 'FEST'  || row.lockingMode == 'IMMER';
      }
    },
    // changeRow(next) {
    //   if (this.edit_idx >= 0 && this.edit_idx < this.rows.length) {
    //     next += this.edit_idx;
    //     if (next >= 0 && next < this.rows.length) {
    //       const table = this.$refs.tblAllocation ;
    //       if (table && table.pageCount > 1) {
    //         if (next < table.pageIndex * table.pageSize ) {
    //           table.onPageChange(table.pageIndex - 1)
    //         } else if (next >= (table.pageIndex + 1) * table.pageSize ) {
    //           table.onPageChange(table.pageIndex + 1)
    //         }
    //       }
    //       this.edit(this.rows[next].id);
    //     }
    //   }
    // },
    anlagesummeVerteilen() {
      if (!this.rows?.length || this.stepData?.depotoptimierung) {
        return;
      }
      if (this.gesamtbetrag == null ) {
        this.gesamtbetrag = this.stepData.einmalanlage.gesamtbetrag + this.stepData.einmalanlage.fehlbetrag ;
      }
      const fixBetrag = this.rows.filter(row => row.lockingMode !== 'JA').reduce( (sum, row) => sum += row.summe, 0);
      const verteilBetrag = this.gesamtbetrag - fixBetrag;
      const editRows = this.rows.filter(row => row.lockingMode === 'JA');
      const rowBetrag = Math.round( verteilBetrag * 100 / editRows.length) / 100;
      const lastBetrag = verteilBetrag - rowBetrag * (editRows.length - 1);
      
      this.modified = true;
      const rows = this.rows.map((row, idx) => {
        if (row.lockingMode === 'JA') {
          row.summe = idx == this.rows.length - 1 ? lastBetrag : rowBetrag;
          row.aufteilung = this.gesamtbetrag ? (row.summe * 100 / this.gesamtbetrag) : 0;
        }
        return row;
      });
      const changes = {
        step: this.step,
        changed: rows
      }
      this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.CHANGE_ROWS, changes)
     
      this.calcAllocation();
    },
    rowChanged(key, idx, value) {
      this.edit(this.rows[idx].id);
      if (key == 'turnus') {
        this.turnus = value;
      } else {
        this[key] = parseFloat(value);
      }
      this.rowEdited();
    },
    rowEdited() {
      if (!this.rows?.length || this.stepData.depotoptimierung) {
        return;
      }
      if (this.gesamtbetrag == null ) {
        this.gesamtbetrag = this.stepData.einmalanlage.gesamtbetrag + this.stepData.einmalanlage.fehlbetrag ;
      }
      
      const row = this.rows[this.edit_idx];
      if (row.aufteilung != this.aufteilung || row.summe != this.summe || row.rate != this.rate || row.turnus != this.turnus) {
        if ( row.summe != this.summe) {
          this.aufteilung = (this.summe * 100 / this.gesamtbetrag) ;
        } else {
          this.summe = Math.round(this.gesamtbetrag * this.aufteilung) / 100;
        }
        if (typeof row.betragBasis == 'number') {
          if (row.lockingMode === 'KEINKAUF' && this.summe > row.betragBasis) {
            this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, {
              show: true,
              title: 'Fehler Position bearbeiten',
              text: 'Kauf ist nicht erlaubt, aber der Betrag von ' + formatNumber(this.summe, 2) + ' € ist größer als der Depotwert von ' + formatNumber(row.betragBasis, 2) + ' €.'
            })
            this.edit(row.id);
            return;
          }
          if (row.lockingMode === 'KEINVERKAUF' && this.summe < row.betragBasis) {
            this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, {
              show: true,
              title: 'Fehler Position bearbeiten',
              text: 'Verkauf ist nicht erlaubt, aber der Betrag von ' + formatNumber(this.summe, 2) + ' € ist kleiner als der Depotwert von ' + formatNumber(row.betragBasis, 2) + ' €.'
            })
            this.edit(row.id);
            return;
          }
        }
        if (this.rate && !this.turnus) {
          this.turnus = 'MONATLICH';
        }
        row.aufteilung = this.aufteilung || 0;
        row.summe = this.summe || 0;
        row.rate = this.rate || 0;
        row.turnus = this.turnus || '';
        this.modified = true;
        row.turnusDisp = '';
        if (row.turnus) {
          row.turnusDisp = {
            MONATLICH: 'monatlich',
            VIERTELJ: 'vierteljährlich',
            JAEHRLICH: 'jährlich',
          }[row.turnus];
        }
        const changes = {
          step: this.step,
          rowIdx: this.edit_idx,
          changed: row
        }
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.CHANGE_ROWS, changes);

        this.calcAllocation();
      }
    },
    calcAllocation() {
      let gesamtbetrag = 0;
      let sparplan = 0;
      let entnahmeplan = 0;
      let rate = 0;
      let durchschnittSRI = 0;
      this.rows.forEach(row => {
        if (row.summe == null) {
          row.summe = 0;
        }
        gesamtbetrag += row.summe;
        durchschnittSRI += row.Frs * row.summe;
        if (row.turnus) {
          rate = row.rate;
          let faktor = 12;
          if (row.turnus === 'JAEHRLICH') {
            faktor = 1;
          } else if (row.turnus === 'VIERTELJ') {
            faktor = 4;
          }
          if (rate > 0) {
            sparplan += rate * faktor;
          } else if (rate < 0) {
            entnahmeplan += -rate * faktor;
          }
        }
      })
      const fehlbetrag = Math.round( (this.gesamtbetrag - gesamtbetrag) * 100 ) / 100;
      const gesamtprozent = Math.round( gesamtbetrag * 10000 / this.gesamtbetrag ) / 100;
      durchschnittSRI = Math.round( durchschnittSRI / gesamtbetrag * 10000 ) / 10000;
      
      this.stepData.einmalanlage.gesamtbetrag = Math.round( gesamtbetrag * 100 ) / 100;
      this.stepData.einmalanlage.fehlbetrag = Math.round( fehlbetrag * 100 ) / 100;
      this.stepData.einmalanlage.gesamtprozent = Math.round( gesamtprozent * 100 ) / 100;
      this.stepData.einmalanlage.durchschnittSRI = durchschnittSRI;
      
      this.stepData.entnahmeplan.jahressumme = Math.round( entnahmeplan * 100 ) / 100;
      this.stepData.sparplan.jahressumme = Math.round( sparplan * 100 ) / 100;
      
      if (this.edit_id) {
        this.edit(this.edit_id);
      }
    },
    save(makePersist, source, allocate) {
      if (this.loading) {
        return Promise.resolve();
      }
      this.loading = true;
      return this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SAVE_PARAMETERS, {
        adviceId: this.id,
        parameters: {
          step: INVESTMENT_ADVICE.STEPS.OUTCOME,
          ergebnisParameterData: this.stepData,
          allocate: allocate,
          rows: this.rows,
        },
      });
    },
    async deletePositions(id) {
      this.stepAllocation[this.currentAsset].loading = true;
      const result = await this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.DELETE_POSITIONS, {
        adviceId :this.id,
        key: INVESTMENT_ADVICE.STEPS.OUTCOME,
        delete: {
          adviceId :this.id,
          quelle: undefined, 
          purpose: INVESTMENT_ADVICE.PURPOSE.OUTPUT,
          positions: id ? [id] : []
        }
      })
      this.stepAllocation[this.currentAsset].loading = false;
      
      if (this.rows?.length && !this.stepData?.depotoptimierung) {
         this.calcAllocation();
      }
    },
    optimisieren() {
      this.loading = true;
      this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_OUTCOME, {
        adviceId: this.adviceId,
        type: INVESTMENT_ADVICE.OUTCOME.OPTIMIZATION,
        refresh: true
      }).then(() => {
        this.loadStepData();
      });
    },
    doAction({key, row}) {
      switch (key) {
        case 'FACTSHEET':
          viewDocument({href: row.FACTSHEET_URL})
          break;
        case 'FACTSHEET_KAG':
          viewDocument({href: row.FACTSHEET_KAG})
          break;
        case 'PRIIP_BIB':
          viewDocument({href: row.PRIIP_BIB_URL})
          break;
        case 'DELETE':
          this.deletePositions(row.id)
          break;
        default:
          break;
      }
    },
    gotoFondsinfo(row) {
      const path = `/shared/vermogensubersicht/fondsinfo/${row.isin}`;
      this.$router.push({ path });
    },
    viewDocument(href) {
      viewDocument({href});
    },
  },
  watch: {
    stepData() {
      if (this.stepData?.depotoptimierung === false) {
        this.gesamtbetrag = this.stepData.einmalanlage.gesamtbetrag + this.stepData.einmalanlage.fehlbetrag;
      }
    },
    rows() {
      if (this.rows?.length ) {
        const doppelt = {};
        let isinDoppelt = '';
        this.rows.forEach( row => {
          if ( doppelt[row.isin]) {
            doppelt[row.isin] += 1;
            if (doppelt[row.isin] == 2) {
              isinDoppelt += ', ' + row.isin
            }
          } else {
            doppelt[row.isin] = 1;
          }
        })
        if (isinDoppelt) {
          isinDoppelt = isinDoppelt.slice(2);
          const fond = isinDoppelt.length <= 15 ? 'Fond ist' : 'Fonds sind'
          this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, {
            show: true,
            title: 'Fehler',
            text: 'Folgende ' + fond + ' mehrfach vorhanden: "' + isinDoppelt + '"<br>Bitte korrigieren.'
          })
        }
      }
      if (this.rows?.length && !this.stepData?.depotoptimierung) {
        if (!this.edit_id) {
          this.edit(this.rows[0].id)
        }
      } else {
        this.edit_id = 0;
      }
    }
  }
};
</script>
<style scoped>
.nav {
  padding-left: 0px;
}
.d-flex > .box__container {
  flex-grow: 1;
}
.show-edit-radio {
  display: inline-block;
  padding-right: 5px;
}
.edited-row {
  display: inline-block;
  height: 100%;
  width: 18px;
  box-sizing: border-box;
  position: relative;
}
.edited-row > span {
  background-color: #eee;
}
.edited-row > .selected {
  background-color: transparent;
}
.nicht-handelbar {
  color: var(--color-danger);
}
.text-danger {
  color: var(--color-danger);
}
.text-warning {
  color: var(--color-danger);
}
</style>
