<template>
  <div>
    <BaseModal
      ref="addEditModal"
      modalTitle="Shortcut"
      :autoClose="false"
      @onConfirmButton="submit()"
      @close="close()"
    >
        <!--<div>Platzhalter</div>
        <div class="row">
          <div
              class="col-12 col-sm-4 col-md-2 col-lg-2"
              :key="'p' + i"
              v-for="(p, i) in placeholdersOptions"
          >
            <ComboBox
                firstEmpty
                v-model="placeholder"
                :label="p.name"
                :values="p.data"
                @change="insertPlaceholder($event)"
            >
            </ComboBox>
          </div>
        </div>
        <hr/>-->
        <div>
          <InputField
            label="Shortcut"
            v-model="shortcut.key"
            @input="changeShortcut(shortcut, $event, 'key')"
            :maxLength="100"
          />

          <span class="validation_error" v-if="htmlShortcuts.some((htmlShortcut) => htmlShortcut.key == shortcut.key)">Der Shortcut ist bereits belegt.</span>

          <InputTextArea
            label="Beschreibung"
            v-model="shortcut.description"
            @input="changeShortcut(shortcut, $event, 'description')"
            :maxlength="1000">
          </InputTextArea>

          <InputToggleSwitch
            label="Vermittler Freigabe"
            v-model="shortcut.forBroker"
            @input="changeShortcut(shortcut, $event, 'forBroker')"
          />
        </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import { mapGetters } from 'vuex';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import TEMPLATES_EDIT_TYPES from "@/store/templatesEdit/types";
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

export default {
  props: {
    shortcut: {
      default: () => ({}),
    },
  },

  data() {
    return {
      placeholder: '',
      placeholderValue: '',
    };
  },

  computed: {
    ...mapGetters({
      placeholders: MAILCOMPOSER_TYPES.GETTERS.GET_PLACEHOLDERS,
      htmlShortcuts: TEMPLATES_EDIT_TYPES.GETTERS.HTML_SHORTCUTS,
    }),
    placeholdersOptions() {
      const keys = this.placeholders && Object.keys(this.placeholders)
      return this.placeholders && Object.values(this.placeholders).map((v, i) => ({
        name: keys[i],
        data: v.map(p => ({
          value: Object.values(p)[0],
          label: Object.keys(p)[0]
        })
        )
      })) || []
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    
    insertPlaceholder(value) {
      this.shortcut.value = this.shortcut.value + value;
      this.$nextTick(() => {
        this.placeholder = '';
      });
    },

    changeShortcut(item, value, field) {
      this.shortcut[field] = value;
    },

    async submit() {
      if(this.htmlShortcuts.some((htmlShortcut) => htmlShortcut.key == this.shortcut.key)) {
        this.shortcut.key += '(1)'
      }
      this.$emit('changeShortcutValue', this.shortcut)
        this.close();
    },
  },
  mounted() {
    this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS, {});
    this.$refs.addEditModal.open();
  },
  components: {
    BaseModal,
    ComboBox,
    InputField,
    InputToggleSwitch,
    InputTextArea,
  },
};
</script>

<style scoped>
.validation_error {
  color: red;
}
</style>
