<template>
    <div v-if="visibleActions.length" class="d-flex justify-content-end">
        <BaseContextMenu :tid="tid">
            <ContextMenuItem v-for="action in visibleActions" :key="action.actionKey">
                <component
                    :is="action.component"
                    :row="row"
                    :tid="tid"
                    expanded
                    v-bind="action"
                    @action="onAction"
                />
            </ContextMenuItem>
        </BaseContextMenu>
    </div>
</template>
<script>
import BaseDropdownMenu from "@/components/core/BaseDropdownMenu.vue";
import ContextMenuItem from "@/components/core/base-context-menu/ContextMenuItem.vue";
import {PhDotsThreeVertical} from "phosphor-vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';

export default {
    mixins: [InteractiveHelpCommonsMixin],
    components: {
        BaseDropdownMenu,
        PhDotsThreeVertical,
        BaseContextMenu,
        ContextMenuItem,
    },
    props: {
        column: {
            type: String,
            required: true,
        },
        row: {
            type: Object,
            required: true,
        },
        tid: {
            type: String,
            required: true,
        }
    },
    computed: {
        visibleActions() {
            if (!this.row[this.column] || !this.row[this.column].length)
                return [];
            return this.row[this.column]
                //.filter(action => action.visible(this.row));
        },
    },
    methods: {
        onAction({key, row}) {
            this.$emit("action", {key, row});
        },
    },
}
</script>
<style scoped>
</style>
