import EXTERNE_ZUGANG_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index';

export default {
  [EXTERNE_ZUGANG_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState());
  },

  [EXTERNE_ZUGANG_TYPES.MUTATIONS.GET_EXTERNE_ZUGANG_LIST_ABSCHLUSS_SUCCESS](state, payload) {
    Vue.set(state, 'externeZugangAbschluss', payload);
  },

  [EXTERNE_ZUGANG_TYPES.MUTATIONS.GET_EXTERNE_ZUGANG_LIST_VERGLEICH_SUCCESS](state, payload) {
    Vue.set(state, 'externeZugangVergleich', payload);
  },
}
