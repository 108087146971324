<template>
  <div>
    <PageHeaderTitleNavigation v-on="$listeners" :defaultMenu="customOptionsMenu">
      <template #title>{{ gesellschaftName }}</template>
        <template #subtitle>
          <template v-if="bezeichnung">
            <span>{{ bezeichnung }}</span>
            <span> | </span>
          </template>
          <template>
            <span>Schadensmeldung</span>
          </template>
      </template>
    </PageHeaderTitleNavigation>

    <div class="box__container">
      <ZahlungenGeschaedigte :schadenId="schadenId" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ZahlungenGeschaedigte from './ZahlungenGeschaedigte.vue';
import { TabMenu, } from '@/menu/menu-utils';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ZahlungenGeschaedigte
  },
  computed: {
    ...mapGetters({
      configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
    }),
    detailsData() {
      return this.configData || null;
    },
    gesellschaftName() {
      return this.detailsData?.gesellschaft || '';
    },
    bezeichnung() {
      return this.detailsData?.sparte || '';
    },
    vertragId() {
      return this.$route.params.vertragId;
    },
    schadenId() {
      return this.$route.params.schadenId;
    },
    customOptionsMenu() {
      let result = [ 
        TabMenu(`/home/versicherungen/insurance-group/schadensmeldung/${this.vertragId}/${this.schadenId}`, 'Schadensmeldung'),
        TabMenu(`/home/versicherungen/insurance-group/schadensmeldung/zahlungen-geschaedigte/${this.vertragId}/${this.schadenId}`, 'Schadenzahlung'),
      ]
      return result;
    },
  },
}
</script>
