import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for changing the state
 * actions: asynchronous operation responsible for calling external resources and commiting mutations 
 */

export function getInitialState() {
  return {
    collatedPdfs: [],

    pdfUploads: [],
    pdfUploadStatus: null,
    pdfUploadDeckblatt: false,
    deckblattCombos: {}, // indexed by kundennr
    comboValues: {}, // indexed by key
    SEND_RUN:false,
    RESULT_TABLE :[]
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}