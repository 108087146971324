import EMAIL_NOT_RECOGNIZED_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';

const config = {
	defaultSpinner: true
};

export default {
	async [EMAIL_NOT_RECOGNIZED_TYPES.ACTIONS.GET_EMAIL_NOT_RECOGNIZED]({ getters, commit}) {

		const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/email_not_recognized/email_not_recognized`, config);
		if (response.data) {
			commit(EMAIL_NOT_RECOGNIZED_TYPES.MUTATIONS.GET_EMAIL_NOT_RECOGNIZED_SUCCESS, { emailNotRecognized: response.data });
		}

	},

	async [EMAIL_NOT_RECOGNIZED_TYPES.ACTIONS.REMOVE_EMAIL_NOT_RECOGNIZED]({ getters, commit }, id) {
		const deleteUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/email_not_recognized/email_not_recognized?id=${id}`
		const response = await axios.delete(deleteUrl, config);
		if (response.data) {
			commit(EMAIL_NOT_RECOGNIZED_TYPES.MUTATIONS.GET_EMAIL_NOT_RECOGNIZED_SUCCESS, { emailNotRecognized: response.data });
		}
	},

}