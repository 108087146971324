<template>
  <div class="menu-structure-edit__container">
    <PageHeaderTitleNavigation 
      title="Menüstruktur" 
      :subtitle="menuStructure.label" 
      :actions="headerActions" 
      @action-DISCARD="discardChanges()" 
      @action-EDIT="editMenuStructure()" 
    />

    <div class="box__container">
      <GhostLoading v-if="loading" type="title" />
      <template v-else>
        <MenuStructureSortable 
          :menus="primaryMenu" 
          @orderChanged="orderChanged($event)"
          @add="addMenu($event)" 
          @remove="removeMenu($event)" 
          @addCustom="openAddCustomMenuModal($event)" 
        />
      </template>
    </div>

    <AddMenuStructureModal ref="addMenuStructureModal" @saved="onMenuStructureSaved" />
    <OptionMenuAddCustomMenuModal ref="optionMenuAddCustomMenuModal" @add="addMenu($event)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_STRUCTURE_TYPES from '@/store/menuStructure/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import MenuStructureSortable from '@/components/core/menu/menuStructure/MenuStructureSortable.vue';
import AddMenuStructureModal from '@/components/core/menu/menuStructure/AddMenuStructureModal.vue';
import OptionMenuAddCustomMenuModal from '@/components/core/menu/menuStructure/optionMenuCustom/OptionMenuAddCustomMenuModal.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    PageHeaderTitleNavigation, 
    GhostLoading, 
    MenuStructureSortable, 
    AddMenuStructureModal, 
    OptionMenuAddCustomMenuModal, 
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      menuStructureContext: MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURE_CONTEXT,
      hasEdited: MENU_STRUCTURE_TYPES.GETTERS.HAS_MENU_STRUCTURE_EDITED,
    }),
    paramId() {
      return parseInt(this.$route.params?.id);
    },
    menuStructure() {
      const { menuStructure, menuStructureEdited } = this.menuStructureContext || {};
      return menuStructureEdited || menuStructure || {};
    },
    headerActions() {
      const { loading, hasEdited } = this;

      return [
        PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen')
          .withDisabled(() => loading || !hasEdited),
        PageHeaderSimpleAction('EDIT', 'Bearbeiten')
          .withDisabled(() => loading),
        // TODO add an action to create a variation of the current menu
      ];
    },
    primaryMenu() {
      const { primaryMenu } = this.menuStructureContext;
      return [ ...primaryMenu ];
    },
  },
  methods: {
    async init(id) {
      try {
        this.loading = true;
        await this.$store.dispatch(MENU_STRUCTURE_TYPES.ACTIONS.SETUP_MENU_STRUCTURE_CONTEXT, { id });
      } finally {
        this.loading = false;
      }
    },
    orderChanged({ parent, menus }) {
      this.$store.commit(MENU_STRUCTURE_TYPES.MUTATIONS.ORDER_CHANGED, { 
        parent, 
        menus, 
      });
    },
    addMenu({ parent, menus }) {
      this.$store.commit(MENU_STRUCTURE_TYPES.MUTATIONS.ADD_MENU, { 
        parent, 
        menus, 
      });
    },
    removeMenu({ parent, menu }) {
      this.$store.commit(MENU_STRUCTURE_TYPES.MUTATIONS.REMOVE_MENU, { 
        parent, 
        menu, 
      });
    },
    openAddCustomMenuModal({ parent }) {
      this.$refs.optionMenuAddCustomMenuModal?.open(parent);
    },
    async saveOptionsMenu() {
      await this.$store.dispatch(MENU_STRUCTURE_TYPES.ACTIONS.SAVE_OPTIONS_MENU, { 
        menuStructureId: this.paramId, 
      });
    },
    discardChanges() {
      this.$store.commit(MENU_STRUCTURE_TYPES.MUTATIONS.RESET_MENU_STRUCTURE_CONTEXT_EDITED);
    },
    editMenuStructure() {
      this.$refs.addMenuStructureModal?.open(this.menuStructure);
    },
    onMenuStructureSaved(menuStructure) {
      this.$store.commit(MENU_STRUCTURE_TYPES.MUTATIONS.SET_MENU_STRUCTURE_EDITED, menuStructure);
    },
  },
  async mounted() {
    this.init(this.paramId);
  },
  async beforeRouteLeave(from, to, next) {
    await this.saveOptionsMenu();
    next();
  }
}
</script>
