var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress-bar__container" }, [
    _c("div", {
      staticClass: "progress-bar",
      class: {
        "progress-bar-finish": _vm.isFinished,
        "progress-bar-started": _vm.isStarted,
        "progress-bar-keep-animation-active": _vm.keepAnimationActive
      },
      on: { transitionend: _vm.onTransitionEnd }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }