import WERT_TYPES from './types';

export default {
    [WERT_TYPES.GETTERS.GET_PERFORMANCE_DATA](state) {
        return state.performanceData;
    },
    [WERT_TYPES.GETTERS.GET_HISTORICAL_DATA](state) {
        return state.historicalData;
    },
    [WERT_TYPES.GETTERS.GET_REALIZED_PROFIT](state) {
        return state.realizedProfit;
    },
    [WERT_TYPES.GETTERS.GET_YEAR_PERFORMANCE_DATA](state) {
        return state.yearPerformanceData;
    },
    [WERT_TYPES.GETTERS.GET_TRANSACTIONS](state) {
        return state.transactions;
    },
    [WERT_TYPES.GETTERS.GET_DEPOT_CONFIGS](state) {
        return state.gepotConfigs;
    },
    [WERT_TYPES.GETTERS.GET_PERFORMACE_PAYLOAD](state) {
        return state.performancePayload;
    },
}