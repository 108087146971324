<template>
  <div>
    <PageHeaderTitleNavigation
      title="Vermögensverwaltungen"
      subtitle="Produktliste"
      :defaultMenu="noHeaderMenu ? [] : $appNavigation.currentTabMenu"
      :actions="headerActions"
      @action-NEW="openRegularFondsfinder"
    />

    <div class="box__container">
      Für die Zusammenstellung Ihrer persönlichen Strategie benötigen Sie vom
      Vermögensverwalter überprüfte und bestätigte Investmentfonds. Unter den
      unten aufgeführten Reitern können Sie sich einen Überblick über bereits
      zertifizierte, beantrage oder abgelehnte Wertpapiere schaffen. Sollten die
      von Ihnen gewünschten Wertpapiere fehlen, bitten wir Sie diese über
      <b>„Neues Wertpapier vorschlagen“</b> dem Vermögensverwalter zur
      Zertifizierung vorzulegen. Bitte beachten Sie, dass Sie den Status auf
      „Beantragt“ setzen. Der Status „in Vorbereitung“ dient ausschließlich
      Ihrem Auswahlverfahren. Bitte beachten Sie, dass der von Ihnen gewünschte
      Fonds ein Fondsvolumen von mindestens 30 Mio € und älter als drei Jahre
      sein sollte. Unter Bemerkungen können Sie weitere Informationen zum Fonds
      eingeben. Nach der Überprüfung des Wertpapiers, durch den
      Vermögensverwalter, erhalten Sie eine Benachrichtigung per Mail über den
      Status des Fonds.
    </div>

    <FondsFinderSuchMaske emitFilters @onSearch="search" />
    <FondsFinderPositionsList
      zurueckButtonText="Zurück zur Vermögensverwaltung"
      :goBackEnabled="isSelectable"
      :isSelectable="isSelectable"
      :isBuyPositions="false"
      @goBack="navigateToEditor"
      @handleTableAction="handleTableAction"
    />
  </div>
</template>

<script>
import FondsFinderSuchMaske from "@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue";
import FondsFinderPositionsList from "@/components/antrag/fondsfinder/FondsFinderPositionsList.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import CORE_TYPES from "@/store/core/types";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";

export default {
  mixins: [mixin],
  props: {
    noHeaderMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data() {
    return {
      emitFilters: true,
    };
  },
  mounted() {
    setTimeout(() => this.search(), 200);
  },
  computed: {
    headerActions() {
      return [PageHeaderSimpleAction("NEW", "Neues Wertpapier vorschlagen")];
    },
    act() {
      return `search_${this.$route.query.categoryId}..`;
    },
    isSelectable() {
      return this.$route.params.versionId ? true : false;
    },
  },
  methods: {
    openRegularFondsfinder() {
      this.$addBreadcrumb({
        label: "zurück zur Liste der verfügbaren Wertpapiere",
        fullPath: this.$route.fullPath,
        breadcrumb: "Wertpapiersuche",
      });
      this.$router.push({
        path: `/beratung/vv/fondsfinder-neu/${this.$route.params.schemaId}/${this.$route.params.versionId}`,
        query: { categoryId: `neu_${this.$route.query.categoryId}` },
      });
    },
    search() {
      this.$store.dispatch(VV_TYPES.ACTIONS.VV_GET_AVAILABLE_FONDS, {
        versionId: this.$route.params.versionId,
        act: this.act,
        showOnlyGenehmigt: this.isSelectable ? "1" : "0",
      });
    },
    navigateToEditor(data = []) {
      if (data?.length) {
        this.$store.dispatch(VV_TYPES.ACTIONS.ADD_GENEHMIGT_WERTPAPIERE, {
          versionId: this.$route.params.versionId,
          isins: data.map((fond) => fond.isin),
        });
      }
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE);
      this.$router.push({
        path: `/beratung/vv/vv-schema-editor/${this.$route.params.schemaId}/${this.$route.params.versionId}`,
        query: {
          backAction: true,
          substepKey: `${this.$route.params.versionId}`,
        },
      });
    },
  },
};
</script>

<style>
</style>