<template>
  <div class="dashboard-container">
    <Dashboard type="'broker'" :allowConfig="false" :allowListView="isTest || isBypass" :disabledCards="disabledCards" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '../../store/core/types';
import DASHBOARD_TYPES from '@/components/dashboard/store/types';
import Dashboard from '@/components/dashboard/Dashboard.vue'

export default {
  props: {
    isIntern: {
      type: Boolean,
      default: () => false,
      required: false
    },
  },
  data: () => {
    return {
    }
  },

  computed: {
    ...mapGetters({
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
      isBypass: CORE_TYPES.GETTERS.IS_BYPASS,
      disabledCards: DASHBOARD_TYPES.GETTERS.GET_ENABLED_CARDS,
    }),
  },

  components: {
    Dashboard,
  },
}
</script>

<style scoped>

</style>