import BIPRO_TYPES from '@/store/bipro/types';
import { mapGetters } from "vuex";


export default {
  data() {
    return {
    };
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      deepLinksGetter: BIPRO_TYPES.GETTERS.DEEPLINKS,
    }),
  },
  methods: {
    async findDeeplinks(vertragId) {
      try {
        this.loading = true;
        this.$set(this, "deepLinks", []);
        await this.$store.dispatch(BIPRO_TYPES.ACTIONS.FIND_INSURANCE_DEEPLINKS, { vertragId });
      } finally {
        this.loading = false;
      }
    },
     
  },
}
