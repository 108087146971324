import VERTRAG_WP_TYPES from './types';
import CORE_TYPES from '@/store/core/types.js'
import { prepareFilenameForDownload } from '@/helpers/string-helper';
import { makeQueryParam } from '@/helpers/utils-helper';

export default {
	[VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_ACTIVE_FIELDS](state){
		return state.vertragWP.activeFields || {}
	},

	[VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_FORM_DATA](state){
		return state.vertragWP.formData
	},

	[VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_HINWEIS](state){
		return state.vertragWPHinweis
	},

	[VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_VALUES](state){
		return state.vertragWPValues
	},

	[VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_ERRORS](state) {
		return state.vertragWP.errors || []
	},

	[VERTRAG_WP_TYPES.GETTERS.GET_PDF_LINK_GENERATOR](state, getters) {
    return (pruefungId, filename) => {
      const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]
      const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]

      const params = makeQueryParam({ pruefungId, token })
      
      return `${apiAddress}/download_service/vertrag_wp_pruefung/${prepareFilenameForDownload(filename)}?${params}`;
    }
  },

	[VERTRAG_WP_TYPES.GETTERS.GET_SIGNO_VIEWER_LINK_GENERATOR](state, getters) {
    return (pruefungId) => {
      const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]
      const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]

      const params = makeQueryParam({ pruefungId, token })
      
      return `${apiAddress}/vertrag_wp/signo_viewer?${params}`;
    }
  },

}