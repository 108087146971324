<template>
  <div class="antrag-components__container">
    <div class="box__container">

      <InputRadioBoxGroup 
        v-model="form.alleDepot" 
        :validateUntouched="true"
        :values="[
          {value: 'alleDepot', label: 'Alle Positionen des Depots sind vollständig zu übertragen'},
          {value: '', label: 'Bitte übertragen Sie die folgend ausgewählten Positionen in den angegebenen Stückzahlen:'}
        ]"
        @input="$emit('save', { alleDepot: $event ? true : false })"/>

      <WertpapierauswahlDepotUebertragung
        :hasGesamtbestand="true"
        :positions="positionData"
        :anzahlFonds="anzahlFonds"
        :disabled="form.alleDepot === 'alleDepot'"
        @save="$emit('save', $event)"
        @fondsfinder="$emit('fondsfinder', $event)">
      </WertpapierauswahlDepotUebertragung>
      <FormDivider/>

      <InputToggleSwitch v-model="form.sparplanBeenden" label="bestehende Sparpläne löschen"
        @input="$emit('save', { sparplanBeenden: $event })"/>
      <InputToggleSwitch v-model="form.freistellungBeenden" label="Löschung des Freistellungauftrags"
        @input="$emit('save', { freistellungBeenden: $event })"/>
      <InputToggleSwitch v-model="form.depotAufloesen" label="anschließende Depotauflösung"
        @input="$emit('save', { depotAufloesen: $event })"/>
         <InputToggleSwitch v-model="form.kontoAufloesen" label="anschließende Kontoauflösung"
        @input="$emit('save', { kontoAufloesen: $event })"/>
      <InputToggleSwitch v-model="form.verkaufBruchstuecke" label="Bruchstücke sind zu verkaufen und der Erlös auf mein folgendes Konto gutzuschreiben:"
        @input="$emit('save', { verkaufBruchstuecke: $event })"/>

      <EntitySelector v-model="form.bankverbindungId" :values="bankverbindungSelection" label="Verwende Konto" v-if="form.verkaufBruchstuecke" @new="editBankverbindung" @edit="editBankverbindung"
        @change="$emit('save', { bankverbindungId: $event === 'NICHT_VERWENDEN' ? null : $event })"/>

      <BaseButton class="col-12" @click="$emit('back')">Zurück</BaseButton>
    </div>
  </div>
</template>
<script>

import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import WertpapierauswahlDepotUebertragung from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierauswahlDepotUebertragung.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import validator from "@/mixins/validator";

export default {
  mixins: [antragNavigationMixin, antragMixin, validator],
  validators: {},
  components: {
    InputToggleSwitch,
    BaseButton,
    EntitySelector,
    FormDivider,
    WertpapierauswahlDepotUebertragung,
    InputRadioBoxGroup,
  },
  props: {
    positionData: {
    },
    bankverbindungSelection: {
    },
    anzahlFonds: {
      type: Number,
      default: 4,
    },
    warnings: {
    },
  },
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    console.log(this.bankverbindungSelection)
    this.form = { 
      alleDepot: this.positionData.alleDepot === true ? 'alleDepot' : '',
      sparplanBeenden: this.positionData.sparplanBeenden,
      freistellungBeenden: this.positionData.freistellungBeenden,
      depotAufloesen: this.positionData.depotAufloesen,
      kontoAufloesen:this.positionData.kontoAufloesen,
      verkaufBruchstuecke: this.positionData.verkaufBruchstuecke,
      bankverbindungId: this.generateCombinedBvId(this.positionData.bankverbindungId),
    };
    const errors = this.warningsPositionUebertragungEditor(this.warnings, this.form);
    errors?.forEach(err => this.$pushErrors(err.id, err.message));
    this.$emit('hasErrors', errors?.length);
  },
  methods: {
    editBankverbindung(event) {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG);
    },
    generateCombinedBvId(id){
      if(Number(id)<0){
        return 'BGS_' + id.substring(1)
      }else{
        return id;
      }
      
    }
  },
}
</script>
<style scoped>

</style>