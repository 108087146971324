<template>
<div class="person-checks-component" 
  :data-person-id="selectedPersonId" 
  :data-is-firma="isFirmaSelected"
  :data-olden-than="olderThanSelected"></div>
</template>

<script>
import { mapGetters } from "vuex";

import ANTRAG_TYPES from "@/store/antrag/types";
import CUSTOMERDATA_TYPES from "@/store/customerData/types.js";

const DEFAULT_YES = "1";
const DEFAULT_NO = "0";

export default {
  props: {
    id: {
      type: String,
    },
    antragId: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ['update'],
  computed: {
    ...mapGetters({
      antragData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
      customerData: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA,
      additionalPersons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
    }),
    persons() {
      const additionalPersons = (this.additionalPersons || []);
      return [this.customerData, ...additionalPersons];
    },
    selectedPersonId() {
      return this.antragData[this.antragId] && this.antragData[this.antragId][this.personInput];
    },
    selectedPerson() {
      return this.persons.find(person => person && (person.personId+'') === (this.selectedPersonId+''));
    },
    personInput() {
      return this.config.person;
    },
    isFirmaSelected() {
      return this.antragData[this.antragId] && this.antragData[this.antragId][this.isFirmaInput];
    },
    isFirmaInput() {
      return this.config.isFirma;
    },
    isGesVertreterInput() {
      return this.config.isGesVertreter;
    },
    isKindInput() {
      return this.config.isKind;
    },
    hasAdressInput() {
      return this.config.hasAdress;
    },
    olderThanSelected() {
      return this.antragData[this.antragId] && this.antragData[this.antragId][this.olderThanInput];
    },
    olderThanInput() {
      return this.config.olderThan;
    },
    olderThanAge() {
      return this.config.olderThanAge;
    },
    hasAdressYes() {
      return (this.config && this.config.hasAdressYes) || DEFAULT_YES;
    },
    hasAdressNo() {
      return (this.config && this.config.hasAdressNo) || DEFAULT_NO;
    },
    isFirmaYes() {
      return (this.config && this.config.isFirmaYes) || DEFAULT_YES;
    },
    isFirmaNo() {
      return (this.config && this.config.isFirmaNo) || DEFAULT_NO;
    },
  },
  watch: {
    selectedPersonId() {
      this.update();
    },
    persons() {
      this.update();
    }
  },
  methods: {
    update() {
      const person = this.selectedPerson;

      const valueChanges = {};

      // Input hasAdress
      if (this.hasAdressInput) {
        valueChanges[this.hasAdressInput] = person && this.isValidShippingAddress(person.shippingAddress) ? this.hasAdressYes : this.hasAdressNo;
      }

      // Input isFirma
      if(this.isFirmaInput) {
        valueChanges[this.isFirmaInput] = person && this.isFirma(person.personalDataAddress) ? this.isFirmaYes : this.isFirmaNo;
      }

      // Input isGesVertreter
      if(this.isGesVertreterInput && person && person.personalDataBirth && person.personalDataBirth.dayOfBirth) {
        const birthDate = this.parseDateOfBirth(person.personalDataBirth.dayOfBirth);
        const age = this.getAgeFromNow(birthDate);
        valueChanges[this.isGesVertreterInput] = (!this.isFirma(person.personalDataAddress) && (age > 18)) ? DEFAULT_YES : DEFAULT_NO;
      } else if(this.isGesVertreterInput && person){
        valueChanges[this.isGesVertreterInput] = !this.isFirma(person.personalDataAddress)  ? DEFAULT_YES : DEFAULT_NO
      }

      // Input olderThan
      if(this.olderThanInput && this.olderThanAge && person && person.personalDataBirth && person.personalDataBirth.dayOfBirth) {
        const birthDate = this.parseDateOfBirth(person.personalDataBirth.dayOfBirth);
        const age = this.getAgeFromNow(birthDate);
        valueChanges[this.olderThanInput] = age > this.olderThanAge ? DEFAULT_YES : DEFAULT_NO;
      } else if (this.olderThanInput) {
        valueChanges[this.olderThanInput] = undefined;
      }

      //isKind
      if(this.isKindInput && person && person.personalDataBirth && person.personalDataBirth.dayOfBirth) {
        const birthDate = this.parseDateOfBirth(person.personalDataBirth.dayOfBirth);
        const age = this.getAgeFromNow(birthDate);
        valueChanges[this.isKindInput] = (!this.isFirma(person.personalDataAddress) && (age < 18)) ? DEFAULT_YES : DEFAULT_NO;
      } else if (this.isKindInput) {
        valueChanges[this.isKindInput] = DEFAULT_NO;
      }
      
      /* emit update only if the values changed */
      if (Object.keys(valueChanges).length && this.antragId && Object.keys(valueChanges).some(key => this.antragData[this.antragId][key] !== valueChanges[key])) {
        this.$emit('update', valueChanges);
      }
    },
    isValidShippingAddress(shippingAddress) {
      return shippingAddress && (shippingAddress.city || shippingAddress.country || shippingAddress.street || shippingAddress.zip);
    },
    isFirma(personalDataAddress) {
      return personalDataAddress && personalDataAddress.title == "Firma";
    },
    parseDateOfBirth(dayOfBirth) {
      if (typeof(dayOfBirth) == "string") {
        return new Date(dayOfBirth.split('.').reverse().join('-'));
      }

      return dayOfBirth;
    },
    getAgeFromNow(birthDate) {
      const now = new Date();
      const age = now.getFullYear() - birthDate.getFullYear();

      if(now.getMonth() < birthDate.getMonth() || now.getMonth() == birthDate.getMonth() && now.getDate() < birthDate.getDate()) {
        return age - 1;
      } else {
        return age;
      }
    }
  },
  mounted() {
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS);

    this.update();
  }
};
</script>

<style scoped>
.person-checks-component {
  display: none;
}
</style>
