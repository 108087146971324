<template>
  <div>
    <PageHeaderTitleNavigation 
      :defaultMenu="$appNavigation.currentTabMenu"
      :actions="headerActions"
      @action-FONDSPLATTFORMENVERGLEICH="openFondsplattformenvergleichModal"
      title="Courtagetabellen"
      subtitle="Offene Wertpapiere" 
    />

    <WertpapierSearch 
      ref="wertpapierFilter"
      name="searchWPCourtage"
      emitFilters
      isCourtage
      :type="wi_type"
      :defaultOptions="defaultFilterOptions"
      @onSearch="handleSearch"
    />

    <div class="box__container" v-if="fondsOhneCourtageText">
      <div class="box__title">Info</div>
      <span v-html="sanitize(fondsOhneCourtageText)"/>
    </div>
    <div class="box__container" v-if="gebuehrFondsOhneCourtageInfo">
      <div class="box__title">Gebühr: Fonds ohne Courtage</div>
      <span v-html="sanitize(gebuehrFondsOhneCourtageInfo)"/>
    </div>

    <div v-if="filtered" class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />

      <NoData v-else-if="rowCount == 0" :title="TABLE_TITLE" />

      <template v-else-if="rowCount">
        <!-- MSC-24486 removed the PaginatedTable because it brings no performance optimisation, 
		        but in the opposite it convinces to load the hole data by each page load. 
		        The hole table is calculated in the method servlet.kscPerformDaten(status, requestData) -->
        <!-- <PaginatedTable 
          tableId="99aaf2c1-69c9-42f3-9118-978d8ea8f459"
          :title="TABLE_TITLE"
          :headers="headers"
          :pages="pages"
          :pageCount="pageCount"
          :rowCount="rowCount"
          :page="selectedPage"
          :exportConfig="exportConfig"
          @page="selectedPage = $event"
          @requestPage="loadPage"
        /> -->
        <Table
          :title="TABLE_TITLE"
          tableId="99aaf2c1-69c9-42f3-9118-978d8ea8f459"
          :headers="headers"
          :rows="rows"
          rowId="isin"
          :rowsPerPage="20"
          :exportConfig="exportConfig"
          :mobileConfig="{title: 'fondsname', headers: ['gesellschaft', 'abschlussProvision', 'bestandsProvision']}"
        />
      </template>
    </div>

    <BaseModal 
      ref="fondsplattformenModal" 
      labelButtonConfirm="Vergleichen"
      modalTitle="Fondsplattformen"
      @onConfirmButton="filterFondsplattformenvergleich()" 
    >

      <div class="row">
        <div class="col-12">
          <Table v-if="fondsplattformen && fondsplattformen.rows.length"
            tableId="18bed2fa-f425-44cf-b011-714f72243980"
            rowId="lagerstelle"
            :headers="fondsplattformen.headers"
            :rows="fondsplattformen.rows"
            v-model="selectedRows"
          />
        </div>
      </div>
    </BaseModal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import COURTAGETABELLE_INVESTMENT_TYPES from '@/store/courtagetabelleInvestment/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import PaginatedTable from '@/components/table2/PaginatedTable.vue';
import Table from '@/components/table2/Table.vue';
import {TextColumn, } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import {sanitize} from '@/helpers/string-helper.js';
import WertpapierSearch from '@/components/wertpapierinfo/WertpapierSearch.vue';
import pageSizeMixin from './page-size-mixin.js';
import BaseButton from '@/components/core/BaseButton.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const WERTPAPIER_FILTER_LAGERSTELLE_OPTION = '1.1.06';
const type = 'OFFENEFONDS';
const TABLE_TITLE = 'Courtagetabelle';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    PaginatedTable,
    Table,
    GhostLoading,
    NoData,
    BaseModal,
    WertpapierSearch,
    BaseButton,
  },
  mixins: [pageSizeMixin],
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      selectedPage: 0,
      selectedFilters: {},
      exportSelectedFilters: {},
      wi_type: 'I',
      orderBy: null,
      selectedRows: [],
      isComparingFonds: false,
      filtered: false,
    }
  },
  computed: {
    ...mapGetters({
      courtagetabelleInvestmentList: COURTAGETABELLE_INVESTMENT_TYPES.GETTERS.COURTAGETABELLE_INVESTMENT_LIST,
      courtagetabelleInvestmentFondsplattformen: COURTAGETABELLE_INVESTMENT_TYPES.GETTERS.COURTAGETABELLE_INVESTMENT_FONDSPLATTFORMEN,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    defaultFilterOptions() {
      return {
        [WERTPAPIER_FILTER_LAGERSTELLE_OPTION]: {
          fixed: true,
          value: this.isFA ? 'CAPITALBANK' : 'NICHT_VERWENDEN',
        },
      };
    },
    headers() {
      if(this.courtagetabelleInvestmentList?.headers) {
        return {
          lockedLeft: [],
          center: this.courtagetabelleInvestmentList.headers.map(header => TextColumn(header.key, header.label)),
          lockedRight: [],
        }
      }
      return [];
    },
    pages() {
      if (!this.courtagetabelleInvestmentList?.rows) {
        return [];
      }

      return { [this.selectedPage]: this.courtagetabelleInvestmentList.rows.map(row => ({...row, ...row.fondsComparison})) }
    },
    rows() {
      return this.courtagetabelleInvestmentList?.rows?.map(row => ({...row, ...row.fondsComparison})) || [];
    },
    rowCount() {
      return this.courtagetabelleInvestmentList?.count || 0;
    },
    pageCount() {
      return Math.ceil(this.rowCount / this.maxCount);
    },
    fondsOhneCourtageText() {
      return this.courtagetabelleInvestmentList?.fondsOhneCourtageText || null;
    },
    gebuehrFondsOhneCourtageInfo() {
      return this.courtagetabelleInvestmentList?.gebuehrFondsOhneCourtageInfo || null;
    },
    fondsplattformen() {
      if(this.courtagetabelleInvestmentFondsplattformen?.rows?.length) {
        return {
          headers: {lockedLeft: this.courtagetabelleInvestmentFondsplattformen.headers.map(header => TextColumn(header.key, header.label))},
          rows: this.courtagetabelleInvestmentFondsplattformen.rows
          }
      }
      return null;
    },
    exportConfig() {
      return {
        roottext: 'Offene Wertpapiere',
        // dispatch: this.exportFile, needed only for PaginatedTable
      };
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('FONDSPLATTFORMENVERGLEICH', 'Fondsplattformenvergleich')
        .withVisible(() => !this.isFA)
        .withDisabled(() => this.loading)
      ];
    }, 
  },
  methods:{
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    doSearch() {
      this.$refs?.wertpapierFilter?.$refs?.filter?.$refs?.baseFilter?.emitFilterEvent();
    },
    filterFondsplattformenvergleich() {
      this.isComparingFonds = true;
      this.doSearch()
    },
    handleSearch(event){
      this.selectedFilters = event;
      const lagerstelle = WERTPAPIER_FILTER_LAGERSTELLE_OPTION;

      if (this.isComparingFonds) {
        this.selectedFilters.selectedFondsplattformen = this.selectedRows.map(selected => selected.lagerstelle)

        if (lagerstelle in this.selectedFilters) {
          delete this.selectedFilters[lagerstelle]
          this.$refs?.wertpapierFilter?.$refs?.filter?.$refs?.baseFilter?.removeRowsByKey('1.1', lagerstelle);
        }

        this.isComparingFonds = false;
      } else {
        if (!Object.keys(this.selectedFilters).length || !this.selectedFilters[lagerstelle] || this.selectedFilters[lagerstelle] === 'NICHT_VERWENDEN') {
          this.emitAlertOfMissingFilter();
          return;
        }
      }

      this.filterData();
    },
    filterData() {
      this.courtagetabelleInvestmentList.fondsOhneCourtageText = null;
      this.courtagetabelleInvestmentList.gebuehrFondsOhneCourtageInfo = null;

      this.loading = true;
      this.filtered = true;
      this.exportSelectedFilters = { ...this.selectedFilters, };
      this.$store.dispatch(COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.GET_COURTAGETABELLE_INVESTMENT_LIST, 
          {filters: this.selectedFilters, page: this.selectedPage, order: this.orderBy, type, maxCount: this.maxCount}
        )
        .finally(() => this.loading = false);
    },
    loadPage(event){
        this.selectedPage = event;
        this.filterData();
    },
    sortDataByColumn(event) {
      if(event){
        this.orderBy = event;
        this.filterData();
      }
    },
    emitAlertOfMissingFilter() {
      this.$confirmModal({
        message: 'Bitte wählen Sie eine Lagerstelle aus',
        title: 'Benötigter Filter',
        showConfirmButton: false,
        labelButtonCancel: 'Zurück'
      }).catch(() => {})
    },
    openFondsplattformenvergleichModal() {
      if(!this.fondsplattformen?.rows?.length) {
        this.$store.dispatch(COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.GET_COURTAGETABLLE_INVESTMENT_FONDSPLATTFORMEN,{page: this.selectedPage, type})
        .then(response => {
            this.$refs.fondsplattformenModal?.open();
          });
      } else  {
        this.$refs.fondsplattformenModal?.open();
      }
    },
    // async exportFile(fileType) {
    //   if(!Object.keys(this.exportSelectedFilters).length) return;

    //   return await this.$store.dispatch(COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.EXPORT_COURTAGETABELLE_INVESTMENT, {
    //     filters: this.exportSelectedFilters,
    //     type,
    //     fileType,
    //   });
    // },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Details', 
      to,
      from,
      replaceLastPreviousData: true,
    });

    this.selectedFilters = null;
    this.$store.commit(COURTAGETABELLE_INVESTMENT_TYPES.MUTATIONS.CLEAR_COURTAGETABELLE_INVESTMENT_LIST);

    next()
  },
}
</script>
