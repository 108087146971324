import ANSPRECHPARTNER_TYPES from './types';
import CORE_TYPES from "@/store/core/types";

import axios from 'axios';
import * as utils from '../../helpers/commonfunctions';

const config = {
  defaultSpinner: true
};

const multipartConfig = {
  defaultSpinner: true,
  headers: {'Content-Type': 'multipart/form-data'},
};

export default {
  
  [ANSPRECHPARTNER_TYPES.ACTIONS.SAVE_ANSPRECHPARTNER]({ dispatch, commit, state }, payload) {
    return new Promise((resolve, reject) => {
      if(!state.dataHasChanged) {
        resolve();
      } else {
        let serviceUrl = '/gesellschaftPersonenDatenService/saveAnsprechpartner';
        axios.post(`${process.env.VUE_APP_API}`  + serviceUrl, state.ansprechpartnerData, config)
          .then((response) => {
            if(state.tempUserPicture && response.data.id) {
              payload = {
                personId: response.data.id,
                file: state.tempUserPicture.file,
              }
              dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.SAVE_USER_PICTURE, payload);
            }
            commit(ANSPRECHPARTNER_TYPES.MUTATIONS.SET_ANSPRECHPARTNER, response.data)
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
        });
      }
    });
  },

  [ANSPRECHPARTNER_TYPES.ACTIONS.SAVE_USER_PICTURE]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', payload.file);
      if(payload.personId) {
        formData.append('personId', payload.personId);
      }

      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsuserpicture`, formData, multipartConfig)
        .then((response) => {
          dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.GET_USER_PICTURE, payload);
          commit(ANSPRECHPARTNER_TYPES.MUTATIONS.SET_TEMP_USER_PICTURE, null)
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async [ANSPRECHPARTNER_TYPES.ACTIONS.DELETE_USER_PICTURE]({ getters, dispatch }, payload) {
    let serviceUrl = '/mrsuserpicture';

    if (payload) {
      if (payload.personId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'personId', payload.personId);
      }
    }

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}` + serviceUrl, config)
      .then(_ => {
        dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.GET_USER_PICTURE, payload);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  [ANSPRECHPARTNER_TYPES.ACTIONS.GET_USER_PICTURE]({ commit }, payload) {
    let serviceUrl = '/gesellschaftPersonenDatenService/getAPPicture';

    if (payload) {
      if (payload.personId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'personId', payload.personId);
      }
    } 

    axios.get(`${process.env.VUE_APP_API}` + serviceUrl).then(response => {
      if (response.data) {
        commit(ANSPRECHPARTNER_TYPES.MUTATIONS.GET_USER_PICTURE_SUCCESS, response.data);
      }
    })
  },

}