var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseToolbarColorPicker", {
    directives: [
      {
        name: "tippy",
        rawName: "v-tippy",
        value: { arrow: true },
        expression: "{ arrow: true }"
      }
    ],
    attrs: {
      value: _vm.value,
      disabled: _vm.disabled,
      content: "Schriftfarbe"
    },
    on: { change: _vm.action }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }