var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cards__container" },
    [
      _c("div", { staticClass: "box__container cards__item" }, [
        _c(
          "div",
          { staticClass: "cards__items" },
          [
            _c("BaseCollapsable", {
              attrs: { showSection: _vm.showExpanded },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "cards__item--header" }, [
                        _c(
                          "div",
                          {
                            staticClass: "box__title cards__item--header-title"
                          },
                          [
                            _c("span", [
                              _c("a", [_vm._v(_vm._s(_vm.position.fondsname))])
                            ]),
                            _c("span", { staticClass: "text-divider" }, [
                              _vm._v("|")
                            ]),
                            _c("span", [
                              _vm._v("SRI " + _vm._s(_vm.position.sri))
                            ]),
                            _c("span", { staticClass: "text-divider" }, [
                              _vm._v("|")
                            ]),
                            _c(
                              "span",
                              { class: _vm.getColorClass(_vm.position) },
                              [_vm._v(_vm._s(_vm.position.art))]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "cards__item--header-actions" },
                          [
                            _c(
                              "a",
                              {
                                attrs: { title: "Position löschen" },
                                on: {
                                  click: function($event) {
                                    return _vm.openModalDeleteFond(_vm.position)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "cards__item--header-action--item-icon"
                                  },
                                  [_c("PhTrash", { attrs: { size: 16 } })],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("InputField", {
                            staticClass: "ml-4 mt-2 mr-3",
                            attrs: {
                              label: "Betrag",
                              isEmbedded: "",
                              type: "currency",
                              precision: "2",
                              id: "betrag" + _vm.position.index,
                              value: _vm.position.betrag,
                              disabled: _vm.disabled
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChangeInputField(
                                  $event,
                                  _vm.position,
                                  "betrag"
                                )
                              }
                            }
                          }),
                          _vm.categoryId == "KAUF"
                            ? _c("ComboBox", {
                                staticClass: "fixed-width",
                                attrs: {
                                  label: "Rabatt auf Agio",
                                  isEmbedded: "",
                                  sortComboboxValues: false,
                                  id: "aa" + _vm.position.index,
                                  value: _vm.position.aa,
                                  values: _vm.sparplanAAValues,
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      _vm.position,
                                      "aa"
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.categoryId == "SPARPLAN"
                            ? _c("ComboBox", {
                                staticClass: "ml-3 mt-2 mr-3",
                                attrs: {
                                  label: "Abbuchung jeweils zum:",
                                  isEmbedded: "",
                                  id: "beginnTag" + _vm.position.index,
                                  value: _vm.position.beginnTag,
                                  values: [
                                    { label: "", value: "" },
                                    { label: "1.", value: "1" },
                                    { label: "15.", value: "15" }
                                  ],
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      _vm.position,
                                      "beginnTag"
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.categoryId == "SPARPLAN"
                            ? _c("ComboBox", {
                                staticClass: "ml-3 mt-2 mr-3",
                                attrs: {
                                  label: "Turnus",
                                  isEmbedded: "",
                                  sortComboboxValues: false,
                                  id: "frequenz" + _vm.position.index,
                                  value: _vm.position.frequenz,
                                  values: [
                                    { label: "", value: "" },
                                    { label: "monatlich", value: "MONATLICH" },
                                    {
                                      label: "vierteljährlich",
                                      value: "VIERTELJ"
                                    }
                                  ],
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      _vm.position,
                                      "frequenz"
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _vm.categoryId == "SPARPLAN"
                            ? _c("DatePickerField", {
                                staticClass: "ml-2 mt-2 mr-2",
                                attrs: {
                                  monthPicker: "",
                                  dateFormat: "MM.YYYY",
                                  label: "Abbuchungstermin Sparplan",
                                  isEmbedded: "",
                                  id: "beginnMonat" + _vm.position.index,
                                  value: _vm.position.beginnMonat,
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      _vm.position,
                                      "beginnMonat"
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.categoryId == "SPARPLAN"
                            ? _c("ComboBox", {
                                staticClass: "fixed-width",
                                attrs: {
                                  label: "Dynamik",
                                  isEmbedded: "",
                                  sortComboboxValues: false,
                                  id: "dynamik" + _vm.position.index,
                                  value: _vm.position.dynamik,
                                  values: _vm.sparplanDynamikValues,
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      _vm.position,
                                      "dynamik"
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _vm.categoryId == "SPARPLAN"
                            ? _c("DatePickerField", {
                                staticClass: "ml-2 mt-2 mr-2",
                                attrs: {
                                  monthPicker: "",
                                  dateFormat: "MM.YYYY",
                                  label: "Letzte Ausführung",
                                  isEmbedded: "",
                                  id: "endMonat" + _vm.position.index,
                                  value: _vm.position.endMonat,
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      _vm.position,
                                      "endMonat"
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.categoryId == "SPARPLAN"
                            ? _c("ComboBox", {
                                staticClass: "fixed-width",
                                attrs: {
                                  label: "Rabatt auf Agio",
                                  isEmbedded: "",
                                  sortComboboxValues: false,
                                  id: "aa" + _vm.position.index,
                                  value: _vm.position.aa,
                                  values: _vm.sparplanAAValues,
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      _vm.position,
                                      "aa"
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.position, positionLabel: _vm.position.art },
        on: {
          delete: function($event) {
            return _vm.removePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }