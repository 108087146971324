var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "anlageassistent_container" }, [
    _c(
      "div",
      { staticClass: "banner" },
      [_c("editable-frame", { attrs: { type: "anlageassistent_title_page" } })],
      1
    ),
    _c("hr"),
    _c("div", { staticClass: "anlageassistent_content" }, [
      _c(
        "div",
        { staticClass: "anlageassistent_image" },
        [
          _c("EditableImageOnly", { attrs: { type: "anlageassistent_image1" } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "anlageassistent_text" },
        [
          _c("editable-frame", { attrs: { type: "anlageassistent_text1" } }),
          _c(
            "base-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.push("assistent")
                }
              }
            },
            [_vm._v("JETZT ANLAGEASSISTENT ENTDECKEN!")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }