var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "legitimation-images-firma__container" },
    [
      !_vm.rechtsform
        ? _c("div", { staticClass: "box__container" }, [_vm._m(0)])
        : _c(
            "div",
            _vm._l(_vm.requiredFiles, function(requiredFile) {
              return _c("div", { staticClass: "scans-container" }, [
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("ImageUpload", {
                      staticClass: "scan-container",
                      attrs: {
                        title: requiredFile.title,
                        edgeCorrection: "",
                        image: _vm.previewImages[requiredFile.shorthand],
                        loading: _vm.isLoading,
                        disabled:
                          _vm.isLoading || !_vm.isEditable || _vm.disabled,
                        config: _vm.imageUploadConfig,
                        photoCamera: "back",
                        hasDeleteButton: ""
                      },
                      on: {
                        delete: function($event) {
                          return _vm.deleteImage(requiredFile.shorthand)
                        },
                        file: function($event) {
                          return _vm.convertFileIfNeeded(
                            [$event],
                            requiredFile.shorthand
                          )
                        },
                        change: function($event) {
                          return _vm.uploadImage(
                            [$event],
                            requiredFile.shorthand
                          )
                        }
                      }
                    }),
                    _vm.isFA &&
                    (requiredFile.shorthand === "HRBSEITE1" ||
                      requiredFile.shorthand === "TRANSPARENZREGISTERAUSZUG")
                      ? _c("DatePickerField", {
                          attrs: {
                            label: "Austellungsdatum",
                            isValueAsString: "",
                            isComponentHalfSize: true,
                            disabled:
                              _vm.isLoading ||
                              !_vm.isEditable ||
                              _vm.disabled ||
                              _vm.previewImages[requiredFile.shorthand] ===
                                undefined
                          },
                          on: {
                            change: function($event) {
                              return _vm.reupload(
                                requiredFile.shorthand,
                                this.legitimation.unterlagenFirma[
                                  requiredFile.shorthand
                                ]
                              )
                            }
                          },
                          model: {
                            value:
                              _vm.unterlagenFirmaDates[requiredFile.shorthand],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.unterlagenFirmaDates,
                                requiredFile.shorthand,
                                $$v
                              )
                            },
                            expression:
                              "unterlagenFirmaDates[requiredFile.shorthand]"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            }),
            0
          ),
      _vm.hasActions && !_vm.isSmallScreen
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "row mb-0" }, [
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isSecondary: "",
                        disabled: _vm.isLoading || _vm.disabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.deleteImages.open()
                        }
                      }
                    },
                    [_vm._v("Gespeicherte Bilder löschen")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("DownloadLink", {
                    attrs: {
                      downloadServicePath: "/generateLegitimationPDF",
                      title: "PDF herunterladen",
                      queryParams: { personId: _vm.customerData.personId },
                      asButton: ""
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("p", [_vm._v("Sonstige Unterlagen")]),
          _vm._l(_vm.otherFiles, function(otherFile) {
            return _c("div", [
              _vm.previewImages[otherFile.shorthand]
                ? _c(
                    "div",
                    [
                      _c("ImageUpload", {
                        staticClass: "scan-container",
                        attrs: {
                          title: otherFile.title,
                          edgeCorrection: "",
                          image: _vm.previewImages[otherFile.shorthand],
                          loading: _vm.isLoading,
                          disabled:
                            _vm.isLoading || !_vm.isEditable || _vm.disabled,
                          config: _vm.imageUploadConfig,
                          photoCamera: "back",
                          hasDeleteButton: ""
                        },
                        on: {
                          delete: function($event) {
                            return _vm.deleteImage(otherFile.shorthand)
                          },
                          file: function($event) {
                            return _vm.convertFileIfNeeded(
                              [$event],
                              otherFile.shorthand
                            )
                          },
                          change: function($event) {
                            return _vm.uploadImage(
                              [$event],
                              otherFile.shorthand
                            )
                          }
                        }
                      }),
                      _c("ComboBox", {
                        attrs: {
                          label: "Unterlagentyp ändern",
                          values: _vm.requiredEmptyFilesAsCombobox,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeUnterlagentyp(
                              otherFile.shorthand,
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          })
        ],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "deleteImages",
          attrs: {
            modalTitle: "Löschen Legitimationsurkunde",
            labelButtonConfirm: "Löschen",
            labelButtonCancel: "Abbrechen",
            isModalAsFullPage: false
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deleteImages()
            }
          }
        },
        [_vm._v(" Möchten Sie die gespeicherten Dokumente wirklich löschen? ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "font-bold" }, [
        _vm._v("Schritt Stammdaten: ")
      ]),
      _vm._v("Bitte setzen Sie zuerst die Rechtsform.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }