var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-xl-8" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Wählen Sie die Positionen zum Verkauf aus:",
                    values: _vm.valuesForDepot,
                    disabled: _vm.disabled
                  },
                  on: { input: _vm.onChangeInput },
                  model: {
                    value: _vm.depotPosition,
                    callback: function($$v) {
                      _vm.depotPosition = $$v
                    },
                    expression: "depotPosition"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-xl-4 mt-4 pl-2" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      isEmbedded: true,
                      disabled: !_vm.depotPosition || _vm.disabled
                    },
                    on: {
                      click: function($event) {
                        return _vm.addPosition()
                      }
                    }
                  },
                  [_vm._v("Fonds hinzufügen")]
                )
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                tableId: "46c30c0a-aa2b-4885-b3ef-cfedaeec59tr",
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "isin",
                hidePagination: ""
              },
              on: { "action-DELETE": _vm.openModalDeleteFond },
              scopedSlots: _vm._u(
                [
                  {
                    key: "betraag",
                    fn: function(row) {
                      return [
                        _c("ComboBox", {
                          attrs: {
                            values: _vm.anlageValues,
                            value: row.betrag,
                            disabled: row.verkaufBestandGesamt || _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "betrag"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "betrag",
                    fn: function(row) {
                      return [
                        row.betrag === true
                          ? _c("InputField", {
                              attrs: {
                                type: "currency",
                                value: row.wert,
                                disabled:
                                  row.verkaufBestandGesamt || _vm.disabled,
                                id: "wert" + row.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    row,
                                    "wert"
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        !(row.betrag === true)
                          ? _c("InputField", {
                              attrs: {
                                type: "number",
                                value: row.wert,
                                disabled:
                                  row.verkaufBestandGesamt || _vm.disabled,
                                id: "wert" + row.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    row,
                                    "wert"
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "verkaufBestandGesamt",
                    fn: function(row) {
                      return [
                        _c("InputToggleSwitch", {
                          attrs: {
                            value: row.verkaufBestandGesamt,
                            id: "verkaufBestandGesamt" + row.index,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "verkaufBestandGesamt"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "sparplanLoeschen",
                    fn: function(row) {
                      return [
                        _c("InputToggleSwitch", {
                          attrs: {
                            value: row.sparplanLoeschen,
                            id: "sparplanLoeschen" + row.index,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "sparplanLoeschen"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "verkaufDatum",
                    fn: function(row) {
                      return [
                        _c("DatePickerField", {
                          attrs: {
                            value: row.verkaufDatum,
                            id: "verkaufDatum" + row.index,
                            isValueAsString: "",
                            dateFormat: "DD.MM.YYYY"
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "verkaufDatum"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "bemerkung",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: {
                            value: row.bemerkung,
                            id: "bemerkung" + row.index
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "bemerkung"
                              )
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                2651666262
              )
            })
          : _vm._e(),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: { position: _vm.positionToDelete },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }