var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          title: "Courtagetabellen",
          subtitle: "Beteiligungen"
        }
      }),
      _vm.searchMetadata && _vm.searchMetadata.length
        ? _c("BaseFilter", {
            attrs: {
              filterId: "courtagetabelleInvestmentBeteiligingenSuche",
              title: "Beteiligungen",
              replacePreviousSelectedValues: "",
              metadata: _vm.searchMetadata,
              configFilter: _vm.configFilter
            },
            on: {
              onFilter: function($event) {
                return _vm.handleSearch($event)
              }
            }
          })
        : _vm._e(),
      _vm.gebuehrFondsOhneCourtageInfo
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "box__title" }, [_vm._v("Info")]),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.sanitize(_vm.gebuehrFondsOhneCourtageInfo)
                )
              }
            })
          ])
        : _vm._e(),
      _vm.filtered
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.TABLE_TITLE }
                  })
                : _vm.rowCount == 0
                ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
                : _vm.rowCount
                ? [
                    _c("PaginatedTable", {
                      attrs: {
                        tableId: "99aaf2c1-69c9-42f3-9118-978d8ea8f459",
                        title: _vm.TABLE_TITLE,
                        headers: _vm.headers,
                        pages: _vm.pages,
                        pageCount: _vm.pageCount,
                        rowCount: _vm.rowCount,
                        page: _vm.selectedPage,
                        exportConfig: _vm.exportConfig
                      },
                      on: {
                        page: function($event) {
                          _vm.selectedPage = $event
                        },
                        requestPage: _vm.loadPage,
                        sort: function($event) {
                          return _vm.sortDataByColumn($event)
                        }
                      },
                      model: {
                        value: _vm.selectedRow,
                        callback: function($$v) {
                          _vm.selectedRow = $$v
                        },
                        expression: "selectedRow"
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }