import FONDSFAVORITEN_TYPES from './types';
import { getInitialState } from './index'

export default {
  [FONDSFAVORITEN_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [FONDSFAVORITEN_TYPES.MUTATIONS.LOAD_FAVORITENLISTS_SUCCESS](state, payload) {
    state.favoritenlists = payload
  },
  [FONDSFAVORITEN_TYPES.MUTATIONS.LOAD_FAVORITENLISTS_OPTIONS_SUCCESS](state, payload) {
    state.options = payload
  },
}