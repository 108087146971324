var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("h1", [_vm._v("Ich wünsche eine Beratung")]),
    _c(
      "fieldset",
      [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.viewer.anrede,
                expression: "viewer.anrede"
              }
            ],
            attrs: { id: "select-id" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.viewer,
                  "anrede",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { disabled: "", value: "" } }, [
              _vm._v("Bitte auswählen")
            ]),
            _c("option", [_vm._v("Frau")]),
            _c("option", [_vm._v("Herr")])
          ]
        ),
        _c("InputField", {
          attrs: { label: "Vorname: " },
          model: {
            value: _vm.viewer.vorname,
            callback: function($$v) {
              _vm.$set(_vm.viewer, "vorname", $$v)
            },
            expression: "viewer.vorname"
          }
        }),
        _c("InputField", {
          attrs: { label: "Nachname: " },
          model: {
            value: _vm.viewer.nachname,
            callback: function($$v) {
              _vm.$set(_vm.viewer, "nachname", $$v)
            },
            expression: "viewer.nachname"
          }
        }),
        _c("InputField", {
          attrs: { label: "E-Mail: " },
          model: {
            value: _vm.viewer.email,
            callback: function($$v) {
              _vm.$set(_vm.viewer, "email", $$v)
            },
            expression: "viewer.email"
          }
        }),
        _c("InputField", {
          attrs: { label: "Telefon: ", inputmode: "tel" },
          model: {
            value: _vm.viewer.telefon,
            callback: function($$v) {
              _vm.$set(_vm.viewer, "telefon", $$v)
            },
            expression: "viewer.telefon"
          }
        }),
        _c(
          "div",
          [
            _c(
              "BaseButton",
              {
                attrs: { disabled: _vm.validation.isInvalid("viewer") },
                on: { click: _vm.sendBeratungsChat }
              },
              [_vm._v("Send")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }