var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c("div", { staticClass: "box__title" }, [_vm._v("Maklervertrag")]),
    _vm.darfVersicherungMaklerauftragSehen
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("DatePickerField", {
              attrs: {
                label: "Maklervertrag seit",
                isValueAsString: "",
                isComponentHalfSize: true,
                disabled: ""
              },
              model: {
                value: _vm.sonstiges.maklervertragErstelleDatum,
                callback: function($$v) {
                  _vm.$set(_vm.sonstiges, "maklervertragErstelleDatum", $$v)
                },
                expression: "sonstiges.maklervertragErstelleDatum"
              }
            }),
            _c("DatePickerField", {
              attrs: {
                label: "Maklervertrag gekündigt",
                isValueAsString: "",
                isComponentHalfSize: true,
                validateUntouched: ""
              },
              on: {
                input: function($event) {
                  return _vm.addCustomerDataEdited("sonstiges")
                }
              },
              model: {
                value: _vm.sonstiges.maklervertragGekuendigtDatum,
                callback: function($$v) {
                  _vm.$set(_vm.sonstiges, "maklervertragGekuendigtDatum", $$v)
                },
                expression: "sonstiges.maklervertragGekuendigtDatum"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }