var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: { "action-NEW_ANSPRECHPARTNER": _vm.editOrCreateAnsprechpartner }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: {
            tid: _vm._generateTidFromString(
              "cards__container layout__negative-margin--8"
            )
          }
        },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "fbfd1c1b-3e8c-4c41-9101-5f4ff8265777",
                  title: _vm.TABLE_TITLE,
                  rows: _vm.rows,
                  headers: _vm.headers,
                  rowsPerPage: 20
                },
                on: {
                  "click-name": _vm.editOrCreateAnsprechpartner,
                  "action-DELETE": _vm.deleteAnsprechpartner
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "telefon",
                      fn: function(row) {
                        return [
                          _vm.isTelefon
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(row.telefon)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tapiCall(row.telefon)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.telefon))]
                              )
                            : _c(
                                "label",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(row.telefon)
                                  }
                                },
                                [_vm._v(_vm._s(row.telefon))]
                              )
                        ]
                      }
                    },
                    {
                      key: "mobil",
                      fn: function(row) {
                        return [
                          _vm.isTelefon
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(row.mobil)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tapiCall(row.mobil)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.mobil))]
                              )
                            : _c(
                                "label",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(row.mobil)
                                  }
                                },
                                [_vm._v(_vm._s(row.mobil))]
                              )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3942056681
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  content: "Keine Ansprechpartner hinterlegt"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }