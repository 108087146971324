var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table", title: "Sonstiges" } })
        : [
            _c("InputToggleSwitch", {
              attrs: { label: "Eigene App", inLineLabel: "" },
              model: {
                value: _vm.eigeneApp,
                callback: function($$v) {
                  _vm.eigeneApp = $$v
                },
                expression: "eigeneApp"
              }
            }),
            _c("InputField", {
              attrs: { label: "App Name", disabled: !_vm.sonstiges.eigeneApp },
              model: {
                value: _vm.appName,
                callback: function($$v) {
                  _vm.appName = $$v
                },
                expression: "appName"
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }