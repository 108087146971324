<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :actions="headerActions"
      @action-ADD="createItem()"
    />

    <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
    <Table
      tableId="c53e30f8-2048-4256-b9bc-0e2992645f4e"
      :title="TABLE_TITLE"
      class="table-container"
      v-else-if="!loading && tableRows.length"
      :headers="tableHeaders"
      :rows="tableRows"
      :rowsPerPage="20"
      :hidePagination="tableRows.length < 21"
      :exportConfig="{ roottext: TABLE_TITLE }" 
      @click-dateCreated="editItem($event)"
      @action="executeAction($event)"
      dragnDropFilesOnRow
      @files="chooseUploadType($event)"
    />
    <NoData v-else :title="TABLE_TITLE" />

    <BaseModal :confirmDisabled="filesAlreadyAdded" @onConfirmButton="handleRowFiles(uploadData)" ref="uploadMOdal" modalTitle="Hochladen" labelButtonConfirm="Hochladen" :showConfirmButton="!filesAlreadyAdded">
      <span v-if="filesAlreadyAdded">Bereits hinzugefügte Dateien. Bitte entfernen Sie diese zuerst.</span>
      <ComboBox
            v-else
            label="Hochladen Typ"
            v-model="uploadType"
            :firstEmpty="false"
            :values="uploadTypeOptions"
          />
    </BaseModal>    
  </div>
</template>

<script>
import VUBLOCK_TYPES from "@/store/vuBlock/types";
import { mapGetters } from "vuex";
import { PhTrash } from "phosphor-vue";

import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import NoData from "@/components/core/NoData.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  DateColumn,
  ActionColumn,
  ConfirmedAction,
} from "@/components/table2/table_util.js";
import { PageHeaderLoadingAction } from "@/components/core/header-title-navigation/page-header-utils";
import VersUebertragBlock  from '@/views/intern/VersUebertragBlock.js';
import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import Vue from 'vue';

export default {
  mixins: [VersUebertragBlock],

  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    NoData,
    GhostLoading,
    BaseModal,
    ComboBox,
  },

  data: function () {
    return {
      loading: false,
      loadingNew: false,

      vuBlockDataAll: [],

      TABLE_TITLE: "Bestandsübertragungen",
      ACTION_DELETE_VUBLOCK: "ACTION_DELETE_VUBLOCK",

      tableHeaders: {
        lockedLeft: [
          DateColumn("dateCreated", "Datum").makeLink().makeAlwaysVisible(),
        ],
        center: [
          TextColumn("status", "Status"),
          TextColumn("fertigText", "Übertragungen abgeschlossen"),
        ],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      },
      uploadType: VUBLOCK_TYPES.FILE_AUSKUNFTSVOLLMACHT,
      uploadData: {},
    };
  },

  mounted() {
    this.loading = true;

    this.$store
      .dispatch(VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK_ALL)
      .then((response) => {
        this.vuBlockDataAll = response?.data || [];
        this.$store.commit(VUBLOCK_TYPES.MUTATIONS.UPDATE_VUBLOCK_SELECTED, {});
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    async chooseUploadType(event) {
      this.uploadData = event;
      this.$refs.uploadMOdal.open();
    },
    async handleRowFiles(event) {
      const tempFiles = event.files;
      const row = event.row;

      let vuBlockPathId = row?.id;

      if (
        this.selectedVuBlock?.id == vuBlockPathId &&
        this.selectedVuBlockGesellId
      ) {
        // same vuBlock and currently working on a gesellschaft: only reload the gesellschaft line
        await this.$store.dispatch(VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK_GESELL, {
          id: this.selectedVuBlockGesellId,
        });
      } else {
        this.loading = true;

        await this.$store
          .dispatch(VUBLOCK_TYPES.ACTIONS.GET_VUBLOCK, {
            id: vuBlockPathId,
          })
          .finally(() => {
            this.loading = false;
          });
      }


      this.onFileUpload(tempFiles, this.uploadType);
    },    
    executeAction(actionData) {
      switch (actionData.key) {
        case this.ACTION_DELETE_VUBLOCK:
          this.deleteItem(actionData.row);
          break;
      }
    },

    deleteItem(row) {
      this.$store
        .dispatch(VUBLOCK_TYPES.ACTIONS.DELETE_VUBLOCK, {
          id: row.id,
        })
        .then(() => {
          this.vuBlockDataAll = this.vuBlockDataAll.filter(
            (data) => data.id != row.id
          );
        });
    },

    createItem() {
      this.loadingNew = true;

      this.$store
        .dispatch(VUBLOCK_TYPES.ACTIONS.CREATE_VUBLOCK)
        .then((response) => {
          if (response?.data) {
            this.vuBlockDataAll.push(response.data);
          }
        })
        .finally(() => {
          this.loadingNew = false;
        });
    },

    editItem(row) {
      this.$router.push({ path: `bearbeiten/${row.id}` });
    },

  },
  watch: {
    selectedVuBlock: {
      handler(newValue) {

        let updatedRow = this.vuBlockDataAll.find(v => v.id == newValue?.id);

      if (updatedRow?.id) {
        updatedRow.fileNameAuskunftsvollmacht = newValue?.fileNameAuskunftsvollmacht;
        updatedRow.fileNameEinverstaendnis = newValue?.fileNameEinverstaendnis;


        if (updatedRow.fileNameAuskunftsvollmacht && !updatedRow.fileNameEinverstaendnis) {
          this.uploadType = VUBLOCK_TYPES.FILE_EINVERSTAENDNIS;
        }
      }

      },
      deep: true,
    },    
  },
  computed: {
    ...mapGetters({
      selectedVuBlock: VUBLOCK_TYPES.GETTERS.VUBLOCK_SELECTED,
      isEditAllowed: VUBLOCK_TYPES.GETTERS.VIEW_IS_EDIT_ALLOWED,
    }),
    filesAlreadyAdded() {
      return !!this.uploadData?.row?.fileNameAuskunftsvollmacht && !!this.uploadData?.row?.fileNameEinverstaendnis;
      
    },
    uploadTypeOptions() {
      const result = [];

      if (!this.uploadData?.row?.fileNameAuskunftsvollmacht) {
        result.push({label: "Auskunftsvollmacht", value: VUBLOCK_TYPES.FILE_AUSKUNFTSVOLLMACHT});
      }

      if (!this.uploadData?.row?.fileNameEinverstaendnis) {
        result.push({label: "Einverständniserklärung", value: VUBLOCK_TYPES.FILE_EINVERSTAENDNIS});
      }

      return result;
    }, 
    headerActions() {
      return this.isEditAllowed
        ? [
            PageHeaderLoadingAction("ADD", "Hinzufügen", () => {
              return this.loadingNew;
            }),
          ]
        : [];
    },

    tableRows() {
      if (!this.vuBlockDataAll?.length) {
        return [];
      }

      return this.vuBlockDataAll?.map((data) => ({
        ...data,
        actions: this.isEditAllowed
          ? [
              ConfirmedAction(
                this.ACTION_DELETE_VUBLOCK,
                PhTrash,
                "Löschen",
                `Wollen Sie den Eintrag ${data.dateCreated}, ${data.fertigText} wirklich löschen?`,
                "Bestätigung",
                "Ja"
              ),
            ]
          : [],
      }));
    },
  },

  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück zur Liste",
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    next();
  },
};
</script>

<style scoped>
</style>