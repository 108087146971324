<template>
  <div>
    <div class="drop_down__area" v-if="isSmallScreen">
      <div class="title__area box__title" v-if="title">
        {{ title }} 
      </div>
      <div class="actions__area">
        <FotoButton v-if="cameraAvailable && hasPhotoButton" class="image-upload__button" buttonClass="foto-button" isLink isSecondary  
          @file="upload([$event])" :disabled="disabled" :edgeCorrection="edgeCorrection" :camera="photoCamera">
          {{ photoButtonText }}
        </FotoButton>        
        <BaseContextMenu>
          <template>
            <div class="drop_down__buttons">
              <BaseFileSelect v-if="hasUploadButton" class="image-upload__button" isClear  @files="upload($event)" accept="image/png, image/jpeg, .pdf" :disabled="disabled">
                {{ uploadButtonText }}
              </BaseFileSelect>
              <BaseButton isClear v-if="hasImage && hasEditTool"  @click="openCroppingDialog()" :disabled="disabled || loading">
                Bearbeiten
              </BaseButton>        
            </div>
          </template>

          <ContextMenuItem v-for="action of actions" :key="action.key">
            <component
              v-if="action.component"
              v-bind="action"
              :is="action.component"
              :tid="_generateTidFromString(action.key)"
              @action="handleComponentAction">
              <template :slot="`action-${action.actionKey}`"><slot :name="`action-${action.actionKey}`" /></template>
            </component>
          </ContextMenuItem>



        </BaseContextMenu>    
      </div>
    </div>



    <DragnDropArea @files="upload($event)" :hoverText="hoverText" class="image-upload__container"
        :class="{ 'has-image': hasImage, 'no-image': !hasImage, }" :disabled="disabled" :tid="_generateTidFromString(title ? title : '')">
      <div class="image-upload__img-container mb-3">
        <div v-if="title && (!isSmallScreen)" class="box__title">
          {{ title }} <AnimatedSpinner v-if="loading" />
        </div>
        <img v-if="image" :src="image" class="image-upload__img" :class="[`image-${imageFit}`]" :style="imageStyle" ref="image" crossorigin="anonymous"/>
        <ImageCroppingDialog v-if="editImage" :src="editImage" @cancel="editImage=null" @file="onChange($event)" :detectedCorners="[]" :simpleDialog="true" />
      </div>
      <div v-if="!isSmallScreen">
        <BaseButtonsContainer v-if="hasImage" class="mt-3">
          <BaseFileSelect v-if="hasUploadButton" class="image-upload__button" isPrimary  @files="upload($event)" accept="image/png, image/jpeg, .pdf" :disabled="disabled">
            {{ uploadButtonText }}
          </BaseFileSelect>
          <FotoButton v-if="cameraAvailable && hasPhotoButton" class="image-upload__button" isSecondary  
            @file="upload([$event])" :disabled="disabled" :edgeCorrection="edgeCorrection" :camera="photoCamera">
            {{ photoButtonText }}
          </FotoButton>
          <BaseButton v-if="hasImage && hasEditTool" isSecondary @click="openCroppingDialog()" :disabled="disabled || loading">
            Bearbeiten
          </BaseButton>
          <BaseButton v-if="hasImage && hasDeleteButton" isSecondary @click="$emit('delete')" :disabled="disabled || loading">
            Löschen
          </BaseButton>
        </BaseButtonsContainer>
        <div v-else class="buttons_container image-upload__img image-upload__button--img" :style="imageStyle">
          <BaseFileSelect v-if="hasUploadButton" class="image-upload__button" isPrimary  @files="upload($event)" accept="image/png, image/jpeg, .pdf" :disabled="disabled">
            {{ uploadButtonText }}
          </BaseFileSelect>
          <FotoButton v-if="cameraAvailable && hasPhotoButton" class="image-upload__button" buttonClass="foto-button" isSecondary  
            @file="upload([$event])" :disabled="disabled" :edgeCorrection="edgeCorrection" :camera="photoCamera">
            {{ photoButtonText }}
          </FotoButton>
        </div>
      </div>
    </DragnDropArea>
  </div>
</template>

<script>
import BaseButtonsContainer from '@/components/core/BaseButtonsContainer.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import FotoButton from '@/components/fileUpload/FotoButton.vue';
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import ImageCroppingDialog from '@/components/fileUpload/ImageCroppingDialog.vue';
import {rotateImage90Clockwise} from '@/helpers/image-operation-helper';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from "@/components/core/base-context-menu/ContextMenuItem.vue";
import { mapGetters } from 'vuex';
import BRIDGE_TYPES from '@/store/bridge/types';
import CORE_TYPES from '@/store/core/types';

const FIT_VALIDS = ['cover', 'cover-vertically', 'cover-horizontally', 'contain', 'fill'];
const DEFAULT_HEIGHT = '200px';
const DEFAULT_FIT = 'cover';


export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    title: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    hoverText: {
      type: String,
      default: 'Datei hier ablegen',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({
        height: DEFAULT_HEIGHT,
        fit: DEFAULT_FIT,
      }),
      validator(value) {
        return FIT_VALIDS.indexOf(value?.fit) >= 0;
      },
    },
    photoButtonText: {
      type: String,
      default: 'Fotografieren',
    },
    uploadButtonText: {
      type: String,
      default: 'Hochladen',
    },
    edgeCorrection: {
      type: Boolean,
      default: false,
    },
    photoCamera: {
      type: String,
    },
    photoHighQuality: {
      type: Boolean,
      default: false,
    },
    hasEditTool: {
      type: Boolean,
      default: true,
    },
    hasPhotoButton: {
      type: Boolean,
      default: true,
    },
    hasUploadButton: {
      type: Boolean,
      default: true,
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },    
  },
  data() {
    return {
      cameraAvailable: false,
      editImage: null,
    };
  },
  computed: {
    ...mapGetters({
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),    
    hasImage() {
      return !!this.image;
    },
    imageHeight() {
      return this.config?.height || DEFAULT_HEIGHT;
    },
    imageFit() {
      return this.config?.fit || DEFAULT_FIT;
    },
    imageStyle() {
      return {
        height: this.imageHeight,
      };
    },
  },
  methods: {
    upload(files) {
      this.editImage = null;
      this.$emit('file', files?.length ? files[0] : null);
    },
    onChange(file) {
      this.editImage = null;
      this.$emit('change', file);
    },
    openCroppingDialog() {
      this.editImage = this.image;
    },
    rotateRight() {
      const canvas = rotateImage90Clockwise(this.$refs.image);
      canvas.toBlob(blob => {
        this.onChange(blob);
      })
    },
    updateCameraAvailable(devices) {
      this.cameraAvailable = devices.some(device => device.kind == 'videoinput');
    },
    handleComponentAction({ key, value, }) {
      this.$emit('action', { key, value, });
      this.$emit(`action-${key}`, value);
    },    
  },
  mounted() {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then(this.updateCameraAvailable);
    }
  },
  components: {
    BaseButtonsContainer,
    DragnDropArea,
    FotoButton,
    BaseFileSelect,
    BaseButton,
    AnimatedSpinner,
    ImageCroppingDialog,
    BaseContextMenu,
    ContextMenuItem,
  },
}
</script>

<style scoped>
.image-upload__container {
  display: flex;
  flex-direction: column;
  text-align: start;
}
.image-upload__img {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.box__container .image-upload__img {
  box-shadow: none;
  border-radius: 0;
}
.image-upload__button--img {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.image-upload__button {
  margin: 0;
}
.no-image .image-upload__button {
  flex: 1 1 50%;
}
.has-image .image-upload__button {
  display: inline-block;
  margin-right: 8px;
}
.has-image .image-upload__button:last-child {
  margin-right: 0;
}
.image-upload__img >>> .foto-button {
  width: 100%;
  height: 100%;
  margin: 0;
}
.buttons_container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

/* begin */

.drop_down__area {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: nowrap;
  align-items: baseline;
  position: relative;
}

.drop_down__area > div {
  flex: 1 1 auto;
  /* background-color: bisque; */
  overflow: hidden;
}

.title__area {
  text-align: left;
}

.actions__area {
  display:flex;
  justify-content: flex-end;
  align-items: center;
}

/* end */

.drop_down__buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: baseline;
}
</style>
