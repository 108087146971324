import AUFTRAEGE_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { isNumber } from '@/helpers/number-formatter.js';

const config = {
  defaultSpinner: true
};

export default {
  async [AUFTRAEGE_TYPES.ACTIONS.GET_AUFTRAEGE]({ commit, rootState }, payload) {

    await axios.post(`${rootState.core.apiAddress}/maklerauftraege/getMaklerauftraegeList`, payload, config).then(response => {
      if (response.status === 200 && response.data) {
        if (typeof response.data.askPIN == 'number') {
          commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
            key: 'TABELLE_MAKLER_ANTRAEGE', 
            value: response.data.askPIN,
            action: AUFTRAEGE_TYPES.ACTIONS.GET_AUFTRAEGE,
            payload: JSON.stringify(payload)
          })
        }
        commit(AUFTRAEGE_TYPES.MUTATIONS.GET_AUFTRAEGE_SUCCESS, response.data)
      }
    });
  },

  [AUFTRAEGE_TYPES.ACTIONS.REMOVE_AUFTRAG]({ commit, rootState }, payload) {

    axios.delete(`${rootState.core.apiAddress}/maklerauftraege/removeAuftrag?id=${payload.id}`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(AUFTRAEGE_TYPES.MUTATIONS.REMOVE_AUFTRAG_SUCCESS, response.data)
      }
    });
  },
  [AUFTRAEGE_TYPES.ACTIONS.UPDATE_AUFTRAG]({ commit, rootState }, payload) {

    axios.post(`${rootState.core.apiAddress}/maklerauftraege/updateData?id=${payload.id}`, payload, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(AUFTRAEGE_TYPES.MUTATIONS.UPDATE_AUFTRAG_SUCCESS, response.data)
      }
    });
  },
  [AUFTRAEGE_TYPES.ACTIONS.AUFTRAG_STORNIEREN]({ commit, rootState }, payload) {

    return new Promise((resolve, reject) => {
      axios.put(`${rootState.core.apiAddress}/maklerauftraege/auftragStornieren?id=${payload.id}`, payload, config).then(response => {
        if (response.status === 200 && response.data) {
          resolve(response?.data);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [AUFTRAEGE_TYPES.ACTIONS.AUFTRAG_BESTAETIGUNG]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${rootState.core.apiAddress}/maklerauftraege/auftragBestaetigung?id=${payload.id}`, payload, config).then(response => {
        if (response.status === 200 && response.data) {
          resolve(response?.data);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  },
 
  async [AUFTRAEGE_TYPES.ACTIONS.GET_PDF_LIST]({ rootState }, payload) {

    const response = await axios.post(`${rootState.core.apiAddress}/maklerauftraege/getPdfs?id=${payload?.id}`, payload, config);
    return response?.data;
  },

  async [AUFTRAEGE_TYPES.ACTIONS.GET_VERTRAEGE]({ rootState }, payload) {

    if (payload?.length === 7 && isNumber(payload)) {
      const response = await axios.get(`${rootState.core.apiAddress}/maklerauftraege/getVertraege?kundennr=${payload}`, config);
      return response?.data;
    }
  },

  async [AUFTRAEGE_TYPES.ACTIONS.SAVE_NEW_AUFTRAG]({ rootState }, payload) {
    const config = {
      defaultSpinner: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("vertraege", payload.vertraege);
    const response = await axios.post(`${rootState.core.apiAddress}/maklerauftraege/saveNewAuftrag`, formData, config);
    return response?.data;
  },
 
  [AUFTRAEGE_TYPES.ACTIONS.RESET_VERSAND]({ rootState }, payload) {

    const response = axios.post(`${rootState.core.apiAddress}/maklerauftraege/resetVersand`, payload, config);
    return response?.data;
  },

  [AUFTRAEGE_TYPES.ACTIONS.GET_AENDERUNGS_LOG]({ commit, rootState }, payload) {

    const params = {};

    if (payload?.searchkeyId) {
      params.searchkeyId = payload?.searchkeyId;
    }

    params.filter = payload?.filter || "";
    params.datum = payload?.datum || "";
    params.typ = payload?.typ || "";
    params.versVetragId = payload?.versVetragId || "";
    params.mitSchadensmeldung = payload?.mitSchadensmeldung || false;

    return new Promise((resolve, reject) => {
      axios.get(`${rootState.core.apiAddress}/maklerauftraege/getAenderungslog`, {params, config}).then(response => {
        if (response.status === 200 && response.data) {
          commit(AUFTRAEGE_TYPES.MUTATIONS.GET_AENDERUNGS_LOG_SUCCESS, response.data);
          resolve(response?.data);
        }
      }).catch((error) => {
        reject(error);
      });

    });
  },
  [AUFTRAEGE_TYPES.ACTIONS.GET_EMAILS_SENT]({ commit, rootState }, payload) {
    if(!payload?.id) {
      return;
    }

    axios.get(`${rootState.core.apiAddress}/maklerauftraege/getEMailsSent`, {params: {id: payload.id}}, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(AUFTRAEGE_TYPES.MUTATIONS.GET_EMAILS_SENT_SUCCESS, response.data);
      }
    });
  },
  
  [AUFTRAEGE_TYPES.ACTIONS.IST_BEARBEITER]({ commit, rootState }) {
    axios.get(`${rootState.core.apiAddress}/maklerauftraege/istBearbeiter`, config).then(response => {
      commit(AUFTRAEGE_TYPES.MUTATIONS.IST_BEARBEITER_SUCCESS, response.data);
    })
  },
  
}