<template>
    <div>
        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.parent.label"
            :subtitle="$appNavigation.currentMenu.label"
            :defaultMenu="$appNavigation.currentTabMenu"
            :actions="actions"
            @action-REMOVE="$refs.confirmDeleteOvertaken.open()"
            @action-RESET="doConfirm('zurücksetzen')"
        />

        <div class="box__container">
            <div  class="box__title">Depotlimits</div>
            <div>Übersicht über alle Limits, die auf die Gesamtanlagensumme (Depot) Ihrer Kunden gesetzt wurden.</div>
            <div class="layout__negative-margin--8 antrag-action-button__container">
                <InputToggleSwitch v-model="includeStruktur" label="Limits der Untermakler anzeigen" inLineLabel suppressValidationMessage @input="reload()"/>
            </div>
        </div>
        <div class="box__container">
            <div v-if="!loading && rows && rows.length">
                <Table
                    tableId="17af0092-8ada-4c3a-85b8-5a9206c94dc5"
                    :title="TABLE_TITLE"
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    hidePagination
                    rowId="id"
                    :mobileConfig="{title: 'kunde', headers: ['depotWert', 'abweichungLow', 'abweichungHigh']}"
                    @action-edit="editLimit"
                    @action-delete="selectedLimit = $event; $refs.confirmDelete.open()"
                    @click-kunde="openCustomerNewTab"
                >
                </Table>
            </div>
            <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
            <NoData v-else :title="TABLE_TITLE" />
        </div>
        <BaseModal ref="confirmDelete" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onCancelButton="selectedLimit = null" 
            @onCloseButton="selectedLimit = null" 
            @onConfirmButton="removeLimit()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Löschen
            </template>
            <div>Wollen Sie wirklich das Depotlimit löschen?</div>
        </BaseModal>
        
        <BaseModal ref="confirmDeleteOvertaken" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onConfirmButton="removeOvertaken()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Löschen
            </template>
            <div>Wollen Sie wirklich alle überschrittene Limits löschen?</div>
        </BaseModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import Table from "@/components/table2/Table.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { PhCheck, PhWarning } from "phosphor-vue";
import mixin from '@/mixins/limit/limit-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import {TextColumn, NumberColumn, PercentageColumn, DateColumn, IconColumn, SimpleAction, ActionColumn, Icon} from "@/components/table2/table_util.js";
import {wpListeMixin} from '@/mixins/wp-suche.js';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const TABLE_TITLE = 'Depotlimits';

export default {
    mixins: [mixin, wpListeMixin],
    components: {
        Table,
        InputToggleSwitch,
        PhWarning,
        BaseModal,
        PageHeaderTitleNavigation,
        GhostLoading,
        NoData,
    },
    data() {
        return {
            TABLE_TITLE,
            loading: false,
            includeStruktur: '0',
            removeAction: null,
            selectedLimit: null,
            columnLabelAusgangskurs: 'Ausgangskurs',
        }
    },
    mounted() {
        this.reload();
    },
    computed: {
        ...mapGetters({
            depotlimits: LIMIT_TYPES.GETTERS.DEPOTLIMIT_LIST,
        }),
        headers() {
            const headers = { 
                lockedLeft: [
                    IconColumn("status", "Status", 80).makeSortable(cell => cell?.length && cell[0].title),
                    TextColumn("kundennr", "Kundennr").makeAlwaysVisible(),
                    TextColumn("kunde", "Kunde").makeLink(),
                ],
                center: [
                    NumberColumn("asgangswert", "Ausgangswert", 2),
                    NumberColumn("depotWert", "Aktueller Depotwert", 2),
                    PercentageColumn("abweichungLow", "Unteres Limit", 4),
                    PercentageColumn("abweichungHigh", "Oberes Limit", 4),
                    TextColumn("hasDynamic", "Dynamik"),
                    NumberColumn("dynamik", "Dynamik Ausgangswert", 2),
                    DateColumn("dateUpdated", "Letzte Änderung"),
                    DateColumn("dateOutOfLimit", "Benachrichtigung"),
                ],
                lockedRight: [
                    ActionColumn("actions", ""),
                ],
            };
            if ('1' === this.includeStruktur) {
                // insert after status
                headers.lockedLeft.splice(1, 0, TextColumn("maklernr", "Maklernr").makeAlwaysVisible());
            }
            return headers;
        },
        rows() {
            const result = !this.depotlimits ? [] : this.depotlimits.map((limit, index) => {
                const actions = [SimpleAction("edit", 'PhPencil', "Limit bearbeiten"), SimpleAction("delete", 'PhTrash', "Limit löschen")];
                return {
                    ...limit,
                    status: [limit.statusStr === 'ok'
                        ? Icon(PhCheck, "Ok", 20, 'bold', 'color-success')
                        : Icon(PhWarning, "Warnung", 20, 'bold', 'color-danger')
                    ],
                    hasDynamic: limit.isDynamisch === '1' ? 'Ja' : 'Nein',
                    id: index,
                    actions: actions,
                };
            });
            return result;
        },
        actions() {
            if (this.isCustomerOrigin) {
                return [];
            }
            return [
                PageHeaderSimpleAction('REMOVE', 'Überschrittene Limits löschen'),
                PageHeaderSimpleAction('RESET', 'Überschrittene Limits zurücksetzen'),
            ]
        }
    },
    methods: {
        editLimit(event) {
            if (event?.kundennr) {
                this.$addBreadcrumb({
                    label: `zurück zu den Limits Depots`,
                    fullPath: this.$route.fullPath,
                    breadcrumb: 'Depotlimit',
                });
                this.$router.push(`${this.mainPath}/depotlimit?kundennr=${event.kundennr}`)
            }
        },
        async removeLimit() {
            if (this.selectedLimit?.kundennr) {
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_DEPOTLIMIT, {kundennr: this.selectedLimit.kundennr});
                this.reload();
            }
        },
        async reload() {
            try {
                this.loading = true;
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_DEPOTLIMIT_LIST, {includeStruktur: this.includeStruktur});
            } finally {
                this.loading = false;
            }
        },
        async removeOvertaken() {
            await this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_OVERTAKEN_DEPOTLIMITS, {includeStruktur: this.includeStruktur});
            this.reload();
        },
        async doConfirm(exec) {
            await this.doConfirmAction(exec, 'depotlimitList');
        },
    },

}
</script>

<style>

</style>