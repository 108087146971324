export const ALL_DEPOTS = 'ALLE_DEPOTS_ANZEIGEN';
export const NICHT_ZUGEORDNET_DEPOT = 'Nicht zugeordnet';
export const WERTPAPIER_VERSICHERUNG_DEPOT = 'WERTPAPIER_VERSICHERUNG';
export const VORSORGE_DEPOT = 'Vorsorge';

export const MAP_CHART_TYPE_TO_TITLE = {
  'ChartTopholdings': 'Top Holdings',
  'ChartDistributionIndustry': 'Branchen',
  'ChartDistributionFunds': 'Wertpapierverteilung',
  'ChartDistributionFundsType': 'Wertpapiergruppe',
  'ChartDistributionStates': 'Länder',
  'ChartDistributionCategoryFunds': 'Wertpapierkategorie',
  'ChartDistributionSRI': 'Aufteilung SRI',
  'ChartDistributionCurrencies': 'Wertpapierwährung',
  'ChartdistributionEarnings': 'Ertragsverwendung',
  'ChartDistributionDeposetary': 'Lagerstellen',
  'TopHoldingsCardComponent': 'Top Holdings',
  'DistributionOfIndustryCardComponent': 'Branchen',
  'DistributionOfStatesCardComponent': 'Länder',
  'CategoriesOfFundsCardComponent': 'Wertpapierkategorie',
  'DistributionOfCurrenciesCardComponent': 'Wertpapierwährung',
  'DistributionOfFundsTypeCardComponent': 'Wertpapiergruppe',
  'DistributionOfEarningsCardComponent': 'Ertragsverwendung',
  'DistributionOfDepositoryCardComponent': 'Lagerstellen',
  'DistributionOfSriCardComponent': 'Aufteilung SRI',
  'DistributionOfFundsCardComponent': 'Ertragsverwendung',
  'PaiChart': 'PAI | Kategorie C | Vermeidung schädlicher Nachhaltigkeitsauswirkung',
  'SFDRChart': 'SFDR | Kategorie B | hohe Nachhaltigkeitsauswirkung',
  'TaxonomieChart': 'Taxonomie | Kategorie A | höchste Nachhaltigkeitsauswirkung',
};
