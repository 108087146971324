var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Verknüpfte virtuelle Depots:",
          id: "virtuelleDepotsMeineZiele",
          actions: _vm.actions,
          noPrimaryAction: true,
          showBackButton: false
        },
        on: {
          "action-DEPOTS": _vm.getDepots,
          "action-REMOVE_ALL_DEPOTS": _vm.removeAllDepots
        }
      }),
      _c("Table", {
        attrs: {
          headers: _vm.depotHeaders,
          rows: _vm.depotRows,
          rowId: "id",
          hidePagination: "",
          mobileConfig: {},
          noDataContent: "Keine Virtuelle Depots sind verknüpft."
        },
        on: { "action-REMOVE": _vm.removeDepot }
      }),
      _c(
        "BaseModal",
        {
          ref: "unterdepotsModal",
          attrs: {
            modalTitle: "Virtuelles Unterdepot zum Ziel hinzufügen",
            labelButtonConfirm: "Übernehmen",
            size: "lg",
            confirmDisabled: !_vm.selectedRows.length
          },
          on: {
            onConfirmButton: _vm.addDepots,
            onCloseButton: function($event) {
              _vm.selectedRows = []
            },
            onCancelButton: function($event) {
              _vm.selectedRows = []
            }
          }
        },
        [
          _c("Table", {
            attrs: {
              title: "Virtuelle Unterdepots",
              headers: _vm.modalHeaders,
              rows: _vm.modalRows,
              rowId: "id",
              hidePagination: "",
              mobileConfig: { selectionActive: true },
              selected: _vm.selectedRows
            },
            on: { selected: _vm.setSelected }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }