var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : !_vm.hasData
        ? _c("NoData")
        : _c("Table", {
            attrs: { headers: _vm.headers, rows: _vm.rows },
            on: {
              "action-EDIT": function($event) {
                return _vm.$emit("edit", $event)
              },
              "action-DELETE": function($event) {
                return _vm.$emit("remove", $event)
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }