import Vue from 'vue'
import BROKERDATA_TYPES from '@/store/brokerData/types'
import CORE_TYPES from '@/store/core/types'
import { mapGetters } from 'vuex'
import { isObject } from '@/helpers/commonfunctions.js'

import {
  MAP_VERMITTLER_ROUTE_TO_DEFAULT_SUBSTEP,
  ANSPRECHPARTNER_ROUTE_TO_DEFAULT_SUBSTEP
} from './vermittler-daten-steps-utils';

const persoenlicheDatenMixin = {
  computed: {
    ...mapGetters({
      personId: BROKERDATA_TYPES.GETTERS.GET_ANSPRECHPARTNER_BY_ID,
      broker: BROKERDATA_TYPES.GETTERS.GET_BROKER_BY_ID,
      brokerData: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA,
      vermittlerDataEdited: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_EDITED,
      vermittlerDataConfig: BROKERDATA_TYPES.GETTERS.CUSTOMER_BROKER_CONFIG,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    isKontoEditable(){
      return this.brokerData?.isKontoEditable;
    },

    isPersonData() {
      return this.$route.path.search(/\/person-data\//gi) >= 0;
    },
    step() {
      return this.$route.path;
    },
    substep() {
      if(this.isPersonData) {
        const path = this.$route?.matched[this.$route.matched.length - 1]?.path;
        return this.$route?.query?.substep || MAP_VERMITTLER_ROUTE_TO_DEFAULT_SUBSTEP[path] || '';
      } else if(this.$route.fullPath.startsWith('/intern/ansprechpartner')){
        return this.$route?.query?.substep ||  ANSPRECHPARTNER_ROUTE_TO_DEFAULT_SUBSTEP[this.$route?.path] || '';
      }else{
        return this.$route?.query?.substep || MAP_VERMITTLER_ROUTE_TO_DEFAULT_SUBSTEP[this.$route?.path] || '';
      }
    },
  },
  methods: {
    initialState() { // Required to be implemented
      return {};
    },
  
    updateGroupIntoData(groupName) {
      Object.keys(this[groupName]).forEach(fieldName => {
    
        if (this.brokerData && this.brokerData[groupName] && fieldName in this.brokerData[groupName]) {
          this[groupName][fieldName] = this.brokerData[groupName][fieldName]
        }
      })
    },

    // get the information from brokerdata and add it into $data (field)
    updateFieldIntoData(fieldName) {
      if(isObject(this[fieldName])) {
        console.error('[CHECK]: updateFieldIntoData cannot be used in object fields.')
        return;
      }

      this[fieldName] = fieldName in this.vermittlerDataEdited ? this.vermittlerDataEdited[fieldName] : this.brokerData[fieldName];
    },

    // if something in this group has changed, return all fields
    getChangedFormDataGroup(groupName) {
      const group = Object.keys(this[groupName])
        .reduce((acu, cur) => {
          acu[cur] = this[groupName][cur]
          return acu;
        }, {})

      return this.isChanged(group, groupName) ? group : null
    },

    // check if a group was changed
    isChanged(group, groupName) {
      return Object.keys(group)
        .filter(fieldName => {
          if (!this.brokerData[groupName]) {
            return group[fieldName]
          }
          return this.brokerData[groupName][fieldName] !== group[fieldName]
        })
        .length > 0
    },

    // send the changed data to vermittlerDataEdited. This will be saved later
    addBrokerDataEdited(group, data) {
      const groupData = arguments.length === 2 ? data : this.getChangedFormDataGroup(group)

      if (groupData !== null || groupData !== undefined) {
        const payload = {
          [group]: isObject(groupData) ? { ...this.brokerData[group], ...groupData, } : groupData,
        }

        this.$store.commit(BROKERDATA_TYPES.MUTATIONS.ADD_BROKER_DATA_EDITED, payload)

        if(this?.validation?.brokerData?.emailStandard?.valid) {
          this.formErrors = [];
        } else if (this.addFormErrors) {
          this.addFormErrors();
        }
      }
    },
    
    // send the changed data to vermittlerDataEdited. This will be saved later (specific for ComboBox)
    addBrokerDataEditedCombo(group, field, value) {
      if(!group in this) return;

      this[group][field] = value;
      this.addBrokerDataEdited(group);
    },

    // get the list from brokerData and add it into $data
    updateListIntoData(groupName) {
      this[groupName] = [...this.brokerData[groupName]];
    },

    navigateToPath(path) {
      if(this.$route.fullPath !== path) {
        this.saveChanges().then(result => this.$router.push(path))
      }
    },

    navigateToSubstepPath(event) {
      this.navigateToPath(`${event?.stepKey}?substep=${event?.substepKey}`);
    },

    async saveChanges() { 
      let promise = Promise.resolve();
      if (this?.isFormInvalid && this?.isFormInvalid()) {
        return promise;
      }

      return await promise.then(() => new Promise((resolve) => resolve(this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.SAVE_BROKER_DATA))));
    },
    assignBrokerData(brokerData) {
      Vue.set(this, 'brokerData', {...brokerData})
    }
  },
 
};

export default persoenlicheDatenMixin;
