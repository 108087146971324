<template>
  <div>
    <PageHeaderTitleNavigation 
      v-if="isNewAntrag || isFondsfinder"
      title="eFonds24" 
      subtitle="eZeichnung" 
      :actions="headerActions" 
      @action-UPLOAD="doUpload()" 
    />
    <AntragHeaderTitleNavigation 
      v-else-if="!isNewAntrag"
      title="eFonds24"
      subtitle="eZeichnung"
      :antragId="id" 
      :antrag="antrag" 
      :antragData="data" 
      :disabled="!data || (!data.antragsdatenId && !data.PARAMETER_PROTOKOLL_ID)"
      :currentStepAdditionalButtons="buttons"
    />

    <div v-if="!isFondsfinder">
      <div class="row my-0">
        <div class="col-12">
          <AntragSidetext 
            :antragId="id"
            :antragData="data"
            :warnings="warnings">
          </AntragSidetext>
        </div>
      </div>

      <div class="box__container p-4" v-if="hasAccessRights">
        <EntitySelector v-if="isNewAntrag"
          v-model="personId"
          label="Zeichner"
          isComponentHalfSize
          isPersonPicker
          :values="persons"
          hideNewButton
          @input="$emit('input', $event)"
          @change="updateStoreData({ 'personId': $event})"
          @edit="editPerson(personId)"
          :disabled="!isNewAntrag"
        />
        <div v-else>Zeichner: {{data && data.ZeichnerVorname}} {{data && data.ZeichnerNachname}}</div>
        <div class="color-danger" v-if="!guid">
          Die Person ist bei der eFonds-Plattform noch nicht angelegt. Bitte eFonds-Stammdaten anlegen.
        </div>
        <div class="color-danger" v-else-if="stammdatenChanged">
          Persönliche Daten wurden geändert. Bitte eFonds-Stammdaten anpassen.
        </div>
        <div>
          <BaseButton @click="openStammdaten()" isSecondary>
            <span>eFonds-Stammdaten anzeigen</span>
          </BaseButton>
        </div>
      </div>

      <div class="box__container p-4" v-if="hasAccessRights">
        <WertpapierAuswahlUnique 
          :data="data" 
          :antragId="id" 
          :config="config" 
          :hasFactsheet="true"
          id="KAUF" 
          label="Wertpapierauswahl" 
          @openFondsfinder="isFondsfinder = true"
          @removePosition="removePosition($event)" 
          :disabled="!isNewAntrag"
        />
        <div>FondsInfos: <a @click="openInfoInEfonds" v-if="isin && isin.length">Wertpapier im {{efondsName}} System anzeigen</a></div>
        <div>Einreichung: {{einreichungStr}}</div>
        <div v-if="!isNewAntrag">
          Status:
          <span v-if="data && data.checkOk"><PhCheck :size="22" class="color-success mr-1" /></span>
          <span v-else><PhWarning :size="22" class="color-danger mr-1" /></span>
          <span v-if="data && data.editButton"><a @click="editEzeichnung" v-fc-tooltip="statusTooltip">{{data && data.status}}</a></span>
          <span v-else>{{data && data.status}}</span>
        </div>
        <!-- <div class="mb-3"><a @click="editEzeichnung" v-if="data && data.editButton">Zeichnung im {{efondsName }} System bearbeiten</a></div> -->
        <div class="d-flex my-3" v-if="isNewAntrag">  
          Minimum: {{minimalStr}}
        </div>
        <InputField label="Zeichnungssumme" type="currency" v-model="betrag" validateUntouched :foreign_currency="getCurrency || 'EUR'" isComponentHalfSize 
          @change="updateStoreData({'betrag': $event})" :disabled="!isNewAntrag || !isin" />

        <div v-if="!isNewAntrag">
          <div class="font-bold">Vorbelegung und Ort der Unterschrift</div>
          <InputField label="Ort" v-model="ort" isComponentHalfSize 
            :disabled="data && data.signed"
          />
          <DatePickerField
            dateFormat="DD.MM.YYYY"
            v-model="datum"
            label="Datum"
            isComponentHalfSize 
            isValueAsString
            :disabled="data && data.signed"
          />
        </div>
        <BaseModal ref="hatFehler" labelButtonConfirm="Ok" :showCancelButton="false" :showCloseButton="false">
          <template v-slot:modalTitle>
            <i class="las la-exclamation-triangle mr-2" style="color: orangered"></i>
            Fehler
          </template>
          <div>{{errorStr}}</div>
        </BaseModal>
        
        <BaseModal ref="stammdatenChangedModal" labelButtonConfirm="eZeichnung starten" labelButtonCancel="Abbrechen"
          @onConfirmButton="openEFondsZeichnung()">
          <template v-slot:modalTitle>
            <PhInfo size="24" />
            Information
          </template>
          <div>Die Stammdaten zwischen eFonds und smartMSC weichen ab.</div>
        </BaseModal>
      </div>
      <div v-else-if="isLoadingAntrag" class="box__container">
        <GhostLoading type="input" class="forms__input--half-size" />
        <GhostLoading type="title" class="forms__input--half-size" />
      </div>
      <div v-else class="box__container">Fehlende Berechtigungen für diesen Bereich</div>

      <div class="box__container p-4" v-if="hasAccessRights && !isNewAntrag">
        <div class="box__title">Formulardaten</div>
        <div v-if="rows && rows.length">
          <Table
              :headers="headers"
              :rows="rows"
              :rowsPerPage="100"
              hidePagination
              rowId="ezeichnung"
          >
          </Table>
            </div>
            <div v-else>Keine Daten</div>
      </div>
    </div>

    <div v-else>
      <FondsFinderSuchMaske :lagerstelleId="lagerstelle" :isBeteiligung="true"/>

      <FondsFinderPositionsList 
        ref="fondsfinderResult"
        :lagId="lagerstelle"
        :goBackEnabled="true"
        :singleSelectionProp="true"
        :hasDefaultButton="true"
        @goBack="isFondsfinder = false"
        @getSelectedOutcome="setIsin($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ANTRAG_TYPES from '@/store/antrag/types';
import ANTRAG_EFONDS_TYPES from '@/store/antragEfonds/types';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import { formatNumber } from '@/helpers/number-formatter.js'
import WertpapierAuswahlUnique from'@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlUnique.vue';
import InputField from "@/components/core/forms/InputField.vue";
import validator from "@/mixins/validator";
import { required, minValue } from '@/mixins/validator/rules';
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import AntragHeaderTitleNavigation from '@/components/antrag/AntragHeaderTitleNavigation.vue';
import AntragSidetext from '@/components/antrag/AntragSidetext.vue'; 
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import { PhInfo } from 'phosphor-vue'
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import {TextColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { PhWarning, PhCheck } from "phosphor-vue";
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from "@/helpers/log-message-helper";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import { PageHeaderLoadingAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [validator, antragMixin],
  validators: {
  },
  components: {
    WertpapierAuswahlUnique,
    InputField,
    BaseButton,
    BaseModal,
    OptionMenu,
    PageHeaderTitleNavigation,
    AntragHeaderTitleNavigation,
    AntragSidetext,
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    EntitySelector,
    PhInfo,
    DatePickerField,
    Table,
    PhCheck,
    PhWarning,
    GhostLoading,
  },
  data() {
    return {
      amount: '',
      currency: '',
      observedComponent: '',
      ort: '',
      datum: '',
      betrag: '',
      loading: false,
      config: {isEmbedded: true, addButtonText: 'Wertpapier hinzufügen'},
      antragsName: 'eZeichnung',
      lagerstelle: 'efonds24.de',
      isFondsfinder: false,
      isInit: true,
      errorStr: null,
      einreichungStr: '',
      headers: { lockedLeft: [
                    TextColumn("id", "FormularfeldID"),
                    TextColumn("value", "Wert")],
                center: [],
                lockedRight: []
      },
    }
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
    headerActions() {
      if(this.isFondsfinder) return [];

      return [
        PageHeaderLoadingAction('UPLOAD', 'eZeichnung starten', () => this.loading).withDisabled(() => !this.isValid),
      ];
    },
    isLoadingAntrag() {
      const { id, antraegeData = {}, } = this;
      return !(id in antraegeData);
    },
    data() {
      return this.antraegeData && this.antraegeData[this.id];
    },
    antrag() {
      const antrag = this.antraege && this.antraege[this.id];
      // set manually hideESignatur on the base of einreichungStr
      const config = antrag?.steps?.length && antrag.steps[0].config;
      if (config && !config.hideESignatur && this.einreichungStr && !this.einreichungStr.includes('digital signiert')) {
        antrag.steps[0].config.hideESignatur = true;
      }
      return antrag;
    },
    hasAccessRights() {
      return this.data?.accessRights;
    },
    persons() {
      return this.antrag?.comboboxSelections?.persons || [];
    },
    warnings() {
      return this.antrag?.warnings?.antragWarnings || [];
    },
    minimalStr() {
      return (formatNumber(this.amount, 0) || '') + ' ' + (this.currency || '');
    },
    getCurrency() {
      return this.currency || '';
    },
    isin() {
      return this.data?.KAUF || '';
    }, 
    isValid() {
      return this.isin && this.betrag && this.betrag >= this.amount && this.personId && this.guid;
    },
    id() {
      return calculateAntragID({lagerstelle: this.lagerstelle, antragsName: this.antragsName });
    },
    personId() {
      return this.data?.personId || '';
    },
    betragValidators() {
      return { betrag: [minValue(this.amount || 0, `Der Betrag muss bei Einmalanlagen mindestens ${this.minimalStr} betragen.`)] };
    },
    guid() {
      // in this case status means guid 
      return this.personId && this.persons?.find(p => p.value === this.personId)?.guid;
    },
    stammdatenChanged() {
      return this.guid && this.personId && this.persons?.find(p => p.value === this.personId)?.changed;
    },
    isNewAntrag() {
      return !this.$route.query.antragsdatenId && !this.$route.query.antragsnrIntern;
    },
    rows() {
      return this.data?.rows || [];
    },
    statusTooltip() {
      return `Zeichnung im ${this.efondsName} System bearbeiten`;
    },
    buttons() {
      return !this.isNewAntrag && [
        { label: 'Aktualisieren', clickFn: () => this.reload()}, 
        { label: `Im ${this.efondsName} System bearbeiten`, clickFn: () => this.editEzeichnung()},
        { label: 'ExAnte Kosteninformationen', disabled: this.data?.disableKostenInfo, clickFn: () => this.getKostenInfo()}];
    },
    efondsName() {
      return this.data?.efondsName || 'eFonds AG';
    }
  },
  mounted() {
    if (this.$route.query?.backAction) {
      this.updatePersons();
    } 
    if (!this.isNewAntrag || !this.$route.query?.backAction) {
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.RESET_STATE);
      this.reload();
    }
    this.isInit = false;
    if (this.isin && !this.amount) {
      this.getMinimalBetragInfo();
      this.getEinreichungStr();
    }
    this.updateValues(true);
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === '/beratung/antrag-beratungsmappe-selector') {
      next()
    } else if (to.query?.bmOptChosen || to.query?.beratungsMappeId || to.query?.avoidAntragLoading || (to.query?.hideBeratungsmappepopup === "true")) {
      next()
    } else {
      next({ path: '/beratung/antrag-beratungsmappe-selector', query: { formPath: to.fullPath, lagerstelleLabel: to.query?.lagerstelleLabel } })
    }
  },
  watch: {
    data(newValue) {
       if (!this.isInit) {
        if (this.data) {
          this.updateValues();
        }
        if (this.isin) {
          this.getMinimalBetragInfo();
          this.getEinreichungStr();
        }
      }
      if (this.data?.errorStr) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(this.data.errorStr, 'danger'));
        this.updateStoreData({ 'errorStr': null});
      }
    },
    amount(newValue) {
      if (this.validation) {
        this.validation['betrag'] = null;
      }
      const validators = this.amount ?  { betrag: [minValue(this.amount || 0, `Der Betrag muss bei Einmalanlagen mindestens ${this.minimalStr} betragen.`)]}
        : { betrag: [required('Zeichnungssumme ist erforderlich!', {forceTouch: true})] }
      this.$configureValidators(validators);
    }
  },
  methods: {
    updateStoreData(data) {
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.UPDATE_ANTRAG_DATA, {
        id: this.id,
        data: data,
      });
    },
    getMinimalBetragInfo() {
      if (this.isin) {
        this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_MINIMAL_INVEST_INFO, {isin: this.isin}).then(data => {
          this.amount = data?.amount || '';
          this.currency = data?.currency || '';
        });
      }
    },
    getEinreichungStr() {
      if (this.isin) {
        this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_EINREICHUNG_STR, {isin: this.isin}).then(response => 
              this.einreichungStr = response || '');
      }
    },
    doUpload() {
      if (this.stammdatenChanged) {
        this.$refs.stammdatenChangedModal.open();
      } else {
        this.openEFondsZeichnung();
      }
    },
    async openEFondsZeichnung() {
      this.loading = true;
      await this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_LINK_NEW, 
        { betrag: formatNumber(this.betrag), isin: this.isin, personId: this.personId, beratungsMappeId: this.$route.query.beratungsMappeId || '' })
          .then(data => {

        if (data.errorStr) {
          this.errorStr = data.errorStr;
          this.$refs.hatFehler.open();
        } else if (data?.link) {
          // this.clearData();
          window.open(data.link, "_blank");
          this.$router.go(-1);
        }
      });
      this.loading = false;
    },
    removePosition() {
      this.clearData();
    },
    clearData() {
      this.updateStoreData({ KAUF: null, betrag: null });
      this.amount = '';
      this.currency = '';
      this.betrag = '';
    },
    setIsin(data) {
      if (data?.records?.length && data.records[0]?.isin) {
        this.$store.commit(FONDSFINDER_TYPES.MUTATIONS.ADD_FONDS_INFO, { positionInfo: data.records[0] })
        this.$store.dispatch(ANTRAG_TYPES.ACTIONS.UPDATE_ANTRAG_DATA, {
          id: this.id,
          data: { KAUF: data.records[0]?.isin, betrag: null }
        });
      }
    },
    openStammdaten()  {
      const query = Object.entries((this.$route.query || {})).map(([key, val]) => `${key}=${val}`).join('&');
      this.$addBreadcrumb({
        label: 'zurück zur eZeichnung', 
        fullPath: `/beratung/formulare/antrag/eZeichnung?${query}`,
        breadcrumb: 'eFonds-Stammdaten',
      });
      this.$router.push({ path: '/beratung/formulare/antrag/efonds24Stammdaten', query: Object.assign(this.$route.query, { personId: this.personId }) });
    },
    updatePersons() {
      this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_PERSONS, { id: this.id });
    },
    openInfoInEfonds() {
      if (this.isin) {
        this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_INFO_LINK, { isin: this.isin });
      }
    },
    editEzeichnung() {
      if (this.data?.antragsnrIntern || this.data?.antragsdatenId) {
        this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_LINK_EDIT, 
          {antragsnrIntern: this.data?.antragsnrIntern, antragsdatenId: this.data?.antragsdatenId})
      }
    },
    getKostenInfo() {
      this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_KOSTENINFO_PDF, {antragsnrIntern: this.data?.antragsnrIntern, antragsdatenId: this.data?.antragsdatenId});
    },
    async reload() {
      if (this.ort !== this.data?.ort || this.datum !== this.data?.datum) {
        await this.saveAntrag();
      }
      const data = { lagerstelle: this.lagerstelle, antragsName: this.antragsName, id: this.id };
      if (this.$route.query?.isDeepLink) {
        data.forceReload = '1';
      }
      this.loadAntrag(data);
    },
    updateValues(updateAnyway = false) {
      if (updateAnyway || !this.isNewAntrag) {
        this.ort = this.data?.ort;
        this.datum = this.data?.datum;
        this.betrag = this.data?.betrag;
      }
    },
    async saveAntrag() {
      if (!this.isNewAntrag) {
      await this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.SAVE_EZEICHNUNG, { 
        antragsnrIntern: this.data?.antragsnrIntern, 
        antragsdatenId: this.data?.antragsdatenId, 
        beratungsMappeId: this.$route.query.beratungsMappeId || '', 
        INPUT_UNTERSCHRIFTSVORBELEGUNG_ORT: this.ort, 
        INPUT_UNTERSCHRIFTSVORBELEGUNG_DATUM: this.datum 
      });
    }
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveAntrag();
    next();
  }
 
};
</script>

<style scoped>
  .antrag-action-button__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
</style>