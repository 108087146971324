import SPARTENTYP_EINSTELLUNG_TYPES from './types';

export default {
  [SPARTENTYP_EINSTELLUNG_TYPES.GETTERS.SPARTENTYP_LIST](state) {
    return state.spartentypList;
  },
  [SPARTENTYP_EINSTELLUNG_TYPES.GETTERS.SPARTEN_TYPEN](state) {
    return state.spartenTypen;
  },
  [SPARTENTYP_EINSTELLUNG_TYPES.GETTERS.SELECTED_SPARTENTYP](state) {
    return state.selectedSpartenTyp;
  },
  [SPARTENTYP_EINSTELLUNG_TYPES.GETTERS.CONFIG_DEFINITION_EDITED](state) {
    return state.configDefinitionEdited && { ...state.configDefinitionEdited, } || null;
  },
  [SPARTENTYP_EINSTELLUNG_TYPES.GETTERS.REMOVED_SPARTENTYP](state) {
    return state.removedSpartenTyp;
  },


  
  

  
}