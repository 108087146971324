var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "antrag-step__container" },
    [
      _c("Stepper3", {
        ref: "stepper",
        attrs: {
          stepType: _vm.stepType,
          visible: _vm.showStepper,
          stepperName: _vm.stepperName,
          selectedStepKey: _vm.selectedStep,
          selectedSubstepKey: _vm.selectedSubstep,
          stepperService: _vm.getStepperMediator,
          antragData: _vm.configData,
          disabled: _vm.disabled
        },
        on: {
          setStep: function($event) {
            return _vm.$emit("setStep", $event)
          },
          setSubstep: function($event) {
            return _vm.$emit("setSubstep", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }