var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.row.bezeichnung + " abschließen" }
      }),
      _vm.loading
        ? [
            _c(
              "GhostLoading",
              { attrs: { useBoxContainer: "" } },
              [_c("Block", { attrs: { height: "130" } })],
              1
            ),
            _c(
              "GhostLoading",
              { attrs: { useBoxContainer: "" } },
              [
                _c("Block", { attrs: { type: "title" } }),
                _c("Block", { attrs: { height: "250" } })
              ],
              1
            )
          ]
        : _vm.pages.length
        ? [
            _vm.hints
              ? _c("HinweiseUndFehler", {
                  staticClass: "dicht",
                  attrs: { hints: _vm.hints }
                })
              : _vm._e(),
            _vm._l(_vm.pages, function(page, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "BoxContainer",
                    { attrs: { title: page.label } },
                    [
                      _c("ul", {
                        staticClass: "ffb-consent-page-text",
                        domProps: { innerHTML: _vm._s(page.text) }
                      }),
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: "Bestätigung " + page.label,
                          inLineLabel: true,
                          value: page.confirm
                        },
                        on: {
                          input: function($event) {
                            return _vm.chgConfirm(index, $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            })
          ]
        : _c("div", { staticClass: "box__container" }, [_c("NoData")], 1),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "tool-bar" }, [
          _c("div", [
            _c(
              "a",
              {
                staticClass: "mr-2",
                class: { "link-disabled": !_vm.isConfirmed },
                attrs: {
                  href: _vm.row.videoidentlink1,
                  target: "videoidentlink1"
                }
              },
              [_vm._v("Videolegitimation Depotinhaber 1")]
            )
          ]),
          _c("div", [
            _vm.row.videoidentlink2
              ? _c(
                  "a",
                  {
                    staticClass: "mr-2",
                    class: { "link-disabled": !_vm.isConfirmed },
                    attrs: {
                      href: _vm.row.videoidentlink2,
                      target: "videoidentlink2"
                    }
                  },
                  [_vm._v("Videolegitimation Depotinhaber 2")]
                )
              : _vm._e()
          ]),
          _c("div", { class: { "tool-disabled": !_vm.isConfirmed } })
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }