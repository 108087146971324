var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showRecentMenuOpened
    ? _c("div", { staticClass: "recent-menu-opened__container" }, [
        _c("div", { staticClass: "recent-menu-opened--title" }, [
          _vm._v("Zuletzt geöffnet")
        ]),
        _c(
          "ul",
          { staticClass: "recent-menu-opened--list" },
          _vm._l(_vm.lastRecentMenuOpened, function(menu, index) {
            return _c(
              "li",
              {
                key: index,
                on: {
                  click: function($event) {
                    return _vm.navigateTo(menu.path)
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "menu-icon" },
                  [
                    _c(
                      menu.component,
                      _vm._b(
                        { tag: "component" },
                        "component",
                        menu.componentProps,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.sanitize(menu.label)) }
                })
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }