<template>
<div class="pagination-container">
  <span class="pagination-text text-centered d-flex align-items-center">Anzahl angezeigter Datensätze: {{totalPages == 1 ? total :itensOnPage}} von {{total || 0}}</span>
  <ul class="fc-pagination justify-content-end m-0" v-if="totalPages > 1">
    <li :class="{ 'page-disabled': currentPage <= 0 }">
      <a @click="onClickPreviousPage">
        <PhCaretLeft :size="16" class="mr-2" />
        <span class="hide-on-small-screen">zurück</span>
      </a>
    </li>

    <template v-for="pageId in pages">
      <li :key="pageId" :class="{ 
          'page-disabled': currentPage == pageId, 
          'page-active': currentPage == pageId, 
        }">
        <span class="elipsis" v-if="pageId < 0">...</span>
        <a v-else @click="onClickPage(pageId)">
          {{ pageId + 1 }}
        </a>
      </li>
    </template>

    <li :class="{ 'page-disabled': currentPage >= totalPages - 1 }">
      <a @click="onClickNextPage" >
        <span class="hide-on-small-screen">weiter</span>
        <PhCaretRight :size="16" class="ml-2" />
      </a>
    </li>
  </ul>
</div>
</template>

<script>
import { PhCaretLeft, PhCaretRight } from 'phosphor-vue';

export default {
	props: {
    totalPages: {
      type: Number,
      required: true
    },
    currentPage: { // 0-based index
      type: Number,
      required: true
    },
    total:  {
      type: Number,
      required: true
    },
    perPage:  {
      type: Number,
      required: true
    },
    displayedItens: {
      type: Number,
      default: 0
    }
  },
  computed: {
    pages() {
      const result = [];
      let usedElipsis = false;
      let elipsisCode = -12;
      for (let i = 0; i < this.totalPages; i++) {
        const showPageIndex = Math.min(i, this.totalPages - 1 - i, Math.abs(this.currentPage - i)) < 2;
        if (showPageIndex) {
          result.push(i);
          usedElipsis = false;
        } else if (!usedElipsis) {
          usedElipsis = true;
          result.push(elipsisCode--);
        }
      }
      return result;
    },
    itensOnPage() {
      return this.displayedItens || this.perPage || 0;
    }
  },
  methods: {
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickPreviousPage() {
      this.onClickPage(this.currentPage - 1);
    },
    onClickNextPage() {
      this.onClickPage(this.currentPage + 1);
    },
  },
  components: {
    PhCaretLeft,
    PhCaretRight,
  },
};
</script>

<style scoped>
.fc-pagination {
  flex: 1 0 auto;
}
.page-active {
  font-weight: bold;
}
li.page-disabled a {
  cursor: auto;
}
li a {
  cursor: pointer;
}
li.elipsis {
  padding: 5px;
}
.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.pagination-text {
  flex: 0 0 330px;
}
</style>
