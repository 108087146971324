import DASHBOARD_TYPES from './types';
import CORE_TYPES from '../../../store/core/types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage } from '../../../helpers/log-message-helper';
import { formatNumber } from '@/helpers/number-formatter.js';
import { BROKER_STRUCTURE_ROLES, EMPLOYEE_ROLES, VIEW_ROLES, ROLES } from '@/router/roles';
import FC_CONFIG from '@/configs/fcConfig';
import FC_CONFIG_TYPES from '@/store/fcConfig/types.js'
import MSC_NEWS_TYPES from '@/store/mscnews/types';
import { toDayjs } from '@/helpers/date-helper.js'
import dayjs from 'dayjs'

const config = {
  defaultSpinner: true
};

const PERIOD_DAYS_SHOW_FK_EVENT_MODAL = 7

export default {
  [DASHBOARD_TYPES.ACTIONS.SAVE_DASHBOARD]({ commit, state, rootState }, payload) {
    axios.post(rootState.core.apiAddress + '/../mrsdashboard', payload).then(response => {
      commit(DASHBOARD_TYPES.MUTATIONS.SAVE_DASHBOARD, response.data);
    }).catch(error => {
      commit(DASHBOARD_TYPES.MUTATIONS.SAVE_DASHBOARD, error.response && error.response.data);
    })
  },
  [DASHBOARD_TYPES.ACTIONS.GET_DASHBOARD_CHARTS]({ commit, state, rootState, dispatch, getters }, payload) {
      const getData = (parameter) => {
        commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS_STRUCTURE, parameter ? 1 : 0);
        const requests = [
          {
            name: `radialBarMultiple#Kundenbindung`,
            id: 'Kundenbindung',
            queryName: [
              {
                name: 'anzahlNutzerApp',
                request: 'NUTZER_APP',
              },
              {
                name: 'anzahlNutzerFC',
                request: 'NUTZER_FC',
              },
              {
                name: 'anzahlDsgvoAktiv',
                request: 'DSGVO_AKTIV',
              },
              {
                name: 'anzahlKundenMitBestand',
                request: 'ANZAHL_KUNDEN_MIT_BESTAND',
              },
              {
                name: 'anzahlKundenOhneBestand',
                request: 'ANZAHL_KUNDEN_OHNE_BESTAND',
              },
            ],
            data: (response) => ({
              "Finance-App": response.anzahlNutzerApp || 0,
              "FinanceCloud": response.anzahlNutzerFC || 0,
              "DSGVO aktiv": response.anzahlDsgvoAktiv || 0,
            }),
            title: (response) => `Kundenbindung (${formatNumber((+response.anzahlKundenMitBestand || 0) + (+response.anzahlKundenOhneBestand || 0))})`,
          },
          {
            name: 'Gesamtcourtage',
            id: 'Gesamtcourtage',
            roles: [EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_INVESTMENT],
            queryName: [
              {
                name: 'JAHRESUMSATZ_INVESTMENT',
                request: 'JAHRESUMSATZ_INVESTMENT',
              },
              {
                name: 'JAHRESUMSATZ_SERVICEGEBUEHR',
                request: 'JAHRESUMSATZ_SERVICEGEBUEHR',
              },
              {
                name: 'JAHRESUMSATZ_BETEILIGUNG',
                request: 'JAHRESUMSATZ_BETEILIGUNG',
              },
              {
                name: 'JAHRESUMSATZ_VERSICHERUNG',
                request: 'JAHRESUMSATZ_VERSICHERUNG',
              },
              {
                name: 'JAHRESUMSATZ_KREDITE',
                request: 'JAHRESUMSATZ_KREDITE',
              },
            ],
            data: (response) => {
              const years = Object.keys({
                ...response['JAHRESUMSATZ_INVESTMENT'],
                ...response['JAHRESUMSATZ_SERVICEGEBUEHR'],
                ...response['JAHRESUMSATZ_BETEILIGUNG'],
                ...response['JAHRESUMSATZ_VERSICHERUNG'],
                ...response['JAHRESUMSATZ_KREDITE'],
              })
              const results = years.map(year => ({
                [year]: {
                  Investment: response['JAHRESUMSATZ_INVESTMENT'][year] || [0],
                  Servicegebühr: response['JAHRESUMSATZ_SERVICEGEBUEHR'][year] || [0],
                  Beteiligungen: response['JAHRESUMSATZ_BETEILIGUNG'][year] || [0],
                  Versicherungen: response['JAHRESUMSATZ_VERSICHERUNG'][year] || [0],
                  Finanzierungen: response['JAHRESUMSATZ_KREDITE'][year] || [0],
                }
              })).reduce((acc, curr) => ({
                ...acc,
                [Object.keys(curr)[0]]: Object.values(curr)[0]
              }), {});
              return results;
            },
          },
          {
            name: 'area#Gesamtcourtage kumuliert',
            id: 'Gesamtcourtage_YEAR',
            roles: [EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_INVESTMENT],
            queryName: [
              {
                name: 'Gesamtumsatz ytd',
                request: 'UMSATZ_YTD_GESAMT',
              }
            ],
            data: (response) => response['Gesamtumsatz ytd'],
          },
          {
            name: 'area#Bestand',
            id: 'Bestand',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            queryName: [
              {
                name: 'BESTAND_INVESTMENT',
                request: 'BESTAND_INVESTMENT',
              }
            ],
            data: (response) => response['BESTAND_INVESTMENT'],
          },
          {
            name: `donut#Bestandsübersicht`,
            id: 'Bestandsübersicht',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            queryName: [
              {
                name: 'anzahlKundenOhneBestand',
                request: 'ANZAHL_KUNDEN_OHNE_BESTAND',
              },
              {
                name: 'anzahlKundenMitBestand',
                request: 'ANZAHL_KUNDEN_MIT_BESTAND',
              },
            ],
            data: (response) => ({
              "Kunden ohne Bestand": response.anzahlKundenOhneBestand || 0,
              "Kunden mit Bestand": response.anzahlKundenMitBestand || 0,
            }),
            title: (response) => `Bestandsübersicht (${formatNumber((+response.anzahlKundenMitBestand || 0) + (+response.anzahlKundenOhneBestand || 0)) || 0})`,
            all: (response) => (+response.anzahlKundenOhneBestand || 0) + (+response.anzahlKundenMitBestand || 0) || 1,
          },
          {
            name: `radialBarMultiple#Bestandkunden`,
            id: 'Bestandkunden',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            queryName: [
              {
                name: 'anzahlKundenMitBestand',
                request: 'ANZAHL_KUNDEN_MIT_BESTAND',
              },
              {
                name: 'anzahlKundenMitBestandUndAnlegerprofil',
                request: 'ANZAHL_KUNDEN_MIT_BESTAND_UND_ANLEGERPROFIL',
              },
              {
                name: 'anzahlKundenMitBestandUndRahmenverinbarung',
                request: 'ANZAHL_KUNDEN_MIT_BESTAND_UND_RAHMENVERINBARUNG',
              },
              {
                name: 'anzahlKundenMitBestandUndServicegebuehr',
                request: 'ANZAHL_KUNDEN_MIT_BESTAND_UND_SERVICEGEBUEHR',
              },
              {
                name: 'anzahlKundenMitBestandUndSparplan',
                request: 'ANZAHL_KUNDEN_MIT_BESTAND_UND_SPARPLAN',
              },
            ],
            data: (response) => ({
              "mit Anlegerprofil": (response.anzahlKundenMitBestandUndAnlegerprofil || 0),
              "mit Rahmenvereinbarung": (response.anzahlKundenMitBestandUndRahmenverinbarung || 0),
              "mit Servicegebühr": (response.anzahlKundenMitBestandUndServicegebuehr || 0),
              "mit Sparplan": (response.anzahlKundenMitBestandUndSparplan || 0),
            }),
            title: (response) => `Bestandkunden (${formatNumber(response.anzahlKundenMitBestand || 0)})`,
            labels: (response) => ({
              "mit Anlegerprofil": response.anzahlKundenMitBestandUndAnlegerprofil || 0,
              "mit Rahmenvereinbarung": response.anzahlKundenMitBestandUndRahmenverinbarung || 0,
              "mit Servicegebühr": response.anzahlKundenMitBestandUndServicegebuehr || 0,
              "mit Sparplan": response.anzahlKundenMitBestandUndSparplan || 0,
            }),
          },
          {
            name: 'bar#Nettomittelzufluss Gesamt',
            id: 'Nettomittelzufluss_Gesamt',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_INVESTMENT, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]],
            queryName: [
              {
                name: 'Nettomittelzufluss Gesamt',
                request: 'NETTOMITTELZUFLUSS_GESAMT',
              }
            ],
            data: (response) => response['Nettomittelzufluss Gesamt'],
          },
          {
            name: 'area#Courtage Investmentfonds',
            id: 'Courtage_Investmentfonds',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_INVESTMENT, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]],
            queryName: [
              {
                name: 'Umsatz Investmentfonds ytd',
                request: 'UMSATZ_YTD_INVESTMENT',
              }
            ],
            data: (response) => response['Umsatz Investmentfonds ytd']
          },
          {
            name: 'area#Courtage Servicegebühr',
            id: 'Courtage_Servicegebühr',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_INVESTMENT, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]],
            queryName: [
              {
                name: 'Umsatz Servicegebühr ytd',
                request: 'UMSATZ_YTD_SERVICEGEBUEHR',
              }
            ],
            data: (response) => response['Umsatz Servicegebühr ytd']
          },
          {
            name: 'pie#Top Lagerstelle Bestand',
            id: 'Top_Lagerstelle_Bestand',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            queryName: [
              {
                name: 'Top Lagerstelle',
                request: 'BESTAND_TOP_LAGERSTELLEN',
              }
            ],
            data: (response) => response['Top Lagerstelle']
          },
          {
            name: 'pie#Top Gesellschaften Bestand',
            id: 'Top_Gesellschaften_Bestand',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            queryName: [
              {
                name: 'Top Gesellschaften',
                request: 'BESTAND_TOP_GESELLSCHAFTEN',
              }
            ],
            data: (response) => response['Top Gesellschaften']
          },
          {
            name: 'pie#Top Fonds Bestand',
            id: 'Top_Fonds_Bestand',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            queryName: [
              {
                name: 'Top Fonds',
                request: 'BESTAND_TOP_FONDS',
              }
            ],
            data: (response) => response['Top Fonds']
          },
          {
            name: 'pie#Top Lagerstelle Nettomittelaufkommen',
            id: 'Top_Lagerstelle_Nettomittelaufkommen',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            queryName: [
              {
                name: 'Nettomittelzufluss nach Lagerstelle',
                request: 'NETTOMITTELZUFLUSS_TOP_LAGERSTELLEN',
              }
            ],
            data: (response) => response['Nettomittelzufluss nach Lagerstelle']
          },
          {
            name: 'pie#Top Gesellschaften Nettomittelaufkommen',
            id: 'Top_Gesellschaften_Nettomittelaufkommen',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            queryName: [
              {
                name: 'Nettomittelzufluss nach Gesellschaft',
                request: 'NETTOMITTELZUFLUSS_TOP_GESELLSCHAFTEN',
              }
            ],
            data: (response) => response['Nettomittelzufluss nach Gesellschaft']
          },
          {
            name: 'pie#Top Fonds Nettomittelaufkommen',
            id: 'Top_Fonds_Nettomittelaufkommen',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
            queryName: [
              {
                name: 'Nettomittelzufluss nach Fonds',
                request: 'NETTOMITTELZUFLUSS_TOP_FONDS',
              }
            ],
            data: (response) => response['Nettomittelzufluss nach Fonds']
          },
          {
            name: `radialBarMultiple#Kundenübersicht`,
            id: 'Kundenübersicht',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]],
            queryName: [
              {
                name: 'anzahlKundenMitVersicherungen',
                request: 'ANZAHL_KUNDEN_MIT_VERSICHERUNGEN',
              },
              {
                name: 'anzahlKundenMitVersicherungenMitMaklervertrag',
                request: 'ANZAHL_KUNDEN_MIT_MAKLERVERTRAG_VERSICHERUNGEN',
              },
              {
                name: 'ANZAHL_KUNDEN_OHNE_BESTAND',
                request: 'ANZAHL_KUNDEN_OHNE_BESTAND',
              },
              {
                name: 'ANZAHL_KUNDEN_MIT_BESTAND',
                request: 'ANZAHL_KUNDEN_MIT_BESTAND',
              },
            ],
            data: (response) => ({
              'Kunden mit Versicherung': response.anzahlKundenMitVersicherungen || 0,
              'Kunden mit Versicherung und Maklervertrag': response.anzahlKundenMitVersicherungenMitMaklervertrag || 0,
            }),
            title: (response) => `Kundenübersicht (${formatNumber((+response.ANZAHL_KUNDEN_OHNE_BESTAND || 0) + (+response.ANZAHL_KUNDEN_MIT_BESTAND || 0))})`,
          },
          {
            name: 'vertrag#Aufteilung in den letzten 12 Monaten',
            id: 'Aufteilung_in_den_letzten_12_Monaten',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]],
            queryName: [
              {
                name: 'anzahlVertraegeVers',
                request: 'VERTRAEGE_VERS',
              },
              {
                name: 'Gesamtumsatz',
                request: 'NETTOMITTELZUFLUSS_GESAMT',
              },
              {
                name: 'anzahlKundenMitVersicherungen',
                request: 'ANZAHL_KUNDEN_MIT_VERSICHERUNGEN',
              },
              {
                name: 'anzahlMaklerauftraege12Monate',
                request: 'MAKLERAUFTRAG_VERSICHERUNGEN_12_MONATE',
              },
              {
                name: 'anzahlVertraegeVersVorsorgeFremd',
                request: 'VORSORGE_FREMD',
              },
              {
                name: 'GESUNDHEIT_EIGEN',
                request: 'GESUNDHEIT_EIGEN',
              },
              {
                name: 'VORSORGE_EIGEN',
                request: 'VORSORGE_EIGEN',
              },
              {
                name: 'SACHEN_EIGEN',
                request: 'SACHEN_EIGEN',
              },
              {
                name: 'BETRIEBLICH_EIGEN',
                request: 'BETRIEBLICH_EIGEN',
              },
              {
                name: 'SACHEN_FREMD',
                request: 'SACHEN_FREMD',
              },
              {
                name: 'GESUNDHEIT_FREMD',
                request: 'GESUNDHEIT_FREMD',
              },
              {
                name: 'BETRIEBLICH_FREMD',
                request: 'BETRIEBLICH_FREMD',
              },
              {
                name: 'UMSATZ_VERSICHERUNGEN_ABSCHLUSS_12_MONATE',
                request: 'UMSATZ_VERSICHERUNGEN_ABSCHLUSS_12_MONATE',
              },
              {
                name: 'UMSATZ_VERSICHERUNGEN_BESTAND_12_MONATE',
                request: 'UMSATZ_VERSICHERUNGEN_BESTAND_12_MONATE',
              },
            ],
            data: (response) => ({
              insgesmat: response['GESUNDHEIT_EIGEN'] + response['VORSORGE_EIGEN'] + response['SACHEN_EIGEN'] + response['BETRIEBLICH_EIGEN'] + response['SACHEN_FREMD'] + response['GESUNDHEIT_FREMD'] + response['BETRIEBLICH_FREMD'] + response['anzahlVertraegeVersVorsorgeFremd'] || 0,
              VertageKunde: response.anzahlVertraegeVers / response.anzahlKundenMitVersicherungen || 0,
              UmsatzKunde: ((response['UMSATZ_VERSICHERUNGEN_ABSCHLUSS_12_MONATE'] + response['UMSATZ_VERSICHERUNGEN_BESTAND_12_MONATE']) / response['anzahlKundenMitVersicherungen']) || 0,

              anzahlMaklerauftraege12Monate: response.anzahlMaklerauftraege12Monate || 0,
              anzahlVertraegeVersVorsorgeFremd: response.anzahlVertraegeVersVorsorgeFremd || 0,

              CourtageAbschluss: response['UMSATZ_VERSICHERUNGEN_ABSCHLUSS_12_MONATE'] || 0,
              CourtageBestand: response['UMSATZ_VERSICHERUNGEN_BESTAND_12_MONATE'] || 0,
            }),
          },
          {
            name: 'area#Courtage Versicherungen',
            id: 'Courtage_Versicherungen',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]],
            queryName: [
              {
                name: 'UMSATZ_YTD_VERSICHERUNGEN_BESTAND',
                request: 'UMSATZ_YTD_VERSICHERUNGEN_BESTAND',
              },
              {
                name: 'UMSATZ_YTD_VERSICHERUNGEN_ABSCHLUSS',
                request: 'UMSATZ_YTD_VERSICHERUNGEN_ABSCHLUSS',
              }
            ],
            data: (response) => {
              const years = Object.keys({
                ...response['UMSATZ_YTD_VERSICHERUNGEN_ABSCHLUSS'],
                ...response['UMSATZ_YTD_VERSICHERUNGEN_BESTAND']
              })

              const result = years.map(year => ({
                [year]: [...response['UMSATZ_YTD_VERSICHERUNGEN_ABSCHLUSS'][year].keys()].map((v, month) =>
                  (response['UMSATZ_YTD_VERSICHERUNGEN_ABSCHLUSS'][year][month] || 0) + (response['UMSATZ_YTD_VERSICHERUNGEN_BESTAND'][year][month] || 0)
                )
              })).reduce((acc, curr) => {
                const key = Object.keys(curr)[0];
                return ({
                ...acc,
                [key]: curr[key] 
              })}, {});
              return result;
            },
          },
          {
            name: 'stacked#Anzahl Verträge',
            id: 'Anzahl_Verträge',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]],
            queryName: [
              {
                name: 'anzahlVertraegeVersGesundheitEigen',
                request: 'GESUNDHEIT_EIGEN',
              },
              {
                name: 'anzahlVertraegeVersVorsorgeEigen',
                request: 'VORSORGE_EIGEN',
              },
              {
                name: 'anzahlVertraegeVersSachEigen',
                request: 'SACHEN_EIGEN',
              },
              {
                name: 'anzahlVertraegeVersBetrieblichEigen',
                request: 'BETRIEBLICH_EIGEN',
              },
              {
                name: 'anzahlVertraegeVersSachFremd',
                request: 'SACHEN_FREMD',
              },
              {
                name: 'anzahlVertraegeVersGesundheitFremd',
                request: 'GESUNDHEIT_FREMD',
              },
              {
                name: 'anzahlVertraegeVersBetrieblichFremd',
                request: 'BETRIEBLICH_FREMD',
              },
              {
                name: 'anzahlVertraegeVersVorsorgeFremd',
                request: 'VORSORGE_FREMD',
              },
            ],
            data: (response) => {
              const items = [
                'Vorsorge', 'Sach', 'Betrieblich', 'Gesundheit'
              ];
              const keys = {
                'Vorsorge': 'anzahlVertraegeVersVorsorge',
                'Sach': 'anzahlVertraegeVersSach',
                'Betrieblich': 'anzahlVertraegeVersBetrieblich',
                'Gesundheit': 'anzahlVertraegeVersGesundheit',
              };
              const result = items.map(item => ({
                [item]: {
                  Eigenbestand: [response[keys[item] + 'Eigen'] || 0],
                  Fremdbestand: [response[keys[item] + 'Fremd'] || 0],
                }
              })).reduce((acc, curr) => ({
                ...acc,
                [Object.keys(curr)[0]]: Object.values(curr)[0]
              }), {});
              return result;
            },
          },
          {
            name: 'area#Courtage Versicherungen Bestand',
            id: 'Courtage_Versicherungen_Bestand',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]],
            queryName: [
              {
                name: 'Umsatz Versicherungen Bestand ytd',
                request: 'UMSATZ_YTD_VERSICHERUNGEN_BESTAND',
              }
            ],
            data: (response) => response['Umsatz Versicherungen Bestand ytd'],
          },
          {
            name: 'area#Courtage Versicherungen Abschluss',
            id: 'Courtage_Versicherungen_Abschluss',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]],
            queryName: [
              {
                name: 'Umsatz Versicherungen Abschluss ytd',
                request: 'UMSATZ_YTD_VERSICHERUNGEN_ABSCHLUSS',
              }
            ],
            data: (response) => response['Umsatz Versicherungen Abschluss ytd']
          },
          {
            name: 'pie#Top Lagerstelle Beteiligungen',
            id: 'Top_Lagerstelle_Beteiligungen',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_BETEILIGUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS]],
            queryName: [
              {
                name: 'Top Lagerstelle Beteiligung',
                request: 'BESTAND_TOP_LAGERSTELLEN_BETEILIGUNG',
              }
            ],
            data: (response) => response['Top Lagerstelle Beteiligung']
          },
          {
            name: 'area#Courtage Beteiligungen',
            id: 'Courtage_Beteiligungen',
            roles: [[EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_BETEILIGUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS]],
            queryName: [
              {
                name: 'Umsatz Beteiligungen ytd',
                request: 'UMSATZ_YTD_BETEILIGUNG',
              }
            ],
            data: (response) => response['Umsatz Beteiligungen ytd']
          },
          {
            name: 'pie#Abschlussvolumen',
            id: 'Abschlussvolume',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN],
            queryName: [
              {
                name: 'abschlussVolumenFinanzierungen12Monate',
                request: 'VOLUMEN_FINANZIERUNGEN_12_MONATE',
              }
            ],
            data: (response) => ({'Abschlussvolumen': response['abschlussVolumenFinanzierungen12Monate']})
          },
          {
            name: 'area#Courtage Finanzierungen',
            id: 'Courtage_Finanzierungen',
            roles: [BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN],
            queryName: [
              {
                name: 'Umsatz Finanzierungen ytd',
                request: 'UMSATZ_YTD_FINANZIERUNGEN',
              }
            ],
            data: (response) => response['Umsatz Finanzierungen ytd']
          },
          {
            name: 'num#Vertrag Investment',
            id: 'Vertrag_Investment',
            queryName: [
              {
                name: 'vertragseit',
                request: 'VERTRAG_SEIT_FONDS',
              }
            ],
            data: (response) => response['vertragseit'] && response['vertragseit']['VERTRAG_SEIT_FONDS']
          },
          {
            name: 'num#Vertrag Versicherung',
            queryName: [
              {
                name: 'vertragseit',
                request: 'VERTRAG_SEIT_VERS',
              }
            ],
            data: (response) => response['vertragseit'] && response['vertragseit']['VERTRAG_SEIT_VERS']
          },
          {
            name: 'num#Courtagestufe',
            id: 'Courtagestufe',
            queryName: [
              {
                name: 'courtagestufe',
                request: 'COURTAGESTUFE',
              }
            ],
            data: (response) => response['courtagestufe'] && response['courtagestufe']['COURTAGESTUFE']
          },
          {
            name: 'num#Top Courtage',
            id: 'TopCourtage',
            queryName: [
              {
                name: 'topCourtage',
                request: 'TOP_COURTAGE',
              }
            ],
            data: (response) => response['topCourtage'] !== undefined && response['topCourtage'] !== null && (response['topCourtage']['TOP_COURTAGE'] + '€')
          },

          {
            name: 'anzahlVertraegeBeteiligungen',
            id: 'anzahlVertraegeBeteiligungen',
            queryName: [
              {
                name: 'anzahlVertraegeBeteiligungen',
                request: 'VERTRAEGE_BETEILIGUNGEN',
              }
            ],
            data: (response) => response['anzahlVertraegeBeteiligungen'],
            isValue: true,
          },
          {
            name: 'anzahlVertraegeFinanzierungen',
            id: 'anzahlVertraegeFinanzierungen',
            queryName: [
              {
                name: 'anzahlVertraegeFinanzierungen',
                request: 'VERTRAEGE_FINANZIERUNGEN',
              }
            ],
            data: (response) => response['anzahlVertraegeFinanzierungen'],
            isValue: true,
          },
          {
            name: 'infoInventory#Bestandsübersicht',
            id: 'inventory',
            queryName: [],
            data: (response) => response['inventory'],
          },
          {
            name: 'infoInventory#Kostenpflichtige Produkte',
            id: 'products',
            queryName: [],
            data: (response) => response['products'],
          },
        ];

        const descriptionsMap = {
          'infoInventory#Bestandsübersicht': 'Allgemein - Bestandsübersicht',
          'infoInventory#Kostenpflichtige Produkte': 'Allgemein - Kostenpflichtige Produkte',
          'radialBarMultiple#Kundenbindung': 'Allgemein - Kundenbindung',
          'radialBarMultiple#Kundenübersicht': 'Versicherungen - Kundenübersicht',
          'area#Courtage Servicegebühr': 'Investment - Courtage Servicegebühr',
          'pie#Top Fonds Bestand': 'Investment - Top Fonds Bestand',
          'pie#Top Lagerstelle Bestand': 'Investment - Top Lagerstellen Bestand',
          'area#Courtage Versicherungen Bestand': 'Versicherungen - Courtage Versicherungen Bestand',
          'area#Courtage Versicherungen': 'Versicherungen - Courtage Versicherungen',
          'pie#Top Gesellschaften Nettomittelaufkommen': 'Investment - Top Gesellschaften Nettomittelaufkommen',
          'pie#Top Lagerstelle Beteiligungen': 'Beteiligungen - Top Lagerstellen BeteiligungenBeteiligungen/AIF´s',
          'vertrag#Aufteilung in den letzten 12 Monaten': 'Versicherungen - Aufteilung in den letzten 12 Monaten',
          'area#Courtage Beteiligungen': 'Beteiligungen - Courtage Beteiligungen/AIF´s',
          'area#Bestand': 'Investment - Bestand',
          'area#Courtage Investmentfonds': 'Investment - Courtage Investment',
          'pie#Top Gesellschaften Bestand': 'Investment - Top Gesellschaften Bestand',
          'pie#Top Fonds Nettomittelaufkommen': 'Investment - Top Fonds Nettomittelaufkommen',
          'pie#Abschlussvolumen': 'Finanzierungen - Abschlussvolumen',
          'stacked#Anzahl Verträge': 'Versicherungen - Anzahl Verträge',
          'radialBarMultiple#Bestandkunden': 'Investment - Bestandskunden',
          'area#Gesamtcourtage Gesamtcourtage kumuliert': 'Allgemein - Gesamtcourtage YTD',
          'donut#Bestandsübersicht': 'Investment - Bestandsübersicht',
          'area#Courtage Versicherungen Abschluss': 'Versicherungen - Courtage Versicherungen Abschluss',
          'bar#Nettomittelzufluss Gesamt': 'Investment - Nettomittelzufluss Gesamt',
          'Gesamtcourtage': 'Allgemein - Gesamtcourtage',
          'pie#Top Lagerstelle Nettomittelaufkommen': 'Investment - Top Lagerstelle Nettomittelaufkommen',
          'area#Courtage Finanzierungen': 'Finanzierungen - Courtage Finanzierungen',
        };

        const allPromises = {};
        const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES]
        axios.get(rootState.core.apiAddress + `/kpi/getAllCards`, config).then((resp) => {
          const visibleCards = {};
          resp.data.map(card => visibleCards[card.name] = card.show);
          if (getters[CORE_TYPES.GETTERS.IS_FA]) {
            visibleCards['products'] = false;
          }
          commit(DASHBOARD_TYPES.MUTATIONS.SET_ENABLED_CARDS, visibleCards);
          
          requests.map(request => {
            if (!visibleCards[request.id]) {
              commit(DASHBOARD_TYPES.MUTATIONS.MARK_CARD_SUCCESS, request.id)
              return;
            }
            if (!request.roles || hasRoles(request.roles)) {
              request.queryName.map(queryName => {
                allPromises[queryName.request] = null;
              })
            } else {
              commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS, {
                name: request.name,
                id: request.id,
                isValue: request.isValue,
                isError: false,
                roles: request.roles
              });
            }
          });
          Object.keys(allPromises).map(request => {
            allPromises[request] = axios.get(rootState.core.apiAddress + `/../mrsmaklerkpi?query_name=${request}&struktur=${(parameter ? 1 : 0)}`, config)
          })
          allPromises['descriptions'] = axios.get(rootState.core.apiAddress + `/../mrsmaklerkpidescription`, config)
          allPromises['products'] = axios.get(rootState.core.apiAddress + `/../${parameter ? 'mrspanelassetextendedstruct' : 'mrspanelassetextended'}`, config)
          if (!getters[CORE_TYPES.GETTERS.IS_FA]) {
            allPromises['inventory'] = axios.get(rootState.core.apiAddress + `/../mrspanelcharged?struktur=${(parameter ? 1 : 0)}`, config)
          }

          requests.map(request => {
            if (visibleCards[request.id] === false) {
              return;
            }
            Promise.all(
              request.queryName.map(queryName => {
                return allPromises[queryName.request];
              }).concat([allPromises['inventory'], allPromises['products'], allPromises['descriptions']])
            ).then(values => {
              const params = {};
              let info = '';
              values.map((resp, i) => {
                if (request.queryName[i]) {
                  params[request.queryName[i].name] = resp && resp.data && (resp.data.anzahl || resp.data.text || resp.data.Textliste && Object.keys(resp.data.Textliste).length && resp.data.Textliste || resp.data.Textvalue && Object.keys(resp.data.Textvalue).length && resp.data.Textvalue)
                }
              });
              const descriptions = values[values.length - 1] && values[values.length - 1].data.Descriptions;
              if (request.name === 'infoInventory#Bestandsübersicht') {
                params['inventory'] = {data: values[values.length - 2] && values[values.length - 2].data.objects}
              }
              if (request.name === 'infoInventory#Kostenpflichtige Produkte') {
                params['products'] = {data: values[values.length - 3] && values[values.length - 3].data.objects}
              }
              info = descriptions[descriptionsMap[request.name]];
              commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS, {
                name: request.name,
                ...(request.data && {data: request.data(params)} || {}),
                ...(request.labels && {labels: request.labels(params)} || {}),
                ...(request.all && {all: request.all(params)} || {}),
                ...(request.title && {title: request.title(params)} || {}),
                info: info,
                isValue: request.isValue,
                roles: request.roles,
                id: request.id,
              });
            }).catch(() => {
              // commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS, error.response && error.response.data);
              const params = {};
              commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS, {
                name: request.name,
                ...(request.data && {data: request.data(params)} || {}),
                ...(request.labels && {labels: request.labels(params)} || {}),
                ...(request.all && {all: request.all(params)} || {}),
                ...(request.title && {title: request.title(params)} || {}),
                isValue: request.isValue,
                isError: true,
                roles: request.roles,
                id: request.id,
              });
              dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es gab ein Problem bei dem Abruf der Daten.', 'danger'));
            });
          });
        });
      }

      commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS_RESET);
        if (payload === 1 || payload === 0) {
          axios.get(rootState.core.apiAddress + `/../mrsmaklerkpi?query_name=START_CALCULATION&struktur=${payload}`, config).then((resp) => {
            getData(payload);
          });
        } else {
          axios.get(rootState.core.apiAddress + '/Parameter/dasboard', config).then(parameter => {
            commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS_STRUCTURE, parameter.data);
            axios.get(rootState.core.apiAddress + `/../mrsmaklerkpi?query_name=START_CALCULATION&struktur=${(parameter.data ? 1 : 0)}`, config).then((resp) => {
              getData(parameter.data);
            });
          }).catch(error => {
            commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS, error.response && error.response.data);
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es gab ein Problem bei dem Abruf der Daten.', 'danger'));
          })
        }
  },

  [DASHBOARD_TYPES.ACTIONS.GET_DASHBOARD_CONFIGURATION]({ commit, rootState, state}) {
    if (state.configuration && Object.keys(state.configuration).length) {
      
    } else {
      axios.get(rootState.core.apiAddress + '/dashboard/get_dashboard_configuration', config).then(response => {
        commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CONFIGURATION_SUCCESS, response.data);
      })
    }
  },

  [DASHBOARD_TYPES.ACTIONS.GET_STECKBRIEF_CONFIGURATION]({ commit, rootState}) {
    axios.get(rootState.core.apiAddress + '/dashboard/get_steckbrief_configuration', config).then(response => {
      commit(DASHBOARD_TYPES.MUTATIONS.GET_STECKBRIEF_CONFIGURATION_SUCCESS, response.data);
    })
  },

  [DASHBOARD_TYPES.ACTIONS.START_CALCULATION_DASHBOARD_CHARTS]({ commit, state, rootState, dispatch}) {
    if (!state.isPrecalculated) {
      axios.get(rootState.core.apiAddress + '/Parameter/dasboard', config).then(parameter => {
        commit(DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS_STRUCTURE, parameter.data);
        axios.get(rootState.core.apiAddress + `/../mrsmaklerkpi?query_name=START_CALCULATION&struktur=${(parameter.data ? 1 : 0)}`, config).then((resp) => {
          commit(DASHBOARD_TYPES.MUTATIONS.START_CALCULATION_DASHBOARD_CHARTS, true);
        });
      }).catch(error => {
        commit(DASHBOARD_TYPES.MUTATIONS.START_CALCULATION_DASHBOARD_CHARTS, false);
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es gab ein Problem bei dem Abruf der Daten.', 'danger'));
      })
    }
  },

  async [DASHBOARD_TYPES.ACTIONS.GET_BROKER_OVERVIEW_CONFIGURATION]({ commit, rootState, }) {
    const response = await axios.get(`${rootState.core.apiAddress}/dashboard/get_broker_overview_configuration`, config);
    commit(DASHBOARD_TYPES.MUTATIONS.GET_BROKER_OVERVIEW_CONFIGURATION_SUCCESS, response?.data || {});
  },

  async [DASHBOARD_TYPES.ACTIONS.GET_INTERN_OVERVIEW_CONFIGURATION]({ commit, rootState, }) {
    const response = await axios.get(`${rootState.core.apiAddress}/dashboard/get_intern_overview_configuration`, config);
    commit(DASHBOARD_TYPES.MUTATIONS.GET_INTERN_OVERVIEW_CONFIGURATION_SUCCESS, response?.data || {});
  },  

  async [DASHBOARD_TYPES.ACTIONS.SAVE_BROKER_OVERVIEW_CONFIGURATION]({ commit, rootState, }, { parameters }) {
    if(!Object.keys(parameters)?.length) return;

    const response = await axios.post(`${rootState.core.apiAddress}/dashboard/save_broker_overview_configuration`, parameters, config);
    commit(DASHBOARD_TYPES.MUTATIONS.GET_BROKER_OVERVIEW_CONFIGURATION_SUCCESS, response?.data || {});
  },

  async [DASHBOARD_TYPES.ACTIONS.SAVE_INTERN_OVERVIEW_CONFIGURATION]({ commit, rootState, }, { parameters }) {
    if(!Object.keys(parameters)?.length) return;

    const response = await axios.post(`${rootState.core.apiAddress}/dashboard/save_intern_overview_configuration`, parameters, config);
    commit(DASHBOARD_TYPES.MUTATIONS.GET_INTERN_OVERVIEW_CONFIGURATION_SUCCESS, response?.data || {});
  },  

  async [DASHBOARD_TYPES.ACTIONS.GET_BROKER_PARAGRAPHS_CONFIGURATION]({ commit, rootState, }) {
    const response = await axios.get(`${rootState.core.apiAddress}/dashboard/get_broker_paragraphs`, config);
    commit(DASHBOARD_TYPES.MUTATIONS.GET_BROKER_PARAGRAPHS_CONFIGURATION_SUCCESS, response?.data || {});
  }, 

  async [DASHBOARD_TYPES.ACTIONS.SHOW_FK_EVENTS_MODAL]({ dispatch, getters }) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES]
    if (!hasRoles(VIEW_ROLES.VIEW_BROKER_ONLY) || !hasRoles(ROLES.SHOW_EVENTS)) {
      return
    }

    await dispatch('FC_CONFIG_ACTIONS_LOAD_FC_CONFIG', { 
      configId: FC_CONFIG.LAST_VISUALIZED_EVENT_DATE, 
      configType: FC_CONFIG.LAST_VISUALIZED_EVENT_DATE, 
      forceReload: true
    })

    const now = dayjs();
    const config = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_LAST_VISUALIZED_EVENT_DATE]
    let date = config?.[FC_CONFIG.LAST_VISUALIZED_EVENT_DATE]?.content
    date = date ? toDayjs(date) : null
    let showFKEventsModal = !date || now.diff(date, 'days') >= PERIOD_DAYS_SHOW_FK_EVENT_MODAL;

    if (showFKEventsModal) {
      await dispatch(MSC_NEWS_TYPES.ACTIONS.GET_FK_EVENTS);
      const fkEvents = getters[MSC_NEWS_TYPES.GETTERS.FK_EVENTS];

      if (fkEvents?.length) {
        const lastVisualized = { 
          configId: 'LAST_VISUALIZED_EVENT_DATE', 
          configType: 'LAST_VISUALIZED_EVENT_DATE', 
          content: now.format('DD.MM.YYYY') }
        dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, lastVisualized)
      } else {
        showFKEventsModal = false;
      }
    }

    return { showFKEventsModal }
  },
}
