var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.getPositions && _vm.getPositions.length
        ? _c(
            "div",
            [
              _vm.config && _vm.config.comment
                ? _c("div", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.config.comment))
                  ])
                : _vm._e(),
              _vm.isEditDisabled
                ? _c("FormLabel", {
                    staticClass: "mb-2",
                    attrs: {
                      label:
                        "Diese Daten sind bei der Löschung nicht notwendig und werden daher nicht angedruckt."
                    }
                  })
                : _vm._e(),
              _vm.rows && _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      headers: _vm.tableHeaders,
                      rows: _vm.rows,
                      rowId: "index",
                      hidePagination: ""
                    },
                    on: { "action-DELETE": _vm.openModalDeleteFond },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "betrag",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: _vm.betragType,
                                  value: row.betrag,
                                  disabled: _vm.isEditDisabled,
                                  placeholder:
                                    _vm.config &&
                                    _vm.config.betrag &&
                                    _vm.config.betrag.placeholder,
                                  id: "betrag" + row.index
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      _vm.getBetragId
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "aa",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "percent",
                                  value: row.aa,
                                  id: "aa" + row.index,
                                  disabled: _vm.disabled,
                                  placeholder: row.defaultAA
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "aa"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "modus",
                          fn: function(row) {
                            return [
                              _c("ComboBox", {
                                attrs: {
                                  value: row.modus,
                                  id: "modus" + row.index,
                                  values: _vm.values("modus", row),
                                  firstEmpty: true,
                                  disabled: _vm.isDisabled("modus", row)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "modus"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "lagerstelle",
                          fn: function(row) {
                            return [
                              _c("ComboBox", {
                                attrs: {
                                  value: row.lagerstelle,
                                  id: "lagerstelle" + row.index,
                                  values: _vm.values("lagerstelle", row),
                                  firstEmpty: true,
                                  disabled: _vm.isDisabled("lagerstelle", row)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "lagerstelle"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "depotnummer",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  value: row.depotnummer,
                                  id: "depotnummer" + row.index,
                                  disabled: _vm.isDisabled("depotnummer", row)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "depotnummer"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "frequenz",
                          fn: function(row) {
                            return [
                              _c("ComboBox", {
                                attrs: {
                                  value: row.frequenz,
                                  id: "frequenz" + row.index,
                                  values: _vm.values("frequenz", row),
                                  disabled: _vm.isDisabled("frequenz", row),
                                  firstEmpty: true
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "frequenz"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "dynamik",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "percent",
                                  value: row.dynamik,
                                  id: "dynamik" + row.index,
                                  disabled: _vm.isDisabled("dynamik", row)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "dynamik"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "zielsumme",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "currency",
                                  precision: 2,
                                  value: row.zielsumme,
                                  id: "zielsumme" + row.index,
                                  disabled: _vm.isDisabled("zielsumme", row)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "zielsumme"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "laufzeit",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "number",
                                  value: row.laufzeit,
                                  id: "laufzeit" + row.index,
                                  disabled: _vm.isDisabled("laufzeit", row)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "laufzeit"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "bemerkung",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  value: row.bemerkung,
                                  id: "bemerkung" + row.index,
                                  disabled: _vm.isDisabled("bemerkung", row)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "bemerkung"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "provfrei",
                          fn: function(row) {
                            return [
                              _c("input-toggle-switch", {
                                attrs: {
                                  value: row.provfrei,
                                  id: "provfrei" + row.index,
                                  disabled: _vm.isDisabled("provfrei", row)
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "provfrei"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "depotuebertrag",
                          fn: function(row) {
                            return [
                              _c("input-toggle-switch", {
                                attrs: {
                                  value: row.depotuebertrag,
                                  id: "depotuebertrag" + row.index,
                                  disabled: _vm.isDisabled(
                                    "depotuebertrag",
                                    row
                                  )
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "depotuebertrag"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "keineIndexierung",
                          fn: function(row) {
                            return [
                              _c("InputToggleSwitch", {
                                attrs: {
                                  value: row.keineIndexierung,
                                  id: "keineIndexierung" + row.index,
                                  disabled: _vm.isDisabled(
                                    "keineIndexierung",
                                    row
                                  )
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      !$event,
                                      row,
                                      "indexierung"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1635909844
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.doDeletePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }