var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.depots && _vm.depots.length
      ? _c(
          "div",
          [
            _vm._l(_vm.depots, function(depot, index) {
              return _c("div", { key: index }, [
                _c("div", { staticClass: "row justify-content-between" }, [
                  _c("label", { staticClass: "col-8" }, [
                    _vm._v(_vm._s(depot.label))
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isBlock: true },
                          on: {
                            click: function($event) {
                              return _vm.openFormsFinder(depot)
                            }
                          }
                        },
                        [_vm._v("Position tauschen")]
                      )
                    ],
                    1
                  )
                ])
              ])
            }),
            _c("hr"),
            _c("div", { staticClass: "input-forms__input-container" }, [
              _vm.hasPositions
                ? _c("div", [
                    _c(
                      "div",
                      [
                        _c("WertpapierAuswahlPositionsTausch", {
                          attrs: {
                            positions: _vm.positions,
                            config: _vm.config,
                            categoryId: _vm.categoryId,
                            antragId: _vm.antragId,
                            tauschVonName: _vm.tauschVonName,
                            tauschZuName: _vm.tauschZuName
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _c("div", [_c("div", [_vm._v("Wertpapierauswahl ist leer")])])
            ])
          ],
          2
        )
      : _c("div", [_vm._v(" Sie haben keine Positionen. ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }