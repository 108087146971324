import INTERN_TYPES from './types';
import { getInitialState } from './index';

export default {
  [INTERN_TYPES.MUTATIONS.TEMPLATE](state) {
    state.template = {};
  },
  [INTERN_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
}