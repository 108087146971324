var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.stepData && !_vm.stepData.depotoptimierung
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c(
              "BaseButton",
              {
                staticClass: "btn btn-primary mr-3 my-1",
                attrs: { disabled: _vm.loading || _vm.loadingPositions },
                on: {
                  click: function($event) {
                    return _vm.anlagesummeVerteilen()
                  }
                }
              },
              [_vm._v("Anlagesumme verteilen")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.stepData && _vm.stepData.depotoptimierung
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c(
              "BaseButton",
              {
                staticClass: "btn btn-primary mr-3 my-1",
                attrs: { disabled: _vm.loading || _vm.loadingPositions },
                on: {
                  click: function($event) {
                    return _vm.optimisieren()
                  }
                }
              },
              [_vm._v("Depotoptimierung")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.stepData
      ? _c("div", { staticClass: "row my-0" }, [
          _c("div", { staticClass: "col-md-4 col-12 d-flex" }, [
            _c("div", { staticClass: "box__container" }, [
              _c("div", { staticClass: "text-centered mb-0" }, [
                _vm._v("Einmalanlage:")
              ]),
              _c("div", { staticClass: "row my-0" }, [
                _c("div", { staticClass: "col-md-6 col-12" }, [
                  _vm._v("Gesamtbetrag")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-md-6 col-12",
                    class:
                      _vm.stepData.einmalanlage.gesamtbetrag < 0
                        ? "negative-number"
                        : "positive-number"
                  },
                  [
                    _c("CurrencyLabel", {
                      attrs: { value: _vm.stepData.einmalanlage.gesamtbetrag }
                    })
                  ],
                  1
                )
              ]),
              _vm.stepData.ziel
                ? _c("div", { staticClass: "row my-0" }, [
                    _c("div", { staticClass: "col-md-6 col-12" }, [
                      _vm._v("Errechneter Anfangsbetrag:")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12 positive-number" },
                      [
                        _c("CurrencyLabel", {
                          attrs: {
                            value: _vm.stepData.einmalanlage.anfangsbetragZiel
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row my-0" }, [
                _c("div", { staticClass: "col-md-6 col-12" }, [
                  _vm._v("Gesamtprozent")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-md-6 col-12",
                    class:
                      _vm.stepData.einmalanlage.gesamtprozent.toFixed(2) != 100
                        ? "negative-number"
                        : "positive-number"
                  },
                  [
                    _c("CurrencyLabel", {
                      attrs: {
                        value: _vm.stepData.einmalanlage.gesamtprozent,
                        symb: "%"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row my-0" }, [
                _c("div", { staticClass: "col-md-6 col-12" }, [
                  _vm._v("Fehlbetrag")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-md-6 col-12",
                    class:
                      _vm.stepData.einmalanlage.fehlbetrag.toFixed(2) > 0
                        ? "negative-number"
                        : "positive-number"
                  },
                  [
                    _c("CurrencyLabel", {
                      attrs: { value: _vm.stepData.einmalanlage.fehlbetrag }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row my-0" }, [
                _c("div", { staticClass: "col-md-6 col-12" }, [
                  _vm._v("Durchschn. SRI")
                ]),
                _c("div", { staticClass: "col-md-6 col-12 positive-number" }, [
                  _vm._v(
                    _vm._s(_vm.stepData.einmalanlage.durchschnittSRI.toFixed(1))
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "col-md-4 col-12 d-flex" }, [
            _c("div", { staticClass: "box__container" }, [
              _c("div", { staticClass: "text-centered mb-0" }, [
                _vm._v("Sparplan:")
              ]),
              _c("div", { staticClass: "row my-0" }, [
                _c("div", { staticClass: "col-md-6 col-12" }, [
                  _vm._v("Jahressumme")
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-12 positive-number" },
                  [
                    _c("CurrencyLabel", {
                      attrs: { value: _vm.stepData.sparplan.jahressumme }
                    })
                  ],
                  1
                )
              ]),
              _vm.stepData.ziel
                ? _c("div", { staticClass: "row my-0" }, [
                    _c("div", { staticClass: "col-md-6 col-12" }, [
                      _vm._v("Errechneter Anfangsbetrag")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12 positive-number" },
                      [
                        _c("CurrencyLabel", {
                          attrs: {
                            value: _vm.stepData.sparplan.anfangsbetragZiel
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "col-md-4 col-12 d-flex" }, [
            _c("div", { staticClass: "box__container" }, [
              _c("div", { staticClass: "text-centered mb-0" }, [
                _vm._v("Entnahmeplan:")
              ]),
              _c("div", { staticClass: "row my-0" }, [
                _c("div", { staticClass: "col-md-6 col-12" }, [
                  _vm._v("Jahressumme")
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-12 positive-number" },
                  [
                    _c("CurrencyLabel", {
                      attrs: { value: _vm.stepData.entnahmeplan.jahressumme }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row my-0" }, [
                _c("div", { staticClass: "col-md-6 col-12" }, [
                  _vm._v("Anfangsbetrag")
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-12 positive-number" },
                  [
                    _c("CurrencyLabel", {
                      attrs: { value: _vm.stepData.entnahmeplan.anfangsbetrag }
                    })
                  ],
                  1
                )
              ]),
              _vm.stepData.ziel
                ? _c("div", { staticClass: "row my-0" }, [
                    _c("div", { staticClass: "col-md-6 col-12" }, [
                      _vm._v("Errechneter Anfangsbetrag")
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12 positive-number" },
                      [
                        _c("CurrencyLabel", {
                          attrs: {
                            value: _vm.stepData.entnahmeplan.anfangsbetragZiel
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      [
        _vm.rows.length
          ? _c(
              "div",
              [
                _vm.stepData.depotoptimierung
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("Table", {
                          ref: "tblAllocation",
                          attrs: {
                            tableId: "a63c4e14-a545-4b34-9076-18d8fa461711",
                            headers: _vm.headers,
                            rows: _vm.rows,
                            rowId: "id",
                            rowsPerPage: 10,
                            showColumnsConfigEvenNoTitle: true,
                            exportConfig: { roottext: "Anlageallokation" }
                          },
                          on: {
                            "action-FACTSHEET": function($event) {
                              return _vm.viewDocument($event.FACTSHEET_URL)
                            },
                            "action-FACTSHEET_KAG": function($event) {
                              return _vm.viewDocument($event.FACTSHEET_KAG_URL)
                            },
                            "action-PRIIP_BIB": function($event) {
                              return _vm.viewDocument($event.PRIIP_BIB_URL)
                            },
                            "action-EDIT": function($event) {
                              return _vm.edit($event.id)
                            },
                            "action-DELETE": function($event) {
                              return _vm.deletePositions($event.id)
                            },
                            "click-name": _vm.gotoFondsinfo
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "active",
                                fn: function(row) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "edited-row",
                                        on: {
                                          click: function($event) {
                                            return _vm.edit(row.id)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: {
                                              selected: _vm.edit_id == row.id
                                            }
                                          },
                                          [
                                            _c("PhPencilLine", {
                                              style: {
                                                opacity:
                                                  _vm.edit_id == row.id
                                                    ? "1"
                                                    : "0.3"
                                              },
                                              attrs: { size: 18 }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3400757097
                          )
                        })
                      ],
                      1
                    )
                  : _vm._l(_vm.rows, function(row, idx) {
                      return _c(
                        "div",
                        { key: row[_vm.id], staticClass: "box__container" },
                        [
                          _c(
                            "div",
                            { staticClass: "box__title d-flex" },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoFondsinfo(row)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.name))]
                              ),
                              row.lockingModePill.type != "success"
                                ? _c("span", [
                                    _vm._v(" | "),
                                    _c(
                                      "span",
                                      {
                                        class:
                                          "text-" + row.lockingModePill.type
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(row.lockingModePill.label)
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "span",
                                { staticStyle: { "flex-grow": "1" } },
                                [
                                  _vm._v(
                                    " | " +
                                      _vm._s(row.isin) +
                                      " | SRI " +
                                      _vm._s(row.Frs) +
                                      " "
                                  ),
                                  row.isNichtHandelbar
                                    ? _c(
                                        "span",
                                        { staticClass: "nicht-handelbar" },
                                        [_vm._v(" | nicht handelbar")]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "BaseContextMenu",
                                [
                                  _vm._l(row.actions, function(action) {
                                    return [
                                      action.actionKey != "EDIT"
                                        ? _c(
                                            "ContextMenuItem",
                                            { key: action.actionKey },
                                            [
                                              _c(
                                                action.component,
                                                _vm._b(
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      row: row,
                                                      tid:
                                                        action.actionKey +
                                                        row.id,
                                                      expanded: ""
                                                    },
                                                    on: { action: _vm.doAction }
                                                  },
                                                  "component",
                                                  action,
                                                  false
                                                )
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("InputField", {
                                staticClass: "col-12 col-sm-6 col-xl-3",
                                attrs: {
                                  label: "Anteil im Zieldepot",
                                  value: row.aufteilung,
                                  disabled:
                                    row.lockingMode == "FEST" ||
                                    row.lockingMode == "IMMER",
                                  type: "percent",
                                  precision: _vm.percentPrecision
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.rowChanged(
                                      "aufteilung",
                                      idx,
                                      $event
                                    )
                                  }
                                }
                              }),
                              _c("InputField", {
                                staticClass: "col-12 col-sm-6 col-xl-3",
                                attrs: {
                                  label: _vm.zielLabel,
                                  value: row.summe,
                                  disabled:
                                    row.lockingMode == "FEST" ||
                                    row.lockingMode == "IMMER",
                                  type: "currency"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.rowChanged("summe", idx, $event)
                                  }
                                }
                              }),
                              _c("InputField", {
                                staticClass: "col-12 col-sm-6 col-xl-3",
                                attrs: {
                                  label: "Spar- / Entnahmerate",
                                  value: row.rate,
                                  disabled: _vm.stepData.depotoptimierung,
                                  type: "currency"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.rowChanged("rate", idx, $event)
                                  }
                                }
                              }),
                              _c("ComboBox", {
                                staticClass: "col-12 col-sm-6 col-xl-3",
                                attrs: {
                                  label: "Turnus",
                                  value: row.turnus || "MONATLICH",
                                  values: _vm.turnusList,
                                  disabled: _vm.stepData.depotoptimierung
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.rowChanged("turnus", idx, $event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    })
              ],
              2
            )
          : _vm.allocationRows == null
          ? _c("GhostLoading", [_c("Block", { attrs: { height: "100" } })], 1)
          : _c("NoData", { attrs: { noIcon: true } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }