var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Kundenkategorie", actions: _vm.headerActions },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              label: "Dynamische Anpassung der Kundenkategorie",
              inLineLabel: ""
            },
            on: {
              input: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.automatic,
              callback: function($$v) {
                _vm.$set(_vm.form, "automatic", $$v)
              },
              expression: "form.automatic"
            }
          }),
          _c("InputField", {
            attrs: {
              type: "currency",
              label: "Kategorie A ab Depotbestand:",
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.categoryA,
              callback: function($$v) {
                _vm.$set(_vm.form, "categoryA", $$v)
              },
              expression: "form.categoryA"
            }
          }),
          _c("InputField", {
            attrs: {
              type: "currency",
              label: "Kategorie B ab Depotbestand:",
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.categoryB,
              callback: function($$v) {
                _vm.$set(_vm.form, "categoryB", $$v)
              },
              expression: "form.categoryB"
            }
          }),
          _c("InputField", {
            attrs: {
              type: "currency",
              label: "Kategorie C ab Depotbestand:",
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                return _vm.dataChanged($event)
              }
            },
            model: {
              value: _vm.form.categoryC,
              callback: function($$v) {
                _vm.$set(_vm.form, "categoryC", $$v)
              },
              expression: "form.categoryC"
            }
          }),
          _c("div", [_vm._v("Kategorie D darunter")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }