var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.$appNavigation.currentMenu.label }
      }),
      _vm.layoutMetadata && _vm.loading == false
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "9580c51b-2654-47cc-93cb-921a43e820a2" }
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.layoutMetadata.header))]),
              _c("p", [_vm._v(_vm._s(_vm.layoutMetadata.head1))]),
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.layoutMetadata.text1) }
              }),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.layoutMetadata.text2) }
              }),
              _vm.layoutMetadata.text3
                ? _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.layoutMetadata.text3) }
                  })
                : _vm._e(),
              _vm.layoutMetadata.url
                ? _c("DownloadLink", {
                    attrs: {
                      asButton: "",
                      title: _vm.layoutMetadata.actionButtonLabel,
                      href: _vm.layoutMetadata.url
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm.loading
        ? _c("GhostLoading", {
            attrs: { type: "paragraph", useBoxContainer: "" }
          })
        : _c("NoData")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }