<template>
    <button class="btn-clean clickable">
        <component :is="iconComponent" :size="size"/>
        <span v-if="expanded" class="pl-1">
            <DownloadLink
                asLabel
                :title="label"
                :queryParams="queryParams"
                :filename="filename"
                :downloadServicePath="downloadServicePath"
                :href="href"/>
        </span>
    </button>
</template>
<script>
import actionMixin from './action-mixin.js';
import DownloadLink from '@/components/core/download/DownloadLink.vue'

export default {
    mixins: [actionMixin],
    props: {
        queryParams: {
            type: Object,
        },
        filename: {
            type: String,
        },
        downloadServicePath: {
            type: String,
        },
        href: {
            type: String,
        },
    },
    components: {
        DownloadLink,
    }
}
</script>
<style src='./action.scss' lang='scss' scoped/>
