<template>
    <div>
        <InputToggleSwitch v-if="erlaubeEsgSchwach"
            v-model="check_nachhaltigkeit_abweichend"
            label="Die Geeignetheitsprüfung hat ergeben, dass keine Anlagen verfügbar sind, die den ursprünglichen Nachhaltigkeitspräferenzen des Kunden entsprechen. Aus diesem Grunde hat der Kunde für die vorliegende Beratung seine Nachhaltigkeitspräferenzen angepasst." inLineLabel 
            :disabled="isDisabledProp"
            @input="updateStore('check_nachhaltigkeit_abweichend', $event)" />
        <InputRadioBoxGroup 
            title="Der Kunde hat"
            v-model="input_esg_aktiv" 
            labelClass="font-bold"
            :disabled="isDisabledProp || erlaubeEsgSchwach && (check_nachhaltigkeit_abweichend === false || check_nachhaltigkeit_abweichend === '0')"
            :validateUntouched="true"
            :values="[
                {value: '1', label: 'die folgenden Nachhaltigkeitspräferenzen'},
                {value: '0', label: 'keine Nachhaltigkeitspräferenzen'}
            ]"
            @input="updateStore('input_esg_aktiv', $event)" />
        

		<div class="mb-3" v-if="erlaubeEsgSchwach">Ausgestaltung der Nachhaltigkeitspräferenzen des Kunden (Kombinationen zwischen A, B und C sind möglich, D schließt hingegen A-C aus)</div>
        <div class="mb-3" v-if="!erlaubeEsgSchwach">Ausgestaltung der Nachhaltigkeitspräferenzen des Kunden (Kombinationen zwischen A, B und C sind möglich)</div>
        
        <div class="box__container">
            <InputToggleSwitch v-model="input_esg_taxo_aktiv" :disabled="disabled || inputSchwachAktiv"
                :config="{bold: true}" label="A Taxonomie" inLineLabel validateUntouched
                @input="updateStore('input_esg_taxo_aktiv', $event)" 
            />
            <div class="mb-3">Investitionen in ökologisch nachhaltige Tätigkeiten, die mit messbaren Bewertungskriterien einen wesentlichen Beitrag zu einem der mehreren Umweltziele/n leisten.</div> 
            <div v-if="input_esg_taxo_aktiv === true || input_esg_taxo_aktiv === '1'">   
                <InputRadioBoxGroup 
                    title="Mindestanteil"
                    v-model="input_esg_taxo_perc" 
                    :validateUntouched="true"
                    :disabled="disabled || !input_esg_taxo_aktiv || inputSchwachAktiv"
                    :values="radioValuesTaxo"
                    @input="updateStore('input_esg_taxo_perc', $event)" 
                />
                <InputField v-if="input_esg_taxo_perc === 'CUSTOM'"
                    v-model="input_esg_taxo_perc_custom"
                    type="percent"
                    isComponentHalfSize
                    :disabled="disabled || !input_esg_taxo_aktiv && input_esg_taxo_perc !== 'CUSTOM' || inputSchwachAktiv"
                    @change="updateStoreNumber('input_esg_taxo_perc_custom', $event)"
                />
                <div>Maximal erreichbarer Anteil: {{label_esg_taxo_max}}%</div>
                <div>Anzahl Wertpapiere gewählter Anteil: {{label_esg_taxo_anzahl}}</div>
            </div>
        </div>
        <div class="box__container">
            <InputToggleSwitch v-model="input_esg_sfdr_aktiv" :disabled="disabled || inputSchwachAktiv"
                :config="{bold: true}" label="B SFDR" 
                inLineLabel validateUntouched
                @input="updateStore('input_esg_sfdr_aktiv', $event)"
            />
            <div class="mb-3">Investitionen, die einen einfachen Beitrag zur Erreichung von ökologischen und/oder sozialen Nachhaltigkeitszielen leisten.</div>
            <div v-if="input_esg_sfdr_aktiv === true || input_esg_sfdr_aktiv === '1'">     
                <InputRadioBoxGroup 
                    title="Mindestanteil"
                    v-model="input_esg_sfdr_perc" 
                    :validateUntouched="true"
                    :disabled="disabled || !input_esg_sfdr_aktiv || inputSchwachAktiv"
                    :values="radioValuesSfdr"
                    @input="updateStore('input_esg_sfdr_perc', $event)" />
                <InputField v-if="input_esg_sfdr_perc === 'CUSTOM'"
                    v-model="input_esg_sfdr_perc_custom"
                    type="percent"
                    isComponentHalfSize validateUntouched
                    :disabled="disabled || !input_esg_sfdr_aktiv && input_esg_sfdr_perc !== 'CUSTOM' || inputSchwachAktiv"
                    @change="updateStoreNumber('input_esg_sfdr_perc_custom', $event)"
                />
                <div>Maximal erreichbarer Anteil: {{label_esg_sfdr_max}}%</div>
                <div>Anzahl Wertpapiere gewählter Anteil: {{label_esg_sfdr_anzahl}}</div>
            </div>
        </div>
        <div class="box__container">
            <InputToggleSwitch v-model="input_esg_pai_aktiv" isComponentHalfSize :disabled="disabled || inputSchwachAktiv"
                :config="{bold: true}" label="C PAIs" 
                inLineLabel validateUntouched
                @input="updateStore('input_esg_pai_aktiv', $event)" 
            />
            <div class="mb-3">Investitionen, bei denen zumindest die wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren berücksichtigt werden.</div>
            <div v-if="input_esg_pai_aktiv === true || input_esg_pai_aktiv === '1'"> 
                <InputToggleSwitch v-model="input_esg_pai_check_treibhaus" isComponentHalfSize :disabled="disabled || !input_esg_pai_aktiv || inputSchwachAktiv"
                    label="Treibhausgasemissionen" inLineLabel validateUntouched
                    @input="updateStore('input_esg_pai_check_treibhaus', $event)" />
                <InputToggleSwitch v-model="input_esg_pai_check_wasser" isComponentHalfSize :disabled="disabled || !input_esg_pai_aktiv || inputSchwachAktiv"
                    label="Wasserverbrauch" inLineLabel  validateUntouched
                    @input="updateStore('input_esg_pai_check_wasser', $event)" />
                <InputToggleSwitch v-model="input_esg_pai_check_biodivers" isComponentHalfSize :disabled="disabled || !input_esg_pai_aktiv || inputSchwachAktiv"
                    label="Biodiversität" inLineLabel  validateUntouched
                    @input="updateStore('input_esg_pai_check_biodivers', $event)" />
                <InputToggleSwitch v-model="input_esg_pai_check_abfall" isComponentHalfSize :disabled="disabled || !input_esg_pai_aktiv || inputSchwachAktiv"
                    label="Abfälle" inLineLabel  validateUntouched
                    @input="updateStore('input_esg_pai_check_abfall', $event)" />
                <InputToggleSwitch v-model="input_esg_pai_check_soziales" isComponentHalfSize :disabled="disabled || !input_esg_pai_aktiv || inputSchwachAktiv"
                    label="Soziales & Arbeitnehmerbelange (inkl. Menschenrechte & Korruption)" inLineLabel validateUntouched
                    @input="updateStore('input_esg_pai_check_soziales', $event)" />
                <InputRadioBoxGroup 
                    title="Mindestanteil"
                    v-model="input_esg_pai_perc" 
                    :validateUntouched="true"
                    :disabled="disabled || !input_esg_pai_aktiv || inputSchwachAktiv"
                    :values="DEFAULT_PROMILLE_ARRAY"
                    @input="updateStore('input_esg_pai_perc', $event)"  />
                <InputField v-if="input_esg_pai_perc === 'CUSTOM'"
                    v-model="input_esg_pai_perc_custom"
                    type="percent"
                    isComponentHalfSize validateUntouched
                    :disabled="disabled || !input_esg_pai_aktiv && input_esg_pai_perc !== 'CUSTOM' || inputSchwachAktiv"
                    @change="updateStoreNumber('input_esg_pai_perc_custom', $event)"
                />
            </div>
        </div>

        <div class="box__container" v-if="erlaubeEsgSchwach">
            <InputToggleSwitch v-model="input_esg_schwach_aktiv" :disabled="disabled"
                :config="{bold: true}" label="D Keine konkrete Gewichtung" inLineLabel validateUntouched
                @input="updateStore('input_esg_schwach_aktiv', $event)" 
            />
            <div class="mb-3">Keine konkrete Gewichtung in den Kategorien A, B, C. Der Kunde wünscht zumindest eine Berücksichtigung einer der drei Kategorien, macht insoweit aber keine Vorgaben.</div>
            <div class="ml-5 mb-3" v-if="input_esg_schwach_aktiv === true || input_esg_schwach_aktiv === '1'">    
                <InputRadioBoxGroup 
                    title="Mindestanteil"
                    v-model="input_esg_schwach_perc" 
                    :validateUntouched="true"
                    :disabled="disabled || !input_esg_schwach_aktiv"
                    :values="DEFAULT_PROMILLE_ARRAY"
                    @input="updateStore('input_esg_schwach_perc', $event)" />
                <InputField v-if="input_esg_schwach_perc === 'CUSTOM'"
                    v-model="input_esg_schwach_perc_custom"
                    type="currency"
                    isComponentHalfSize validateUntouched
                    :disabled="disabled || !input_esg_schwach_aktiv && input_esg_schwach_perc !== 'CUSTOM'"
                    @change="updateStoreNumber('input_esg_schwach_perc_custom', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import { formatNumber, parse } from '@/helpers/number-formatter.js'
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
    mixins: [anlegerprofilMixin],
    props: {
        values: {
            type: Object,
            default: () => {},
        },
        erlaubeEsgSchwach: {
            type: Boolean,
            default: false,
        },
        isDisabledProp: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        InputRadioBoxGroup,
        InputToggleSwitch,
        InputField,
        InputTextArea,
    },
    data() {
        return {
            check_nachhaltigkeit_abweichend: '0',
            input_esg_aktiv: '',
            input_esg_taxo_aktiv: '0',
            input_esg_taxo_perc: '',
            input_esg_taxo_perc_custom: '',
            input_esg_sfdr_aktiv: '0',
            input_esg_sfdr_perc: '',
            input_esg_sfdr_perc_custom: '',
            input_esg_pai_aktiv: '0',
            input_esg_pai_perc: '',
            input_esg_pai_perc_custom: '',
            input_esg_pai_check_treibhaus: '0',
            input_esg_pai_check_biodivers: '0',
            input_esg_pai_check_wasser: '0',
            input_esg_pai_check_abfall: '0',
            input_esg_pai_check_soziales: '0',
            input_esg_schwach_aktiv: '0',
            input_esg_schwach_perc: '',
            input_esg_schwach_perc_custom: '',
            label_esg_taxo_anzahl: '',
            label_esg_taxo_max: '',
            label_esg_sfdr_anzahl: '',
            label_esg_sfdr_max: '',

            DEFAULT_PROMILLE_ARRAY_TAXO: [{label: '2,5%', value: '2,5'}, {label: '5%', value: '5,0'}, {label: '7,5%', value: '7,5'},
                {label: '10%', value: '10,0'}, {label: '25%', value: '25,0'}, {label: '50%', value: '50,0'}, {label: '75%', value: '75,0'}, 
                {label: '100%', value: '100,0'}, {label: 'freie Eingabe', value: 'CUSTOM'}],
            DEFAULT_PROMILLE_ARRAY: [{label: '10%', value: '10,0'}, {label: '25%', value: '25,0'}, {label: '50%', value: '50,0'}, {label: '75%', value: '75,0'}, 
                {label: '100%', value: '100,0'}, {label: 'freie Eingabe', value: 'CUSTOM'}],

        }
    },
    computed: {
        disabled() {
            return this.isDisabledProp || (!this.input_esg_aktiv || this.input_esg_aktiv === '0') 
                || (this.erlaubeEsgSchwach ? (!this.check_nachhaltigkeit_abweichend || this.check_nachhaltigkeit_abweichend === '0') : false);
        },
        inputSchwachAktiv() {
            return this.erlaubeEsgSchwach && (this.input_esg_schwach_aktiv === true || this.input_esg_schwach_aktiv === '1');
        },
        radioValuesTaxo() {
            return this.erlaubeEsgSchwach && this.label_esg_taxo_max
                ? this.DEFAULT_PROMILLE_ARRAY_TAXO.filter(elem => parse(elem.value) <= parse(this.label_esg_taxo_max) || elem.value === 'CUSTOM')
                : this.DEFAULT_PROMILLE_ARRAY_TAXO;
        },
        radioValuesSfdr() {
            return this.erlaubeEsgSchwach && this.label_esg_sfdr_max
                ? this.DEFAULT_PROMILLE_ARRAY.filter(elem => parse(elem.value) <= parse(this.label_esg_sfdr_max) || elem.value === 'CUSTOM')
                : this.DEFAULT_PROMILLE_ARRAY;
        },
    },
    mounted() {
        this.patchForm();
        if (this.erlaubeEsgSchwach && this.$route.query.setCheckNachhaltigkeit) {
            this.check_nachhaltigkeit_abweichend = '1';
            this.$emit('updateStore', { componentId: 'check_nachhaltigkeit_abweichend', value: '1' })    
        }
        this.$nextTick().then(() => {
            this.updateWarnings();
        });
    },
    methods: {
        patchForm() {
            if (this.values && Object.keys(this.values).length) {
                Object.keys(this).filter(key => Object.keys(this.values).includes(key)).forEach(key => this[key] = this.values[key])
            }
        },
        updateStoreNumber(componentId, value) {
            this.$emit('updateStore', { componentId: componentId, value: formatNumber(value) })
        },
        updateStore(componentId, value) {
            if (componentId) {
                this.$emit('updateStore', { componentId: componentId, value: value });
            }
        }
    }
}
</script>

<style>

</style>