<template>
  <div>
    <PageHeaderTitleNavigation
      title="ExPost"
      :subtitle="title"
      :defaultMenu="customOptionMenu"
    />

    <template v-if="config.loading">
      <GhostLoading useBoxContainer>
        <Block height="50" />
        <Block height="50" />
        <Block height="50" />
      </GhostLoading>
    </template>
    <BoxContainer v-else-if="display === 'SETTINGS'" :title="title">
      <HinweiseUndFehler
        :hints="[
          {
            title:
              'Welche Kunden sollen den ExPost-Kostenausweis sehen / bekommen?',
            message:
              'Den ExPost-Kostenausweis erhalten ausschließlich Kunden mit<br />' +
              '1.) Honorarkosten im MSC und/oder<br />' +
              '2.) einem Depot bei einer Depotbank, welche nach unseren Informationen ' +
              'keinen eigenen ExPost-Kostenausweis erstellt hat und/oder<br />' +
              '3.) einem Depot und einer Servicegebührenvereinbarung bei einer ' +
              'Depotbank, welche diese Servicegebühr nach unseren Informationen nicht ' +
              'im eigenen ExPost-Kostenausweis ausgewiesen hat.',
          },
          {
            title: 'ExPost-Hinweis-E-Mail an Kunden senden',
            message:
              'Die oben ausgewählten Kunden erhalten mit dieser Option automatisch eine ' +
              'Hinweis-E-Mail (Standardtext von uns oder individuell von Ihnen ' +
              'hinterlegt) zur Information über das neue Dokument im Dokumentenarchiv ' +
              'sofern der Mailversand an die Kunden möglich ist. Wird der automatische ' +
              'Mailversand deaktiviert und/oder nutzen die Kunden den Kundenzugang ' +
              'nicht, so muss die Benachrichtigung und die postalische Zusendung der ' +
              'ExPost-Kostenausweise in Eigenregie vorgenommen werden.',
          },
          {
            title:
              'Der ExPost-Kostenausweis wird im Namen und mit dem Logo des aktuellen Beraters erzeugt.',
            message:
              'Die ExPost-Kostenausweise werden mit dieser Option im Namen und mit Logo des genannten Beraters erzeugt.',
          },
          {
            title: 'farbliche Hervorhebungen im ExPost-Kostenausweis verwenden',
            message:
              'Prozent- und Betragsangaben sowie Erläuterungstexte werden zur besseren Übersichtlichkeit in unterschiedlichen Farben dargestellt.',
          },
        ]"
      />
      <div class="box__title">
        Hier können Sie einstellen, welche Kunden den ExPost Kostenausweis zur
        Verfügung gestellt bekommen.
      </div>
      <InputRadioBoxGroup
        v-model="wichtige_kosten_modus"
        :values="config.values_kosten_modus"
      />
      <div class="box__title">
        Hier können Sie weitere generelle ExPost-Einstellungen vornehmen.
      </div>
      <InputToggleSwitch
        v-model="mailversand_aktiv"
        label="ExPost-Hinweis-E-Mail an Kunden senden"
        inLineLabel
      />
      <InputToggleSwitch
        v-model="ist_herausgeber"
        inLineLabel
        :label="config.label_herausgeber"
      />
      <InputToggleSwitch
        v-model="ist_farbe_aktiv"
        inLineLabel
        label="farbliche Hervorhebungen im ExPost-Kostenausweis verwenden"
      />
      <BaseButton class="mt-3 mb-2" @click="saveConfig"
        >Einstellungen für alle Kunden übernehmen</BaseButton
      >
    </BoxContainer>

    <template v-else-if="display === 'OVERVIEW'">
      <HinweiseUndFehler
        :hints="[
          {
            title: 'Die rot markierten Kunden',
            message:
              'Der Kunde kann den ExPost Auszug nicht als E-Mail erhalten (der Mailversand ist deaktiviert, es ist keine Mailadresse eingetragen, der Kunde darf das Dokumentenarchiv nichts sehen oder der Kunde ist inaktiv).',
          },
        ]"
      />

      <GenericPersonFilter
        ref="filter"
        filterId="37054866-c81e-4b03-bc13-f23912c312ce"
        :saveKey="'searchExPostKundenUebesicht'"
        :title="title"
        :metadata="filterData"
        :configFilter="configFilter"
        :predefinedFilter="predefinedFilter"
        :searchFromParam="searchFromParam"
        @search="doSearch"
      />
      <BoxContainer v-if="rows">
        <Fields
          v-if="rows.length"
          :fields="config.list_form"
          @onBtClick="serienbrief"
        />
        <Table
          class="table-container"
          ref="viewstatistik"
          v-if="rows.length"
          :headers="headers"
          :rows="rows"
          rowId="index"
          :rowsPerPage="25"
          :mobileConfig="{headers: ['key1', 'key2', 'key3']}"
          @action-PDF="getPDF"
          @click-key0="loadCustomer"
        />
        <NoData v-else />
      </BoxContainer>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import BoxContainer from "@/components/core/BoxContainer.vue";
import EXPOST_TYPES from "@/store/expost/types";
import CORE_TYPES from "@/store/core/types";
import Fields from "@/components/begleitscheine/Fields.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import Block from "@/components/core/loading/ghost-loading/Block.vue";
import Table from "@/components/table2/Table.vue";
import NoData from "@/components/core/NoData.vue";
import {
  TextColumn,
  ActionColumn,
  PillColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import { PhFilePdf } from "phosphor-vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import GenericPersonFilter from "@/components/core/GenericPersonFilter.vue";
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";

export default {
  name: "ExPost",
  components: {
    PageHeaderTitleNavigation,
    BoxContainer,
    OptionMenu,
    InputToggleSwitch,
    GenericPersonFilter,
    HinweiseUndFehler,
    Fields,
    InputRadioBoxGroup,
    BaseButton,
    GhostLoading,
    Block,
    Table,
    NoData,
  },
  data: function () {
    return {
      display: "",
      title: "",
      wichtige_kosten_modus: "",
      mailversand_aktiv: false,
      ist_herausgeber: false,
      ist_farbe_aktiv: false,
      values: {},
      combos: {},
      filterData: [
        {
          key: "allgemein",
          label: "Allgemein",
          type: "group",
          menuItems: [],
        },
      ],
      primarySelection: [
        {
          value: "allgemein",
          label: "Allgemein",
          type: "group",
        },
      ],
      configFilter: {
        placeholderForDefSearchInput: "Kontonummer (Kundennummer), Kundenname",
        hideFirstColumn: true,
        filterZurucksetzen: () => {
          this.rows = null;
        },
        programmaticSearchParameter: [],
        filterType: "kundenuebersicht",
        checkDefaultSearchInput: false,
      },
      predefinedFilter: {},
      searchFromParam: "",
      showFields: 0,
      headers: {},
      rows: null,
      defaultSearchInputKeys: ["", ""],
      actionList: "",
    };
  },
  mounted() {
    if (this.config.loading) {
      this.$store.dispatch(EXPOST_TYPES.ACTIONS.LOAD_CONFIG);
    } else {
      this.onChangeConfig();
    }
    this.select("SETTINGS");
  },
  computed: {
    ...mapGetters({
      config: EXPOST_TYPES.GETTERS.GET_CONFIG,
      listData: EXPOST_TYPES.GETTERS.GET_LIST,
    }),
    customOptionMenu() {
      return [
        {
          label: "Einstellungen",
          textBold: this.display === "SETTINGS",
          action: () => {
            this.select("SETTINGS");
          },
        },
        {
          label: "Kundenübersicht",
          textBold: this.display === "OVERVIEW",
          action: () => {
            this.select("OVERVIEW");
          },
        },
      ];
    },
  },
  watch: {
    config: "onChangeConfig",
    listData: "onChangeList",
  },
  methods: {
    select(display) {
      this.display = display;
      this.title =
        this.display === "SETTINGS" ? "Einstellungen" : "Kundenübersicht";
      this.$updateCurrentBreadcrumb({
        breadcrumb: "ExPost " + this.title,
      });
      if (this.display === "OVERVIEW") {
        setTimeout(() => {
          this.addFilter();
        }, 0);
      }
    },
    onChangeConfig() {
      this.wichtige_kosten_modus = this.config.wichtige_kosten_modus;
      this.mailversand_aktiv = this.config.mailversand_aktiv;
      this.ist_herausgeber = this.config.ist_herausgeber;
      this.ist_farbe_aktiv = this.config.ist_farbe_aktiv;

      this.values = this.config.filter_value;

      this.filterData[0].menuItems = [];
      this.config.filter_form.forEach((filter) => {
        if (filter.dataType === "combo") {
          this.filterData[0].menuItems.push({
            type: "combobox",
            label: filter.label,
            key: filter.key,
            comboOptions: filter.values,
          });
        } else if (filter.dataType === "radio") {
          this.filterData[0].menuItems.push({
            type: "combobox",
            label: filter.label,
            key: filter.key,
            comboOptions: filter.values.filter((v) => v.value),
          });
        } else if (filter.dataType === "text") {
          if (
            "Kontonummer" === filter.label ||
            filter.key === "filter_kunde_nummer"
          ) {
            this.defaultSearchInputKeys[0] = filter.key;
          }
          if (
            "Kunde Name" === filter.label ||
            filter.key === "filter_kunde_name"
          ) {
            filter.label = "Kundenname";
            this.defaultSearchInputKeys[1] = filter.key;
          }
          this.filterData[0].menuItems.push({
            type: "text",
            label: filter.label,
            key: filter.key,
          });
        } else if (filter.dataType === "buttonP") {
          this.actionList = filter.action;
        }
      });
    },
    addFilter() {
      const baseFilter = this.getBaseFilter();
      if (baseFilter && !baseFilter.$data.showMoreOptions) {
        Object.keys(this.values).forEach((key) => {
          this.addFilterRow(baseFilter, key, this.values[key]);
        });
        baseFilter.$data.showMoreOptions = true;
      }
    },
    addFilterRow(baseFilter, key, value) {
      if (value) {
        const filter = this.filterData[0].menuItems.find((f) => f.key === key);
        baseFilter.addRow({
          key: "allgemein",
          primarySelection: this.primarySelection,
          type: "group",
          disabled: false,
          secondaryKey: key,
          [key]: value,
          value: value,
          comboOptions: filter.comboOptions,
        });
      }
    },
    getBaseFilter() {
      if (this.$refs.filter) {
        for (
          let j = 0;
          this.$refs.filter.$children && j < this.$refs.filter.$children.length;
          j++
        ) {
          if (this.$refs.filter.$children[j].filterZurucksetzen) {
            return this.$refs.filter.$children[j];
          }
        }
      }
      return null;
    },
    saveConfig() {
      this.$store.dispatch(EXPOST_TYPES.ACTIONS.SAVE_CONFIG, {
        wichtige_kosten_modus: this.wichtige_kosten_modus,
        mailversand_aktiv: this.mailversand_aktiv,
        ist_herausgeber: this.ist_herausgeber,
        ist_farbe_aktiv: this.ist_farbe_aktiv,
      });
    },
    doSearch(arg) {
      const baseFilter = this.getBaseFilter();
      if (arg.defaultSearchInput) {
        if (/^\d*$/.test(arg.defaultSearchInput)) {
          arg[this.defaultSearchInputKeys[0]] = arg.defaultSearchInput;
          if (baseFilter) {
            this.addFilterRow(
              baseFilter,
              this.defaultSearchInputKeys[0],
              arg.defaultSearchInput
            );
            baseFilter.$data.defaultSearchInput = "";
          }
        } else {
          arg[this.defaultSearchInputKeys[1]] = arg.defaultSearchInput;
          if (baseFilter) {
            this.addFilterRow(
              baseFilter,
              this.defaultSearchInputKeys[1],
              arg.defaultSearchInput
            );
            baseFilter.$data.defaultSearchInput = "";
          }
        }
        delete arg.defaultSearchInput;
      }
      const param = {
        ...this.values,
        ...arg,
        internal_attribute_action: this.actionList,
      };
      this.$store.dispatch(EXPOST_TYPES.ACTIONS.LOAD_LIST, param);
    },
    serienbrief(arg) {
      const param = { ...this.values, internal_attribute_action: arg.action };
      this.$store.dispatch(EXPOST_TYPES.ACTIONS.GET_PDF, param);
    },
    loadCustomer(row) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
        customerId: row.key0,
      });
    },
    getPDF(row) {
      this.$store.dispatch(EXPOST_TYPES.ACTIONS.GET_PDF, row.pdf);
    },
    onChangeList() {
      if (!this.listData.table) {
        return;
      }
      const lockedLeft = [];
      const center = [];
      let buttons = 0,
        key = "";
      this.listData.table.headers.forEach((col, k) => {
        if (col.label === " ") {
          buttons++;
        } else {
          key = buttons ? "key" + (k - buttons) : "key" + k;
          if (lockedLeft.length === 0) {
            const colLeft = TextColumn(key, col.label, 200, 0)
              .makeLink()
              .makeAlwaysVisible();
            lockedLeft.push(colLeft);
          } else if (center.length === 0) {
            center.push(PillColumn(key, col.label));
          } else {
            center.push(TextColumn(key, col.label));
          }
        }
      });
      this.headers = {
        lockedLeft: lockedLeft,
        center: center,
        lockedRight: [ActionColumn("actions", null)],
      };
      const rows = [];
      let data;
      this.listData.table.records.forEach((row, k) => {
        data = { index: "r" + k };
        row.forEach((r, j) => {
          if (buttons) {
            if (j < buttons) {
              if (j === 0 && r?.load && Object.keys.length) {
                data.actions = [SimpleAction("PDF", PhFilePdf, "PDF")];
                data.pdf = r.load;
              }
            } else {
              data["key" + (j - buttons)] = r?.value;
            }
          } else {
            data["key" + j] = r?.value;
          }
        });
        if (-1 !== data.key0.indexOf("[")) {
          data.key0 = data.key0.replace(/\[.+?\]/g, "");
          data.key1 = {
            label: data.key1.replace(/\[.+?\]/g, ""),
            type: "danger",
          };
        } else {
          data.key1 = { label: data.key1, type: "black" };
        }
        rows.push(data);
      });
      this.rows = rows;
    },
  },
};
</script>

<style scoped>
.hinweis {
  font-size: 90%;
  margin-top: 14px;
}
</style>