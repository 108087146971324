export const MUTATION_PREFIX = 'FONDSVERGLEICH_MUTATION_: ';
export const ACTIONS_PREFIX = 'FONDSVERGLEICH_ACTIONS_';
export const GETTERS_PREFIX = 'FONDSVERGLEICH_GETTERS_';
import dayjs from 'dayjs';

export function resetSession(settings, fvp) {
  const now = new Date();
  const datum = dayjs(now).format('DD.MM.YYYY');
  return {
    settings: settings,
    hasVergleich: false,
    stepKey: 'FILTER', 
    substepKey: '',
    fvp: {
      id: fvp?.id || null,
      description: fvp?.description || 'Unbenannt',
      create: fvp?.create || datum,
      params: {
        RisikoJahreValue: settings?.RisikoJahreValue,
        WertEntwZeitArt: settings?.WertEntwZeitArt,
        WertEntwJahreValue: settings?.WertEntwJahreValue,
        zeitraum_bis: datum,
        zeitraum_von: dayjs(new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())).format('DD.MM.YYYY'),
        cbSteuern: false,
        cbAgio: false,
        cbRenditePA: false,
        cbAnpassen: false,
        wentwChartVal: 1,
        indices: [],
        funds: [],
      }
    },
    fundsCompare: [],
    fundsUnselect: [],
    fundsdata: {},      
    wertentw: {
      chartSerie: [],
      indicesSeries: {}
    },
    renditeRisiko: [],
    zoom: {start: 0, end: 100},
    ergebnisLoading: {
      fondsDaten: false,
      rendRisiko: false,
      wertentwicklung: false,
    },
  };
}

export default {
  GETTERS: {
    ACT: GETTERS_PREFIX + 'ACT',
    SETTINGS: GETTERS_PREFIX + 'SETTINGS',
    GET_FUNDS: GETTERS_PREFIX + 'GET_FUNDS',
    GET_UNSEL_FUNDS: GETTERS_PREFIX + 'GET_UNSEL_FUNDS',
    GET_START_END_ZOOM: GETTERS_PREFIX + 'GET_START_END_ZOOM',
    GET_FILTER: GETTERS_PREFIX + 'GET_FILTER',
  },
  MUTATIONS: {
    SETTINGS: MUTATION_PREFIX + 'SETTINGS',
    RESET_ACT: MUTATION_PREFIX + 'RESET_ACT',
    SET_ACT: MUTATION_PREFIX + 'SET_ACT',
    SET_FVP: MUTATION_PREFIX + 'SET_FVP',
    SET_FUNDS: MUTATION_PREFIX + 'SET_FUNDS',
    SET_UNSEL_FUNDS: MUTATION_PREFIX + 'SET_UNSEL_FUNDS',
    SET_START_END_ZOOM: MUTATION_PREFIX + 'SET_START_END_ZOOM',
    SET_FILTER: MUTATION_PREFIX + 'SET_FILTER',
  },
  ACTIONS: {
    TOGGLE_AVAILABLE_TO_CUSTOMER: ACTIONS_PREFIX + 'TOGGLE_AVAILABLE_TO_CUSTOMER',
    SETTINGS: ACTIONS_PREFIX + 'SETTINGS',
    FUNDSDATA: ACTIONS_PREFIX + 'FUNDSDATA',
    WERTENTW: ACTIONS_PREFIX + 'WERTENTW',
    RENDRISIKO: ACTIONS_PREFIX + 'RENDRISIKO',
    EINSTELL: ACTIONS_PREFIX + 'EINSTELL',
    PDF: ACTIONS_PREFIX + 'PDF',
    XLS: ACTIONS_PREFIX + 'XLS',
    GET_FONDS: ACTIONS_PREFIX + 'GET_FONDS',
    GET_DEPOT: ACTIONS_PREFIX + 'GET_DEPOT',

    FVPREAD: ACTIONS_PREFIX + 'FVPREAD',
    FVPSAVE: ACTIONS_PREFIX + 'FVPSAVE',
    FVPDEL: ACTIONS_PREFIX + 'FVPDEL',
    FVPSAVEDESC: ACTIONS_PREFIX + 'FVPSAVEDESC',
    FVPCOPY: ACTIONS_PREFIX + 'FVPCOPY',
    FVPSAVEORDER: ACTIONS_PREFIX + 'FVPSAVEORDER',
    FVPNEW: ACTIONS_PREFIX + 'FVPNEW',
  },
}

