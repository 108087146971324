import VERTRAG_STATUS_TYPES from './types';
import { getInitialState } from './index'

export default {
  [VERTRAG_STATUS_TYPES.MUTATIONS.VERTRAG_STATUS_LIST_SUCCESS](state, payload) {
    state.vertragsStatusList = payload;
  },
  [VERTRAG_STATUS_TYPES.MUTATIONS.VERTRAG_STATUS_SUCCESS](state, payload) {
    state.vertragStatus = payload;
  },
  [VERTRAG_STATUS_TYPES.MUTATIONS.DELETE_VERTRAG_STATUS](state, statusId) {
    const index = state.vertragsStatusList?.statusList?.findIndex(status => status.id === statusId);
    if (index > -1) {
      state.vertragsStatusList?.statusList?.splice(index, 1);
    }
  },
  [VERTRAG_STATUS_TYPES.MUTATIONS.SAVE_VERTRAG_STATUS_SUCCESS](state, response) {
    if(response?.id){
      state.vertragsStatusList?.statusList.unshift(response)
    }
  },
  [VERTRAG_STATUS_TYPES.MUTATIONS.UPDATE_VERTRAG_STATUS_SUCCESS](state, response) {
    if(response.id){
      const index = state.vertragsStatusList?.statusList?.findIndex(status => status.id === response.id);
      if (index > -1) {
        state.vertragsStatusList?.statusList?.splice(index, 1, response);
      }
    }
  }, 
  [VERTRAG_STATUS_TYPES.MUTATIONS.RESET_DATA](state, payload) {
    Object.assign(state, getInitialState())
  },

}
