var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          actions: _vm.actions
        },
        on: {
          "action-EMAIL": function($event) {
            return _vm.sendAsEmailOrFax("email")
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("ComboBox", {
            attrs: {
              label: "Versicherung",
              values: _vm.fieldsDefinition.versicherungStatus.value,
              disabled: _vm.fieldsDefinition.versicherungStatus.disabled,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.vertragsspiegel.versicherungStatus,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "versicherungStatus", $$v)
              },
              expression: "vertragsspiegel.versicherungStatus"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Betrieblich",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.betrieblich.disabled
            },
            model: {
              value: _vm.vertragsspiegel.betrieblich,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "betrieblich", $$v)
              },
              expression: "vertragsspiegel.betrieblich"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Gesundheit",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.gesundheit.disabled
            },
            model: {
              value: _vm.vertragsspiegel.gesundheit,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "gesundheit", $$v)
              },
              expression: "vertragsspiegel.gesundheit"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Sachversicherungen",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.sachversicherungen.disabled
            },
            model: {
              value: _vm.vertragsspiegel.sachversicherungen,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "sachversicherungen", $$v)
              },
              expression: "vertragsspiegel.sachversicherungen"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Sonstige",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.sonstige.disabled
            },
            model: {
              value: _vm.vertragsspiegel.sonstige,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "sonstige", $$v)
              },
              expression: "vertragsspiegel.sonstige"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Vorsorge",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.vorsorge.disabled
            },
            model: {
              value: _vm.vertragsspiegel.vorsorge,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "vorsorge", $$v)
              },
              expression: "vertragsspiegel.vorsorge"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Wertpapiere",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.wertpapier.disabled
            },
            model: {
              value: _vm.vertragsspiegel.wertpapier,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "wertpapier", $$v)
              },
              expression: "vertragsspiegel.wertpapier"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Sonstige Produkte",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.sonstigeProdukte.disabled
            },
            model: {
              value: _vm.vertragsspiegel.sonstigeProdukte,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "sonstigeProdukte", $$v)
              },
              expression: "vertragsspiegel.sonstigeProdukte"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Bankkonten",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.bankkonten.disabled
            },
            model: {
              value: _vm.vertragsspiegel.bankkonten,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "bankkonten", $$v)
              },
              expression: "vertragsspiegel.bankkonten"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Weitere Vermögenswerte",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.verschiedene.disabled
            },
            model: {
              value: _vm.vertragsspiegel.verschiedene,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "verschiedene", $$v)
              },
              expression: "vertragsspiegel.verschiedene"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Kredite/Darlehen",
              values: _vm.yesNoValues,
              disabled: _vm.fieldsDefinition.kredite.disabled
            },
            model: {
              value: _vm.vertragsspiegel.kredite,
              callback: function($$v) {
                _vm.$set(_vm.vertragsspiegel, "kredite", $$v)
              },
              expression: "vertragsspiegel.kredite"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }