var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isEmpty
        ? _c("div", { staticClass: "box__container" }, [
            _vm._v("Keine zusatzliche Daten erforderlich")
          ])
        : _c("div", [
            _vm.components["main"]
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Allgemein")
                    ]),
                    _c("ComponentList", {
                      attrs: {
                        components: _vm.components["main"],
                        data: _vm.positionData,
                        comboboxValues: _vm.comboboxValues,
                        warnings: _vm.warnings,
                        disabled: _vm.readOnly
                      },
                      on: {
                        change: function($event) {
                          return _vm.change($event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.components["einmaligKauf"]
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "box__title" }, [_vm._v("Kauf")]),
                    _c("ComponentList", {
                      attrs: {
                        components: _vm.components["einmaligKauf"],
                        data: _vm.positionData,
                        comboboxValues: _vm.comboboxValues,
                        warnings: _vm.warnings,
                        disabled: _vm.readOnly
                      },
                      on: {
                        change: function($event) {
                          return _vm.change($event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.components["einmaligVerkauf"]
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Verkauf")
                    ]),
                    _c("ComponentList", {
                      attrs: {
                        components: _vm.components["einmaligVerkauf"],
                        data: _vm.positionData,
                        comboboxValues: _vm.comboboxValues,
                        warnings: _vm.warnings,
                        disabled: _vm.readOnly
                      },
                      on: {
                        change: function($event) {
                          return _vm.change($event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.components["einmaligTausch"]
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Umschichtung")
                    ]),
                    _c("ComponentList", {
                      attrs: {
                        components: _vm.components["einmaligTausch"],
                        data: _vm.positionData,
                        comboboxValues: _vm.comboboxValues,
                        warnings: _vm.warnings,
                        disabled: _vm.readOnly
                      },
                      on: {
                        change: function($event) {
                          return _vm.change($event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.components["periodisch"]
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Sparplan / Entnahmeplan")
                    ]),
                    _c("ComponentList", {
                      attrs: {
                        components: _vm.components["periodisch"],
                        data: _vm.positionData,
                        comboboxValues: _vm.comboboxValues,
                        warnings: _vm.warnings,
                        disabled: _vm.readOnly
                      },
                      on: {
                        change: function($event) {
                          return _vm.change($event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
      _c("BaseModal", { ref: "showEmptyDepotModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }