<template>
  <div>
    <PageHeaderTitleNavigation :title="title" />

    <div v-if="marketingCampaign">
      <div class="box__container">
        <div
          v-html="sanitize(marketingCampaign.body)">
        </div>
      </div>

      <div class="box__container">
        <div>
          <div class="layout__negative-margin--8">
            <BaseButton 
              disabled  
              isSecondary>
              Abbrechen
            </BaseButton>
            
            <BaseButton 
              disabled>
              Bestätigen
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BaseButton from '@/components/core/BaseButton.vue';
import { sanitize } from '@/helpers/string-helper.js';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  components: {
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data() {
    return {
      marketingCampaign: null,
    }
  },
  computed: {
    title() {
      return `Marketing Kampagne` + (this.marketingCampaign?.title ? ' | ' + this.marketingCampaign.title : '');
    }
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    
  },
  mounted() {
    this.marketingCampaign = this.$route.params.marketing
  },
}
</script>
