var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: { modalTitle: "Honorar löschen", showDefaultButtons: false },
      on: {
        onCloseButton: function($event) {
          return _vm.emitCancelled()
        },
        onEscButton: function($event) {
          return _vm.emitCancelled()
        }
      }
    },
    [
      _c("p", [
        _vm._v(
          "Das Honorar/Serviceleistung wird gelöscht! Wollen Sie das Datum der nächsten zu generierenden Rechnung zurücksetzen?"
        )
      ]),
      _c("p", [
        _vm._v(
          "Damit würden dieses und eventuell folgende Honorare beim nächsten Generierungslauf neu erstellt."
        )
      ]),
      _c(
        "BaseButton",
        {
          on: {
            click: function($event) {
              _vm.emitConfirmed(true)
              _vm.close()
            }
          }
        },
        [_vm._v("Datum zurücksetzen")]
      ),
      _c(
        "BaseButton",
        {
          on: {
            click: function($event) {
              _vm.emitConfirmed(false)
              _vm.close()
            }
          }
        },
        [_vm._v("Datum nicht zurücksetzen")]
      ),
      _c(
        "BaseButton",
        {
          staticClass: "mr-3",
          attrs: { isSecondary: "" },
          on: {
            click: function($event) {
              _vm.emitCancelled()
              _vm.close()
            }
          }
        },
        [_vm._v("Abbrechen")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }