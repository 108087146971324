var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.stornoArts
        ? _c("BaseFilter", {
            ref: "filter",
            attrs: {
              filterId: "stornoFilter",
              immidiateSearch: !_vm.$route.query.backAction,
              hasSmartSearch: "",
              showSaveButton: "",
              title: "Filter",
              metadata: _vm.searchMenu,
              configFilter: _vm.configFilter,
              defaultOptions: _vm.defaultOptions,
              extraButton: _vm.isButtonVisible
                ? {
                    buttonText: "Markierte Positionen bestätigen",
                    isExtraButtonPrimary: true
                  }
                : null
            },
            on: {
              onFilter: _vm.handleSearch,
              extraButtonClick: _vm.confirmSelection
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }