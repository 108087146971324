export const MUTATION_PREFIX = 'SSO_MUTATIONS_';
export const ACTIONS_PREFIX = 'SSO_ACTIONS_';
export const GETTERS_PREFIX = 'SSO_GETTERS_';

export default {
    MUTATIONS: {
        LOGOUT: MUTATION_PREFIX + 'LOGOUT',
        LOGIN: MUTATION_PREFIX + 'LOGIN',
        GET_SSO_ACCOUNT_INFO: MUTATION_PREFIX + 'GET_SSO_ACCOUNT_INFO',
        GET_FK_ACCOUNT_INFO: MUTATION_PREFIX + 'GET_FK_ACCOUNT_INFO',
        ACTIVATE_ACCOUNT: MUTATION_PREFIX + 'ACTIVATE_ACCOUNT',
        UPDATE_PERMISSION_SUCCESS: MUTATION_PREFIX + 'UPDATE_PERMISSION_SUCCESS',
        UPDATE_SHOW_SSO_CUSTOMER_OVERVIEW: MUTATION_PREFIX + 'UPDATE_SHOW_SSO_CUSTOMER_OVERVIEW',
    },
    ACTIONS: {
        INVITE_TO_SSO: ACTIONS_PREFIX + 'INVITE_TO_SSO',
        GET_SSO_ACCOUNT_INFO: ACTIONS_PREFIX + 'GET_SSO_ACCOUNT_INFO',
        GET_FK_ACCOUNT_INFO: ACTIONS_PREFIX + 'GET_FK_ACCOUNT_INFO',
        REQUEST_SMS_CODE: ACTIONS_PREFIX + 'REQUEST_SMS_CODE',
        REQUEST_EMAIL_CODE: ACTIONS_PREFIX + 'REQUEST_EMAIL_CODE',
        ACTIVATE_ACCOUNT: ACTIONS_PREFIX + 'ACTIVATE_ACCOUNT',
        REGISTER: ACTIONS_PREFIX + 'REGISTER',
        LOGOUT: ACTIONS_PREFIX + 'LOGOUT',
        SET_DEFAULT: ACTIONS_PREFIX + 'SET_DEFAULT',
        LOGIN_FK: ACTIONS_PREFIX + 'LOGIN_FK',
        CREATE_INVITE: ACTIONS_PREFIX + 'CREATE_INVITE',
        ACCEPT_INVITE: ACTIONS_PREFIX + 'ACCEPT_INVITE',
        DELETE_INVITE_SSO: ACTIONS_PREFIX + 'DELETE_INVITE_SSO',
        DELETE_INVITE_FK: ACTIONS_PREFIX + 'DELETE_INVITE_FK',
        DELETE_ACCESS_SSO: ACTIONS_PREFIX + 'DELETE_ACCESS_SSO',
        DELETE_ACCESS_FK: ACTIONS_PREFIX + 'DELETE_ACCESS_FK',
        CHANGE_LOGIN_NAME: ACTIONS_PREFIX + 'CHANGE_LOGIN_NAME',
        CHANGE_PASSWORD: ACTIONS_PREFIX + 'CHANGE_PASSWORD',
        CHANGE_EMAIL: ACTIONS_PREFIX + 'CHANGE_EMAIL',
        CHANGE_PHONE_NUMBER: ACTIONS_PREFIX + 'CHANGE_PHONE_NUMBER',
        UPDATE_PERMISSION: ACTIONS_PREFIX + 'UPDATE_PERMISSION',
        UPDATE_SHOW_SSO_CUSTOMER_OVERVIEW: ACTIONS_PREFIX + 'UPDATE_SHOW_SSO_CUSTOMER_OVERVIEW',
    },
    GETTERS: {
        LOGIN_NAME: GETTERS_PREFIX + 'LOGIN_NAME',
        PHONE_NUMBER: GETTERS_PREFIX + 'PHONE_NUMBER',
        EMAIL: GETTERS_PREFIX + 'EMAIL',
        IS_ACTIVATED: GETTERS_PREFIX + 'IS_ACTIVATED',
        FK_ACCOUNTS: GETTERS_PREFIX + 'FK_ACCOUNTS',
        SSO_INVITATIONS: GETTERS_PREFIX + 'SSO_INVITATIONS',
        SSO_ACCOUNTS_ALL: GETTERS_PREFIX + 'SSO_ACCOUNTS_ALL',
        SSO_ACCOUNTS: GETTERS_PREFIX + 'SSO_ACCOUNTS',
        GET_SHOW_SSO_CUSTOMER_OVERVIEW: GETTERS_PREFIX + 'GET_SHOW_SSO_CUSTOMER_OVERVIEW',
        INVITATIONS: GETTERS_PREFIX + 'INVITATIONS',
        REFERRER: GETTERS_PREFIX + 'REFERRER',
    }
}