<template>
    <div>
        <BaseModal 
            ref="addSparteModal"
            modalTitle="Neue Sparte anlegen"
            labelButtonConfirm="Sparte hinzufügen"
            @onConfirmButton="addNewSparte()"
            @close="close()">        

                <InputField
                    label="Neue Sparte"
                    v-model="beschreibung"
                    disabled
                />

                <ComboBox
                    label="Sparte"
                    v-model="sparteId"
                    :values="sparteValues"
                />
            
        </BaseModal>                
          
    </div>
</template>

<script>

import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import BaseModal from '@/components/core/BaseModal.vue'

export default {
    components: {
        ComboBox,
        InputField,
        BaseModal,
    },
    data() {
        return {
            sparteId: null,
            beschreibung: null,
            vertragId: null,
        }
    },
    mounted() {
        
    },
    computed: {
        ...mapGetters({

            isFA: CORE_TYPES.GETTERS.IS_FA,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
            sparten: VERSICHERUNG_TYPES.GETTERS.GET_SPARTEN,
        }),
        sparteValues() {
            return this.sparten?.rows?.map(sp => ({value: sp.key, label: sp.value})) || [];
        }
    },
    methods: {
        open(vertragId) {
            this.vertragId = vertragId;

            this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_SPARTEN, {vertragId }).then(response => {
                this.beschreibung = response?.beschreibung;
                this.$refs.addSparteModal.open()
            });
        },
        addNewSparte() {
            this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.ADD_SPARTE, {vertragId: this.vertragId, sparteId: this.sparteId }).then(() => {
                this.retrievetAllData();
               });            
        },
        close() {
            this.$emit('close')
        },
        async retrievetAllData() {
            return await Promise.all([
            this.retrieveInsuranceDetails(),
            this.retrieveDepotPositions(),
            ]);
        },
        retrieveInsuranceDetails() {
        return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS, {
            versVertrag: this.$route.params.id,
        });
        },
        retrieveDepotPositions() {
        return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_DEPOT_POSITIONS, {
            vertragsNr: this.$route.params.id,
        });
        },

    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({
            label: 'zurück zu Neue Sparte anlegen', 
            to,
            from,
        });
        next()
    }
}
</script>

<style>

</style>