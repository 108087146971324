export const MUTATION_PREFIX = 'BIPRO_CATEGORY_MUTATIONS_';
export const ACTIONS_PREFIX = 'BIPRO_CATEGORY_ACTIONS_';
export const GETTERS_PREFIX = 'BIPRO_CATEGORY_GETTERS_';

export default {
    MUTATIONS: {
        RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
        RETRIEVE_BIPRO_CATEGORIES_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_BIPRO_CATEGORIES_SUCCESS',
        SELECT_ROW: MUTATION_PREFIX + 'SELECT_ROW',
    },
    ACTIONS: {
        RETRIEVE_BIPRO_CATEGORIES: ACTIONS_PREFIX + 'RETRIEVE_BIPRO_CATEGORIES',
        SAVE_BIPRO_CATEGORY: ACTIONS_PREFIX + 'SAVE_BIPRO_CATEGORY',
        UPDATE_BIPRO_CATEGORY: ACTIONS_PREFIX + 'UPDATE_BIPRO_CATEGORY',
        DELETE_BIPRO_CATEGORY: ACTIONS_PREFIX + 'DELETE_BIPRO_CATEGORY',
    },
    GETTERS: {
        BIPRO_CATEGORIES: GETTERS_PREFIX + 'BIPRO_CATEGORIES',
        SELECTED_ROW: GETTERS_PREFIX + 'SELECTED_ROW',
    }
}
