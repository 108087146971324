import DASHBOARD_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue'

export default {
  [DASHBOARD_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [DASHBOARD_TYPES.MUTATIONS.SAVE_DASHBOARD](state) {
    state.template = {};
  },
  [DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS_RESET](state) {
    state.charts = getInitialState().charts;
  },
  [DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS](state, payload) {
    if (payload && payload.isValue) {
      state.charts = {
        ...state.charts,
        [payload.name]: payload.data
      }
    } else {
    state.charts.data = state.charts.data.map(chart => payload && payload.name === chart.name ? 
      {...chart, ...payload, isLoaded: true} : chart);
    }
    // state.charts = {...state.charts, ...payload, ...(payload && Object.keys(payload) ? {isLoaded: true} : {isLoaded: false})};
  },
  [DASHBOARD_TYPES.MUTATIONS.MARK_CARD_SUCCESS](state, chartId) {
    const chart = state.charts.data.find(chart => chart.id === chartId);
    if (chart) chart.isLoaded = true;
  },
  [DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CHARTS_STRUCTURE](state, payload) {
    state.isStructure = payload;
  },
  [DASHBOARD_TYPES.MUTATIONS.GET_DASHBOARD_CONFIGURATION_SUCCESS](state, payload) {
    Vue.set(state, 'configuration', payload);
  },
  [DASHBOARD_TYPES.MUTATIONS.GET_STECKBRIEF_CONFIGURATION_SUCCESS](state, payload) {
    Vue.set(state, 'steckbriefConfiguration', payload);
  },
  [DASHBOARD_TYPES.MUTATIONS.START_CALCULATION_DASHBOARD_CHARTS](state, payload) {
    state.isPrecalculated = payload;
  },
  [DASHBOARD_TYPES.MUTATIONS.GET_BROKER_OVERVIEW_CONFIGURATION_SUCCESS](state, payload) {
    Vue.set(state, 'brokerOverviewConfiguration', { ...payload });
  },
  [DASHBOARD_TYPES.MUTATIONS.GET_INTERN_OVERVIEW_CONFIGURATION_SUCCESS](state, payload) {
    Vue.set(state, 'internOverviewConfiguration', { ...payload });
  },
  [DASHBOARD_TYPES.MUTATIONS.SET_ENABLED_CARDS](state, payload) {
    state.enabledMaklerCards = payload;
  },
  [DASHBOARD_TYPES.MUTATIONS.GET_BROKER_PARAGRAPHS_CONFIGURATION_SUCCESS](state, payload) {
    Vue.set(state, 'brokerParagraphsConfiguration', { ...payload });
  },


  
}
