var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showIOSLink || _vm.showAndroidLink
    ? _c(
        "div",
        { staticClass: "download-app-info__container" },
        [
          _vm.hasErrors
            ? _vm._l(_vm.sanitizedErrors, function(err) {
                return _c("div", { key: err, staticClass: "mb-2" }, [
                  _vm._v(" " + _vm._s(err) + " ")
                ])
              })
            : _vm._e(),
          _vm.hasAppName
            ? _c("div", { staticClass: "mb-0" }, [
                _c("u", [_vm._v(_vm._s(_vm.appName) + " installieren")])
              ])
            : _vm._e(),
          _c("div", { staticClass: "app-links__container" }, [
            _vm.showIOSLink
              ? _c(
                  "div",
                  { staticClass: "app-link__container" },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.urlIOS,
                          title: "Download über App Store (iOS)",
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "app-button",
                          attrs: { src: "img/app-store-button.svg" }
                        })
                      ]
                    ),
                    _vm.showQRCode
                      ? _c("QRCode", {
                          staticClass: "hide-on-small-screen",
                          attrs: { text: _vm.urlIOS }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.showAndroidLink
              ? _c(
                  "div",
                  { staticClass: "app-link__container" },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.urlAndroid,
                          title: "Download über Google Play Store",
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "app-button",
                          attrs: { src: "img/play-store-button.png" }
                        })
                      ]
                    ),
                    _vm.showQRCode
                      ? _c("QRCode", {
                          staticClass: "hide-on-small-screen",
                          attrs: { text: _vm.urlAndroid }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }