<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :title="$appNavigation.currentMenu.label" :id="$appNavigation.currentOptionMenuId"
      :actions="headerActions" v-on="$listeners" @action-SAVE="saveShortcut()" @action-REFRESH="onRefresh()" />

    <!--<BaseModal
      ref="addEditModal"
      modalTitle="Shortcut"
      :autoClose="false"
      @onConfirmButton="submit()"
      @close="close()"
    >-->

    <div>
      <InputField label="Shortcut" v-model="shortcut.key" @input="changeShortcut(shortcut, $event, 'key')"
        :maxLength="1000" />

      <InputTextArea label="Beschreibung" v-model="shortcut.description" :maxlength="1000">
      </InputTextArea>

      <InputToggleSwitch label="Vermittler Freigabe" v-model="shortcut.forBroker"
        @input="changeShortcut(shortcut, $event, 'forBroker')" />

      <HtmlEditor v-model="shortcut.value" isFontParams isPreviewButton suppressValidationMessage startEditorFocused
        validateUntouched class="text-input" :textPlaceholderOptions="placeholdersOptions" :previewHTML="shortcut.value"
        @input="changeShortcut(shortcut, $event, 'value')" @onTogglePreviewEnabled="handlePreview"
        @isEditorValid="setEditorValid" @editor="initEditor" @cursorPosition="updateCursorPosition" />

    </div>
    <!--</BaseModal>-->
  </div>
</template>

<script>
import TEMPLATES_EDIT_TYPES from "@/store/templatesEdit/types";
import BaseModal from "@/components/core/BaseModal.vue";
import { mapGetters } from "vuex";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import InputField from "@/components/core/forms/InputField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import HtmlEditor from "@/components/html-editor/HtmlEditor.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";
import axios from "axios";

export function newShortcut() {
  return {
    key: "",
    value: "",
    forBroker: true,
    isNew: true,
  };
}

export default {
  data() {
    return {
      placeholder: "",
      placeholderValue: "",
      isEditorValid: false,
      cursorPosition: 0,
      shortcut: {},
      editor: null,
    };
  },

  computed: {
    ...mapGetters({
      htmlShortcuts: TEMPLATES_EDIT_TYPES.GETTERS.HTML_SHORTCUTS,
      placeholders: MAILCOMPOSER_TYPES.GETTERS.GET_PLACEHOLDERS,
      previewHtml: MAILCOMPOSER_TYPES.GETTERS.GET_PREVIEW,
    }),

    key() {
      return this.$route.params.key !== "new" ? this.$route.params.key : null;
    },
    placeholdersOptions() {
      const keys = this.placeholders && Object.keys(this.placeholders);
      return (
        (this.placeholders &&
          Object.values(this.placeholders).map((v, i) => ({
            name: keys[i],
            data: v.map((p) => ({
              value: Object.values(p)[0],
              label: Object.keys(p)[0],
            })),
          }))) ||
        []
      );
    },
    headerActions() {
      const { key } = this;

      const actions = [];
      actions.push(PageHeaderSimpleAction("SAVE", "Speichern"));
      if (key) {
        actions.push(PageHeaderSimpleAction("REFRESH", "Aktualisieren"));
      }
      return actions;
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    initEditor(editor) {
      this.editor = editor;
    },

    updateCursorPosition(position) {
      this.cursorPosition = position;
    },

    setEditorValid(isEditorValid) {
      this.isEditorValid = isEditorValid;
      this.$emit("isEditorValid", isEditorValid);
    },

    handlePreview(value, setValue = true) { },

    changeShortcut(item, value, field) {
      this.shortcut[field] = value;
    },

    async saveShortcut() {
      let removePromise = [];
      if (this.activeKey && this.activeKey !== this.shortcut.key) {
        removePromise.push(
          this.handleDelete(
            {
              ...this.shortcut,
              key: this.activeKey,
            },
            false
          )
        );
      }
      const { key, value, forBroker, description } = this.shortcut;
      return Promise.all(removePromise).then((r) => {
        return this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_HTML_SHORTCUTS, { key, value, forBroker, description }).then(() => {
          this.$router.push({ path: `/communication/html-shortcuts` });
        })
      });
    },

    async onRefresh() {
      this.pageLoading = true;

      this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.SET_HTML_SHORTCUTS, []);
      await Promise.all([
        this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_HTML_SHORTCUTS),
      ]).finally(() => (this.pageLoading = false));
    },
  },
  mounted() {
    this.onRefresh();

    this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS, {});
  },

  watch: {
    htmlShortcuts(shortcuts) {
      if (!(shortcuts || []).some((shortcut) => shortcut.key == this.key)) {
        this.shortcut = newShortcut();
      } else {
        this.shortcut = (shortcuts || []).find(
          (shortcut) => shortcut.key == this.key
        );
      }
    },
  },
  components: {
    BaseModal,
    ComboBox,
    InputField,
    InputToggleSwitch,
    InputTextArea,
    HtmlEditor,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
};
</script>

<style scoped>
.text-input {
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 100;
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
