import CALENDAR_SETTINGS_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import axios from 'axios';

const SERVICE_PATH = '/calendarSettings';

const config = {
  defaultSpinner: true,
};

export default {

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_COMBO_DATA]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/comboData`, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_COMBO_DATA, response?.data);
  },

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_INTERN_PERSONEN]({ getters, commit, }) {
    if(getters[CALENDAR_SETTINGS_TYPES.GETTERS.INTERN_PERSONEN]?.length > 0) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/internPersonen`, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_INTERN_PERSONEN, response?.data);
  },

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_SETTINGS_DATA]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/settingsData`, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_SETTINGS_DATA, response?.data);
  },

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.SAVE_SETTINGS_DATA]({ getters, commit, }) {
    const edited = getters[CALENDAR_SETTINGS_TYPES.GETTERS.SETTINGS_DATA_EDITED];
    if(!edited) return;

    const payload = { ...edited, };
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/settingsData`, payload, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.RESET_SETTINGS_DATA_EDITED);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_SETTINGS_DATA, response?.data);
  },

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_FEIERTAGE_GRUPPEN_SPEZIAL]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/feiertageGruppenSpezial`, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_FEIERTAGE_GRUPPEN_SPEZIAL, response?.data);
  },

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.SAVE_FEIERTAGE_GRUPPEN_SPEZIAL]({ getters, commit, }, payload) {
    if(!payload?.bezeichnung) return;

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/feiertageGruppenSpezial`, payload, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_FEIERTAGE_GRUPPEN_SPEZIAL, response?.data);
  },

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.DELETE_FEIERTAGE_GRUPPEN_SPEZIAL]({ getters, commit, }, { id, }) {
    if(!id) return;

    const response = await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/${id}/feiertageGruppenSpezial`, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_FEIERTAGE_GRUPPEN_SPEZIAL, response?.data);
  },

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_FEIERTAGE_SPEZIAL]({ getters, commit, }) {
    if(getters[CALENDAR_SETTINGS_TYPES.GETTERS.FEIERTAGE_SPEZIAL]?.length > 0) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/feiertageSpezial`, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_FEIERTAGE_SPEZIAL, response?.data);
  },

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.SAVE_FEIERTAGE_SPEZIAL]({ getters, commit, }, payload) {
    if(!payload?.gruppeSpezialId || !payload?.datum || !payload?.bezeichnung) return;

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/feiertageSpezial`, payload, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_FEIERTAGE_SPEZIAL, response?.data);
  },

  async [CALENDAR_SETTINGS_TYPES.ACTIONS.DELETE_FEIERTAGE_SPEZIAL]({ getters, commit, }, { id, }) {
    if(!id) return;

    const response = await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/${id}/feiertageSpezial`, config);
    commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_FEIERTAGE_SPEZIAL, response?.data);
  },

}
