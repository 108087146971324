import VERTRAG_WP_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import { makeQueryParam } from '../../helpers/utils-helper';

const config = {
	defaultSpinner: true
};

export default {
	async [VERTRAG_WP_TYPES.ACTIONS.GET_VERTRAG_WP]({ getters, commit }, payload) {

		try {
			commit(VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_SUCCESS, {})
			const params = makeQueryParam(payload)
			const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertrag_wp/vertrag_wp?${params}`, { ...config });

			if (response?.data) {
				const vertragWP = response.data

				// // reset inactive fields
				// if (payload?.vtgArt34Modus && response.data?.activeFields) {
				// 	const activeFields = response.data?.activeFields
				// 	for (const key in activeFields) {
				// 		if (Object.hasOwnProperty.call(activeFields, key) && !activeFields[key]) {
				// 			vertragWP.formData[key] = null
				// 		}
				// 	}
				// }
				commit(VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_SUCCESS, vertragWP)
			}
		} catch (error) {
			commit(VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_ERROR)
			throw error
		}

	},

	async [VERTRAG_WP_TYPES.ACTIONS.GET_VERTRAG_WP_HINWEIS]({ getters, commit }) {

		try {
			commit(VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_HINWEIS_SUCCESS, [])
			const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertrag_wp/hinweise_vertrag_wp`, { ...config });

			if (response?.data) {
				commit(VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_HINWEIS_SUCCESS, response.data)
			}
		} catch (error) {
			commit(VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_HINWEIS_ERROR)
			throw error
		}

	},

	async [VERTRAG_WP_TYPES.ACTIONS.GET_VERTRAG_WP_VALUES]({ getters, commit }) {

		try {
			commit(VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_VALUES_SUCCESS, [])
			const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertrag_wp/values_vertrag_wp`, { ...config });

			if (response?.data) {
				commit(VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_VALUES_SUCCESS, response.data)
			}
		} catch (error) {
			commit(VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_VALUES_ERROR)
			throw error
		}

	},

	async [VERTRAG_WP_TYPES.ACTIONS.SAVE_FORM]({ getters, commit }, form) {
		try {
			const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertrag_wp/vertrag_wp`, form, config);

			commit(VERTRAG_WP_TYPES.MUTATIONS.SAVE_VERTRAG_WP_SUCCESS, response.data) 
		} catch (error) {
			// empty block
		}
	},

	async [VERTRAG_WP_TYPES.ACTIONS.GET_MAKLER_PREVIEW_DATA]({ getters }) {
		try {
			const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertrag_wp/makler_preview_data`, config);

			if (response?.data) {
				return response.data;
			}
		} catch (error) {
			// empty block
			return []
		}
	},

	async [VERTRAG_WP_TYPES.ACTIONS.GET_BESTAENDE_PREVIEW_DATA]({ getters }) {
		try {
			const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertrag_wp/bestaende_preview_data`, config);

			if (response?.data) {
				return response.data;
			}
		} catch (error) {
			// empty block
			return []
		}
	},

	async [VERTRAG_WP_TYPES.ACTIONS.TO_SIGNATURE_VORLEGEN]({ getters }) {
		try {
			const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertrag_wp/to_signatur_vorlegen`, config);
			return response?.data;
		} catch (error) {
			// empty block
		}
	},
	

}