var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputRadioBoxGroup", {
          attrs: {
            value: _vm.form.allePos,
            validateUntouched: true,
            values: [
              {
                value: "Gesamtdepot",
                label: "Bitte übertragen Sie das gesamte Depot"
              },
              {
                value: "einzelneWP",
                label: "Bitte übertragen Sie einzelne Wertpapiere:"
              }
            ]
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { allePos: $event })
            }
          }
        }),
        _c("FormDivider"),
        _c("InputRadioBoxGroup", {
          attrs: {
            value: _vm.form.uebertragungsArt,
            title: "Art des Übertrags",
            values: _vm.artValues
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { uebertragungsArt: $event })
            }
          }
        }),
        _c("FormDivider"),
        _c("InputCheckBoxGroup", {
          attrs: {
            title: "Übertrag der Verlustverrechnungstöpfe",
            values: [
              { label: "Aktien", path: "aktienVerlust" },
              { label: "Sonstige", path: "sonstigeVerlust" },
              {
                label: "ausländische Quellensteuer",
                path: "auslaendischeVerlust"
              }
            ]
          },
          on: {
            checkBoxToggle: function($event) {
              return _vm.$emit("save", $event)
            }
          },
          model: {
            value: _vm.form,
            callback: function($$v) {
              _vm.form = $$v
            },
            expression: "form"
          }
        }),
        _c("FormDivider"),
        _vm.antragName === "uebertragungsauftrag"
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("FormLabel", {
                      attrs: {
                        label: "Bitte überweisen Sie den Betrag von",
                        config: { bold: true }
                      }
                    })
                  ],
                  1
                ),
                _c("InputField", {
                  attrs: {
                    isComponentHalfSize: true,
                    type: "currency",
                    label: "Betrag"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { betrag: $event })
                    }
                  },
                  model: {
                    value: _vm.form.betrag,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "betrag", $$v)
                    },
                    expression: "form.betrag"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    isComponentHalfSize: true,
                    label: "Währung",
                    values: _vm.customerDataConfig.waehrungen
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { waehrung1: $event })
                    }
                  },
                  model: {
                    value: _vm.form.waehrung1,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "waehrung1", $$v)
                    },
                    expression: "form.waehrung1"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    isComponentHalfSize: true,
                    type: "currency",
                    label: "Betrag"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { betrag2: $event })
                    }
                  },
                  model: {
                    value: _vm.form.betrag2,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "betrag2", $$v)
                    },
                    expression: "form.betrag2"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    isComponentHalfSize: true,
                    label: "Währung",
                    values: _vm.customerDataConfig.waehrungen
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { waehrung1: $event })
                    }
                  },
                  model: {
                    value: _vm.form.waehrung2,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "waehrung2", $$v)
                    },
                    expression: "form.waehrung2"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    isComponentHalfSize: true,
                    type: "currency",
                    label: "Betrag"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { betrag3: $event })
                    }
                  },
                  model: {
                    value: _vm.form.betrag3,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "betrag3", $$v)
                    },
                    expression: "form.betrag3"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    isComponentHalfSize: true,
                    label: "Währung",
                    values: _vm.customerDataConfig.waehrungen
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { waehrung1: $event })
                    }
                  },
                  model: {
                    value: _vm.form.waehrung3,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "waehrung3", $$v)
                    },
                    expression: "form.waehrung3"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    isComponentHalfSize: true,
                    type: "currency",
                    label: "Betrag"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { betrag4: $event })
                    }
                  },
                  model: {
                    value: _vm.form.betrag4,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "betrag4", $$v)
                    },
                    expression: "form.betrag4"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    isComponentHalfSize: true,
                    label: "Währung",
                    values: _vm.customerDataConfig.waehrungen
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { waehrung1: $event })
                    }
                  },
                  model: {
                    value: _vm.form.waehrung4,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "waehrung4", $$v)
                    },
                    expression: "form.waehrung4"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    isComponentHalfSize: true,
                    type: "currency",
                    label: "Betrag"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { betrag5: $event })
                    }
                  },
                  model: {
                    value: _vm.form.betrag5,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "betrag5", $$v)
                    },
                    expression: "form.betrag5"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    isComponentHalfSize: true,
                    label: "Währung",
                    values: _vm.customerDataConfig.waehrungen
                  },
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { waehrung1: $event })
                    }
                  },
                  model: {
                    value: _vm.form.waehrung5,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "waehrung5", $$v)
                    },
                    expression: "form.waehrung5"
                  }
                }),
                _c("InputRadioBoxGroup", {
                  attrs: {
                    title: "Löschung von Konto und Depot",
                    value: _vm.form.loeschung,
                    validateUntouched: true,
                    values: [
                      { value: "Gesamtauflösung", label: "Gesamtlöschung" },
                      { value: "Konto", label: "nur Konto" },
                      { value: "Depot", label: "nur Depot" }
                    ]
                  },
                  on: {
                    input: function($event) {
                      return _vm.$emit("save", { loeschung: $event })
                    }
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "Der Übertrag erfolgt aufgrund der Ablösung eines bestehenden Kredites"
                  },
                  on: {
                    input: function($event) {
                      return _vm.$emit("save", { aufgrundDerAbloesung: $event })
                    }
                  },
                  model: {
                    value: _vm.form.aufgrundDerAbloesung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "aufgrundDerAbloesung", $$v)
                    },
                    expression: "form.aufgrundDerAbloesung"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "BaseButton",
          {
            staticClass: "col-12 mr-2",
            attrs: { isSecondary: true },
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v("Zurück")]
        ),
        _c(
          "BaseButton",
          {
            staticClass: "col-12",
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }