var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("FormLabel", {
          attrs: {
            label: "Bitte tragen Sie hier die zu übertragenden Depots ein"
          }
        }),
        _c("FormHeader", { attrs: { label: "Aktuelle Depotverbindung" } }),
        _c("InputField", {
          attrs: { label: "Name der Bank" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherManuellName: $event })
            }
          },
          model: {
            value: _vm.form.bisherManuellName,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherManuellName", $$v)
            },
            expression: "form.bisherManuellName"
          }
        }),
        _c("InputField", {
          attrs: { label: "Straße" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherManuellStrasse: $event })
            }
          },
          model: {
            value: _vm.form.bisherManuellStrasse,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherManuellStrasse", $$v)
            },
            expression: "form.bisherManuellStrasse"
          }
        }),
        _c("InputField", {
          attrs: { label: "PLZ" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherManuellPLZ: $event })
            }
          },
          model: {
            value: _vm.form.bisherManuellPLZ,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherManuellPLZ", $$v)
            },
            expression: "form.bisherManuellPLZ"
          }
        }),
        _c("InputField", {
          attrs: { label: "Ort" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherManuellOrt: $event })
            }
          },
          model: {
            value: _vm.form.bisherManuellOrt,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherManuellOrt", $$v)
            },
            expression: "form.bisherManuellOrt"
          }
        }),
        _c("InputField", {
          attrs: { label: "Nummer" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherDepotnr: $event })
            }
          },
          model: {
            value: _vm.form.bisherDepotnr,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherDepotnr", $$v)
            },
            expression: "form.bisherDepotnr"
          }
        }),
        _c("FormDivider"),
        _c("FormHeader", { attrs: { label: "Depotinhaber 1" } }),
        _c("InputField", {
          attrs: { label: "Name" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber1Name: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber1Name,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber1Name", $$v)
            },
            expression: "form.altInhaber1Name"
          }
        }),
        _c("InputField", {
          attrs: { label: "Vorname" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber1Vorname: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber1Vorname,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber1Vorname", $$v)
            },
            expression: "form.altInhaber1Vorname"
          }
        }),
        _c("InputField", {
          attrs: { label: "Straße" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber1Strasse: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber1Strasse,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber1Strasse", $$v)
            },
            expression: "form.altInhaber1Strasse"
          }
        }),
        _c("InputField", {
          attrs: { label: "PLZ" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber1Plz: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber1Plz,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber1Plz", $$v)
            },
            expression: "form.altInhaber1Plz"
          }
        }),
        _c("InputField", {
          attrs: { label: "Ort" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber1Ort: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber1Ort,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber1Ort", $$v)
            },
            expression: "form.altInhaber1Ort"
          }
        }),
        _c("DatePickerField", {
          attrs: { isValueAsString: "", label: "Geburtsdatum" },
          on: {
            input: function($event) {
              return _vm.$emit("save", { altInhaber1Geburtsdatum: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber1Geburtsdatum,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber1Geburtsdatum", $$v)
            },
            expression: "form.altInhaber1Geburtsdatum"
          }
        }),
        _c("InputField", {
          attrs: { label: "Steuer-Id (TIN)" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber1Steuernummer: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber1Steuernummer,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber1Steuernummer", $$v)
            },
            expression: "form.altInhaber1Steuernummer"
          }
        }),
        _c("FormDivider"),
        _c("FormHeader", { attrs: { label: "Depotinhaber 2" } }),
        _c("InputField", {
          attrs: { label: "Name" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber2Name: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber2Name,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber2Name", $$v)
            },
            expression: "form.altInhaber2Name"
          }
        }),
        _c("InputField", {
          attrs: { label: "Vorname" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber2Vorname: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber2Vorname,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber2Vorname", $$v)
            },
            expression: "form.altInhaber2Vorname"
          }
        }),
        _c("InputField", {
          attrs: { label: "Straße" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber2Strasse: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber2Strasse,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber2Strasse", $$v)
            },
            expression: "form.altInhaber2Strasse"
          }
        }),
        _c("InputField", {
          attrs: { label: "PLZ" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber2Plz: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber2Plz,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber2Plz", $$v)
            },
            expression: "form.altInhaber2Plz"
          }
        }),
        _c("InputField", {
          attrs: { label: "Ort" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber2Ort: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber2Ort,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber2Ort", $$v)
            },
            expression: "form.altInhaber2Ort"
          }
        }),
        _c("DatePickerField", {
          attrs: { label: "Geburtsdatum", isValueAsString: "" },
          on: {
            input: function($event) {
              return _vm.$emit("save", { altInhaber2Geburtsdatum: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber2Geburtsdatum,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber2Geburtsdatum", $$v)
            },
            expression: "form.altInhaber2Geburtsdatum"
          }
        }),
        _c("InputField", {
          attrs: { label: "Steuer-Id (TIN)" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { altInhaber2Steuernummer: $event })
            }
          },
          model: {
            value: _vm.form.altInhaber2Steuernummer,
            callback: function($$v) {
              _vm.$set(_vm.form, "altInhaber2Steuernummer", $$v)
            },
            expression: "form.altInhaber2Steuernummer"
          }
        }),
        _c(
          "BaseButton",
          {
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }