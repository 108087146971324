var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FinanceImmobilienvermoegen", {
        attrs: {
          isRefusedPage: _vm.isDisabled || _vm.isRefusedPage,
          finance: _vm.finance
        },
        on: {
          updateImmo: function($event) {
            return _vm.updateStoreStep33($event)
          }
        }
      }),
      _c("div", { staticClass: "sum d-flex justify-content-between" }, [
        _c("div", { staticClass: "my-2" }, [
          _vm._v("Summe Immobilienvermögen")
        ]),
        _c(
          "div",
          { staticClass: "my-2 pr-3" },
          [_c("CurrencyLabel", { attrs: { value: _vm.sumImmobilien } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }