import PENDING_ACTIONS_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {


  [PENDING_ACTIONS_TYPES.ACTIONS.SIMPLE_ACTION_KUNDENZUGANG]({ getters, dispatch, commit }, payload) {
    const config = {
      defaultSpinner: true
    };
    

    let serviceUrl = '/pendingActionsService/simpleActionKundenzugang';

    const actionCommand = payload?.actionCommand;

    const simpleActionId = payload?.simpleActionId;

    if (simpleActionId) {
      serviceUrl = `${serviceUrl}?simpleActionId=${simpleActionId}`;
    }   

    if (actionCommand) {
      serviceUrl = `${serviceUrl}&actionCommand=${actionCommand}`;
    }   

    

    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${serviceUrl}`, {}, config)
        .then((response) => {
          commit(PENDING_ACTIONS_TYPES.MUTATIONS.SIMPLE_ACTION_KUNDENZUGANG_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },  
  [PENDING_ACTIONS_TYPES.ACTIONS.GET_ACTION_ID]({ getters, dispatch, commit }, payload) {
    const config = {
      defaultSpinner: true
    };
    

    let serviceUrl = '/pendingActionsService/getActionId';

    const actionStatus = payload?.actionStatus;

    const actionType = payload?.actionType;

    if (actionStatus) {
      serviceUrl = `${serviceUrl}?actionStatus=${actionStatus}`;
    }   

    if (actionType) {
      serviceUrl = `${serviceUrl}&actionType=${actionType}`;
    }   

    

    return new Promise((resolve, reject) => {
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${serviceUrl}`, config)
        .then((response) => {
          commit(PENDING_ACTIONS_TYPES.MUTATIONS.GET_ACTION_ID_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, 


  [PENDING_ACTIONS_TYPES.ACTIONS.GET_SIMPLE_ACTION_FONDSSHOP_DATA]({ getters, dispatch, commit }, payload) {
    const config = {
      defaultSpinner: true
    };
    

    let serviceUrl = '/pendingActionsService/getSimpleActionFondsshopData';

    const simpleActionId = payload?.simpleActionId;

    if (simpleActionId) {
      serviceUrl = `${serviceUrl}?simpleActionId=${simpleActionId}`;
    }   

    return new Promise((resolve, reject) => {
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${serviceUrl}`, config)
        .then((response) => {
          commit(PENDING_ACTIONS_TYPES.MUTATIONS.GET_SIMPLE_ACTION_FONDSSHOP_DATA_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, 

  [PENDING_ACTIONS_TYPES.ACTIONS.GET_SIMPLE_ACTION_LISTE]({ getters, dispatch, commit }, payload) {
    const config = {
      defaultSpinner: true
    };
    

    let serviceUrl = '/pendingActionsService/getSimpleActionListe';

    const buttonMode = payload?.buttonMode;

    if (buttonMode) {
      serviceUrl = `${serviceUrl}?buttonMode=${buttonMode}`;
    }   

    return new Promise((resolve, reject) => {
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${serviceUrl}`, config)
        .then((response) => {
          commit(PENDING_ACTIONS_TYPES.MUTATIONS.GET_SIMPLE_ACTION_LISTE_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },   
  [PENDING_ACTIONS_TYPES.ACTIONS.EXECUTE_SIMPLE_ACTION]({ getters, dispatch, commit }, payload) {
    const config = {
      defaultSpinner: true
    };
    

    let serviceUrl = '/pendingActionsService/executeSimpleAction';

    const actionCommand = payload?.actionCommand;
    const simpleActionId = payload?.simpleActionId;
    const settings = payload?.settings;
    const type = payload?.type;
    const customers = payload?.customers;


    if (simpleActionId) {
      serviceUrl = `${serviceUrl}?simpleActionId=${simpleActionId}`;
    }   

    if (actionCommand) {
      serviceUrl = `${serviceUrl}&actionCommand=${actionCommand}`;
    }   

    if (type) {
      serviceUrl = `${serviceUrl}&type=${type}`;
    }    
    
    if (settings) {
      serviceUrl = `${serviceUrl}&settings=${settings}`;
    }     

    if (customers) {
      customers.forEach(c => {
        serviceUrl = `${serviceUrl}&customers=${c}`;
      })
    }

    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${serviceUrl}`, {}, config)
        .then((response) => {
          commit(PENDING_ACTIONS_TYPES.MUTATIONS.EXECUTE_SIMPLE_ACTION_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },    
   
  

}