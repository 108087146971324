var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.renderComponent
      ? _c(
          "div",
          { staticClass: "container" },
          [
            _vm.stepType === "step" && _vm.stepType !== "tab"
              ? [
                  _c(
                    "div",
                    { staticClass: "layout__negative-margin--8 color-text" },
                    [
                      _c("BaseCollapsable", {
                        staticClass: "mt-1 mb-1",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "stepper-liner-sm" },
                                    _vm._l(_vm.stepsComputed, function(
                                      step,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: "nav" + index + _vm.lastUpdated
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "stepper-item__icon",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onClickStop(
                                                    step.stepKey
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("VerticalStepIcon", {
                                                staticClass: "clickable",
                                                attrs: {
                                                  stepperStatus: _vm.stepperStatus(
                                                    step
                                                  ),
                                                  isCurrent:
                                                    _vm.selectedStepKey ==
                                                    step.stepKey,
                                                  collapsed: true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "antrag-sidetext__container mt-8px"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "layout__negative-margin--8 color-text"
                                        },
                                        _vm._l(_vm.stepsComputed, function(
                                          step,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key:
                                                "nav" + index + _vm.lastUpdated
                                            },
                                            [
                                              _c("div", {
                                                class: [
                                                  "clickable",
                                                  {
                                                    "font-bold":
                                                      _vm.selectedStepKey ==
                                                      step.stepKey
                                                  },
                                                  {
                                                    "substep-sm":
                                                      _vm.isMobileNativeContext
                                                  }
                                                ],
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.sanitize(step.label)
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickStop(
                                                      step.stepKey
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm.getSubsteps(step)[0]
                                                .substepKey
                                                ? _vm._l(
                                                    _vm.getSubsteps(step),
                                                    function(
                                                      substep,
                                                      subIndex
                                                    ) {
                                                      return _c("div", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.showLastSubstep(
                                                                index,
                                                                step
                                                              ) &&
                                                              !substep.hidden,
                                                            expression:
                                                              "showLastSubstep(index, step) && !substep.hidden"
                                                          }
                                                        ],
                                                        key:
                                                          "sub" +
                                                          subIndex +
                                                          _vm.lastUpdated,
                                                        class: [
                                                          "clickable pl-3",
                                                          {
                                                            "font-bold":
                                                              _vm.selectedSubstepKey ==
                                                              substep.substepKey
                                                          },
                                                          {
                                                            "substep-sm":
                                                              _vm.isMobileNativeContext
                                                          }
                                                        ],
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.sanitize(
                                                              substep.title
                                                            )
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onClickSubstep(
                                                              step.stepKey,
                                                              substep.substepKey
                                                            )
                                                          }
                                                        }
                                                      })
                                                    }
                                                  )
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          629485609
                        )
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.stepType === "tab"
              ? _c(
                  "div",
                  { staticClass: "stepper-container stepper-container-tab" },
                  [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "nav nav-tabs tabs-line d-none d-sm-flex pl-0"
                      },
                      [
                        _vm._l(_vm.stepsComputed, function(step, index) {
                          return [
                            _c(
                              "li",
                              {
                                key: "nav" + index + _vm.lastUpdated,
                                class: ["nav-item", step.current && "active"]
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    class: [step.current && "active"],
                                    attrs: { "data-toggle": "tab" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickStop(step.stepKey)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(step.label))]
                                )
                              ]
                            )
                          ]
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "nav nav-tabs tabs-line d-sm-none" },
                      [
                        _vm._l(_vm.stepsComputed, function(step, index) {
                          return [
                            _c(
                              "li",
                              {
                                key: "nav" + index + _vm.lastUpdated,
                                class: ["nav-item", step.current && "active"]
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    class: [step.current && "active"],
                                    attrs: { "data-toggle": "tab" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickStop(step.stepKey)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(step.label))]
                                )
                              ]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }