import MAKLER34F_TYPES from './types';
import { getInitialState } from './index';

import Vue from 'vue';

export default {

  [MAKLER34F_TYPES.MUTATIONS.SET_LOADING_DATA](state, payload) {
    Vue.set(state, 'loadingData', payload === true);
  },

  [MAKLER34F_TYPES.MUTATIONS.SET_DATA](state, payload) {
    Vue.set(state, 'data', { ...payload || {}, });
  },

  [MAKLER34F_TYPES.MUTATIONS.UPDATE_GESELL34F_TYP_DATA](state, payload) {
    const { data, } = state;
    if(data.gesellschaften34fTyp?.length > 0) {
      const index = data.gesellschaften34fTyp.findIndex(typ => typ.id === payload.id);
      Vue.set(state.data.gesellschaften34fTyp, index, { ...payload || {}, });
    }
  },

  [MAKLER34F_TYPES.MUTATIONS.SET_GESELLSCHAFT34F_TYP_SELECTED](state, payload) {
    Vue.set(state, 'gesellschaft34fTypSelected', payload);
  },

  [MAKLER34F_TYPES.MUTATIONS.SET_GESELLSCHAFTEN34F_TYP_EDITED](state, payload) {
    Vue.set(state, 'gesellschaften34fTypEdited', { 
      ...state.gesellschaften34fTypEdited || {},
      ...payload || {},
    });
  },

  [MAKLER34F_TYPES.MUTATIONS.SET_MAKLER34F_GESELLSCHAFTEN_EDITED](state, payload) {
    Vue.set(state, 'makler34fGesellschaftenEdited', { 
      ...state.makler34fGesellschaftenEdited || {},
      ...payload || {},
    });
  },

  [MAKLER34F_TYPES.MUTATIONS.RESET_GESELLSCHAFTEN34F_TYP_EDITED](state) {
    Vue.set(state, 'gesellschaften34fTypEdited', null);
  },

  [MAKLER34F_TYPES.MUTATIONS.RESET_MAKLER34F_GESELLSCHAFTEN_EDITED](state) {
    Vue.set(state, 'makler34fGesellschaftenEdited', null);
  },

  [MAKLER34F_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

}
