var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isUserAllowedToGetMultibankingData &&
    _vm.filteredAccounts &&
    _vm.accountDetails
    ? _c(
        "div",
        {
          key: _vm.accounts && _vm.accounts.length,
          staticClass: "kontoverwaltung-account-list"
        },
        [
          _c("KontoverwaltungAccountTable", {
            attrs: { hasActions: "" },
            on: {
              "action-EDIT_ACCOUNT": function($event) {
                return _vm.editAccount($event.account)
              },
              "action-MAKE_TRANSFER": function($event) {
                return _vm.transferRequest($event.account)
              },
              "action-GO_TO_TRANSACTIONS": function($event) {
                return _vm.goToTransactions($event.account)
              },
              "action-AUTHENTICATE_ACCOUNT": function($event) {
                return _vm.authenticateAccount($event.account)
              }
            }
          }),
          _vm.showGhost
            ? _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c(
                    "GhostLoading",
                    [
                      _c("Block", { attrs: { type: "title", width: "25%" } }),
                      _c("Block"),
                      _c("Block"),
                      _c("Block"),
                      _c("Block"),
                      _c("Block")
                    ],
                    1
                  )
                ],
                1
              )
            : !_vm.accounts || !_vm.accounts.length
            ? _c("div", { staticClass: "box__container" }, [_c("NoData")], 1)
            : _vm._e(),
          _c(
            "BaseModal",
            {
              ref: "editAccountModal",
              attrs: {
                showDefaultButtons: false,
                actions: _vm.baseModalActions
              },
              on: {
                "action-SAVE": function($event) {
                  return _vm.saveEditingAccount()
                },
                "action-DELETE": function($event) {
                  return _vm.askDeleteAccount()
                },
                "action-CLOSE": function($event) {
                  return _vm.$refs.editAccountModal.close()
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.editingAccount.title) +
                            " " +
                            _vm._s(_vm.editingAccount.iban) +
                            " "
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3976098546
              )
            },
            [
              _vm.editingAccount
                ? _c("InputField", {
                    attrs: { label: "Bezeichnung ändern:" },
                    model: {
                      value: _vm.editingAccount.displayName,
                      callback: function($$v) {
                        _vm.$set(_vm.editingAccount, "displayName", $$v)
                      },
                      expression: "editingAccount.displayName"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "deleteAccountModal",
              on: { onConfirmButton: _vm.deleteAccount },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.editingAccount.title) +
                            " " +
                            _vm._s(_vm.editingAccount.iban) +
                            " "
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3976098546
              )
            },
            [
              _vm.editingAccount
                ? _c("div", [
                    _c("p", [
                      _vm._v("Soll das Konto "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.editingAccount.displayName) +
                            " (IBAN: " +
                            _vm._s(_vm.editingAccount.iban) +
                            ")"
                        )
                      ]),
                      _vm._v(" wirklich entfernt werden?")
                    ]),
                    _c("p", [
                      _vm._v(
                        "Die Umsätze dieses Kontos werden nicht mehr in Ihren Auswertungen berücksichtigt!"
                      )
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "BaseModal",
            {
              ref: "dataWillBeUpdated",
              attrs: {
                showCancelButton: false,
                modalTitle: "Kontoaktualisierung",
                labelButtonConfirm: "Schließen"
              }
            },
            [_vm._v(" Daten werden aktualisiert ")]
          ),
          _c(
            "BaseModal",
            {
              ref: "errorModal",
              attrs: {
                showCancelButton: false,
                modalTitle: "Fehler",
                labelButtonConfirm: "Schließen"
              }
            },
            [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
          ),
          _c(
            "BaseModal",
            {
              ref: "authenticateAccountMessageModal",
              attrs: {
                showCancelButton: false,
                modalTitle: "",
                labelButtonConfirm: "Schließen"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.requestedAccountToAuthenticateAgainMessage) +
                  " "
              )
            ]
          ),
          _c(
            "BaseModal",
            {
              ref: "transferSuccess",
              attrs: {
                showCancelButton: false,
                modalTitle: "",
                labelButtonConfirm: "Schließen"
              }
            },
            [_vm._v(" Ihre Überweisung wurde erfolgreich übermittelt ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }