<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Konfiguration des Re-Balancing für den FFB-Fondsshop" 
      :actions="headerActions"
      @action-CONFIRM="confirm()" 
      @action-LATER="spaterAnzeigen()" 
      @action-DECLINE="decline()" 
      @action-EMAIL-EDIT="editEmail()" 
    />

    <div v-if="simpleActionFondsshopData && simpleActionFondsshopData.settings" class="box__container">
      <p>Leider kam es bei einigen Depoteröffnungen über Ihren Fondsshop zu einem Fehler in der Formulargenerierung.</p>

      <p>Entgegen Ihrer Erwartung wurden bei den in der Anlage aufgeführten Kunden nicht das automatische Rebalancing der Depots beauftragt, konfiguriert (vierteljährlich/halbjährlich/jährlich) und ausgeführt. Aufgrund eines technischen Fehlers fehlte die entsprechende Beauftragung in den Depoteröffnungsunterlagen.</p>

      <p>Mit dieser Aktion werden automatisch alle Ihre betroffenen Kunden per E-Mail (und ggf. App-Push-Nachricht) kontaktiert. Dem Kunden wird ein Formular zur Verfügung gestellt, dass er (ggf. auch digital per App) unterschreiben und Ihnen bzw. der FFB zuschicken kann. Sie können aus folgenden Formularvarianten wählen. Bitte beachten Sie: Der Kunde kann nur eine dieser Varianten zugeschickt bekommen und unterschreiben</p>


      <InputRadioBoxGroup 
          :values="[
                    {value: 'MIT_REBALANCING', label: 'Formular mit sofortigem Rebalancing des Bestands'},
                    {value: 'OHNE_REBALANCING', label: 'Formular ohne sofortigem Rebalancing des Bestands'},
                    {value: 'AUSWAHL_REBALANCING', label: 'Formular mit Kundenauswahl für sofortiges Rebalancing des Bestands'},
                ]"
          :value="settings"
          isComponentHalfSize
          @input="onRadioInput($event)"
          style="width: 100%"/>

      <p>Liste der betroffenen Kunden:</p>    

      <p>Sie können ebenfalls die PDF-Dokumente für die Kunden herunterladen, um diese per Post zu verschicken</p> 
			<p>Der E-Mail-Versand findet statt, falls der Kunde eine E-Mail-Adresse besitzt und in der Liste angekreuzt ist.</p> 
      <p>Ein Kunde kann auch mehrere betroffene Depots haben und daher mehrere Formulare benötigen.</p> 

      <Table v-if="tableKundenRows.length"
                tableId="4298d323-c283-4bd5-a7b2-a67a5f3d692a"
                v-model="selected"
                :headers="tableKundenHeaders"
                :rows="tableKundenRows"
                :rowsPerPage="5"
                @click-kundennr="openCustomerNewTab"
            >
            <template v-slot:settings="row">
              <DownloadLink
                asLink
                :title="row.settings"
                downloadServicePath="/simpleActionFondshopPdf"
                :filename="'Fondsshop_Depot_' + settings + '_Rebalancing.pdf'"
                :queryParams="{ 
                  simpleActionKundeId: row.id,
                  settings,
                }"/>
            </template>
      </Table>
      <NoData v-else-if="rows.length === 0" />       
    
    </div>
  </div>
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import PENDING_ACTIONS_TYPES from "@/store/pendingActions/types";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";

import { PageHeaderSimpleAction, PageHeaderDownloadLinkAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    PageHeaderTitleNavigation,
    BaseButton,
    DownloadLink,
    Table,
    NoData,
    InputRadioBoxGroup,
  },
  data: function () {
    return {
      settings: null,
      selected: [],
    }
  },
  computed: {
    ...mapGetters({
      pendingActions: CORE_TYPES.GETTERS.PENDING_ACTIONS,
      actionId: PENDING_ACTIONS_TYPES.GETTERS.ACTION_ID,
      simpleActionFondsshopData: PENDING_ACTIONS_TYPES.GETTERS.SIMPLE_ACTION_FONDSSHOP_DATA,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('CONFIRM', 'Ja'),
        PageHeaderSimpleAction('LATER', 'Später anzeigen'),
        PageHeaderSimpleAction('DECLINE', 'Nein'),
        PageHeaderDownloadLinkAction('EMAIL-PREVIEW', 'E-mail Vorschau anzeigen', 
          'ActionFondshop.pdf', '/simpleActionFondshopPdf', { simpleActionId: this.calculatedActionId }),
        PageHeaderSimpleAction('EMAIL-EDIT', 'E-Mail-Vorlage bearbeiten'),
      ];
    },
    tableKundenHeaders() {
      return {
              lockedLeft: [
                TextColumn("id", "Id").makeHidden(),
                TextColumn("angekreuzt", "Angekreuzt").makeHidden(),
                TextColumn("kundennr", "Kundennr").makeLink(),
                SlotColumn("settings", "Depotnummer"),
              ],
              lockedRight: [
                TextColumn("name", "Name"),
                TextColumn("vorName", "Vorname"),
              ],
          };      
    },
    tableKundenRows() {
      return this.simpleActionFondsshopData?.kundenListe;
    },
    calculatedActionId() {
      const simpleActionId = this.$route.query.simpleActionId;
      return simpleActionId || this.actionId?.value;
    },
  },
  mounted: function () {
      const simpleActionId = this.$route.query.simpleActionId;

      if (simpleActionId) {
        this.getSimpleActionFondsshopData(simpleActionId);    

      } else {
        this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.GET_ACTION_ID, {
            actionStatus: 'PENDING',
            actionType: 'SimpleActionFondsshop',
          }).then(response => {
              this.$store.commit(PENDING_ACTIONS_TYPES.MUTATIONS.GET_ACTION_ID_SUCCESS, response);
              this.getSimpleActionFondsshopData(this.actionId);    

          });

      }

   
  },
  methods: {
    getSimpleActionFondsshopData(simpleActionId) {
      this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.GET_SIMPLE_ACTION_FONDSSHOP_DATA, {
          simpleActionId,
          }).then(response => {
            this.settings = response?.settings;
            this.$store.commit(PENDING_ACTIONS_TYPES.MUTATIONS.GET_SIMPLE_ACTION_FONDSSHOP_DATA_SUCCESS, response);
          });    
    },
    confirm() {
      // SimpleActionFrameworkServlet.java -> line 280 -> updateData(...)

      const simpleActionId = this.$route.query.simpleActionId || this.actionId?.value;

      let customers = [];

      if (this.selected?.length) {
        this.selected.forEach(s => {
          customers.push(s.kundennr);
        })
      }

      this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.EXECUTE_SIMPLE_ACTION, {
        simpleActionId,
        actionCommand: 'BUTTON_YES',
        settings: this.settings,
        type: "SimpleActionFondsshop",
        customers,
        }).then(response => {
            this.$store.commit(PENDING_ACTIONS_TYPES.MUTATIONS.SIMPLE_ACTION_KUNDENZUGANG_SUCCESS, response);

            if (this.$route.query.simpleActionId) {
              this.$router.go(-1);
            } else {
              this.$router.push('/home');
            }
            
        });  
    },
    spaterAnzeigen() {
      this.$router.push('/home');
    },
    decline() {
      // SimpleActionFrameworkServlet.java -> line 280 -> updateData(...)

      const simpleActionId = this.$route.query.simpleActionId || this.actionId?.value;

      let customers = [];

      if (this.selected?.length) {
        this.selected.forEach(s => {
          customers.push(s.kundennr);
        })
      }

      this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.EXECUTE_SIMPLE_ACTION, {
        simpleActionId,
        actionCommand: 'BUTTON_NO',
        settings: this.settings,
        type: "SimpleActionFondsshop",
        customers,
        }).then(response => {
            this.$store.commit(PENDING_ACTIONS_TYPES.MUTATIONS.SIMPLE_ACTION_KUNDENZUGANG_SUCCESS, response);
            
            if (this.$route.query.simpleActionId) {
              this.$router.go(-1);
            } else {
              this.$router.push('/home');
            }
        });        
    },
    onRadioInput(value) {
      this.settings = value;
    },
    openCustomerNewTab(row) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
          { customerId: row?.kundennr, insurances: false, path: `/persoenlichedaten/customer-data/steps/stammdaten` });
    },   
    editEmail() {
      const attachments = []

      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_FONDSHOP_ACTION_MAIL, {
        simpleActionId: this.calculatedActionId,
        }).then(response => {
            const customResponse = {
              ...response.data,
              attachments: attachments,
            }
                

            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_FONDSHOP_ACTION, customResponse);
            this.$router.push({path: '/communication/mailcomposer-single', query: {replaceContent: true}});

        })  
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(PENDING_ACTIONS_TYPES.ACTIONS.GET_SIMPLE_ACTION_LISTE);  

    this.$addBreadcrumb({
      label: 'zurück zu Konfiguration des Re-Balancing für den FFB-Fondsshop', 
      to,
      from,
      breadcrumb: "Konfiguration des Re-Balancing für den FFB-Fondsshop"
    });

    next();
  },
}
</script>

<style scoped>

</style>