<template>
  <div>
    <GhostLoading v-if="loading && !scrollLoading" type="table" :title="title"/>
    <PaginatedTable
      v-else
      tableId="15b90920-ebdd-11ec-8ea0-0242ac120002"
      :title="title"
      :headers="headers"
      :page="selectedPage"
      :pages="pages"
      :pageCount="pageCount"
      :rowCount="rowCount"
      :sorted="searchSort"
      :pageSize="maxRowsPerPage"
      :menuRowsPerPage="menuRowsPerPage"
      :mobileConfig="{title: 'subject', headers: ['typ', 'd', 'status']}"
      v-on="$listeners"
      @scrollLoading="scrollLoading = $event"
      @click-subject="clickBetreff"
      @action-OPEN="handleActionOpen"
      @action-BEMERKUNG="handleActionBemerkung"
      @action-TRACE="handleActionTrace"
      @action-OPENEMAIL="handleActionOpenMail"
      @action-EDIT="handleActionEdit"
      @action-CONFIRM="handleActionConfirm"
      @action-TOERROR="handleActionToError"
      @action-SEND="handleActionSend"
      @action-COMM="clickBetreff"
    >
      
      <template #subject="row">
        <DownloadLink
          v-if="row.canRead && !row.mobileTableContext"
          target="_blank"
          rel="noopener noreferer"
          :title="row.subject"
          downloadServicePath="/dokumentEingangData"
          :queryParams="{
            id: row.id,
          }"
        />
        <span v-else>{{row.subject}}</span>
      </template>
    </PaginatedTable>
    <Bemerkung ref="Bemerkung" />
    <Trace ref="Trace" />
    <Confirm ref="Confirm" />
    <ToError ref="ToError"/>
  </div>
</template>

<script>

import V_P_TYPES from "@/store/versandProtokolle/types";
import { mapGetters } from "vuex";
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import Bemerkung from "@/views/communication/versandProtokolle/BemerkungDialog.vue";
import Trace from "@/views/communication/versandProtokolle/TraceDialog.vue";
import Confirm from "@/views/communication/versandProtokolle/ConfirmDialog.vue";
import ToError from "@/views/communication/versandProtokolle/ToErrorDialog.vue";
import {
  TextColumn,
  HtmlColumn,
  DateTimeColumn,
  PillColumn,
  ActionColumn,
  SlotColumn,
  SimpleAction,
  MENU_ROWS_PER_PAGE,
} from "@/components/table2/table_util.js";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
export default {
  components: {
    PaginatedTable,
    DownloadLink,
    GhostLoading,
    Bemerkung,
    Trace,
    Confirm,
    ToError
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    records: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchSort: {
      type: Object,
      default: () => {},
    },
    selectedPage: {
      type: Number,
      default: 0,
    },
    maxRowsPerPage: {
      type: Number,
      default: 25,
    },
  },
  data() {
    return {
      scrollLoading: false,
      menuRowsPerPage: MENU_ROWS_PER_PAGE,
    };
  },
  computed: {
    ...mapGetters({
      rec: V_P_TYPES.GETTERS.FILTER,
      setup: V_P_TYPES.GETTERS.SETUP,
      rowCount: V_P_TYPES.GETTERS.ROW_COUNT,
    }),
    disabledColumns() {
      if (this.setup && this.setup.modus != "VERWALTUNG") {
        return ["maklerVerwalter", "dateUpdated", "bemerkung"];
      } else {
        return [];
      }
    },
    headers() {
      return {
        lockedLeft: [
         
        ].filter((column) => !this.disabledColumns.includes(column.key)),
        center: [
         PillColumn("status", "Status"),
          PillColumn("sendStatus", "Weiterleitung"),

          SlotColumn("subject", "Betreff").makeSortable(), 
          HtmlColumn("typ", "Dokumenstyp", 250, 1),
          DateTimeColumn("d", "Empfangen"),
          DateTimeColumn("dateUpdated", "Geändert"),
          TextColumn("eingangsType", "Eingang", 250, 1),
          PillColumn("maklerVerwalter", "Zuweisung"),

          TextColumn("bemerkung", "Bemerkung"),
          ActionColumn("actions", "Aktionen")
        ].filter((column) => !this.disabledColumns.includes(column.key)),
        lockedRight: [].filter(
          (column) => !this.disabledColumns.includes(column.key)
        ),
      };
    },
    pages() {
      const result = {};
      Object.entries(this.records).forEach(([page, records]) => {
        result[page] = records.map(record => {
          const row = {
            ...record,
          };

          if (record.actions) {
            row.actions = record.actions.map((legend) => {
              return SimpleAction(legend.key, legend.icon, legend.label);
            });
          }

          return row;
        })
      })
      return result;
    },
    pageCount() {
      return Math.ceil(this.rowCount / this.maxRowsPerPage);
    },
  },
  methods: {
    clickBetreff(tableRow) {
      if (tableRow && tableRow.id) {
        this.$store.commit(V_P_TYPES.MUTATIONS.COMMUNICATIONS, null);
        this.$router.push(`versandprtokolle/eingang/${tableRow.id}`);
      }
    },
    handleActionOpen(tableRow) {
      if (tableRow && tableRow.id) {
        this.$store.dispatch(V_P_TYPES.ACTIONS.LOAD_EINGANG, tableRow.id);
      }
    },
    handleActionBemerkung(tableRow) {
      if (tableRow && tableRow.id) {
        this.$refs.Bemerkung?.open(tableRow.id);
      }
    },
    handleActionTrace(tableRow) {
      if (tableRow && tableRow.id) {
        this.$refs.Trace?.open(tableRow.id);
      }
    },


    handleActionConfirm(tableRow) {
      if (tableRow && tableRow.id) {
        this.$refs.Confirm?.open(tableRow.id, tableRow.subject, tableRow.d);
      }
    },
    handleActionToError(tableRow) {
      if (tableRow && tableRow.id) {
        this.$refs.ToError?.open(tableRow.id, tableRow.subject, tableRow.d);
      }
    },
    async handleActionOpenMail(tableRow) {
      if (tableRow && tableRow.id) {
        await this.$store.dispatch(V_P_TYPES.ACTIONS.QUELLE, tableRow.id);
      }
    },
    handleActionSend(tableRow) {
      if (tableRow && tableRow.id) {
        let e={};
        e.eingangId=tableRow.id;
        e.back=false; 
        e.fromFiltr=true;
        e.page = this.selectedPage
       
        this.$store.dispatch(V_P_TYPES.ACTIONS.SEND,e );
      }
    },

    handleActionEdit(tableRow) {
      if (tableRow && tableRow.id) {
        this.$router.push(`versandprtokolle/pages/${tableRow.id}`);
      }
    },
  },
};
</script>