<template>
  <div v-if="selectedBuchungsnr">
    <div class="box__title">Verteilung der Abrechnung auf die Struktur (Buchungsnr: {{ selectedBuchung.buchungsnr }})</div>

    <Table v-if="!loading && rows.length"
        tableId="e5326abc-fa73-49dd-a385-05d6d3843074"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        rowId="lfdNr"
    />
    <div v-else-if="loading" class="text-centered"><AnimatedSpinner /></div>
    <div v-else>Keine Daten</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';
import CORE_TYPES from '@/store/core/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn, PercentageColumn, NumberColumn} from "@/components/table2/table_util.js";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';


export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedBuchung: CREDIT_TYPES.GETTERS.SELECTED_BUCHUNG,
      buchungProvStrukturen: CREDIT_TYPES.GETTERS.BUCHUNG_PROV_STRUKTUREN,
      rights: CREDIT_TYPES.GETTERS.RIGHTS,
      isBrokerMaster: CORE_TYPES.GETTERS.IS_BROKER_MASTER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    selectedBuchungsnr() {
      return this.selectedBuchung?.id;
    },
    allowEditCourtage() {
      return this.isBrokerMaster && this.rights?.allowModifyVertragAbrechnung || this.isIntern;
    },
    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("lfdNr", "Lfd. Nr."),
            ],
            center: [
                TextColumn("maklernr", "Vermittlernr"),
                CurrencyColumn("betrag", "Prov. Betrag"),
            ],
        };
        if (this.allowEditCourtage) {
            headers.center.splice(1, 0, PercentageColumn("provSatz", "Prov. Satz"))
            headers.center.push(TextColumn("aenderung", "Änderung"))
            headers.center.push(PercentageColumn("prozentsatz", "Prozent"))
        }
        headers.center.push(PercentageColumn("mwst", "MwSt."));
        return headers;
    },
    rows() {
        return this.buchungProvStrukturen || [];
    },
  },
  watch: {
    selectedBuchungsnr(value) {
      if(value) {
        this.findBuchungProvStrukturen();
      }
    },
  },
  methods: {
    async findBuchungProvStrukturen() {
      this.loading = true;
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.FIND_BUCHUNG_PROV_STRUKTUREN);
      this.loading = false;
    },
  },
  components: {
    Table,
    AnimatedSpinner,
  },
}
</script>
