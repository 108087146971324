<template>
    <a :href="href" target="_blank" rel="noopener noreferer" :tid="_generateTidFromString(tid + actionKey)" class="btn-clean clickable">
        <component :is="iconComponent" :size="size"/>
        <span v-if="expanded">
            {{label}}
        </span>
    </a>
</template>
<script>
import actionMixin from './action-mixin.js';

export default {
    mixins: [actionMixin],
    props: {
        href: {
            type: String,
            required: true,
        },
    },
}
</script>
<style src='./action.scss' lang='scss' scoped/>
