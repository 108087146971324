var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: _vm.lagerstelleName + " Depotauswahl für Externer Zugang",
      labelButtonConfirm: "Externer " + _vm.lagerstelleName + " Zugang öffnen",
      confirmDisabled: !_vm.depotnummer
    },
    on: {
      onConfirmButton: function($event) {
        return _vm.openExterneZugangBank({
          konsorte: _vm.konsorte,
          depotnr: _vm.depotnummer,
          lagerstelle: _vm.lagerstelleId
        })
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("ComboBox", {
              attrs: { label: "Konsorte", values: _vm.combos.konsorteCombo },
              on: {
                change: function($event) {
                  return _vm.updateDepotnr()
                }
              },
              model: {
                value: _vm.konsorte,
                callback: function($$v) {
                  _vm.konsorte = $$v
                },
                expression: "konsorte"
              }
            }),
            _c("ComboBox", {
              attrs: { label: "Depotnr", values: _vm.depotnummerCombo },
              model: {
                value: _vm.depotnummer,
                callback: function($$v) {
                  _vm.depotnummer = $$v
                },
                expression: "depotnummer"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }