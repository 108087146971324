var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: _vm.title } }),
      _vm.marketingCampaign
        ? _c("div", [
            _c("div", { staticClass: "box__container" }, [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.sanitize(_vm.marketingCampaign.body))
                }
              })
            ]),
            _c("div", { staticClass: "box__container" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "layout__negative-margin--8" },
                  [
                    _c(
                      "BaseButton",
                      { attrs: { disabled: "", isSecondary: "" } },
                      [_vm._v(" Abbrechen ")]
                    ),
                    _c("BaseButton", { attrs: { disabled: "" } }, [
                      _vm._v(" Bestätigen ")
                    ])
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }