export const MUTATION_PREFIX = 'MAKLER34F: ';
export const ACTIONS_PREFIX = 'MAKLER34F_ACTIONS_';
export const GETTERS_PREFIX = 'MAKLER34F_GETTERS_';

export default {
  MUTATIONS: {
    SET_LOADING_DATA: MUTATION_PREFIX + 'SET_LOADING_DATA',
    SET_DATA: MUTATION_PREFIX + 'SET_DATA',
    UPDATE_GESELL34F_TYP_DATA: MUTATION_PREFIX + 'UPDATE_GESELL34F_TYP_DATA',

    SET_GESELLSCHAFT34F_TYP_SELECTED: MUTATION_PREFIX + 'SET_GESELLSCHAFT34F_TYP_SELECTED',
    SET_GESELLSCHAFTEN34F_TYP_EDITED: MUTATION_PREFIX + 'SET_GESELLSCHAFTEN34F_TYP_EDITED',
    SET_MAKLER34F_GESELLSCHAFTEN_EDITED: MUTATION_PREFIX + 'SET_MAKLER34F_GESELLSCHAFTEN_EDITED',
    RESET_GESELLSCHAFTEN34F_TYP_EDITED: MUTATION_PREFIX + 'RESET_GESELLSCHAFTEN34F_TYP_EDITED',
    RESET_MAKLER34F_GESELLSCHAFTEN_EDITED: MUTATION_PREFIX + 'RESET_MAKLER34F_GESELLSCHAFTEN_EDITED',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    FIND_DATA: ACTIONS_PREFIX + 'FIND_DATA',
    FIND_GESELL34F_TYP: ACTIONS_PREFIX + 'FIND_GESELL34F_TYP',
    IMPORT_GESELLSCHAFT: ACTIONS_PREFIX + 'IMPORT_GESELLSCHAFT',
    MAKE_GESELLSCHAFT_VISIBLE: ACTIONS_PREFIX + 'MAKE_GESELLSCHAFT_VISIBLE',
    SAVE_GESELLSCHAFT: ACTIONS_PREFIX + 'SAVE_GESELLSCHAFT',
    DELETE_GESELLSCHAFT: ACTIONS_PREFIX + 'DELETE_GESELLSCHAFT',
    SAVE_CHECKED_GESELLSCHAFTEN: ACTIONS_PREFIX + 'SAVE_CHECKED_GESELLSCHAFTEN',
  },
  GETTERS: {
    IS_LOADING_DATA: GETTERS_PREFIX + 'IS_LOADING_DATA',
    DATA: GETTERS_PREFIX + 'DATA',
    GESELLSCHAFT34F_TYP_SELECTED: GETTERS_PREFIX + 'GESELLSCHAFT34F_TYP_SELECTED',
    GESELLSCHAFTEN34F_TYP_EDITED: GETTERS_PREFIX + 'GESELLSCHAFTEN34F_TYP_EDITED',
    MAKLER34F_GESELLSCHAFTEN_EDITED: GETTERS_PREFIX + 'MAKLER34F_GESELLSCHAFTEN_EDITED',
  },
}
