var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "DBM",
          subtitle: _vm.$appNavigation.currentMenu.label
        }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "Avg Response Time",
          filterId: _vm.baseFilterSaveKey,
          metadata: _vm.searchMetadata,
          configFilter: _vm.configFilter,
          showSaveButton: true,
          immidiateSearch: "",
          defaultOptions: _vm.defaultOptions
        },
        on: {
          onFilter: function($event) {
            return _vm.search($event)
          },
          onFilterZurucksetzen: function($event) {
            return _vm.search(null)
          }
        }
      }),
      _vm.chartData
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("LineChart", {
                attrs: {
                  height: "470px",
                  format: "integer",
                  isArea: false,
                  customTooltipFormatter: _vm.customTooltipFormatter,
                  chartData: _vm.chartData,
                  markPointData: _vm.markPointData,
                  gridLeft: 40
                },
                on: { click: _vm.onClick }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.endpoints
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("Table", {
                attrs: { headers: _vm.headers, rows: _vm.rows, rowsPerPage: 20 }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }