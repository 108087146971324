<template>
  <div>
    <!-- Weitere Wermoegen / Weitere Verbindlichkeiten show parent options menu -->
    <PageHeaderTitleNavigation
      :title="pageMetadata.title"
      :subtitle="pageMetadata.subtitle"
      :actions="headerActions"
      @action-ADD-AKTIVITAET="newAppointment" 
    />

    <StepperForm
      ref="stepperForm"
      stepType="tab"
      stepperName="other-product"
      :stepperMediator="stepperMediator"
      :hasFertig="isTestBroker || isTestCustomer"
      :selectedStepKey="selectedStepKey"
      :additionalActionsForMobile="baseModalActions"
      @handleOtherActions="handleOtherActions"
      @set-step="setStep($event)"
      @on-fertig="onDone"
    >
      <template #contentTemplate>
        <Produktdaten
          ref="produktdaten"
          v-if="selectedStepKey === 'produktdaten'"
        />
        <Anpassungen v-else-if="selectedStepKey === 'anpassungen'" />
        <div v-else-if="selectedStepKey === 'dokumente'">
          <DragnDropArea @files="dropFile" hoverText="Datei hier ablegen" v-if="type === 'Immobilie'">
            <div class="box__container"  >
              <DocumentsTable ref="documentsTableEl" title="Dokumente" :nodeId="combos.nodeId" />
            </div>
          </DragnDropArea>
          <Dokumente v-else/>
        </div>
        <Abrechnung v-else-if="selectedStepKey === 'abrechnung'" />
        <Aenderungslog v-else-if="selectedStepKey === 'aenderungslog'" />
        <ManuelleStruktur v-else-if="selectedStepKey === 'manuelle-struktur'" />
        <Aktivitaet v-else-if="selectedStepKey === 'aktivitaet'" />
      </template>
    </StepperForm>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OTHER_PRODUCTS_TYPES from "@/store/otherProducts/types";
import CORE_TYPES from "@/store/core/types";

import StepperForm from "@/components/stepper/StepperForm.vue";

import Produktdaten from "./steps/Produktdaten.vue";
import Anpassungen from "./steps/Anpassungen.vue";
import Dokumente from "./steps/Dokumente.vue";
import Abrechnung from "./steps/Abrechnung.vue";
import Aenderungslog from "./steps/Aenderungslog.vue";
import ManuelleStruktur from "./steps/ManuelleStruktur.vue";
import Aktivitaet from "./steps/Aktivitaet.vue";
import CALENDAR_TYPES from "@/store/calendar/types";
import BaseModal from "@/components/core/BaseModal.vue";
import AppointmentEditCreate from "@/components/calendar/appointmentFiles/AppointmentEditCreate.vue";
import DocumentsTable from '@/components/documents/DocumentsTable.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { StepperMediator } from "@/components/stepper/utils";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

import {
  MAP_TYPE_TO_HOME_PATH,
  SPROD_ID_NEW,
  MAP_TYPE_TO_TITLE_SINGULAR,
  WEITERE_VERMOEGEN_TYPE,
  WEITERE_VERBINDLICHKEITEN_TYPE,
} from "./otherProductsUtils.js";

import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import EVENT_BUS, {  ANPASSUNGEN_NEUE_ANPASSUNG, } from '@/event-bus';

const DEFAULT_STEP = "produktdaten";
const BEREICH = "SonstigeProdukte";


export default {
  components: {
    StepperForm,
    Produktdaten,
    Anpassungen,
    Dokumente,
    Abrechnung,
    Aenderungslog,
    ManuelleStruktur,
    Aktivitaet,
    BaseModal,
    AppointmentEditCreate,
    DocumentsTable,
    DragnDropArea,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    useParentOptionMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let index = 0;
    return {
      steps: [
        {
          stepKey: "produktdaten",
          label: "Produktdaten",
          totalProgress: 1,
          index: index++,
        },
        {
          stepKey: "anpassungen",
          label: "Anpassungen",
          totalProgress: 1,
          index: index++,
        },
        {
          stepKey: "dokumente",
          label: "Dokumente",
          totalProgress: 1,
          index: index++,
          hidden: true,
        },
        {
          stepKey: "abrechnung",
          label: "Abrechnung",
          totalProgress: 1,
          index: index++,
          hidden: true,
        },
        {
          stepKey: "aenderungslog",
          label: "Änderungslog",
          totalProgress: 1,
          index: index++,
          hidden: true,
        },
        {
          stepKey: "manuelle-struktur",
          label: "Manuelle Struktur",
          totalProgress: 1,
          index: index++,
          hidden: true,
        },
        {
          stepKey: "aktivitaet",
          label: "Aktivität",
          totalProgress: 1,
          index: index++,
          hidden: true,
        },
      ],
      BEREICH,
    };
  },
  computed: {
    ...mapGetters({
      sprodId: OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID,
      otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      combos: OTHER_PRODUCTS_TYPES.GETTERS.COMBOS,
      isTestBroker: CORE_TYPES.GETTERS.IS_TEST_BROKER,
      isTestCustomer: CORE_TYPES.GETTERS.IS_TEST_CUSTOMER,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    baseModalActions() {
        const actions =  [];

        if (this.selectedStepKey === 'anpassungen') {
          actions.push(new BaseModalSimpleAction('NEUE_ANPASSUNG', 'Neue Anpassung'));
        }

        return actions;
    },       
    pageMetadata() {
      return {
        title: this.otherProduct?.produktart || MAP_TYPE_TO_TITLE_SINGULAR?.[this.type] || ' ',
        subtitle:
          this.otherProduct?.produktbez,
      };
    },
    headerActions() {
      const { isBrokerOrBypass, } = this;

      const actions = [];
      if(isBrokerOrBypass) {
        actions.push(PageHeaderSimpleAction('ADD-AKTIVITAET', 'Aufgabe hinzufügen'));
      }
      return actions;
    },
    rights() {
      return this.loginData?.rights || {};
    },
    isNew() {
      return this.sprodId === SPROD_ID_NEW;
    },
    stepperMediator() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },
    selectedStepKey() {
      return this.$route.params?.step || DEFAULT_STEP;
    },
  },
  watch: {
    $route(_, from) {
      if (
        from?.params?.step === "produktdaten" &&
        !this.$refs?.produktdaten?.checkData()
      ) {
        this.$router.push(
          `${MAP_TYPE_TO_HOME_PATH[this.type]}/edit/${
            this.sprodId
          }/produktdaten`
        );
      }
    },
    combos() {
      this.configureSteps();
    },
  },
  methods: {
    handleOtherActions(actionData) {
      if (actionData?.key === 'NEUE_ANPASSUNG') {
        EVENT_BUS.$emit(ANPASSUNGEN_NEUE_ANPASSUNG);
      }
    },      
    init() {
      this.$store.commit(
        OTHER_PRODUCTS_TYPES.MUTATIONS.SET_CURRENT_TYPE,
        this.type
      );
      this.$store.commit(
        OTHER_PRODUCTS_TYPES.MUTATIONS.SET_SELECTED_SPROD_ID,
        this.$route.params?.sprodId
      );

      this.findCombos();
      this.getOtherProduct(this.sprodId);

      this.configureSteps();
    },
    findCombos() {
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.GET_COMBOS);
    },
    getOtherProduct() {
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.GET_OTHER_PRODUCT);
    },
    setStep(stepKey) {
      if (this.selectedStepKey === stepKey) return;
      this.$router.push(
        `${MAP_TYPE_TO_HOME_PATH[this.type]}/edit/${this.sprodId}/${stepKey}`
      );
    },
    onDone() {
      this.$router.push({
        path: `${MAP_TYPE_TO_HOME_PATH[this.type]}/search?backAction=true`,
      });
    },
    configureSteps() {
      this.showStep(
        "dokumente",
        this.rights?.showDocumentArchive || this.rights?.updateDocumentArchive
      );
      this.showStep(
        "abrechnung",
        this.isByPass && this.combos?.allowReadVertragAbrechnung
      );
      this.showStep("aenderungslog", this.isIntern);
      this.showStep(
        "manuelle-struktur",
        this.isIntern || this.combos?.hasRightManuelleCourtage
      );
      this.showStep("aktivitaet", this.isByPass);
    },
    showStep(stepKey, show = false) {
      const step = this.steps.find((s) => s.stepKey === stepKey);
      step.hidden = !show;
      this.$refs.stepperForm.setStepHidden(stepKey, !show);
    },
    newAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_TERMIN);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
        activity: true,
      });
      this.openAppointment();
    },
    findAppointments() {
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_APPOINTMENTS);
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
        bereich: this.BEREICH,
        bereichId: this.sprodId,
        mode: 'Aufgabe',
        isActivity: false,
        selectedDate: null,
        discardChanges: this.resetAppointment,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    resetAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA);
    },
    dropFile(files) {
      this.$refs.documentsTableEl.dateiHochladen(files);
    },
    addDefaultBreadcrumbIfNotExists() {
      this.$addDefaultBreadcrumb([
        { fullPath: `${MAP_TYPE_TO_HOME_PATH[this.type]}/search`, },
      ]);
    },
  },
  mounted() {
    this.init();
    this.addDefaultBreadcrumbIfNotExists();
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$refs?.produktdaten?.checkData()) {
      await this.$store.dispatch(
        OTHER_PRODUCTS_TYPES.ACTIONS.SAVE_OTHER_PRODUCT
      );
    }
    this.$store.commit(
      OTHER_PRODUCTS_TYPES.MUTATIONS.RESET_OTHER_PRODUCT_STATE
    );

    this.$addBreadcrumb({
      to,
      from,
      label: this.pageMetadata.title,
    });

    next();
  },
};
</script>
