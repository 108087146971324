import NAFI_TYPES from './types';
import LOG_TYPES from '@/store/log/types'
import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";

const config = {
  defaultSpinner: true
};

export default {
  [NAFI_TYPES.ACTIONS.GET_NAFI_ZUGANGSDATEN]({ commit }) {
    axios.get(`${process.env.VUE_APP_API}/nafi/getNafiData`, config).then(response => {
      commit(NAFI_TYPES.MUTATIONS.GET_NAFI_ZUGANGSDATEN_SUCCESS, response.data)
    })
  },

  [NAFI_TYPES.ACTIONS.GENERATE_NAFI_PASSWORD]({ commit }) {
    axios.get(`${process.env.VUE_APP_API}/nafi/generateNafiPasswort`, config).then(response => {
      commit(NAFI_TYPES.MUTATIONS.GENERATE_NAFI_PASSWORD_SUCCESS, response.data)
    })
  },

  [NAFI_TYPES.ACTIONS.NAFI_LOGIN]({ state, dispatch }) {
    axios.post(`${process.env.VUE_APP_API}/nafi/nafiLogin`, state.nafiZugang, config).then(response => {
      if (response.data?.length) {
        response.data.forEach(entry => dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(entry?.label, entry.value)));
      }
    })
  },

}