var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canCall
    ? _c("a", { on: { click: _vm.tapiCall } }, [
        _vm._v(_vm._s(_vm.phoneNumber))
      ])
    : _c("span", [_vm._v(_vm._s(_vm.phoneNumber))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }