import * as phosphor from 'phosphor-vue';

const smallScreenMatchedMedia = matchMedia('screen and (max-width: 768px)');

export default {
  props: {
    actionKey: {
      type: String,
      required: true,
    },
    tid: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    smallScreenLabel: {
      type: String,
    },
    value: {
      type: Object,
    },
    isMainAction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Function,
      default: null,
    },
    icon: {
      required: false
    },
    size: {
      type: Number,
      default: 20
    },
    visible: {
      type: Function,
      default: null,
    },
    loading: {
      type: Function,
      default: null,
    }
  },
  data() {
    return {
      useSmallScreenLabel: false,
    };
  },
  computed: {
    responsiveLabel() {
      if (this.useSmallScreenLabel) {
        return this.smallScreenLabel || this.label;
      }
      return this.label;
    },
    isDisabled() {
      return this.disabled && this.disabled(this.actionKey);
    },
    iconComponent() {
      if (typeof this.icon === 'string') {
        return phosphor[this.icon];
      } else {
        return this.icon;
      }
    },
    isLoading() {
      return this.loading?.()
    }
  },
  methods: {
    onClick() {
      this.emitAction(this.value);
    },
    emitAction(value) {
      this.$emit("action", {
        key: this.actionKey,
        value,
      });
    },
    setUseSmallScreenLabel() {
      this.useSmallScreenLabel = smallScreenMatchedMedia.matches;
    },
  },
  mounted() {
    this.setUseSmallScreenLabel();
    smallScreenMatchedMedia.addEventListener('change', this.setUseSmallScreenLabel);
  },
  destroyed() {
    smallScreenMatchedMedia.removeEventListener('change', this.setUseSmallScreenLabel);
  },
}
