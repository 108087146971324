import DOCUMENT_TYPES from './types';
import { isRolesIncludes } from '@/router/guards';
import { ROLES, VIEW_ROLES } from '@/router/roles';
import { makeQueryParam } from '@/helpers/utils-helper';
import CORE_TYPES from '@/store/core/types'
import { prepareFilenameForDownload } from '@/helpers/string-helper';



export default {
  [DOCUMENT_TYPES.GETTERS.DOCUMENT_DATA](state) {
    return state.documentData;
  },
  [DOCUMENT_TYPES.GETTERS.GET_SIMPLE_FILE_LINK_MAKER](state, getters) {
    return (filename, fileId) => {
      const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]
      const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]

      const params = makeQueryParam({ fileId, token })
      
      return `${apiAddress}/download_service/get_simple_file/${prepareFilenameForDownload(filename)}?${params}`
    }
  },
  [DOCUMENT_TYPES.GETTERS.GET_ANTRAG_SCANS_LINK_MAKER](state, getters) {
    return (filename, nodeId, sendData) => {
      const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]
      const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]

      const params = makeQueryParam({nodeId, token })
      
      return `${apiAddress}/download_service/get_additional_file/${prepareFilenameForDownload(filename)}?${params}&${sendData.map((n) => `sendData=${n}`).join('&')}`;
    }
  },
  [DOCUMENT_TYPES.GETTERS.DOCUMENT_HISTORY](state) {
    return state.documentHistory;
  },
  [DOCUMENT_TYPES.GETTERS.COLLAPSED](state) {
    return state.collapsedMap;
  },   
}