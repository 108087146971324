var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container antrag-bottom-buttons-container" },
    [
      _c("div", { staticClass: "row" }, [
        _vm.hasPreviousStep
          ? _c(
              "div",
              { staticClass: "col-12 my-1 col-sm-auto hide-on-small-screen" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: "", isBlock: "" },
                    on: {
                      click: function($event) {
                        return _vm.onButtonPrevious()
                      }
                    }
                  },
                  [
                    _vm._v(" Zurück "),
                    _vm.showPreviousSpinner ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.hasNextStep
          ? _c(
              "div",
              { staticClass: "col-12 my-1 col-sm-auto" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { isBlock: "" },
                    on: {
                      click: function($event) {
                        return _vm.onButtonNext()
                      }
                    }
                  },
                  [
                    _vm._v(" Weiter "),
                    _vm.showNextSpinner ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.routeStep === "aktionen" || !_vm.hasNextStep
          ? _c(
              "div",
              { staticClass: "col-12 my-1 col-sm-auto" },
              [
                _c("BaseBackButtonBreadcrumb", {
                  attrs: { showAsButton: "", fixedLabel: "Schließen" },
                  on: {
                    beforeBackTrigged: function($event) {
                      return _vm.beforeBackTrigged()
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.hasBachToPreviousFormLink
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "BaseButton",
                  {
                    staticClass:
                      "antrag-bottom-buttons-back-previous-antrag-button",
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.navigateToPreviousAntrag()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " Zurück zur " +
                        _vm._s(_vm.backToPreviousFormLabel) +
                        "/Fertig "
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }