import RISIKOKLASSEN_DEFINITION_TYPES from './types';
import CORE_TYPES from '@/store/core/types';

import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {

  async [RISIKOKLASSEN_DEFINITION_TYPES.ACTIONS.GET_RISIKOKLASSEN_DEFINITION]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/risikoklassenDefinition`, config);
    commit(RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.SET_RISIKOKLASSEN_DEFINITION, response?.data || {});
  },

  async [RISIKOKLASSEN_DEFINITION_TYPES.ACTIONS.SAVE_RISIKOKLASSEN_DEFINITION]({ getters, commit, }) {
    const edited = getters[RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.RISIKOKLASSEN_DEFINITION_EDITED];
    const isChangeAllowed = getters[RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.IS_CHANGE_ALLOWED];
    if(!edited || !isChangeAllowed) return;

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/risikoklassenDefinition`, edited, config);
    commit(RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.RESET_RISIKOKLASSEN_DEFINITION_EDITED);
    commit(RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.SET_RISIKOKLASSEN_DEFINITION, response?.data || {});
  },

  async [RISIKOKLASSEN_DEFINITION_TYPES.ACTIONS.DELETE_RISIKOKLASSEN_DEFINITION]({ getters, commit, dispatch, }) {
    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/risikoklassenDefinition`, config);
    commit(RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.RESET_RISIKOKLASSEN_DEFINITION_EDITED);
    dispatch(RISIKOKLASSEN_DEFINITION_TYPES.ACTIONS.GET_RISIKOKLASSEN_DEFINITION);
  },

}
