var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBaseHeader
    ? _c(
        "BaseHeaderContainer",
        { ref: "baseHeaderContainer" },
        [
          _c("StoppuhrModal"),
          _c("CallModal"),
          _c("IncomingCallDialog"),
          _c("HungUpCallDialog"),
          _c(
            "div",
            [
              _c("HeaderAlert"),
              _c("BaseTopNavbar"),
              _c("BaseTopProgressBar"),
              _c("BaseNavbarWorkspaces", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showWorkspaceHeader,
                    expression: "showWorkspaceHeader"
                  }
                ]
              }),
              _c("IncomingCallTapiDialog"),
              _c("OutcomingCallTapiDialog"),
              _c("CallTapiModal")
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }