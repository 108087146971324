<template>
    <FlexibleList
      :rows="rows"
    >
      <template #title="row">
        {{ row.vorname }} {{ row.name }}
      </template>

      <template #value="row">
        {{ row.date }}
      </template>
    </FlexibleList>
</template>


<script>
import BERICHTE_TYPES from '@/store/berichte/types';
import { mapGetters } from "vuex";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import { toDateString } from '@/filters.js';

export default {
  components: {
    FlexibleList,
  },
  computed: {
    ...mapGetters({
      altersvorsorge: BERICHTE_TYPES.GETTERS.ALTERSVORSORGE,
    }),
    rows() {
      return (this.altersvorsorge?.zulagen?.vorsorgeZulagenKinder || []).map(row => ({
        ...row,
        date: toDateString(row.geburtsdatum),
      }))
    },
  },
};
</script>