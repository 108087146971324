var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSearchMetadata
    ? _c("BaseFilter", {
        attrs: {
          filterId: _vm.filterId,
          showAlwaysPrimaryselection: false,
          metadata: _vm.searchMetadata,
          defaultOptions: _vm.defaultOptionsFilter,
          immidiateSearch: "",
          showSaveButton: !!_vm.filterId
        },
        on: { onFilter: _vm.search }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }