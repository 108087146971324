<template>
  <BaseModal
    ref="modal"
    modalTitle="Person Beziehungen Bearbeiten"
    :showDefaultButtons="false"
  >
    <template #default>
      <div class="box__title">{{ personFullName }}</div>

      <PersonBeziehungFields 
        v-if="person"
        :relationship="person.relationship" 
        @change="dataChanged($event)"
      />
    </template>
    <template #footer>
      <BaseButton 
        class="mr-3"
        isSecondary
        @click="close()">Abbrechen</BaseButton>
      <BaseButton 
        @click="save()" 
        :disabled="!hasChanged || loading">
        <AnimatedSpinner v-if="loading" /> Speichern
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import BaseModal from '@/components/core/BaseModal.vue';
import PersonBeziehungFields from './PersonBeziehungFields.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

import persoenlicheDatenMixin from '../persoenliche-daten-mixin';
import { mapGetters } from 'vuex'

export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      loading: false,
      person: null,
      relationship: null,
    };
  },
  computed: {
    ...mapGetters({
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
    }),
    personFullName() {
      return `${this.person?.firstName || ''} ${this.person?.lastName || ''}`;
    },
    hasChanged() {
      return this.relationship && !!Object.keys(this.relationship).length;
    },
  },
  methods: {
    open(person) {
      this.$refs.modal.open();
      this.person = person;
    },
    close() {
      this.$refs.modal.close();
    },
    dataChanged(relationship) {
      this.relationship = { ...relationship, };
    },
    async save() {
      if(!this.hasChanged) {
        this.close();
        return ;
      }

      const personId = this.person?.personId;
      const relationship = {
        personId,
        ...this.relationship,
      };
      const payload = {
        personId: this.personId,
        relationship,
      };
      this.loading = true;
      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_RELATIONSHIP, payload);
      this.loading = false;
      this.$emit('saved');
      this.close();
    },
  },
  components: {
    BaseModal,
    PersonBeziehungFields,
    BaseButton,
    AnimatedSpinner,
  },
}
</script>
