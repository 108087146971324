export const MUTATION_PREFIX = 'fondsAntragCheck_';
export const ACTIONS_PREFIX = 'fondsAntragCheck_';
export const GETTERS_PREFIX = 'fondsAntragCheck_';

export default {
  MUTATIONS: {
    SETUP: MUTATION_PREFIX + 'SETUP',
    FILTER: MUTATION_PREFIX + 'FILTER',
    RESET_FILTER: MUTATION_PREFIX + 'RESET_FILTER',
    UPDATE_ROW: MUTATION_PREFIX + 'UPDATE_ROW',
    INFO: MUTATION_PREFIX + 'INFO',
    
  },
  ACTIONS: {
    //FIND_CORNERS: ACTIONS_PREFIX + 'FIND_CORNERS',
    SETUP: ACTIONS_PREFIX + 'SETUP',
    FILTER: ACTIONS_PREFIX + 'FILTER',
    SET_STATUS: ACTIONS_PREFIX + 'SET_STATUS',
    INFO: ACTIONS_PREFIX + 'INFO',
    
  },
  GETTERS: {
    SETUP: GETTERS_PREFIX + 'SETUP',
    FILTER: GETTERS_PREFIX + 'FILTER',
    ROW_COUNT: GETTERS_PREFIX + 'ROW_COUNT',
    INFO: GETTERS_PREFIX + 'INFO',
  }
}