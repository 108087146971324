import dayjs from 'dayjs';
import SCHADENSMELDUNG_TYPES from '@/store/schadensmeldung/types';


export default {
  methods: {
    parseDate(dateStr) {
      if(dateStr && !(dateStr instanceof Date)) {
        return dayjs(dateStr, 'DD.MM.YYYY').toDate();
      }
      return dateStr;
    },
    formatDate(date) {
      if(date) {
        const dayjsObject =  dayjs(this.parseDate(date));
        if(dayjsObject.isValid()) {
          return dayjsObject.format('DD.MM.YYYY');
        } else {
          return dayjs(this.parseDate(date)).format('DD.MM.YYYY');
        }
      }
      return date;
    },
    dataChanged(fieldName, event) {
      const form = { ...this.form };
      if(fieldName){
        form[fieldName] = event;
      }
      if(form.ereignisdatum) {
        form.ereignisdatum = this.formatDate(form.ereignisdatum);
      }
      if(form.meldedatum) {
        form.meldedatum = this.formatDate(form.meldedatum);
      }
      if(form.erledigtDatum) {
        form.erledigtDatum = this.formatDate(form.erledigtDatum);
      }

      // important to prevent save schadensmeldung with an invalid state between steps
      if(this.validation){
        form.validation = this.validation;
      }

      this.$store.commit(SCHADENSMELDUNG_TYPES.MUTATIONS.APPEND_SCHADENSMELDUNG_EDITED, form);
    },
  },
};
