var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Freistellungsauftrag bearbeiten" }
      }),
      _vm.formData
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "a2e7b485-27fa-46e2-93b3-e1445b1f331c" }
            },
            [
              _c("ComboBox", {
                attrs: {
                  label: "Gesellschaft",
                  values: _vm.gesellschaften,
                  disabled: _vm.formData.gesellschaft.disabled,
                  sortComboboxValues: false,
                  firstEmpty: false
                },
                on: {
                  change: function($event) {
                    _vm.formData.isFreieEingabe = $event === "FREIE_EINGABE"
                  }
                },
                model: {
                  value: _vm.formData.gesellschaft,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "gesellschaft", $$v)
                  },
                  expression: "formData.gesellschaft"
                }
              }),
              _vm.formData.isFreieEingabe
                ? _c("InputField", {
                    attrs: { label: "freie Eingabe" },
                    model: {
                      value: _vm.formData.freieEingabe,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "freieEingabe", $$v)
                      },
                      expression: "formData.freieEingabe"
                    }
                  })
                : _vm._e(),
              _c("DatePickerField", {
                attrs: { isValueAsString: "", label: " Gültigkeit von" },
                model: {
                  value: _vm.formData.gueltigAb,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "gueltigAb", $$v)
                  },
                  expression: "formData.gueltigAb"
                }
              }),
              _c("DatePickerField", {
                attrs: { isValueAsString: "", label: " Gültigkeit bis" },
                model: {
                  value: _vm.formData.gueltigBis,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "gueltigBis", $$v)
                  },
                  expression: "formData.gueltigBis"
                }
              }),
              _c("InputField", {
                attrs: { label: "Betrag", type: "currency" },
                model: {
                  value: _vm.formData.betrag,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "betrag", $$v)
                  },
                  expression: "formData.betrag"
                }
              }),
              _c("DatePickerField", {
                attrs: {
                  isValueAsString: "",
                  label: "Aktuelles Datum des Verfügungsbetrags"
                },
                model: {
                  value: _vm.formData.aktuellesJahr,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "aktuellesJahr", $$v)
                  },
                  expression: "formData.aktuellesJahr"
                }
              }),
              _c("InputField", {
                attrs: { label: "Verfügungsbetrag", type: "currency" },
                model: {
                  value: _vm.formData.verfuegbar,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "verfuegbar", $$v)
                  },
                  expression: "formData.verfuegbar"
                }
              }),
              _c("InputField", {
                attrs: { label: "Verbrauchter Freibetrag", type: "currency" },
                model: {
                  value: _vm.formData.zinsen,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "zinsen", $$v)
                  },
                  expression: "formData.zinsen"
                }
              }),
              _c(
                "div",
                { staticClass: "row mb-0" },
                [
                  _c("span", { staticClass: "col-auto" }, [
                    _vm._v("Bemerkung")
                  ]),
                  !_vm.isCustomerOnly
                    ? _c("InputToggleSwitch", {
                        staticClass: "col",
                        attrs: {
                          label: "Für Kunde sichtbar",
                          inLineLabel: true
                        },
                        model: {
                          value: _vm.formData.bemSichtbarKunde,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "bemSichtbarKunde", $$v)
                          },
                          expression: "formData.bemSichtbarKunde"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("InputTextArea", {
                attrs: { label: "", rows: 4, disabled: _vm.isCustomerOnly },
                model: {
                  value: _vm.bemerkung,
                  callback: function($$v) {
                    _vm.bemerkung = $$v
                  },
                  expression: "bemerkung"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }