var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        labelButtonCancel: "Ok",
        isModalAsFullPage: false,
        showConfirmButton: false
      },
      on: { onCloseButton: _vm.close, onCancelButton: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [_vm._v(" Der Anruf wurde beendet ")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.callInfo && _vm.callInfo.established
        ? _c("span", [
            _vm._v(
              "Vielen Dank, dass Sie die Telefonie-Funktion verwendet haben."
            )
          ])
        : _vm._e(),
      _vm.callInfo && _vm.callInfo.alertMsg
        ? _c("div", [_vm._v(" " + _vm._s(_vm.callInfo.alertMsg) + " ")])
        : _vm._e(),
      (_vm.isBroker || _vm.allowKundenFreigabe) &&
      _vm.callInfo &&
      _vm.callInfo.recording
        ? _c("div", { staticClass: "kundenFreigabe" }, [
            _vm._v(
              " Die Aufzeichnung dieses Telefonats finden Sie innerhalb der nächsten 24 Stunden im Dokumentenarchiv. "
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }